import React from 'react';

// css
import './LoadingBar.css';

const LoadingBar = props => {
	const {
		className = '',
		loading = false,
		porcentagem = 0,
		texto = 'Enviando, aguarde...',
		textoFinalizado = 'Enviado com sucesso',
		abs,
	} = props;

	return (
		<div className={`cpnt-loadingbar ${abs ? 'cpnt-loadingbar-absolute' : ''} ${className}`}>
			{loading ? (
				<>
					<div className="cpnt-loadingbar-barra" style={{width: `${porcentagem}%`}}></div>
					<div className="cpnt-loadingbar-infotexto">{`${texto} ${porcentagem}%`}</div>
				</>
			) : (
				<div className="cpnt-loadingbar-infotexto">{textoFinalizado}</div>
			)}
		</div>
	);
};

export default LoadingBar;