import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast as msg } from 'react-toastify';

// css
import './FormUsuario.css';

// API
import { api } from "@_api/api";

// Imagens
import imgIconeAtivo from '@svg/solid/circle-check.svg';
import imgIconeInativo from '@svg/solid/circle-x.svg';
import imgUserPic from '@img/userpic.png';

// Componentes
import Icone from '@cpnt/Icone/Icone';
import ImgTituloDesc from '@cpnt/ImgTituloDesc/ImgTituloDesc';
import Input2 from '@cpnt/Input2/Input2';
import InputCpfCnpj from '@cpnt/InputCpfCnpj/InputCpfCnpj';
import InputTelefone from '@cpnt/InputTelefone/InputTelefone';
import PortalConfirmacao from '@portalGeral/PortalConfirmacao/PortalConfirmacao';
import { Select2 } from '@cpnt/Select2/Select2';

// Objetos
import { NIVEIS_ACESSO } from '@config/objetos';
import { caminhoFoto, textoNivelAcesso } from '@func/utils';

// Routes
import { closePortal, getPortalDados, gets, isShowPortal, showPortal } from '@route/routeUtils';
import { msgs, msgStatus } from "@_func/request";

const FormUsuario = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		isCadastro,
		stateDados,
		stateEmpresas,
		setStateDados,
		stateCargos,
		after,
		loadingGetEmpresas,
		loadingGetCargos,
		onSuccessStatus,
		onErrorStatus,
	} = props;

	const [stateGet, setStateGet] = useState(gets());
	const [loadingEditarStatus, setLoadingEditarStatus] = useState(false);

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	/*
	** Edição de active
	** ================================================== */
	const editarStatusUsuario = async () => {
		setLoadingEditarStatus(true);

		const active = stateDados.active ? 0 : 1;

		const retUpdateActiveUsuario = await api.users.update.put({
			id: stateDados.id,
			active: active,
		});

		if(retUpdateActiveUsuario.status === 200) {
			closePortal({portal: 'confirmacao', stateGet, navigate});
			msg.success(`Usuário ${active ? 'ativado' : 'desativado'} com sucesso`);
			setStateDados({
				...stateDados,
				active: active,
			});
			onSuccessStatus({
				ret: retUpdateActiveUsuario,
			})
		} else {
			msgs([retUpdateActiveUsuario]);
			onErrorStatus({
				ret: retUpdateActiveUsuario,
			});
		}

		setLoadingEditarStatus(false);
	}

	return (
		<>
			{!isCadastro ? (
				<ImgTituloDesc
					wrap="padding-b-15"
					titulo={stateDados.name}
					desc={
						<div>
							<div>ID do usuário: {stateDados.id}</div>
							<div className={stateDados.role === 1 ? 'color-red' : 'color-green'}>{textoNivelAcesso(stateDados.role)}</div>
						</div>
					}
					img={stateDados.photo ? caminhoFoto(stateDados.photo).url : imgUserPic}
					classNameTitulo="titulo"
					classNameDesc="td-desc"
					classNameImgBox="foto-wrap"
					responsivo={true}
					tamanho="80px"
					classNameBg="bg"
				/>
			) : null}
			<div className="wrap">
				<div className="flex flex-wrap">
					{isCadastro ? (
						<div className="input-box w-100p">
							<Select2
								label="Empresa"
								req
								_={{
									value: stateDados.id_company,
									onChange: e => {
										setStateDados({
											...stateDados,
											id_company: e.value,
										});
									},
									placeholder: 'Selecione uma empresa',
									options: stateEmpresas,
									isClearable: false,
								}}
								loading={loadingGetEmpresas}
							/>
						</div>
					) : (
						<div className="input-box w-100p">
							<div className="input input-inativo w-100p" onClick={() => {
								msg.warning('Não é possível alterar a empresa do usuário.');
							}}>
								<span className="label ativo">Empresa</span>
								<span>{stateDados.company}</span>
							</div>
						</div>
					)}

					<div className="input-box w-40p">
						<Input2
							label="Nome completo"
							req
							_={{
								value: stateDados.name,
								onChange: e => setStateDados({
									...stateDados,
									name: e.target.value,
								}),
							}}
						/>
					</div>
					<div className="input-box w-30p">
						<InputCpfCnpj
							label='CPF ou CNPJ'
							_={{
								value: stateDados.cpf_cnpj,
								onChange: e => {
									setStateDados({
										...stateDados,
										cpf_cnpj: e.target.value,
									})
								}
							}}
						/>
					</div>
					<div className="input-box w-30p">
						<InputTelefone
							label="Telefone"
							_={{
								value: stateDados.phone,
								onChange: e => {
									setStateDados({
										...stateDados,
										phone: e,
									})
								}
							}}
						/>
					</div>
					<div className="input-box w-50p">
						<Select2
							label="Cargo"
							req
							_={{
								value: stateDados.id_position,
								options: stateCargos,
								onChange: e => {
									setStateDados({
										...stateDados,
										id_position: e.value,
									});
								},
							}}
							loading={loadingGetCargos}
						/>
					</div>
					<div className="input-box w-50p">
						<Select2
							label="Nível de acesso"
							req
							_={{
								value: stateDados.role,
								options: NIVEIS_ACESSO,
								onChange: e => {
									setStateDados({
										...stateDados,
										role: e.value,
									});
								},
							}}
						/>
					</div>

					<div className="input-box w-70p">
						<Input2
							label="E-mail"
							req
							_={{
								value: stateDados.email,
								onChange: e => {
									setStateDados({
										...stateDados,
										email: e.target.value,
									});
								},
							}}
						/>
					</div>
					<div className="input-box w-30p">
						<Input2
							label={isCadastro ? 'Senha padrão' : 'Nova senha'}
							{...(isCadastro ? {req: true} : {})}
							_={{
								...(stateDados.password && stateDados.password !== '' ? {
									value: stateDados.password
								} : {}),
								onChange: e => {
									var valor = e;
									if(typeof(e) !== 'string') {
										valor = e.target.value;
									}
									setStateDados({
										...stateDados,
										password: valor,
									});
								},
							}}
							btnPassword={true}
							onClickBtnPassword={e => setStateDados({
								...stateDados,
								password: e,
							})}
						/>
					</div>
				</div>

				{!isCadastro ? (
					<div className="flex flex-between margin-t-15">
						<div
							className="inline-flex flex-center-v cursor-pointer"
							onClick={() => showPortal({portal: 'confirmacao', dados: stateDados.id, stateGet, navigate})}
						>
							<Icone
								imagem={stateDados.active ? imgIconeAtivo : imgIconeInativo}
								cor={stateDados.active ? '#229d3e' : '#E74C3C'}
								tamanho="36px"
							/>
							<div className="margin-l-10">
								<div>{stateDados.active ? 'Usuário ativo' : 'Usuário inativo'}</div>
								<div className="fs-12 color-1">{stateDados.active ? 'Clique para desativar' : 'Clique para ativar'}</div>
							</div>
						</div>
					</div>
				) : null}

			</div>

			{after}

			<PortalConfirmacao
				titulo="Alteração de status"
				desc={
					<div>
						<div>Deseja realmente alterar o status desse usuário para {stateDados.active ? <strong className="color-red">inativo</strong> : <strong className="color-green">ativo</strong>}?</div>
						<div className="margin-t-15">Essa alteração não influência em quaisquer outros campos que ainda não foram salvos.</div>
					</div>
				}
				show={isShowPortal({portal: 'confirmacao', stateGet})}
				id={getPortalDados({portal: 'confirmacao', stateGet})}
				onClickCancelar={() => closePortal({portal: 'confirmacao', stateGet, navigate})}
				onClose={() => closePortal({portal: 'confirmacao', stateGet, navigate})}
				onSuccess={() => closePortal({portal: 'confirmacao', stateGet, navigate})}
				onClickConfirmar={() => editarStatusUsuario(stateDados.id)}
				loadingConfirmar={loadingEditarStatus}
				closeOnEsc={false}
			/>
		</>
	);
};

export default FormUsuario;