import React from 'react';

// css
import './ImgDesc.css';

// Imagens
import imgUserPicDefault from '@img/userpic.png';
import { cssBg, cssTamanho } from '@func/utils';
import Tip from '@cpnt/Tip/Tip';

const ImgDesc = props => {
	const {
		titulo,
		desc,
		img,
		className = '',
		wrap = '',
		classNameWrap = '',
		classNameTitulo = 'td-desc-alt text-one-line',
		classNameDesc = '',
		classNameImgBox = 'foto-wrap',
		classNameImg = '',
		tamanho = '40px',
		preview = true,
		tipTitulo,
		onClick,
		responsivo,
		classNameBg = 'bg',
		_tipDefaults = {
			placement: 'right-center',
		},
		before,
		after,
	} = props;

	var {
		placeholder = 'userpicture',
		imgPreview,
	} = props;

	if(placeholder) {
		switch (placeholder) {
			case 'userpicture': placeholder = imgUserPicDefault; break;
			case 'blank': placeholder = ''; break;
			default: placeholder = ''; break;
		}
	}

	if(!imgPreview) {
		imgPreview = img;
	}

	return (
		<div className={`cpnt-imgdesc ${wrap} ${classNameWrap}`}>
			{before}
			<div className={`cpnt-imgdesc-box ${responsivo ? 'cpnt-imgdesc-resp' : ''} ${className}`} {...(onClick ? {onClick: onClick} : {})}>
				{img ? (
					preview ? (
						<Tip
							tip={
								<div className={`cpnt-imgdesc-foto-box ${classNameImgBox}`} style={cssTamanho(tamanho)}>
									<div className={`cpnt-itemfotodesc-img fill ${classNameBg} ${classNameImg}`} style={cssBg(img)}>
										{placeholder && <div className={`cpnt-itemfotodesc-img-placeholder fill-bg ${classNameBg}`} style={cssBg(placeholder)}></div>}
									</div>
								</div>
							}
							closeOnLeave
							_defaults={_tipDefaults}
							_={{
								delayLeave: 0,
							}}
							{...(tipTitulo ? {titulo: tipTitulo} : {})}
						>
							<div className="cpnt-imgdesc-foto-preview-box">
								<div className={`cpnt-imgdesc-foto-preview ratio-1 ${classNameBg}`} style={cssBg(imgPreview)}>
									{placeholder && <div className={`cpnt-itemfotodesc-img-placeholder fill-bg ${classNameBg}`} style={cssBg(placeholder)}></div>}
								</div>
							</div>
						</Tip>
					) : (
						<div className={`cpnt-imgdesc-foto-box ${classNameImgBox}`} style={cssTamanho(tamanho)}>
							<div className={`cpnt-itemfotodesc-img fill ${classNameBg} ${classNameImg}`} style={cssBg(img)}>
								{placeholder && <div className={`cpnt-itemfotodesc-img-placeholder fill-bg ${classNameBg}`} style={cssBg(placeholder)}></div>}
							</div>
						</div>
					)
				) : null}
				<div className="cpnt-imgdesc-info-box">
					{titulo && <div className={`cpnt-imgdesc-titulo ${classNameTitulo}`}>{titulo}</div>}
					{desc && <div className={`cpnt-imgdesc-desc ${classNameDesc}`}>{desc}</div>}
				</div>
			</div>
			{after}
		</div>
	);
};

export { ImgDesc };