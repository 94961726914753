import React, { useState } from 'react';

// css
import './SecaoRedesSociais.css';

// Portais
import { PortalRedeSocialFormCadastro } from "@cpnt/Portais/PortaisRedesSociais/PortalRedeSocialFormCadastro/PortalRedeSocialFormCadastro";
import { PortalRedeSocialFormEdicao } from "@cpnt/Portais/PortaisRedesSociais/PortalRedeSocialFormEdicao/PortalRedeSocialFormEdicao";

// Componentes
import { ListaSecao } from "@cpnt/ListaSecao/ListaSecao";
import { dataEngParaBr } from "@func/utils";

const SecaoRedesSociais = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		classNameTituloBox = '',
		classNameListaWrap = 'margin-t-25',
		classNameBox = 'box-2',

		subtitulo,

		stateRedesSociais = [],
		setStateRedesSociais,
		loadingGetRedesSociais,
	} = props;

	const [showPortalAdd, setShowPortalAdd] = useState(false);
	const [showPortalEditar, setShowPortalEditar] = useState(false);
	const [stateRedeSocialEditar, setStateRedeSocialEditar] = useState({});

	return (
		<>
			<div className={`cpnt-secexp ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-secexp-box ${className}`}>
					<ListaSecao
						titulo="Redes sociais"
						subtitulo={subtitulo}
						textoAdd="Adicionar"
						textoNenhumItemEncontrado="Nenhuma rede social adicionada"
						stateDados={stateRedesSociais}
						setStateDados={setStateRedesSociais}
						keyTitulo="url"
						keyLink="url"
						keyDescCb={dataEngParaBr}
						classNameWrap={classNameListaWrap}
						classNameTituloBox={classNameTituloBox}
						classNameBox={classNameBox}
						exibirApenasChecked={false}
						onClickAdd={e => setShowPortalAdd(true)}
						onClickEdit={e => {
							setStateRedeSocialEditar({
								key: e.key,
								...e.dados,
							});
							setShowPortalEditar(true);
						}}
						onClickRemover={e => {
							var itens = stateRedesSociais.filter((val, key) => key !== e.key);
							setStateRedesSociais(itens);
						}}
						loading={loadingGetRedesSociais}
						alt
					/>
				</div>
			</div>

			<PortalRedeSocialFormCadastro
				show={showPortalAdd}
				onClose={e => setShowPortalAdd(false)}
				onSuccess={e => {
					var exp = [...stateRedesSociais];
					exp.push(e);
					setStateRedesSociais(exp);
					setShowPortalAdd(false);
				}}
			/>

			<PortalRedeSocialFormEdicao
				show={showPortalEditar}
				onClose={e => setShowPortalEditar(false)}
				dados={stateRedeSocialEditar}
				onSuccess={e => {
					var exp = [...stateRedesSociais];
					exp[e.key] = e;
					setStateRedesSociais(exp);
					setShowPortalEditar(false);
				}}
			/>
		</>
	);
};

export { SecaoRedesSociais };
