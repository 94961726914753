import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import c from './PortalEmpresasFormPlano.module.scss';

// API
import { apiCompany } from '@_api/requests/company/company';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { nomeNivelAcesso } from "@_func/profile";
import { msgs, msgStatus } from "@_func/request";
import { camposInvalidos } from '@func/utils';
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";
import Info2 from "@cpnt/Info2/Info2";

const PortalEmpresasFormPlano = props => {

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = c['portal'],
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		id: '', role: 9,
	};

	const [showPortalConfirmacao, setShowPortalConfirmacao] = useState(false);
	const [showPortalAlterado, setShowPortalAlterado] = useState(false);

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	// Edição
	const [loadingEditarEmpresa, setLoadingEditarEmpresa] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados(camposDefault);
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await apiCompany.getOne.get(id);

		if(retGetItem.status === 200) {
			setStateDados({
				...retGetItem.data,
				role: 9,
			});
			_setStateDados(msgStatus(retGetItem));
		} else {
			_setStateDados(msgStatus(retGetItem));
			msgs([retGetItem]);
		}

		setLoading(false);
	}

	/*
	** Editar empresa
	** ================================================== */
	const editarEmpresa = async () => {
		if(loadingEditarEmpresa) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			id: stateDados.id_empresa,
			role: stateDados.role,
		};

		if(camposInvalidos(dadosEnviar, {
			role: 'Plano da empresa (nível de acesso)'
		}, [
			'id',
			'role',
		])) return;

		setLoadingEditarEmpresa(true);

		const retUpdateEmpresa = await apiCompany.update.put(dadosEnviar);

		if(retUpdateEmpresa.status === 200) {
			msg.success('Nível de acesso alterado com sucesso');
			// onSuccess({
			// 	ret: retUpdateEmpresa,
			// });
			onClose();
			setShowPortalConfirmacao(false);
			setShowPortalAlterado(true);
		} else {
			msgs([retUpdateEmpresa]);
			onError({
				ret: retUpdateEmpresa,
			});
		}

		setLoadingEditarEmpresa(false);
	}

	return (
		<>
			<Portal
				titulo="Alterar nível de acesso da empresa"
				show={show}
				closeOnEsc={closeOnEsc}
				onClose={onClose}
				className={portalClassName}
			>
				{loading ? (
					<Loading
						texto="Carregando dados, aguarde..."
						wrap="padding-30"
					/>
				) : (
					<div className={wrap}>
						<div className={className}>
							{_stateDados.status === 200 ? (
								<>
									<div>
										<div className="box-txt">
											<div><b>Aviso:</b> diversas informações serão afetadas após a mudança e não será possível desfazer.</div>
										</div>
										<div className={`${c['boxes-nivel']}`}>
											<div className={`${c['box-nivel']} ${c['box-nivel-free']} ${stateDados.role === 9 ? c['active'] : ''}`} onClick={() => setStateDados({...stateDados, role: 9})}>
												{nomeNivelAcesso(9)}
											</div>
											<div className={`${c['box-nivel']} ${c['box-nivel-premium']} ${stateDados.role === 3 ? c['active'] : ''}`} onClick={() => setStateDados({...stateDados, role: 3})}>
												{nomeNivelAcesso(3)}
											</div>
										</div>
										<div>Após salvar, a mudança será aplicada imediatamente. É recomendado que o usuário faça login novamente após a mudança.</div>
										<div className="form-bottom">
											<Button2
												_={{
													value: 'Cancelar',
													className: 'btn btn-1',
													type: 'button',
													onClick: onClose,
												}}
												classExtra="btn-1"
											/>
											<Button2
												_={{
													value: 'Alterar plano',
													onClick: () => setShowPortalConfirmacao(true)
												}}
												loading={loadingEditarEmpresa}
												wrap="margin-l-15"
											/>
										</div>
									</div>
								</>
							) : (
								<Info
									texto={_stateDados.msg}
									icone="erro"
								/>
							)}
						</div>
					</div>
				)}

			</Portal>

			<PortalConfirmacao
				titulo="Deseja realmente alterar o plano dessa empresa?"
				desc="Diversas informações serão afetadas após a mudança e não será possível desfazer."
				show={showPortalConfirmacao}
				onClose={() => setShowPortalConfirmacao(false)}
				onClickCancelar={() => setShowPortalConfirmacao(false)}
				onClickConfirmar={() => {
					editarEmpresa();
				}}
				loadingConfirmar={loadingEditarEmpresa}
			/>

			<Portal
				titulo="O plano novo já está ativo"
				show={showPortalAlterado}
				onClose={() => {
					setShowPortalAlterado(false);
					onClose();
				}}
				className={`${c['portal-info-alterado']}`}
			>
				<Info2
					icone="sucesso"
					titulo="Plano alterado com sucesso"
					desc={<div className="margin-t-30">As alterações já entraram em vigor. É recomendado que a empresa faça login novamente na plataforma para que as alterações tenham efeito por completo.</div>}
					iconeCor="#229d3e"
					iconeTamanho="130px"
				/>
			</Portal>
		</>
	);
};

export default PortalEmpresasFormPlano;