import React, { useState } from 'react';

// css
import './PerfilUsuario.css';

// Imagens
import imgIconeTelefone from '@svg/regular/phone.svg';
import imgIconeEmail from '@svg/solid/at.svg';
import imgIconeUsuario from '@svg/solid/user-lock.svg';
import imgIconeSite from '@svg/regular/earth-americas.svg';
import imgIconeLinkedin from '@svg/brands/linkedin.svg';
import imgLogoCrowd from '@img/logo.svg';

// Components
import Icone from '@cpnt/Icone/Icone';
import LinhaItem from '@cpnt/LinhaItem/LinhaItem';
import Loading from '@cpnt/Loading/Loading';
import stringImg from '@cpnt/stringImg/stringImg';
import Portal from '@cpnt/Portal/Portal';

// Partes
import FotoGlyph from '../FotoGlyph/FotoGlyph';

// Funções
import { addPrefixo, calcularAnos, caminhoFoto, formatarTelefone, validarDataAPI } from '@func/utils';
import { PortalFoto } from "../../PortalFoto/PortalFoto";
import { fotoOuGravatar } from "@_func/profile";

const PerfilUsuario = props => {

	const {
		active,
		description,
		email,
		site,
		linkedin,
		name,
		phone,
		photo,
		role_company,

		birth_date,
		city,
		state,
		access_level,
		company,
		role_position,
		area,
		area_expertise,
		about,

		// States
		habilidadesArea,
		competencias,

		// Loading
		loadingGetHabilidadesArea,
		loadingGetCompetencias,
	} = props;

	const [showPortalFoto, setShowPortalFoto] = useState(false);

	return (
		<div className="cpnt-perfil cpnt-perfil-usuario">
			<div className="cpnt-perfilusuario-wrap">

				<header className="cpnt-perfilusuario-header">
					<div className="cpnt-perfilusuario-img-container">
						<FotoGlyph
							foto={fotoOuGravatar({foto: photo, email: email})}
							wrap="cpnt-perfilusuario-img"
							{...(photo ? {
								onClick: () => setShowPortalFoto(true),
								className: 'cursor-pointer',
							} : {})}
						/>
					</div>

					<div className="cpnt-perfilusuario-nome-container">
						<div className="cpnt-perfil-info cpnt-perfilusuario-info-box">
							<div className="cpnt-perfilusuario-nome">{name}</div>
							<div className="cpnt-perfilusuario-info">
								<div className="flex">
									<div>{access_level || 'Nível de acesso não informado'}</div>
								</div>

								<div className="cpnt-perfilusuario-empersainfo">
									<div>
										<LinhaItem
											className="block"
											titulo="Empresa"
											desc={company}
											alt
										/>
										<LinhaItem
											className="block"
											titulo="Papel"
											desc={role_company || 'Papel não informado'}
											alt
										/>
										<LinhaItem
											className="block"
											titulo="Atuando na área de"
											desc={area || 'Área não informada'}
											alt
										/>
									</div>
								</div>
							</div>

						</div>
					</div>
				</header>

				<div className="cpnt-perfil-wrap">
					<aside className="cpnt-perfil-sidebar">
						<div className="cpnt-perfil-sidebar-inner">

							{active !== 1 ? (
								<>
									<div className="cpnt-perfil-secao">
										<div className="cpnt-perfil-info-inativo">
											<Icone
												imagem={imgIconeUsuario}
												cor="#E74C3C"
												tamanho="26px"
											/>
											<span className="margin-l-10 bold">Usuário desativado</span>
										</div>
									</div>
								</>
							) : null }

							<div className="cpnt-perfil-secao">
								<h3 className="cpnt-perfil-titulo">Sobre {name.split(' ')[0]}</h3>
								<div className="no-wrap-line">
									{about || 'Nenhuma informação adicionada.'}
								</div>
							</div>

							<div className="cpnt-perfil-secao">
								<h3 className="cpnt-perfil-titulo">Competências</h3>
								<div>
									{loadingGetCompetencias ? (
										<Loading alt />
									) : (
										<>
											{competencias && competencias.length ? (
												<div className="wrap-3">
													<div className="flex flex-wrap">
														{competencias.map((val, key) => {
															return (
																<div key={key} className="cpnt-perfil-tag-sidebar">
																	{val.name}
																</div>
															)
														})}
													</div>
												</div>
											) : (
												<div>Nenhuma competência cadastrada.</div>
											)}
										</>
									)}
								</div>
							</div>
						</div>
					</aside>
					<div className="cpnt-perfil-conteudo">
						<div className="cpnt-perfil-secao">
							<h3 className="cpnt-perfil-titulo">Perfil profissional</h3>
							<div className="relative">
								<div className="cpnt-perfildesc-glyph cpnt-perfildesc-glyph-1"></div>
								<div className="cpnt-perfildesc-glyph cpnt-perfildesc-glyph-2"></div>
								<div className="cpnt-perfildesc-glyph cpnt-perfildesc-glyph-3"></div>
								<div className="cpnt-perfildesc-glyph cpnt-perfildesc-glyph-4"></div>
								<div className="cpnt-perfil-descricao-perfil scroll scroll-alt">
									{description || 'Nenhuma informação adicionada.'}
								</div>
							</div>
						</div>

						<div className="cpnt-perfil-secao">
							<h3 className="cpnt-perfil-titulo">Atuando na área de {area || '"Nenhuma área informada"'}</h3>
							<div>
								{loadingGetHabilidadesArea ? (
									<Loading alt />
								) : (
									<>
										{habilidadesArea && habilidadesArea.length ? (
											<>
												<div>Principais habilidades na área de <strong>{area}</strong>:</div>
												<div className="wrap-3 margin-t-10">
													<div className="flex flex-wrap">
														{habilidadesArea.map((val, key) => {
															return (
																<div key={key} className="cpnt-perfil-tag-conteudo">
																	{val.name}
																</div>
															)
														})}
													</div>
												</div>
											</>
										) : (
											<div>Nenhuma habilidade cadastrada.</div>
										)}
									</>
								)}
							</div>
						</div>

					</div>
				</div>
			</div>

			<div className="cnpt-perfil-footer">
				<div className="cnpt-perfil-footer-wrap"><img src={imgLogoCrowd} alt="logo-crowd" /></div>
			</div>

			<PortalFoto
				show={showPortalFoto}
				foto={photo}
				onClose={() => {
					setShowPortalFoto(false);
				}}
			/>

		</div>
	)
};

export default PerfilUsuario;