import React, { useEffect, useRef, useState } from 'react';
import ReactWebcam from 'react-webcam';

// Imagens
import Button2 from "@cpnt/Button2/Button2";
import TextoIconeLink from "@cpnt/TextoIconeLink/TextoIconeLink";
import { cssBg, msg } from "@func/utils";
import imgIconeCamera from '@img/icones/geral/camera.png';
import imgIconeDownload from '@svg/padrao/cloud-arrow-down.svg';

const WebcamVideo = props => {

	const gerarPreviewVideo = useRef();

	const {
		onClickCancelarGravacao,
		onClickConcluirGravacao,
		setStateVideoUrl,
	} = props;

	useEffect(() => {
		setRecordedChunks([]);
	}, []);

	const [contTentativasGerarPreview, setContTentativasGerarPreview] = useState(0);

	const webcamRef = React.useRef(null);
	const mediaRecorderRef = React.useRef(null);
	const [capturing, setCapturing] = React.useState(false);
	const [recordedChunks, setRecordedChunks] = React.useState([]);

	const handleStartCaptureClick = React.useCallback(() => {
		if(webcamRef.current.stream) {

			msg.info('Gravação iniciada, apresente-se =D');

			setCapturing(true);
			mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
				mimeType: "video/webm"
			});

			mediaRecorderRef.current.addEventListener(
				"dataavailable",
				handleDataAvailable
			);
			mediaRecorderRef.current.start();
		} else {
			msg.error('Parece que sua Webcam ainda não carregou ou está bloqueada. Tente gravar novamente ao visualizar a imagem, ou caso tenha bloqueado, faça o desbloqueio da Webcam.', {
				autoClose: 10000
			});
		}
	}, [webcamRef, setCapturing, mediaRecorderRef]);

	const handleDataAvailable = React.useCallback(({ data }) => {
		if (data.size > 0) {
			setRecordedChunks((prev) => prev.concat(data));
		}
	}, [setRecordedChunks]);

	const handleStopCaptureClick = React.useCallback(() => {
		if(mediaRecorderRef.current) {
			mediaRecorderRef.current.stop();
			setCapturing(false);
			gerarPreviewVideo.current.click();
		} else {
			msg.error('Não foi possível parar a gravação. Caso o erro persista, grave um vídeo externamente e envie através do botão de selecionar vídeo.', {
				autoClose: 10000
			});
		}
	}, [mediaRecorderRef, webcamRef, setCapturing]);

	const handleDownload = React.useCallback(() => {
		if(recordedChunks.length) {
			const blob = new Blob(recordedChunks, {
				type: "video/webm"
			});
			const url = URL.createObjectURL(blob);
			const a = document.createElement("a");
			document.body.appendChild(a);
			a.style = "display: none";
			a.href = url;
			a.download = "video-apresentacao-crowd.webm";
			a.click();
			// window.URL.revokeObjectURL(url);
			// setRecordedChunks([]);
		}
	}, [recordedChunks]);

	const erroAoCapturarVideo = (e) => {
		props.setStateVideoUploadFreelancer(false);
		if(e.name) {
			if(e.name === 'NotAllowedError') {
				msg.error('Você precisa permitir o acesso a câmera para poder gravar o vídeo. Caso você tenha bloqueado, desbloqueie e tente gravar novamente.', {
					autoClose: 10000
				});
				return;
			}
		}
		msg.error('Não foi possível identificar sua câmera');
	}

	const gerarLinkVideo = React.useCallback(() => {
		if(recordedChunks.length <= 0) {
			setContTentativasGerarPreview(contTentativasGerarPreview + 1);
			if(contTentativasGerarPreview === 3) {
				msg.info('Gerando prévia do seu vídeo, aguarde...');
			}
			if(contTentativasGerarPreview === 20) {
				msg.info('Gerando prévia do seu vídeo, por favor aguarde mais um pouco...');
			}
			setTimeout(() => {
				if(contTentativasGerarPreview >= 60) {
					msg.error('Não foi possível gerar a prévia do seu vídeo.');
				} else {
					gerarPreviewVideo.current.click();
				}
			}, 1000);
		} else {
			const blob = new Blob(recordedChunks, {
				type: "video/webm"
			});
			const url = URL.createObjectURL(blob);
			setStateVideoUrl(url);
			// setRecordedChunks([]);
		}
	}, [recordedChunks]);

	return (
		<>
			<div className="cpnt-webcam-camera-box" style={cssBg(imgIconeCamera)}>
				<ReactWebcam
					audio={true}
					ref={webcamRef}
					width={500}
					height={375}
					onUserMediaError={erroAoCapturarVideo}
					mirrored={true}
				/>
			</div>
			<div className="form-bottom">
				{recordedChunks.length ? (
					<Button2
						_={{
							value: <TextoIconeLink texto="Download vídeo" icone={imgIconeDownload} />,
							onClick: handleDownload,
						}}
						wrap="margin-r-auto"
					/>
				) : null}

				<Button2
					_={{
						value: 'Fechar',
						className: 'btn btn-1',
						onClick: onClickCancelarGravacao,
					}}
				/>

				{recordedChunks.length ? (
					<Button2
						_={{
							value: 'Concluir gravação',
							onClick: onClickConcluirGravacao,
						}}
						wrap="margin-l-10"
					/>
				) : (
					!capturing ? (
						<Button2
							_={{
								value: 'Iniciar gravação',
								onClick: handleStartCaptureClick,
							}}
							wrap="margin-l-10"
						/>
					) : (
						<Button2
							_={{
								value: 'Finalizar gravação',
								onClick: handleStopCaptureClick,
							}}
							wrap="margin-l-10"
						/>
					)
				)}
			</div>

			<span
				className="none"
				ref={gerarPreviewVideo}
				onClick={gerarLinkVideo}
			/>
		</>
	);
};

export { WebcamVideo };
