import React from 'react';

// CSS
import './OverlayConteudo.css';

// Imagens
import iconeSucesso from '@_img/default/icone-sucesso.png';

const OverlayConteudo = props => {

	const dados = {
		exibir: false,
		texto: '',
		icone: iconeSucesso,
		classTexto: 'align-center',
		exibirFechar: true,
		exibirFecharTexto: 'Fechar',
		exibirFecharClass: '',
		bgAlt: false,
		...props
	};

	return (
		<div className={`overlay-conteudo fill ${dados.bgAlt ? 'overlay-conteudo-bg-alt' : ''} ${dados.exibir ? 'overlay-conteudo-ativo' : ''}`}>
			<div className="overlay-conteudo-wrap">

				<div className="overlay-conteudo-inner">
					{dados.icone ? (
						<div className="overlay-conteudo-icone-wrap">
							<div className="overlay-conteudo-icone">
								<img src={dados.icone} alt="icone" />
							</div>
						</div>
					) : null}

					{dados.texto && dados.texto !== '' ? (
						<div className={`overlay-conteudo-texto ${dados.classTexto}`}>
							{dados.texto}
						</div>
					) : null}

					{dados.exibirFechar ? (
						<div className="overlay-conteudo-fechar-wrap">
							<div className={`overlay-conteudo-fechar ${dados.exibirFecharClass}`} onClick={() => dados.toggle(false)}>
								{dados.exibirFecharTexto}
							</div>
						</div>
					) : null}
				</div>

			</div>
		</div>
	)
}

export default OverlayConteudo;