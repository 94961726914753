import React from 'react';
import Placeholder from '@cpnt/Placeholder/Placeholder';

// CSS
import './PlaceholderProfissionais.css';

const PlaceholderProfissionais = props => {
	const {
		itensQtd,
		classNamePlaceholder = '',
		classNameWrap = 'cpnt-placeholderprof',
	} = props;

	return (
		<div className={`${classNameWrap} ${classNamePlaceholder}`}>
			<div className="cpnt-itemprof cpnt-itemprofplaceholder">
				<div className="cpnt-itemprof-box">
					<div className="cpnt-itemprof-topbar">
						<Placeholder tipo="linha" wrap="w-100" />
						<Placeholder tipo="linha" wrap="w-100" />
					</div>
					<div className="cpnt-itemprof-cols">
						<div className="cpnt-itemprof-col cpnt-itemprof-col-1">
							<div className="cpnt-itemprof-col-wrap cpnt-itemprof-col-wrap-1">
								<Placeholder tipo="circulo" width="94px" height="94px" />
								<div className="w-100p max-w-100 flex flex-center flex-column">
									<Placeholder tipo="linha" wrap="w-100p" />
									<Placeholder tipo="linha" wrap="w-80p" />
									<Placeholder tipo="linha" wrap="w-90p" />
									<Placeholder tipo="linha" wrap="w-70p margin-t-15"/>
									<Placeholder tipo="linha" wrap="w-80p" />
								</div>
							</div>
						</div>
						<div className="cpnt-itemprof-col cpnt-itemprof-col-2">
							<div className="cpnt-itemprof-col-wrap cpnt-itemprof-col-wrap-2">
								<Placeholder tipo="linha" wrap="w-80p" />
								<Placeholder tipo="linha-min" wrap="w-50p" />
								<div className="cpnt-itemprof-icones-box">
									<div className="cpnt-itemprof-icones-box-1">
										<Placeholder tipo="circulo" width="30px" height="30px" />
										<Placeholder tipo="circulo" width="30px" height="30px" />
										<Placeholder tipo="circulo" width="30px" height="30px" />
									</div>
									<div className="cpnt-itemprof-icones-box-2">
										<Placeholder tipo="circulo" width="30px" height="30px" />
										<Placeholder tipo="circulo" width="30px" height="30px" />
										<Placeholder tipo="circulo" width="30px" height="30px" />
									</div>
								</div>
								<div className="cpnt-itemprof-icones-exp">
									<Placeholder tipo="linha" />
									<Placeholder tipo="linha" />
								</div>
								<div className="cpnt-itemprof-desctexto-wrap">
									<Placeholder tipo="linha" wrap="w-90p" />
									<Placeholder tipo="linha-min" wrap="w-80p" />
									<Placeholder tipo="linha-min" wrap="w-70p" />
									<Placeholder tipo="linha-min" wrap="w-50p" />
									<Placeholder tipo="linha-min" wrap="w-60p" />
								</div>
							</div>
						</div>
					</div>
					<div className="cpnt-itemprof-tags-wrapper flex">
						<Placeholder wrap="w-80p" />
						<Placeholder wrap="w-70p" />
						<Placeholder wrap="w-50p" />
						<Placeholder wrap="w-60p" />
					</div>
				</div>
			</div>
		</div>
	)
}

export { PlaceholderProfissionais };
