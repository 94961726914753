import React, { useEffect, useState } from 'react';

// CSS
import './FunilMatch.css';

// Imagens
import imgFunil1 from '@img/funil/1.png';
import imgFunil2 from '@img/funil/2.png';
import imgFunil3 from '@img/funil/3.png';
import imgIconeSetaEsq from '@svg/padrao/arrow-left.svg';
import imgIconeSetaDir from '@svg/padrao/arrow-right.svg';

// Compoentes
import AnimacaoPesquisa from '@cpnt/AnimacaoPesquisa/AnimacaoPesquisa';
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';

// Funções
import { msg } from "@func/utils";

const FunilMatch = props => {
	const {
		metaStatusBusca,
		stateProfissionaisMetadata,
		loading = true,
		after,
		showFooter = true,

		onClickBack,
		onClickNext,
		onClickNextManual,

		textoVoltar = 'Alterar dados do briefing',
		textoProximo = 'Exibir profissionais encontrados',
		textoProximoManual = 'Pesquisar profissionais manualmente',
		exibirBtnManual = true,
	} = props;

	const [animar, setAnimar] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setAnimar(loading);
		}, 500);
	}, [loading]);

	const textoBtnProximo = () => {
		if(loading) {
			return 'Pesquisando, aguarde...';
		}
		if(metaStatusBusca === 200) {
			return textoProximo;
		}
		return textoProximoManual;
	}

	return (
		<>
			{loading ? (
				<AnimacaoPesquisa />
			) : (
				metaStatusBusca === 200 ? (
					<AnimacaoPesquisa
						encontrado={true}
						qtdTotal={stateProfissionaisMetadata?._?.nbHitsTotal}
						qtdEncontrado={stateProfissionaisMetadata?._?.relevance100}
						qtdPorcentagem={90}
					/>
				) : (
					<div>
						<Info
							texto="Nenhum resultado encontrado"
							icone="erro"
						/>
					</div>
				)
			)}

			{showFooter ? (
				<div className="form-bottom flex flex-between flex-column-780-center">
					<Button2
						_={{
							value: <TextoIconeLink texto={textoVoltar} icone={imgIconeSetaEsq} />,
							onClick: () => {
								if(loading) {
									msg.warning('Por favor, aguarde...');
								} else {
									onClickBack();
								}
							}
						}}
						loading={loading}
					/>
					{(exibirBtnManual) || (metaStatusBusca === 200 && !exibirBtnManual) ? (
						<Button2
							_={{
								value: <TextoIconeLink texto={textoBtnProximo()} icone={imgIconeSetaDir} reverso={true} />,
								onClick: () => {
									if(loading) {
										msg.warning('Por favor, aguarde...');
									} else {
										if(metaStatusBusca === 200) {
											onClickNext();
										} else {
											onClickNextManual();
										}
									}
								}
							}}
							loading={loading}
						/>
					) : null}
				</div>
			) : null}

			{after}
		</>
	);
};

export default FunilMatch;