import React, { useState } from 'react';

// css
import c from './PortalAvaliacaoJob.module.scss';

// Componentes
import { Portal } from "@_cpnt/Portal/Portal/Portal";
import { CirculoNumero } from "@_cpnt/CirculoNumero/CirculoNumero";
import { Input } from "@_cpnt/Inputs/Input/Input";
import { Btn } from "@_cpnt/Btn/Btn/Btn";
import { EstrelasAvaliacao } from "@_cpnt/EstrelasAvaliacao/EstrelasAvaliacao";
import { msg } from "@_func/cpnt";
import { camposInvalidos } from "@func/utils";

// Imagens

// Vídeos

const PortalAvaliacaoJob = props => {

	const {
		show,
		onClose,
		onSuccess,
		onError,
		x,
		closeOnEsc = false,
		portalClassName = c['container'],
	} = props;

	const [loading, setLoading] = useState(false);
	const [stateDia, setStateDia] = useState(false);
	const [stateAvaliacao, setStateAvaliacao] = useState(false);
	const [stateDescricao, setStateDescricao] = useState('');

	const numerosAvaliacoes = [{numero: 10}, {numero: 20}, {numero: 30}];

	const enviarAvaliacao = async () => {

		if(loading) {
			msg.warning('Por favor, aguarde...');
			return;
		}

		const dadosEnviar = {
			rating: stateDia,
			text: stateDescricao,
			payday: stateDia,
		};

		if(camposInvalidos(dadosEnviar, {
			payday: 'Qual será o dia do pagamento',
			rating: 'Quantas estrelas',
			text: 'Sua avaliação do job',
		}, [
			'payday',
			'rating',
			'text',
		])) return;

		setLoading(true);

		setTimeout(() => {
			setLoading(false);
			msg.success(<>Obrigado por sua avaliação!<br /><br />Seu job foi aprovado com sucesso.</>);
			closePortal();
		}, 2000);

	}

	const closePortal = () => {
		setStateDia(false);
		setStateAvaliacao(false);
		setStateDescricao('');
		onClose();
	}

	return (
		<Portal
			// titulo="Avalie o serviço realizado para poder aprovar"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={closePortal}
			classNameWrap={portalClassName}
			classNameX={c['x']}
			x={x}
		>
			<div className={`${c['conteudo']}`}>
				<h2 className={`margin-t-0 ${c['texto-dia']}`}>Escolha o dia em que o profissional será pago no próximo mês</h2>
				<div className={`${c['circulos']}`}>
					{numerosAvaliacoes.map((val, key) => {
						return (
							<CirculoNumero
								key={key}
								numero={val.numero}
								className={`${c['circulo']} ${stateDia === val.numero ? c['active'] : ''}`}
								onClick={() => setStateDia(val.numero)}
								{...(stateDia === val.numero ? {active: true} : {})}
							/>
						)
					})}
				</div>
				<p className={`${c['texto-alerta']}`}>Lembre-se de que o pagamento deve ser realizado<br /><strong>5 dias antes para garantir a liberação na data escolhida.</strong></p>

				<h2 className={`${c['texto-avalie']}`}>Avalie a qualidade do profissional</h2>
				<div>
					<EstrelasAvaliacao
						onClick={e => setStateAvaliacao(e)}
						value={stateAvaliacao}
					/>
				</div>

				<p className={`${c['texto-compartilhe']}`}>Compartilhe sua experiência para garantir que continuemos a conectar você aos melhores talentos.</p>
				<Input
					_={{
						type: 'textarea',
						placeholder: 'Escreva aqui seu comentário',
						value: stateDescricao,
						onChange: e => setStateDescricao(e.target.value),
					}}
				/>
				<div className={`${c['btn']}`}>
					<Btn
						_={{
							value: 'Avaliar',
							onClick: enviarAvaliacao,
						}}
						loading={loading}
					/>
				</div>
			</div>
		</Portal>
	);
};

export { PortalAvaliacaoJob };
