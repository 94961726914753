import Placeholder from '@cpnt/Placeholder/Placeholder';
import React from 'react';

const PlaceholderClientes = props => {
	const {
		itensQtd,
	} = props;

	return (
		<tr>
			<td className="col-id">
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td className="">
				<div className="td-wrap">
					<div className="flex flex-center-v flex-1">
						<Placeholder tipo="circulo" />
						<div className="w-100p">
							<Placeholder tipo="linha" wrap="w-100p" />
							<Placeholder tipo="linha-min" wrap="w-80p" />
						</div>
					</div>
				</div>
			</td>
			<td className="">
				<div className="td-wrap">
					<div className="flex flex-center-v flex-1">
						<Placeholder tipo="circulo" />
						<div className="w-100p">
							<Placeholder tipo="linha" wrap="w-100p" />
							<Placeholder tipo="linha-min" wrap="w-80p" />
						</div>
					</div>
				</div>
			</td>
			<td className="col-min-3">
				<div className="td-wrap">
					<Placeholder tipo="circulo" />
				</div>
			</td>
			<td className="col-min-3">
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td className="col-min-3">
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td className="col-min-3">
				<div className="td-wrap">
					<Placeholder tipo="circulo" />
				</div>
			</td>
			<td className="col-acao">
				<div className="td-wrap">
					<Placeholder tipo="retangulo-alt" />
				</div>
			</td>
		</tr>
	)
}

export { PlaceholderClientes };
