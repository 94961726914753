import React from 'react';

// Componentes
import { Select2 } from '@cpnt/Select2/Select2';
import Input2 from '@cpnt/Input2/Input2';
import InputCpfCnpj from '@cpnt/InputCpfCnpj/InputCpfCnpj';
import InputTelefone from '@cpnt/InputTelefone/InputTelefone';

// Funções
import { apenasNumerosCpfCnpj } from '@func/utils';
import { LISTA_PIX } from "@config/pix";
import InputTelefone2 from "@cpnt/InputTelefone2/InputTelefone2";

const PagamentoPix = props => {
	const {
		classNameWrap = '',
		stateDadosBancarios,
		setStateDadosBancarios,
	} = props;

	const renderCampo = () => {

		if(stateDadosBancarios.pix_type === 'cpf') {
			return (
				<Input2
					label="CPF"
					req
					_={{
						value: stateDadosBancarios.pix_value,
						onChange: e => {
							setStateDadosBancarios({
								...stateDadosBancarios,
								pix_value: e.target.value,
							});
						},
						mask: '999.999.999-99',
					}}
				/>
			)
		}

		if(stateDadosBancarios.pix_type === 'cnpj') {
			return (
				<Input2
					label="CNPJ"
					req
					_={{
						value: stateDadosBancarios.pix_value,
						onChange: e => {
							setStateDadosBancarios({
								...stateDadosBancarios,
								pix_value: e.target.value,
							});
						},
						mask: '99.999.999/9999-99',
					}}
				/>
			)
		}

		if(stateDadosBancarios.pix_type === 'email') {
			return (
				<Input2
					label="E-mail"
					req
					_={{
						type: 'email',
						value: stateDadosBancarios.pix_value,
						onChange: e => {
							setStateDadosBancarios({
								...stateDadosBancarios,
								pix_value: e.target.value,
							});
						},
					}}
				/>
			)
		}

		if(stateDadosBancarios.pix_type === 'celular') {
			return (
				<InputTelefone2
					label="Celular"
					req
					_={{
						value: stateDadosBancarios.pix_value,
						onChange: e => {
							setStateDadosBancarios({
								...stateDadosBancarios,
								pix_value: e
							});
						},
					}}
				/>
			)
		}

	}

	return (
		<div className={`${classNameWrap}`}>
			<div>Receber pagamentos via PIX</div>
			<div className="wrap-5 margin-t-5">
				<div className="flex flex-wrap">
					<div className="input-box w-300 box-1-680">
						<Select2
							label="Tipo de chave"
							req
							_={{
								options: LISTA_PIX,
								value: stateDadosBancarios.pix_type,
								isClearable: false,
								onChange: e => {
									setStateDadosBancarios({
										...stateDadosBancarios,
										pix_type: e.value,
									});
								},
							}}
						/>
					</div>
					<div className="input-box w-300 box-1-680">
						{renderCampo()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PagamentoPix;