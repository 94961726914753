import { validarRetorno, config } from '../axios';
import api from 'axios';
import qs from 'qs';

const apiFreelancerExperience = {
	all: {
		get: async (idFreelancer, filtrosObj = {}) => {
			var filtros = qs.stringify(filtrosObj, { encode: false });
			return await api.get(`/freelancerExperience/all/${idFreelancer}?${filtros}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	insertMany: {
		post: async dados => {
			return await api.post(`/freelancerExperience/insertMany`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiFreelancerExperience;
