import React, { useContext, useState } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// Componentes
import Button2 from "@cpnt/Button2/Button2";
import LinhaItem from "@cpnt/LinhaItem/LinhaItem";
import Portal from "@cpnt/Portal/Portal";

// Funções
import { dataApiParaBr, tipoTemplate, htmlToJsx } from "@func/utils";

const DetalhesTemplate = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		wrap = '',
		className = '',
		stateDados,
		after,
	} = props;

	const [showTemplate, setShowTemplate] = useState(false);

	return (
		<>
			<div className={`cpnt-detalhesequipe ${wrap}`}>
				<div className={`cpnt-detalhesequipe-box ${className}`}>

					<div>
						<h3 className="titulo margin-t-0">{stateDados.title}</h3>
						<div className="td-desc">ID: {stateDados.id}</div>
					</div>

					{store.usuario.role === 1 ? (
						<div>
							<h3 className="margin-b-0">{stateDados.company_name ? 'Template da empresa' : 'Template público'}</h3>
							<div>
								{stateDados.company_name ? stateDados.company_name : 'Visível para todas as empresas'}
							</div>
						</div>
					) : null}

					<div>
						<h3 className="margin-b-0">Tipo de template</h3>
						<div>
							{stateDados.type_name}
						</div>
					</div>

					<div>
						<div className="flex flex-center-v">
							<h3 className="">Template</h3>
							<div className="a margin-l-15" onClick={e => setShowTemplate(true)}>(visualizar)</div>
						</div>
						<div className="texto margin-t--10">
							{stateDados.excerpt}
						</div>
					</div>

					<div>
						<h3 className="margin-b-0">Status</h3>
						<div>
							{stateDados.active ? 'Ativo' : 'Inativo'}
						</div>
					</div>

					<div>
						<h3 className="margin-b-0">Datas</h3>
						<div>
							<LinhaItem
								titulo="Criado em"
								desc={dataApiParaBr(stateDados.created_at)}
								alt
							/>
							<LinhaItem
								titulo="Atualizado em"
								desc={stateDados.updated_at ? dataApiParaBr(stateDados.updated_at) : '(nunca foi atualizado)'}
								alt
							/>
						</div>
					</div>

				</div>
			</div>

			{after}

			<Portal
				titulo="Pré-visualização do template"
				show={showTemplate}
				onClose={() => {
					setShowTemplate(false);
				}}
				className="preview-template"
			>
				<div className={`box-txt ${tipoTemplate(stateDados.id_type) === 'texto' ? 'texto-simples' : ''}`}>{htmlToJsx(stateDados.text)}</div>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Fechar',
							onClick: e => setShowTemplate(false)
						}}
					/>
				</div>
			</Portal>

		</>

	);
};

export { DetalhesTemplate };
