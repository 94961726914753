import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalDisponibilidadeRemuneracaoFormEdicao.css';

// API
import apiFreelancer from "@api/freelancer/freelancer";

// Partes

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import Input2 from "@cpnt/Input2/Input2";
import { PtDisponibilidade } from "@cpnt/Partes/PtDisponibilidade/PtDisponibilidade";
import { PtRemuneracao } from "@cpnt/Partes/PtRemuneracao/PtRemuneracao";
import { camposInvalidos, conveterRealParaNumero} from '@func/utils';
import { msgs, msgStatus } from "@_func/request";

const PortalDisponibilidadeRemuneracaoFormEdicao = props => {

	const {
		idFreelancer,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-dispremun-form-edicao',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({
		description: '',
		title: '',
	});
	const [_stateDados, _setStateDados] = useState({});
	const [statePaises, setStatePaises] = useState([]);

	// Edição
	const [loadingEditar, setLoadingEditar] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		setLoading(true);

		const retGetDados = await apiFreelancer.getOne.get(idFreelancer);
		_setStateDados(msgStatus(retGetDados));

		if(retGetDados.status === 200) {
			setStateDados(retGetDados.data);
		} else {
			msgs([retGetDados]);
		}

		setLoading(false);
	}

	/*
	** Editar dados da empresa
	** ================================================== */
	const editar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		// Dados do freelancer
		const dadosEnviar = {
			availability: stateDados.availability,
			// hours_per_week: stateDados.hours_per_week,
			price: conveterRealParaNumero(stateDados.price),
			title: stateDados.title,
		};

		if(camposInvalidos(dadosEnviar, {
			availability: 'Disponibilidade',
			hours_per_week: 'Disponibilidade',
			title: 'Título profissional',
		}, [
			'availability',
			// 'hours_per_week',
			'price',
			'title',
		])) return;

		// Valor hora
		if(dadosEnviar.price < 1) {
			msg.error('Você inseriu uma remuneração muito baixa, verifique as informações e tente novamente.');
			return;
		}

		setLoadingEditar(true);

		const retUpdateFreelancer = await apiFreelancer.update.put({
			id: idFreelancer,
			...dadosEnviar,
		});

		if(retUpdateFreelancer.status === 200) {
			msg.success('Dados atualizados com sucesso.');
			onSuccess({
				ret: retUpdateFreelancer,
				dados: stateDados,
			});
		} else {
			msgs([retUpdateFreelancer]);
			onError({
				ret: retUpdateFreelancer,
			});
		}

		setLoadingEditar(false);
	}

	return (
		<Portal
			titulo="Dados para os jobs"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<>
								<h3 className="titulo">Título profissional</h3>
								<div className="margin-t-10">
									<div className="wrap-5">
										<div className="input-box">
											<Input2
												label="Título profissional"
												req
												tip="Informe um título para o seu perfil (ex: programador full-stack, redator publicitário, designer UX, gerente de projetos, etc.)"
												_={{
													value: stateDados.title,
													onChange: e => {
														setStateDados({
															...stateDados,
															title: e.target.value,
														});
													}
												}}
											/>
										</div>
									</div>
								</div>
								<PtRemuneracao
									stateDados={stateDados}
									setStateDados={setStateDados}
								/>
								<div className="form-bottom">
									<Button2
										_={{
											value: 'Cancelar',
											className: 'btn btn-1',
											onClick: onClose,
										}}
									/>
									<Button2
										_={{
											value: 'Salvar dados',
											onClick: editar,
										}}
										wrap="margin-l-10"
										loading={loadingEditar}
									/>
								</div>
							</>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export { PortalDisponibilidadeRemuneracaoFormEdicao };
