import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

import ItemFotoDesc from '@cpnt/ItemFotoDesc/ItemFotoDesc';
import LinhaItem from '@cpnt/LinhaItem/LinhaItem';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';
import { caminhoFoto, dataApiParaBr, dataPorExtenso, formatarDinheiro, getSenioridadeBySlug, htmlToJsx, joinArray, validarDataAPI } from '@func/utils';
import moment from 'moment/min/moment-with-locales';

// Imagens
import BtnLabel from '@cpnt/BtnLabel/BtnLabel';
import Button2 from '@cpnt/Button2/Button2';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';
import PortalProcessoSeletivoInfoHistorico from '@portal/PortaisProcessoSeletivo/PortalProcessoSeletivoInfoHistorico/PortalProcessoSeletivoInfoHistorico';
import PortalPerfil from '@portalGeral/PortalPerfil/PortalPerfil';
import { gets, showPortal } from "@route/routeUtils";
import imgIconeHistorico from '@svg/regular/clock-rotate-left.svg';
import imgIconeBriefing from '@svg/regular/file-lines.svg';
import imgIconeChat from '@svg/regular/comment-lines.svg';
import { PortalPerfilUsuario } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilUsuario";

// css
// import './DetalhesInfoJob.css';

const DetalhesInfoJob = props => {

	const navigate = useNavigate();
	const location = useLocation();
	const [store, setStore] = useContext(StoreContext);

	const {
		id,
		className = '',
		wrap = '',
		after,

		stateDados = {},
		showBtnNegociar = true,

		stateBriefingProfissionais = [],
		stateBriefingHabilidades = [],
		stateBriefingObjetivos = [],
		stateBriefingSegmentos = [],
		stateBriefingIdiomas = [],
		stateHistoricoId,
		stateHistoricoDados = [],

		loadingGetProfissionais,
		loadingGetHabilidades,
		loadingGetObjetivos,
		loadingGetSegments,
		loadingGetIdiomas,
		loadingGetCompetencias,
		loadingGetHistorico,
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	/*
	** Overlay Perfil
	** ================================================== */
	const [showPortalPerfilCompleto, setShowPortalPerfilCompleto] = useState(false);
	const [statePerfilCompletoId, setStatePerfilCompletoId] = useState(false);
	const [statePerfilCompletoTipo, setStatePerfilCompletoTipo] = useState('freelancer');

	/*
	** Portais
	** ================================================== */
	const [showPortalInformacoesTextoBriefing, setShowPortalInformacoesTextoBriefing] = useState(false);
	const [showPortalHistoricoBriefing, setShowPortalHistoricoBriefing] = useState(false);
	const [showPortalInfoHistorico, setShowPortalInfoHistorico] = useState(false);


	return (
		<>
			<div className={`cpnt-detprocsel ${wrap}`}>
				<div className={`cpnt-detprocsel-box ${className}`}>
					<div className="cpnt-process-info-dados">
						<div className="cpnt-process-info-dados-geral">
							<div>
								<h3 className="margin-t-0">Dados gerais</h3>
								<div>
									<LinhaItem
										titulo="Título"
										desc={stateDados.title}
										alt
									/>
									<LinhaItem
										titulo="ID"
										desc={stateDados.id}
										alt
									/>
									{store.usuario.id === stateDados.contracted_freela_user_id ? (
										<LinhaItem
											titulo="Projeto"
											desc={stateDados.project_name}
											alt
										/>
									) : null}
									<LinhaItem
										titulo="Criado em"
										desc={dataPorExtenso(stateDados.created_at, true, 'há ', '', '')}
										alt
									/>
								</div>
							</div>
							<div>
								<h3>Dados dos perfis dos profissionais</h3>
								<div>
									<LinhaItem
										titulo="Categoria"
										desc={stateDados.area_name ? stateDados.area_name : 'Não informado'}
										alt
									/>
									<LinhaItem
										titulo="Senioridade"
										desc={getSenioridadeBySlug(stateDados.seniority, 'Não informado')}
										alt
									/>
								</div>
							</div>
							{/* Habilidades */}
							<div>
								<h3 className="margin-b-5">Interesses do profissional</h3>
								{loadingGetHabilidades ? (
									<Loading alt />
								) : (
									<div>
										{stateBriefingObjetivos.length ? joinArray([...stateBriefingObjetivos.map(item => item.name)]) : 'Nenhum interesse informado'}
									</div>
								)}
							</div>
						</div>
						<div className="cpnt-process-info-dados-acoes">
							<div>
								<h3 className="margin-t-0 margin-b-10">Recrutador responsável</h3>
								<div>
									<ItemFotoDesc
										titulo={stateDados.user_name}
										subtitulo={stateDados.user_role_company}
										foto={caminhoFoto(stateDados.user_photo).url}
										onClick={() => {
											setStatePerfilCompletoId(stateDados.id_user);
											setStatePerfilCompletoTipo('usuario');
											setShowPortalPerfilCompleto(true);
										}}
										className="cursor-pointer"
									/>
								</div>
								<div>
									<h3 className="margin-b-5">Descrição</h3>
									<div>
										<TextoIconeLink
											texto="Visualizar descrição da proposta"
											icone={imgIconeBriefing}
											iconeCor="#000"
											className="a"
											onClick={() => {
												setShowPortalInformacoesTextoBriefing(true);
											}}
										/>
									</div>
								</div>
								{showBtnNegociar ? (
									<div>
										<h3 className="margin-b-5">Chat</h3>
										<div>
											<TextoIconeLink
												texto="Negociar proposta"
												icone={imgIconeChat}
												iconeCor="#000"
												className="a"
												navigate={`/jobs/detalhes?id_briefing=${stateDados.id}`}
											/>
										</div>
									</div>
								) : null}
							</div>
						</div>
					</div>

					{/* Habilidades */}
					<div>
						<h3 className="margin-b-5">Habilidades</h3>
						{loadingGetHabilidades ? (
							<Loading alt />
						) : (
							<div className="wrap-5">
								<div className="flex flex-wrap">
									{!stateBriefingHabilidades.length ? (
										<div className="padding-h-5">Nenhuma habilidade selecionada.</div>
									) : (
										stateBriefingHabilidades.map((val, key) => {
											return (
												<BtnLabel key={key} texto={val.name} x={false} />
											)
										})
									)}
								</div>
							</div>
						)}
					</div>

					{/* Idiomas */}
					<div>
						<h3 className="margin-b-5">Idiomas</h3>
						{loadingGetIdiomas ? (
							<Loading alt />
						) : (
							<div className="wrap-5">
								<div className="flex flex-wrap">
									{!stateBriefingIdiomas.length ? (
										<div className="padding-h-5">Nenhum idioma selecionado.</div>
									) : (
										stateBriefingIdiomas.map((val, key) => {
											return (
												<BtnLabel key={key} texto={val.name} x={false} />
											)
										})
									)}
								</div>
							</div>
						)}
					</div>

					{/* Competencias */}
					<div>
						<h3 className="margin-b-5">Competências</h3>
						{loadingGetCompetencias ? (
							<Loading alt />
						) : (
							<div className="wrap-5">
								<div className="flex flex-wrap">
									{!stateDados.length ? (
										<div className="padding-h-5">Nenhuma competência selecionada.</div>
									) : (
										stateDados.map((val, key) => {
											return (
												<BtnLabel key={key} texto={val.name} x={false} />
											)
										})
									)}
								</div>
							</div>
						)}
					</div>

					{/* Segmentos */}
					<div>
						<h3 className="margin-b-5">Segmentos de interesse</h3>
						{loadingGetSegments ? (
							<Loading alt />
						) : (
							<div className="wrap-5">
								<div className="flex flex-wrap">
									{!stateBriefingSegmentos.length ? (
										<div className="padding-h-5">Nenhum segmento selecionado.</div>
									) : (
										stateBriefingSegmentos.map((val, key) => {
											return (
												<BtnLabel key={key} texto={val.name} x={false} />
											)
										})
									)}
								</div>
							</div>
						)}
					</div>
				</div>
				{after}
			</div>

			{/* Portal Texto do briefing */}
			<Portal
				titulo="Descrição da proposta de job"
				show={showPortalInformacoesTextoBriefing}
				closeOnEsc={false}
				onClose={() => {
					setShowPortalInformacoesTextoBriefing(false)
				}}
				className="w-800"
			>
				<div className="box-txt">{htmlToJsx(typeof(stateDados.text) === 'string' ? stateDados.text : '<div>Nenhuma descrição adicionada a essa proposta de job.</div>')}</div>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Fechar',
							onClick: e => {
								setShowPortalInformacoesTextoBriefing(false);
							}
						}}
					/>
				</div>
			</Portal>

			<PortalPerfilUsuario
				id={statePerfilCompletoId}
				show={showPortalPerfilCompleto}
				onClose={() => setShowPortalPerfilCompleto(false)}
			/>

		</>
	);
};

export default DetalhesInfoJob;