import React from 'react';

// Componentes
import Input2 from '@cpnt/Input2/Input2';

const ListaLocalForm = props => {
	const {
		onSubmit,
		stateNomeLista,
		setStateNomeLista,
		after,
	} = props;

	return (
		<>
			<form className="box-txt" onSubmit={onSubmit}>
				<Input2
					label="Nome da lista"
					req
					_={{
						value: stateNomeLista,
						onChange: e => {
							setStateNomeLista(e.target.value);
						}
					}}
				/>
			</form>
			{after}
		</>
	);
};

export { ListaLocalForm };