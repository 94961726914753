import React from 'react';

import { ARQUIVO_TERMOS_DE_USO } from "@_config/config";

const textoAvisoDatasPagamento = () => {

	const pdfTermosDeUso = require(`@pdf/${ARQUIVO_TERMOS_DE_USO}`);

	return (
<div className="box-txt">
<p>Acreditamos que você já tenha conferido essas informações quando aceitou os nossos <a href={pdfTermosDeUso} target="_blank" rel="noreferrer">termos de uso</a>, mas vale reforçar essa orientação para que não tenhamos contratempos quando você tiver que receber por algum trabalho realizado para a Crowd.</p>
<ul>
	<li>Para os jobs aprovados entre os dias 1-31 do mês vigente, o pagamento será realizado no dia 30 ou 31 do mês seguinte;</li>
	<li>Isso quer dizer que, caso um card seja aprovado entre 01 a 31/07, por exemplo, o pagamento será efetuado em 31/08;</li>
	<li>Caso estas datas caiam no final de semana, o pagamento será realizado um dia útil antes;</li>
	<li>Não há exceções para essa regra. </li>
</ul>
<p>Após a aprovação do card na plataforma, você deverá nos enviar a sua Nota Fiscal. A emissão de uma nota fiscal é obrigação do prestador de serviços e comerciante em todo o território nacional de acordo com a legislação vigente no país, sob pena de responder por crime de sonegação de impostos, com reclusão de 02 (anos) a 05 (anos) além de ser responsabilizado pela multas , conforme é indicado na <a href="http://www.planalto.gov.br/ccivil_03/leis/l8137.htm" target="_blank" rel="noreferrer">Lei n. 8137</a> publicada em 27 de dezembro de 1990.</p>
</div>
	)
}

export default textoAvisoDatasPagamento;
