import api from 'axios';
import qs from 'qs';

// Funções
import { validarRetorno } from '@api/axios';

// Config
import { API_TIMEOUT, URL_API_BLOG_WORDPRESS } from '@_config/config';

const configAxiosWP = {
	timeout: API_TIMEOUT,
	baseURL: URL_API_BLOG_WORDPRESS,
}

const apiWordpress = {
	posts: {
		get: async dadosObj => {
			var dadosEnviar = '';
			if(dadosObj) {
				dadosEnviar = `?${qs.stringify(dadosObj, { encode: false })}`;
			}
			return await api.get(`/posts${dadosEnviar}`, configAxiosWP)
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	img: {
		get: async mediaUrl => {
			return await api.get(mediaUrl, configAxiosWP)
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	}
};

export default apiWordpress;
