import React, { useContext } from 'react';

// Libs
import { Link } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import c from './HomeScreen.module.scss';

// Func
import { primeiroNome } from "@_func/regex";

// Cpnt
import { URL_SUPORTE_ZENDESK } from "@_config/config";
import { BoxInfo } from "@_cpnt/Box/BoxInfo/BoxInfo";
import { BoxInfoRoot } from "@_cpnt/Box/BoxInfo/BoxInfoRoot/BoxInfoRoot";
import { Topbar } from "@_cpnt/Topo/Topbar/Topbar";
import { TopbarRoot } from "@_cpnt/Topo/Topbar/TopbarRoot/TopbarRoot";

export const HomeScreen = props => {
	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
		boxes,
	} = props;

	return (
		<div className={`${className} ${c['container']}`}>
			<TopbarRoot>
				<Topbar.Titulo>Página inicial</Topbar.Titulo>
			</TopbarRoot>

			<div className="base">
				<div className="base-content">
					<div className={`${c['conteudo']}`}>
						<h3 className={`align-center ${c['texto-info']}`}>Olá, {primeiroNome(store.usuario.name)}.<span className={`${c['texto-mobile']}`}> </span>O que você gostaria de fazer hoje?</h3>
						<div className={`${c['boxes']}`}>
							{boxes.map((val, key) => {
								return (
									<BoxInfoRoot key={key} className={`hover-box`} {...(val.onClick ? {onClick: val.onClick} : {link: val.link})}>
										<BoxInfo.Icone img={val.icone} />
										<BoxInfo.Titulo>{val.titulo}</BoxInfo.Titulo>
										<BoxInfo.Desc className={`${c['boxes-desc']}`}>{val.desc}</BoxInfo.Desc>
									</BoxInfoRoot>
								)
							})}
						</div>
						<div className="align-center">
							<p>Precisa de suporte?</p>
							<p>Acesse nossa <Link to="/perfil?secao=ajuda">Central de Ajuda</Link></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
