import React from 'react';
import { Link } from 'react-router-dom';

// Componentes
import Tip from '@cpnt/Tip/Tip';
import IconeChat from '@cpnt/IconeChat/IconeChat';

// Funções
import { linkVoltar, singularPlural } from '@func/utils';
import { clearGet } from "@route/routeUtils";

const IconeChatWrap = props => {
	const {
		link,
		qtdMensagens,
		textoNovaMensagem = 'Nova mensagem',
		textoNovasMensagens = 'Novas mensagens',
		textoSemMensagem = 'Nenhuma mensagem nova',
		_tipDefaults = {},
		linkState,
	} = props;

	return (
		<Tip
			tip={
				<Link
					className="inline-flex"
					to={link}
					{...(linkState ? {state: linkVoltar(linkState)} : {})}
				>
					<IconeChat
						qtdMensagens={qtdMensagens}
					/>
				</Link>
			}
			_={{
				delayLeave: 0
			}}
			_defaults={_tipDefaults}
			closeOnLeave
		>
			{singularPlural(qtdMensagens, textoNovaMensagem, textoNovasMensagens, textoSemMensagem)}
		</Tip>
	);
};

export { IconeChatWrap };