import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalDisponibilidadeJobs.css';

// API
import apiFreelancer from "@api/freelancer/freelancer";

// Partes

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Checkbox2 from '@cpnt/Checkbox2/Checkbox2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções

import { msgs, msgStatus } from "@_func/request";

const PortalDisponibilidadeJobs = props => {

	const {
		idFreelancer,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-dispjobs-form-edicao',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	/*
	** States
	** ================================================== */
	const [_stateDados, _setStateDados] = useState({});
	const [stateChecked, setStateChecked] = useState(false);

	// Edição
	const [loadingEditar, setLoadingEditar] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateChecked(false);
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		setLoading(true);

		const retGetDados = await apiFreelancer.getOne.get(idFreelancer);
		_setStateDados(msgStatus(retGetDados));

		if(retGetDados.status === 200) {
			setStateChecked(retGetDados.data.active ? false : true);
		} else {
			msgs([retGetDados]);
		}

		setLoading(false);
	}

	/*
	** Editar dados da empresa
	** ================================================== */
	const editar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		setLoadingEditar(true);

		const retUpdateFreelancer = await apiFreelancer.update.put({
			id: idFreelancer,
			active: stateChecked ? 0 : 1,
		});

		if(retUpdateFreelancer.status === 200) {
			msg.success('Dados atualizados com sucesso.');
			onSuccess({
				ret: retUpdateFreelancer,
				dados: stateChecked ? 0 : 1,
			});
		} else {
			msgs([retUpdateFreelancer]);
			onError({
				ret: retUpdateFreelancer,
			});
		}

		setLoadingEditar(false);
	}

	return (
		<Portal
			titulo="Disponibilidade para jobs"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<>
								<div><strong>Importante:</strong> <span>caso você deixar esta opção marcada, seu perfil não será exibido nas buscas feitas pelas empresas.</span></div>
								<div className="margin-t-15">Recomendamos utilizar esta opção caso você esteja muito ocupado com outros jobs, ou caso precise parar por um tempo indeterminado e não queira receber novas propostas de jobs no momento.</div>
								<div className="margin-t-20">
									<Checkbox2
										label={<><span className={`${stateChecked ? 'color-red' : ''}`}>Não estou disponível para novos jobs</span><br /><span className="fs-13 color-1">Ao marcar esse campo, nenhuma empresa poderá contratá-lo para novos jobs.</span></>}
										_={{
											checked: stateChecked,
											onChange: e => {
												setStateChecked(e.target.checked);
											}
										}}
									/>
								</div>
								<div className="form-bottom">
									<Button2
										_={{
											value: 'Cancelar',
											className: 'btn btn-1',
											onClick: onClose,
										}}
									/>
									<Button2
										_={{
											value: 'Salvar dados',
											onClick: editar,
										}}
										wrap="margin-l-10"
										loading={loadingEditar}
									/>
								</div>
							</>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export { PortalDisponibilidadeJobs };
