import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiFreelancerPayment = {
	all: {
		get: async idFreelancer => {
			return await api.get(`/freelancerPayment/all/${idFreelancer}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	insert: {
		post: async (dados) => {
			return await api.post(`/freelancerPayment/insert`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	insertMany: {
		post: async (dados) => {
			return await api.post(`/freelancerPayment/insertMany`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getOne: {
		get: async (idFreelancer) => {
			return await api.get(`/freelancerPayment/getOne/${idFreelancer}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiFreelancerPayment;
