import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalFormacaoFormCadastro.css';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { camposInvalidos, dataApiCrop } from '@func/utils';
import FormFormacoes from "@cpnt/Forms/FormFormacao/FormFormacao";
import FormFormacao from "@cpnt/Forms/FormFormacao/FormFormacao";

const PortalFormacaoFormCadastro = props => {

	const {
		show,
		onClose = () => null,
		closeOnEsc = false,
		portalClassName = 'portal-formacao-form-cadastro',
		onSuccess = () => null,
		dados = {},
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		company: '',
		description: '',
		start_date: '',
		end_date: '',
		active: 1,
	};

	const [stateDados, setStateDados] = useState(camposDefault);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados(stateDados);
		}
	}

	/*
	** Editar empresa
	** ================================================== */
	const cadastrar = async e => {
		e.preventDefault();

		const dadosEnviar = {
			...camposDefault,
			company: stateDados.company,
			description: stateDados.description,
			start_date: dataApiCrop(stateDados.start_date),
			end_date: dataApiCrop(stateDados.end_date),
			active: stateDados.active,
		};

		if(camposInvalidos(dadosEnviar, {}, [
			'company',
			'description',
			'start_date',
		])) return;

		onSuccess(stateDados);
		setTimeout(() => {
			setStateDados(camposDefault);
		}, 400);
	}

	return (
		<Portal
			titulo="Adicionar formação"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<FormFormacao
				state={stateDados}
				setState={setStateDados}
			/>
			<div className="form-bottom">
				<Button2
					_={{
						value: 'Cancelar',
						className: 'btn btn-1',
						onClick: onClose,
					}}
				/>
				<Button2
					_={{
						value: 'Adicionar formação',
						onClick: cadastrar,
					}}
					wrap="margin-l-10"
				/>
			</div>
		</Portal>
	);
};

export { PortalFormacaoFormCadastro };
