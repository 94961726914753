export const _tempChatTemplatesUsuario = [
	// {
	// 	id: 1,
	// 	titulo: 'Proposta de Job',
	// 	mensagem: 'Podemos fechar por esse valor e esse prazo?',
	// 	metadata: {
	// 		titulo: 'Proposta de job',
	// 		valor: 3000,
	// 		descricao: '30 dias',
	// 		prazo: 30,
	// 		botoes: [
	// 			{
	// 				tipo: 'vermelho',
	// 				texto: 'Recusar',
	// 			},
	// 			{
	// 				tipo: 'verde',
	// 				texto: 'Aceitar',
	// 			},
	// 		],
	// 	},
	// 	mensagemLida: true,
	// 	type: 'proposta',
	// },
	// {
	// 	id: 2,
	// 	titulo: 'Pergunta Sim / Não',
	// 	mensagem: 'Você tem disponibilidade imediata para iniciar o job?',
	// 	metadata: {
	// 		titulo: 'Pergunta',
	// 		botoes: [
	// 			{
	// 				tipo: 'vermelho',
	// 				texto: 'Não',
	// 			},
	// 			{
	// 				tipo: 'verde',
	// 				texto: 'Sim',
	// 			},
	// 		],
	// 		parcelas: [],
	// 	},
	// 	mensagemLida: true,
	// 	type: 'pergunta',
	// },
	// {
	// 	id: 3,
	// 	titulo: 'Pergunta personalizada',
	// 	mensagem: 'Você acha que consegue finalizar esse job ainda hoje?',
	// 	metadata: {
	// 		titulo: 'Estimativa de prazo',
	// 		botoes: [
	// 			{
	// 				tipo: 'vermelho',
	// 				texto: 'Não',
	// 			},
	// 			{
	// 				tipo: 'preto',
	// 				texto: 'Não tenho certeza',
	// 			},
	// 			{
	// 				tipo: 'verde',
	// 				texto: 'Sim',
	// 			},
	// 		],
	// 		parcelas: [],
	// 	},
	// 	mensagemLida: true,
	// 	type: 'pergunta',
	// },
	{
		id: 4,
		titulo: 'Mensagem verde',
		mensagem: 'Foi um prazer trabalhar com você nesse projeto, infelizmente ele está chegando ao fim, mas com certeza futuramente terão outros projetos que poderemos trabalhar juntos novamente. Muito obrigado pelo seu esforço e dedicação, até a próxima.',
		metadata: {
			titulo: 'Muito obrigado por tudo!',
		},
		mensagemLida: true,
		type: 'verde',
	},
	{
		id: 5,
		titulo: 'Mensagem vermelha',
		mensagem: "Desculpe, mas você não foi selecionado para esse job, agradeço seu tempo e atenção, espero poder negociar com você em futuros jobs.\n\nNosso contato se encerra aqui, até a próxima.",
		metadata: {
			titulo: 'Processo seletivo finalizado',
		},
		mensagemLida: true,
		type: 'vermelho',
	},
	{
		id: 6,
		titulo: 'Mensagem amarela',
		mensagem: 'Os e-mails solicitando as notas fiscais para os jobs já foram enviadas, caso não tenha recebido, verifique a sua caixa de entrada.',
		metadata: {
			titulo: 'Importante',
		},
		mensagemLida: true,
		type: 'amarelo',
	},
	{
		id: 7,
		titulo: 'Mensagem azul',
		mensagem: 'Os cards de pagamento para os jobs deste mês já foram criados, recomendados que caso você tenha jobs a receber, insira os valores nos cards para não ter problemas com os seus pagamentos.',
		metadata: {
			titulo: 'Pagamentos do mês',
		},
		mensagemLida: true,
		type: 'azul',
	},
	// {
	// 	id: 8,
	// 	titulo: 'Bloquear Chat',
	// 	mensagem: 'O chat foi desativado. Não é mais possível enviar novas mensagens.',
	// 	metadata: {
	// 		titulo: 'Chat desativado',
	// 	},
	// 	mensagemLida: true,
	// 	type: 'vermelho',
	// },
	// {
	// 	id: 9,
	// 	titulo: 'Desbloquear Chat',
	// 	mensagem: 'O chat foi reativado. Agora é possível enviar mensagens novamente.',
	// 	metadata: {
	// 		titulo: 'Chat reativado',
	// 	},
	// 	mensagemLida: true,
	// 	type: 'verde',
	// },
];

export const _tempChatTemplatesFreelancer = [
	{
		id: 1,
		titulo: 'Proposta de Job',
		mensagem: 'Podemos fechar por esse valor e esse prazo?',
		metadata: {
			titulo: 'Proposta de job',
			valor: 3000,
			descricao: '30 dias',
			prazo: 30,
			botoes: [
				{
					tipo: 'vermelho',
					texto: 'Recusar',
				},
				{
					tipo: 'verde',
					texto: 'Aceitar',
				},
			],
		},
		mensagemLida: true,
		type: 'proposta',
	},
];
