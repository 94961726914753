import React, { useContext, useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalJobsAdminFormCadastro.css';

// API
import { apiCompany } from '@_api/requests/company/company';
import apiTasks from '@api/tasks/tasks';
import { apiGetSelectProjetos, apiGetStatusTask, apiGetTemplatesPorTipo } from '@apiGeral/geral';

// Partes
import { FormJobsAdminCadastro } from '@page/Logado/JobsAdmin/FormJobsAdminCadastro/FormJobsAdminCadastro';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { camposInvalidos } from '@func/utils';
import { msgs, msgStatus } from "@_func/request";

const PortalJobsAdminFormCadastro = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-jobsadmin-form-cadastro',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		title: '',
		id_user: store.usuario.id_user,
		id_project: null,
		id_freelancer: null,
		id_template: 0,
		message: '',
	};

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetStatus, setLoadingGetStatus] = useState(false);

	// Edição
	const [loadingCadastrarJobAdmin, setLoadingCadastrarJobAdmin] = useState(false);
	const [loadingGetProjetos, setLoadingGetProjetos] = useState(false);
	const [loadingGetTemplatesPorTipo, setLoadingGetTemplatesPorTipo] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});
	const [stateStatus, setStateStatus] = useState([]);
	const [stateProjetos, setStateProjetos] = useState([]);
	const [stateEmpresa, setStateEmpresa] = useState({});
	const [stateTemplatesPorTipo, setStateTemplatesPorTipo] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await apiCompany.getOne.get(store.usuario.id_company);
		_setStateDados(msgStatus(retGetItem));
		if(retGetItem.status === 200) {
			setStateEmpresa(retGetItem.data);
			apiGetStatusTask({setLoadingGetStatusTask: setLoadingGetStatus, setStateStatusTask: setStateStatus});
			apiGetTemplatesPorTipo({idTipo: 2, setLoadingGetTemplatesPorTipo, setStateTemplatesPorTipo, active: 1, showMsg: false});
			const retProjetos = await apiGetSelectProjetos({id: store.usuario.id_company, setLoadingGetProjetos, setStateProjetos});
			if(retProjetos.status === 200) {
				setStateDados(prevState => ({
					...prevState,
					id_project: retProjetos.data[0].id,
				}));
			}
		} else {
			msgs([retGetItem]);
		}
		setLoading(false);
	}

	const cadastrarJobAdmin = async e => {
		e.preventDefault();
		if(loadingCadastrarJobAdmin) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			title: stateDados.title,
			id_user: store.usuario.id,
			id_project: stateDados.id_project,
			id_freelancer: stateDados.id_freelancer,
			message: stateDados.message,
			// status: 13,
			// id_customer: stateDados.id_customer,
		};

		if(camposInvalidos(dadosEnviar, {
			message: 'Mensagem',
		}, [
			'title',
			'id_project',
			'id_user',
			'id_freelancer',
			'message',
		])) return;

		setLoadingCadastrarJobAdmin(true);

		const retInsertJob = await apiTasks.insert.post(dadosEnviar);

		if(retInsertJob.status === 200) {
			msg.success('Job cadastrado com sucesso.');
			onSuccess({
				ret: retInsertJob,
			});
			setTimeout(() => {
				setStateDados(camposDefault);
			}, 500);
		} else {
			msgs([retInsertJob]);
			onError({
				ret: retInsertJob,
			});
		}
		setLoadingCadastrarJobAdmin(false);
	}

	return (
		<Portal
			titulo="Cadastrar job"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<FormJobsAdminCadastro
								onSubmit={cadastrarJobAdmin}
								stateDados={stateDados}
								setStateDados={setStateDados}
								stateStatus={stateStatus}
								loadingGetStatus={loadingGetStatus}
								stateEmpresa={stateEmpresa}
								stateProjetos={stateProjetos}
								loadingGetProjetos={loadingGetProjetos}
								stateTemplates={stateTemplatesPorTipo}
								loadingGetTemplates={loadingGetTemplatesPorTipo}
								after={
									<div className="form-bottom">
										<Button2
											_={{
												value: 'Cancelar',
												className: 'btn btn-1',
												type: 'button',
												onClick: onClose,
											}}
											classExtra="btn-1"
										/>
										<Button2
											_={{
												value: 'Fazer uma contratação',
												onClick: cadastrarJobAdmin
											}}
											loading={loadingCadastrarJobAdmin}
											wrap="margin-l-15"
										/>
									</div>
								}
							/>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export { PortalJobsAdminFormCadastro };
