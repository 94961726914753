import React from 'react';

// css
import './LinhaEspacoTexto.css';

const LinhaEspacoTexto = props => {
	const {
		titulo = '',
		desc = '',
		wrap = '',
		classNameTitulo = '',
		classNameDesc = '',
		className = '',
		alt,
	} = props;

	return (
		<div className={`cpnt-linhaespacotexto-wrap ${alt ? 'cpnt-linhaespacotexto-alt' : ''} ${wrap}`}>
			<div className={`cpnt-linhaespacotexto-inner ${className}`}>
				<div className={`cpnt-linhaespacotexto-titulo ${classNameTitulo}`}>{titulo}</div>
				<div className="cpnt-linhaespacotexto-linhasep"></div>
				<div className={`cpnt-linhaespacotexto-desc ${classNameDesc}`}>{desc}</div>
			</div>
		</div>
	);
};

export default LinhaEspacoTexto;