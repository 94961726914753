import { useRoutes } from 'react-router-dom';

// Layout painel
// import LayoutPainel from '@role/Layouts/LayoutPainel/LayoutPainel';
import { LayoutPainel } from "@_role/Layouts/LayoutPainel/LayoutPainel";

// Itens sidebar
import LayoutBlank from "@_role/Layouts/LayoutBlank/LayoutBlank";

// Screens
import { HomePremiumScreen } from "@_page/Logado/HomeScreen/HomePremiumScreen";
import { sidebarPremium } from "@_role/Sidebar/SidebarPremium";
import { RedirectTo } from "@cpnt/RedirectTo/RedirectTo";
import BuscarProfissionais from "@page/Logado/BuscarProfissionais/BuscarProfissionais";
import CadastrarProcessoSeletivo from "@page/Logado/CadastrarProcessoSeletivo/CadastrarProcessoSeletivo";
import CadastrarProcessoSeletivoSelecionados from "@page/Logado/CadastrarProcessoSeletivoSelecionados/CadastrarProcessoSeletivoSelecionados";
import Clientes from "@page/Logado/Clientes/Clientes";
import ComplementarProcessoSeletivo from "@page/Logado/ComplementarProcessoSeletivo/ComplementarProcessoSeletivo";
import { ConvideUmFreelancer } from "@page/Logado/ConvideUmFreelancer/ConvideUmFreelancer";
import Erro404Logado from "@page/Logado/Erro404Logado/Erro404Logado";
import HunterChat from "@page/Logado/HunterChat/HunterChat";
import JobsAdmin from "@page/Logado/JobsAdmin/JobsAdmin";
import NegociarJobs from "@page/Logado/NegociarJobs/NegociarJobs";
import NegociarProcessoSeletivo from "@page/Logado/NegociarProcessoSeletivo/NegociarProcessoSeletivo";
import PerfilAdministrador from "@page/Logado/PerfilAdministrador/PerfilAdministrador";
import ProcessosSeletivos from "@page/Logado/ProcessosSeletivos/ProcessosSeletivos";
import ProfissoesPredefinidas from "@page/Logado/ProfissoesPredefinidas/ProfissoesPredefinidas";
import Projetos from "@page/Logado/Projetos/Projetos";
import { Templates } from "@page/Logado/Templates/Templates";
import { VideosCrowder } from "@page/Logado/Videos/VideosCrowder";
import { VideosEmpresas } from "@page/Logado/Videos/VideosEmpresas";
import Logout from "@page/SemRestricoes/Logout/Logout";
import { MatchDeProfissionaisScreen } from "@_page/Logado/MatchDeProfissionaisScreen/MatchDeProfissionaisScreen";

export const ROTAS_PREMIUM = [
	{
		path: '/',
		element:
		<LayoutBlank
			title="Página inicial"
			component={RedirectTo}
			redirectTo="/inicio"
		/>,
	},
	{
		path: '/inicio',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Página inicial"
			component={HomePremiumScreen}
		/>,
	},
	{
		path: '/perfil',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Perfil"
			component={PerfilAdministrador}
		/>,
	},
	{
		path: '/buscar-profissionais',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Buscar profissionais"
			component={BuscarProfissionais}
		/>,
	},
	{
		path: '/briefing',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Propostas de jobs"
			component={ProcessosSeletivos}
		/>,
	},
	{
		path: '/briefing/cadastrar',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Cadastrar proposta de job"
			component={MatchDeProfissionaisScreen}
		/>,
	},
	{
		path: '/briefing/cadastrar-selecionados',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Cadastrar proposta de job freelancer selecionados"
			component={CadastrarProcessoSeletivoSelecionados}
		/>,
	},
	{
		path: '/briefing/negociacao',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Negociação"
			component={NegociarProcessoSeletivo}
			className="container-chat"
		/>,
	},
	{
		path: '/briefing/complementar',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Complementar proposta de job"
			component={ComplementarProcessoSeletivo}
		/>,
	},
	{
		path: '/projetos',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Buscar projetos"
			component={Projetos}
		/>,
	},
	{
		path: '/clientes',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Clientes"
			component={Clientes}
		/>,
	},
	{
		path: '/contratacoes',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Contratações"
			component={JobsAdmin}
		/>,
	},
	{
		path: '/contratacoes/negociacao',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Negociar Contratações"
			component={NegociarJobs}
			className="container-chat"
		/>,
	},
	{
		path: '/chat',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Chat"
			component={HunterChat}
		/>,
	},
	{
		path: '/configuracao-do-match',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Configuração do Briefing/Vaga"
			component={ProfissoesPredefinidas}
		/>,
	},
	{
		path: '/templates',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Templates"
			component={Templates}
		/>,
	},
	// {
	// 	path: '/comunidade',
	// 	element:
	// 	<LayoutPainel
	// 		sidebar={sidebarPremium}
	// 		meta={{
	// 			title: 'Comunidade',
	// 		}}
	// 		iframe="https://comunidadecrowd.circle.so/home?iframe=true"
	// 		component={CircleEmbed}
	// 	/>,
	// },
	{
		path: '/videos/empresas',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Vídeos"
			component={VideosEmpresas}
		/>,
	},
	{
		path: '/videos/crowders',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Vídeos"
			component={VideosCrowder}
		/>,
	},
	// {
	// 	path: '/indique-a-crowd',
	// 	element:
	// 	<LayoutPainel
	// 		sidebar={sidebarPremium}
	// 		meta={{
	// 			title: 'Indique a Crowd',
	// 		}}
	// 		component={IndiqueACrowd}
	// 	/>,
	// },
	{
		path: '/convide-um-freelancer',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Convide um freelancer"
			component={ConvideUmFreelancer}
		/>,
	},
	{
		path: '/logout',
		element:
		<Logout
			title="Fazendo logout, aguarde..."
		/>,
	},
	{
		path: '*',
		element:
		<LayoutPainel
			sidebar={sidebarPremium}
			title="Página não encontrada..."
			component={Erro404Logado}
		/>,
	}
];

function ScreensPremium() {
	const rotasPremium = useRoutes(ROTAS_PREMIUM);
	return rotasPremium;
}

export { ScreensPremium };
