import React, { useEffect, useState } from 'react';

// css
import c from './PortalAnimacaoMatchProfissionais.module.scss';

// Componentes
import { Portal } from "@_cpnt/Portal/Portal/Portal";
import { VideoPlayer } from "@_cpnt/VideoPlayer/VideoPlayer";

// Imagens
import imgSucesso from '@_img/default/icone-sucesso.png';

// Vídeos
import videoAI from '@video/crowd-ai-working.mp4';
import { TEMPO_ANIMACAO_MATCH } from "@_config/config";

const PortalAnimacaoMatchProfissionais = props => {

	const {
		show,
		onClose,
		x,
		closeOnEsc = false,
		portalClassName = c['container'],
		bottom,
	} = props;

	const [stateStep, setStateStep] = useState(0);

	useEffect(() => {
		if(show) {
			setTimeout(() => {
				setStateStep(1);
			}, TEMPO_ANIMACAO_MATCH)
		}
	}, [show]);

	return (
		<Portal
			titulo={stateStep === 0 ? 'Buscando profissionais com IA' : 'Profissionais encontrados'}
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
			bg="var(--color-bg-video-ia)"
			bottom={stateStep === 0 ? null : bottom}
			x={x}
		>

			{stateStep === 0 ? (
				<div>
					<VideoPlayer
						_={{
							url: videoAI,
							playing: true,
							controls: false,
							loop: true,
							muted: true,
							style: {
								backgroundColor: '#FDFDFD',
							}
						}}
					/>
					<div className="align-center">
						<h3 className={`${c['titulo']}`}>Buscando...</h3>
						<p>Nossa <strong>IA</strong> vai encontrar os melhores profissionais para o seu projeto.</p>
					</div>
				</div>
			) : null}

			{stateStep === 1 ? (
 				<>
					<div className={c['textos']}>
						<div className={`${c['box-texto-img-sucesso']}`}>
							<div><img src={imgSucesso} alt="Sucesso" className={`${c['img-sucesso']}`} /></div>
						</div>
						<div className={`${c['box-texto']} box-txt`}>
							<h3 className={`${c['titulo']}`}>Nossa IA conseguiu encontrar profissionais altamente qualificados para o seu projeto!</h3>
							<p>Parabéns! Os profissionais relevantes já foram notificados e agora você só precisa aguardar as respostas.</p>
							<p>Fique atento ao seu e-mail, onde você receberá notificações das respostas dos profissionais.</p>
							<p>Não se esqueça de verificar também a pasta de spam.</p>
							<p><strong>Sucesso!</strong></p>
						</div>
					</div>
				</>
			) : null}

		</Portal>
	);
};

export { PortalAnimacaoMatchProfissionais };
