import _ from 'lodash';

/*
** Verifica o histórico
** ================================================== */
export const diffViewerVerificarHistorico = (props = {}) => {
	const {
		id,
		historico = [],
		setStateEdicaoAnterior = () => null,
		setStateEdicaoAtual = () => null,
		setStateEdicaoTemDiff = () => null,
	} = props;

	var indexEditAtual = false;
	var indexEditAnterior = false;

	var editAtual = false;
	var editAnterior = false;

	indexEditAtual = _.findIndex(historico, function(o) { return o.id === id; });

	if(indexEditAtual !== false) {
		editAtual = historico[indexEditAtual];
		indexEditAnterior = historico[indexEditAtual + 1];
		if(indexEditAnterior && indexEditAnterior !== false) {
			editAnterior = historico[indexEditAtual + 1];
		}
	}

	setStateEdicaoAtual(editAtual);
	setStateEdicaoAnterior(editAnterior);

	if(editAtual && editAnterior) {
		setStateEdicaoTemDiff(true);
	}
}
