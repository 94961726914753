import React from 'react';
import ContabilizarPaginas from '../ContabilizarPaginas/ContabilizarPaginas';
import Paginacao from '../Paginacao/Paginacao';

// css
import './TotalPaginacao.css';

const TotalPaginacao = props => {
	const {
		stateGet = {},
		getsDefault = {},
		stateMetadata = {},
		loading,
		algolia,
		resultados,
	} = props;

	var paginas = stateMetadata.qtdPages;

	if(stateMetadata.qtdPaginas) paginas = stateMetadata.qtdPaginas;
	if(stateMetadata.qtdPages) paginas = stateMetadata.qtdPages;

	return (
		<div className="cpnt-totalepaginacao">
			<ContabilizarPaginas
				limite={stateGet.limit}
				paginaAtual={stateGet.page}
				qtdPaginas={paginas}
				total={stateMetadata.qtdRecords}
				algolia={algolia}
				resultados={resultados}
			/>
			<Paginacao
				paginaAtual={stateGet.page}
				totalItens={stateMetadata.qtdRecords}
				totalPaginas={paginas}
				qtdItensPorPagina={stateGet.limit}
				inativo={paginas > 1 ? false : true}
				getsDefault={getsDefault}
				loading={loading}
			/>
		</div>
	);
};

export default TotalPaginacao;