import React from 'react';

// CSS
import './ItemFotoDesc.css';

// Imagens
import imgUserPicDefault from '@img/userpic.png';

const ItemFotoDesc = props => {

	const {
		id,
		titulo,
		subtitulo,
		foto,
		onClick,
		className = '',
		classNameSubtitulo = 'text-one-line max-w-300',
		onClose,
		placeholder = imgUserPicDefault,
		alt,
		min,
		corAlt,
	} = props;

	var fotoExibir = foto;

	return (
		<div className={`cpnt-itemfotodesc ${className} ${alt ? 'cpnt-itemfotodesc-alt' : ''} ${min ? 'cpnt-itemfotodesc-min' : ''} ${corAlt ? `cpnt-itemfotodesc-coralt-${corAlt}` : ''}`}>
			<div className="cpnt-itemfotodesc-box" {...(onClick ? {onClick: e => onClick(id)} : {})}>
				<div className="cpnt-itemfotodesc-img-box">
					<div className="cpnt-itemfotodesc-wrap">
						{placeholder && <div className="cpnt-itemfotodesc-img fill bg" style={{backgroundImage: `url(${imgUserPicDefault})`}}></div>}
						<div className={`cpnt-itemfotodesc-img fill bg ${!placeholder ? 'bg-white' : ''}`} style={{backgroundImage: `url(${fotoExibir})`}}></div>
					</div>
				</div>
				{titulo || subtitulo ? (
					<div className="cpnt-itemfotodesc-texto">
						<div className="cpnt-itemfotodesc-texto-titulo"><strong>{titulo}</strong></div>
						<div className={`cpnt-itemfotodesc-texto-desc fs-12 color-1 fw-lighter ${classNameSubtitulo}`}><strong>{subtitulo}</strong></div>
					</div>
				) : null}
			</div>
			{onClose && <div className="x x-small" onClick={onClose}></div>}
		</div>
	)
}

export default ItemFotoDesc;