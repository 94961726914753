import React, { useState } from 'react';

// Compoenentes
import Input2 from '@cpnt/Input2/Input2';
import Tip from "@cpnt/Tip/Tip";
import CriteriosSenha from "@cpnt/CriteriosSenha/CriteriosSenha";

const FormSenha = props => {
	const {
		className = '',
		wrap = '',

		stateSenha,
		setStateSenha,
		email,
	} = props;

	const [stateCriteriosSenha, setStateCriteriosSenha] = useState([]);

	return (
		<div className={`cpnt-formportfolio ${wrap}`}>
			<div className={`cpnt-formportfolio-box ${className}`}>
				<div>
					<div className="wrap-5">
						<div className="flex flex-wrap">
							<div className="input-box box-1">
								<Input2
									label="Senha atual"
									req
									_={{
										value: stateSenha.actual_password,
										type: 'password',
										onChange: e => {
											setStateSenha({
												...stateSenha,
												actual_password: e.target.value,
											});
										}
									}}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="margin-t-15">
					<h3 className="titulo">Nova senha</h3>
					<div className="wrap-5 margin-t-5">
						<div className="flex flex-wrap">
							<div className="input-box box-1">
								<Tip
									titulo="Nova senha"
									tip={
										<Input2
											label="Nova senha"
											req
											_={{
												value: stateSenha.new_password,
												type: 'password',
												autoComplete: 'off',
												onChange: e => {
													setStateSenha({
														...stateSenha,
														new_password: e.target.value,
													})
												},
											}}
											debounce={false}
										/>
									}
									classNameTipDefault=""
									info
									click
									_defaults={{
										placement: 'right-center',
									}}
								>
									<CriteriosSenha
										senha={stateSenha.new_password}
										stateCriterios={stateCriteriosSenha}
										setStateCriterios={setStateCriteriosSenha}
									/>
								</Tip>
							</div>

							<div className="input-box box-1">
								<Input2
									label="Confirmar nova senha"
									req
									_={{
										value: stateSenha.password_confirmation,
										type: 'password',
										autoComplete: 'off',
										onChange: e => {
											setStateSenha({
												...stateSenha,
												password_confirmation: e.target.value,
											})
										},
									}}
									debounce={false}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="margin-t-15 desc">
					<div><strong>E-mail:</strong> {email}</div>
				</div>
			</div>
		</div>
	);
};

export default FormSenha;