import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiBriefingsCombos = {
	status: {
		get: async () => {
			return await api.get(`/briefingsCombos/status`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
}

export default apiBriefingsCombos;
