import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalPremioFormEdicao.css';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { camposInvalidos, dataApiCrop } from '@func/utils';
import FormCertificado from "@cpnt/Forms/FormCertificado/FormCertificado";
import FormPremio from "@cpnt/Forms/FormPremio/FormPremio";

const PortalPremioFormEdicao = props => {

	const {
		show,
		onClose = () => null,
		closeOnEsc = false,
		portalClassName = 'portal-premio-form-edicao',
		onSuccess = () => null,
		dados = {},
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		company: '',
		role: '',
		start_date: '',
		end_date: '',
		description: '',
	};

	const [stateDados, setStateDados] = useState(camposDefault);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({
				...stateDados,
				...dados,
			});
		}
	}

	/*
	** Editar empresa
	** ================================================== */
	const editar = async e => {
		e.preventDefault();

		const dadosEnviar = {
			...camposDefault,
			title: stateDados.title,
			issue_date: dataApiCrop(stateDados.issue_date),
		};

		if(camposInvalidos(dadosEnviar, {}, [
			'title',
			'issue_date',
		])) return;

		onSuccess({
			...dados,
			...stateDados,
		});
	}

	return (
		<Portal
			titulo="Editar prêmio"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<FormPremio
				state={stateDados}
				setState={setStateDados}
			/>
			<div className="form-bottom">
				<Button2
					_={{
						value: 'Cancelar',
						className: 'btn btn-1',
						onClick: onClose,
					}}
				/>
				<Button2
					_={{
						value: 'Confirmar edição',
						onClick: editar,
					}}
					wrap="margin-l-10"
				/>
			</div>
		</Portal>
	);
};

export { PortalPremioFormEdicao };
