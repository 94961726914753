import React from 'react';

// Libs
import { Link } from 'react-router-dom';

// CSS
import c from './ItemNotificacao.module.scss';

export const ItemNotificacao = props => {
	const {
		className = '',
		titulo,
		desc,
		onClick,
	} = props;

	return (
		<div onClick={onClick} className={`${className} ${c['container']}`}>
			<div className={`${c['titulo']}`}>{titulo}</div>
			<div className={`${c['desc']}`}>{desc}</div>
		</div>
	);
}
