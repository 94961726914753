import React from 'react';

// CSS
import './Status.css';

// Imagens
import imgIconePlay from '@svg/solid/circle-play.svg';
import imgIconePause from '@svg/solid/circle-pause.svg';
import imgIconeFinalizado from '@svg/solid/circle-check.svg';

// Componentes
import Icone from '@cpnt/Icone/Icone';

const Status = props => {

	const dados = {
		status: 0,
		className: '',
		onClick: () => null,
		...props,
	};

	return (
		<div className={`cpnt-status ${dados.className}`} onClick={dados.onClick}>
			<div className="cpnt-status-icones">
				{dados.status === 1 && (
					<span className="cpnt-status-icone" title="Execução">
						<Icone imagem={imgIconePlay} cor="#229d3e" tamanho="22px" />
					</span>
				)}
				{dados.status === 2 && (
					<span className="cpnt-status-icone" title="Pausado">
						<Icone imagem={imgIconePause} cor="#E74C3C" tamanho="22px" />
					</span>
				)}
				{dados.status === 3 && (
					<span className="cpnt-status-icone" title="Finalizado">
						<Icone imagem={imgIconeFinalizado} cor="#2596e1" tamanho="22px" />
					</span>
				)}
			</div>
		</div>
	);
};

export default Status;
