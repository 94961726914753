import React from 'react';

// css
import './PortalTermosDeUso.css';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Portal from '@cpnt/Portal/Portal';

// Textos
import textoTermosDeUso from "@texto/textoTermosDeUso";
import { ARQUIVO_TERMOS_DE_USO } from "@_config/config";

const PortalTermosDeUso = props => {

	const {
		show,
		onClose,
		closeOnEsc = true,
		portalClassName = 'portal-termos-uso',
	} = props;

	const pdfTermosDeUso = require(`@pdf/${ARQUIVO_TERMOS_DE_USO}`);

	return (
		<Portal
			titulo={
				<div className="portal-termos-titulo-box">
					<h3 className="portal-termos-titulo">Termos de Uso da Crowd</h3>
					<a href={pdfTermosDeUso} target="_blank" className="portal-termos-btn btn btn-default" rel="noreferrer">Download Termos de Uso</a>
				</div>
			}
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{textoTermosDeUso()}
			<div className="form-bottom">
				<Button2
					_={{
						value: 'Fechar',
						onClick: onClose,
					}}
				/>
			</div>
		</Portal>
	);
};

export default PortalTermosDeUso;