import React, { useContext, useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalDadosPessoaisUsuarioEdicao.css';

// API

// Partes

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { salvarSessaoLocal } from "@_func/auth";
import { apiGetCargos } from "@api/_functions/geral";
import { api } from "@_api/api";
import { camposInvalidos, dataApiCrop, getKey, isDataEngMaiorQueHoje, removerKeysVazias, validarDataEng } from '@func/utils';
import { FormDadosPessoaisUsuario } from "@page/Logado/Usuarios/FormDadosPessoaisUsuario/FormDadosPessoaisUsuario";
import { msgs, msgStatus } from "@_func/request";

const PortalDadosPessoaisUsuarioEdicao = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		idUsuario,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-dadospes-form-edicao',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetCargos, setLoadingGetCargos] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({
		name: '',
		birth_date: '',
		id_country: '',
		cpf_cnpj: '',
	});
	const [_stateDados, _setStateDados] = useState({});
	const [stateCargos, setStateCargos] = useState([]);

	// Edição
	const [loadingEditar, setLoadingEditar] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		setLoading(true);

		const retGetDados = await api.users.getOne.get(idUsuario);
		_setStateDados(msgStatus(retGetDados));

		if(retGetDados.status === 200) {
			setStateDados(retGetDados.data);
			apiGetCargos({setLoadingGetCargos, setStateCargos});
		} else {
			msgs([retGetDados]);
		}

		setLoading(false);
	}

	/*
	** Editar dados da empresa
	** ================================================== */
	const editar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			name: stateDados.name,
			birth_date: dataApiCrop(stateDados.birth_date),
			phone: stateDados.phone,
			cpf_cnpj: stateDados.cpf_cnpj,
		};

		if(store.usuario.role === 1) {
			dadosEnviar.id_position = stateDados.id_position;
		}

		if(camposInvalidos(dadosEnviar, {}, [
			'name',
		])) return;

		if(dadosEnviar.birth_date && dadosEnviar.birth_date !== '' && !validarDataEng(dadosEnviar.birth_date)) {
			msg.error('A data inserida é inválida.');
			return;
		}

		if(validarDataEng(dadosEnviar.birth_date) && isDataEngMaiorQueHoje(dadosEnviar.birth_date)) {
			msg.error('A data de aniversário não pode ser maior ou igual a data de hoje');
			return;
		}

		var dadosEnviarSemCamposVazios = removerKeysVazias(dadosEnviar);

		setLoadingEditar(true);

		const retUpdateUsuario = await api.users.update.put({
			...dadosEnviarSemCamposVazios,
			id: idUsuario,
		});

		if(retUpdateUsuario.status === 200) {
			msg.success('Dados atualizados sucesso.');
			salvarSessaoLocal({
				...store,
				usuario: {
					...store.usuario,
					...dadosEnviar,
				},
			});

			setStore({
				...store,
				usuario: {
					...store.usuario,
					...dadosEnviar,
				}
			});

			onSuccess({
				ret: retUpdateUsuario,
				dados: {
					...dadosEnviar,
					role_position: getKey(stateCargos, stateDados.id_position, 'id').name
				},
			});
		} else {
			msgs([retUpdateUsuario]);
			onError({
				ret: retUpdateUsuario,
			});
		}
		setLoadingEditar(false);
	}

	return (
		<Portal
			titulo="Dados pessoais"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<>
								<FormDadosPessoaisUsuario
									stateDados={stateDados}
									setStateDados={setStateDados}
									stateCargos={stateCargos}
									loadingGetCargos={loadingGetCargos}
								/>
								<div className="form-bottom">
									<Button2
										_={{
											value: 'Cancelar',
											className: 'btn btn-1',
											onClick: onClose,
										}}
									/>
									<Button2
										_={{
											value: 'Salvar dados',
											onClick: editar,
										}}
										wrap="margin-l-10"
										loading={loadingEditar}
									/>
								</div>
							</>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export { PortalDadosPessoaisUsuarioEdicao };
