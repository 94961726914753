import React, { useState } from 'react';

// css
import './SecaoFormacoes.css';

// Portais

// Componentes
import { ListaSecao } from "@cpnt/ListaSecao/ListaSecao";
import { PortalFormacaoFormCadastro } from "@cpnt/Portais/PortaisFormacoes/PortalFormacaoFormCadastro/PortalFormacaoFormCadastro";
import { PortalFormacaoFormEdicao } from "@cpnt/Portais/PortaisFormacoes/PortalFormacaoFormEdicao/PortalFormacaoFormEdicao";

const SecaoFormacoes = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		classNameTituloBox = '',
		classNameListaWrap = 'margin-t-25',
		classNameBox = 'box-2',

		onClickBtnSalvar,
		loadingBtnSalvar,
		stateFormacoes = [],
		setStateFormacoes,
		loadingGetFormacoes,
	} = props;

	const [showPortalAdd, setShowPortalAdd] = useState(false);
	const [showPortalEditar, setShowPortalEditar] = useState(false);
	const [stateFormacaoEditar, setStateFormacaoEditar] = useState({});

	return (
		<>
			<div className={`cpnt-formacoes ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-formacoes-box ${className}`}>
					<ListaSecao
						titulo="Formações"
						textoAdd="Adicionar"
						onClickBtnSalvar={onClickBtnSalvar}
						loadingBtnSalvar={loadingBtnSalvar}
						textoNenhumItemEncontrado="Nenhuma formação adicionada"
						stateDados={stateFormacoes}
						setStateDados={setStateFormacoes}
						keyTitulo="company"
						keyDesc="description"
						classNameWrap={classNameListaWrap}
						classNameTituloBox={classNameTituloBox}
						classNameBox={classNameBox}
						exibirApenasChecked={false}
						onClickAdd={e => setShowPortalAdd(true)}
						onClickEdit={e => {
							setStateFormacaoEditar({
								key: e.key,
								...e.dados,
							});
							setShowPortalEditar(true);
						}}
						onClickRemover={e => {
							var itens = stateFormacoes.filter((val, key) => key !== e.key);
							setStateFormacoes(itens);
						}}
						loading={loadingGetFormacoes}
						alt
					/>
				</div>
			</div>

			<PortalFormacaoFormCadastro
				show={showPortalAdd}
				onClose={e => setShowPortalAdd(false)}
				onSuccess={e => {
					var exp = [...stateFormacoes];
					exp.push(e);
					setStateFormacoes(exp);
					setShowPortalAdd(false);
				}}
			/>

			<PortalFormacaoFormEdicao
				show={showPortalEditar}
				onClose={e => setShowPortalEditar(false)}
				dados={stateFormacaoEditar}
				onSuccess={e => {
					var exp = [...stateFormacoes];
					exp[e.key] = e;
					setStateFormacoes(exp);
					setShowPortalEditar(false);
				}}
			/>
		</>
	);
};

export { SecaoFormacoes };
