import React, { Fragment, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// css
import './FiltrosProfissionais.css';

// Componentes
import { BtnExpandirMinimizar } from '@cpnt/BtnExpandirMinimizar/BtnExpandirMinimizar';
import { BtnLimparFiltros } from '@cpnt/BtnLimparFiltros/BtnLimparFiltros';
import Checkbox2 from '@cpnt/Checkbox2/Checkbox2';
import Input2 from '@cpnt/Input2/Input2';
import { Select2 } from '@cpnt/Select2/Select2';
import Tip from '@cpnt/Tip/Tip';

// Objetos
import { SENIORIDADE } from '@config/objetos';
import { FILTROS_RESULTADOS } from '@config/objetos';

// Funções
import { disponibilidadeFreelancer } from '@func/utils';

// Rota
import { setGetNavigate } from '@route/routeUtils';
import { DEV } from "@_config/config";

const FiltrosProfissionais = props => {

	const location = useLocation();
	const navigate = useNavigate();

	const {
		// Default
		className = '',
		classNameWrap = '',
		wrap = '',

		// Rota
		stateGet,
		getsDefault,

		// States
		stateEstados,
		stateIdiomas,
		stateCompetencias,
		stateObjetivos,
		stateAreas,
		stateHabilidades,
		stateSegmentos,

		/*
		** Loadings
		** ================================================== */
		loadingGetEstados,
		loadingGetIdiomas,
		loadingGetCompetencias,
		loadingGetObjetivos,
		loadingGetAreas,
		loadingGetHabilidades,
		loadingGetSegmentos,
	} = props;

	const [stateIsShowFiltros, setStateIsShowFiltros] = useState(true);

	return (
		<>
			<div className={`cpnt-filtrosprof ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-filtrosprof-box ${className}`}>
					<form
						onSubmit={e => e.preventDefault()}
						className="flex flex-wrap"
					>
						<div className="input-box w-33p box-1-980">
							<Input2
								label="Pesquisar"
								_={{
									placeholder: 'Nome, habilidade, descrição, ID e etc...',
									value: stateGet.text,
									onChange: e => {
										setGetNavigate({
											gets: {text: e.target.value},
											navigate,
											stateGet,
											location,
										});
									}
								}}
							/>
						</div>
						<div className="input-box w-33p box-1-980">
							<Select2
								label="Segmentos"
								_={{
									value: stateGet.segments,
									options: stateSegmentos,
									onChange: e => {
										setGetNavigate({
											gets: {segments: e.length ? e.map(val => val.value) : null},
											navigate,
											stateGet,
											location,
										});
									},
									isMulti: true,
									isClearable: true,
								}}
								loading={loadingGetSegmentos}
							/>
						</div>
						<div className="input-box w-33p box-1-980 flex">
							<Select2
								label="Senioridade"
								_={{
									value: stateGet.seniority,
									options: SENIORIDADE,
									onChange: e => {
										setGetNavigate({
											gets: {seniority: e ? e.value : null},
											navigate,
											stateGet,
											location,
										});
									},
									isClearable: true,
								}}
							/>
							<BtnExpandirMinimizar
								isExpanded={stateIsShowFiltros}
								_={{
									onClick: e => {
										setStateIsShowFiltros(!stateIsShowFiltros);
									},
								}}
							/>
						</div>
						{stateIsShowFiltros ? (
							<Fragment>
								<div className="input-box w-25p box-1-980">
									<Select2
										label="Categoria"
										_={{
											value: stateGet.area,
											options: stateAreas,
											onChange: e => {
												setGetNavigate({
													gets: {area: e ? e.value : null},
													navigate,
													stateGet,
													location,
												});
											},
											isClearable: true,
										}}
										loading={loadingGetAreas}
									/>
								</div>
								<div className="input-box w-25p box-1-980">
									<Select2
										label="Habilidades (de acordo com a área)"
										_={{
											value: stateGet.skills,
											options: stateHabilidades,
											onChange: e => {
												setGetNavigate({
													gets: {skills: e.length ? e.map(val => val.value) : null},
													navigate,
													stateGet,
													location,
												});
											},
											isClearable: true,
											isMulti: true,
										}}
										loading={loadingGetAreas || loadingGetHabilidades}
									/>
								</div>
								<div className="input-box w-25p box-1-980">
									<Select2
										label="Idiomas"
										_={{
											value: stateGet.languages,
											options: stateIdiomas,
											onChange: e => {
												setGetNavigate({
													gets: {languages: e.length ? e.map(val => val.value) : null},
													navigate,
													stateGet,
													location,
												});
											},
											isMulti: true,
											isClearable: true,
										}}
										loading={loadingGetIdiomas}
									/>
								</div>
								<div className="input-box w-25p box-1-980">
									<Select2
										label="Softskills"
										_={{
											value: stateGet.competences,
											options: stateCompetencias,
											onChange: e => {
												setGetNavigate({
													gets: {competences: e.length ? e.map(val => val.value) : null},
													navigate,
													stateGet,
													location,
												});
											},
											isMulti: true,
											isClearable: true,
										}}
										loading={loadingGetCompetencias}
									/>
								</div>
								<div className={`input-box box-1-980 ${DEV ? 'w-25p' : 'w-33p'}`}>
									<Select2
										label="Estado"
										_={{
											value: stateGet.state,
											options: stateEstados,
											onChange: e => {
												setGetNavigate({
													gets: {state: e ? e.value : null},
													navigate,
													stateGet,
													location,
												});
											},
											isClearable: true,
										}}
										loading={loadingGetEstados}
									/>
								</div>
								{DEV && (
									<div className="input-box w-25p box-1-980">
										<Input2
											label="Cidade"
											_={{
												value: stateGet.city,
												onChange: e => {
													setGetNavigate({
														gets: {city: e.target.value},
														navigate,
														stateGet,
														location,
													});
												}
											}}
										/>
									</div>
								)}
								{/* <div className="input-box w-25p box-1-980">
									<Select2
										label="Disponibilidade"
										_={{
											value: stateGet.availability,
											options: [
												{label: disponibilidadeFreelancer(1), value: 1},
												{label: disponibilidadeFreelancer(2), value: 2},
												{label: disponibilidadeFreelancer(3), value: 3},
											],
											onChange: e => {
												setGetNavigate({
													gets: {availability: e ? e.value : null},
													navigate,
													stateGet,
													location,
												});
											},
											isClearable: true,
										}}
									/>
								</div> */}
								<div className={`input-box box-1-980 ${DEV ? 'w-25p' : 'w-33p'}`}>
									<Select2
										label="Exibir perfis incompletos"
										_={{
											value: stateGet.all_results,
											options: [
												{label: 'Sim', value: 'sim'},
												{label: 'Não', value: 'nao'}
											],
											onChange: e => {
												setGetNavigate({
													gets: {all_results: e ? e.value : null},
													navigate,
													stateGet,
													location,
												});
											},
											isClearable: true,
										}}
									/>
								</div>
								<div className={`input-box ${DEV ? 'w-25p' : 'w-33p'} flex box-2-980 box-1-680 box-1-980`}>
									<Select2
										label="Resultados por página"
										_={{
											value: stateGet.limit,
											onChange: e => {
												setGetNavigate({
													gets: {limit: e.value},
													navigate,
													stateGet,
													location,
												});
											},
											options: FILTROS_RESULTADOS,
										}}
									/>
									<BtnLimparFiltros
										_={{
											onClick: e => {
												setGetNavigate({
													gets: getsDefault,
													navigate,
													stateGet,
													location,
												});
											},
										}}
										classNameTip="margin-l-auto"
									/>
								</div>
							</Fragment>
						) : null}

					</form>
				</div>
			</div>
		</>
	);
};

export { FiltrosProfissionais };