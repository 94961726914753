import React from 'react';

// css
import './Notificacoes.css';

// Imagens
import imgIconeNotificacao from '@svg/regular/bell.svg';

// Componentes
import Foto from '@cpnt/Foto/Foto';
import Icone from '@cpnt/Icone/Icone';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';

// Funções
import { dataPorExtenso } from '@func/utils';
import { primeiroNome } from "@_func/regex";

const Notificacoes = props => {
	const {
		className = '',
		wrap = '',
		stateNotificacoes = [],
		loadingGetNotificacoes,
		getNotificacoes = () => null,
		marcarTodasComoLidas = () => null,
		marcarComoLida = () => null,
		loadingAcoes,
		loadingMarcarComoLida,
		stateNotificacaoBotaoAtivo,
		setStateNotificacaoBotaoAtivo,
	} = props;

	/*
	** Quantidade de não lidas
	** ================================================== */
	const qtdNotificacoesNaoLidas = () => {
		const qtd = stateNotificacoes.filter(item => !item.readed);
		if(qtd.length) {
			return <div className="topo-menu-notificacao">{qtd.length > 99 ? '99+' : qtd.length}</div>
		}
		return '';
	}

	return (
		<div className={`cpnt-notificacoes ${wrap}`}>
			<div className={`cpnt-notificacoes-box ${className}`}>
				<div className="cpnt-notificacoes-fixo relative">
					<h3 className="margin-t-0 flex flex-center-v">
						<Icone
							imagem={imgIconeNotificacao}
							tamanho="26px"
							cor="#2596e1"
						/>
						<span className="margin-l-15">Notificações</span>
						{qtdNotificacoesNaoLidas()}
					</h3>
					<div className="fs-14 relative">
						{loadingAcoes ? (
							<Loading alt absolute className="fs-14" />
						) : null}
						<div className="cpnt-notificacoes-infobox flex flex-between flex-center-v border-t padding-t-10">
							<div>Exibir:</div>
							<div className="cpnt-notificacoes-btns flex flex-center-v flex-1 padding-l-15 flex-end">
								<div
									className={`btn-min border-blue-hover ${stateNotificacaoBotaoAtivo === 'todos' ? 'border-blue' : ''}`}
									onClick={() => {
										setStateNotificacaoBotaoAtivo('todos');
										getNotificacoes({
											readed: null,
										});
									}}
								>
									Todas
								</div>
								<div
									className={`btn-min border-blue-hover margin-l-10 ${stateNotificacaoBotaoAtivo === 'naolidas' ? 'border-blue' : ''}`}
									onClick={() => {
										setStateNotificacaoBotaoAtivo('naolidas');
										getNotificacoes({
											readed: 0,
										});
									}}
								>
									Não lidas
								</div>
								<div
									className={`btn-min border-blue-hover margin-l-10 ${stateNotificacaoBotaoAtivo === 'lidas' ? 'border-blue' : ''}`}
									onClick={() => {
										setStateNotificacaoBotaoAtivo('lidas');
										getNotificacoes({
											readed: 1,
										});
									}}
								>
									lidas
								</div>
							</div>
						</div>
						<div className="cpnt-notificacoes-infobox flex flex-between flex-center-v border-t padding-t-10 margin-t-10">
							<div>Ações:</div>
							<div className="cpnt-notificacoes-btns flex flex-center-v flex-1 flex-end padding-l-15">
								<div className="btn-min border-blue-hover" onClick={marcarTodasComoLidas}>Marcar todas como lidas</div>
							</div>
						</div>
					</div>
				</div>
				<div className="cpnt-notificacoes-conteudo scroll scroll-alt">
					{loadingGetNotificacoes ? (
						<div className="padding-30">
							<Loading texto="Carregando notificações, aguarde..." />
						</div>
					) : (
						stateNotificacoes === 403 ? (
							<div className="padding-30">
								<Info
									icone="erro"
									texto="Você não tem permissão para acessar essas notificações."
								/>
							</div>
						) : (
							Object.keys(stateNotificacoes).length === 0 && stateNotificacoes !== 200 ? (
								<div className="padding-30">
									<Info
										icone="erro"
										texto="Nenhuma notificação disponível."
										alt
									/>
								</div>
							) : (
								stateNotificacoes && stateNotificacoes.length ? (
									stateNotificacoes.map((val, key) => {
										return (
											<div
												key={key}
												className={`cpnt-notificacoes-lista-item relative ${!val.readed ? 'cpnt-notificacoes-lista-item-naolida' : ''}`}
												onClick={() => marcarComoLida(val)}
											>
												{loadingMarcarComoLida === val.id ? (
													<Loading texto="Marcando como lida, aguarde..." absolute alt />
												) : null}
												{val.photo ? (
													<div className="cpnt-notificacoes-lista-img">
														<div className="margin-auto">
															<Foto
																imagem={val.photo}
															/>
														</div>
														<div className="text-one-line align-center fs-12">{primeiroNome(val.name)}</div>
													</div>
												) : null}
												<div className="cpnt-notificacoes-lista-info">
													<div>{val.text}</div>
													<div className="flex flex-between fs-12 color-1 margin-t-10">
														<div>{val.titulo}</div>
														<div>{dataPorExtenso(val.date, true, 'há ')}</div>
													</div>
												</div>
											</div>
										)
									})
								) : (
									<div>Nenhuma notificação disponível.</div>
								)
							)
						)
					)}

				</div>

			</div>

		</div>
	);
};

export default Notificacoes;