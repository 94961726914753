import React, { useContext, useState } from 'react';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './ListaTemplates.css';

// Componentes
import Portal from '@cpnt/Portal/Portal';

import Button2 from "@cpnt/Button2/Button2";
import Input2 from "@cpnt/Input2/Input2";
import Radio2 from "@cpnt/Radio2/Radio2";
import { htmlToJsx, stringPesquisa, tipoTemplate } from "@func/utils";
import imgIconePreview from '@img/icones/geral/preview.png';

const ListaTemplates = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		titulo = 'Selecione',
		itens = [],
		btnTexto = 'Confirmar',
		onConfirm = () => null,
		closePortal = () => null,
		portalClassName = 'cpnt-templates-preview',
		className = 'conteudo-sem-tag box-txt',
		textoPesquisar = 'Pesquisar template',
		exibirPesquisa = true,
		scroll,
		getTemplates = () => null,
		stateTipo,
		setStateTipo,
	} = props;

	const [itensExibicao, setItensExibicao] = useState(itens);

	const filtrarItens = e => {
		var itensFiltrados = itens.filter(value => {
			var textoUsuario = stringPesquisa(e.target.value);
			var textoExistente = stringPesquisa(value.title);

			if(textoExistente.indexOf(textoUsuario) > -1) {
				return textoExistente;
			}
		});

		if(e.target.value === '') {
			itensFiltrados = itens;
		}

		setItensExibicao(itensFiltrados);
	}

	const [showOverlay, setShowOverlay] = useState(false);
	const [overlayConteudo, setOverlayConteudo] = useState(<></>);
	const [checkboxTemplateSelecionado, setCheckboxTemplateSelecionado] = useState({});

	const definirOverlayConteudo = (value, el) => {
		setOverlayConteudo(
			<div className={className}>
				<h3 className="margin-t-0">Template: <strong>{value.title}</strong></h3>
				<div className={`box-txt ${tipoTemplate(value.id_type) === 'texto' ? 'texto-simples' : ''}`}>{htmlToJsx(value.text)}</div>
			</div>
		);
		setShowOverlay(true);
	};

	const selecionarTemplate = e => {
		if(!checkboxTemplateSelecionado.id) {
			msg.error('Você não selecionou nenhum modelo');
			return;
		}
		onConfirm(checkboxTemplateSelecionado);
	};

	return (
		<>
			{titulo && <h3 className="margin-t-0">{titulo}</h3>}
			{exibirPesquisa ? (
				<div className="margin-b-20">
					<Input2
						label={textoPesquisar}
						_={{
							onChange: filtrarItens,
						}}
						debounce={false}
						autoFocus={true}
					/>
				</div>
			) : null}

			<div className="cpnt-template-container" style={{...(!scroll ? {maxHeight: 'none'} : null)}}>
				{!itensExibicao.length ?
					<h3 className="msg">Nenhuma informação encontrada</h3>
				:
					itensExibicao.map((value, key) => {
						return (
							<div key={key} className="cpnt-template-box">
								<div className="cpnt-template-box-wrap flex">
									<div className="cpnt-template-preview cursor-pointer" onClick={el => definirOverlayConteudo(value, el)}>
										<div className="cpnt-template-icopreview">
											<img src={imgIconePreview} alt="icone-preview" className="block w-100p" />
										</div>
										<div className="cpnt-template-btnpreview margin-t-10">Pré-visualizar</div>
									</div>
									<div className="cpnt-template-texto flex flex-1 flex-column">
										<div className="flex-1">{value.title}</div>
										<div className="cpnt-template-sublabel">{value.excerpt}</div>
										<div>
											<Radio2
												_={{
													checked: value.id === checkboxTemplateSelecionado.id ? true : false,
													onChange: e => setCheckboxTemplateSelecionado(value)
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						)
					})
				}
			</div>
			<div className="form-bottom">
				<Button2
					_={{
						value: 'Cancelar',
						className: 'btn btn-1',
						onClick: closePortal,
					}}
				/>
				<Button2
					_={{
						value: btnTexto,
						onClick: selecionarTemplate,
					}}
					wrap="margin-l-15"
				/>
			</div>

			<Portal
				titulo="Exemplo de template preenchido"
				show={showOverlay}
				onClose={() => {
					setShowOverlay(false);
				}}
				className={portalClassName}
			>
				{overlayConteudo}
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Fechar',
							onClick: e => setShowOverlay(false)
						}}
					/>
				</div>
			</Portal>

		</>
	);
};

export default ListaTemplates;