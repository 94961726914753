import React from 'react';

// css
import './BtnExpandirMinimizar.css';

// Componentes
import Tip from '@cpnt/Tip/Tip';

// Imagens
import imgIconeExpandir from '@svg/solid/caret-down.svg';
import imgIconeMinimizar from '@svg/solid/caret-up.svg';

// Funções
import { cssCorImagem } from '@func/utils';

const BtnExpandirMinimizar = props => {
	const {
		isExpanded,
		className,
		classNameWrap = '',
		classNameTip = '',
		_,
	} = props;

	return (
		<Tip
			tip={
				<div className={`cpnt-btnexpandnmin-btn-wrap ${classNameWrap}`}>
					<button
						type="button"
						{..._}
						className={`${className || _.className || ''} cpnt-btnexpandnmin-btn bg-mask-alt`}
						style={
							cssCorImagem({
								img: isExpanded ? imgIconeMinimizar : imgIconeExpandir,
								cor: '#0277BD',
							})
						}
					>
						{/* {value || _.value} */}
					</button>
				</div>
			}
			_defaults={{
				placement: 'left-center'
			}}
			classNameTip={classNameTip}
		>
			{isExpanded ? 'Minimizar os filtros' : 'Expandir os filtros'}
		</Tip>
	);
};

export { BtnExpandirMinimizar };
