import React, { useContext, useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalDadosPessoaisFormEdicao.css';

// API
import { apiGetPaises } from "@api/_functions/geral";
import apiFreelancer from "@api/freelancer/freelancer";

// Partes
import { PtDadosPessoais } from "@cpnt/Partes/PtDadosPessoais/PtDadosPessoais";

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { salvarSessaoLocal } from "@_func/auth";
import { camposInvalidos, dataApiCrop, isDataEngMaiorQueHoje, removerKeysVazias, validarCpf, validarDataEng } from '@func/utils';
import { msgs, msgStatus } from "@_func/request";

const PortalDadosPessoaisFormEdicao = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		idFreelancer,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-dadospes-form-edicao',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetPaises, setLoadingGetPaises] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({
		name: '',
		birth_date: '',
		id_country: '',
		cpf_cnpj: '',
	});
	const [_stateDados, _setStateDados] = useState({});
	const [statePaises, setStatePaises] = useState([]);

	// Edição
	const [loadingEditar, setLoadingEditar] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		setLoading(true);

		const retGetDados = await apiFreelancer.getOne.get(idFreelancer);
		_setStateDados(msgStatus(retGetDados));

		if(retGetDados.status === 200) {
			setStateDados(retGetDados.data);
			apiGetPaises({setLoadingGetPaises, setStatePaises, showMsg: false});
		} else {
			msgs([retGetDados]);
		}

		setLoading(false);
	}

	/*
	** Editar dados da empresa
	** ================================================== */
	const editar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			name: stateDados.name,
			birth_date: dataApiCrop(stateDados.birth_date),
			id_country: stateDados.id_country,
			cpf_cnpj: stateDados.cpf_cnpj,
			phone: stateDados.phone,
		};

		if(camposInvalidos(dadosEnviar, {}, [
			'name',
			'id_country',
			'cpf_cnpj',
			'phone',
		])) return;

		if(dadosEnviar.birth_date && dadosEnviar.birth_date !== '' && !validarDataEng(dadosEnviar.birth_date)) {
			msg.error('A data inserida é inválida.');
			return;
		}

		if(validarDataEng(dadosEnviar.birth_date) && isDataEngMaiorQueHoje(dadosEnviar.birth_date)) {
			msg.error('A data de aniversário não pode ser maior ou igual a data de hoje');
			return;
		}

		if(dadosEnviar.id_country === 33) {
			if(!validarCpf(dadosEnviar.cpf_cnpj)) return;
		}

		var dadosEnviarSemCamposVazios = removerKeysVazias(dadosEnviar);

		setLoadingEditar(true);

		const retUpdateFreelancer = await apiFreelancer.update.put({
			...dadosEnviarSemCamposVazios,
			id: idFreelancer,
		});

		if(retUpdateFreelancer.status === 200) {
			msg.success('Dados atualizados sucesso.');
			salvarSessaoLocal({
				...store,
				usuario: {
					...store.usuario,
					...dadosEnviar,
				},
			});

			setStore({
				...store,
				usuario: {
					...store.usuario,
					...dadosEnviar,
				}
			});

			onSuccess({
				ret: retUpdateFreelancer,
				dados: dadosEnviar,
			});
		} else {
			msgs([retUpdateFreelancer]);
			onError({
				ret: retUpdateFreelancer,
			});
		}
		setLoadingEditar(false);
	}

	return (
		<Portal
			titulo="Dados pessoais"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<>
								<PtDadosPessoais
									stateDados={stateDados}
									setStateDados={setStateDados}
									statePaises={statePaises}
									loadingGetPaises={loadingGetPaises}
								/>
								<div className="form-bottom">
									<Button2
										_={{
											value: 'Cancelar',
											className: 'btn btn-1',
											onClick: onClose,
										}}
									/>
									<Button2
										_={{
											value: 'Salvar dados',
											onClick: editar,
										}}
										wrap="margin-l-10"
										loading={loadingEditar}
									/>
								</div>
							</>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export { PortalDadosPessoaisFormEdicao };
