import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './Templates.css';

// Hooks
import useWindowDimension from '@/_/hooks/useWindowDimensions';

// API
import apiTemplates from "@api/templates/templates";
import { apiGetEmpresas, apiGetTemplatesTipo } from '@apiGeral/geral';

// Imagens
import imgIconeEditar from '@svg/regular/pen.svg';

// Components
import { BtnAdd } from '@cpnt/BtnAdd/BtnAdd';
import { BtnLimparFiltros } from '@cpnt/BtnLimparFiltros/BtnLimparFiltros';
import FiltrosWrap from '@cpnt/FiltrosWrap/FiltrosWrap';
import Icone from '@cpnt/Icone/Icone';
import ImgTituloDesc from '@cpnt/ImgTituloDesc/ImgTituloDesc';
import Input2 from '@cpnt/Input2/Input2';
import { PlaceholderRender } from '@cpnt/Placeholder/Placeholder';
import { PortalTemplateDetalhes } from "@cpnt/Portais/PortaisTemplates/PortalTemplateDetalhes/PortalTemplateDetalhes";
import PortalTemplateFormCadastro from "@cpnt/Portais/PortaisTemplates/PortalTemplateFormCadastro/PortalTemplateFormCadastro";
import PortalTemplateFormEdicao from "@cpnt/Portais/PortaisTemplates/PortalTemplateFormEdicao/PortalTemplateFormEdicao";
import { Select2 } from '@cpnt/Select2/Select2';
import { Select3Lista } from '@cpnt/Select3/Select3';
import StatusGeral from "@cpnt/StatusGeral/StatusGeral";
import Tip from '@cpnt/Tip/Tip';
import TotalPaginacao from '@cpnt/TotalPaginacao/TotalPaginacao';

// Objetos
import { FILTROS_RESULTADOS, STATUS_FILTRO_MIN } from '@config/objetos';

// Rotas
import { clearGet, closePortal, getPortalDados, gets, initFuncExec, isShowPortal, setGet, setGetNavigate, showPortal } from '@route/routeUtils';

// Funções
import { dataApiParaBr, msg, tamanhoTela } from '@func/utils';
import { msgs, msgStatus } from "@_func/request";
import { Btn } from "@_cpnt/Btn/Btn/Btn";

const Templates = props => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	const screen = useWindowDimension();

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = (getsAtualizados = getsDefault) => {
		initFuncExec([
			{
				func: getTemplates,
				param: getsAtualizados
			},
			{
				func: apiGetEmpresas,
				param: {
					setStateEmpresas,
					setLoadingGetEmpresas,
				},
				init: true,
			},
			{
				func: apiGetTemplatesTipo,
				param: {
					...(store.usuario.role !== 1 ? {
						filtros: {
							enable_in_company: 1,
						}
					} : {}),
					setLoadingGetTemplatesTipo,
					setStateTemplatesTipo,
				},
				init: true,
			},
		], init, location, stateLocation);
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		overlay: [],
		limit: 20,
		page: 1,
		active: null,

		title: '',
		type: null,
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
		init: init,
		defaults: {id_company: store.usuario.id_company}
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	/*
	** Loading
	** ================================================== */
	const [loadingGetTemplates, setLoadingGetTemplates] = useState(false);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);
	const [loadingAlterarStatus, setLoadingAlterarStatus] = useState(false);
	const [loadingGetTemplatesTipo, setLoadingGetTemplatesTipo] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateTemplates, setStateTemplates] = useState([]);
	const [_stateTemplates, _setStateTemplates] = useState({});
	const [stateTemplatesMetadata, setStateTemplatesMetadata] = useState({});
	const [stateEmpresas, setStateEmpresas] = useState([]);
	const [stateTemplatesTipo, setStateTemplatesTipo] = useState([]);

	/*
	** API - Projetos Filtro
	** ================================================== */
	const getTemplates = async (dadosObj = {}) => {
		if(loadingGetTemplates) {
			return;
		}
		setLoadingGetTemplates(true);

		var dadosEnviar = clearGet(dadosObj);
		const retGetTemplates = await apiTemplates.all.get(dadosEnviar);

		_setStateTemplates(msgStatus(retGetTemplates));

		if(retGetTemplates.status === 200) {
			setStateTemplates(retGetTemplates.data);
			setStateTemplatesMetadata(retGetTemplates.metadata);
		} else {
			setStateTemplates([]);
			setStateTemplatesMetadata({});
		}

		setLoadingGetTemplates(false);
	}

	/*
	** API - Alterar status da tarefa
	** ================================================== */
	const alterarStatusTemplate = async dadosObj => {
		if(loadingAlterarStatus === dadosObj.id) {
			msg.warning('Por favor, aguarde...');
			return;
		}

		setLoadingAlterarStatus(dadosObj.id);

		const retUpdateTemplate = await apiTemplates.update.put({
			id: dadosObj.id,
			active: dadosObj.status,
		});

		if(retUpdateTemplate.status === 200) {
			msg.success('Status atualizado com sucesso');
			setStateTemplates([...stateTemplates.map(item => {
				if(item.id === dadosObj.id) {
					return {
						...item,
						active: dadosObj.status,
					}
				}
				return item;
			})])
		} else {
			msgs([retUpdateTemplate]);
		}
		setLoadingAlterarStatus(false);
	}

	/*
	** Nomes dos status abreviados
	** ================================================== */
	const nomeStatusJobsAdminExibicao = status => {
		var _status = '(desconhecido)';
		switch (status) {
			case 'Em orçamento': _status = 'Orçamento'; break;
			case 'Em execução': _status = 'Execução'; break;
			default: _status = status; break;
		}
		return _status;
	}

	/*
	** Exibe o ícone correto do proprietario
	** ================================================== */
	const proprietarioTemplate = dados => {

		if(!dados.id_company) {
			return (
				<Tip
					titulo="Template público"
					tip={<span className="color-green">Público</span>}
					info
					_defaults={{
						placement: 'left-center',
					}}
				>
					<div>Todas as empresas tem acesso a esse template</div>
				</Tip>
			)
		}

		if(dados.id_company === store.usuario.id_company) {
			return (
				<Tip
					titulo="Template próprio"
					tip={<span className="color-blue">Próprio</span>}
					info
					_defaults={{
						placement: 'left-center',
					}}
				>
					<div>Apenas a empresa <strong>{dados.company_name}</strong> tem acesso a esse template.</div>
				</Tip>
			)
		}

		return (
			<Tip
				titulo="Template de empresa"
				tip={<span className="">Empresa</span>}
				info
				_defaults={{
					placement: 'left-center',
				}}
			>
				<div>Esse template pertence a empresa <strong>{dados.company_name}</strong></div>
			</Tip>
		)
	}

	/*
	** Variaveis de acordo com o tamanho da tela
	** ================================================== */
	var winQtdFotos = tamanhoTela([
		{width: 1580, dados: 2},
		{width: 1320, dados: 1},
		{width: 1280, dados: 1},
		{width: 980, dados: 10},
		{width: 680, dados: 4},
	], 3, screen);

	var winQtdSolucoes = tamanhoTela([
		{width: 1320, dados: 1},
		{width: 1280, dados: 2},
		{width: 980, dados: 10},
		{width: 680, dados: 4},
	], 2, screen);

	var winPosTooltipEsquerdo = tamanhoTela([
		{width: 980, dados: {placement: 'bottom-center'}},
	], {placement: 'left-center'}, screen);

	return (
		<>
			<div className="pg-jobsadmin base-full">
				<div className="conteudo-box">
					<div className="titulo-box titulo-box-res">
						<div className="flex flex-column">
							<h3 className="titulo">Templates</h3>
						</div>
						<Btn
							_={{
								value: 'Cadastrar template',
								onClick: () => showPortal({portal: 'cadastro', stateGet, navigate})
							}}
							size="m"
						/>
					</div>

					<FiltrosWrap>
						<form
							onSubmit={e => e.preventDefault()}
							className="flex flex-wrap wrap-5"
						>
							<div className="input-box flex-1">
								<Input2
									label="Pesquisar templates"
									_={{
										value: stateGet.title,
										onChange: e => {
											setGetNavigate({
												gets: {title: e.target.value},
												navigate,
												stateGet,
												location,
											});
										}
									}}
								/>
							</div>
							<div className="input-box flex-1 relative box-2-980 box-1-680">
								{store.usuario.role === 1 ? (
									<Select2
										label="Empresa"
										_={{
											value: stateGet.id_company,
											onChange: e => {
												setGetNavigate({
													gets: {id_company: e ? e.value : null},
													forceCamposVazios: {id_company: e ? e.value : null},
													navigate,
													stateGet,
													location,
												});
											},
											options: [
												{value: 0, label: <strong>Templates públicos</strong>},
												...stateEmpresas
											],
											isClearable: true,
										}}
										loading={loadingGetEmpresas}
									/>
								) : (
									<Select2
										label="Proprietário"
										_={{
											value: stateGet.id_company,
											onChange: e => {
												setGetNavigate({
													gets: {id_company: e ? e.value : null},
													forceCamposVazios: {id_company: e ? e.value : null},
													navigate,
													stateGet,
													location,
												});
											},
											options: [
												{value: store.usuario.id_company, label: 'Próprios'},
												{value: 0, label: 'Públicos'},
											],
											isClearable: true,
										}}
									/>
								)}
							</div>
							<div className="input-box flex-1 relative box-2-980 box-1-680">
								<Select2
									label="Tipo"
									_={{
										value: stateGet.type,
										options: stateTemplatesTipo,
										onChange: e => {
											setGetNavigate({
												gets: {type: e ? e.value : null},
												navigate,
												stateGet,
												location,
											});
										},
										isClearable: true,
									}}
									loading={loadingGetTemplatesTipo}
								/>
							</div>
							<div className="input-box flex-1 relative box-2-980 box-1-680">
								<Select2
									label="Status"
									_={{
										value: stateGet.active,
										onChange: e => {
											setGetNavigate({
												gets: {active: e ? e.value : null},
												forceCamposVazios: {active: e ? e.value : null},
												navigate,
												stateGet,
												location,
											});
										},
										options: STATUS_FILTRO_MIN,
										isClearable: true,
									}}
								/>
							</div>
							<div className="input-box flex-1 min-w-250 input-box-btnlimpar box-2-980 box-1-680">
								<Select2
									label="Resultados por página"
									_={{
										value: stateGet.limit,
										onChange: e => {
											setGetNavigate({
												gets: {limit: e.value},
												navigate,
												stateGet,
												location,
											});
										},
										options: FILTROS_RESULTADOS,
									}}
								/>
								<BtnLimparFiltros
									_={{
										onClick: e => {
											setGetNavigate({
												gets: getsDefault,
												navigate,
												stateGet,
												location,
											});
										},
									}}
								/>
							</div>
						</form>
					</FiltrosWrap>

					<div id="tabela-container">
						<TotalPaginacao {...{stateGet, stateMetadata: stateTemplatesMetadata, loading: loadingGetTemplates, getsDefault}} />
						<div className="table-wrap">
							<table className="tabela">
								<thead className="tabela-titulo-fixo">
									<tr>
										<th className="col-id">
											<Tip tip="ID">Identificador único do template</Tip>
										</th>
										<th>
											<Tip tip="Título">Título do template</Tip>
										</th>
										<th>
											<Tip tip="Resumo">Resumo em sobre para que se aplica esse template</Tip>
										</th>
										<th className="tbl-templates-col-tipo">
											<Tip tip="Tipo">Tipo para qual esse template se aplica</Tip>
										</th>
										<th className="col-data">
											<Tip tip="Criado em">Data que o template foi criado</Tip>
										</th>
										<th className="tbl-templates-col-proprietario">
											<Tip info tip="Proprietário">Se o template é público ou privado</Tip>
										</th>
										<th className="col-min-3">
											<Tip tip="Status">Se está ativo ou não</Tip>
										</th>
										<th className="col-acao">
											<Tip tip="Ação">Editar</Tip>
										</th>
									</tr>
								</thead>

								<tbody>
									<PlaceholderRender
										loading={loadingGetTemplates}
										init={init}
										itens={stateTemplates}
										request={_stateTemplates}
										cpnt="PlaceholderTemplates"
										qtdColunas={8}
										itensQtd={{
											foto: winQtdFotos,
											solucoes: winQtdSolucoes,
										}}
									>
										{stateTemplates.map((val, key) => {
											return (
												<tr key={key}>

													<td data-titulo="ID" className="col-id">
														<div className="td-wrap">
															{val.id}
														</div>
													</td>

													<td data-titulo="Título">
														<div className="td-wrap">
															<ImgTituloDesc
																wrap="a td-info"
																titulo={val.title || '(Template sem título)'}
																classNameTitulo="td-titulo"
																classNameDesc="td-desc"
																responsivo={true}
																onClick={() => showPortal({portal: 'detalhes', dados: val.id, stateGet, navigate})}
															/>
														</div>
													</td>

													<td data-titulo="Resumo">
														<div className="td-wrap">
															{val.excerpt}
														</div>
													</td>

													<td data-titulo="Tipo">
														<div className="td-wrap">
															{val.type_name}
														</div>
													</td>

													<td data-titulo="Criado em">
														<div className="td-wrap align-center">
															{dataApiParaBr(val.created_at, '-')}
														</div>
													</td>

													<td data-titulo="Proprietário">
														<div className="td-wrap align-center">
															{proprietarioTemplate(val)}
														</div>
													</td>

													<td data-titulo="Status">
														<div className="td-wrap scroll-visible align-center">
															<Tip
																tip={
																	<div className="a">
																		<StatusGeral
																			status={val.active}
																			className="cursor-pointer"
																		/>
																		<div className="td-desc-alt">{nomeStatusJobsAdminExibicao(val.status_name)}</div>
																	</div>
																}
																click={true}
																show={loadingAlterarStatus === val.id ? true : false}
																_defaults={winPosTooltipEsquerdo}
															>
																<Select3Lista
																	titulo={<h3 className="margin-0">Status do template</h3>}
																	options={STATUS_FILTRO_MIN}
																	optionsType="radio"
																	optionsChecked={{
																		value: val.active,
																	}}
																	onClick={e => {
																		alterarStatusTemplate({
																			id: val.id,
																			status: e.value,
																		});
																	}}
																	loading={loadingAlterarStatus === val.id ? true : false}
																	labelClass="flex-1"
																	posicao=""
																	classNameDefault=""
																/>
															</Tip>
														</div>
													</td>

													<td data-titulo="Ação">
														<div className="td-wrap align-center">
															{!val.id_company && store.usuario.role !== 1 ? (
																<Icone
																	imagem={imgIconeEditar}
																	cor="#555"
																	className={`margin-auto opct-3`}
																	onClick={() => msg.error('Você não tem permissão para editar esse template.')}
																/>
															) : (
																<Icone
																	imagem={imgIconeEditar}
																	cor="#555"
																	className={`margin-auto cursor-pointer`}
																	onClick={() => showPortal({portal: 'edicao', dados: val.id, stateGet, navigate})}
																/>
															)}
														</div>
													</td>

												</tr>
											)
										})}
									</PlaceholderRender>
								</tbody>
							</table>
						</div>
						<TotalPaginacao {...{stateGet, stateMetadata: stateTemplatesMetadata, loading: loadingGetTemplates, getsDefault}} />
					</div>
				</div>
			</div>

			<PortalTemplateFormCadastro
				show={isShowPortal({portal: 'cadastro', stateGet})}
				onClose={() => closePortal({portal: 'cadastro', stateGet, navigate})}
				onSuccess={() => {
					getTemplates(gets());
					closePortal({portal: 'cadastro', stateGet, navigate});
				}}
				closeOnEsc={false}
			/>

			<PortalTemplateFormEdicao
				show={isShowPortal({portal: 'edicao', stateGet})}
				id={getPortalDados({portal: 'edicao', stateGet})}
				onClose={() => closePortal({portal: 'edicao', stateGet, navigate})}
				onSuccess={() => {
					getTemplates(gets());
					closePortal({portal: 'edicao', stateGet, navigate});
				}}
				onDelete={() => {
					getTemplates(gets());
					closePortal({portal: ['edicao', 'confirmacao-deletar-template'], stateGet, navigate});
				}}
				closeOnEsc={false}
			/>

			<PortalTemplateDetalhes
				show={isShowPortal({portal: 'detalhes', stateGet})}
				id={getPortalDados({portal: 'detalhes', stateGet})}
				onClose={() => closePortal({portal: 'detalhes', stateGet, navigate})}
				onSuccess={() => closePortal({portal: 'detalhes', stateGet, navigate})}
				onClickEditar={e => showPortal({portal: 'edicao', dados: e, remover: 'detalhes', stateGet, navigate})}
				closeOnEsc={false}
			/>
		</>
	);
}

export { Templates };
