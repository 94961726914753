import React from 'react';

// css
import './IconeChat.css';

import Icone from '@cpnt/Icone/Icone';
import imgIconeComentario from '@svg/light/comment-lines.svg';

const IconeChat = props => {
	const {
		className = '',
		wrap = '',
		qtdMensagens = 0,
	} = props;

	return (
		<div className={`cpnt-iconechat ${wrap}`}>
			<div className={`cpnt-iconechat-box ${className}`}>
				<Icone
					imagem={imgIconeComentario}
					cor={qtdMensagens ? 'var(--color-1)' : 'var(--color-1)'}
					tamanho="30px"
				/>
				<div className={`cpnt-iconechat-count ${qtdMensagens > 0 ? 'cpnt-iconechat-com-msg' : 'cpnt-iconechat-sem-msg'}`}>
					{qtdMensagens > 99 ? '99+' : qtdMensagens}
				</div>
			</div>
		</div>
	);
};

export default IconeChat;