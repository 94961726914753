import React from 'react';
import { Link } from 'react-router-dom';

// CSS
import './TextoIconeLink.css';

const TextoIconeLink = props => {
	const dados = {
		texto: false,
		desc: false,
		icone: false,
		link: false,
		router: false,
		target: '_self',
		className: '',
		classNameTexto: '',
		iconeTamanho: false,
		iconeCor: '#999', // css
		svg: true,
		onClick: false,
		reverso: false,
		classNameIcone: '',
		...props,
	};

	return (
		<>
			{(!dados.navigate && !dados.link) ? (
				<div className={`cpnt-textoicone ${dados.reverso ? 'cpnt-textoicone-reverso' : ''} ${dados.className}`} {...(dados.onClick && { onClick: dados.onClick })}>
					{TextoIconeLinkConteudo(dados)}
				</div>
			) : (
				<>
					{dados.link && (
						<a href={dados.link} target={dados.target} className={`cpnt-textoicone ${dados.reverso ? 'cpnt-textoicone-reverso' : ''} ${dados.className}`} {...(dados.onClick && { onClick: dados.onClick })}>
							{TextoIconeLinkConteudo(dados)}
						</a>
					)}

					{dados.navigate && (
						<Link to={dados.navigate} target={dados.target} className={`cpnt-textoicone ${dados.reverso ? 'cpnt-textoicone-reverso' : ''} ${dados.className}`} {...(dados.onClick && { onClick: dados.onClick })}>
							{TextoIconeLinkConteudo(dados)}
						</Link>
					)}
				</>
			)}
		</>
	)
};

export const TextoIconeLinkConteudo = props => {

	var style = {};
	if(props.svg) {
		style = {
			...(props.iconeTamanho ? {
				width: props.iconeTamanho,
				minWidth: props.iconeTamanho,
				maxWidth: props.iconeTamanho,
				minHeight: props.iconeTamanho,
				maxHeight: props.iconeTamanho,
				height: props.iconeTamanho,
			} : {}),
			backgroundColor: props.iconeCor,
			maskImage: `url(${props.icone})`,
			WebkitMaskImage: `url(${props.icone})`,
		};
	} else {
		style = {
			...(props.iconeTamanho ? {
				width: props.iconeTamanho,
				minWidth: props.iconeTamanho,
				maxWidth: props.iconeTamanho,
				minHeight: props.iconeTamanho,
				maxHeight: props.iconeTamanho,
				height: props.iconeTamanho,
			} : {}),
			backgroundImage: `url(${props.icone})`,
		};
	}

	return (
		<>
			{props.icone &&
				<div
					className={`cpnt-textoicone-icone bg bg-mask-alt relative ${props.classNameIcone} ${props.texto ? '' : 'cpnt-textoicone-icone-sem-texto'}`}
					style={style}
				>
				</div>
			}
			{props.texto &&
				<div className={`cpnt-textoicone-texto ${props.classNameTexto}`}>
					{props.texto}
					{props.desc ? (
						<div className={`cpnt-textoicone-desc ${props.classNameDesc}`}>
							{props.desc}
						</div>
					) : null}
				</div>
			}
		</>
	)
};

// export const TextoIconeLinkConteudo = props => {

// 	var style = {};
// 	if(props.svg) {
// 		style = {
// 			...(props.iconeTamanho ? {
// 				width: props.iconeTamanho,
// 				minWidth: props.iconeTamanho,
// 				maxWidth: props.iconeTamanho,
// 				minHeight: props.iconeTamanho,
// 				maxHeight: props.iconeTamanho,
// 				height: props.iconeTamanho,
// 			} : {}),
// 			backgroundImage: `url(${props.icone})`
// 		};
// 	} else {
// 		style = {
// 			...(props.iconeTamanho ? {
// 				width: props.iconeTamanho,
// 				minWidth: props.iconeTamanho,
// 				maxWidth: props.iconeTamanho,
// 				minHeight: props.iconeTamanho,
// 				maxHeight: props.iconeTamanho,
// 				height: props.iconeTamanho,
// 			} : {}),
// 			backgroundImage: `url(${props.icone})`
// 		};
// 	}

// 	return (
// 		<>
// 			{props.icone &&
// 				<div
// 					className={`cpnt-textoicone-icone bg bg-alt relative ${props.classNameIcone} ${props.texto ? '' : 'cpnt-textoicone-icone-sem-texto'}`}
// 					style={style}
// 				>
// 				</div>
// 				// <div className={`cpnt-textoicone-icone relative ${props.texto ? '' : 'cpnt-textoicone-icone-sem-texto'}`} style={props.iconeTamanho ? {width: props.iconeTamanho, height: props.iconeTamanho} : null}>
// 				// 	<div style={{backgroundImage: `url(${props.icone})`}} className="bg-alt fill" />
// 				// </div>
// 			}
// 			{props.texto &&
// 				<div className="cpnt-textoicone-texto">{props.texto}</div>
// 			}
// 		</>
// 	)
// };

export default TextoIconeLink;
