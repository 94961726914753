import React, { useState } from 'react';

// CSS
import { HomeScreen } from "./HomeScreen/HomeScreen";

// Img
import imgIconeMatch from '@_svg/match.svg';
import imgIconeAcademy from '@fa/solid/book-open.svg';
import imgIconeContratar from '@fa/solid/caret-up.svg';
import imgIconePerfil from '@fa/solid/user-large.svg';

// URLs
import { URL_BLOG, URL_CROWD_ACADEMY } from "@_config/config";
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";

export const HomeFreelancerScreen = () => {

	const [showPortalAviso, setShowPortalAviso] = useState(false);

	return (
		<>
			<HomeScreen
				boxes={[
					{
						icone: imgIconePerfil,
						titulo: 'Meu Perfil',
						desc: 'Evolua e receba mais oportunidades',
						link: '/perfil',
					},
					{
						icone: imgIconeMatch,
						titulo: 'Propostas de jobs',
						desc: 'Propostas de marketing e tecnologia',
						link: '/jobs',
					},
					{
						icone: imgIconeAcademy,
						titulo: 'Academy',
						desc: 'Cursos e treinamentos gratuitos',
						link: URL_CROWD_ACADEMY,
					},
					{
						icone: imgIconeContratar,
						titulo: 'Contratar',
						desc: 'O freelancer ideal em minutos',
						onClick: () => {
							console.log('click');
							setShowPortalAviso(true);
						}
					},
				]}
			/>

			<PortalConfirmacao
				portalClassName="w-500"
				titulo="Você será redirecionado"
				desc="Será necessário criar uma nova conta com outro email para publicar seu projeto."
				show={showPortalAviso}
				onClose={() => setShowPortalAviso(false)}
				onClickCancelar={() => setShowPortalAviso(false)}
				btnConfirmarTexto="Ir para criação de conta"
				onClickConfirmar={() => {
					window.open('https://crowd.br.com/contratar-freelancer/', '_blank');
				}}
			/>
		</>
	);
}
