import React, { useEffect, useState } from 'react';

// css
import './PortalPortfolioFormEdicao.css';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Portal from '@cpnt/Portal/Portal';

// Funções
import FormPortfolio from "@cpnt/Forms/FormPortfolio/FormPortfolio";
import { addPrefixo, camposInvalidos, validarUrl } from '@func/utils';

const PortalPortfolioFormEdicao = props => {

	const {
		show,
		onClose = () => null,
		closeOnEsc = false,
		portalClassName = 'portal-port-form-edicao',
		onSuccess = () => null,
		dados = {},
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		url: '',
	};

	const [stateDados, setStateDados] = useState(camposDefault);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({
				...stateDados,
				...dados,
			});
		}
	}

	/*
	** Editar empresa
	** ================================================== */
	const editar = async e => {
		e.preventDefault();

		const dadosEnviar = {
			...camposDefault,
			url: stateDados.url,
		};

		if(camposInvalidos(dadosEnviar, {}, [
			'url',
		])) return;

		if(!validarUrl(dadosEnviar.url)) return;

		onSuccess({
			...dados,
			...stateDados,
			url: addPrefixo(stateDados.url),
		});
	}

	return (
		<Portal
			titulo="Editar portfólio"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<form onSubmit={editar}>
				<FormPortfolio
					state={stateDados}
					setState={setStateDados}
				/>
				<div className="form-bottom">
					<Button2
						_={{
							type: 'button',
							value: 'Cancelar',
							className: 'btn btn-1',
							onClick: onClose,
						}}
					/>
					<Button2
						_={{
							value: 'Confirmar edição',
						}}
						wrap="margin-l-10"
					/>
				</div>
			</form>
		</Portal>
	);
};

export { PortalPortfolioFormEdicao };
