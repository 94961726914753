import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// Funções
import { apagarSessaoLocal } from '@_func/auth';

const Logout = props => {
	const [store, setStore] = useContext(StoreContext);
	const [deslogadoErro, setDeslogadoErro] = useState(false);
	const navigate = useNavigate();

	const deslogar = async () => {

		/*
		** Não está salvando token no banco, então ignora o request na API...
		** ================================================== */
		// const ret = await apiLogin.logout();

		// Apaga apenas local
		const logout = apagarSessaoLocal();

		setTimeout(() => {
			if(logout) {
				setStore({
					_logado: false,
					token: false,
					usuario: {},
				});
				navigate('/login');
			} else {
				setDeslogadoErro(true);
			}
		}, 1000);
	}

	if(!deslogadoErro) {
		deslogar();
		return <h4 className="margin-0 padding-20">Deslogado, por favor aguarde...</h4>;
	}

	if(deslogadoErro) {
		return <h4 className="margin-0 padding-20">Não foi possível deslogar, tente novamente...</h4>
	}

	return <></>;
}

export default Logout;