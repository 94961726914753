import React, { useContext, useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalJobsAdminFormEdicao.css';

// API
import apiTasks from '@api/tasks/tasks';

// Partes
import { FormJobsAdminEdicao } from '@page/Logado/JobsAdmin/FormJobsAdminEdicao/FormJobsAdminEdicao';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Objetos

// Funções
import { camposInvalidos } from '@func/utils';
import { getStatusJobsAdminNivelAcesso, statusJobsAdminEnviarMensagemAlteracaoStatus, statusJobsAdminEnviarMensagemAlteracaoTitulo } from '@page/Logado/JobsAdmin/funcJobsAdmin';
import { msgs, msgStatus } from "@_func/request";

const PortalJobsAdminFormEdicao = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-jobsadmin-form-edicao',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		id: '',
		title: '',
		status: '',
		id_freelancer: '',
	};

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetStatus, setLoadingGetStatus] = useState(false);

	// Edição
	const [loadingEditarJobAdmin, setLoadingEditarJobAdmin] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});
	const [stateStatus, setStateStatus] = useState([]);
	const [stateDadosIniciais, setStateDadosIniciais] = useState(false);
	const [stateBkpDados, setStateBkpDados] = useState({});

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await apiTasks.getOne.get(id);

		if(retGetItem.status === 200) {
			setStateDados({...stateDados, ...retGetItem.data});
			setStateBkpDados({
				status: retGetItem.data.status,
				title: retGetItem.data.title
			});
			_setStateDados(msgStatus(retGetItem));
			setStateDadosIniciais(retGetItem.data);
			getStatusJobsAdminNivelAcesso({
				statusAtual: retGetItem.data.status,
				role: store.usuario.role,
				setStateStatus,
			});
		} else {
			_setStateDados(msgStatus(retGetItem));
			msgs([retGetItem]);
		}

		setLoading(false);
	}

	/*
	** API - Editar
	** ================================================== */
	const editarJobAdmin = async e => {
		e.preventDefault();
		if(loadingEditarJobAdmin) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		if(!stateDados.price && stateDados.status === 15) {
			msg.warning('Antes de aprovar o job, é necessário que o profissional remoto freelancer envie uma proposta no chat e essa proposta seja aceita por um usuário Hunter ou Admin.');
			return;
		} else if(!stateDados.price && stateDados.status === 14) {
			msg.warning('Antes de iniciar a execução do job, é necessário que o profissional remoto freelancer envie uma proposta no chat e essa proposta seja aceita por um usuário Hunter ou Admin.');
			return;
		}

		const dadosEnviar = {
			...camposDefault,
			id: stateDados.id,
			title: stateDados.title,
			status: stateDados.status,
			id_freelancer: stateDados.id_freelancer,
		}

		if(camposInvalidos(dadosEnviar, {}, [
			'id',
			'title',
			'status',
			'id_freelancer',
		])) return;

		setLoadingEditarJobAdmin(true);

		const retUpdateCard = await apiTasks.update.put(dadosEnviar);

		if(retUpdateCard.status === 200) {

			msg.success('Job atualizado com sucesso.');

			/*
			** Verifica se o status foi alterado e envia mensagem
			** ================================================== */
			if(stateBkpDados.status !== stateDados.status) {
				statusJobsAdminEnviarMensagemAlteracaoStatus({
					idJob: stateDados.id,
					idStatus: stateDados.status,
					idFreelancer: stateDados.id_freelancer,
					statusLabel: stateDados.statusLabel,
					idUser: store.usuario.id,
				});
			}

			/*
			** Verifica se o título foi alterado e envia mensagem
			** ================================================== */
			if(stateBkpDados.title !== stateDados.title) {
				statusJobsAdminEnviarMensagemAlteracaoTitulo({
					idJob: stateDados.id,
					idStatus: stateDados.status,
					idFreelancer: stateDados.id_freelancer,
					statusLabel: stateDados.statusLabel,
					idUser: store.usuario.id,
					nomeUsuario: store.usuario.name,
					tituloJob: stateDados.title,
					tituloJobOld: stateBkpDados.title,
				});
			}

			onSuccess({
				ret: retUpdateCard,
			});

		} else {
			msgs([retUpdateCard]);
			onError({
				ret: retUpdateCard,
			});
		}

		setLoadingEditarJobAdmin(false);
	}

	return (
		<Portal
			titulo="Editar job"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<FormJobsAdminEdicao
								onSubmit={editarJobAdmin}
								stateDados={stateDados}
								setStateDados={setStateDados}
								stateStatus={stateStatus}
								loadingGetStatus={loadingGetStatus}
								after={
									<div className="form-bottom">
										<Button2
											_={{
												value: 'Cancelar',
												className: 'btn btn-1',
												type: 'button',
												onClick: onClose,
											}}
											classExtra="btn-1"
										/>
										<Button2
											_={{
												value: 'Salvar dados',
												onClick: editarJobAdmin
											}}
											loading={loadingEditarJobAdmin}
											wrap="margin-l-15"
										/>
									</div>
								}
							/>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export { PortalJobsAdminFormEdicao };
