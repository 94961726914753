import React from 'react';

// css
import c from './Toggler.module.css';

const Toggler = props => {
	const {
		textoAtivo = 'Ativado',
		textoInativo = 'Desativado',
		className = '',
		_,
	} = props;

	return (
		<div className={`${c['toggler']} ${className}`}>
			<label className={`${c['toggler-box']}`}>
				<input
					type='checkbox'
					{..._}
					className={`${c['input']}`}
				/>
				<span className={`${c['check']}`}></span>
				<span className={`${c['box']}`}></span>
				<span className={`${c['label']} ${c['label-ativo']}`}>{textoAtivo}</span>
				<span className={`${c['label']} ${c['label-inativo']}`}>{textoInativo}</span>
			</label>
		</div>
	);
};

export default Toggler;