import React, { useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import { IconeTextoRoot } from "@_cpnt/Texto/IconeTexto/IconeTextoRoot/IconeTextoRoot";
import c from './Sidebar.module.scss';
import { IconeTexto } from "@_cpnt/Texto/IconeTexto/IconeTexto";

import imgLogoCrowd from '@_img/default/logo.svg';
import { linkTarget, urlImg } from "@_func/url";
import { fotoOuGravatar, nomeNivelAcesso } from "@_func/profile";

import imgLogout from '@fa/solid/right-from-bracket.svg';
import { Btn } from "@_cpnt/Btn/Btn/Btn";
import { hideSidebar, isHiddenSidebar, toggleSidebar } from "@_func/cpnt";

export const Sidebar = props => {
	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
		sidebar = {},
	} = props;

	const {
		menu = [],
		padrao = {},
		naoFinalizado = {},
	} = sidebar;

	var { btn } = sidebar;

	var menuSidebar = menu || [];

	/*
	** Sidebar do freelancer são 2 diferentes
	** Uma para cadastro finalizado
	** Outra para cadastro não finalizado
	** ================================================== */
	if(store.usuario.role === 2) {
		if(!store.usuario.finish) {
			menuSidebar = naoFinalizado?.menu || [];
		} else {
			menuSidebar = padrao?.menu || [];
			btn = padrao?.btn || {};
		}
	}

	return (
		<>
			<div className={`${className} ${c['sidebar']} ${isHiddenSidebar(store, c['sidebar-hide'])}`}>
				<div className={`${c['top']}`}>
					<img src={imgLogoCrowd} alt="logo-crowd" />
				</div>
				<div className={`${c['header']}`}>
					<div className={`${c['foto-box']}`}>
						<img src={fotoOuGravatar({foto: store.usuario.photo, email: store.usuario.email, avatar: 'mp'})} alt="foto" className={`${c['foto']}`} />
					</div>
					<div className={`${c['info']}`}>
						<div className={`${c['nome']}`}>{store.usuario.name}</div>
						<div className={`${c['info-2']}`}>
							{store.usuario.role !== 2 && (
								<div className={`${c['empresa']} text-one-line`}>{store.usuario.company_name}</div>
							)}
							<div className={`${c['email']} text-one-line`}>{store.usuario.email}</div>
						</div>
						<div className={`${c['nivel-acesso']}`}>{nomeNivelAcesso(store.usuario.role)}</div>
					</div>
				</div>
				<div className={`${c['menu']} ${btn ? '' : c['menu-sem-btn']}`}>
					{menuSidebar.map((val, key) => {
						return (
							<div className={`${c['box-item']}`} key={key}>
								<h3 className={`${c['titulo']}`} key={key}>{val.title}</h3>
								<div className={`${c['menu-itens']}`}>
									{val.itens && val.itens.map((val2, key2) => {
										var img;
										if(val2.custom) {
											img = require(`@_svg/${val2.icon}`);
										} else {
											img = require(`@fa/${val2.icon}`);
										}
										return (
											<IconeTextoRoot
												key={key2}
												navlink={val2.path}
												className={`${c['menu-item']}`}
												onClick={() => {
													hideSidebar({store, setStore});
												}}
												_={linkTarget(val2.path)}
											>
												<IconeTexto.Icone img={img} />
												<IconeTexto.Texto texto={val2.title} className={`${c['menu-item-texto']}`} />
											</IconeTextoRoot>
										)
									})}
								</div>
							</div>
						)
					})}
				</div>
				{btn ? (
					<div className={`${c['footer']} ${store.usuario.role === 2 ? c['footer-freelancer'] : ''}`}>
						<Btn
							_={{
								value: btn.title
							}}
							className={`${c['btn-footer']}`}
							link={{
								href: btn.path,
								target: '_blank',
								rel: 'noreferrer',
							}}
						/>
					</div>
				) : null}
			</div>
			<div className={`${c['bg-resp']} ${isHiddenSidebar(store, c['bg-resp-hide'])}`} onClick={() => toggleSidebar({store, setStore})}></div>
		</>
	);
}
