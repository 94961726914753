import { dataPorExtenso } from "@func/utils";
import React from 'react';

const DetalhesEmpresa = props => {
	const {
		stateDados,
		after,
	} = props;

	return (
		<>
			<div>
				<div>
					<strong>Nome do cliente:</strong>
					<span className="margin-l-5">{stateDados.name} <span className="color-1">(ID: {stateDados.id_empresa})</span></span>
				</div>
				<div className="sep-t margin-t-5 padding-t-5">
					<strong>Status:</strong>
					<span className="margin-l-5">{(stateDados.active ? 'Ativo' : <span className="color-red">Inativo</span>)}</span>
				</div>
				{stateDados.type !== 'F' ? (
					<div className="sep-t margin-t-5 padding-t-5">
						<strong>Razão Social:</strong>
						<span className="margin-l-5">{stateDados.social_reason}</span>
					</div>
				) : null}
				<div className="sep-t margin-t-5 padding-t-5">
					<strong>{stateDados.type !== 'F' ? 'CNPJ:' : 'CPF:'}</strong>
					<span className="margin-l-5">{stateDados.cnpj}</span>
				</div>
				<div className="sep-t margin-t-5 padding-t-5">
					<strong>Porcentagem na plataforma:</strong>
					<span className="margin-l-5">{stateDados.percentage}%</span>
				</div>
				<div className="sep-t margin-t-5 padding-t-5">
					<strong>Quantidade de clientes:</strong>
					<span className="margin-l-5">{stateDados.qty_customers}</span>
				</div>
				<div className="sep-t margin-t-5 padding-t-5">
					<strong>Tipo de cadastro:</strong>
					{stateDados.independent ? (
						<span className="margin-l-5 color-green">Independente</span>
					) : (
						<span className="margin-l-5 color-blue">Painel</span>
					)}
				</div>
				{stateDados.independent ? (
					stateDados.activate_at ? (
						<>
							<div className="sep-t margin-t-5 padding-t-5">
								<strong>Acesso ativado em:</strong>
								<span className="margin-l-5">{dataPorExtenso(stateDados.activate_at)}</span>
							</div>
							<div className="sep-t margin-t-5 padding-t-5">
								<strong>Acesso ativado por:</strong>
								<span className="margin-l-5">{stateDados.activate_by_user_name}</span>
							</div>
						</>
					) : (
						<>
							<div className="sep-t margin-t-5 padding-t-5">
								<strong>Status do acesso:</strong>
								<span className="margin-l-5">ainda não ativado</span>
							</div>
						</>
					)
				) : null}
				<div className="sep-t margin-t-5 padding-t-5">
					<strong>Endereço:</strong>
					<span className="margin-l-5">{stateDados.street}, Nº {stateDados.number} - Bairro: {stateDados.municipal_registration} / {stateDados.state}</span>
				</div>
				<div className="sep-t margin-t-5 padding-t-5">
					<strong>CEP:</strong>
					<span className="margin-l-5">{stateDados.zip_code}</span>
				</div>
				<div className="sep-t margin-t-5 padding-t-5">
					<strong>Nome do principal contato:</strong>
					<span className="margin-l-5">{stateDados.name_responsible}</span>
				</div>
				<div className="sep-t margin-t-5 padding-t-5">
					<strong>Telefone:</strong>
					<span className="margin-l-5">{stateDados.phone}</span>
				</div>
				<div className="sep-t margin-t-5 padding-t-5">
					<strong>E-mail:</strong>
					<span className="margin-l-5"><a href={`mailto:${stateDados.email_responsible}`}>{stateDados.email_responsible}</a></span>
				</div>
			</div>

			{after}
		</>

	);
};

export { DetalhesEmpresa };
