import React from 'react';

// CSS
import './Radio2.css';

const Radio2 = props => {

	const {
		wrap = '',
		label,
		labelClass = '',
		invalid,
		_ = {},
	} = props;

	return (
		<div className={`cpnt-radio2 ${wrap} ${invalid ? 'cpnt-radio2-invalido' : ''}`}>
			<label>
				<input
					type='radio'
					{..._}
				/>
				<span className="cpnt-radio2-check"></span>
				<span className={`cpnt-radio2-label ${labelClass}`}>{label}</span>
			</label>
		</div>
	)
}

export default Radio2;