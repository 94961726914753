import React from 'react';

// css
import './DadosContato.css';

// Components
import Loading from '@cpnt/Loading/Loading';
import Foto from '@cpnt/Foto/Foto';
import DadosBancarios from '@shared/components/DadosBancarios/DadosBancarios';

// Funções
import { caminhoFoto, dataApiParaBr, dataHaQuantoTempo, formatarDinheiro, formatarTelefone, validarDataAPI } from '@func/utils';

const DadosContato = props => {

	const {
		id,
		id_user,
		email,
		phone,
		city,
		price,
		state,
		title,
		name,
		photo,
		birth_date,
		name_area,
		seniority_level,
		street,
		district,
		zip_code,
		number,
		created_at,

		// States
		empresa = [],
		dadosBancarios = [],

		// Loading
		loadingGetEmpresa,
		loadingGetDadosBancarios,
	} = props;

	return (
		<div className="cpnt-dados">
			<div className="cpnt-dadoscontato-wrap">

				<div className="cpnt-dadoscontato-secao">
					<div className="cpnt-dadoscontato-info-box">
						<div className="cpnt-dadoscontato-info-img">
							<Foto
								imagem={caminhoFoto(photo).url}
								tamanho="130px"
							/>
						</div>
						<div className="cpnt-dadoscontato-info-texto">
							<h2 className="margin-b-0 margin-t-0">{name}</h2>
							<div>{title}</div>
							<div className="margin-t-10">
								<div>
									<strong>Cadastrado em: </strong>
									<span>{dataApiParaBr(created_at, 'Não informado')} {validarDataAPI(created_at) ? <span className="color-1">({dataHaQuantoTempo(created_at, 'há ')})</span> : ''}</span>
								</div>
								<div>
									<strong>Valor p/ hora: </strong>
									<span>{formatarDinheiro(price ? price : 'Não informado', 'R$ ')}</span>
								</div>
								<div>
									<strong>ID de profissional: </strong>
									<span>{id ? id : 'Não informado'}</span>
								</div>
								<div>
									<strong>ID de usuário: </strong>
									<span>{id_user ? id_user : 'Não informado'}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="cpnt-dadoscontato-secao">
					<h3 className="cpnt-dadoscontato-titulo">Contato</h3>

					<div className="margin-t-5">
						<div>
							<strong>E-mail: </strong>
							<span>{email ? email : 'Não informado'}</span>
						</div>
						<div className="sep-t padding-t-10 margin-t-10">
							<strong>Telefone: </strong>
							<span>{phone ? formatarTelefone(phone) : 'Não informado'}</span>
						</div>
						<div className="sep-t padding-t-10 margin-t-10">
							<strong>Data de nascimento: </strong>
							<span>{dataApiParaBr(birth_date, 'Não informado')} {validarDataAPI(birth_date) ? <span className="color-1">({dataHaQuantoTempo(birth_date)})</span> : ''}</span>
						</div>
						<div className="sep-t padding-t-10 margin-t-10">
							<strong>Categoria: </strong>
							<span>{name_area ? name_area : 'Não informado'} {seniority_level ? <span className="color-1">({seniority_level})</span> : ''}</span>
						</div>
					</div>
				</div>

				<div className="cpnt-dadoscontato-secao">
					<h3 className="cpnt-dadoscontato-titulo">Dados da empresa</h3>
					<div className="margin-t-5">
						{loadingGetEmpresa ? (
							<Loading alt />
						) : (
							<>
								{empresa && typeof(empresa) === 'object' ? (
									<div>
										<div>
											<strong>CNPJ: </strong>
											<span>{empresa.cnpj ? empresa.cnpj : 'Não informado'}</span>
										</div>
										<div className="sep-t padding-t-10 margin-t-10">
											<strong>Razão Social: </strong>
											<span>{empresa.razao_social ? empresa.razao_social : 'Não informado'}</span>
										</div>
										<div className="sep-t padding-t-10 margin-t-10">
											<strong>Nome Fantasia: </strong>
											<span>{empresa.fantasy_name ? empresa.fantasy_name : 'Não informado'}</span>
										</div>
										<div className="sep-t padding-t-10 margin-t-10">
											<strong>Endereço da Sede: </strong>
											<span>{empresa.address ? empresa.address : 'Não informado'}</span>
										</div>
									</div>
								) : (
									<div>Nenhuma empresa cadastrada.</div>
								)}
							</>
						)}
					</div>
				</div>

				<div className="cpnt-dadoscontato-secao">
					<h3 className="cpnt-dadoscontato-titulo">Dados bancários</h3>
					<div className="margin-t-5">
						{loadingGetDadosBancarios ? (
							<Loading alt />
						) : (
							<>
								{dadosBancarios && typeof(dadosBancarios) === 'object' ? (
									<DadosBancarios {...dadosBancarios} />
								) : (
									<div>Nenhum dado bancário cadastrado.</div>
								)}
							</>
						)}
					</div>
				</div>

				<div className="cpnt-dadoscontato-secao">
					<h3 className="cpnt-dadoscontato-titulo">Endereço</h3>
					<div className="margin-t-5">
						<div>
							<strong>Logradouro: </strong>
							<span>{street ? street : 'Não informado'}</span>
						</div>
						<div className="sep-t padding-t-10 margin-t-10">
							<strong>Número: </strong>
							<span>{number ? number : 'Não informado'}</span>
						</div>
						<div className="sep-t padding-t-10 margin-t-10">
							<strong>Bairro: </strong>
							<span>{district ? district : 'Não informado'}</span>
						</div>
						<div className="sep-t padding-t-10 margin-t-10">
							<strong>Cidade: </strong>
							<span>{city ? city : 'Não informado'}</span>
						</div>
						<div className="sep-t padding-t-10 margin-t-10">
							<strong>Estado: </strong>
							<span>{state ? state : 'Não informado'}</span>
						</div>
						<div className="sep-t padding-t-10 margin-t-10">
							<strong>CEP: </strong>
							<span>{zip_code ? zip_code : 'Não informado'}</span>
						</div>
					</div>
				</div>

			</div>

		</div>
	)
};

export default DadosContato;