import React, { useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import c from './DashboardScreen.module.scss';

export const DashboardScreen = props => {
	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
	} = props;

	return (
		<div className={`${className} ${c['container']}`}>
			<div style={{padding: '30px'}}><strong>Dashboard Admin</strong><br />em desenvolvimento...</div>
		</div>
	);
}
