import React, { useState } from 'react';

// css
import './FormJobsAdminLiteCadastro.css';

// Componentes
import Input2 from '@cpnt/Input2/Input2';
import { Select2 } from '@cpnt/Select2/Select2';
import Select2Algolia from '@cpnt/Select2Algolia/Select2Algolia';

const FormJobsAdminLiteCadastro = props => {

	const {
		onSubmit,
		after,

		stateDados = {},
		setStateDados,
		stateProjetos = [],
		loadingGetProjetos,
		stateEmpresa,
		stateTemplates,
		loadingGetTemplates,
	} = props;

	const [stateAlgolia, setStateAlgolia] = useState([]);

	return (
		<form onSubmit={onSubmit}>
			<div className="padding-b-15">
				<h3 className="titulo">{stateEmpresa.name}</h3>
				<div className="td-desc">ID da empresa: {stateEmpresa.id_empresa}</div>
			</div>
			<div className="wrap-h-5">
				<div className="flex flex-wrap">
					<div className="input-box w-100p">
						<Input2
							label="Título"
							req
							_={{
								value: stateDados.title,
								onChange: e => setStateDados({
									...stateDados,
									title: e.target.value,
								})
							}}
						/>
					</div>
					<div className="input-box w-50p box-1-680">
						<Select2Algolia
							label="Freelancer"
							req
							setStateOptions={setStateAlgolia}
							_={{
								value: stateDados.id_freelancer,
								onChange: e => {
									setStateDados({
										...stateDados,
										id_freelancer: e.value,
									})
								},
								defaultOptions: stateAlgolia,
								options: stateAlgolia,
							}}
						/>
					</div>
					<div className="input-box w-50p box-1-680">
						<Select2
							label="Template"
							tip="Você pode selecionar textos predefinidos para enviar ao profissional, ou pode escrever seu próprio texto no campo de mensagem."
							_={{
								options: stateTemplates,
								value: stateDados.id_template,
								onChange: e => {
									setStateDados({
										...stateDados,
										id_template: e ? e.value : null,
										...(e ? {message: e.text} : {message: ''})
									});
								},
								isClearable: true,
							}}
							loading={loadingGetTemplates}
						/>
					</div>
					<div className="input-box w-100p">
						<Input2
							label="Mensagem"
							req
							_={{
								type: 'textarea',
								value: stateDados.message,
								onChange: e => setStateDados({
									...stateDados,
									message: e.target.value,
								})
							}}
						/>
					</div>
				</div>
			</div>
			{after}
		</form>
	);
};

export { FormJobsAdminLiteCadastro };
