import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// css
import './DetalhesProjeto.css';

// Componentes
import AlterarPorcentagem from '@cpnt/AlterarPorcentagem/AlterarPorcentagem';
import AlterarStatusProjeto from '@cpnt/AlterarStatusProjeto/AlterarStatusProjeto';
import Button2 from '@cpnt/Button2/Button2';
import Disponibilidade from '@cpnt/Disponibilidade/Disponibilidade';
import FotosAgrupadas from '@cpnt/FotosAgrupadas/FotosAgrupadas';
import LinhaItem from '@cpnt/LinhaItem/LinhaItem';
import Loading from '@cpnt/Loading/Loading';
import PorcentagemCirculo from '@cpnt/PorcentagemCirculo/PorcentagemCirculo';
import Portal from '@cpnt/Portal/Portal';
import Status from '@cpnt/Status/Status';

// Partes
import DetalhesAnexos from '../DetalhesAnexos/DetalhesAnexos';
import DetalhesEquipe from '../DetalhesEquipe/DetalhesEquipe';
import DetalhesSolucoes from '../DetalhesSolucoes/DetalhesSolucoes';

// Funções
import { caminhoFoto, dataApiParaBr, formatarDinheiro, htmlToJsx, sanitize } from '@func/utils';
import { nomeStatus } from '@shared/functions/components';
import { fotosEquipeCompleta } from '../functionsProjetos';
import { closePortal, getPortalDados, gets, isShowPortal, showPortal } from '@route/routeUtils';
import PortalProjetosEquipe from '@portal/PortaisProjetos/PortalProjetosEquipe/PortalProjetosEquipe';
import PortalProjetosSolucoes from '@portal/PortaisProjetos/PortalProjetosSolucoes/PortalProjetosSolucoes';
import PortalProjetosAnexos from '@portal/PortaisProjetos/PortalProjetosAnexos/PortalProjetosAnexos';

const DetalhesProjeto = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		className = '',
		wrap = '',
		after,

		stateProjeto = {},
		layout = 'edicao',
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	/*
	** Portal
	** ================================================== */
	const [showPortalEquipeVisualizacao, setShowPortalEquipeVisualizacao] = useState(false);
	const [showPortalSolucoesVisualizacao, setShowPortalSolucoesVisualizacao] = useState(false);
	const [showPortalAnexosVisualizacao, setShowPortalAnexosVisualizacao] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateEquipeVisualizacao, setStateEquipeVisualizacao] = useState([]);
	const [stateSolucoesVisualizacao, setStateSolucoesVisualizacao] = useState([]);
	const [stateAnexosVisualizacao, setStateAnexosVisualizacao] = useState([]);

	return (
		<div className={`cpnt-detalhesprojeto ${wrap}`}>
			<div className={`cpnt-detalhesprojeto-box ${className}`}>

				<h3 className="margin-v-0 inline-flex flex-center-v">
					{stateProjeto.restricted === 1 && <Disponibilidade textoIndisponivel="Restrito" min status={0} className="margin-r-7 margin-b--3" />}
					<span>{stateProjeto.name} <span className="color-1">(ID: {stateProjeto.id})</span></span>
				</h3>
				<div className="fs-16 color-1 margin-t-2">{stateProjeto.client_name}</div>

				<div className="cpnt-detalhesprojeto-info margin-t-30">
					<div className="cpnt-detalhesprojeto-info-wrap">
						<div className="cpnt-detalhesprojeto-info-1">
							<h3 className="margin-b-10 margin-t-0">Dados do projeto</h3>
							<div>
								{/* <LinhaItem titulo="Produto" desc={stateProjeto.product_type_name} alt /> */}
								<LinhaItem titulo="Cenário" desc={stateProjeto.scenery_type_name} alt />
							</div>
						</div>
						<div className="cpnt-detalhesprojeto-info-2">
							<h3 className="margin-b-10 margin-t-0">Rentabilidade</h3>
							<div>
								<LinhaItem titulo="Receita total prevista" desc={`R$ ${formatarDinheiro(stateProjeto.expected_total_cost)}`} alt />
								<LinhaItem titulo="Custo previsto" desc={`R$ ${formatarDinheiro(stateProjeto.total_revenue)}`} alt />
								<LinhaItem titulo="Budget mensal" desc={`R$ ${formatarDinheiro(stateProjeto.monthly_budget)}`} alt />
							</div>
						</div>
					</div>
					<div className="cpnt-detalhesprojeto-porcentagem-wrap">
						<div className="cpnt-detalhesprojeto-porcentagem relative">
							<PorcentagemCirculo
								porcentagem={stateProjeto.percentage_completed}
								alt
							/>
							<span className="margin-l-5">Projeto {nomeStatus(stateProjeto.status).toLowerCase()}</span>
						</div>
					</div>
				</div>

				<div>
					<h3 className="margin-t-0 margin-b-10">Prazo</h3>
					<div className="flex">
						<LinhaItem titulo="Início" desc={dataApiParaBr(stateProjeto.start_date)} alt className="margin-r-25" />
						<LinhaItem titulo="Fim" desc={dataApiParaBr(stateProjeto.end_date)} alt />
					</div>
				</div>

				<div className="margin-t-30">
					<h3 className="margin-b-10 margin-t-0">Descrição</h3>
					<div className="cpnt-detalhesprojeto-deschtml box-txt scroll scroll-alt">{stateProjeto.description && stateProjeto.description !== '' ? htmlToJsx(stateProjeto.description) : 'Nenhuma descrição disponível.'}</div>
				</div>

				<div className="cpnt-detalhesprojeto-fotos">
					<div className="cpnt-detalhesprojeto-fotos-equipe">
						<h3 className="margin-b-10 margin-t-0">Equipe</h3>
						{fotosEquipeCompleta({
							dados: stateProjeto,
							freelancers: false,
							onClick: () => setShowPortalEquipeVisualizacao(true),
						})}
					</div>
					<div className="cpnt-detalhesprojeto-fotos-freelancers">
						<h3 className="margin-b-10 margin-t-0">Freelancers</h3>
						<div>
							{fotosEquipeCompleta({
								dados: stateProjeto,
								usuarios: false,
								onClick: () => setShowPortalEquipeVisualizacao(true),
							})}
						</div>
					</div>
					{/* <div className="cpnt-detalhesprojeto-fotos-solucoes">
						<h3 className="margin-b-10 margin-t-0">Soluções</h3>
						<div>
							{stateProjeto.solutions && stateProjeto.solutions.length ? (
								<FotosAgrupadas
									fotos={stateProjeto.solutions.map(value2 => {
										return {
											foto: value2.img_solution,
											desc: value2.name_solution,
										}
									})}
									onClick={() => setShowPortalSolucoesVisualizacao(true)}
									circulo={false}
									placeholder={false}
									className="cursor-pointer"
									alt={true}
									espacoAlt={1}
								/>
							) : (
								<div className="color-1">Nenhuma solução selecionada.</div>
							)}
						</div>
					</div> */}
					<div className="cpnt-detalhesprojeto-fotos-solucoes">
						<h3 className="margin-b-10 margin-t-0">Anexos</h3>
						<div>
							{stateProjeto.attachs && stateProjeto.attachs.length ? (
								<div
									onClick={() => setShowPortalAnexosVisualizacao(true)}
									className="link inline-flex"
								>
									{stateProjeto.attachs.length} anexo{stateProjeto.attachs.length > 1 ? 's' : ''}
								</div>
							) : (
								<div className="color-1">Nenhum anexo adicionado.</div>
							)}
						</div>
					</div>
				</div>
				{after}
			</div>

			<PortalProjetosEquipe
				id={stateProjeto.id}
				show={showPortalEquipeVisualizacao}
				onClose={() => setShowPortalEquipeVisualizacao(false)}
			/>

			<PortalProjetosSolucoes
				id={stateProjeto.id}
				show={showPortalSolucoesVisualizacao}
				onClose={() => setShowPortalSolucoesVisualizacao(false)}
			/>

			<PortalProjetosAnexos
				id={stateProjeto.id}
				show={showPortalAnexosVisualizacao}
				onClose={() => setShowPortalAnexosVisualizacao(false)}
			/>

		</div>
	);
};

export default DetalhesProjeto;