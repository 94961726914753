import React from 'react';
import { Link } from 'react-router-dom';

// css
import './BtnAdd.css';

// Imagens
import imgIconeAdd from '@svg/regular/square-plus.svg';
import Icone from '../Icone/Icone';

const BtnAdd = props => {
	const {
		titulo,
		className = '',
		onClick,
		link,
		img = true,
		reverse,
		pos,
	} = props;

	var imagem = imgIconeAdd;
	if(img === true) {
		imagem = imgIconeAdd;
	} else if(img) {
		imagem = img;
	}

	if(link) {
		return (
			<Link to={link} className={`cpnt-btnadd-wrap ${className} ${pos ? 'cpnt-btnadd-pos' : ''}`}>
				<div className={`cpnt-btnadd-inner ${reverse ? 'cpnt-btnadd-inner-reverse' : ''}`} onClick={onClick}>
					<Icone
						imagem={imagem}
						cor="#0277BD"
						wrap="cpnt-btnadd-icone-box"
						tamanho="20px"
					/>
					<div className="cpnt-btnadd-titulo">{titulo}</div>
				</div>
			</Link>
		);
	}

	return (
		<div className={`cpnt-btnadd-wrap ${className} ${pos ? 'cpnt-btnadd-pos' : ''}`}>
			<div className={`cpnt-btnadd-inner ${reverse ? 'cpnt-btnadd-inner-reverse' : ''}`} onClick={onClick}>
				{img ? (
					<Icone
						imagem={imagem}
						cor="#0277BD"
						wrap="cpnt-btnadd-icone-box"
						tamanho="20px"
					/>
				) : null}
				<div className="cpnt-btnadd-titulo">{titulo}</div>
			</div>
		</div>
	);
};

export { BtnAdd };