import React from 'react';

// CSS
import { Icone } from "@_cpnt/Icone/Icone";
import c from './Btn.module.scss';

import imgChevronLeft from '@_img/default/chev-left.svg';
import imgChevronRight from '@_img/default/chev-right.svg';
import { Loading } from "@_cpnt/Loading/Loading";

export const Btn = props => {
	const {
		className = '',
		classNameDefault = 'hover-wrap',
		icone = true,
		onClick,
		loading,
		tipo = 'default',
		link,
		size = 'default',
		_,
	} = props;

	var {
		cor = 'var(--color-1)',
	} = props;

	if(tipo !== 'default') {
		var corTipos = {
			error: 'var(--color-white)'
		};

		if(corTipos[tipo]) {
			cor = corTipos[tipo];
		}
	}

	const Inner = () => {
		return (
			<>
				<Loading abs show={loading} />
				<IconeLateral img={imgChevronRight} show={icone} pos="left" cor={cor} />
				<div className={`${c['texto']}`}>{_?.value}</div>
				<IconeLateral img={imgChevronLeft} show={icone} pos="right" cor={cor} />
			</>
		)
	}

	if(link) {
		return (
			<a className={`${className} ${c[tipo]} ${c[size]} ${c['container']} ${classNameDefault}`} {...link} {...(cor ? {style: {color: cor}} : {})}>
				<Inner />
			</a>
		)
	}

	return (
		<button className={`${className} ${c[tipo]} ${c[size]} ${c['container']} ${classNameDefault}`} onClick={onClick} {..._} {...(cor ? {style: {color: cor}} : {})}>
			<Inner />
		</button>
	);
}

const IconeLateral = props => {
	const {
		show,
		img = imgChevronLeft,
		cor,
	} = props;

	if(!show) return <></>;

	return <Icone img={img} cor={cor} />
}
