import qs from 'qs';
import { converterNumerosParaNumeric, isObjDiferente, removerKeysVazias } from '@func/utils';
import _ from 'lodash';
import { isNumeric } from "@_func/regex";

/*
** Verifica se existe um get de acordo com o parametro
** ================================================== */
export const get = (parametroGet) => {
	var param = qs.parse(window.location.search.slice(1));
	if(param[parametroGet] !== undefined) {
		return param[parametroGet];
	}
	return false;
}

/*
** Pega os gets em formato de OBJ
** ================================================== */
export const gets = (queryStringGet = window.location.search.slice(1)) => {
	var param = qs.parse(queryStringGet);
	return param;
}

/*
** Gets sem portais específicos
** ================================================== */
export const getsSemPortais = (dadosGet, portaisIdArr = []) => {
	var retorno = dadosGet;
	if(dadosGet && dadosGet.overlay) {
		retorno.overlay = _.filter(dadosGet.overlay, e => {
			return !portaisIdArr.includes(e.portal);
		});
	}
	return retorno;
}

/*
** Pega os gets em formato de OBJ
** ================================================== */
export const getsLocation = (location = {search: ''}) => {
	var param = qs.parse(location.search.slice(1));
	return param;
}

/*
** Verifica se é necessário fazer o get novamente
**
** Alguns componentes com URL não precisam disparar o get
** como por exemplo um overlay
** ================================================== */
export const checkTriggerGet = stateLocation => {
	var winGet = window.location.search.slice(1);

	var antigoGetObj = qs.parse(stateLocation);
	var novoGetObj = qs.parse(winGet);

	var antigoGetObjLimpo = removerKeysVazias(antigoGetObj)
	var novoGetObjLimpo = removerKeysVazias(novoGetObj);

	var antigoGetObjSemKeyOverlay = {...antigoGetObjLimpo};
	var novoGetObjSemKeyOverlay = {...novoGetObjLimpo};

	// Overlay não precisa disparar o get padrão novamente
	delete antigoGetObjSemKeyOverlay.overlay;
	delete novoGetObjSemKeyOverlay.overlay;

	var novoGetDiferenteDoAntigo = !_.isEqual(novoGetObjSemKeyOverlay, antigoGetObjSemKeyOverlay);

	if(stateLocation === false && winGet === '') {
		novoGetDiferenteDoAntigo = true;
	}

	return novoGetDiferenteDoAntigo;
}

/*
** Verifica o que é preciso executar na função init
** Caso a alteração seja na chave overlay, não executa nada
** ================================================== */
export const initFuncExec = (dadosArr, init, location, stateLocation) => {

	var winGet = location.search.slice(1);

	var antigoGetObj = qs.parse(stateLocation);
	var novoGetObj = qs.parse(winGet);

	var antigoLimpo = removerKeysVazias(antigoGetObj);
	var novoLimpo = removerKeysVazias(novoGetObj);

	var diferente = isObjDiferente(antigoLimpo, novoLimpo, false);

	if(diferente) {
		var diferenteObj = Object.keys(diferente);
		if(diferenteObj.length === 1 && diferenteObj[0] === 'overlay') {
			return;
		}
	}

	dadosArr.map((val, key) => {
		if(val.child) {
			if(val.child.condicao || val.child.condicao === undefined) {
				if((val.child.init === true && !init) || !val.child.init) {
					val.child.func.map((val2, key2) => {
						if(val2.condicao || val2.condicao === undefined) {
							if((val2.init === true && !init) || !val2.init) {
								val2.func(val2.param);
								if(val2.cb) {
									val2.cb(val2.cbParam);
								}
							}
						}
					});
					if(val.child.cb) {
						val.child.cb(val.child.cbParam);
					}
				}
			}
		} else {
			if(val.condicao || val.condicao === undefined) { // se não passar nada, executa por padrão
				if((val.init === true && !init) || !val.init) {
					val.func(val.param);
					if(val.cb) {
						val.cb(val.cbParam);
					}
				}
			}
		}
	});
}

/*
** Limpar dados get antes de enviar para a rota da API
** ================================================== */
export const clearGet = (dados) => {
	var dadosEnviar = removerKeysVazias(dados);
	dadosEnviar = converterNumerosParaNumeric(dadosEnviar);
	if(dadosEnviar.page) {
		dadosEnviar.page = dadosEnviar.page - 1;
	}
	delete dadosEnviar.overlay;
	return dadosEnviar;
}

/*
** Define os gets de acordo com a URL, transforma em
** states e define os campos com os valores corretos
** ================================================== */
export const setGet = (dadosObj = {}) => {
	const {
		getsDefault = {},
		setStateGet = () => null,
		location = {search: ''},
		stateLocation = '',
		setStateLocation = () => null,
		funcExec = [],
		init,
		defaults = {},
	} = dadosObj;

	const getsObj = qs.parse(location.search.slice(1));

	const objCompleto = {
		...getsDefault,
		...getsObj,
	};

	var objComDefaults = objCompleto;

	// Aplica os valores padrões apenas ao carregar a tela, e apenas se não tiver gets
	if(!init && !Object.keys(getsObj).length) {
		objComDefaults = {
			...objCompleto,
			...defaults,
		}
	}

	setStateLocation(qs.stringify(objComDefaults));
	setStateGet(objComDefaults);

	// Verifica se é diferente da anterior e se não é overlay
	// Se true, executa as funções. Também pode passar array
	if(checkTriggerGet(stateLocation)) {
		if(funcExec && typeof(funcExec) === 'function') {
			funcExec(objComDefaults, location.state);
		} else {
			funcExec.map((val, key) => {
				val.func({
					...val.param,
					dados: {
						...objComDefaults,
						...val.param.dados,
					},
					state: location.state,
				})
			});
		}
	}

	// Exemplo de array

	// setGet({
	// 	getsDefault: getsDefault,
	// 	setStateGet: setStateGet,
	// 	stateLocation: stateLocation,
	// 	setStateLocation: setStateLocation,
	// 	funcExec: [
	// 		{
	// 			func: getProjetos,
	// 			param: {
	// 				loading: loadingGetProjeto,
	// 				setLoading: setLoadingGetProjeto,
	// 				setDados: setStateProjetos,
	// 				setDadosMetadata: setStateProjetosMetadata,
	// 			}
	// 		},
	// 	]
	// });
}

/*
** Define os gets na URL e navega até a página com os gets
** ================================================== */
export const setGetNavigate = (dadosObj = {}) => {
	const {
		gets = {},
		stateGet = {},
		resetGetPage = true,
		forceCamposVazios = {},
		navigate = () => null,
		uniq,
		state,
	} = dadosObj;

	const dadosEnviarObj = {
		...stateGet,
		...gets,
	}

	// Alguns gets não precisam resetar a página
	// Como no caso do overlay
	if(resetGetPage) {
		delete dadosEnviarObj.page;
	}

	var dadosEnviar = removerKeysVazias(dadosEnviarObj);

	/*
	** Alguns campos precisam enviar mesmo vazio ou zero
	** Como no caso do filtro por status 0
	** ================================================== */
	if(forceCamposVazios && typeof(forceCamposVazios) && Object.keys(forceCamposVazios).length > 0) {
		var camposVazios = {};
		Object.keys(forceCamposVazios).map((val, key) => {
			if(isNumeric(forceCamposVazios[val]) || forceCamposVazios[val]) {
				camposVazios[val] = forceCamposVazios[val];
			}
		});
	}

	dadosEnviar = {
		...dadosEnviar,
		...camposVazios
	}

	if(uniq) {
		dadosEnviar = removerKeysVazias(gets);
	}

	const dadosGetString = qs.stringify(dadosEnviar);

	navigate(`?${dadosGetString}`, {state: state});
}

/*
** PORTAL
** ================================================== */

/*
** Verifica se é preciso exibir o portal por ID
** ================================================== */
export const getPortalDados = (dadosObj = {}, retornoDefault = {}) => {
	const {
		portal,
		stateGet = {},
	} = dadosObj;

	var retorno = retornoDefault;

	var overlayExistente = _.find(stateGet.overlay, {portal: portal});
	if(overlayExistente && Object.keys(overlayExistente).length > 1) {
		if(typeof(overlayExistente) === 'object') {
			retorno = overlayExistente.dados;
			if(!isNumeric(retorno)) {
				if(!retorno) {
					retorno = retornoDefault;
				}
			} else {
				retorno = Number(retorno);
			}
		}
	}
	return retorno;
}

/*
** Verifica se é preciso exibir o portal por ID
** ================================================== */
export const isShowPortal = (dadosObj = {}) => {
	const {
		portal,
		stateGet = {},
	} = dadosObj;

	var overlayExistente = _.find(stateGet.overlay, {portal: portal});
	if(overlayExistente && typeof(overlayExistente) === 'object' && Object.keys(overlayExistente).length > 0) {
		return true;
	}
	return false;
}

/*
** Exibe o portal
** ================================================== */
export const showPortal = (dadosObj = {}) => {
	const {
		portal,
		dados,
		stateGet = {},
		navigate = () => null,
		remover,
	} = dadosObj;

	var overlay = stateGet.overlay;
	if(!overlay) {
		overlay = [];
	} else {
		overlay = stateGet.overlay;
	}
	overlay.push({
		portal: portal,
		dados: dados,
	});

	if(remover) {
		if(typeof(remover) === 'object') {
			// Fazer array
		} else if(typeof(remover) === 'string') {
			overlay = overlay.filter(item => item.portal !== remover);
		}
	}

	setGetNavigate({
		gets: {overlay: overlay},
		stateGet,
		resetGetPage: false,
		navigate,
	});
}

/*
** Fecha o portal
** ================================================== */
export const closePortal = (dadosObj = {}) => {
	const {
		portal,
		stateGet = {},
		navigate = () => null,
	} = dadosObj;

	var retorno = [];

	if(typeof(portal) === 'string') {
		if(stateGet.overlay && typeof(stateGet.overlay) === 'object') {
			retorno = stateGet.overlay.filter(item => item.portal !== portal);
		}
	} else if(portal && typeof(portal) === 'object' && portal.length) {
		retorno = _.filter(stateGet.overlay, e => {
			return !portal.includes(e.portal);
		});
	}

	setGetNavigate({
		gets: {overlay: retorno},
		resetGetPage: false,
		navigate,
		stateGet,
	});
}
