import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiBriefingCompetences = {
	insertMany: {
		post: async (dados) => {
			return await api.post(`/briefingCompetences/insertMany`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getAll: {
		get: async idBriefing => {
			return await api.get(`/briefingCompetences/getAll/${idBriefing}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiBriefingCompetences;