import React from 'react';
import _ from 'lodash';
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";
import { PortalPerfilFreelancer } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilFreelancer";
import { PortalListaLocalFormCadastro } from "@cpnt/Portais/PortaisProfissionais/PortalListaLocalFormCadastro/PortalListaLocalFormCadastro";
import { PortalListaLocalFormEdicao } from "@cpnt/Portais/PortaisProfissionais/PortalListaLocalFormEdicao/PortalListaLocalFormEdicao";
import { PortalListaLocalProfissionaisSelecionados } from "@cpnt/Portais/PortaisProfissionais/PortalListaLocalProfissionaisSelecionados/PortalListaLocalProfissionaisSelecionados";
import { PortalProfissionaisSelecionados } from "@cpnt/Portais/PortaisProfissionais/PortalProfissionaisSelecionados/PortalProfissionaisSelecionados";
import Portal from "@cpnt/Portal/Portal";
import { apagarListaLocal, limparListaLocal, removerTodasAsListasLocal } from "@func/selecaoUsuariosBriefings";
import { caminhoFoto, dataApiParaBr, formatarDinheiro, joinArray, msg, unificarArr } from "@func/utils";
import { closePortal, getPortalDados, isShowPortal, showPortal } from "@route/routeUtils";
import imgUserPic from '@img/userpic.png';
import { enderecoAlgolia } from "../funcListaProfissionais";
import PortalListaFreelancers from "@cpnt/Portais/PortaisFreelancers/PortalListaFreelancers/PortalListaFreelancers";
import { formatarAlgoliaParaListaProfissional, formatarGetParaListaProfissional } from "@page/Logado/CadastrarProcessoSeletivo/funcCadastrarProcessoSeletivo";


/*
** Trata os dados antes de exibir no componente
** ================================================== */
export const tratarDadosAlgolia = props => {
	const {
		val,
		stateProfissionaisSelecionados,
	} = props;

	var checked = false;
	if(stateProfissionaisSelecionados.length) {
		var freelancerSelecionado = _.find(stateProfissionaisSelecionados, {id: val.Id});
		if(freelancerSelecionado) {
			checked = true;
		}
	}

	var idiomasExibicaoArr = [];

	if(val.Languages && typeof(val.Languages) === 'object' && val.Languages.length) {
		idiomasExibicaoArr = val.Languages.map(item => `${item.language}${item.level ? ` (${item.level})` : ''}`);
	}

	if(val.Title === 'null') {
		val.Title = '';
	}

	var desc = '';
	if(val._highlightResult && val._highlightResult.Description && val._highlightResult.Description !== 'null') {
		desc = val._highlightResult.Description.value;
	} else if(val.Description !== 'null') {
		desc = val.Description;
	}
	desc = desc.replace("null\n\n-----\n\n", '');

desc = desc.replace(`null

-----
`, '');

	if(desc === 'null') {
		desc = '';
	}

	if(desc === '') {
		desc = '(nenhuma descrição adicionada)';
	}

	val.Objectives = val.Objectives ? val.Objectives: [];
	val.Ratings = val.Ratings ? val.Ratings: [];
	val.Social = val.Social ? val.Social: [];

	var retorno = {
		_: val,
		id: val.Id,
		idUser: val.UserId,
		nome: val.Name,
		titulo: val.Title && val.Title !== 'null' ? val.Title : '',
		descricao: desc,
		nomeDestaque: (val._highlightResult && val._highlightResult.Name) ? val._highlightResult.Name.value : val.Name,
		tituloDestaque: (val._highlightResult && val._highlightResult.Title && val._highlightResult.Title && val._highlightResult.Title.value && val._highlightResult.Title.value !== 'null') ? val._highlightResult.Title.value : (val.Title && val.Title !== 'null' ? val.Title : ''),
		foto: val.Photo && val.Photo.length > 0 ? caminhoFoto(val.Photo).url : imgUserPic,
		valorHora: formatarDinheiro(val.Price, 'R$ '),
		habilidades: val.Skills,
		habilidadesExibicao: val.Skills.length ? val.Skills.map(item => item.habilidade).join(' – ') : 'Nenhuma habilidade informada',
		disponibilidade: val.Active,
		segmento: val.Area ? val.Area : undefined,
		email: val.Email,
		emailDestaque: (val._highlightResult && val._highlightResult.Email) ? val._highlightResult.Email.value : val.Email,
		dataCadastro: dataApiParaBr(val.Created_at),
		perfilFinalizado: val.Finish,
		telefone: val.Phone,
		objetivos: val.Objectives,
		objetivosExibicao: val.Objectives.length ? joinArray(val.Objectives) : 'Nenhum objetivo informado',
		endereco: enderecoAlgolia(val),
		idiomas: val.Languages,
		idiomasExibicao: idiomasExibicaoArr.length ? joinArray(idiomasExibicaoArr) : 'Nenhum idioma informado',
		redesSociais: val.Social,
		portfolios: val.Portfolio,
		senioridade: val.Seniority,
		avaliacoes: val.Ratings,
		ocultarSelecao: false,
		checked: checked,
	};

	return retorno;
};

/*
** Ação ao clicar na lista local
** ================================================== */
export const onClickSelectListaLocal = props => {
	const {
		dados = {},
		stateGet,
		navigate,
	} = props;

	if(dados.value === 'visualizarListas') {
		showPortal({portal: 'listas-salvas-local', stateGet, navigate})
	}

	else if(dados.value === 'visualizarPerfis') {
		showPortal({portal: 'profissionais-selecionados', stateGet, navigate})
	}

	else if(dados.value === 'novaLista') {
		showPortal({portal: 'cadastrar-lista-local', stateGet, navigate})
	}

	else if(dados.value === 'limparLista') {
		showPortal({portal: 'confirmacao-limpar-lista', stateGet, navigate})
	}

	else if(dados.value === 'editarLista') {
		showPortal({portal: 'editar-lista-local', stateGet, navigate})
	}

	else if(dados.value === 'removerLista') {
		showPortal({portal: 'confirmacao-apagar-lista', stateGet, navigate})
	}

	else if(dados.value === 'removerTodasAsListas') {
		showPortal({portal: 'confirmacao-apagar-todas-listas', stateGet, navigate})
	}

	else {
		msg.error('Ação não identificada');
	}
}

/*
** Clicar nas ações
** ================================================== */
export const onClickAcoes = props => {
	const {
		acao,
		selecionarTodos,
		desselecionarTodos,
		stateListaAtual,
		stateProfissionais,
		setStateSelecionados,
		showPortal,
		stateGet,
		navigate,
	} = props;
	if(acao === 'selecionarTodos') {
		selecionarTodos({
			stateListaAtual,
			stateProfissionais,
			setStateSelecionados,
		});
	}

	else if(acao === 'desselecionarTodos') {
		desselecionarTodos({
			stateListaAtual,
			stateProfissionais,
			setStateSelecionados,
		});
	}

	else if(acao === 'limparLista') {
		showPortal({portal: 'confirmacao-limpar-lista', stateGet, navigate})
	}

	else {
		msg.error('Ação não identificada');
	}
}

/*
** Popup de limpar a lista salva local
** ================================================== */
export const onClickLimparListaSalva = props => {
	const {
		stateGet,
		navigate,
	} = props;

	showPortal({portal: 'confirmacao-limpar-lista-salva', stateGet, navigate});
}

/*
** Exibe todos os profissionais selecionados e salvos localmente
** ================================================== */
export const onClickExibirTodosSalvoELocal = props => {
	const {
		stateGet,
		navigate,
	} = props;

	showPortal({portal: 'lista-unificada', stateGet, navigate});
}

/*
** Exibe todos os profissionais salvos anteriormente
** ================================================== */
export const onClickExibirTodosSalvos = props => {
	const {
		stateGet,
		navigate,
	} = props;

	showPortal({portal: 'lista-anterior', stateGet, navigate});
}

export const ListaProfissionaisAcoes = props => {
	const {
		stateGet,
		navigate,

		stateListaAtual,
		setStateListaAtual,
		stateListas,
		setStateListas,
		stateSelecionados,
		setStateSelecionados,
		setStateFreelancersSalvos,
		stateSelecionadosAnteriormente,
	} = props;

	return (
		<>
			<PortalPerfilFreelancer
				id={getPortalDados({portal: 'perfil-freelancer', stateGet})}
				show={isShowPortal({portal: 'perfil-freelancer', stateGet})}
				onClose={() => closePortal({portal: 'perfil-freelancer', stateGet, navigate})}
			/>

			<PortalPerfilFreelancer
				id={getPortalDados({portal: 'profissional-perfil-freelancer', stateGet})}
				show={isShowPortal({portal: 'profissional-perfil-freelancer', stateGet})}
				onClose={() => closePortal({portal: 'profissional-perfil-freelancer', stateGet, navigate})}
			/>

			<PortalListaFreelancers
				lista={unificarArr([stateSelecionadosAnteriormente, formatarAlgoliaParaListaProfissional(stateSelecionados)])}
				show={isShowPortal({portal: 'lista-unificada', stateGet})}
				onClose={() => closePortal({portal: 'lista-unificada', stateGet, navigate})}
				before={
					<h3 className="margin-0">Essa lista inclui todos os profissionais da lista local e salvos anteriormente</h3>
				}
				portalId="lista-unificada-profissional-selecionado-perfil"
			/>

			<PortalListaFreelancers
				lista={stateSelecionadosAnteriormente}
				show={isShowPortal({portal: 'lista-anterior', stateGet})}
				onClose={() => closePortal({portal: 'lista-anterior', stateGet, navigate})}
				before={
					<h3 className="margin-0">Todos os profissionais salvos na edição anterior</h3>
				}
				portalId="lista-salva-profissional-selecionado-perfil"
			/>

			<PortalListaLocalProfissionaisSelecionados
				show={isShowPortal({portal: 'listas-salvas-local', stateGet})}
				onClose={() => closePortal({portal: 'listas-salvas-local', stateGet, navigate})}

				stateListaAtual={stateListaAtual}
				setStateListaAtual={setStateListaAtual}
				stateListas={stateListas}
				setStateListas={setStateListas}
				stateSelecionados={stateSelecionados}
				setStateSelecionados={setStateSelecionados}
			/>

			<PortalProfissionaisSelecionados
				show={isShowPortal({portal: 'profissionais-selecionados', stateGet})}
				onClose={() => closePortal({portal: 'profissionais-selecionados', stateGet, navigate})}

				stateListaAtual={stateListaAtual}
				setStateListaAtual={setStateListaAtual}
				stateListas={stateListas}
				setStateListas={setStateListas}
				stateSelecionados={stateSelecionados}
				setStateSelecionados={setStateSelecionados}
			/>

			<PortalListaLocalFormCadastro
				show={isShowPortal({portal: 'cadastrar-lista-local', stateGet})}
				onClose={() => closePortal({portal: 'cadastrar-lista-local', stateGet, navigate})}
				onSuccess={() => {
					closePortal({portal: 'cadastrar-lista-local', stateGet, navigate});
				}}

				stateListaAtual={stateListaAtual}
				setStateListaAtual={setStateListaAtual}
				stateListas={stateListas}
				setStateListas={setStateListas}
				stateSelecionados={stateSelecionados}
				setStateSelecionados={setStateSelecionados}
			/>

			<PortalListaLocalFormEdicao
				show={isShowPortal({portal: 'editar-lista-local', stateGet})}
				onClose={() => closePortal({portal: 'editar-lista-local', stateGet, navigate})}
				onSuccess={() => {
					closePortal({portal: 'editar-lista-local', stateGet, navigate});
				}}

				stateListaAtual={stateListaAtual}
				setStateListaAtual={setStateListaAtual}
				stateListas={stateListas}
				setStateListas={setStateListas}
				stateSelecionados={stateSelecionados}
				setStateSelecionados={setStateSelecionados}
			/>

			<PortalConfirmacao
				show={isShowPortal({portal: 'confirmacao-limpar-lista', stateGet})}
				titulo="Deseja realmente limpar essa lista?"
				desc={<>Todos os dados salvos na lista <strong>{stateListaAtual}</strong> serão removidos. Essa ação não poderá ser desfeita.</>}
				id={getPortalDados({portal: 'confirmacao-limpar-lista', stateGet})}
				onClose={() => closePortal({portal: 'confirmacao-limpar-lista', stateGet, navigate})}
				onClickCancelar={() => closePortal({portal: 'confirmacao-limpar-lista', stateGet, navigate})}
				onClickConfirmar={() => {
					limparListaLocal({
						stateListaAtual,
						setStateSelecionados,
					});
					closePortal({portal: 'confirmacao-limpar-lista', stateGet, navigate});
				}}
			/>

			<PortalConfirmacao
				show={isShowPortal({portal: 'confirmacao-apagar-lista', stateGet})}
				titulo="Deseja realmente apagar essa lista?"
				desc={<>A lista <strong>{stateListaAtual}</strong> será removida. Todos os dados salvos nessa lista também serão removidos. Essa ação não poderá ser desfeita.</>}
				id={getPortalDados({portal: 'confirmacao-apagar-lista', stateGet})}
				onClose={() => closePortal({portal: 'confirmacao-apagar-lista', stateGet, navigate})}
				onClickCancelar={() => closePortal({portal: 'confirmacao-apagar-lista', stateGet, navigate})}
				onClickConfirmar={() => {
					if(stateListaAtual === 'Lista padrão') {
						msg.error('Não é possível apagar a lista padrão. Se necessário, você poderá limpar os dados dessa lista clicando em "Limpar lista".');
					} else {
						apagarListaLocal({
							stateListaAtual,
							setStateListas,
							setStateListaAtual,
							setStateSelecionados,
						});
						closePortal({portal: 'confirmacao-apagar-lista', stateGet, navigate});
					}
				}}
			/>

			<PortalConfirmacao
				show={isShowPortal({portal: 'confirmacao-apagar-todas-listas', stateGet})}
				titulo="Deseja realmente apagar todas as listas?"
				desc={<>Todos os dados salvos de <strong className="color-red">todas</strong> as listas serão removidos. Essa ação não poderá ser desfeita.</>}
				id={getPortalDados({portal: 'confirmacao-apagar-todas-listas', stateGet})}
				onClose={() => closePortal({portal: 'confirmacao-apagar-todas-listas', stateGet, navigate})}
				onClickCancelar={() => closePortal({portal: 'confirmacao-apagar-todas-listas', stateGet, navigate})}
				onClickConfirmar={() => {
					removerTodasAsListasLocal({
						setStateListas,
						setStateListaAtual,
					});
					closePortal({portal: 'confirmacao-apagar-todas-listas', stateGet, navigate});
				}}
			/>

			<PortalConfirmacao
				show={isShowPortal({portal: 'confirmacao-limpar-lista-salva', stateGet})}
				titulo="Deseja realmente remover todos da lista?"
				desc={
					<>
						<div>Todos os profissionais que foram salvos anteriormente serão removidos dessa lista.</div>
						<div className="margin-t-10">Para que a remoção seja definitiva, é necessário salvar os dados após remover.</div>
					</>
				}
				id={getPortalDados({portal: 'confirmacao-limpar-lista-salva', stateGet})}
				onClose={() => closePortal({portal: 'confirmacao-limpar-lista-salva', stateGet, navigate})}
				onClickCancelar={() => closePortal({portal: 'confirmacao-limpar-lista-salva', stateGet, navigate})}
				onClickConfirmar={() => {
					setStateFreelancersSalvos([]);
					closePortal({portal: 'confirmacao-limpar-lista-salva', stateGet, navigate});
				}}
			/>

		</>
	);
};
