import React from 'react';
import _ from 'lodash';

// Componentes
import FotosAgrupadas from "@cpnt/FotosAgrupadas/FotosAgrupadas";

// Consts
import { STATUS_PROJETO } from '@config/objetos';

// Funções
import { caminhoFoto } from '@func/utils';

/*
** Monta array com as fotos da equipe
** ================================================== */
export const fotosEquipeCompleta = (dadosObj) => {

	const {
		dados,
		freelancers = true,
		usuarios = true,
		alt = false,
		onClick = () => null,
		qtd = 3,
	} = dadosObj;

	var arr = [];

	// Team
	if(usuarios) {
		if(dados.team && dados.team.length > 0) {
			arr.push(...dados.team);
		}
	}

	if(freelancers) {
		// Team Freelancers
		if(dados.teamFreelancers && dados.teamFreelancers.length > 0) {
			arr.push(...dados.teamFreelancers);
		}
	}

	// Team Mentores
	if(dados.teamMentors && dados.teamMentors.length > 0) {
		arr.push(...dados.teamMentors);
	}

	// Team Profissionais envolvidos
	if(dados.teamProfessionalsInvolved && dados.teamProfessionalsInvolved.length > 0) {
		arr.push(...dados.teamProfessionalsInvolved);
	}

	if(arr.length <= 0) {
		if(alt) {
			return <div className="color-1">Nenhum usuário da equipe selecionado.</div>
		} else {
			return <div className="color-1">-</div>
		}
	}

	var fotos = arr.map(value2 => {
		if(value2.user_photo !== undefined) {
			return {
				foto: caminhoFoto(value2.user_photo).url,
				desc: (
					<div className="align-center">
						<div className="strong">{value2.user_name}</div>
						<div className="desc">{value2.role}</div>
					</div>
				),
			};
		}
		return {
			foto: caminhoFoto(value2.photo).url,
			desc: (
				<div className="align-center">
					<div className="strong">{value2.name}</div>
					<div className="desc">{value2.area_name}</div>
				</div>
			),
		};
	});

	return (
		<FotosAgrupadas
			fotos={fotos}
			onClick={onClick}
			className="cursor-pointer"
			qtd={qtd}
			{...(alt ? {
				espacoAlt: 1,
			} : {})}
		/>
	)
}

export const statusProjeto = status => {
	var statusNome = '(sem status)';
	if(status || status === 0) {
		var statusNomeObj = _.find(STATUS_PROJETO, {value: status});
		if(statusNomeObj && statusNomeObj.label) {
			statusNome = statusNomeObj.label;
		}
	}
	return statusNome;
}
