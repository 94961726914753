import React, { useState } from 'react';

// css
import './PtCartaApresentacao.css';

// Imagens
import imgIconeModelo from '@svg/regular/file-lines.svg';

// Componentes
import Input2 from "@cpnt/Input2/Input2";
import Portal from "@cpnt/Portal/Portal";
import Button2 from "@cpnt/Button2/Button2";
import TextoIconeLink from "@cpnt/TextoIconeLink/TextoIconeLink";
import { PortalTemplates } from "@cpnt/Portais/PortaisTemplates/PortalTemplates/PortalTemplates";

const PtCartaApresentacao = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		titulo = 'Carta de apresentação',
		stateTexto,
		setStateTexto,
		idTipoTemplate = 5,
		showDica = true,
	} = props;

	const [showPortalInfo, setShowPortalInfo] = useState(false);
	const [showPortalTemplate, setShowPortalTemplate] = useState(false);

	return (
		<>
			<div className={`cpnt-cartaapres ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-cartaapres-box ${className}`}>
					<div className="flex flex-center-v flex-column-680">
						{titulo && <h3 className="titulo">{titulo}</h3>}
						{showDica && <strong className="pos a btn-res-l-680" onClick={e => setShowPortalInfo(true)}>O que é isso?</strong>}
					</div>
					<div className="margin-t-15">
						<Input2
							label="Escreva aqui"
							req
							_={{
								type: 'textarea',
								value: stateTexto,
								onChange: e => {
									setStateTexto(e.target.value);
								}
							}}
						/>
					</div>
					<div className="align-center desc margin-t-15">
						<div>Você pode selecionar um de nossos modelos prontos.</div>
						<div className="margin-t-5">Ao selecionar um modelo, a sua apresentação atual será sobrescrita. Lembre-se de modificar o modelo e inserir suas informações.</div>
					</div>
					<div className="align-center margin-t-15">
						<Button2
							_={{
								value: <TextoIconeLink texto="Selecionar modelo" icone={imgIconeModelo} />,
								onClick: e => setShowPortalTemplate(true)
							}}
						/>
					</div>
				</div>
			</div>

			<Portal
				titulo="Para que serve a carta de apresentação"
				show={showPortalInfo}
				onClose={() => setShowPortalInfo(false)}
				className="w-600"
			>
				A sua oportunidade de transmitir uma boa impressão sobre você. Para te ajudar, disponibilizamos alguns modelos de carta. A carta é a sua aliada para capturar a atenção do recrutador, falando um pouco mais sobre você e suas principais características. Isso te deixa mais perto de receber propostas de jobs freelancers.
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Fechar',
							onClick: e => setShowPortalInfo(false)
						}}
					/>
				</div>
			</Portal>

			<PortalTemplates
				idTipo={idTipoTemplate} // Carta de apresentação de freelancers
				showFiltros={false}
				show={showPortalTemplate}
				onClose={() => setShowPortalTemplate(false)}
				onSelect={e => {
					setStateTexto(e.text);
					setShowPortalTemplate(false);
				}}
			/>
		</>
	);
};

export { PtCartaApresentacao };