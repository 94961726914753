import React from 'react';

// Imagens
import imgIconeRemover from '@img/icones/geral/x-branco.png';

const BtnLabel = props => {
	const dados = {
		onClose: () => null,
		texto: '',
		x: true,
		className: '',
		...props,
	};

	return (
		<div className={`bg-black color-white flex flex-center-v margin-5 padding-5 padding-l-10 padding-r-10 ${dados.className}`}>
			<span className="fs-12">{dados.texto}</span>
			{dados.x &&
				<span className="w-10 margin-l-15 cursor-pointer" onClick={() => dados.onClose(dados)}>
					<img src={imgIconeRemover} alt="Remover" className="block w-100p" />
				</span>
			}
		</div>
	)
}

export default BtnLabel;