// import { apiCompanies } from "./requests/companies";
// import { apiStatesGeral } from "./requests/select";

import { apiBriefings } from "./requests/briefings/briefings";
import { apiCep } from "./requests/cep/cep";
import { apiCompany } from "./requests/company/company";
import { apiCompanyLite } from "./requests/companyLite/companyLite";
import { apiCompanyLiteGeral } from "./requests/companyLite/companyLite-geral";
import { apiCustomers } from "./requests/customers/customers";
import { apiFreelancerRatings } from "./requests/freelancerRatings/freelancerRatings";
import { apiNotification } from "./requests/notification/notification";
import { apiProfessions } from "./requests/professions/professions";
import { apiProfessionsGeral } from "./requests/professions/professions-geral";
import { apiSelect } from "./requests/select/select";
import { apiSelectGeral } from "./requests/select/select-geral";
import { apiUsers } from "./requests/users/users";
import { apiUsersGeral } from "./requests/users/users-geral";

const api = {
	select: apiSelect,
	notification: apiNotification,
	customers: apiCustomers,
	freelancerRatings: apiFreelancerRatings,
	company: apiCompany,
	companyLite: apiCompanyLite,
	cep: apiCep,
	users: apiUsers,
	professions: apiProfessions,
	briefings: apiBriefings,

	_: {
		select: apiSelectGeral,
		users: apiUsersGeral,
		professions: apiProfessionsGeral,
		companyLite: apiCompanyLiteGeral,
	}
};

export { api };
