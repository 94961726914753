import React from 'react';
import Placeholder from '@cpnt/Placeholder/Placeholder';

const PlaceholderTemplates = props => {
	return (
		<tr>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="circulo" />
				</div>
			</td>
			<td className="col-acao">
				<div className="td-wrap">
					<Placeholder tipo="retangulo-alt" />
				</div>
			</td>
		</tr>
	)
}

export { PlaceholderTemplates };
