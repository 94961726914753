import React from 'react';
import Linkify from 'react-linkify';

// css
import './ChatMensagem.css';

// Imagens
import imgIconeDoubleCheck from '@svg/outros/doublecheck.svg';

// Components
import Foto from '@cpnt/Foto/Foto';
import Icone from '@cpnt/Icone/Icone';
import Disponibilidade from '@cpnt/Disponibilidade/Disponibilidade';

// Partes
import ChatMensagemProposta from './ChatMensagemProposta';
import ChatMensagemSistema from './ChatMensagemSistema';
import ChatMensagemNotaFiscal from './ChatMensagemNotaFiscal';
import ChatMensagemVariavel from './ChatMensagemVariavel';

// Funções
import { htmlToJsx, removerHtml } from '@func/utils';
import ChatMensagemContrato from './ChatMensagemContrato';
import ChatMensagemContratoAssinado from './ChatMensagemContratoAssinado';

import { primeiroNome } from "@_func/regex";
import ChatMensagemBriefing from "./ChatMensagemBriefing";

const ChatMensagem = props => {
	const {
		className = '',
		propria,
		wrap = '',

		name,
		photo,

		mensagem,
		enviarMensagem = () => null,
		type = 'default',
		data,
		dataExtenso,
		mensagemLida,
		onClickBtnRespostaProposta = () => null,
		onClickInfo = () => null,

		setDetalhesMensagem = () => null,
		setShowDetalhesMensagem = () => null,
		idProposta,
	} = props;

	var {
		metadata = {},
	} = props;

	// Cor do box
	var skinBox = type;

	if(type === 'imagem-url') {
		skinBox = 'default';
	}

	if(type === 'resposta' || type === 'resposta-proposta') {
		if(metadata.resposta) {

			// Tema do box
			if(metadata.resposta.tipo === 'verde' || metadata.resposta.tipo === 'vermelho') {
				skinBox = metadata.resposta.tipo;
			} else {
				skinBox = 'outro';
			}
			metadata.titulo = 'Resposta';
		}
	}

	if(!type) {
		skinBox = 'default';
	}

	if(!metadata) {
		metadata = {};
	}

	if(type === 'sistema') {
		return (
			<ChatMensagemSistema
				mensagem={mensagem}
				metadata={metadata}
				className={className}
				tipo={type}
			/>
		)
	}

	if(type === 'briefing') {
		return (
			<ChatMensagemBriefing
				{...props}
				mensagem={mensagem}
				metadata={metadata}
				className={className}
				tipo={type}
			/>
		)
	}

	if(type === 'variavel') {
		return (
			<ChatMensagemVariavel
				mensagem={mensagem}
				metadata={metadata}
				className={className}
				tipo={type}
			/>
		)
	}

	if(type === 'nota-fiscal-xml' || type === 'nota-fiscal-pdf') {
		return (
			<ChatMensagemNotaFiscal
				{...props}
				mensagem={mensagem}
				metadata={metadata}
				className={className}
				tipo={type}
			/>
		)
	}

	if(type === 'contrato') {
		return (
			<ChatMensagemContrato
				{...props}
				mensagem={mensagem}
				metadata={metadata}
				className={className}
				tipo={type}
				enviarMensagem={enviarMensagem}
			/>
		)
	}

	if(type === 'contrato-assinado') {
		return (
			<ChatMensagemContratoAssinado
				{...props}
				mensagem={mensagem}
				metadata={metadata}
				className={className}
				tipo={type}
			/>
		)
	}

	var textoMensagemComLink = removerHtml(mensagem, ['b', 'a', 'strong']);

	return (
		<div className={`cpnt-chatmsg cpnt-chatmsg-posicao-${propria ? 'right' : 'left'} cpnt-chatmsg-tipo-${skinBox} ${wrap}`}>
			<div className={`cpnt-chatmsg-user cpnt-chatmsg-user-${propria ? 'right' : 'left'}`}>
				<div>
					<Foto
						imagem={photo}
					/>
				</div>
				{name && name !== '' ? (
					<div className="cpnt-chatmsg-user-name max-w-100p">
						<div className="text-one-line">
							{primeiroNome(name)}
						</div>
					</div>
				) : null}
			</div>

			<div className={`cpnt-chatmsg-mensagem-box cpnt-chatmsg-mensagem-box-${propria ? 'right' : 'left'} ${className}`}>
				{metadata.titulo && type !== 'proposta' ? (
					<div className={`cpnt-chatmsg-custom-titulo ${metadata.resposta ? 'cpnt-chatmsg-custom-titulo-resposta' : ''}`}>
						<h3 className="margin-0 padding-0">{metadata.titulo}</h3>
						{metadata.idProposta ? (
							<div className="fs-12">ID da proposta: {metadata.idProposta}</div>
						) : null}
						{metadata.resposta ? (
							<Disponibilidade
								status={metadata.resposta.tipo === 'verde' ? 1 : 0}
								textoDisponivel={metadata.resposta.texto}
								textoIndisponivel={metadata.resposta.texto}
								className="cpnt-chatmsg-cpnt-disponibilidade"
								min
							/>
						) : null}
					</div>
				) : null}

				{type !== 'proposta' ? (
					type === 'imagem-url' && metadata.imagem && metadata.imagem.preview && metadata.imagem.url ? (
						<div className="cpnt-chatmsg-mensagem cpnt-chatmsg-mensagem-imagem">
							<div
								className="cursor-pointer"
								onClick={() => {
									setDetalhesMensagem(metadata.imagem);
									setShowDetalhesMensagem(true);
								}}
							>
								<img src={metadata.imagem.url} alt="imagem" className="block w-100p" />
							</div>
						</div>
					) : (
						<div className="cpnt-chatmsg-mensagem">
							<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
								<a href={decoratedHref} key={key} target="_blank" rel="noreferrer">{decoratedText}</a>
							)}>{htmlToJsx(textoMensagemComLink)}</Linkify>
						</div>
					)
				) : null}

				{type === 'proposta' && metadata.proposta ? (
					<ChatMensagemProposta
						metadata={metadata}
						idProposta={idProposta}
						onClickBtnRespostaProposta={onClickBtnRespostaProposta}
						onClickInfo={onClickInfo}
						mensagem={mensagem}
						params={props}
					/>
				) : null}

				{/* {metadata.botoes && metadata.botoes.length ? (
					<div className="cpnt-chatmsg-botoes">
						{metadata.botoes.map((val, key) => {
							return (
								<div
									key={key}
									className={`cpnt-chatmsg-botao btn btn-${val.tipo} btn-${val.tipo !== 'verde' && val.tipo !== 'vermelho' ? 'default' : (val.tipo === 'verde' ? 'green' : 'red')}`}
									onClick={() => {
										onClickBtnRespostaProposta({
											mensagem: props,
											botao: {
												_key: key,
												...val
											},
										})
									}}
								>
									{val.texto}
								</div>
							)
						})}
					</div>
				) : null} */}

				<div className={`cpnt-chatmsg-footer cpnt-chatmsg-footer-${propria ? 'right' : 'left'}`}>
					<span>{dataExtenso || data}</span>
					{propria ? (
						<span className="margin-l-10">
							<Icone
								imagem={imgIconeDoubleCheck}
								cor={mensagemLida ? '#2596e1' : '#D5D5D5'}
								tamanho="14px"
							/>
						</span>
					) : null}
				</div>
			</div>

		</div>
	);
};

export default ChatMensagem;