export const _tempDadosFechamento = [
	{
		"id": 9131,
		"id_user": 42585,
		"id_job": 4790,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "Layout do site OnePage 2/2",
		"id_project": 4754,
		"project_name": "222 - Kaopa",
		"id_customer": 5088,
		"customer_name": "KAOPA OLEO E TORTA",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 965,
		"freelancer_name": "Débora Mantovani",
		"freelancer_photo": "/users/profile/1608_foto.jpeg",
		"id_briefing": null,
		"cnpj": "11.172.734/3496-57",
		"price": 602.27,
		"liquid_price": 2002,
		"created_at": "2021-06-17T15:59:37.000Z",
		"approval_at": "2021-06-30T03:00:00.000Z",
		"start_date": null,
		"payday": "2021-07-15T03:00:00.000Z",
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 0,
		"status": 15,
		"status_name": "Aprovado",
		"email": "malki@kutu.ke"
	},
	{
		"id": 9130,
		"id_user": 42585,
		"id_job": 4771,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "Conteúdo do site OnePage 2/2",
		"id_project": 4754,
		"project_name": "222 - Kaopa",
		"id_customer": 5088,
		"customer_name": "KAOPA OLEO E TORTA",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 25947,
		"freelancer_name": "Cida Torres Simões",
		"freelancer_photo": "Content/images/user/5d5d7f9f-139c-4972-85b5-deefcbe25b9c.jpg",
		"id_briefing": null,
		"cnpj": "18.173.687/1398-05",
		"price": 284.09,
		"liquid_price": 1202,
		"created_at": "2021-06-17T15:13:18.000Z",
		"approval_at": "2021-06-17T03:00:00.000Z",
		"start_date": null,
		"payday": "2021-07-15T03:00:00.000Z",
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 0,
		"status": 14,
		"status_name": "Aprovado",
		"email": "surdiku@omoj.ie"
	},
	{
		"id": 9110,
		"id_user": 42585,
		"id_job": 3739,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "Campanha de mídia - Maio/Jun (2/4)",
		"id_project": 4746,
		"project_name": "214 - Saba's Center",
		"id_customer": 1,
		"customer_name": "CROWD",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 13299,
		"freelancer_name": "Guilherme T. R. Leão",
		"freelancer_photo": "Content/images/user/98dd6188-f1db-49cc-be7f-45b753715357.jpg",
		"id_briefing": null,
		"cnpj": "33.670.280/5417-90",
		"price": 681.82,
		"liquid_price": 2171,
		"created_at": "2021-06-14T19:31:48.000Z",
		"approval_at": "2021-06-14T03:00:00.000Z",
		"start_date": null,
		"payday": "2021-06-30T03:00:00.000Z",
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 0,
		"status": 15,
		"status_name": "Aprovado",
		"email": "ulo@gerew.fj"
	},
	{
		"id": 9053,
		"id_user": 42585,
		"id_job": 3722,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "Materiais de apoio para Mídia 1/2",
		"id_project": 2319,
		"project_name": "CROWD TI",
		"id_customer": 1,
		"customer_name": "CROWD",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 19957,
		"freelancer_name": "Amanda Fróes",
		"freelancer_photo": "Content/images/user/57bcc941-bd68-4167-b528-a30f828755b9.jpg",
		"id_briefing": null,
		"cnpj": "17.467.356/5817-80",
		"price": 568.18,
		"liquid_price": 1749,
		"created_at": "2021-05-28T17:34:58.000Z",
		"approval_at": "2021-05-28T03:00:00.000Z",
		"start_date": null,
		"payday": "2021-06-15T03:00:00.000Z",
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 0,
		"status": 15,
		"status_name": "Aprovado",
		"email": "firteh@huru.gov"
	},
	{
		"id": 8919,
		"id_user": 42585,
		"id_job": 4895,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "Crowd - Mapeamento de Mídia 2/2",
		"id_project": 16,
		"project_name": "08 - Crowd Mkt",
		"id_customer": 1,
		"customer_name": "CROWD",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 19957,
		"freelancer_name": "Amanda Fróes",
		"freelancer_photo": "Content/images/user/57bcc941-bd68-4167-b528-a30f828755b9.jpg",
		"id_briefing": null,
		"cnpj": "62.573.149/6493-18",
		"price": null,
		"liquid_price": 1614,
		"created_at": "2021-04-26T22:19:20.000Z",
		"approval_at": "2021-04-26T22:19:20.000Z",
		"start_date": null,
		"payday": null,
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 1,
		"status": 16,
		"status_name": "Cancelado",
		"email": "muses@arudo.mz"
	},
	{
		"id": 8918,
		"id_user": 42585,
		"id_job": 3234,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "Crowd - Mapeamento de Mídia 1/2",
		"id_project": 16,
		"project_name": "08 - Crowd Mkt",
		"id_customer": 1,
		"customer_name": "CROWD",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 19957,
		"freelancer_name": "Amanda Fróes",
		"freelancer_photo": "Content/images/user/57bcc941-bd68-4167-b528-a30f828755b9.jpg",
		"id_briefing": null,
		"cnpj": "61.305.753/3485-20",
		"price": null,
		"liquid_price": 1215,
		"created_at": "2021-04-26T22:19:05.000Z",
		"approval_at": "2021-04-26T22:19:05.000Z",
		"start_date": null,
		"payday": null,
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 1,
		"status": 16,
		"status_name": "Cancelado",
		"email": "lab@copginoc.bf"
	},
	{
		"id": 8687,
		"id_user": 42585,
		"id_job": 4067,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "Analisar e classificar lista de Habilidades",
		"id_project": 4727,
		"project_name": "189 - CROWD | Nova Plataforma",
		"id_customer": 1,
		"customer_name": "CROWD",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 13430,
		"freelancer_name": "LENARA LYRA FERNANDES",
		"freelancer_photo": "Content/images/user/b998dc88-ea76-4680-8876-c36cf9be4011.jpg",
		"id_briefing": null,
		"cnpj": "84.137.426/9568-03",
		"price": 375,
		"liquid_price": 1797,
		"created_at": "2021-03-05T19:34:23.000Z",
		"approval_at": "2021-03-24T03:00:00.000Z",
		"start_date": null,
		"payday": "2021-04-15T03:00:00.000Z",
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 0,
		"status": 15,
		"status_name": "Aprovado",
		"email": "ke@nagnip.com"
	},
	{
		"id": 8686,
		"id_user": 42585,
		"id_job": 3794,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "Campanha Março",
		"id_project": 21,
		"project_name": "33 - Teti Gio",
		"id_customer": 5104,
		"customer_name": "TETI GIO",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 13300,
		"freelancer_name": "RODRIGO ALVES VAMPRE HUMMEL",
		"freelancer_photo": "Content/images/user/7e3bf8ac-a6ea-431a-9335-1ef3cf6348b0.jpg",
		"id_briefing": null,
		"cnpj": "62.945.396/9985-72",
		"price": 568.18,
		"liquid_price": 2032,
		"created_at": "2021-03-03T23:28:17.000Z",
		"approval_at": "2021-03-31T03:00:00.000Z",
		"start_date": null,
		"payday": "2021-04-15T03:00:00.000Z",
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 1,
		"status": 15,
		"status_name": "Aprovado",
		"email": "zoheb@udawubop.me"
	},
	{
		"id": 8684,
		"id_user": 42585,
		"id_job": 4555,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "Consultoria Estratégica para o Planejamento de Mídia",
		"id_project": 21,
		"project_name": "33 - Teti Gio",
		"id_customer": 5104,
		"customer_name": "TETI GIO",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 3607,
		"freelancer_name": "Rafael Giuzio",
		"freelancer_photo": "Content/images/user/c9484f44-e3ff-4401-899f-d6a0bf6f0234.jpg",
		"id_briefing": null,
		"cnpj": "01.812.568/0257-50",
		"price": null,
		"liquid_price": 2412,
		"created_at": "2021-03-03T20:40:21.000Z",
		"approval_at": "2021-03-03T20:40:21.000Z",
		"start_date": null,
		"payday": null,
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 1,
		"status": 16,
		"status_name": "Cancelado",
		"email": "saridoid@banbajawa.mz"
	},
	{
		"id": 8684,
		"id_user": 42585,
		"id_job": 3202,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "Consultoria Estratégica para o Planejamento de Mídia",
		"id_project": 21,
		"project_name": "33 - Teti Gio",
		"id_customer": 5104,
		"customer_name": "TETI GIO",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 3607,
		"freelancer_name": "Rafael Giuzio",
		"freelancer_photo": null,
		"id_briefing": null,
		"cnpj": "06.116.281/4232-75",
		"price": null,
		"liquid_price": 1132,
		"created_at": "2021-03-03T20:40:21.000Z",
		"approval_at": "2021-03-03T20:40:21.000Z",
		"start_date": null,
		"payday": null,
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 1,
		"status": 16,
		"status_name": "Cancelado",
		"email": "fug@becno.ki"
	},
	{
		"id": 8622,
		"id_user": 42585,
		"id_job": 3000,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "Website 1/6",
		"id_project": 4749,
		"project_name": "220 - BNB Guests",
		"id_customer": 5094,
		"customer_name": "BNB GUESTS",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 28735,
		"freelancer_name": "Rafael Nepomuceno Dev",
		"freelancer_photo": "/users/profile/32194_foto.jpeg",
		"id_briefing": null,
		"cnpj": "17.859.544/7627-07",
		"price": null,
		"liquid_price": 2225,
		"created_at": "2021-02-18T22:23:27.000Z",
		"approval_at": "2021-02-18T22:23:27.000Z",
		"start_date": null,
		"payday": null,
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 0,
		"status": 16,
		"status_name": "Cancelado",
		"email": "dukvul@uhe.ga"
	},
	{
		"id": 8234,
		"id_user": 42585,
		"id_job": 3530,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "União + Sydle",
		"id_project": 4679,
		"project_name": "170 - Camil | Tech",
		"id_customer": 1,
		"customer_name": "CROWD",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 18433,
		"freelancer_name": "Rodrigo Nunes de Souza",
		"freelancer_photo": "/users/profile/20902_foto.jpeg",
		"id_briefing": null,
		"cnpj": "22.486.889/2367-23",
		"price": 3153.41,
		"liquid_price": 1623,
		"created_at": "2020-11-10T19:06:56.000Z",
		"approval_at": "2020-11-10T03:00:00.000Z",
		"start_date": null,
		"payday": null,
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 0,
		"status": 15,
		"status_name": "Aprovado",
		"email": "zehas@ehoca.dm"
	},
	{
		"id": 8231,
		"id_user": 42585,
		"id_job": 4087,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "União / Participação da etapa de GMUD",
		"id_project": 4679,
		"project_name": "170 - Camil | Tech",
		"id_customer": 1,
		"customer_name": "CROWD",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 28735,
		"freelancer_name": "Rafael Nepomuceno Dev",
		"freelancer_photo": "/users/profile/32194_foto.jpeg",
		"id_briefing": null,
		"cnpj": "53.223.959/7038-20",
		"price": 3977.27,
		"liquid_price": 1781,
		"created_at": "2020-11-06T23:03:28.000Z",
		"approval_at": "2021-01-14T03:00:00.000Z",
		"start_date": null,
		"payday": "2021-01-31T03:00:00.000Z",
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 0,
		"status": 14,
		"status_name": "Aprovado",
		"email": "fedengom@sejmumho.mm"
	},
	{
		"id": 8230,
		"id_user": 42585,
		"id_job": 4679,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "União / Página dos Chefs e Fale Conosco",
		"id_project": 4679,
		"project_name": "170 - Camil | Tech",
		"id_customer": 1,
		"customer_name": "CROWD",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 28735,
		"freelancer_name": "Rafael Nepomuceno Dev",
		"freelancer_photo": "/users/profile/32194_foto.jpeg",
		"id_briefing": null,
		"cnpj": "11.346.977/4506-24",
		"price": 1886.36,
		"liquid_price": 1629,
		"created_at": "2020-11-06T21:54:17.000Z",
		"approval_at": "2020-11-30T03:00:00.000Z",
		"start_date": null,
		"payday": null,
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 0,
		"status": 15,
		"status_name": "Aprovado",
		"email": "apduju@sahmetlu.vg"
	},
	{
		"id": 8229,
		"id_user": 42585,
		"id_job": 4723,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "União / Página de Receitas (geral e interna)",
		"id_project": 4679,
		"project_name": "170 - Camil | Tech",
		"id_customer": 1,
		"customer_name": "CROWD",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 28735,
		"freelancer_name": "Rafael Nepomuceno Dev",
		"freelancer_photo": "/users/profile/32194_foto.jpeg",
		"id_briefing": null,
		"cnpj": "43.199.106/9285-03",
		"price": 3636.36,
		"liquid_price": 1612,
		"created_at": "2020-11-06T21:54:02.000Z",
		"approval_at": "2020-12-15T03:00:00.000Z",
		"start_date": null,
		"payday": null,
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 0,
		"status": 14,
		"status_name": "Aprovado",
		"email": "himev@ulibeh.org"
	},
	{
		"id": 8220,
		"id_user": 42585,
		"id_job": 4667,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "Ajustes na pagina interna do produto",
		"id_project": 4728,
		"project_name": "190 - CROWD | Ecommerce",
		"id_customer": 1,
		"customer_name": "CROWD",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 7541,
		"freelancer_name": "Lucas Garcia",
		"freelancer_photo": "Content/images/user/e3355b29-25b5-469e-bd07-c86aa9501722.jpg",
		"id_briefing": null,
		"cnpj": "70.213.955/6035-83",
		"price": 569,
		"liquid_price": 1840,
		"created_at": "2020-11-04T19:56:29.000Z",
		"approval_at": "2020-11-13T03:00:00.000Z",
		"start_date": null,
		"payday": null,
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 1,
		"status": 14,
		"status_name": "Aprovado",
		"email": "deoja@giwazit.lt"
	},
	{
		"id": 8216,
		"id_user": 42585,
		"id_job": 4564,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "Atividades 1 a 15 de novembro",
		"id_project": 4728,
		"project_name": "190 - CROWD | Ecommerce",
		"id_customer": 1,
		"customer_name": "CROWD",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 7541,
		"freelancer_name": "Lucas Garcia",
		"freelancer_photo": "Content/images/user/e3355b29-25b5-469e-bd07-c86aa9501722.jpg",
		"id_briefing": null,
		"cnpj": "33.774.409/5389-12",
		"price": 1159.09,
		"liquid_price": 998,
		"created_at": "2020-11-04T19:00:26.000Z",
		"approval_at": "2020-11-13T03:00:00.000Z",
		"start_date": null,
		"payday": null,
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 1,
		"status": 15,
		"status_name": "Aprovado",
		"email": "cizlelu@ozu.eg"
	},
	{
		"id": 8215,
		"id_user": 42585,
		"id_job": 3130,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "Live Preview / Publicar na HostGator LP e OnePage",
		"id_project": 4728,
		"project_name": "190 - CROWD | Ecommerce",
		"id_customer": 1,
		"customer_name": "CROWD",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 18433,
		"freelancer_name": "Rodrigo Nunes de Souza",
		"freelancer_photo": "/users/profile/20902_foto.jpeg",
		"id_briefing": null,
		"cnpj": "60.548.009/4811-61",
		"price": 681.82,
		"liquid_price": 2379,
		"created_at": "2020-11-04T18:52:01.000Z",
		"approval_at": "2020-11-13T03:00:00.000Z",
		"start_date": null,
		"payday": null,
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 1,
		"status": 14,
		"status_name": "Aprovado",
		"email": "fa@ro.aq"
	},
	{
		"id": 8194,
		"id_user": 42585,
		"id_job": 4763,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "União / Páginas de Produtos (geral e interna)",
		"id_project": 4679,
		"project_name": "170 - Camil | Tech",
		"id_customer": 1,
		"customer_name": "CROWD",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 28735,
		"freelancer_name": "Rafael Nepomuceno Dev",
		"freelancer_photo": "/users/profile/32194_foto.jpeg",
		"id_briefing": null,
		"cnpj": "41.647.413/2624-20",
		"price": 1704.54,
		"liquid_price": 1780,
		"created_at": "2020-10-29T16:20:18.000Z",
		"approval_at": "2020-11-10T03:00:00.000Z",
		"start_date": null,
		"payday": null,
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 0,
		"status": 15,
		"status_name": "Aprovado",
		"email": "nugwu@watabuzi.mv"
	},
	{
		"id": 8191,
		"id_user": 42585,
		"id_job": 3553,
		"user_name": "Fabi Gomes",
		"user_photo": "Content/images/user/95bfad51-4109-4fd4-86b2-062a675bdcf7.jpg",
		"title": "União / Páginas Livro, Termos, Política, Nossa História e 110 anos",
		"id_project": 4679,
		"project_name": "170 - Camil | Tech",
		"id_customer": 1,
		"customer_name": "CROWD",
		"customer_tax_percent": 12,
		"id_company": 1,
		"company_name": "Crowd Agency Sistemas e Tecnologia Ltda-me",
		"id_freelancer": 28735,
		"freelancer_name": "Rafael Nepomuceno Dev",
		"freelancer_photo": "/users/profile/32194_foto.jpeg",
		"id_briefing": null,
		"cnpj": "13.385.377/7304-74",
		"price": 3250,
		"liquid_price": 827,
		"created_at": "2020-10-29T16:08:07.000Z",
		"approval_at": "2020-10-30T03:00:00.000Z",
		"start_date": null,
		"payday": null,
		"invoice_pdf": null,
		"invoice_xml": null,
		"countMessages": 0,
		"status": 15,
		"status_name": "Aprovado",
		"email": "caslef@pevekoob.ms"
	}
];
