import React, { useEffect, useState } from 'react';

// css
import './PortalEmpresasDetalhes.css';

// API
import { apiCompany } from '@_api/requests/company/company';

// Partes
import { DetalhesEmpresa } from '@page/Logado/Empresas/DetalhesEmpresa/DetalhesEmpresa';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções

import { msgs, msgStatus } from "@_func/request";

const PortalEmpresasDetalhes = props => {

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-empresas-form-edicao',
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({});
	const [_stateDados, _setStateDados] = useState({});

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await apiCompany.getOne.get(id);

		if(retGetItem.status === 200) {
			setStateDados(retGetItem.data);
			_setStateDados(msgStatus(retGetItem));
		} else {
			_setStateDados(msgStatus(retGetItem));
			msgs([retGetItem]);
		}

		setLoading(false);
	}

	return (
		<Portal
			titulo="Detalhes da empresa"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<DetalhesEmpresa
								stateDados={stateDados}
								after={
									<div className="form-bottom">
										<Button2
											_={{
												value: 'Fechar',
												type: 'button',
												onClick: onClose,
											}}
										/>
									</div>
								}
							/>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export default PortalEmpresasDetalhes;