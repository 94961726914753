import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import css from './CadastrarProcessoSeletivoSelecionadosFormCadastro.module.css';

// Componentes
import Input2 from '@cpnt/Input2/Input2';
import { InputDate } from "@cpnt/InputDate/InputDate";
import Loading from '@cpnt/Loading/Loading';
import { Select2 } from '@cpnt/Select2/Select2';

import { PortalProfissionaisSelecionados } from "@cpnt/Portais/PortaisProfissionais/PortalProfissionaisSelecionados/PortalProfissionaisSelecionados";
import { singularPlural } from "@func/utils";
import { gets } from "@route/routeUtils";

const CadastrarProcessoSeletivoSelecionadosFormCadastro = props => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();
	const location = useLocation();

	const {
		onSubmit,
		isRascunho,
		onClickResetarProposta,

		// Funções
		getClientes,
		getUsuarios,
		getProjetos,

		// State
		stateDados,
		setStateDados,
		setStateProjetos,

		after,

		stateEmpresas,
		stateClientes,
		stateProjetos,
		stateUsuarios,
		stateTemplates,

		loading,
		loadingGetEmpresas,
		loadingGetClientes,
		loadingGetProjetos,
		loadingGetAreas,
		loadingGetUsuarios,

		stateSelecionados,
	} = props;

	const [stateGet, setStateGet] = useState(gets());
	const [showPortaFreelancersSelecionados, setShowPortaFreelancersSelecionados] = useState(false);

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	const exibirProfissionaisSelecionados = () => {
		setShowPortaFreelancersSelecionados(true);
	}

	return (
		<>
			<form className="relative" onSubmit={onSubmit}>
				{loading ? (
					<Loading absolute bgAlt2 />
				) : null}
				{isRascunho ? (
					<div className="padding-b-15">
						<h3 className="titulo">{stateDados.name}</h3>
						<div className="td-desc">ID da proposta de job: {stateDados.id}</div>
					</div>
				) : null}

				<div className="margin-t-25">
					<div className="flex wrap-5">
						<div className="flex flex-wrap w-100p">
							<div className="input-box w-100p">
								<h3 className="titulo">Dados da proposta</h3>
								<div className="td-desc">Complete as informações abaixo para enviar uma proposta de job aos prestadores de serviços selecionados:</div>
							</div>
							<div className={`input-box w-100p ${css['box-users']} margin-b-15 flex flex-wrap`}>
								<div className="link" onClick={exibirProfissionaisSelecionados}>
									({stateSelecionados.length}) {singularPlural(stateSelecionados.length, 'profissional selecionado', 'profisisonais selecionados')}
								</div>
							</div>
							<div className={`input-box ${store.usuario.role === 1 ? 'w-25p' : 'w-50p'} box-3-980 box-1-680`}>
								<Input2
									label="Título da proposta"
									req
									_={{
										value: stateDados.title,
										onChange: e => {
											setStateDados({
												...stateDados,
												title: e.target.value,
											})
										},
									}}
								/>
							</div>
							{store.usuario.role === 1 ? (
								<div className="input-box w-25p box-3-980 box-1-680">
									<Select2
										label="Empresa"
										req
										_={{
											value: stateDados.id_company,
											options: stateEmpresas,
											onChange: e => {
												setStateDados({
													...stateDados,
													id_company: e.value,
													id_user: null,
													id_customer: null,
													id_project: null,
												});
												getClientes(e.value);
												getUsuarios(e.value);
												setStateProjetos([]);
											}
										}}
										loading={loadingGetEmpresas}
									/>
								</div>
							) : null}
							<div className="input-box w-25p box-3-980 box-1-680">
								<Select2
									label="Cliente"
									req
									_={{
										value: stateDados.id_customer,
										options: stateClientes,
										onChange: e => {
											setStateDados({
												...stateDados,
												id_customer: e.value,
												id_project: null,
											});
											getProjetos({
												id_customer: e.value,
												id_company: stateDados.id_company,
											});
										}
									}}
									loading={loadingGetClientes}
								/>
							</div>
							<div className="input-box w-25p box-3-980 box-1-680">
								<Select2
									label="Projeto"
									tip="Os projetos são de acordo com o cliente selecionado. Caso não esteja visualizando os projetos, selecione um cliente primeiro."
									req
									_={{
										value: stateDados.id_project,
										options: stateProjetos,
										onChange: e => {
											setStateDados({
												...stateDados,
												id_project: e ? e.value : false,
											});
										}
									}}
									loading={loadingGetProjetos}
								/>
							</div>
							{/* <div className="input-box w-25p box-3-980 box-1-680">
								<Select2
									label="Template do Briefing"
									req
									_={{
										value: stateDados.id_template,
										options: stateTemplates,
										onChange: e => {
											setStateDados({
												...stateDados,
												id_template: e ? e.value : null,
											});
										}
									}}
									loading={loadingGetAreas}
								/>
							</div> */}
							<div className="input-box w-33p box-3-980 box-1-680">
								<Select2
									label="Usuário responsável"
									req
									_={{
										value: stateDados.id_user,
										options: stateUsuarios,
										onChange: e => {
											setStateDados({
												...stateDados,
												id_user: e.value,
											});
										}
									}}
									loading={loadingGetUsuarios}
								/>
							</div>
							<div className="input-box w-33p box-3-980 box-1-680">
								<InputDate
									label="Contratar até"
									value={stateDados.delivery_at}
									onChange={e => {
										setStateDados({
											...stateDados,
											delivery_at: e,
										});
									}}
								/>
							</div>
							<div className="input-box w-33p box-3-980 box-1-680">
								<Input2
									label="Orçamento"
									_={{
										value: stateDados.budget,
										onChange: e => {
											setStateDados({
												...stateDados,
												budget: e,
											});
										}
									}}
									dinheiro={true}
								/>
							</div>

						</div>
					</div>
				</div>

				{after}
			</form>

			<PortalProfissionaisSelecionados
				show={showPortaFreelancersSelecionados}
				onClose={() => setShowPortaFreelancersSelecionados(false)}
				stateSelecionados={stateSelecionados}
			/>
		</>
	);
};

export { CadastrarProcessoSeletivoSelecionadosFormCadastro };
