import md5 from 'crypto-js/md5';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// Hooks
import useWindowDimension from '@/_/hooks/useWindowDimensions';

// CSS
import 'react-datepicker/dist/react-datepicker.css';
import './Clientes.css';

// API
import { api } from '@_api/api';
import { apiGetEmpresas } from '@apiGeral/geral';

// Components
import FiltrosWrap from '@cpnt/FiltrosWrap/FiltrosWrap';
import Icone from '@cpnt/Icone/Icone';
import ImgTituloDesc from '@cpnt/ImgTituloDesc/ImgTituloDesc';
import Input2 from '@cpnt/Input2/Input2';
import { PlaceholderRender } from '@cpnt/Placeholder/Placeholder';
import { PortalClientesDetalhes } from '@portal/PortaisClientes/PortalClientesDetalhes/PortalClientesDetalhes';
import { PortalClientesDetalhesAnexos } from '@portal/PortaisClientes/PortalClientesDetalhesAnexos/PortalClientesDetalhesAnexos';
import { PortalClientesDetalhesResponsavel } from '@portal/PortaisClientes/PortalClientesDetalhesResponsavel/PortalClientesDetalhesResponsavel';
import PortalClientesFormCadastro from '@portal/PortaisClientes/PortalClientesFormCadastro/PortalClientesFormCadastro';
import PortalClientesFormEdicao from '@portal/PortaisClientes/PortalClientesFormEdicao/PortalClientesFormEdicao';
import { PortalPreviewSite } from '@portal/PortaisClientes/PortalPreviewSite/PortalPreviewSite';
import { Select2 } from '@cpnt/Select2/Select2';
import { Select3Lista } from '@cpnt/Select3/Select3';
import Status from '@cpnt/Status/Status';
import Tip from '@cpnt/Tip/Tip';
import TotalPaginacao from '@cpnt/TotalPaginacao/TotalPaginacao';

// Imagens
import imgIconeEmpresaAlt2 from '@img/icones/geral/empresa2.png';
import imgIconeInfo from '@svg/regular/earth-americas.svg';
import imgIconeAnexo from '@svg/regular/paperclip.svg';
import imgIconeEditar from '@svg/regular/pen.svg';
import imgIconeStatus from '@svg/regular/play-pause.svg';
import imgIconeProjetos from '@svg/regular/table-tree.svg';

// Route
import { clearGet, closePortal, getPortalDados, initFuncExec, isShowPortal, setGet, setGetNavigate, showPortal } from '@route/routeUtils';

// Funções
import { BtnAdd } from '@cpnt/BtnAdd/BtnAdd';
import { FILTROS_RESULTADOS, STATUS_FILTRO } from '@config/objetos';
import { addPrefixo, caminhoFoto, tamanhoTela } from '@func/utils';
import { BtnLimparFiltros } from '@cpnt/BtnLimparFiltros/BtnLimparFiltros';
import { msgs, msgStatus } from "@_func/request";
import { Btn } from "@_cpnt/Btn/Btn/Btn";

const Clientes = props => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	const screen = useWindowDimension();

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = (getsAtualizados = getsDefault) => {
		initFuncExec([
			{
				func: getClientes,
				param: getsAtualizados
			},
			{
				func: apiGetEmpresas,
				condicao: store.usuario.role === 1,
				param: {
					setLoadingGetEmpresas,
					setStateEmpresas,
				},
				init: true,
			},
		], init, location, stateLocation);
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		overlay: [],
		limit: 20,
		page: 1,

		id_company: store.usuario.id_company,
		name: '',
		name_responsible: '',
		active: null,
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	/*
	** Loading
	** ================================================== */
	const [loadingGetClientes, setLoadingGetClientes] = useState(false);
	const [loadingAlterarStatus, setLoadingAlterarStatus] = useState(false);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateClientes, setStateClientes] = useState([]);
	const [_stateClientes, _setStateClientes] = useState({});
	const [stateClientesMetadata, setStateClientesMetadata] = useState({});
	const [stateEmpresas, setStateEmpresas] = useState([]);

	/*
	** API - Projetos Filtro
	** ================================================== */
	const getClientes = async (dadosObj = stateGet) => {
		if(loadingGetClientes) {
			return;
		}
		setLoadingGetClientes(true);

		var dadosEnviar = clearGet(dadosObj);
		const retGetClientes = await api.customers.all.get(dadosEnviar);

		_setStateClientes(msgStatus(retGetClientes));

		if(retGetClientes.status === 200) {
			setStateClientes(retGetClientes.data);
			setStateClientesMetadata(retGetClientes.metadata);
		} else {
			setStateClientes([]);
			setStateClientesMetadata({});
		}

		setLoadingGetClientes(false);
	}

	/*
	** Alterar status do cliente
	** ================================================== */
	const alterarStatusCliente = async dadosObj => {
		if(loadingAlterarStatus === dadosObj.id) {
			msg.warning('Por favor, aguarde...');
			return;
		}

		setLoadingAlterarStatus(dadosObj.id);

		const retUpdateCliente = await api.customers.update.put({
			id: dadosObj.id,
			active: dadosObj.status,
		});

		if(retUpdateCliente.status === 200) {
			msg.success('Status atualizado com sucesso');
			setStateClientes([...stateClientes.map(item => {
				if(item.id === dadosObj.id) {
					return {
						...item,
						active: dadosObj.status,
					}
				}
				return item;
			})])
		} else {
			msgs([retUpdateCliente]);
		}
		setLoadingAlterarStatus(false);
	}

	/*
	** Variaveis de acordo com o tamanho da tela
	** ================================================== */
	var winPosTooltipDireito = tamanhoTela([
		{width: 980, dados: {placement: 'bottom-center'}},
	], {placement: 'right-center'}, screen);

	var winPosTooltipEsquerdo = tamanhoTela([
		{width: 980, dados: {placement: 'bottom-center'}},
	], {placement: 'left-center'}, screen);

	return (
		<div className="pg-clientes base-full">
			<div className="conteudo-box">
				<div className="titulo-box">
					<div className="flex flex-center-v">
						<h3 className="titulo">Clientes</h3>
					</div>
					<Btn
						_={{
							value: 'Cadastrar cliente',
							onClick: () => showPortal({portal: 'cadastro', stateGet, navigate})
						}}
						size="m"
					/>
				</div>

				<FiltrosWrap>
					<form
						onSubmit={e => e.preventDefault()}
						className="flex flex-wrap wrap-5"
					>
						{store.usuario.role === 1 ? (
							<div className="input-box flex-1">
								<Select2
									label="Empresa"
									_={{
										value: stateGet.id_company,
										options: stateEmpresas,
										onChange: e => {
											setGetNavigate({
												gets: {
													id_company: e.value,
													name: '',
													name_responsible: '',
													status: null,
													page: 1,
												},
												navigate,
												stateGet,
												location,
											});
										},
									}}
									loading={loadingGetEmpresas}
								/>
							</div>
						) : null }
						<div className={`input-box box-1-980 ${store.usuario.role === 1 ? 'flex-1' : 'flex-1'}`}>
							<Input2
								label="Pesquisar cliente"
								_={{
									value: stateGet.name,
									onChange: e => {
										setGetNavigate({
											gets: {name: e.target.value},
											navigate,
											stateGet,
											location,
										});
									}
								}}
							/>
						</div>
						<div className={`input-box box-1-980 ${store.usuario.role === 1 ? 'flex-1' : 'flex-1'}`}>
							<Input2
								label="Pesquisar responsável"
								_={{
									value: stateGet.name_responsible,
									onChange: e => {
										setGetNavigate({
											gets: {name_responsible: e.target.value},
											navigate,
											stateGet,
											location,
										});
									}
								}}
							/>
						</div>
						<div className="input-box flex-1 box-1-980 input-box-btnlimpar">
							<Select2
								label="Status"
								_={{
									value: stateGet.active,
									onChange: e => {
										setGetNavigate({
											gets: {active: e ? e.value : null},
											forceCamposVazios: {active: e ? e.value : null},
											navigate,
											stateGet,
											location,
										});
									},
									options: STATUS_FILTRO,
									isClearable: true,
								}}
								loading={loadingGetClientes}
								wrap="flex-1"
							/>
						</div>
						<div className="input-box w-250 box-1-980 input-box-btnlimpar">
							<Select2
								label="Resultados por página"
								_={{
									value: stateGet.limit,
									onChange: e => {
										setGetNavigate({
											gets: {limit: e.value},
											navigate,
											stateGet,
											location,
										});
									},
									options: FILTROS_RESULTADOS,
								}}
								wrap="flex-1"
							/>
							<BtnLimparFiltros
								_={{
									onClick: e => {
										setGetNavigate({
											gets: {
												name: '',
												status: null,
												limit: 20,
											},
											navigate,
											stateGet,
											location,
										});
									},
								}}
							/>
						</div>
					</form>
				</FiltrosWrap>

				<div id="tabela-container">
					<TotalPaginacao {...{stateGet, stateMetadata: stateClientesMetadata, loading: loadingGetClientes, getsDefault}} />
					<div className="table-wrap">
						<table className="tabela">
							<thead>
								<tr>
									<th className="col-id">
										<Tip tip="ID">ID único do projeto</Tip>
									</th>
									<th className="">
										<Tip tip="Cliente">Nome do cliente</Tip>
									</th>
									<th className="">
										<Tip tip="Responsável">Responsável por essa empresa</Tip>
									</th>
									<th className="col-min-3">
										<Tip tip="Site">Site da empresa</Tip>
									</th>
									<th className="col-min-3">
										<Tip tip="Projetos">Quantidade de projetos da empresa</Tip>
									</th>
									<th className="col-min-3">
										<Tip tip="Anexos">Quantidade de anexos</Tip>
									</th>
									<th className="col-min-3">
										<Tip tip="Status">Status da empresa</Tip>
									</th>
									<th className="col-acao">
										<Tip tip="Ação">Ação</Tip>
									</th>
								</tr>
							</thead>
							<tbody>
								<PlaceholderRender
									loading={loadingGetClientes}
									init={init}
									itens={stateClientes}
									request={_stateClientes}
									cpnt="PlaceholderClientes"
									qtdColunas={8}
								>
									{stateClientes.map((val, key) => {
										return (
											<tr key={key}>

												<td data-titulo="ID" className="col-id">
													<div className="td-wrap">{val.id}</div>
												</td>

												<td data-titulo="Cliente">
													<div className="td-wrap">
														<ImgTituloDesc
															wrap="a td-info"
															titulo={val.name}
															desc={val.cpf_cnpj}
															img={val.logo ? caminhoFoto(val.logo, imgIconeEmpresaAlt2).url : imgIconeEmpresaAlt2}
															classNameTitulo="td-titulo"
															classNameDesc="td-desc"
															classNameImgBox="foto-wrap-square"
															responsivo={true}
															onClick={() => showPortal({portal: 'detalhes', dados: val.id, stateGet, navigate})}
															_tipDefaults={winPosTooltipDireito}
														/>
													</div>
												</td>

												<td data-titulo="Responsável">
													<div className="td-wrap">
														<ImgTituloDesc
															wrap="a td-info"
															titulo={<>{val.name_responsible}{val.position_responsible ? <span className="color-1 fw-normal"> - ({val.position_responsible})</span> : ''}</>}
															desc={val.email_responsible}
															img={`https://www.gravatar.com/avatar/${md5(val.email_responsible)}?d=blank&s=60`}
															imgPreview={`https://www.gravatar.com/avatar/${md5(val.email_responsible)}?d=blank&s=200`}
															placeholder='userpicture'
															classNameTitulo="td-titulo"
															classNameDesc="td-desc"
															classNameImgBox="foto-wrap"
															responsivo={true}
															onClick={() => showPortal({portal: 'responsavel', dados: val.id, stateGet, navigate})}
															_tipDefaults={winPosTooltipDireito}
														/>
													</div>
												</td>

												<td data-titulo="Site">
													<div className="td-wrap align-center">
														{val.site && val.site !== '' ? (
															<Tip
																tip={
																	<div
																		className="a"
																		onClick={() => showPortal({portal: 'site', dados: val.site, stateGet, navigate})}
																	>
																		<Icone
																			imagem={imgIconeInfo}
																			cor="#0277BD"
																			className="margin-auto cursor-pointer"
																		/>
																		<div className="td-desc-alt">Site</div>
																	</div>
																}
																_defaults={winPosTooltipEsquerdo}
															>
																<a href={addPrefixo(val.site)} target="_blank" rel="noreferrer">{val.site}</a>
															</Tip>
														) : <>-</>}
													</div>
												</td>

												<td data-titulo="Projetos">
													<div className="td-wrap align-center">
														{val.count_projects && val.count_projects !== '' ? (
															<Link className="a" to={`/projetos?id_company=${val.id_company}&id_customer=${val.id}`}>
																<Icone
																	imagem={imgIconeProjetos}
																	cor="#555"
																	className="margin-auto cursor-pointer"
																/>
																<div className="td-desc-alt">{val.count_projects}</div>
															</Link>
														) : <>-</>}
													</div>
												</td>

												<td data-titulo="Anexos">
													<div className="td-wrap align-center">
														{val.count_attachs && val.count_attachs !== '' ? (
															<div className="a">
																<Icone
																	imagem={imgIconeAnexo}
																	cor="#555"
																	className="margin-auto cursor-pointer"
																	onClick={() => showPortal({portal: 'anexos', dados: val.id, stateGet, navigate})}
																/>
																<div className="td-desc-alt">{val.count_attachs}</div>
															</div>
														) : <>-</>}
													</div>
												</td>

												<td data-titulo="Status" className="relative">
													<div className="td-wrap scroll-visible align-center">
														<Tip
															tip={
																<div>
																	<Status
																		title="Alterar status"
																		imagem={imgIconeStatus}
																		cor="#555"
																		className="margin-auto cursor-pointer"
																		status={val.active ? 1 : 2}
																	/>
																	<div className="td-desc-alt cursor-pointer">{val.active ? 'Ativo' : 'Inativo'}</div>
																</div>
															}
															click={true}
															show={loadingAlterarStatus === val.id ? true : false}
															_defaults={winPosTooltipEsquerdo}
														>
															<Select3Lista
																titulo={<h3 className="margin-0">Status do projeto</h3>}
																options={[
																	{value: 1, label: 'Status ativo'},
																	{value: 0, label: 'Status inativo'},
																]}
																optionsType="radio"
																optionsChecked={{
																	value: val.active,
																}}
																onClick={e => {
																	alterarStatusCliente({
																		id: val.id,
																		status: e.value,
																		nomeStatus: e.label,
																		porcentagem: val.percentage_completed,
																	})
																}}
																loading={loadingAlterarStatus === val.id ? true : false}
																labelClass="flex-1"
																posicao=""
																classNameDefault=""
															/>
														</Tip>
													</div>
												</td>

												<td data-titulo="Ação" className="relative">
													<div className="td-wrap align-center">
														<Icone
															imagem={imgIconeEditar}
															cor="#555"
															className={`margin-auto cursor-pointer`}
															onClick={() => showPortal({portal: 'edicao', dados: val.id, stateGet, navigate})}
														/>
													</div>
												</td>

											</tr>
										)
									})}
								</PlaceholderRender>
							</tbody>
						</table>
					</div>
					<TotalPaginacao {...{stateGet, stateMetadata: stateClientesMetadata, loading: loadingGetClientes, getsDefault}} />
				</div>
			</div>

			<PortalClientesFormCadastro
				show={isShowPortal({portal: 'cadastro', stateGet})}
				onClose={() => closePortal({portal: 'cadastro', stateGet, navigate})}
				onSuccess={() => {
					closePortal({portal: 'cadastro', stateGet, navigate});
					getClientes();
				}}
				closeOnEsc={false}
			/>

			<PortalClientesFormEdicao
				show={isShowPortal({portal: 'edicao', stateGet})}
				id={getPortalDados({portal: 'edicao', stateGet})}
				onClose={() => closePortal({portal: 'edicao', stateGet, navigate})}
				onSuccess={() => {
					closePortal({portal: 'edicao', stateGet, navigate});
					getClientes();
				}}
				closeOnEsc={false}
			/>

			<PortalClientesDetalhes
				show={isShowPortal({portal: 'detalhes', stateGet})}
				id={getPortalDados({portal: 'detalhes', stateGet})}
				onClose={() => closePortal({portal: 'detalhes', stateGet, navigate})}
			/>

			<PortalClientesDetalhesResponsavel
				show={isShowPortal({portal: 'responsavel', stateGet})}
				id={getPortalDados({portal: 'responsavel', stateGet})}
				onClose={() => closePortal({portal: 'responsavel', stateGet, navigate})}
			/>

			<PortalClientesDetalhesAnexos
				show={isShowPortal({portal: 'anexos', stateGet})}
				id={getPortalDados({portal: 'anexos', stateGet})}
				onClose={() => closePortal({portal: 'anexos', stateGet, navigate})}
			/>

			<PortalPreviewSite
				show={isShowPortal({portal: 'site', stateGet})}
				url={getPortalDados({portal: 'site', stateGet})}
				onClose={() => closePortal({portal: 'site', stateGet, navigate})}
			/>

		</div>
	);
}

export default Clientes;