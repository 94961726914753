import React from 'react';

const Qtd = props => {
	const {
		qtd = 0,
		vazio = 'Nenhum item',
		singular = 'item',
		plural = 'itens',
		className = 'color-1 fs-14 fw-400',
		wrap = '',
		exibirVazio = true,
		before,
		after,
	} = props;

	if(!exibirVazio && qtd <= 0) {
		return <></>;
	}

	return (
		<span className={`cpnt-qtd ${wrap}`}>
			{before}
			<span className={`cpnt-qtd-box ${className}`}>
				{qtd > 0 ? (
					<>
						<strong>{qtd}</strong>
						<span className="margin-l-5">{qtd === 1 ? singular : plural}</span>
					</>
				) : (
					<span>{vazio}</span>
				)}
			</span>
			{after}
		</span>
	);
};

export default Qtd;
