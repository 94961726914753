import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { toast as msg } from 'react-toastify';

// API
import apiRedefinition from '@api/redefinition/redefinition';

// Components
import Input from '@cpnt/Input/Input';
import OverlayConteudo from '@cpnt/OverlayConteudo/OverlayConteudo';
import Button2 from '@cpnt/Button2/Button2';

// Imagens
import imgLogo from '@img/logo.png';
import imgIconeErro from '@_img/default/icone-erro.png';
import imgLoading from '@imgdefault/loading-azul.svg';

// Funções
import { camposInvalidos, validar200 } from '@func/utils';
import { msgs, msgStatus } from "@_func/request";

const RedefinirSenha = props => {

	const [loading, setLoading] = useState(false);
	const [overlaySucesso, setOverlaySucesso] = useState(false);
	const [tokenValido, setTokenValido] = useState(null);
	const [tokenInvalidoMsg, setTokenInvalidoMsg] = useState(false);
	const [passwordInvalido, setPasswordInvalido] = useState(false);

	useEffect(() => {
		validarToken();
	}, []);

	const validarToken = async e => {
		const q = queryString.parse(window.location.search);
		const retValidarToken = await apiRedefinition.redefinirSenha.get(q.token);

		if(validar200([retValidarToken])) {
			setTokenValido(true);
			msg.info('Token validado com sucesso, insira sua nova senha para continuar');
		} else {
			setTokenValido(false);
			setTokenInvalidoMsg(retValidarToken.error);
			msgs([retValidarToken]);
		}
	}

	const salvarNovaSenha = async e => {
		e.preventDefault();

		if(loading) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const q = queryString.parse(window.location.search);

		const el = e.target.elements;
		const dados = {
			password: el.password.value,
		};

		if(camposInvalidos(dados, {password: 'Nova senha'})) {
			setPasswordInvalido(true);
			return;
		}

		setLoading(true);

		const retRedefinirSenha = await apiRedefinition.redefinirSenha.put(dados, q.token);

		if(validar200([retRedefinirSenha])) {
			setOverlaySucesso(true);
			msg.info(retRedefinirSenha.message);
		} else {
			msgs([retRedefinirSenha]);
		}

		setLoading(false);
	}

	return (
		<div>
			<div className="tela-inicio-e-inner">

				<OverlayConteudo
					toggle={e => setTokenValido(false)}
					exibir={tokenValido === true ? false : true}
					exibirFechar={false}
					icone={tokenValido === false ? imgIconeErro : imgLoading}
					texto={
						<div>
							<div>
								{tokenInvalidoMsg ? tokenInvalidoMsg : 'Validando token, aguarde...'}
							</div>
							<div className="margin-t-20">
								<Link to="/recuperar-senha">Recuperar senha</Link>
							</div>
						</div>
					}
				/>

				<OverlayConteudo
					toggle={e => setOverlaySucesso(e)}
					exibir={overlaySucesso}
					exibirFechar={false}
					texto={
						<div>
							<div>Senha alterada com sucesso!</div>
							<div className="margin-t-20">
								<Link to="/login">Ir para login</Link>
							</div>
						</div>
					}
				/>

				<div className="tela-inicio-e-logo padding-t-30 padding-b-30">
					<img src={imgLogo} alt="Logo CROWD" className="block w-100p" />
				</div>
				<h1 className="tela-inicio-e-titulo padding-t-20">Redefinir Senha</h1>
				<form className="tela-inicio-form" methoed="POST" action="/meus-jobs" onSubmit={salvarNovaSenha}>
					<div className="input-box">
						<Input
							name="password"
							type="password"
							label='Insira a nova senha*'
							invalid={passwordInvalido}
							invalidToggle={e => setPasswordInvalido(false)}
						/>
					</div>
					<div className="input-box">
						<Button2
							_={{
								value: 'Redefinir senha',
								className: 'btn btn-default flex-1 w-100p'
							}}
							wrap="flex flex-1"
							loading={loading}
						/>
					</div>
				</form>
				<div className="margin-t-30 fs-13 padding-b-30">
					<Link to="/" className="no-decoration bold">Voltar para o início</Link>
				</div>
			</div>
		</div>
	)
}

export default RedefinirSenha;