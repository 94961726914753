import React from 'react';

// CSS
import './StatusBriefing.css';

// Components
import Icone from '@cpnt/Icone/Icone';

// Imagens
import imgIconeNaoContratado from '@svg/solid/user-xmark.svg';

import imgIconeAndamento from '@svg/solid/circle-play.svg';
import imgIconePausado from '@svg/solid/circle-pause.svg';
import imgIconeContratado from '@svg/solid/circle-check.svg';
import imgIconeArquivado from '@svg/regular/box-taped.svg';
import imgIconeRascunho from '@svg/solid/file-pen.svg';
import imgIconeDesconhecido from '@svg/solid/file.svg';

export const iconeStatusBriefing = id => {
	// IDS dos status do briefing

	// id: 4 => "Em Andamento"
	// id: 5 => "Pausado"
	// id: 6 => "Contratado"
	// id: 7 => "Arquivado"
	// id: 8 => "Em rascunho"

	const imagens = {
		// Custom
		id1: {icone: imgIconeNaoContratado, cor: '#E74C3C'},

		// Default
		id4: {icone: imgIconeAndamento, cor: '#229d3e'},
		id5: {icone: imgIconePausado, cor: '#eab20e'},
		id6: {icone: imgIconeContratado, cor: '#2596e1'},
		id7: {icone: imgIconeArquivado, cor: '#676767'},
		id8: {icone: imgIconeRascunho, cor: '#e7843c'},
	};

	if(imagens[`id${id}`] !== undefined) {
		return imagens[`id${id}`];
	}

	return {
		icone: imgIconeDesconhecido,
		cor: '#CCC',
	};
}

const StatusBriefing = props => {

	const {
		status = 0,
		className = '',
		onClick = () => null,
	} = props;

	const iconeMaisCor = iconeStatusBriefing(status);

	return (
		<div className={`cpnt-statusbriefing ${className}`} onClick={onClick}>
			<div className="cpnt-statusbriefing-icones">
				<Icone imagem={iconeMaisCor.icone} cor={iconeMaisCor.cor} tamanho="22px" />
			</div>
		</div>
	);
};

export default StatusBriefing;
