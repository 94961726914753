import { MANUTENCAO } from "@_config/config";
import { gets } from "@route/routeUtils";

/*
** Salva a sessão do usuário
** ================================================== */
export const salvarSessaoLocal = props => {
	const dados = {
		token: '',
		usuario: {},
		...props,
	};

	var sucesso = false;

	try {
		localStorage.setItem('@crowdplataforma/token', dados.token);
		localStorage.setItem('@crowdplataforma/usuario', JSON.stringify(dados.usuario));
	} catch (error) {
		sucesso = false;
	}
	return sucesso;
};

/*
** Apaga s sessão do usuário
** ================================================== */
export const apagarSessaoLocal = () => {
	var sucesso = false;
	try {
		localStorage.removeItem('@crowdplataforma/usuario');
		localStorage.removeItem('@crowdplataforma/token');
		localStorage.removeItem('@crowdplataforma/avisomobile');
		localStorage.removeItem('@crowdplataforma/acessoativo');
		localStorage.removeItem('@crowdplataforma/notificacaotopo');
		sucesso = true;
	} catch (error) {
		sucesso = false;
	}
	return sucesso;
};

/*
** Verifica se está logado
** ================================================== */
export const isLogado = () => {

	var token = localStorage.getItem('@crowdplataforma/token');
	var dados = localStorage.getItem('@crowdplataforma/usuario');

	if(!token || token === '' || !dados || dados === '') {
		return false;
	}

	try {
		dados = JSON.parse(dados);
	} catch (error) {
		return false;
	}

	return {
		token: token,
		usuario: dados,
	}
}

/*
** Verifica se está em manutenção
** ================================================== */
export const exibirPaginaManutencao = () => {
	if(MANUTENCAO) {
		var get = gets();

		if(get.bypass === 'false') {
			localStorage.removeItem('@crowdplataforma/bypassmanutencao');
			return true;
		}

		if(get.bypass === 'crowd') {
			localStorage.setItem('@crowdplataforma/bypassmanutencao', 'bypass');
			return false;
		}
		var manutenc = localStorage.getItem('@crowdplataforma/bypassmanutencao');
		if(manutenc && manutenc !== '') {
			return false;
		}

		return true;
	}
	return false;
}
