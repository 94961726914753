import React from 'react';

// Componentes
import Input2 from "@cpnt/Input2/Input2";
import Tip from "@cpnt/Tip/Tip";

const PtRemuneracao = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		stateDados = {},
		setStateDados,
	} = props;

	return (
		<>
			<div className={`cpnt-remun ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-remun-box ${className}`}>
					<Tip
						tip={
							<h3 className="margin-b-5">Remuneração <span className="q"></span></h3>
						}
						info
					>
						Na Crowd temos diferentes tipos de jobs, sendo eles a curto, médio e longo prazo, e também jobs pontuais, onde o orçamento é realizado em horas de trabalho.
					</Tip>

					<div className="flex flex-center-v margin-t-15">
						<Input2
							label="Valor em reais"
							req
							dinheiro
							_={{
								value: stateDados.price,
								onChange: e => {
									setStateDados({
										...stateDados,
										price: e,
									});
								}
							}}
						/>
						<Tip
							titulo="Como calcular"
							tip={
								<div className="fs-14">
									<span>por hora</span>
									<span className="q"></span>
								</div>
							}
							info
							classNameTipDefault="margin-l-10"
						>
							A remuneração que você deseja receber por mês dividido por 120.
						</Tip>
					</div>
				</div>
			</div>
		</>
	);
};

export { PtRemuneracao };
