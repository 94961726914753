import React from 'react';

// css
import './TimelineVertical.css';

const TimelineVertical = props => {
	const {
		label,
		titulo,
		subtitulo,
		subtitulo2,
		descricao,
		primeiro,
		qtd,
		ultimo,
	} = props;

	const classBox = () => {
		var retorno = 'cpnt-timelinevert';

		if(primeiro) {
			retorno += ' cpnt-timelinevert-primeiro';
		} else if(ultimo) {
			retorno += ' cpnt-timelinevert-ultimo';
		} else if(!primeiro && !ultimo) {
			retorno += ' cpnt-timelinevert-geral';
		}

		if(primeiro && qtd === 1) {
			retorno += ' cpnt-timelinevert-primeiro-single';
		}

		return retorno;
	}

	return (
		<div className={classBox()}>
			<div className="cpnt-timelinevert-label">
				<div className="cpnt-timelinevert-label-wrap">
					<div className="cpnt-timelinevert-label-btn">{label}</div>
				</div>
			</div>
			<div className="cpnt-timelinevert-linha"></div>
			<div className="cpnt-timelinevert-conteudo">
				<h3 className="cpnt-timelinevert-titulo">{titulo}</h3>
				{subtitulo && subtitulo !== '' ? <div className="cpnt-timelinevert-subtitulo">{subtitulo}</div> : null}
				{subtitulo2 && subtitulo2 !== '' ? <div className="cpnt-timelinevert-subtitulo2">{subtitulo2}</div> : null}
				{descricao && descricao !== '' ? <div className="cpnt-timelinevert-descricao">{descricao}</div> : null}
			</div>
		</div>
	);
};

export default TimelineVertical;