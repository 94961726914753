import React from 'react';

// Imagens
import imgIconeDoubleCheck from '@svg/outros/doublecheck.svg';
import imgIconePDF from '@svg/solid/file-pdf.svg';
import imgIconeXML from '@svg/solid/file-code.svg';

// Componentes
import Icone from '@cpnt/Icone/Icone';
import LinhaItem from '@cpnt/LinhaItem/LinhaItem';

// Funções
import { caminhoUrl, dataApiParaBrExtenso, dataHaQuantoTempo } from '@func/utils';

const ChatMensagemNotaFiscal = props => {
	const {
		metadata = {},
		wrap = '',
		className = '',
		mensagem,
		dataExtenso,
		propria,
		name,
		tipo,
		mensagemLida,
	} = props;

	return (
		<div className={`cpnt-chatmsg cpnt-chatmsg-posicao-center cpnt-chatmsg-tipo-${metadata.tipo ? metadata.tipo : 'default'} ${wrap}`}>
			<div className={`cpnt-chatmsg-mensagem-box cpnt-chatmsg-mensagem-box-center ${className}`}>
				{metadata.titulo && metadata.titulo !== '' ? (
					<div className={`cpnt-chatmsg-custom-titulo`}>
						<h3 className="margin-0 padding-0">{metadata.titulo}</h3>
						<div className="fs-14 color-1">{name} enviou a Nota Fiscal</div>
					</div>
				) : null}
				<div className="cpnt-chatmsg-mensagem">
					<div className="cpnt-chatmsg-mensagem-infoicone">
						<div className="cpnt-chatmsg-mensagem-infoicone-icone">
							<Icone
								imagem={tipo === 'nota-fiscal-xml' ? imgIconeXML : imgIconePDF}
								cor={tipo === 'nota-fiscal-xml' ? '#0277bd' : '#ad0b00'}
								tamanho="55px"
							/>
						</div>
						<div className="cpnt-chatmsg-mensagem-infoicone-info">
								<LinhaItem
									titulo="Enviado em"
									desc={dataApiParaBrExtenso(props.created_at)}
									alt
								/>
								<LinhaItem
									titulo="Enviado há"
									desc={dataHaQuantoTempo(props.created_at)}
									alt
								/>
								<LinhaItem
									titulo="Arquivo"
									desc={<a href={caminhoUrl(mensagem)} target="_blank" rel="noreferrer">{metadata.notaFiscal.tipoExibicao}</a>}
									alt
								/>
						</div>
					</div>
				</div>
				<div className={`cpnt-chatmsg-footer cpnt-chatmsg-footer-center`}>
					<span>{dataExtenso}</span>
					{propria ? (
						<span className="margin-l-10">
							<Icone
								imagem={imgIconeDoubleCheck}
								cor={mensagemLida ? '#2596e1' : '#D5D5D5'}
								tamanho="14px"
							/>
						</span>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default ChatMensagemNotaFiscal;