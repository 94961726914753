import React, { useEffect, useState, memo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';

// css
import './ChatSidebar.css';

// Imagens
import imgIconeFiltro from '@svg/light/filter-list.svg';

// Componentes
import ChatListaItem from '../ChatListaItem/ChatListaItem';
import Loading from '@cpnt/Loading/Loading';

// Funções
import { stringPesquisa } from '@func/utils';
import Radio2 from '@cpnt/Radio2/Radio2';
import Select3 from '@cpnt/Select3/Select3';
import Icone from '@cpnt/Icone/Icone';

// Config
import { DEV } from '@_config/config';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';
import { Btn } from "@_cpnt/Btn/Btn/Btn";

const ChatSidebar = props => {
	const {
		className = '',
		wrap = '',
		header,
		footer,

		mensagens = [],
		mensagensInfo = {},
		onClickMensagemSidebar = () => null,
		filtro,
		onClickFiltro = () => null,

		isDigitando,
		setIsDigitando = () => null,
		isDigitandoInfo,
		loading,
	} = props;

	const [stateLista, setStateLista] = useState(mensagens);
	const [stateListaExibicao, setStateListaExibicao] = useState(mensagens);

	useEffect(() => {
		setStateLista(mensagens);
	}, [mensagens]);

	/*
	** States
	** ================================================== */
	const filtrarItens = termo => {
		var textoUsuario = stringPesquisa(termo);

		var itensFiltrados = mensagens.filter(value => {
			var textoExistente = stringPesquisa(value.name);
			if(textoExistente.indexOf(textoUsuario) > -1) {
				return textoExistente;
			}
			return null;
		});

		setStateLista(itensFiltrados);
	}

	const Row = ({ index, style }) => {
		if(filtro === 1 && index === stateLista.length) {
			return avisoListaVazia({
				style: style,
				key: index,
			});
		}

		// <div style={style}>Row {index}</div>
		var val = stateLista[index];

		return (
			<div
				style={style}
				key={index}
			>
				<ChatListaItem
					onClick={() => onClickMensagemSidebar(val)}
					{...val}
					isDigitando={isDigitando}
					setIsDigitando={setIsDigitando}
					isDigitandoInfo={isDigitandoInfo}
				/>
			</div>
		)

	}

	// // Use these actual sizes to calculate your percentage based sizes
	// stateLista.length ? stateLista.map((val, key) => {
	// 	return (
	// 		<ChatListaItem
	// 			onClick={() => onClickMensagemSidebar(val)}
	// 			key={key}
	// 			{...val}
	// 			isDigitando={isDigitando}
	// 			setIsDigitando={setIsDigitando}
	// 			isDigitandoInfo={isDigitandoInfo}
	// 		/>
	// 	)
	// }) : (
	// 	<div className="cpnt-chatsidebar-semresultado">Nenhum usuário encontrado.</div>
	// )

	const tamanhoColuna = (a) => {
		return 74;
	}

	const selectFiltro = () => {
		return (
			<Select3
				customLabel={
					<TextoIconeLink
						texto="Filtrar conversas"
						icone={imgIconeFiltro}
						iconeCor="#0277BD"
					/>
				}
				posicao="bottom"
				options={[
					{value: 2, status: 2, label: 'Todas as mensagens'},
					{value: 1, status: 1, label: 'Mensagens com interações'},
				]}
				optionsType="radio"
				optionsChecked={{
					value: filtro,
				}}
				onClick={e => {
					onClickFiltro({
						value: e.value,
						status: e.status,
						who: e.who,
					});
				}}
				closeOnClick={true}
				loading={loading}
				classNameDropdown="no-wrap"
				// showDropdown={loading ? true : false}
			/>
		)
	}

	const avisoListaVazia = ({style, key}) => {
		return (
			<div key={key} style={style} className={`cpnt-chatsidebar-info-vazio box-txt`}>
				<p>Estamos exibindo apenas as conversas dos profissionais que já interagiram.</p>
				<div>
					{selectFiltro()}
				</div>
			</div>
		)
	}

	return (
		<div className={`cpnt-chatsidebar ${wrap}`}>
			<div className={`cpnt-chatsidebar-box ${className}`}>
				{header ? (
					<div className="cpnt-chatsidebar-header">
						{header && header}
					</div>
				) : null}
				<div className="cpnt-chatsidebar-pesquisa-wrap">
					<div className="cpnt-chatsidebar-pesquisa-box">
						<input
							type="search"
							className="cpnt-chatsidebar-pesquisa"
							placeholder="Pesquisar"
							onChange={e => filtrarItens(e.target.value)}
						/>
					</div>
				</div>

				<div className="cpnt-chatsidebar-countfiltro relative">
					<div>Conversas encontradas: <strong>{stateLista.length}</strong></div>
					{selectFiltro()}
				</div>

				<div className="cpnt-chatsidebar-lista scroll scroll-alt relative">
					{loading ? (
						<div className="padding-15 flex flex-center">
							<Loading texto="Carregando conversas, aguarde..." />
						</div>
					) : (
						<AutoSizer>
							{({ height, width, ...rest }) => {
								return (
									<List
										height={height}
										itemCount={stateLista.length + (filtro === 1 ? 1 : 0)}
										itemSize={tamanhoColuna}
										width={width}
									>
										{Row}
									</List>
								)

							}}
						</AutoSizer>
					)}
				</div>

				{footer ? (
					<div className="cpnt-chatsidebar-footer">
						{footer}
					</div>
				) : null}
			</div>
		</div>
	);
};

export default memo(ChatSidebar);