import { caminhoFoto, formatarTelefone } from "@func/utils";
import React from 'react';

// css
import './PropostaJobComplementoForm.css';

import imgIconeAtualizar from '@svg/regular/rotate.svg';

import { BtnAdd } from "@cpnt/BtnAdd/BtnAdd";
import { Editor } from "@_cpnt/Inputs/Editor/Editor";
import Icone from "@cpnt/Icone/Icone";
import { ImgDesc } from "@cpnt/ImgDesc/ImgDesc";
import Input2 from "@cpnt/Input2/Input2";
import LinhaItem from "@cpnt/LinhaItem/LinhaItem";
import { onClickExibirTodosSalvoELocal, onClickExibirTodosSalvos, onClickLimparListaSalva, onClickSelectListaLocal } from "@cpnt/ListaProfissionais/ListaProfissionaisAcoes/ListaProfissionaisAcoes";
import ListaTituloDesc from "@cpnt/ListaTituloDesc/ListaTituloDesc";
import Loading from "@cpnt/Loading/Loading";
import { PortalTemplates } from "@cpnt/Portais/PortaisTemplates/PortalTemplates/PortalTemplates";
import { ProfissionaisSelecionados } from '@cpnt/ProfissionaisSelecionados/ProfissionaisSelecionados';
import { Select2 } from "@cpnt/Select2/Select2";
import Tip from "@cpnt/Tip/Tip";
import { onClickRemover } from "@func/selecaoUsuariosBriefings";
import { closePortal, isShowPortal, showPortal } from "@route/routeUtils";

const PropostaJobComplementoForm = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		stateProfissionais = [],
		setStep,
		stateSelecionados = [],
		stateUsuarios = [],
		loadingGetHistorico,
		stateHistorico = [],
		getHistorico,
		exibirHistorico,

		stateDados,
		setStateDados,

		stateSelecionadosAnteriormente,
		setStateSelecionadosAnteriormente,

		stateDescricaoBriefing,
		setStateDescricaoBriefing,
		setStateIsDescricaoAlterada,

		stateGet,
		navigate,

		// Lista
		stateListaAtual,
		setStateListaAtual,
		stateListas,
		setStateListas,
		setStateSelecionados,
	} = props;

	return (
		<>
			<div className={`cpnt-complprocselform ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-complprocselform-box ${className}`}>
					<div className="cpnt-complprocselform-topimgs">
						<ProfissionaisSelecionados
							before={
								<Tip
									titulo="Responsável pelo projeto"
									tip={
										<ImgDesc
											wrap="cpnt-profsel-imgdesc a td-info"
											titulo="Responsável"
											img={caminhoFoto(stateDados.user_photo).url}
											preview={false}
											onClick={() => showPortal({portal: 'perfil-usuario', dados: stateDados.id_user, stateGet, navigate})}
											classNameImgBox="foto-wrap-2"
											tamanho="46px"
											classNameWrap="relative"
										/>
									}
								>
									<LinhaItem
										titulo="Nome"
										desc={stateDados.user_name || 'Não informado'}
										alt
									/>
									<LinhaItem
										titulo="Posição"
										desc={stateDados.user_position || 'Não informado'}
										alt
									/>
									<LinhaItem
										titulo="Cargo"
										desc={stateDados.user_role_company || 'Não informado'}
										alt
									/>
									<LinhaItem
										titulo="E-mail"
										desc={stateDados.user_email || 'Não informado'}
										alt
									/>
									<LinhaItem
										titulo="Telefone"
										desc={stateDados.user_phone ? formatarTelefone(stateDados.user_phone) : 'Não informado'}
										alt
									/>
								</Tip>
							}
							stateGet={stateGet}
							navigate={navigate}
							classNameWrap="cpnt-profsel-sticky padding-v-5"

							stateListaAtual={stateListaAtual}
							setStateListaAtual={setStateListaAtual}
							stateListas={stateListas}
							setStateListas={setStateListas}
							stateSelecionados={stateSelecionados}
							setStateSelecionados={setStateSelecionados}
							stateSelecionadosAnteriormente={stateSelecionadosAnteriormente}
							setStateSelecionadosAnteriormente={setStateSelecionadosAnteriormente}

							textoEdicaoAnterior="Esses profissionais já fazem parte da proposta de job. Não é possível removê-los da proposta atual."
							showBtnRemover={false}

							onClickSelectListaLocal={e => onClickSelectListaLocal({
								dados: e,
								stateGet,
								navigate,
							})}
							onClickRemover={e => {
								onClickRemover({dados: e, stateListaAtual, setStateSelecionados})
								setStateSelecionadosAnteriormente(stateSelecionadosAnteriormente.filter(item => item.id_freelancer !== e.id));
							}}
							onClickLimparListaSalva={e => onClickLimparListaSalva({
								stateGet,
								navigate,
							})}
							onClickExibirTodosSalvoELocal={e => {
								onClickExibirTodosSalvoELocal({
									stateGet,
									navigate,
								})
							}}
							onClickExibirTodosSalvos={e => {
								onClickExibirTodosSalvos({
									stateGet,
									navigate,
								})
							}}
						/>

						{/* <div className="cpnt-propostajobcomp-lista cpnt-propostajobcomp-lista-anteriores">
							<Tip
								titulo="Detalhes da lista"
								tip={
									<>
										<div className="btn-circulo a">
											<div className="btn-circulo-wrap">
												<span>{stateSelecionadosAnteriormente.length > 99 ? '99+' : stateSelecionadosAnteriormente.length}</span>
											</div>
											<div className="td-desc-alt no-wrap">
												Anteriores
											</div>
										</div>
									</>
								}
							>
								Profissionais que já estão inclusos na proposta de job atualmente.
							</Tip>
							{stateSelecionadosAnteriormente.map((val, key) => {
								var _val = formatarGetParaListaProfissional(val);
								return (
									<ItemProfisisonalSelecionadoComImg
										key={key}
										dados={_val}
										stateGet={stateGet}
										navigate={navigate}
										classNameWrap="cpnt-profsel-itens-salvo"
										x={false}
									/>
								)
							})}
						</div>

						<div className="cpnt-propostajobcomp-lista cpnt-propostajobcomp-lista-anteriores">
							<Tip
								titulo="Detalhes da lista"
								tip={
									<>
										<div className="btn-circulo a">
											<div className="btn-circulo-wrap">
												<span>{stateSelecionadosAnteriormente.length > 99 ? '99+' : stateSelecionadosAnteriormente.length}</span>
											</div>
											<div className="td-desc-alt no-wrap">
												Novos
											</div>
										</div>
									</>
								}
							>
								asd
							</Tip>
							{stateSelecionadosAnteriormente.map((val, key) => {
								var _val = formatarGetParaListaProfissional(val);
								return (
									<ItemProfisisonalSelecionadoComImg
										key={key}
										dados={_val}
										stateGet={stateGet}
										navigate={navigate}
										classNameWrap="cpnt-profsel-itens-salvo"
										x={false}
									/>
								)
							})}
						</div> */}

					</div>

					<div className="cpnt-complprocselform-step1-box">
						<div className="cpnt-complprocselform-step1-box1">
							<div className="cpnt-complprocselform-bar">
								<h3 className="titulo margin-0">Dados da proposta</h3>
							</div>
							<div className="input-wrap">
								<div className="wrap-5">
									<div className="flex flex-wrap w-100p">
										<div className="input-box w-25p box-2-1180 box-1-680">
											<Input2
												label="Título"
												req
												_={{
													value: stateDados.title,
													onChange: e => {
														setStateDados({
															...stateDados,
															title: e.target.value,
														});
													},
												}}
											/>
										</div>
										<div className="input-box w-25p box-2-1180 box-1-680">
											<Input2
												label="Budget (R$)"
												_={{
													value: stateDados.budget,
													onChange: e => {
														setStateDados({
															...stateDados,
															budget: e,
														});
													},
												}}
												dinheiro={true}
											/>
										</div>
										<div className="input-box w-25p box-2-1180 box-1-680">
											<Select2
												label="Responsável"
												req
												_={{
													value: stateDados.id_user,
													options: stateUsuarios,
													onChange: e => {
														setStateDados({
															...stateDados,
															user_photo: e.photo,
															id_user: e.id,

															user_name: e.name,
															user_position: '',
															user_role_company: e.role_company,
															user_email: e.email,
															user_phone: e.phone,
														})
													},
													isClearable: false,
												}}
												virtualized
											/>
										</div>
										<div className="input-box w-25p box-2-1180 box-1-680">
											<Input2
												label="Motivo da edição"
												req
												tip={
													<>
														<div>Informe brevemente o que você está complementando. Esta informação aparecerá no histórico de atualizações desta proposta de job.</div>
														<div className="margin-t-10"><strong>Por exemplo:</strong> Mais detalhes sobre o escopo do job.</div>
													</>
												}
												_={{
													value: stateDados.reason,
													onChange: e => {
														setStateDados({
															...stateDados,
															reason: e.target.value,
														});
													},
												}}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="cpnt-complprocselform-step1-template">
								<div className="cadprocsel-p2-titulo-acao">
									<div className="cadprocsel-p2-titulo-box">
										<h3 className="titulo">Descrição da proposta de job</h3>
										<BtnAdd
											titulo="Selecionar template"
											onClick={() => showPortal({portal: 'template', stateGet, navigate})}
											className="cadprocsel-p2-btn-template"
										/>
									</div>
									<div className="desc">Detalhe aqui as informações essenciais sobre o job para que o prestador de serviços consiga entender a sua necessidade e enviar um orçamento para você. Para facilitar, você pode usar um dos templates disponíveis.</div>
								</div>
								<div className="margin-t-15">
									<Editor
										value={stateDescricaoBriefing}
										setValue={e => {
											setStateDescricaoBriefing(e);
											if(stateDescricaoBriefing !== e) {
												setStateIsDescricaoAlterada(true);
											}
										}}
									/>
								</div>
							</div>
						</div>

						<div className="cpnt-complprocselform-step1-box2">
							<div className="cpnt-complprocselform-bar">
								<h3 className="titulo margin-0">Histórico de atualizações</h3>
							</div>
							<div className="input-wrap">
								<div className="relative">
									{loadingGetHistorico ? <Loading alt absolute texto="" /> : null}
									<div className="flex flex-between flex-center-v">
										<div className="fs-14 color-1">Total de atualizações: {stateHistorico.length}</div>
										<div className="btn-min-2" onClick={e => getHistorico(stateDados.id)}>
											<Icone imagem={imgIconeAtualizar} cor="#2596e1" tamanho="16px" />
											<span className="margin-l-5">Atualizar lista</span>
										</div>
									</div>
								</div>
								<div className="cpnt-complprocselform-step1-historico scroll scroll-alt margin-t-10">
									<ListaTituloDesc
										itens={stateHistorico}
										onClickItem={exibirHistorico}
										loading={loadingGetHistorico}
										// loadingItem={loadingGetItemHistoricoAtualizacoes}
									/>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<PortalTemplates
				idTipo={1}
				show={isShowPortal({portal: 'template', stateGet})}
				onClose={() => closePortal({portal: 'template', stateGet, navigate})}
				onSelect={e => {
					setStateDados({
						...stateDados,
						id_template: e.id,
					});
					setStateDescricaoBriefing(e.text);
					closePortal({portal: 'template', stateGet, navigate})
				}}
			/>
		</>
	);
};

export { PropostaJobComplementoForm };
