import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PerfilAdministrador.css';

// API
import { api } from "@_api/api";

// Imagens
import imgUserPic from '@img/userpic.png';
import imgIconePerfil from '@svg/duotone/id-card.svg';
import imgIconeEditar from '@svg/regular/pen-to-square.svg';

// Componentes
import { apiEditarCompetenciasUsuario, apiEditarHabilidadesUsuario, apiGetAreas, apiGetCompetenciasUsuarioChecked, apiGetHabilidades, apiGetHabilidadesUsuarioChecked } from "@api/_functions/geral";
import { BoxWrap2 } from "@cpnt/BoxWrap2/BoxWrap2";
import { BtnIconeDesc } from "@cpnt/BtnIconeDesc/BtnIconeDesc";
import Button2 from "@cpnt/Button2/Button2";
import ImgTituloDesc from "@cpnt/ImgTituloDesc/ImgTituloDesc";
import Info from "@cpnt/Info/Info";
import LinhaItem from "@cpnt/LinhaItem/LinhaItem";
import { ListaComCheckbox2 } from "@cpnt/ListaComCheckbox2/ListaComCheckbox2";
import { ListaSecao } from "@cpnt/ListaSecao/ListaSecao";
import Loading from "@cpnt/Loading/Loading";
import { PtFoto } from "@cpnt/Partes/PtFoto/PtFoto";
import { PortalCartaApresentacaoUsuarioFormEdicao } from "@cpnt/Portais/PortaisCartasApresentacao/PortalCartaApresentacaoUsuarioFormEdicao/PortalCartaApresentacaoUsuarioFormEdicao";
import { PortalEmailAcessoFormEdicao } from "@cpnt/Portais/PortaisDadosDeAcesso/PortalEmailAcessoFormEdicao/PortalEmailAcessoFormEdicao";
import { PortalSenhaAcessoFormEdicao } from "@cpnt/Portais/PortaisDadosDeAcesso/PortalSenhaAcessoFormEdicao/PortalSenhaAcessoFormEdicao";
import { PortalDadosPessoaisUsuarioEdicao } from "@cpnt/Portais/PortaisDadosPessoais/PortalDadosPessoaisUsuarioEdicao/PortalDadosPessoaisUsuarioEdicao";
import { PortalPerfilUsuario } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilUsuario";
import Portal from "@cpnt/Portal/Portal";
import { Select2 } from "@cpnt/Select2/Select2";
import Tip from "@cpnt/Tip/Tip";

// Funções
import {isLogado, salvarSessaoLocal } from "@_func/auth";
import { arrGetCheckeds, blobToFile, caminhoFoto, dataApiParaBr, dataHaQuantoTempo, extensaoPorMimeType, extensaoString, formatarCNPJ, formatarCPF, formatarTelefone, msg, textoNivelAcesso, validarCnpj, validarCpf, validarDataAPI } from "@func/utils";

import { hash } from "@_func/generate";

// Rota
import { initFuncExec, setGet } from "@route/routeUtils";

// Obj
import { EMAIL_CONTATO } from "@_config/config";
import { LINKS_SECOES_SIDEBAR_USUARIO } from "./perfilObj";
import FiltrosWrap from "@cpnt/FiltrosWrap/FiltrosWrap";
import { PortalFoto } from "@cpnt/Portais/PortaisGerais/PortalFoto/PortalFoto";
import { msgs, msgStatus } from "@_func/request";
import { fotoOuGravatar } from "@_func/profile";

const PerfilAdministrador = props => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const getUsuarioRef = useRef(0);
	const permissaoRef = useRef(false);

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = async (getsAtualizados = getsDefault) => {

		var dadosUsuario = stateDados;

		if(getUsuarioRef.current === 0) {
			setLoadingGetDados(true);
			const retGetDados = await api.users.getOne.get(store.usuario.id);
			_setStateDados(retGetDados);
			if(retGetDados.status === 200) {
				setStateDados(retGetDados.data);
				dadosUsuario = retGetDados.data;
				permissaoRef.current = true;
				getUsuarioRef.current++;
			} else {
				msgs([retGetDados]);
			}
			setLoadingGetDados(false);
		}

		if(permissaoRef.current) {
			var idUsuario = dadosUsuario.id;
			initFuncExec([
				{
					func: getsHabilidades,
					param: dadosUsuario,
					condicao: getsAtualizados.secao === 'habilidades',
				},
				{
					func: apiGetCompetenciasUsuarioChecked,
					param: {idUsuario, idArea: dadosUsuario.id_area, setLoadingGetCompetencias, setStateCompetencias},
					condicao: getsAtualizados.secao === 'competencias',
				},
			], init, location, stateLocation);
		}
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		secao: '',
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	/*
	** Portais
	** ================================================== */
	const [showPortalDadosPessoais, setShowPortalDadosPessoais] = useState(false);
	const [showPortalEmail, setShowPortalEmail] = useState(false);
	const [showPortalSenha, setShowPortalSenha] = useState(false);

	const [showPortalHabilidades, setShowPortalHabilidades] = useState(false);
	const [showPortalCompetencias, setShowPortalCompetencias] = useState(false);
	const [showPortalPerfil, setShowPortalPerfil] = useState(false);
	const [showPortalFoto, setShowPortalFoto] = useState(false);

	const [showPortalCartaApresentacaoProfissional, setShowPortalCartaApresentacaoProfissional] = useState(false);
	const [showPortalCartaApresentacaoPessoal, setShowPortalCartaApresentacaoPessoal] = useState(false);
	/*
	** Loading
	** ================================================== */
	const [loadingGetDados, setLoadingGetDados] = useState(true);
	const [loadingGetHabilidades, setLoadingGetHabilidades] = useState(false);
	const [loadingGetCompetencias, setLoadingGetCompetencias] = useState(false);
	const [loadingGetAreas, setLoadingGetAreas] = useState(false);

	const [loadingEditarHabilidades, setLoadingEditarHabilidades] = useState(false);
	const [loadingEditarCompetencias, setLoadingEditarCompetencias] = useState(false);
	const [loadingEditarFoto, setLoadingEditarFoto] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({
		// Passo 1
		id: store.usuario.id_freelancer,
		about: '',
		access_level: '',
		active: '',
		area: '',
		area_expertise: '',
		birth_date: '',
		city: '',
		company: '',
		complement: '',
		cpf_cnpj: '',
		description: '',
		district: '',
		email: '',
		id_area: '',
		id_area_expertise: '',
		id_company: '',
		id_position: '',
		linkedin: '',
		name: '',
		number: '',
		phone: '',
		photo: '',
		role: '',
		role_company: '',
		role_position: '',
		site: '',
		state: '',
		street: '',
		zip_code: '',
	});
	const [_stateDados, _setStateDados] = useState({});

	const [stateHabilidades, setStateHabilidades] = useState([]);
	const [stateCompetencias, setStateCompetencias] = useState([]);
	const [stateFotoCapturada, setStateFotoCapturada] = useState(false);
	const [stateAreas, setStateAreas] = useState([]);

	const [stateDadosTempHabilidades, setStateDadosTempHabilidades] = useState({
		id_area: '',
		seniority: '',
	});

	/*
	** Salvar foto
	** ================================================== */
	const editarFoto = async e => {

		if(loadingEditarFoto) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		if(!stateFotoCapturada || stateFotoCapturada === '') {
			msg.info('Nenhuma ação necessária.');
			return;
		}

		if(stateFotoCapturada && stateFotoCapturada !== '') {
			const extensao = extensaoString(stateFotoCapturada);
			if(extensao) {
				var foto = await fetch(stateFotoCapturada).then(r => r.blob());

				if(!foto.name) {
					foto = blobToFile(foto, `foto.${extensaoPorMimeType(foto)}`);
				}

				const dadosFormData = new FormData();

				dadosFormData.append('id_user', store.usuario.id);
				dadosFormData.append('photo', foto);

				setLoadingEditarFoto(true);

				const retUploadFoto = await api.users.uploadPhoto.post(dadosFormData);

				if(retUploadFoto.status === 200) {
					const dadosLogado = isLogado();
					salvarSessaoLocal({
						...dadosLogado,
						usuario: {
							...dadosLogado.usuario,
							photo: retUploadFoto.data.url,
						}
					});
					setStore({
						...store,
						usuario: {
							...dadosLogado.usuario,
							photo: `${retUploadFoto.data.url}?id=${hash()}`
						}
					});
					setStateDados({
						...stateDados,
						photo: `${retUploadFoto.data.url}?id=${hash()}`,
					});
					msg.success('Foto atualizada com sucesso');
					setStateFotoCapturada(false);
				} else {
					msgs([retUploadFoto]);
				}
			} else {
				msg.error('Não foi possível identificar o tipo de arquivo.');
			}
		}

		setLoadingEditarFoto(false);
	};

	/*
	** Salvar habilidades e área
	** ================================================== */
	const editarHabilidades = async () => {
		if(arrGetCheckeds(stateHabilidades).length < 3) {
			msg.error('Selecione no mínimo 3 habilidades nessa área.');
			return;
		}

		if(!stateDadosTempHabilidades.id_area || stateDadosTempHabilidades.id_area === '') {
			msg.error('Selecione sua categoria.');
			return;
		}

		setLoadingEditarHabilidades(true);
		const retUpdateUsuario = await api.users.update.put({
			id: store.usuario.id,
			id_area: stateDadosTempHabilidades.id_area,
		});
		if(retUpdateUsuario.status === 200) {
			await apiEditarHabilidadesUsuario({idUsuario: store.usuario.id, loadingEditarHabilidades, setLoadingEditarHabilidades, stateHabilidades});
			setStateDados({
				...stateDados,
				id_area: stateDadosTempHabilidades.id_area,
				name_area: stateDadosTempHabilidades.name_area,
			});
		} else {
			msgs([retUpdateUsuario]);
		}
		setLoadingEditarHabilidades(false);
	}

	/*
	** Habilidades
	** ================================================== */
	const getsHabilidades = dadosUsuario => {
		apiGetAreas({setLoadingGetAreas, setStateAreas});
		apiGetHabilidadesUsuarioChecked({idUsuario: dadosUsuario.id, idArea: dadosUsuario.id_area, setLoadingGetHabilidades, setStateHabilidades, showMsg: false});
		setStateDadosTempHabilidades({
			id_area: dadosUsuario.id_area,
			name_area: dadosUsuario.name_area,
		});
	}

	const cpfCnpjOuDocumento = () => {
		if(validarCpf(stateDados.cpf_cnpj, false)) {
			return <LinhaItem titulo="CPF" desc={`${formatarCPF(stateDados.cpf_cnpj)}`} alt />
		}

		if(validarCnpj(stateDados.cpf_cnpj, false)) {
			<LinhaItem titulo="CNPJ" desc={`${formatarCNPJ(stateDados.cpf_cnpj)}`} alt />
		}

		<LinhaItem titulo="Documento" desc={stateDados.cpf_cnpj || '(não informado)'} alt />
	}

	const scrollTo = () => {
		if(window.outerWidth <= 980) {
			scroller.scrollTo('perfil', {
				duration: 800,
				delay: 0,
				smooth: 'ease',
				offset: -20,
			});
		}
	}

	return (
		<>
			<div className="pg-perfil base-full">
				<div className="conteudo-box">
					<div className="titulo-box titulo-box-res">
						<h3 className="titulo">Perfil</h3>
					</div>

					{loadingGetDados ? (
						<Loading
							texto="Carregando dados, aguarde..."
							wrap="padding-30"
						/>
					) : (
						_stateDados.status === 200 ? (
							<div className="pg-perfil-wrapper">
								<div className="pg-perfil-header">
									<div className="base-m">
										<div className="base-content">
											<div className="pg-perfil-header-inner">
												<div>
													<ImgTituloDesc
														titulo={`${stateDados.name}`}
														desc={
															<div className="fs-14 desc">
																{stateDados.company && stateDados.company !== '' ? (
																	<div className="strong">{stateDados.company}</div>
																) : null}
																<div className="margin-t-10">
																	<div>Cargo: {stateDados.role_position || '(não informado)'}</div>
																	<div>E-mail: {stateDados.email}</div>
																	<div>ID de usuário: {stateDados.id}</div>
																</div>
															</div>
														}
														img={fotoOuGravatar({foto: stateDados.photo, email: stateDados.email})}
														classNameTitulo="titulo strong"
														classNameDesc="td-desc"
														classNameImgBox="foto-wrap"
														responsivo={true}
														tamanho="110px"
														classNameBg="bg"
														preview={false}
														classNameImg="cursor-pointer"
														onClickImg={e => setShowPortalFoto(true)}
													/>
												</div>
												<div className="pg-perfil-header-dir">
													<BtnIconeDesc
														titulo="Visualizar perfil"
														desc="Veja como seu perfil é visualizado"
														icone={imgIconePerfil}
														iconeCor="#2596e1"
														classNameTitulo="color-blue-alt"
														className="hover-underline"
														onClick={e => setShowPortalPerfil(true)}
														alt
													/>
													<Tip
														tip={
															<div className="pg-perfil-nivel-acesso">
																<div className={stateDados.role === 1 ? 'color-red' : 'color-green'}>{textoNivelAcesso(stateDados.role)}</div>
																<div className="td-desc-alt">Nível de acesso</div>
															</div>
														}
														classNameConteudo="w-200"
														classNameTip="margin-auto w-100p"
													>
														<div>Você não pode alterar seu próprio nível de acesso.</div>
														<div className="margin-t-10">Caso seja necessário, solicite a um administrador.</div>
													</Tip>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="pg-perfil-inner">
									<div className="base-m">
										<div className="base-content">
											<div className="pg-perfil-conteudo-box">
												<div className="pt-perfil-sidebar">
													<FiltrosWrap
														textoShowFiltros="Exibir menus"
														textoHideFiltros="Ocultar menus"
													>
														{LINKS_SECOES_SIDEBAR_USUARIO.map((secaoLoop, secaoKey) => {
															return (
																<div className="pt-perfil-sidebar-secao" key={secaoKey}>
																	<h3>{secaoLoop.titulo}</h3>
																	<div className="pt-perfil-sidebar-secao-conteudo">
																		<ul>
																			{secaoLoop.links.map((val, key) => {
																				return (
																					<li key={key}>
																						<Link onClick={scrollTo} to={`/perfil?secao=${val.link}`} className={`a ${stateGet.secao === val.link || (stateGet.secao === '' && val.link === 'resumo') ? 'pt-perfil-sidebar-item-ativo' : ''}`}>{val.texto}</Link>
																					</li>
																				)
																			})}
																		</ul>
																	</div>
																</div>
															)
														})}
													</FiltrosWrap>
												</div>
												<div id="perfil" className="pg-perfil-conteudo">
													<div className="pg-perfil-conteudo-inner">
														<div className="pg-perfil-inner-1">
															{(!stateGet.secao || stateGet.secao === '' || stateGet.secao === 'resumo') && (
																<BoxWrap2
																	titulo="Resumo do perfil"
																	classNameWrap="pg-perfil-box"
																	alt
																>
																	<div>
																		<h3 className="margin-0">Apresentação profissional</h3>
																		<div className="sep-t margin-t-10 padding-t-10">
																			{stateDados.description && stateDados.description !== '' ? (
																				<div className="citacao no-wrap-line">{stateDados.description}</div>
																			) : (
																				<>Nenhuma descrição adicionada.</>
																			)}
																		</div>
																	</div>

																	<div className="margin-t-25">
																		<h3 className="margin-0">Apresentação pessoal</h3>
																		<div className="sep-t margin-t-10 padding-t-10">
																			{stateDados.about && stateDados.about !== '' ? (
																				<div className="citacao no-wrap-line">{stateDados.about}</div>
																			) : (
																				<>Nenhuma descrição adicionada.</>
																			)}
																		</div>
																	</div>

																	<div className="margin-t-25">
																		<h3 className="margin-0">Dados pessoais</h3>
																		<div className="sep-t margin-t-10 padding-t-10">
																			<LinhaItem titulo="Empresa" desc={stateDados.company} alt />
																			<LinhaItem titulo="Nome" desc={stateDados.name || '(não informado)'} alt />
																			<LinhaItem titulo="Área" desc={stateDados.area || '(não informado)'} alt />
																			<LinhaItem titulo="Cargo" desc={stateDados.role_company || '(não informado)'} alt />
																			<LinhaItem titulo="Nível de acesso" desc={stateDados.access_level} alt />
																			<LinhaItem titulo="Telefone" desc={stateDados.phone && stateDados.phone !== '' ? formatarTelefone(stateDados.phone) : '(não informado)'} alt />
																		</div>
																	</div>

																	<div className="margin-t-25">
																		<h3 className="margin-0">Outras informações</h3>
																		<div className="sep-t margin-t-10 padding-t-10">
																			<LinhaItem titulo="ID de usuário" desc={stateDados.id} alt />
																			<LinhaItem titulo="ID da empresa" desc={stateDados.id_company} alt />
																		</div>
																	</div>
																</BoxWrap2>
															)}

															{stateGet.secao === 'dados' && (
																<BoxWrap2
																	titulo="Dados pessoais"
																	btnTitulo="Editar dados"
																	btnImg={imgIconeEditar}
																	classNameWrap="pg-perfil-box"
																	onClickBtn={() => setShowPortalDadosPessoais(true)}
																>
																	<LinhaItem
																		titulo="Nome completo"
																		desc={stateDados.name && stateDados.name !== '' ? stateDados.name : '(não informado)'}
																		alt
																	/>
																	{stateDados.birth_date && stateDados.birth_date !== '' && validarDataAPI(stateDados.birth_date) ? (
																		<LinhaItem titulo="Data de nascimento" desc={`${dataApiParaBr(stateDados.birth_date)}${validarDataAPI(stateDados.birth_date) ? ` (${dataHaQuantoTempo(stateDados.birth_date)})` : ''}`} alt />
																	) : (
																		<LinhaItem titulo="Data de nascimento" desc="(não informado)" alt />
																	)}
																	{cpfCnpjOuDocumento()}
																	<LinhaItem titulo="Cargo" desc={stateDados.role_company || '(não informado)'} alt />
																	<LinhaItem titulo="Telefone" desc={stateDados.phone && stateDados.phone !== '' ? formatarTelefone(stateDados.phone) : '(não informado)'} alt />
																</BoxWrap2>
															)}

															{stateGet.secao === 'apresentacao-profissional' && (
																<BoxWrap2
																	titulo="Carta de apresentação profissional"
																	btnTitulo="Editar dados"
																	btnImg={imgIconeEditar}
																	classNameWrap="pg-perfil-box"
																	onClickBtn={() => setShowPortalCartaApresentacaoProfissional(true)}
																	alt
																>
																	{stateDados.description && stateDados.description !== '' ? (
																		<div className="citacao no-wrap-line">{stateDados.description}</div>
																	) : (
																		<>Nenhuma descrição adicionada.</>
																	)}
																	<div className="desc padding-t-15 sep-t margin-t-15">
																		<div><strong>Obs:</strong> sua apresentação ficará disponível para os profissionais remotos visualizarem.</div>
																		<div className="margin-t-10">Recomendamos que você fale sobre suas experiências profissionais, qual seu foco profissional no momento, e seus objetivos profissionais.</div>
																	</div>
																</BoxWrap2>
															)}

															{stateGet.secao === 'apresentacao-pessoal' && (
																<BoxWrap2
																	titulo="Carta de apresentação pessoal"
																	btnTitulo="Editar dados"
																	btnImg={imgIconeEditar}
																	classNameWrap="pg-perfil-box"
																	onClickBtn={() => setShowPortalCartaApresentacaoPessoal(true)}
																	alt
																>
																	{stateDados.about && stateDados.about !== '' ? (
																		<div className="citacao no-wrap-line">{stateDados.about}</div>
																	) : (
																		<>Nenhuma descrição adicionada.</>
																	)}
																	<div className="desc padding-t-15 sep-t margin-t-15">
																		<div><strong>Obs:</strong> sua apresentação ficará disponível para os profissionais remotos visualizarem.</div>
																		<div className="margin-t-10">Você pode falar sobre seus hobbies, o que gosta de fazer no seu tempo livre, o que mais gosta de fazer e etc...</div>
																	</div>
																</BoxWrap2>
															)}

															{stateGet.secao === 'acessos' && (
																<BoxWrap2
																	titulo="Acesso"
																	btnImg={imgIconeEditar}
																	classNameWrap="pg-perfil-box"
																>
																	<LinhaItem
																		titulo="E-mail"
																		desc={
																			<>
																				{stateDados.email}
																				<span className="a strong margin-l-20" onClick={() => setShowPortalEmail(true)}>alterar e-mail</span>
																			</>
																		}
																		alt
																	/>
																	<LinhaItem
																		titulo="Senha"
																		desc={
																			<>
																				••••••••
																				<span className="a strong margin-l-20" onClick={() => setShowPortalSenha(true)}>alterar senha</span>
																			</>
																		}
																		className="sep-t margin-t-10 padding-t-10"
																		alt
																	/>
																</BoxWrap2>
															)}

															{stateGet.secao === 'foto' && (
																<BoxWrap2
																	titulo="Foto de perfil"
																	classNameWrap="pg-perfil-box"
																	alt
																>
																	<PtFoto
																		stateDados={stateDados}
																		setStateDados={setStateDados}
																		stateFotoCapturada={stateFotoCapturada}
																		setStateFotoCapturada={setStateFotoCapturada}
																	/>
																	{stateFotoCapturada && stateFotoCapturada !== '' ? (
																		<div className="pg-perfil-btn-box">
																			<Button2
																				_={{
																					value: 'Salvar dados',
																					onClick: editarFoto
																				}}
																				loading={loadingEditarFoto}
																			/>
																		</div>
																	) : null}
																</BoxWrap2>
															)}

															{stateGet.secao === 'habilidades' && (
																<BoxWrap2
																	titulo="Área e Habilidades"
																	classNameWrap="pg-perfil-box"
																>
																	<div className="desc">O preenchimento dessas informações é importante para que os profissionais remotos ao visualizarem seu perfil, saiba das suas experiências e conhecimentos, facilitando assim a comunicação entre vocês.</div>
																	<div className="sep-t margin-t-15 padding-t-25"></div>
																	<Select2
																		label="Categoria"
																		req
																		tip="Qual área você tem experiência e está focado atualmente."
																		_={{
																			value: stateDadosTempHabilidades.id_area,
																			options: stateAreas,
																			onChange: async e => {
																				setStateDadosTempHabilidades({
																					...stateDadosTempHabilidades,
																					id_area: e.value,
																					name_area: e.label,
																				});

																				const retGetHabilidades = await apiGetHabilidades({
																					idArea: e.value,
																					setLoadingGetHabilidades: setLoadingGetHabilidades,
																					setStateHabilidades: setStateHabilidades,
																				});

																				if(retGetHabilidades.status === 200) {
																					msg.info('Selecione suas habilidades nessa área.');
																				}
																				setShowPortalHabilidades(true);
																			}
																		}}
																		loading={loadingGetAreas || loadingGetHabilidades || loadingEditarHabilidades}
																	/>

																	<ListaSecao
																		titulo="Habilidades nessa área"
																		subtitulo="Escolha no mínimo 3 e no máximo 10"
																		textoNenhumItemEncontrado="Nenhuma habilidade selecionada"
																		classNameWrap="margin-t-20"
																		stateDados={stateHabilidades}
																		setStateDados={setStateHabilidades}
																		keyTitulo="name"
																		onClickAdd={() => setShowPortalHabilidades(true)}
																		loading={loadingGetHabilidades || loadingEditarHabilidades}
																	/>

																	{!loadingGetHabilidades && (
																		<div className="pg-perfil-btn-box">
																			<Button2
																				_={{
																					value: 'Salvar dados',
																					onClick: editarHabilidades,
																				}}
																				loading={loadingEditarHabilidades}
																			/>
																		</div>
																	)}
																</BoxWrap2>
															)}

															{stateGet.secao === 'competencias' && (
																<ListaSecao
																	titulo="Competências"
																	classNameTituloBox="box-alt-titulo"
																	subtitulo="Escolha no máximo 5"
																	textoNenhumItemEncontrado="Nenhuma competência selecionada"
																	stateDados={stateCompetencias}
																	setStateDados={setStateCompetencias}
																	keyTitulo="name"
																	onClickAdd={() => setShowPortalCompetencias(true)}
																	loading={loadingGetCompetencias}
																	after={
																		!loadingGetCompetencias && (
																			<div className="pg-perfil-btn-box">
																				<Button2
																					_={{
																						value: 'Salvar dados',
																						onClick: e => apiEditarCompetenciasUsuario({idUsuario: store.usuario.id, loadingEditarCompetencias, setLoadingEditarCompetencias, stateCompetencias})
																					}}
																					loading={loadingEditarCompetencias}
																				/>
																			</div>
																		)
																	}
																/>
															)}

															{stateGet.secao === 'ajuda' && (
																<BoxWrap2
																	titulo="Central de ajuda"
																	classNameWrap="pg-perfil-box fs-16"
																>
																	<div>
																		<h3 className="margin-b-5">Está com dúvidas? Acesse nossa central de ajuda:</h3>
																		<div>
																			<a href="https://comunidadecrowd.zendesk.com/hc/pt-br" target="_blank" rel="noreferrer" className="a">
																				https://comunidadecrowd.zendesk.com/hc/pt-br
																			</a>
																		</div>
																	</div>
																	<div>
																		<h3 className="margin-b-5">Principais dúvidas:</h3>
																		<ul>
																			<li><a href="https://comunidadecrowd.zendesk.com/hc/pt-br/articles/4419622831511-Conhecendo-a-plataforma-CROWD" target="_blank" rel="noreferrer" className="a">Conhecendo a plataforma Crowd</a></li>
																			<li><a href="https://comunidadecrowd.zendesk.com/hc/pt-br/articles/4419775466391-Como-buscar-profissionais-na-plataforma-Crowd" target="_blank" rel="noreferrer" className="a">Como buscar profissionais na plataforma Crowd</a></li>
																			<li><a href="https://comunidadecrowd.zendesk.com/hc/pt-br/articles/4422513449239-Como-cadastrar-um-novo-processo-seletivo" target="_blank" rel="noreferrer" className="a">Como enviar propostas de jobs aos profissionais remotos</a></li>
																			<li><a href="https://comunidadecrowd.zendesk.com/hc/pt-br/articles/4440641840407-Como-cadastrar-um-novo-job-Passo-a-passo" target="_blank" rel="noreferrer" className="a">Como cadastrar um novo job - Passo a passo</a></li>
																			<li><a href="https://comunidadecrowd.zendesk.com/hc/pt-br/articles/4440676006679-Aceitando-ou-recusando-propostas-enviadas-pelos-profissionais-remotos" target="_blank" rel="noreferrer" className="a">Aceitando ou recusando propostas enviadas pelos profissionais remotos</a></li>
																			<li><a href="https://comunidadecrowd.zendesk.com/hc/pt-br/articles/8881873011351" target="_blank" rel="noreferrer" className="a">Aprovando jobs na plataforma Crowd </a></li>
																			<li><a href="https://comunidadecrowd.zendesk.com/hc/pt-br/articles/8881916184343" target="_blank" rel="noreferrer" className="a">Como funciona o fechamento e pagamento dos jobs contratados via plataforma Crowd</a></li>
																			<li><a href="https://comunidadecrowd.zendesk.com/hc/pt-br/articles/4420892953367-Como-atualizar-o-seu-perfil-de-Hunter-na-Plataforma-Crowd" target="_blank" rel="noreferrer" className="a">Como atualizar o seu perfil de Hunter na Plataforma Crowd </a></li>
																			<li><a href="https://comunidadecrowd.zendesk.com/hc/pt-br/articles/7973775290903-Erro-de-token-inv%C3%A1lido-Empresas" target="_blank" rel="noreferrer" className="a">Erro de token inválido</a></li>
																		</ul>
																	</div>
																	<div>
																		<h3 className="margin-b-5">Precisa contratar freelancers rapidamente?</h3>
																		<div>
																			<div>Solicite o serviço de curadoria:<br /><a href="https://crowd.app.br/me-indique-um-profissional-remoto" target="_blank" rel="noreferrer" className="a">https://crowd.app.br/me-indique-um-profissional-remoto</a>.</div>
																			<div className="margin-t-10">Conectamos você com especialistas para implementar o que você precisa: desenvolvedores, gestores de tráfego, UX/UI designers, redatores e outros profissionais de tecnologia e marketing.</div>
																		</div>
																	</div>
																	<div>
																		<h3 className="margin-b-5">Precisa de especialistas para acelerar o seu projeto? Veja nossas dicas:</h3>
																		<div>
																			<ul>
																				<li><a href="https://blog.crowd.br.com/squads-remotos-ageis/" target="_blank" rel="noreferrer" className="a">Squads remotos: o que são, como funcionam, tipos de squads, quando vale a pena adotar e onde contratar</a></li>
																				<li><a href="https://blog.crowd.br.com/squad-de-tecnologia/" target="_blank" rel="noreferrer" className="a">Squad de Tecnologia: como uma equipe remota pode acelerar o desenvolvimento de plataformas, aplicativos e sistemas</a></li>
																				<li><a href="https://blog.crowd.br.com/squad-de-marketing/" target="_blank" rel="noreferrer" className="a">Squad de marketing: como uma equipe remota pode alavancar seus resultados </a></li>
																				<li><a href="https://blog.crowd.br.com/squad-de-conteudo/" target="_blank" rel="noreferrer" className="a">Squad de Conteúdo: como uma equipe remota pode agilizar sua produção de conteúdo online</a></li>
																				<li><a href="https://blog.crowd.br.com/squad-de-midia/" target="_blank" rel="noreferrer" className="a">Squad de mídia: como uma equipe remota pode alavancar os resultados das suas campanhas</a></li>
																				<li><a href="https://blog.crowd.br.com/estruturacao-de-squads/" target="_blank" rel="noreferrer" className="a">Estruturação de Squads: como montar squads para acelerar sua transformação digital</a></li>
																				<li><a href="https://blog.crowd.br.com/processo-de-gestao-de-squads/" target="_blank" rel="noreferrer" className="a">Como trabalhar com squads: processo de gestão de squads remotos</a></li>
																				<li><a href="https://blog.crowd.br.com/contratacao-de-squads/" target="_blank" rel="noreferrer" className="a">Contratação de squads: quanto custa, o que considerar e onde solicitar orçamento</a></li>
																			</ul>
																		</div>
																		<div>Solicite um orçamento de curadoria de squads:<br /><a href="https://crowd.app.br/me-indique-um-profissional-remoto" target="_blank" rel="noreferrer" className="a">https://crowd.app.br/me-indique-um-profissional-remoto</a></div>
																	</div>
																	<div>
																		<h3 className="margin-b-5">Não encontrou o que precisa?</h3>
																		<div>
																			Entre em contato conosco: <a href="https://comunidadecrowd.zendesk.com/hc/pt-br/requests/new" target="_blank" rel="noreferrer" className="a">envie sua solicitação ao suporte clicando aqui</a>. Na sua mensagem, informe o ID da Proposta de Job ou do Job relacionado à sua dúvida.
																		</div>
																	</div>
																</BoxWrap2>
															)}

															{stateGet.secao === 'termos' && (
																<BoxWrap2
																	titulo="Termos de uso"
																	classNameWrap="pg-perfil-box fs-16"
																>
																	<div>
																		<div className="desc">Você pode acessar nossos termos de uso sempre que precisar através do link:</div>
																		<div>
																			<Link to="/termos-de-uso">{window.location.origin}/termos-de-uso</Link>
																		</div>
																	</div>
																</BoxWrap2>
															)}

															{stateGet.secao === 'politica' && (
																<BoxWrap2
																	titulo="Política de privacidade"
																	classNameWrap="pg-perfil-box fs-16"
																>
																	<div>
																		<div className="desc">Você pode acessar nossa política de privacidade sempre que precisar através do link:</div>
																		<div>
																			<Link to="https://crowd.br.com/politica-de-privacidade-da-crowd/">https://crowd.br.com/politica-de-privacidade-da-crowd/</Link>
																		</div>
																	</div>
																</BoxWrap2>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)
					)}
				</div>
			</div>

			<PortalDadosPessoaisUsuarioEdicao
				show={showPortalDadosPessoais}
				idUsuario={stateDados.id}
				onClose={() => setShowPortalDadosPessoais(false)}
				onSuccess={e => {
					setStateDados({
						...stateDados,
						...e.dados,
					})
					setShowPortalDadosPessoais(false);
				}}
				closeOnEsc={false}
			/>

			<PortalCartaApresentacaoUsuarioFormEdicao
				tituloApresentacao="Fale sobre seu perfil profissional"
				idUsuario={store.usuario.id}
				idTipoTemplate={4}
				keyDescricao="description"
				show={showPortalCartaApresentacaoProfissional}
				onClose={() => setShowPortalCartaApresentacaoProfissional(false)}
				onSuccess={e => {
					setShowPortalCartaApresentacaoProfissional(false);
					setStateDados({
						...stateDados,
						description: e.dados,
					});
				}}
				closeOnEsc={false}
			/>

			<PortalCartaApresentacaoUsuarioFormEdicao
				tituloApresentacao="Fale sobre seu perfil pessoal"
				idUsuario={store.usuario.id}
				idTipoTemplate={9}
				keyDescricao="about"
				show={showPortalCartaApresentacaoPessoal}
				onClose={() => setShowPortalCartaApresentacaoPessoal(false)}
				onSuccess={e => {
					setShowPortalCartaApresentacaoPessoal(false);
					setStateDados({
						...stateDados,
						about: e.dados,
					});
				}}
				closeOnEsc={false}
			/>

			<PortalEmailAcessoFormEdicao
				id={store.usuario.id}
				tipo="usuario"
				show={showPortalEmail}
				onClose={() => setShowPortalEmail(false)}
				desc="Insire o e-mail que você deseja utilizar para acessar a plataforma."
				onSuccess={e => {
					setStateDados({
						...stateDados,
						email: e.dados,
					});
				}}
				closeOnEsc={false}
			/>

			<PortalSenhaAcessoFormEdicao
				id={store.usuario.id}
				tipo="usuario"
				show={showPortalSenha}
				onClose={() => setShowPortalSenha(false)}
				onSuccess={e => {
					setShowPortalSenha(false);
				}}
				closeOnEsc={false}
			/>

			<Portal
				titulo="Selecione suas habilidades"
				show={showPortalHabilidades}
				onClose={() => setShowPortalHabilidades(false)}
				className="w-600"
			>
				<ListaComCheckbox2
					stateItens={stateHabilidades}
					setStateItens={setStateHabilidades}
					after={
						<div className="form-bottom">
							<Button2
								_={{
									value: 'Fechar',
									onClick: e => setShowPortalHabilidades(false)
								}}
							/>
						</div>
					}
				/>
			</Portal>

			<Portal
				titulo="Selecione suas competências"
				show={showPortalCompetencias}
				onClose={() => setShowPortalCompetencias(false)}
				className="w-600"
			>
				<div className="fs-14 padding-b-25">Sua vivência influencia suas competências e demonstram quem você é. Por isso, selecione até 5 itens na lista abaixo.</div>
				<ListaComCheckbox2
					stateItens={stateCompetencias}
					setStateItens={setStateCompetencias}
					after={
						<div className="form-bottom">
							<Button2
								_={{
									value: 'Fechar',
									onClick: e => setShowPortalCompetencias(false)
								}}
							/>
						</div>
					}
				/>
			</Portal>

			<PortalPerfilUsuario
				id={store.usuario.id}
				show={showPortalPerfil}
				onClose={() => setShowPortalPerfil(false)}
			/>

			<PortalFoto
				show={showPortalFoto}
				foto={stateDados.photo}
				closeOnEsc={true}
				onClose={() => {
					setShowPortalFoto(false);
				}}
			/>
		</>
	);
};

export default PerfilAdministrador;
