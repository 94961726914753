import React, { useRef, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, Link, Image, Font } from '@react-pdf/renderer';

// Imagens
import imgLogoCrowd from '@img/logo.png';

// Funções
import { addPrefixo, calcularAnos, dataApiParaBr, dataApiParaBrExtenso, dataHaQuantoTempo, formatarDinheiro, joinArray, simNao, validarDataAPI, validarDataSegundosAPI, validarUrl } from '@func/utils';

// Config
import { TITULO_PAGINA_DEFAULT } from '@_config/config';

import { primeiroNome } from "@_func/regex";

const PerfilFreelancerPDF = props => {

	const {
		id,
		email,
		phone,
		city,
		price,
		active,
		availability,
		state,
		description,
		title,
		name,
		video,
		photo,
		birth_date,
		name_area,
		seniority,
		seniority_level,

		website,
		created_at,
		ratings = {},
		tasks = {},

		// States
		idiomas,
		redesSociais = [],
		habilidades = [],
		competencias = [],
		segmentos = [],
		experiencias = [],
		formacoes = [],
		certificados = [],
		premios = [],
		portfolios = [],
		objetivos = [],
		// dadosEmpresa,

		foto,
	} = props;

	// Font.register({
	// 	family: 'Open Sans',
	// 	fonts: [
	// 		{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
	// 		{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
	// 	]
	// });

	// Font.register({
	// 	family: "Open Sans",
	// 	src: "https://cdn.jsdelivr.net/npm/open-sans-fonts@1.6.2/open-sans/Regular/OpenSans-Regular.ttf"
	// });

	const css = StyleSheet.create({
		// Geral
		page: {
			padding: 25,
			boxSizing: 'border-box',
			maxWidth: '100%',
			// fontFamily: 'Open Sans',
		},
		wrap: {
			flexDirection: 'row',
		},
		sidebar: {
			width: 200,
			padding: 10,
			paddingLeft: 0,
			boxSizing: 'border-box',
		},
		conteudo: {
			flex: 1,
			padding: 10,
			paddingRight: 0,
			boxSizing: 'border-box',
		},
		introInfo: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			maxWidth: '100%',
			minWidth: 0,
		},

		// Logo
		introLogoWrap: {
			width: 150,
			minWidth: 150,
			maxWidth: 150,
		},
		introLogo: {
			width: 150,
			height: 11,
		},

		// Foto
		fotoWrap: {
			width: '100%',
			marginTop: 10,
			// height: 200,
		},
		foto: {
			width: '100%',
			// height: 200,
		},

		introTituloWrap: {
			paddingLeft: 30,
			textAlign: 'right',
		},
		introTitulo: {
			fontSize: 10,
		},
		introDescWrap: {
			marginTop: 15,
		},
		introDesc: {
			fontSize: 8,
		},
		// Partes
		nomeWrap: {
			color: '#FFF',
			backgroundColor: '#333333',
			paddingTop: 5,
			paddingBottom: 5,
			paddingLeft: 10,
			paddingRight: 10,
			marginTop: 15,
			flexDirection: 'column'
		},
		nomeTitulo: {
			fontSize: 8,
		},
		nome: {
			fontSize: 20,
			fontWeight: 'bold',
		},
		titulo: {
			marginTop: 10,
			marginBottom: 5,
			fontSize: 12,
			fontWeight: 'bold',
			padding: 5,
			backgroundColor: '#cdebff'
		},
		subtitulo: {
			fontSize: 10,
			fontWeight: 'bold',
			padding: 5,
		},
		linha: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			marginTop: 1,
		},
		t: {
			fontSize: 9,
		},
		e: {
			fontSize: 9,
			fontWeight: 600,
		},
		d: {
			fontSize: 9,
			marginLeft: '5px',
		},
		desc: {
			marginTop: 3,
		},
		sep: {
			marginTop: 5,
			paddingTop: 5,
			borderTopWidth: 1,
			borderColor: '#EEE',
		},
		link: {
			color: '#0277BD'
		}
	});

	const segmentosFunc = () => {
		var retorno = 'Nenhum segmento de interesse cadastrado.';
		if(segmentos && typeof(segmentos) === 'object' && segmentos.length) {
			const segmentosTexto = segmentos.map(item => item.name);
			retorno = `${joinArray(segmentosTexto)}.`;
		}
		return retorno;
	}

	const habilidadesFunc = () => {
		var retorno = 'Nenhuma habilidade cadastrada.';
		if(habilidades && typeof(habilidades) === 'object' && habilidades.length) {
			const habilidadesTexto = habilidades.map(item => item.name);
			retorno = `${joinArray(habilidadesTexto)}.`;
		}
		return retorno;
	}

	const competenciasFunc = () => {
		var retorno = 'Nenhuma competência cadastrada.';
		if(competencias && typeof(competencias) === 'object' && competencias.length) {
			const competenciasTexto = competencias.map(item => item.name);
			retorno = `${joinArray(competenciasTexto)}.`;
		}
		return retorno;
	}

	return (
		<Document>
			<Page size="A4" style={css.page}>

				<View>
					<View style={css.introInfo}>
						<View style={css.introLogoWrap}>
							<Image src={imgLogoCrowd} style={css.introLogo} alt="Foto" />
						</View>
						<View style={css.introTituloWrap}>
							<Text style={css.introTitulo}>{TITULO_PAGINA_DEFAULT}</Text>
						</View>
					</View>
				</View>

				<View style={css.nomeWrap}>
					<Text style={css.nome}>{primeiroNome(name)}</Text>
				</View>

				<View style={css.wrap}>
					<View style={css.sidebar}>
						<View>

							<View style={css.fotoWrap}>
								<Image
									style={css.foto}
									alt="Foto"
									src={foto}
								/>
							</View>

							<Text style={css.titulo}>Dados pessoais</Text>

							<View style={css.linha}>
								<Text style={css.e}>Idade:</Text>
								<Text style={css.d}>{validarDataAPI(birth_date) ? `${calcularAnos(birth_date)} anos` : 'Não informado'}</Text>
							</View>

							<View style={css.linha}>
								<Text style={css.e}>Endereço:</Text>
								<Text style={css.d}>{city || 'Cidade'} / {state || 'Estado'}</Text>
							</View>
						</View>

						<View>
							<Text style={css.titulo}>Dados da plataforma</Text>
							<View style={css.linha}>
								<Text style={css.e}>Nível de conhecimento:</Text>
								<Text style={css.d}>{seniority_level}</Text>
							</View>
							<View style={css.linha}>
								<Text style={css.e}>Valor por hora:</Text>
								<Text style={css.d}>{formatarDinheiro(price, 'R$ ')}</Text>
							</View>
							<View style={css.linha}>
								<Text style={css.e}>Média de avaliações:</Text>
								<Text style={css.d}>{ratings.total}/5 (total {tasks.tasks_total})</Text>
							</View>
							<View style={css.linha}>
								<Text style={css.e}>Disponível para novos jobs:</Text>
								<Text style={css.d}>{simNao(active)}</Text>
							</View>
							<View style={css.linha}>
								<Text style={css.e}>Cadastrado em:</Text>
								<Text style={css.d}>{validarDataSegundosAPI(created_at) ? `${dataApiParaBr(created_at)} (${dataHaQuantoTempo(created_at, 'há ')})` : 'Não disponível'}</Text>
							</View>
							<View style={css.linha}>
								<Text style={css.e}>ID na plataforma:</Text>
								<Text style={css.d}>{id}</Text>
							</View>
						</View>

						<View>
							<Text style={css.titulo}>Segmentos de interesse</Text>
							<View>
								<Text style={css.t}>{segmentosFunc()}</Text>
							</View>
						</View>

						<View>
							<Text style={css.titulo}>Habilidades profissionais</Text>

							{/* {seniority_level ? (
								<Text style={css.subtitulo}>{seniority_level} em:</Text>
							) : null} */}

							<View>
								<Text style={css.t}>{habilidadesFunc()}</Text>
							</View>
						</View>

						<View>
							<Text style={css.titulo}>Competências</Text>
							<View>
								<Text style={css.t}>{competenciasFunc()}</Text>
							</View>
						</View>

						<View>
							<View>
								<Text style={css.titulo}>Idiomas</Text>
							</View>
							{idiomas && idiomas.length ? (
								<View>
									{idiomas.map((val, key) => {
										return (
											<Text key={key} style={css.t}>• {val.language_name} ({val.level_name})</Text>
										)
									})}
								</View>
							) : (
								<Text style={css.t}>Nenhuma rede social cadastrada.</Text>
							)}
						</View>

						<View>
							<Text style={css.titulo}>Portfólio{portfolios.length > 1 ? 's' : ''}</Text>
							<View>
								{portfolios && portfolios.length ? (
									<View>
										{portfolios.map((val, key) => {
											return (
												<Link key={key} style={[css.t, css.link]} href={val.url} target="_blank">
													{addPrefixo(val.url)}
												</Link>
											)
										})}
									</View>
								) : (
									<Text style={css.t}>Nenhum portfólio cadastrado.</Text>
								)}
							</View>
						</View>

						<View>
							<Text style={css.titulo}>Contato</Text>
							<View style={css.linha}>
								<Text style={css.e}>Telefone:</Text>
								<Text style={css.d}>{phone || 'não informado'}</Text>
							</View>
							<View style={css.linha}>
								<Text style={css.e}>E-mail:</Text>
								<Text style={css.d}>{email || 'não informado'}</Text>
							</View>
							<View style={css.linha}>
								<Text style={css.e}>Website:</Text>
								<Text style={css.d}>
									{validarUrl(website, false) ? (
										<Link href={addPrefixo(website)} style={css.link} target="_blank">{website}</Link>
									) : 'não informado'}
								</Text>
							</View>
						</View>

						<View>
							<Text style={css.titulo}>Redes sociais</Text>
							{redesSociais && redesSociais.length ? (
								<View>
									{redesSociais.map((val, key) => {
										return (
											<View>
												<Text style={css.t}>[{val.url}]</Text>
											</View>
										)
									})}
								</View>
							) : (
								<Text style={css.t}>Nenhuma rede social cadastrada.</Text>
							)}
						</View>

					</View>
					<View style={css.conteudo}>
						<View>
							<Text style={css.titulo}>Sobre {primeiroNome(name)}</Text>
							<View style={css.linha}>
								<Text style={css.e}>Título:</Text>
								<Text style={css.d}>{title}</Text>
							</View>
							<View style={css.linha}>
								<Text style={css.e}>Categoria:</Text>
								<Text style={css.d}>{name_area}</Text>
							</View>
						</View>

						<Text style={css.titulo}>Descrição</Text>
						<Text style={css.t}>{description}</Text>

						<View>
							<Text style={css.titulo}>Experiência profissional</Text>
							{experiencias && experiencias.length ? (
								<View>
									{experiencias.map((val, key) => {
										return (
											<View key={key}>
												{key !== 0 ? (
													<View style={css.sep}></View>
												) : null}
												<View style={css.linha}>
													<Text style={css.e}><strong>Empresa:</strong></Text>
													<Text style={css.d}>{val.company}</Text>
												</View>
												<View style={css.linha}>
													<Text style={css.e}>Período:</Text>
													<Text style={css.d}>{dataApiParaBrExtenso(val.start_date)} até {val.end_date ? dataApiParaBrExtenso(val.end_date) : 'o momento'}</Text>
												</View>
												<View style={css.linha}>
													<Text style={css.t}>Atividades realizadas: {val.description}</Text>
												</View>
											</View>
										)
									})}
								</View>
							) : (
								<Text style={css.t}>Nenhuma experiência profissional cadastrada.</Text>
							)}
						</View>

						<View>
							<Text style={css.titulo}>Formações</Text>
							{formacoes && formacoes.length ? (
								<View>
									{formacoes.map((val, key) => {
										return (
											<View key={key}>
												{key !== 0 ? (
													<View style={css.sep}></View>
												) : null}
												<View style={css.linha}>
													<Text style={css.e}>Instituição:</Text>
													<Text style={css.d}>{val.company}</Text>
												</View>
												<View style={css.linha}>
													<Text style={css.e}>Período:</Text>
													<Text style={css.d}>{dataApiParaBrExtenso(val.start_date)} até {val.end_date ? dataApiParaBrExtenso(val.end_date) : 'o momento'}</Text>
												</View>
												<View style={css.desc}>
													<Text style={css.t}>Atividades realizadas:</Text>
													<Text style={css.t}>{val.description}</Text>
												</View>
											</View>
										)
									})}
								</View>
							) : (
								<Text style={css.t}>Nenhuma formação cadastrada.</Text>
							)}
						</View>

						<View>
							<Text style={css.titulo}>Certificados</Text>
							{certificados && certificados.length ? (
								<View>
									{certificados.map((val, key) => {
										return (
											<View key={key}>
												{key !== 0 ? (
													<View style={css.sep}></View>
												) : null}
												<View style={css.linha}>
													<Text style={css.e}>Título:</Text>
													<Text style={css.d}>{val.title}</Text>
												</View>
												<View style={css.linha}>
													<Text style={css.e}>Data:</Text>
													<Text style={css.d}>{dataApiParaBrExtenso(val.issue_date)}</Text>
												</View>
											</View>
										)
									})}
								</View>
							) : (
								<Text style={css.t}>Nenhum certificado cadastrado.</Text>
							)}
						</View>

						<View>
							<Text style={css.titulo}>Prêmios</Text>
							{premios && premios.length ? (
								<View>
									{premios.map((val, key) => {
										return (
											<View key={key}>
												{key !== 0 ? (
													<View style={css.sep}></View>
												) : null}
												<View style={css.linha}>
													<Text style={css.e}>Título:</Text>
													<Text style={css.d}>{val.title}</Text>
												</View>
												<View style={css.linha}>
													<Text style={css.e}>Data:</Text>
													<Text style={css.d}>{dataApiParaBrExtenso(val.issue_date)}</Text>
												</View>
											</View>
										)
									})}
								</View>
							) : (
								<Text style={css.t}>Nenhum prêmio cadastrado.</Text>
							)}
						</View>

					</View>
				</View>
			</Page>
		</Document>
	)
}

export default PerfilFreelancerPDF;