import React from 'react';

export const IconeTextoTexto = props => {

	const {
		texto,
		...rest
	} = props;

	return (
		<div {...rest}>
			{texto}
		</div>
	);
}
