import InputMask from '@mona-health/react-input-mask';
import React, { useRef } from 'react';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { DebounceInput } from "react-debounce-input";
import { NumericFormat } from 'react-number-format';

// CSS
import './Input2.css';

// Funções
import { hash } from '@_func/generate';
import Tip from "@cpnt/Tip/Tip";

const Input2 = props => {

	const inputRef = useRef();

	var dados = {
		_: {},
		wrap: '',
		req: false,
		labelClass: '',
		classBox: '',
		active: true,
		btnPassword: false,
		customLabel: false,
		customLabelClass: '',
		toString: false,
		debounce: true,
		tip: false,
		onClickBtnPassword: () => null,
		...props,
	};

	if(dados._.value !== undefined && dados._.value === null) {
		dados._.value = '';
	}

	if(dados._.defaultValue !== undefined && dados._.defaultValue === null) {
		dados._.defaultValue = '';
	}

	if(dados.toString) {
		if(typeof(dados._.value) === 'number') {
			dados._.value = dados._.value.toString();
		}
		if(typeof(dados._.defaultValue) === 'number') {
			dados._.defaultValue = dados._.defaultValue.toString();
		}
	}

	const mascara = e => {
		let { value } = e.nextState;
		return {
			...e.nextState,
			value
		};
	}

	const gerarPassword = () => {
		var senha = hash();
		senha = senha.toUpperCase();
		senha = senha.substring(1, 11);
		if(dados._.value && dados._.onChange) {
			dados._.onChange(senha);
		} else if(inputRef.current) {
			inputRef.current.value = senha;
		}
		dados.onClickBtnPassword(senha);
	}

	const renderType = () => {
		if(dados._.type === 'textarea') {
			return <textarea {...dados._} />
		}

		if(dados._.mask) {
			return <InputMask beforeMaskedStateChange={mascara} {...dados._} maskPlaceholder='' />
		}

		if(dados.dinheiro) {
			return (
				<NumericFormat
					thousandSeparator="."
					decimalSeparator=","
					prefix="R$ "
					fixedDecimalScale={true}
					decimalScale={2}
					defaultValue={dados._.value}
					onValueChange={(values) => {
						const { floatValue } = values;
						dados._.onChange(floatValue);
					}}
				/>
			)
		}

		if(dados.cpfCnpj) {
			return <CpfCnpj {...dados._} />
		}

		if(dados.debounce) {
			var dadosDebounceDefault = {};
			if(dados.debounce && typeof(dados.debounce) === 'object') {
				dadosDebounceDefault = dados.debounce;
			}
			return (
				<DebounceInput
					debounceTimeout={500}
					forceNotifyByEnter={true}
					forceNotifyOnBlur={true}
					minLength={0}
					{...dados._}
					{...dadosDebounceDefault}
				/>
			)
		}

		if(!dados.dinheiro && dados._.type !== 'textarea' && !dados.debounce) {
		// if(!dados._.mask && !dados.dinheiro && dados._.type !== 'textarea' && !dados.debounce) {
			return (
				<input
					{...dados._}
					{...(
						dados.btnPassword && dados._.value === undefined ? (
							{
								ref: inputRef,
								style: {
									paddingRight: '45px'
								},
							}
						) :
							{}
						)
					}
				/>
			);
		}
	}

	return (
		<div className={dados.wrap}>
			<div className={`inputtext2-box inputtext2-box-ativo ${dados.classBox} ${dados.invalid ? 'inputtext2-box-invalido' : ''} ${dados.dropDown ? 'inputtext2-box-dropdown' : ''} ${dados._.disabled ? 'inputtext2-disabled' : ''}`}>
				{renderType()}
				{dados.tip ? (
					<div className={`cpnt-inputtext2-customlabel ${dados.customLabelClass}`}>
						<Tip
							tip={
								<>
									{dados.label}
									<span className="q"></span>
									{dados.req && <span className="inputtext2-req">*</span>}
								</>
							}
							info
						>
							{dados.tip}
						</Tip>
					</div>
				) : null}
				{!dados.tip ? (
					<>
						{dados.customLabel && <div className={`cpnt-inputtext2-customlabel ${dados.customLabelClass}`}>{dados.customLabel}</div>}
						{dados.label && <label className={dados.labelClass}>{dados.label}{dados.req && <span className="inputtext2-req">*</span>}</label>}
						{dados.dropDown && <span className="inputtext2-dropdown"></span>}
						{dados.btnPassword && <button type="button" className="inputtext2-btn-password" onClick={gerarPassword}></button>}
					</>
				) : null}
			</div>
		</div>
	)
}

export default Input2;