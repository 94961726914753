// API
import { apiSelect } from "./select";

// Func
import { dadosSelect } from "@_func/components";
import { msgs, apiObj } from "@_func/request";

export const apiSelectGeral = {
	segments: {
		get: async ({setLoadingGetSegmentos, setStateSegmentos, showMsg}) => {
			setLoadingGetSegmentos(true);

			const req = await apiSelect.segments.get();
			const ret = apiObj(req);

			if(ret.status === 200) {
				ret.data = dadosSelect({data: ret.data})
			} else {
				if(showMsg) {
					msgs([ret]);
				}
			}

			setStateSegmentos(ret);
			setLoadingGetSegmentos(false);
			return ret;
		}
	},
	languages: {
		get: async ({setLoadingGetIdiomas, setStateIdiomas, showMsg}) => {
			setLoadingGetIdiomas(true);

			const req = await apiSelect.languages.get();
			const ret = apiObj(req);

			if(ret.status === 200) {
				ret.data = dadosSelect({data: ret.data})
			} else {
				if(showMsg) {
					msgs([ret]);
				}
			}

			setStateIdiomas(ret);
			setLoadingGetIdiomas(false);
			return ret;
		}
	},
	state: {
		get: async ({setLoadingGetEstados, setStateEstados, showMsg, uf}) => {
			setLoadingGetEstados(true);

			const req = await apiSelect.state.get();
			const ret = apiObj(req);

			if(ret.status === 200) {
				if(uf) {
					ret.data = dadosSelect({
						data: ret.data,
						value: 'name',
						key: 'uf',
					});
				} else {
					ret.data = dadosSelect({data: ret.data})
				}
			} else {
				if(showMsg) {
					msgs([ret]);
				}
			}

			setStateEstados(ret);
			setLoadingGetEstados(false);
			return ret;
		}
	},
	competences: {
		get: async ({setLoadingGetCompetencias, setStateCompetencias, showMsg}) => {
			setLoadingGetCompetencias(true);

			const req = await apiSelect.competences.get();
			const ret = apiObj(req);

			if(ret.status === 200) {
				ret.data = dadosSelect({data: ret.data})
			} else {
				if(showMsg) {
					msgs([ret]);
				}
			}

			setStateCompetencias(ret);
			setLoadingGetCompetencias(false);
			return ret;
		}
	},
	customers: {
		get: async ({idCompany, setLoadingGetClientes, setStateClientes, showMsg}) => {
			setLoadingGetClientes(true);

			const req = await apiSelect.customers.get(idCompany);
			const ret = apiObj(req);

			if(ret.status === 200) {
				ret.data = dadosSelect({data: ret.data})
			} else {
				if(showMsg) {
					msgs([ret]);
				}
			}

			setStateClientes(ret);
			setLoadingGetClientes(false);
			return ret;
		}
	},
	projects: {
		get: async ({idCompany, idCustomer, setLoadingGetProjetos, setStateProjetos, showMsg}) => {
			setLoadingGetProjetos(true);

			const req = await apiSelect.projects.get({id_company: idCompany, ...(idCustomer ? {id_customer: idCustomer} : {})});
			const ret = apiObj(req);

			if(ret.status === 200) {
				ret.data = dadosSelect({data: ret.data})
			} else {
				if(showMsg) {
					msgs([ret]);
				}
			}

			setStateProjetos(ret);
			setLoadingGetProjetos(false);
			return ret;
		}
	},
	areas: {
		get: async ({setLoadingGetAreas, setStateAreas, showMsg}) => {
			setLoadingGetAreas(true);

			const req = await apiSelect.areas.get();
			const ret = apiObj(req);

			if(ret.status === 200) {
				ret.data = dadosSelect({data: ret.data})
			} else {
				if(showMsg) {
					msgs([ret]);
				}
			}

			setStateAreas(ret);
			setLoadingGetAreas(false);
			return ret;
		}
	},
	typeContract: {
		get: async ({setLoadingGetTiposContrato, setStateTiposContrato, showMsg}) => {
			setLoadingGetTiposContrato(true);

			const req = await apiSelect.typeContract.get();
			const ret = apiObj(req);

			if(ret.status === 200) {
				ret.data = dadosSelect({data: ret.data})
			} else {
				if(showMsg) {
					msgs([ret]);
				}
			}

			setStateTiposContrato(ret);
			setLoadingGetTiposContrato(false);
			return ret;
		}
	},
	modelWork: {
		get: async ({setLoadingGetModelosDeTrabalho, setStateModelosDeTrabalho, showMsg}) => {
			setLoadingGetModelosDeTrabalho(true);

			const req = await apiSelect.modelWork.get();
			const ret = apiObj(req);

			if(ret.status === 200) {
				ret.data = dadosSelect({data: ret.data})
			} else {
				if(showMsg) {
					msgs([ret]);
				}
			}

			setStateModelosDeTrabalho(ret);
			setLoadingGetModelosDeTrabalho(false);
			return ret;
		}
	},
};
