// Func
import { dadosSelect } from "@_func/components";
import { msgs, apiObj } from "@_func/request";
import { apiUsers } from "./users";

export const apiUsersGeral = {
	getAllUsers: {
		get: async ({idCompany, active, setLoadingGetUsuarios, setStateUsuarios, showMsg}) => {
			setLoadingGetUsuarios(true);

			const req = await apiUsers.getAllUsers.get(idCompany, active);
			const ret = apiObj(req);

			if(ret.status === 200) {
				ret.data = dadosSelect({
					data: ret.data,
					keyIcone: 'photo',
				})
			} else {
				if(showMsg) {
					msgs([ret]);
				}
			}

			setStateUsuarios(ret);
			setLoadingGetUsuarios(false);
			return ret;
		}
	},
};
