import React, { useState } from 'react';
import { toast as msg } from 'react-toastify';

// API
import { apiCep } from '@_api/requests/cep/cep';
import apiCnpj from '@api/cnpj/cnpj';

// Componentes
import BtnAdicionar from '@cpnt/BtnAdicionar/BtnAdicionar';
import Input2 from '@cpnt/Input2/Input2';
import InputCalendario from '@cpnt/InputCalendario/InputCalendario';
import InputTelefone from '@cpnt/InputTelefone/InputTelefone';
import Loading from '@cpnt/Loading/Loading';
import Radio2 from '@cpnt/Radio2/Radio2';
import Tip from '@cpnt/Tip/Tip';
import { DevBox } from '@cpnt/DevBox/DevBox';

// Funções
import { DEV } from '@_config/config';
import { apenasNumeros, validarCep, validarCnpj, validarCpf } from '@func/utils';

import { hash } from "@_func/generate";
import { msgs, msgStatus } from "@_func/request";
import InputCpfCnpj, { validarCpfCnpj } from "@cpnt/InputCpfCnpj/InputCpfCnpj";

const FormEmpresaLite = props => {
	const {
		onSubmit,
		isCadastro,

		// State
		stateDados,
		setStateDados,

		after,
	} = props;

	/*
	** States
	** ================================================== */
	const [loadingPesquisarCep, setLoadingPesquisarCep] = useState(false);
	const [loadingPesquisarCNPJ, setLoadingPesquisarCNPJ] = useState(false);

	/*
	** Endereço
	** ================================================== */
	const pesquisarCEP = async e => {

		setStateDados({
			...stateDados,
			street: '',
			number: '',
			complement: '',
			district: '',
			city: '',
			state: '',
		});

		if(e === '') return;

		if(!validarCep(e)) {
			msg.error('CEP Inválido');
			return;
		}

		setLoadingPesquisarCep(true);

		const retGetCEP = await apiCep.get(e);

		if(retGetCEP.status === 200) {
			msg.success('CEP localizado com sucesso!', {
				autoClose: 2000
			});
			setStateDados({
				...stateDados,
				zip_code: e,
				street: retGetCEP.data.street,
				district: retGetCEP.data.district,
				city: retGetCEP.data.city,
				state: retGetCEP.data.uf,
			});
		} else {
			msgs([retGetCEP]);
		}

		setLoadingPesquisarCep(false);
	};

	/*
	** Pesquisar CNPJ
	** ================================================== */
	const pesquisarCNPJ = async e => {

		if(!validarCpfCnpj(e)) return;

		if(validarCpf(e, false)) return;

		setStateDados({
			...stateDados,
			name: '',
			social_reason: '',
			street: '',
			district: '',
			zip_code: '',
			state: '',
			city: '',
			number: '',
			complement: '',
		});

		if(e === '') return;

		if(!validarCnpj(e)) return;

		setLoadingPesquisarCNPJ(true);

		const retGetCNPJ = await apiCnpj.get(apenasNumeros(e));

		if(retGetCNPJ.status === 200) {

			// Nome contato
			var nomeContato = false;
			if(retGetCNPJ.data.qsa && typeof(retGetCNPJ.data.qsa) === 'object' && retGetCNPJ.data.qsa.length && retGetCNPJ.data.qsa[0] && retGetCNPJ.data.qsa[0].nome && retGetCNPJ.data.qsa[0].nome !== '') {
				nomeContato = retGetCNPJ.data.qsa[0].nome;
			}

			// E-mail contato
			var emailContato = false;
			if(retGetCNPJ.data.email && retGetCNPJ.data.email !== '') {
				emailContato = retGetCNPJ.data.email;
			}

			msg.success('CNPJ localizado com sucesso');
			setStateDados({
				...stateDados,
				name: retGetCNPJ.data.fantasia,
				social_reason: retGetCNPJ.data.nome,
				street: retGetCNPJ.data.logradouro,
				district: retGetCNPJ.data.bairro,
				zip_code: retGetCNPJ.data.cep && retGetCNPJ.data.cep.replace('.', ''),
				state: retGetCNPJ.data.uf,
				city: retGetCNPJ.data.municipio,
				number: retGetCNPJ.data.numero,
				complement: retGetCNPJ.data.complemento,
				phone: retGetCNPJ.data.telefone,
				...(nomeContato ? {
					name_responsible: nomeContato,
				} : {}),
				...(emailContato ? {
					email: emailContato,
				} : {}),
			});
		} else {
			msgs([retGetCNPJ]);
		}

		setLoadingPesquisarCNPJ(false);
	};

	return (
		<form className="relative" onSubmit={onSubmit}>
			{!isCadastro ? (
				<div className="padding-b-15">
					<h3 className="titulo">{stateDados.name}</h3>
					<div className="td-desc">ID da empresa: {stateDados.id_empresa}</div>
				</div>
			) : null}

			{loadingPesquisarCNPJ && <Loading texto="Consultando CNPJ, aguarde..." absolute />}

			<div className="flex wrap-5">
				<div className="flex flex-wrap flex-1 flex-top-self relative">
					<div className="input-box box-1">
						<InputCpfCnpj
							label='CNPJ'
							req
							_={{
								value: stateDados.cnpj,
								onChange: e => {
									setStateDados({
										...stateDados,
										cnpj: e.target.value,
									})
								},
								onBlur: e => {
									pesquisarCNPJ(e.target.value);
								}
							}}
						/>
					</div>
					<div className="input-box box-2">
						<Input2
							label="Nome da empresa"
							req
							_={{
								name: 'name',
								value: stateDados.name,
								onChange: e => setStateDados({
									...stateDados,
									name: e.target.value
								}),
							}}
						/>
					</div>
					<div className="input-box box-2">
						<Input2
							label="Razão Social"
							req
							_={{
								name: 'social_reason',
								value: stateDados.social_reason,
								onChange: e => setStateDados({
									...stateDados,
									social_reason: e.target.value
								}),
							}}
						/>
					</div>

					<div className="input-box box-3">
						<Input2
							label="Nome do principal contato"
							req
							_={{
								value: stateDados.name_responsible,
								onChange: e => setStateDados({
									...stateDados,
									name_responsible: e.target.value
								}),
							}}
						/>
					</div>
					<div className="input-box box-3">
						<InputTelefone
							label="Telefone"
							req
							_={{
								value: stateDados.phone,
								onChange: e => {
									setStateDados({
										...stateDados,
										phone: e
									});
								},
							}}
						/>
					</div>
					<div className="input-box box-3">
						<Input2
							label="E-mail"
							req
							_={{
								value: stateDados.email_responsible,
								onChange: e => setStateDados({
									...stateDados,
									email_responsible: e.target.value
								}),
							}}
						/>
					</div>
				</div>
			</div>

			<h3 className="titulo margin-t-15 margin-b-10">Endereço</h3>
			<div className="relative">
				{loadingPesquisarCep && <Loading texto="Consultando CEP, aguarde..." absolute />}
				<div className="flex flex-wrap wrap-h-5">
					<div className="input-box w-100p">
						<Input2
							label="CEP"
							req
							_={{
								value: stateDados.zip_code,
								onChange: e => {
									setStateDados({
										...stateDados,
										zip_code: e.target.value,
									})
								},
								mask: '99999-999',
								onBlur: e => {
									pesquisarCEP(e.target.value);
								}
							}}
						/>
					</div>
					<div className="input-box w-80p">
						<Input2
							label="Logradouro"
							req
							_={{
								value: stateDados.street,
								onChange: e => setStateDados({
									...stateDados,
									street: e.target.value
								}),
							}}
						/>
					</div>
					<div className="input-box w-20p">
						<Input2
							label="Número"
							req
							_={{
								value: stateDados.number,
								onChange: e => setStateDados({
									...stateDados,
									number: e.target.value
								}),
							}}
						/>
					</div>
					<div className="input-box w-50p">
						<Input2
							label="Complemento"
							_={{
								value: stateDados.complement,
								onChange: e => setStateDados({
									...stateDados,
									complement: e.target.value
								}),
							}}
						/>
					</div>
					<div className="input-box w-50p">
						<Input2
							label="Bairro"
							req
							_={{
								value: stateDados.district,
								onChange: e => setStateDados({
									...stateDados,
									district: e.target.value
								}),
							}}
						/>
					</div>
					<div className="input-box w-50p">
						<Input2
							label="Cidade"
							req
							_={{
								value: stateDados.city,
								onChange: e => setStateDados({
									...stateDados,
									city: e.target.value
								}),
							}}
						/>
					</div>
					<div className="input-box w-50p">
						<Input2
							label="Estado"
							req
							_={{
								value: stateDados.state,
								onChange: e => setStateDados({
									...stateDados,
									state: e.target.value
								}),
							}}
						/>
					</div>
				</div>
			</div>

			<div>
				<h3 className="titulo margin-t-15">Tratativas Comerciais</h3>

				<div className="wrap padding-t-5">
					<div className="input-box w-200">
						<Input2
							label="Taxa plataforma (%)"
							req
							_={{
								mask: '99',
								maskPlaceholder: null,
								value: stateDados.percentage,
								onChange: e => setStateDados({
									...stateDados,
									percentage: e.target.value
								}),
							}}
							toString={true}
						/>
					</div>
				</div>
			</div>

			{after}

		</form>
	);
};

export default FormEmpresaLite;