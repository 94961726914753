export const servicosObj = [
	{
		titulo: 'Redator de conteúdo',
		icone: 'prof-redator.webp',
	},
	{
		titulo: 'Copywriter',
		icone: 'prof-copy.webp',
	},
	{
		titulo: 'Editor de Vídeo',
		icone: 'editor-de-video.png',
	},
	{
		titulo: 'Desenvolvedor Front-end',
		icone: 'dev-front-end.png',
	},
	{
		titulo: 'Desenvolvedor Back-end',
		icone: 'dev-back-end.png',
	},
	{
		titulo: 'Desenvolvedor Full-stack',
		icone: 'dev-full-stack.png',
	},
	{
		titulo: 'Desenvolvedor WordPress',
		icone: 'dev-wordpress.png',
	},
	{
		titulo: 'Product Designer',
		icone: 'ico-design-02.webp',
	},
	{
		titulo: 'Designer Gráfico',
		icone: 'prof-design.webp',
	},
	{
		titulo: 'UX Designer',
		icone: 'ux-designer.png',
	},
	{
		titulo: 'UI Designer',
		icone: 'ui-designer.png',
	},
	{
		titulo: 'Motion Designer',
		icone: 'motion-designer.png',
	},
	{
		titulo: 'Gestor de Tráfego Pago',
		icone: 'ico-media-02.webp',
	},
	{
		titulo: 'Estrategista de Mídia',
		icone: 'ico-media-01.webp',
	},
	{
		titulo: 'Business Intelligence',
		icone: 'business-intelligence.png',
	},
	{
		titulo: 'Product Owner',
		icone: 'product-owner.png',
	},
	{
		titulo: 'Product Manager',
		icone: 'product-manager.png',
	},
	{
		titulo: 'Gerente de Projetos',
		icone: 'gestor-de-projetos.png',
	},
	{
		titulo: 'Customer Success',
		icone: 'customer-success.png',
	},
	{
		titulo: 'Atendimento',
		icone: 'atendimento.png',
	},
	{
		titulo: 'Estrategista de Marketing Digital',
		icone: 'estrategista-de-marketing-digital.png',
	},
];