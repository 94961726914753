import React, { useState, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import moment from 'moment/min/moment-with-locales';

// CSS
import '../Input/Input';

registerLocale('ptBR', ptBR);

const InputCalendario = props => {
	const dados = {
		name: '',
		label: false,
		value: null,
		placeholder: 'Selecione...',
		class: '',
		wrap: '',
		onSelect: () => null,
		onChange: () => null,
		...props,
	};

	useEffect(() => {
		setData(dados.value);
	}, [dados.value]);

	const onSelect = e => {
		setData(e);
		dados.onSelect(e);
	}

	if(!dados.value || dados.value === '') {
		dados.value = null;
	} else if(dados.value === 'Invalid date') {
		dados.value = null;
	} else if((typeof(dados.value) === 'object')) {
		if(!moment(dados.value).isValid()) {
			dados.value = null;
		}
	}

	const [data, setData] = useState(dados.value);

	return (
		<div className={`inputtext-box-wrap ${dados.wrap}`}>
			<div className={`inputtext-box ${dados.class}`}>
				{dados.label && <label className="label ativo">{dados.label}</label>}
				<DatePicker
					name={dados.name}
					selected={data}
					placeholderText={dados.placeholder}
					onSelect={date => onSelect(date)} //when day is clicked
					onChange={date => onSelect(date)}
					dateFormat="dd/MM/yyyy"
					peekNextMonth
					showMonthDropdown
					showYearDropdown
					dropdownMode="select"
					locale={ptBR}
				/>
			</div>
		</div>
	);
}

export default InputCalendario;
