import React from 'react';

// CSS
import './ProjetosInfoQtd.css';

// Imagens
import imgIconeInfo from '@svg/solid/circle-info.svg';

// Componentes
import Icone from '@cpnt/Icone/Icone';
import LinhaEspacoTexto from '@cpnt/LinhaEspacoTexto/LinhaEspacoTexto';
import Qtd from '@cpnt/Qtd/Qtd';
import Tip from '@cpnt/Tip/Tip';

// Funções
import { singularPlural } from '@func/utils';

const ProjetosInfoQtd = props => {
	const {
		qtdGeneralRecords,
		qtdGeneralConcluded,
		qtdGeneralActive,
		qtdGeneralPaused,
	} = props;

	return (
		<div className="color-1 fs-14 fw-400">
			<div className="cpnt-projetosinfoqtd-desk">
				<span className="margin-l-20">
					<strong>{qtdGeneralRecords}</strong> projetos no total
				</span>
				<span className="color-1 fs-14 fw-400 margin-l-10">
					<Qtd
						qtd={qtdGeneralConcluded}
						singular="concluído"
						plural="concluídos"
						exibirVazio={false}
						before={<span className="margin-r-10">|</span>}
					/>
				</span>
				<span className="color-1 fs-14 fw-400 margin-l-10">
					<Qtd
						qtd={qtdGeneralActive}
						singular="ativo"
						plural="ativos"
						exibirVazio={false}
						before={<span className="margin-r-10">|</span>}
					/>
				</span>
				<span className="color-1 fs-14 fw-400 margin-l-10">
					<Qtd
						qtd={qtdGeneralPaused}
						singular="pausado"
						plural="pausados"
						exibirVazio={false}
						before={<span className="margin-r-10">|</span>}
					/>
				</span>
			</div>
			<div className="cpnt-projetosinfoqtd-mobile">
				<Tip
					titulo="Dados do projeto"
					tip={
						<Icone
							imagem={imgIconeInfo}
							cor="#0277BD"
						/>
					}
					click={true}
					classNameTitulo="align-center"
					_defaults={{
						placement: 'bottom-center',
					}}
				>
					<LinhaEspacoTexto
						titulo={singularPlural(qtdGeneralConcluded, 'Projeto no total', 'Projetos no total')}
						desc={qtdGeneralRecords}
						classNameDesc="strong"
					/>
					<LinhaEspacoTexto
						titulo={singularPlural(qtdGeneralConcluded, 'Concluído', 'Concluídos')}
						desc={qtdGeneralConcluded}
						classNameDesc="strong"
					/>
					<LinhaEspacoTexto
						titulo={singularPlural(qtdGeneralActive, 'Ativo', 'Ativos')}
						desc={qtdGeneralActive}
						classNameDesc="strong"
					/>
					<LinhaEspacoTexto
						titulo={singularPlural(qtdGeneralPaused, 'Pausado', 'Pausados')}
						desc={qtdGeneralPaused}
						classNameDesc="strong"
					/>
				</Tip>
			</div>
		</div>
	);
};

export default ProjetosInfoQtd;