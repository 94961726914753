import { Icone } from "@_cpnt/Icone/Icone";
import { Texto } from "@_cpnt/Texto/Texto/Texto";

import c from './BoxInfo.module.scss';

export const BoxInfo = {
	Icone: (props) => {
		return <Icone className={`${c['icone']}`} {...props} />
	},
	Titulo: (props) => {
		return <Texto className={`${c['titulo']}`} {...props} />
	},
	Desc: Texto,
};
