import React, { useEffect, useRef } from 'react';
import Mortal from 'react-mortal';
import { lock, unlock } from 'tua-body-scroll-lock';

// CSS
import './Portal.css';

// Funções
import { hash } from '@_func/generate';

const Portal = props => {

	const refPortal = useRef(null);
	const _id = hash();

    const {
		show,
		titulo,
		subtitulo,
		onClose = () => null,
		closeOnClickBg = false,
		closeOnEsc = true,
		children,
		x = true,
		// id = hash('cpnt-portal-'),
		classNameDefault = 'cpnt-portal-default',
		className = '',
		classNameContainer = '',
		classNameBg = '',
		classNameX = '',
		classNameWrapbox = '',
		classNameWrap = 'cpnt-portal-wrap',
		classNameConteudo = 'cpnt-portal-conteudo',
		outside,
	} = props;

	var id = hash('cpnt-portal-')

	/*
	** Desativa o scroll no body e deixa scroll apenas no overlay
	** ================================================== */
	useEffect(() => {
		if(show) {
			/*
			** Copia o ref para evitar de perder ao desmontar
			** ================================================== */
			var _refPortal = refPortal.current;

			/*
			** Desativa o scroll no body e deixa scroll apenas no overlay
			** ================================================== */
			lock(_refPortal, { useGlobalLockState: true });
			/*
			** Verifica o evento ao pressionar a tecla (ESC)
			** ================================================== */
			window.addEventListener('keydown', closeOnEscBtn);

			/*
			** Ao desmontar
			** ================================================== */
			return () => {

				/*
				** Ativa o scroll novamente
				** ================================================== */
				unlock(_refPortal, { useGlobalLockState: true });

				/*
				** Remove o eventListener para evitar sobrecarga
				** ================================================== */
				window.removeEventListener('keydown', closeOnEscBtn);

				/*
				** Verifica se tem outro overlay ativo e da focus nele
				** Assim é possível usar o ESC no próximo portal também
				** ================================================== */
				if(_refPortal.parentElement
					&& _refPortal.parentElement.previousElementSibling
					&& _refPortal.parentElement.previousElementSibling.children
					&& _refPortal.parentElement.previousElementSibling.children[0]
					&& _refPortal.parentElement.previousElementSibling.children[0].dataset
					&& _refPortal.parentElement.previousElementSibling.children[0].dataset.portal
				) {
					_refPortal.parentElement.previousElementSibling.children[0].focus();
				}
			}
		}
	}, [show]);

	/*
	** Fecha ao clicar no background
	** ================================================== */
	const closeOnClickCheck = e => {
		if(e.target.id === id) {
			onClose();
		}
	}

	/*
	** Fecha ao pressionar ESC
	** ================================================== */
	const closeOnEscBtn = e => {
		if(closeOnEsc) {
			if(e.key === 'Escape' && refPortal.current.parentElement.nextElementSibling === null){
				onClose();
			}
		}
	}

    return (
		<Mortal
			isOpened={show}
			onClose={onClose}
			closeOnEsc={false}
			motionStyle={(spring, isVisible) => {
				return {
					opacity: spring(isVisible ? 1 : 0),
					scale: spring(isVisible ? 1 : 1.07),
				}
			}}
		>
			{(motion, isVisible) => {
				return (
					<div
						className={`cpnt-portal ${classNameContainer}`}
						style={{
							pointerEvents: isVisible ? 'auto' : 'none',
							opacity: motion.opacity,
							transform: `scale(${motion.scale})`,
						}}
						tabIndex={1}
						data-portal
					>
						<div
							className={`cpnt-portal-wrap-box ${classNameWrapbox}`}
							id={id}
							ref={refPortal}
							{...(closeOnClickBg ? {onClick: closeOnClickCheck} : {})}
						>
							<div className={`${classNameWrap}`}>
								<div className="cpnt-portal-inner">
									{x && <span className={classNameX ? classNameX : 'cpnt-portal-close'} onClick={onClose}></span>}

									<div className={`${classNameConteudo}`}>
										{titulo || subtitulo ? (
											<div className="cpnt-portal-top">
												{titulo && <h3 className="cpnt-portal-titulo">{titulo}</h3>}
												{subtitulo && <div className="cpnt-portal-subtitulo">{subtitulo}</div>}
											</div>
										) : null}
										<div className={`${classNameDefault} ${className}`}>{children}</div>
									</div>
									{outside}
								</div>
							</div>
						</div>
						<div className={`cpnt-portal-bg ${classNameBg}`}></div>
					</div>
				)
			}}
		</Mortal>
    )
}

export default Portal