import React from 'react';

// Componentes
import Disponibilidade from '@cpnt/Disponibilidade/Disponibilidade';
import ItemFotoDesc from '@cpnt/ItemFotoDesc/ItemFotoDesc';

const DetalhesSolucoes = props => {
	const {
		className = '',
		wrap = '',

		restricted,
		name,
		id,
		client_name,
		solutions = [],
		after,
	} = props;

	return (
		<>
			<div className={`cpnt-detalhessolucoes ${wrap}`}>
				<div className={`cpnt-detalhessolucoes-box ${className}`}>
					<h3 className="margin-v-0 inline-flex flex-center-v">
						{restricted === 1 && <Disponibilidade textoIndisponivel="Restrito" min status={0} className="margin-r-7 margin-b--3" />}
						<span>{name} <span className="color-1">(ID: {id})</span></span>
					</h3>
					<div className="fs-16 color-1 margin-t-2">{client_name}</div>

					<div>
						{solutions && solutions.length ? (
							<div>
								<h3>Soluções utilizadas no projeto <span className="color-1 fw-lighter">({solutions.length})</span></h3>
								<div className="wrap-5">
									{solutions.map((value, index) => {
										return (
											<ItemFotoDesc
												className="margin-5"
												key={index}
												foto={value.img_solution}
												titulo={value.name_solution}
												placeholder={false}
												alt
											/>
										)
									})}
								</div>
							</div>
						) : (
							<div>
								<h3 className="margin-b-0">Soluções utilizadas no projeto</h3>
								<div className="fs-16 color-1">Nenhum solução utilizada no projeto.</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{after}
		</>
	);
};

export default DetalhesSolucoes;