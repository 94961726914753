import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalDadosEmpresaFormEdicao.css';

// API

// Partes

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import apiFreelancerCompany from "@api/freelancerCompany/freelancerCompany";
import { PtDadosDaEmpresa } from "@cpnt/Partes/PtDadosDaEmpresa/PtDadosDaEmpresa";
import { camposInvalidos,  validarCnpj } from '@func/utils';
import { msgs, msgStatus } from "@_func/request";

const PortalDadosEmpresaFormEdicao = props => {

	const {
		idFreelancer,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-dadosemp-form-edicao',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetCnpj, setLoadingGetCnpj] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({
		description: '',
	});
	const [_stateDados, _setStateDados] = useState({});

	// Edição
	const [loadingEditar, setLoadingEditar] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		setLoading(true);

		const retGetDados = await apiFreelancerCompany.getOne.get(idFreelancer);

		if(retGetDados.status === 200) {
			_setStateDados(msgStatus(retGetDados));
			setStateDados(retGetDados.data);
		} else if(retGetDados.status === 403) {
			_setStateDados(msgStatus(retGetDados));
			msgs([retGetDados]);
		} else {
			_setStateDados(msgStatus({status: 200}));
		}

		setLoading(false);
	}

	/*
	** Editar dados da empresa
	** ================================================== */
	const editar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			cnpj: stateDados.cnpj,
			fantasy_name: stateDados.fantasy_name,
			razao_social: stateDados.razao_social,
			address: stateDados.address
		};

		if(camposInvalidos(dadosEnviar, {}, [
			'cnpj',
			'fantasy_name',
			'razao_social',
			'address',
		])) return;

		if(!validarCnpj(stateDados.cnpj)) return;

		setLoadingEditar(true);

		const retInsertDadosEmpresa = await apiFreelancerCompany.insert.post({
			...dadosEnviar,
			id_freelancer: idFreelancer,
		});

		if(retInsertDadosEmpresa.status === 200) {
			msg.success('Dados da empresa atualizados com sucesso.');
			onSuccess({
				ret: retInsertDadosEmpresa,
				dados: stateDados,
			})
		} else {
			msgs([retInsertDadosEmpresa]);
			onError({
				ret: retInsertDadosEmpresa,
			});
		}

		setLoadingEditar(false);
	}

	return (
		<Portal
			titulo="Dados da empresa"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<>
								<div className="desc"><strong>Importante:</strong> caso você seja selecionado para um job, somente poderá aceitar o briefing caso o seu cadastro esteja completo com os dados da sua empresa.</div>
								<PtDadosDaEmpresa
									classNameWrap="margin-t-20"
									stateDadosEmpresa={stateDados}
									setStateDadosEmpresa={setStateDados}
									loadingGetDadosEmpresa={loadingGetCnpj}
									setLoadingGetDadosEmpresa={setLoadingGetCnpj}
								/>
								<div className="form-bottom">
									<Button2
										_={{
											value: 'Cancelar',
											className: 'btn btn-1',
											onClick: onClose,
										}}
									/>
									<Button2
										_={{
											value: 'Salvar dados',
											onClick: editar,
										}}
										wrap="margin-l-10"
										loading={loadingEditar}
									/>
								</div>
							</>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export { PortalDadosEmpresaFormEdicao };
