import React, { useEffect, useState } from 'react';

// css
import './PortalFoto.css';

// Componentes
import Portal from '@cpnt/Portal/Portal';
import Loading from "@cpnt/Loading/Loading";

// Funções
import { caminhoFoto, msg } from '@func/utils';
import Icone from "@cpnt/Icone/Icone";
import imgIconeExpand from '@svg/regular/arrows-maximize.svg';

const PortalFoto = props => {

	const {
		show,
		foto,
		onClose,
		addUrl = true,
	} = props;

	const [toggleClassExpand, setToggleClassExpand] = useState(0);
	const [loading, setLoading] = useState(false);
	const [stateFoto, setStateFoto] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateFoto(false);
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 0);
		}
	}

	/*
	** Init
	** ================================================== */
	const inicializarGets = async () => {
		if(foto && foto !== '') {
			if(addUrl) {
				setStateFoto(caminhoFoto(foto).url);
			} else {
				setStateFoto(foto);
			}
		} else {
			msg.error('Foto não encontrada.');
		}
		setLoading(false);
	}

	return (
		<Portal
			show={show}
			closeOnEsc={true}
			onClose={onClose}
			className={`cpnt-portal-foto cpnt-porta-foto-expand-ativo-${toggleClassExpand}`}
			classNameX="x"
			classNameDefault=""
		>
			{loading ? (
				<div className="padding-30">
					<Loading texto="Carregando foto, aguarde..." />
				</div>
			) : (
				<>
					<div className="border-radius-5 overflow">
						{stateFoto && stateFoto !== '' ? (
							<img src={stateFoto} alt="Foto" className="block w-100p" />
						) : (
							<div className="cpnt-portal-default">Foto não disponível.</div>
						)}
					</div>
					<div className="cpnt-porta-foto-expand" onClick={() => setToggleClassExpand(toggleClassExpand >= 2 ? 0 : (toggleClassExpand + 1))}>
						<Icone imagem={imgIconeExpand} cor="#FFF" tamanho="16px" />
					</div>
				</>
			)}
		</Portal>
	);
};

export { PortalFoto };
