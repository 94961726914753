import React, { useState, useEffect } from 'react';
import _ from 'lodash';

// css
import './ListaComCheckbox2.css';

// Componentes
import LinhaItem from "@cpnt/LinhaItem/LinhaItem";
import Checkbox2 from "@cpnt/Checkbox2/Checkbox2";
import Input2 from "@cpnt/Input2/Input2";

// Funções
import { msg, stringPesquisa } from '@func/utils';

const ListaComCheckbox2 = props => {
	const {
		termo = '',
		className = '',
		classNameWrap = '',
		wrap = '',
		classNameLista = '',
		tituloPesquisar = 'Pesquisar',
		stateItens = [],
		dados = {},
		showPesquisa = true,
		min,
		max,
		keyTitulo = 'name',
		setStateItens,
		before,
		after,
	} = props;

	const [stateTermo, setStateTermo] = useState(termo);
	const [itensExibicao, setItensExibicao] = useState(stateItens);
	const [stateQtd, setStateQtd] = useState([]);

	useEffect(() => {
		setItensExibicao(stateItens);
		filtrarItens(stateTermo);
		setStateQtd(stateItens.filter(item => item.checked));
	}, [stateItens]);

	/*
	** Filtrar campos / pesquisa
	** ================================================== */
	const filtrarItens = termoPesquisa => {
		var itensFiltrados = stateItens.filter(value => {
			var textoUsuario = stringPesquisa(termoPesquisa);
			var textoExistente = stringPesquisa(value[keyTitulo]);

			if(textoExistente.indexOf(textoUsuario) > -1) {
				return textoExistente;
			}
		});
		setItensExibicao(itensFiltrados);
	}

	/*
	** Marcar campo
	** ================================================== */
	const marcarCampo = dadosObj => {
		const isChecked = dadosObj.e.target.checked;
		const itensAtualizados = stateItens.map((val, key) => {
			if(val.id === dadosObj.dados.id) {
				if(isChecked) {
					return {
						...val,
						...dados,
						checked: true,
					}
				} else {
					if(dados) {
						_.unset(val, Object.keys(dados));
					}
					return {
						...val,
						checked: false,
					}
				}
			}
			return val;
		});

		if(isChecked && max) {
			const selecionados = itensAtualizados.filter(val => val.checked === true);
			if(selecionados.length > max) {
				msg.error(`Selecione no máximo ${max} opções.`);
			}
		}

		setStateItens(itensAtualizados);
	}

	/*
	** Marcar ou desmarcar todos os campos
	** ================================================== */
	const marcarDesmarcarTodos = checked => {
		const itensAtualizados = stateItens.map((val, key) => {
			return {
				...val,
				...dados,
				checked: checked,
			}
		});
		if(checked && max) {
			if(itensAtualizados.length > max) {
				msg.error(`Selecione no máximo ${max} opções.`);
			}
		}
		setStateItens(itensAtualizados);
	}

	return (
		<>
			<div className={`cpnt-listacheckbox2 ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-listacheckbox2-box ${className}`}>
					{before}
					{showPesquisa ? (
						<div className="padding-b-15">
							<Input2
								label={tituloPesquisar}
								_={{
									onChange: e => {
										setStateTermo(e.target.value);
										filtrarItens(e.target.value);
									},
									type: 'search',
									autoFocus: true,
								}}
								debounce={false}
							/>
						</div>
					) : null}

					<div className="flex fs-14 padding-b-15 flex-column-580">
						<LinhaItem titulo="Selecionados" desc={stateQtd.length} alt />
						{min || max ? (
							<div className="margin-l-30 flex">
								{min || min === 0 ? <LinhaItem titulo="Mínimo" desc={min} alt /> : null}
								{max ? <LinhaItem titulo="Máximo" desc={max} alt className={min || min === 0 ? 'margin-l-10' : ''} /> : null}
							</div>
						) : null}
						<div className="margin-l-auto">
							<div className="flex">
								<div className="a" onClick={e => marcarDesmarcarTodos(true)}>Marcar todos</div>
								<div className="margin-h-5 color-3">|</div>
								<div className="a" onClick={e => marcarDesmarcarTodos(false)}>Desmarcar todos</div>
							</div>
						</div>
					</div>

					<div className={`cpnt-listacheckbox2-lista ${classNameLista}`}>
						{!itensExibicao.length ?
							<h3 className="msg">Nenhuma informação encontrada</h3>
						:
							itensExibicao.map(value => {
								return (
									<div key={value.id || value[keyTitulo]} className="cpnt-listacheckbox2-lista-item">
										<div className="flex flex-center-v">
											<div className="flex-1">{value[keyTitulo]}</div>
											<div>
												<Checkbox2
													_={{
														checked: value.checked || false,
														onChange: e => marcarCampo({
															e: e,
															dados: value,
														})
													}}
												/>
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
					{after}
				</div>
			</div>
		</>
	);
};

export { ListaComCheckbox2 };