/*
** Verifica se é número
** ================================================== */
export const isNumeric = value => {
    return /^-?\d+$/.test(value);
}

/*
** Pega o primeiro nome do usuário
** ================================================== */
export const primeiroNome = nomeCompleto => {
	if(nomeCompleto && nomeCompleto !== '' && nomeCompleto.indexOf(' ') >= 0) {
		return nomeCompleto.split(' ')[0];
	}
	return nomeCompleto;
}
