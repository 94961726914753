import React, { useState } from 'react';

import { BarraHorizontal } from "@cpnt/BarraHorizontal/BarraHorizontal";
import Portal from "@cpnt/Portal/Portal";
import { PtDadosPessoais } from "@cpnt/Partes/PtDadosPessoais/PtDadosPessoais";
import { PtEndereco } from "@cpnt/Partes/PtEndereco/PtEndereco";

// css
import './Passo1.css';
import Input2 from "@cpnt/Input2/Input2";
import { BtnAdd } from "@cpnt/BtnAdd/BtnAdd";
import Button2 from "@cpnt/Button2/Button2";
import Loading from "@cpnt/Loading/Loading";
import stringImg from "@cpnt/stringImg/stringImg";
import { addPrefixo, msg, validarUrl } from "@func/utils";
import { SecaoRedesSociais } from "@cpnt/SecaoRedesSociais/SecaoRedesSociais";

const Passo1 = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		stateDados = {},
		setStateDados,

		statePaises = [],
		loadingGetPaises,

		stateRedesSociais = [],
		setStateRedesSociais,
		loadingGetRedesSociais,

		stateEndereco,
		setStateEndereco,
		loadingGetEndereco,
	} = props;

	const [showPortalAddRedeSocial, setShowPortalAddRedeSocial] = useState(false);
	const [showPortalEditarRedeSocial, setShowPortalEditarRedeSocial] = useState(false);
	const [stateAdicionar, setStateAdicionar] = useState('');
	const [stateEditar, setStateEditar] = useState({});

	return (
		<>
			<div className={`cpnt-passo1 ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-passo1-box ${className}`}>
					<div>
						<h3 className="subtitulo margin-t-0 margin-b-10">Dados pessoais</h3>
						<PtDadosPessoais
							stateDados={stateDados}
							setStateDados={setStateDados}
							statePaises={statePaises}
							loadingGetPaises={loadingGetPaises}
						/>
					</div>

					<div className="margin-t-20">
						<h3 className="subtitulo margin-b-10">Endereço para contato</h3>
						<PtEndereco
							stateDados={stateDados}
							setStateDados={setStateDados}
							internacional={stateDados.id_country !== 33 ? true : false}
						/>
					</div>

					<SecaoRedesSociais
						subtitulo="Informe as redes sociais que você usa para fins profissionais. Isso nos ajuda a conhecer melhor o seu trabalho."
						stateRedesSociais={stateRedesSociais}
						setStateRedesSociais={setStateRedesSociais}
						loadingGetRedesSociais={loadingGetRedesSociais}
					/>
				</div>
			</div>

		</>
	);
};

export { Passo1 };