import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast as msg } from 'react-toastify';

// API
import apiRedefinition from '@api/redefinition/redefinition';

// Components
import Input from '@cpnt/Input/Input';
import OverlayConteudo from '@cpnt/OverlayConteudo/OverlayConteudo';
import Button2 from '@cpnt/Button2/Button2';

// Imagens
import logo from '@img/logo.png';

// Funções
import { validar200, validarEmail, camposInvalidos } from '@func/utils';
import { msgs, msgStatus } from "@_func/request";

const RecuperarSenha  = props => {

	const [loading, setLoading] = useState(false);
	const [overlaySucesso, setOverlaySucesso] = useState(false);
	const [overlayMensagem, setOverlayMensagem] = useState(false);
	const [emailInvalido, setEmailInvalido] = useState(false);

	const formSubmit = async e => {
		e.preventDefault();

		if(loading) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			email: e.target.elements.email.value
		};

		if(camposInvalidos(dadosEnviar)) {
			setEmailInvalido(true);
			return;
		}

		if(!validarEmail(dadosEnviar.email)) {
			msg.error('E-mail inválido.');
			return;
		}

		setLoading(true);

		const retRedefinirSenha = await apiRedefinition.redefinirSenha.put(dadosEnviar);

		if(validar200([retRedefinirSenha])) {
			setOverlaySucesso(true);
			setOverlayMensagem(retRedefinirSenha.message);
			msg.success(retRedefinirSenha.message);
		} else {
			msgs([retRedefinirSenha]);
		}

		setLoading(false);
	}

	return (
		<div>
			<div className="tela-inicio-e-inner">
				<OverlayConteudo
					toggle={e => setOverlaySucesso(false)}
					exibir={overlaySucesso}
					texto={overlayMensagem}
				/>
				<div className="tela-inicio-e-logo padding-t-30 padding-b-30">
					<img src={logo} alt="Logo CROWD" className="block w-100p max-w-350 margin-auto" />
				</div>
				<h1 className="tela-inicio-e-titulo padding-t-20">Recuperar Senha</h1>
				<form className="tela-inicio-form" methoed="POST" action="/meus-jobs" onSubmit={formSubmit}>
					<div className="input-box">
						<Input
							name="email"
							type="email"
							label='E-mail cadastrado*'
							invalid={emailInvalido}
							invalidToggle={e => setEmailInvalido(false)}
						/>
					</div>
					<div className="input-box">
						<Button2
							_={{
								value: 'Enviar para meu e-mail',
								className: 'btn btn-default flex-1 w-100p'
							}}
							wrap="flex flex-1"
							loading={loading}
						/>
					</div>
				</form>
				<div className="margin-t-30 fs-13 padding-b-30">
					Já resetou sua senha? <Link to="/login" className="no-decoration bold">Acesse sua conta</Link>
				</div>
			</div>
		</div>
	)
}

export default RecuperarSenha;