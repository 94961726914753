const JOB_MENSAGENS_PADROES = {};

/*
** Proposta - Aprovado
** ================================================== */
JOB_MENSAGENS_PADROES.propostaAprovada = {
	titulo: 'Sua proposta foi aceita',
	mensagem: "Parabéns, sua proposta foi aceita.\n\nEm breve, o responsável deste job vai entrar em contato com você para enviar as orientações necessárias para você iniciar a execução do job.\n\nQualquer dúvida que você tiver durante a execução, é só entrar em contato com o responsável deste job.\n\nUm ótimo trabalho!",
	tipo: 'verde',
};

/*
** Proposta - Recusada
** ================================================== */
JOB_MENSAGENS_PADROES.propostaRecusada = {
	titulo: 'Sua proposta foi recusada',
	mensagem: "Infelizmente sua proposta não foi aceita. Você pode negociar para chegar a um acordo que fique bom para ambas as partes.",
	tipo: 'vermelho',
};

/*
** Proposta - Cancelada
** ================================================== */
JOB_MENSAGENS_PADROES.propostaCancelada = {
	titulo: 'Proposta cancelada',
	mensagem: "Essa proposta foi cancelada. Se você acredita que houve algum engano com o cancelamento dessa proposta, entre em contato conosco.",
	tipo: 'vermelho',
}

/*
** Proposta - Em Orçamento
** ================================================== */
JOB_MENSAGENS_PADROES.propostaEmOrcamento = {
	titulo: 'Proposta em orçamento',
	mensagem: "O status dessa tarefa foi alterado para \"Em Orçamento\".",
	tipo: 'amarelo',
}

/*
** Proposta - Em execução
** ================================================== */
JOB_MENSAGENS_PADROES.propostaEmExecucao = {
	titulo: 'Proposta em execução',
	mensagem: "O status dessa tarefa foi alterado para \"Em Execução\".",
	tipo: 'azul',
}

/*
** JOBS / CARDS
** ================================================== */

/*
** Job - Em Orçamento
** ================================================== */
JOB_MENSAGENS_PADROES.jobEmOrcamento = {
	titulo: 'Job em orçamento',
	mensagem: "O status desse job foi alterado para \"Em Orçamento\".",
	tipo: 'amarelo',
}

/*
** Job - Em execução
** ================================================== */
JOB_MENSAGENS_PADROES.jobEmExecucao = {
	titulo: 'Job em execução',
	mensagem: "O status desse job foi alterado para \"Em Execução\".",
	tipo: 'azul',
}

/*
** Job - Aprovado
** ================================================== */
JOB_MENSAGENS_PADROES.jobAprovado = {
	titulo: 'Seu job foi aprovado',
	mensagem: "Parabéns, seu job foi aprovado e você vai receber por ele na próxima chave de pagamento!\n\nImportante: para receber o pagamento, é necessário fazer o envio da nota fiscal, fique atento as datas de envio para poder receber seu pagamento em dia.",
	tipo: 'verde',
};

/*
** Job - Recusado
** ================================================== */
JOB_MENSAGENS_PADROES.jobRecusado = {
	titulo: 'Seu job foi recusado',
	mensagem: "Infelizmente seu job não foi aceito. Se você acredita que houve algum engano com a reprovação desse job, entre em contato conosco.",
	tipo: 'vermelho',
};

/*
** Job - Cancelado
** ================================================== */
JOB_MENSAGENS_PADROES.jobCancelado = {
	titulo: 'Job cancelado',
	mensagem: "Esse job foi cancelado. Se você acredita que houve algum engano com o cancelamento desse job, entre em contato conosco.",
	tipo: 'vermelho',
}

/*
** Job - Novo job
** ================================================== */
JOB_MENSAGENS_PADROES.novoJob = {
	titulo: 'Novo job',
	mensagem:
`Olá! Tudo bem?

Este é o job referente à [atividade] para o [projeto] de [período de tempo].

Por favor, você poderia me enviar a proposta com o valor e o prazo para execução do serviço?

Obrigado!`,
	tipo: 'vermelho',
}

/*
** Export
** ================================================== */
export default JOB_MENSAGENS_PADROES;