import { DEV, URL_BLOG, URL_CROWD_ACADEMY } from "@_config/config";

/*
** Sidebar Premium
** ================================================== */
const sidebarPremium = {
	menu: [
		{
			title: 'Contratações',
			itens: [

				{
					title: 'Página inicial',
					path: '/inicio',
					icon: 'solid/house.svg',
				},
				{
					title: 'Busca com IA',
					path: '/buscar-profissionais',
					icon: 'solid/magnifying-glass.svg',
				},
				{
					title: 'Briefing/Vaga',
					path: '/briefing',
					icon: 'match.svg',
					custom: true,
				},
				{
					title: 'Contratações',
					path: '/contratacoes',
					icon: 'solid/shield-halved.svg',
				},
			],
		},
		{
			title: 'Gerenciar',
			itens: [

				{
					title: 'Templates',
					path: '/templates',
					icon: 'regular/copy.svg',
				},
				{
					title: 'Meu Perfil',
					path: '/perfil',
					icon: 'solid/user-large.svg',
				},
				{
					title: 'Clientes',
					path: '/clientes',
					icon: 'solid/heart.svg',
				},
				{
					title: 'Projetos',
					path: '/projetos',
					icon: 'solid/folder.svg',
				},
			]
		},
		{
			title: 'Outros',
			itens: [

				{
					title: 'Academy',
					path: URL_CROWD_ACADEMY,
					icon: 'solid/book-open.svg',
				},
				{
					title: 'Blog',
					path: URL_BLOG,
					icon: 'solid/newspaper.svg',
				},
				{
					title: 'Termos de uso',
					path: '/perfil?secao=termos',
					icon: 'regular/file-lines.svg',
				},
			]
		},
		{
			title: '',
			itens: []
		},
		{
			title: '',
			itens: [
				{
					title: 'Central de ajuda',
					path: '/perfil?secao=ajuda',
					icon: 'solid/circle-question.svg',
				},
				{
					title: 'Logout',
					path: '/logout',
					icon: 'solid/right-from-bracket.svg',
				},
			]
		},

		// {
		// 	title: 'Financeiro',
		// 	itens: [
		// 		{
		// 			title: 'Fechamento',
		// 			path: '/fechamento',
		// 			icon: 'solid/house.svg',
		// 		},
		// 		{
		// 			title: 'Gestão de remessa',
		// 			path: '/fechamento/gestao-de-remessa',
		// 			icon: 'solid/house.svg',
		// 			inativo: true,
		// 		},
		// 	]
		// },

	]
};

export { sidebarPremium };
