/*
** Trata o endereço do algolia
** ================================================== */
export const enderecoAlgolia = dados => {
	var retorno = '';

	if(dados.Country && typeof(dados.Country) === 'object') {
		if(dados.Country.Name && dados.Country.Name !== '') {
			retorno += dados.Country.Name;
		}
	} else if(dados.Country && dados.Country !== '') {
		retorno += dados.Country;
	}

	if(dados.City && typeof(dados.City) === 'object') {
		if(dados.City.Name && dados.City.Name !== '') {
			retorno += retorno === '' ? dados.City.Name : ` / ${dados.City.Name}`;
		}
	} else if(dados.City && dados.City !== '') {
		retorno += retorno === '' ? dados.City : ` / ${dados.City}`;
	}

	if(dados.State && typeof(dados.State) === 'object') {
		if(dados.State.Name && dados.State.Name !== '') {
			retorno += retorno === '' ? dados.State.Name : ` / ${dados.State.Name}`;
		}
	} else if(dados.State && dados.State !== '') {
		retorno += retorno === '' ? dados.State : ` / ${dados.State}`;
	}

	if(retorno === '') {
		retorno = 'Endereço não informado';
	}

	return retorno;
}
