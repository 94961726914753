import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalPerfil.css';

// API
import apiFreelancerLanguages from '@api/freelancerLanguages/freelancerLanguages';
import apiFreelancerSocial from '@api/freelancerSocial/freelancerSocial';
import apiFreelancerSkills from '@api/freelancerSkills/freelancerSkills';
import apiFreelancerCompetence from '@api/freelancerCompetence/freelancerCompetence';
import apiFreelancerSegments from '@api/freelancerSegments/freelancerSegments';
import apiFreelancerExperience from '@api/freelancerExperience/freelancerExperience';
import apiFreelancerFormation from '@api/freelancerFormation/freelancerFormation';
import apiFreelancerCertificate from '@api/freelancerCertificate/freelancerCertificate';
import apiFreelancerAwards from '@api/freelancerAwards/freelancerAwards';
import apiFreelancerPortfolio from '@api/freelancerPortfolio/freelancerPortfolio';
import apiFreelancerGoals from '@api/freelancerGoals/freelancerGoals';
import { api } from "@_api/api";

// Components
import Portal from '@cpnt/Portal/Portal';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import apiFreelancer from '@api/freelancer/freelancer';

// Partes
import PerfilFreelancer from './PerfilFreelancer/PerfilFreelancer';
import PerfilUsuario from './PerfilUsuario/PerfilUsuario';
import apiUserSkills from '@api/userSkills/userSkills';
import apiUserSkillsExpertise from '@api/userSkillsExpertise/userSkillsExpertise';
import apiUserCompetences from '@api/userCompetences/userCompetences';
import { msgs } from '@_func/request';

const PortalPerfilUsuario = props => {
	const {
		show,
		wrap = '',
		onClose,
		closeOnEsc = true,
		className = '',
		portalClassName = 'cpnt-perfil-portal-container',
		id,
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetRedesSociais, setLoadingGetRedesSociais] = useState(true);
	const [loadingGetIdiomas, setLoadingGetIdiomas] = useState(true);

	const [loadingGetHabilidades, setLoadingGetHabilidades] = useState(true);
	const [loadingGetCompetencias, setLoadingGetCompetencias] = useState(true);
	const [loadingGetSegmentos, setLoadingGetSegmentos] = useState(true);
	const [loadingGetExperiencias, setLoadingGetExperiencias] = useState(true);
	const [loadingGetFormacoes, setLoadingGetFormacoes] = useState(true);
	const [loadingGetCertificados, setLoadingGetCertificados] = useState(true);
	const [loadingGetPremios, setLoadingGetPremios] = useState(true);
	const [loadingGetPortfolios, setLoadingGetPortfolios] = useState(true);
	const [loadingGetObjetivos, setLoadingGetObjetivos] = useState(true);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(false);
	const [stateRedesSociais, setStateRedesSociais] = useState([]);
	const [stateIdiomas, setStateIdiomas] = useState([]);
	const [stateHabilidades, setStateHabilidades] = useState([]);
	const [stateCompetencias, setStateCompetencias] = useState([]);
	const [stateSegmentos, setStateSegmentos] = useState([]);
	const [stateExperiencias, setStateExperiencias] = useState([]);
	const [stateFormacoes, setStateFormacoes] = useState([]);
	const [stateCertificados, setStateCertificados] = useState([]);
	const [statePremios, setStatePremios] = useState([]);
	const [statePortfolios, setStatePortfolios] = useState([]);
	const [stateObjetivos, setStateObjetivos] = useState([]);
	// const [stateDadosEmpresa, setStateDadosEmpresa] = useState([]);

	/*
	** State - Usuário
	** ================================================== */
	const [stateHabilidadesArea, setStateHabilidadesArea] = useState([]);
	const [stateCompetenciasUsuario, setStateCompetenciasUsuario] = useState([]);

	/*
	** State - Loading
	** ================================================== */
	const [loadingGetHabilidadesArea, setLoadingGetHabilidadesArea] = useState(false);
	const [loadingGetHabilidadesEspecialidade, setLoadingGetHabilidadesEspecialidade] = useState(false);
	const [loadingGetCompetenciasUsuario, setLoadingGetCompetenciasUsuario] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		inicializarGets();
	}, [show]);

	/*
	** FREELANCER
	** ================================================== */
	const inicializarGets = async () => {
		if(show === true) {

			setStateDados(false);
			setLoading(true);

			const retGetUsuario = await api.users.getOne.get(id);
			if(retGetUsuario.status === 200) {
				setLoading(false);
				setStateDados(retGetUsuario.data);
				getHabilidadesArea(id);
				getCompetenciasUsuario(id);
			} else {
				setLoading(false);
				setStateDados(retGetUsuario.error || 'Não foi possível carregar os dados do usuário.');
				msgs([retGetUsuario]);
			}

		}
	}

	/*
	** API - Habilidades área
	** ================================================== */
	const getHabilidadesArea = async id => {
		setLoadingGetHabilidadesArea(true);
		const retGetHabilidadesArea = await apiUserSkills.getAll.get(id);
		if(retGetHabilidadesArea.status === 200) {
			setStateHabilidadesArea(retGetHabilidadesArea.data);
		} else {
			setStateHabilidadesArea([]);
		}
		setLoadingGetHabilidadesArea(false);
	}

	/*
	** API - Competências usuário
	** ================================================== */
	const getCompetenciasUsuario = async id => {
		setLoadingGetCompetenciasUsuario(true);
		const retGetCompetenciasUsuario = await apiUserCompetences.getAll.get(id);
		if(retGetCompetenciasUsuario.status === 200) {
			setStateCompetenciasUsuario(retGetCompetenciasUsuario.data);
		} else {
			setStateCompetenciasUsuario([]);
		}
		setLoadingGetCompetenciasUsuario(false);
	}

	return (
		<Portal
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
			classNameDefault="cpnt-perfil-portal"
			classNameX="x"
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				typeof(stateDados) === 'object' ? (
					<div className={`cpnt-perfil ${wrap}`}>
						<div className={`cpnt-perfil-box ${className}`}>
							<PerfilUsuario
								{...stateDados}

								// States
								habilidadesArea={stateHabilidadesArea}
								competencias={stateCompetenciasUsuario}

								// Loading
								loadingGetHabilidadesArea={loadingGetHabilidadesArea}
								loadingGetHabilidadesEspecialidade={loadingGetHabilidadesEspecialidade}
								loadingGetCompetenciasUsuario={loadingGetCompetenciasUsuario}
							/>
						</div>
					</div>
				) : (
					<div className="padding-30">
						<Info icone="erro" texto={stateDados} />
					</div>
				)
			)}
		</Portal>
	);
};

export { PortalPerfilUsuario };