import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiUserSkills = {
	getAll: {
		get: async (idUser) => {
			return await api.get(`/userSkills/getAll/${idUser}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	insertMany: {
		post: async (dados) => {
			return await api.post(`/userSkills/insertMany`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiUserSkills;