import { validarRetorno, config } from '@_api/axios';
import api from 'axios';

const apiCep = {
	get: async cep => {
		return await api.get(`/cep/${cep}`, config())
		.then(retorno => {
			return validarRetorno(retorno);
		})
		.catch(retorno => {
			return validarRetorno(retorno);
		});
	}
};

export { apiCep };
