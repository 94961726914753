import React from 'react';

// css
import './ItemProfissionalSelecionado.css';

// Imagens
import imgIconeTelefone from '@svg/regular/phone.svg';
import imgIconeEmail from '@svg/solid/at.svg';
import imgIconeMapa from '@svg/solid/location-dot.svg';
import imgIconeDinheiro from '@svg/solid/sack-dollar.svg';
import imgIconeUsuario from '@svg/solid/user.svg';
import imgIconeArea from '@svg/duotone/display.svg';
import imgIconeRemover from '@svg/solid/trash-can.svg';

// Componentes
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';
import Tip from '@cpnt/Tip/Tip';

// Funções
import { cssBg } from '@func/utils';

const ItemProfissionalSelecionado = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		children,
		dados = {},
		onClickRemover,
		x,
	} = props;

	return (
		<>
			<div className={`cpnt-itemprofsel ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-itemprofsel-box ${className}`}>
					<Tip
						tip={children}
						closeOnLeave={false}
						_={{
							delayLeave: 100,
							delayEnter: 50,
						}}
					>
						<div className="cpnt-itemprofsel-wrap">
							<div className="cpnt-itemprofsel-wrap-img-box">
								<div className="ratio-1 bg" style={cssBg(dados.foto)}></div>
							</div>
							<div className="align-center strong margin-t--5">Sobre esse prestador de serviços:</div>
							<div className="sep-t margin-v-10"></div>
							<TextoIconeLink
								texto={dados.area}
								icone={imgIconeArea}
								iconeTamanho="16px"
								className="text-one-line"
							/>
							<TextoIconeLink
								texto={dados.nome}
								icone={imgIconeUsuario}
								iconeTamanho="16px"
								className="margin-t-5 text-one-line"
							/>
							<TextoIconeLink
								texto={`${dados.valorHora} / hora`}
								icone={imgIconeDinheiro}
								iconeTamanho="16px"
								className="margin-t-5 text-one-line"
							/>
							<TextoIconeLink
								texto={dados.endereco}
								icone={imgIconeMapa}
								iconeTamanho="16px"
								className="margin-t-5 text-one-line"
							/>
							{x ? (
								<>
									<div className="sep-t margin-v-10"></div>
									<div className="inline-flex">
										<TextoIconeLink
											texto="Remover da lista atual"
											className="cursor-pointer"
											icone={imgIconeRemover}
											iconeCor="#D6232A"
											onClick={e => onClickRemover(dados)}
										/>
									</div>
								</>
							) : null}
						</div>
					</Tip>
				</div>
			</div>
		</>
	);
};

export { ItemProfissionalSelecionado };
