import React, { useState } from 'react';

// css
import './SecaoExperiencias.css';

// Portais
import { PortalExperienciaFormCadastro } from "@cpnt/Portais/PortaisExperiencias/PortalExperienciaFormCadastro/PortalExperienciaFormCadastro";
import { PortalExperienciaFormEdicao } from "@cpnt/Portais/PortaisExperiencias/PortalExperienciaFormEdicao/PortalExperienciaFormEdicao";

// Componentes
import { ListaSecao } from "@cpnt/ListaSecao/ListaSecao";

const SecaoExperiencias = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		classNameTituloBox = '',
		classNameListaWrap = 'margin-t-25',
		classNameBox = 'box-2',

		onClickBtnSalvar,
		loadingBtnSalvar,
		stateExperiencias = [],
		setStateExperiencias,
		loadingGetExperiencias,
	} = props;

	const [showPortalAdd, setShowPortalAdd] = useState(false);
	const [showPortalEditar, setShowPortalEditar] = useState(false);
	const [stateExperienciaEditar, setStateExperienciaEditar] = useState({});

	return (
		<>
			<div className={`cpnt-secexp ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-secexp-box ${className}`}>
					<ListaSecao
						titulo="Experiências"
						textoAdd="Adicionar"
						onClickBtnSalvar={onClickBtnSalvar}
						loadingBtnSalvar={loadingBtnSalvar}
						textoNenhumItemEncontrado="Nenhuma experiência adicionada"
						stateDados={stateExperiencias}
						setStateDados={setStateExperiencias}
						keyTitulo="company"
						keyDesc="role"
						classNameWrap={classNameListaWrap}
						classNameTituloBox={classNameTituloBox}
						classNameBox={classNameBox}
						exibirApenasChecked={false}
						onClickAdd={e => setShowPortalAdd(true)}
						onClickEdit={e => {
							setStateExperienciaEditar({
								key: e.key,
								...e.dados,
							});
							setShowPortalEditar(true);
						}}
						onClickRemover={e => {
							var itens = stateExperiencias.filter((val, key) => key !== e.key);
							setStateExperiencias(itens);
						}}
						loading={loadingGetExperiencias}
						alt
					/>
				</div>
			</div>

			<PortalExperienciaFormCadastro
				show={showPortalAdd}
				onClose={e => setShowPortalAdd(false)}
				onSuccess={e => {
					var exp = [...stateExperiencias];
					exp.push(e);
					setStateExperiencias(exp);
					setShowPortalAdd(false);
				}}
			/>

			<PortalExperienciaFormEdicao
				show={showPortalEditar}
				onClose={e => setShowPortalEditar(false)}
				dados={stateExperienciaEditar}
				onSuccess={e => {
					var exp = [...stateExperiencias];
					exp[e.key] = e;
					setStateExperiencias(exp);
					setShowPortalEditar(false);
				}}
			/>
		</>
	);
};

export { SecaoExperiencias };
