import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter, useLocation } from "react-router-dom";

import { DEV, GTM_ID, URL } from "@_config/config";
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

TagManager.initialize({ gtmId: GTM_ID });

const GTMEventPageViewChange = () => {
	const location = useLocation();

	useEffect(() => {
		const pagePath = URL + location.pathname + location.search;
		TagManager.dataLayer({
			dataLayer: {
				event: 'pageview',
				page: pagePath,
			},
		});
	}, [location]);

	return null;
};

root.render(
	<BrowserRouter>
		{!DEV && <GTMEventPageViewChange />}
		<App />
	</BrowserRouter>
);
