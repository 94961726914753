import md5 from 'crypto-js/md5';
import imgUserPicDefault from '@_img/default/userpic.png';

import { ROLES_CLASS_SLUGS_BY_NIVEL, ROLES_NOMES_BY_NIVEL } from "@_obj/select";
import { caminhoFoto } from "@func/utils";
/*
** Nome do nível de acesso de acordo com a role
** ================================================== */
export const nomeNivelAcesso = role => {
	if(ROLES_NOMES_BY_NIVEL[role] !== undefined) {
		return ROLES_NOMES_BY_NIVEL[role];
	}
	return 'CROWD';
}

/*
** Slug do nível de acesso de acordo com a role
** ================================================== */
export const classNivelAcesso = role => {
	if(ROLES_CLASS_SLUGS_BY_NIVEL[role] !== undefined) {
		return ROLES_CLASS_SLUGS_BY_NIVEL[role];
	}
	return ROLES_CLASS_SLUGS_BY_NIVEL[0];
}

/*
** Classe do body
** ================================================== */
export const addClassNivelAcesso = role => {
	Object.keys(ROLES_CLASS_SLUGS_BY_NIVEL).map((val) => {
		if(ROLES_CLASS_SLUGS_BY_NIVEL[val]) {
			window.document.body.classList.remove(ROLES_CLASS_SLUGS_BY_NIVEL[val]);
		}
	});
	window.document.body.classList.add(classNivelAcesso(role));
}

/*
** Verifica a foto do usuário pelo e-mail no Gravatar
** ================================================== */
export const gravatar = props => {

	/*
	* @param string $email The email address
	* @param int $size Size in pixels, defaults to 64px [ 1 - 2048 ]
	* @param string $default_image_type Default imageset to use [ 404 | mp | identicon | monsterid | wavatar ]
	* @param bool $force_default Force default image always. By default false.
	* @param string $rating Maximum rating (inclusive) [ g | pg | r | x ]
	* @param bool $return_image True to return a complete IMG tag False for just the URL
	* @param array $html_tag_attributes Optional, additional key/value attributes to include in the IMG tag
	* */

	const {
		email,
		size = 200,
		avatar = 'blank',
	} = props;

	return `https://www.gravatar.com/avatar/${md5(email)}?s=${size}&d=${avatar}`;
}

/*
** Verifica se tem imagem e caso não tenha, busca no Gravatar
** ================================================== */
export const fotoOuGravatar = props => {
		const {
		foto,
		email,
		size = 200,
		avatar = 'blank',
		placeholder = imgUserPicDefault,
		cbFoto = true,
	} = props;

	if(foto && typeof(foto) === 'string' && foto !== '' && foto !== '/images/user.png') {
		if(cbFoto) {
			return caminhoFoto(foto).url;
		}
		return foto;
	}
	return gravatar({email, size, avatar});
}
