import { useRoutes } from 'react-router-dom';

// Admin
import LayoutBlank from "@_role/Layouts/LayoutBlank/LayoutBlank";

// Itens sidebar

// Screens
import { HomeFreelancerScreen } from "@_page/Logado/HomeScreen/HomeFreelancerScreen";
import { LayoutPainel } from "@_role/Layouts/LayoutPainel/LayoutPainel";
import { sidebarFreelancer } from "@_role/Sidebar/SidebarFreelancer";
import { RedirectTo } from "@cpnt/RedirectTo/RedirectTo";
import { ConvideUmFreelancer } from "@page/Logado/ConvideUmFreelancer/ConvideUmFreelancer";
import DetalhesJob from "@page/Logado/DetalhesJob/DetalhesJob";
import Erro404Logado from "@page/Logado/Erro404Logado/Erro404Logado";
import { IndiqueACrowd } from "@page/Logado/IndiqueACrowd/IndiqueACrowd";
import Jobs from "@page/Logado/Jobs/Jobs";
import MeusJobs from "@page/Logado/MeusJobs/MeusJobs";
import NegociarMeusJobs from "@page/Logado/NegociarMeusJobs/NegociarMeusJobs";
import { Perfil } from "@page/Logado/Perfil/Perfil";
import PerfilSteps from "@page/Logado/PerfilSteps/PerfilSteps";
import { VideosFreelancer } from "@page/Logado/Videos/VideosFreelancer";
import Logout from "@page/SemRestricoes/Logout/Logout";

export const ROTAS_FREELANCER = [
	{
		path: '/',
		element:
		<LayoutBlank
			title="Página inicial"
			component={RedirectTo}
			redirectTo="/inicio"
		/>,
	},
	{
		path: '/inicio',
		element:
		<LayoutPainel
			sidebar={sidebarFreelancer}
			title="Página inicial"
			component={HomeFreelancerScreen}
		/>,
	},
	{
		path: '/perfil',
		element:
		<LayoutPainel
			sidebar={sidebarFreelancer}
			title="Perfil"
			component={Perfil}
		/>,
	},
	{
		path: '/jobs',
		element:
		<LayoutPainel
			sidebar={sidebarFreelancer}
			title="Propostas de jobs"
			component={Jobs}
		/>,
	},
	{
		path: '/jobs/detalhes',
		element:
		<LayoutPainel
			sidebar={sidebarFreelancer}
			title="Detalhes da proposta de job"
			component={DetalhesJob}
			className="container-chat"
		/>,
	},
	{
		path: '/perfil/passo-a-passo',
		element:
		<LayoutPainel
			sidebar={sidebarFreelancer}
			title="Perfil"
			component={PerfilSteps}
		/>,
	},
	{
		path: '/meus-jobs',
		element:
		<LayoutPainel
			sidebar={sidebarFreelancer}
			title="Meus Jobs"
			component={MeusJobs}
		/>,
	},
	{
		path: '/meus-jobs/negociacao',
		element:
		<LayoutPainel
			sidebar={sidebarFreelancer}
			title="Negociação do job"
			component={NegociarMeusJobs}
			className="container-chat"
		/>,
	},
	{
		path: '/logout',
		element:
		<Logout
			title="Fazendo logout, aguarde..."
		/>,
	},
	{
		path: '/videos/freelancers',
		element:
		<LayoutPainel
			sidebar={sidebarFreelancer}
			title="Vídeos"
			component={VideosFreelancer}
		/>,
	},
	{
		path: '/convide-um-freelancer',
		element:
		<LayoutPainel
			sidebar={sidebarFreelancer}
			title="Convide um freelancer"
			component={ConvideUmFreelancer}
		/>,
	},
	{
		path: '/indique-a-crowd',
		element:
		<LayoutPainel
			sidebar={sidebarFreelancer}
			title="Indique a Crowd"
			component={IndiqueACrowd}
		/>,
	},

	// {
	// 	path: '/comunidade',
	// 	element:
	// 	<LayoutPainel
	// 		sidebar={sidebarFreelancer}
	// 		meta={{
	// 			title: 'Comunidade',
	// 		}}
	// 		iframe="https://comunidadecrowd.circle.so/home?iframe=true"
	// 		component={CircleEmbed}
	// 	/>,
	// },

	// (DEV ?
	// 	{
	// 		path: '/crowd-academy',
	// 		element:
	// 		<LayoutPainel
	// 			sidebar={sidebarFreelancer}
	// 			meta={{
	// 				title: 'Academy',
	// 			}}
	// 			component={CrowdAcademy}
	// 		/>,
	// 	} : {}
	// ),
	{
		path: '*',
		element:
		<LayoutPainel
			sidebar={sidebarFreelancer}
			title="Página não encontrada..."
			component={Erro404Logado}
		/>,
	}
];

function ScreensFreelancer() {
	const rotasFreelancer = useRoutes(ROTAS_FREELANCER);
	return rotasFreelancer;
}

export { ScreensFreelancer };
