import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiFreelancerCompany = {
	insert: {
		post: async dados => {
			return await api.post(`/freelancerCompany/insert`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getOne: {
		get: async idFreelancer => {
			return await api.get(`/freelancerCompany/getOne/${idFreelancer}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiFreelancerCompany;