import React from 'react';

// CSS
import c from './Br.module.scss';

export const Br = () => {

	return (
		<div className={`br ${c['container']}`} />
	);
}
