import React, { useEffect, useState } from 'react';

// css
import './PortalPreviewSite.css';

// API
import { api } from '@_api/api';

// Componentes
import Portal from '@cpnt/Portal/Portal';
import Loading from '@cpnt/Loading/Loading';
import Info from '@cpnt/Info/Info';
import imgIconeLink from '@svg/regular/arrow-up-right-from-square.svg';

// Funções
import { addPrefixo} from '@func/utils';
import { DetalhesCliente } from '@page/Logado/Clientes/DetalhesCliente/DetalhesCliente';
import Button2 from '@cpnt/Button2/Button2';
import { DetalhesClienteAnexos } from '@page/Logado/Clientes/DetalhesClienteAnexos/DetalhesClienteAnexos';
import Icone from '@cpnt/Icone/Icone';

const PortalPreviewSite = props => {

	const {
		url,
		show,
		onClose,
		closeOnEsc = false,
		portalClassName = 'portal-clientes-detalhes-anexos',
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetAnexos, setLoadingGetAnexos] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({});
	const [_stateDados, _setStateDados] = useState({});
	const [stateAnexos, setStateAnexos] = useState([]);

	return (
		<Portal
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			classNameX="x"
			outside={
				url && url !== '' ? (
					<>
						<a href={addPrefixo(url)} target="_blank" rel="noreferrer" className="cliente-site-preview-btn-link">
							<Icone imagem={imgIconeLink} cor="#FFF" tamanho="12px" />
						</a>
						<div className="cliente-site-preview-resize"></div>
					</>
				) : null
			}
			classNameDefault="cpnt-portal-site"
			classNameContainer="cpnt-portal-site-container"
		>
			{url && url !== '' ? (
				<div>
					<iframe src={addPrefixo(url)} title="sitepreview" frameBorder="0" sandbox="allow-same-origin allow-scripts allow-popups allow-forms"></iframe>
					<div className="fill-center">
						<Loading texto="Carregando site, aguarde..." />
					</div>
				</div>
			) : (
				<div className="fill flex flex-center">
					<Info icone="alerta" texto="Nenhum site encontrado." />
				</div>
			)}
		</Portal>
	);
};

export { PortalPreviewSite };
