import React from 'react';
import ReactPlayer from 'react-player';

// css
import c from './VideoPlayer.module.scss';

const VideoPlayer = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
	} = props;

	/*
	** Valores default
	** ================================================== */
	// https://www.npmjs.com/package/react-player

	var defaults = {
		controls: true,
		light: false,
		playsinline: true,
	};

	var { _ } = props;
	_ = {
		...defaults,
		..._,
	}

	return (
		<>
			<div className={`${c['vid']} ${wrap} ${classNameWrap}`}>
				<div className={`${c['vid-box']} ${className}`}>
					<div className={`${c['vid-wrapper']}`}>
						<div className={`${c['vid-inner']}`}>
							<ReactPlayer className={`${c['vid-player']}`} {..._} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { VideoPlayer };