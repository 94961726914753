import React from 'react';

// Libs
import { Link, NavLink } from "react-router-dom";

// CSS
import c from './IconeTextoRoot.module.scss';

export const IconeTextoRoot = props => {

	const {
		className = '',
		children,
		link,
		color,
		navlink,
		end,
		onClick,
		_,
	} = props;

	if(link) {
		return (
			<Link
				to={link}
				className={`${className}
				${c['container']}`}
				{...(color ? {style: {color: color}} : {})}
				{...(end ? {end: end} : {})}
				onClick={onClick}
				{..._}
			>
				{children}
			</Link>
		)
	}

	if(navlink) {
		return (
			<NavLink
				to={navlink}
				className={`${className}
				${c['container']}`}
				{...(color ? {style: {color: color}} : {})}
				// {...(end ? {end: end} : {})}
				end
				onClick={onClick}
				{..._}
			>
				{children}
			</NavLink>
		)
	}

	return (
		<div className={`${className} ${c['container']}`} {...(color ? {style: {color: color}} : {})} {...(onClick ? {onClick: onClick} : {})}>
			{children}
		</div>
	);
}
