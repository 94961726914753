import React from 'react';

export const Texto = props => {

	const {
		children,
		...rest
	} = props;

	return (
		<div {...rest}>
			{children}
		</div>
	);
}
