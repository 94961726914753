import React, { useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// Imagens
import imgIconeCancelado from '@svg/solid/circle-x.svg';
import imgIconeAprovado from '@svg/solid/circle-check.svg';

// Componentes
import Icone from '@cpnt/Icone/Icone';

// Funções
import { formatarDinheiro, removerHtml } from '@func/utils';

const ChatMensagemProposta = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		mensagem,
		metadata = {
			proposta: {}
		},
		onClickBtnRespostaProposta = () => null,
		onClickInfo = () => null,
		idProposta,
		params,
	} = props;

	return (
		<>
			{/* Título */}
			<div className={`cpnt-chatmsg-custom-titulo`}>
				<h3 className="margin-0 padding-0">Proposta</h3>
				{idProposta ? <div className="fs-12">ID da proposta: {idProposta}</div> : null}
			</div>

			{/* Mensagem			 */}
			{mensagem && mensagem !== '' ? (
				<div className="cpnt-chatmsg-mensagem">{removerHtml(mensagem)}</div>
			) : null}

			{/* Dados da mensagem */}
			{store.usuario.role === 2 ? (
				<div className="cpnt-chatmsg-custom-conteudo box-txt align-center padding-10">
					<div>
						<div>Total a receber</div>
						<h3 className="margin-0 fs-22 color-green">{formatarDinheiro(metadata.proposta.valorSemPorcentagem, 'R$ ')}</h3>
					</div>
					<div className="margin-t-10">
						<div>Prazo de execução do serviço</div>
						<h3 className="margin-0 fs-18">{metadata.proposta.prazo ? (metadata.proposta.prazo === 1 ? `${metadata.proposta.prazo} dia` : `${metadata.proposta.prazo} dias`) : 'Não informado'}</h3>
					</div>
				</div>
			) : (
				<div className="cpnt-chatmsg-custom-conteudo box-txt align-center padding-10">
					<div>
						<div>Valor total do serviço</div>
						<h3 className="margin-0 fs-22 color-green">{formatarDinheiro(metadata.proposta.valorComPorcentagem, 'R$ ')}</h3>
					</div>
					<div className="margin-t-10 fs-12 max-w-250 margin-l-auto margin-r-auto strong">
						Já incluída a garantia de contratação segura CROWD. <span className="i cursor-pointer" onClick={onClickInfo}></span>
					</div>
					<div className="margin-t-10 sep-t margin-t-15 padding-t-10">
						<div>Prazo de execução do serviço</div>
						<h3 className="margin-0 fs-18">{metadata.proposta.prazo ? (metadata.proposta.prazo === 1 ? `${metadata.proposta.prazo} dia` : `${metadata.proposta.prazo} dias`) : 'Não informado'}</h3>
					</div>
				</div>
			)}

			{/* Botões */}
			<div className="cpnt-chatmsg-botoes relative">
				{params.contracted || params.refused ? (
					<div className={`fill-center z-index-1 strong ${params.contracted ? 'bg-green-light' : 'bg-red-light'}`}>
						{params.contracted ? (
							<div className="flex flex-center">
								<Icone
									imagem={imgIconeAprovado}
									cor="#229d3e"
									tamanho="16px"
								/>
								<span className="color-green margin-l-5">Proposta aceita</span>
							</div>
						 ) : (
							<div className="flex flex-center">
								<Icone
									imagem={imgIconeCancelado}
									cor="#E74C3C"
									tamanho="16px"
								/>
								<span className="color-red margin-l-5">Proposta recusada</span>
							</div>
						)}
					</div>
				) : null}
				<div
					className="cpnt-chatmsg-botao btn btn-red"
					onClick={() => {
						onClickBtnRespostaProposta({
							resposta: false,
							mensagem: mensagem,
							metadata: metadata,
							idProposta: idProposta,
							params: params,
						})
					}}
				>
					Recusar
				</div>
				<div
					className="cpnt-chatmsg-botao btn btn-green"
					onClick={() => {
						onClickBtnRespostaProposta({
							resposta: true,
							mensagem: mensagem,
							metadata: metadata,
							idProposta: idProposta,
							params: params,
						})
					}}
				>
					Aceitar
				</div>
			</div>
		</>
	);
};

export default ChatMensagemProposta;