import React from 'react';

// CSS
import c from './EstrelasAvaliacao.module.scss';

export const EstrelasAvaliacao = props => {

	const {
		className = '',
		value,
		onClick,
	} = props;

	return (
		<div
			className={`${c['estrelas']} ${className}`}
		>
			<div className={`${c['rate']}`}>
				<i className={`${value && value > 0 ? c['active'] : ''}`} onClick={() => onClick(1)}>★</i>
				<i className={`${value && value > 1 ? c['active'] : ''}`} onClick={() => onClick(2)}>★</i>
				<i className={`${value && value > 2 ? c['active'] : ''}`} onClick={() => onClick(3)}>★</i>
				<i className={`${value && value > 3 ? c['active'] : ''}`} onClick={() => onClick(4)}>★</i>
				<i className={`${value && value > 4 ? c['active'] : ''}`} onClick={() => onClick(5)}>★</i>
			</div>
		</div>
	);
}
