import { validarRetorno, config } from '../axios';
import api from 'axios';
import qs from 'qs';

const apiBriefingFreelancers = {
	insertMany: {
		post: async (dados) => {
			return await api.post(`/briefingFreelancers/insertMany`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getAll: {
		get: async idBriefing => {
			return await api.get(`/briefingFreelancers/getAll/${idBriefing}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getOneBriefing: {
		get: async idBriefing => {
			return await api.get(`/briefingFreelancers/getOneBriefing/${idBriefing}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	allChats: {
		get: async dadosObj => {
			var dadosEnviar = qs.stringify(dadosObj, { encode: false });
			return await api.get(`/briefingFreelancers/allChats?${dadosEnviar}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	all: {
		get: async dadosObj => {
			var dadosEnviar = qs.stringify(dadosObj, { encode: false });
			return await api.get(`/briefingFreelancers/all?${dadosEnviar}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	jobsCombos: {
		get: async idFreelancer => {
			return await api.get(`/briefingFreelancers/jobsCombos/${idFreelancer}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiBriefingFreelancers;