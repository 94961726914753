import { toast as msg } from 'react-toastify';
import _ from 'lodash';

// Funções
import { caminhoFoto, formatarDinheiro } from './utils';
import { enderecoAlgolia } from '@cpnt/ListaProfissionais/funcListaProfissionais';

/*
** Inicializar
** ================================================== */
export const initListaLocal = () => {
	var listas = getAllUsuariosLocal();
	var retorno = {
		jaExistia: true,
		sucessoCriar: null,
	};
	if(!listas) {
		listas = {
			'Lista padrão': []
		};
		try {
			localStorage.setItem('@crowdplataforma/usuariosbriefing', JSON.stringify(listas));
			retorno = {
				jaExistia: false,
				sucessoCriar: true,
			}
		} catch (error) {
			retorno = {
				jaExistia: false,
				sucessoCriar: false,
			}
		}
	}
	return retorno;
}

/*
** Remover todas as listas
** ================================================== */
export const removerTodasAsListasLocal = (dados) => {
	const {
		setStateListas,
		setStateListaAtual,
	} = dados;

	var retorno = false;
	try {
		localStorage.removeItem('@crowdplataforma/usuariosbriefing');
		retorno = true;
	} catch (error) {
	}

	if(retorno) {
		msg.success('Todas as listas foram removidas com sucesso.')
	} else {
		msg.error('Não foi possível remover as listas');
	}

	initListaLocal();

	const listas = listasLocal();
	setStateListas(listas);
	setStateListaAtual('Lista padrão');

	return retorno;
}

/*
** Criar lista
** ================================================== */
export const addListaLocal = (nomeLista, exibirMsgSeExiste = true, retornoTrueSeExiste = false) => {
	if(!nomeLista || nomeLista === '' || nomeLista.trim() === '') {
		msg.error('Insira um nome para a lista');
		return false;
	}

	var listas = getAllUsuariosLocal();
	if(listas[nomeLista] !== undefined) {
		if(exibirMsgSeExiste) {
			msg.error('Já existe uma lista com esse nome.');
		}
		return retornoTrueSeExiste;
	}

	if(!listas) {
		listas = {
			[nomeLista]: []
		};
	} else {
		listas[nomeLista] = [];
	}

	var retorno = false;
	try {
		localStorage.setItem('@crowdplataforma/usuariosbriefing', JSON.stringify(listas));
		retorno = true;
	} catch (error) {
		retorno = false;
	}

	return retorno;
}

/*
** Deletar lista
** ================================================== */
export const removerListaLocal = nomeLista => {
	var listas = getAllUsuariosLocal();
	if(nomeLista === 'Lista padrão') {
		msg.error('Não é possível apagar a lista padrão.');
		return false;
	}

	if(listas[nomeLista] !== undefined) {
		delete listas[nomeLista];
		var retorno = false;
		try {
			localStorage.setItem('@crowdplataforma/usuariosbriefing', JSON.stringify(listas));
			retorno = true;
		} catch (error) {
			retorno = false;
		}
	} else {
		msg.error('Não foi encontrado nenhuma lista com esse nome.');
		return false;
	}

	return retorno;
}

/*
** Deletar lista
** ================================================== */
export const limparListaSelecaoLocal = nomeLista => {
	var listas = getAllUsuariosLocal();
	if(listas[nomeLista] !== undefined) {
		listas[nomeLista] = [];
		var retorno = false;
		try {
			localStorage.setItem('@crowdplataforma/usuariosbriefing', JSON.stringify(listas));
			retorno = true;
		} catch (error) {
			retorno = false;
		}
	} else {
		msg.error('Não foi encontrado nenhuma lista com esse nome.');
		return false;
	}

	return retorno;
}

/*
** Adicionar / Salvar
** ================================================== */
export const addUsuarioLocal = (dadosUsuario, idLista) => {
	var listas = getAllUsuariosLocal();
	if(listas) {
		if(listas[idLista] !== undefined) {
			if(!_.find(listas[idLista], {id: Number(dadosUsuario.id)})) {
				listas[idLista].push(dadosUsuario);
			}
		} else {
			listas[idLista] = [dadosUsuario];
		}
	} else {
		listas = {
			[idLista]: [
				dadosUsuario
			]
		};
	}
	var retorno = false;
	try {
		localStorage.setItem('@crowdplataforma/usuariosbriefing', JSON.stringify(listas));
		retorno = true;
	} catch (error) {
		retorno = false;
	}
	return retorno;
}

/*
** Get um
** ================================================== */
export const getUsuarioLocal = (idUsuario, idLista) => {
	var dados = localStorage.getItem('@crowdplataforma/usuariosbriefing');
	var retorno = false;
	if(dados) {
		var jsonParse = JSON.parse(dados);
		if(jsonParse[idLista] !== undefined) {
			retorno = _.find(jsonParse[idLista], {id: Number(idUsuario)});
		}
	}
	return retorno;
}

/*
** Get All
** ================================================== */
export const getAllUsuariosLocal = (idLista = false, retornoDefault = false) => {
	var dados = localStorage.getItem('@crowdplataforma/usuariosbriefing');
	var retorno = retornoDefault;
	if(dados) {
		var jsonParse = JSON.parse(dados);
		if(idLista) {
			if(jsonParse[idLista] !== undefined) {
				retorno = jsonParse[idLista];
			}
		} else {
			retorno = jsonParse;
		}
	}
	return retorno;
}

/*
** Remover usuario
** ================================================== */
export const removerUsuarioLocal = (idUsuario, idLista) => {
	var listas = getAllUsuariosLocal();
	var retorno = false;
	if(listas) {
		if(listas[idLista] !== undefined) {
			listas[idLista] = listas[idLista].filter(item => Number(item.id) !== Number(idUsuario));
			try {
				localStorage.setItem('@crowdplataforma/usuariosbriefing', JSON.stringify(listas));
				retorno = true;
			} catch (error) {
				retorno = false;
			}
		}
	}
	return retorno;
}

/*
** Listas
** ================================================== */
export const listasLocal = () => {
	var listas = getAllUsuariosLocal();
	var listasItens = Object.keys(listas);
	var retorno = [];
	if(listasItens.length) {
		retorno = [...listasItens.map(item => {
			return {
				value: item,
				label: item,
			}
		})]
	}
	return retorno;
}

/*
** Listas com callback de state
** ================================================== */

/*
** Selecionar freelancer
** ================================================== */
export const selecionarFreelancerLocal = (dadosObj) => {
	const {
		dados,
		stateListaAtual,
		setStateSelecionados,
	} = dadosObj;

	var acao = false;
	if(dados._checked) {
		acao = addUsuarioLocal({
			...dados,
			area: dados.segmento,
		}, stateListaAtual);
	} else {
		acao = removerUsuarioLocal(dados.id, stateListaAtual)
	}
	setStateSelecionados(getAllUsuariosLocal(stateListaAtual));
	return acao;
}

/*
** Selecionar vários freelancers
** ================================================== */
export const addRemoverVariosFreelancersLocal = (dados) => {
	const {
		adicionarFreelancer,
		stateListaAtual,
		stateListaAdicionar,
	} = dados;

	var listas = getAllUsuariosLocal();

	var listaAtual = listas[stateListaAtual];

	if(!listaAtual) {
		msg.error(`Lista atual ${stateListaAtual} não encontrada...`);
		return false;
	}

	if(adicionarFreelancer !== undefined && adicionarFreelancer !== null) {

		var listaFinal = listas;

		if(adicionarFreelancer === true) {
			var listaAppend = [];

			stateListaAdicionar.map((val, key) => {
				var jaExiste = _.find(listaAtual, {id: val.Id});
				if(!jaExiste) {
					listaAppend.push(dadosGetAlgoliaParaGetUser({
						...val,
						_checked: true,
					}));
				}
			});

			listaFinal[stateListaAtual] = [
				...listaAtual,
				...listaAppend
			];
		} else {
			var listaSemSelecao = [];
			if(listaAtual.length) {
				listaAtual.map((val, key) => {
					var item = _.find(stateListaAdicionar, {Id: val.id});
					if(!item) {
						listaSemSelecao.push(val);
					}
				})
			}
			listaFinal[stateListaAtual] = listaSemSelecao;
		}

		var retorno = false;
		try {
			localStorage.setItem('@crowdplataforma/usuariosbriefing', JSON.stringify(listaFinal));
			retorno = true;
		} catch (error) {
			retorno = false;
		}

		return retorno;
	}

	return false;
}

/*
** Susbtitui vários freelancers local
** ================================================== */
export const substituirFreelancersLocal = (dados) => {
	const {
		stateListaAtual,
		stateListaAdicionar,
	} = dados;

	var listas = getAllUsuariosLocal();

	var listaAtual = listas[stateListaAtual];

	if(!listaAtual) {
		msg.error(`Lista atual ${stateListaAtual} não encontrada...`);
		return false;
	}

	listas[stateListaAtual] = stateListaAdicionar;

	var retorno = false;
	try {
		localStorage.setItem('@crowdplataforma/usuariosbriefing', JSON.stringify(listas));
		retorno = true;
	} catch (error) {
		retorno = false;
	}

	return retorno;
}

export const dadosGetAlgoliaParaGetUser = dados => {
	return {
		id: dados.Id,
		nome: dados.Name,
		area: dados.Area,
		nomeDestaque: dados.Name,
		disponibilidade: dados.Availability,
		email: dados.Email,
		emailDestaque: dados.Email,
		endereco: enderecoAlgolia(dados),
		foto: caminhoFoto(dados.Photo).url,
		perfilFinalizado: dados.Finish,
		segmento: dados.Area,
		telefone: dados.Phone,
		titulo: dados.Title,
		valorHora: formatarDinheiro(dados.Price, 'R$ '),
	};
}

export const adicionarListaLocal = (dados) => {
	const {
		stateNomeLista,
		setStateListas,
		setStateListaAtual,
		setStateSelecionados,
	} = dados;

	const nomeLista = stateNomeLista.trim();
	var criarLista = addListaLocal(nomeLista);

	if(criarLista) {
		msg.success('Lista criada com sucesso.');
		var listas = listasLocal();
		setStateListas(listas);
		setStateListaAtual(nomeLista);
		setStateSelecionados(getAllUsuariosLocal(nomeLista));
	}

	return criarLista;
}

export const adicionarListaPorNomeLocal = (dados = {}) => {
	var {
		nomeLista,
		exibirMsgSeExiste = false,
		exibirMsgSucesso = false,
		msgNomeInvalido = 'Nome da lista local inválida.',
		msgSucesso = 'Lista criada com sucesso.',
		retornoTrueSeExiste = true,
	} = dados;

	if(typeof(nomeLista) !== 'string') {
		msg.error(msgNomeInvalido);
		return false;
	}
	nomeLista = nomeLista.trim();
	var criarLista = addListaLocal(nomeLista, exibirMsgSeExiste, retornoTrueSeExiste);

	if(criarLista) {
		if(exibirMsgSucesso) {
			msg.success(msgSucesso);
		}
		var listas = listasLocal();
		var freelancers = getAllUsuariosLocal(nomeLista);
		return {
			listas: listas,
			freelancers: freelancers,
			nomeLista: nomeLista,
		}
	}
	return false;
}

export const apagarListaLocal = (dados, exibirMsg = true) => {
	const {
		stateListaAtual,
		setStateListas,
		setStateListaAtual,
		setStateSelecionados,
	} = dados;

	var apagar = removerListaLocal(stateListaAtual);
	if(apagar) {
		if(exibirMsg) {
			msg.success('Lista removida com sucesso.');
		}
		const listas = listasLocal();
		setStateListas(listas);
		setStateListaAtual('Lista padrão');
		setStateSelecionados(getAllUsuariosLocal('Lista padrão'));
	}
}

export const limparListaLocal = (dados, showMsg = true) => {
	const {
		stateListaAtual,
		setStateSelecionados,
	} = dados;

	var limpar = limparListaSelecaoLocal(stateListaAtual);
	if(limpar) {
		if(showMsg) {
			msg.success('Lista limpa com sucesso.');
		}
		setStateSelecionados(getAllUsuariosLocal(stateListaAtual));
	}
}

export const editarListaLocal = dados => {
	const {
		stateNomeLista,
		stateListaAtual,
		setStateListas,
		setStateListaAtual,
		setStateSelecionados,
	} = dados;

	const nomeListaNova = stateNomeLista.trim();

	const nomeAtual = stateListaAtual;
	const listas = getAllUsuariosLocal();
	// const listaAtual = getAllUsuariosLocal(stateListaAtual);

	var jaExiste = false;
	Object.keys(listas).map((val, key) => {
		if(val === nomeListaNova) {
			jaExiste = true;
		}
	});

	if(jaExiste) {
		msg.error('Já existe uma lista com esse nome.');
		return false;
	}

	var listasEditada = {};
	Object.keys(listas).map((val, key) => {
		if(val === stateListaAtual) {
			listasEditada[nomeListaNova] = listas[nomeAtual];
		} else {
			listasEditada[val] = listas[nomeAtual];
		}
	});

	var retorno = false;

	try {
		localStorage.setItem('@crowdplataforma/usuariosbriefing', JSON.stringify(listasEditada));
		retorno = true;
	} catch (error) {
		retorno = false;
	}

	if(retorno) {
		const listas = listasLocal();
		setStateListas(listas);
		setStateListaAtual(nomeListaNova);
		setStateSelecionados(getAllUsuariosLocal(nomeListaNova));
		msg.success('Lista renomeada com sucesso.');
	} else {
	}

	return retorno;
}

/*
** Seleciona todos os resultados visíveis
** ================================================== */
export const selecionarUm = ({dados, stateListaAtual, setStateSelecionados}) => {
	selecionarFreelancerLocal({
		dados,
		stateListaAtual,
		setStateSelecionados,
	});
}

/*
** Ao clicar em remove rum
** ================================================== */
export const onClickRemover = ({dados, stateListaAtual, setStateSelecionados}) => {
	var acao = removerUsuarioLocal(dados.id, stateListaAtual);
	if(acao) {
		setStateSelecionados(getAllUsuariosLocal(stateListaAtual));
	}
}

/*
** Seleciona todos os resultados visíveis
** ================================================== */
export const selecionarTodos = ({stateListaAtual, stateProfissionais, setStateSelecionados}) => {
	var acao = addRemoverVariosFreelancersLocal({
		adicionarFreelancer: true,
		stateListaAtual,
		stateListaAdicionar: [...stateProfissionais.filter(item => item.Active)],
	});

	if(acao) {
		setStateSelecionados(getAllUsuariosLocal(stateListaAtual));
	}
}

/*
** Desseleciona todos os resultados visíveis
** ================================================== */
export const desselecionarTodos = ({stateListaAtual, stateProfissionais, setStateSelecionados}) => {
	var acao = addRemoverVariosFreelancersLocal({
		adicionarFreelancer: false,
		stateListaAtual,
		stateListaAdicionar: [...stateProfissionais.filter(item => item.Active)],
	});

	if(acao) {
		setStateSelecionados(getAllUsuariosLocal(stateListaAtual));
	}
}
