import React, { useContext, useEffect, useRef, useState } from 'react';
import { scroller } from 'react-scroll';

// Store
import StoreContext from '@/_/contexts/StoreContext';

import Button2 from "@cpnt/Button2/Button2";
import Input2 from "@cpnt/Input2/Input2";

// css
import { apiGetTemplate } from "@api/_functions/geral";
import Icone from "@cpnt/Icone/Icone";
import OverlayConteudo from "@cpnt/OverlayConteudo/OverlayConteudo";
import Tip from "@cpnt/Tip/Tip";
import { camposInvalidos, htmlToJsx, msg, validarEmail } from "@func/utils";
import imgPessoas from '@img/fotos/pessoas.png';
import './ConvideUmFreelancer.css';
import { servicosObj } from "./servicosObj";

import imgIconeFacebook from '@svg/brands/facebook.svg';
import imgIconeLinkedin from '@svg/brands/linkedin.svg';
import imgIconeTweeter from '@svg/brands/twitter-square.svg';
import imgIconeWhatsApp from '@svg/brands/whatsapp-square.svg';
import imgIconeCopiar from '@svg/regular/copy.svg';
import imgIconeLink from '@svg/regular/link.svg';
import imgIconeEmail from '@svg/solid/envelope.svg';

import apiUserIndication from "@api/userIndication/userIndication";
import { DEV_ID, URL_INDICACAO_FREELANCER } from "@_config/config";
import Loading from "@cpnt/Loading/Loading";
import Portal from "@cpnt/Portal/Portal";
import TextoIconeLink from "@cpnt/TextoIconeLink/TextoIconeLink";
import { msgs, msgStatus } from "@_func/request";

const ConvideUmFreelancer = props => {
	const [store, setStore] = useContext(StoreContext);
	const refLinkIndicacao = useRef();

	const {
		className = '',
		classNameWrap = '',
		wrap = '',
	} = props;

	const camposDefault = {
		sender_name: store.usuario.name,
		sender_email: store.usuario.email,
		receiver_name: '',
		receiver_email: '',
		message: '',
	};
	const [stateDados, setStateDados] = useState(camposDefault);
	const [showMensagemSucesso, setShowMensagemSucesso] = useState(false);
	const [loadingGetTemplate, setLoadingGetTemplate] = useState(false);
	const [loadingGetTemplateLink, setLoadingGetTemplateLink] = useState(false);
	const [loadingGetTemplateEnviado, setLoadingGetTemplateEnviado] = useState(false);
	const [loadingEnviar, setLoadingEnviar] = useState(false);
	const [stateTemplate, setStateTemplate] = useState({
		text: '',
	});
	const [stateTemplateLink, setStateTemplateLink] = useState({
		text: '',
	});
	const [stateTemplateEnviado, setStateTemplateEnviado] = useState({
		text: '',
	});
	const [loadingLink, setLoadingLink] = useState(false);
	const [showPortalLink, setShowPortalLink] = useState(false);
	const [stateLink, setStateLink] = useState(false);
	const [stateBackupTemplate, setStateBackupTemplate] = useState('');

	useEffect(() => {
		getTemplates();
	}, []);

	const getTemplates = async () => {

		apiGetTemplate({id: (DEV_ID ? 63 : 95), setLoadingGetTemplate: setLoadingGetTemplateLink, setStateTemplate: setStateTemplateLink});
		apiGetTemplate({id: (DEV_ID ? 64 : 96), setLoadingGetTemplate: setLoadingGetTemplateEnviado, setStateTemplate: setStateTemplateEnviado});
		const retGetTemplate = await apiGetTemplate({id: (DEV_ID ? 61 : 93), setLoadingGetTemplate, setStateTemplate});
		if(retGetTemplate.status === 200) {
			setStateBackupTemplate(retGetTemplate.data.text);
		}
	}

	const enviarConvite = async (e) => {
		e.preventDefault();

		if(loadingEnviar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			sender_name: stateDados.sender_name,
			sender_email: stateDados.sender_email,
			receiver_name: stateDados.receiver_name,
			receiver_email: stateDados.receiver_email,
			message: stateTemplate.text,
		};

		if(camposInvalidos(dadosEnviar, {
			sender_name: 'Seu nome',
			sender_email: 'Seu e-mail',
			receiver_name: 'Nome do seu amigo',
			receiver_email: 'E-mail do seu amigo',
			message: 'Mensagem',
		}, [
			'sender_name',
			'sender_email',
			'receiver_name',
			'receiver_email',
			'message',
		])) return;

		if(!validarEmail(dadosEnviar.sender_email)) return;

		if(!validarEmail(dadosEnviar.receiver_email)) return;

		setLoadingEnviar(true);

		const retEnviarConvite = await apiUserIndication.indication.post(dadosEnviar);

		if(retEnviarConvite.status === 200) {
			msg.success('Convite enviado com sucesso.');
			setShowMensagemSucesso(true);
			setStateDados(camposDefault);
			setStateTemplate({
				text: stateBackupTemplate,
			});
		} else {
			msgs([retEnviarConvite]);
		}
		setLoadingEnviar(false);
	}

	const gerarLink = async () => {
		if(stateLink) {
			return stateLink;
		}
		setLoadingLink(true);
		const retGetLink = await apiUserIndication.getHash.get(store.usuario.id);
		if(retGetLink.status === 200) {
			msg.success('Link gerado com sucesso');
			setStateLink(retGetLink.data.hash);
		} else {
			msgs([retGetLink]);
			setStateLink(false);
		}
		setLoadingLink(false);
		return retGetLink.data.hash;
	}

	const abrirLink = async (tipo) => {

		var codigo = await gerarLink();

		const links = {
			whatsapp: `https://wa.me/?text=`,
			facebook: `https://www.facebook.com/sharer/sharer.php?u=`,
			// linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=`,
			linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=`,
			tweeter: `https://twitter.com/intent/tweet?url=`,
			email: `mailto:?body=`,
		}

		if(tipo === 'link') {
			setShowPortalLink(true);
			return;
		}

		if(!links[tipo]) {
			msg.warning('Tipo de compartilhamento não identificado');
			return;
		}

		var linkTipo = `${links[tipo]}${URL_INDICACAO_FREELANCER}${codigo} - ${stateTemplateLink.text}`;

		if(tipo === 'facebook') {
			linkTipo = `${links[tipo]}${URL_INDICACAO_FREELANCER}${codigo}`;
		}

		if(tipo === 'linkedin') {
			linkTipo = `${links[tipo]}${encodeURIComponent(URL_INDICACAO_FREELANCER+codigo)}`;
		}

		refLinkIndicacao.current.href = linkTipo;
		refLinkIndicacao.current.click();
	}

	const copiar = tipo => {
		if(tipo === 'link') {
			navigator.clipboard.writeText(`${URL_INDICACAO_FREELANCER}${stateLink}`);
			msg.success('Link copiado com sucesso');
		} else {
			navigator.clipboard.writeText(stateLink);
			msg.success('Código copiado com sucesso');
		}
	}

	return (
		<>
			<div className="pg-perfil base-full">
				<div className="conteudo-box">
					<div className="titulo-box titulo-box-res">
						<div className="flex flex-center-v">
							<h3 className="titulo">Convide um freelancer</h3>
						</div>
					</div>

					<div className={`pg-convideumfreelancer ${wrap} ${classNameWrap}`}>
						<div className={`pg-convideumfreelancer-box ${className}`}>
							<div className="base">
								<div className="base-content">
									<div className="pg-convide-box">
										<div className="pg-convide-l box-1-980">
											<h3 className="titulo titulo-g">Indique a CROWD para seus amigos e conhecidos que são freelancers</h3>
											<div>
												<img src={imgPessoas} alt="pessoas" className="block w-100p padding-t-30 padding-b-10" />
											</div>
											<div>
												<p>Sabe aqueles profissionais super competentes e experientes que você indica para seus clientes e parceiros?</p>
												<p>Convide-os  para fazer parte da CROWD também — plataforma de negócios para freelancers de marketing, produto e tecnologia que buscam crescimento profissional, clientes melhores e boa remuneração.</p>
											</div>
											<h4>Alguns benefícios:</h4>
											<ul className="lista-check">
												<li>Cadastro 100% gratuito.</li>
												<li>Oportunidade para atuar em projetos de grandes e médias empresas</li>
												<li>Possibilidade de receber propostas de jobs que pagam em real ou em dólar.</li>
												<li>Não precisa correr atrás de jobs, as melhores propostas chegam até o freelancer</li>
												<li>Única plataforma focada no mercado de marketing, produto e tecnologia</li>
												<li>Seja seu próprio chefe, aumente sua renda e trabalhe de qualquer lugar</li>
												<li>Atendimento e suporte humanizado do início ao fim do projeto</li>
												<li>Feedbacks de experts para ajudar você crescer profissionalmente</li>
												<li>Previsibilidade e certeza de pagamento após aprovação dos jobs</li>
												<li>Você como freelancer protagonista e com voz ativa nos projeto</li>
											</ul>
										</div>
										<div className="pg-convide-r relative box-1-980" id="convide">
											<h3 className="margin-t-0">Preencha o formulário abaixo</h3>
											<div>Suas indicações ficarão registradas e você poderá ser recompensado no futuro.
												<Tip
													tip={
														<span className="q"></span>
													}
													info
												>
													<div className="box-txt">
														<p>A Crowd está trabalhando para ter uma grande comunidade de freelancers, e você poderá fazer parte disso!</p>
														<p>Indique outros freelancers para participar da comunidade Crowd, suas indicações ficarão registradas.</p>
														<p>Estamos trabalhando em novos recursos onde você poderá acompanhar suas indicações e até mesmo ser recompensado pelas indicações que fizer, então não perca tempo e comece a indicar novos freelancers hoje mesmo!</p>
													</div>
												</Tip>
											</div>

											<OverlayConteudo
												exibir={showMensagemSucesso}
												exibirFechar={true}
												texto={
													<div className="box-txt">
														{htmlToJsx(stateTemplateEnviado.text)}
													</div>
												}
												toggle={e => setShowMensagemSucesso(false)}
												bgAlt={true}
											/>
											<form
												onSubmit={enviarConvite}
												className="wrap-5 margin-t-5 padding-t-5"
											>
												<div className="input-box">
													<Input2
														label="Seu nome"
														req
														_={{
															value: stateDados.sender_name,
															onChange: e => {
																setStateDados({
																	...stateDados,
																	sender_name: e.target.value,
																});
															}
														}}
													/>
												</div>
												<div className="input-box">
													<Input2
														label="Seu e-mail"
														req
														_={{
															type: 'email',
															value: stateDados.sender_email,
															onChange: e => {
																setStateDados({
																	...stateDados,
																	sender_email: e.target.value,
																});
															}
														}}
													/>
												</div>
												<div className="input-box">
													<Input2
														label="Nome do seu amigo"
														req
														_={{
															value: stateDados.receiver_name,
															onChange: e => {
																setStateDados({
																	...stateDados,
																	receiver_name: e.target.value,
																});
															}
														}}
													/>
												</div>
												<div className="input-box">
													<Input2
														label="E-mail do seu amigo"
														req
														_={{
															type: 'email',
															value: stateDados.receiver_email,
															onChange: e => {
																setStateDados({
																	...stateDados,
																	receiver_email: e.target.value,
																});
															}
														}}
													/>
												</div>
												<div className="input-box">
													<Input2
														label="Mensagem"
														req
														_={{
															type: 'textarea',
															value: stateTemplate.text,
															onChange: e => {
																setStateTemplate({
																	...stateTemplate,
																	text: e.target.value,
																});
															}
														}}
														loading={loadingGetTemplate}
													/>
												</div>
												<div className="input-box">
													<Button2
														_={{
															value: 'Enviar convite',
															onClick: enviarConvite,
														}}
														wrap="w-100p flex-1"
														classNameExtra="w-100p"
														loading={loadingEnviar}
													/>
												</div>
											</form>
											<div className="margin-t-40">
												<h3 className="align-center titulo">Compartilhe seu link de indicação</h3>
												<div className="flex flex-between padding-15 relative">
													{loadingLink ? <Loading alt absolute bgAlt3 /> : null}
													<Tip tip={<Icone imagem={imgIconeLink} tamanho="36px" cor="#0054a6" onClick={e => abrirLink('link')} className="cursor-pointer" />}>Visualizar link</Tip>
													<Tip tip={<Icone imagem={imgIconeWhatsApp} tamanho="36px" cor="#34af23" onClick={e => abrirLink('whatsapp')} className="cursor-pointer" />}>Compartilhar no WhatsApp</Tip>
													<Tip tip={<Icone imagem={imgIconeFacebook} tamanho="36px" cor="#3b5998" onClick={e => abrirLink('facebook')} className="cursor-pointer" />}>Compartilhar no Facebook</Tip>
													<Tip tip={<Icone imagem={imgIconeLinkedin} tamanho="36px" cor="#007bb6" onClick={e => abrirLink('linkedin')} className="cursor-pointer" />}>Compartilhar no LinkedIn</Tip>
													<Tip tip={<Icone imagem={imgIconeTweeter} tamanho="36px" cor="#00aced" onClick={e => abrirLink('tweeter')} className="cursor-pointer" />}>Compartilhar no Tweeter</Tip>
													<Tip tip={<Icone imagem={imgIconeEmail} tamanho="36px" cor="#f4ae01" onClick={e => abrirLink('email')} className="cursor-pointer" />}>Envia por e-mail</Tip>
												</div>
											</div>
										</div>
									</div>

									<div>
										<h3>Freelancers de marketing e tecnologia em alta</h3>
										<p>Confira os prestadores de serviços mais buscados atualmente</p>
										<div className="pg-convide-icones-box">
											{servicosObj.map((val, key) => {
												const img = require(`@img/servicos/${val.icone}`);
												return (
													<div className="pg-convide-icone" key={key}>
														<div>
															<img src={img} alt={val.icone} />
														</div>
														<h3 className="margin-t-5">{val.titulo}</h3>
													</div>
												)
											})}
										</div>
									</div>

									<div className="flex flex-center padding-t-50">
										<Button2
											_={{
												value: 'Convide um freelancer',
												onClick: e => {
													scroller.scrollTo('convide', {
														duration: 800,
														delay: 0,
														smooth: 'ease',
														offset: 1,
													});
												},
												className: 'btn btn-default btn-large'
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Portal Mensagem customizada */}
			<Portal
				titulo="Link e código de indicação"
				show={showPortalLink}
				onClose={() => {
					setShowPortalLink(false);
				}}
				className="w-700"
			>
				<div className="input-box flex flex-center-v">
					<Input2
						label="Link de indicação"
						tip={
							<>
								<div>Compartilhe esse link com quem você deseja indicar.</div>
								<div className="margin-t-10">Esse link levará diretamente para a tela de cadastro com seu código de indicação.</div>
							</>
						}
						_={{
							value: `${URL_INDICACAO_FREELANCER}${stateLink}`,
							onChange: e => null,
							readOnly: true,
						}}
						wrap="flex-1"
					/>
					<Button2
						_={{
							value: <TextoIconeLink texto="Copiar" icone={imgIconeCopiar} iconeCor="#FFF" />,
							onClick: e => copiar('link'),
						}}
						wrap="margin-l-10"
					/>
				</div>
				<div className="input-box flex flex-center-v">
					<Input2
						label="Seu código de indicação"
						tip={
							<>
								<div>Você também pode compartilhar seu código de indicação. Esse código deve ser inserido no campo "indicação" no momento do cadastro.</div>
								<div className="margin-t-10">Lembre-se, seu código é único e não pode ser alterado.</div>
							</>
						}
						_={{
							value: stateLink,
							onChange: e => null,
							readOnly: true,
						}}
						wrap="flex-1"
					/>
					<Button2
						_={{
							value: <TextoIconeLink texto="Copiar" icone={imgIconeCopiar} iconeCor="#FFF" />,
							onClick: e => copiar('codigo'),
						}}
						wrap="margin-l-10"
					/>
				</div>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Fechar',
							onClick: () => setShowPortalLink(false),
						}}
					/>
				</div>
			</Portal>
			<a href="#linkindicacao" target="_blank" rel="noreferrer" ref={refLinkIndicacao} className="none">Link de indicação</a>
		</>
	);
};

export { ConvideUmFreelancer };
