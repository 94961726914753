import React from 'react';

const Button2 = props => {

	const dados = {
		_: {},
		wrap: '',
		classNameExtra: '',
		...props,
	};

	return (
		<div className={`${dados.wrap ? dados.wrap : 'inline-flex'}`}>
			<button
				className={`${dados._.className ? dados._.className : 'btn btn-default'} ${dados.classNameExtra}`}
				{...dados._}
			>
				{dados.loading ? <span className="btn-loading"></span> : null}
				{dados._.value}
			</button>
		</div>
	)
}

export default Button2;