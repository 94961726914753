import React from 'react';

// Componentes
import Checkbox2 from '@cpnt/Checkbox2/Checkbox2';
import Input2 from '@cpnt/Input2/Input2';
import { InputDate } from "@cpnt/InputDate/InputDate";

const FormExperiencia = props => {
	const {
		className = '',
		wrap = '',
		state = {},
		setState,
	} = props;

	return (
		<div className={`cpnt-formexperiencia ${wrap}`}>
			<div className={`cpnt-formexperiencia-box ${className}`}>
				<div className="wrap-5">
					<div className="flex flex-wrap">
						<div className="input-box box-2 box-1-680">
							<Input2
								label="Empresa/Orgão"
								req
								_={{
									value: state.company,
									onChange: e => {
										setState({
											...state,
											company: e.target.value,
										});
									}
								}}
							/>
						</div>
						<div className="input-box box-2 box-1-680">
							<Input2
								label="Cargo"
								req
								_={{
									value: state.role,
									onChange: e => {
										setState({
											...state,
											role: e.target.value,
										});
									}
								}}
							/>
						</div>
						<div className="input-box box-2 box-1-680">
							<InputDate
								label="Data inicial"
								req
								value={state.start_date}
								onChange={e => {
									setState({
										...state,
										start_date: e,
									});
								}}
							/>
						</div>
						<div className="input-box box-2 box-1-680">
							<InputDate
								label="Data final"
								tip="Deixe em branco caso seja até o momento"
								value={state.end_date}
								onChange={e => {
									setState({
										...state,
										end_date: e,
									});
								}}
							/>
						</div>
						<div className="input-box box-1">
							<Input2
								label="Descrição"
								req
								_={{
									type: 'textarea',
									value: state.description,
									onChange: e => {
										setState({
											...state,
											description: e.target.value,
										});
									}
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FormExperiencia;