import React, { useState } from 'react';

// Componentes
import { EMAIL_CONTATO } from "@_config/config";
import Button2 from "@cpnt/Button2/Button2";
import Checkbox2 from "@cpnt/Checkbox2/Checkbox2";
import Loading from "@cpnt/Loading/Loading";
import PagamentoDadosBancarios from "@cpnt/PagamentoDadosBancarios/PagamentoDadosBancarios";
import PagamentoInternacional from "@cpnt/PagamentoInternacional/PagamentoInternacional";
import PagamentoPix from "@cpnt/PagamentoPix/PagamentoPix";
import Portal from "@cpnt/Portal/Portal";
import { Select2 } from "@cpnt/Select2/Select2";
import Tip from "@cpnt/Tip/Tip";
import textoAvisoDatasPagamento from "@texto/textoAvisoDatasPagamento";

const PtDadosBancarios = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		stateBancos,

		stateDados = {},
		setStateDados,

		stateDadosBancarios = {},
		setStateDadosBancarios,
		loadingGetDadosBancarios,

		stateTiposPagamento,
		loadingGetTiposPagamento,

		steps,
	} = props;

	const [showPortalPagamento, setShowPortalPagamento] = useState(false);

	/*
	** Campo de acordo com o tipo de pagamento
	** ================================================== */
	const tipoPagamentoSelecionado = () => {
		if(!stateDadosBancarios.id_payment_type || stateDadosBancarios.id_payment_type === '') {
			return (
				<div className="fs-15">
					{steps ? (
						<div className="margin-t-15">Se você não tiver esses dados agora, é possível preencher depois em seu perfil.</div>
					) : null}
					<div className="margin-t-15"><strong>Importante:</strong> caso receba uma proposta de job, é necessário que seu perfil esteja completo e com os dados bancários preenchidos para prosseguir com o job.</div>
				</div>
			)
		}

		if(stateDadosBancarios.id_payment_type === 1) {
			return (
				<PagamentoDadosBancarios
					classNameWrap="margin-t-15"
					stateBancos={stateBancos}
					stateDadosBancarios={stateDadosBancarios}
					setStateDadosBancarios={setStateDadosBancarios}
				/>
			)
		}

		if(stateDadosBancarios.id_payment_type === 4) {
			return (
				<PagamentoPix
					classNameWrap="margin-t-15"
					stateDadosBancarios={stateDadosBancarios}
					setStateDadosBancarios={setStateDadosBancarios}
				/>
			)
		}

		if(stateDadosBancarios.id_payment_type === 3) {
			return (
				<PagamentoInternacional
					classNameWrap="margin-t-15"
					stateDadosBancarios={stateDadosBancarios}
					setStateDadosBancarios={setStateDadosBancarios}
				/>
			)
		}
	}

	return (
		<>
			<div className={`cpnt-ptdadosbanc ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-ptdadosbanc-box ${className}`}>
					<div>
						<h3 className="titulo margin-0">Informações bancárias</h3>
						<div className="wrap-5 margin-t-5">
							<div className="flex flex-wrap relative">
								<div className="input-box w-300 box-1-680">
									<Select2
										label="Tipo de pagamento"
										req
										_={{
											value: stateDadosBancarios.id_payment_type,
											options: stateTiposPagamento,
											onChange: e => {
												setStateDadosBancarios({
													...stateDadosBancarios,
													id_payment_type: e ? e.value : null,
												})
											},
											isClearable: true,
										}}
										loading={loadingGetTiposPagamento}
									/>
								</div>
							</div>
						</div>
					</div>

					{loadingGetDadosBancarios ? (
						<Loading />
					) : (
						tipoPagamentoSelecionado()
					)}

					<div className="wrap-5">
						{stateDadosBancarios.id_payment_type && stateDadosBancarios.id_payment_type !== '' ? (
							<div className="input-box box-1 margin-t-15">
								<Tip
									tip={
										<strong>Importante <span className="q"></span></strong>
									}
									info
								>
									<div>Por questão de segurança a Crowd não deposita pagamentos na conta de terceiros.</div>
									<div className="margin-t-10">O mesmo se aplica para a Nota Fiscal, pois a Crowd não aceita notas de terceiros.</div>
									<div className="margin-t-10">Se tiver alguma dúvida sobre isso, entre em contato com <a href={`mailto:${EMAIL_CONTATO}`}>{EMAIL_CONTATO}</a></div>
								</Tip>
								<div className="desc">Caso a conta seja de <strong>pessoa física</strong>, é necessário que o títular da conta seja da mesma pessoa informada no cadastro.</div>
							</div>
						) : null}

						<div className="input-box box-1 margin-t-15">
							<Checkbox2
								label={<>Estou ciente sobre as datas de pagamento. <span className="link" onClick={e => {e.preventDefault(); setShowPortalPagamento(true)}}>Confira nossa orientação.</span></>}
								_={{
									checked: stateDados.accept_date_payment,
									onChange: e => setStateDados({
										...stateDados,
										accept_date_payment: e.target.checked,
									}),
								}}
								small
							/>
						</div>
					</div>

				</div>
			</div>

			<Portal
				titulo="Datas de pagamento"
				show={showPortalPagamento}
				onClose={() => setShowPortalPagamento(false)}
				className="w-850"
			>
				{textoAvisoDatasPagamento()}
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Fechar',
							onClick: e => setShowPortalPagamento(false)
						}}
					/>
				</div>
			</Portal>
		</>
	);
};

export { PtDadosBancarios };
