import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalDadosBancarioFormEdicao.css';

// API
import apiFreelancer from "@api/freelancer/freelancer";

// Partes
import { PtCartaApresentacao } from "@cpnt/Partes/PtCartaApresentacao/PtCartaApresentacao";

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { camposInvalidos, getKey, sanitize, validarCnpj, validarCpf, validarEmail, validarTelefone } from '@func/utils';
import { trim } from "lodash";
import apiFreelancerCompany from "@api/freelancerCompany/freelancerCompany";
import { PtDadosDaEmpresa } from "@cpnt/Partes/PtDadosDaEmpresa/PtDadosDaEmpresa";
import { PtDadosBancarios } from "@cpnt/Partes/PtDadosBancarios/PtDadosBancarios";
import { apiGetBancos, apiGetDadosBancarios, apiGetTiposPagamento } from "@api/_functions/geral";
import apiFreelancerPayment from "@api/freelancerPayment/freelancerPayment";
import { msgs, msgStatus } from "@_func/request";

const PortalDadosBancarioFormEdicao = props => {

	const {
		idFreelancer,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-dadosbanc-form-edicao',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetCnpj, setLoadingGetCnpj] = useState(false);
	const [loadingGetTiposPagamento, setLoadingGetTiposPagamento] = useState(false);
	const [loadingGetDadosBancarios, setLoadingGetDadosBancarios] = useState(false);
	const [loadingGetBancos, setLoadingGetBancos] = useState(false);

	const [stateDadosBancarios, setStateDadosBancarios] = useState({});
	const [stateBancos, setStateBancos] = useState([]);
	const [stateTiposPagamento, setStateTiposPagamento] = useState([]);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({
		accept_date_payment: '',
	});
	const [_stateDados, _setStateDados] = useState({});

	// Edição
	const [loadingEditar, setLoadingEditar] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		setLoading(true);

		const retGetDados = await apiFreelancer.getOne.get(idFreelancer);
		_setStateDados(msgStatus(retGetDados));

		if(retGetDados.status === 200) {
			setStateDados(retGetDados.data);

			apiGetBancos({setStateBancos, setLoadingGetBancos});
			apiGetTiposPagamento({setStateTiposPagamento, setLoadingGetTiposPagamento});
			apiGetDadosBancarios({idFreelancer: idFreelancer, setStateDadosBancarios, setLoadingGetDadosBancarios, showMsg: false});
		} else {
			msgs([retGetDados]);
		}

		setLoading(false);
	}

	/*
	** Editar dados bancários
	** ================================================== */
	const editar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		// Termos
		if(!stateDados.accept_date_payment || stateDados.accept_date_payment === '') {
			msg.error('Você precisa concordar com os termos das datas de pagamento para poder continuar.');
			return;
		}

		if(!stateDadosBancarios.id_payment_type || stateDadosBancarios.id_payment_type === '') {
			msg.error('Selecione o tipo de pagamento');
			return;
		}

		var dadosEnviar = {};

		/*
		** 1 => Dados Bancários
		** ================================================== */
		if(stateDadosBancarios.id_payment_type === 1) {
			dadosEnviar = {
				id_freelancer: idFreelancer,
				id_bank: stateDadosBancarios.id_bank,
				full_name: stateDadosBancarios.full_name,
				cpf: stateDadosBancarios.cpf,
				agency: stateDadosBancarios.agency,
				account: stateDadosBancarios.account,
				payment_type: stateDadosBancarios.id_payment_type, // precisa mandar sem o id_
			};

			if(camposInvalidos(dadosEnviar, {
				id_bank: 'Banco',
				full_name: 'Títular da conta',
				cpf: 'CPF do títular ou CNPJ',
				agency: 'Agência com dígito',
				account: 'Conta com dígito',
			})) return;

			var cpfOuCnpj = 0;

			// não é cpf
			if(!validarCpf(dadosEnviar.cpf, false)) {
				cpfOuCnpj++;
			}

			// não é cnpj
			if(!validarCnpj(dadosEnviar.cpf, false)) {
				cpfOuCnpj++;
			}

			if(cpfOuCnpj >= 2) {
				msg.error('CPF ou CNPJ inválido');
				return;
			}
		}

		/*
		** 4 => PIX
		** ================================================== */
		else if(stateDadosBancarios.id_payment_type === 4) {
			dadosEnviar = {
				id_freelancer: idFreelancer,
				pix_value: stateDadosBancarios.pix_value,
				pix_type: stateDadosBancarios.pix_type,
				payment_type: stateDadosBancarios.id_payment_type, // precisa mandar sem o id_ ¬¬
			};

			if(camposInvalidos(dadosEnviar, {
				pix_type: 'Tipo de chave PIX',
				pix_value: 'Chave PIX'
			})) return;

			// Validar Celular
			if(stateDadosBancarios.pix_type === 'celular') {
				if(!validarTelefone(stateDadosBancarios.pix_value, false)) {
					msg.error('O celuar que você inseriu para a chave PIX é inválido.');
					return;
				}
			}

			// Validar CPF
			else if(stateDadosBancarios.pix_type === 'cpf') {
				if(!validarCpf(stateDadosBancarios.pix_value, false)) {
					msg.error('O CPF que você inseriu para a chave PIX é inválido.');
					return;
				}
			}

			// Validar CNPJ
			else if(stateDadosBancarios.pix_type === 'cnpj') {
				if(!validarCnpj(stateDadosBancarios.pix_value, false)) {
					msg.error('O CNPJ que você inseriu para a chave PIX é inválido.');
					return;
				}
			}

			// Validar Email
			else if(stateDadosBancarios.pix_type === 'email') {
				if(!validarEmail(stateDadosBancarios.pix_value, false)) {
					msg.error('O e-mail que você inseriu para a chave PIX é inválido.');
					return;
				}
			}
		}

		/*
		** 3 => Internacional
		** ================================================== */
		else if(stateDadosBancarios.id_payment_type === 3) {
			dadosEnviar = {
				id_freelancer: idFreelancer,
				full_name: stateDadosBancarios.full_name,
				swift_bic_code: stateDadosBancarios.swift_bic_code,
				iban_account_number: stateDadosBancarios.iban_account_number,
				payment_type: stateDadosBancarios.id_payment_type, // precisa mandar sem o id_ ¬¬
			};

			if(camposInvalidos(dadosEnviar)) return;
		}

		setLoadingEditar(true);

		const retUpdateFreelancer = await apiFreelancer.update.put({
			id: idFreelancer,
			accept_date_payment: stateDados.accept_date_payment ? 1 : 0,
		});

		if(retUpdateFreelancer.status !== 200) {
			msgs([retUpdateFreelancer]);
			return;
		}

		const retInsertPayment = await apiFreelancerPayment.insert.post(dadosEnviar);

		if(retInsertPayment.status === 200) {
			msg.success('Dados bancários atualizados com sucesso');
			onSuccess({
				ret: retInsertPayment,
				dados: {
					...stateDadosBancarios,
					payment_type: getKey(stateTiposPagamento, stateDadosBancarios.id_payment_type, 'id').name,
				},
			});
		} else {
			msgs([retInsertPayment]);
			onError({
				ret: retInsertPayment,
			});
		}

		setLoadingEditar(false);
	}

	return (
		<Portal
			titulo="Dados bancários"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<>
								<PtDadosBancarios
									stateDados={stateDados}
									setStateDados={setStateDados}
									stateDadosBancarios={stateDadosBancarios}
									setStateDadosBancarios={setStateDadosBancarios}
									stateBancos={stateBancos}
									stateTiposPagamento={stateTiposPagamento}
									loadingGetTiposPagamento={loadingGetTiposPagamento}
									loadingGetDadosBancarios={loadingGetDadosBancarios}
								/>
								<div className="form-bottom">
									<Button2
										_={{
											value: 'Cancelar',
											className: 'btn btn-1',
											onClick: onClose,
										}}
									/>
									<Button2
										_={{
											value: 'Salvar dados',
											onClick: editar,
										}}
										wrap="margin-l-10"
										loading={loadingEditar}
									/>
								</div>
							</>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export { PortalDadosBancarioFormEdicao };
