import React, { useContext, useEffect, useState } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './PtVideo.css';

// Componentes
import apiFreelancer from "@api/freelancer/freelancer";
import Button2 from "@cpnt/Button2/Button2";
import TextoIconeLink from "@cpnt/TextoIconeLink/TextoIconeLink";
import Tip from "@cpnt/Tip/Tip";
import { VideoPlayer } from "@_cpnt/VideoPlayer/VideoPlayer";
import { XLoading } from "@cpnt/XLoading/XLoading";
import { caminhoVideo, msg } from "@func/utils";

import { hash } from "@_func/generate";

// Imagens
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";
import Portal from "@cpnt/Portal/Portal";
import { Webcam } from "@cpnt/Webcam/Webcam";
import imgIconeDownload from '@svg/padrao/cloud-arrow-down.svg';
import imgIconePasta from '@svg/solid/folder-open.svg';
import imgIconeVideo from '@svg/solid/video.svg';
import { msgs, msgStatus } from "@_func/request";

const PtVideo = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		stateDados = {},
		setStateDados,
		stateVideoUrl,
		setStateVideoUrl,
		afterTip,
	} = props;


	const [showPortalVideo, setShowPortalVideo] = useState(false);
	const [showPortalApagar, setShowPortalApagar] = useState(false);

	const [loadingApagarVideo, setLoadingApagarVideo] = useState(false);
	const [videoKey, setVideoKey] = useState('video');

	useEffect(() => {
		setVideoKey(hash());
	}, [stateVideoUrl]);

	const selecionarVideoLocal = async e => {
		var video = e.target.files[0];
		if(video.type === 'video/mp4' || video.type === 'video/webm') {
			setTimeout(() => {
				var url = URL.createObjectURL(video);
				setStateVideoUrl(url);
			}, 300);
		} else {
			msg.error('Arquivo não permitido. Selecione um vídeo com o formato mp4, avi, mov ou webm.');
		}
	}

	const confirmarRemoverVideo = async () => {
		if(loadingApagarVideo) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		if(stateVideoUrl) {
			setStateVideoUrl(false);
			msg.success('Vídeo removido com sucesso.');
			setShowPortalApagar(false);
			return;
		}

		if(!stateDados.video || stateDados.video === '') {
			msg.warning('Não há nenhum vídeo para ser removido.');
			setShowPortalApagar(false);
			return;
		}

		setLoadingApagarVideo(true);

		const retDeleteVideoFreelancer = await apiFreelancer.video.delete(store.usuario.id_freelancer);

		if(retDeleteVideoFreelancer.status === 200) {
			msgs([retDeleteVideoFreelancer], true);
			setStateDados({
				...stateDados,
				video: false,
			});
			setStateVideoUrl(false);
			setStore({
				...store,
				usuario: {
					...store.usuario,
					video: false,
				},
			});
			setVideoKey(hash());
		} else {
			msgs([retDeleteVideoFreelancer]);
		}

		setShowPortalApagar(false);
		setLoadingApagarVideo(false);
	}

	return (
		<>
			<div className={`cpnt-video ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-video-box ${className}`}>
					<div className="cpnt-video-videobox">
						<div className="cpnt-video-player-box">
							{(stateDados.video && stateDados.video !== '') || (stateVideoUrl && stateVideoUrl !== '') ? (
								<XLoading
									absAlt
									loading={loadingApagarVideo}
									onClick={e => setShowPortalApagar(true)}
								/>
							) : null}
							<VideoPlayer
								key={videoKey}
								_={{
									url: stateVideoUrl || caminhoVideo(stateDados.video),
								}}
							/>
						</div>
					</div>
					<div className="cpnt-video-btns">
						<Button2
							_={{
								value: <TextoIconeLink texto="Capturar vídeo" icone={imgIconeVideo} />,
								onClick: e => {
									setShowPortalVideo(true);
								}
							}}
						/>
						<div className="margin-l-10 flex btn-res-l-480">
							<label>
								<div className="btn btn-default">
									<TextoIconeLink texto="Selecionar vídeo" icone={imgIconePasta} />
								</div>
								<input type="file" name="video" className="none" onChange={selecionarVideoLocal} />
							</label>
						</div>
					</div>

					{stateVideoUrl ? (
						<div className="flex flex-center margin-t-10">
							<Tip
								tip={
									<a href={stateVideoUrl} target="_blank" rel="noreferrer" download="crowd-video-apresentacao" className="btn btn-default">
										<TextoIconeLink texto="Download vídeo" icone={imgIconeDownload} />
									</a>
								}
								info
								_defaults={{
									placement: 'top-center',
								}}
							>
								<div>Recomendamos fazer o download do vídeo eu seu dispositivo para caso você venha a precisar dele futuramente.</div>
								{afterTip}
							</Tip>
						</div>
					) : null}
				</div>
			</div>

			<Portal
				titulo="Vídeo de apresentação"
				show={showPortalVideo}
				onClose={() => setShowPortalVideo(false)}
				className="w-600"
			>
				<Webcam
					tipo="video"
					onClickCancelar={e => setShowPortalVideo(false)}
					stateVideoUrl={stateVideoUrl}
					setStateVideoUrl={setStateVideoUrl}
					onClickConcluirGravacao={e => {
						msg.success(
							<>
								<div><strong>Parabéns,</strong> seu vídeo de apresentação foi gravado com sucesso!</div>
								<div className="margin-t-10">Você pode fazer download do vídeo clicando no botão de download.</div>
								<div className="margin-t-10">Ao salvar, seu vídeo será anexado ao seu perfil profissional.</div>
							</>,
							{
								autoClose: 10000,
							}
						)
						setShowPortalVideo(false);
					}}
				/>
			</Portal>

			<PortalConfirmacao
				show={showPortalApagar}
				titulo="Deseja realmente apagar esse vídeo?"
				desc="O vídeo será removido permanentemente, não será possível recuperá-lo após remover."
				onClose={e => {
					setShowPortalApagar(false);
				}}
				onClickCancelar={e => {
					setShowPortalApagar(false);
				}}
				onClickConfirmar={confirmarRemoverVideo}
				loadingConfirmar={loadingApagarVideo}
			/>
		</>
	);
};

export { PtVideo };
