import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiBriefingGoals = {
	insertMany: {
		post: async (dados) => {
			return await api.post(`/briefingGoals/insertMany`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	all: {
		get: async idBriefing => {
			return await api.get(`/briefingGoals/all/${idBriefing}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getAll: {
		get: async idBriefing => {
			return await api.get(`/briefingGoals/getAll/${idBriefing}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiBriefingGoals;