import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalUsuariosFormCadastro.css';

// API
import { apiGetCargos, apiGetEmpresas } from '@apiGeral/geral';
import { api } from "@_api/api";

// Partes
import FormUsuario from '@page/Logado/Usuarios/FormUsuario/FormUsuario';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { camposInvalidos, removerKeysVazias, validarCnpj, validarCpf, validarEmail } from '@func/utils';
import { gets } from "@route/routeUtils";
import { msgs, msgStatus } from "@_func/request";

const PortalUsuariosFormCadastro = props => {

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-usuarios-form-cadastro',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		active: 1,
		id: '',
		id_company: '',
		name: '',
		cpf_cnpj: '',
		phone: '',
		email: '',
		role: 3,
		id_position: null,
	};

	/*
	** Loading
	** ================================================== */
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState([]);
	const [loadingGetCargos, setLoadingGetCargos] = useState([]);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [stateCargos, setStateCargos] = useState([]);
	const [stateEmpresas, setStateEmpresas] = useState([]);

	// Edição
	const [loadingCadastrarUsuario, setLoadingCadastrarUsuario] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		apiGetEmpresas({setLoadingGetEmpresas, setStateEmpresas});
		apiGetCargos({setLoadingGetCargos, setStateCargos});

		// Define a empresa atual no select
		const getsAtuais = gets();
		if(getsAtuais.id_company && getsAtuais.id_company !== '') {
			setStateDados({
				...stateDados,
				id_company: getsAtuais.id_company,
			});
		}
	}

	/*
	** Cadastrar usuário
	** ================================================== */
	const cadastrarUsuario = async e => {
		e.preventDefault();

		if(loadingCadastrarUsuario) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			id_company: stateDados.id_company,
			name: stateDados.name,
			cpf_cnpj: stateDados.cpf_cnpj,
			phone: stateDados.phone,
			email: stateDados.email,
			role: stateDados.role,
			id_position: stateDados.id_position,
			password: stateDados.password,
		};

		if(camposInvalidos(dadosEnviar, {
			role: 'Nível de acesso',
			name: 'Nome completo',
			password: 'Senha padrão',
		}, [
			'id_company',
			'name',
			'email',
			'password',
			'role',
			'id_position',
		])) return;

		if(!validarEmail(dadosEnviar.email)) return;

		if(dadosEnviar.cpf_cnpj && dadosEnviar.cpf_cnpj !== '') {
			const isCpf = validarCpf(dadosEnviar.cpf_cnpj, false);
			const isCnpj = validarCnpj(dadosEnviar.cpf_cnpj, false);

			if(!isCpf && !isCnpj) {
				msg.error('CPF ou CNPJ inválido');
				return;
			}
		}

		setLoadingCadastrarUsuario(true);

		const dadosEnviarSemKeysVazias = removerKeysVazias(dadosEnviar);
		const retInsertUsuario = await api.users.insert.post(dadosEnviarSemKeysVazias);

		if(retInsertUsuario.status === 200) {
			msg.success('Usuário cadastrado com sucesso.');
			onSuccess({
				ret: retInsertUsuario,
			})
		} else {
			msgs([retInsertUsuario]);
			onError({
				ret: retInsertUsuario,
			});
		}

		setLoadingCadastrarUsuario(false);
	}

	return (
		<Portal
			titulo="Cadastrar usuário"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<div className={wrap}>
				<div className={className}>
					<FormUsuario
						isCadastro
						onSubmit={cadastrarUsuario}
						stateDados={stateDados}
						setStateDados={setStateDados}
						stateCargos={stateCargos}
						stateEmpresas={stateEmpresas}
						loadingGetEmpresas={loadingGetEmpresas}
						loadingGetCargos={loadingGetCargos}
						after={
							<div className="form-bottom">
								<Button2
									_={{
										value: 'Cancelar',
										className: 'btn btn-1',
										type: 'button',
										onClick: onClose,
									}}
									classExtra="btn-1"
								/>
								<Button2
									_={{
										value: 'Cadastrar usuário',
										onClick: cadastrarUsuario
									}}
									loading={loadingCadastrarUsuario}
									wrap="margin-l-15"
								/>
							</div>
						}
					/>
				</div>
			</div>
		</Portal>
	);
};

export { PortalUsuariosFormCadastro };
