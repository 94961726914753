import React, { useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import codes from 'country-calling-code';
import _ from 'lodash';
import queryString from 'query-string';
import ReactGA from 'react-ga';
import { isSupportedCountry } from 'react-phone-number-input';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './Cadastro.css';

// API
import apiFreelancer from '@api/freelancer/freelancer';

// Imagens
import logo from '@img/logo.png';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Checkbox2 from '@cpnt/Checkbox2/Checkbox2';
import CriteriosSenha, { validarSenha } from '@cpnt/CriteriosSenha/CriteriosSenha';
import Input2 from '@cpnt/Input2/Input2';
import InputTelefone2 from '@cpnt/InputTelefone2/InputTelefone2';
import OverlayConteudo from '@cpnt/OverlayConteudo/OverlayConteudo';
import PortalTermosDeUso from "@cpnt/Portais/PortaisTermos/PortalTermosDeUso/PortalTermosDeUso";
import PortalTermosNotaFiscal from "@cpnt/Portais/PortaisTermos/PortalTermosNotaFiscal/PortalTermosNotaFiscal";
import { Select2 } from '@cpnt/Select2/Select2';
import Tip from "@cpnt/Tip/Tip";

// Funções
import { apenasNumeros, camposInvalidos, msg, validarCpf, validarEmail } from '@func/utils';

// Config
import { DEV, GOOGLE_ANALYTICS_KEY, URL_SITE } from '@_config/config';

// Rotas
import { msgs } from "@_func/request";
import { closePortal, getPortalDados, gets, isShowPortal, showPortal } from "@route/routeUtils";
import { api } from "@_api/api";

const Cadastro = () => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();
	const location = useLocation();

	/*
	** Loading
	** ================================================== */
	const [loadingCadastrar, setLoadingCadastrar] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateCadastro, setStateCadastro] = useState({
		name: '',
		email: '',
		password: '',
		phone: '',
		price: 0,
		availability: 1,
		alocado: 0,
		cpf_cnpj: '',
		id_country: 33,
		accept_jobs_mail: 1,
		accept_jobs_whatsapp: 1,
		accept_newsletter: false,

		// termos
		accept_terms: false,
		accept_issuing_invoice: false,
	});
	const [statePaises, setStatePaises] = useState([
		{
			value: 33,
			label: 'Brasil',
			initials: 'BR',
		},
		{
			value: 75,
			label: "Espanha",
			initials: 'ES'
		},
		{
			value: 182,
			label: "Portugal",
			initials: 'PT'
		},
		{
			value: 76,
			label: "Estados Unidos da América",
			initials: 'US'
		},
	]);
	const [stateTelefone, setStateTelefone] = useState('+55');
	const [stateSiglaPaisSelecionado, setStateSiglaPaisSelecionado] = useState('BR');
	const [showOverlaySucesso, setShowOverlaySucesso] = useState(false);

	const [stateCriteriosSenha, setStateCriteriosSenha] = useState([]);
	const [showTooltipPassword, setShowTooltipPassword] = useState(false);
	const [showTooltipNome, setShowTooltipNome] = useState(false);

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		// Google Analytics
		ReactGA.initialize(GOOGLE_ANALYTICS_KEY, {
			debug: false,
		});
		ReactGA.pageview(window.location.pathname + window.location.search);

		// Carrega países
		getPaises();
	}, []);

	/*
	** Validar telefone
	** ================================================== */
	useEffect(() => {
		if(stateTelefone && stateTelefone.length && stateTelefone.length >= 20) {
			var value = stateTelefone.slice(0, 20);
			setStateTelefone(value);
		}
	}, [stateTelefone]);

	/*
	** API - Get países
	** ================================================== */
	const getPaises = async () => {
		const retGetPaises = await api.select.countrys.get();
		if(retGetPaises.status === 200) {
			setStatePaises([...retGetPaises.data.map(value => ({
				value: value.id,
				label: value.name,
				initials: value.initials,
			}))]);
		} else {
			msg.warning('Não foi possível carregar todos os países. Será exibido apenas uma lista simplificada.');
		}
	}

	const cadastrarProfissional = async e => {
		e.preventDefault();

		if(loadingCadastrar) {
			msg.warning('Por favor, aguarde...');
			return;
		}

		var campo = 'CPF';

		if(stateCadastro.id_country !== 33) {
			campo = 'Documento válido';
		}

		// Validar campos
		if(camposInvalidos(stateCadastro, {
			cpf_cnpj: campo,
		}, [
			'name',
			'email',
			'password',
			'cpf_cnpj',
			'accept_terms',
			'accept_issuing_invoice',
		])) return;

		var nome = stateCadastro.name.trim();

		// Validar nome
		if(!nome || !nome.length || nome === '' || nome.indexOf(' ') <= -1) {
			setShowTooltipNome(true);
			msg.error('Você deve informar seu nome completo, corrija para continuar.');
			return;
		}

		if(!validarSenha(stateCadastro.password).valido) {
			setShowTooltipPassword(true);
			msg.error('A senha inserida não atende as critérios necessários.');
			return;
		}

		// Validar telefone
		if(!stateTelefone || !stateTelefone.length || stateTelefone === '') {
			msg.error('Telefone inválido.');
			return;
		}

		// Validar CPF
		if(stateCadastro.id_country === 33 && !validarCpf(stateCadastro.cpf_cnpj, false)) {
			msg.error('CPF inválido.');
			return;
		} else if(!stateCadastro.cpf_cnpj || stateCadastro.cpf_cnpj === '' || stateCadastro.cpf_cnpj.length < 3) {
			msg.error('Insira um documento válido no seu país.');
			return;
		}

		// validar se é um e-mail no lugar do nome
		if(validarEmail(stateCadastro.name, false)) {
			msg.error('Você inseriu um e-mail no lugar do seu nome, corrija para continuar.');
			return;
		}

		// validar se é um e-mail no lugar do nome
		if(validarCpf(stateCadastro.name, false)) {
			msg.error('Você inseriu um CPF no lugar do seu nome, corrija para continuar.');
			return;
		}

		// Validar e-mail
		if(!validarEmail(stateCadastro.email)) return;

		// Validar senha
		if(stateCadastro.password.length < 6) {
			setShowTooltipPassword(true);
			msg.error('A senha deve conter no mínimo 6 caracteres.');
			return;
		}

		// validar termos
		if(!stateCadastro.accept_terms || !stateCadastro.accept_issuing_invoice) {
			msg.error('Você deve aceitar os termos para realizar o cadastro.');
			return;
		}

		// validar termos
		if(!stateCadastro.accept_terms || !stateCadastro.accept_issuing_invoice) {
			msg.error('Você deve aceitar os termos para realizar o cadastro.');
			return;
		}

		// validar se não é telefone no lugar do nome
		var numeros = apenasNumeros(stateCadastro.name);
		numeros = String(numeros);
		if(numeros.length >= 8){
			msg.error('Você inseriu um telefone no lugar do seu nome, corrija para continuar.');
			return;
		}

		setLoadingCadastrar(true);

		const retCadastrarProfissional = await apiFreelancer.insert.post({
			...stateCadastro,
			phone: stateTelefone,
		});

		if(retCadastrarProfissional.status === 200) {
			msg.success('Cadastro realizado com sucesso!');

			setShowOverlaySucesso(true);

			if(!DEV) {
				/*
				** Envia o evento para o GTM
				** ================================================== */
				TagManager.dataLayer({
					dataLayer: {
						event: 'cadastroProfissional',
						userDetails: {
							id: 'cadastre-se',
						}
					}
				});

				/*
				** Envia evento para o Google Analytics informando o cadastro
				** https://github.com/react-ga/react-ga#examples
				** ================================================== */
				const q = queryString.parse(window.location.search);
				var categoria = 'Não informado';

				if(q && typeof(q) === 'object' && q.utm_source && q.utm_source !== '') {
					categoria = q.utm_source;
				}

				ReactGA.event({
					category: categoria, // ORIGEM
					action: 'Cadastro Freelancer', // AÇÃO
				});
			}

		} else {
			msgs([retCadastrarProfissional]);
		}

		setLoadingCadastrar(false);

	}

	return (
		<>
			<div className="tela-inicio-e-inner">
				<OverlayConteudo
					exibir={showOverlaySucesso}
					exibirFechar={false}
					texto={
						<div>
							<div className="strong fs-20">Cadastro realizado com sucesso!</div>
							<div className="margin-t-20">Enviamos uma mensagem de confirmação para o seu e-mail. Siga as instruções da mensagem para continuar o seu cadastro na CROWD.</div>
						</div>
					}
				/>
				<a href={`${URL_SITE}`} className="tela-inicio-e-logo">
					<img src={logo} alt="Logo CROWD" className="block w-100p max-w-350 margin-auto" />
				</a>
				<h1 className="tela-inicio-e-titulo padding-t-10 margin-b-0">Crie sua conta de profissional</h1>
				<div className="margin-t-5 align-center fs-13">
					Já possui conta de profissional? <Link to="/login" className="no-decoration bold">Acesse sua conta</Link>
				</div>

				<form className="tela-inicio-form margin-t-25" onSubmit={cadastrarProfissional}>
					<div className="input-box">
						<Tip
							titulo="Dados pessoais"
							tip={
								<Input2
									label="Nome completo"
									req
									_={{
										value: stateCadastro.name,
										onChange: e => {
											var nomeSemCaracteres = e.target.value.replace(/[/\\#,+()$~%.`´~@¨§º=^'":*?<>|_{}-]/g, '');
											setStateCadastro({
												...stateCadastro,
												name: nomeSemCaracteres,
											});
										},
									}}
								/>
							}
							classNameTipDefault=""
							info
							click
							_defaults={{
								placement: 'right-center',
							}}
						>
							<div>Após realizar o cadastro, você poderá informar os dados da sua empresa para utilizar durante as negociações e também nos jobs.</div>
							<div className="margin-t-15">Neste primeiro momento informe apenas os seus dados pessoais.</div>
						</Tip>
					</div>
					<div className="input-box">
						<Select2
							label="País"
							req
							_={{
								options: statePaises,
								value: stateCadastro.id_country,
								isClearable: false,
								onChange: e => {
									if(e && e.value) {
										setStateCadastro({
											...stateCadastro,
											id_country: e.value,
										});

										// Pega o código de acordo com a letra
										var numeroObj = _.find(codes, {isoCode2: e.initials});
										var numero = '';

										// Define o número para modificar o campo
										if(numeroObj) {
											numero = _.get(numeroObj, 'countryCodes.0');
											if(numero && numero !== '') {
												numero = `+${numero}`;
											}
										}

										// Define os valores
										setStateSiglaPaisSelecionado(e.initials);
										setStateTelefone(numero);
									}
								},
							}}
							virtualized
						/>
					</div>
					<div className="input-box">
						<InputTelefone2
							label="Telefone (com prefixo do país)"
							req
							_={{
								value: stateTelefone,
								onChange: setStateTelefone,
								international: true,
								withCountryCallingCode: true,
								country: stateSiglaPaisSelecionado,
								defaultCountry: 'BR',
								onCountryChange: siglaPais => {
									if(isSupportedCountry(siglaPais)) {
										setStateSiglaPaisSelecionado(siglaPais);
									} else {
										setStateSiglaPaisSelecionado('');
									}
								},
							}}
						/>
					</div>
					<div className="input-box">
						{stateCadastro.id_country === 33 ? (
							<Input2
								label="CPF"
								req
								_={{
									value: stateCadastro.cpf_cnpj,
									onChange: e => {
										setStateCadastro({
											...stateCadastro,
											cpf_cnpj: e.target.value,
										})
									},
									mask: '999.999.999-99'
								}}
							/>
						) : (
							<Input2
								label="Documento válido"
								req
								_={{
									value: stateCadastro.cpf_cnpj,
									onChange: e => {
										var doc = e.target.value.replace(/[/\\$~%`´~@¨§º=^'"?<>|{}]/g, '');
										setStateCadastro({
											...stateCadastro,
											cpf_cnpj: doc,
										})
									}
								}}
							/>
						)}
					</div>
					<div className="input-box">
						<Input2
							label="E-mail"
							req
							_={{
								value: stateCadastro.email,
								type: 'email',
								onChange: e => {
									setStateCadastro({
										...stateCadastro,
										email: e.target.value,
									})
								}
							}}
						/>
					</div>
					<div className="input-box relative">
						<Tip
							titulo="Critérios da senha"
							tip={
								<Input2
									label="Senha"
									req
									_={{
										value: stateCadastro.password,
										type: 'password',
										autoComplete: 'off',
										onChange: e => {
											setStateCadastro({
												...stateCadastro,
												password: e.target.value,
											})
										},
									}}
									debounce={false}
								/>
							}
							classNameTipDefault=""
							info
							click
							_defaults={{
								placement: 'right-center',
							}}
						>
							<CriteriosSenha
								senha={stateCadastro.password}
								stateCriterios={stateCriteriosSenha}
								setStateCriterios={setStateCriteriosSenha}
							/>
						</Tip>
					</div>

					{/* Notificações */}
					<div className="margin-t-10">
						<div className="padding-h-5">
							<span className="flex flex-center-v">
								<strong>Notificações</strong>
							</span>
						</div>
						<div className="input-box">
							<Checkbox2
								label={
									<div className="flex flex-center-v">
										<span>Desejo receber e-mails de <strong>Newsletter</strong></span>
										<Tip
											tip={<span className="q"></span>}
											info
											_defaults={{
												placement: 'right-center',
											}}
										>
											Comunicações de marketing sobre novidades da plataforma, novos conteúdos e benefícios para você.
										</Tip>
									</div>
								}
								_={{
									checked: stateCadastro.accept_newsletter,
									onChange: e => {
										setStateCadastro({
											...stateCadastro,
											accept_newsletter: e.target.checked,
										})
									}
								}}
								smallLabel
							/>
						</div>
					</div>

					{/* Termos */}
					<div className="margin-t-10">
						<div className="padding-h-5">
							<strong>Termos</strong>
						</div>
						<div className="margin-t-0 input-box">
							<Checkbox2
								label={
									<>
										<span>Li e concordo com os</span>
										<span className="link margin-l-5" onClick={e => {
											e.preventDefault();
											showPortal({portal: 'termos-e-condicoes', stateGet, navigate})
										}}>Termos e condições</span>
									</>
								}
								_={{
									checked: stateCadastro.accept_terms,
									onChange: e => {
										setStateCadastro({
											...stateCadastro,
											accept_terms: e.target.checked,
										})
									}
								}}
								smallLabel
							/>
						</div>
						<div className="padding-t-2 input-box">
							<Checkbox2
								label={
									<>
										<span>Estou ciente que é obrigatório a emissão de nota fiscal própria ou invoice (exclusivo para estrangeiros).</span>
										<span className="link margin-l-5" onClick={e => {
											e.preventDefault();
											showPortal({portal: 'nota-fiscal', stateGet, navigate})
										}}>Por que é obrigatória?</span>
									</>
								}
								_={{
									checked: stateCadastro.accept_issuing_invoice,
									onChange: e => {
										setStateCadastro({
											...stateCadastro,
											accept_issuing_invoice: e.target.checked,
										})
									}
								}}
								smallLabel
							/>
						</div>
					</div>

					<div className="input-box">
						<Button2
							_={{
								value: 'ME CADASTRE COMO PROFISSIONAL',
								type: 'submit',
								className: 'btn btn-default w-100p'
							}}
							wrap="flex margin-t-15"
							loading={loadingCadastrar}
						/>
					</div>
				</form>
			</div>

			<PortalTermosDeUso
				id={getPortalDados({portal: 'termos-e-condicoes', stateGet})}
				show={isShowPortal({portal: 'termos-e-condicoes', stateGet})}
				onClose={() => closePortal({portal: 'termos-e-condicoes', stateGet, navigate})}
			/>

			<PortalTermosNotaFiscal
				id={getPortalDados({portal: 'nota-fiscal', stateGet})}
				show={isShowPortal({portal: 'nota-fiscal', stateGet})}
				onClose={() => closePortal({portal: 'nota-fiscal', stateGet, navigate})}
			/>
		</>
	);
};

export default Cadastro;
