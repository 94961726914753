import { validarRetorno, config } from '@_api/axios';
import api from 'axios';
import qs from 'qs';

const apiSelect = {
	segments: {
		get: async () => {
			return await api.get(`/select/segments`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	skills: {
		get: async (idArea = null) => {
			return await api.get(`/select/skills${idArea ? `/${idArea}` : ''}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	banks: {
		get: async (nomeBanco = null) => {
			return await api.get(`/select/banks${nomeBanco ? `/${nomeBanco}` : ''}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	gender: {
		get: async () => {
			return await api.get(`/select/gender`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	competences: {
		get: async () => {
			return await api.get(`/select/competences`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	languages: {
		get: async () => {
			return await api.get(`/select/languages`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	languages_levels: {
		get: async () => {
			return await api.get(`/select/languages_levels`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	goals: {
		get: async () => {
			return await api.get(`/select/goals`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	company: {
		get: async () => {
			return await api.get(`/select/company`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	positions: {
		get: async () => {
			return await api.get(`/select/positions`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	areas: {
		get: async () => {
			return await api.get(`/select/areas`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	state: {
		get: async () => {
			return await api.get(`/select/state`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	customers: {
		get: async idEmpresa => {
			return await api.get(`/select/customers/${idEmpresa}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	disc: {
		get: async () => {
			return await api.get(`/select/disc`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	mbti: {
		get: async () => {
			return await api.get(`/select/mbti`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	skin_color: {
		get: async () => {
			return await api.get(`/select/skin-color`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	projects: {
		get: async dadosObj => {
			var dadosEnviar = qs.stringify(dadosObj, { encode: false });
			return await api.get(`/select/projects?${dadosEnviar}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	seniority: {
		get: async dadosObj => {
			return await api.get(`/select/seniority`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	countrys: {
		get: async () => {
			return await api.get(`/select/countrys`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	paymentsType: {
		get: async () => {
			return await api.get(`/select/payments-type`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	templatesType: {
		get: async (dadosObj) => {
			var dadosEnviar = qs.stringify(dadosObj, { encode: false });
			return await api.get(`/select/templates-type?${dadosEnviar}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	typeContract: {
		get: async () => {
			return await api.get(`/select/type-contract`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	modelWork: {
		get: async () => {
			return await api.get(`/select/model-work`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export { apiSelect };
