import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalCertificadoFormCadastro.css';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { camposInvalidos, dataApiCrop } from '@func/utils';
import FormCertificado from "@cpnt/Forms/FormCertificado/FormCertificado";

const PortalCertificadoFormCadastro = props => {

	const {
		show,
		onClose = () => null,
		closeOnEsc = false,
		portalClassName = 'portal-cert-form-cadastro',
		onSuccess = () => null,
		dados = {},
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		title: '',
		issuer: '',
		url: '',
		issue_date: '',
		...dados,
	};

	const [stateDados, setStateDados] = useState(camposDefault);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados(stateDados);
		}
	}

	/*
	** Editar empresa
	** ================================================== */
	const cadastrar = async e => {
		e.preventDefault();

		const dadosEnviar = {
			...camposDefault,
			title: stateDados.title,
			issuer: stateDados.issuer,
			url: stateDados.url,
			issue_date: dataApiCrop(stateDados.issue_date),
		};

		if(camposInvalidos(dadosEnviar, {}, [
			'title',
			'issuer',
			'issue_date',
		])) return;

		onSuccess(stateDados);
		setTimeout(() => {
			setStateDados(camposDefault);
		}, 400);
	}

	return (
		<Portal
			titulo="Adicionar certificado"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<FormCertificado
				state={stateDados}
				setState={setStateDados}
			/>
			<div className="form-bottom">
				<Button2
					_={{
						value: 'Cancelar',
						className: 'btn btn-1',
						onClick: onClose,
					}}
				/>
				<Button2
					_={{
						value: 'Adicionar certificado',
						onClick: cadastrar,
					}}
					wrap="margin-l-10"
				/>
			</div>
		</Portal>
	);
};

export { PortalCertificadoFormCadastro };
