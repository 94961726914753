import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// css
import './TopoPage.css';

// Imagens
import imgLogoCrowd from '@img/logo.svg';
import { isLogado } from "@_func/auth";

const TopoPage = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
	} = props;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const menu = () => {
		if(isLogado()._logado) {
			return (
				<Link to="/perfil">Perfil</Link>
			)
		}

		return (
			<Link to="/">Login</Link>
		)
	}

	return (
		<>
			<div className={`cpnt-topopage ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-topopage-box ${className}`}>
					<div className="base">
						<div className="base-content">
							<div className="cpnt-topopage-inner">
								<div className="cpnt-topopage-logo-wrap">
									<img src={imgLogoCrowd} alt="CROWD" className="block w-100p" />
								</div>
								<div className="cpnt-topopage-logo-wrap">
									{menu()}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { TopoPage };