import React, { useState } from 'react';

// API
import apiAlgolia from '@api/algolia/algolia';

// Componentes
import Checkbox2 from '@cpnt/Checkbox2/Checkbox2';
import { PortalPerfilFreelancer } from '@portalGeral/PortalPerfil/PortalPerfilFreelancer';
import { Select2 } from '@cpnt/Select2/Select2';

// Funções
import { formatarSelectAlgolia } from '@func/utils';

const Select2Algolia = props => {

	const {
		setStateOptions = () => null,
		req,
		loading,
		desativarRequest,
		_,
	} = props;

	const [showPortalFreelancer, setShowPortalFreelancer] = useState(false);
	const [stateFreelancerId, setStateFreelancerId] = useState(false);
	const [stateDados, setStateDados] = useState({
		all_results: 1,
		text: '',
	});

	const getDados = async (termoPesquisa) => {
		if(desativarRequest) return;

		const dadosEnviar = {
			all_results: stateDados.all_results ? 1 : 0,
			text: termoPesquisa,
		};

		const retGetUsuarios = await apiAlgolia.withoutRelevance.post(dadosEnviar);

		var retorno = [];

		if(retGetUsuarios.status === 200) {
			retorno = formatarSelectAlgolia(retGetUsuarios.data);
		}

		setStateOptions(retorno);

		return retorno;
	};

	return (
		<>
			<Select2
				label="Profissional"
				ajaxPlaceholder={!desativarRequest ? "Digite o nome ou o ID do profissional para pesquisar" : 'Funcionalidade desativada'}
				req={req}
				_={_}
				ajax={getDados}
				cpnt="algolia"
				cpntParams={{
					onClick: e => {
						setStateFreelancerId(e);
						setShowPortalFreelancer(true);
					},
				}}
				before={
					<div className="flex flex-wrap flex-center-v padding-15 padding-b-10 border-b">
						<Checkbox2
							label="Exibir perfis incompletos"
							_={{
								checked: stateDados.all_results,
								onChange: e => {
									setStateDados({
										...stateDados,
										all_results: e.target.checked,
									});
								}
							}}
						/>
					</div>
				}
				loading={loading}
			/>
			<PortalPerfilFreelancer
				id={stateFreelancerId}
				show={showPortalFreelancer}
				onClose={() => setShowPortalFreelancer(false)}
			/>
		</>
	);
};

export default Select2Algolia;