import React from 'react';
import Placeholder from '@cpnt/Placeholder/Placeholder';
import { multiplicarElementos } from '@func/utils';

const PlaceholderProjetos = props => {
	const {
		itensQtd,
	} = props;

	return (
		<tr>
			<td className="col-id">
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td className="tbl-projetos-col-projetos">
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td className="col-time">
				<div className="td-wrap">
					<div className="td-wrap-placeholder">{multiplicarElementos(itensQtd.foto, <Placeholder tipo="circulo" />)}</div>
				</div>
			</td>
			<td className="col-solucoes">
				<div className="td-wrap">
					<div className="td-wrap-placeholder">{multiplicarElementos(itensQtd.solucoes - 1, <Placeholder tipo="circulo" />)}</div>
				</div>
			</td>
			<td className="col-data">
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td className="col-data">
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td className="col-min-3">
				<div className="td-wrap">
					<Placeholder tipo="circulo" />
				</div>
			</td>
			<td className="col-min-4">
				<div className="td-wrap">
					<Placeholder tipo="circulo" />
				</div>
			</td>
			<td className="col-min-3">
				<div className="td-wrap">
					<Placeholder tipo="circulo" />
				</div>
			</td>
			<td className="col-acao">
				<div className="td-wrap">
					<Placeholder tipo="retangulo-alt" />
				</div>
			</td>
		</tr>
	)
}

export default PlaceholderProjetos;