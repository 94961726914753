import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalUsuariosFormEdicao.css';

// API
import { apiGetCargos, apiGetEmpresas } from '@apiGeral/geral';
import { api } from "@_api/api";

// Partes
import FormUsuario from '@page/Logado/Usuarios/FormUsuario/FormUsuario';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { camposInvalidos,  removerKeysVazias, validarCnpj, validarCpf, validarEmail } from '@func/utils';
import { msgs, msgStatus } from "@_func/request";

const PortalUsuariosFormEdicao = props => {

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-usuarios-form-edicao',
		onSuccess = () => null,
		onError = () => null,
		onSuccessStatus = () => null,
		onErrorStatus = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		id: '',
		id_company: '',
		name: '',
		cpf_cnpj: '',
		phone: '',
		email: '',
		role: 3,
		id_position: null,
	};

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState([]);
	const [loadingGetCargos, setLoadingGetCargos] = useState([]);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});
	const [stateCargos, setStateCargos] = useState([]);
	const [stateEmpresas, setStateEmpresas] = useState([]);

	// Edição
	const [loadingEditarUsuario, setLoadingEditarUsuario] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await api.users.getOne.get(id);

		if(retGetItem.status === 200) {
			setStateDados(retGetItem.data);
			_setStateDados(msgStatus(retGetItem));
			apiGetEmpresas({setLoadingGetEmpresas, setStateEmpresas});
			apiGetCargos({setLoadingGetCargos, setStateCargos});
		} else {
			_setStateDados(msgStatus(retGetItem));
			msgs([retGetItem]);
		}

		setLoading(false);
	}

	/*
	** Editar usuário
	** ================================================== */
	const editarUsuario = async e => {
		e.preventDefault();

		if(loadingEditarUsuario) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			id: stateDados.id,
			id_company: stateDados.id_company,
			name: stateDados.name,
			cpf_cnpj: stateDados.cpf_cnpj,
			phone: stateDados.phone,
			email: stateDados.email,
			role: stateDados.role,
			id_position: stateDados.id_position,
			password: stateDados.password,
		};

		if(camposInvalidos(dadosEnviar, {}, [
			'id',
			'id_company',
			'name',
			'email',
			'role',
			'id_position',
		])) return;

		if(!validarEmail(dadosEnviar.email)) return;

		// Senha
		if(stateDados.password && stateDados.password !== '') {
			if(stateDados.password.length < 6) {
				msg.error('A senha deve conter no mínimo 6 caracteres.');
				return;
			}
		}

		// CPF ou CNPJ
		if(stateDados.cpf_cnpj && stateDados.cpf_cnpj !== '') {
			const isCpf = validarCpf(stateDados.cpf_cnpj, false);
			const isCnpj = validarCnpj(stateDados.cpf_cnpj, false);

			if(!isCpf && !isCnpj) {
				msg.error('CPF ou CNPJ inválido');
				return;
			}
		}

		setLoadingEditarUsuario(true);

		const dadosEnviarSemKeysVazias = removerKeysVazias(dadosEnviar);
		const retUpdateUsuario = await api.users.update.put(dadosEnviarSemKeysVazias);

		if(retUpdateUsuario.status === 200) {
			msg.success('Usuário atualizado com sucesso.');
			onSuccess({
				ret: retUpdateUsuario,
			})
		} else {
			msgs([retUpdateUsuario]);
			onError({
				ret: retUpdateUsuario,
			});
		}

		setLoadingEditarUsuario(false);
	}

	return (
		<Portal
			titulo="Editar usuário"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<FormUsuario
								onSubmit={editarUsuario}
								stateDados={stateDados}
								setStateDados={setStateDados}
								stateCargos={stateCargos}
								stateEmpresas={stateEmpresas}
								loadingGetEmpresas={loadingGetEmpresas}
								loadingGetCargos={loadingGetCargos}
								onSuccessStatus={onSuccessStatus}
								onErrorStatus={onErrorStatus}
								after={
									<div className="form-bottom">
										<Button2
											_={{
												value: 'Cancelar',
												className: 'btn btn-1',
												type: 'button',
												onClick: onClose,
											}}
											classExtra="btn-1"
										/>
										<Button2
											_={{
												value: 'Salvar dados',
												onClick: editarUsuario
											}}
											loading={loadingEditarUsuario}
											wrap="margin-l-15"
										/>
									</div>
								}
							/>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export { PortalUsuariosFormEdicao };
