import React from 'react';
import { corDegrade } from '@func/utils';

// css
import './PorcentagemHorizontal.css';

const PorcentagemHorizontal = props => {
	const {
		className = '',
		wrap = '',
		porcentagem = 0,
	} = props;

	return (
		<div className={`cpnt-porcentagemhorizontal ${wrap}`}>
			<div className={`cpnt-porcentagemhorizontal-box ${className}`}>
				<div className="cpnt-porcentagemhorizontal-inner">
					<div className="cpnt-porcentagemhorizontal-inner-wrap">
						<div className="cpnt-porcentagemhorizontal-porcentagem" style={{width: `${porcentagem}%`, backgroundColor: porcentagem >= 100 ? '#1099ec' : corDegrade('#F7B36B', '#019E33', porcentagem)}}></div>
						<div className="cpnt-porcentagemhorizontal-texto">{porcentagem}%</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default PorcentagemHorizontal;