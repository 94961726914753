import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiAlgolia = {
	post: async dados => {
		return await api.post(`/algolia`, dados, config())
		.then(retorno => {
			var ret = validarRetorno(retorno);
			if(ret.status === 200) {
				if(ret.error === undefined && ret.data !== undefined && typeof(ret.data) === 'object' && ret.data.length) {
					return {
						status: 200,
						message: 'Resultados encontrados',
						data: ret.data,
						metadata: {
							_: ret.metadata,
							qtdPages: ret.metadata.nbPages,
							qtdRecords: ret.metadata.nbHits,
						}
					}
				} else if(!ret.data || (typeof(ret.data) === 'object' && !ret.data.length)) {

					var metadata = {};
					if(ret.metadata && typeof(ret.metadata) === 'object') {
						metadata = ret.metadata;
					}

					return {
						status: 400,
						message: 'Nenhum resultado encontrado',
						data: [],
						metadata: {
							_: ret.metadata,
							qtdPages: metadata.nbPages,
							qtdRecords: metadata.nbHits,
						}
					}
				}
			}

			return {
				status: ret.status,
				message: ret.error || 'Não foi possível realizar a requisição',
				data: [],
				metadata: {
					_: ret.metadata,
				}
			}
		})
		.catch(retorno => {
			return validarRetorno(retorno);
		});
	},
	withoutRelevance: {
		post: async dados => {
			return await api.post(`/algolia/withoutRelevance`, dados, config())
			.then(retorno => {
				var ret = validarRetorno(retorno);

				if(ret.status === 200) {
					if(ret.error === undefined && ret.data !== undefined && typeof(ret.data) === 'object' && ret.data.length) {
						return {
							status: 200,
							message: 'Resultados encontrados',
							data: ret.data,
							metadata: {
								_: ret.metadata,
								qtdPages: ret.metadata.nbPages,
								qtdRecords: ret.metadata.nbHits,
							}
						}
					} else if(!ret.data || (typeof(ret.data) === 'object' && !ret.data.length)) {

						var metadata = {};
						if(ret.metadata && typeof(ret.metadata) === 'object') {
							metadata = ret.metadata;
						}

						return {
							status: 400,
							message: 'Nenhum resultado encontrado',
							data: [],
							metadata: {
								_: ret.metadata,
								qtdPages: metadata.nbPages,
								qtdRecords: metadata.nbHits,
							}
						}
					}
				}

				return {
					status: ret.status,
					message: ret.error || 'Não foi possível realizar a requisição',
					data: [],
					metadata: {
						_: ret.metadata,
					}
				}

			})
			.catch(retorno => {
				var ret = validarRetorno(retorno);
				ret = {
					...retorno,
					status: ret.status,
					message: ret.error || 'Não foi possível realizar a requisição',
					data: [],
					metadata: {
						_: ret.metadata,
					}
				}
				return ret;
			});
		}
	}
};

export default apiAlgolia;
