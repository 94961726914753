import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast as msg } from 'react-toastify';
import moment from 'moment/min/moment-with-locales';
import md5 from 'crypto-js/md5';

// Store e Hooks
import StoreContext from '@/_/contexts/StoreContext';
import useWindowFocus from '@/_/hooks/useWindowFocus';

// Textos
import JOB_MENSAGENS_PADROES from '@texto/jobMensagensPadroes';

// css
import './EmpresasChat.css';

// API
import socket from '@_api/socket';
import apiTasksMessages from '@api/tasksMessages/tasksMessages';
import apiTasks from '@api/tasks/tasks';
import apiBriefingMessages from '@api/briefingMessages/briefingMessages';
import apiTasksCombos from '@api/tasksCombos/tasksCombos';

// Componentes
import Chat from '@cpnt/Chat/Chat';
import Foto from '@cpnt/Foto/Foto';
import TextoWrap from '@cpnt/TextoWrap/TextoWrap';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';
import Icone from '@cpnt/Icone/Icone';
import LinhaItem from '@cpnt/LinhaItem/LinhaItem';
import PortalPerfil from '@portalGeral/PortalPerfil/PortalPerfil';
import Portal from '@cpnt/Portal/Portal';
import Button2 from '@cpnt/Button2/Button2';
import Equipe from '@cpnt/Equipe/Equipe';
import Loading from '@cpnt/Loading/Loading';
import StatusRadio from '@cpnt/StatusRadio/StatusRadio';
import { iconeStatusCard } from '@cpnt/StatusCards/StatusCards';
import Input2 from '@cpnt/Input2/Input2';
import Radio2 from '@cpnt/Radio2/Radio2';
import ChatMensagem from '@cpnt/Chat/ChatMensagem/ChatMensagem';

// Imagens
import imgIconeVoltar from '@svg/solid/arrow-left.svg';
import imgIconeValor from '@svg/solid/sack-dollar.svg';
import imgIconeEditar from '@svg/regular/pen.svg';
import imgIconePDF from '@svg/solid/file-pdf.svg';
import imgIconeXML from '@svg/solid/file-code.svg';

// Funções
import { formatarMensagemChat, fotoAleatoria } from '@shared/functions/components';
import { caminhoFoto, caminhoUrl, camposInvalidos, dataApiParaBr, dataHaQuantoTempo, formatarDinheiro, htmlToJsx, sanitize, validarDataAPI } from '@func/utils';
import notificacao from '@_func/notification';
import { chatFormatarDadosUsuario, chatSalaAddUsuarioOnline, chatSalaAddUsuariosOnline, chatSalaRemoverUsuarioOnline } from '@cpnt/Chat/functions';

// Config
import templatesBriefings from '@config/templateBriefings';
import { apiCompany } from '@_api/requests/company/company';
import { hash } from "@_func/generate";
import { msgs, msgStatus } from "@_func/request";

const useQuery = () => {
	return new URLSearchParams(useLocation().search);
}

const EmpresasChat = () => {

	const [store, setStore] = useContext(StoreContext);
	const janelaAtiva = useWindowFocus();
	const janelaAtivaRef = useRef();

	const navigate = useNavigate();
	let query = useQuery();

	const [showPortalPerfilCompleto, setShowPortalPerfilCompleto] = useState(false);
	const [showPortalTextoBriefing, setShowPortalTextoBriefing] = useState(false);
	const [showPortalEquipeProjeto, setShowPortalEquipeProjeto] = useState(false);
	const [stateIdUsuarioPerfilCompleto, setStateIdUsuarioPerfilCompleto] = useState(false);

	// Popup
	const [showPopupAlterarStatus, setShowPopupAlterarStatus] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateEmpresa, setStateEmpresa] = useState({});
	const [_retGetTarefa, _setRetGetTarefa] = useState(null);
	const [loadingGetTarefa, setLoadingGetTarefa] = useState(true);

	const [loadingEnviarPdfOuXml, setLoadingEnviarPdfOuXml] = useState(false);

	const [showPortalMensagemBotaoProposta, setShowPortalMensagemBotaoProposta] = useState(false);

	const queryIdEmpresaRef = useRef(Number(query.get('id_task')));
	const queryIdFreelancerRef = useRef(Number(query.get('id_freelancer')));

	const _stateProposta = {
		sim: {
			...JOB_MENSAGENS_PADROES.propostaAprovada,
			tipo: 'verde',
			resposta: true,
		},
		nao: {
			...JOB_MENSAGENS_PADROES.propostaRecusada,
			tipo: 'vermelho',
			resposta: false,
		},
	}
	const [stateProposta, setStateProposta] = useState({
		..._stateProposta.sim,
		idProposta: false,
	});

	const statePropostaRef = useRef(stateProposta);

	// Briefing
	const [loadingGetStatusTarefa, setLoadingGetStatusTarefa] = useState(false);
	const [loadingAlterarStatusTarefa, setLoadingAlterarStatusTarefa] = useState(false);
	const [stateStatusTarefa, setStateStatusTarefa] = useState([]);

	const [taxaPorcentagem, setTaxaPorcentagem] = useState(0);

	/*
	** Chat
	** ================================================== */

	// Debug
	if(window.location.host === 'localhost:3000') {
		// localStorage.debug = 'socket.io-client:socket';
	}

	// States
	const [mensagensChat, setMensagensChat] = useState([]);
	const [mensagemChat, setMensagemChat] = useState('');
	const [usuariosOnline, setUsuariosOnline] = useState([]);
	const [isDigitandoChat, setIsDigitandoChat] = useState(false);
	const [isDigitandoInfoChat, setIsDigitandoInfoChat] = useState(false);
	const [loadingGetMensagensChat, setLoadingGetMensagensChat] = useState(false);

	const [emitirEventoMensagemLida, setEmitirEventoMensagemLida] = useState(false);
	const mensagensChatRef = useRef([]);
	const usuariosOnlineRef = useRef([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		getMensagensChat();
		getTarefa();
		getStatusBriefing();
		setTimeout(() => {
			marcarMensagemComoLida();
		}, 2000);
	}, []);

	/*
	** Chat - inicializar
	** ================================================== */
	useEffect(() => {
		if(!socket.connected) {
			socket.auth.token = store.token;
  			socket.connect();
		}
	}, []);

	/*
	** Chat - sair da sala
	** ================================================== */
	useEffect(() => {
		return () => {
			socket.emit('message', {
				data: {
					id_task: query.get('id_task'),
					id_freelancer: query.get('id_freelancer'),
				},
				context: 'TasksMessages',
				function: 'leaveRoom'
			});
		}
	}, []);

	/*
	** Alterar estado do focus
	** ================================================== */
	useEffect(() => {
		janelaAtivaRef.current = janelaAtiva;
	}, [janelaAtiva]);

	/*
	** Verifica se tem novas mensagens e emite evento
	** ================================================== */
	useEffect(() => {
		if(emitirEventoMensagemLida) {
			marcarMensagemComoLida();
		}
	}, [janelaAtiva]);

	useEffect(() => {
		if(janelaAtivaRef.current) {
			marcarMensagemComoLida();
		}
	}, [emitirEventoMensagemLida]);

	/*
	** Chat - eventos
	** ================================================== */
	useEffect(() => {

		/*
		** Digitando
		** ================================================== */
		socket.on('typingTaskMessage', e => {
			setIsDigitandoChat(true);
			setIsDigitandoInfoChat(chatFormatarDadosUsuario(e.data));
		});

		/*
		** Nova mensagem do outro usuário - insere no chat
		** ================================================== */
		socket.on('getMessage', dados => {
			setIsDigitandoChat(false);

			var mensagensAtualizadas = [...mensagensChatRef.current];
			mensagensAtualizadas.push({
				...formatarMensagemChat(dados.data),
				propria: false,
			});
			setMensagensChat(mensagensAtualizadas);
			mensagensChatRef.current = mensagensAtualizadas;
			setEmitirEventoMensagemLida(true);

			if(!janelaAtivaRef.current) {
				var foto;
				var nome;

				if(dados.data.id_user) {
					foto = caminhoFoto(dados.data.photo_user).url;
					nome = dados.data.name_user;
				} else {
					if(dados.data.name_user_freelancer) {
						nome = dados.data.name_user_freelancer;
						foto = caminhoFoto(dados.data.photo_user_freelancer).url;
					} else {
						nome = dados.data.name_freelancer;
						foto = caminhoFoto(dados.data.photo_user_freela).url;
					}
				}

				if(dados.data.type === 'imagem-url') {
					notificacao(nome, {
						body: `${nome} enviou uma imagem`,
						icon: dados.data.metadata.imagem.preview
					});
				} else if(dados.data.type === 'nota-fiscal-xml' || dados.data.type === 'nota-fiscal-pdf') {
					notificacao(nome, {
						body: `${nome} enviou uma nota fiscal.`,
						icon: foto
					});
				} else {
					notificacao(nome, {
						body: dados.data.text && dados.data.text !== '' ? dados.data.text : 'Você recebeu uma nova mensagem.',
						icon: foto
					});
				}
			}
		});

		/*
		** Própria mensagem enviada e cadastrada, insere no chat
		** ================================================== */
		socket.on('insertMessage', dados => {
			if(dados.success) {
				var mensagensAtualizadas = [...mensagensChatRef.current];
				mensagensAtualizadas.push({
					...formatarMensagemChat(dados.data),
					propria: true,
				});
				setMensagensChat(mensagensAtualizadas);
				mensagensChatRef.current = mensagensAtualizadas;
			} else {
				msg.error(dados.message, {
					autoClose: false,
				});
			}
		});

		/*
		** Usuário conectado, exibe a imagem no topo do chat
		** ================================================== */
		socket.on('joinChatTask', e => {
			const _usuariosOnline = chatSalaAddUsuarioOnline(e.data, usuariosOnlineRef.current);
			setUsuariosOnline(_usuariosOnline);
			usuariosOnlineRef.current = _usuariosOnline;
		});

		/*
		** Usuário desconectado, remove da sala
		** Remove apenas se desconectar para não dar a impressão
		** que ele ficou offline
		** ================================================== */
		socket.on('disconnected', e => {
			const _usuariosOnline = chatSalaRemoverUsuarioOnline(e.data, usuariosOnlineRef.current);
			setUsuariosOnline(_usuariosOnline);
			usuariosOnlineRef.current = _usuariosOnline;
		});

		/*
		** Mensagens lidas
		** ================================================== */
		socket.on('messagesReaded', dados => {
			if(dados.data.id_user !== store.usuario.id) {
				const mensagensLidas = mensagensChatRef.current.map(val => {
					return {
						...val,
						mensagemLida: true,
					}
				});
				setMensagensChat(mensagensLidas);
				mensagensChatRef.current = mensagensLidas;
			}
		});

		/*
		** Se conecta na sala para receber as mensagens
		** ================================================== */
		socket.emit('message', {
			data: {
				id_task: query.get('id_task'),
				id_freelancer: query.get('id_freelancer'),
			},
			context: 'TasksMessages',
			function: 'joinRoom'
		});

		/*
		** Verifica quem está online ao se conectar na sala
		** ================================================== */
		socket.emit('message', {
			data: {
				id_task: query.get('id_task'),
				id_freelancer: query.get('id_freelancer'),
			},
			context: 'TasksMessages',
			function: 'getUsersConnected'
		});

		/*
		** Recebe quem está conectado na
		** ================================================== */
		socket.on('usersConnected', async dados => {
			const _usuariosOnline = chatSalaAddUsuariosOnline(dados.data, {
				id_user: store.usuario.id,
			});
			setUsuariosOnline(_usuariosOnline);
			usuariosOnlineRef.current = _usuariosOnline;
		});

		/*
		** Proposta aceita
		** ================================================== */
		socket.on('acceptedPropose', async dados => {
			if(dados.success) {
				confirmacaoDeRespostaMensagemChat(dados);

				const idEmpresa = query.get('id_task')
				const retGetTarefa = await apiTasks.getOne.get(idEmpresa);
				if(retGetTarefa.status === 200) {
					setStateEmpresa(retGetTarefa.data);
				}
			} else {
				msg.error(dados.error);
			}
		});

		/*
		** Proposta recusada
		** ================================================== */
		socket.on('refusedPropose', dados => {
			if(dados.success) {
				confirmacaoDeRespostaMensagemChat(dados);
			} else {
				msg.error(dados.error);
			}
		});

	}, [socket]);

	/*
	** Após enviar a proposta, envia a mensagem no chat
	** ================================================== */
	const confirmacaoDeRespostaMensagemChat = (dados) => {
		if(dados.user.id_user === store.usuario.id) {
			const mensagensComBotaoOculto = mensagensChatRef.current.map(val => {
				if(val && val.id && statePropostaRef.current.params && val.id === statePropostaRef.current.params.id) {
					if(statePropostaRef.current.resposta) {
						val.contracted = true;
					} else {
						val.refused = true;
					}
				}
				return val;
			});

			setMensagensChat(mensagensComBotaoOculto);
			mensagensChatRef.current = mensagensComBotaoOculto;

			// Envia mensagem de confirmação
			const dadosEnviar = {
				data: {
					id_freelancer: queryIdFreelancerRef.current,
					id_task: queryIdEmpresaRef.current,
					// active: 0,
					text: statePropostaRef.current.mensagem,
					readed: 0,
					id_user: store.usuario.id,
					metadata: {
						titulo: statePropostaRef.current.titulo,
						idProposta: statePropostaRef.current.idProposta,
					},
					type: statePropostaRef.current.resposta ? 'verde' : 'vermelho',
				},
				context: 'TasksMessages',
				function: 'postMessage'
			};

			socket.emit('message', dadosEnviar);
		}
	}

	/*
	** API - Get Dados da tarefa
	** ================================================== */
	const getTarefa = async () => {
		const idEmpresa = query.get('id_company');
		if(!idEmpresa) {
			msg.error('O id da empresa deve ser informado.');
			return;
		}

		setLoadingGetTarefa(true);
		const retGetTarefa = await apiCompany.getOne.get(idEmpresa);

		if(retGetTarefa.status === 200) {
			setStateEmpresa(retGetTarefa.data);
			_setRetGetTarefa(200);
		} else if(retGetTarefa.status === 403) {
			setStateEmpresa({});
			_setRetGetTarefa(403);
		} else {
			setStateEmpresa([]);
			_setRetGetTarefa(retGetTarefa.status);
			msgs([retGetTarefa]);
		}
		setLoadingGetTarefa(false);
	}


	/*
	** API - Get mensagens
	** ================================================== */
	const getMensagensChat = async () => {

		setMensagensChat([]);
		mensagensChatRef.current = [];
		setLoadingGetMensagensChat(false);

		return;

		setLoadingGetMensagensChat(true);

		/*
		** Get mensagens do Briefing antes das mensagens do chat
		** ================================================== */
		const retGetMensagensBriefing = await apiBriefingMessages.all.get({
			id_briefing: query.get('id_briefing'),
			id_freelancer: query.get('id_freelancer'),
			limit: 9999
		});

		var mensagensFormatadas = [];

		if(retGetMensagensBriefing.status === 200) {
			mensagensFormatadas = retGetMensagensBriefing.data.map(val => {
				var propria = false;
				if(store.usuario.id === val.id_user) {
					if(val.type !== 'proposta') {
						propria = true;
					}
				}
				return {
					...formatarMensagemChat(val),
					propria: propria,
				};
			})
		} else if(retGetMensagensBriefing.status === 403) {
			mensagensFormatadas = [formatarMensagemChat({
				created_at: null,
				metadata: {
					titulo: 'Aviso',
					tipo: 'vermelho',
					icone: 'error',
				},
				text: 'Você não tem permissão para acessar as mensagens referente ao processo seletivo deste orçamento.',
				type: 'sistema',
			})];
		}

		/*
		** Get mensagens do chat atual
		** ================================================== */
		const retGetMensagens = await apiTasksMessages.all.get({
			id_task: query.get('id_task'),
			limit: 9999
		});

		if(mensagensFormatadas.length) {
			mensagensFormatadas.push(formatarMensagemChat({
				created_at: null,
				metadata: {
					titulo: 'Aviso',
					tipo: 'default',
				},
				text: 'As mensagens acima são referentes as "Propostas de jobs". As mensagens referentes ao "Job" serão listadas abaixo (caso houver).',
				type: 'sistema',
			}));
		}

		if(retGetMensagens.status === 200) {
			mensagensFormatadas.push(...retGetMensagens.data.map(val => {
				var propria = false;
				if(store.usuario.id === val.id_user) {
					if(val.type !== 'proposta') {
						propria = true;
					}
				}
				return {
					...formatarMensagemChat(val),
					propria: propria,
				};
			}));
			marcarMensagemComoLida();
		}

		setMensagensChat(mensagensFormatadas);
		mensagensChatRef.current = mensagensFormatadas;
		setLoadingGetMensagensChat(false);
	}

	/*
	** Marcar mensagem como lida
	** ================================================== */
	const marcarMensagemComoLida = (dados = {}) => {
		const dadosMensagem = {
			id_task: query.get('id_task'),
			id_freelancer: query.get('id_freelancer'),
			...dados,
		};

		const dadosEnviar = {
			data: dadosMensagem,
			context: 'TasksMessages',
			function: 'readingMessages'
		};

		socket.emit('message', dadosEnviar);
		setEmitirEventoMensagemLida(false);
	}

	// API - Enviar mensagem
	const enviarMensagemChat = async (dadosObj = {}) => {
		const mensagensNovas = [...mensagensChat];
		mensagensNovas.push({
			id: hash(),
			data: moment().format('HH:mm'),
			mensagem: dadosObj.mensagem,
			metadata: {},
			mensagemLida: true,
			name: store.usuario.name,
			photo: caminhoFoto(store.usuario.photo).url,
			msgBy: 'hunter',
			type: dadosObj.type ? dadosObj.type : 'default',
			propria: true,
			created_at: moment().format(),
			...(dadosObj.metadata ? {metadata: dadosObj.metadata} : {}),
		});

		setMensagensChat(mensagensNovas);
		setMensagemChat('');

		return;

		var texto = '';

		if(dadosObj.mensagem) {
			texto = dadosObj.mensagem;
		} else {
			texto = mensagemChat;
		}

		if(!texto || texto === '' || texto.trim() === '') {
			if(dadosObj.type !== 'proposta') {
				return;
			}
		}

		const dadosEnviar = {
			data: {
				id_freelancer: Number(query.get('id_freelancer')),
				id_task: Number(query.get('id_task')),
				id_user: store.usuario.id,
				// active: 0,
				text: texto,
				readed: 0,
				type: dadosObj.type ? dadosObj.type : 'default',
				...(dadosObj.metadata ? {metadata: dadosObj.metadata} : {}),
			},
			context: 'TasksMessages',
			function: 'postMessage'
		};

		socket.emit('message', dadosEnviar);
		setMensagemChat('');
	}

	const digitandoChat = () => {
		socket.emit('message', {
			data: {
				id_freelancer: query.get('id_freelancer'),
				id_task: query.get('id_task'),
			},
			context: 'TasksMessages',
			function: 'userTyping',
		});
	}

	/*
	** Mensagem - clicar no botão de ação
	** ================================================== */
	const onClickBtnRespostaProposta = dados => {
		if(dados.params.contracted || dados.params.refused) {
			msg.warning('Essa proposta já foi respondida anteriormente.');
			return;
		}

		const dadosProposta = {
			...dados,
			...(dados.resposta ? {
				titulo: _stateProposta.sim.titulo,
				mensagem: _stateProposta.sim.mensagem,
			} : {
				titulo: _stateProposta.nao.titulo,
				mensagem: _stateProposta.nao.mensagem,
			}),
			idProposta: dados.idProposta,
		};

		setStateProposta(dadosProposta);
		statePropostaRef.current = dadosProposta;

		setShowPortalMensagemBotaoProposta(true);
		return;
	}


	/*
	** Enviar orçamento no overlay
	** ================================================== */
	const onClickBtnEnviarRespostaProposta = () => {
		if(camposInvalidos(stateProposta, {titulo: 'Título da mensagem'}, [
			'titulo', 'mensagem', 'idProposta'
		])) {
			return;
		}

		setShowPortalMensagemBotaoProposta(false);

		if(socket.emit) {
			socket.emit('message', {
				data: {
					id_freelancer: queryIdFreelancerRef.current,
					id_task: queryIdEmpresaRef.current,
					// id_user: store.usuario.id,
					id_propose: stateProposta.idProposta,
				},
				context: 'TasksMessages',
				function: stateProposta.resposta === true ? 'acceptPropose' : 'refusePropose',
			});
		}

	}

	/*
	** API - Status do briefing
	** ================================================== */
	const getStatusBriefing = async () => {
		setLoadingGetStatusTarefa(true);
		const retGetStatus = await apiTasksCombos.status.get();
		if(retGetStatus.status === 200) {
			setStateStatusTarefa([...retGetStatus.data.map(val => {
				return {
					label: val.name,
					value: val.id,
				}
			})]);
		} else {
			msgs([retGetStatus]);
			setStateStatusTarefa([]);
		}
		setLoadingGetStatusTarefa(false);
	}

	return (
		<div className="conteudo-box tela-detalhesjob padding-10">

			<div className="flex flex-center-v flex-between padding-t-5 padding-b-25">
				<div className="flex flex-center-v">
					<h3 className="margin-0">Chat com a empresa</h3>
				</div>
				<div className="flex flex-center-v">
					<div className="flex flex-center-v">
						<TextoIconeLink
							icone={imgIconeVoltar}
							texto="Empresas"
							className="fs-14 cursor-pointer color-blue-hover transition"
							onClick={() => navigate('/empresas')}
						/>
					</div>
				</div>
			</div>

			<div className="tela-empresaschat-container">
				<div className="tela-empresaschat-info-box box-default overflow">
					<div className="tela-empresaschat-info-wrap color-white align-center">
						<div className="tela-empresaschat-empresainfo text-one-line">
							<span>{stateEmpresa.name}</span>
						</div>
						<div className="tela-empresaschat-info-hunter">
							{loadingGetTarefa || _retGetTarefa !== 200 ? (
								<>
									<Foto
										tamanho="80px"
									/>
									<div className="margin-t-10">
										<TextoWrap
											titulo={loadingGetTarefa ? 'Carregando dados' : 'Dados indisponíveis'}
											subtitulo={loadingGetTarefa ? 'Aguarde...' : 'Não foi possível carregar'}
											className="fs-14 align-center"
											corAlt={2}
										/>
									</div>
								</>
							) : (
								<>
									<Foto
										imagem={stateEmpresa.id === 137 ? 'https://plataformacrowd.s3.sa-east-1.amazonaws.com/Content/images/user/088d022e-9a8f-4caf-ab9d-a82865a2d909.jpg' : `https://www.gravatar.com/avatar/${md5(stateEmpresa.email_responsible)}?d=blank`}
										tamanho="80px"
									/>
									<div className="margin-t-10">
										<TextoWrap titulo={stateEmpresa.name_responsible} subtitulo="Responsável" className="fs-14 align-center" corAlt={2} />
									</div>
								</>
							)}
						</div>
					</div>
					<div className="tela-empresaschat-info-dados relative box-txt scroll scroll-alt">

						{loadingGetTarefa ? (
							<Loading
								texto="Carregando, aguarde..."
							/>
						) : (
							_retGetTarefa === 403 ? (
								<div>
									Você não tem permissão para acessar os dados dessa conversa.
								</div>
							) : (
								_retGetTarefa !== 200 ? (
									<div>
										Não foi possível carregar as informações da conversa.
									</div>
								) : (
									<div>
										<div>
											<h3 className="margin-0">Contratos assinados</h3>
											<div className="margin-t-10">
												<div>
													<div className="sep-linha">
														<TextoIconeLink
															icone={imgIconePDF}
															iconeCor="#ad0b00"
															texto="Termos de uso da plataforma"
															desc="Assinado em: 02/04/2021"
															onClick={() => null}
														/>
													</div>
													<div className="sep-linha">
														<TextoIconeLink
															icone={imgIconePDF}
															iconeCor="#ad0b00"
															texto="Termos de pagamento"
															desc="Assinado em: 30/10/2021"
															onClick={() => null}
														/>
													</div>
													<div className="sep-linha">
														<TextoIconeLink
															icone={imgIconePDF}
															iconeCor="#ad0b00"
															texto="Datas de pagamento"
															desc="Assinado em: 12/02/2022"
															onClick={() => null}
														/>
													</div>
												</div>
											</div>
										</div>

										<div className="margin-t-30">
											<h3 className="margin-0">Dados da empresa</h3>
											<div className="margin-t-10">
												<div className="sep-linha">
													<strong>Nome: </strong>
													<span>{stateEmpresa.name}</span>
												</div>
												<div className="sep-linha">
													<strong>ID: </strong>
													<span>{stateEmpresa.id}</span>
												</div>
												<div className="sep-linha">
													<strong>Porcentagem na plataforma: </strong>
													<span>{`${stateEmpresa.percentage}%`}</span>
												</div>
												<div className="sep-linha">
													<strong>Clientes: </strong>
													<span>{stateEmpresa.qty_customers}</span>
												</div>
												<div className="sep-linha">
													<strong>Status: </strong>
													<span>{(stateEmpresa.active ? 'Ativo' : <span className="color-red">Inativo</span>)}</span>
												</div>
											</div>
										</div>

										<div className="margin-t-30">
											<h3 className="margin-0">Contato</h3>
											<div className="margin-t-10">
												<div className="sep-linha">
													<strong>Responsável: </strong>
													<span>{stateEmpresa.name_responsible}</span>
												</div>
												<div className="sep-linha">
													<strong>Telefone: </strong>
													<span>{stateEmpresa.phone}</span>
												</div>
												<div className="sep-linha">
													<strong>E-mail: </strong>
													<span><a href={`mailto:${stateEmpresa.email_responsible}`}>{stateEmpresa.email_responsible}</a></span>
												</div>
											</div>
										</div>

										<div className="margin-t-30">
											<h3 className="margin-0">Endereço</h3>
											<div className="margin-t-10">
												<div className="sep-linha">
													<strong>Logradouro: </strong>
													<span>{`${stateEmpresa.street}, Nº ${stateEmpresa.number}`}</span>
												</div>
												<div className="sep-linha">
													<strong>Bairro: </strong>
													<span>{stateEmpresa.municipal_registration || '(Não informado)'}</span>
												</div>
												<div className="sep-linha">
													<strong>Estado: </strong>
													<span>{stateEmpresa.state || '(Não informado)'}</span>
												</div>
												<div className = "sep-linha">
													<strong>CEP: </strong>
													<span>{stateEmpresa.zip_code || '(Não informado)'}</span>
												</div>
											</div>
										</div>

									</div>
								)
							)
						)}
					</div>
				</div>

				<div className="tela-empresaschat-chat-box">
					<Chat
						enviarMensagem={enviarMensagemChat}
						mensagem={mensagemChat}
						setMensagem={setMensagemChat}
						mensagens={mensagensChat}
						setDigitando={digitandoChat}
						// showTelaInicial={true}

						isDigitando={isDigitandoChat}
						setIsDigitando={setIsDigitandoChat}
						isDigitandoInfo={isDigitandoInfoChat}
						loadingGetMensagens={loadingGetMensagensChat}

						conversandoCom={usuariosOnline}
						conversandoComExibirOnline={true}
						layoutExibicao="usuario"
						// exibirBotaoProposta={false}

						onClickBtnRespostaProposta={onClickBtnRespostaProposta}
						taxaPorcentagem={taxaPorcentagem}
					/>
				</div>
			</div>

			{/* Portal informações hunter */}
			<PortalPerfil
				idFreelancer={stateIdUsuarioPerfilCompleto}
				show={showPortalPerfilCompleto}
				tipo="freelancer"
				onClose={() => {
					setShowPortalPerfilCompleto(false);
				}}
			/>


			{/* Portal Mensagem customizada */}
			<Portal
				show={showPortalTextoBriefing}
				onClose={() => {
					setShowPortalTextoBriefing(false);
				}}
				className="box-txt w-800"
			>
				{htmlToJsx(templatesBriefings[0].template)}
			</Portal>


			{/* Portal Mensagem customizada */}
			<Portal
				show={showPortalEquipeProjeto}
				onClose={() => {
					setShowPortalEquipeProjeto(false);
				}}
				className="box-txt w-800"
			>
				<div>
					<Equipe
						equipe={{
							name: 'Desenvolvimento da nova plataforma',
							id: '4875',
							client_name: 'CROWD',
							team: [
								{
									user_name: fotoAleatoria(9).nome,
									user_photo: fotoAleatoria(9).img,
									role: fotoAleatoria(9).area,
								},
								{
									user_name: fotoAleatoria(10).nome,
									user_photo: fotoAleatoria(10).img,
									role: fotoAleatoria(10).area,
								},
								{
									user_name: fotoAleatoria(11).nome,
									user_photo: fotoAleatoria(11).img,
									role: fotoAleatoria(11).area,
								},
							],
							teamMentors: [
								{
									user_name: fotoAleatoria(10).nome,
									user_photo: fotoAleatoria(10).img,
									role: fotoAleatoria(10).area,
								},
							],
							teamFreelancers: [
								{
									freelancer_name: fotoAleatoria(0, true).nome,
									photo: fotoAleatoria(0, true).img,
									area_name: fotoAleatoria(0, true).area,
								},
								{
									freelancer_name: fotoAleatoria(1, true).nome,
									photo: fotoAleatoria(1, true).img,
									area_name: fotoAleatoria(1, true).area,
								},
							],
							teamProfessionalsInvolved: [
								{
									user_name: fotoAleatoria(0).nome,
									user_photo: fotoAleatoria(0).img,
									role: fotoAleatoria(0).area,
								},
								{
									user_name: fotoAleatoria(2).nome,
									user_photo: fotoAleatoria(2).img,
									role: fotoAleatoria(2).area,
								},
							],
						}}
					/>
				</div>
				<div className="flex flex-end margin-t-50">
					<Button2
						_={{
							value: 'Fechar',
							type: 'button',
							onClick: () => {
								setShowPortalEquipeProjeto(false);
							}
						}}
					/>
				</div>
			</Portal>

			{/* Portal Mensagem customizada */}
			<Portal
				show={showPortalMensagemBotaoProposta}
				onClose={() => {
					setShowPortalMensagemBotaoProposta(false);
				}}
				className="box-txt w-900"
			>
				<div className="box-txt">
					<h3 className="margin-0">Resposta do job</h3>
					<div className="fs-14 color-1 margin-t-5">Caso necessário, você pode escrever uma mensagem informando o motivo de ter aceitado ou de ter recusado o job do freelancer.</div>

					<div className="cpnt-chatmsgcustom-dados-preview margin-t-15">
						<div className="cpnt-chatmsgcustom-dados-mensagem-box">
							<div className="cpnt-chatmsgcustom-dados-mensagem">
								<div className="wrap-5">
									<div className="input-box-alt">
										<Input2
											label="Título da mensagem"
											_={{
												value: stateProposta.titulo,
												onChange: e => {
													const dadosProposta = {
														...stateProposta,
														titulo: e.target.value
													};
													setStateProposta(dadosProposta);
													statePropostaRef.current = dadosProposta;
												}
											}}
										/>
									</div>
									<div className="input-box-alt">
										<Input2
											label="Mensagem"
											_={{
												type: 'textarea',
												value: stateProposta.mensagem,
												onChange: e => {
													const dadosProposta = {
														...stateProposta,
														mensagem: e.target.value
													};
													setStateProposta(dadosProposta);
													statePropostaRef.current = dadosProposta;
												}
											}}
										/>
									</div>
									<div className="input-box-alt">
										<div className="strong">Sua resposta:</div>
										<div className="flex margin-t-5">
											<Radio2
												label="Aceitar"
												_={{
													name: 'opcao-proposta',
													checked: stateProposta.resposta === true ? true : false,
													onChange: e => {
														const dadosProposta = {
															...stateProposta,
															..._stateProposta.sim,
															resposta: e.target.checked
														};
														setStateProposta(dadosProposta);
														statePropostaRef.current = dadosProposta;
													}
												}}
											/>
											<Radio2
												label="Recusar"
												_={{
													name: 'opcao-proposta',
													checked: stateProposta.resposta === false ? true : false,
													onChange: e => {
														const dadosProposta = {
															...stateProposta,
															..._stateProposta.nao,
															resposta: e.target.checked ? false : true,
														};
														setStateProposta(dadosProposta);
														statePropostaRef.current = dadosProposta;
													}
												}}
												wrap="margin-l-15"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="cpnt-chatmsgcustom-preview-box">
							<h3 className="margin-0">Pré visualização</h3>
							<div className="cpnt-chatmsgcustom-preview">
								<ChatMensagem
									{...{
										data: moment().subtract(0, 'minutes').format('HH:mm'),
										mensagem: stateProposta.mensagem,
										enviarMensagem: enviarMensagemChat,
										metadata: {
											titulo: stateProposta.titulo,
										},
										name: store.usuario.name,
										photo: caminhoFoto(store.usuario.photo).url,
										type: stateProposta.resposta === true ? 'verde' : 'vermelho',
										propria: false,
									}}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-end margin-t-50">
					<Button2
						_={{
							value: 'Cancelar',
							type: 'button',
							onClick: () => {
								setShowPortalMensagemBotaoProposta(false);
							},
							className: 'btn btn-1 margin-l-10',
						}}
					/>
					<Button2
						_={{
							value: stateProposta.resposta === true ? 'Aprovar proposta' : 'Recusar proposta',
							type: 'button',
							onClick: onClickBtnEnviarRespostaProposta,
							className: `btn margin-l-10 ${stateProposta.resposta === true ? 'btn-green' : 'btn-red'}`,
						}}
					/>
				</div>
			</Portal>

		</div>
	);
};

export default EmpresasChat;