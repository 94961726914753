import { io } from 'socket.io-client';

import { URL_API_SOCKET } from '@_config/config';
import { isLogado } from '@_func/auth';

const socket = io(URL_API_SOCKET, {
	path: '/socket',
	transports: ['websocket', 'polling'],
	auth: {
		token: isLogado()?.token
	},
	// logLevel: 3,
	// debug: true,
	// secure: false,
});

export default socket;
