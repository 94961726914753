import React, { useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import c from './Radio.module.scss';

export const Radio = props => {
	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
		label,
		show = true,
		_,
	} = props;

	const { checked } = _;

	if(!show) {
		return <></>;
	}

	return (
		<label className={`${className} ${c['container']}`}>
			<input type="radio" {..._} />
			<div className={`${c['radio']} ${checked ? c['radio-checked'] : c['radio-unchecked']}`} />
			{label && <div className={`${c['label']}`}>{label}</div>}
		</label>
	);
}
