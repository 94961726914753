import { validarRetorno, config } from '@_api/axios';
import api from 'axios';
import qs from 'qs';

const apiProfessions = {
	insert: {
		post: async dados => {
			return await api.post(`/professions/insert`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	update: {
		put: async dados => {
			return await api.put(`/professions/update`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getOne: {
		get: async id => {
			return await api.get(`/professions/getOne/${id}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	all: {
		get: async (dadosObj) => {
			var dadosEnviar = qs.stringify(dadosObj, { encode: false });
			return await api.get(`/professions/all?${dadosEnviar}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	delete: async (id) => {
		return await api.get(`/professions/${id}`, config())
		.then(retorno => {
			return validarRetorno(retorno);
		})
		.catch(retorno => {
			return validarRetorno(retorno);
		});
	}
};

export { apiProfessions };