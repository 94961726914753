import React, { useCallback } from 'react';
import ReactWebcam from 'react-webcam';

// Imagens
import Button2 from "@cpnt/Button2/Button2";
import { cssBg, msg } from "@func/utils";
import imgIconeCamera from '@img/icones/geral/camera.png';

const WebcamFoto = props => {

	const webcamFotoRef = React.useRef(null);

	const {
		onClickCancelar,
		setStateFotoCapturada,
	} = props;

	/*
	** FOTO
	** ================================================== */
	const onClickCapturarFoto = useCallback(e => {
		const imgCapturada = webcamFotoRef && webcamFotoRef.current && webcamFotoRef.current.getScreenshot();
		if(!imgCapturada) {
			msg.error('Você precisa permitir o acesso a câmera para poder tirar foto. Caso você tenha bloqueado, desbloqueie e tente tirar a foto novamente.', {
				autoClose: 10000
			});
		} else {
			msg(
				<div>
					<h3 className="align-center margin-0">Foto capturada com sucesso!</h3>
					<div className="margin-t-15 img-shadow-radius">
						<img src={imgCapturada} alt="foto" className="block w-100p" />
					</div>
					<div className="margin-t-10 align-center">Para tirar outra foto, clique no botão <strong>Capturar foto</strong> novamente.</div>
				</div>,
				{
					autoClose: 10000,
				}
			)
			setStateFotoCapturada(imgCapturada);
		}
	}, [webcamFotoRef]);

	/*
	** Erro ao capturar imagem
	** ================================================== */
	const erroAoCapturarImagem = (e) => {
		if(e.name) {
			if(e.name === 'NotAllowedError') {
				msg.error('Você precisa permitir o acesso a câmera para poder tirar foto. Caso você tenha bloqueado, desbloqueie e tente tirar a foto novamente.', {
					autoClose: 10000
				});
				return;
			}
		}
		msg.error('Não foi possível identificar sua câmera');
	}

	return (
		<>
			<div className="cpnt-webcam-camera-box" style={cssBg(imgIconeCamera)}>
				<ReactWebcam
					audio={false}
					ref={webcamFotoRef}
					screenshotFormat="image/jpeg"
					width={500}
					height={375}
					onUserMediaError={erroAoCapturarImagem}
					mirrored={true}
				/>
			</div>
			<div className="form-bottom">
				<Button2
					_={{
						value: 'Fechar',
						className: 'btn btn-1',
						onClick: onClickCancelar,
					}}
				/>
				<Button2
					_={{
						value: 'Capturar foto',
						onClick: onClickCapturarFoto,
					}}
					wrap="margin-l-10"
				/>
			</div>
		</>
	);
};

export { WebcamFoto };
