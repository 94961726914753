import React from 'react';

// CSS
import './Checkbox2.css';

const Checkbox2 = props => {

	const {
		_,
		wrap = '',
		label,
		labelClass = '',
		invalid,
		smallLabel,
		req,
	} = props;

	return (
		<div className={`cpnt-checkbox2 ${wrap} ${invalid ? 'cpnt-checkbox2-invalido' : ''}`}>
			<label>
				<input
					type='checkbox'
					{..._}
				/>
				<span className="cpnt-checkbox2-check"></span>
				<span className={`cpnt-checkbox2-label ${labelClass} ${smallLabel ? 'cpnt-checkbox2-label-small' : ''}`}>{label}{req && <span className="cpnt-checkbox2-req">*</span>}</span>
			</label>
		</div>
	)
}

export default Checkbox2;