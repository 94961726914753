import React, { useState, memo } from 'react';
import _ from 'lodash';

// CSS
import './ListaProfissionais.css';

// Componentes
import { ItemProfissional } from '@cpnt/ItemProfissional/ItemProfissional';
import { PlaceholderRender } from '@cpnt/Placeholder/Placeholder';
import Portal from '@cpnt/Portal/Portal';
import { ProfissionaisAcoes } from '@cpnt/ProfissionaisSelecionados/ProfissionaisAcoes/ProfissionaisAcoes';
import { ProfissionaisSelecionados } from '@cpnt/ProfissionaisSelecionados/ProfissionaisSelecionados';
import TotalPaginacao from '@cpnt/TotalPaginacao/TotalPaginacao';

// Rotas
import { showPortal } from '@route/routeUtils';

// Funções
import { ListaProfissionaisAcoes, onClickAcoes, onClickExibirTodosSalvoELocal, onClickExibirTodosSalvos, onClickLimparListaSalva, onClickSelectListaLocal, tratarDadosAlgolia } from "./ListaProfissionaisAcoes/ListaProfissionaisAcoes";
import { PortalFoto } from "@cpnt/Portais/PortaisGerais/PortalFoto/PortalFoto";

const ListaProfissionaisMemo = props => {

	/*
	** Default
	** ================================================== */
	const {
		wrap = '',
		classNameWrap = '',
		className = '',

		/*
		** Funções de rota
		** ================================================== */
		init,
		stateGet,
		getsDefault,
		navigate,

		/*
		** Show / hide
		** ================================================== */
		showListaLocal = true,
		showAcoes = true,
		showSelect = true,

		/*
		** Loading
		** ================================================== */
		loadingGetProfissionais,

		/*
		** States
		** ================================================== */
		stateProfissionais,
		stateProfissionaisSelecionados = [],
		_stateProfissionais,
		stateProfissionaisMetadata,
		stateSelecionadosAnteriormente = [],
		setStateSelecionadosAnteriormente = () => null,

		/*
		** Lista local
		** ================================================== */
		stateListaAtual = [],
		setStateListaAtual = () => null,
		stateListas = [],
		setStateListas = () => null,
		stateSelecionados = [],
		setStateSelecionados = () => null,

		/*
		** Ações
		** ================================================== */
		selecionarUm,
		selecionarTodos,
		desselecionarTodos,
		onClickRemover,
	} = props;

	/*
	** Portais
	** ================================================== */
	const [showPortalFoto, setShowPortalFoto] = useState({show: false, foto: false});

	return (
		<>
			<div className={`cpnt-listaprof ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-listaprof-box ${className}`}>
					<div id="tabela-container">
						{showListaLocal ? (
							<ProfissionaisSelecionados
								stateGet={stateGet}
								navigate={navigate}
								classNameWrap="cpnt-profsel-sticky padding-v-5"

								stateListaAtual={stateListaAtual}
								setStateListaAtual={setStateListaAtual}
								stateListas={stateListas}
								setStateListas={setStateListas}
								stateSelecionados={stateSelecionados}
								setStateSelecionados={setStateSelecionados}
								stateSelecionadosAnteriormente={stateSelecionadosAnteriormente}
								setStateSelecionadosAnteriormente={setStateSelecionadosAnteriormente}
								onClickSelectListaLocal={e => onClickSelectListaLocal({
									dados: e,
									stateGet,
									navigate,
								})}
								onClickRemover={e => {
									onClickRemover({dados: e, stateListaAtual, setStateSelecionados})
									setStateSelecionadosAnteriormente(stateSelecionadosAnteriormente.filter(item => item.id_freelancer !== e.id));
								}}
								onClickLimparListaSalva={e => onClickLimparListaSalva({
									stateGet,
									navigate,
								})}
								onClickExibirTodosSalvoELocal={e => {
									onClickExibirTodosSalvoELocal({
										stateGet,
										navigate,
									})
								}}
								onClickExibirTodosSalvos={e => {
									onClickExibirTodosSalvos({
										stateGet,
										navigate,
									})
								}}
							/>
						) : null}

						{showAcoes ? (
							<ProfissionaisAcoes
								onClickSelectListaLocal={e => onClickSelectListaLocal({
									dados: e,
									stateGet,
									navigate,
								})}
								stateListaAtual={stateListaAtual}
								setStateListaAtual={setStateListaAtual}
								stateListas={stateListas}
								setStateListas={setStateListas}
								stateSelecionados={stateSelecionados}
								setStateSelecionados={setStateSelecionados}
								onClick={e => onClickAcoes({
									acao: e,
									selecionarTodos,
									desselecionarTodos,
									stateListaAtual,
									stateProfissionais,
									setStateSelecionados,
									showPortal,
									stateGet,
									navigate,
								})}
							/>
						) : null}

						<TotalPaginacao {...{stateGet, stateMetadata: stateProfissionaisMetadata, loading: loadingGetProfissionais, getsDefault, resultados: stateProfissionais.length, algolia: true}} />

						<div className="cpnt-listaprof-itens-box box">
							<PlaceholderRender
								loading={loadingGetProfissionais}
								init={init}
								itens={stateProfissionais}
								request={_stateProfissionais}
								cpnt="PlaceholderProfissionais"
								table={false}
								classNamePlaceholder="cpnt-listaprof-item-box box-3 box-2-1680 box-1-980"
							>
								{stateProfissionais.map((val, key) => {
									var _val = tratarDadosAlgolia({
										val,
										stateProfissionaisSelecionados,
									});

									var checked = false;
									if(stateSelecionados.length) {
										var freelancerSelecionado = _.find(stateSelecionados, {id: val.Id});
										if(freelancerSelecionado) {
											checked = true;
										}
									}

									return (
										<div className="cpnt-listaprof-item-box box-3 box-2-1680 box-1-980">
											<ItemProfissional
												key={key}
												{..._val}
												checked={checked}
												tabIndex={key}
												onClickVerPerfil={e => showPortal({portal: 'perfil-freelancer', dados: e, stateGet, navigate})}
												onClickVerFoto={e => setShowPortalFoto({foto: e, show: true})}
												onSelect={e => selecionarUm({dados: e, stateListaAtual, setStateSelecionados})}
												showSelect={showSelect}
											/>
										</div>
									)
								})}
							</PlaceholderRender>
						</div>
						<TotalPaginacao {...{stateGet, stateMetadata: stateProfissionaisMetadata, loading: loadingGetProfissionais, getsDefault, resultados: stateProfissionais.length, algolia: true}} />
					</div>
				</div>
			</div>

			<ListaProfissionaisAcoes
				stateGet={stateGet}
				navigate={navigate}
				stateListaAtual={stateListaAtual}
				setStateListaAtual={setStateListaAtual}
				stateListas={stateListas}
				setStateListas={setStateListas}
				stateSelecionados={stateSelecionados}
				setStateSelecionados={setStateSelecionados}
				setStateFreelancersSalvos={setStateSelecionadosAnteriormente}
				stateSelecionadosAnteriormente={stateSelecionadosAnteriormente}
			/>

			<PortalFoto
				show={showPortalFoto.show}
				foto={showPortalFoto.foto}
				closeOnEsc={true}
				onClose={() => {
					setShowPortalFoto({
						...showPortalFoto,
						show: false,
					});
				}}
			/>

		</>
	);
};

const ListaProfissionais = memo(ListaProfissionaisMemo);
export { ListaProfissionais };