import React from 'react';

// CSS
import './ItemUsuario.css';

// Components
import Disponibilidade from '@cpnt/Disponibilidade/Disponibilidade';

// Imagens
import imgIconeEditar from '@img/icones/geral/editar.png';
import imgUserPicDefault from '@img/userpic.png';

// Funções
import { cssBg, slugNivelAcesso, textoNivelAcesso } from '@func/utils';

const ItemUsuario = props => {
	const {
		foto,
		name,
		id,
		role_company,
		role,
		email,
		active,
		role_position,

		onClickFoto,
		onClickEditar,
	} = props;

	return (
		<div className="cpnt-itemusuario-item-wrap">
			<div className="cpnt-itemusuario-item flex flex-center-v border-blue-hover">
				<div className="cpnt-itemusuario-userpic-box">
					<div className="cpnt-itemusuario-foto-box foto-wrap" onClick={e => {
						onClickFoto(props);
					}}>
						<div className="fill bg" style={cssBg(imgUserPicDefault)}></div>
						<div className="fill bg" style={cssBg(foto)}></div>
					</div>
				</div>
				<div className="cpnt-itemusuario-info flex-1">
					<div className="cpnt-itemusuario-nome text-one-line">{name}</div>
					<div className="cpnt-itemusuario-dados fs-13 opct-7">
						<div className="cpnt-itemusuario-cargo">
							<strong>ID: </strong>
							<span>{id}</span>
						</div>
						<div className="cpnt-itemusuario-cargo">
							<strong>Papel: </strong>
							<span>{role_company || 'Não informado'}</span>
						</div>
						<div className="cpnt-itemusuario-nivel">
							<strong>Nível de acesso: </strong>
							<span className={`cpnt-itemusuario-nivel-${slugNivelAcesso(role)}`}>{textoNivelAcesso(role)}</span>
						</div>
						<div className="cpnt-itemusuario-email text-one-line">
							<strong>E-mail: </strong>
							<span>{email || 'Não informado'}</span>
						</div>
					</div>
				</div>
				<div className="cpnt-itemusuario-status">
					<Disponibilidade
						status={active}
						textoDisponivel="Ativo"
						textoIndisponivel="Inativo"
						min
					/>
				</div>
				<div className="cpnt-itemusuario-edit" onClick={e => onClickEditar(props)}>
					<img src={imgIconeEditar} alt="editar" className="block w-100p" />
				</div>
			</div>
		</div>
	);
};

export default ItemUsuario;