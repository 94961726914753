import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';

// Componentes
import Input2 from "@cpnt/Input2/Input2";
import { isSupportedCountry } from 'react-phone-number-input';

// Imagens
import logo from '@img/logo.png';
import imgSquad from '@img/outros/plataforma-squad.png';
import imgIconeSetaEsq from '@svg/padrao/arrow-left.svg';
import imgIconeSetaDir from '@svg/padrao/arrow-right.svg';
import imgIconeCadastro from '@svg/solid/circle-check.svg';

// CSS
import { DEV, EMAIL_CONTATO } from "@_config/config";
import Button2 from "@cpnt/Button2/Button2";
import Checkbox2 from "@cpnt/Checkbox2/Checkbox2";
import CriteriosSenha, { validarSenha } from "@cpnt/CriteriosSenha/CriteriosSenha";
import InputTelefone2 from "@cpnt/InputTelefone2/InputTelefone2";
import Loading from "@cpnt/Loading/Loading";
import PortalTermosDeUso from "@cpnt/Portais/PortaisTermos/PortalTermosDeUso/PortalTermosDeUso";
import { Select2 } from "@cpnt/Select2/Select2";
import TextoIconeLink from "@cpnt/TextoIconeLink/TextoIconeLink";
import Tip from "@cpnt/Tip/Tip";
import { apenasNumeros, camposInvalidos, formatarSelect, msg, sanitize, validarCep, validarCnpj, validarEmail, validarTelefonePais } from "@func/utils";
import { gets } from "@route/routeUtils";

import { Editor } from "@_cpnt/Inputs/Editor/Editor";
import InputCpfCnpj, { isCPFouCNPJ, validarCpfCnpj } from "@cpnt/InputCpfCnpj/InputCpfCnpj";
import c from './CadastroDeEmpresasLite.module.css';
import { apiGetAreas } from "@api/_functions/geral";
import { SELECT_TIPO_SERVICO, SELECT_TIPO_SERVICO_STRING, SENIORIDADE_TEMPO } from "@config/objetos";
import apiTemplates from "@api/templates/templates";
import apiCnpj from "@api/cnpj/cnpj";

import imgDestaque from '@img/outros/img-notebook-v2.png';
import { msgs, msgStatus } from "@_func/request";
import { api } from "@_api/api";

const CadastroDeEmpresasLite = () => {

	const navigate = useNavigate();
	const location = useLocation();
	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	/*
	** States
	** ================================================== */
	const camposDefault = {
		name_responsible: '',
		cnpj: '',
		email_responsible: '',
		password: '',
		send_email: 1,
		accept_terms: 0,
		name: '',
		fantasy_name: '',
		social_reason: '',
		zip_code: '',
		street: '',
		number: '',
		complement: '',
		district: '',
		city: '',
		state: '',
	};

	const camposDefaultProposta = {
		title: '',
		id_area: null,
		id_profession: null,
		seniority: null,
		text: '',
		_id_type: null, // Não vai para a API
		// custom_keyword: '',
		// segments: [
		// 	{
		// 		id_segment: 0,
		// 	}
		// ],
	};

	const [stateEmailExistente, setStateEmailExistente] = useState(false);
	const [stateDados, setStateDados] = useState(camposDefault);
	const [stateBriefing, setStateBriefing] = useState(camposDefaultProposta);
	const [step, setStep] = useState(1);
	const [showPortalTermos, setShowPortalTermos] = useState(false);
	const [stateCriteriosSenha, setStateCriteriosSenha] = useState([]);
	const [stateDescricaoBriefing, setStateDescricaoBriefing] = useState('');
	const [stateCPFouCNPJ, setStateCPFouCNPJ] = useState(false);
	const [stateAreas, setStateAreas] = useState([]);
	const [stateTemplates, setStateTemplates] = useState([]);
	const [stateInfoTemplate, setStateInfoTemplate] = useState({});

	const [loadingCadastrar, setLoadingCadastrar] = useState(false);
	const [loadingGetCep, setLoadingGetCep] = useState(false);
	const [loadingStep1, setLoadingStep1] = useState(false);
	const [loadingGetAreas, setLoadingGetAreas] = useState(false);
	const [loadingGetTemplates, setLoadingGetTemplates] = useState(false);

	/*
	** Telefone
	** ================================================== */
	const [stateTelefone, setStateTelefone] = useState('+55');
	const [stateSiglaPaisSelecionado, setStateSiglaPaisSelecionado] = useState('BR');

	const MSG_EMAIL_EXISTENTE = `Já existe um cadastro com esse e-mail. Caso você esteja com problemas para acessar a plataforma, envie um e-mail para ${EMAIL_CONTATO}.`;

	/*
	** Step 1 para 2
	** ================================================== */
	const step1to2 = () => {
		if(loadingCadastrar) {
			msg.warning('Por favor, aguarde...');
			return;
		}
		if(stateEmailExistente) {
			msg.error(MSG_EMAIL_EXISTENTE, {
				autoClose: 15000,
			});
			return;
		}
		stateDados.phone = stateTelefone;

		if(camposInvalidos(stateDados, {
			name_responsible: 'Nome',
			cnpj: 'CPF / CNPJ',
			email_responsible: 'E-mail',
			password: 'Senha de acesso',
		}, [
			'name_responsible',
			'cnpj',
			'email_responsible',
			'phone',
			'password',
		])) return;

		if(!validarCpfCnpj(stateDados.cnpj)) return;
		if(!validarEmail(stateDados.email_responsible)) return;
		if(!validarTelefonePais(stateDados.phone)) return;

		if(!validarSenha(stateDados.password).valido) {
			msg.error(
				<>
					<div>A senha inserida não atende as critérios necessários:</div>
					<div className="margin-t-10">
						<ul className="margin-0 padding-l-15 padding-t-10">
							<li>No mínimo 8 caracteres</li>
							<li>No mínimo 1 letra maiúscula</li>
							<li>No mínimo 1 dígito</li>
							<li>No mínimo 1 caractere especial</li>
						</ul>
					</div>
				</>
			);
			return;
		}

		if(!stateDados.accept_terms) {
			msg.error('Você deve aceitar os termos e condições para realizar seu cadastro.');
			return;
		}

		setStateCPFouCNPJ(isCPFouCNPJ(stateDados.cnpj));

		if(!DEV) {
			TagManager.dataLayer({
				dataLayer: {
					event: 'cadastroContrateFreelancer',
					userDetails: {
						id: 'cadastro-de-empresas-lite',
					}
				}
			});
		}

		setStep(2);
	}

	/*
	** Step 2 para 3
	** ================================================== */
	const step2to3 = () => {
		if(loadingCadastrar) {
			msg.warning('Por favor, aguarde...');
			return;
		}

		var validar = [];

		if(stateCPFouCNPJ === 'cnpj') {
			validar = [
				'name',
				'social_reason',
				'zip_code',
				'street',
				'number',
				'district',
				'city',
				'state',
			];
		} else {
			validar = [
				'zip_code',
				'street',
				'number',
				'district',
				'city',
				'state',
			];
		}

		if(camposInvalidos(stateDados, {}, validar)) return;

		if(!validarCep(stateDados.zip_code)) return;

		if(!stateAreas.length) {
			apiGetAreas({setLoadingGetAreas, setStateAreas});
		}

		setStep(3);
	}

	/*
	** Step 2 para 3
	** ================================================== */
	const step3toFinal = async () => {

		if(loadingCadastrar) {
			msg.warning('Por favor, aguarde...');
			return;
		}

		const dadosEnviar = {
			name: stateDados.name,
			name_responsible: stateDados.name_responsible,
			email_responsible: stateDados.email_responsible,
			password: stateDados.password,
			phone: stateDados.phone,
			cnpj: stateDados.cnpj,
			fantasy_name: stateDados.fantasy_name,
			social_reason: stateDados.social_reason,
			street: stateDados.street,
			district: stateDados.district,
			zip_code: stateDados.zip_code,
			state: stateDados.state,
			city: stateDados.city,
			number: stateDados.number,
			complement: stateDados.complement,
			accept_terms: stateDados.accept_terms ? 1 : 0,
			send_email: 1,

			// Dados do briefing
			briefing: {
				// ...stateBriefing,
				title: stateBriefing.title,
				id_area: stateBriefing.id_area,
				id_profession: stateBriefing.id_profession,
				seniority: stateBriefing.seniority,
				text: sanitize(stateDescricaoBriefing),
			}
		};

		if(isCPFouCNPJ === 'cpf') {
			dadosEnviar.name = dadosEnviar.name_responsible;
		}

		if(camposInvalidos(dadosEnviar.briefing, {
			title: 'Título da proposta',
			id_area: 'Área de interesse',
			id_profession: 'Se precisa de um profissional ou serviço',
			seniority: 'Senioridade',
			text: 'Descrição do briefing',
		}, [
			'title',
			'id_area',
			'id_profession',
			'seniority',
			'text',
		])) return;

		setLoadingCadastrar(true);

		const retInsertEmpresa = await api.companyLite.insert.post(dadosEnviar);

		if(retInsertEmpresa.status === 200) {
			msg.success('Cadastro realizado com sucesso!');
			navigate('/cadastro-de-empresas-lite/ativacao', dadosEnviar);
		} else {
			msgs([retInsertEmpresa]);
		}

		setLoadingCadastrar(false);
	}

	const onBlurValidarCpfCnpj = async (val) => {
		if(val === '') return;
		validarCpfCnpj(val);

		var tipo = isCPFouCNPJ(val);
		if(tipo === 'cnpj') {
			getCnpj(val);
		}
	}

	const onBlurValidarEmail = async (val) => {
		if(val === '') return;
		if(!validarEmail(val)) return;

		setLoadingStep1(true);
		setStateEmailExistente(false);

		const retCnpjCadastrado = await api.companyLite.validation.post({
			email: val,
		});

		if(retCnpjCadastrado.status === 400) {
			msg.error(MSG_EMAIL_EXISTENTE, {
				autoClose: 15000,
			});
			setStateEmailExistente(true);
			setLoadingStep1(false);
		} else {
			msgs([retCnpjCadastrado]);
			setLoadingStep1(false);
		}
	}

	const onBlurValidarCep = async (val) => {
		if(val === '') return;
		if(!validarCep(val)) return;

		setStateDados({
			...stateDados,
			street: '',
			district: '',
			city: '',
			state: '',
		});

		setLoadingGetCep(true);

		const retGetCEP = await api.cep.get(val);

		if(retGetCEP.status === 200) {
			msg.success('CEP localizado com sucesso');
			setStateDados({
				...stateDados,
				street: retGetCEP.data.street,
				district: retGetCEP.data.district,
				city: retGetCEP.data.city,
				state: retGetCEP.data.uf,
			});
		} else {
			msgs([retGetCEP]);
		}

		setLoadingGetCep(false);
	}

	const getTemplate = async (opcao) => {
		setLoadingGetTemplates(true);

		const retTemplate = await api.companyLite.professions.get({
			id_area: stateBriefing.id_area,
			type: opcao,
		});

		// const retTemplate = await api.professions.all.get({
		// 	id_area: stateBriefing.id_area,
		// 	type: opcao,
		// 	limit: 999,
		// });

		if(retTemplate.status === 200) {
			setStateTemplates(formatarSelect({
				dados: retTemplate.data,
				keyValue: 'id',
				keyName: 'title',
			}));
		} else {
			msgs([retTemplate]);
		}

		setLoadingGetTemplates(false);

	}

	/*
	** API - CNPJ
	** ================================================== */
	const getCnpj = async cnpj => {
		if(cnpj === '') return;

		setLoadingStep1(true);
		const retGetCNPJ = await apiCnpj.get(apenasNumeros(cnpj));
		if(retGetCNPJ.status === 200) {
			msg.success('CNPJ validado com sucesso.');
			var cep = retGetCNPJ?.data?.cep || '';
			cep = cep.replace('.', '');
			var dados = {
				...stateDados,
				social_reason: retGetCNPJ.data.nome,
				name: retGetCNPJ.data.fantasia,
				zip_code: cep,
				street: retGetCNPJ.data.logradouro,
				number: retGetCNPJ.data.numero,
				complement: retGetCNPJ.data.complemento,
				district: retGetCNPJ.data.bairro,
				city: retGetCNPJ.data.municipio,
				state: retGetCNPJ.data.uf,
			};
			setStateDados(dados);
		} else {
			// msg.warning('Não foi possível carregar automaticamente os dados da sua empresa, mas não se preocupe, você poderá preencher os campos manualmente no passo seguinte.', {
			// 	autoClose: 6000
			// });
			// msgs([retGetCNPJ]);
		}
		setLoadingStep1(false);
	}

	const paginacao = () => {
		if(DEV) {
			if(step === 3) {
				return (
					<div className="align-center margin-t-10"><span onClick={() => navigate('/cadastro-de-empresas-lite/ativacao')}>Finalizar</span></div>
				)
			}
			return (
				<div className="flex flex-center margin-t-30">
					<span className="bg-1 padding-5" onClick={e => {setStep(step - 1)}}>Voltar</span> | <span className="bg-1 padding-5" onClick={e => {
						if((step + 1) === 3) {
							if(!stateAreas.length) {
								apiGetAreas({setLoadingGetAreas, setStateAreas});
							}
						}
						setStep(step + 1);
					}}>Próximo</span>
				</div>
			)
		}
		return '';
	}

	return (
		<>
			{step !== 3 && step !== 4 ? (
				<div className="tela-inicio-e-m">
					<div className="tela-inicio-e-wrap relative">
						<div className="tela-inicio-e-logo padding-t-30 padding-b-10 margin-auto">
							<div><img src={logo} alt="Logo CROWD" className="block w-100p max-w-350 margin-auto" /></div>
						</div>

						{/*
						** Step 1
						** ================================================== */}
						{step === 1 ? (
							<form onSubmit={e => {
								e.preventDefault();
								step1to2();
							}}>
								<h3 className="tela-inicio-e-titulo align-center">Encontre o freelancer ideal para seu projeto</h3>
								<div className="fs-13 padding-b-30 align-center">
									Já possui conta de empresa? <Link to="/login" className="no-decoration bold">Faça o login aqui.</Link>
								</div>
								<div className="flex flex-wrap relative flex-column">
									{loadingStep1 ? <Loading texto="Consultando dados, aguarde..." absolute /> : null}
									<div className="input-box box-1">
										<Input2
											label="Nome"
											req
											_={{
												value: stateDados.name_responsible,
												onChange: e => {
													setStateDados({
														...stateDados,
														name_responsible: e.target.value,
													});
												}
											}}
										/>
									</div>
									<div className="input-box box-1">
										<InputCpfCnpj
											label='CPF / CNPJ'
											req
											_={{
												value: stateDados.cnpj,
												onChange: e => {
													setStateDados({
														...stateDados,
														cnpj: e.target.value,
													})
												},
												onBlur: e => onBlurValidarCpfCnpj(e.target.value),
											}}
										/>
									</div>
									<div className="input-box box-1">
										<Input2
											label="E-mail"
											req
											_={{
												value: stateDados.email_responsible,
												onChange: e => {
													setStateDados({
														...stateDados,
														email_responsible: e.target.value,
													});
												},
												onBlur: e => onBlurValidarEmail(e.target.value),
											}}
										/>
									</div>
									<div className="input-box box-1">
										<InputTelefone2
											label="Celular (com prefixo do país)"
											req
											_={{
												value: stateTelefone,
												onChange: setStateTelefone,
												international: true,
												withCountryCallingCode: true,
												country: stateSiglaPaisSelecionado,
												defaultCountry: 'BR',
												onCountryChange: siglaPais => {
													if(isSupportedCountry(siglaPais)) {
														setStateSiglaPaisSelecionado(siglaPais);
													} else {
														setStateSiglaPaisSelecionado('');
													}
												},
												// onBlur: e => onBlurValidar(e.target.value, 'telefone'),
											}}
										/>
									</div>
									<div className="input-box box-1">
										<Tip
											titulo="Critérios da senha"
											tip={
												<Input2
													label="Senha de acesso"
													req
													_={{
														value: stateDados.password,
														type: 'password',
														autoComplete: 'off',
														onChange: e => {
															setStateDados({
																...stateDados,
																password: e.target.value,
															})
														},
													}}
													debounce={false}
												/>
											}
											classNameTipDefault=""
											info
											click
											_defaults={{
												placement: 'right-center',
											}}
										>
											<CriteriosSenha
												senha={stateDados.password}
												stateCriterios={stateCriteriosSenha}
												setStateCriterios={setStateCriteriosSenha}
											/>
										</Tip>

									</div>

									{/* Termos */}
									<div className="margin-t-10">
										<div className="padding-h-5">
											<strong>Termos</strong>
										</div>
										<div className="margin-t-0 input-box">
											<Checkbox2
												label={
													<>
														<span>Li e concordo com os</span>
														<span className="link margin-l-5" onClick={e => {
															e.preventDefault();
															setShowPortalTermos(true);
														}}>Termos e condições de empresas.</span>
													</>
												}
												_={{
													checked: stateDados.accept_terms,
													onChange: e => {
														setStateDados({
															...stateDados,
															accept_terms: e.target.checked,
														})
													}
												}}
												smallLabel
											/>
										</div>
									</div>

									<div className="margin-t-30">
										<Button2
											_={{
												value: <TextoIconeLink texto="Continuar" icone={imgIconeSetaDir} iconeCor="#FFF" reverso />,
												type: 'submit',
											}}
											wrap="w-200 margin-auto"
											classNameExtra="w-100p"
										/>
									</div>
								</div>

							</form>
						) : null}

						{/*
						** Step 2
						** ================================================== */}
						{step === 2 ? (
							<form
								className="relative"
								onSubmit={e => {
									e.preventDefault();
									step2to3();
								}}
							>
								{loadingStep1 ? <Loading texto="Consultando CNPJ, aguarde..." absolute /> : null}
								<h3 className="tela-inicio-e-titulo align-center">Revise seus dados</h3>
								<div className={`flex flex-wrap ${c['inputs-dados']}`}>

									{stateCPFouCNPJ === 'cnpj' ? (
										<>
											<div className={`input-box w-170 ${c['input-cnpj']}`}>
												<div className="input input-inativo w-100p" onClick={() => {
													msg.warning('Caso seja necessário alterar o CNPJ, retorne ao passo anterior.');
												}}>
													<span className="label ativo">CNPJ</span>
													<span>{stateDados.cnpj}</span>
												</div>
											</div>
											<div className="input-box flex-1">
												<Input2
													label="Nome Fantasia"
													req
													_={{
														value: stateDados.name,
														onChange: e => {
															setStateDados({
																...stateDados,
																name: e.target.value,
															});
														}
													}}
												/>
											</div>
											<div className="input-box flex-1">
												<Input2
													label="Razão Social"
													req
													_={{
														value: stateDados.social_reason,
														onChange: e => {
															setStateDados({
																...stateDados,
																social_reason: e.target.value,
															});
														}
													}}
												/>
											</div>
										</>
									) : (
										<>
											<div className={`input-box box-1 ${c['input-cnpj']}`}>
												<div className="input input-inativo w-100p" onClick={() => {
													msg.warning('Caso seja necessário alterar o CPF, retorne ao passo anterior.');
												}}>
													<span className="label ativo">CPF</span>
													<span>{stateDados.cnpj}</span>
												</div>
											</div>
										</>
									)}
								</div>

								<h3 className="tela-inicio-e-titulo align-center">Endereço</h3>
								<div className="flex flex-wrap relative">
									{loadingGetCep ? <Loading absolute /> : null}
									<div className="input-box w-30p box-2-980 box-1-680">
										<Input2
											label="CEP"
											req
											_={{
												value: stateDados.zip_code,
												onChange: e => {
													setStateDados({
														...stateDados,
														zip_code: e.target.value,
													});
												},
												onBlur: e => onBlurValidarCep(e.target.value),
												mask: '99999-999',
											}}
										/>
									</div>
									<div className="input-box w-70p box-2-980 box-1-680">
										<Input2
											label="Logradouro"
											req
											_={{
												value: stateDados.street,
												onChange: e => {
													setStateDados({
														...stateDados,
														street: e.target.value,
													});
												}
											}}
										/>
									</div>
									<div className="input-box w-150 box-2-980 box-1-680">
										<Input2
											label="Número"
											req
											_={{
												value: stateDados.number,
												onChange: e => {
													setStateDados({
														...stateDados,
														number: e.target.value,
													});
												}
											}}
										/>
									</div>
									<div className="input-box flex-grow box-2-980 box-1-680">
										<Input2
											label="Complemento"
											_={{
												value: stateDados.complement,
												onChange: e => {
													setStateDados({
														...stateDados,
														complement: e.target.value,
													});
												}
											}}
										/>
									</div>
									<div className="input-box box-3 box-2-980 box-1-680">
										<Input2
											label="Bairro"
											req
											_={{
												value: stateDados.district,
												onChange: e => {
													setStateDados({
														...stateDados,
														district: e.target.value,
													});
												}
											}}
										/>
									</div>
									<div className="input-box box-3 box-2-980 box-1-680">
										<Input2
											label="Cidade"
											req
											_={{
												value: stateDados.city,
												onChange: e => {
													setStateDados({
														...stateDados,
														city: e.target.value,
													});
												}
											}}
										/>
									</div>
									<div className="input-box box-3 box-2-980 box-1-680">
										<Input2
											label="Estado"
											req
											_={{
												value: stateDados.state,
												onChange: e => {
													setStateDados({
														...stateDados,
														state: e.target.value,
													});
												}
											}}
										/>
									</div>
								</div>
								<div className="input-box">
									<div className="cademp-btn-box">
										<div className={`${c['btn-nextprev-box']}`}>
											<Button2
												_={{
													value: <TextoIconeLink texto="Anterior" icone={imgIconeSetaEsq} iconeCor="#FFF" />,
													onClick: () => {
														if(loadingCadastrar) {
															msg.warning('Por favor, aguarde...');
															return;
														}
														setStep(1);
													},
													type: 'button',
												}}
												classNameExtra="w-100p"
											/>
										</div>
										<div className={`${c['btn-nextprev-box']}`}>
											<Button2
												_={{
													value: <TextoIconeLink texto="Próximo" icone={imgIconeSetaDir} iconeCor="#FFF" reverso />,
													type: 'submit',
												}}
												classNameExtra="w-100p"
											/>
										</div>
									</div>
								</div>
							</form>
						) : null}

						{paginacao()}
					</div>
				</div>
			) : null}

			{step !== 3 && step !== 4 ? (
				<div className="tela-inicio-d-alt">
					{step === 1 ? (
						<div className="fs-15">
							<h3 className="margin-t-0 margin-b-10">Uma nova era na contratação freelancer. Rápida, segura, impulsionada por IA.</h3>
							<div className="padding-v-10">
								<img src={`${imgDestaque}`} alt="imagem" className="block w-100p" />
							</div>
							<div>
								<p>Freelancers da nova economia que você pode contratar na CROWD:</p>
								<ul className="lista-check">
									<li>Desenvolvedor Full-stack</li>
									<li>Engenheiros de Machine Learning</li>
									<li>Cientistas de Dados</li>
									<li>Gestores de Tráfego Pago</li>
									<li>Social Media</li>
									<li>Redatores de Conteúdo</li>
									<li>Copywriters</li>
									<li>Editores de Vídeo</li>
									<li>Designers UX / UI</li>
									<li>Gestores de Produto Digital</li>
								</ul>
								<p>Liberdade é receber orçamentos de freelancers ideais em menos de 12 horas, com a possibilidade de contratar com segurança.</p>
							</div>
						</div>
					) : null}

					{step === 2 ? (
						<div className="fs-15">
							<h3 className="margin-t-0 margin-b-10">Match com os melhores profissionais</h3>
							<p>Nosso match de IA é feito com os melhores freelancers para o seu projeto, não é um mural de jobs como em outras plataformas.</p>
							<div>
								<img src={imgSquad} alt="plataforma-crowd" />
							</div>
							<div>
								<p>Verifique experiência profissional, amostra de trabalhos, avaliações de clientes e entreviste se necessário.</p>
							</div>
						</div>
					) : null}

				</div>
			) : null}

			{step === 3 ? (
				<div className={c['container-conteudo']}>
					<div className="tela-inicio-e-logo padding-b-10 margin-auto">
						<div><img src={logo} alt="Logo CROWD" className="block w-100p max-w-350 margin-auto" /></div>
					</div>
					<div className="fs-15 align-center">
						<h3 className="margin-b-10">Você está a 1 passo de encontrar profissionais de marketing ou tecnologia para o seu projeto.</h3>
						<p>Nossa IA analisa seu projeto e seleciona os freelancers ideais para suas necessidades. Compare propostas, converse com os candidatos e contrate o melhor para o seu projeto.</p>
					</div>

					<form
						className={c['form']}
						onSubmit={e => {
							e.preventDefault();
							step3toFinal();
						}}
					>
						<div className={c['box-l']}>
							<div>
								<h3 className="tela-inicio-e-titulo margin-t-0">Detalhe sua proposta</h3>
								<p className="fs-15 margin-b-0">Forneça algumas informações para o match perfeito:</p>
							</div>
							<Input2
								label="Título da proposta"
								req
								_={{
									value: stateBriefing.title,
									onChange: e => {
										setStateBriefing({
											...stateBriefing,
											title: e.target.value,
										});
									}
								}}
							/>
							<Select2
								label="Área de interesse"
								req
								_={{
									value: stateBriefing.id_area,
									options: stateAreas,
									onChange: e => {
										setStateBriefing({
											...stateBriefing,
											id_area: e.value,
											_id_type: null,
											id_profession: null,
										});
										setStateTemplates([]);
									},
								}}
								loading={loadingGetAreas}
							/>
							<Select2
								label="Preciso de um"
								req
								_={{
									value: stateBriefing._id_type,
									options: SELECT_TIPO_SERVICO_STRING,
									onChange: e => {

										setStateTemplates([]);
										setStateBriefing({
											...stateBriefing,
											id_profession: null,
											_id_type: e.value,
										});

										getTemplate(e.value);
									},
								}}
								loading={loadingGetTemplates}
							/>
							<Select2
								label="Escolha o serviço ou profissional"
								req
								_={{
									value: stateBriefing.id_profession,
									options: stateTemplates,
									onChange: e => {
										setStateBriefing({
											...stateBriefing,
											id_profession: e.value,
										});
										setStateInfoTemplate(e);
										setStateDescricaoBriefing(e.text);
									},
								}}
								loading={loadingGetTemplates}
							/>
							<Select2
								label="Senioridade"
								req
								_={{
									value: stateBriefing.seniority,
									options: SENIORIDADE_TEMPO,
									onChange: e => {
										setStateBriefing({
											...stateBriefing,
											seniority: e.value,
										});
									},
								}}
							/>
						</div>

						<div className={c['box-r']}>
							<h3 className="tela-inicio-e-titulo margin-t-0">Detalhe o briefing aqui</h3>
							<p className="fs-15">Esta é a mensagem que o freelancer vai receber para entender seu projeto e enviar um orçamento para você:</p>

							<Editor
								value={stateDescricaoBriefing}
								setValue={e => {
									setStateDescricaoBriefing(e);
								}}
								height={304}
							/>
						</div>

						<div className={c['box-c']}>
							<div>
								<Button2
									_={{
										value: <TextoIconeLink texto="Voltar" icone={imgIconeSetaEsq} iconeCor="#FFF" />,
										onClick: () => {
											if(loadingCadastrar) {
												msg.warning('Por favor, aguarde...');
											} else {
												setStep(2);
											}
										},
										type: 'button',
									}}
									wrap="flex-1 flex"
									classNameExtra="w-100p"
								/>
							</div>
							<div>
								<Button2
									_={{
										value: <TextoIconeLink texto="Finalizar cadastro" icone={imgIconeCadastro} iconeCor="#1cb53f" reverso />,
										type: 'submit',
									}}
									wrap="flex-1 flex"
									classNameExtra="w-100p"
									loading={loadingCadastrar}
								/>
							</div>
						</div>
					</form>
					{paginacao()}
				</div>
			) : null}

			<PortalTermosDeUso
				show={showPortalTermos}
				onClose={() => setShowPortalTermos(false)}
			/>
		</>
	);
};

export default CadastroDeEmpresasLite;