import React from 'react';

export const textoImportanciaNotaFiscal = () => {
	return (
<div className="box-txt">
<p>Você está prestes a se cadastrar na plataforma da CROWD como um profissional remoto prestador de serviços de comunicação, marketing, design, conteúdo ou tecnologia.</p>
<p>Você terá oportunidade de prestar serviços para a CROWD e para outras pequenas, médias e grandes empresas que usam a plataforma. Por isso, é necessário emitir nota fiscal pelo serviço prestado.</p>
<p>Além de transmitir credibilidade às empresas para as quais você prestará serviços, a nota fiscal é um documento importante para você ficar em dia com o Fisco. </p>
<p>Afinal, você é mais que um freela que faz renda extra informal: você é um profissional remoto comprometido com o crescimento da sua carreira e a saúde do seu negócio freelancer.</p>
<p><i>A emissão de uma nota fiscal é obrigação do prestador de serviços e comerciante em todo o território nacional, de acordo com a legislação vigente no país, sob pena de responder por crime de sonegação de impostos, com reclusão de 2 (dois) a 5 (cinco) anos, além de ser responsabilizado pelas multas conforme indicado na <a href="http://www.planalto.gov.br/ccivil_03/leis/l8137.htm" target="_blank" rel="noreferrer">Lei n. 8137</a> publicada em 27 de dezembro de 1990.</i></p>
</div>
	)
}
