import React, { useEffect, useState, useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalTemplates.css';

// API
import apiProjects from '@api/projects/projects';

// Partes
import DetalhesProjeto from '@page/Logado/Projetos/DetalhesProjeto/DetalhesProjeto';

// Componentes
import Portal from '@cpnt/Portal/Portal';
import Loading from '@cpnt/Loading/Loading';
import Info from '@cpnt/Info/Info';
import { Select2 } from '@cpnt/Select2/Select2';
import Button2 from '@cpnt/Button2/Button2';

// Funções
import { removerKeysVazias } from '@func/utils';
import apiTemplates from "@api/templates/templates";
import ListaTemplates from "@cpnt/ListaTemplates/ListaTemplates";
import Radio2 from "@cpnt/Radio2/Radio2";
import { apiGetEmpresas } from "@api/_functions/geral";
import { isNumeric } from "@_func/regex";
import { msgs, msgStatus } from "@_func/request";

const PortalTemplates = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		idTipo,
		show,
		onClose,
		closeOnEsc = false,
		onSelect,
		titulo = 'Selecione um template',
		portalClassName = 'portal-template-geral',
		showFiltros = true,
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({});
	const [_stateDados, _setStateDados] = useState({});
	const [stateTipo, setStateTipo] = useState('publicos');
	const [stateEmpresaFiltro, setStateEmpresaFiltro] = useState(store.usuario.id_company);
	const [stateEmpresas, setStateEmpresas] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setStateTipo('publicos'); // visualizacaoFreelancer precisa ser público
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		} else {
			// Tempo da animação de fechar, limpa os dados
			setTimeout(() => {
				setStateDados(false);
			}, 500);
		}
	}

	/*
	** INIT
	** ================================================== */
	const inicializarGets = async () => {
		if(show === true) {
			const retGetItem = await apiTemplates.all.get({type: idTipo, id_company: 0, limit: 999});

			if(retGetItem.status === 200) {
				setStateDados(retGetItem.data);
				_setStateDados({
					status: 200,
				});
			} else {
				setStateDados([]);
				_setStateDados(msgStatus(retGetItem));
				msgs([retGetItem]);
			}

			setLoading(false);

		}
	}

	/*
	** Get templates
	** ================================================== */
	const getTemplates = async dadosObj => {

		var dadosEnviar = {
			type: idTipo,
			...dadosObj,
			limit: 999,
		};

		if(!isNumeric(dadosEnviar.id_company)) {
			dadosEnviar = removerKeysVazias(dadosEnviar);
		}

		setLoading(true);

		const retGetTemplates = await apiTemplates.all.get(dadosEnviar);

		if(retGetTemplates.status === 200) {
			setStateDados(retGetTemplates.data);
		} else {
			setStateDados([]);
			msgs([retGetTemplates]);
		}
		setLoading(false);
	}

	return (
		<Portal
			titulo={titulo}
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{showFiltros ? (
				<div className="cpnt-template-filtro">
					<div className="padding-b-5 fs-14 bold">Tipo de template</div>
					<div className="cpnt-template-filtros-box relative">
						{loading ? (
							<Loading
								texto="Carregando dados, aguarde..."
								absolute
								alt
								bgAlt3
							/>
						) : null}
						<Radio2
							label="Públicos"
							_={{
								checked: stateTipo === 'publicos' ? true : false,
								onChange: e => {
									getTemplates({
										id_company: 0,
									});
									setStateTipo('publicos');
								}
							}}
							wrap="margin-r-20"
						/>
						<Radio2
							label="Próprios"
							_={{
								checked: stateTipo === 'proprios' ? true : false,
								onChange: e => {
									getTemplates({
										id_company: store.usuario.id_company,
									});
									setStateTipo('proprios');
								}
							}}
							wrap="margin-r-20"
						/>
						{store.usuario.role !== 1 ? (
							<Radio2
								label="Todos"
								_={{
									checked: stateTipo === 'todos' ? true : false,
									onChange: e => {
										getTemplates({
											id_company: null,
										});
										setStateTipo('todos');
									}
								}}
							/>
						) : (
							<Radio2
								label="Por empresa"
								_={{
									checked: stateTipo === 'empresa' ? true : false,
									onChange: e => {
										getTemplates({
											id_company: stateEmpresaFiltro,
										});
										setStateTipo('empresa');
										if(!stateEmpresas.length) {
											apiGetEmpresas({setLoadingGetEmpresas, setStateEmpresas})
										}
									}
								}}
							/>
						)}
					</div>
					{store.usuario.role === 1 && stateTipo !== 'publicos' ? <div className="td-desc-alt margin-t-10 padding-b-5"><strong>Aviso:</strong> caso você esteja cadastrando uma profissão pública, é importante selecionar um template público. Caso você selecionar um template próprio, as outras empresas não conseguirão visualizar o template devido as permissões de acesso.</div> : null}
					{store.usuario.role === 1 && stateTipo === 'empresa' ? (
						<Select2
							label="Empresa"
							_={{
								value: stateEmpresaFiltro,
								onChange: e => {
									getTemplates({id_company: e.value});
									setStateEmpresaFiltro(e.value);
								},
								options: stateEmpresas,
							}}
							loading={loadingGetEmpresas}
							wrap="margin-t-15"
						/>
					) : null}
				</div>
			) : null}

			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<>
					{_stateDados.status === 200 ? (
						<ListaTemplates
							scroll={true}
							titulo={false}
							tituloPesquisar="Pesquisar template"
							itens={stateDados}
							onConfirm={onSelect}
							radioName="radioFormPortalTemplate"
							btnTexto="Selecionar modelo"
							className=""
							getTemplates={getTemplates}
							closePortal={onClose}
							stateTipo={stateTipo}
							setStateTipo={setStateTipo}
						/>
					) : (
						<Info
							texto={_stateDados.msg}
							icone="erro"
						/>
					)}
				</>
			)}
		</Portal>
	);
};

export { PortalTemplates };