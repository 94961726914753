import React from 'react';

// CSS
import { HomeScreen } from "./HomeScreen/HomeScreen";

// Img
import imgIconeMatch from '@_svg/match.svg';
import imgIconeAcademy from '@fa/solid/book-open.svg';
import imgIconeBusca from '@fa/solid/magnifying-glass.svg';
import imgIconeSquad from '@fa/solid/square-caret-up.svg';

// URLs
import { URL_CROWD_ACADEMY, URL_CROWD_SQUAD } from "@_config/config";

export const HomePremiumScreen = () => {
	return (
		<HomeScreen
			boxes={[
				{
					icone: imgIconeBusca,
					titulo: 'Busca',
					desc: 'Acesse mais de 30.000 profissionais',
					link: '/buscar-profissionais',
				},
				{
					icone: imgIconeMatch,
					titulo: 'Briefing/Vaga',
					desc: 'Publique e nossa IA faz o match',
					link: '/briefing',
				},
				{
					icone: imgIconeAcademy,
					titulo: 'Academy',
					desc: 'Cursos de transformação digital',
					link: URL_CROWD_ACADEMY,
				},
				{
					icone: imgIconeSquad,
					titulo: 'Contratar Agência',
					desc: 'Para projetos que exigem um time',
					link: 'https://crowd.br.com/projeto-squad/',
				},
			]}
		/>
	);
}
