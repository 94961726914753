import React, { useState } from 'react';
import { isSupportedCountry } from 'react-phone-number-input';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';

// CSS
import './MeIndiqueUmProfissionalRemoto.css';

// Imagens
import imgFoto1 from './Images/img-1.png';
import imgFoto2 from './Images/img-2.png';
import imgFoto3 from './Images/img-3.png';

import imgIcone1 from './Images/icone-1.webp';
import imgIcone2 from './Images/icone-2.webp';
import imgIcone3 from './Images/icone-3.webp';

import imgLogoCrowd from '@img/logo.png';

// Componentes
import apiHubspot from "@api/_externo/hubspot";
import Button2 from "@cpnt/Button2/Button2";
import Checkbox2 from "@cpnt/Checkbox2/Checkbox2";
import Info from "@cpnt/Info/Info";
import Input2 from "@cpnt/Input2/Input2";
import InputTelefone2 from "@cpnt/InputTelefone2/InputTelefone2";
import Portal from "@cpnt/Portal/Portal";
import { Select2 } from '@cpnt/Select2/Select2';
import { camposInvalidos, htmlToJsx, msg } from "@func/utils";
import { PgIndUser } from "./Components/PgIndUser/PgIndUser";
import { msgs } from "@_func/request";

const MeIndiqueUmProfissionalRemoto = () => {

	const camposDefault = {
		firstname: '',
		email: '',
		phone: '',
		preciso_de_um_profissional_de___: '',
		qual_seu_orcamento_mensal_para_marketing_digital_: '',
		message: '',
		accept_terms: false,
	}
	const [stateDados, setStateDados] = useState(camposDefault);
	const [loadingEnviar, setLoadingEnviar] = useState(false);
	const [stateRetornoMensagem, setStateRetornoMensagem] = useState('');
	const [showPortalSucesso, setShowPortalSucesso] = useState(false);

	const [stateTelefone, setStateTelefone] = useState('');
	const [stateSiglaPaisSelecionado, setStateSiglaPaisSelecionado] = useState('BR');

	const scrollTo = () => {
		scroller.scrollTo('formulario', {
			duration: 800,
			delay: 0,
			smooth: 'ease',
			offset: -100,
		});
	}

	const enviarDados = async e => {
		e.preventDefault();

		if(loadingEnviar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			firstname: stateDados.firstname,
			email: stateDados.email,
			phone: stateTelefone,
			preciso_de_um_profissional_de___: stateDados.preciso_de_um_profissional_de___,
			qual_seu_orcamento_mensal_para_marketing_digital_: stateDados.qual_seu_orcamento_mensal_para_marketing_digital_,
			message: stateDados.message,
			accept_terms: stateDados.accept_terms,
		};

		if(camposInvalidos(dadosEnviar, {
			firstname: 'Nome',
			email: 'E-mail',
			phone: 'Telefone',
			preciso_de_um_profissional_de___: 'Tipo de profissional que precisa',
			qual_seu_orcamento_mensal_para_marketing_digital_: 'Qual orçamento disponível',
			accept_terms: 'Termos de uso e política de privacidade',
			message: 'Informe o que você precisa',
		}, [
			'firstname',
			'email',
			'phone',
			'preciso_de_um_profissional_de___',
			'qual_seu_orcamento_mensal_para_marketing_digital_',
			'accept_terms',
			'message',
		])) return;

		const dadosEnviarHub = {
			fields: [
				{
					name: 'firstname',
					value: dadosEnviar.firstname,
				},
				{
					name: 'email',
					value: dadosEnviar.email,
				},
				{
					name: 'phone',
					value: dadosEnviar.phone,
				},
				{
					name: 'preciso_de_um_profissional_de___',
					value: dadosEnviar.preciso_de_um_profissional_de___,
				},
				{
					name: 'qual_seu_orcamento_mensal_para_marketing_digital_',
					value: dadosEnviar.qual_seu_orcamento_mensal_para_marketing_digital_,
				},
				{
					name: 'message',
					value: dadosEnviar.message,
				},
			],
			legalConsentOptions: {
				consent: {
					consentToProcess: stateDados.accept_terms,
					text: "Li e concordo com os Termos de uso e a Política de privacidade.",
				}
			},
			context: {
				pageName: document.title,
				pageUri: window.location.href,
			}
		};

		setLoadingEnviar(true);

		const ret = await apiHubspot.sendForm.post('5c58848c-28d9-483b-aeed-54a848a7fd11', dadosEnviarHub);

		if(ret.status === 200) {
			setStateRetornoMensagem(ret.data.inlineMessage ? ret.data.inlineMessage : 'Em breve você receberá um contato do nosso time comercial.');
			setShowPortalSucesso(true);
			setStateDados(camposDefault);
		} else {
			msgs([ret]);
		}

		setLoadingEnviar(false);
	}

	return (
		<>
			<div className="pg-indique-profissional-container">
				<div className="pg-indique-profissional-conteudo-box">
					<div className="pg-indique-profissional-conteudo-inner">
						<div className="pg-ind-topo">
							<div className="pg-indique-base">
								<div className="pg-indique-base-content">
									<div className="pg-ind-menu">
										<div className="pg-ind-topo-logo">
											<Link to="/"><img src={imgLogoCrowd} alt="logo-crowd" /></Link>
										</div>
										<div className="pg-ind-topo-btn">
											<Button2
												_={{
													value: 'Orçamento',
													onClick: scrollTo
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="pg-ind-main">
							<div className="pg-indique-base">
								<div className="pg-indique-base-content">
									<div className="pg-ind-main-box">
										<div className="pg-ind-main-l box-txt">
											<p>ENCONTRE QUEM VOCÊ PRECISA PARA O SEU PROJETO</p>
											<h1>Desenvolvedores, gestores de tráfego, UX/UI designers, redatores e outros especialistas em tecnologia e marketing</h1>
											<p>Acelere sua transformação digital com os melhores profissionais remotos de marketing e tecnologia. Conectamos você com especialistas para implementar o que você precisa.</p>
											<div className="pg-ind-main-orc">
												Receba um orçamento
											</div>
										</div>
										<div className="pg-ind-main-r" id="formulario">
											<form onSubmit={enviarDados}>
												<div className="input-box">
													<Input2
														label="Nome"
														req
														_={{
															value: stateDados.firstname,
															onChange: e => {
																setStateDados({
																	...stateDados,
																	firstname: e.target.value,
																})
															}
														}}
													/>
												</div>
												<div className="input-box">
													<Input2
														label="E-mail"
														req
														_={{
															type: 'email',
															value: stateDados.email,
															onChange: e => {
																setStateDados({
																	...stateDados,
																	email: e.target.value,
																})
															}
														}}
													/>
												</div>
												<div className="input-box">
													<InputTelefone2
														label="Telefone (com prefixo do país)"
														req
														_={{
															value: stateTelefone,
															onChange: setStateTelefone,
															international: true,
															withCountryCallingCode: true,
															country: stateSiglaPaisSelecionado,
															defaultCountry: 'BR',
															onCountryChange: siglaPais => {
																if(isSupportedCountry(siglaPais)) {
																	setStateSiglaPaisSelecionado(siglaPais);
																} else {
																	setStateSiglaPaisSelecionado('');
																}
															},
														}}
													/>
												</div>
												<div className="input-box">
													<Select2
														label="Preciso de um profissional de..."
														req
														_={{
															value: stateDados.preciso_de_um_profissional_de___,
															options: [
																{value: '', label: 'Selecione'},
																{value: 'Marketing', label: 'Marketing'},
																{value: 'Tecnologia', label: 'Tecnologia'},
																{value: 'Design', label: 'Design'},
																{value: 'Conteúdo', label: 'Conteúdo'},
																{value: 'Mídia', label: 'Mídia'},
															],
															onChange: e => {
																setStateDados({
																	...stateDados,
																	preciso_de_um_profissional_de___: e.value,
																})
															},
														}}
													/>
												</div>
												<div className="input-box">
													<Select2
														label="Meu orçamento mensal previsto é de..."
														req
														_={{
															value: stateDados.qual_seu_orcamento_mensal_para_marketing_digital_,
															options: [
																{value: '', label: 'Selecione'},
																{value: 'Até R$ 3.000', label: 'Até R$ 3.000'},
																{value: 'R$ 5.000 a R$ 10.000', label: 'R$ 5.000 a R$ 10.000'},
																{value: 'R$ 10.000 a R$ 20.000', label: 'R$ 10.000 a R$ 20.000'},
																{value: 'Acima de R$ 20.000', label: 'Acima de R$ 20.000'},
															],
															onChange: e => {
																setStateDados({
																	...stateDados,
																	qual_seu_orcamento_mensal_para_marketing_digital_: e.value,
																})
															},
														}}
													/>
												</div>
												<div className="input-box">
													<Input2
														label="Informe aqui a ajuda que você precisa:"
														req
														_={{
															type: 'textarea',
															value: stateDados.message,
															onChange: e => {
																setStateDados({
																	...stateDados,
																	message: e.target.value,
																})
															}
														}}
													/>
												</div>
												<div className="input-box">
													<Checkbox2
														label={<>Li e concordo com os <a href="https://crowd.app.br/termos-de-uso" target="_blank" rel="noreferrer" className="link">Termos de uso</a> e a <a href="https://crowd.br.com/politica-de-privacidade" target="_blank" rel="noreferrer" className="link">Política de privacidade</a>.</>}
														req
														_={{
															checked: stateDados.accept_terms,
															onChange: e => {
																setStateDados({
																	...stateDados,
																	accept_terms: e.target.checked ? true : false,
																});
															},
														}}
														smallLabel
													/>
												</div>
												<div className="input-box">
													<Button2
														_={{
															value: 'Enviar',
															onClick: enviarDados,
														}}
														wrap="flex-1"
														classNameExtra="flex-1 w-100p"
														loading={loadingEnviar}
													/>
												</div>
											</form>
										</div>
									</div>
									<div className="pg-ind-main-b box-txt">
										<h4>+200 clientes atendidos. +20 mil profissionais remotos especialistas em marketing e tecnologia. 8 anos de mercado.</h4>
									</div>
								</div>
							</div>
						</div>
						<div className="pg-ind-users">
							<div className="pg-indique-base">
								<div className="pg-indique-base-content">
									<div className="pg-ind-users-inner">
										<PgIndUser
											img={imgFoto1}
											nome="Bruno Augusto"
											desc="Produtor, Adorni Films"
											texto="Eles respondem muito rápido. Isso que é incrível na ferramenta. Um dos principais benefícios da Crowd também é em relação ao valor: você consegue fugir um pouco daqueles antigos fornecedores que você tinha e busca novos."
										/>
										<PgIndUser
											img={imgFoto2}
											nome="André Brusco"
											desc="Agente Comercial, Escala 7"
											texto="O bom da Crowd é que é simples de usar. Consegui fechar jobs em menos de 1 semana e o relacionamento com o freelancer foi super aberto e super franco. Eu consigo ter um contato fácil com os meus freelancers a hora que eu quiser."
										/>
										<PgIndUser
											img={imgFoto3}
											nome="Loraine Burgard"
											desc="Sócia, Tudointhebox"
											texto="Para gente foi como se tivesse um departamento de criação inteiro ao nosso dispor. Isso é uma coisa incrível porque eu pude contratar profissionais que eu jamais poderia ter dentro, hoje, no meu escritório."
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="pg-ind-passos">
							<div className="pg-indique-base">
								<div className="pg-indique-base-content">
									<div className="pg-ind-passos-inner">
										<h2 className="pg-ind-passos-titulo">O que acontece depois que você solicita um orçamento</h2>
										<div className="pg-ind-passos-icons-box">
											<div className="pg-ind-passos-icons">
												<div className="pg-ind-passos-icon-box">
													<img src={imgIcone1} alt="icone-1" />
												</div>
												<div className="pg-ind-passos-icon-txt pg-ind-passos-icon-txt-1">
													1. Analisamos o seu pedido e entramos em contato para entender as necessidades do seu projeto.
												</div>
											</div>
											<div className="pg-ind-passos-icons">
												<div className="pg-ind-passos-icon-box">
													<img src={imgIcone2} alt="icone-2" />
												</div>
												<div className="pg-ind-passos-icon-txt pg-ind-passos-icon-txt-2">
													2. Fazemos a curadoria do profissional remoto e apresentamos a proposta adequada ao tamanho da sua necessidade.
												</div>
											</div>
											<div className="pg-ind-passos-icons">
												<div className="pg-ind-passos-icon-box">
													<img src={imgIcone3} alt="icone-3" />
												</div>
												<div className="pg-ind-passos-icon-txt pg-ind-passos-icon-txt-3">
													3. Após a aprovação da proposta, te conectamos diretamente com o profissional remoto e o Customer Success Crowd.
												</div>
											</div>
										</div>
										<div className="pg-ind-passos-icons-btn">
											<Button2
												_={{
													value: 'Receba um orçamento',
													onClick: scrollTo,
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="pg-ind-footer-bottombar">
							<div className="pg-indique-base">
								<div className="pg-indique-base-content">
									<div className="pg-ind-footer-bottombar-inner">
										<div className="pg-ind-footer-bottombar-l">
											Copyright © {(new Date().getFullYear())} CROWD Todos os direitos reservados.
										</div>
										<div className="pg-ind-footer-bottombar-r">
											<a href="https://crowd.br.com/termos-de-uso/" target="_blank" rel="noreferrer">Termos de uso</a>
											<a href="https://crowd.br.com/politica-de-privacidade/" target="_blank" rel="noreferrer">Política de privacidade</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Portal
				show={showPortalSucesso}
				onClose={() => setShowPortalSucesso(false)}
				className="w-600"
			>
				<Info
					icone="sucesso"
					texto={htmlToJsx(stateRetornoMensagem)}
				/>
			</Portal>
		</>
	);
};

export default MeIndiqueUmProfissionalRemoto;