import React, { useState, memo } from 'react';

// css
import './ChatContainer.css';

// Imagens
import imgIconeSidebar from './sidebar.svg';

// Componentes
import Chat from '../Chat';
import ChatSidebar from '../ChatSidebar/ChatSidebar';
import PortalLateral from "@cpnt/Portais/PortaisGerais/PortalLateral/PortalLateral";
import useWindowDimension from "@/_/hooks/useWindowDimensions";
import Icone from "@cpnt/Icone/Icone";

const ChatContainer = props => {
	const screen = useWindowDimension();

	const {
		className = 'cpnt-chatcontainer-box',
		classNameExtra = 'cpnt-chatcontainer-full',
		sidebarHeader,
		sidebarFooter,

		// Chat
		mensagensSidebar = [],
		mensagensInfo = {},
		onClickMensagemSidebar = () => null,
		filtroSidebar,
		onClickFiltroSidebar = () => null,
		loadingGetMensagens,
		loadingGetMensagensSidebar,

		mensagens = [],

		mensagem,
		setMensagem = () => null,

		enviarMensagem = () => null,

		setDigitando,
		isDigitando,
		setIsDigitando = () => null,
		isDigitandoInfo,
		isDigitandoSidebar,
		setIsDigitandoSidebar = () => null,
		isDigitandoSidebarInfo,
		conversandoCom = [],
		showTelaInicial,

		layoutExibicao,
		onClickBtnRespostaProposta = () => null,
		onClickInfo = () => null,

		taxaPorcentagem,
	} = props;

	const [showPortalConversas, setShowPortalConversas] = useState(false);

	const ChatSidebarRender = () => {
		return (
			<ChatSidebar
				mensagens={mensagensSidebar}
				header={sidebarHeader}
				footer={sidebarFooter}
				// onClickMensagemSidebar={onClickMensagemSidebar}
				onClickMensagemSidebar={e => {
					if(screen.width < 980) {
						setShowPortalConversas(false);
					}
					onClickMensagemSidebar(e);
				}}
				filtro={filtroSidebar}
				onClickFiltro={onClickFiltroSidebar}
				isDigitandoInfo={isDigitandoSidebarInfo}
				isDigitando={isDigitandoSidebar}
				setIsDigitando={setIsDigitandoSidebar}
				loading={loadingGetMensagensSidebar}
				mensagensInfo={mensagensInfo}
			/>
		)
	}

	return (
		<>
			<div className={`cpnt-chatcontainer ${className} ${classNameExtra}`}>
				{screen.width > 980 ? (
					<ChatSidebarRender />
				) : null}
				<Chat
					headerRight={
						screen.width < 980 ? (
							<div className="cpnt-chatcontainer-btn-res" onClick={() => setShowPortalConversas(true)}>
								<Icone
									imagem={imgIconeSidebar}
									cor="#0277BD"
									tamanho="28px"
								/>
								<div>Conversas</div>
							</div>
						) : null
					}
					classNameWrap=''
					loadingGetMensagens={loadingGetMensagens}
					mensagens={mensagens}
					mensagem={mensagem}
					setMensagem={setMensagem}
					enviarMensagem={enviarMensagem}
					setDigitando={setDigitando}
					isDigitando={isDigitando}
					setIsDigitando={setIsDigitando}
					isDigitandoInfo={isDigitandoInfo}
					conversandoCom={conversandoCom}
					layoutExibicao={layoutExibicao}
					showTelaInicial={showTelaInicial}
					onClickBtnRespostaProposta={onClickBtnRespostaProposta}
					taxaPorcentagem={taxaPorcentagem}
					onClickInfo={onClickInfo}
				/>
			</div>

			{screen.width < 980 ? (
				<PortalLateral
					show={showPortalConversas}
					onClose={() => {
						setShowPortalConversas(false);
					}}
					closeOnClickBg={true}
					className="box-txt"
					pos="left"
					classNameContainer="cpnt-chatcontainer-res"
					x={false}
				>
					<ChatSidebarRender />
				</PortalLateral>
			) : null}
		</>
	);
};

export default memo(ChatContainer);