import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './Projetos.css';

// Rota
import { clearGet, closePortal, getPortalDados, gets, initFuncExec, isShowPortal, setGet, setGetNavigate, showPortal } from '@route/routeUtils';

// API
import apiProjects from '@api/projects/projects';

// Partes
import ProjetosInfoQtd from './ProjetosInfoQtd/ProjetosInfoQtd';

// Components
import Button2 from '@cpnt/Button2/Button2';
import FotosAgrupadas from '@cpnt/FotosAgrupadas/FotosAgrupadas';
import Icone from '@cpnt/Icone/Icone';
import Input2 from '@cpnt/Input2/Input2';
import { PlaceholderRender } from '@cpnt/Placeholder/Placeholder';
import PorcentagemCirculo from '@cpnt/PorcentagemCirculo/PorcentagemCirculo';
import PorcentagemHorizontal from '@cpnt/PorcentagemHorizontal/PorcentagemHorizontal';
import PortalPerfil from '@portalGeral/PortalPerfil/PortalPerfil';
import PortalProjetosAnexos from '@portal/PortaisProjetos/PortalProjetosAnexos/PortalProjetosAnexos';
import PortalProjetosDetalhes from '@portal/PortaisProjetos/PortalProjetosDetalhes/PortalProjetosDetalhes';
import PortalProjetosEquipe from '@portal/PortaisProjetos/PortalProjetosEquipe/PortalProjetosEquipe';
import PortalProjetosFormCadastro from '@portal/PortaisProjetos/PortalProjetosFormCadastro/PortalProjetosFormCadastro';
import PortalProjetosFormEdicao from '@portal/PortaisProjetos/PortalProjetosFormEdicao/PortalProjetosFormEdicao';
import PortalProjetosSolucoes from '@portal/PortaisProjetos/PortalProjetosSolucoes/PortalProjetosSolucoes';
import { Select2 } from '@cpnt/Select2/Select2';
import { Select3Lista } from '@cpnt/Select3/Select3';
import Status from '@cpnt/Status/Status';
import Tip from '@cpnt/Tip/Tip';
import TotalPaginacao from '@cpnt/TotalPaginacao/TotalPaginacao';

// Imagens
import imgIconeAnexo from '@svg/regular/paperclip.svg';
import imgIconeEditar from '@svg/regular/pen.svg';
import imgIconePorcentagem from '@svg/regular/percent.svg';
import imgIconeStatus from '@svg/regular/play-pause.svg';

// Funções
import { dataApiParaBr, isNumeroDiferente, tamanhoTela } from '@func/utils';
import { fotosEquipeCompleta, statusProjeto } from './functionsProjetos';

// Configs
import { BtnAdd } from '@cpnt/BtnAdd/BtnAdd';
import FiltrosWrap from '@cpnt/FiltrosWrap/FiltrosWrap';
import { FILTROS_RESULTADOS, STATUS_PROJETO } from '@config/objetos';
import useWindowDimension from '@/_/hooks/useWindowDimensions';
import { apiGetCenarios, apiGetClientes, apiGetEmpresas, apiGetProdutos, apiGetProjetosCount, apiGetStatus, apiGetUsuarios, apiValidarAcesso } from '@apiGeral/geral';
import { BtnLimparFiltros } from '@cpnt/BtnLimparFiltros/BtnLimparFiltros';
import { msgs, msgStatus } from "@_func/request";
import { Btn } from "@_cpnt/Btn/Btn/Btn";

const Projetos = (props) => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	const screen = useWindowDimension();

	/*
	** Validar acesso
	** ================================================== */
	const [stateAcesso, setStateAcesso] = useState({});
	const [stateIsAcessoValido, setStateIsAcessoValido] = useState(true);

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = async (getsAtualizados = getsDefault) => {

		await apiValidarAcesso({setStateAcesso, setStateIsAcessoValido});

		initFuncExec([
			{
				func: getProjetos,
				param: getsAtualizados
			},
			{
				func: apiGetEmpresas,
				condicao: store.usuario.role === 1,
				param: {
					setLoadingGetEmpresas,
					setStateEmpresas,
				},
				init: true,
			},
			{
				func: apiGetProjetosCount,
				param: {
					id: getsAtualizados.id_company,
					setLoadingGetProjetosCount,
					setStateProjetosCount,
				},
				condicao: !init ? true : isNumeroDiferente(stateGet.id_company, getsAtualizados.id_company),
			},
			{
				func: apiGetClientes,
				param: {
					id: getsAtualizados.id_company,
					setLoadingGetClientes,
					setStateClientes,
				},
				condicao: !init ? true : isNumeroDiferente(stateGet.id_company, getsAtualizados.id_company)
			},
			{
				func: apiGetUsuarios,
				param: {
					id: getsAtualizados.id_company,
					setLoadingGetUsuarios,
					setStateUsuarios,
				},
				condicao: !init ? true : isNumeroDiferente(stateGet.id_company, getsAtualizados.id_company)
			},
			// {
			// 	func: apiGetProdutos,
			// 	param: {
			// 		setLoadingGetProdutos,
			// 		setStateProdutos,
			// 	},
			// 	init: true,
			// },
			{
				func: apiGetStatus,
				param: {
					setLoadingGetStatus,
					setStateStatus,
				},
				init: true,
			},
			{
				func: apiGetCenarios,
				param: {
					setLoadingGetCenarios,
					setStateCenarios,
				},
				init: true,
			},
		], init, location, stateLocation);
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		id_company: store.usuario.id_company,
		overlay: [],
		limit: 20,
		page: 1,

		id_user: null,
		name: '',
		product_type: null,
		scenery_type: null,
		status: null,
		id_customer: null,
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	/*
	** Loading
	** ================================================== */
	const [loadingGetProjetos, setLoadingGetProjetos] = useState(false);
	const [loadingGetProjetosCount, setLoadingGetProjetosCount] = useState(false);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);
	const [loadingGetClientes, setLoadingGetClientes] = useState(false);
	const [loadingGetUsuarios, setLoadingGetUsuarios] = useState(false);
	// const [loadingGetProdutos, setLoadingGetProdutos] = useState(false);
	const [loadingGetStatus, setLoadingGetStatus] = useState(false);
	const [loadingGetCenarios, setLoadingGetCenarios] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateProjetos, setStateProjetos] = useState([]);
	const [_stateProjetos, _setStateProjetos] = useState({});
	const [stateProjetosMetadata, setStateProjetosMetadata] = useState({});
	const [stateProjetosCount, setStateProjetosCount] = useState(false);

	/*
	** Filtros Select
	** ================================================== */
	const [stateEmpresas, setStateEmpresas] = useState([]);
	const [stateClientes, setStateClientes] = useState([]);
	const [stateUsuarios, setStateUsuarios] = useState([]);
	const [stateProdutos, setStateProdutos] = useState([]);
	const [stateCenarios, setStateCenarios] = useState([]);
	const [stateStatus, setStateStatus] = useState([]);

	// Popups
	const [loadingAlterarPorcentagem, setLoadingAlterarPorcentagem] = useState(false);
	const [loadingAlterarStatus, setLoadingAlterarStatus] = useState(false);

	const corCenario = idCenario => {
		const CORES_CENARIOS = {
			1: '#84C9F2', // Azul - Controlado
			2: '#FFC701', // Amarelo - Atenção
			3: '#BCDEA1' // Verde - Upsell
		};
		if(CORES_CENARIOS[idCenario]) {
			return CORES_CENARIOS[idCenario];
		}
		return 'transparent';
	}

	/*
	** API - Get Projetos
	** ================================================== */
	const getProjetos = async (dadosObj = {}) => {
		if(loadingGetProjetos) {
			return;
		}

		setLoadingGetProjetos(true);

		var dadosEnviar = clearGet(dadosObj);
		const retGetProjetos = await apiProjects.all.get(dadosEnviar);

		_setStateProjetos(msgStatus(retGetProjetos));

		if(retGetProjetos.status === 200) {
			setStateProjetos(retGetProjetos.data);
			setStateProjetosMetadata(retGetProjetos.metadata);
		} else {
			setStateProjetos([]);
			setStateProjetosMetadata({});
		}

		setLoadingGetProjetos(false);
	}

	/*
	** API - Alterar porcentagem do projeto
	** ================================================== */
	const alterarPorcentagemProjeto = async dados => {
		if(loadingAlterarPorcentagem) {
			msg.warning('Por favor, aguarde...');
			return false;
		}
		setLoadingAlterarPorcentagem(dados.id);

		const dadosEnviar = {
			id: dados.id,
			percentage_completed: dados.porcentagem,
		};

		// 2 = Finalizado
		if(dados.porcentagem >= 100) {
			dadosEnviar.status = 3;
		}

		// Não finalizado, volta o status para pausado
		else if(dados.porcentagem < 100) {
			if(dados.status === 3) {
				dadosEnviar.status = 1;
			}
		}

		const retUpdateProjetoPorcentagem = await apiProjects.update.put(dadosEnviar);

		if(retUpdateProjetoPorcentagem.status === 200) {
			msg.success(`Porcentagem alterada para: ${dados.porcentagem}%`);
			const projetos = stateProjetos.map((val) => {
				if(val.id === dados.id) {
					return {
						...val,
						...dadosEnviar,
					}
				}
				return val;
			});
			setStateProjetos(projetos);
		} else {
			msgs([retUpdateProjetoPorcentagem]);
		}
		setLoadingAlterarPorcentagem(false);
	}

	/*
	** API - Alterar status do projeto
	** ================================================== */
	const alterarStatusProjeto = async dados => {

		if(loadingAlterarStatus) {
			msg.warning('Por favor, aguarde...');
			return false;
		}
		setLoadingAlterarStatus(dados.id);

		const dadosEnviar = {
			id: dados.id,
			status: dados.status,
		};

		// 2 = Finalizado
		if(dados.status === 3) {
			dadosEnviar.percentage_completed = 100;
		}

		// Não finalizado, volta o status para menos de 100
		else if(dados.status === 1 || dados.status === 2) {
			if(dados.porcentagem >= 100) {
				dadosEnviar.percentage_completed = 90;
			}
		}

		const retUpdateProjetoStatus = await apiProjects.update.put(dadosEnviar);

		if(retUpdateProjetoStatus.status === 200) {
			msg.success(`Status alterado para: ${dados.nomeStatus}`);
			const projetos = stateProjetos.map((val) => {
				if(val.id === dados.id) {
					return {
						...val,
						...dadosEnviar,
					}

				}
				return val;
			})
			setStateProjetos(projetos);
		} else {
			msgs([retUpdateProjetoStatus]);
		}
		setLoadingAlterarStatus(false);
	}

	/*
	** Variaveis de acordo com o tamanho da tela
	** ================================================== */
	var winQtdFotos = tamanhoTela([
		{width: 1580, dados: 2},
		{width: 1320, dados: 1},
		{width: 1280, dados: 1},
		{width: 980, dados: 10},
		{width: 680, dados: 4},
	], 3, screen);

	var winQtdSolucoes = tamanhoTela([
		{width: 1320, dados: 1},
		{width: 1280, dados: 2},
		{width: 980, dados: 10},
		{width: 680, dados: 4},
	], 2, screen);

	var winPosTooltipDireito = tamanhoTela([
		{width: 980, dados: {placement: 'bottom-center'}},
	], {}, screen);

	return (
		<div className="pg-projetos base-full">
			<div className="conteudo-box">
				<div className="titulo-box">
					{/* <Voltar /> */}
					<div className="flex flex-center-v">
						<h3 className="titulo">Projetos</h3>
						{!loadingGetProjetosCount && stateProjetosCount ? (
							<ProjetosInfoQtd {...stateProjetosCount} />
						) : null}
					</div>
					<Btn
						_={{
							value: 'Cadastrar projeto',
							onClick: () => showPortal({portal: 'cadastro', stateGet, navigate})
						}}
						size="m"
					/>
				</div>

				<FiltrosWrap>
					<form
						onSubmit={e => e.preventDefault()}
						className="flex flex-wrap wrap-5"
					>
						<div className={`input-box box-1-980 ${store.usuario.role === 1 ? 'box-4' : 'flex-1'}`}>
							<Input2
								label="Pesquisar projeto"
								_={{
									value: stateGet.name,
									onChange: e => {
										setGetNavigate({
											gets: {name: e.target.value},
											navigate,
											stateGet,
											location,
										});
									}
								}}
							/>
						</div>
						{store.usuario.role === 1 && (
							<div className="box-4 input-box relative box-2-980 box-1-680">
								<Select2
									label="Empresa"
									_={{
										value: stateGet.id_company,
										options: stateEmpresas,
										onChange: e => {
											setGetNavigate({
												gets: {
													id_company: e.value,
													id_customer: null,
													name: '',
													product_type: null,
													status: null,
													id_user: null,
													page: 1,
													scenery_type: null,
												},
												navigate,
												stateGet,
												location,
											});
										},
									}}
									loading={loadingGetEmpresas}
								/>
							</div>
						)}
						<div className="box-4 input-box relative box-2-980 box-1-680">
							<Select2
								label="Cliente"
								_={{
									value: stateGet.id_customer,
									options: stateClientes,
									onChange: e => {
										setGetNavigate({
											gets: {id_customer: e ? e.value : null},
											navigate,
											stateGet,
											location,
										});
									},
									isClearable: true,
								}}
								loading={loadingGetClientes}
							/>
						</div>
						<div className="input-box box-4 box-2-980 box-1-680">
							<Select2
								label="Resultados por página"
								_={{
									value: stateGet.limit,
									onChange: e => {
										setGetNavigate({
											gets: {limit: e.value},
											navigate,
											stateGet,
											location,
										});
									},
									options: FILTROS_RESULTADOS,
								}}
							/>
						</div>
						<button type="submit" className="none"></button>
					</form>
					<div>
						<div className="wrap-5">
							<div className="flex flex-wrap">
								<div className="box-3 input-box relative box-2-980 box-1-680">
									<Select2
										label="Equipe"
										_={{
											value: stateGet.id_user,
											onChange: e => {
												setGetNavigate({
													gets: {id_user: e.length ? e.map(val => val.value) : false},
													navigate,
													stateGet,
													location,
												});
											},
											options: stateUsuarios,
											isMulti: true,
											isClearable: true,
										}}
										loading={loadingGetUsuarios}
									/>
								</div>
								{/* <div className="box-3 input-box relative box-2-980 box-1-680">
									<Select2
										label="Produto"
										_={{
											value: stateGet.product_type,
											onChange: e => {
												setGetNavigate({
													gets: {product_type: e ? e.value : null},
													navigate,
													stateGet,
													location,
												});
											},
											options: stateProdutos,
											isClearable: true,
										}}
										loading={loadingGetProdutos}
									/>
								</div> */}
								<div className="box-3 input-box relative box-2-980 box-1-680">
									<Select2
										label="Cenário"
										_={{
											value: stateGet.scenery_type,
											onChange: e => {
												setGetNavigate({
													gets: {scenery_type: e ? e.value : null},
													navigate,
													stateGet,
													location,
												});
											},
											options: stateCenarios,
											isClearable: true,
										}}
										loading={loadingGetCenarios}
									/>
								</div>
								<div className="box-3 input-box relative box-2-980 box-1-680 input-box-btnlimpar">
									<Select2
										label="Status"
										_={{
											value: stateGet.status,
											onChange: e => {
												setGetNavigate({
													gets: {status: e ? e.value : null},
													navigate,
													stateGet,
													location,
												});
											},
											options: stateStatus,
											isClearable: true,
										}}
										loading={loadingGetStatus}
										wrap="flex-1"
									/>
									<BtnLimparFiltros
										_={{
											onClick: e => {
												setGetNavigate({
													gets: getsDefault,
													navigate,
													stateGet,
													location,
												});
											},
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</FiltrosWrap>

				<div id="tabela-container">
					<TotalPaginacao {...{stateGet, stateMetadata: stateProjetosMetadata, loading: loadingGetProjetos, getsDefault}} />
					<div className="table-wrap">
						<table className="tabela">
							<thead className="tabela-titulo-fixo">
								<tr className="">
									<th className="col-id">
										<Tip tip="ID">ID único do projeto</Tip>
									</th>
									<th className="tbl-projetos-col-projetos">
										<Tip tip="Projeto">Nome do projeto</Tip>
									</th>
									<th className="col-time">
										<Tip tip="Time">Time que está atuando nesse projeto</Tip>
									</th>
									{/* <th className="tbl-projetos-col-produto">
										<Tip tip="Produto">Tipo de produto</Tip>
									</th> */}
									<th className="tbl-projetos-col-cenario">
										<Tip tip="Cenário">Cenário do projeto</Tip>
									</th>
									{/* <th className="col-solucoes">
										<Tip tip="Soluções">Soluções utilizadas nesse projeto</Tip>
									</th> */}
									<th className="col-data">
										<Tip tip="Início">Data de início do projeto</Tip>
									</th>
									<th className="col-data">
										<Tip tip="Término">Data prevista para o término do projeto</Tip>
									</th>
									<th className="col-min-3">
										<Tip
											tip={
												<Icone
													imagem={imgIconeAnexo}
													cor="#FFF"
													className="margin-auto"
												/>
											}
										>
											Anexos do projeto
										</Tip>
									</th>
									<th className="col-min-4">
										<Tip
											tip={
												<Icone
													imagem={imgIconeStatus}
													cor="#FFF"
													className="margin-auto"
												/>
											}
										>
											Status atual do projeto
										</Tip>
									</th>
									<th className="col-min-3">
										<Tip
											tip={
												<Icone
													imagem={imgIconePorcentagem}
													cor="#FFF"
													className="margin-auto"
												/>
											}
										>
											Porcentagem completa
										</Tip>
									</th>
									<th className="col-acao">
										<Tip
											tip={
												<Icone
													imagem={imgIconeEditar}
													cor="#FFF"
													className="margin-auto"
												/>
											}
										>
											Editar projeto
										</Tip>
									</th>
								</tr>
							</thead>
							<tbody>
								<PlaceholderRender
									loading={loadingGetProjetos}
									init={init}
									itens={stateProjetos}
									request={_stateProjetos}
									cpnt="PlaceholderProjetos"
									qtdColunas={10}
									itensQtd={{
										foto: winQtdFotos,
										solucoes: winQtdSolucoes,
									}}
								>
									{stateProjetos.map((value, index) => {
										return (
											<tr key={index} {...(value.restricted ? {className: 'tr-destaque'} : {})}>
												<td data-titulo="ID" className="col-id">
													<div className="td-wrap">
														{value.restricted ? (
															<>
																<div>{value.id}</div>
																<div className="fs-10 color-red">Restrito</div>
															</>
														) : (
															value.id
														)}
													</div>
												</td>
												<td data-titulo="Nome">
													<div className="td-wrap">
														<div
															className="a td-info"
															onClick={() => {
																showPortal({portal: 'detalhes', dados: value.id, stateGet, navigate})
															}}
														>
															<div className="td-titulo">{value.name}</div>
															<div className="td-desc">{value.client_name}</div>
														</div>
													</div>
												</td>
												<td data-titulo="Equipe">
													<div className="td-wrap align-center">
														{fotosEquipeCompleta({
															dados: value,
															onClick: () => showPortal({portal: 'equipe', dados: value.id, stateGet, navigate}),
															qtd: winQtdFotos
														})}
													</div>
												</td>
												{/* <td data-titulo="Produto">
													<div className="td-wrap align-center">
														{value.product_type_name ? (
															value.product_type_name
														) : (
															<div>-</div>
														)}
													</div>
												</td> */}
												<td data-titulo="Cenário" style={{backgroundColor: corCenario(value.scenery_type)}}>
													<div className="td-wrap align-center">
														{value.scenery_type_name ? (
															value.scenery_type_name
														) : (
															<div>-</div>
														)}
													</div>
												</td>
												{/* <td data-titulo="Soluções">
													<div className="td-wrap align-center">
														{value.solutions && value.solutions.length ? (
															<FotosAgrupadas
																key={value.photo}
																fotos={value.solutions.map(value2 => {
																	return {
																		foto: value2.img_solution,
																		desc: value2.name_solution,
																	}
																})}
																onClick={() => {
																	showPortal({portal: 'solucoes', dados: value.id, stateGet, navigate})
																}}
																circulo={false}
																placeholder={false}
																className="cursor-pointer"
																tamanho="medio"
																alt={true}
																qtd={winQtdSolucoes}
																espacoAlt={1}
															/>
														) : (
															<div className="color-1">-</div>
														)}
													</div>
												</td> */}
												<td data-titulo="Data de início">
													<div className="td-wrap align-center">{dataApiParaBr(value.start_date, '-')}</div>
												</td>
												<td data-titulo="Data final">
													<div className="td-wrap align-center">{dataApiParaBr(value.end_date, '-')}</div>
												</td>
												<td data-titulo="Anexos">
													<div className="td-wrap align-center">
														{value.attachs && value.attachs.length ? (
															<div className="a">
																<Icone
																	imagem={imgIconeAnexo}
																	cor="#555"
																	className="margin-auto cursor-pointer"
																	onClick={() => showPortal({portal: 'anexos', dados: value.id, stateGet, navigate})}
																/>
																<div className="td-desc-alt">{value.attachs.length}</div>
															</div>
														) : (
															<div>-</div>
														)}
													</div>
												</td>
												<td data-titulo="Status" className="relative">
													<div className="td-wrap scroll-visible align-center">
														<Tip
															tip={
																<div>
																	<Status
																		title="Alterar status"
																		imagem={imgIconeStatus}
																		cor="#555"
																		className="margin-auto cursor-pointer"
																		status={value.status}
																	/>
																	<div className="td-desc-alt cursor-pointer">{statusProjeto(value.status)}</div>
																</div>
															}
															click={true}
															show={loadingAlterarStatus === value.id ? true : false}
															_defaults={winPosTooltipDireito}
														>
															<Select3Lista
																titulo={<h3 className="margin-0">Status do projeto</h3>}
																options={STATUS_PROJETO}
																optionsType="radio"
																optionsChecked={{
																	value: value.status,
																}}
																onClick={e => {
																	alterarStatusProjeto({
																		id: value.id,
																		status: e.value,
																		nomeStatus: e.label,
																		porcentagem: value.percentage_completed,
																	})
																}}
																loading={loadingAlterarStatus === value.id ? true : false}
																labelClass="flex-1"
																posicao=""
																classNameDefault=""
															/>
														</Tip>
													</div>
												</td>
												<td data-titulo="Porcentagem" className="relative">
													<div className="td-wrap scroll-visible align-center">
														<Tip
															tip={
																<PorcentagemCirculo
																	porcentagem={value.percentage_completed}
																	wrap="cursor-pointer"
																/>
															}
															click={true}
															show={loadingAlterarPorcentagem === value.id ? true : false}
															_defaults={winPosTooltipDireito}
														>
															<Select3Lista
																titulo={
																	<div className="align-center">
																		<h3 className="margin-0">Porcentagem do projeto</h3>
																		<div className="desc">ID: {value.id} <span className="sep-texto"></span> Atual: {value.percentage_completed || 0}%</div>
																	</div>
																}
																options={[
																	{value: 100, label: <PorcentagemHorizontal porcentagem={100} />},
																	{value: 90, label: <PorcentagemHorizontal porcentagem={90} />},
																	{value: 80, label: <PorcentagemHorizontal porcentagem={80} />},
																	{value: 70, label: <PorcentagemHorizontal porcentagem={70} />},
																	{value: 60, label: <PorcentagemHorizontal porcentagem={60} />},
																	{value: 50, label: <PorcentagemHorizontal porcentagem={50} />},
																	{value: 40, label: <PorcentagemHorizontal porcentagem={40} />},
																	{value: 30, label: <PorcentagemHorizontal porcentagem={30} />},
																	{value: 20, label: <PorcentagemHorizontal porcentagem={20} />},
																	{value: 10, label: <PorcentagemHorizontal porcentagem={10} />},
																	{value: 0, label: <PorcentagemHorizontal porcentagem={0} />},
																]}
																optionsType="radio"
																optionsChecked={{
																	value: value.percentage_completed,
																}}
																onClick={e => alterarPorcentagemProjeto({
																	id: value.id,
																	porcentagem: e.value,
																	status: value.status,
																	nomeStatus: e.label,
																})}
																loading={loadingAlterarPorcentagem === value.id ? true : false}
																labelClass="flex-1"
																posicao=""
																classNameDefault=""
															/>
														</Tip>
													</div>
												</td>
												<td data-titulo="Ação" className="relative">
													<div className="td-wrap align-center">
														<Icone
															imagem={imgIconeEditar}
															cor="#555"
															className={`margin-auto cursor-pointer`}
															onClick={() => showPortal({portal: 'edicao', dados: value.id, stateGet, navigate})}
														/>
													</div>
												</td>
											</tr>
										)
									})}
								</PlaceholderRender>
							</tbody>
						</table>
					</div>
					<TotalPaginacao {...{stateGet, stateMetadata: stateProjetosMetadata, loading: loadingGetProjetos, getsDefault}} />
				</div>
			</div>

			<PortalProjetosFormCadastro
				show={isShowPortal({portal: 'cadastro', stateGet})}
				id={getPortalDados({portal: 'cadastro', stateGet})}
				onClose={() => closePortal({portal: 'cadastro', stateGet, navigate})}
				onSuccess={() => {
					getProjetos(gets());
					closePortal({portal: 'cadastro', stateGet, navigate});
				}}
				closeOnEsc={false}
				acessoRestrito={!stateIsAcessoValido}
			/>

			<PortalProjetosFormEdicao
				show={isShowPortal({portal: 'edicao', stateGet})}
				id={getPortalDados({portal: 'edicao', stateGet})}
				onClose={() => closePortal({portal: 'edicao', stateGet, navigate})}
				onSuccess={() => {
					getProjetos(gets());
					closePortal({portal: 'edicao', stateGet, navigate});
				}}
				closeOnEsc={false}
				acessoRestrito={!stateIsAcessoValido}
			/>

			<PortalProjetosDetalhes
				show={isShowPortal({portal: 'detalhes', stateGet})}
				id={getPortalDados({portal: 'detalhes', stateGet})}
				onClose={() => closePortal({portal: 'detalhes', stateGet, navigate})}
			/>

			<PortalProjetosEquipe
				id={getPortalDados({portal: 'equipe', stateGet})}
				show={isShowPortal({portal: 'equipe', stateGet})}
				onClose={() => closePortal({portal: 'equipe', stateGet, navigate})}
			/>

			<PortalProjetosSolucoes
				id={getPortalDados({portal: 'solucoes', stateGet})}
				show={isShowPortal({portal: 'solucoes', stateGet})}
				onClose={() => closePortal({portal: 'solucoes', stateGet, navigate})}
			/>

			<PortalProjetosAnexos
				id={getPortalDados({portal: 'anexos', stateGet})}
				show={isShowPortal({portal: 'anexos', stateGet})}
				onClose={() => closePortal({portal: 'anexos', stateGet, navigate})}
			/>

			<PortalPerfil
				idFreelancer={getPortalDados({portal: 'perfil', stateGet}).id}
				idUsuario={getPortalDados({portal: 'perfil', stateGet}).id}
				tipo={getPortalDados({portal: 'perfil', stateGet}).tipo}
				show={isShowPortal({portal: 'perfil', stateGet})}
				onClose={() => closePortal({portal: 'perfil', stateGet, navigate})}
			/>
		</div>
	);
}

export default Projetos;