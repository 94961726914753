import React from 'react';

// Libs
import { Link } from 'react-router-dom';

// CSS
import c from './BoxInfoRoot.module.scss';

//
import { linkTarget } from "@_func/url";

export const BoxInfoRoot = props => {
	const {
		className = '',
		children,
		link,
		onClick,
	} = props;

	if(onClick || !link) {
		return (
			<div className={`${className} ${c['container']}`} onClick={onClick}>
				{children}
			</div>
		);
	}

	return (
		<Link to={link} {...linkTarget(link)} className={`${className} ${c['container']}`}>
			{children}
		</Link>
	)
}
