import React, { useEffect, useState, Fragment } from 'react';
import { useLayer, useHover, Arrow } from 'react-laag';

// css
import './Tip.css';

const Tip = props => {

	const {
		tip,
		tipContainer = true,
		children,
		click,
		show,
		titulo,
		classNameDefault = 'cpnt-tip',
		classNameTitulo = '',
		classNameTipDefault = 'cpnt-tip-tip',
		classNameTip = '',
		classNameContainer = '',
		classNameConteudo = '',
		info,

		tipAttr,
		child,
		closeOnLeave = true,
		closeOnClick,
		_ = {},
		_defaults = {},
		delay = 0,
	} = props;


	// https://www.react-laag.com/
	const [isOver, hoverProps, close] = useHover({
		delayEnter: 50, // wait 300ms before showing
		delayLeave: delay, // wait 300ms before leaving
		hideOnScroll: false, // hide layer immediately when user starts scrolling
		..._,
	});

	const [isOpen, setOpen] = useState(show);

	useEffect(() => {
		if(click) {
			if(show !== isOpen) {
				setOpen(show);
			}
		}
	}, [show]);

	/*
	** Hover
	** ================================================== */
	const {
		triggerProps,
		layerProps,
		arrowProps,
		renderLayer
	} = useLayer({
		isOpen: isOver,
		overflowContainer: true,
		placement: "bottom-center",
		auto: true,
		triggerOffset: 12,
		arrowOffset: 4,
		onDisappear: (disappearType) => {
			if (disappearType === "full") {
				close();
			}
		},
		onOutsideClick: () => {
			close();
		},
		..._defaults,
	});

	/*
	** Click
	** ================================================== */
	const { renderLayer: renderLayer2, triggerProps: triggerProps2, layerProps: layerProps2, arrowProps: arrowProps2 } = useLayer({
		isOpen,
		overflowContainer: true,
		placement: "left-center",
		auto: true,
		triggerOffset: 12,
		arrowOffset: 4,
		onDisappear: (disappearType) => {
			if (disappearType === "full") {
				setOpen(false);
			}
		},
		onOutsideClick: () => {
			setOpen(false);
		},
		..._defaults,
	});

	/*
	** Click
	** ================================================== */
	if(click) {
		return (
			<>
				{tipContainer ? (
					<div {...triggerProps2} onClick={() => setOpen(!isOpen)} className={`${classNameTipDefault} ${classNameTip}`}>
						{tip}
					</div>
				) : (
					<div {...triggerProps2} onClick={() => setOpen(!isOpen)} className={`${classNameTipDefault}`}>
						{child}
					</div>
				)}
				{isOpen &&
					renderLayer2(
						<div className={`${classNameDefault} ${classNameContainer}`} {...layerProps2}>
							{titulo ? (
								<div className={`cpnt-tip-titulo ${classNameTitulo}`}><h3 className="margin-0">{titulo}</h3></div>
							) : null}
							<div className={`cpnt-tip-conteudo ${classNameConteudo} ${info ? 'cpnt-tip-conteudo-info' : ''}`} {...(closeOnClick ? {onClick: e => setOpen(false)} : {})}>{children}</div>
							<Arrow {...arrowProps2} />
						</div>
					)
				}
			</>
		)
	}

	/*
	** Hover
	** ================================================== */
	return (
		<>
			{tipContainer ? (
				<div {...triggerProps} {...hoverProps} className={`${classNameTipDefault} ${classNameTip}`}>
					{tip}
				</div>
			) : (
				<div {...tipAttr} {...triggerProps} {...hoverProps}>
					{child}
				</div>
			)}
			{isOver &&
				renderLayer(
					// <div className={`${classNameDefault} ${classNameContainer}`} {...layerProps}> // Fecha ao sair o mouse
					<div className={`${classNameDefault} ${classNameContainer}`} {...layerProps} {...(!closeOnLeave ? hoverProps : {})}>
						{titulo ? (
							<div className={`cpnt-tip-titulo ${classNameTitulo}`}><h3 className="margin-0">{titulo}</h3></div>
						) : null}
						<div className={`cpnt-tip-conteudo ${classNameConteudo} ${info ? 'cpnt-tip-conteudo-info' : ''}`} {...(closeOnClick ? {onClick: e => setOpen(false)} : {})}>{children}</div>
						<Arrow {...arrowProps} />
					</div>
				)
			}
		</>
	);
};

export default Tip;