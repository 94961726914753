import qs from 'qs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';
import useWindowFocus from '@/_/hooks/useWindowFocus';

// Textos
import chatMensagensVariaveis from '@texto/chatMensagensVariaveis';

// css
import './DetalhesJob.css';

// API
import apiBriefingMessages from '@api/briefingMessages/briefingMessages';
import apiBriefings from '@api/briefings/briefings';

// Socket
import socket from '@_api/socket';

// Componentes
import BtnLabel from '@cpnt/BtnLabel/BtnLabel';
import Button2 from '@cpnt/Button2/Button2';
import Chat from '@cpnt/Chat/Chat';
import Icone from '@cpnt/Icone/Icone';
import LinhaItem from '@cpnt/LinhaItem/LinhaItem';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';
import { iconeStatusBriefing } from '@cpnt/StatusBriefing/StatusBriefing';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';
import { formatarMensagemChat } from '@shared/functions/components';

// Funções
import { chatFormatarDadosUsuario } from '@cpnt/Chat/functions';
import { htmlToJsx, joinArray} from '@func/utils';

// Imagens
import imgIconeBriefing from '@svg/regular/file-lines.svg';
import imgIconeInfo from '@svg/solid/circle-info.svg';
import imgLogoCrowdBlack from '@_img/default/logo-bg-black.png';

// Funções
import { apiGetBriefingCompetenciasGetAll, apiGetBriefingHabilidadesGetAll, apiGetBriefingIdiomasGetAll, apiGetBriefingObjetivosGetAll, apiGetBriefingSegmentosGetAll } from "@api/_functions/geral";
import ImgTituloDesc from "@cpnt/ImgTituloDesc/ImgTituloDesc";
import Info from "@cpnt/Info/Info";
import { PortalPerfilUsuario } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilUsuario";
import { PortalJobDetalhes } from "@cpnt/Portais/PortaisProcessoSeletivo/PortalJobDetalhes/PortalJobDetalhes";
import Tip from "@cpnt/Tip/Tip";
import Voltar from "@cpnt/Voltar/Voltar";
import notificacao from '@_func/notification';
import { caminhoFoto, disponibilidadeFreelancer, getSenioridadeBySlug } from '@func/utils';
import useDebounce from "@/_/hooks/useDebounce";
import useWindowDimension from "@/_/hooks/useWindowDimensions";
import { initFuncExec, setGet } from "@route/routeUtils";
import { isNumeric } from "@_func/regex";
import { msgs, msgStatus } from "@_func/request";

const useQuery = () => {
	return new URLSearchParams(useLocation().search);
}

const DetalhesJob = () => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	const janelaAtiva = useWindowFocus();
	const screen = useWindowDimension();

	const [isDigitando, setIsDigitando] = useState(false);
	const debounceEvento = useDebounce(isDigitando, 500);

	/*
	** Debounce - Para não ficar enviando o evento a todo momento
	** ================================================== */
	useEffect(() => {
		socket.emit('message', {
			data: {
				id_freelancer: stateGet.id_freelancer,
				id_task: stateGet.id_task,
			},
			context: 'TasksMessages',
			function: 'userTyping',
		});
	}, [debounceEvento]);

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = (getsAtualizados = getsDefault) => {
		initFuncExec([
			{
				func: getBriefing,
				param: getsAtualizados
			},
		], init, location, stateLocation);
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		overlay: [],
		id_briefing: null,
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);


	const janelaAtivaRef = useRef();

	const [showPortalPerfilCompleto, setShowPortalPerfilCompleto] = useState(false);
	const [showPortalTextoBriefing, setShowPortalTextoBriefing] = useState(false);
	const [showPortalEquipeProjeto, setShowPortalEquipeProjeto] = useState(false);
	const [showPortalDetalhes, setShowPortalDetalhes] = useState(false);

	const [stateIdUsuarioPerfilCompleto, setStateIdUsuarioPerfilCompleto] = useState(false);

	/*
	** Loading
	** ================================================== */
	const [loadingGetBriefingHabilidades, setLoadingGetBriefingHabilidades] = useState([]);
	const [loadingGetBriefingObjetivos, setLoadingGetBriefingObjetivos] = useState([]);
	const [loadingGetBriefingSegmentos, setLoadingGetBriefingSegmentos] = useState([]);
	const [loadingGetBriefingIdiomas, setLoadingGetBriefingIdiomas] = useState([]);
	const [loadingGetBriefingCompetencias, setLoadingGetBriefingCompetencias] = useState([]);

	/*
	** States
	** ================================================== */
	const [stateBriefing, setStateBriefing] = useState({});
	const [_stateBriefing, _setStateBriefing] = useState({});
	const [loadingGetBriefing, setLoadingGetBriefing] = useState(false);

	const [stateBriefingHabilidades, setStateBriefingHabilidades] = useState([]);
	const [stateBriefingObjetivos, setStateBriefingObjetivos] = useState([]);
	const [stateBriefingSegmentos, setStateBriefingSegmentos] = useState([]);
	const [stateBriefingIdiomas, setStateBriefingIdiomas] = useState([]);
	const [stateBriefingCompetencias, setStateBriefingCompetencias] = useState([]);

	const [taxaPorcentagem, setTaxaPorcentagem] = useState(0);

	/*
	** Chat
	** ================================================== */
	// Debug
	// if(window.location.href === 'localhost') {
		// localStorage.debug = 'socket.io-client:socket';
	// }

	// States
	const [mensagensChat, setMensagensChat] = useState([]);
	const [mensagemChat, setMensagemChat] = useState('');
	const [usuariosOnline, setUsuariosOnline] = useState([]);
	const [isDigitandoChat, setIsDigitandoChat] = useState(false);
	const [isDigitandoInfoChat, setIsDigitandoInfoChat] = useState(false);
	const [loadingGetMensagensChat, setLoadingGetMensagensChat] = useState(true);
	const [loadingEnviarProposta, setLoadingEnviarProposta] = useState(false);

	const [emitirEventoMensagemLida, setEmitirEventoMensagemLida] = useState(false);
	const mensagensChatRef = useRef([]);

	/*
	** Chat - Sair da sala
	** ================================================== */
	useEffect(() => {
		return () => {
			socket.emit('message', {
				data: {
					id_briefing: stateGet.id_briefing,
					id_freelancer: store.usuario.id_freelancer,
				},
				context: 'BriefingMessages',
				function: 'leaveRoom'
			});
		}
	}, []);

	/*
	** Chat - inicializar
	** ================================================== */
	useEffect(() => {
		if(!socket.connected) {
			socket.auth.token = store.token;
  			socket.connect();
		}
	}, []);

	/*
	** Alterar estado do focus
	** ================================================== */
	useEffect(() => {
		janelaAtivaRef.current = janelaAtiva;
	}, [janelaAtiva]);

	/*
	** Verifica se tem novas mensagens e emite evento
	** ================================================== */
	useEffect(() => {
		if(emitirEventoMensagemLida) {
			marcarMensagemComoLida({
				id_briefing: stateGet.id_briefing,
			});
		}
	}, [janelaAtiva]);

	useEffect(() => {
		if(janelaAtivaRef.current) {
			marcarMensagemComoLida({
				id_briefing: stateGet.id_briefing,
			});
		}
	}, [emitirEventoMensagemLida]);

	/*
	** Chat - eventos
	** ================================================== */
	useEffect(() => {

		/*
		** Digitando
		** ================================================== */
		socket.on('typing', e => {
			setIsDigitandoChat(true);
			setIsDigitandoInfoChat(chatFormatarDadosUsuario(e.data));
		});

		/*
		** Nova mensagem do outro usuário - insere no chat
		** ================================================== */
		socket.on('getMessage', dados => {
			setIsDigitandoChat(false);

			var mensagensAtualizadas = [...mensagensChatRef.current];
			mensagensAtualizadas.push({
				...formatarMensagemChat(dados.data),
				propria: false,
			});
			setMensagensChat(mensagensAtualizadas);
			mensagensChatRef.current = mensagensAtualizadas;
			setEmitirEventoMensagemLida(true);

			if(!janelaAtivaRef.current) {
				var nome = dados.data.name_user ? dados.data.name_user : dados.data.name_frelancer;
				if(dados.data.type === 'imagem-url') {
					notificacao(nome, {
						body: `${nome} enviou uma imagem`,
						icon: dados.data.metadata.imagem.preview
					});
				} else {
					if(dados.data.text) {
						notificacao(nome, {
							body: dados.data.text,
							icon: caminhoFoto(dados.data.photo_user ? dados.data.photo_user : dados.data.photo_user_freela).url
						});
					}
				}
			}
		});

		/*
		** Própria mensagem enviada e cadastrada, insere no chat
		** ================================================== */
		socket.on('insertMessage', dados => {
			if(dados.success) {
				var mensagensAtualizadas = [...mensagensChatRef.current];
				mensagensAtualizadas.push({
					...formatarMensagemChat(dados.data),
					propria: true,
				});
				setMensagensChat(mensagensAtualizadas);
				mensagensChatRef.current = mensagensAtualizadas;
			} else {
				msg.error(dados.message, {
					autoClose: false,
				});
			}
		});

		/*
		** Usuário conectado, exibe a imagem no topo do chat
		** ================================================== */
		socket.on('joinChatRoom', e => {

			// TELA DE FREELANCER

			// const _usuariosOnline = chatSalaAddUsuarioOnline(e.data, usuariosOnline);
			// setUsuariosOnline(_usuariosOnline);
		});

		/*
		** Usuário desconectado, remove da sala
		** Remove apenas se desconectar para não dar a impressão
		** que ele ficou offline
		** ================================================== */
		socket.on('disconnected', e => {
			// if(e.data.id_freelancer !== store.usuario.id_freelancer) {
			// 	const _usuariosOnline = chatSalaRemoverUsuarioOnline(e.data, usuariosOnline);
			// 	setUsuariosOnline(_usuariosOnline);
			// }
		});

		/*
		** Mensagens lidas
		** ================================================== */
		socket.on('messagesReaded', dados => {
			if(dados.data.id_user !== store.usuario.id) {
				const mensagensLidas = mensagensChatRef.current.map(val => {
					return {
						...val,
						mensagemLida: true,
					}
				});
				setMensagensChat(mensagensLidas);
				mensagensChatRef.current = mensagensLidas;
			}
		});

		/*
		** Se conecta na sala para receber as mensagens
		** ================================================== */
		socket.emit('message', {
			data: {
				id_briefing: stateGet.id_briefing,
				id_freelancer: store.usuario.id_freelancer,
			},
			context: 'BriefingMessages',
			function: 'joinRoom'
		});

		/*
		** Proposta aceita
		** ================================================== */
		socket.on('acceptedPropose', dados => {
		});

	}, [socket]);

	/*
	** API - Get Dados briefing
	** ================================================== */
	const getBriefing = async (dadosObj = {}) => {
		const idBriefing = dadosObj.id_briefing;

		if(!idBriefing || !isNumeric(idBriefing)) {
			msg.error('O id do briefing deve ser informado.');
			_setStateBriefing(msgStatus({
				status: 403,
			}, {
				msg: 'O id do briefing deve ser informado.',
			}));
			return;
		}

		setLoadingGetBriefing(true);
		const retGetBriefing = await apiBriefings.getOne.get(idBriefing);
		_setStateBriefing(msgStatus(retGetBriefing));
		setLoadingGetBriefing(false);

		if(retGetBriefing.status === 200) {
			setTaxaPorcentagem(retGetBriefing.data.customer_tax_percent ? retGetBriefing.data.customer_tax_percent : 12);
			getMensagensChat(idBriefing);
			setStateBriefing(retGetBriefing.data);
			apiGetBriefingHabilidadesGetAll({idBriefing, setLoadingGetBriefingHabilidades, setStateBriefingHabilidades, showMsg: false});
			apiGetBriefingObjetivosGetAll({idBriefing, setLoadingGetBriefingObjetivos, setStateBriefingObjetivos, showMsg: false});
			apiGetBriefingSegmentosGetAll({idBriefing, setLoadingGetBriefingSegmentos, setStateBriefingSegmentos, showMsg: false});
			apiGetBriefingIdiomasGetAll({idBriefing, setLoadingGetBriefingIdiomas, setStateBriefingIdiomas, showMsg: false});
			apiGetBriefingCompetenciasGetAll({idBriefing, setLoadingGetBriefingCompetencias, setStateBriefingCompetencias, showMsg: false});
			marcarMensagemComoLida({
				id_briefing: idBriefing,
			});
		} else {
			msgs([retGetBriefing]);
			setLoadingGetMensagensChat(false);
			setMensagensChat(400);
		}

	}

	/*
	** API - Get mensagens
	** ================================================== */
	const getMensagensChat = async (idBriefing) => {
		setLoadingGetMensagensChat(true);
		const retGetMensagens = await apiBriefingMessages.all.get({
			id_briefing: idBriefing,
			id_freelancer: store.usuario.id_freelancer,
			limit: 9999
		});

		if(retGetMensagens.status === 200) {
			// Sobrescrever porque é mensagem de sistema
			retGetMensagens.data[0].name_user = 'CROWD';
			retGetMensagens.data[0].photo_user = imgLogoCrowdBlack;

			retGetMensagens.data.splice(1, 0, {
				type: 'briefing',
				_: {
					onClick: () => {
						setShowPortalTextoBriefing(true);
					}
				},
			});

			const mensagensFormatadas = retGetMensagens.data.map(val => {
				return formatarMensagemChat(val);
			});

			setMensagensChat(mensagensFormatadas);
			mensagensChatRef.current = mensagensFormatadas;
			marcarMensagemComoLida({
				id_briefing: idBriefing,
			});
		} else {
			if(retGetMensagens.status !== 400) {
				msgs([retGetMensagens]);
			}
		}
		setLoadingGetMensagensChat(false);
	}

	/*
	** Marcar mensagem como lida
	** ================================================== */
	const marcarMensagemComoLida = (dados = {}) => {
		const dadosMensagem = {
			id_briefing: dados.id_briefing,
			id_freelancer: store.usuario.id_freelancer,
			...dados,
		};

		const dadosEnviar = {
			data: dadosMensagem,
			context: 'BriefingMessages',
			function: 'readingMessages'
		};

		socket.emit('message', dadosEnviar);
		setEmitirEventoMensagemLida(false);
	}

	// API - Enviar mensagem
	const enviarMensagemChat = async (dadosObj = {}) => {

		var texto = '';

		if(dadosObj.mensagem) {
			texto = dadosObj.mensagem;
		} else {
			texto = mensagemChat;
		}

		if(!texto || texto === '' || texto.trim() === '') {
			if(dadosObj.type !== 'proposta' && dadosObj.type !== 'imagem-url') {
				return;
			}
		}

		const dadosEnviar = {
			data: {
				id_freelancer: store.usuario.id_freelancer,
				id_briefing: stateGet.id_briefing,
				// active: 0,
				text: texto,
				readed: 0,
				type: dadosObj.type ? dadosObj.type : 'default',
				...(dadosObj.metadata ? {metadata: dadosObj.metadata} : {}),
			},
			context: 'BriefingMessages',
			function: 'postMessage'
		};

		socket.emit('message', dadosEnviar);
		setMensagemChat('');
	}

	// API - Enviar mensagem
	const enviarProposta = async (dados = {}) => {
		const dadosEnviar = {
			data: {
				id_freelancer: store.usuario.id_freelancer,
				id_briefing: stateGet.id_briefing,
				// active: 0,
				text: dados.mensagem,
				readed: 0,
				type: 'proposta',
				metadata: {
					deadline_days: dados.prazo && dados.prazo !== '' ? dados.prazo : 0,
					price: dados.valorComPorcentagem,
					liquid_price: dados.valor,
					proposta: dados.proposta,
				},
			},
			context: 'BriefingMessages',
			function: 'postMessage'
		};
		socket.emit('message', dadosEnviar);
		setMensagemChat('');
	}

	/*
	** Socket - Dispara evendo de digitando
	** ================================================== */
	const digitandoChat = e => {
		socket.emit('message', {
			data: {
				id_freelancer: store.usuario.id_freelancer,
				id_briefing: stateGet.id_briefing,
			},
			context: 'BriefingMessages',
			function: 'userTyping',
		});
	}

	/*
	** Socket - Enviar reposta da proposta
	** ================================================== */
	const onClickBtnRespostaProposta = dados => {
		msg.info('Você não pode responder sua própria proposta. Este é apenas um exemplo de como o contratante vai receber.');
	}

	const onClickInfo = () => {
	}

	return (
		<div className="tela-detalhesjob">
			<div className="pg-negocjob-wrapper">
				<div className="titulo-box titulo-box-res">
					<div className="pg-negocjob-titulo-box">
						<Voltar
							desc="Visualizar todas as propostas"
							link={location.state ? `/jobs?${qs.stringify(location.state)}` : '/jobs'}
						/>
						{_stateBriefing.status !== 200 ? (
							<h3 className="titulo text-one-line">Negociar job</h3>
						) : (
							<div className="pg-negocjob-titulo-inner">
								<h3 className="titulo text-one-line">{stateBriefing.title}</h3>
								<div className="pg-negocjob-titulo-status td-desc">
									<Icone imagem={iconeStatusBriefing(stateBriefing.id_status).icone} tamanho="16px" cor={iconeStatusBriefing(stateBriefing.id_status).cor} />
									<span className="margin-l-5">{stateBriefing.status_name}</span>
								</div>
							</div>
						)}
					</div>
					{_stateBriefing.status === 200 ? (
						<div className="cpnt-btnadd-wrap pg-negocjob-titulo-sep-box">
							<div className="pg-negocjob-titulo-sep">
								<Tip
									tip={
										<TextoIconeLink
											icone={imgIconeInfo}
											texto="Detalhes"
											className="a"
											iconeTamanho="18px"
											iconeCor="#0277BD"
											onClick={e => setShowPortalDetalhes(true)}
										/>
									}
									info
								>
									Detalhes da proposta
								</Tip>
							</div>
						</div>
					) : null}
				</div>
			</div>

			<div className="tela-detalhejob-container">
				<div className="tela-detalhejob-info-box box-default overflow">
					<div className="tela-detalhejob-info-wrap">
						{_stateBriefing.status !== 200 ? (
							<>
								<div className="tela-detalhejob-info-hunter">
									<ImgTituloDesc
										wrap="a td-info"
										titulo={loadingGetBriefing ? 'Carregando dados' : 'Dados indisponíveis'}
										desc={loadingGetBriefing ? 'Aguarde...' : 'Não foi possível carregar as informações'}
										placeholder='userpicture'
										classNameTitulo="td-titulo"
										classNameDesc="td-desc"
										classNameImgBox="foto-wrap"
										responsivo={true}
									/>
								</div>
							</>
						) : (
							<>
								<div className="tela-detalhejob-info-hunter" data-tour="responsavel">
									<ImgTituloDesc
										wrap="a td-info"
										titulo={stateBriefing.user_name}
										desc="Responsável pela proposta"
										img={caminhoFoto(stateBriefing.user_photo).url}
										placeholder='userpicture'
										classNameTitulo="td-titulo"
										classNameDesc="td-desc"
										classNameImgBox="foto-wrap"
										responsivo={true}
										onClick={() => {
											setShowPortalPerfilCompleto(true);
										}}
									/>
								</div>
							</>
						)}
					</div>
					<div className="tela-detalhejob-info-dados relative box-txt scroll scroll-alt" data-tour="sidebar">

						{loadingGetBriefing ? (
							<Loading
								texto="Carregando, aguarde..."
							/>
						) : (
							_stateBriefing.status === 200 ? (
								<div className="overflow">
									<div className="tela-detalhejob-sec box-txt">
										{stateBriefing.contracted_freela_id && stateBriefing.contracted_freela_id === store.usuario.id_freelancer ? (
											<div>
												<div className="border-1 border-dashed border-blue border-radius overflow">
													<h3 className="align-center bg-white color-blue margin-0 padding-10 border-0 border-b border-dashed border-blue">Você foi contratado</h3>
													<div className="bg-blue-light padding-15">
														{chatMensagensVariaveis({
															modelo: 'avisoBriefingAprovado',
															tipoUsuario: 'freelancer',
														}).mensagem}
													</div>
												</div>
											</div>
										) : null}
										<h3 className="margin-b-5">Descritivo</h3>
										<TextoIconeLink
											icone={imgIconeBriefing}
											iconeCor="#0277BD"
											texto="Ver descrição completa do briefing"
											className="a"
											onClick={() => {
												setShowPortalTextoBriefing(true);
											}}
										/>
									</div>
									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Briefing</h3>
										<div>{stateBriefing.title}</div>
									</div>
									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Projeto</h3>
										<div>{stateBriefing.project_name}</div>
									</div>
									{/* <div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Cliente</h3>
										<div>{stateBriefing.customer_name}</div>
									</div> */}
									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Status</h3>
										<div>
											<LinhaItem
												titulo="Status do job"
												desc={stateBriefing.status_name || 'sem status'}
												alt
											/>
										</div>
										<div>
											<LinhaItem
												titulo="Status da tarefa"
												desc={stateBriefing.task_status_name || 'sem status'}
												alt
											/>
										</div>
									</div>
									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Nível de conhecimento</h3>
										<div>{getSenioridadeBySlug(stateBriefing.seniority)}</div>
									</div>
									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Área</h3>
										<div>{stateBriefing.area_name ? stateBriefing.area_name : 'Não informada'}</div>
									</div>

									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Habilidades recomendadas</h3>
										<div className="wrap-5">
											<div className="flex flex-wrap">
												{stateBriefingHabilidades.length ? (
													stateBriefingHabilidades.map((val, key) => {
														return (
															<BtnLabel key={key} texto={val.name} x={false} />
														)
													})
												) : (
													<div className="padding-h-5">Nenhuma habilidade informada.</div>
												)}
											</div>
										</div>
									</div>

									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Competências recomendadas</h3>
										<div className="wrap-5">
											<div className="flex flex-wrap">
												{stateBriefingCompetencias.length ? (
													stateBriefingCompetencias.map((val, key) => {
														return (
															<BtnLabel key={key} texto={val.name} x={false} />
														)
													})
												) : (
													<div className="padding-h-5">Nenhuma competência informada.</div>
												)}
											</div>
										</div>
									</div>

									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Segmentos de interesse</h3>
										<div className="wrap-5">
											<div className="flex flex-wrap">
												{stateBriefingSegmentos.length ? (
													stateBriefingSegmentos.map((val, key) => {
														return (
															<BtnLabel key={key} texto={val.name} x={false} />
														)
													})
												) : (
													<div className="padding-h-5">Nenhum segmento especificado.</div>
												)}
											</div>
										</div>
									</div>

									<div className="tela-detalhejob-sec box-txt" data-tour="idiomas">
										<h3 className="margin-b-5">Idiomas recomendados</h3>
										<div className="wrap-5">
											<div className="flex flex-wrap">
												{stateBriefingIdiomas.length ? (
													stateBriefingIdiomas.map((val, key) => {
														return (
															<BtnLabel key={key} texto={val.name} x={false} />
														)
													})
												) : (
													<div className="padding-h-5">Nenhum idioma especificado.</div>
												)}
											</div>
										</div>
									</div>

									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Disponibilidade</h3>
										<div>{disponibilidadeFreelancer(stateBriefing.availability)}</div>
									</div>

									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Interesses</h3>
										<div className="flex flex-wrap">
											{stateBriefingObjetivos.length ? (
												joinArray([...stateBriefingObjetivos.map(item => item.name)])
											) : (
												<div>Nenhum interesse especificado.</div>
											)}
										</div>
									</div>
								</div>
							) : (
								<Info
									texto={_stateBriefing.msg}
									icone="erro"
									alt
								/>
							)
						)}
					</div>
				</div>
				<div className="tela-detalhejob-chat-box" data-tour="chat">
					<Chat
						enviarMensagem={enviarMensagemChat}
						enviarProposta={enviarProposta}
						onClickBtnRespostaProposta={onClickBtnRespostaProposta}
						onClickInfo={onClickInfo}
						loadingEnviarProposta={loadingEnviarProposta}
						mensagem={mensagemChat}
						setMensagem={setMensagemChat}
						mensagens={mensagensChat}
						setDigitando={digitandoChat}

						isDigitando={isDigitandoChat}
						setIsDigitando={setIsDigitandoChat}
						isDigitandoInfo={isDigitandoInfoChat}
						loadingGetMensagens={loadingGetMensagensChat}

						conversandoCom={usuariosOnline}
						layoutExibicao="freelancer"
						exibirBotaoProposta={stateBriefing.id_status === 6 ? false : true}
						bloquearChat={stateBriefing.id_status === 6 ? true : false}
						bloquearChatMotivo="Essa proposta de job já foi encerrada."
						taxaPorcentagem={taxaPorcentagem}
					/>
				</div>
			</div>

			{/* Portal Mensagem customizada */}
			<Portal
				titulo="Descrição da proposta de job"
				show={showPortalTextoBriefing}
				onClose={() => {
					setShowPortalTextoBriefing(false);
				}}
				className="w-800"
			>
				<div className="box-txt">{htmlToJsx(typeof(stateBriefing.text) === 'string' ? stateBriefing.text : 'Nenhuma descrição disponível.')}</div>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Fechar',
							onClick: () => setShowPortalTextoBriefing(false)
						}}
					/>
				</div>
			</Portal>

			<PortalJobDetalhes
				show={showPortalDetalhes}
				showBtnNegociar={false}
				id={stateBriefing.id}
				onClose={() => setShowPortalDetalhes(false)}
			/>

			<PortalPerfilUsuario
				show={showPortalPerfilCompleto}
				showBtnNegociar={false}
				id={stateBriefing.id_user}
				onClose={() => setShowPortalPerfilCompleto(false)}
			/>

		</div>
	);
};

export default DetalhesJob;