import React from 'react';

// css
import './Webcam.css';

// Componentes
import { WebcamFoto } from "./WebcamFoto";
import { WebcamVideo } from "./WebcamVideo";

const Webcam = props => {

	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		tipo = 'foto',
		onClickCancelar,
		setStateFotoCapturada,

		stateVideoUrl,
		setStateVideoUrl,
		onClickConcluirGravacao,
	} = props;

	const renderTipo = () => {
		if(tipo === 'foto') {
			return (
				<WebcamFoto
					onClickCancelar={onClickCancelar}
					setStateFotoCapturada={setStateFotoCapturada}
				/>
			)
		}

		if(tipo === 'video') {
			return (
				<WebcamVideo
					stateVideoUrl={stateVideoUrl}
					setStateVideoUrl={setStateVideoUrl}
					onClickConcluirGravacao={onClickConcluirGravacao}
				/>
			)
		}

		return <div>Tipo desconhecido</div>
	}

	return (
		<>
			<div className={`cpnt-webcam ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-webcam-box ${className}`}>
					{renderTipo()}
				</div>
			</div>
		</>
	);
};

export { Webcam };
