import React, { useState, useContext } from 'react';
import moment from 'moment/min/moment-with-locales';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './ChatPropostaFreelancer.css';

// Componentes
import ChatMensagem from '../ChatMensagem/ChatMensagem';
import Button2 from '@cpnt/Button2/Button2';
import Input2 from '@cpnt/Input2/Input2';

// Funções
import { caminhoFoto, conveterRealParaNumero, formatarDinheiro } from '@func/utils';
import Input from "@cpnt/Input/Input";
import { IconeTexto } from "@_cpnt/Texto/IconeTexto/IconeTexto";
import { IconeTextoRoot } from "@_cpnt/Texto/IconeTexto/IconeTextoRoot/IconeTextoRoot";

import imgEstrela from '@fa/solid/star.svg';
import imgProtecao from '@fa/solid/shield-check.svg';
import { msg } from "@_func/cpnt";

const ChatPropostaFreelancer = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
		wrap = '',

		setShowPortal = () => null,
		enviarProposta,
		loadingEnviarProposta,

		taxaPorcentagem = 12,
	} = props;

	const [stateMetadataValor, setStateMetadataValor] = useState(0);
	const [stateMetadataValorComPorcentagem, setStateMetadataValorComPorcentagem] = useState(0);
	const [stateMetadataPrazo, setStateMetadataPrazo] = useState(0);
	const [stateMensagem, setStateMensagem] = useState('');

	return (
		<div className={`cpnt-chatpropfreela ${wrap}`}>
			<div className={`cpnt-chatpropfreela-box ${className}`}>

				<div className="flex flex-wrap gap-20">
					<strong>Benefícios de ser contratado por dentro:</strong>
					<IconeTextoRoot>
						<IconeTexto.Icone img={imgEstrela} />
						<IconeTexto.Texto texto="Receba avaliações e pegue mais jobs" />
					</IconeTextoRoot>
					<IconeTextoRoot>
						<IconeTexto.Icone img={imgProtecao} />
						<IconeTexto.Texto texto="Proteja-se, evite calotes" />
					</IconeTextoRoot>
				</div>

				<div className="cpnt-chatpropfreela-dados-preview margin-t-20">
					<div className="cpnt-chatpropfreela-dados-mensagem-box">
						<h3 className="margin-0">Dados da mensagem</h3>
						<div className="cpnt-chatpropfreela-dados-mensagem">
							<div className="wrap-5">
								<div className="input-box">
									<Input2
										label="Mensagem"
										_={{
											type: 'textarea',
											value: stateMensagem,
											onChange: e => {
												setStateMensagem(e.target.value);
											}
										}}
									/>
								</div>

								<div className="input-box">
									<Input2
										label="Valor total a receber"
										_={{
											value: stateMetadataValor,
											onChange: e => {
												var valorFreela = conveterRealParaNumero(e);
												const valorFinal = valorFreela / ((100 - taxaPorcentagem) / 100);
												setStateMetadataValor(valorFreela);
												setStateMetadataValorComPorcentagem(valorFinal);
											}
										}}
										dinheiro
									/>
								</div>
								<div className="input-box">
									<Input2
										label="Prazo para entrega (em dias)"
										_={{
											value: stateMetadataPrazo,
											onChange: (e) => {
												setStateMetadataPrazo(e.target.value);
											},
											mask: '999',
										}}
									/>
								</div>
							</div>

						</div>
					</div>

					<div className="cpnt-chatpropfreela-preview-box">
						<h3 className="margin-0">Pré visualização</h3>
						<div className="cpnt-chatpropfreela-preview">
							<ChatMensagem
								{...{
									data: moment().subtract(0, 'minutes').format('HH:mm'),
									mensagem: stateMensagem,
									metadata: {
										titulo: 'Proposta',
										valor: `R$ ${stateMetadataValor} (${stateMetadataValorComPorcentagem})`,
										descricao: `${stateMetadataPrazo} dias`,
										proposta: {
											prazo: Number(stateMetadataPrazo),
											valorSemPorcentagem: stateMetadataValor,
											valorComPorcentagem: stateMetadataValorComPorcentagem,
											porcentagem: taxaPorcentagem,
										}
									},
									name: store.usuario.name,
									photo: caminhoFoto(store.usuario.photo).url,
									type: 'proposta',
									propria: false,
								}}
							/>
						</div>

					</div>
				</div>

				<div className="form-bottom">
					<Button2
						_={{
							value: 'Cancelar',
							className: 'btn btn-1',
							onClick: () => setShowPortal(false),
						}}
					/>
					<Button2
						_={{
							value: 'Enviar proposta',
							onClick: () => {
								if(!stateMetadataValor || stateMetadataValor === 0 || stateMetadataValor === '0' || stateMetadataValor < 0) {
									msg.error('Insira o valor total a receber');
									return;
								}
								setShowPortal(false);
								enviarProposta({
									valor: stateMetadataValor,
									porcentagem: taxaPorcentagem,
									valorComPorcentagem: stateMetadataValorComPorcentagem,
									prazo: Number(stateMetadataPrazo),
									mensagem: stateMensagem,
									proposta: {
										prazo: Number(stateMetadataPrazo),
										valorSemPorcentagem: stateMetadataValor,
										valorComPorcentagem: stateMetadataValorComPorcentagem,
										porcentagem: taxaPorcentagem,
									}
								});
							}
						}}
						wrap="margin-l-10"
						loading={loadingEnviarProposta}
					/>
				</div>

			</div>
		</div>
	);
};

export default ChatPropostaFreelancer;