import React from 'react';

// Componentes
import Info from '@cpnt/Info/Info';

// Funções
import { removerHtml } from '@func/utils';

const ChatMensagemSistema = props => {
	const {
		metadata = {},
		wrap = '',
		className = '',
		mensagem,
	} = props;

	return (
		<div className={`cpnt-chatmsg cpnt-chatmsg-posicao-center cpnt-chatmsg-tipo-${metadata.tipo ? metadata.tipo : 'default'} ${wrap}`}>
			{mensagem && mensagem !== '' ? (
				<div className={`cpnt-chatmsg-mensagem-box cpnt-chatmsg-mensagem-box-center ${className}`}>
					{metadata.titulo && metadata.titulo !== '' ? (
						<div className={`cpnt-chatmsg-custom-titulo`}>
							<h3 className="margin-0 padding-0">{metadata.titulo}</h3>
						</div>
					) : null}
					<div className="cpnt-chatmsg-mensagem">
						{metadata.icone ? (
							<Info
								icone={metadata.icone}
								texto={mensagem}
								alt
							/>
						) : (
							removerHtml(mensagem)
						)}
					</div>
				</div>
			) : null}
		</div>
	);
};

export default ChatMensagemSistema;