import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiBriefingsHistory = {
	insert: {
		post: async dados => {
			return await api.post(`/briefingsHistory/insert`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getOne: {
		get: async idBriefing => {
			return await api.get(`/briefingsHistory/getOne/${idBriefing}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getAll: {
		get: async idBriefing => {
			return await api.get(`/briefingsHistory/getAll/${idBriefing}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiBriefingsHistory;
