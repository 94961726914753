import React, { useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import c from './TopbarRoot.module.scss';

export const TopbarRoot = props => {
	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
		children,
	} = props;

	return (
		<div className={`${className} ${c['topbar']}`}>
			{children}
		</div>
	);
}
