import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiTasksCombos = {
	status: {
		get: async id => {
			return await api.get(`/tasksCombos/status`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiTasksCombos;