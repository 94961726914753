import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';

// css
import './PortalListaLocalProfissionaisSelecionados.css';

// API
import { apiCompany } from '@_api/requests/company/company';

// Partes
import { DetalhesEmpresa } from '@page/Logado/Empresas/DetalhesEmpresa/DetalhesEmpresa';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções

import { msgs, msgStatus } from "@_func/request";
import { DetalhesProfissionaisSelecionados } from '@cpnt/ProfissionaisSelecionados/DetalhesProfissionaisSelecionados/DetalhesProfissionaisSelecionados';
import { apagarListaLocal, getAllUsuariosLocal, initListaLocal, limparListaLocal, listasLocal } from '@func/selecaoUsuariosBriefings';
import { ListaLocalProfissionaisSelecionados } from '@cpnt/ProfissionaisSelecionados/ListaLocalProfissionaisSelecionados/ListaLocalProfissionaisSelecionados';
import PortalConfirmacao from '../../PortaisGerais/PortalConfirmacao/PortalConfirmacao';
import { closePortal, getPortalDados, gets, isShowPortal, showPortal } from '@route/routeUtils';
import { PortalListaLocalFormEdicao } from '../PortalListaLocalFormEdicao/PortalListaLocalFormEdicao';
import { PortalPerfilFreelancer } from '@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilFreelancer';
import { PortalListaLocalFormCadastro } from '../PortalListaLocalFormCadastro/PortalListaLocalFormCadastro';

const PortalListaLocalProfissionaisSelecionados = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-listalocprofsel-detalhes',

		stateListaAtual,
		setStateListaAtual,
		stateListas,
		setStateListas,
		stateSelecionados,
		setStateSelecionados,
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	return (
		<Portal
			titulo="Gerenciar lista de profisisonais"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<div className={wrap}>
				<div className={className}>
					<ListaLocalProfissionaisSelecionados
						stateListaAtual={stateListaAtual}
						setStateListaAtual={setStateListaAtual}
						stateSelecionados={stateSelecionados}
						setStateSelecionados={setStateSelecionados}
						stateListas={stateListas}
						setStateListas={setStateListas}
						onClickEditarLista={e => {
							showPortal({portal: 'editar-lista-local-prof', stateGet, navigate});
						}}
						onClickItemLista={e => {
							showPortal({portal: 'perfil-freelancer-prof', dados: e.id, stateGet, navigate});
						}}
						after={
							<div className="form-bottom-alt">
								<div className="flex">
									{stateListaAtual === 'Lista padrão' ? (
										<Button2
											_={{
												value: 'Apagar lista',
												onClick: () => {
													msg.warning('Não é possível apagar a lista padrão.');
												},
												className: 'btn btn-1 btn-red',
											}}
											wrap="opct-4"
										/>
									) : (
										<Button2
											_={{
												value: 'Apagar lista',
												onClick: e => showPortal({portal: 'confirmacao-apagar-lista-prof', stateGet, navigate}),
												className: 'btn btn-1 btn-red',
											}}
										/>
									)}
									<Button2
										_={{
											value: 'Limpar lista',
											onClick: e => showPortal({portal: 'confirmacao-limpar-lista-prof', stateGet, navigate}),
											className: 'btn btn-1 btn-red margin-l-10',
										}}
									/>
								</div>
								<div className="flex">
									<Button2
										_={{
											value: 'Nova lista',
											onClick: e => showPortal({portal: 'cadastrar-lista-local-prof', stateGet, navigate}),
											className: 'btn btn-default margin-l-30',
										}}
									/>
									<Button2
										_={{
											value: 'Fechar edição',
											onClick: onClose,
											className: 'btn btn-default margin-l-10',
										}}
									/>
								</div>
							</div>
						}
					/>
				</div>
			</div>

			<PortalPerfilFreelancer
				id={getPortalDados({portal: 'perfil-freelancer-prof', stateGet})}
				show={isShowPortal({portal: 'perfil-freelancer-prof', stateGet})}
				onClose={() => closePortal({portal: 'perfil-freelancer-prof', stateGet, navigate})}
			/>

			<PortalListaLocalFormEdicao
				show={isShowPortal({portal: 'editar-lista-local-prof', stateGet})}
				onClose={() => closePortal({portal: 'editar-lista-local-prof', stateGet, navigate})}
				onSuccess={() => {
					closePortal({portal: 'editar-lista-local-prof', stateGet, navigate});
				}}

				stateListaAtual={stateListaAtual}
				setStateListaAtual={setStateListaAtual}
				stateListas={stateListas}
				setStateListas={setStateListas}
				stateSelecionados={stateSelecionados}
				setStateSelecionados={setStateSelecionados}
			/>

			<PortalListaLocalFormCadastro
				show={isShowPortal({portal: 'cadastrar-lista-local-prof', stateGet})}
				onClose={() => closePortal({portal: 'cadastrar-lista-local-prof', stateGet, navigate})}
				onSuccess={() => {
					closePortal({portal: 'cadastrar-lista-local-prof', stateGet, navigate});
				}}

				stateListaAtual={stateListaAtual}
				setStateListaAtual={setStateListaAtual}
				stateListas={stateListas}
				setStateListas={setStateListas}
				stateSelecionados={stateSelecionados}
				setStateSelecionados={setStateSelecionados}
			/>

			<PortalConfirmacao
				show={isShowPortal({portal: 'confirmacao-limpar-lista-prof', stateGet})}
				titulo="Deseja realmente limpar essa lista?"
				desc={<>Todos os dados salvos na lista <strong>{stateListaAtual}</strong> serão removidos. Essa ação não poderá ser desfeita.</>}
				id={getPortalDados({portal: 'confirmacao-limpar-lista-prof', stateGet})}
				onClose={() => closePortal({portal: 'confirmacao-limpar-lista-prof', stateGet, navigate})}
				onClickCancelar={() => closePortal({portal: 'confirmacao-limpar-lista-prof', stateGet, navigate})}
				onClickConfirmar={() => {
					limparListaLocal({
						stateListaAtual,
						setStateSelecionados,
					});
					closePortal({portal: 'confirmacao-limpar-lista-prof', stateGet, navigate});
				}}
			/>

			<PortalConfirmacao
				show={isShowPortal({portal: 'confirmacao-apagar-lista-prof', stateGet})}
				titulo="Deseja realmente apagar essa lista?"
				desc={<>A lista <strong>{stateListaAtual}</strong> será removida. Todos os dados salvos nessa lista também serão removidos. Essa ação não poderá ser desfeita.</>}
				id={getPortalDados({portal: 'confirmacao-apagar-lista-prof', stateGet})}
				onClose={() => closePortal({portal: 'confirmacao-apagar-lista-prof', stateGet, navigate})}
				onClickCancelar={() => closePortal({portal: 'confirmacao-apagar-lista-prof', stateGet, navigate})}
				onClickConfirmar={() => {
					if(stateListaAtual === 'Lista padrão') {
						msg.error('Não é possível apagar a lista padrão. Se necessário, você poderá limpar os dados dessa lista clicando em "Limpar lista".');
					} else {
						apagarListaLocal({
							stateListaAtual,
							setStateListas,
							setStateListaAtual,
							setStateSelecionados,
						});
						closePortal({portal: 'confirmacao-apagar-lista-prof', stateGet, navigate});
					}
				}}
			/>
		</Portal>
	);
};

export { PortalListaLocalProfissionaisSelecionados };