import React from 'react';

// css
import './Passo5.css';

// Partes
import { PtCartaApresentacao } from "@cpnt/Partes/PtCartaApresentacao/PtCartaApresentacao";

const Passo5 = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		stateDados = {},
		setStateDados,
		before,
	} = props;

	return (
		<>
			<div className={`cpnt-passo5 ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-passo5-box ${className}`}>
					{before}
					<PtCartaApresentacao
						stateTexto={stateDados.description}
						setStateTexto={e => {
							setStateDados({
								...stateDados,
								description: e,
							});
						}}
					/>
				</div>
			</div>
		</>
	);
};

export { Passo5 };
