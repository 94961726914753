import React from 'react';

// Componentes
import Input2 from '@cpnt/Input2/Input2';
import { InputDate } from "@cpnt/InputDate/InputDate";

const FormPremio = props => {
	const {
		className = '',
		wrap = '',

		state,
		setState,
	} = props;

	return (
		<div className={`cpnt-formpremios ${wrap}`}>
			<div className={`cpnt-formpremios-box ${className}`}>
				<div className="wrap-5">
					<div className="flex flex-wrap">
						<div className="input-box w-70p box-1-680">
							<Input2
								label="Título"
								req
								_={{
									value: state.title,
									onChange: e => {
										setState({
											...state,
											title: e.target.value,
										});
									}
								}}
							/>
						</div>
						<div className="input-box w-30p box-1-680">
							<InputDate
								label="Data de emissão"
								req
								value={state.issue_date}
								onChange={e => {
									setState({
										...state,
										issue_date: e,
									});
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FormPremio;