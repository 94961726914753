import { validarRetorno, config } from '../axios';
import api from 'axios';
import qs from 'qs';

const apiTasks = {
	insert: {
		post: async dados => {
			return await api.post(`/tasks/insert`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	update: {
		put: async dados => {
			return await api.put(`/tasks/update`, dados, config(true))
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	all: {
		get: async dadosObj => {
			var dadosEnviar = qs.stringify(dadosObj, { encode: false });
			return await api.get(`/tasks/all?${dadosEnviar}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getOne: {
		get: async id => {
			return await api.get(`/tasks/getOne/${id}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	tasksCombos: {
		get: async idFreelancer => {
			return await api.get(`/tasks/tasksCombos/${idFreelancer}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiTasks;