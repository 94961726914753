import React, { useEffect, useState } from 'react';

// Imagens
import imgPlaceholder from '@img/placeholder/placeholder-100x63.png';

// css
import './Posts.css';

// API
import apiWordpress from '@api/wordpress/wordpress';

// Componentes
import Loading from '../Loading/Loading';

// Funções
import { html, htmlToJsx, sanitize } from '@func/utils';
import { msgs, msgStatus } from "@_func/request";

const Posts = props => {
	const {
		className = '',
		wrap = '',
		before,
		after,
	} = props;

	/*
	** States
	** ================================================== */
	const [statePosts, setStatePosts] = useState([]);
	const [loadingGetPosts, setLoadingGetPosts] = useState(false);
	const [stateImgs, setStateImgs] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		getPostsJobs();
	}, []);

	/*
	** API - Carregar posts
	** ================================================== */
	const getPostsJobs = async () => {
		setLoadingGetPosts(true);
		const retGetPosts = await apiWordpress.posts.get({
			tags: 63, // Tag de 'jobs' => https://blog.crowd.br.com/wp-admin/edit-tags.php?taxonomy=post_tag
		});

		if(retGetPosts.status === 200) {
			var imagens = [];
			for (var item of retGetPosts.data) {
				var get = await apiWordpress.img.get(item?._links['wp:featuredmedia'][0]?.href);
				imagens.push(get?.source_url);
			}
			setStatePosts(retGetPosts.data);
			setStateImgs(imagens);
		} else {
			msgs([retGetPosts]);
		}
		setLoadingGetPosts(false);
	}

	const imgDestaque = (key) => {
		return stateImgs[key];
	}

	return (
		<div className={`cpnt-posts ${wrap}`}>
			<div className={`cpnt-posts-box ${className}`}>
				{loadingGetPosts ? (
					<Loading texto="Carregando conteúdo, aguarde..." />
				) : (
					<div>
						{before}
						<div className="wrap-15">
							<div className="box cpnt-posts-boxes">
								{statePosts && statePosts.length ? (
									statePosts.map((val, key) => {
										return (
											<div className="cpnt-posts-boxloop box-4 box-2-1680 box-1-680">
												<a
													key={key}
													href={val.link}
													target="_blank"
													rel="noreferrer"
													className="cpnt-posts-boxloop-wrap cpnt-posts-boxloop-wrap-link"
												>
													<div
														className="cpnt-posts-img-wrap bg"
														style={{backgroundImage: `url(${imgDestaque(key)})`}}
													>
														<img src={imgPlaceholder} alt="img-post" className="block w-100p" />
													</div>
													<div className="cpnt-posts-titulo-conteudo">
														<h3 className="cpnt-posts-titulo">{val.title.rendered}</h3>
														<div className="cpnt-posts-conteudo">
															{htmlToJsx(val.excerpt.rendered)}
														</div>
													</div>
												</a>
											</div>
										)
									})
								) : (
									<div className="box-txt box-default-2 bg-white border border-color-2 padding-15 border-box margin-15">
										<h3 className="margin-0">Posts não disponíveis</h3>
										<div>Não foi possível carregar as postagens, tente novamente mais tarde.</div>
									</div>
								)}
							</div>
						</div>
						{after}
					</div>
				)}
			</div>
		</div>
	);
};

export default Posts;