import React from 'react';

// css
import c from './AnimacaoPesquisa.module.css';
import { VideoPlayer } from "@_cpnt/VideoPlayer/VideoPlayer";
import videoAI from '@video/crowd-ai-working.mp4';
import videoMatch from '@video/crowd-match.mp4';

const AnimacaoPesquisa = props => {
	const {
		className = '',
		encontrado,

		qtdTotal,
		qtdEncontrado,
		qtdPorcentagem,
	} = props;

	return (
		<div>
			{encontrado ? (
				<div className="align-center">
					Total de profissionais cadastrados na plataforma: <strong>{qtdTotal}</strong>
				</div>
			) : null}
			<div className={`${c['aviso-container']} ${className}`}>
				<div className={`${c['aviso-e']} ${c['aviso-box']}`}>
					{encontrado ? (
						<div className={c['textos']}>
							<p className="margin-v-0">Encontramos <strong>{qtdEncontrado} profissionais que combinam {qtdPorcentagem}%</strong> com o que você precisa para o seu projeto.</p>
						</div>
					) : (
						<div className={c['textos']}>
							<h3 className={`${c['aviso-titulo']} margin-v-0`}>IA trabalhando</h3>
							<p className="margin-v-0 padding-t-25">Analisando mais de 30.000 talentos e selecionando os profissionais ideais para você.</p>
						</div>
					)}
				</div>
				<div className={`${c['aviso-d']} ${c['aviso-box']}`}>
					<VideoPlayer
						_={{
							url: encontrado ? videoMatch : videoAI,
							playing: true,
							controls: false,
							loop: true,
							muted: true,
							style: {
								backgroundColor: '#FDFDFD',
							}
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default AnimacaoPesquisa;