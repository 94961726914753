import { useRoutes } from 'react-router-dom';

// Layout painel
import LayoutBlank from "@_role/Layouts/LayoutBlank/LayoutBlank";
import {LayoutPainel} from "@_role/Layouts/LayoutPainel/LayoutPainel";

// Itens sidebar
import Notificacoes from "@cpnt/Notificacoes/Notificacoes";
import { RedirectTo } from "@cpnt/RedirectTo/RedirectTo";
import { AvisosTopo } from "@page/Logado/AvisosTopo/AvisosTopo";
import BuscarProfissionais from "@page/Logado/BuscarProfissionais/BuscarProfissionais";
import CadastrarProcessoSeletivo from "@page/Logado/CadastrarProcessoSeletivo/CadastrarProcessoSeletivo";
import CadastrarProcessoSeletivoSelecionados from "@page/Logado/CadastrarProcessoSeletivoSelecionados/CadastrarProcessoSeletivoSelecionados";
import Clientes from "@page/Logado/Clientes/Clientes";
import ComplementarProcessoSeletivo from "@page/Logado/ComplementarProcessoSeletivo/ComplementarProcessoSeletivo";
import { ConvideUmFreelancer } from "@page/Logado/ConvideUmFreelancer/ConvideUmFreelancer";
import Empresas from "@page/Logado/Empresas/Empresas";
import EmpresasChat from "@page/Logado/EmpresasChat/EmpresasChat";
import Erro404Logado from "@page/Logado/Erro404Logado/Erro404Logado";
import Fechamento from "@page/Logado/Fechamento/Fechamento";
import { InicioAdmin } from "@page/Logado/InicioAdmin/InicioAdmin";
import JobsAdmin from "@page/Logado/JobsAdmin/JobsAdmin";
import NegociarJobs from "@page/Logado/NegociarJobs/NegociarJobs";
import NegociarProcessoSeletivo from "@page/Logado/NegociarProcessoSeletivo/NegociarProcessoSeletivo";
import PerfilAdministrador from "@page/Logado/PerfilAdministrador/PerfilAdministrador";
import ProcessosSeletivos from "@page/Logado/ProcessosSeletivos/ProcessosSeletivos";
import ProfissoesPredefinidas from "@page/Logado/ProfissoesPredefinidas/ProfissoesPredefinidas";
import Projetos from "@page/Logado/Projetos/Projetos";
import { Templates } from "@page/Logado/Templates/Templates";
import Usuarios from "@page/Logado/Usuarios/Usuarios";
import { VideosCrowder } from "@page/Logado/Videos/VideosCrowder";
import { VideosEmpresas } from "@page/Logado/Videos/VideosEmpresas";
import { VideosFreelancer } from "@page/Logado/Videos/VideosFreelancer";
import Wistia from "@page/Logado/_Testes/Wistia/Wistia";
import { _Testes } from "@page/Logado/_Testes/_Testes";
import Logout from "@page/SemRestricoes/Logout/Logout";
import { DashboardScreen } from "@_page/Logado/DashboardScreen/DashboardScreen";
import { sidebarAdmin } from "@_role/Sidebar/SidebarAdmin";
import { HomeAdminScreen } from "@_page/Logado/HomeScreen/HomeAdminScreen";
import { MatchDeProfissionaisScreen } from "@_page/Logado/MatchDeProfissionaisScreen/MatchDeProfissionaisScreen";

export const ROTAS_ADMIN = [
	{
		path: '/',
		element:
		<LayoutBlank
			title="Página inicial"
			component={RedirectTo}
			redirectTo="/inicio"
		/>,
	},
	{
		path: '/inicio',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Página inicial"
			component={HomeAdminScreen}
		/>,
	},

	/*
	** Testes
	** ================================================== */
	{
		path: '/testes',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Testes Crowd"
			component={_Testes}
		/>,
	},

	/*
	** Desenvolvimento
	** ================================================== */
	{
		path: '/dashboard',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Dashboard"
			component={DashboardScreen}
		/>,
	},

	/*
	** Rotas default
	** ================================================== */
	{
		path: '/perfil',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Perfil"
			component={PerfilAdministrador}
		/>,
	},
	{
		path: '/buscar-profissionais',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Buscar profissionais"
			component={BuscarProfissionais}
		/>,
	},
	{
		path: '/briefing',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Propostas de jobs"
			component={ProcessosSeletivos}
		/>,
	},
	{
		path: '/briefing/cadastrar',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Briefing/Vaga"
			component={MatchDeProfissionaisScreen}
		/>,
	},
	{
		path: '/briefing/cadastrar-selecionados',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Cadastrar proposta de job freelancer selecionados"
			component={CadastrarProcessoSeletivoSelecionados}
		/>,
	},
	{
		path: '/briefing/negociacao',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Negociação"
			component={NegociarProcessoSeletivo}
			className="container-chat"
		/>,
	},
	{
		path: '/briefing/complementar',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Complementar proposta de job"
			component={ComplementarProcessoSeletivo}
		/>,
	},
	{
		path: '/projetos',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Buscar projetos"
			component={Projetos}
		/>,
	},
	{
		path: '/empresas',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Empresas"
			component={Empresas}
		/>,
	},
	{
		path: '/empresas/chat',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Chat com a empresa"
			component={EmpresasChat}
		/>,
	},
	{
		path: '/fechamento',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Fechamento"
			component={Fechamento}
		/>,
	},
	{
		path: '/clientes',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Clientes"
			component={Clientes}
		/>,
	},
	{
		path: '/usuarios',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Usuários"
			component={Usuarios}
		/>,
	},
	{
		path: '/contratacoes',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Contratações"
			component={JobsAdmin}
		/>,
	},
	{
		path: '/contratacoes/negociacao',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Negociar contratações"
			component={NegociarJobs}
			className="container-chat"
		/>,
	},
	{
		path: '/configuracao-do-match',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Configuração do Briefing/Vaga"
			component={ProfissoesPredefinidas}
		/>,
	},
	{
		path: '/templates',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Templates"
			component={Templates}
		/>,
	},
	{
		path: '/notificacoes',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Notificações"
			component={Notificacoes}
		/>,
	},
	// {
	// 	path: '/comunidade',
	// 	element:
	// 	<LayoutPainel
	// 		sidebar={sidebarAdmin}
	// 		meta={{
	// 			title: 'Comunidade',
	// 		}}
	// 		iframe="https://comunidadecrowd.circle.so/home?iframe=true"
	// 		component={CircleEmbed}
	// 	/>,
	// },
	{
		path: '/videos/crowders',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Vídeos"
			component={VideosCrowder}
		/>,
	},
	{
		path: '/videos/freelancers',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Vídeos"
			component={VideosFreelancer}
		/>,
	},
	{
		path: '/videos/empresas',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Vídeos"
			component={VideosEmpresas}
		/>,
	},
	// {
	// 	path: '/indique-a-crowd',
	// 	element:
	// 	<LayoutPainel
	// 		sidebar={sidebarAdmin}
	// 		meta={{
	// 			title: 'Indique a Crowd',
	// 		}}
	// 		component={IndiqueACrowd}
	// 	/>,
	// },
	{
		path: '/convide-um-freelancer',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Convide um freelancer"
			component={ConvideUmFreelancer}
		/>,
	},
	{
		path: '/avisos',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Avisos da barra do topo"
			component={AvisosTopo}
		/>,
	},
	{
		path: '/logout',
		element:
		<Logout
			title="Fazendo logout, aguarde..."
		/>,
	},
	{
		path: '*',
		element:
		<LayoutPainel
			sidebar={sidebarAdmin}
			title="Página não encontrada..."
			component={Erro404Logado}
		/>,
	}
];

function ScreensAdmin() {
	const rotasAdmin = useRoutes(ROTAS_ADMIN);
	return rotasAdmin;
}

export { ScreensAdmin };