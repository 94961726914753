import React from 'react';
import Mortal from 'react-mortal';

// CSS
import c from './PortalLateral.module.scss';

// Funções
import { hash } from '@_func/generate';

export const PortalLateral = props => {
    const {
		show,
		onClose,
		closeOnClickBg = true,
		closeOnEsc,
		children,
		x = true,
		id = `cpnt-portallateral-${hash()}`,
		className = '',
		classNameContainer = '',
		overflow = true,
		chilrenOutside,
	} = props;

	if(overflow) {
		if(show) {
			window.document.body.classList.add('overflow');
		} else {
			window.document.body.classList.remove('overflow');
		}
	}

	const closeOnClickCheck = e => {
		if(e.target.id === id) {
			onClose();
		}
	}

    return (
		<Mortal
			isOpened={show}
			onClose={onClose}
			closeOnEsc={closeOnEsc}
			motionStyle={(spring, isVisible) => {
				return {
					opacity: spring(isVisible ? 1 : 0),
					panelOffset: spring(isVisible ? 0 : 100),
					visibility: spring(isVisible ? 'visible' : 'hidden')
				}
			}}
		>
			{(motion, isVisible) => {
				return (
					<div
						className={`${c['container']} ${classNameContainer}`}
						style={{
							opacity: motion.opacity,
							pointerEvents: isVisible ? 'auto' : 'none',
						}}
					>
						<div
							className={`${c['wrapper']}`}
							id={id}
							{...(closeOnClickBg ? {onClick: closeOnClickCheck} : {})}
						>
							<div
								className={`${c['inner']}`}
								style={{
									transform: `translate3d(${motion.panelOffset}%, 0, 0)`,
								}}
							>
								<div className={`${c['content']}`}>
									{x && <span className={c['x']} onClick={onClose}></span>}
									<div className={`${c['children']} ${className}`}>{children}</div>
									{chilrenOutside}
								</div>
							</div>
						</div>
						<div
							className={`${c['bg']}`}
							{...(closeOnClickBg ? {onClick: onClose} : {})}
						></div>
					</div>
				)
			}}
    	</Mortal>
    )
}
