import Tip from '@cpnt/Tip/Tip';
import React from 'react';
import { ListaLocalSelect } from '../ListaLocalSelect/ListaLocalSelect';

// css
import './ProfissionaisAcoes.css';

// Imagens
import imgIconeCog from '@svg/solid/gear.svg';

// Componentes
import { Select2 } from '@cpnt/Select2/Select2';
import Icone from '@cpnt/Icone/Icone';
import { getAllUsuariosLocal } from '@func/selecaoUsuariosBriefings';

const ProfissionaisAcoes = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		onClickSelectListaLocal,
		stateListaAtual,
		setStateListaAtual,
		stateListas,
		setStateListas,
		stateSelecionados,
		setStateSelecionados,
		onClick,
	} = props;

	return (
		<>
			<div className={`cpnt-profacoes ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-profacoes-box ${className}`}>
					<div className="cpnt-profacoes-btns">
						<Tip
							titulo="Selecionar todos"
							tip={
								<div
									className="a"
									onClick={e => {
										onClick('selecionarTodos');
									}}
								>
									Selecionar todos
								</div>
							}
							classNameTip="cpnt-profacoes-item"
						>
							<div className="max-w-300">Marca todos os resultados exibidos nessa página. Resultados de outras páginas não serão marcados.</div>
						</Tip>
						<div className="cpnt-profacoes-sep">|</div>
						<Tip
							titulo="Desselecionar todos"
							tip={
								<div
									className="a"
									onClick={e => {
										onClick('desselecionarTodos');
									}}
								>
									Desselecionar todos
								</div>
							}
							classNameTip="cpnt-profacoes-item"
						>
							<div className="max-w-300">Desmarca todos os resultados exibidos nessa página. Resultados de outras páginas não serão desmarcados.</div>
						</Tip>
						<div className="cpnt-profacoes-sep">|</div>
						<Tip
							titulo="Limpar lista"
							tip={
								<div
									className="a"
									onClick={e => {
										onClick('limparLista');
									}}
								>
									Limpar lista
								</div>
							}
							classNameTip="cpnt-profacoes-item"
						>
							<div className="max-w-300">Remove todas as seleções da lista atual. Profissionais selecionados em outras páginas também serão removidos.</div>
						</Tip>
					</div>
					<div className="cpnt-profsel-acoes">
						<Select2
							label="Lista de profissionais selecionados"
							_={{
								value: stateListaAtual,
								options: stateListas,
								onChange: e => {
									setStateListaAtual(e.label);
									setStateSelecionados(getAllUsuariosLocal(e.label));
								}
							}}
							min
						/>
						<div className="cpnt-profsel-btn-opcoes">
							<Tip
								tip={
									<Icone
										imagem={imgIconeCog}
										className="cursor-pointer"
										cor="#0277BD"
										tamanho="26px"
									/>
								}
								click
								closeOnClick
								_defaults={{
									placement: 'left-center'
								}}
							>
								<ListaLocalSelect onClick={onClickSelectListaLocal} />
							</Tip>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { ProfissionaisAcoes };