import React, { useContext, useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalProjetosFormEdicao.css';

// API
import apiProjects from '@api/projects/projects';
import { api } from "@_api/api";

// Partes
import FormProjetos from '@page/Logado/Projetos/FormProjetos/FormProjetos';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { msgs, msgStatus } from "@_func/request";
import { apiGetAreas, apiGetCenarios, apiGetClientes, apiGetEmpresas, apiGetProdutos, apiGetSolucoes, apiGetStatus, apiGetUsuarios } from '@apiGeral/geral';
import { addPrefixo, caminhoFoto, camposInvalidos, conveterRealParaNumero, dataApiCrop, formatarDinheiro, formatarSelect, removerKeysVazias, sanitize } from '@func/utils';

const PortalProjetosFormEdicao = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-800',
		onSuccess = () => null,
		onError = () => null,
		acessoRestrito,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		active: 1, id_company: store.usuario.id_company,
		id_customer: null, name: '',
		// product_type: 0,
		project_type: 0, status: 1, scenery_type: 0,
		service_type: 1, start_date: '', end_date: '', total_revenue: 0, total_recipe: 0, realized_revenue: 0,
		monthly_budget: 0, expected_total_cost: 0, realized_cost: 0, percentage_completed: 0,
		restricted: 0, description: '', attachs: [], team: [], teamFreelancers: [],
		// solutions: [],
		teamMentors: [],
		teamProfessionalsInvolved: []
	};

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);
	const [loadingGetClientes, setLoadingGetClientes] = useState(false);
	const [loadingGetUsuarios, setLoadingGetUsuarios] = useState(false);
	const [loadingGetProdutos, setLoadingGetProdutos] = useState(false);
	const [loadingGetCenarios, setLoadingGetCenarios] = useState(false);
	const [loadingGetStatus, setLoadingGetStatus] = useState(false);
	const [loadingGetSolucoes, setLoadingGetSolucoes] = useState(false);
	const [loadingGetAreas, setLoadingGetAreas] = useState(false);

	// Edição
	const [loadingEditarProjeto, setLoadingEditarProjeto] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({});
	const [_stateDados, _setStateDados] = useState({});
	const [stateEmpresas, setStateEmpresas] = useState([]);
	const [stateClientes, setStateClientes] = useState([]);
	const [stateProdutos, setStateProdutos] = useState([]);
	const [stateCenarios, setStateCenarios] = useState([]);
	const [stateStatus, setStateStatus] = useState([]);
	const [stateAnexos, setStateAnexos] = useState([]);
	const [stateUsuarios, setStateUsuarios] = useState([]);
	const [stateFreelancers, setStateFreelancers] = useState([]);
	const [stateDescricao, setStateDescricao] = useState([]);
	const [stateUsuariosSelecionado, setStateUsuariosSelecionado] = useState([]);
	const [stateSolucoes, setStateSolucoes] = useState([]);
	const [stateAreas, setStateAreas] = useState([]);
	const [stateRetornoAlgolia, setStateRetornoAlgolia] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** FREELANCER
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await apiProjects.getOne.get(id);

		if(retGetItem.status === 200) {
			await setDadosProjeto(retGetItem.data);

			_setStateDados({
				status: 200,
			});

			if(store.usuario.role === 1) {
				getEmpresas();
			}

			getClientes(retGetItem.data.id_company);
			getSolucoes();
			getProdutos();
			getCenarios();
			getStatus();
			getUsuarios(retGetItem.data.id_company);
			getAreas();
		} else {
			_setStateDados(msgStatus(retGetItem));
			msgs([retGetItem]);
		}

		setLoading(false);
	}

	/*
	** API - Funções
	** ================================================== */
	const getEmpresas = async () => await apiGetEmpresas({setLoadingGetEmpresas, setStateEmpresas});
	const getClientes = async (idEmpresa) => await apiGetClientes({id: idEmpresa, setLoadingGetClientes, setStateClientes});
	const getUsuarios = async (idEmpresa) => await apiGetUsuarios({id: idEmpresa, setLoadingGetUsuarios, setStateUsuarios});
	const getProdutos = async () => await apiGetProdutos({setLoadingGetProdutos, setStateProdutos});
	const getCenarios = async () => await apiGetCenarios({setLoadingGetCenarios, setStateCenarios});
	const getStatus = async () => await apiGetStatus({setLoadingGetStatus, setStateStatus});
	const getSolucoes = async () => await apiGetSolucoes({setLoadingGetSolucoes, setStateSolucoes});
	const getAreas = async () => await apiGetAreas({setLoadingGetAreas, setStateAreas});

	/*
	** Define as chaves do projeto
	** ================================================== */
	const setDadosProjeto = async dados => {

		// Carrega usuários da empresa atual
		var getTimeFormatado = [];

		// Usuários
		if(dados.id_company) {
			const usuariosEmpresa = await api.users.getAllUsers.get(dados.id_company, 2);
			if(usuariosEmpresa.status === 200) {
				getTimeFormatado = formatarSelect({dados: usuariosEmpresa.data, keyValue: 'id', keyIcone: 'photo', iconClass: 'border-radius-50'})
			}
		}

		// Descrição
		if(!dados.description) {
			dados.description = '';
		}

		// Soluções
		if(dados.solutions && dados.solutions.length > 0) {
			dados.solutions = [...dados.solutions.map(item => {
				return {
					...item,
					label: item.name_solution,
					value: item.id_solution,
					icon: item.img_solution,
				}
			})];
		}

		// Anexos
		var anexos = [];
		if(dados.attachs && dados.attachs.length > 0) {
			anexos = [...dados.attachs.map(item => {
				return {
					...item,
					label: item.label,
					link: addPrefixo(item.link),
				}
			})]
		}

		// Equipe
		var equipeSelecionada = [];
		if(dados.team && dados.team.length > 0) {
			equipeSelecionada = [...dados.team.map(item => {
				return {
					...item,
					id_user: item.id_user,
					role: item.role,
					// value: item.id_user,
				}
			})]
		}

		// Freelancers
		var freelancers = [];
		var freelancersAlgolia = [];

		if(dados.teamFreelancers && dados.teamFreelancers.length > 0) {
			freelancers = [...dados.teamFreelancers.map(item => {
				freelancersAlgolia.push({
					value: item.id_freelancer,
					label: item.name,
					photo: caminhoFoto(item.photo).url,
					id: item.id_freelancer,
					email: item.email || '(email não informado)',
					price: item.price,
					valor: item.price ? formatarDinheiro(item.price, 'R$') : '(valor não informado)',
					area: item.area_name || '(Área não informada)',
				});
				return {
					...item,
					value: item.id_freelancer,
					label: item.name,
					id_freelancer: item.id_freelancer,
					id_area: item.id_area,
				}
			})]
		}

		setStateDados(dados);
		setStateAnexos(anexos);
		setStateUsuarios(getTimeFormatado);
		setStateFreelancers(freelancers);
		setStateRetornoAlgolia(freelancersAlgolia);
		setStateUsuariosSelecionado(equipeSelecionada);
		setStateDescricao(dados.description);

		return true;
	}

	/*
	** Edita o projeto
	** ================================================== */
	const editarProjeto = async e => {
		e.preventDefault();
		if(loadingEditarProjeto) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		// Anexos
		var anexos = [];
		var anexosInvalidos = false;
		if(stateAnexos && stateAnexos.length && stateAnexos.length > 0) {
			anexos = [...stateAnexos.map(item => {
				if(!item.label || item.label === '' || !item.link || item.link === '') {
					anexosInvalidos = true;
				}
				return {
					label: item.label,
					link: addPrefixo(item.link),
				}
			})]
		}
		if(anexosInvalidos) {
			msg.error('Anexos: você inseriu um anexo sem preencher o título ou o link, verifique antes de prosseguir.');
			return;
		}

		// Soluções
		var solucoes = [];
		if(stateDados.solutions && stateDados.solutions.length && stateDados.solutions.length > 0) {
			solucoes = [...stateDados.solutions.map(item => {
				return {
					id_solution: item.value,
				}
			})]
		}

		// Equipe
		var team = [];
		var equipeInvalida = false;
		if(stateUsuariosSelecionado && stateUsuariosSelecionado.length && stateUsuariosSelecionado.length > 0) {
			team = [...stateUsuariosSelecionado.map(item => {
				if(!item.id_user || item.id_user === '' || !item.role || item.role === '') {
					equipeInvalida = true;
				}
				return {
					id_user: item.id_user,
					role: item.role,
				}
			})];
		}

		if(equipeInvalida) {
			msg.error('Time: é necessário selecionar um integrante e um papel.');
			return;
		}

		// Freelancers
		var freelancers = [];
		var freelancersInvalidos = false;
		if(stateFreelancers && stateFreelancers.length && stateFreelancers.length > 0) {
			freelancers = [...stateFreelancers.map(item => {
				if(!item.id_freelancer || item.id_freelancer === '' || !item.id_area || item.id_area === '') {
					freelancersInvalidos = true;
				}
				return {
					id_freelancer: Number(item.id_freelancer),
					id_area: item.id_area,
				}
			})]
		}
		if(freelancersInvalidos) {
			msg.error('Freelancers: é necessário selecionar um freelancer e uma área.');
			return;
		}

		const dadosEnviar = {
			...camposDefault,
			...stateDados,

			description: sanitize(stateDescricao),

			attachs: anexos,
			team: team,
			solutions: solucoes,
			teamFreelancers: freelancers,

			// Datas
			start_date: dataApiCrop(stateDados.start_date),
			end_date: dataApiCrop(stateDados.end_date),

			// Valores
			monthly_budget: conveterRealParaNumero(stateDados.monthly_budget),
			expected_total_cost: conveterRealParaNumero(stateDados.expected_total_cost),
			total_revenue: conveterRealParaNumero(stateDados.total_revenue),
		};

		// Projetos finalizados define 100%
		if(dadosEnviar.status === 3) {
			dadosEnviar.percentage_completed = 100;
		}

		if(camposInvalidos(dadosEnviar, {}, ['id_company', 'id_customer', 'name'])) return;

		const dadosEnviarSemKeysVazias = removerKeysVazias(dadosEnviar);

		// Restaura as keys dos preços
		dadosEnviarSemKeysVazias.monthly_budget = dadosEnviar.monthly_budget;
		dadosEnviarSemKeysVazias.expected_total_cost = dadosEnviar.expected_total_cost;
		dadosEnviarSemKeysVazias.total_revenue = dadosEnviar.total_revenue;

		setLoadingEditarProjeto(true);

		const retUpdateProjeto = await apiProjects.update.put(dadosEnviarSemKeysVazias);

		if(retUpdateProjeto.status === 200) {
			msg.success('Projeto atualizado com sucesso.');
			onSuccess({
				ret: retUpdateProjeto,
			})
		} else {
			msgs([retUpdateProjeto]);
			onError({
				ret: retUpdateProjeto,
			});
		}

		setLoadingEditarProjeto(false);
	}


	return (
		<Portal
			titulo="Editar projeto"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				typeof(stateDados) === 'object' ? (
					<div className={wrap}>
						<div className={className}>
							{loading ? (
								<Loading
									texto="Carregando, aguarde..."
								/>
							) : (
								_stateDados.status === 200 ? (
									<FormProjetos
										onSubmit={editarProjeto}
										isCadastro={true}
										// States
										stateDados={stateDados}
										setStateDados={setStateDados}
										stateDescricao={stateDescricao}
										setStateDescricao={setStateDescricao}
										stateEmpresas={stateEmpresas}
										stateClientes={stateClientes}
										stateProdutos={stateProdutos}
										stateCenarios={stateCenarios}
										stateStatus={stateStatus}
										stateAnexos={stateAnexos}
										setStateAnexos={setStateAnexos}
										stateUsuarios={stateUsuarios}
										stateUsuariosSelecionado={stateUsuariosSelecionado}
										setStateUsuariosSelecionado={setStateUsuariosSelecionado}
										stateFreelancers={stateFreelancers}
										setStateFreelancers={setStateFreelancers}
										stateSolucoes={stateSolucoes}
										stateAreas={stateAreas}
										setStateAreas={setStateAreas}
										stateRetornoAlgolia={stateRetornoAlgolia}
										setStateRetornoAlgolia={setStateRetornoAlgolia}
										// Loading
										loadingGetEmpresas={loadingGetEmpresas}
										loadingGetClientes={loadingGetClientes}
										loadingGetProdutos={loadingGetProdutos}
										loadingGetCenarios={loadingGetCenarios}
										loadingGetStatus={loadingGetStatus}
										loadingGetSolucoes={loadingGetSolucoes}
										loadingGetUsuarios={loadingGetUsuarios}
										loadingGetAreas={loadingGetAreas}
										// funções
										getClientes={getClientes}
										getUsuarios={getUsuarios}
										acessoRestrito={acessoRestrito}
										after={
											<div className="form-bottom">
												<Button2
													_={{
														value: 'Cancelar',
														type: 'button',
														onClick: onClose,
														className: 'btn btn-1'
													}}
												/>
												<Button2
													_={{
														value: 'Salvar alterações',
														onClick: e => editarProjeto(e),
													}}
													wrap="margin-l-15"
													loading={loadingEditarProjeto}
												/>
											</div>
										}
									/>
								) : (
									<Info
										texto={_stateDados.msg}
										icone="erro"
									/>
								)
							)}
						</div>
					</div>
				) : (
					<div className="padding-30">
						<Info icone="erro" texto={_stateDados.msg} />
					</div>
				)
			)}
		</Portal>
	);
};

export default PortalProjetosFormEdicao;