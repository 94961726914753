import React from 'react';

// css
import './FooterPage.css';

// Imagens
import imgLogoCrowd from '@img/logo.svg';

const FooterPage = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
	} = props;

	return (
		<>
			<div className={`cpnt-footerpage ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-footerpage-box ${className}`}>
					<div className="base">
						<div className="base-content">
							<div className="cpnt-footerpage-inner">
								<div className="copt-footerpage-logo-wrap">
									<img src={imgLogoCrowd} alt="CROWD" className="block w-100p" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { FooterPage };