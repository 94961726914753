import React from 'react';

// Componentes
import Checkbox2 from "@cpnt/Checkbox2/Checkbox2";
import Tip from "@cpnt/Tip/Tip";

const PtNotificacoes = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		stateDados = {},
		setStateDados,
	} = props;

	return (
		<>
			<div className={`cpnt-notific ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-notific-box ${className}`}>
					<div>
						<div>Você pode selecionar separadamente como deseja ser notificado.</div>
						<div className="margin-t-15"><strong>Lembre-se:</strong> caso você escolha não ser notificado por e-mail e nem via WhatsApp, você precisará acessar a plataforma para saber se há novos jobs ou se os seus jobs foram aprovados, diminuindo muito as chances de você responder propostas à tempo e conseguir fechar o job, ou até mesmo de não ser informado sobre a data de emissão da nota fiscal para receber o seu pagamento.</div>
					</div>
					<div className="wrap-5 margin-t-15">
						<div className="flex flex-wrap">
							<div className="input-box box-1">
								<Checkbox2
									label={
										<>
											<span>Aceito receber notificações de <strong>jobs</strong> por e-mail</span>
										</>
									}
									_={{
										checked: stateDados.accept_jobs_mail,
										onChange: e => {
											setStateDados({
												...stateDados,
												accept_jobs_mail: e.target.checked,
											})
										}
									}}
								/>
							</div>
							<div className="input-box box-1">
								<Checkbox2
									label={
										<>
											<span>Aceito receber notificações de <strong>jobs</strong> via WhatsApp</span>
										</>
									}
									_={{
										checked: stateDados.accept_jobs_whatsapp,
										onChange: e => {
											setStateDados({
												...stateDados,
												accept_jobs_whatsapp: e.target.checked,
											})
										}
									}}
								/>
							</div>
							<div className="input-box box-1">
								<Checkbox2
									label={
										<div className="flex flex-center-v">
											<span>Desejo receber e-mails de <strong>Newsletter</strong></span>
											<Tip
												tip={<span className="q"></span>}
												info
												_defaults={{
													placement: 'right-center',
												}}
											>
												Comunicações de marketing sobre novidades da plataforma, novos conteúdos e benefícios para você.
											</Tip>
										</div>
									}
									_={{
										checked: stateDados.accept_newsletter,
										onChange: e => {
											setStateDados({
												...stateDados,
												accept_newsletter: e.target.checked,
											})
										}
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { PtNotificacoes };
