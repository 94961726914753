import React from 'react';

// CSS
import './InputTelefone.css';

const InputTelefone = props => {

	const {
		_ = {},
		label = false,
		wrap = '',
		className = '',
		invalid,
		req,
	} = props;

	if(_.value !== undefined && _.value === null) {
		_.value = '';
	}

	if(_.defaultValue !== undefined && _.defaultValue === null) {
		_.defaultValue = '';
	}

	const validarTelefone = (event) => {
		let { value } = event.target

		value = value.replace(/\D/g, '');
		value = value.replace(/(\d{2})/, '($1) ');
		const isMobilePhone = /^[(][0-9][0-9][)][\s][9]/.test(value);

		if (!/[0-9]/.test(event.key)) {
			event.preventDefault();
		}

		if (isMobilePhone) {
			event.currentTarget.maxLength = 15;
			value = value.replace(/\D/g, '');
			value = value.replace(/(\d{2})(\d{1})/, '($1) $2');
			value = value.replace(/(\d{5})/, '$1-');
			value = value.replace(/(\d{4})/, '$1');
		} else {
			event.currentTarget.maxLength = 14;
			value = value.replace(/(\d{4})/, '$1-');
			value = value.replace(/(\d{4})/, '$1');
		}
		event.currentTarget.value = value;

		if(_.onChange) {
			_.onChange(value);
		}
	}

	return (
		<div className={`cpnt-inputtelefone ${wrap}`}>
			<div className={`cpnt-inputtelefone-box cpnt-inputtelefone-box-ativo ${className} ${invalid ? 'cpnt-inputtelefone-box-invalido' : ''}`}>
				{_.value !== undefined && _.value !== null ? (
					<input
						type="tel"
						onChange={validarTelefone}
						{...(_.value ? {value: _.value} : {})}
						{...(_.name ? {name: _.name} : {})}
						{...(_.defaultValue ? {defaultValue: _.defaultValue} : {})}
					/>
				) : (
					<input
						type="tel"
						onKeyUp={validarTelefone}
						{...(_.name ? {name: _.name} : {})}
						{...(_.defaultValue ? {defaultValue: _.defaultValue} : {})}
					/>
				)}
				{label && <label className="label">{label}{req && <span className="cpnt-inputtelefone-req">*</span>}</label>}
			</div>
		</div>
	)
}

export default InputTelefone;