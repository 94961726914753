import React from 'react';

// css
import './Estrelas.css';

const Estrelas = props => {
	const {
		className = '',
		qtd = 0,
		max = 5,
		corAtivo,
		classNameAtivo = '',
		corInativo,
		classNameInativo = '',
		wrap = 'flex',
		tamanho,
	} = props;

	const porcentagem = () => {
		return (qtd * 100) / max;
	}

	return (
		<div className={`${wrap}`}>
			<div className={`cpnt-estrelas ${className}`} {...(tamanho ? {style: {minWidth: tamanho, maxWidth: tamanho}} : {})}>
				<div
					className={`cpnt-estrelas-icone cpnt-estrelas-icone-cinza ${classNameInativo}`}
					style={{...(corInativo ? {backgroundColor: corInativo} : {})}}
				></div>
				<div
					className={`cpnt-estrelas-icone cpnt-estrelas-icone-amarela ${classNameAtivo}`}
					style={{
						width: `${porcentagem()}%`,
						...(corAtivo ? {backgroundColor: corAtivo} : {})
					}}
				></div>
			</div>
		</div>
	);
};

export default Estrelas;