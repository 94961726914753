import { validarRetorno, config } from '../axios';
import api from 'axios';
import qs from 'qs';
import queryString from 'query-string';
import { stringify } from '@func/utils';

const apiProjects = {
	insert: {
		post: async dados => {
			return await api.post(`/projects/insert`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getOne: {
		get: async id => {
			return await api.get(`/projects/getOne/${id}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	counts: {
		get: async id => {
			return await api.get(`/projects/counts?id_company=${id}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	all: {
		get: async dadosObj => {
			var dadosEnviar = encodeDados({
				dados: dadosObj,
				keys: ['id_user'],
			});
			return await api.get(`/projects/all?${dadosEnviar}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	update: {
		put: async dados => {
			return await api.put(`/projects/update`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

const encodeDados = ({dados, keys}) => {
	var retorno = '';
	if((dados && typeof(dados) === 'object') && (keys && typeof(keys) === 'object' && keys.length)) {
		var dadosToQueryString = {};
		var dadosDefault = {};
		Object.keys(dados).map((val, key) => {
			if(!keys.includes(val)) {
				dadosToQueryString[val] = dados[val];
			} else {
				dadosDefault[val] = dados[val];
			}
		});
		retorno = `${queryString.stringify(dadosToQueryString)}&${stringify(dadosDefault)}`;
	}
	return retorno;
}

export default apiProjects;