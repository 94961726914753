import React from 'react';

// CSS
import { HomeScreen } from "./HomeScreen/HomeScreen";

// Img
import imgIconeMatch from '@_svg/match.svg';
import imgIconeAcademy from '@fa/solid/book-open.svg';
import imgIconeBusca from '@fa/solid/magnifying-glass.svg';
import imgIconeBlog from '@fa/solid/newspaper.svg';

// URLs
import { URL_BLOG, URL_CROWD_ACADEMY } from "@_config/config";

export const HomeAdminScreen = () => {
	return (
		<HomeScreen
			boxes={[
				{
					icone: imgIconeBusca,
					titulo: 'Busca',
					desc: 'Acesse mais de 30.000 profissionais',
					link: '/buscar-profissionais',
				},
				{
					icone: imgIconeMatch,
					titulo: 'Briefing/Vaga',
					desc: 'Publique e nossa IA faz o match',
					link: '/briefing',
				},
				{
					icone: imgIconeAcademy,
					titulo: 'Academy',
					desc: 'Cursos de transformação digital',
					link: URL_CROWD_ACADEMY,
				},
				{
					icone: imgIconeBlog,
					titulo: 'Blog',
					desc: 'Dicas em marketing e tecnologia.',
					link: URL_BLOG,
				},
			]}
		/>
	);
}
