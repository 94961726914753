import React from 'react';

// Funções
import { pixExibicao } from '@config/pix';

const DadosBancarios = props => {
	const {
		className = '',
		wrap = '',

		id_payment_type,
		payment_type,
		full_name,
		cpf,
		name,
		agency,
		account,
		pix_type,
		pix_value,
		swift_bic_code,
		iban_account_number,
	} = props;

	/*
	** Dados Bancários
	** ================================================== */
	const exibirDadosBancarios = () => {
		if(id_payment_type === 1) {
			return (
				<div>
					<div>
						<strong>Tipo selecionado: </strong>
						<span>{payment_type}</span>
					</div>
					<div className="sep-t padding-t-10 margin-t-10">
						<strong>Títular: </strong>
						<span>{full_name ? full_name : 'Não informado'}</span>
					</div>
					<div className="sep-t padding-t-10 margin-t-10">
						<strong>CPF: </strong>
						<span>{cpf ? cpf : 'Não informado'}</span>
					</div>
					<div className="sep-t padding-t-10 margin-t-10">
						<strong>Banco: </strong>
						<span>{name ? name : 'Não informado'}</span>
					</div>
					<div className="sep-t padding-t-10 margin-t-10">
						<strong>Agência: </strong>
						<span>{agency ? agency : 'Não informado'}</span>
					</div>
					<div className="sep-t padding-t-10 margin-t-10">
						<strong>Conta: </strong>
						<span>{account ? account : 'Não informado'}</span>
					</div>
				</div>
			)
		}

		// PIX
		else if(id_payment_type === 4) {
			return (
				<div>
					<div>
						<strong>Tipo selecionado: </strong>
						<span>{payment_type}</span>
					</div>
					<div className="sep-t padding-t-10 margin-t-10">
						<strong>Tipo de chave: </strong>
						<span>{pix_type ? pixExibicao(pix_type) : 'Não informado'}</span>
					</div>
					<div className="sep-t padding-t-10 margin-t-10">
						<strong>Chave: </strong>
						<span>{pix_value ? pix_value : 'Não informado'}</span>
					</div>
				</div>
			)
		}

		// PIX
		else if(id_payment_type === 3) {
			return (
				<div>
					<div>
						<strong>Tipo selecionado: </strong>
						<span>{payment_type}</span>
					</div>
					<div className="sep-t padding-t-10 margin-t-10">
						<strong>Full name or account holder: </strong>
						<span>{full_name ? full_name : 'Não informado'}</span>
					</div>
					<div className="sep-t padding-t-10 margin-t-10">
						<strong>SWIFT / BIC code: </strong>
						<span>{swift_bic_code ? swift_bic_code : 'Não informado'}</span>
					</div>
					<div className="sep-t padding-t-10 margin-t-10">
						<strong>IBAN / Account number: </strong>
						<span>{iban_account_number ? iban_account_number : 'Não informado'}</span>
					</div>
				</div>
			)
		}

		return (
			<div>
				<h3>Tipo selecionado: {payment_type || 'Nenhum tipo informado'}</h3>
				<div>Nenhuma forma de pagamento foi informado.</div>
			</div>
		)
	}

	return (
		<div className={`cpnt-dadosbancarios ${wrap}`}>
			<div className={`cpnt-dadosbancarios-box ${className}`}>
				{exibirDadosBancarios()}
			</div>
		</div>
	);
};

export default DadosBancarios;