import React from 'react';
import moment from 'moment/min/moment-with-locales';

// css
import './ListaTituloDesc.css';

// Temp
import imgPlaceholder from '@img/placeholder/1x1.png';
import { caminhoFoto, dataApiParaBr, dataApiParaBrExtenso, dataPorExtenso } from '@func/utils';
import Loading from '../Loading/Loading';
import { primeiroNome } from "@_func/regex";

const ListaTituloDesc = props => {
	const {
		className = '',
		wrap = '',
		onClickItem = () => null,
		itens = [],
		loading,
		loadingItem,
	} = props;

	return (
		<div className={`cpnt-lista-titulodesc ${wrap}`}>
			<div className={`cpnt-lista-titulodesc-box relative ${className}`}>
				{loading && !itens.length ? (
					<div className="padding-30">
						<Loading />
					</div>
				) : (
					<>
						{loading ? <Loading absolute /> : null}
						{itens.length ? itens.map((val, key) => {
							return (
								<div key={key} className="cpnt-lista-titulodesc-wrap" onClick={() => onClickItem(val)}>
									{loadingItem && loadingItem === val.id ? (
										<Loading texto="" absolute alt />
									) : null}
									<div className="cpnt-lista-titulodesc-img-box">
										<div className="cpnt-lista-titulodesc-img border-radius-50 bg" style={{backgroundImage: `url(${caminhoFoto(val.edited_photo).url})`}}>
											<img src={imgPlaceholder} alt="img" className="block w-100p" />
										</div>
									</div>
									<div className="cpnt-lista-titulodesc-info">
										<div className="cpnt-lista-titulodesc-desc text-one-line">{val.reason || '(motivo não informado)'}</div>
										<div className="cpnt-lista-titulodesc-nome-data color-1">
											<div className="cpnt-lista-titulodesc-nome">Editado por {primeiroNome(val.edited_name)}</div>
											<div className="cpnt-lista-titulodesc-data">{dataPorExtenso(val.created_at, true, 'há ', 'Hoje às ', 'Dia ')}</div>
										</div>
									</div>
								</div>
							)
						}) : (
							<div className="padding-20 flex flex-center flex-column">
								<div>Nenhum histórico encontrado.</div>
								<div className="margin-t-15 color-1 fs-14 align-center">
									Caso houver alguma atualização nessa proposta de job, você poderá visualizar o histórico de atualizações aqui.
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default ListaTituloDesc;