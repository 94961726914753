// API
import { msgs, msgStatus } from "@_func/request";
import apiTasksCombos from "@api/tasksCombos/tasksCombos";

// Funções
import { formatarSelect } from "@func/utils";


/*
** API - Get status
** ================================================== */
export const getStatusJobsAdminLiteNivelAcesso = async ({statusAtual, setStateStatus, role}) => {
	const retGetStatusTask = await apiTasksCombos.status.get();
	if(retGetStatusTask.status === 200) {
		var dadosSelect = formatarSelect({dados: retGetStatusTask.data, keyValue: 'id'});
		setStateStatus(statusJobsAdminLiteTratarStatus(dadosSelect, statusAtual, role));
	} else {
		msgs([retGetStatusTask]);
	}
}

export const statusJobsAdminLiteTratarStatus = (listaStatus, status, role) => {
	// if(role === 1) {
	// 	return listaStatus;
	// }

	var statusHead = [
		// Cancelado
		...(status === 16 ? listaStatus.filter(item => item.value === 16) : []),

		// Aprovado
		...(status === 15 ? listaStatus.filter(item => item.value === 15) : []),

		// Execução
		...(status === 14 ? listaStatus.filter(item => item.value !== 13) : []),

		// Execução
		...(status === 13 ? listaStatus.filter(item => item.value !== 15) : []),
	];

	return statusHead;
}
