import React, { useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalEmpresasFormCadastro.css';

// API
import { apiCompany } from '@_api/requests/company/company';

// Partes
import FormEmpresa from '@page/Logado/Empresas/FormEmpresa/FormEmpresa';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { camposInvalidos, removerKeysVazias, validarCnpj, validarEmail } from '@func/utils';
import { msgs, msgStatus } from "@_func/request";

const PortalEmpresasFormCadastro = props => {

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-empresas-form-cadastro',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		name: '', active: 1, cnpj: '', qty_persons: '', name_responsible: '', email_responsible: '', state_registration: '',
		municipal_registration: '', social_reason: '', phone: '', street: '', district: '', zip_code: '', state: '',
		city: '', number: '', complement: '', type: 'J', percentage: 12,

		site: '',
		tipo_taxa: 'fixa',
		porcentagem_taxa: '12',
		data_faturamento_ddl: '',
		tax_percent: '12',
	};

	// Edição
	const [loadingCadastrarEmpresa, setLoadingCadastrarEmpresa] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [stateFechamentoDados, setStateFechamentoDados] = useState([]);
	const [stateTaxaVariavelDados, setStateTaxaVariavelDados] = useState([]);

	/*
	** Cadastrar empresa
	** ================================================== */
	const cadastrarEmpresa = async e => {
		e.preventDefault();

		if(loadingCadastrarEmpresa) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			name: stateDados.name,
			active: stateDados.active,
			cnpj: stateDados.cnpj,
			qty_persons: stateDados.qty_persons,
			name_responsible: stateDados.name_responsible,
			email_responsible: stateDados.email_responsible,
			state_registration: stateDados.state_registration,
			municipal_registration: stateDados.municipal_registration,
			social_reason: stateDados.social_reason,
			phone: stateDados.phone,
			street: stateDados.street,
			district: stateDados.district,
			zip_code: stateDados.zip_code,
			state: stateDados.state,
			city: stateDados.city,
			number: stateDados.number,
			complement: stateDados.complement,
			type: 'J',
			percentage: stateDados.percentage,
		};

		if(camposInvalidos(dadosEnviar, {}, [
			'name',
			'cnpj',
			'name_responsible',
			'social_reason',
			'email_responsible',
			'type',
			'percentage',
		])) return;

		if(!validarCnpj(dadosEnviar.cnpj)) return;

		if(dadosEnviar.email_responsible && dadosEnviar.email_responsible !== '' && !validarEmail(dadosEnviar.email_responsible)) return;

		setLoadingCadastrarEmpresa(true);

		var dadosEnviarSemKeysVazias = removerKeysVazias(dadosEnviar);

		const retUpdateEmpresa = await apiCompany.insert.post(dadosEnviarSemKeysVazias);

		if(retUpdateEmpresa.status === 200) {
			msg.success('Empresa cadastrada com sucesso.');
			onSuccess({
				ret: retUpdateEmpresa,
			})
			setTimeout(() => {
				setStateDados(camposDefault);
			}, 500);
		} else {
			msgs([retUpdateEmpresa]);
			onError({
				ret: retUpdateEmpresa,
			});
		}

		setLoadingCadastrarEmpresa(false);
	}

	return (
		<Portal
			titulo="Cadastrar empresa"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<div className={wrap}>
				<div className={className}>
					<FormEmpresa
						isCadastro
						onSubmit={cadastrarEmpresa}
						stateDados={stateDados}
						setStateDados={setStateDados}
						stateFechamentoDados={stateFechamentoDados}
						setStateFechamentoDados={setStateFechamentoDados}
						stateTaxaVariavelDados={stateTaxaVariavelDados}
						setStateTaxaVariavelDados={setStateTaxaVariavelDados}
						after={
							<div className="form-bottom">
								<Button2
									_={{
										value: 'Cancelar',
										className: 'btn btn-1',
										type: 'button',
										onClick: onClose,
									}}
									classExtra="btn-1"
								/>
								<Button2
									_={{
										value: 'Cadastrar empresa',
										onClick: cadastrarEmpresa
									}}
									loading={loadingCadastrarEmpresa}
									wrap="margin-l-15"
								/>
							</div>
						}
					/>
				</div>
			</div>
		</Portal>
	);
};

export default PortalEmpresasFormCadastro;