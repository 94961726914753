import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// css
import './DetalhesCliente.css';

// Imagens
import imgIconeEmpresaAlt2 from '@img/icones/geral/empresa2.png';

// Funções
import { caminhoFoto, cssBg, htmlToJsx, jsxToHtml } from '@func/utils';
import { gets } from '@route/routeUtils';

const DetalhesCliente = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		className = '',
		wrap = '',
		after,

		stateCliente = {},
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	return (
		<div className={`cpnt-detalhescliente ${wrap}`}>
			<div className={`cpnt-detalhescliente-box ${className}`}>

				<div className="flex">
					<div className="w-150">
						<div className={`w-150 h-150 bg-alt foto-wrap-square`} style={cssBg(caminhoFoto(stateCliente.logo, imgIconeEmpresaAlt2).url)}></div>
					</div>
					<div className="margin-l-30 flex-1">
						<div>
							<strong>Nome do cliente:</strong>
							<span className="margin-l-5">{stateCliente.name} <span className="color-1">(ID: {stateCliente.id})</span></span>
						</div>
						{stateCliente.type !== 'F' ? (
							<div className="sep-t margin-t-5 padding-t-5">
								<strong>Razão Social:</strong>
								<span className="margin-l-5">{stateCliente.social_reason}</span>
							</div>
						) : null}
						<div className="sep-t margin-t-5 padding-t-5">
							<strong>{stateCliente.type !== 'F' ? 'CNPJ:' : 'CPF:'}</strong>
							<span className="margin-l-5">{stateCliente.cpf_cnpj}</span>
						</div>
						<div className="sep-t margin-t-5 padding-t-5">
							<strong>Endereço:</strong>
							<span className="margin-l-5">{stateCliente.street}, Nº {stateCliente.number} - Bairro: {stateCliente.municipal_registration} / {stateCliente.state}</span>
						</div>
						<div className="sep-t margin-t-5 padding-t-5">
							<strong>CEP:</strong>
							<span className="margin-l-5">{stateCliente.zip_code}</span>
						</div>
						<div className="sep-t margin-t-5 padding-t-5">
							<strong>Nome do principal contato:</strong>
							<span className="margin-l-5">{stateCliente.name_responsible}</span>
						</div>
						<div className="sep-t margin-t-5 padding-t-5">
							<strong>Telefone:</strong>
							<span className="margin-l-5">{stateCliente.phone}</span>
						</div>
						<div className="sep-t margin-t-5 padding-t-5">
							<strong>E-mail:</strong>
							<span className="margin-l-5"><a href={`mailto:${stateCliente.email_responsible}`}>{stateCliente.email_responsible}</a></span>
						</div>
						<div className="sep-t margin-t-5 padding-t-5">
							<strong>Site:</strong>
							{stateCliente.site && stateCliente.site !== '' ? <span className="margin-l-5"><a href={stateCliente.site} target="_blank" rel="noreferrer">{stateCliente.site}</a></span> : 'Nenhum site informado.'}
						</div>
						<div className="sep-t margin-t-5 padding-t-5">
							<div className="no-wrap-line"><strong>Descrição:</strong></div>
							<div className="box-txt margin-t-10">{htmlToJsx(stateCliente.description) || 'Nenhuma descrição disponível.'}</div>
						</div>
					</div>
				</div>
				{after}
			</div>

		</div>
	);
};

export { DetalhesCliente };