import React, { useEffect, memo } from 'react';

// css
import './ChatListaItem.css';

// Componentes
import Foto from '@cpnt/Foto/Foto';
import Icone from '@cpnt/Icone/Icone';

// Imagens
import imgIconeDoubleCheck from '@svg/outros/doublecheck.svg';

// Funções
import { dataHaQuantoTempo, dataPorExtenso, removerHtml, validarDataAPI } from '@func/utils';
import Tip from "@cpnt/Tip/Tip";

const ChatListaItem = props => {
	const {
		className = '',
		wrap = '',

		_id,
		name,
		photo,
		mensagem,
		data,
		qtdMensagens,
		mensagemLida,
		mensagemNova,
		msgBy,
		ativo,
		propria,

		onClick = () => null,
		isDigitando,
		setIsDigitando = () => null,
		isDigitandoInfo,

		online,
	} = props;

	var dataDia = '';

	if(props._ && typeof(props._) === 'object' && validarDataAPI(props._.created_at)) {
		dataDia = dataHaQuantoTempo(props._.created_at);
	}

	var tipo = 'default';

	if(props._.type && props._.type !== '') {
		tipo = props._.type;
	}

	useEffect(() => {
		if(isDigitando) {
			const timeOutId = setTimeout(() => {
				setIsDigitando(false);
			}, 3000);
			return () => clearTimeout(timeOutId);
		}
	}, [isDigitando]);

	const exibirTextoMensagem = tipoMsg => {
		var tipos = {
			'imagem-url': 'Enviou uma imagem',
			'resposta': 'Enviou uma proposta',
			'sistema': 'Mensagem de sistema',
			'variavel': 'Mensagem informativa',
			'nota-fiscal-xml': 'Enviou a nota fiscal XML',
			'nota-fiscal-pdf': 'Enviou a nota fiscal PDF',
			'contrato': 'Enviou um contrato',
			'contrato-assinado': 'Assinou um contrato',
		};

		if(tipos[tipoMsg]) {
			return tipos[tipoMsg];
		}

		return 'Clique para visualizar a mensagem';
	}

	const textoSemHtml = removerHtml(mensagem);

	return (
		<div onClick={onClick} className={`cpnt-chatlistaitem ${ativo ? 'cpnt-chatlistaitem-ativo' : ''} ${wrap} ${mensagemNova ? 'cpnt-chatlistaitem-msg-nova' : 'cpnt-chatlistaitem-msg-antiga'}`}>
			<div className={`cpnt-chatlistaitem-box ${className}`}>
				<div className="cpnt-chatlistaitem-foto">
					<Foto
						imagem={photo}
					/>
					<div className={`cpnt-chatlistaitem-info ${online ? 'cpnt-chatlistaitem-info-ativo' : 'cpnt-chatlistaitem-info-inativo'}`}></div>
				</div>
				<div className="cpnt-chatlistaitem-nomemsg">
					<div className="cpnt-chatlistaitem-nome text-one-line">{name}</div>
					<div className="cpnt-chatlistaitem-msg-status">
						{msgBy !== 'freelancer' && propria ? (
							<div className="cpnt-chatlistaitem-status">
								<Icone
									imagem={imgIconeDoubleCheck}
									cor={mensagemLida ? '#2596e1' : '#AAA'}
									tamanho="16px"
								/>
							</div>
						) : null}

						{isDigitando && isDigitandoInfo && isDigitandoInfo._id === _id ? (
							<div className="cpnt-chatlistaitem-digitando">digitando...</div>
						) : (
							!mensagem || mensagem === '' ? (
								<div className="cpnt-chatlistaitem-texto text-one-line italic color-3">
									{exibirTextoMensagem(tipo)}
								</div>
							) : (
								<div className="cpnt-chatlistaitem-texto text-one-line" title={textoSemHtml}>{textoSemHtml}</div>
							)
						)}

					</div>
				</div>
				<div className="cpnt-chatlistaitem-dataqtd">
					<div className="cpnt-chatlistaitem-data">
						<span>{data}</span>
						{dataDia !== '' ? (
							<Tip
								tip={
									<span className="cpnt-chatlistaitem-data-tempo">{dataDia}</span>
								}
							>
								<div className="align-center fs-13">
									<div className="strong">Mensagem enviada em:</div>
									<div>{dataPorExtenso(props._.created_at, false)}</div>
								</div>
							</Tip>

						) : null}
					</div>
					{!mensagemLida && qtdMensagens ? (
						<div className="cpnt-chatlistaitem-qtd">{qtdMensagens}</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default memo(ChatListaItem);