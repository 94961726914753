import React, { useContext, useState } from 'react';
import moment from 'moment/min/moment-with-locales';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './FormTemplate.css';

// Componentes
import Input2 from '@cpnt/Input2/Input2';
import { Select2 } from '@cpnt/Select2/Select2';
import Checkbox2 from "@cpnt/Checkbox2/Checkbox2";
import { Editor } from "@_cpnt/Inputs/Editor/Editor";
import Info from "@cpnt/Info/Info";

// Funções
import { CHAT_MSGS_TIPO_CORES, TIPOS_TEMPLATE } from "@config/objetos";
import ChatMensagem from "@cpnt/Chat/ChatMensagem/ChatMensagem";
import { caminhoFoto, tipoTemplate } from "@func/utils";

const FormTemplate = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		onSubmit,
		isCadastro,

		// State
		stateDados,
		setStateDados,

		after,

		stateTemplatesTipo,
		loadingGetTemplatesTipo,

		loadingGetEmpresas,
		stateEmpresas,

		loadingGetProfissoes,
		stateProfissoes,
	} = props;


	/*
	** Renderizao template correto
	** ================================================== */
	const renderTemplate = () => {
		var tipo = tipoTemplate(stateDados.id_type);

		var retorno = [];

		retorno.push(
			<>
				<div key={1} className="input-box box-2">
					<Input2
						label="Título do template"
						tip="Título exibido para identificar o template para ser selecionado."
						req
						_={{
							value: stateDados.title,
							onChange: e => setStateDados({
								...stateDados,
								title: e.target.value
							}),
						}}
					/>
				</div>
				<div className="input-box box-1">
					<Input2
						label="Resumo"
						tip="Descreva em poucas palavras para que serve esse template. Não utilize tags HTML."
						req
						_={{
							type: 'textarea',
							value: stateDados.excerpt,
							onChange: e => setStateDados({
								...stateDados,
								excerpt: e.target.value
							}),
							className: 'h-70'
						}}
					/>
				</div>

				{
				/*
				** Mensagem do chat fica tudo em um lugar mais abaixo
				** Apenas para ficar melhor organizado
				** ================================================== */
				}
				{stateDados.id_type !== 6 ? (
					<div className="input-box box-1">
						{tipo === 'html' ? (
							<>
								<div className="padding-b-5 fs-14 bold">Descrição completa</div>
								<Editor
									value={stateDados.text}
									setValue={e => setStateDados({
										...stateDados,
										text: e
									})}
								/>
							</>
						) : null}
						{tipo === 'texto' ? (
							<Input2
								label="Descrição completa"
								req
								_={{
									type: 'textarea',
									value: stateDados.text,
									onChange: e => setStateDados({
										...stateDados,
										text: e.target.value
									}),
								}}
							/>
						) : null}
					</div>
				) : null}
			</>
		);

		// id: 6  =  tipo: Mensagens personalizadas do chat
		if(stateDados.id_type === 6) {
			retorno.push(
				<div key={2} className="box-1">
					<div className="cptn-formtemplate-msgpreview-box">
						<div className="cptn-formtemplate-msgpreview-box-1">
							<h3 className="titulo margin-t-15 margin-b-10 padding-h-5">Dados da mensagem do chat</h3>
							<div className="input-box box-1">
								<Input2
									label="Título da mensagem do chat"
									tip="Título exibido na caixa de mensagem do chat. Ao contrário dos outros templates, esse título será utilizado no lugar do título do template."
									req
									_={{
										value: stateDados.chat_titulo,
										onChange: e => {
											setStateDados({
												...stateDados,
												chat_titulo: e.target.value,
											})
										},
									}}
								/>
							</div>
							<div className="input-box box-1">
								<Select2
									label="Cor da mensagem"
									req
									_={{
										options: CHAT_MSGS_TIPO_CORES,
										value: stateDados.chat_cor,
										onChange: e => {
											setStateDados({
												...stateDados,
												chat_cor: e.value,
											});
										},
									}}
									loading={loadingGetTemplatesTipo}
								/>
							</div>
							<div className="input-box box-1">
								<Input2
									label="Mensagem"
									req
									_={{
										type: 'textarea',
										value: stateDados.text,
										onChange: e => setStateDados({
											...stateDados,
											text: e.target.value
										}),
									}}
								/>
							</div>
						</div>

						<div className="cptn-formtemplate-msgpreview-box-2">
							<h3 className="titulo margin-t-15 margin-b-10 padding-h-5">Pré-visualização da mensagem</h3>
							<div className="padding-t-5 padding-b-15">
								<ChatMensagem
									{...{
										data: moment().subtract(0, 'minutes').format('HH:mm'),
										mensagem: stateDados.text && stateDados.text !== '' ? stateDados.text : '(não preenchido)',
										metadata: {
											titulo: stateDados.chat_titulo && stateDados.chat_titulo !== '' ? stateDados.chat_titulo : '(não preenchido)',
										},
										name: store.usuario.name,
										photo: caminhoFoto(store.usuario.photo).url,
										type: stateDados.chat_cor,
										propria: false,
									}}
								/>
							</div>
						</div>
					</div>

				</div>
			)
		}

		if(store.usuario.role === 1 && (stateDados.id_type === 13 || stateDados.id_type === 14)) {
			retorno.push(
				<div key={4} className="input-box box-1">
					<Select2
						label="Profissão para cadastro de perfil lite"
						req
						_={{
							options: stateProfissoes,
							value: stateDados.id_profession,
							onChange: e => {
								setStateDados({
									...stateDados,
									id_profession: e.value,
								});
							},
						}}
						loading={loadingGetProfissoes}
					/>
				</div>
			);
		}

		if(!retorno.length) {

			/*
			** Se não encontrar o tipo correto, exibe aviso
			** ================================================== */
			<div className="flex flex-center w-100p padding-t-15">
				<Info
					icone="info"
					texto={
						<div className="max-w-500 margin-auto">
							Não foram encontrados os campos para esse tipo de template. Caso esse tipo de template seja novo, será necessário programar os campos corretos para ele no frontend.
						</div>
					}
					alt
				/>
			</div>

		} else {

			/*
			** Status vai entrar para todos
			** ================================================== */
			retorno.push(
				<div key={3} className="input-box box-1 margin-t-10">
					<Checkbox2
						label={
							<>
								<div>Ativar template para uso</div>
								<div className="color-1 fs-13 margin-t-5">Caso desmarcado, com exceção da tela de gerenciamento de templates, esse template não aparecerá nas listagens.</div>
							</>
						}
						_={{
							checked: stateDados.active,
							onChange: e => {
								setStateDados({
									...stateDados,
									active: e.target.checked,
								});
							},
						}}
					/>
				</div>
			)

		}

		return <>{retorno}</>
	}

	return (
		<form className="relative" onSubmit={onSubmit}>
			{!isCadastro ? (
				<div className="padding-b-20">
					<h3 className="titulo">{stateDados.title}</h3>
					<div className="td-desc">ID do template: {stateDados.id}</div>
				</div>
			) : null}

			<div className="flex wrap-5">
				<div className="flex flex-wrap flex-1 relative">

					{store.usuario.role === 1 ? (
						<div className="input-box box-1">
							<Select2
								label="Empresa"
								tip="Caso o template não seja público, é necessário especificar para qual empresa esse template vai pertencer."
								req
								_={{
									options: stateEmpresas,
									value: stateDados.id_company,
									onChange: e => {
										setStateDados({
											...stateDados,
											id_company: e.value,
											id_type: null,
										});
									},
								}}
								loading={loadingGetEmpresas}
							/>
						</div>
					) : null}

					<div className={`input-box ${stateDados.id_type ? 'box-2' : 'box-1'}`}>
						<Select2
							label="Tipo de template"
							req
							_={{
								options: stateTemplatesTipo,
								value: stateDados.id_type,
								onChange: e => {
									setStateDados({
										...stateDados,
										id_type: e.value,
									});
								},
							}}
							loading={loadingGetTemplatesTipo}
						/>
					</div>

					{!stateDados.id_type ? (
						<div className="flex flex-center w-100p padding-t-15">
							<Info
								icone="info"
								texto={
									<div className="max-w-500 margin-auto">
										Os campos variam de acordo com o tipo de template. Selecione um template para continuar.
									</div>
								}
								alt
							/>
						</div>
					) : (
						renderTemplate()
					)}

				</div>
			</div>

			{after}

		</form>
	);
};

export { FormTemplate };
