import React, { useEffect, useState } from 'react';

// css
import './PortalPerfil.css';

// API
import apiFreelancerAwards from '@api/freelancerAwards/freelancerAwards';
import apiFreelancerCertificate from '@api/freelancerCertificate/freelancerCertificate';
import apiFreelancerCompetence from '@api/freelancerCompetence/freelancerCompetence';
import apiFreelancerExperience from '@api/freelancerExperience/freelancerExperience';
import apiFreelancerFormation from '@api/freelancerFormation/freelancerFormation';
import apiFreelancerGoals from '@api/freelancerGoals/freelancerGoals';
import apiFreelancerLanguages from '@api/freelancerLanguages/freelancerLanguages';
import apiFreelancerPortfolio from '@api/freelancerPortfolio/freelancerPortfolio';
import apiFreelancerSegments from '@api/freelancerSegments/freelancerSegments';
import apiFreelancerSkills from '@api/freelancerSkills/freelancerSkills';
import apiFreelancerSocial from '@api/freelancerSocial/freelancerSocial';

// Components
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';
import apiFreelancer from '@api/freelancer/freelancer';

// Partes
import { msgs } from '@_func/request';
import PerfilFreelancer from './PerfilFreelancer/PerfilFreelancer';

const PortalPerfilFreelancer = props => {
	const {
		show,
		wrap = '',
		onClose,
		closeOnEsc = true,
		className = '',
		portalClassName = 'cpnt-perfil-portal-container',
		id,
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetRedesSociais, setLoadingGetRedesSociais] = useState(true);
	const [loadingGetIdiomas, setLoadingGetIdiomas] = useState(true);

	const [loadingGetHabilidades, setLoadingGetHabilidades] = useState(true);
	const [loadingGetCompetencias, setLoadingGetCompetencias] = useState(true);
	const [loadingGetSegmentos, setLoadingGetSegmentos] = useState(true);
	const [loadingGetExperiencias, setLoadingGetExperiencias] = useState(true);
	const [loadingGetFormacoes, setLoadingGetFormacoes] = useState(true);
	const [loadingGetCertificados, setLoadingGetCertificados] = useState(true);
	const [loadingGetPremios, setLoadingGetPremios] = useState(true);
	const [loadingGetPortfolios, setLoadingGetPortfolios] = useState(true);
	const [loadingGetObjetivos, setLoadingGetObjetivos] = useState(true);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(false);
	const [stateRedesSociais, setStateRedesSociais] = useState([]);
	const [stateIdiomas, setStateIdiomas] = useState([]);
	const [stateHabilidades, setStateHabilidades] = useState([]);
	const [stateCompetencias, setStateCompetencias] = useState([]);
	const [stateSegmentos, setStateSegmentos] = useState([]);
	const [stateExperiencias, setStateExperiencias] = useState([]);
	const [stateFormacoes, setStateFormacoes] = useState([]);
	const [stateCertificados, setStateCertificados] = useState([]);
	const [statePremios, setStatePremios] = useState([]);
	const [statePortfolios, setStatePortfolios] = useState([]);
	const [stateObjetivos, setStateObjetivos] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		inicializarGets();
	}, [show]);

	/*
	** FREELANCER
	** ================================================== */
	const inicializarGets = async () => {
		if(show === true) {

			setStateDados(false);
			setLoading(true);

			const retGetFreelancer = await apiFreelancer.getOne.get(id);
			if(retGetFreelancer.status === 200) {
				setLoading(false);
				setStateDados(retGetFreelancer.data);
				getRedesSociais(retGetFreelancer.data.id_user);
				getIdiomas(id);
				getObjetivos(id);
				getHabilidadesFreelancer(id);
				getCompetencias(id);
				getSegmentos(id);
				getExperiencias(id);
				getFormacoes(id);
				getCertificados(id);
				getPremios(id);
				getPortfolios(id);
			} else {
				setLoading(false);
				setStateDados(retGetFreelancer.error || 'Não foi possível carregar os dados do profissional.');
				msgs([retGetFreelancer]);
			}

		}
	}

	/*
	** API - Objetivos
	** ================================================== */
	const getObjetivos = async id => {
		setLoadingGetObjetivos(true);
		const retGetFreelancerObjetivos = await apiFreelancerGoals.all.get(id);
		if(retGetFreelancerObjetivos.status === 200) {
			setStateObjetivos(retGetFreelancerObjetivos.data);
		} else {
			setStateObjetivos([]);
		}
		setLoadingGetObjetivos(false);
	}

	/*
	** API - Redes sociais
	** ================================================== */
	const getRedesSociais = async idUsuario => {
		setLoadingGetRedesSociais(true);
		const retGetRedesSociais = await apiFreelancerSocial.all.get(idUsuario);
		if(retGetRedesSociais.status === 200) {
			setStateRedesSociais(retGetRedesSociais.data);
		} else {
			setStateRedesSociais([]);
		}
		setLoadingGetRedesSociais(false);
	}

	/*
	** API - Idiomas
	** ================================================== */
	const getIdiomas = async idUsuario => {
		setLoadingGetIdiomas(true);
		const retGetIdiomas = await apiFreelancerLanguages.all.get(idUsuario);
		if(retGetIdiomas.status === 200) {
			setStateIdiomas(retGetIdiomas.data);
		} else {
			setStateIdiomas([]);
		}
		setLoadingGetIdiomas(false);
	}

	/*
	** API - Habilidades
	** ================================================== */
	const getHabilidadesFreelancer = async id => {
		setLoadingGetHabilidades(true);
		const retGetFreelancerHabilidades = await apiFreelancerSkills.all.get(id);
		if(retGetFreelancerHabilidades.status === 200) {
			setStateHabilidades(retGetFreelancerHabilidades.data);
		} else {
			setStateHabilidades([]);
		}
		setLoadingGetHabilidades(false);
	}

	/*
	** API - Competencias
	** ================================================== */
	const getCompetencias = async id => {
		setLoadingGetCompetencias(true);
		const retGetFreelancerCompetencias = await apiFreelancerCompetence.all.get(id);
		if(retGetFreelancerCompetencias.status === 200) {
			setStateCompetencias(retGetFreelancerCompetencias.data);
		} else {
			setStateCompetencias([]);
		}
		setLoadingGetCompetencias(false);
	}

	/*
	** API - Segmentos
	** ================================================== */
	const getSegmentos = async id => {
		setLoadingGetSegmentos(true);
		const retGetFreelancerSegmentos = await apiFreelancerSegments.all.get(id);
		if(retGetFreelancerSegmentos.status === 200) {
			setStateSegmentos(retGetFreelancerSegmentos.data);
		} else {
			setStateSegmentos([]);
		}
		setLoadingGetSegmentos(false);
	}

	/*
	** API - Experiências
	** ================================================== */
	const getExperiencias = async id => {
		setLoadingGetExperiencias(true);
		const retGetExperiencias = await apiFreelancerExperience.all.get(id, {
			orderBy: 'start_date',
			orderType: 'desc',
		});
		if(retGetExperiencias.status === 200) {
			setStateExperiencias(retGetExperiencias.data);
		} else {
			setStateExperiencias([]);
		}
		setLoadingGetExperiencias(false);
	}

	/*
	** API - Formações
	** ================================================== */
	const getFormacoes = async id => {
		setLoadingGetFormacoes(true);
		const retGetFormacoes = await apiFreelancerFormation.all.get(id, {
			orderBy: 'start_date',
			orderType: 'desc',
		});
		if(retGetFormacoes.status === 200) {
			setStateFormacoes(retGetFormacoes.data);
		} else {
			setStateFormacoes([]);
		}
		setLoadingGetFormacoes(false);
	}

	/*
	** API - Certificados
	** ================================================== */
	const getCertificados = async id => {
		setLoadingGetCertificados(true);
		const retGetCertificados = await apiFreelancerCertificate.all.get(id, {
			orderBy: 'issue_date',
			orderType: 'desc',
		});
		if(retGetCertificados.status === 200) {
			setStateCertificados(retGetCertificados.data);
		} else {
			setStateCertificados([]);
		}
		setLoadingGetCertificados(false);
	}

	/*
	** API - Prêmios
	** ================================================== */
	const getPremios = async id => {
		setLoadingGetPremios(true);
		const retGetPremios = await apiFreelancerAwards.all.get(id, {
			orderBy: 'issue_date',
			orderType: 'desc',
		});
		if(retGetPremios.status === 200) {
			setStatePremios(retGetPremios.data);
		} else {
			setStatePremios([]);
		}
		setLoadingGetPremios(false);
	}

	/*
	** API - Portfolios
	** ================================================== */
	const getPortfolios = async id => {
		setLoadingGetPortfolios(true);
		const retGetPortfolios = await apiFreelancerPortfolio.all.get(id);
		if(retGetPortfolios.status === 200) {
			setStatePortfolios(retGetPortfolios.data);
		} else {
			setStatePortfolios([]);
		}
		setLoadingGetPortfolios(false);
	}

	return (
		<Portal
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
			classNameDefault="cpnt-perfil-portal"
			classNameX="x"
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				typeof(stateDados) === 'object' ? (
					<div className={`cpnt-perfil ${wrap}`}>
						<div className={`cpnt-perfil-box ${className}`}>
							<PerfilFreelancer
								{...stateDados}

								// Funções
								onClose={onClose}

								// States
								redesSociais={stateRedesSociais}
								idiomas={stateIdiomas}
								habilidades={stateHabilidades}
								competencias={stateCompetencias}
								segmentos={stateSegmentos}
								experiencias={stateExperiencias}
								formacoes={stateFormacoes}
								certificados={stateCertificados}
								premios={statePremios}
								portfolios={statePortfolios}
								objetivos={stateObjetivos}

								// Loading
								loadingGetRedesSociais={loadingGetRedesSociais}
								loadingGetIdiomas={loadingGetIdiomas}
								loadingGetHabilidades={loadingGetHabilidades}
								loadingGetCompetencias={loadingGetCompetencias}
								loadingGetSegmentos={loadingGetSegmentos}
								loadingGetExperiencias={loadingGetExperiencias}
								loadingGetFormacoes={loadingGetFormacoes}
								loadingGetCertificados={loadingGetCertificados}
								loadingGetPremios={loadingGetPremios}
								loadingGetPortfolios={loadingGetPortfolios}
								loadingGetObjetivos={loadingGetObjetivos}
							/>
						</div>
					</div>
				) : (
					<div className="padding-30">
						<Info icone="erro" texto={stateDados} />
					</div>
				)
			)}
		</Portal>
	);
};

export { PortalPerfilFreelancer };