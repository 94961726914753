import React from 'react';

// CSS
import c from './Loading.module.scss';

// Img
import imgLoading from './loading.svg';

// Cpnf
import { Icone } from "@_cpnt/Icone/Icone";

export const Loading = props => {
	const {
		className = '',
		cor = 'var(--color-1)',
		size = 'x2',
		abs,
		bg = 'var(--color-white-alpha-4)',
		show,
	} = props;

	if(!show) return null;

	return (
		<div className={`${c['container']} ${abs ? c['abs-container'] : ''}`} style={{backgroundColor: bg}}>
			<Icone img={imgLoading} cor={cor} className={`${className} ${size} ${abs ? c['abs'] : ''}`} />
		</div>
	);
}
