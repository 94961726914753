import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalListaFreelancers.css';

// API
import apiProjects from '@api/projects/projects';

// Partes
import DetalhesEquipe from '@page/Logado/Projetos/DetalhesEquipe/DetalhesEquipe';

// Componentes
import Portal from '@cpnt/Portal/Portal';
import Loading from '@cpnt/Loading/Loading';
import Info from '@cpnt/Info/Info';
import Button2 from '@cpnt/Button2/Button2';

// Funções
import { caminhoFoto} from '@func/utils';
import ItemFotoDesc from "@cpnt/ItemFotoDesc/ItemFotoDesc";
import { closePortal, getPortalDados, gets, isShowPortal, showPortal } from "@route/routeUtils";
import { PortalPerfilFreelancer } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilFreelancer";

const PortalListaFreelancers = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		lista,
		show,
		titulo = 'Lista de profissionais selecionados',
		onClose,
		closeOnEsc,
		portalClassName = 'portal-lista-freelancers',
		textoVazio = 'Nenhum profissional encontrado',
		before,
		after,
		portalId = 'profissional-selecionado-perfil',
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	return (
		<>
			<Portal
				titulo={titulo}
				show={show}
				closeOnEsc={closeOnEsc}
				onClose={onClose}
				className={portalClassName}
			>
				{lista && typeof(lista) === 'object' && lista.length ? (
					<>
						{before}
						<div className="fs-14 padding-b-15">Profissionais selecionados: <strong>{lista.length}</strong></div>
						<div className="cpnt-portallistafreela-wrap">
							{lista.map((val, key) => {
								return (
									<ItemFotoDesc
										key={key}
										className="margin-3 cursor-pointer"
										foto={caminhoFoto(val.photo).url}
										titulo={val.name_freelancer}
										subtitulo={val.area}
										onClick={() => {
											showPortal({
												portal: portalId,
												dados: val.id,
												stateGet,
												navigate,
											});
										}}
									/>
								)
							})}
						</div>
						{after}
					</>
				) : (
					<div>{textoVazio}</div>
				)}

				<div className="form-bottom">
					<Button2
						_={{
							value: 'Fechar',
							type: 'button',
							onClick: onClose,
						}}
					/>
				</div>
			</Portal>

			<PortalPerfilFreelancer
				id={getPortalDados({portal: portalId, stateGet})}
				show={isShowPortal({portal: portalId, stateGet})}
				onClose={() => closePortal({portal: portalId, stateGet, navigate})}
			/>
		</>
	);
};

export default PortalListaFreelancers;