import React, { useState, useEffect, useContext } from 'react';
import { toast as msg } from 'react-toastify';
import _ from 'lodash';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './FormCliente.css';

// API
import { apiCompany } from '@_api/requests/company/company';
import apiCnpj from '@api/cnpj/cnpj';
import { apiCep } from '@_api/requests/cep/cep';

// Componentes
import Loading from '@cpnt/Loading/Loading';
import { Select2 } from '@cpnt/Select2/Select2';
import Input from '@cpnt/Input/Input';
import Input2 from '@cpnt/Input2/Input2';
import Radio2 from '@cpnt/Radio2/Radio2';
import InputTelefone from '@cpnt/InputTelefone/InputTelefone';

// Imagens
import imgLoading from '@imgdefault/loading-azul.svg';
import imgIconeEmpresa from '@img/icones/geral/empresa-placeholder.png';

// Funções
import { caminhoFoto, validarCep, validarCnpj, apenasNumeros, kbToMb, typeFileSelecionarImagem, cssBg } from '@func/utils';
import { Editor } from '@_cpnt/Inputs/Editor/Editor';
import { msgs, msgStatus } from "@_func/request";

const FormCliente = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		onSubmit,
		isCadastro,

		// State
		stateDados,
		setStateDados,

		// States
		stateEmpresas,
		loadingGetEmpresas,

		stateLogotipo,
		setStateLogotipo,

		setStateLogotipoNovo,

		after,
	} = props;

	/*
	** States
	** ================================================== */
	const [exibirAviso, setExibirAviso] = useState(true);
	const [loadingPesquisarCep, setLoadingPesquisarCep] = useState(false);
	const [loadingPesquisarCNPJ, setLoadingPesquisarCNPJ] = useState(false);

	/*
	** Logotipo
	** ================================================== */
	const [btnRemoverLogotipo, setBtnRemoverLogotipo] = useState(false);
	const [btnRemoverLogotipoAPI, setBtnRemoverLogotipoAPI] = useState(stateDados.logo ? true : false);
	const [loadingApagandoLogotipo, setLoadingApagandoLogotipo] = useState(false);

	/*
	** Callback - Verifica se foi sucesso e define a iamgem
	** ================================================== */
	const definirImagem = dados => {
		if(dados.sucesso) {
			setStateLogotipo(dados.base64);
			setBtnRemoverLogotipo(true);
			setStateLogotipoNovo(true);
		}
	}

	/*
	** Remove o logotipo
	** ================================================== */
	const removerLogotipo = () => {
		const img = caminhoFoto(stateLogotipo, imgIconeEmpresa);
		setStateLogotipo(img.url);
		if(img.api) {
			setBtnRemoverLogotipo(false);
			setBtnRemoverLogotipoAPI(true);
		} else {
			setBtnRemoverLogotipo(false);
			setBtnRemoverLogotipoAPI(false);
		}
		setStateLogotipoNovo(false);
	}

	/*
	** API - Remove o logotipo
	** ================================================== */
	const removerLogotipoAPI = async () => {

		if(loadingApagandoLogotipo) {
			msg.warning('Por favor, aguarde...');
			return false;
		}
		setLoadingApagandoLogotipo(true);

		const retDeleteLogotipoEmpresa = await apiCompany.foto.delete(1); // ID do logotipo

		if(retDeleteLogotipoEmpresa.status === 200) {
			setStateLogotipo(imgIconeEmpresa);
			setBtnRemoverLogotipo(false);
			setBtnRemoverLogotipoAPI(false);
			msgs([retDeleteLogotipoEmpresa], true);
		} else {
			msgs([retDeleteLogotipoEmpresa]);
		}

		setStateLogotipoNovo(false);
		setLoadingApagandoLogotipo(false);
	}

	/*
	** Endereço
	** ================================================== */
	const pesquisarCEP = async e => {
		setStateDados({
			...stateDados,
			street: '',
			number: '',
			complement: '',
			district: '',
			city: '',
			state: '',
		});

		if(!e || e === '') return;

		if(!validarCep(e)) return;

		setLoadingPesquisarCep(true);

		const retGetCEP = await apiCep.get(e);

		if(retGetCEP.status === 200) {
			msg.success('CEP localizado com sucesso!', {
				autoClose: 2000
			});
			setStateDados({
				...stateDados,
				zip_code: e,
				street: retGetCEP.data.street,
				district: retGetCEP.data.district,
				city: retGetCEP.data.city,
				state: retGetCEP.data.uf,
			});
		} else {
			msgs([retGetCEP]);
		}

		setLoadingPesquisarCep(false);
	};

	/*
	** Pesquisar CNPJ
	** ================================================== */
	const pesquisarCNPJ = async e => {

		setStateDados({
			...stateDados,
			name: '',
			social_reason: '',
			street: '',
			district: '',
			zip_code: '',
			state: '',
			city: '',
			number: '',
			complement: '',
		});

		if(!e || e === '') return;

		if(!validarCnpj(e)) return;

		setLoadingPesquisarCNPJ(true);

		const retGetCNPJ = await apiCnpj.get(apenasNumeros(e));

		if(retGetCNPJ.status === 200) {
			msg.success('CNPJ localizado com sucesso');
			setStateDados({
				...stateDados,
				name: retGetCNPJ.data.nome,
				social_reason: retGetCNPJ.data.fantasia,
				street: retGetCNPJ.data.logradouro,
				district: retGetCNPJ.data.bairro,
				zip_code: retGetCNPJ.data.cep && retGetCNPJ.data.cep.replace('.', ''),
				state: retGetCNPJ.data.uf,
				city: retGetCNPJ.data.municipio,
				number: retGetCNPJ.data.numero,
				complement: retGetCNPJ.data.complemento,
				phone: retGetCNPJ.data.telefone,
				email_responsible: retGetCNPJ.data.email,
				...(_.has(retGetCNPJ.data, 'qsa[0].nome') ? {
					name_responsible: retGetCNPJ.data.qsa[0].nome,
					position_responsible: retGetCNPJ.data.qsa[0].qual || '',
				} : {})
			});
		} else {
			msgs([retGetCNPJ]);
		}

		setLoadingPesquisarCNPJ(false);
	};

	return (
		<form className="formcliente" onSubmit={onSubmit}>
			{!isCadastro ? (
				<div className="padding-b-15">
					<h3 className="titulo">{stateDados.name}</h3>
					<div className="td-desc">ID do cliente: {stateDados.id}</div>
				</div>
			) : null}

			{loadingPesquisarCNPJ && <Loading texto="Consultando CNPJ, aguarde..." absolute />}

			<div className="wrap">
				<div className="formcliente-info-container">
					<div className="formcliente-logo-box">
						<div className="quadro-1 bg-alt relative clientes-logotipo-box" style={cssBg(stateLogotipo || imgIconeEmpresa)}>
							{btnRemoverLogotipo &&
								<span className="foto-video-x" onClick={removerLogotipo}>
									{loadingApagandoLogotipo && <span className="fill bg scale-1-7" style={cssBg(imgLoading)}></span>}
								</span>
							}
							{!btnRemoverLogotipo && btnRemoverLogotipoAPI &&
								<span className="foto-video-x" onClick={removerLogotipoAPI}>
									{loadingApagandoLogotipo && <span className="fill bg scale-1-7" style={cssBg(imgLoading)}></span>}
								</span>
							}
						</div>
						<div className="flex flex-center margin-t-15">
							<label className="w-100p">
								<div className="btn btn-default w-100p">
									{stateDados.type !== 'f' ? 'Selecionar logotipo' : 'Selecionar foto'}
								</div>
								<input type="file" name="foto" className="none" onChange={e => typeFileSelecionarImagem({e: e, cb: definirImagem})} />
							</label>
						</div>
					</div>

					<div className="formcliente-logo-info-box">
						<div className="input-box w-100p padding-t-0">
							<div className="flex margin-t-10">
								<div className="fs-14 bold">Tipo de cadastro:</div>
								<Radio2
									label="Pessoa Jurídica"
									_={{
										checked: stateDados.type === 'j' ||  stateDados.type === 'J' ? true : false,
										name: 'type',
										onChange: e => setStateDados({
											...stateDados,
											type: e.target.checked ? 'j' : false
										}),
									}}
									wrap="margin-l-20"
								/>
								<Radio2
									label="Pessoa Física"
									_={{
										checked: stateDados.type === 'f' || stateDados.type === 'F' ? true : false,
										name: 'type',
										onChange: e => setStateDados({
											...stateDados,
											type: e.target.checked ? 'f' : false
										}),
									}}
									wrap="margin-l-20"
								/>
							</div>
						</div>

						{isCadastro ? (
							<div className={`input-box ${stateDados.type === 'j' || stateDados.type === 'J' ? 'w-50p' : 'w-100p'}`}>
								<Select2
									label="Empresa"
									req
									_={{
										options: stateEmpresas,
										value: stateDados.id_company,
										onChange: e => {
											setStateDados({
												...stateDados,
												id_company: e.value,
											});
										}
									}}
									loading={loadingGetEmpresas}
								/>
							</div>
						) : null }

						{stateDados.type === 'j' || stateDados.type === 'J' ? (
							<div className={`input-box ${isCadastro ? 'w-50p' : 'w-100p'}`}>
								<Input2
									label="CNPJ"
									req
									_={{
										mask: '99.999.999/9999-99',
										value: stateDados.cpf_cnpj,
										onChange: e => {
											setStateDados({
												...stateDados,
												cpf_cnpj: e.target.value,
											})
										},
										onBlur: e => {
											pesquisarCNPJ(e.target.value);
										}
									}}
								/>
							</div>
						) : (
							<div className={`input-box w-50p`}>
								<Input2
									label="CPF"
									req
									_={{
										mask: '999.999.999-99',
										value: stateDados.cpf_cnpj,
										onChange: e => {
											setStateDados({
												...stateDados,
												cpf_cnpj: e.target.value,
											})
										},
									}}
								/>
							</div>
						)}

						<div className="input-box w-50p">
							<Input2
								label="Nome do cliente"
								req
								_={{
									name: 'name',
									value: stateDados.name,
									onChange: e => setStateDados({
										...stateDados,
										name: e.target.value
									}),
								}}
							/>
						</div>

						{stateDados.type === 'j' || stateDados.type === 'J' ? (
							<div className="input-box w-50p">
								<Input2
									label="Razão Social"
									req
									_={{
										value: stateDados.social_reason,
										onChange: e => setStateDados({
											...stateDados,
											social_reason: e.target.value
										}),
									}}
								/>
							</div>
						) : null}

						<div className="input-box w-40p">
							<Input2
								label="Nome do principal contato"
								req
								_={{
									value: stateDados.name_responsible,
									onChange: e => setStateDados({
										...stateDados,
										name_responsible: e.target.value
									}),
								}}
							/>
						</div>
						<div className="input-box w-30p">
							<Input2
								label="Cargo"
								req
								_={{
									value: stateDados.position_responsible,
									onChange: e => setStateDados({
										...stateDados,
										position_responsible: e.target.value
									}),
								}}
							/>
						</div>
						<div className="input-box w-30p">
							<InputTelefone
								label="Telefone"
								req
								_={{
									value: stateDados.phone,
									onChange: e => {
										setStateDados({
											...stateDados,
											phone: e
										});
									},
								}}
							/>
						</div>
						<div className="input-box w-40p">
							<Input2
								label="E-mail"
								req
								_={{
									value: stateDados.email_responsible,
									onChange: e => setStateDados({
										...stateDados,
										email_responsible: e.target.value
									}),
								}}
							/>
						</div>
						<div className="input-box w-60p">
							<Input2
								label="Site"
								_={{
									value: stateDados.site,
									onChange: e => setStateDados({
										...stateDados,
										site: e.target.value
									}),
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="wrap padding-t-10">
				<div className="input-box w-100p">
					<div className="relative padding-b-5 fs-14 bold">
						Descrição / Informações sobre o cliente
					</div>
					<Editor
						value={stateDados.description}
						setValue={e => {
							setStateDados({
								...stateDados,
								description: e
							})
						}}
					/>
				</div>
			</div>

			<div className="padding-t-15">
				<h3 className="titulo">Endereço</h3>
				<div className="relative">
					{loadingPesquisarCep && <Loading texto="Consultando CEP, aguarde..." absolute />}
					<div className="flex flex-wrap wrap-h-5">
						<div className="input-box w-100p">
							<Input2
								label="CEP"
								req
								_={{
									value: stateDados.zip_code,
									onChange: e => {
										setStateDados({
											...stateDados,
											zip_code: e.target.value,
										})
									},
									mask: '99999-999',
									onBlur: e => {
										pesquisarCEP(e.target.value);
									}
								}}
							/>
						</div>
						<div className="input-box w-80p">
							<Input2
								label="Logradouro"
								req
								_={{
									value: stateDados.street,
									onChange: e => setStateDados({
										...stateDados,
										street: e.target.value
									}),
								}}
							/>
						</div>
						<div className="input-box w-20p">
							<Input2
								label="Número"
								req
								_={{
									value: stateDados.number,
									onChange: e => setStateDados({
										...stateDados,
										number: e.target.value
									}),
								}}
							/>
						</div>
						<div className="input-box w-50p">
							<Input2
								label="Complemento"
								_={{
									value: stateDados.complement,
									onChange: e => setStateDados({
										...stateDados,
										complement: e.target.value
									}),
								}}
							/>
						</div>
						<div className="input-box w-50p">
							<Input2
								label="Bairro"
								req
								_={{
									value: stateDados.district,
									onChange: e => setStateDados({
										...stateDados,
										district: e.target.value
									}),
								}}
							/>
						</div>
						<div className="input-box w-50p">
							<Input2
								label="Cidade"
								req
								_={{
									value: stateDados.city,
									onChange: e => setStateDados({
										...stateDados,
										city: e.target.value
									}),
								}}
							/>
						</div>
						<div className="input-box w-50p">
							<Input2
								label="Estado"
								req
								_={{
									value: stateDados.state,
									onChange: e => setStateDados({
										...stateDados,
										state: e.target.value
									}),
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			{after}

		</form>
	);
};

export default FormCliente;