import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalDadosContato.css';

// API
import apiFreelancerCompany from '@api/freelancerCompany/freelancerCompany';
import apiFreelancerPayment from '@api/freelancerPayment/freelancerPayment';
import apiFreelancer from '@api/freelancer/freelancer';

// Componentes
import Portal from '@cpnt/Portal/Portal';
import Loading from '@cpnt/Loading/Loading';
import Info from '@cpnt/Info/Info';

// Funções
import { msgs } from '@_func/request';
import DadosContato from './DadosContato/DadosContato';

const PortalDadosContato = props => {
	const {
		show,
		wrap = '',
		onClose,
		closeOnEsc = true,
		className = '',
		portalClassName = 'cpnt-dados-portal-container',
		idFreelancer,
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetEmpresa, setLoadingGetEmpresa] = useState(true);
	const [loadingGetDadosBancarios, setLoadingGetDadosBancarios] = useState(true);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(false);
	const [stateEmpresa, setStateEmpresa] = useState([]);
	const [stateDadosBancarios, setStateDadosBancarios] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		inicializarGets();
	}, [show]);

	/*
	** FREELANCER
	** ================================================== */
	const inicializarGets = async () => {
		if(show === true) {

			setStateDados(false);
			setLoading(true);

			const retGetFreelancer = await apiFreelancer.getOne.get(idFreelancer);
			if(retGetFreelancer.status === 200) {
				setLoading(false);
				setStateDados(retGetFreelancer.data);
				getEmpresa(idFreelancer);
				getDadosBancarios(idFreelancer);
			} else {
				const mensagem = `Não foi possível carregar os dados do freelancer: ${retGetFreelancer.error}.`;
				setLoading(false);
				setStateDados(mensagem);
				msg.error(mensagem);
			}
		} else {
			// Tempo da animação de fechar, limpa os dados
			setTimeout(() => {
				setStateDados(false);
			}, 400);
		}
	}

	/*
	** API - Redes sociais
	** ================================================== */
	const getEmpresa = async idFreela => {
		setLoadingGetEmpresa(true);
		const retEmpresa = await apiFreelancerCompany.getOne.get(idFreela);
		if(retEmpresa.status === 200) {
			setStateEmpresa(retEmpresa.data);
		} else {
			setStateEmpresa([]);
		}
		setLoadingGetEmpresa(false);
	}

	/*
	** API - Redes sociais
	** ================================================== */
	const getDadosBancarios = async idFreela => {
		setLoadingGetDadosBancarios(true);
		const retDadosBancarios = await apiFreelancerPayment.getOne.get(idFreela);
		if(retDadosBancarios.status === 200) {
			setStateDadosBancarios(retDadosBancarios.data);
		} else {
			setStateDadosBancarios([]);
		}
		setLoadingGetDadosBancarios(false);
	}

	return (
		<Portal
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
			classNameDefault="cpnt-dados-portal cpnt-portal-default"
			classNameX="x"
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				typeof(stateDados) === 'object' ? (
					<div className={wrap}>
						<div className={className}>
							<DadosContato
								{...stateDados}

								// Funções
								onClose={onClose}

								// States
								empresa={stateEmpresa}
								dadosBancarios={stateDadosBancarios}

								// Loading
								loadingGetEmpresa={loadingGetEmpresa}
								loadingGetDadosBancarios={loadingGetDadosBancarios}
							/>
						</div>
					</div>
				) : (
					<div className="padding-30">
						<Info icone="erro" texto={stateDados} />
					</div>
				)
			)}
		</Portal>
	);
};

export default PortalDadosContato;