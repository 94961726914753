import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './FormProfissoesPredefinidas.css';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Checkbox2 from '@cpnt/Checkbox2/Checkbox2';
import Input2 from '@cpnt/Input2/Input2';
import Portal from '@cpnt/Portal/Portal';
import { Select2 } from '@cpnt/Select2/Select2';
import { ListaComCheckbox2 } from "@cpnt/ListaComCheckbox2/ListaComCheckbox2";
import { ListaSecao } from "@cpnt/ListaSecao/ListaSecao";
import { BtnAdd } from "@cpnt/BtnAdd/BtnAdd";
import { PortalTemplates } from "@cpnt/Portais/PortaisTemplates/PortalTemplates/PortalTemplates";
import Tip from "@cpnt/Tip/Tip";

// Funções
import { htmlToJsx, tipoTemplate } from '@func/utils';

// Rota
import { closePortal, getPortalDados, gets, isShowPortal, showPortal } from "@route/routeUtils";
import Radio2 from "@cpnt/Radio2/Radio2";
import { Tag } from "@cpnt/Tag/Tag";

const FormProfissoesPredefinidas = props => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();
	const location = useLocation();

	const {

		className = '',
		wrap = '',

		isCadastro,

		setStateDados = () => null,
		stateDados = {},
		after,

		stateAreas = [],
		stateTemplate,
		setStateTemplate,
		stateEmpresas,

		loadingGetEmpresas,
		loadingGetAreas,
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	return (
		<>
			<div className={`cpnt-form-profhab ${wrap}`}>
				<div className={`cpnt-form-profhab-box ${className}`}>
					{!isCadastro ? (
						<div className="padding-b-20">
							<h3 className="titulo">{stateDados.title}</h3>
							<div className="td-desc">ID: {stateDados.id}</div>
						</div>
					) : null}
					<div>
						<div className="box-1">
							<div className="wrap-5">
								<div className="flex flex-wrap">
									{store.usuario.role === 1 ? (
										<div className="input-box box-1">
											<Select2
												label="Empresa"
												tip="Caso essa profissão não seja pública, é necessário especificar para qual empresa essa profissão vai pertencer."
												req
												_={{
													options: stateEmpresas,
													value: stateDados.id_company,
													onChange: e => {
														setStateDados({
															...stateDados,
															id_company: e.value,
														});
													},
												}}
												loading={loadingGetEmpresas}
											/>
										</div>
									) : null}
									<div className="input-box box-2 box-1-680">
										<Input2
											label="Título"
											req
											_={{
												value: stateDados.title,
												onChange: e => {
													setStateDados({
														...stateDados,
														title: e.target.value,
													});
												},
											}}
										/>
									</div>
									<div className="input-box box-2 box-1-680">
										<Select2
											label="Categoria"
											req
											_={{
												value: stateDados.id_area,
												options: stateAreas,
												onChange: e => {
													setStateDados({
														...stateDados,
														id_area: e.value,
													});
												},
											}}
											loading={loadingGetAreas}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="margin-t-20">
							<div className="flex flex-center-v">
								<h3 className="titulo">Template da proposta</h3>
								<BtnAdd
									titulo="Selecionar template"
									onClick={e => showPortal({portal: 'cadprofissoes-templates', stateGet, navigate})}
									className="margin-l-25 margin-b--3"
								/>
							</div>
							<div className="margin-t-10">
								{stateTemplate.id ? (
									<>
										<Tip
											tip={
												<>
													<div
														className="a"
														onClick={e => showPortal({portal: 'cadprofissoes-template', stateGet, navigate})}
													>
														{stateTemplate.title} (ID {stateTemplate.id})
													</div>
												</>
											}
											info
										>
											Clique para visualizar o template
										</Tip>
										<Tip
											tip={
												<>
													<div
														className="color-1 a margin-l-15"
														onClick={e => {
															setStateDados({
																...stateDados,
																id_template: false,
															});
															setStateTemplate({});
														}}
													>
														(remover template)
													</div>
												</>
											}
											info
										>
											Caso não queira utilizar nenhum template para essa profissão
										</Tip>
									</>
								) : (
									<div className="msg">Nenhum template selecionado.</div>
								)}
								{stateTemplate.id && (
									<div className="texto margin-t-5">{stateTemplate.excerpt || 'Resumo não informado'}</div>
								)}
								{store.usuario.role === 1 && stateDados.id_company === 0 ? (
									<div className="td-desc-alt margin-t-5"><strong>Aviso:</strong> você está cadastrando uma profissão como <strong>pública</strong>. Selecione um template público ou o template não será carregado corretamente para as outras empresas devido as permissões de acesso.</div>
								) : null}
							</div>
						</div>

						<div className="inputs-box margin-t-15">
							<div className="input-box box-1">
								<h3 className="titulo">Tipo</h3>
							</div>
							<div className="input-box">
								<Radio2
									label="Profissional"
									_={{
										checked: stateDados.type === 'P' ? true : false,
										onChange: e => {
											setStateDados({
												...stateDados,
												type: 'P',
											})
										}
									}}
								/>
							</div>
							<div className="input-box">
								<Radio2
									label="Serviço"
									_={{
										checked: stateDados.type === 'S' ? true : false,
										onChange: e => {
											setStateDados({
												...stateDados,
												type: 'S',
											})
										}
									}}
								/>
							</div>

							<div className="input-box box-1 margin-t-15">
								<Input2
									label="Principal habilidade do profissional"
									req
									_={{
										value: stateDados.keyword,
										onChange: e => {
											setStateDados({
												...stateDados,
												keyword: e.target.value,
											});
										},
									}}
								/>
							</div>

							<div className="input-box box-1 margin-t-15">
								{stateDados.keyword && stateDados.keyword !== '' ? (
									<Tag
										texto={stateDados.keyword}
										x={true}
										onClick={e => {
											setStateDados({
												...stateDados,
												keyword: '',
											})
										}}
										className="padding-10"
									/>
								) : (
									<div className="italic">Nenhum termo informado</div>
								)}
							</div>

						</div>

						<div className="input-box box-1 margin-t-25">
							<Checkbox2
								label={
									<>
										<div>Exibir profissão no cadastro de propostas de jobs</div>
										<div className="color-1 fs-13 margin-t-5">Deixe desmarcado quando estiver criando rascunhos ou quando precisar ocultar uma profissão. Assim, a profissão não será exibida no cadastro de propostas de jobs.</div>
									</>
								}
								_={{
									checked: stateDados.active,
									onChange: e => {
										setStateDados({
											...stateDados,
											active: e.target.checked,
										});
									},
								}}
							/>
						</div>
					</div>

					{after}
				</div>
			</div>

			<PortalTemplates
				idTipo={1}
				show={isShowPortal({portal: 'cadprofissoes-templates', stateGet})}
				onClose={() => closePortal({portal: 'cadprofissoes-templates', stateGet, navigate})}
				onSelect={e => {
					setStateDados({
						...stateDados,
						id_template: e.id,
					});
					setStateTemplate(e);
					closePortal({portal: 'cadprofissoes-templates', stateGet, navigate})
				}}
			/>

			<Portal
				titulo="Pré-visualização do template"
				show={isShowPortal({portal: 'cadprofissoes-template', stateGet})}
				id={getPortalDados({portal: 'cadprofissoes-template', stateGet})}
				onClose={() => closePortal({portal: 'cadprofissoes-template', stateGet, navigate})}
				className="cpnt-templates-preview"
			>
				<div className={`box-txt ${tipoTemplate(stateTemplate.id_type) === 'texto' ? 'texto-simples' : ''}`}>{htmlToJsx(stateTemplate.text)}</div>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Fechar',
							onClick: e => closePortal({portal: 'cadprofissoes-template', stateGet, navigate})
						}}
					/>
				</div>
			</Portal>
		</>
	);
};

export default FormProfissoesPredefinidas;