import React, { useEffect, useState } from 'react';

// css
import './PortalProfissionaisSelecionados.css';

// API
import { apiCompany } from '@_api/requests/company/company';

// Partes
import { DetalhesEmpresa } from '@page/Logado/Empresas/DetalhesEmpresa/DetalhesEmpresa';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções

import { msgs, msgStatus } from "@_func/request";
import { DetalhesProfissionaisSelecionados } from '@cpnt/ProfissionaisSelecionados/DetalhesProfissionaisSelecionados/DetalhesProfissionaisSelecionados';
import { getAllUsuariosLocal, initListaLocal, listasLocal } from '@func/selecaoUsuariosBriefings';

const PortalProfissionaisSelecionados = props => {

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-profsel-detalhes',

		stateListaAtual,
		setStateListaAtual,
		stateListas,
		setStateListas,
		stateSelecionados,
		setStateSelecionados,
	} = props;

	return (
		<Portal
			titulo="Profissionais selecionados"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<div className={wrap}>
				<div className={className}>
					<DetalhesProfissionaisSelecionados
						stateListaAtual={stateListaAtual}
						stateSelecionados={stateSelecionados}
						after={
							<div className="form-bottom">
								<Button2
									_={{
										value: 'Fechar',
										type: 'button',
										onClick: onClose,
									}}
								/>
							</div>
						}
					/>
				</div>
			</div>
		</Portal>
	);
};

export { PortalProfissionaisSelecionados };