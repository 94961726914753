import React from 'react';

const PaginasIniciasTexto = props => {
	return (
		<>
			{/* <div>
				<h3>Instantâneo e inteligente: A nova era do recrutamento com IA chegou.</h3>
				<p>Contrate talentos da nova economia 4x mais rápido da maneira que desejar: CLT, PJ ou freelancer.</p>
			</div> */}
		</>
	);
};

export { PaginasIniciasTexto };