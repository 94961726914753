import React, { useContext, useState } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// Componentes
import Button2 from "@cpnt/Button2/Button2";
import LinhaItem from "@cpnt/LinhaItem/LinhaItem";
import Loading from "@cpnt/Loading/Loading";
import Portal from "@cpnt/Portal/Portal";
import { Tag } from "@cpnt/Tag/Tag";

// Funções
import { dataApiParaBr, htmlToJsx, matchTipo, tipoTemplate } from '@func/utils';

const DetalhesProfissoesPredefinidas = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
		wrap = '',
		stateDados,
		loadingGetTemplate,
		stateTemplate,
		after,
	} = props;

	const [showTemplate, setShowTemplate] = useState(false);

	return (
		<>
			<div className={`cpnt-detalhesequipe ${wrap}`}>
				<div className={`cpnt-detalhesequipe-box ${className}`}>

					<div>
						<h3 className="titulo margin-t-0">{stateDados.title}</h3>
						<div className="td-desc">ID: {stateDados.id}</div>
					</div>

					{store.usuario.role === 1 ? (
						<div>
							<h3 className="margin-b-0">{stateDados.company_name ? 'Criado pela empresa' : 'Profissão pública'}</h3>
							<div>
								{stateDados.company_name ? stateDados.company_name : 'Visível para todas as empresas'}
							</div>
						</div>
					) : null}

					<div>
						<h3 className="margin-b-0">Categoria</h3>
						<div>
							{stateDados.area_name}
						</div>
					</div>

					<div>
						<div className="flex flex-center-v">
							<h3 className="">Template</h3>
							{stateTemplate.text ? (
								<div className="a margin-l-15" onClick={e => setShowTemplate(true)}>(visualizar)</div>
							) : null}
						</div>
						{loadingGetTemplate ? (
							<Loading alt absolute />
						) : (
							stateTemplate.id ? (
								<div className="msg align-left margin-t--10">{stateTemplate.excerpt}</div>
							) : (
								<div className="msg margin-t--10">Nenhum template selecionado.</div>
							)
						)}
					</div>

					<div>
						<h3 className="margin-b-5">Tipo</h3>
						<LinhaItem
							titulo="Quero contratar um"
							desc={matchTipo(stateDados.type)}
							alt
						/>

						<div>
							<div className="margin-t-15 margin-b-5">
								<strong>Principal habilidade do profissional</strong>
							</div>
							<Tag
								texto={stateDados.keyword}
								x={true}
								className="padding-10"
							/>
						</div>
					</div>

					<div>
						<h3 className="margin-b-0">Status</h3>
						<div>
							{stateDados.active ? 'Ativo' : 'Inativo'}
						</div>
					</div>

					<div>
						<h3 className="margin-b-0">Datas</h3>
						<div>
							<LinhaItem
								titulo="Criado em"
								desc={dataApiParaBr(stateDados.created_at)}
								alt
							/>
							<LinhaItem
								titulo="Atualizado em"
								desc={stateDados.updated_at ? dataApiParaBr(stateDados.updated_at) : 'Ainda não atualizado'}
								alt
							/>
						</div>
					</div>

				</div>
			</div>

			{after}

			<Portal
				titulo="Pré-visualização do template"
				show={showTemplate}
				onClose={() => {
					setShowTemplate(false);
				}}
				className="preview-template"
			>
				<div className={`box-txt ${tipoTemplate(stateTemplate.id_type) === 'texto' ? 'texto-simples' : ''}`}>{htmlToJsx(stateTemplate.text)}</div>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Fechar',
							onClick: e => setShowTemplate(false)
						}}
					/>
				</div>
			</Portal>
		</>
	);
};

export { DetalhesProfissoesPredefinidas };
