import React, { useContext, useEffect, useState } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './PtFoto.css';

// Componentes
import Button2 from "@cpnt/Button2/Button2";
import TextoIconeLink from "@cpnt/TextoIconeLink/TextoIconeLink";
import { XLoading } from "@cpnt/XLoading/XLoading";
import { caminhoFoto, msg } from "@func/utils";
import imgIconeFoto from '@svg/solid/camera.svg';

// Imagens
import { salvarSessaoLocal } from "@_func/auth";
import { hash } from "@_func/generate";
import Foto from "@cpnt/Foto/Foto";
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";
import Portal from "@cpnt/Portal/Portal";
import { Webcam } from "@cpnt/Webcam/Webcam";
import imgIconePasta from '@svg/solid/folder-open.svg';
import { msgs, msgStatus } from "@_func/request";
import { api } from "@_api/api";

const PtFoto = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		stateDados = {},
		setStateDados,
		stateFotoCapturada,
		setStateFotoCapturada,
	} = props;

	const [showPortalFoto, setShowPortalFoto] = useState(false);
	const [showPortalApagar, setShowPortalApagar] = useState(false);

	const [loadingApagarFoto, setLoadingApagarFoto] = useState(false);

	const [stateFoto, setStateFoto] = useState(false);

	/*
	** Verifica se tem foto nos dados
	** ================================================== */
	useEffect(() => {
		if(stateDados.photo && stateDados.photo !== '') {
			var foto = `${caminhoFoto(stateDados.photo).url}?id=${hash()}`;
			setStateFoto(foto);
		}
	}, [stateDados.photo]);

	/*
	** Captura a foto e define a foto
	** ================================================== */
	useEffect(() => {
		if(stateFotoCapturada) {
			setStateFoto(stateFotoCapturada);
		}
	}, [stateFotoCapturada]);

	/*
	** Remove a foto
	** ================================================== */
	const removerFoto = () => {
		setStateFotoCapturada(false);

		if(stateFotoCapturada) {
			if(stateDados.photo && stateDados.photo !== '') {
				var foto = `${caminhoFoto(stateDados.photo).url}?id=${hash()}`;
				setStateFoto(foto);
			} else {
				setStateFoto(false);
			}
		} else {
			setShowPortalApagar(true);
		}
	}

	/*
	** Remove a foto da API
	** ================================================== */
	const confirmarRemoverFoto = async () => {
		if(loadingApagarFoto) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		setLoadingApagarFoto(true);
		const retDeleteFoto = await api.users.foto.delete(store.usuario.id);

		if(retDeleteFoto.status === 200) {
			setStateFoto(false);
			setStateDados({
				...stateDados,
				photo: false,
			});
			salvarSessaoLocal({
				...store,
				usuario: {
					...store.usuario,
					photo: false,
				}
			});
			setStore({
				...store,
				usuario: {
					...store.usuario,
					photo: false
				}
			});
			setShowPortalApagar(false);
			msg.success('Foto removida com sucesso');
		} else {
			msgs([retDeleteFoto]);
		}

		setLoadingApagarFoto(false);
	}

	/*
	** Selecionar foto local
	** ================================================== */
	const selecionarFotoLocal = async e => {
		var img = {};
		if(e && e.target && e.target.files && e.target.files[0]) {
			img = e.target.files[0];
		}
		if(img.type === 'image/png' || img.type === 'image/jpg' || img.type === 'image/jpeg') {
			let reader = new FileReader();
			reader.readAsDataURL(img);
			reader.onload = eImg => {
				let imagemBase64 = eImg.target.result;
				if(imagemBase64) {
					setStateFoto(imagemBase64);
					setStateFotoCapturada(imagemBase64)
				} else {
					msg.error('Parece que sua foto está corrompida ou com alguma assinatura inválida, envie outra foto.');
				}
			};
		} else {
			msg.error('Arquivo não permitido. Selecione uma foto com o formato jpg, jpeg ou png.');
		}
	}

	return (
		<>
			<div className={`cpnt-foto ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-foto-box ${className}`}>
					<div className="cpnt-foto-wrapper">
						<div className="cpnt-foto-fotobox">
							<div className="relative">
								<Foto
									imagem={stateFoto}
									tamanho="250px"
								/>
								{(stateDados.photo && stateDados.photo !== '') || (stateFoto && stateFoto !== '') ? (
									<XLoading
										abs
										loading={loadingApagarFoto}
										onClick={removerFoto}
									/>
								) : null}
							</div>
						</div>
						<div className="cpnt-foto-btns">
							<Button2
								_={{
									value: <TextoIconeLink texto="Capturar foto" icone={imgIconeFoto} />,
									onClick: e => {
										setShowPortalFoto(true);
									}
								}}
							/>
							<div className="margin-l-10 flex btn-res-l-480">
								<label>
									<div className="btn btn-default">
										<TextoIconeLink texto="Selecionar foto" icone={imgIconePasta} />
									</div>
									<input type="file" name="foto" className="none" onChange={selecionarFotoLocal} />
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Portal
				titulo="Foto de perfil"
				show={showPortalFoto}
				onClose={() => setShowPortalFoto(false)}
				className="w-600"
			>
				<Webcam
					tipo="foto"
					onClickCancelar={e => setShowPortalFoto(false)}
					setStateFotoCapturada={setStateFotoCapturada}
				/>
			</Portal>

			<PortalConfirmacao
				show={showPortalApagar}
				titulo="Deseja realmente apagar essa foto?"
				desc="A foto será removida permanentemente, não será possível recuperá-la após remover."
				onClose={e => {
					setShowPortalApagar(false);
				}}
				onClickCancelar={e => {
					setShowPortalApagar(false);
				}}
				onClickConfirmar={confirmarRemoverFoto}
				loadingConfirmar={loadingApagarFoto}
			/>
		</>
	);
};

export { PtFoto };
