import React, { useEffect, useState, useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalProjetosEquipe.css';

// API
import apiProjects from '@api/projects/projects';

// Partes
import DetalhesEquipe from '@page/Logado/Projetos/DetalhesEquipe/DetalhesEquipe';

// Componentes
import Portal from '@cpnt/Portal/Portal';
import Loading from '@cpnt/Loading/Loading';
import Info from '@cpnt/Info/Info';
import Button2 from '@cpnt/Button2/Button2';

// Funções

import { msgs, msgStatus } from "@_func/request";

const PortalProjetosEquipe = props => {

	const {
		id,
		show,
		onClose,
		closeOnEsc = false,
		portalClassName = 'portal-projetos-equipe',
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({});
	const [_stateDados, _setStateDados] = useState({});

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		} else {
			// Tempo da animação de fechar, limpa os dados
			setTimeout(() => {
				setStateDados(false);
			}, 500);
		}
	}

	/*
	** INIT
	** ================================================== */
	const inicializarGets = async () => {
		if(show === true) {
			const retGetItem = await apiProjects.getOne.get(id);

			if(retGetItem.status === 200) {
				setStateDados(retGetItem.data);
				_setStateDados({
					status: 200,
				});
			} else {
				_setStateDados(msgStatus(retGetItem));
				msgs([retGetItem]);
			}

			setLoading(false);

		} else {
			// Tempo da animação de fechar, limpa os dados
			setTimeout(() => {
				setStateDados(false);
			}, 500);
		}
	}

	return (
		<Portal
			titulo="Equipe do projeto"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				_stateDados.status === 200 ? (
					<DetalhesEquipe
						{...stateDados}
						after={
							<div className="form-bottom">
								<Button2
									_={{
										value: 'Fechar',
										onClick: onClose
									}}
								/>
							</div>
						}
					/>
				) : (
					<Info
						texto={_stateDados.msg}
						icone="erro"
					/>
				)
			)}
		</Portal>
	);
};

export default PortalProjetosEquipe;