import React, { useState, useContext } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './DetalhesCard.css';

import ItemFotoDesc from "@cpnt/ItemFotoDesc/ItemFotoDesc";
import LinhaItem from "@cpnt/LinhaItem/LinhaItem";
import TextoIconeLink from "@cpnt/TextoIconeLink/TextoIconeLink";
import { caminhoFoto, dataPorExtenso, formatarDinheiro, getSenioridadeBySlug, htmlToJsx } from "@func/utils";

import imgIconeBriefing from '@svg/regular/file-lines.svg';
import imgIconeProposta from '@svg/regular/comment-lines.svg';
import Portal from "@cpnt/Portal/Portal";
import Button2 from "@cpnt/Button2/Button2";
import { PortalPerfilUsuario } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilUsuario";
import IconeChat from "@cpnt/IconeChat/IconeChat";
import { PortalPerfilFreelancer } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilFreelancer";

const DetalhesCard = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		stateDados,
		stateBriefing,
		showNegociar = true,
	} = props;

	const [showPortalTextoBriefing, setShowPortalTextoBriefing] = useState(false);
	const [showPortalPerfil, setShowPortalPerfil] = useState(false);
	const [showPortalPerfilFreelancer, setShowPortalPerfilFreelancer] = useState(false);

	return (
		<>
			<div className={`cpnt-detalhescard ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-detalhescard-box ${className}`}>

					<div>
						<h3 className="margin-t-0">Negociação</h3>
						<div className="flex flex-center-v cpnt-detalhescard-negoc">
							<div>
								<ItemFotoDesc
									titulo={stateDados.user_name}
									subtitulo="Responsável"
									foto={caminhoFoto(stateDados.user_photo).url}
									onClick={() => {
										setShowPortalPerfil(true);
									}}
									className="cursor-pointer"
								/>
							</div>
							{showNegociar ? (
								<Link to={`${store.usuario.role === 2 ? `/meus-jobs/negociacao?id_task=${stateDados.id}` : `/contratacoes/negociacao?id_task=${stateDados.id}`}`} className="flex flex-center-v a margin-l-30 btn-res-l-680">
									<IconeChat
										qtdMensagens={stateDados.countMessages}
									/>
									<div className="margin-l-10">Negociar job</div>
								</Link>
							) : null}
							{store.usuario.role !== 2 ? (
								<ItemFotoDesc
									titulo={stateDados.freelancer_name}
									subtitulo="Associado ao job"
									foto={caminhoFoto(stateDados.freelancer_photo).url}
									onClick={() => {
										setShowPortalPerfilFreelancer(true);
									}}
									className="margin-l-auto cursor-pointer btn-res-l-680"
								/>
							) : null}
						</div>
					</div>

					{stateDados.project_name && stateDados.project_name !== '' ? (
						<div>
							<h3 className="margin-b-5">Projeto associado ao job</h3>
							<div>
								{stateDados.project_name}
							</div>
						</div>
					) : null}

					<div>
						<h3 className="margin-b-5">Dados do job</h3>
						<div>
							<LinhaItem
								titulo="ID único do job"
								desc={stateDados.id}
								alt
							/>
							<LinhaItem
								titulo="Título do job"
								desc={stateDados.title}
								alt
							/>
							{store.usuario.role === 2 ? (
								<LinhaItem
									titulo="Valor"
									desc={formatarDinheiro(stateDados.liquid_price ? stateDados.liquid_price : stateDados.price, 'R$ ')}
									alt
								/>
							) : (
								<>
									<LinhaItem
										titulo="Valor líquido"
										desc={stateDados.liquid_price ? formatarDinheiro(stateDados.liquid_price, 'R$ ') : '(não disponível)'}
										alt
									/>
									<LinhaItem
										titulo="Valor bruto"
										desc={formatarDinheiro(stateDados.price, 'R$ ')}
										alt
									/>
								</>
							)}
							<LinhaItem
								titulo="Status"
								desc={stateDados.status_name}
								alt
							/>
						</div>
					</div>

					<div>
						<h3 className="margin-b-5">Datas</h3>
						<div>
							<LinhaItem
								titulo="Criado em"
								desc={dataPorExtenso(stateDados.created_at, true, 'há ', '', '')}
								alt
							/>
							<LinhaItem
								titulo="Aprovado em"
								desc={dataPorExtenso(stateDados.approval_at, true, 'há ', '', '')}
								alt
							/>
							<LinhaItem
								titulo="Pagamento previsto para"
								desc={dataPorExtenso(stateDados.payday, true, 'há ', '', '')}
								alt
							/>
						</div>
					</div>

					<div className="sep-t margin-t-20 padding-t-15">
						{!stateBriefing ? (
							<div>
								Esse job não está associado a nenhuma proposta de job específica.
							</div>
						) : (
							<div>
								<div>
									<h3 className="margin-t-0 margin-b-5">Esse job faz parte de uma proposta de job</h3>
									<div>
										<div>
											<TextoIconeLink
												texto="Acessar proposta"
												icone={imgIconeProposta}
												iconeCor="#000"
												className="a"
												navigate={store.usuario.role === 2 ? `/jobs/detalhes?id_briefing=${stateDados.id_briefing}` : `/briefing/negociacao?id_briefing=${stateDados.id_briefing}&id_freelancer=${stateDados.id_freelancer}`}
											/>
										</div>
										<div>
											<TextoIconeLink
												texto="Visualizar descrição"
												icone={imgIconeBriefing}
												iconeCor="#000"
												className="a"
												onClick={() => {
													setShowPortalTextoBriefing(true);
												}}
											/>
										</div>
										<div className="margin-t-15">
											<LinhaItem
												titulo="Título da proposta"
												desc={stateBriefing.title}
												alt
											/>
											<LinhaItem
												titulo="ID da proposta"
												desc={stateBriefing.id}
												alt
											/>
											<LinhaItem
												titulo="Projeto"
												desc={stateBriefing.project_name || '(não informado)'}
												alt
											/>
											<LinhaItem
												titulo="Criado em"
												desc={dataPorExtenso(stateBriefing.created_at, true, 'há ', '', '')}
												alt
											/>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>

				</div>
			</div>

			{/* Portal Texto do briefing */}
			<Portal
				titulo="Descrição da proposta de job"
				show={showPortalTextoBriefing}
				closeOnEsc={false}
				onClose={() => {
					setShowPortalTextoBriefing(false)
				}}
				className="w-800"
			>
				<div className="box-txt">{htmlToJsx(stateBriefing && typeof(stateBriefing.text) === 'string' ? stateBriefing.text : '<div>Nenhuma descrição adicionada a essa proposta de job.</div>')}</div>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Fechar',
							onClick: e => {
								setShowPortalTextoBriefing(false);
							}
						}}
					/>
				</div>
			</Portal>

			<PortalPerfilUsuario
				id={stateDados.id_user}
				show={showPortalPerfil}
				onClose={e => {
					setShowPortalPerfil(false);
				}}
			/>

			<PortalPerfilFreelancer
				id={stateDados.id_freelancer}
				show={showPortalPerfilFreelancer}
				onClose={e => {
					setShowPortalPerfilFreelancer(false);
				}}
			/>
		</>
	);
};

export { DetalhesCard };