import React, { useContext, useEffect, useState } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalProfissoesPredefinidasFormCadastro.css';

// API
import apiProfessions from "@api/professions/professions";
import { apiGetAreas, apiGetEmpresas } from '@apiGeral/geral';

// Imagens
import imgIconePesquisa from '@svg/solid/magnifying-glass.svg';

// Partes
import FormProfissoesPredefinidas from "@page/Logado/ProfissoesPredefinidas/FormProfissoesPredefinidas/FormProfissoesPredefinidas";

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Portal from '@cpnt/Portal/Portal';
import TextoIconeLink from "@cpnt/TextoIconeLink/TextoIconeLink";

// Funções
import { msgs } from "@_func/request";
import { camposInvalidos, msg } from '@func/utils';
import { getLabelState } from "@shared/functions/components";

const PortalProfissoesPredefinidasFormCadastro = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-prof-pred-form',
		onSuccess = () => null,
		onError = () => null,
		exibirMatch,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		title: '',
		active: 1,
		id_template: 0,
		id_area: 0,
		id_company: 0,
		keyword: '',
		type: '',
	};

	// Edição
	const [loadingCadastrarProfissaoPredefinida, setLoadingCadastrarProfissaoPredefinida] = useState(false);
	const [loadingGetAreas, setLoadingGetAreas] = useState(false);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [stateAreas, setStateAreas] = useState([]);
	const [stateTemplate, setStateTemplate] = useState({});
	const [stateEmpresas, setStateEmpresas] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** Init
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await apiGetAreas({setLoadingGetAreas, setStateAreas});
		if(retGetItem.status === 200) {
			apiGetsDefault();
		} else {
			msgs([retGetItem]);
		}
	}

	const apiGetsDefault = async (dados) => {

		if(store.usuario.role === 1) {
			apiGetEmpresas({setLoadingGetEmpresas, setStateEmpresas});
		}

		apiGetAreas({setLoadingGetAreas, setStateAreas});
	}

	/*
	** Exibe a pesquisa
	** ================================================== */
	const exibirPesquisaAlgolia = () => {
		var dadosEnviar = {
			area_name: getLabelState(stateAreas, stateDados.id_area, 'value'),
			text: stateDados.keyword,
		};
		exibirMatch(dadosEnviar);
	}

	/*
	** Editar ProfissaoPredefinida
	** ================================================== */
	const cadastrarProfissaoPredefinida = async e => {
		e.preventDefault();

		if(loadingCadastrarProfissaoPredefinida) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			title: stateDados.title,
			active: stateDados.active ? 1 : 0,
			id_template: stateDados.id_template,
			id_area: stateDados.id_area,
			id_company: stateDados.id_company,
			keyword: stateDados.keyword,
			type: stateDados.type,
		};

		if(camposInvalidos(dadosEnviar, {}, [
			'title',
			'id_area',
		])) return;

		if(!dadosEnviar.id_company || dadosEnviar.id_company === 0 || dadosEnviar.id_company === '') {
			dadosEnviar.id_company = null;
		}

		if(!dadosEnviar.id_template || dadosEnviar.id_template === 0 || dadosEnviar.id_template === '') {
			dadosEnviar.id_template = null;
		}

		setLoadingCadastrarProfissaoPredefinida(true);

		const retInsertProfissaoPredefinida = await apiProfessions.insert.post(dadosEnviar);

		if(retInsertProfissaoPredefinida.status === 200) {
			msg.success('Configuração do Briefing/Vaga cadastrado com sucesso');
			setStateDados(camposDefault);
			setStateTemplate({});
			onSuccess({
				ret: retInsertProfissaoPredefinida,
			})
		} else {
			msgs([retInsertProfissaoPredefinida]);
			onError({
				ret: retInsertProfissaoPredefinida,
			});
		}

		setLoadingCadastrarProfissaoPredefinida(false);
	}


	return (
		<Portal
			titulo="Cadastrar Configuração do Briefing/Vaga"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={e => {
				onClose();
			}}
			className={portalClassName}
		>
			<div className={wrap}>
				<div className={className}>
					<FormProfissoesPredefinidas
						isCadastro={true}
						getHabilidades={e => {
						}}
						stateDados={stateDados}
						setStateDados={setStateDados}
						stateAreas={stateAreas}
						setStateAreas={setStateAreas}
						stateTemplate={stateTemplate}
						setStateTemplate={setStateTemplate}
						stateEmpresas={[
							{value: 0, label: <strong>Profissão pública (todos terão acesso)</strong>},
							...stateEmpresas
						]}
						loadingGetAreas={loadingGetAreas}
						loadingGetEmpresas={loadingGetEmpresas}
						after={
							<div className="form-bottom form-bottom-res-680">
								<div>
									<Button2
										_={{
											value: <TextoIconeLink texto="Simular pesquisa" icone={imgIconePesquisa} />,
											onClick: () => exibirPesquisaAlgolia(),
										}}
									/>
								</div>
								<div className="flex margin-l-auto margin-l-unset-680">
									<Button2
										_={{
											value: 'Cancelar',
											className: 'btn btn-1',
											onClick: e => {
												onClose();
											}
										}}
										wrap="btn-res-l-680"
									/>
									<Button2
										_={{
											value: 'Cadastrar',
											onClick: cadastrarProfissaoPredefinida,
										}}
										loading={loadingCadastrarProfissaoPredefinida}
										wrap="margin-l-10 margin-t-10-680"
									/>
								</div>
							</div>
						}
					/>
				</div>
			</div>
		</Portal>
	);
};

export { PortalProfissoesPredefinidasFormCadastro };
