import React, { useContext, useEffect, useState } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './Passo9.css';

// Imagens
import imgIconePasta from '@svg/solid/folder-open.svg';
import imgIconeVideo from '@svg/solid/video.svg';
import imgIconeDownload from '@svg/padrao/cloud-arrow-down.svg';

// API
import apiFreelancer from "@api/freelancer/freelancer";

// Componentes
import Button2 from "@cpnt/Button2/Button2";
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";
import TextoIconeLink from "@cpnt/TextoIconeLink/TextoIconeLink";
import Tip from "@cpnt/Tip/Tip";
import { VideoPlayer } from "@_cpnt/VideoPlayer/VideoPlayer";
import { Webcam } from "@cpnt/Webcam/Webcam";
import { XLoading } from "@cpnt/XLoading/XLoading";
import Portal from "@cpnt/Portal/Portal";

// Funções
import { PtVideo } from "@cpnt/Partes/PtVideo/PtVideo";

const Passo9 = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		stateDados = {},
		setStateDados,

		stateVideoUrl,
		setStateVideoUrl,
	} = props;

	return (
		<>
			<div className={`cpnt-passo9 ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-passo9-box ${className}`}>
					<div>
						<h3 className="subtitulo margin-t-0">Vídeo de apresentação</h3>
						<div className="desc">
							<div>Este vídeo <strong>não é obrigatório</strong>. Ele nos ajuda a te conhecer melhor e é uma ótima oportunidade para transmitir uma boa impressão sobre você. Fale sobre seu trabalho, habilidades profissionais e experiências. Recomendamos um vídeo de até 1 minuto.</div>
							<div className="margin-t-10">Envie seu vídeo com o tempo médio de 1 minuto no formato .MP4 ou .WEBM, com orientação Paisagem.</div>
						</div>
					</div>
					<div className="cpnt-passo9-wrapper">
						<PtVideo
							stateDados={stateDados}
							setStateDados={setStateDados}
							stateVideoUrl={stateVideoUrl}
							setStateVideoUrl={setStateVideoUrl}
							afterTip={
								<div className="margin-t-10">Lembre-se, enquanto você não finalizar o cadastro, o seu vídeo de apresentação não será anexado ao seu perfil.</div>
							}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export { Passo9 };
