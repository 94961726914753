import React from 'react';
import Placeholder from '@cpnt/Placeholder/Placeholder';

const PlaceholderUsuarios = props => {
	const {
		itensQtd,
		classNamePlaceholder = '',
	} = props;

	return (
		<div className={`${classNamePlaceholder}`}>
			<div className="cpnt-itemusuario-item">
				<div className="flex flex-center-v flex-1">
					<Placeholder tipo="circulo" width="94px" height="94px" />
					<div className="w-100p">
						<Placeholder tipo="linha" wrap="w-90p" />
						<Placeholder tipo="linha-min" wrap="w-80p" />
						<Placeholder tipo="linha-min" wrap="w-70p" />
						<Placeholder tipo="linha-min" wrap="w-50p" />
						<Placeholder tipo="linha-min" wrap="w-60p" />
					</div>
					<Placeholder tipo="retangulo-alt" />
				</div>
			</div>
		</div>
	)
}

export { PlaceholderUsuarios };
