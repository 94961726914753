import React from 'react';

// css
import './Placeholder.css';

// Imagens
import imgIconeVazio from '@svg/light/filter-list.svg';

// Funções
import { isNumeric } from "@_func/regex";

// Componentes
import Info2 from '@cpnt/Info2/Info2';

// Placeholders
import PlaceholderProjetos from '@cpnt/Placeholders/PlaceholderProjetos/PlaceholderProjetos';
import { PlaceholderClientes } from '@cpnt/Placeholders/PlaceholderClientes/PlaceholderClientes';
import { PlaceholderEmpresas } from '@cpnt/Placeholders/PlaceholderEmpresas/PlaceholderEmpresas';
import { PlaceholderUsuarios } from '../Placeholders/PlaceholderUsuarios/PlaceholderUsuarios';
import { PlaceholderBriefings } from '../Placeholders/PlaceholderBriefings/PlaceholderBriefings';
import { PlaceholderJobsAdmin } from '../Placeholders/PlaceholderJobsAdmin/PlaceholderJobsAdmin';
import { PlaceholderProfissionais } from '../Placeholders/PlaceholderProfissionais/PlaceholderProfissionais';
import { PlaceholderProfissoesPredefinidas } from "@cpnt/Placeholders/PlaceholderProfissoesPredefinidas/PlaceholderProfissoesPredefinidas";
import { PlaceholderTemplates } from "@cpnt/Placeholders/PlaceholderTemplates/PlaceholderTemplates";
import { PlaceholderJobs } from "@cpnt/Placeholders/PlaceholderJobs/PlaceholderJobs";
import { PlaceholderCards } from "@cpnt/Placeholders/PlaceholderCards/PlaceholderCards";
import { PlaceholderBriefingsLite } from "@cpnt/Placeholders/PlaceholderBriefingsLite/PlaceholderBriefingsLite";
import { PlaceholderJobsAdminLite } from "@cpnt/Placeholders/PlaceholderJobsAdminLite/PlaceholderJobsAdminLite";

const Placeholder = props => {
	const {
		className = '',
		wrap = '',
		tipo = 'linha',
		width = 'default',
		height = 'default',
		style = {}
	} = props;

	const renderTipo = () => {
		var widthTipo = '';
		var heightTipo = '';

		if(tipo === 'linha') {
			widthTipo = width === 'default' ? '100%' : width;
			heightTipo = height === 'default' ? '20px' : height;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-linha ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		if(tipo === 'linha-min') {
			widthTipo = width === 'default' ? '100%' : width;
			heightTipo = height === 'default' ? '14px' : height;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-linha-min ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		if(tipo === 'linha-min-2') {
			widthTipo = width === 'default' ? '100%' : width;
			heightTipo = height === 'default' ? '10px' : height;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-linha-min ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		if(tipo === 'circulo') {
			widthTipo = width === 'default' ? '38px' : width;
			heightTipo = height === 'default' ? '38px' : width;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-circulo ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		if(tipo === 'quadrado') {
			widthTipo = width === 'default' ? '38px' : width;
			heightTipo = height === 'default' ? '38px' : width;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-quadrado ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		if(tipo === 'retangulo') {
			widthTipo = width === 'default' ? '38px' : width;
			heightTipo = height === 'default' ? '100px' : height;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-retangulo ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		if(tipo === 'retangulo-alt') {
			widthTipo = width === 'default' ? '20px' : width;
			heightTipo = height === 'default' ? '34px' : height;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-retangulo-alt ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		if(tipo === 'custom') {
			widthTipo = width === 'default' ? '38px' : width;
			heightTipo = height === 'default' ? '38px' : height;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-custom ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		if(tipo === 'min') {
			widthTipo = width === 'default' ? '26px' : width;
			heightTipo = height === 'default' ? '20px' : height;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-min ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		return <div>...</div>;
	}

	return renderTipo();
};

export const PlaceholderRender = (props) => {
	const {
		qtd = 10,
		qtdColunas = 0,
		children,
		loading,
		init,
		itens,
		request,
		cpnt,
		conteudoSemResultado,
		classNameContainerSemResultado = 'flex flex-center w-100p border-box',
		table = true,
	} = props;

	var componente = PLACEHOLDER_ITENS[cpnt];

	if(!componente) {
		return <>{children}</>;
	}

	var retorno = [];
	var _request = false;

	if(request && Object.keys(request).length > 0) {
		_request = true;
	}

	if(!init || !_request) {
		if(isNumeric(qtd)) {
			var qtdLoop = Number(qtd);
			for (let i = 0; i < qtdLoop; i++) {
				retorno.push(componente({...props, key: `placeholder-${i}`}));
			}
		}
		return <>{retorno}</>;
	}

	if(loading) {
		var count = itens ? itens.length : 0;
		if(count <= 0) {
			count = 1;
		}
		for (let i = 0; i < count; i++) {
			retorno.push(componente({...props, key: `placeholder-loading-${i}`}));
		}
		return <>{retorno}</>;
	}

	if(init && !loading && itens.length <= 0) {
		if(table) {
			retorno.push(
				<tr key="sem-resultado-tr" className="tr-disabled">
					<td colSpan={qtdColunas}>
						{conteudoSemResultado ? conteudoSemResultado : (
							<div className="padding-30 flex flex-center">
								<Info2
									icone={imgIconeVazio}
									titulo={request.msg || "Nenhum conteúdo encontrado"}
									desc="Tente utilizar filtros diferentes na sua busca"
								/>
							</div>
						)}
					</td>
				</tr>
			);
		} else {
			retorno.push(
				<div key="sem-resultado-div" className={classNameContainerSemResultado}>
					{conteudoSemResultado ? conteudoSemResultado : (
						<div className="padding-30 flex flex-center">
							<Info2
								icone={imgIconeVazio}
								titulo={request.msg || "Nenhum conteúdo encontrado"}
								desc="Tente utilizar filtros diferentes na sua busca"
							/>
						</div>
					)}
				</div>
			);
		}
		return <>{retorno}</>;
	}

	return <>{children}</>;
}

export const PLACEHOLDER_ITENS = {
	PlaceholderProjetos: e => <PlaceholderProjetos {...e} />,
	PlaceholderClientes: e => <PlaceholderClientes {...e} />,
	PlaceholderEmpresas: e => <PlaceholderEmpresas {...e} />,
	PlaceholderUsuarios: e => <PlaceholderUsuarios {...e} />,
	PlaceholderBriefings: e => <PlaceholderBriefings {...e} />,
	PlaceholderJobsAdmin: e => <PlaceholderJobsAdmin {...e} />,
	PlaceholderCards: e => <PlaceholderCards {...e} />,
	PlaceholderJobs: e => <PlaceholderJobs {...e} />,
	PlaceholderProfissionais: e => <PlaceholderProfissionais {...e} />,
	PlaceholderProfissoesPredefinidas: e => <PlaceholderProfissoesPredefinidas {...e} />,
	PlaceholderTemplates: e => <PlaceholderTemplates {...e} />,
	PlaceholderBriefingsLite: e => <PlaceholderBriefingsLite {...e} />,
	PlaceholderJobsAdminLite: e => <PlaceholderJobsAdminLite {...e} />,
};

export default Placeholder;