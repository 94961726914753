import React, { useContext, useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalClientesFormEdicao.css';

// API

// Partes

import imgIconeEmpresa from '@img/icones/geral/empresa-placeholder.png';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { api } from '@_api/api';
import { addPrefixo, caminhoFoto, camposInvalidos,  removerKeysVazias, validarCnpj, validarCpf, validarEmail, validarUrl } from '@func/utils';
import FormCliente from '@page/Logado/Clientes/FormCliente/FormCliente';

import { msgs, msgStatus } from "@_func/request";

const PortalClientesFormEdicao = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-800',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		id_company: store.usuario.id_company, cpf_cnpj: '', name: '', social_reason: '', street: '', district: '',
		zip_code: '', state: '', city: '', number: '', complement: '', phone: '', active: 1, description: '',
		name_responsible: '', email_responsible: '', position_responsible: '', prospect: '', logo: false,
		site: '', type: 'j',
	};

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	// Edição
	const [loadingEditarCliente, setLoadingEditarCliente] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({});
	const [_stateDados, _setStateDados] = useState({});
	const [stateLogotipo, setStateLogotipo] = useState(false);
	const [stateLogotipoNovo, setStateLogotipoNovo] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados(camposDefault);
			setStateLogotipo(false);
			setStateLogotipoNovo(false);
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** FREELANCER
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await api.customers.getOne.get(id);

		if(retGetItem.status === 200) {
			setStateDados(retGetItem.data);
			_setStateDados(msgStatus(retGetItem));
			if(retGetItem.data.logo && retGetItem.data.logo !== '') {
				var url = caminhoFoto(retGetItem.data.logo, imgIconeEmpresa).url;
				setStateLogotipo(url);
			}
		} else {
			_setStateDados(msgStatus(retGetItem));
			msgs([retGetItem]);
		}

		setLoading(false);
	}

	/*
	** Editar cliente
	** ================================================== */
	const editarCliente = async e => {
		e.preventDefault();

		if(loadingEditarCliente) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			id: stateDados.id,
			id_company: stateDados.id_company,
			cpf_cnpj: stateDados.cpf_cnpj,
			name: stateDados.name,
			social_reason: stateDados.social_reason,
			street: stateDados.street,
			district: stateDados.district,
			zip_code: stateDados.zip_code,
			state: stateDados.state,
			city: stateDados.city,
			number: stateDados.number,
			complement: stateDados.complement,
			name_responsible: stateDados.name_responsible,
			email_responsible: stateDados.email_responsible,
			description: stateDados.description,
			position_responsible: stateDados.position_responsible,
			phone: stateDados.phone,
			site: addPrefixo(stateDados.site),
			active: stateDados.active,
			type: stateDados.type,
		};

		if(camposInvalidos(dadosEnviar, {}, [
			'id',
			'id_company',
			'cpf_cnpj',
			'name',
			...(stateDados.type === 'j' ? ['social_reason'] : []),
			'street',
			'district',
			'zip_code',
			'state',
			'city',
			'number',
			'name_responsible',
			'email_responsible',
			'position_responsible',
			'phone',
			'type',
		])) return;

		if((stateDados.type === 'J' || stateDados.type === 'j') && !validarCnpj(dadosEnviar.cpf_cnpj)) return;
		if((stateDados.type === 'F' || stateDados.type === 'f') && !validarCpf(dadosEnviar.cpf_cnpj)) return;

		if(!validarEmail(dadosEnviar.email_responsible)) return;

		if(dadosEnviar.site && dadosEnviar.site !== '' && !validarUrl(dadosEnviar.site)) return;

		var dadosEnviarSemKeysVaziasOuFormData = removerKeysVazias(dadosEnviar);

		// if(stateLogotipoNovo) {
		// 	dadosEnviarSemKeysVaziasOuFormData = await dadosEnviarFormData({
		// 		dadosEnviar,
		// 		imagem: stateLogotipo,
		// 		showConsoleLog: true,
		// 	});
		// 	consoleFormData(dadosEnviarSemKeysVaziasOuFormData)
		// }

		setLoadingEditarCliente(true);

		const retUpdateCliente = await api.customers.update.put(dadosEnviarSemKeysVaziasOuFormData);

		if(retUpdateCliente.status === 200) {
			msg.success('Cliente atualizado com sucesso.');
			onSuccess({
				ret: retUpdateCliente,
			})
		} else {
			msgs([retUpdateCliente]);
			onError({
				ret: retUpdateCliente,
			});
		}

		setLoadingEditarCliente(false);
	}

	return (
		<Portal
			titulo="Editar cliente"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{loading ? (
							<Loading
								texto="Carregando, aguarde..."
							/>
						) : (
							_stateDados.status === 200 ? (
								<FormCliente
									onSubmit={editarCliente}
									stateDados={stateDados}
									setStateDados={setStateDados}
									stateLogotipo={stateLogotipo}
									setStateLogotipo={setStateLogotipo}
									setStateLogotipoNovo={setStateLogotipoNovo}
									after={
										<div className="form-bottom">
											<Button2
												_={{
													value: 'Cancelar',
													className: 'btn btn-1',
													type: 'button',
													onClick: onClose,
												}}
												classExtra="btn-1"
											/>
											<Button2
												_={{
													value: 'Salvar dados',
													onClick: editarCliente
												}}
												loading={loadingEditarCliente}
												wrap="margin-l-15"
											/>
										</div>
									}
								/>
							) : (
								<Info
									texto={_stateDados.msg}
									icone="erro"
								/>
							)
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export default PortalClientesFormEdicao;