import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalCardDetalhes.css';

// Componentes
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import apiBriefings from "@api/briefings/briefings";
import apiTasks from "@api/tasks/tasks";
import Button2 from '@cpnt/Button2/Button2';
import { PortalPerfilFreelancer } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilFreelancer";

import { DetalhesCard } from "@page/Logado/MeusJobs/DetalhesCard/DetalhesCard";
import { closePortal, getPortalDados, gets, isShowPortal } from "@route/routeUtils";
import { msgs, msgStatus } from "@_func/request";

const PortalCardDetalhes = props => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();
	const location = useLocation();

	const {
		show,
		id,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-cards-detalhes',
		portalId = 'portal-cards-detalhes',
		showNegociar = true,
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGeFreelancers, setLoadingGeFreelancers] = useState(false);
	const [loadingGetBriefing, setLoadingGetBriefing] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({});
	const [_stateDados, _setStateDados] = useState({});
	const [stateFreelancers, setStateFreelancers] = useState([]);
	const [stateBriefing, setStateBriefing] = useState(false);

	const [loadingGetProfissionais, setLoadingGetProfissionais] = useState(false);
	const [loadingGetHabilidades, setLoadingGetHabilidades] = useState(false);
	const [loadingGetObjetivos, setLoadingGetObjetivos] = useState(false);
	const [loadingGetSegments, setLoadingGetSegments] = useState(false);
	const [loadingGetIdiomas, setLoadingGetIdiomas] = useState(false);
	const [loadingGetCompetencias, setLoadingGetCompetencias] = useState(false);
	const [loadingGetHistorico, setLoadingGetHistorico] = useState(false);

	const [stateBriefingProfissionais, setStateBriefingProfissionais] = useState([]);
	const [stateBriefingHabilidades, setStateBriefingHabilidades] = useState([]);
	const [stateBriefingObjetivos, setStateBriefingObjetivos] = useState([]);
	const [stateBriefingSegmentos, setStateBriefingSegmentos] = useState([]);
	const [stateBriefingIdiomas, setStateBriefingIdiomas] = useState([]);
	const [stateHistoricoId, setStateHistoricoId] = useState(false);
	const [stateHistoricoDados, setStateHistoricoDados] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setLoading(true);
			setLoadingGetBriefing(true);
			setStateDados({});
			setStateBriefing(false);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** FREELANCER
	** ================================================== */
	const inicializarGets = async () => {
		if(show === true) {
			const retGetCard = await apiTasks.getOne.get(id);
			_setStateDados(msgStatus(retGetCard));

			if(retGetCard.status === 200) {
				setStateDados(retGetCard.data);

				if(retGetCard.data.id_briefing) {
					const retGetBriefing = await apiBriefings.getOne.get(retGetCard.data.id_briefing);
					if(retGetBriefing.status === 200) {
						setStateBriefing(retGetBriefing.data);
					}
				}

			} else {
				msgs([retGetCard]);
			}

			setLoading(false);
			setLoadingGetBriefing(false);
		}

	}

	return (
		<Portal
			titulo="Detalhes do job"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{loadingGeFreelancers ? (
							<Loading />
						) : (
							_stateDados.status === 200 ? (
								<>
									<DetalhesCard
										stateDados={stateDados}
										loadingGetBriefing={loadingGetBriefing}
										stateBriefing={stateBriefing}
										showNegociar={showNegociar}
									/>
								</>
							) : (
								<>
									<Info
										texto={_stateDados.msg}
										icone="erro"
									/>

								</>
							)
						)}
						<div className="form-bottom">
							<Button2
								_={{
									value: 'Fechar',
									type: 'button',
									onClick: onClose,
								}}
							/>
						</div>
					</div>
				</div>
			)}

			<PortalPerfilFreelancer
				id={getPortalDados({portal: portalId, stateGet})}
				show={isShowPortal({portal: portalId, stateGet})}
				onClose={() => closePortal({portal: portalId, stateGet, navigate})}
			/>

		</Portal>
	);
};

export { PortalCardDetalhes };
