import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';

// css
import './PortalListaLocalFormCadastro.css';

// API
import { apiCompany } from '@_api/requests/company/company';

// Partes
import { DetalhesEmpresa } from '@page/Logado/Empresas/DetalhesEmpresa/DetalhesEmpresa';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { camposInvalidos } from '@func/utils';
import { DetalhesProfissionaisSelecionados } from '@cpnt/ProfissionaisSelecionados/DetalhesProfissionaisSelecionados/DetalhesProfissionaisSelecionados';
import { adicionarListaLocal, apagarListaLocal, getAllUsuariosLocal, initListaLocal, limparListaLocal, listasLocal } from '@func/selecaoUsuariosBriefings';
import { ListaLocalProfissionaisSelecionados } from '@cpnt/ProfissionaisSelecionados/ListaLocalProfissionaisSelecionados/ListaLocalProfissionaisSelecionados';
import PortalConfirmacao from '../../PortaisGerais/PortalConfirmacao/PortalConfirmacao';
import { closePortal, getPortalDados, gets, isShowPortal, showPortal } from '@route/routeUtils';
import { ListaLocalForm } from '@cpnt/ProfissionaisSelecionados/ListaLocalFormCadastro/ListaLocalFormCadastro';

const PortalListaLocalFormCadastro = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		onSuccess = () => null,
		onError = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-listaloc-form-cadastro',

		stateListaAtual,
		setStateListaAtual,
		stateListas,
		setStateListas,
		stateSelecionados,
		setStateSelecionados,
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	useEffect(() => {
		initListaLocal();
		setStateListas(listasLocal());
	}, []);

	useEffect(() => {
		if(show) {
			setStateNomeLista('');
		}
	}, [show]);

	/*
	** States
	** ================================================== */
	// const [stateListaAtual, setStateListaAtual] = useState('Lista padrão');
	// const [stateListas, setStateListas] = useState([]);
	// const [stateSelecionados, setStateSelecionados] = useState(getAllUsuariosLocal(stateListaAtual));

	const [stateNomeLista, setStateNomeLista] = useState('');

	const cadastrarLista = (e) => {
		e.preventDefault();

		if(!stateNomeLista || stateNomeLista === '') {
			msg.error('Insira o nome da lista');
			return;
		}

		var criarLista = adicionarListaLocal({
			stateNomeLista,
			setStateListas,
			setStateListaAtual,
			setStateSelecionados,
		});

		if(criarLista) {
			onSuccess();
		} else {
			onError();
		}
	}

	return (
		<Portal
			titulo="Adicionar lista de seleção de profissionais"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<div className={wrap}>
				<div className={className}>
					<ListaLocalForm
						onSubmit={cadastrarLista}
						stateNomeLista={stateNomeLista}
						setStateNomeLista={setStateNomeLista}
						after={
							<div className="form-bottom">
								<Button2
									_={{
										value: 'Cancelar',
										className: 'btn btn-1',
										type: 'button',
										onClick: onClose,
									}}
									classExtra="btn-1"
								/>
								<Button2
									_={{
										value: 'Cadastrar lista',
										onClick: cadastrarLista
									}}
									wrap="margin-l-15"
								/>
							</div>
						}
					/>
				</div>
			</div>

		</Portal>
	);
};

export { PortalListaLocalFormCadastro };