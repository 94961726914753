import React from 'react';

// css
import './Passo8.css';

// Componentes
import { PtFoto } from "@cpnt/Partes/PtFoto/PtFoto";

const Passo8 = props => {

	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		stateDados = {},
		setStateDados,

		stateFotoCapturada,
		setStateFotoCapturada,
	} = props;

	return (
		<>
			<div className={`cpnt-passo8 ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-passo8-box ${className}`}>
					<div className="flex flex-center-v">
						<h3 className="titulo margin-t-0 flex flex-start">Foto de perfil <span className="req fs-16">*</span></h3>
					</div>
					<PtFoto
						stateDados={stateDados}
						setStateDados={setStateDados}
						stateFotoCapturada={stateFotoCapturada}
						setStateFotoCapturada={setStateFotoCapturada}
					/>
				</div>
			</div>
		</>
	);
};

export { Passo8 };
