import moment from 'moment';
import 'moment/locale/pt-br';

/*
** Define o idioma da lib "moment" como PT-BR
** ================================================== */
moment.locale('pt-br');

/*
** Valida data
** ================================================== */
export const validarDataAPI = e => {
	if(e && e !== null && e !== undefined && e !== '' && e.length > 0 && (e.length === 10 || e.length === 19 || e.length === 24) && e !== '0000-00-00 00:00:00') {
		return true;
	}
	return false;
}

/*
** Pega a data atual
** ================================================== */
export const dateNow = (props) => {
	const {
		horas,
		lang = 'br',
	} = props;

	if(horas) {
		return moment().format('YYYY-MM-DD H:mm:ss').locale(lang);
	}
	return moment().format('YYYY-MM-DD').locale(lang);
}

/*
** Data por extenso
** ================================================== */
export const dataPorExtenso = (props) => {

	const {
		data,
		prefixo = 'Há '
	} = props;

	if(!validarDataAPI(data)) {
		return '(data não informada)';
	}

	return `${prefixo}${moment(data).fromNow(true)}`;
}


/*
** Exporta todos em um único objeto data
** ================================================== */
export const data = {
	agora: dateNow,
	porExtenso: dataPorExtenso,
};
