import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiFreelancer = {
	insert: {
		post: async dados => {
			return await api.post(`/freelancer/insert`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getOne: {
		get: async id => {
			return await api.get(`/freelancer/getOne/${id}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	update: {
		put: async dados => {
			return await api.put(`/freelancer/update`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	uploadVideo: {
		post: async dados => {
			return await api.post(`/freelancer/uploadVideo`, dados, config(true))
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	video: {
		delete: async id => {
			return await api.delete(`/freelancer/video/${id}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	indication: {
		post: async dados => {
			return await api.post(`/freelancer/indication`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	public: {
		getOne: {
			get: async id => {
				return await api.get(`/freelancer/public/getOne/${id}`, config())
				.then(retorno => {
					return validarRetorno(retorno);
				})
				.catch(retorno => {
					return validarRetorno(retorno);
				});
			}
		},
	}
};

export default apiFreelancer;
