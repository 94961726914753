import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalProjetosFormCadastro.css';

// API
import apiProjects from '@api/projects/projects';
import { api } from "@_api/api";
import apiProjectsCombos from '@api/projectsCombos/projectsCombos';

// Componentes
import Portal from '@cpnt/Portal/Portal';
import FormProjetos from '@page/Logado/Projetos/FormProjetos/FormProjetos';
import Button2 from '@cpnt/Button2/Button2';

// Funções
import { addPrefixo, camposInvalidos, conveterRealParaNumero, dataApiCrop, formatarSelect, removerKeysVazias, sanitize } from '@func/utils';
import { msgs, msgStatus } from "@_func/request";

const PortalProjetosFormCadastro = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		show,
		onClose = () => null,
		closeOnEsc = false,
		portalClassName = 'w-800',
		onSuccess = () => null,
		onError = () => null,
		acessoRestrito,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		active: 1, id_company: store.usuario.id_company,
		id_customer: null, name: '',
		// product_type: 0,
		project_type: 0, status: 1, scenery_type: 0,
		service_type: 1, start_date: '', end_date: '', total_revenue: 0, total_recipe: 0, realized_revenue: 0,
		monthly_budget: 0, expected_total_cost: 0, realized_cost: 0, percentage_completed: '0',
		restricted: 0, description: '', attachs: [], team: [], teamFreelancers: [],
		// solutions: [],
		teamMentors: [],
		teamProfessionalsInvolved: []
	};

	/*
	** Loading
	** ================================================== */
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(true);
	const [loadingGetClientes, setLoadingGetClientes] = useState(true);
	const [loadingGetUsuarios, setLoadingGetUsuarios] = useState(true);
	const [loadingGetProdutos, setLoadingGetProdutos] = useState(true);
	const [loadingGetCenarios, setLoadingGetCenarios] = useState(true);
	const [loadingGetStatus, setLoadingGetStatus] = useState(true);
	const [loadingGetSolucoes, setLoadingGetSolucoes] = useState(true);
	const [loadingGetAreas, setLoadingGetAreas] = useState(true);

	// Edição
	const [loadingCadastrarProjeto, setLoadingCadastrarProjeto] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [stateEmpresas, setStateEmpresas] = useState([]);
	const [stateClientes, setStateClientes] = useState([]);
	const [stateProdutos, setStateProdutos] = useState([]);
	const [stateCenarios, setStateCenarios] = useState([]);
	const [stateStatus, setStateStatus] = useState([]);
	const [stateAnexos, setStateAnexos] = useState([]);
	const [stateUsuarios, setStateUsuarios] = useState([]);
	const [stateFreelancers, setStateFreelancers] = useState([]);
	const [stateDataInicio, setStateDataInicio] = useState(null);
	const [stateDataFim, setStateDataFim] = useState(null);
	const [stateDescricao, setStateDescricao] = useState('');
	const [stateUsuariosSelecionado, setStateUsuariosSelecionado] = useState([]);
	const [stateSolucoes, setStateSolucoes] = useState([]);
	const [stateAreas, setStateAreas] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** FREELANCER
	** ================================================== */
	const inicializarGets = async () => {
		if(store.usuario.role === 1) {
			getEmpresas();
		}
		getClientes();
		// getProdutos();
		// getSolucoes();
		getCenarios();
		getStatus();
		getUsuarios();
		getAreas();
	}

	/*
	** API - Get Empresas
	** ================================================== */
	const getEmpresas = async () => {
		setLoadingGetEmpresas(true);
		const retGetEmpresas = await api.select.company.get();
		if(retGetEmpresas.status === 200) {
			setStateEmpresas(formatarSelect({dados: retGetEmpresas.data, keyValue: 'id'}));
		} else {
			msgs([retGetEmpresas]);
		}
		setLoadingGetEmpresas(false);
	};

	/*
	** API - Get Clientes
	** ================================================== */
	const getClientes = async (idEmpresa = store.usuario.id_company) => {
		setLoadingGetClientes(true);
		const retGetClientes = await api.select.customers.get(idEmpresa);
		if(retGetClientes.status === 200) {
			setStateClientes(formatarSelect({dados: retGetClientes.data, keyValue: 'id'}));
		} else {
			msgs([retGetClientes]);
			setStateClientes([]);
		}
		setLoadingGetClientes(false);
	}

	/*
	** API - Get Usuários da
	** ================================================== */
	const getUsuarios = async (idEmpresa = store.usuario.id_company) => {
		setLoadingGetUsuarios(true);
		const retGetUsuarios = await api.users.getAllUsers.get(idEmpresa, 2);
		if(retGetUsuarios.status === 200) {
			setStateUsuarios(formatarSelect({dados: retGetUsuarios.data, keyValue: 'id', keyIcone: 'photo', iconClass: 'border-radius-50'}));
		} else {
			setStateUsuarios([]);
		}
		setLoadingGetUsuarios(false);
	}

	/*
	** APi - Get produtos
	** ================================================== */
	const getProdutos = async () => {
		setLoadingGetProdutos(true);
		const retGeProdutos = await apiProjectsCombos.tipoProduto.get();
		if(retGeProdutos.status === 200) {
			setStateProdutos(formatarSelect({dados: retGeProdutos.data}));
		} else {
			setStateProdutos([]);
		}
		setLoadingGetProdutos(false);
	}

	/*
	** APi - Get produtos
	** ================================================== */
	const getCenarios = async () => {
		setLoadingGetCenarios(true);
		const retGeCenarios = await apiProjectsCombos.scenery.get();
		if(retGeCenarios.status === 200) {
			setStateCenarios(formatarSelect({dados: retGeCenarios.data}));
		} else {
			setStateCenarios([]);
		}
		setLoadingGetCenarios(false);
	}

	/*
	** APi - Get status
	** ================================================== */
	const getStatus = async () => {
		setLoadingGetStatus(true);
		const retGeStatus = await apiProjectsCombos.status.get();
		if(retGeStatus.status === 200) {
			setStateStatus(formatarSelect({dados: retGeStatus.data}));
		} else {
			setStateStatus([]);
		}
		setLoadingGetStatus(false);
	}

	/*
	** APi - Get soluções cadastro
	** ================================================== */
	const getSolucoes = async () => {
		setLoadingGetSolucoes(true);
		const retGetSolucoes = await apiProjectsCombos.solution.get();
		if(retGetSolucoes.status === 200) {
			var formatado = formatarSelect({dados: retGetSolucoes.data, keyName: 'name', keyValue: 'value', keyIcone: 'url', caminhoFotoDefault: false});
			setStateSolucoes(formatado);
		} else {
			msgs([retGetSolucoes]);
		}
		setLoadingGetSolucoes(false);
	}

	/*
	** APi - Get areas
	** ================================================== */
	const getAreas = async () => {
		setLoadingGetAreas(true);
		const retGetAreas = await api.select.areas.get();
		if(retGetAreas.status === 200) {
			var formatado = formatarSelect({dados: retGetAreas.data, keyValue: 'id'});
			setStateAreas(formatado);
		} else {
			msgs([retGetAreas]);
		}
		setLoadingGetAreas(false);
	}

	/*
	** Edita o projeto
	** ================================================== */
	const cadastrarProjeto = async e => {
		e.preventDefault();
		if(loadingCadastrarProjeto) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		// Anexos
		var anexos = [];
		var anexosInvalidos = false;
		if(stateAnexos && stateAnexos.length && stateAnexos.length > 0) {
			anexos = [...stateAnexos.map(item => {
				if(!item.label || item.label === '' || !item.link || item.link === '') {
					anexosInvalidos = true;
				}
				return {
					label: item.label,
					link: addPrefixo(item.link),
				}
			})]
		}
		if(anexosInvalidos) {
			msg.error('Anexos: você inseriu um anexo sem preencher o título ou o link, verifique antes de prosseguir.');
			return;
		}

		// Soluções
		// var solucoes = [];
		// if(stateDados.solutions && stateDados.solutions.length && stateDados.solutions.length > 0) {
		// 	solucoes = [...stateDados.solutions.map(item => {
		// 		return {
		// 			id_solution: item.value,
		// 		}
		// 	})]
		// }

		// Equipe
		var team = [];
		var equipeInvalida = false;
		if(stateUsuariosSelecionado && stateUsuariosSelecionado.length && stateUsuariosSelecionado.length > 0) {
			team = [...stateUsuariosSelecionado.map(item => {
				if(!item.id_user || item.id_user === '' || !item.role || item.role === '') {
					equipeInvalida = true;
				}
				return {
					id_user: item.id_user,
					role: item.role,
				}
			})]
		}
		if(equipeInvalida) {
			msg.error('Time: é necessário selecionar um integrante e um papel.');
			return;
		}

		// Freelancers
		var freelancers = [];
		var freelancersInvalidos = false;
		if(stateFreelancers && stateFreelancers.length && stateFreelancers.length > 0) {
			freelancers = [...stateFreelancers.map(item => {
				if(!item.id_freelancer || item.id_freelancer === '' || !item.id_area || item.id_area === '') {
					freelancersInvalidos = true;
				}
				return {
					id_freelancer: Number(item.id_freelancer),
					id_area: item.id_area,
				}
			})]
		}
		if(freelancersInvalidos) {
			msg.error('Freelancers: é necessário selecionar um freelancer e uma área.');
			return;
		}

		const dadosEnviar = {
			...camposDefault,
			...stateDados,

			description: sanitize(stateDescricao),

			attachs: anexos,
			team: team,
			// solutions: solucoes,
			teamFreelancers: freelancers,

			// Datas
			start_date: dataApiCrop(stateDados.start_date),
			end_date: dataApiCrop(stateDados.end_date),

			// Valores
			monthly_budget: conveterRealParaNumero(stateDados.monthly_budget),
			expected_total_cost: conveterRealParaNumero(stateDados.expected_total_cost),
			total_revenue: conveterRealParaNumero(stateDados.total_revenue),
		};

		if(camposInvalidos(dadosEnviar, {
			name: 'Nome do projeto',
		}, ['id_company', 'id_customer', 'name'])) return;

		const dadosEnviarSemKeysVazias = removerKeysVazias(dadosEnviar);

		setLoadingCadastrarProjeto(true);

		const retInsertProjeto = await apiProjects.insert.post(dadosEnviarSemKeysVazias);

		if(retInsertProjeto.status === 200) {
			msg.success('Projeto cadastrado com sucesso.');
			onSuccess({
				ret: retInsertProjeto,
			})
		} else {
			msgs([retInsertProjeto]);
			onError({
				ret: retInsertProjeto,
			});
		}

		setLoadingCadastrarProjeto(false);
	}

	return (
		<Portal
			titulo="Cadastrar novo projeto"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<FormProjetos
				onSubmit={cadastrarProjeto}
				isCadastro={true}
				// States
				stateDados={stateDados}
				setStateDados={setStateDados}
				stateDescricao={stateDescricao}
				setStateDescricao={setStateDescricao}
				stateEmpresas={stateEmpresas}
				stateClientes={stateClientes}
				// stateProdutos={stateProdutos}
				stateCenarios={stateCenarios}
				stateStatus={stateStatus}
				stateAnexos={stateAnexos}
				setStateAnexos={setStateAnexos}
				stateUsuarios={stateUsuarios}
				stateUsuariosSelecionado={stateUsuariosSelecionado}
				setStateUsuariosSelecionado={setStateUsuariosSelecionado}
				stateFreelancers={stateFreelancers}
				setStateFreelancers={setStateFreelancers}
				stateDataInicio={stateDataInicio}
				setStateDataInicio={setStateDataInicio}
				stateDataFim={stateDataFim}
				setStateDataFim={setStateDataFim}
				// stateSolucoes={stateSolucoes}
				stateAreas={stateAreas}
				setStateAreas={setStateAreas}
				// Loading
				loadingGetEmpresas={loadingGetEmpresas}
				loadingGetClientes={loadingGetClientes}
				// loadingGetProdutos={loadingGetProdutos}
				loadingGetCenarios={loadingGetCenarios}
				loadingGetStatus={loadingGetStatus}
				// loadingGetSolucoes={loadingGetSolucoes}
				loadingGetUsuarios={loadingGetUsuarios}
				loadingGetAreas={loadingGetAreas}
				// funções
				getClientes={getClientes}
				getUsuarios={getUsuarios}

				acessoRestrito={acessoRestrito}
				after={
					<div className="form-bottom">
						<Button2
							_={{
								value: 'Cancelar',
								type: 'button',
								onClick: onClose,
								className: 'btn btn-1'
							}}
						/>
						<Button2
							_={{
								value: 'Cadastrar projeto',
								onClick: e => cadastrarProjeto(e),
							}}
							wrap="margin-l-15"
							loading={loadingCadastrarProjeto}
						/>
					</div>
				}
			/>
		</Portal>
	);
};

export default PortalProjetosFormCadastro;