import { DEV } from "@_config/config";
import { apenasNumeros, arrGetCheckeds, checkArr, dataApiCrop, formatarSelect, formatarSelectAlgolia, msg, removerKeysVazias } from "@func/utils";

import { api } from "@_api/api";
import apiAlgolia from '@api/algolia/algolia';
import apiBriefingsCombos from '@api/briefingsCombos/briefingsCombos';
import apiProjects from '@api/projects/projects';
import apiProjectsCombos from '@api/projectsCombos/projectsCombos';
import apiTasksCombos from '@api/tasksCombos/tasksCombos';
import apiBriefingFreelancers from "@api/briefingFreelancers/briefingFreelancers";
import apiBriefingGoals from "@api/briefingGoals/briefingGoals";
import apiBriefingSegments from "@api/briefingSegments/briefingSegments";
import apiBriefingLanguages from "@api/briefingLanguages/briefingLanguages";
import apiBriefingCompetences from "@api/briefingCompetences/briefingCompetences";
import apiProfessions from "@api/professions/professions";
import apiTemplates from "@api/templates/templates";
import apiBriefingsHistory from "@api/briefingsHistory/briefingsHistory";

// Freelancer
import apiFreelancerSocial from '@api/freelancerSocial/freelancerSocial';
import { apiCep } from "@_api/requests/cep/cep";
import apiCnpj from "@api/cnpj/cnpj";
import apiFreelancerGoals from "@api/freelancerGoals/freelancerGoals";
import { config } from "@api/axios";
import apiFreelancerExperience from "@api/freelancerExperience/freelancerExperience";
import apiFreelancerFormation from "@api/freelancerFormation/freelancerFormation";
import apiFreelancerCertificate from "@api/freelancerCertificate/freelancerCertificate";
import apiFreelancerAwards from "@api/freelancerAwards/freelancerAwards";
import apiFreelancerPortfolio from "@api/freelancerPortfolio/freelancerPortfolio";
import apiFreelancerPayment from "@api/freelancerPayment/freelancerPayment";
import apiFreelancerCompany from "@api/freelancerCompany/freelancerCompany";
import apiFreelancerSkills from "@api/freelancerSkills/freelancerSkills";
import apiFreelancerLanguages from "@api/freelancerLanguages/freelancerLanguages";
import { checkItensMergeIdiomas } from "@shared/functions/components";
import apiFreelancerSegments from "@api/freelancerSegments/freelancerSegments";
import apiFreelancerCompetence from "@api/freelancerCompetence/freelancerCompetence";
import apiFreelancer from "@api/freelancer/freelancer";
import apiUserCompetences from "@api/userCompetences/userCompetences";
import apiUserSkills from "@api/userSkills/userSkills";
import apiBriefingSkills from "@api/briefingSkills/briefingSkills";
import { msgs, msgStatus } from "@_func/request";

/*
** API - Get Empresas
** ================================================== */
export const apiGetEmpresas = async ({setLoadingGetEmpresas, setStateEmpresas}) => {
	setLoadingGetEmpresas(true);
	const retGetEmpresas = await api.select.company.get();
	if(retGetEmpresas.status === 200) {
		setStateEmpresas(formatarSelect({dados: retGetEmpresas.data, keyValue: 'id'}));
	} else {
		msgs([retGetEmpresas]);
	}
	setLoadingGetEmpresas(false);
};

/*
** API - Get Empresas
** ================================================== */
export const apiGetOneAlgolia = async ({text, setLoadingGetAlgolia, setStateAlgolia}) => {
	setLoadingGetAlgolia(true);

	const dadosEnviar = {
		all_results: 1,
		text: text,
	};

	const retGetAlgolia = await apiAlgolia.withoutRelevance.post(dadosEnviar);

	var retorno = [];

	if(retGetAlgolia.status === 200) {
		setStateAlgolia(formatarSelectAlgolia(retGetAlgolia.data));
		retorno = formatarSelectAlgolia(retGetAlgolia.data);
	} else {
		setStateAlgolia([{
			value: text,
			label: `Freelancer ID: ${text}`
		}]);
		msgs([retGetAlgolia]);
	}

	setStateAlgolia(retorno);
	setLoadingGetAlgolia(false);
};

/*
** API - Get Empresas
** ================================================== */
export const apiGetCargos = async ({setLoadingGetCargos, setStateCargos}) => {
	setLoadingGetCargos(true);
	const retGetCargos = await api.select.positions.get();
	if(retGetCargos.status === 200) {
		setStateCargos(formatarSelect({dados: retGetCargos.data, keyValue: 'id'}));
	} else {
		msgs([retGetCargos]);
	}
	setLoadingGetCargos(false);
};

/*
** API - Get Clientes
** ================================================== */
export const apiGetClientes = async ({id, setLoadingGetClientes, setStateClientes}) => {
	setLoadingGetClientes(true);
	const retGetClientes = await api.select.customers.get(id);
	if(retGetClientes.status === 200) {
		setStateClientes(formatarSelect({dados: retGetClientes.data, keyValue: 'id'}));
	} else {
		// msgs([retGetClientes]);
		setStateClientes([]);
	}
	setLoadingGetClientes(false);
	return retGetClientes;
}

/*
** API - Get Usuários da
** ================================================== */
export const apiGetUsuarios = async ({id, setLoadingGetUsuarios, setStateUsuarios}) => {
	setLoadingGetUsuarios(true);
	const retGetUsuarios = await api.users.getAllUsers.get(id, 2);
	if(retGetUsuarios.status === 200) {
		setStateUsuarios(formatarSelect({dados: retGetUsuarios.data, keyValue: 'id', keyIcone: 'photo', iconClass: 'border-radius-50'}));
	} else {
		setStateUsuarios([]);
	}
	setLoadingGetUsuarios(false);
	return retGetUsuarios;
}

/*
** API - Get projetos
** ================================================== */
export const apiGetSelectProjetos = async ({id, setLoadingGetProjetos, setStateProjetos, showCliente = true}) => {
	setLoadingGetProjetos(true);
	const retGetProjetos = await api.select.projects.get({id_company: id});
	if(retGetProjetos.status === 200) {
		var dados = formatarSelect({dados: retGetProjetos.data, keyValue: 'id', keyIcone: 'photo', iconClass: 'border-radius-50'});
		var retorno = dados;
		// if(DEV) {
		// 	retorno = dados.map(val => {
		// 		return {
		// 			...val,
		// 			label: showCliente ? <>{val.name} <span className='opct-5'>(Nome cliente)</span></> : val.name
		// 		}
		// 	});
		// }
		setStateProjetos(retorno);
	} else {
		setStateProjetos([]);
	}
	setLoadingGetProjetos(false);
	return retGetProjetos;
}

/*
** API - Get projetos
** ================================================== */
export const apiGetSelectProjetosClientes = async ({idCliente, idEmpresa, setLoadingGetProjetos, setStateProjetos}) => {
	setLoadingGetProjetos(true);
	const retGetProjetos = await api.select.projects.get({id_customer: idCliente, id_company: idEmpresa});
	if(retGetProjetos.status === 200) {
		var dados = formatarSelect({dados: retGetProjetos.data, keyValue: 'id'});
		setStateProjetos(dados);
	} else {
		setStateProjetos([]);
	}
	setLoadingGetProjetos(false);
	return retGetProjetos;
}

/*
** API - Get cards
** ================================================== */
export const apiGetSelectCardsStatus = async ({id, setLoadingGetStatusCards, setStateStatusCards}) => {
	setLoadingGetStatusCards(true);
	const retGetStatusCards = await apiTasksCombos.status.get();
	if(retGetStatusCards.status === 200) {
		// setStateStatus(statusJobsAdminTratarStatus(dadosSelect, statusAtual, role));
		setStateStatusCards(formatarSelect({dados: retGetStatusCards.data, keyValue: 'id'}));
	} else {
		setStateStatusCards([]);
	}
	setLoadingGetStatusCards(false);
}

/*
** API - Get status do briefing
** ================================================== */
export const apiGetStatusBriefing = async ({setLoadingGetStatusBriefing, setStateStatusBriefing}) => {
	setLoadingGetStatusBriefing(true);
	const retGetStatusBriefing = await apiBriefingsCombos.status.get();
	if(retGetStatusBriefing.status === 200) {
		setStateStatusBriefing(formatarSelect({dados: retGetStatusBriefing.data, keyValue: 'id', keyIcone: 'photo', iconClass: 'border-radius-50'}));
	} else {
		setStateStatusBriefing([]);
	}
	setLoadingGetStatusBriefing(false);
}

/*
** API - Get status do briefing
** ================================================== */
export const apiGetStatusTask = async ({setLoadingGetStatusTask, setStateStatusTask}) => {
	setLoadingGetStatusTask(true);
	const retGetStatusTask = await apiTasksCombos.status.get();
	if(retGetStatusTask.status === 200) {
		setStateStatusTask(formatarSelect({dados: retGetStatusTask.data, keyValue: 'id'}));
	} else {
		setStateStatusTask([]);
	}
	setLoadingGetStatusTask(false);
}

/*
** APi - Get produtos
** ================================================== */
export const apiGetProdutos = async ({setLoadingGetProdutos, setStateProdutos}) => {
	setLoadingGetProdutos(true);
	const retGeProdutos = await apiProjectsCombos.tipoProduto.get();
	if(retGeProdutos.status === 200) {
		setStateProdutos(formatarSelect({dados: retGeProdutos.data}));
	} else {
		setStateProdutos([]);
	}
	setLoadingGetProdutos(false);
}

/*
** APi - Get Cenários
** ================================================== */
export const apiGetCenarios = async ({setLoadingGetCenarios, setStateCenarios}) => {
	setLoadingGetCenarios(true);
	const retGeCenarios = await apiProjectsCombos.scenery.get();
	if(retGeCenarios.status === 200) {
		setStateCenarios(formatarSelect({dados: retGeCenarios.data}));
	} else {
		setStateCenarios([]);
	}
	setLoadingGetCenarios(false);
}

/*
** APi - Get status
** ================================================== */
export const apiGetStatus = async ({setLoadingGetStatus, setStateStatus}) => {
	setLoadingGetStatus(true);
	const retGeStatus = await apiProjectsCombos.status.get();
	if(retGeStatus.status === 200) {
		setStateStatus(formatarSelect({dados: retGeStatus.data}));
	} else {
		setStateStatus([]);
	}
	setLoadingGetStatus(false);
}

/*
** APi - Get soluções cadastro
** ================================================== */
export const apiGetSolucoes = async ({setLoadingGetSolucoes, setStateSolucoes}) => {
	setLoadingGetSolucoes(true);
	const retGetSolucoes = await apiProjectsCombos.solution.get();
	if(retGetSolucoes.status === 200) {
		var formatado = formatarSelect({dados: retGetSolucoes.data, keyName: 'name', keyValue: 'value', keyIcone: 'url', caminhoFotoDefault: false});
		setStateSolucoes(formatado);
	} else {
		msgs([retGetSolucoes]);
	}
	setLoadingGetSolucoes(false);
}

/*
** APi - Get areas
** ================================================== */
export const apiGetAreas = async ({setLoadingGetAreas, setStateAreas}) => {
	setLoadingGetAreas(true);
	const retGetAreas = await api.select.areas.get();
	if(retGetAreas.status === 200) {
		var formatado = formatarSelect({dados: retGetAreas.data, keyValue: 'id'});
		setStateAreas(formatado);
	} else {
		msgs([retGetAreas]);
	}
	setLoadingGetAreas(false);
	return retGetAreas;
}

/*
** APi - Get template
** ================================================== */
export const apiGetTemplate = async ({id, setLoadingGetTemplate, setStateTemplate}) => {
	setLoadingGetTemplate(true);
	const retGetTemplate = await apiTemplates.getOne.get(id);
	if(retGetTemplate.status === 200) {
		setStateTemplate(retGetTemplate.data);
	} else {
		msgs([retGetTemplate]);
	}
	setLoadingGetTemplate(false);
	return retGetTemplate;
}

/*
** APi - Get templates
** ================================================== */
export const apiGetTemplates = async ({idTipo, setLoadingGetTemplates, setStateTemplates}) => {
	setLoadingGetTemplates(true);
	const retGetTemplates = await apiTemplates.all.get({type: idTipo, limit: 999});
	if(retGetTemplates.status === 200) {
		var formatado = formatarSelect({dados: retGetTemplates.data, keyValue: 'id', keyName: 'title'});
		setStateTemplates(formatado);
	} else {
		msgs([retGetTemplates]);
	}
	setLoadingGetTemplates(false);
	return retGetTemplates;
}

/*
** API - Get Tipos de template
** ================================================== */
export const apiGetTemplatesTipo = async ({filtros = {}, setLoadingGetTemplatesTipo, setStateTemplatesTipo}) => {
	setLoadingGetTemplatesTipo(true);
	const retGetTemplatesTipo = await api.select.templatesType.get(filtros);
	if(retGetTemplatesTipo.status === 200) {
		setStateTemplatesTipo(formatarSelect({dados: retGetTemplatesTipo.data, keyValue: 'id'}));
	} else {
		msgs([retGetTemplatesTipo]);
		setStateTemplatesTipo([]);
	}
	setLoadingGetTemplatesTipo(false);
	return retGetTemplatesTipo;
}

/*
** API - Get Tipos de template
** ================================================== */
export const apiGetTemplatesPorTipo = async ({idTipo, setLoadingGetTemplatesPorTipo, setStateTemplatesPorTipo, active = 1, showMsg = true}) => {
	setLoadingGetTemplatesPorTipo(true);
	const retGetTemplatesPorTipo = await apiTemplates.all.get({type: idTipo, active, limit: 999});
	if(retGetTemplatesPorTipo.status === 200) {
		setStateTemplatesPorTipo(formatarSelect({dados: retGetTemplatesPorTipo.data, keyValue: 'id', keyName: 'title'}));
	} else {
		if(showMsg) {
			msgs([retGetTemplatesPorTipo]);
		}
		setStateTemplatesPorTipo([]);
	}
	setLoadingGetTemplatesPorTipo(false);
	return retGetTemplatesPorTipo;
}

/*
** APi - Get profissoes predefinidas
** ================================================== */
export const apiGetProfissoesPredefinidas = async ({idArea, setLoadingGetProfissoesPredefinidas, setStateProfissoesPredefinidas}) => {
	setLoadingGetProfissoesPredefinidas(true);
	const retGetProfissoesPredefinidas = await apiProfessions.all.get({id_area: idArea});
	if(retGetProfissoesPredefinidas.status === 200) {
		var formatado = formatarSelect({dados: retGetProfissoesPredefinidas.data, keyValue: 'id'});
		setStateProfissoesPredefinidas(formatado);
	} else {
		msgs([retGetProfissoesPredefinidas]);
	}
	setLoadingGetProfissoesPredefinidas(false);
	return retGetProfissoesPredefinidas;
}

/*
** APi - Get habilidades
** ================================================== */
export const apiGetHabilidades = async ({idArea, setLoadingGetHabilidades, setStateHabilidades}) => {
	setLoadingGetHabilidades(true);
	const retGetHabilidades = await api.select.skills.get(idArea);
	if(retGetHabilidades.status === 200) {
		var formatado = formatarSelect({dados: retGetHabilidades.data, keyValue: 'id'});
		setStateHabilidades(formatado);
	} else {
		msgs([retGetHabilidades]);
	}
	setLoadingGetHabilidades(false);
	return retGetHabilidades;
}

/*
** APi - Get Estados
** ================================================== */
export const apiGetEstados = async ({setLoadingGetEstados, setStateEstados}) => {
	setLoadingGetEstados(true);
	const retGetEstados = await api.select.state.get();
	if(retGetEstados.status === 200) {
		var formatado = formatarSelect({dados: retGetEstados.data, keyValue: 'id'});
		setStateEstados(formatado);
	} else {
		msgs([retGetEstados]);
	}
	setLoadingGetEstados(false);
}

/*
** APi - Get Idiomas
** ================================================== */
export const apiGetIdiomas = async ({setLoadingGetIdiomas, setStateIdiomas}) => {
	setLoadingGetIdiomas(true);
	const retGetIdiomas = await api.select.languages.get();
	if(retGetIdiomas.status === 200) {
		var formatado = formatarSelect({dados: retGetIdiomas.data, keyValue: 'id'});
		setStateIdiomas(formatado);
	} else {
		msgs([retGetIdiomas]);
	}
	setLoadingGetIdiomas(false);
	return retGetIdiomas;
}

/*
** APi - Get competências
** ================================================== */
export const apiGetCompetencias = async ({setLoadingGetCompetencias, setStateCompetencias}) => {
	setLoadingGetCompetencias(true);
	const retGetCompetencias = await api.select.competences.get();
	if(retGetCompetencias.status === 200) {
		var formatado = formatarSelect({dados: retGetCompetencias.data, keyValue: 'id'});
		setStateCompetencias(formatado);
	} else {
		msgs([retGetCompetencias]);
	}
	setLoadingGetCompetencias(false);
	return retGetCompetencias;
}

/*
** APi - Get Objetivos
** ================================================== */
export const apiGetObjetivos = async ({setLoadingGetObjetivos, setStateObjetivos}) => {
	setLoadingGetObjetivos(true);
	const retGetObjetivos = await api.select.goals.get();
	if(retGetObjetivos.status === 200) {
		var formatado = formatarSelect({dados: retGetObjetivos.data, keyValue: 'id'});
		setStateObjetivos(formatado);
	} else {
		msgs([retGetObjetivos]);
	}
	setLoadingGetObjetivos(false);
	return retGetObjetivos;
}

/*
** APi - Get Segmentos
** ================================================== */
export const apiGetSegmentos = async ({setLoadingGetSegmentos, setStateSegmentos}) => {
	setLoadingGetSegmentos(true);
	const retGetSegmentos = await api.select.segments.get();
	if(retGetSegmentos.status === 200) {
		var formatado = formatarSelect({dados: retGetSegmentos.data, keyValue: 'id'});
		setStateSegmentos(formatado);
	} else {
		msgs([retGetSegmentos]);
	}
	setLoadingGetSegmentos(false);
	return retGetSegmentos;
}

/*
** APi - Get count projetos
** ================================================== */
export const apiGetProjetosCount = async ({id, setLoadingGetProjetosCount, setStateProjetosCount}) => {
	setLoadingGetProjetosCount(true);
	const retGetProjetosCount = await apiProjects.counts.get(id);
	if(retGetProjetosCount.status === 200 && retGetProjetosCount.metadata && typeof(retGetProjetosCount.metadata) === 'object') {
		setStateProjetosCount(retGetProjetosCount.metadata);
	}
	setLoadingGetProjetosCount(false);
}

/*
** APi - Get freelancers briefing
** ================================================== */
export const apiGetBriefingFreelancersGetAll = async ({idBriefing, setLoadingGetBriefingFreelancers, setStateBriefingFreelancers}) => {
	setLoadingGetBriefingFreelancers(true);
	const retGetFreelancers = await apiBriefingFreelancers.getAll.get(idBriefing);
	if(retGetFreelancers.status === 200) {
		var formatado = formatarSelect({dados: retGetFreelancers.data, keyName: 'name', keyValue: 'value', keyIcone: 'url', caminhoFotoDefault: false});
		setStateBriefingFreelancers(formatado);
	} else {
		msgs([retGetFreelancers]);
	}
	setLoadingGetBriefingFreelancers(false);
	return retGetFreelancers;
}

/*
** APi - Get freelancers objetivos
** ================================================== */
export const apiGetBriefingObjetivosGetAll = async ({idBriefing, setLoadingGetBriefingObjetivos, setStateBriefingObjetivos, showMsg = true}) => {
	setLoadingGetBriefingObjetivos(true);
	const retGetObjetivos = await apiBriefingGoals.getAll.get(idBriefing);
	if(retGetObjetivos.status === 200) {
		var formatado = formatarSelect({dados: retGetObjetivos.data, keyName: 'name', keyValue: 'value', keyIcone: 'url', caminhoFotoDefault: false});
		setStateBriefingObjetivos(formatado);
	} else {
		if(showMsg) {
			msgs([retGetObjetivos]);
		}
	}
	setLoadingGetBriefingObjetivos(false);
	return retGetObjetivos;
}

/*
** APi - Get freelancers segmentos
** ================================================== */
export const apiGetBriefingSegmentosGetAll = async ({idBriefing, setLoadingGetBriefingSegmentos, setStateBriefingSegmentos, showMsg = true}) => {
	setLoadingGetBriefingSegmentos(true);
	const retGetSegmentos = await apiBriefingSegments.getAll.get(idBriefing);
	if(retGetSegmentos.status === 200) {
		var formatado = formatarSelect({dados: retGetSegmentos.data, keyName: 'name', keyValue: 'value', keyIcone: 'url', caminhoFotoDefault: false});
		setStateBriefingSegmentos(formatado);
	} else {
		if(showMsg) {
			msgs([retGetSegmentos]);
		}
	}
	setLoadingGetBriefingSegmentos(false);
	return retGetSegmentos;
}

/*
** APi - Get freelancers habilidades
** ================================================== */
export const apiGetBriefingHabilidadesGetAll = async ({idBriefing, setLoadingGetBriefingHabilidades, setStateBriefingHabilidades, showMsg = true}) => {
	setLoadingGetBriefingHabilidades(true);
	const retGetHabilidades = await apiBriefingSkills.getAll.get(idBriefing);
	if(retGetHabilidades.status === 200) {
		var formatado = formatarSelect({dados: retGetHabilidades.data, keyName: 'name', keyValue: 'value'});
		setStateBriefingHabilidades(formatado);
	} else {
		if(showMsg) {
			msgs([retGetHabilidades]);
		}
	}
	setLoadingGetBriefingHabilidades(false);
	return retGetHabilidades;
}

/*
** APi - Get freelancers idiomas
** ================================================== */
export const apiGetBriefingIdiomasGetAll = async ({idBriefing, setLoadingGetBriefingIdiomas, setStateBriefingIdiomas, showMsg = true}) => {
	setLoadingGetBriefingIdiomas(true);
	const retGetIdiomas = await apiBriefingLanguages.getAll.get(idBriefing);
	if(retGetIdiomas.status === 200) {
		var formatado = formatarSelect({dados: retGetIdiomas.data, keyName: 'name', keyValue: 'value', keyIcone: 'url', caminhoFotoDefault: false});
		setStateBriefingIdiomas(formatado);
	} else {
		if(showMsg) {
			msgs([retGetIdiomas]);
		}
	}
	setLoadingGetBriefingIdiomas(false);
	return retGetIdiomas;
}

/*
** APi - Get freelancers competências
** ================================================== */
export const apiGetBriefingCompetenciasGetAll = async ({idBriefing, setLoadingGetBriefingCompetencias, setStateBriefingCompetencias, showMsg = true}) => {
	setLoadingGetBriefingCompetencias(true);
	const retGetCompetencias = await apiBriefingCompetences.getAll.get(idBriefing);
	if(retGetCompetencias.status === 200) {
		var formatado = formatarSelect({dados: retGetCompetencias.data, keyName: 'name', keyValue: 'value', keyIcone: 'url', caminhoFotoDefault: false});
		setStateBriefingCompetencias(formatado);
	} else {
		if(showMsg) {
			msgs([retGetCompetencias]);
		}
	}
	setLoadingGetBriefingCompetencias(false);
	return retGetCompetencias;
}

/*
** APi - Get histórico de atualizações
** ================================================== */
export const apiGetBriefingHistoricoAtualizacoesGetAll = async ({idBriefing, setLoadingGetBriefingHistoricoAtualizacoes, setStateBriefingHistoricoAtualizacoes}) => {
	setLoadingGetBriefingHistoricoAtualizacoes(true);
	const retGetHistoricoAtualizacoes = await apiBriefingsHistory.getAll.get(idBriefing);
	if(retGetHistoricoAtualizacoes.status === 200) {
		var formatado = formatarSelect({dados: retGetHistoricoAtualizacoes.data, keyName: 'name', keyValue: 'value'});
		setStateBriefingHistoricoAtualizacoes(formatado);
	} else {
		msgs([retGetHistoricoAtualizacoes]);
	}
	setLoadingGetBriefingHistoricoAtualizacoes(false);
	return retGetHistoricoAtualizacoes;
}

/*
** APi - Get países
** ================================================== */
export const apiGetPaises = async ({setLoadingGetPaises, setStatePaises}) => {
	setLoadingGetPaises(true);
	const retGetPaises = await api.select.countrys.get();
	if(retGetPaises.status === 200) {
		var formatado = formatarSelect({dados: retGetPaises.data, keyValue: 'id'});
		setStatePaises(formatado);
	} else {
		msgs([retGetPaises]);
	}
	setLoadingGetPaises(false);
	return retGetPaises;
}

/*
** APi - Get CEP
** ================================================== */
export const apiGetCep = async ({cep, stateEndereco, setStateEndereco, setLoadingGetEndereco}) => {
	if(setStateEndereco) {
		setStateEndereco({
			...stateEndereco,
			street: '',
			number: '',
			complement: '',
			district: '',
			city: '',
			state: '',
		})
	}

	setLoadingGetEndereco(true);

	const retGetCEP = await apiCep.get(cep);

	if(retGetCEP.status === 200) {
		setStateEndereco({
			...stateEndereco,
			zip_code: cep,
			street: retGetCEP.data.street,
			district: retGetCEP.data.district,
			city: retGetCEP.data.city,
			state: retGetCEP.data.uf,
		});
	} else {
		msgs([retGetCEP]);
	}

	setLoadingGetEndereco(false);

	return retGetCEP;
}

/*
** APi - Get CNPJ
** ================================================== */
export const apiGetCnpj = async ({cnpj, stateDadosEmpresa, setStateDadosEmpresa, setLoadingGetDadosEmpresa}) => {
	if(setStateDadosEmpresa) {
		setStateDadosEmpresa({
			...stateDadosEmpresa,
			cnpj: '',
			razao_social: '',
			fantasy_name: '',
			address: '',
		})
	}

	setLoadingGetDadosEmpresa(true);

	const retGetCNPJ = await apiCnpj.get(apenasNumeros(cnpj), {
		...config(),
		'timeout': 10000,
	});

	if(retGetCNPJ.status === 200) {
		setStateDadosEmpresa({
			...stateDadosEmpresa,
			...retGetCNPJ.data,
			cnpj: cnpj,
			razao_social: retGetCNPJ.data.nome,
			fantasy_name: retGetCNPJ.data.fantasia,
			address: `${retGetCNPJ.data.logradouro} - ${retGetCNPJ.data.bairro}, ${retGetCNPJ.data.municipio} - ${retGetCNPJ.data.uf} - ${retGetCNPJ.data.cep}`
		});
	} else {
		msgs([retGetCNPJ]);
	}

	setLoadingGetDadosEmpresa(false);

	return retGetCNPJ;
}

/*
** APi - Get Senioridade
** ================================================== */
export const apiGetSenioridades = async ({setLoadingGetSenioridades, setStateSenioridades}) => {
	setLoadingGetSenioridades(true);
	const retGetSenioridades = await api.select.seniority.get();
	if(retGetSenioridades.status === 200) {
		var formatado = formatarSelect({dados: retGetSenioridades.data, keyValue: 'id'});
		setStateSenioridades(formatado);
	} else {
		msgs([retGetSenioridades]);
	}
	setLoadingGetSenioridades(false);
	return retGetSenioridades;
}

/*
** APi - Get bancos
** ================================================== */
export const apiGetBancos = async ({setLoadingGetBancos, setStateBancos, showMsg = true}) => {
	setLoadingGetBancos(true);
	const retGetBancos = await api.select.banks.get();
	if(retGetBancos.status === 200) {
		var formatado = formatarSelect({dados: retGetBancos.data, keyValue: 'id'});
		setStateBancos(formatado);
	} else if(showMsg) {
		msgs([retGetBancos]);
	}
	setLoadingGetBancos(false);
	return retGetBancos;
}

/*
** APi - Get bancos
** ================================================== */
export const apiGetTiposPagamento = async ({setLoadingGetTiposPagamento, setStateTiposPagamento}) => {
	setLoadingGetTiposPagamento(true);
	const retGetTiposPagamento = await api.select.paymentsType.get();
	if(retGetTiposPagamento.status === 200) {
		var formatado = formatarSelect({dados: retGetTiposPagamento.data, keyValue: 'id'});
		setStateTiposPagamento(formatado);
	} else {
		msgs([retGetTiposPagamento]);
	}
	setLoadingGetTiposPagamento(false);
	return retGetTiposPagamento;
}

/*
** API - Get empresa freelancer
** ================================================== */
export const apiGetDadosEmpresa = async ({idFreelancer, setLoadingGetDadosEmpresa, setStateDadosEmpresa, showMsg = true}) => {
	setLoadingGetDadosEmpresa(true);
	const retGetDadosEmpresa = await apiFreelancerCompany.getOne.get(idFreelancer);
	if(retGetDadosEmpresa.status === 200) {
		setStateDadosEmpresa(retGetDadosEmpresa.data);
	} else if(showMsg) {
		msgs([retGetDadosEmpresa]);
	}
	setLoadingGetDadosEmpresa(false);
	return retGetDadosEmpresa;
}

/*
** FREELANCERS
** ================================================== */

/*
** Redes sociais
** ================================================== */
export const apiGetRedesSociaisFreelancer = async ({idUsuario, setLoadingGetRedesSociaisFreelancer, setStateRedesSociaisFreelancer, showMsg = true}) => {
	setLoadingGetRedesSociaisFreelancer(true);
	const retGetRedesSociaisFreelancer = await apiFreelancerSocial.all.get(idUsuario);
	if(retGetRedesSociaisFreelancer.status === 200) {
		var formatado = formatarSelect({dados: retGetRedesSociaisFreelancer.data, keyValue: 'id'});
		setStateRedesSociaisFreelancer(formatado);
	} else if(showMsg) {
		msgs([retGetRedesSociaisFreelancer]);
	}
	setLoadingGetRedesSociaisFreelancer(false);
	return retGetRedesSociaisFreelancer;
}

/*
** Experiencias
** ================================================== */
export const apiGetExperiencias = async ({idFreelancer, setLoadingGetExperiencias, setStateExperiencias, showMsg = true}) => {
	setLoadingGetExperiencias(true);
	const retGetExperiencias = await apiFreelancerExperience.all.get(idFreelancer);
	if(retGetExperiencias.status === 200) {
		var formatado = formatarSelect({dados: retGetExperiencias.data, keyValue: 'id'});
		formatado = formatado.map(val => {
			return {...val, start_date: dataApiCrop(val.start_date), end_date: dataApiCrop(val.end_date)}
		});
		setStateExperiencias(formatado);
	} else if(showMsg) {
		msgs([retGetExperiencias]);
	}
	setLoadingGetExperiencias(false);
	return retGetExperiencias;
}

/*
** Formações
** ================================================== */
export const apiGetFormacoes = async ({idFreelancer, setLoadingGetFormacoes, setStateFormacoes, showMsg = true}) => {
	setLoadingGetFormacoes(true);
	const retGetFormacoes = await apiFreelancerFormation.all.get(idFreelancer);
	if(retGetFormacoes.status === 200) {
		var formatado = formatarSelect({dados: retGetFormacoes.data, keyValue: 'id'});
		formatado = formatado.map(val => {
			return {...val, start_date: dataApiCrop(val.start_date), end_date: dataApiCrop(val.end_date)}
		});
		setStateFormacoes(formatado);
	} else if(showMsg) {
		msgs([retGetFormacoes]);
	}
	setLoadingGetFormacoes(false);
	return retGetFormacoes;
}

/*
** Certificados
** ================================================== */
export const apiGetCertificados = async ({idFreelancer, setLoadingGetCertificados, setStateCertificados, showMsg = true}) => {
	setLoadingGetCertificados(true);
	const retGetCertificados = await apiFreelancerCertificate.all.get(idFreelancer);
	if(retGetCertificados.status === 200) {
		var formatado = formatarSelect({dados: retGetCertificados.data, keyValue: 'id'});
		formatado = formatado.map(val => {
			return {...val, issue_date: dataApiCrop(val.issue_date)}
		});
		setStateCertificados(formatado);
	} else if(showMsg) {
		msgs([retGetCertificados]);
	}
	setLoadingGetCertificados(false);
	return retGetCertificados;
}

/*
** Prêmios
** ================================================== */
export const apiGetPremios = async ({idFreelancer, setLoadingGetPremios, setStatePremios, showMsg = true}) => {
	setLoadingGetPremios(true);
	const retGetPremios = await apiFreelancerAwards.all.get(idFreelancer);
	if(retGetPremios.status === 200) {
		var formatado = formatarSelect({dados: retGetPremios.data, keyValue: 'id'});
		formatado = formatado.map(val => {
			return {...val, issue_date: dataApiCrop(val.issue_date)}
		});
		setStatePremios(formatado);
	} else if(showMsg) {
		msgs([retGetPremios]);
	}
	setLoadingGetPremios(false);
	return retGetPremios;
}

/*
** Portfólios
** ================================================== */
export const apiGetPortfolios = async ({idFreelancer, setLoadingGetPortfolios, setStatePortfolios, showMsg = true}) => {
	setLoadingGetPortfolios(true);
	const retGetPortfolios = await apiFreelancerPortfolio.all.get(idFreelancer);
	if(retGetPortfolios.status === 200) {
		var formatado = formatarSelect({dados: retGetPortfolios.data, keyValue: 'id'});
		setStatePortfolios(formatado);
	} else if(showMsg) {
		msgs([retGetPortfolios]);
	}
	setLoadingGetPortfolios(false);
	return retGetPortfolios;
}

/*
** Pagamento
** ================================================== */
export const apiGetDadosBancarios = async ({idFreelancer, setLoadingGetDadosBancarios, setStateDadosBancarios, showMsg = true}) => {
	setLoadingGetDadosBancarios(true);
	const retGetDadosBancarios = await apiFreelancerPayment.getOne.get(idFreelancer);
	if(retGetDadosBancarios.status === 200) {
		setStateDadosBancarios({
			...retGetDadosBancarios.data,
			_payment_type: retGetDadosBancarios.data.payment_type,
			...(!retGetDadosBancarios.data.pix_value ? {pix_value: ''} : {})
		});
	} else if(showMsg) {
		msgs([retGetDadosBancarios]);
	}
	setLoadingGetDadosBancarios(false);
	return retGetDadosBancarios;
}


/*
** MESCLADOS
** ================================================== */

/*
** Habilidades
** ================================================== */
export const apiGetHabilidadesChecked = async ({idFreelancer, idArea, setLoadingGetHabilidades, setStateHabilidades}) => {
	setLoadingGetHabilidades(true);
	const retGetHabilidades = await apiGetHabilidades({idArea: idArea, setLoadingGetHabilidades, setStateHabilidades});
	if(retGetHabilidades.status === 200) {
		const retGetFreelancerHabilidades = await apiFreelancerSkills.all.get(idFreelancer);
		const checkedHabilidades = checkArr(retGetHabilidades.data, retGetFreelancerHabilidades.data);
		setLoadingGetHabilidades(false);
		setStateHabilidades(checkedHabilidades);
		return checkedHabilidades;
	} else {
		msgs([retGetHabilidades]);
	}
	setLoadingGetHabilidades(false);
	return retGetHabilidades;
}

/*
** Habilidades Usuário
** ================================================== */
export const apiGetHabilidadesUsuarioChecked = async ({idUsuario, idArea, setLoadingGetHabilidades, setStateHabilidades}) => {
	setLoadingGetHabilidades(true);
	const retGetHabilidades = await apiGetHabilidades({idArea: idArea, setLoadingGetHabilidades, setStateHabilidades});
	if(retGetHabilidades.status === 200) {
		const retGetFreelancerHabilidades = await apiUserSkills.getAll.get(idUsuario);
		const checkedHabilidades = checkArr(retGetHabilidades.data, retGetFreelancerHabilidades.data);
		setLoadingGetHabilidades(false);
		setStateHabilidades(checkedHabilidades);
		return checkedHabilidades;
	} else {
		msgs([retGetHabilidades]);
	}
	setLoadingGetHabilidades(false);
	return retGetHabilidades;
}

/*
** Idiomas
** ================================================== */
export const apiGetIdiomasChecked = async ({idFreelancer, setLoadingGetIdiomas, setStateIdiomasNiveis, setStateIdiomas}) => {
	setLoadingGetIdiomas(true);
	const retGetIdiomas = await api.select.languages.get();
	const retGetIdiomasNiveis = await api.select.languages_levels.get();
	if(retGetIdiomas.status === 200 && retGetIdiomasNiveis.status === 200) {
		const retGetFreelancerIdiomas = await apiFreelancerLanguages.all.get(idFreelancer);
		setStateIdiomasNiveis(retGetIdiomasNiveis.data);
		const idiomasChecked = checkItensMergeIdiomas({
			retornoGeral: retGetIdiomas,
			retornoChecked: retGetFreelancerIdiomas,
			retornoNiveis: retGetIdiomasNiveis,
		});
		setStateIdiomas(idiomasChecked);
		setLoadingGetIdiomas(false);
		return idiomasChecked;
	} else {
		msgs([retGetIdiomas]);
	}
	setLoadingGetIdiomas(false);
	return retGetIdiomas;
}

/*
** Segmentos
** ================================================== */
export const apiGetSegmentosChecked = async ({idFreelancer, setLoadingGetSegmentos, setStateSegmentos}) => {
	setLoadingGetSegmentos(true);
	const retGetSegmentos = await api.select.segments.get();
	if(retGetSegmentos.status === 200) {
		const retFreelancerSegmentos = await apiFreelancerSegments.all.get(idFreelancer);
		const segmentosChecked = checkArr(retGetSegmentos.data, retFreelancerSegmentos.data, 'id_segment');
		setStateSegmentos(segmentosChecked);
		setLoadingGetSegmentos(false);
		return segmentosChecked;
	} else {
		msgs([retGetSegmentos]);
	}
	setLoadingGetSegmentos(false);
	return retGetSegmentos;
}

/*
** Competencias
** ================================================== */
export const apiGetCompetenciasChecked = async ({idFreelancer, setLoadingGetCompetencias, setStateCompetencias}) => {
	setLoadingGetCompetencias(true);
	const retGetCompetencias = await api.select.competences.get();
	if(retGetCompetencias.status === 200) {
		const retFreelancerCompetencias = await apiFreelancerCompetence.all.get(idFreelancer);
		const competenciasChecked = checkArr(retGetCompetencias.data, retFreelancerCompetencias.data, 'id_competence');
		setStateCompetencias(competenciasChecked);
		setLoadingGetCompetencias(false);
		return competenciasChecked;
	} else {
		msgs([retGetCompetencias]);
	}
	setLoadingGetCompetencias(false);
	return retGetCompetencias;
}

/*
** Competencias usuário
** ================================================== */
export const apiGetCompetenciasUsuarioChecked = async ({idUsuario, setLoadingGetCompetencias, setStateCompetencias}) => {
	setLoadingGetCompetencias(true);
	const retGetCompetencias = await api.select.competences.get();
	if(retGetCompetencias.status === 200) {
		const retFreelancerCompetencias = await apiUserCompetences.getAll.get(idUsuario);
		const competenciasChecked = checkArr(retGetCompetencias.data, retFreelancerCompetencias.data, 'id_competence');
		setStateCompetencias(competenciasChecked);
		setLoadingGetCompetencias(false);
		return competenciasChecked;
	} else {
		msgs([retGetCompetencias]);
	}
	setLoadingGetCompetencias(false);
	return retGetCompetencias;
}

/*
** Objetivos
** ================================================== */
export const apiGetObjetivosChecked = async ({idFreelancer, setLoadingGetObjetivos, setStateObjetivos}) => {
	setLoadingGetObjetivos(true);
	const retGetObjetivos = await api.select.goals.get();
	if(retGetObjetivos.status === 200) {
		const retGetFreelancerObjetivos = await apiFreelancerGoals.all.get(idFreelancer);
		var objetivosChecked = checkArr(retGetObjetivos.data, retGetFreelancerObjetivos.data, 'id_goal');
		setStateObjetivos(objetivosChecked);
		setLoadingGetObjetivos(false);
		return objetivosChecked;
	} else {
		msgs([retGetObjetivos]);
	}
	setLoadingGetObjetivos(false);
	return retGetObjetivos;
}

/*
** Mesclados
** ================================================== */

/*
** Habilidades
** ================================================== */
export const apiEditarHabilidades = async ({idFreelancer, loadingEditarHabilidades, setLoadingEditarHabilidades, stateHabilidades}) => {

	if(loadingEditarHabilidades) {
		msg.warning('Por favor, aguarde...');
		return false;
	}

	const habilidadesSelecionadas = arrGetCheckeds(stateHabilidades);

	// Habilidades
	if(habilidadesSelecionadas.length < 3) {
		msg.error('Selecione no mínimo 3 habilidades para poder continuar.');
		return;
	}

	// Habilidades
	if(habilidadesSelecionadas.length > 10) {
		msg.error('Você pode selecionar no máximo 10 habilidades, remove o excedente para poder continuar.');
		return;
	}

	setLoadingEditarHabilidades(true);

	const retInsertHabilidades = await apiFreelancerSkills.insertMany.post({
		id_freelancer: idFreelancer,
		dados: habilidadesSelecionadas.map(value => ({
			id_skill: value.id,
		}))
	});

	if(retInsertHabilidades.status === 200) {
		msg.success('Habilidades atualizadas com sucesso.');
	} else {
		msgs([retInsertHabilidades]);
	}

	setLoadingEditarHabilidades(false);

	return retInsertHabilidades;
}

/*
** Habilidades
** ================================================== */
export const apiEditarHabilidadesUsuario = async ({idUsuario, loadingEditarHabilidades, setLoadingEditarHabilidades, stateHabilidades}) => {

	if(loadingEditarHabilidades) {
		msg.warning('Por favor, aguarde...');
		return false;
	}

	const habilidadesSelecionadas = arrGetCheckeds(stateHabilidades);

	// Habilidades
	if(habilidadesSelecionadas.length < 3) {
		msg.error('Selecione no mínimo 3 habilidades para poder continuar.');
		return;
	}

	// Habilidades
	if(habilidadesSelecionadas.length > 10) {
		msg.error('Você pode selecionar no máximo 10 habilidades, remove o excedente para poder continuar.');
		return;
	}

	setLoadingEditarHabilidades(true);

	const retInsertHabilidades = await apiUserSkills.insertMany.post({
		id_user: idUsuario,
		dados: habilidadesSelecionadas.map(value => ({
			id_skill: value.id,
		}))
	});

	if(retInsertHabilidades.status === 200) {
		msg.success('Habilidades atualizadas com sucesso.');
	} else {
		msgs([retInsertHabilidades]);
	}

	setLoadingEditarHabilidades(false);

	return retInsertHabilidades;
}

/*
** Idiomas
** ================================================== */
export const apiEditarIdiomas = async ({idFreelancer, loadingEditarIdiomas, setLoadingEditarIdiomas, stateIdiomas}) => {

	if(loadingEditarIdiomas) {
		msg.warning('Por favor, aguarde...');
		return false;
	}

	const idiomasSelecionados = arrGetCheckeds(stateIdiomas);

	if(idiomasSelecionados.length < 1) {
		msg.error('Selecione no mínimo 1 idioma para poder continuar');
		return;
	}

	const idiomasSelecionadosSemNivel = [];
	idiomasSelecionados.map(val => {
		if(val.checked === true && !val.id_level) {
			idiomasSelecionadosSemNivel.push(val.name_language);
		}
		return null;
	});

	if(idiomasSelecionadosSemNivel.length > 0) {
		msg.error(
			<>
				{idiomasSelecionadosSemNivel.length > 1 ? (
					<div>Informe os níveis dos idiomas:</div>
				) : (
					<div>Informe o nível do idioma:</div>
				)}
				<div className="margin-t-10">{idiomasSelecionadosSemNivel.join(', ')}</div>
			</>
		);
		return;
	}

	setLoadingEditarIdiomas(true);

	const retInsertFreelancerIdiomas = await apiFreelancerLanguages.insertMany.post({
		id_freelancer: idFreelancer,
		dados: idiomasSelecionados.map(value => ({
			id_language: value.id_language,
			id_level: value.id_level,
		}))
	});

	if(retInsertFreelancerIdiomas.status === 200) {
		msg.success('Idiomas atualizados com sucesso.');
	} else {
		msgs([retInsertFreelancerIdiomas]);
	}

	setLoadingEditarIdiomas(false);
}

/*
** Segmentos
** ================================================== */
export const apiEditarSegmentos = async ({idFreelancer, loadingEditarSegmentos, setLoadingEditarSegmentos, stateSegmentos}) => {

	if(loadingEditarSegmentos) {
		msg.warning('Por favor, aguarde...');
		return false;
	}

	// Segmentos
	const segmentosSelecionados = arrGetCheckeds(stateSegmentos);

	if(segmentosSelecionados.length < 3) {
		msg.error('Selecione no mínimo 3 segmentos de interesse para poder continuar.');
		return;
	}

	if(segmentosSelecionados.length > 10) {
		msg.error('Você pode selecionar no máximo 10 segmentos de interesse, remove o excedente para poder continuar.');
		return;
	}

	setLoadingEditarSegmentos(true);

	const retInsertFreelancerSegmentos = await apiFreelancerSegments.insertMany.post({
		id_freelancer: idFreelancer,
		dados: segmentosSelecionados.map(value => ({
			id_segment: value.id,
		}))
	});

	if(retInsertFreelancerSegmentos.status === 200) {
		msg.success('Segmentos atualizados com sucesso.');
	} else {
		msgs([retInsertFreelancerSegmentos]);
	}

	setLoadingEditarSegmentos(false);
}

/*
** Competências
** ================================================== */
export const apiEditarCompetencias = async ({idFreelancer, loadingEditarCompetencias, setLoadingEditarCompetencias, stateCompetencias}) => {

	if(loadingEditarCompetencias) {
		msg.warning('Por favor, aguarde...');
		return false;
	}

	// Competências
	const competenciasSelecionados = arrGetCheckeds(stateCompetencias);

	if(competenciasSelecionados.length > 5) {
		msg.error('Você pode selecionar no máximo 5 competências, remove o excedente para poder continuar.');
		return;
	}

	setLoadingEditarCompetencias(true);

	const retInsertFreelancerCompentencias = await apiFreelancerCompetence.insertMany.post({
		id_freelancer: idFreelancer,
		dados: competenciasSelecionados.map(value => ({
			id_competence: value.id,
		}))
	});

	if(retInsertFreelancerCompentencias.status === 200) {
		msg.success('Competências atualizadas com sucesso.');
	} else {
		msgs([retInsertFreelancerCompentencias]);
	}

	setLoadingEditarCompetencias(false);
}

/*
** Competências usuário
** ================================================== */
export const apiEditarCompetenciasUsuario = async ({idUsuario, loadingEditarCompetencias, setLoadingEditarCompetencias, stateCompetencias}) => {

	if(loadingEditarCompetencias) {
		msg.warning('Por favor, aguarde...');
		return false;
	}

	// Competências
	const competenciasSelecionados = arrGetCheckeds(stateCompetencias);

	if(competenciasSelecionados.length > 5) {
		msg.error('Você pode selecionar no máximo 5 competências, remove o excedente para poder continuar.');
		return;
	}

	setLoadingEditarCompetencias(true);

	const retInsertUsuarioCompentencias = await apiUserCompetences.insertMany.post({
		id_user: idUsuario,
		dados: competenciasSelecionados.map(value => ({
			id_competence: value.id,
		}))
	});

	if(retInsertUsuarioCompentencias.status === 200) {
		msg.success('Competências atualizadas com sucesso.');
	} else {
		msgs([retInsertUsuarioCompentencias]);
	}

	setLoadingEditarCompetencias(false);
}

/*
** Experiência
** ================================================== */
export const apiEditarExperiencias = async ({idFreelancer, loadingEditarExperiencias, setLoadingEditarExperiencias, stateExperiencias}) => {

	if(loadingEditarExperiencias) {
		msg.warning('Por favor, aguarde...');
		return false;
	}

	if(stateExperiencias.length <= 0) {
		msg.error('É necessário inserir alguma experiência profissional.');
		return;
	}

	setLoadingEditarExperiencias(true);

	const retInsertExperiencias = await apiFreelancerExperience.insertMany.post({
		id_freelancer: idFreelancer,
		dados: stateExperiencias.map(value => {
			return removerKeysVazias({
				...value,
				active: 1,
			});
		})
	});

	if(retInsertExperiencias.status === 200) {
		msg.success('Experiências atualizadas com sucesso.');
	} else {
		msgs([retInsertExperiencias]);
	}

	setLoadingEditarExperiencias(false);
}

/*
** Formações
** ================================================== */
export const apiEditarFormacoes = async ({idFreelancer, loadingEditarFormacoes, setLoadingEditarFormacoes, stateFormacoes}) => {

	if(loadingEditarFormacoes) {
		msg.warning('Por favor, aguarde...');
		return false;
	}

	setLoadingEditarFormacoes(true);

	const retInsertFormacoes = await apiFreelancerFormation.insertMany.post({
		id_freelancer: idFreelancer,
		dados: stateFormacoes.map(value => {
			return removerKeysVazias({
				...value,
				active: 1,
			});
		})
	});

	if(retInsertFormacoes.status === 200) {
		msg.success('Formações atualizadas com sucesso.');
	} else {
		msgs([retInsertFormacoes]);
	}

	setLoadingEditarFormacoes(false);
}

/*
** Certificados
** ================================================== */
export const apiEditarCertificados = async ({idFreelancer, loadingEditarCertificados, setLoadingEditarCertificados, stateCertificados}) => {

	if(loadingEditarCertificados) {
		msg.warning('Por favor, aguarde...');
		return false;
	}

	setLoadingEditarCertificados(true);

	const retInsertCertificados = await apiFreelancerCertificate.insertMany.post({
		id_freelancer: idFreelancer,
		dados: stateCertificados.map(value => {
			return removerKeysVazias(value);
		})
	});

	if(retInsertCertificados.status === 200) {
		msg.success('Certificados atualizados com sucesso.');
	} else {
		msgs([retInsertCertificados]);
	}

	setLoadingEditarCertificados(false);
}

/*
** Prêmios
** ================================================== */
export const apiEditarPremios = async ({idFreelancer, loadingEditarPremios, setLoadingEditarPremios, statePremios}) => {

	if(loadingEditarPremios) {
		msg.warning('Por favor, aguarde...');
		return false;
	}

	setLoadingEditarPremios(true);

	const retInsertPremios = await apiFreelancerAwards.insertMany.post({
		id_freelancer: idFreelancer,
		dados: statePremios.map(value => {
			return removerKeysVazias(value);
		})
	});

	if(retInsertPremios.status === 200) {
		msg.success('Premios atualizados com sucesso.');
	} else {
		msgs([retInsertPremios]);
	}

	setLoadingEditarPremios(false);
}

/*
** Portfólios
** ================================================== */
export const apiEditarPortfolios = async ({idFreelancer, loadingEditarPortfolios, setLoadingEditarPortfolios, statePortfolios}) => {

	if(loadingEditarPortfolios) {
		msg.warning('Por favor, aguarde...');
		return false;
	}

	setLoadingEditarPortfolios(true);

	const retInsertPortfolios = await apiFreelancerPortfolio.insertMany.post({
		id_freelancer: idFreelancer,
		dados: statePortfolios.map(value => {
			return removerKeysVazias(value);
		})
	});

	if(retInsertPortfolios.status === 200) {
		msg.success('Portfólios atualizados com sucesso.');
	} else {
		msgs([retInsertPortfolios]);
	}

	setLoadingEditarPortfolios(false);
}


/*
** Certificados
** ================================================== */
export const apiEditarAceites = async ({idFreelancer, loadingEditarDados, setLoadingEditarDados, stateDados, cb}) => {

	if(loadingEditarDados) {
		msg.warning('Por favor, aguarde...');
		return false;
	}

	setLoadingEditarDados(true);

	const retEditarAceites = await apiFreelancer.update.put({
		id: idFreelancer,
		...stateDados,
	});

	if(retEditarAceites.status === 200) {
		msg.success('Dados atualizados com sucesso.');
		if(cb) cb(stateDados);
	} else {
		msgs([retEditarAceites]);
	}

	setLoadingEditarDados(false);
}


/*
** Portfólios
** ================================================== */
export const apiValidarAcesso = async ({setStateAcesso, setStateIsAcessoValido}) => {

	setStateIsAcessoValido(true);
	return {
		status: 200,
		mensagem: 'Acesso liberado',
		sucesso: true,
		salvoLocal: true,
	};

	const retValidarAcesso = await api.users.validateAccess.get();
	var retorno = {};

	if(retValidarAcesso.status !== 200) {
		setStateIsAcessoValido(false);
		retorno = {
			...retValidarAcesso,
			mensagem: retValidarAcesso.error,
			sucesso: false,
			salvoLocal: false,
		}
	} else {
		setStateIsAcessoValido(true);
		retorno = {
			...retValidarAcesso,
			mensagem: retValidarAcesso.message,
			sucesso: true,
		}
	}

	if(setStateAcesso) {
		setStateAcesso(retorno);
	}

	return retorno;
}

/*
** APi - Get avaliações
** ================================================== */
export const apiGetAvaliacoesGetAll = async ({idFreelancer, setLoadingGetAvaliacoes, setStateAvaliacoes, showMsg = true}) => {
	setLoadingGetAvaliacoes(true);
	const ret = await api.freelancerRatings.ratingMedia.get({
		id_freelancer: idFreelancer,
	});
	if(ret.status === 200) {
		setStateAvaliacoes(ret.data[0]);
	} else {
		if(showMsg) {
			msgs([ret]);
		}
	}
	setLoadingGetAvaliacoes(false);
	return ret;
}
