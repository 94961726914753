import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiFreelancerCompetence = {
	all: {
		get: async (id) => {
			return await api.get(`/freelancerCompetence/all/${id}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	insertMany: {
		post: async (dados) => {
			return await api.post(`/freelancerCompetence/insertMany`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiFreelancerCompetence;