import React from 'react';

// CSS
import c from './Icone.module.scss';

export const Icone = props => {

	const {
		className = '',
		img,
		cor = `var(--color-1)`,
	} = props;

	return (
		<div
			className={`${className} ${c['icone']}`}
			style={{
				...(cor ? {backgroundColor: cor} : {}),
				WebkitMaskImage: `url(${img})`,
				maskImage: `url(${img})`
			}}
		/>
	);
}
