import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import md5 from 'crypto-js/md5';

// css
import './DetalhesClienteResponsavel.css';

// Imagens
import imgUserPic from '@img/userpic.png';

// Funções
import { gets } from '@route/routeUtils';

const DetalhesClienteResponsavel = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		className = '',
		wrap = '',
		after,

		stateCliente = {},
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	return (
		<div className={`cpnt-detalhesclienteresp ${wrap}`}>
			<div className={`cpnt-detalhesclienteresp-box ${className}`}>

				<div className="flex">
					<div className="w-160">
						<div className="w-160 h-160 relative border-radius-50 overflow border-2">
							<div className="bg fill" style={{backgroundImage: `url(${imgUserPic})`}}></div>
							<div className="bg fill" style={{backgroundImage: `url(https://www.gravatar.com/avatar/${md5(stateCliente.email_responsible)}?d=blank&size=300)`}}></div>
						</div>
					</div>
					<div className="margin-l-30 flex-1">
						<div>
							<strong>Cliente:</strong>
							<span className="margin-l-5">{stateCliente.name} <span className="color-1">(ID: {stateCliente.id})</span></span>
						</div>
						<div className="sep-t margin-t-5 padding-t-5">
							<strong>Responsável:</strong>
							<span className="margin-l-5">{stateCliente.name_responsible}</span>
						</div>
						{stateCliente.type !== 'F' ? (
							<div className="sep-t margin-t-5 padding-t-5">
								<strong>Cargo:</strong>
								<span className="margin-l-5">{stateCliente.position_responsible || 'Cargo não informado'}</span>
							</div>
						) : null}
						<div className="sep-t margin-t-5 padding-t-5">
							<strong>Telefone:</strong>
							<span className="margin-l-5">{stateCliente.phone}</span>
						</div>
						<div className="sep-t margin-t-5 padding-t-5">
							<strong>E-mail:</strong>
							<span className="margin-l-5">{stateCliente.email_responsible ? <a href={`mailto:${stateCliente.email_responsible}`}>{stateCliente.email_responsible}</a> : 'Email não informado'}</span>
						</div>
					</div>
				</div>
				{after}
			</div>

		</div>
	);
};

export { DetalhesClienteResponsavel };