import api from 'axios';
import { config, validarRetorno } from '../axios';

const apiDashNotifications = {
	update: {
		put: async dados => {
			return await api.put(`/dashNotifications/update`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getOne: {
		get: async () => {
			return await api.get(`/dashNotifications/getOne/1`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiDashNotifications;