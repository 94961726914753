import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiUserIndication = {
	indication: {
		post: async dados => {
			return await api.post(`/userIndication/indication`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getHash: {
		get: async idUser => {
			return await api.get(`/userIndication/getHash/${idUser}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getByHash: {
		get: async hash => {
			return await api.get(`/userIndication/getByHash/${hash}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiUserIndication;
