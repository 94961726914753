import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiRedefinition = {
	user: {
		email: {
			verification: {
				put: async dados => {
					return await api.put(`/redefinition/user/email/verification`, dados, config())
					.then(retorno => {
						return validarRetorno(retorno);
					})
					.catch(retorno => {
						return validarRetorno(retorno);
					});
				}
			},
			redefine: {
				put: async dados => {
					return await api.put(`/redefinition/user/email/redefine`, dados, config())
					.then(retorno => {
						return validarRetorno(retorno);
					})
					.catch(retorno => {
						return validarRetorno(retorno);
					});
				}
			},
		},
		password: {
			put: async dados => {
				return await api.put(`/redefinition/user/password`, dados, config())
				.then(retorno => {
					return validarRetorno(retorno);
				})
				.catch(retorno => {
					return validarRetorno(retorno);
				});
			}
		},
	},
	redefinirSenha: {
		put: async (dados, hash) => {
			return await api.put(`/redefinition/redefinirSenha${hash ? `/${hash}` : ``}`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
		get: async hash => {
			return await api.get(`/redefinition/redefinirSenha/${hash}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiRedefinition;
