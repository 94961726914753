import React, { useState } from 'react';

// Componentes
import { apiGetCep } from "@api/_functions/geral";
import Input2 from "@cpnt/Input2/Input2";
import Loading from "@cpnt/Loading/Loading";
import { Select2 } from "@cpnt/Select2/Select2";
import { validarCep } from "@func/utils";

const PtEndereco = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		stateDados = {},
		setStateDados,
		showPais,
		internacional,
		statePaises = [],
		loadingGetPaises,
	} = props;

	const [loadingGetCep, setLoadingGetCep] = useState(false);

	const pesquisarCEP = async e => {
		if(!validarCep(e.target.value)) return;
		apiGetCep({
			cep: e.target.value,
			stateEndereco: stateDados,
			setStateEndereco: setStateDados,
			setLoadingGetEndereco: setLoadingGetCep,
		});
	};

	return (
		<>
			<div className={`cpnt-ptendereco ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-ptendereco-box ${className}`}>
					<div>
						<div className="wrap-5">
							<div className="flex flex-wrap relative">
								{loadingGetCep && <Loading alt absolute />}
								{showPais ? (
									<div className="input-box box-1">
										<div className="w-300 padding-b-20 box-1-580">
											<Select2
												label="País"
												tip="O seu documento e endereço é baseado no país que você selecionar."
												req
												_={{
													options: statePaises,
													value: stateDados.id_country,
													isClearable: false,
													onChange: e => {
														if(e && e.value) {
															setStateDados({
																...stateDados,
																id_country: e.value,
															});
														}
													},
												}}
												loading={loadingGetPaises}
											/>
										</div>
									</div>
								) : null}
								<div className="input-box w-20p box-3-980 box-2-780 box-1-580">
									<Input2
										label={internacional ? 'Código Postal (Zipcode)' : 'CEP'}
										req
										_={{
											value: stateDados.zip_code,
											onChange: e => {
												setStateDados({
													...stateDados,
													zip_code: e.target.value,
												})
											},
											...(internacional ? {} : {
												mask: '99999-999',
												onBlur: pesquisarCEP,
											})
										}}
									/>
								</div>
								<div className="input-box w-40p box-3-980 box-2-780 box-1-580">
									<Input2
										label="Logradouro"
										req
										_={{
											value: stateDados.street,
											onChange: e => {
												setStateDados({
													...stateDados,
													street: e.target.value,
												})
											}
										}}
									/>
								</div>
								<div className="input-box w-10p box-3-980 box-2-780 box-1-580">
									<Input2
										label="Número"
										req
										_={{
											value: stateDados.number,
											onChange: e => {
												setStateDados({
													...stateDados,
													number: e.target.value,
												})
											}
										}}
									/>
								</div>
								<div className="input-box w-30p box-3-980 box-2-780 box-1-580">
									<Input2
										label="Complemento"
										_={{
											value: stateDados.complement,
											onChange: e => {
												setStateDados({
													...stateDados,
													complement: e.target.value,
												})
											}
										}}
									/>
								</div>
								<div className="input-box w-40p box-3-980 box-2-780 box-1-580">
									<Input2
										label="Bairro"
										req
										_={{
											value: stateDados.district,
											onChange: e => {
												setStateDados({
													...stateDados,
													district: e.target.value,
												})
											}
										}}
									/>
								</div>
								<div className="input-box w-40p box-3-980 box-2-780 box-1-580">
									<Input2
										label="Cidade"
										req
										_={{
											value: stateDados.city,
											onChange: e => {
												setStateDados({
													...stateDados,
													city: e.target.value,
												})
											}
										}}
									/>
								</div>
								<div className="input-box w-20p box-3-980 box-2-780 box-1-580">
									<Input2
										label="Estado"
										req
										_={{
											value: stateDados.state,
											onChange: e => {
												setStateDados({
													...stateDados,
													state: e.target.value,
												})
											}
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { PtEndereco };
