import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './PerfilSteps.css';

// API
import { api } from "@_api/api";
import { apiEditarCertificados, apiEditarExperiencias, apiEditarFormacoes, apiEditarPortfolios, apiEditarPremios, apiGetAreas, apiGetCertificados, apiGetCompetenciasChecked, apiGetExperiencias, apiGetFormacoes, apiGetHabilidades, apiGetIdiomasChecked, apiGetPaises, apiGetPortfolios, apiGetPremios, apiGetRedesSociaisFreelancer, apiGetSegmentosChecked, apiGetSenioridades } from "@api/_functions/geral";
import apiFreelancer from '@api/freelancer/freelancer';
import apiFreelancerAwards from '@api/freelancerAwards/freelancerAwards';
import apiFreelancerCertificate from '@api/freelancerCertificate/freelancerCertificate';
import apiFreelancerCompetence from '@api/freelancerCompetence/freelancerCompetence';
import apiFreelancerExperience from '@api/freelancerExperience/freelancerExperience';
import apiFreelancerFormation from '@api/freelancerFormation/freelancerFormation';
import apiFreelancerLanguages from '@api/freelancerLanguages/freelancerLanguages';
import apiFreelancerPortfolio from '@api/freelancerPortfolio/freelancerPortfolio';
import apiFreelancerSegments from '@api/freelancerSegments/freelancerSegments';
import apiFreelancerSkills from '@api/freelancerSkills/freelancerSkills';
import apiFreelancerSocial from '@api/freelancerSocial/freelancerSocial';

// Imagens
import imgIconeSeta from '@svg/padrao/arrow-right.svg';
import imgIconeSalvar from '@svg/regular/floppy-disk.svg';
import imgIconePublicar from '@svg/solid/circle-check.svg';

// Components
import { PtLinkedin } from "@cpnt/Partes/PtLinkedin/PtLinkedin";
import { Passo1 } from "@page/Logado/PerfilSteps/StepByStep/Passo1/Passo1";
import { Passo4 } from "@page/Logado/PerfilSteps/StepByStep/Passo4/Passo4";
import { Passo5 } from "@page/Logado/PerfilSteps/StepByStep/Passo5/Passo5";
import { Passo6 } from "@page/Logado/PerfilSteps/StepByStep/Passo6/Passo6";
import { Passo8 } from "@page/Logado/PerfilSteps/StepByStep/Passo8/Passo8";
import { Passo9 } from "@page/Logado/PerfilSteps/StepByStep/Passo9/Passo9";
import { PassoBemVindo } from "@page/Logado/PerfilSteps/StepByStep/PassoBemVindo/PassoBemVindo";

import Button2 from "@cpnt/Button2/Button2";
import Info from "@cpnt/Info/Info";
import Loading from "@cpnt/Loading/Loading";
import Portal from "@cpnt/Portal/Portal";
import TextoIconeLink from "@cpnt/TextoIconeLink/TextoIconeLink";
import Tip from "@cpnt/Tip/Tip";

// Funções
import { isLogado, salvarSessaoLocal } from "@_func/auth";
import { arrGetCheckeds, blobToFile, camposInvalidos, checkArr, conveterRealParaNumero, dataApiCrop, extensaoPorMimeType, extensaoString, isDataEngMaiorQueHoje, msg, removerKeysVazias, sanitize, trim, validar200, validarCpf, validarDataEng } from '@func/utils';
import { qualStep } from "@page/Logado/CadastrarProcessoSeletivo/funcCadastrarProcessoSeletivo";
import { NavSteps } from "@page/Logado/CadastrarProcessoSeletivo/Steps/NavSteps/NavSteps";

import { hash } from "@_func/generate";

// Config
import { BYPASS_BRIEFING_STEPS, BYPASS_PERFIL_STEPS } from '@_config/config';

// Rota
import { isNumeric } from "@_func/regex";
import { msgs, msgStatus } from "@_func/request";
import { initFuncExec, setGet, setGetNavigate } from "@route/routeUtils";

const PerfilSteps = props => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	const getFreelancerRef = useRef(0);
	const permissaoRef = useRef(false);

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = async (getsAtualizados = getsDefault) => {

		var dadosFreelancer = stateDados;

		if(getFreelancerRef.current === 0) {
			dadosFreelancer = await getFreelancer();

			if(isNumeric(dadosFreelancer.step)) {
				var stepIr = Number(dadosFreelancer.step);

				if(stepIr <= 0) {
					stepIr = 1;
				} else if(stepIr > maxStep) {
					stepIr = 1;
				}

				setGetNavigate({
					gets: {step: stepIr},
					navigate,
					stateGet,
					location,
				});

				msg.info('Você parou nessa tela da última vez que preencheu seu perfil. Fique a vontade para continuar com o preenchimento.');
			}
		}

		if(permissaoRef.current) {
			initFuncExec([
				/*
				** STEP 1 - EDICAO
				** Campos do cadastro
				** ================================================== */
				{
					func: getsStep1,
					condicao: getsAtualizados.step === '1' || !getsAtualizados.step,
				},
				{
					func: getsStep2,
					param: dadosFreelancer,
					condicao: getsAtualizados.step === '2',
				},
				{
					func: getsStep3,
					condicao: getsAtualizados.step === '3',
				},
			], init, location, stateLocation);
		}
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		step: 'bem-vindo',
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	/*
	** States
	** ================================================== */
	const camposDefault = {
		// Passo 1
		id: store.usuario.id_freelancer,
		name: '',
		birth_date: '',
		phone: '',
		cpf_cnpj: '',
		id_country: 33, // 33 => Brasil

		zip_code: '',
		street: '',
		number: '',
		complement: '',
		district: '',
		city: '',
		state: '',

		// Passo 3
		availability: '',
		hours_per_week: '',
		price: '',

		// Passo 4
		title: '',
		id_area: null,
		seniority: '',

		// Passo 5
		description: '',

		// Passo 7
		accept_date_payment: true,
	};

	/*
	** STEPS
	** ================================================== */
	const maxStep = 7;
	const [step, setStep] = useState(0);
	const [loadingNextStep, setLoadingNextStep] = useState(false);
	const [loadingPesquisaArea, setLoadingPesquisaArea] = useState(false);

	/*
	** PASSO 1 / STEP 1
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({status: 'loading'});

	const [loadingGetDados, setLoadingGetDados] = useState(false);
	const [loadingGetRedesSociaisFreelancer, setLoadingGetRedesSociaisFreelancer] = useState(false);
	const [loadingGetPaises, setLoadingGetPaises] = useState(false);

	const [stateRedesSociaisFreelancer, setStateRedesSociaisFreelancer] = useState([]);
	const [statePaises, setStatePaises] = useState([]);

	/*
	** PASSO 2 / STEP 2
	** ================================================== */
	const [loadingGetSenioridades, setLoadingGetSenioridades] = useState(false);
	const [loadingGetAreas, setLoadingGetAreas] = useState(false);
	const [loadingGetHabilidades, setLoadingGetHabilidades] = useState(false);
	const [loadingGetIdiomas, setLoadingGetIdiomas] = useState(false);
	const [loadingGetSegmentos, setLoadingGetSegmentos] = useState(false);
	const [loadingGetCompetencias, setLoadingGetCompetencias] = useState(false);

	const [stateSenioridades, setStateSenioridades] = useState([]);
	const [stateAreas, setStateAreas] = useState([]);
	const [stateHabilidades, setStateHabilidades] = useState([]);
	const [stateIdiomas, setStateIdiomas] = useState([]);
	const [stateIdiomasNiveis, setStateIdiomasNiveis] = useState([]);
	const [stateSegmentos, setStateSegmentos] = useState([]);
	const [stateCompetencias, setStateCompetencias] = useState([]);

	/*
	** PASSO 3 / STEP 3
	** ================================================== */
	const [loadingGetExperiencias, setLoadingGetExperiencias] = useState(false);
	const [loadingGetFormacoes, setLoadingGetFormacoes] = useState(false);
	const [loadingGetCertificados, setLoadingGetCertificados] = useState(false);
	const [loadingGetPremios, setLoadingGetPremios] = useState(false);
	const [loadingGetPortfolios, setLoadingGetPortfolios] = useState(false);

	const [loadingEditarExperiencias, setLoadingEditarExperiencias] = useState(false);
	const [loadingEditarFormacoes, setLoadingEditarFormacoes] = useState(false);
	const [loadingEditarCertificados, setLoadingEditarCertificados] = useState(false);
	const [loadingEditarPremios, setLoadingEditarPremios] = useState(false);
	const [loadingEditarPortfolios, setLoadingEditarPortfolios] = useState(false);

	const [stateExperiencias, setStateExperiencias] = useState([]);
	const [stateFormacoes, setStateFormacoes] = useState([]);
	const [stateCertificados, setStateCertificados] = useState([]);
	const [statePremios, setStatePremios] = useState([]);
	const [statePortfolios, setStatePortfolios] = useState([]);

	/*
	** PASSO 7 / STEP 7
	** ================================================== */
	const [stateFotoCapturada, setStateFotoCapturada] = useState(false);

	/*
	** PASSO 8 / STEP 8
	** ================================================== */
	const [stateVideoUrl, setStateVideoUrl] = useState(false);

	/*
	** Gets Step 1
	** ================================================== */
	const getFreelancer = async () => {
		setLoadingGetDados(true);
		const retGetFreelancer = await apiFreelancer.getOne.get(store.usuario.id_freelancer);

		if(retGetFreelancer.status === 200) {

			if(retGetFreelancer.data.finish) {
				_setStateDados(msgStatus(retGetFreelancer, {finish: true}));
				if(BYPASS_BRIEFING_STEPS) {
					msg.info('Você já finalizou o passo a passo anteriormente.');
				}
			} else {
				_setStateDados(msgStatus(retGetFreelancer));
			}

			setStateDados({
				...retGetFreelancer.data,
				birth_date: dataApiCrop(retGetFreelancer.data.birth_date),
			});

			setStep(retGetFreelancer.data.step);

			getFreelancerRef.current++;
			permissaoRef.current = true;
		} else {
			_setStateDados(msgStatus(retGetFreelancer));
			msgs([retGetFreelancer]);
		}

		setLoadingGetDados(false);

		if(retGetFreelancer.status === 200) {
			return retGetFreelancer.data;
		}
		return {};
	}

	/*
	** Gets Step 1
	** ================================================== */
	const getsStep1 = async () => {
		apiGetRedesSociaisFreelancer({idUsuario: store.usuario.id, setStateRedesSociaisFreelancer, setLoadingGetRedesSociaisFreelancer, showMsg: false});
		apiGetPaises({setStatePaises, setLoadingGetPaises});
	}

	/*
	** Gets Step 2
	** ================================================== */
	const getsStep2 = async (dadosFreelancer = {}) => {
		apiGetSenioridades({setStateSenioridades, setLoadingGetSenioridades});
		apiGetAreas({setStateAreas, setLoadingGetAreas});
		apiGetIdiomasChecked({idFreelancer: dadosFreelancer.id, setLoadingGetIdiomas, setStateIdiomas, setStateIdiomasNiveis});
		apiGetSegmentosChecked({idFreelancer: dadosFreelancer.id, setLoadingGetSegmentos, setStateSegmentos})
		apiGetCompetenciasChecked({idFreelancer: dadosFreelancer.id, setLoadingGetCompetencias, setStateCompetencias});

		var idArea = dadosFreelancer.id_area;

		if(dadosFreelancer.id_area) {

			// Caso o select esteja com uma área e o freelancer já tenha habilidades cadastradas de outras áreas
			const retGetFreelancerHabilidades = await apiFreelancerSkills.all.get(dadosFreelancer.id);
			if(retGetFreelancerHabilidades.status === 200 && retGetFreelancerHabilidades.data.length) {
				idArea = retGetFreelancerHabilidades.data[0].id_area;
			}

			const retGetHabilidades = await apiGetHabilidades({idArea: idArea, setLoadingGetHabilidades, setStateHabilidades});
			const checkedHabilidades = checkArr(retGetHabilidades.data, retGetFreelancerHabilidades.data);

			setStateHabilidades(checkedHabilidades);
		}

		if(dadosFreelancer.id_area !== idArea) {
			setStateDados({
				...stateDados,
				id_area: idArea,
			});
		}
	}

	/*
	** Gets Step 3
	** ================================================== */
	const getsStep3 = async () => {
		var idFreelancer = store.usuario.id_freelancer;
		apiGetExperiencias({idFreelancer: idFreelancer, setStateExperiencias, setLoadingGetExperiencias, showMsg: false});
		apiGetFormacoes({idFreelancer: idFreelancer, setStateFormacoes, setLoadingGetFormacoes, showMsg: false});
		apiGetCertificados({idFreelancer: idFreelancer, setStateCertificados, setLoadingGetCertificados, showMsg: false});
		apiGetPremios({idFreelancer: idFreelancer, setStatePremios, setLoadingGetPremios, showMsg: false});
		apiGetPortfolios({idFreelancer: idFreelancer, setStatePortfolios, setLoadingGetPortfolios, showMsg: false});
	}

	/*
	** Definir steps / passo a passo / passos steps / passo-a-passo / step-step / step-by-step
	** ================================================== */
	const definirStep = (acao, byPass = false) => {
		var novoStep = qualStep({
			acao: acao,
			step: stateGet.step,
			maxStep,
		});
		if(byPass) {
			setGetNavigate({
				gets: {step: novoStep},
				navigate,
				stateGet,
				location,
			});
			setStep(novoStep);
		} else {
			if(acao === 'next') {
				if(novoStep === 2) acaoPasso1to2(true);
				if(novoStep === 3) acaoPasso2to3(true);
				if(novoStep === 4) acaoPasso3to4(true);
				if(novoStep === 5) acaoPasso4to5(true);
				if(novoStep === 6) acaoPasso5to6(true);
				if(novoStep === 7) acaoPasso6to7(true);
			} else if(acao === 'prev') {
				var stepIr = novoStep;
				if(novoStep <= 0) {
					stepIr = 1;
				}
				if(loadingNextStep) {
					msg.warning('Por favor, aguarde...');
					return false;
				}
				setGetNavigate({
					gets: {step: stepIr},
					navigate,
					stateGet,
					location,
				});
				setStep(stepIr);
			}
		}
	}

	/*
	** Passso 1 para o 2
	** ================================================== */
	const acaoPasso1to2 = async e => {

		if(loadingNextStep) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			id: store.usuario.id_freelancer,
			name: stateDados.name,
			birth_date: dataApiCrop(stateDados.birth_date),
			id_country: stateDados.id_country,
			phone: stateDados.phone,
			cpf_cnpj: stateDados.cpf_cnpj,

			zip_code: stateDados.zip_code,
			street: stateDados.street,
			number: stateDados.number,
			complement: stateDados.complement,
			district: stateDados.district,
			city: stateDados.city,
			state: stateDados.state,

  			step: 2,
		};

		if(camposInvalidos(dadosEnviar, {}, [
			'name',
			'id_country',
			'phone',
			'cpf_cnpj',

			'zip_code',
			'street',
			'number',
			'district',
			'city',
			'state',
		])) return;

		if(validarDataEng(stateDados.birth_date) && isDataEngMaiorQueHoje(stateDados.birth_date)) {
			msg.error('A data de aniversário não pode ser maior ou igual a data de hoje');
			return;
		}

		if(dadosEnviar.id_country === 33) {
			if(!validarCpf(dadosEnviar.cpf_cnpj)) return;
		}

		var dadosEnviarSemCamposVazios = removerKeysVazias(dadosEnviar);
		dadosEnviarSemCamposVazios.finish = 0;

		setLoadingNextStep(true);

		const retUpdateFreelancer = await apiFreelancer.update.put(dadosEnviarSemCamposVazios);

		const retInsertRedesSociais = await apiFreelancerSocial.insertMany.post({
			id_user: store.usuario.id,
			dados: stateRedesSociaisFreelancer.map((val => {
				return {
					...val,
					type: 0,
				}
			}))
		});

		if(retUpdateFreelancer.status === 200) {
			salvarSessaoLocal({
				...store,
				usuario: {
					...store.usuario,
					name: stateDados.name,
				},
			});

			setStore({
				...store,
				usuario: {
					...store.usuario,
					name: stateDados.name,
				}
			});
		}

		if(validar200([retUpdateFreelancer, retInsertRedesSociais])) {
			msg.success('Informações atualizadas com sucesso', {
				autoClose: 3000
			});
			setGetNavigate({
				gets: {step: 2},
				navigate,
				stateGet,
				location,
			});
			setStep(2);
		} else {
			msgs([retUpdateFreelancer, retInsertRedesSociais]);
		}

		setLoadingNextStep(false);
	};

	/*
	** Passso 2 para o 3
	** ================================================== */
	const acaoPasso2to3 = async e => {

		if(loadingNextStep) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		if(camposInvalidos(stateDados, {
			seniority: 'Nível de experiência',
			price: 'Remuneração',
		}, [
			'title',
			'id_area',
			'seniority',
			'price',
		])) return;

		const habilidadesSelecionadas = arrGetCheckeds(stateHabilidades);

		// Habilidades
		if(habilidadesSelecionadas.length < 3) {
			msg.error('Selecione no mínimo 3 habilidades para poder continuar.');
			return;
		}

		// Habilidades
		if(habilidadesSelecionadas.length > 10) {
			msg.error('Você pode selecionar no máximo 10 habilidades, remove o excedente para poder continuar.');
			return;
		}

		// Idiomas
		const idiomasSelecionados = arrGetCheckeds(stateIdiomas);

		if(idiomasSelecionados.length < 1) {
			msg.error('Selecione no mínimo 1 idioma para poder continuar');
			return;
		}

		const idiomasSelecionadosSemNivel = [];
		idiomasSelecionados.map(val => {
			if(val.checked === true && !val.id_level) {
				idiomasSelecionadosSemNivel.push(val.name_language);
			}
			return null;
		});

		if(idiomasSelecionadosSemNivel.length > 0) {
			msg.error(
				<>
					{idiomasSelecionadosSemNivel.length > 1 ? (
						<div>Informe os níveis dos idiomas:</div>
					) : (
						<div>Informe o nível do idioma:</div>
					)}
					<div className="margin-t-10">{idiomasSelecionadosSemNivel.join(', ')}</div>
				</>
			);
			return;
		}

		// Segmentos
		const segmentosSelecionados = arrGetCheckeds(stateSegmentos);

		if(segmentosSelecionados.length < 3) {
			msg.error('Selecione no mínimo 3 segmentos de interesse para poder continuar.');
			return;
		}

		if(segmentosSelecionados.length > 10) {
			msg.error('Você pode selecionar no máximo 10 segmentos de interesse, remove o excedente para poder continuar.');
			return;
		}

		// Competências
		const competenciasSelecionados = arrGetCheckeds(stateCompetencias);

		if(competenciasSelecionados.length > 5) {
			msg.error('Você pode selecionar no máximo 5 competências, remove o excedente para poder continuar.');
			return;
		}

		setLoadingNextStep(true);

		const retInsertFreelancerHabilidades = await apiFreelancerSkills.insertMany.post({
			id_freelancer: store.usuario.id_freelancer,
			dados: habilidadesSelecionadas.map(value => ({
				id_skill: value.id,
			}))
		});

		const retInsertFreelancerCompentencias = await apiFreelancerCompetence.insertMany.post({
			id_freelancer: store.usuario.id_freelancer,
			dados: competenciasSelecionados.map(value => ({
				id_competence: value.id,
			}))
		});

		const retInsertFreelancerSegmentos = await apiFreelancerSegments.insertMany.post({
			id_freelancer: store.usuario.id_freelancer,
			dados: segmentosSelecionados.map(value => ({
				id_segment: value.id,
			}))
		});

		const retInsertFreelancerIdiomas = await apiFreelancerLanguages.insertMany.post({
			id_freelancer: store.usuario.id_freelancer,
			dados: idiomasSelecionados.map(value => ({
				id_language: value.id_language,
				id_level: value.id_level,
			}))
		});

		if(validar200([retInsertFreelancerHabilidades, retInsertFreelancerCompentencias, retInsertFreelancerIdiomas, retInsertFreelancerSegmentos])) {
			const retUpdateFreelancer = await apiFreelancer.update.put({
				id: store.usuario.id_freelancer,
				title: stateDados.title,
				id_area: stateDados.id_area,
				seniority: stateDados.seniority,
				price: conveterRealParaNumero(stateDados.price),
				step: 3,
				finish: 0,
			});

			if(validar200([retUpdateFreelancer])) {
				msg.success('Informações atualizadas com sucesso.');

				setGetNavigate({
					gets: {step: 3},
					navigate,
					stateGet,
					location,
				});
				setStep(3);
			} else {
				msgs([retUpdateFreelancer]);
			}
		} else {
			msgs([retInsertFreelancerHabilidades, retInsertFreelancerCompentencias, retInsertFreelancerIdiomas, retInsertFreelancerSegmentos]);
		}

		setLoadingNextStep(false);
	}

	/*
	** Passso 3 para o 4
	** ================================================== */
	const acaoPasso3to4 = async e => {

		if(loadingNextStep) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		if(stateExperiencias.length <= 0) {
			msg.error('É necessário inserir alguma experiência profissional.');
			return;
		}

		setLoadingNextStep(true);

		const retInsertExperiencias = await apiFreelancerExperience.insertMany.post({
			id_freelancer: store.usuario.id_freelancer,
			dados: stateExperiencias.map(value => {
				return removerKeysVazias({
					...value,
					active: 1,
				});
			})
		});

		const retInsertFormacoes = await apiFreelancerFormation.insertMany.post({
			id_freelancer: store.usuario.id_freelancer,
			dados: stateFormacoes.map(value => {
				return removerKeysVazias({
					...value,
					active: 1,
				});
			})
		});

		const retInsertCertificados = await apiFreelancerCertificate.insertMany.post({
			id_freelancer: store.usuario.id_freelancer,
			dados: stateCertificados.map(value => {
				return removerKeysVazias(value);
			})
		});

		const retInsertPremios = await apiFreelancerAwards.insertMany.post({
			id_freelancer: store.usuario.id_freelancer,
			dados: statePremios.map(value => {
				return removerKeysVazias(value);
			})
		});

		const retInsertPortfolios = await apiFreelancerPortfolio.insertMany.post({
			id_freelancer: store.usuario.id_freelancer,
			dados: statePortfolios.map(value => {
				return removerKeysVazias(value);
			})
		});

		if(validar200([retInsertExperiencias, retInsertFormacoes, retInsertCertificados, retInsertPremios, retInsertPortfolios])) {
			const retUpdateFreelancer = await apiFreelancer.update.put({
				id: store.usuario.id_freelancer,
				step: 4,
				finish: 0,
			});

			if(retUpdateFreelancer.status === 200) {
				msg.success('Informações atualizadas com sucesso.');
				setGetNavigate({
					gets: {step: 4},
					navigate,
					stateGet,
					location,
				});
				setStep(4);
			} else {
				msgs([retUpdateFreelancer]);
			}

		} else {
			msgs([retInsertExperiencias, retInsertFormacoes, retInsertCertificados, retInsertPremios, retInsertPortfolios]);
		}

		setLoadingNextStep(false);
	};

	/*
	** Passo 4 para o 5
	** ================================================== */
	const acaoPasso4to5 = async () => {
		if(loadingNextStep) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			id: store.usuario.id_freelancer,
			step: 5,
			finish: 0,
			description: sanitize(stateDados.description),
		};

		var desc = trim(dadosEnviar.description, ' ');

		if(camposInvalidos({description: desc}, {
			description: 'Carta de apresentação',
		}, [
			'description',
		])) return;

		if(desc.length <= 50) {
			msg.error('Sua apresentação é muito importante, por favor, insira um apresentação um pouco mais detalhada.');
			return;
		}

		setLoadingNextStep(true);

		const retUpdateFreelancer = await apiFreelancer.update.put(dadosEnviar);

		if(validar200([retUpdateFreelancer])) {
			msg.success('Carta de apresentação atualizada com sucesso');

			setGetNavigate({
				gets: {step: 5},
				navigate,
				stateGet,
				location,
			});
			setStep(5);
		} else {
			msgs([retUpdateFreelancer]);
		}

		setLoadingNextStep(false);
	}

	/*
	** Passso 5 para o 6
	** ================================================== */
	const acaoPasso5to6 = async e => {

		if(loadingNextStep) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		// if((!stateDados.photo || stateDados.photo === '') && (!stateFotoCapturada || stateFotoCapturada === '')) {
		// 	msg.error('É necessário enviar uma foto de perfil.');
		// 	return;
		// }

		if(stateFotoCapturada && stateFotoCapturada !== '') {
			const extensao = extensaoString(stateFotoCapturada);
			if(extensao) {
				var foto = await fetch(stateFotoCapturada).then(r => r.blob());

				if(!foto.name) {
					foto = blobToFile(foto, `foto.${extensaoPorMimeType(foto)}`);
				}

				const dadosFormData = new FormData();

				dadosFormData.append('id_user', store.usuario.id);
				dadosFormData.append('photo', foto);

				setLoadingNextStep(true);

				const retUploadFoto = await api.users.uploadPhoto.post(dadosFormData);

				if(validar200([retUploadFoto])) {
					const dadosLogado = isLogado();
					salvarSessaoLocal({
						...dadosLogado,
						usuario: {
							...dadosLogado.usuario,
							photo: retUploadFoto.data.url,
						}
					});
					setStore({
						...store,
						usuario: {
							...dadosLogado.usuario,
							photo: `${retUploadFoto.data.url}?id=${hash()}`
						}
					});
					setStateDados({
						...stateDados,
						photo: `${retUploadFoto.data.url}?id=${hash()}`,
					});
					msg.success('Foto atualizada com sucesso');
				} else {
					msgs([retUploadFoto]);
					setLoadingNextStep(false);
					return;
				}
			}
		}

		setLoadingNextStep(true);

		const retUpdateFreelancer = await apiFreelancer.update.put({
			id: store.usuario.id_freelancer,
			step: 6,
			finish: 0,
		});

		if(validar200([retUpdateFreelancer])) {
			setGetNavigate({
				gets: {step: 6},
				navigate,
				stateGet,
				location,
			});
			setStep(6);
		} else {
			msgs([retUpdateFreelancer]);
		}

		setLoadingNextStep(false);
	};

	/*
	** Passo 6 para o 7
	** ================================================== */
	const acaoPasso6to7 = async () => {

		if(loadingNextStep) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		if(stateVideoUrl && stateVideoUrl !== '') {
			var video = false;

			try {
				video = await fetch(stateVideoUrl).then(r => r.blob());
			} catch (error) {
				msg.error(
					<>
						<div>Não foi possível identificar o conteúdo do seu vídeo. Selecione outro vídeo ou com formato diferente.</div>
						<div className="margin-t-10">Caso o erro persista, remova o vídeo e finalize seu cadastro. Depois tente enviar novamente através do seu perfil.</div>
					</>, {
					autoClose: 15000
				});
				return;
			}

			if(!video.name) {
				video = blobToFile(video, `video.${extensaoPorMimeType(video)}`);
			}

			setLoadingNextStep(true);

			const dadosFormData = new FormData();

			dadosFormData.append('id_user', store.usuario.id);
			dadosFormData.append('video', video);

			const retUploadVideo = await apiFreelancer.uploadVideo.post(dadosFormData);

			if(retUploadVideo.status === 200) {
				setStore({
					...store,
					usuario: {
						...store.usuario,
						video: retUploadVideo.data.url,
					}
				});
				setStateDados({
					...stateDados,
					video: retUploadVideo.data.url,
				});
			} else {
				msgs([retUploadVideo]);
				setLoadingNextStep(false);
				return;
			}
		}

		setLoadingNextStep(true);

		const retUpdateFreelancer = await apiFreelancer.update.put({
			id: store.usuario.id_freelancer,
			step: 1,
			finish: 1,
		});

		if(retUpdateFreelancer.status === 200) {
			salvarSessaoLocal({
				...store,
				usuario: {
					...store.usuario,
					step: 1,
					finish: 1,
				},
			});
			setStore({
				...store,
				usuario: {
					...store.usuario,
					step: 1,
					finish: 1,
				}
			});
			setGetNavigate({
				gets: {step: 7},
				navigate,
				stateGet,
				location,
			});
			setStep(7);
		} else {
			msgs([retUpdateFreelancer]);
			msg.error('Não foi possível concluir o cadastro. Tente novamente.');
		}
		setLoadingNextStep(false);
	}

	const porcentagemConcluido = () => {
		// Alguns passos tem muita informação, não faz muito sentido calcular por tela
		// var atual = step;
		// var total = maxStep;
		// var porcentagem = (atual / total) * 100;
		// return porcentagem;

		var porcentagens = {
			0: 10,
			1: 15,
			2: 22,
			3: 64,
			4: 85,
			5: 90,
			6: 95,
			7: 100,
		};

		var atual = Number(step);

		if(porcentagens[atual]) {
			return `${porcentagens[atual]}%`;
		}

		return '(10%)';
	}

	const exibirNavegacao = () => {
		if(Number(stateGet.step) > maxStep) {
			return false;
		}

		if(BYPASS_PERFIL_STEPS) {
			if(stateGet.step !== 'bem-vindo' && stateGet.step !== '7' && !loadingGetDados) {
				return true;
			}
		}

		// if(Number(stateGet.step) > Number(stateDados.step)) {
		// 	return false;
		// }

		if(!stateGet.step || stateGet.step === '' || stateGet.step === 'bem-vindo' || stateGet.step === '7') {
			return false;
		}


		if(loadingGetDados) {
			return false;
		}

		if(_stateDados.finish === true && (!BYPASS_BRIEFING_STEPS)) {
			return false;
		}

		return true;
	}

	const renderTelaCorreta = () => {

		if(_stateDados.status === 'loading') {
			return (
				<div className="step-wrap">
					<div className="base-m">
						<div className="base-content">
							<Loading
								texto="Carregando dados, aguarde..."
							/>
						</div>
					</div>
				</div>
			)
		}

		if(_stateDados.status !== 200) {
			return (
				<div className="step-wrap">
					<div className="base-m">
						<div className="base-content">
							<Info
								texto={
									<div>
										<h3 className="margin-0">Não foi possível carregar os dados</h3>
										<div>{_stateDados.msg}</div>
									</div>
								}
								icone="erro"
							/>
						</div>
					</div>
				</div>
			);
		}

		if(_stateDados.finish === true && (!BYPASS_BRIEFING_STEPS)) {
			return (
				<div className="step-wrap">
					<div className="base-m">
						<div className="base-content">
							<Info
								texto={
									<div>
										<h3 className="margin-0">Você já finalizou seu cadastro</h3>
										<div>Você já completou o passo a passo anteriormente. Para atualizar o seu perfil, utilize a <Link to="/perfil" className="a">tela de perfil</Link>.</div>
									</div>
								}
								icone="info"
							/>
						</div>
					</div>
				</div>
			);
		}

		if(!stateGet.step || stateGet.step === 'bem-vindo') {
			return (
				<PassoBemVindo
					classNameWrap="step-wrap"
					stateDados={stateDados}
					after={
						<div className="margin-t-30">
							<Button2
								_={{
									value: <TextoIconeLink texto="Vamos lá" icone={imgIconeSeta} reverso={true} />,
									onClick: () => {
										setGetNavigate({
											gets: {step: 1},
											navigate,
											stateGet,
											location,
										});
									},
									type: 'button',
									className: 'btn btn-default',
									style: {paddingLeft: '40px', paddingRight: '40px'}
								}}
							/>
						</div>
					}
				/>
			)
		}

		if(stateGet.step === '1') {
			return (
				<div className="step-wrap">
					<div className="base-m">
						<div className="base-content">
							<Passo1
								stateDados={stateDados}
								setStateDados={setStateDados}

								stateRedesSociais={stateRedesSociaisFreelancer}
								setStateRedesSociais={setStateRedesSociaisFreelancer}
								loadingGetRedesSociais={loadingGetRedesSociaisFreelancer}

								statePaises={statePaises}
								loadingGetPaises={loadingGetPaises}
							/>
						</div>
					</div>
				</div>
			)
		}

		if(stateGet.step === '2') {
			return (
				<div className="step-wrap">
					<div className="base-m">
						<div className="base-content">
							<Passo4
								before={
									<PtLinkedin
										classNameWrap="padding-b-20"
										stateDados={stateDados}
										setStateDados={setStateDados}
										setStateHabilidades={setStateHabilidades}
										stateExperiencias={stateExperiencias}
										setStateExperiencias={setStateExperiencias}
										stateFormacoes={stateFormacoes}
										setStateFormacoes={setStateFormacoes}
										stateCertificados={stateCertificados}
										setStateCertificados={setStateCertificados}
										statePremios={statePremios}
										setStatePremios={setStatePremios}
										stateIdiomas={stateIdiomas}
										setStateIdiomas={setStateIdiomas}
										stateIdiomasNiveis={stateIdiomasNiveis}
										setLoadingPesquisaArea={setLoadingPesquisaArea}
									/>
								}

								stateDados={stateDados}
								setStateDados={setStateDados}

								stateSenioridades={stateSenioridades}
								loadingGetSenioridades={loadingGetSenioridades}

								stateHabilidades={stateHabilidades}
								setStateHabilidades={setStateHabilidades}
								loadingGetHabilidades={loadingGetHabilidades}

								stateIdiomas={stateIdiomas}
								setStateIdiomas={setStateIdiomas}
								loadingGetIdiomas={loadingGetIdiomas}
								stateIdiomasNiveis={stateIdiomasNiveis}

								stateSegmentos={stateSegmentos}
								setStateSegmentos={setStateSegmentos}
								loadingGetSegmentos={loadingGetSegmentos}

								stateCompetencias={stateCompetencias}
								setStateCompetencias={setStateCompetencias}
								loadingGetCompetencias={loadingGetCompetencias}

								stateAreas={stateAreas}
								loadingGetAreas={loadingGetAreas}

								getHabilidades={e => apiGetHabilidades({
									idArea: e,
									setLoadingGetHabilidades: setLoadingGetHabilidades,
									setStateHabilidades: setStateHabilidades,
								})}
							/>
						</div>
					</div>
				</div>
			)
		}

		if(stateGet.step === '3') {
			return (
				<div className="step-wrap">
					<div className="base-m">
						<div className="base-content">
							<Passo6
								before={
									<PtLinkedin
										classNameWrap="padding-b-20"
										stateDados={stateDados}
										setStateDados={setStateDados}
										setStateHabilidades={setStateHabilidades}
										stateExperiencias={stateExperiencias}
										setStateExperiencias={setStateExperiencias}
										stateFormacoes={stateFormacoes}
										setStateFormacoes={setStateFormacoes}
										stateCertificados={stateCertificados}
										setStateCertificados={setStateCertificados}
										statePremios={statePremios}
										setStatePremios={setStatePremios}
										stateIdiomas={stateIdiomas}
										setStateIdiomas={setStateIdiomas}
										stateIdiomasNiveis={stateIdiomasNiveis}
										setLoadingPesquisaArea={setLoadingPesquisaArea}
									/>
								}

								stateExperiencias={stateExperiencias}
								setStateExperiencias={setStateExperiencias}
								loadingGetExperiencias={loadingGetExperiencias}
								loadingBtnSalvarExperiencias={loadingEditarExperiencias}
								onClickBtnSalvarExperiencias={e => apiEditarExperiencias({idFreelancer: store.usuario.id_freelancer, loadingEditarExperiencias, setLoadingEditarExperiencias, stateExperiencias})}

								stateFormacoes={stateFormacoes}
								setStateFormacoes={setStateFormacoes}
								loadingGetFormacoes={loadingGetFormacoes}
								loadingBtnSalvarFormacoes={loadingEditarFormacoes}
								onClickBtnSalvarFormacoes={e => apiEditarFormacoes({idFreelancer: store.usuario.id_freelancer, loadingEditarFormacoes, setLoadingEditarFormacoes, stateFormacoes})}

								stateCertificados={stateCertificados}
								setStateCertificados={setStateCertificados}
								loadingGetCertificados={loadingGetCertificados}
								loadingBtnSalvarCertificados={loadingEditarCertificados}
								onClickBtnSalvarCertificados={e => apiEditarCertificados({idFreelancer: store.usuario.id_freelancer, loadingEditarCertificados, setLoadingEditarCertificados, stateCertificados})}

								statePremios={statePremios}
								setStatePremios={setStatePremios}
								loadingGetPremios={loadingGetPremios}
								loadingBtnSalvarPremios={loadingEditarPremios}
								onClickBtnSalvarPremios={e => apiEditarPremios({idFreelancer: store.usuario.id_freelancer, loadingEditarPremios, setLoadingEditarPremios, statePremios})}

								statePortfolios={statePortfolios}
								setStatePortfolios={setStatePortfolios}
								loadingGetPortfolios={loadingGetPortfolios}
								loadingBtnSalvarPortfolios={loadingEditarPortfolios}
								onClickBtnSalvarPortfolios={e => apiEditarPortfolios({idFreelancer: store.usuario.id_freelancer, loadingEditarPortfolios, setLoadingEditarPortfolios, statePortfolios})}
							/>
						</div>
					</div>
				</div>
			)
		}

		if(stateGet.step === '4') {
			return (
				<div className="step-wrap">
					<div className="base-m">
						<div className="base-content">
							<Passo5
								before={
									<PtLinkedin
										classNameWrap="padding-b-20"
										stateDados={stateDados}
										setStateDados={setStateDados}
										setStateHabilidades={setStateHabilidades}
										stateExperiencias={stateExperiencias}
										setStateExperiencias={setStateExperiencias}
										stateFormacoes={stateFormacoes}
										setStateFormacoes={setStateFormacoes}
										stateCertificados={stateCertificados}
										setStateCertificados={setStateCertificados}
										statePremios={statePremios}
										setStatePremios={setStatePremios}
										stateIdiomas={stateIdiomas}
										setStateIdiomas={setStateIdiomas}
										stateIdiomasNiveis={stateIdiomasNiveis}
										setLoadingPesquisaArea={setLoadingPesquisaArea}
									/>
								}
								stateDados={stateDados}
								setStateDados={setStateDados}
							/>
						</div>
					</div>
				</div>
			)
		}

		if(stateGet.step === '5') {
			return (
				<div className="step-wrap">
					<div className="base-m">
						<div className="base-content">
							<Passo8
								stateDados={stateDados}
								setStateDados={setStateDados}
								stateFotoCapturada={stateFotoCapturada}
								setStateFotoCapturada={setStateFotoCapturada}
							/>
						</div>
					</div>
				</div>
			)
		}

		if(stateGet.step === '6') {
			return (
				<div className="step-wrap">
					<div className="base-m">
						<div className="base-content">
							<Passo9
								stateDados={stateDados}
								setStateDados={setStateDados}
								stateVideoUrl={stateVideoUrl}
								setStateVideoUrl={setStateVideoUrl}
							/>
						</div>
					</div>
				</div>
			)
		}
	}

	return (
		<>
			<div className="pg-cadprocsel base-full">
				<div className="conteudo-box">
					<div className="titulo-box">
						<div className="cadprocsel-topbar-info flex flex-center-v">
							{BYPASS_PERFIL_STEPS ? (
								<Tip
									tip={
										<h3 className="titulo">Finalize o seu cadastro</h3>
									}
									closeOnLeave={false}
									delay={200}
								>
									<NavSteps
										className=""
										value={stateGet.step}
										onChange={e => () => null}
										onClickPrev={e => definirStep('prev', true)}
										onClickNext={e => definirStep('next', true)}
									/>
								</Tip>
							) : (
								<h3 className="titulo">Finalize o seu cadastro</h3>
							)}

							{!loadingGetDados && stateGet.step !== 'bem-vindo' && stateGet.step !== '' && ((!_stateDados.finish || _stateDados.finish === '') || BYPASS_BRIEFING_STEPS) ? (
								<div className="cadprocsel-info-box flex flex-center-v">
									<div className="cadprocsel-info-steps">
										<Tip
											titulo="Finalize seu cadastro"
											tip={
												<div className="color-1 fs-14"><strong>{porcentagemConcluido()}</strong> concluído</div>
												// <div className="color-1 fs-14">Passo <strong>{stateGet.step}</strong>/<strong>{maxStep}</strong></div>
											}
											info
										>
											<div>Seu perfil está <strong>{porcentagemConcluido()}</strong> concluído.</div>
											<div className="margin-t-10">Precisamos só de mais algumas informações como suas experiências, disponibilidade, habilidades, objetivos e remuneração. Assim nossa curadoria poderá encontrá-lo facilmente buscando por perfis como o seu, o que aumenta muito suas chances de conseguir jobs.</div>
										</Tip>
									</div>
								</div>
							) : null}
						</div>
					</div>

					<div className="cadprocsel-box-wrapper">
						{renderTelaCorreta()}
					</div>
				</div>

				{exibirNavegacao() ? (
					<div className="bottombar padding-l-0 padding-r-0">
						<div className="base-m">
							<div className="base-content">
								<div className="pg-carprocsel-bottombar">
									<div className="pg-carprocsel-bottombar-btn-l-box">
										{!stateGet.step || stateGet.step <= 1 ? (
											<Button2
												_={{
													value: 'Voltar',
													onClick: e => definirStep('prev'),
													disabled: stateGet.step <= 1 ? true : false,
												}}
												loading={loadingNextStep}
											/>
										) : (
											<Tip
												tip={
													<Button2
														_={{
															value: 'Voltar',
															onClick: e => definirStep('prev'),
															disabled: stateGet.step <= 1 ? true : false,
														}}
														loading={loadingNextStep}
													/>
												}
												info
												_defaults={{
													placement: 'top-center',
												}}
											>
												Ao voltar para o passo anterior, os dados do passo atual não serão salvos. Caso você tenha feito alguma alteração importante no passo atual e queira manter, clique em "<strong>Salvar e continuar</strong>" antes de voltar.
											</Tip>
										)}
									</div>
									<div className="pg-carprocsel-bottombar-btn-r-box">
										{stateGet.step === '6' ? (
											<Button2
												_={{
													value: <TextoIconeLink texto="Finalizar cadastro" icone={imgIconePublicar} iconeCor="#1cb53f" />,
													onClick: e => definirStep('next')
												}}
												loading={loadingNextStep}
											/>
										) : (
											<Button2
												_={{
													value: <TextoIconeLink texto="Salvar e continuar" icone={imgIconeSalvar} />,
													onClick: e => definirStep('next')
												}}
												loading={loadingNextStep}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</div>

			<Portal
				titulo="Cadastro finalizado"
				show={stateGet.step === '7' && step === 7}
				onClose={() => {
					navigate('/perfil');
				}}
				className="w-700"
				x={false}
			>
				<Info
					icone="sucesso"
					texto={<h3>Cadastro finalizado com sucesso!</h3>}
					after={
						<div className="align-center fs-15">
							{/* <div>Parabéns! Você finalizou todo os passos necessários do cadastro, agora seu perfil está apto a receber propostas de jobs!</div> */}
							<div className="margin-t-20">Veja como é ser <a href="https://blog.crowd.br.com/como-e-ser-freelancer-na-plataforma-crowd/" className="a" target="_blank" rel="noreferrer">freelancer na plataforma Crowd</a> e como conseguir jobs de <a href="https://blog.crowd.br.com/como-conseguir-mais-jobs-de-conteudo-na-crowd/" className="a" target="_blank" rel="noreferrer">Conteúdo</a>, <a href="https://blog.crowd.br.com/como-e-ser-gestor-de-trafego-freelancer-na-plataforma-crowd/" className="a" target="_blank" rel="noreferrer">Mídia</a>, <a href="https://blog.crowd.br.com/como-conseguir-mais-jobs-de-design-na-crowd/" className="a" target="_blank" rel="noreferrer">Design</a> e <a href="https://blog.crowd.br.com/como-conseguir-mais-jobs-de-tecnologia-na-crowd/" className="a" target="_blank" rel="noreferrer">Tecnologia</a></div>
						</div>
					}
				/>
				<div className="form-bottom">
					{BYPASS_PERFIL_STEPS ? (
						<Button2
							_={{
								value: '(DEV) Voltar',
								onClick: e => definirStep('prev')
							}}
							wrap="margin-r-10"
						/>
					) : null}
					<Button2
						_={{
							value: 'Acessar perfil',
							onClick: e => navigate('/perfil')
						}}
					/>
				</div>
			</Portal>
		</>
	);
}

export default PerfilSteps;
