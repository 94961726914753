import React, { useEffect, useState } from 'react';

// css
import './PortalProcessoSeletivoInfoHistorico.css';

// Componentes
import Portal from '@cpnt/Portal/Portal';
import Loading from '@cpnt/Loading/Loading';
import Info from '@cpnt/Info/Info';

// Funções

import { msgs, msgStatus } from "@_func/request";
import apiBriefingsHistory from '@api/briefingsHistory/briefingsHistory';
import ProcessoSeletivoInfoHistorico from '@page/Logado/ComplementarProcessoSeletivo/ProcessoSeletivoInfoHistorico/ProcessoSeletivoInfoHistorico';
import Button2 from '@cpnt/Button2/Button2';
import { diffViewerVerificarHistorico } from "@cpnt/DiffViewer/diffViewerFunc";
import { apiGetBriefingHistoricoAtualizacoesGetAll } from "@api/_functions/geral";

const PortalProcessoSeletivoInfoHistorico = props => {
	const {
		id,
		idBriefing,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-infohistorico',
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetHistorico, setLoadingGetHistorico] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({});
	const [_stateDados, _setStateDados] = useState({});
	const [stateHistorico, setStateHistorico] = useState([]);

	/*
	** Diff
	** ================================================== */
	const [stateEdicaoAnterior, setStateEdicaoAnterior] = useState({});
	const [stateEdicaoAtual, setStateEdicaoAtual] = useState({});
	const [stateEdicaoTemDiff, setStateEdicaoTemDiff] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show) {
			setLoading(true);
			setStateDados({});
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** FREELANCER
	** ================================================== */
	const inicializarGets = async () => {
		if(show) {
			const retGetItem = await apiBriefingsHistory.getOne.get(id);
			_setStateDados(msgStatus(retGetItem));

			if(retGetItem.status === 200) {
				setStateDados(retGetItem.data);
				getHistorico();
			} else {
				msgs([retGetItem]);
			}

			setLoading(false);
		}
	}

	/*
	** API - Get histórico
	** ================================================== */
	const getHistorico = async () => {
		const retGet = await apiBriefingsHistory.getAll.get(id);
		if(retGet.status === 200) {
			setStateHistorico(retGet.data);

			diffViewerVerificarHistorico({
				id,
				historico: retGet.data,
				setStateEdicaoAnterior,
				setStateEdicaoAtual,
				setStateEdicaoTemDiff,
			});
		}
	}

	return (
		<Portal
			titulo="Histórico de edição"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<ProcessoSeletivoInfoHistorico
								stateDados={stateDados}
								stateEdicaoAnterior={stateEdicaoAnterior}
								stateEdicaoAtual={stateEdicaoAtual || {}}
								stateEdicaoTemDiff={stateEdicaoTemDiff}
								after={
									<div className="form-bottom">
										<Button2
											_={{
												value: 'Fechar',
												onClick: onClose,
											}}
										/>
									</div>
								}
							/>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export default PortalProcessoSeletivoInfoHistorico;