import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import React, { useRef } from 'react';

// CSS
import c from './Editor.module.scss';

// Config
import { TINY_MCE_API_KEY } from "@_config/config";

export const Editor = props => {
	const editorRef = useRef(null);

	const {
		label,
		value,
		setValue,
		className,
		height = 450,
	} = props;

	return (
		<div className={`${c['container']} ${className}`}>
			{label && <label>{label}</label>}
			<TinyEditor
				apiKey={TINY_MCE_API_KEY}
				onInit={(evt, editor) => editorRef.current = editor}
				value={value}
				onEditorChange={(e) => setValue(e)}
				init={{
					height: height,
					menubar: true,
					plugins: [
						'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview',
						'searchreplace', 'visualblocks', 'code', 'fullscreen',
						'help', 'wordcount', 'paste', 'emoticons'
					],
					toolbar: 'undo redo | removeformat| blocks | ' +
					'link bold italic forecolor underline | alignleft aligncenter ' +
					'alignright alignjustify | emoticons | bullist numlist outdent indent | ' +
					' | code help',
					content_style: 'body { font-family: Muli, Helvetica, Arial, sans-serif; font-size: 15px }',
					language: 'pt_BR',
					removed_menuitems: 'fontfamily',
					content_css: '/assets/css/fonts.css',
				}}
			/>
		</div>
	)

};
