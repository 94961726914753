import React, { useState } from 'react';
import { toast as msg } from 'react-toastify';

// API
import { apiCep } from '@_api/requests/cep/cep';
import apiCnpj from '@api/cnpj/cnpj';

// Componentes
import BtnAdicionar from '@cpnt/BtnAdicionar/BtnAdicionar';
import Input2 from '@cpnt/Input2/Input2';
import InputCalendario from '@cpnt/InputCalendario/InputCalendario';
import InputTelefone from '@cpnt/InputTelefone/InputTelefone';
import Loading from '@cpnt/Loading/Loading';
import Radio2 from '@cpnt/Radio2/Radio2';
import Tip from '@cpnt/Tip/Tip';
import { DevBox } from '@cpnt/DevBox/DevBox';

// Funções
import { DEV } from '@_config/config';
import { apenasNumeros, validarCep, validarCnpj, validarCpf } from '@func/utils';

import { hash } from "@_func/generate";
import { msgs, msgStatus } from "@_func/request";
import InputCpfCnpj, { validarCpfCnpj } from "@cpnt/InputCpfCnpj/InputCpfCnpj";

const FormEmpresa = props => {
	const {
		onSubmit,
		isCadastro,

		// State
		stateDados,
		setStateDados,

		after,

		stateTaxaVariavelDados,
		setStateTaxaVariavelDados,

		stateFechamentoDados,
		setStateFechamentoDados,
	} = props;

	const EXIBIR_BLOCO_DEV = false;

	/*
	** States
	** ================================================== */
	const [loadingPesquisarCep, setLoadingPesquisarCep] = useState(false);
	const [loadingPesquisarCNPJ, setLoadingPesquisarCNPJ] = useState(false);

	/*
	** Endereço
	** ================================================== */
	const pesquisarCEP = async e => {

		setStateDados({
			...stateDados,
			street: '',
			number: '',
			complement: '',
			district: '',
			city: '',
			state: '',
		});

		if(e === '') return;

		if(!validarCep(e)) {
			msg.error('CEP Inválido');
			return;
		}

		setLoadingPesquisarCep(true);

		const retGetCEP = await apiCep.get(e);

		if(retGetCEP.status === 200) {
			msg.success('CEP localizado com sucesso!', {
				autoClose: 2000
			});
			setStateDados({
				...stateDados,
				zip_code: e,
				street: retGetCEP.data.street,
				district: retGetCEP.data.district,
				city: retGetCEP.data.city,
				state: retGetCEP.data.uf,
			});
		} else {
			msgs([retGetCEP]);
		}

		setLoadingPesquisarCep(false);
	};

	/*
	** Pesquisar CNPJ
	** ================================================== */
	const pesquisarCNPJ = async e => {

		if(!validarCpfCnpj(e)) return;

		if(validarCpf(e, false)) return;

		setStateDados({
			...stateDados,
			name: '',
			social_reason: '',
			street: '',
			district: '',
			zip_code: '',
			state: '',
			city: '',
			number: '',
			complement: '',
		});

		if(e === '') return;

		if(!validarCnpj(e)) return;

		setLoadingPesquisarCNPJ(true);

		const retGetCNPJ = await apiCnpj.get(apenasNumeros(e));

		if(retGetCNPJ.status === 200) {

			// Nome contato
			var nomeContato = false;
			if(retGetCNPJ.data.qsa && typeof(retGetCNPJ.data.qsa) === 'object' && retGetCNPJ.data.qsa.length && retGetCNPJ.data.qsa[0] && retGetCNPJ.data.qsa[0].nome && retGetCNPJ.data.qsa[0].nome !== '') {
				nomeContato = retGetCNPJ.data.qsa[0].nome;
			}

			// E-mail contato
			var emailContato = false;
			if(retGetCNPJ.data.email && retGetCNPJ.data.email !== '') {
				emailContato = retGetCNPJ.data.email;
			}

			msg.success('CNPJ localizado com sucesso');
			setStateDados({
				...stateDados,
				name: retGetCNPJ.data.fantasia,
				social_reason: retGetCNPJ.data.nome,
				street: retGetCNPJ.data.logradouro,
				district: retGetCNPJ.data.bairro,
				zip_code: retGetCNPJ.data.cep && retGetCNPJ.data.cep.replace('.', ''),
				state: retGetCNPJ.data.uf,
				city: retGetCNPJ.data.municipio,
				number: retGetCNPJ.data.numero,
				complement: retGetCNPJ.data.complemento,
				phone: retGetCNPJ.data.telefone,
				...(nomeContato ? {
					name_responsible: nomeContato,
				} : {}),
				...(emailContato ? {
					email: emailContato,
				} : {}),
			});
		} else {
			msgs([retGetCNPJ]);
		}

		setLoadingPesquisarCNPJ(false);
	};

	/*
	** Adicionar item
	** ================================================== */
	const adicionarItem = (stateItem, setStateItem) => {
		setStateItem([
			...stateItem,
			{
				hash: hash(),
				name: '',
			}
		]);
	}

	/*
	** Remover item
	** ================================================== */
	const removerItem = (value, stateItem, setStateItem) => {
		setStateItem(stateItem.filter(item => item.hash !== value.hash));
	}

	return (
		<form className="relative" onSubmit={onSubmit}>
			{!isCadastro ? (
				<div className="padding-b-15">
					<h3 className="titulo">{stateDados.name}</h3>
					<div className="td-desc">ID da empresa: {stateDados.id_empresa}</div>
				</div>
			) : null}

			{loadingPesquisarCNPJ && <Loading texto="Consultando CNPJ, aguarde..." absolute />}

			<div className="flex wrap-5">
				<div className="flex flex-wrap flex-1 flex-top-self relative">
					<div className="input-box box-1">
						<InputCpfCnpj
							label='CNPJ'
							req
							_={{
								value: stateDados.cnpj,
								onChange: e => {
									setStateDados({
										...stateDados,
										cnpj: e.target.value,
									})
								},
								onBlur: e => {
									pesquisarCNPJ(e.target.value);
								}
							}}
						/>
					</div>
					<div className="input-box box-2">
						<Input2
							label="Nome da empresa"
							req
							_={{
								name: 'name',
								value: stateDados.name,
								onChange: e => setStateDados({
									...stateDados,
									name: e.target.value
								}),
							}}
						/>
					</div>
					<div className="input-box box-2">
						<Input2
							label="Razão Social"
							req
							_={{
								name: 'social_reason',
								value: stateDados.social_reason,
								onChange: e => setStateDados({
									...stateDados,
									social_reason: e.target.value
								}),
							}}
						/>
					</div>

					<div className="input-box box-3">
						<Input2
							label="Nome do principal contato"
							req
							_={{
								value: stateDados.name_responsible,
								onChange: e => setStateDados({
									...stateDados,
									name_responsible: e.target.value
								}),
							}}
						/>
					</div>
					<div className="input-box box-3">
						<InputTelefone
							label="Telefone"
							req
							_={{
								value: stateDados.phone,
								onChange: e => {
									setStateDados({
										...stateDados,
										phone: e
									});
								},
							}}
						/>
					</div>
					<div className="input-box box-3">
						<Input2
							label="E-mail"
							req
							_={{
								value: stateDados.email_responsible,
								onChange: e => setStateDados({
									...stateDados,
									email_responsible: e.target.value
								}),
							}}
						/>
					</div>
				</div>
			</div>

			<h3 className="titulo margin-t-15 margin-b-10">Endereço</h3>
			<div className="relative">
				{loadingPesquisarCep && <Loading texto="Consultando CEP, aguarde..." absolute />}
				<div className="flex flex-wrap wrap-h-5">
					<div className="input-box w-100p">
						<Input2
							label="CEP"
							req
							_={{
								value: stateDados.zip_code,
								onChange: e => {
									setStateDados({
										...stateDados,
										zip_code: e.target.value,
									})
								},
								mask: '99999-999',
								onBlur: e => {
									pesquisarCEP(e.target.value);
								}
							}}
						/>
					</div>
					<div className="input-box w-80p">
						<Input2
							label="Logradouro"
							req
							_={{
								value: stateDados.street,
								onChange: e => setStateDados({
									...stateDados,
									street: e.target.value
								}),
							}}
						/>
					</div>
					<div className="input-box w-20p">
						<Input2
							label="Número"
							req
							_={{
								value: stateDados.number,
								onChange: e => setStateDados({
									...stateDados,
									number: e.target.value
								}),
							}}
						/>
					</div>
					<div className="input-box w-50p">
						<Input2
							label="Complemento"
							_={{
								value: stateDados.complement,
								onChange: e => setStateDados({
									...stateDados,
									complement: e.target.value
								}),
							}}
						/>
					</div>
					<div className="input-box w-50p">
						<Input2
							label="Bairro"
							req
							_={{
								value: stateDados.district,
								onChange: e => setStateDados({
									...stateDados,
									district: e.target.value
								}),
							}}
						/>
					</div>
					<div className="input-box w-50p">
						<Input2
							label="Cidade"
							req
							_={{
								value: stateDados.city,
								onChange: e => setStateDados({
									...stateDados,
									city: e.target.value
								}),
							}}
						/>
					</div>
					<div className="input-box w-50p">
						<Input2
							label="Estado"
							req
							_={{
								value: stateDados.state,
								onChange: e => setStateDados({
									...stateDados,
									state: e.target.value
								}),
							}}
						/>
					</div>
				</div>
			</div>

			<div>
				<h3 className="titulo margin-t-15">Tratativas Comerciais</h3>

				<div className="wrap padding-t-5">
					<div className="input-box w-200">
						<Input2
							label="Taxa plataforma (%)"
							req
							_={{
								mask: '99',
								maskPlaceholder: null,
								value: stateDados.percentage,
								onChange: e => setStateDados({
									...stateDados,
									percentage: e.target.value
								}),
							}}
							toString={true}
						/>
					</div>
				</div>
			</div>

			{EXIBIR_BLOCO_DEV ? (
				<DevBox className="margin-t-20">
					<div className="relative">
						{loadingPesquisarCep && <Loading texto="Consultando CEP, aguarde..." absolute />}
						<div className="flex flex-wrap wrap-h-5">
							<div className="input-box w-100p">
								<div className="fs-16 bold">Tipo de taxa:</div>
								<div className="flex flex-wrap margin-t-5">
									<Radio2
										label="Taxa fixa"
										_={{
											checked: stateDados.tipo_taxa === 'fixa' ? true : false,
											onChange: e => setStateDados({
												...stateDados,
												tipo_taxa: e.target.checked ? 'fixa' : false
											}),
										}}
									/>
									<Radio2
										label="Taxa Variável Condicional"
										_={{
											checked: stateDados.tipo_taxa === 'variavel' ? true : false,
											onChange: e => setStateDados({
												...stateDados,
												tipo_taxa: e.target.checked ? 'variavel' : false
											}),
										}}
										wrap="margin-l-20"
									/>
								</div>
							</div>
							{/* Porcentagem */}
							{stateDados.tipo_taxa === 'fixa' ? (
								<>
									<div className="input-box w-33p">
										<Input2
											label="Taxa plataforma (%)"
											_={{
												mask: '99',
												maskPlaceholder: null,
												value: stateDados.percentage,
												onChange: e => setStateDados({
													...stateDados,
													percentage: e.target.value
												}),
											}}
											toString={true}
										/>
									</div>
									<div className="input-box w-33p">
										<Input2
											customLabel={
												<Tip
													closeOnLeave
													tip={
														<>
															<span>Valor mínimo da taxa mensal (R$)</span>
															<span className="circle margin-l-5">?</span>
														</>
													}
												>
													<div className="w-300">Este valor é referente à assinatura que o cliente pagará para usar a plataforma.</div>
												</Tip>
											}
											_={{
												value: stateDados.porcentagem_minima,
												onChange: e => setStateDados({
													...stateDados,
													porcentagem_minima: e,
												}),
											}}
											dinheiro
										/>
									</div>
								</>
							) : null}

							{stateDados.tipo_taxa === 'variavel' ? (
								<>
									<div className="input-box w-33p">
										<Input2
											customLabel={
												<Tip
													closeOnLeave
													tip={
														<>
															<span>Valor mínimo da taxa mensal (R$)</span>
															<span className="circle margin-l-5">?</span>
														</>
													}
												>
													<div className="w-300">Este valor é referente à assinatura que o cliente pagará para usar a plataforma.</div>
												</Tip>
											}
											_={{
												value: stateDados.porcentagem_minima,
												onChange: e => setStateDados({
													...stateDados,
													porcentagem_minima: e,
												}),
											}}
											dinheiro
										/>
									</div>

									<div className="input-box w-100p flex flex-center-v fs-15">
										<div>Faturamento até <strong>R$ {stateTaxaVariavelDados[0]?.faturamentoAcimaDe}</strong>, a taxa será de <strong>12%</strong>.</div>
										<BtnAdicionar
											onClick={() => {
												adicionarItem(stateTaxaVariavelDados, setStateTaxaVariavelDados);
											}}
											className="margin-l-15"
										/>
									</div>
									<div className="flex flex-wrap w-100p">
										{!stateTaxaVariavelDados.length ?
											<h3 className="msg flex margin-h-5 margin-b-0 flex-1">Nenhuma taxa variável condicional adicionada.</h3>
										:
											stateTaxaVariavelDados.map((value, key) => {
												return (
													<div key={value.hash} className="flex flex-wrap w-100p">
														<div className="flex w-100p wrap-input relative">
															<div className="input-box w-50p">
																<Input2
																	label="Faturamento acima de (R$)"
																	_={{
																		value: value.faturamentoAcimaDe,
																		onChange: e => {
																			var dados = [...stateTaxaVariavelDados];
																			dados[key] = {
																				...dados[key],
																				faturamentoAcimaDe: e,
																			}
																			setStateTaxaVariavelDados(dados);
																		}
																	}}
																	dinheiro
																/>
															</div>
															<div className="input-box w-50p">
																<Input2
																	label="Taxa plataforma (%)"
																	_={{
																		mask: '99',
																		maskPlaceholder: null,
																		value: value.taxa,
																		onChange: e => {
																			var dados = [...stateTaxaVariavelDados];
																			dados[key] = {
																				...dados[key],
																				taxa: e.target.value,
																			}
																			setStateTaxaVariavelDados(dados);
																		}
																	}}
																/>
															</div>
															{key !== 0 ? (
																<div className="x" onClick={e => removerItem(value, stateTaxaVariavelDados, setStateTaxaVariavelDados)}></div>
															) : null}
														</div>
													</div>
												)
											})
										}
									</div>
								</>
							) : null}

							<div className="input-box w-100p flex flex-center-v fs-15 margin-t-10">
								<Tip
									closeOnLeave
									tip={
										<>
											<div className="fs-16 bold">Fechamento</div>
											<span className="circle margin-l-5">?</span>
										</>
									}
								>
									<div className="w-300">O fechamento trata-se do período de aprovação dos jobs. Atualmente, a data parâmetro para o fechamento é a data de aprovação do job.</div>
								</Tip>
								<BtnAdicionar
									onClick={() => {
										adicionarItem(stateFechamentoDados, setStateFechamentoDados);
									}}
									className="margin-l-15"
								/>
							</div>
							<div className="flex flex-wrap w-100p">
								{!stateFechamentoDados.length ?
									<h3 className="msg flex margin-h-5 margin-b-0 flex-1">Nenhum dia de fechamento especificado.</h3>
								:
									stateFechamentoDados.map((value, key) => {
										return (
											<div key={value.hash} className="flex flex-wrap w-100p">
												<div className="flex w-100p wrap-input relative">
													<div className="input-box w-20p">
														<Input2
															label="Dia inicial"
															_={{
																value: value.diaInicial,
																onChange: e => {
																	var dados = [...stateFechamentoDados];
																	dados[key] = {
																		...dados[key],
																		diaInicial: e.target.value,
																	}
																	setStateFechamentoDados(dados);
																}
															}}
														/>
													</div>
													<div className="input-box w-20p">
														<Input2
															label="Dia final"
															_={{
																value: value.diaFinal,
																onChange: e => {
																	var dados = [...stateFechamentoDados];
																	dados[key] = {
																		...dados[key],
																		diaFinal: e.target.value,
																	}
																	setStateFechamentoDados(dados);
																}
															}}
														/>
													</div>
													<div className="input-box w-20p">
														<Input2
															label="Dia do pagamento do profissional remoto"
															labelClass="lh-1"
															_={{
																value: value.diaDoPagamento,
																onChange: e => {
																	var dados = [...stateFechamentoDados];
																	dados[key] = {
																		...dados[key],
																		diaDoPagamento: e.target.value,
																	}
																	setStateFechamentoDados(dados);
																}
															}}
														/>
													</div>
													<div className="input-box w-40p">
														<div className="relative w-100p">
															<span className="label">Mês para o pagamento</span>
															<div className="flex flex-center-v w-100p padding-t-20 padding-l-10">
																<Radio2
																	label="Mês corrente"
																	_={{
																		checked: stateFechamentoDados[key].vigencia === 'mesAtual' ? true : false,
																		onChange: e => {
																			var dados = [...stateFechamentoDados];
																			dados[key] = {
																				...dados[key],
																				vigencia: e.target.checked ? 'mesAtual' : false,
																			}
																			setStateFechamentoDados(dados);
																		}
																	}}
																/>
																<Radio2
																	label="Mês vencido"
																	wrap="margin-l-25"
																	_={{
																		checked: stateFechamentoDados[key].vigencia === 'mesSeguinte' ? true : false,
																		onChange: e => {
																			var dados = [...stateFechamentoDados];
																			dados[key] = {
																				...dados[key],
																				vigencia: e.target.checked ? 'mesSeguinte' : false,
																			}
																			setStateFechamentoDados(dados);
																		}
																	}}
																/>
															</div>
														</div>
													</div>

													{key !== 0 ? (
														<div className="x" onClick={e => removerItem(value, stateFechamentoDados, setStateFechamentoDados)}></div>
													) : null}
												</div>
											</div>
										)
									})
								}
							</div>
						</div>
						<div>
							<h3 className="titulo margin-t-15 margin-b-0">Dados do contrato</h3>
							<div className="flex flex-wrap wrap-h-5">
								<div className="input-box w-66p">
									<Input2
										label="Nome do Vendedor"
										req
										_={{
											value: stateDados.nome_vendedor,
											onChange: e => setStateDados({
												...stateDados,
												nome_vendedor: e.target.value
											}),
										}}
									/>
								</div>
								<div className="input-box w-33p">
									<InputCalendario
										label="Data de Assinatura do Contrato"
										req
										value={stateDados.data_assinatura_contrato}
										onSelect={e => {
											setStateDados({
												...stateDados,
												data_assinatura_contrato: e,
											});
										}}
										class="flex-1"
									/>
								</div>
								<div className="input-box w-33p">
									<InputCalendario
										label="Início da Vigência do Contrato"
										req
										value={stateDados.data_inicio_vigencia}
										onSelect={e => {
											setStateDados({
												...stateDados,
												data_inicio_vigencia: e,
											});
										}}
										class="flex-1"
									/>
								</div>
								<div className="input-box w-33p">
									<InputCalendario
										label="Término da Vigência do Contrato"
										req
										value={stateDados.data_termino_vigencia}
										onSelect={e => {
											setStateDados({
												...stateDados,
												data_termino_vigencia: e,
											});
										}}
										class="flex-1"
									/>
								</div>
								<div className="input-box w-33p">
									<Input2
										label="Prazo de Pagamento (DDL)"
										_={{
											value: stateDados.data_faturamento_ddl,
											placeholder: 'Ex: 10 dias / 14 dias / 28 dias',
											onChange: e => setStateDados({
												...stateDados,
												data_faturamento_ddl: e.target.value
											}),
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</DevBox>

			) : null}

			{after}

		</form>
	);
};

export default FormEmpresa;