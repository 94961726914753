import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './Login.css';

// API
import apiLogin from '@api/login/login';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Input2 from '@cpnt/Input2/Input2';
import OverlayConteudo from '@cpnt/OverlayConteudo/OverlayConteudo';

// Imagens
import logo from '@img/logo.png';

// Funções
import Info from "@cpnt/Info/Info";
import Loading from "@cpnt/Loading/Loading";
import Portal from "@cpnt/Portal/Portal";
import {isLogado, salvarSessaoLocal } from '@_func/auth';
import { camposInvalidos, formatarCNPJ, validarEmail } from '@func/utils';
import { DEV, URL_SITE } from "@_config/config";
import { msgs, msgStatus } from "@_func/request";

const Login = props => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();

	const camposDefault = {
		email: '',
		password: '',
		id_company: 0,
	};
	const [stateDados, setStateDados] = useState(camposDefault);
	const [overlaySucesso, setOverlaySucesso] = useState(false);

	const [stateDadosEmpresas, setStateDadosEmpresas] = useState([]);
	const [showPortalSelecaoEmpresa, setShowPortalSelecaoEmpresa] = useState(false);

	const [loadingLogin, setLoadingLogin] = useState(false);

	const fazerLogin = async (id = 0) => {

		if(loadingLogin) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			email: stateDados.email,
			password: stateDados.password,
		};

		if(camposInvalidos(dadosEnviar, {
			email: 'E-mail'
		})) return;

		// Definir tipo de login
		if(!validarEmail(dadosEnviar.email)) return;

		var dadosLogin = {
			email: stateDados.email,
			password: stateDados.password,
		};

		dadosLogin.id_company = id;

		setLoadingLogin(true);

		const ret = await apiLogin.login.post(dadosLogin);

		if(ret.status === 200) {
			salvarSessaoLocal({
				usuario: ret.data,
				token: ret.auth.token,
			});

			msg.success('Login realizado com sucesso!');

			setOverlaySucesso(true);

			setTimeout(() => {
				setStore({
					...store,
					...isLogado(),
				});

				if(ret.data.role === 2) {
					if(ret.data.finish) {
						navigate('/inicio');
					} else {
						navigate('/perfil/passo-a-passo');
					}
				} else {
					navigate('/inicio');
				}
			}, 3000);

		} else if(ret.status === 403 && ret?.data?.companies?.length){
			setStateDadosEmpresas(ret.data.companies);
			setShowPortalSelecaoEmpresa(true);
		} else {
			msgs([ret]);
		}

		setLoadingLogin(false);
	}

	return (
		<>
			<div>
				<div className="tela-inicio-e-inner">
					<OverlayConteudo
						exibir={overlaySucesso}
						texto="Login realizado com sucesso!"
						exibirFechar={false}
					/>
					<a href={`${URL_SITE}`} className="tela-inicio-e-logo padding-t-30 padding-b-30">
						<img src={logo} alt="Logo CROWD" className="block w-100p max-w-350 margin-auto" />
					</a>
					<h1 className="tela-inicio-e-titulo padding-t-20 padding-b-20">Acesse sua conta</h1>
					<div className="wrap w-100p">
						<form className="tela-inicio-form" onSubmit={e => {
							e.preventDefault();
							fazerLogin();
						}}>
							<div className="input-box">
								<Input2
									label="E-mail"
									req
									_={{
										value: stateDados.email,
										onChange: e => {
											setStateDados({
												...stateDados,
												email: e.target.value,
											})
										}
									}}
									debounce={false}
								/>
							</div>
							<div className="input-box input-box-padding-top">
								<Input2
									label="Digite sua senha"
									req
									_={{
										type: 'password',
										value: stateDados.password,
										onChange: e => {
											setStateDados({
												...stateDados,
												password: e.target.value,
											})
										}
									}}
									debounce={false}
								/>
							</div>
							<div className="input-box flex flex-end padding-t-0 flex-center-v">
								<div>
									<Link to="/recuperar-senha" className="fs-14 no-decoration bold">Esqueceu sua senha?</Link>
								</div>
							</div>
							<div className="input-box">
								<Button2
									_={{
										type: 'submit',
										value: 'Acessar',
										className: 'btn btn-default flex-1'
									}}
									wrap='flex'
									loading={loadingLogin}
								/>
							</div>
						</form>
					</div>
					<div className="margin-t-50 fs-13 margin-b-5 align-center flex flex-center gap-10 flex-wrap">
						<div className="w-100p">Não possui conta? Cadastre-se:</div>
						<Link to="/cadastre-se" className="no-decoration bold">Como profissional</Link>
						<span>|</span>
						<a href="https://crowd.br.com/" className="no-decoration bold">Como contratante</a>
					</div>
				</div>
			</div>

			<Portal
				titulo={!overlaySucesso ? "Você possui acesso a mais de uma empresa" : false}
				show={showPortalSelecaoEmpresa}
				closeOnEsc={false}
				onClose={e => setShowPortalSelecaoEmpresa(false)}
				className="w-600"
				x={!overlaySucesso}
			>
					{loadingLogin ? <Loading absolute /> : null}
					{overlaySucesso ? (
						<Info
							icone="sucesso"
							texto="Login realizado com sucesso!"
						/>
					) : (
						<>
							<div>Selecione a empresa para qual você deseja acessar:</div>
							<div className="margin-t-20 relative">
								{stateDadosEmpresas.map((val, key) => {
									return (
										<div key={key} className="login-btnemp" onClick={e => fazerLogin(val.id)}>
											<h3 className="margin-0 padding-0 color-4">{val.name}</h3>
											<div><strong>CNPJ:</strong> {formatarCNPJ(val.cnpj)}</div>
											<div><strong>ID de cadastro:</strong> {val.id}</div>
										</div>
									)
								})}
							</div>
						</>
					)}
			</Portal>
		</>
	)
}

export default Login;