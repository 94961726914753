import React, { useEffect, useState } from 'react';

// CSS
import c from './Input.module.scss';

// Func
import { hash } from "@_func/generate";

export const Input = props => {

	const {
		className = '',
		label,
		req,
		show = true,
		_,
	} = props;

	const [id] = useState(hash('input-id-'));


	// useEffect(() => {
	// 	genId();
	// }, []);

	// const genId = () => {
	// 	id = hash('input-id-');
	// }

	if(!show) {
		return <></>;
	}

	return (
		<div className={`${className} ${c['container']}`}>
			{label && <label htmlFor={`${id}`}>{label}{req ? <span className={`${c['req']}`}></span> : null}</label>}
			{_?.type && _.type === 'textarea' ? (
				<textarea id={`${id}`} {..._} />
			) : (
				<input id={`${id}`} type="text" {..._} />
			)}
		</div>
	);
}
