import React from 'react';

// css
import './InputFake.css';

const InputFake = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		label,
		onClick,
		value,
	} = props;

	return (
		<>
			<div className={`cpnt-inputfake ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-inputfake-box ${className}`}>
					<div className="cpnt-inputfake-input cpnt-inputfake-inativo w-100p" onClick={onClick}>
						<span className="cpnt-inputfake-label">{label}</span>
						<span>{value || '(não informado)'}</span>
					</div>
				</div>
			</div>
		</>
	);
};

export { InputFake };