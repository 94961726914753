import { useEffect, useState } from 'react';
import _ from 'lodash';

function useWindowDimension(tempo = 500) {
	const [stateDimensao, setStateDimensao] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});
	useEffect(() => {
		const redimensionarListener = _.debounce(() => {
			setStateDimensao({width: window.innerWidth, height: window.innerHeight});
		}, tempo);
		window.addEventListener('resize', redimensionarListener);
		return () => window.removeEventListener('resize', redimensionarListener);
	}, []);
	return stateDimensao;
}

export default useWindowDimension;