import React from 'react';
import { Sketch } from '@uiw/react-color';

// css
import './InputColor.css';

// Componentes
import Tip from "@cpnt/Tip/Tip";

const InputColor = props => {
	const {
		className = '',
		classNameWrap = '',
		label = 'Cor',
		placeholder = 'Selecione uma cor',
		stateColor,
		setStateColor,
	} = props;

	return (
		<>
			<div className={`cpnt-inputcolor ${classNameWrap}`}>
				<div className={`cpnt-inputcolor-box ${className}`}>
					<Tip
						tip={
							<div className={"cpnt-inputcolor-input cpnt-inputcolor-inativo w-100p"}>
								<span className="cpnt-inputcolor-label">{label}</span>
								<span className="cpnt-inpucolor-cor" style={{backgroundColor: stateColor}}>{placeholder}</span>
							</div>
						}
						classNameTipDefault=""
						classNameDefault="z-index-9999"
						click
						_defaults={{
							placement: 'right-center',
						}}
					>
						<Sketch
							color={stateColor}
							onChange={setStateColor}
						/>
					</Tip>
				</div>
			</div>
		</>
	);
};

export { InputColor };