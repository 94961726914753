import React from 'react';
import { Link } from 'react-router-dom';

// CSS
import c from './AvisoAcessoRestrito.module.css';

// Imagens
import iconeAlerta from '@_img/default/icone-alerta.png';

const AvisoAcessoRestrito = props => {

	const {
		mensagem,
	} = props;

	return (
		<div className={c['box']}>
			<div className={c['box-inner']}>
				<div className={c['box-img']}>
					<img src={iconeAlerta} alt="icone" className="block w-100p"/>
				</div>

				<h3 className="align-center">Cadastro em análise: seu acesso BETA está próximo de ser liberado</h3>

				<p>Bem-vindo(a) à plataforma CROWD!</p>
				<p>Nossa equipe já foi notificada sobre seu cadastro e está validando seu acesso Beta.</p>
				<p>Em até 48h úteis, você receberá uma notificação direto no seu email assim que o cadastro for validado.</p>
				<p>Em breve, você vai poder publicar propostas de jobs e contratar profissionais remotos freelancers de marketing e tecnologia:</p>

				<ul className="lista-check">
					<li>Desenvolvedores front-end, back-end e full-stack</li>
					<li>Designers gráfico, UX/UI e Branding</li>
					<li>Redatores, Revisores e Tradutores</li>
					<li>Gestores de Tráfego, Estrategistas e Copywriters</li>
					<li>Editores de vídeo, áudio e foto</li>
					<li>E diversos outros prestadores de serviços.</li>
				</ul>

				<p>Enquanto isso, <Link to="/perfil">aproveite para completar o seu perfil na plataforma</Link>. :)</p>
			</div>
		</div>
	)
}

export default AvisoAcessoRestrito;