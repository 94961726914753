import React from 'react';

// css
import './PortalTermosNotaFiscal.css';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Portal from '@cpnt/Portal/Portal';

// Textos
import { textoImportanciaNotaFiscal } from "@texto/textoNFPorqueEImportante";

const PortalTermosNotaFiscal = props => {

	const {
		show,
		onClose,
		closeOnEsc = true,
		portalClassName = 'portal-termos-nota',
	} = props;

	return (
		<Portal
			titulo="Porque a emissão da Nota Fiscal é importante"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{textoImportanciaNotaFiscal()}
			<div className="form-bottom">
				<Button2
					_={{
						value: 'Fechar',
						onClick: onClose,
					}}
				/>
			</div>
		</Portal>
	);
};

export default PortalTermosNotaFiscal;