import { validarRetorno, config } from '../axios';
import api from 'axios';
import qs from 'qs';

const apiTenor = {
	get: async (dadosObj) => {

		const dados = {
			key: 'P1PAQZATZN8K',
			locale: 'pt_BR',
			limit: 50,
			...dadosObj,
		}

		var dadosEnviar = qs.stringify(dados, { encode: false });

		return await api.get(`https://g.tenor.com/v1/search?${dadosEnviar}`, {})
		.then(retorno => {

			var ret = validarRetorno(retorno);
			if(ret.status === 200 && ret.next && ret.next !== '0' && ret.results && ret.results.length) {
				return ret;
			} else if(ret.status >= 200 && ret.status <= 299) {
				return {
					...ret,
					status: 400,
					message: 'Nenhum resultado encontrado',
				}
			} else {
				return {
					...ret,
				}
			}
		})
		.catch(retorno => {
			return validarRetorno(retorno);
		});
	}
};

export default apiTenor;
