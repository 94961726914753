import React from 'react';

// CSS
import './ListaSecao.css';

// Componentes
import { BtnAdd } from "@cpnt/BtnAdd/BtnAdd";
import Loading from "@cpnt/Loading/Loading";
import { Tag } from "@cpnt/Tag/Tag";
import { BarraHorizontal } from "@cpnt/BarraHorizontal/BarraHorizontal";
import stringImg from "@cpnt/stringImg/stringImg";
import imgIconeSalvar from '@svg/regular/floppy-disk.svg';
import Tip from "@cpnt/Tip/Tip";

const ListaSecao = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		classNameTituloBox = '',
		classNameTitulo = 'titulo',
		classNameBox = 'box-2',

		titulo = 'Selecione as opções desejadas',
		subtitulo,
		textoNenhumItemEncontrado = 'Nenhuma informação encontrada',
		textoSemDados,
		textoAdd = 'Adicionar',

		stateDados = [],
		setStateDados = [],
		onClickAdd,
		onClickEdit,
		onClickRemover,
		onClickBtnSalvar,
		loadingBtnSalvar,
		loading,
		keyLink,

		alt,
		keyTitulo = 'label',
		subLabel,
		keyDesc,
		validarSubLabel = true,
		textoSubLabelInvalido = 'sub opção inválida',
		exibirApenasChecked = true,
		showTitulo = true,
		after,
	} = props;

	const removerItemLista = (id) => {
		var listaAtualizada = stateDados.map(item => {
			if(item.id === id) {
				return {
					...item,
					checked: false,
				}
			}
			return item;
		});
		setStateDados(listaAtualizada);
	};

	const itensLoop = e => {
		if(exibirApenasChecked) {
			if(stateDados.length && stateDados.filter(item => item.checked).length) {
				return stateDados.filter(item => item.checked);
			}
			return [];
		}

		return stateDados;
	}

	return (
		<>
			<div className={`cpnt-listasecao ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-listasecao-box ${className}`}>
					{showTitulo ? (
						<div className={`cpnt-listasecao-titulobox flex flex-center-v ${classNameTituloBox}`}>
							<div className="flex flex-center-v">
								<h3 className={`${classNameTitulo}`}>{titulo}</h3>
							</div>
							<BtnAdd
								titulo={textoAdd}
								onClick={onClickAdd}
								className="margin-l-25 margin-b--3"
							/>
							{onClickBtnSalvar ? (
								<Tip
									tip={
										<div className="relative">
											{loadingBtnSalvar ? <Loading texto="" absolute alt min bgAlt2 /> : null}
											<BtnAdd
												titulo="Salvar dados"
												onClick={onClickBtnSalvar}
												className="margin-l-25 margin-b--3"
												img={imgIconeSalvar}
											/>
										</div>
									}
									info
								>
									Você pode salvar os dados dessa seção separadamente conforme for preenchendo.
								</Tip>
							) : null}
						</div>
					) : null}

					{subtitulo ? (
						<div className="desc margin-b-5">{subtitulo}</div>
					) : null}

					<div className="flex wrap-5 relative">
						{loading || loadingBtnSalvar ? <Loading texto="Aguarde..." alt absolute /> : null}
						<div className="flex flex-wrap w-100p margin-t-10">
							{itensLoop().length ? itensLoop().map((val, key) => {

								var label = val[keyTitulo];
								var subLabelTxt = false;

								if(subLabel) {
									subLabelTxt = val[subLabel];
									if(validarSubLabel) {
										if(!subLabelTxt || subLabelTxt === '') {
											subLabelTxt = <span className="strong" style={{color: '#f99191'}}>{textoSubLabelInvalido}</span>;
										}
									}
								}

								if(alt) {

									var img;
									if(keyLink) {
										img = stringImg(val[keyLink]);
										if(!img.cor) {
											img.cor = '#BBB';
										}
									}

									return (
										<BarraHorizontal
											key={key}
											titulo={label}
											onClickEdit={e => {
												onClickEdit({
													key: key,
													dados: val,
												});
											}}
											onClickX={e => {
												if(onClickRemover) {
													onClickRemover({
														key: key,
														dados: val,
													});
												} else {
													removerItemLista(val.id);
												}
											}}
											classNameWrap={classNameBox}
											{...(keyDesc ? {
												desc: val[keyDesc]
											} : {})}
											{...(keyLink ? {
												link: val[keyLink],
												icone: img.icone,
												iconeCor: img.cor,
											} : {})}
										/>
									)
								}

								return (
									<Tag
										key={key}
										texto={<>{label}{subLabelTxt ? <span className="color-3"> ({subLabelTxt})</span> : null}</>}
										x={true}
										onClick={e => {
											removerItemLista(val.id);
										}}
										classNameWrap="margin-5"
									/>
								)

							}) : (
								!itensLoop().length && textoSemDados && textoSemDados !== '' ? (
									<div className="padding-5 w-100p border-box">
										<div className="msg">{textoSemDados}</div>
									</div>
								) : (
									<div className="padding-5 w-100p border-box">
										<div className="msg">{textoNenhumItemEncontrado}</div>
									</div>
								)
							)}
						</div>
					</div>
					{after}
				</div>
			</div>
		</>
	);
};

export { ListaSecao };