import React from 'react';

import c from './CirculoNumero.module.scss';

export const CirculoNumero = props => {
	const {
		numero,
		active,
		click = true,
		className = '',
		onClick,
	} = props;

	return (
		<div className={`${c['numero']} ${className} ${active ? c['active'] : ''} ${click || onClick ? c['click'] : ''}`} onClick={onClick}>{numero}</div>
	)
}