import React from 'react';

// CSS
import c from './BtnNotificacoes.module.scss';

// Imagens
import imgIconeNotificacao from '@svg/regular/bell.svg';
import { Icone } from "@_cpnt/Icone/Icone";

export const BtnNotificacoes = props => {
	const {
		className = '',
		classNameTexto = '',
		texto = 'Notificações',
		qtd,
		onClick,
	} = props;

	return (
		<div className={`${className} ${c['container']}`} onClick={onClick}>
			<Icone img={imgIconeNotificacao} />
			<div className={`${c['texto']} ${classNameTexto}`}>{texto}</div>
			{qtd && <div className={`${c['qtd']}`}>{qtd > 99 ? '99+' : qtd}</div>}
		</div>
	);
}
