import React from 'react';
import { Link } from 'react-router-dom';
// css
import './Voltar.css';

// Componentes
import Icone from '@cpnt/Icone/Icone';
import imgIconeVoltar from '@svg/solid/chevron-left.svg';
import Tip from "@cpnt/Tip/Tip";

const Voltar = props => {
	const {
		className = '',
		wrap = 'margin-r-10',
		link,
		texto,
		desc = 'Voltar',
	} = props;

	return (
		<Tip
			tip={
				<div className={`cpnt-voltar ${wrap}`}>
					<div className={`cpnt-voltar-box ${className}`}>
						<Link className="cpnt-voltar-btn" to={link}>
							<Icone
								imagem={imgIconeVoltar}
								cor="#0277BD"
								tamanho="16px"
							/>
							{texto ? (
								<div className="cpnt-voltar-texto">{texto}</div>
							) : null}
						</Link>
					</div>
				</div>
			}
			info
		>
			{desc}
		</Tip>
	);
};

export default Voltar;