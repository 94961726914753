import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './ProfissoesPredefinidas.css';

// Hooks
import useWindowDimension from "@/_/hooks/useWindowDimensions";

// API
import apiAlgolia from '@api/algolia/algolia';
import apiProfessions from "@api/professions/professions";
import { apiGetAreas, apiGetEmpresas, apiValidarAcesso } from "@api/_functions/geral";

// Imagens
import imgIconeEditar from '@svg/regular/pen.svg';
import imgIconeStatus from '@svg/regular/play-pause.svg';
import imgIconeVoltar from '@svg/solid/arrow-left.svg';
import imgIconePesquisa from '@svg/solid/magnifying-glass.svg';
import imgIconeTemplate from '@svg/regular/file-lines.svg';

// Componentes
import { BtnAdd } from "@cpnt/BtnAdd/BtnAdd";
import { BtnLimparFiltros } from "@cpnt/BtnLimparFiltros/BtnLimparFiltros";
import FiltrosWrap from "@cpnt/FiltrosWrap/FiltrosWrap";
import FunilMatch from "@cpnt/FunilMatch/FunilMatch";
import Icone from '@cpnt/Icone/Icone';
import Input2 from '@cpnt/Input2/Input2';
import { ListaProfissionais } from "@cpnt/ListaProfissionais/ListaProfissionais";
import { PlaceholderRender } from "@cpnt/Placeholder/Placeholder";
import PortalProfissoesPredefinidasDetalhes from "@cpnt/Portais/PortaisProfissoesPredefinidas/PortalProfissoesPredefinidasDetalhes/PortalProfissoesPredefinidasDetalhes";
import { PortalProfissoesPredefinidasFormCadastro } from "@cpnt/Portais/PortaisProfissoesPredefinidas/PortalProfissoesPredefinidasFormCadastro/PortalProfissoesPredefinidasFormCadastro";
import { PortalProfissoesPredefinidasFormEdicao } from "@cpnt/Portais/PortaisProfissoesPredefinidas/PortalProfissoesPredefinidasFormEdicao/PortalProfissoesPredefinidasFormEdicao";
import Portal from '@cpnt/Portal/Portal';
import { Select2 } from '@cpnt/Select2/Select2';
import { Select3Lista } from '@cpnt/Select3/Select3';
import Status from '@cpnt/Status/Status';
import Tip from "@cpnt/Tip/Tip";
import TotalPaginacao from "@cpnt/TotalPaginacao/TotalPaginacao";

// Funções
import { arrValues, joinArray, matchTipo, msg, removerKeysVazias, tamanhoTela } from '@func/utils';

// Objetos
import { FILTROS_RESULTADOS, STATUS_FILTRO_MIN } from "@config/objetos";

// Rotas
import { clearGet, closePortal, getPortalDados, gets, getsSemPortais, initFuncExec, isShowPortal, setGet, setGetNavigate, showPortal } from "@route/routeUtils";
import { PortalTemplateDetalhes } from "@cpnt/Portais/PortaisTemplates/PortalTemplateDetalhes/PortalTemplateDetalhes";
import AvisoAcessoRestrito from "@cpnt/AvisoAcessoRestrito/AvisoAcessoRestrito";
import { MSG_ACESSO_EM_ANALISE_FUNCIONALIDADE_DESATIVADA } from "@texto/textosGerais";
import { isNumeric } from "@_func/regex";
import { msgs, msgStatus } from "@_func/request";
import { Btn } from "@_cpnt/Btn/Btn/Btn";

const ProfissoesPredefinidas = props => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	const screen = useWindowDimension();

	/*
	** Validar acesso
	** ================================================== */
	const [stateAcesso, setStateAcesso] = useState({});
	const [stateIsAcessoValido, setStateIsAcessoValido] = useState(true);

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = async (getsAtualizados = getsDefault, state) => {

		const validarAcesso = await apiValidarAcesso({setStateAcesso, setStateIsAcessoValido});
		if(validarAcesso.sucesso) {
			initFuncExec([
				{
					func: getProfissoesPredefinidas,
					param: getsAtualizados,
					condicao: getsAtualizados.step === '1',
				},
				{
					func: getProfissionaisMatchApi,
					param: { ...getsAtualizados, ...state },
					condicao: getsAtualizados.step === '2' && validarAcesso.sucesso,
				},
				{
					func: apiGetAreas,
					param: {
						setLoadingGetAreas,
						setStateAreas,
					},
					init: true,
				},
				{
					func: apiGetEmpresas,
					param: {
						setLoadingGetEmpresas,
						setStateEmpresas,
					},
					init: true,
				},
			], init, location, stateLocation);
		}
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		step: '1',
		overlay: [],
		limit: 20,
		page: 1,
		title: '',
		id_area: null,
		active: null,
		id_company: '',
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
		init: init,
		// defaults: {id_company: store.usuario.id_company}
		// Parametro default para iniciar a tela com valor predefinido sem atrapalhar o filtro
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	/*
	** Em caso de abrir com uma página específica, volta para o filtro
	** ================================================== */
	useEffect(() => {
		var _gets = gets();
		if(_gets.step && _gets.step !== '1') {
			setGetNavigate({
				gets: {},
				navigate,
				stateGet,
				location,
				uniq: true,
			});
		}
	}, []);

	/*
	** Loading
	** ================================================== */
	const [loadingGetProfissoesPredefinidas, setLoadingGetProfissoesPredefinidas] = useState(false);
	const [loadingAlterarStatus, setLoadingAlterarStatus] = useState(false);
	const [loadingGetAreas, setLoadingGetAreas] = useState(false);
	const [loadingGetProfissionais, setLoadingGetProfissionais] = useState(false);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateProfissoesPredefinidas, setStateProfissoesPredefinidas] = useState([]);
	const [stateProfissoesPredefinidasMetadata, setStateProfissoesPredefinidasMetadata] = useState({});
	const [_stateProfissoesPredefinidas, _setStateProfissoesPredefinidas] = useState({});
	const [stateFiltros, setStateFiltros] = useState({});
	const [stateFiltrosAlgolia, setStateFiltrosAlgolia] = useState({});
	const [stateProfissionais, setStateProfissionais] = useState([]);
	const [_stateProfissionais, _setStateProfissionais] = useState({});
	const [stateProfissionaisMetadata, setStateProfissionaisMetadata] = useState({});
	const [stateAreas, setStateAreas] = useState([]);
	const [stateEmpresas, setStateEmpresas] = useState([]);

	/*
	** API - Get P
	** ================================================== */
	const getProfissoesPredefinidas = async (dadosObj = stateGet) => {
		if(loadingGetProfissoesPredefinidas) {
			return;
		}

		setLoadingGetProfissoesPredefinidas(true);
		setStateFiltrosAlgolia({});

		setStateFiltros(dadosObj);

		var dadosEnviar = clearGet(dadosObj);

		if(dadosEnviar.id_company === 'publicas') {
			dadosEnviar.id_company = 0;
		}

		const retGetProfissoesPredefinidas = await apiProfessions.all.get(dadosEnviar);

		_setStateProfissoesPredefinidas(msgStatus(retGetProfissoesPredefinidas));

		if(retGetProfissoesPredefinidas.status === 200) {
			setStateProfissoesPredefinidas(retGetProfissoesPredefinidas.data);
			setStateProfissoesPredefinidasMetadata(retGetProfissoesPredefinidas.metadata);
		} else {
			setStateProfissoesPredefinidas([]);
			setStateProfissoesPredefinidasMetadata({});
		}

		setLoadingGetProfissoesPredefinidas(false);
	}

	/*
	** Exibe a pesquisa
	** ================================================== */
	const exibirMatch = dados => {
		var dadosEnviar = {
			area: dados.area_name,
			text: dados.text,
		};

		setStateProfissionais([]);
		_setStateProfissionais({});
		setStateProfissionaisMetadata({});

		getProfissionaisMatchApi(dadosEnviar);
		setStateFiltrosAlgolia(dadosEnviar);
		showPortal({portal: 'match', stateGet, navigate});
	}

	/*
	** API ALGOLIA - Get profissionais match
	** ================================================== */
	const getProfissionaisMatchApi = async (dadosObj = {}) => {
		var dadosEnviar = {
			...stateFiltrosAlgolia,
			...dadosObj,
		};

		var dadosEnviarClear = removerKeysVazias(dadosEnviar);

		if(isNumeric(dadosObj.page)) {
			dadosEnviarClear.page = dadosObj.page;
		}

		delete dadosEnviarClear.step;

		/*
		** API
		** ================================================== */
		setLoadingGetProfissionais(true);

		const retGetProfissionais = await apiAlgolia.post(dadosEnviarClear);

		_setStateProfissionais(msgStatus(retGetProfissionais));

		if(retGetProfissionais.status === 200) {
			setStateProfissionais(retGetProfissionais.data);
			setStateProfissionaisMetadata(retGetProfissionais.metadata);
		} else {
			setStateProfissionais([]);
			setStateProfissionaisMetadata({});
		}

		setLoadingGetProfissionais(false);
	}

	/*
	** API - Alterar status
	** ================================================== */

	/*
	** Alterar status do cliente
	** ================================================== */
	const alterarStatusProfissoesPredefinidas = async dadosObj => {
		if(loadingAlterarStatus === dadosObj.id) {
			msg.warning('Por favor, aguarde...');
			return;
		}

		setLoadingAlterarStatus(dadosObj.id);

		const retUpdateProfissoes = await apiProfessions.update.put({
			id: dadosObj.id,
			active: dadosObj.status,
		});

		if(retUpdateProfissoes.status === 200) {
			msg.success('Status atualizado com sucesso');
			setStateProfissoesPredefinidas([...stateProfissoesPredefinidas.map(item => {
				if(item.id === dadosObj.id) {
					return {
						...item,
						active: dadosObj.status,
					}
				}
				return item;
			})]);
		} else {
			msgs([retUpdateProfissoes]);
		}

		setLoadingAlterarStatus(false);
	}

	/*
	** Exibe o ícone correto do proprietario
	** ================================================== */
	const proprietarioProfissoes = dados => {

		if(!dados.id_company) {
			return (
				<Tip
					titulo="Profissão pública"
					tip={<span className="color-green">Pública</span>}
					info
					_defaults={{
						placement: 'left-center',
					}}
				>
					<div>Todas as empresas tem acesso a essa profissão</div>
				</Tip>
			)
		}

		if(dados.id_company === store.usuario.id_company) {
			return (
				<Tip
					titulo="Profissão própria"
					tip={<span className="color-blue">Própria</span>}
					info
					_defaults={{
						placement: 'left-center',
					}}
				>
					<div>Apenas a empresa <strong>{dados.company_name}</strong> tem acesso a essa profissão.</div>
				</Tip>
			)
		}

		return (
			<Tip
				titulo="Profissão de empresa"
				tip={<span className="">Empresa</span>}
				info
				_defaults={{
					placement: 'left-center',
				}}
			>
				<div>Essa profissão foi cadastrada pela empresa <strong>{dados.company_name}</strong></div>
			</Tip>
		)
	}

	var winPosTooltipEsquerdo = tamanhoTela([
		{width: 980, dados: {placement: 'bottom-center'}},
	], {placement: 'left-center'}, screen);

	return (
		<>
			<div className="pg-profissoes base-full">
				<div className="conteudo-box">
					<div className="titulo-box">
						<div className="flex flex-center-v">
							<Tip
								tip={
									<h3 className="titulo">Configuração do Briefing/Vaga <span className="q-alt"></span></h3>
								}
								info
							>
								Lista de configurações do match para agilizar a proposta de job, preenchendo automaticamente as habilidades, idiomas e competências desses profissionais. Vamos disponibilizar as profissões buscadas com frequência.
							</Tip>
						</div>

						<div className="pg-profissoes-topbar-acoes">
							{stateGet.step === '2' ? (
								<BtnAdd
									img={imgIconeVoltar}
									titulo="Voltar para as listas"
									onClick={() => {
										setGetNavigate({
											gets: {...stateFiltros, step: 1},
											navigate,
											stateGet,
											location,
											uniq: true,
										});
									}}
									className="margin-r-20"
									reverse
								/>
							) : null}
							<Btn
								_={{
									value: 'Cadastrar Configuração do Briefing/Vaga',
									onClick: () => {
										if(stateIsAcessoValido) {
											showPortal({portal: 'cadastro', stateGet, navigate})
										} else {
											msg.warning(MSG_ACESSO_EM_ANALISE_FUNCIONALIDADE_DESATIVADA);
										}
									}
								}}
								size="m"
							/>
						</div>
					</div>

					{stateGet.step === '1' ? (
						<>
							<FiltrosWrap>
								<form
									onSubmit={e => e.preventDefault()}
									className="flex flex-wrap wrap-5"
								>
									<div className="input-box flex-1 box-1-980">
										<Input2
											label="Pesquisar"
											_={{
												value: stateGet.title,
												onChange: e => {
													setGetNavigate({
														gets: {title: e.target.value},
														navigate,
														stateGet,
														location,
													});
												}
											}}
										/>
									</div>
									<div className="input-box flex-1 box-1-980">
										{store.usuario.role === 1 ? (
											<Select2
												label="Empresa"
												_={{
													value: stateGet.id_company,
													onChange: e => {
														setGetNavigate({
															gets: {id_company: e ? e.value : null},
															// forceCamposVazios: {id_company: e ? e.value : null},
															navigate,
															stateGet,
															location,
														});
													},
													options: [
														{value: 'publicas', label: <strong>Profissões públicas</strong>},
														...stateEmpresas
													],
													isClearable: true,
												}}
												loading={loadingGetEmpresas}
											/>
										) : (
											<Select2
												label="Proprietário"
												_={{
													value: stateGet.id_company,
													onChange: e => {
														setGetNavigate({
															gets: {id_company: e ? e.value : null},
															forceCamposVazios: {id_company: e ? e.value : null},
															navigate,
															stateGet,
															location,
														});
													},
													options: [
														{value: store.usuario.id_company, label: 'Próprios'},
														{value: 0, label: 'Públicos'},
													],
													isClearable: true,
												}}
											/>
										)}
									</div>
									<div className="input-box flex-1 box-1-980">
										<Select2
											label="Área"
											_={{
												value: stateGet.id_area,
												onChange: e => {
													setGetNavigate({
														gets: {id_area: e ? e.value : null},
														navigate,
														stateGet,
														location,
													});
												},
												options: stateAreas,
												isClearable: true,
											}}
											loading={loadingGetAreas}
										/>
									</div>
									<div className="input-box flex-1 box-1-980">
										<Select2
											label="Status"
											_={{
												value: stateGet.active,
												onChange: e => {
													setGetNavigate({
														gets: {active: e ? e.value : null},
														forceCamposVazios: {active: e ? e.value : null},
														navigate,
														stateGet,
														location,
													});
												},
												options: STATUS_FILTRO_MIN,
												isClearable: true,
											}}
										/>
									</div>
									<div className="input-box flex-1 input-box-btnlimpar box-1-980">
										<Select2
											label="Resultados por página"
											_={{
												value: stateGet.limit,
												onChange: e => {
													setGetNavigate({
														gets: {limit: e.value},
														navigate,
														stateGet,
														location,
													});
												},
												options: FILTROS_RESULTADOS,
											}}
										/>
										<BtnLimparFiltros
											_={{
												onClick: e => {
													setGetNavigate({
														gets: getsDefault,
														navigate,
														stateGet,
														location,
													});
												},
											}}
										/>
									</div>
								</form>
							</FiltrosWrap>

							{!stateIsAcessoValido && !loadingGetProfissoesPredefinidas ? (
								<AvisoAcessoRestrito {...stateAcesso} />
							) : (
								<div id="tabela-container">
									<TotalPaginacao {...{stateGet, stateMetadata: stateProfissoesPredefinidasMetadata, loading: loadingGetProfissoesPredefinidas, getsDefault}} />
									<div className="table-wrap">
										<table className="tabela">
											<thead className="tabela-titulo-fixo">
												<tr>
													<th className="col-id">
														<Tip info tip="ID">Identificador único</Tip>
													</th>
													<th>
														<Tip info tip="Título">Título exibido na lista</Tip>
													</th>
													<th className="tbl-profpred-col-area-atuacao">
														<Tip info tip="Área">Categoria</Tip>
													</th>
													<th className="tbl-profpred-col-area-atuacao">
														<Tip info tip="Tipo">Se precisa de um profissional ou serviço</Tip>
													</th>
													<th className="tbl-profpred-col-area-atuacao">
														<Tip info tip="Princ. habilidade">Principal habilidade do profissional</Tip>
													</th>
													<th className="tbl-profpred-col-proprietario">
														<Tip info tip="Proprietário">Se a prifissão é pública ou privada</Tip>
													</th>
													<th className="col-min-3">
														<Tip info tip="Exibir">Exibe os mesmos resultados que serão exibidos na busca da proposta</Tip>
													</th>
													<th className="col-min-3">
														<Tip info tip="Tpl.">Se essa profissão tem algum template associado</Tip>
													</th>
													<th className="col-min-3">
														<Tip info tip="Status">Se a lista vai ser exibida ou não durante o cadastro da proposta</Tip>
													</th>
													<th className="col-acao">
														<Tip info tip="Ação">Ação</Tip>
													</th>
												</tr>
											</thead>
											<tbody>
												<PlaceholderRender
													loading={loadingGetProfissoesPredefinidas}
													init={init}
													itens={stateProfissoesPredefinidas}
													request={_stateProfissoesPredefinidas}
													cpnt="PlaceholderProfissoesPredefinidas"
													qtdColunas={10}
												>
												{stateProfissoesPredefinidas.map((val, key) => {
													return (
														<tr key={key}>

															<td data-titulo="ID" className="col-id">
																<div className="td-wrap">{val.id}</div>
															</td>

															<td data-titulo="Título">
																<div className="td-wrap">
																	<div
																		className="a strong"
																		onClick={e => {
																			showPortal({portal: 'detalhes', dados: val.id, stateGet, navigate});
																		}}
																	>{val.title}</div>
																</div>
															</td>

															<td data-titulo="Área">
																<div className="td-wrap align-center">{val.area_name || '-'}</div>
															</td>

															<td data-titulo="Tipo">
																<div className="td-wrap align-center">{matchTipo(val.type)}</div>
															</td>

															<td data-titulo="Palavra-chave">
																<div className="td-wrap">{val.keyword}</div>
															</td>

															<td data-titulo="Proprietário">
																<div className="td-wrap align-center">
																	{proprietarioProfissoes(val)}
																</div>
															</td>

															<td data-titulo="Pesquisar">
																<div className="td-wrap align-center">
																	<div className="a">
																		<Icone
																			imagem={imgIconePesquisa}
																			cor="#555"
																			className="margin-auto cursor-pointer"
																			onClick={e => {
																				exibirMatch({
																					...val,
																					text: val.keyword,
																				});
																			}}
																		/>
																	</div>
																</div>
															</td>

															<td data-titulo="Template">
																<div className="td-wrap align-center">
																	{val.id_template ? (
																		<div className="a" onClick={e => showPortal({portal: 'template', dados: val.id_template, stateGet, navigate})}>
																			<Icone
																				imagem={imgIconeTemplate}
																				cor="#555"
																				className="margin-auto cursor-pointer"
																				onClick={e => {
																					// exibirTemplate(val);
																				}}
																			/>
																			<div className="td-desc-alt cursor-pointer">id: {val.id_template}</div>
																		</div>
																	) : (
																		<>-</>
																	)}
																</div>
															</td>

															<td data-titulo="Status" className="relative">
																<div className="td-wrap scroll-visible align-center">
																	<Tip
																		tip={
																			<div>
																				<Status
																					title="Alterar status"
																					imagem={imgIconeStatus}
																					cor="#555"
																					className="margin-auto cursor-pointer"
																					status={val.active ? 1 : 2}
																				/>
																				<div className="td-desc-alt cursor-pointer">{val.active ? 'Ativo' : 'Inativo'}</div>
																			</div>
																		}
																		click={true}
																		show={loadingAlterarStatus === val.id ? true : false}
																		_defaults={winPosTooltipEsquerdo}
																	>
																		<Select3Lista
																			titulo={<h3 className="margin-0">Status do projeto</h3>}
																			options={[
																				{value: 1, label: 'Status ativo'},
																				{value: 0, label: 'Status inativo'},
																			]}
																			optionsType="radio"
																			optionsChecked={{
																				value: val.active,
																			}}
																			onClick={e => {
																				alterarStatusProfissoesPredefinidas({
																					id: val.id,
																					status: e.value,
																					nomeStatus: e.label,
																					porcentagem: val.percentage_completed,
																				})
																			}}
																			loading={loadingAlterarStatus === val.id ? true : false}
																			labelClass="flex-1"
																			posicao=""
																			classNameDefault=""
																		/>
																	</Tip>
																</div>
															</td>

															<td data-titulo="Ação" className="relative">
																<div className="td-wrap align-center">
																	{!val.id_company && store.usuario.role !== 1 ? (
																		<Icone
																			imagem={imgIconeEditar}
																			cor="#555"
																			className={`margin-auto opct-3`}
																			onClick={() => msg.error('Você não tem permissão para editar essa profissão.')}
																		/>
																	) : (
																		<Icone
																			imagem={imgIconeEditar}
																			cor="#555"
																			className={`margin-auto cursor-pointer`}
																			onClick={() => showPortal({portal: 'edicao', dados: val.id, stateGet, navigate})}
																		/>
																	)}
																</div>
															</td>
														</tr>
													)
												})}
												</PlaceholderRender>
											</tbody>
										</table>
									</div>
									<TotalPaginacao {...{stateGet, stateMetadata: stateProfissoesPredefinidasMetadata, loading: loadingGetProfissoesPredefinidas, getsDefault}} />
								</div>
							)}
						</>
					) : null}
				</div>

				{stateGet.step === '2' ? (
					<ListaProfissionais
						// Rota
						init={init}
						stateGet={stateGet}
						getsDefault={getsDefault}
						navigate={navigate}

						// hide
						showListaLocal={false}
						showAcoes={false}
						showSelect={false}

						// default
						stateProfissionais={stateProfissionais}
						setStateProfissionais={setStateProfissionais}
						_stateProfissionais={_stateProfissionais}
						_setStateProfissionais={_setStateProfissionais}
						stateProfissionaisMetadata={stateProfissionaisMetadata}
						setStateProfissionaisMetadata={setStateProfissionaisMetadata}
						loadingGetProfissionais={loadingGetProfissionais}
						setLoadingGetProfissionais={setLoadingGetProfissionais}

						classNameWrap="margin-t--1"
					/>
				) : null}

				{stateGet.step !== '1' && stateGet.step !== '2' ? (
					<div>Seção não disponível</div>
				) : null}

			</div>

			{stateIsAcessoValido ? (
				<>
					<PortalProfissoesPredefinidasDetalhes
						show={isShowPortal({portal: 'detalhes', stateGet})}
						id={getPortalDados({portal: 'detalhes', stateGet})}
						onClose={() => closePortal({portal: 'detalhes', stateGet, navigate})}
						onSuccess={() => closePortal({portal: 'detalhes', stateGet, navigate})}
						exibirMatch={exibirMatch}
						closeOnEsc={false}
						onClickEditar={e => {
							showPortal({portal: 'edicao', dados: e, stateGet, navigate, remover: 'detalhes'});
						}}
					/>

					<PortalProfissoesPredefinidasFormCadastro
						show={isShowPortal({portal: 'cadastro', stateGet})}
						id={getPortalDados({portal: 'cadastro', stateGet})}
						onClose={() => closePortal({portal: 'cadastro', stateGet, navigate})}
						onSuccess={() => {
							setGetNavigate({
								gets: {...getsSemPortais(gets(), ['cadastro']), step: 1},
								navigate,
								stateGet,
								location,
								uniq: true,
							});
							getProfissoesPredefinidas();
						}}
						exibirMatch={exibirMatch}
						closeOnEsc={false}
					/>

					<PortalProfissoesPredefinidasFormEdicao
						show={isShowPortal({portal: 'edicao', stateGet})}
						id={getPortalDados({portal: 'edicao', stateGet})}
						onClose={() => closePortal({portal: 'edicao', stateGet, navigate})}
						onSuccess={() => {
							closePortal({portal: 'edicao', stateGet, navigate});
							getProfissoesPredefinidas();
						}}
						onDelete={() => {
							getProfissoesPredefinidas(gets());
							closePortal({portal: ['edicao', 'confirmacao-deletar-profissao'], stateGet, navigate});
						}}
						exibirMatch={exibirMatch}
						closeOnEsc={false}
					/>

					<Portal
						titulo="Resultados da busca"
						show={isShowPortal({portal: 'match', stateGet})}
						id={getPortalDados({portal: 'match', stateGet})}
						onClose={() => closePortal({portal: 'match', stateGet, navigate})}
						className="cpnt-funil-overlay"
						x={false}
						closeOnEsc={false}
					>
						<FunilMatch
							metaStatusBusca={_stateProfissionais.status}
							stateProfissionaisMetadata={stateProfissionaisMetadata}
							loading={loadingGetProfissionais}
							textoVoltar="Fechar"
							onClickBack={e => closePortal({portal: 'match', stateGet, navigate})}
							onClickNext={e => {
								setStateFiltros(getsSemPortais(gets(), ['match']));
								setGetNavigate({
									gets: {step: 2},
									navigate,
									stateGet,
									location,
									uniq: true,
								});
							}}
							exibirBtnManual={false}
						/>
					</Portal>

					<PortalTemplateDetalhes
						show={isShowPortal({portal: 'template', stateGet})}
						id={getPortalDados({portal: 'template', stateGet})}
						onClose={() => closePortal({portal: 'template', stateGet, navigate})}
						onSuccess={() => closePortal({portal: 'template', stateGet, navigate})}
						view
					/>
				</>
			) : null}
		</>
	);
};

export default ProfissoesPredefinidas;