import React from 'react';
import { apenasNumeros } from '@func/utils';

// CSS
import './ContabilizarPaginas.css';
import Tip from "@cpnt/Tip/Tip";

const ContabilizarPaginas = props => {
	var {
		className = '',
		wrap = '',

		paginaAtual = 0,
		limite = 0,
		qtdPaginas = 0,
		total = 0,
		algolia,
		resultados,
	} = props;

	const contabilizar = () => {
		paginaAtual = apenasNumeros(paginaAtual, true);
		limite = apenasNumeros(limite, true);
		total = apenasNumeros(total, true);

		var conta1 = ((paginaAtual-1)*limite)+1;
		var conta2 = ((paginaAtual-1)*limite)+limite;

		if(paginaAtual === qtdPaginas) {
			conta2 = total;
		}

		return <div>Exibindo {conta1}-{conta2} de {total || conta2} resultados</div>;
	}

	const divergenciaAlgolia = () => {
		if(algolia) {
			if((resultados < Number(limite)) && (paginaAtual < qtdPaginas)) {
				return (
					<Tip
						tip={
							<div><span className="cpnt-contabilizarpg-sep color-3">|</span>divergência<span className="q"></span></div>
						}
						info
						classNameTipDefault="cpnt-contabilizarpg-tip"
					>
						<div>Os perfis incompletos foram removidos do resultado, por isso você não está visualizando exatamente <strong>{limite} resultados</strong> por página.</div>
						<div className="margin-t-10">Para visualizar todos os resultados, selecione "<strong>Exibir perfis incompletos</strong>" nas opções dos filtros.</div>
					</Tip>
				)
			}
		}
		return <></>
	}

	return (
		<div className={`cpnt-contabilizarpg ${wrap}`}>
			<div className={`cpnt-contabilizarpg-box ${className}`}>
				{contabilizar()}
				{divergenciaAlgolia()}
			</div>
		</div>
	);
};

export default ContabilizarPaginas;