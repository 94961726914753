import React from 'react';
import { Link } from 'react-router-dom';

// CSS
import './Erro404Deslogado.css';

// Imagens
import logo from '@img/logo-single-preto.png';
import imgLogo from '@img/logo.png';
import bg from '@img/bg/bg.png';

const Erro404Deslogado = props => {

	return (
		<div style={{backgroundImage: `url(${bg})`}} className="erro-404-container tela-inicio-bg-animado-top-left">
			<div className="container-center">
				<div className="container-center-wrap">
					<div className="container-center-inner">
						<div className="erro-404-wrap">
							<div className="tela-inicio-e-logo padding-t-30 padding-b-30 margin-auto">
								<img src={imgLogo} alt="Logo CROWD" className="block w-100p" />
							</div>
							<h1 className="erro-404-titulo">Erro 404</h1>
							<p className="erro-404-desc">A página que você está procurando não existe.</p>
							<div>
								<Link to="/" className="fs-14 no-decoration bold">Voltar para o início</Link>
							</div>
							<div className="margin-t-15">
								<Link to="/logout" className="fs-14 no-decoration bold">Fazer logout</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Erro404Deslogado;