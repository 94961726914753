import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './ChatMensagemPersonalizada.css';

// Imagens
import imgIconeBtn from '@svg/solid/circle-plus.svg';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Input2 from '@cpnt/Input2/Input2';
import { Select2 } from '@cpnt/Select2/Select2';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';
import ChatMensagem from '../ChatMensagem/ChatMensagem';

// Funções
import { caminhoFoto, camposInvalidos, conveterRealParaNumero } from '@func/utils';

// Temp
import { apiGetTemplatesPorTipo } from "@api/_functions/geral";
import { CHAT_MSGS_TIPO_CORES } from "@config/objetos";
import { _tempChatTemplatesFreelancer } from './_tempChatTemplates.js';

const ChatMensagemPersonalizada = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
		wrap = '',
		setShowPortal,
		enviarMensagem,
		setChatBloqueado,
		setChatBloqueadoMotivo,
		tipo,
		layoutExibicao,

		taxaPorcentagem = 0,
	} = props;

	const [stateTemplatesPorTipo, setStateTemplatesPorTipo] = useState([]);
	const [loadingGetTemplatesPorTipo, setLoadingGetTemplatesPorTipo] = useState(false);

	useEffect(() => {
		if(layoutExibicao === 'usuario') {
			apiGetTemplatesPorTipo({idTipo: 6, setLoadingGetTemplatesPorTipo, setStateTemplatesPorTipo});
		}
	}, []);

	/*
	** States
	** ================================================== */
	var templateDefault = {metadata: {type: 'default'}};
	var templateDefaultId;

	if(store.usuario.role === 2) {
		templateDefaultId = 1;
		templateDefault = _.find(_tempChatTemplatesFreelancer, {id: templateDefaultId});
	}

	const [stateTemplate, setStateTemplate] = useState(templateDefaultId);
	const [stateMetadataTitulo, setStateMetadataTitulo] = useState(templateDefault.metadata.titulo);
	const [stateMensagem, setStateMensagem] = useState(templateDefault.mensagem);
	const [stateType, setStateType] = useState(templateDefault.type);
	const [stateMetadataValor, setStateMetadataValor] = useState(templateDefault.metadata.valor);
	const [stateMetadataValorPorcentagem, setStateMetadataValorPorcentagem] = useState(0);
	const [stateMetadataDescricao, setStateMetadataDescricao] = useState(templateDefault.metadata.descricao);
	const [stateMetadataPrazo, setStateMetadataPrazo] = useState(templateDefault.metadata.prazo);
	const [stateMetadataBotoes, setStateMetadataBotoes] = useState(templateDefault.metadata.botoes);
	const [stateMetadataParcelas, setStateMetadataParcelas] = useState(templateDefault.metadata.parcelas || false);
	const [stateChatBloqueado, setStateChatBloqueado] = useState(false);

	/*
	** Envia a mensagem personalizada
	** ================================================== */
	const enviarMensagemPersonalizada = () => {
		const dadosEnviar = {
			mensagem: stateMensagem,
			name: store.usuario.name,
			photo: caminhoFoto(store.usuario.photo).url,
			msgBy: tipo,
			type: stateType,
			metadata: {
				titulo: stateMetadataTitulo,
				valor: stateMetadataValor,
				descricao: stateMetadataDescricao,
				botoes: stateMetadataBotoes,
			},
		};

		if(camposInvalidos(dadosEnviar, {
			mensagem: dadosEnviar.mensagem,
			titulo: dadosEnviar.metadata.titulo,
			descricao: dadosEnviar.metadata.descricao,
		})) return;

		enviarMensagem(dadosEnviar);

		// Bloquear chat
		// if(stateTemplate === 8) {
		// 	setChatBloqueado(true);
		// 	setChatBloqueadoMotivo(stateMensagem);
		// } else if(stateTemplate === 9) {
		// 	setChatBloqueado(false);
		// } else if(stateChatBloqueado) {
		// 	setChatBloqueado(true);
		// }

		// Bloquear chat
		if(stateTemplate === 8 || (stateTemplate !== 8 && stateChatBloqueado)) {
			setChatBloqueado(true);
			setChatBloqueadoMotivo(stateTemplate === 8 ? stateMensagem : 'Você recebeu uma mensagem que requer uma resposta, responda para continuar.');
		} else if(stateTemplate === 9) {
			setChatBloqueado(false);
		}

		setShowPortal(false);
	}

	const adicionarBotao = () => {
		var botoesAtuais = [...stateMetadataBotoes];
		botoesAtuais.push({
			tipo: 'preto',
			texto: 'Texto',
		});
		setStateMetadataBotoes(botoesAtuais);
	}

	return (
		<div className={`cpnt-chatmsgcustom ${wrap}`}>
			<div className={`cpnt-chatmsgcustom-box ${className}`}>

				<div className="cpnt-chatmsgcustom-templates-wrap">
					{layoutExibicao === 'usuario' ? (
						<></>
					) : (
						<>
							<div className="color-1 fs-13 padding-b-20">Os dados preenchidos abaixo são apenas exemplos, preencha de acordo com sua necessidade.</div>
						</>
					)}
				</div>

				<div className="cpnt-chatmsgcustom-dados-preview">
					<div className="cpnt-chatmsgcustom-dados-mensagem-box">
						<h3 className="margin-0">Dados da mensagem</h3>
						<div className="cpnt-chatmsgcustom-dados-mensagem">
							<div className="wrap-5">
								{layoutExibicao === 'usuario' ? (
									<>
										<div className="input-box">
											<Select2
												label="Template"
												_={{
													options: stateTemplatesPorTipo,
													onChange: e => {
														setStateMetadataTitulo(e ? e.metadata.title : '');
														setStateMensagem(e ? e.text : '');
														setStateType(e ? e.metadata.type : 'default');
													},
													isClearable: true,
												}}
												loading={loadingGetTemplatesPorTipo}
											/>
										</div>
										<div className="input-box">
											<Select2
												label="Cor"
												_={{
													value: stateType,
													options: CHAT_MSGS_TIPO_CORES,
													onChange: e => {
														setStateType(e.value);
													}
												}}
											/>
										</div>
									</>
								) : null}
								<div className="input-box">
									<Input2
										label="Título da mensagem"
										_={{
											value: stateMetadataTitulo,
											onChange: e => {
												setStateMetadataTitulo(e.target.value);
											}
										}}
									/>
								</div>
								<div className="input-box">
									<Input2
										label="Mensagem"
										_={{
											type: 'textarea',
											value: stateMensagem,
											onChange: e => {
												setStateMensagem(e.target.value);
											}
										}}
									/>
								</div>

								{stateTemplate === 1 ? (
									<>
										<div className="input-box">
											<Input2
												label="Valor total"
												_={{
													value: stateMetadataValor,
													onChange: e => {
														var valorNumero = conveterRealParaNumero(e);
														setStateMetadataValor(valorNumero);
														var valorComPorcentagem = ((taxaPorcentagem / 100) * valorNumero).toFixed(2);
														setStateMetadataValorPorcentagem(valorComPorcentagem);
													}
												}}
												dinheiro
											/>
										</div>
										<div className="input-box">
											<Input2
												label="Dias"
												_={{
													mask: '999',
													maskPlaceholder: null,
													value: stateMetadataDescricao,
													onChange: e => {
														setStateMetadataDescricao(e.target.value);
														setStateMetadataPrazo(Number(e.target.value));
													}
												}}
											/>
										</div>
									</>
								) : null}
							</div>

							{stateTemplate === 3 ? (
								<div className="cpnt-chatmsg-btns-box">
									<div className="flex flex-center-v">
										<h3 className="margin-0">Botões</h3>
										<div className="margin-l-15">
											<TextoIconeLink
												icone={imgIconeBtn}
												iconeTamanho="16px"
												iconeCor="#2596e1"
												texto="Adicionar botão"
												className="fs-14 bold color-blue-hover cursor-pointer transition"
												onClick={() => {
													adicionarBotao();
												}}
											/>
										</div>
									</div>
									<div className="wrap-5">
										<div className="flex flex-column margin-t-10">
											{!stateMetadataBotoes.length ? (
												<div className="margin-h-5 fs-16">Nenhum botão adicionado</div>
											) : (
												stateMetadataBotoes.map((val, key) => {
													return (
														<div className="cpnt-chatmsg-btns-item flex w-100p relative">
															<div
																className="x x-small"
																onClick={() => {
																	setStateMetadataBotoes([...stateMetadataBotoes.filter(item => item !== val)]);
																}}
															></div>
															<div className="input-box flex-1">
																<Input2
																	label="Texto"
																	_={{
																		value: val.texto,
																		placeholder: 'Texto do botão',
																		onChange: e => {
																			setStateMetadataBotoes([...stateMetadataBotoes.map((val2, key) => {
																				if(val === val2) {
																					return {
																						...val,
																						texto: e.target.value,
																					}
																				}
																				return val2;
																			})]);
																		}
																	}}
																/>
															</div>
															<div className="input-box w-150">
																<Select2
																	label="Cor do botão"
																	_={{
																		value: val.tipo,
																		options: [
																			{label: 'Verde', value: 'verde'},
																			{label: 'Vermelho', value: 'vermelho'},
																			{label: 'Preto', value: 'preto'},
																		],
																		onChange: e => {
																			setStateMetadataBotoes([...stateMetadataBotoes.map((val2, key) => {
																				if(val === val2) {
																					return {
																						...val,
																						tipo: e.value
																					}
																				}
																				return val2;
																			})]);
																		}
																	}}
																	wrap="flex-1"
																/>
															</div>
														</div>
													)
												})
											)}
										</div>
									</div>
								</div>
							) : null}

						</div>
					</div>

					<div className="cpnt-chatmsgcustom-preview-box">
						<h3 className="margin-0">Pré visualização</h3>
						<div className="cpnt-chatmsgcustom-preview">
							{stateTemplate === 1 ? (
								<ChatMensagem
									{...{
										data: moment().subtract(0, 'minutes').format('HH:mm'),
										mensagem: stateMensagem && stateMensagem !== '' ? stateMensagem : '(mensagem não informada)',
										metadata: {
											titulo: stateMetadataTitulo && stateMetadataTitulo !== '' ? stateMetadataTitulo : '(título não informado)',
											valor: `R$ ${stateMetadataValor} (${stateMetadataValorPorcentagem})`,
											descricao: `${stateMetadataDescricao} dias`,
											botoes: stateMetadataBotoes,
											proposta: {
												prazo: stateMetadataPrazo,
												valorSemPorcentagem: stateMetadataValor,
												valorComPorcentagem: (stateMetadataValor + stateMetadataValorPorcentagem),
												valorPorcentagem: stateMetadataValorPorcentagem,
											}
										},
										name: store.usuario.name,
										photo: caminhoFoto(store.usuario.photo).url,
										msgBy: 'hunter',
										type: stateType,
										propria: false,
									}}
								/>
							) : (
								<ChatMensagem
									{...{
										data: moment().subtract(0, 'minutes').format('HH:mm'),
										mensagem: stateMensagem && stateMensagem !== '' ? stateMensagem : '(mensagem não informada)',
										metadata: {
											titulo: stateMetadataTitulo && stateMetadataTitulo !== '' ? stateMetadataTitulo : '(título não informado)',
											valor: stateMetadataValor,
											descricao: stateMetadataDescricao,
											botoes: stateMetadataBotoes,
											parcelas: stateMetadataParcelas,
										},
										name: store.usuario.name,
										photo: caminhoFoto(store.usuario.photo).url,
										msgBy: 'hunter',
										type: stateType,
										propria: false,
									}}
								/>
							)}
						</div>

						{stateTemplate === 8 ? (
							<div className="cpnt-chatmsgcustom-info-footer fs-16">O chat será <strong className="color-red">bloqueado</strong>, independentemente da mensagem inserida.</div>
						) : null}

						{stateTemplate === 9 ? (
							<div className="cpnt-chatmsgcustom-info-footer fs-16">O chat será <strong className="color-green">desbloqueado</strong>, independentemente da mensagem inserida.</div>
						) : null}

					</div>
				</div>

				<div className="form-bottom">
					<Button2
						_={{
							value: 'Cancelar',
							className: 'btn btn-1',
							onClick: () => setShowPortal(false),
						}}
					/>
					<Button2
						_={{
							value: 'Enviar mensagem',
							onClick: enviarMensagemPersonalizada,
						}}
						wrap="margin-l-10"
					/>
				</div>

			</div>
		</div>
	);
};

export default ChatMensagemPersonalizada;