import { validarRetorno, config } from '@_api/axios';
import api from 'axios';
import qs from 'qs';

const apiCompanyLite = {
	validation: {
		post: async dados => {
			return await api.post(`/companyLite/validation`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	insert: {
		post: async dados => {
			return await api.post(`/companyLite/insert`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	update: {
		put: async dados => {
			return await api.put(`/companyLite/update`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	activate: {
		get: async dados => {
			// var dadosEnviar = qs.stringify(dados, {encode: false});
			return await api.get(`/companyLite/activate/${dados}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	professions: {
		get: async dados => {
			var dadosEnviar = qs.stringify(dados, {encode: false});
			return await api.get(`/companyLite/professions?${dadosEnviar}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	templates: {
		get: async dadosObj => {
			var dadosEnviar = qs.stringify(dadosObj, { encode: false });
			return await api.get(`/companyLite/templates?${dadosEnviar}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	briefingInsert: {
		post: async dados => {
			return await api.post(`/companyLite/briefingInsert`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	delete: async id => {
		return await api.delete(`/companyLite/${id}`, config())
		.then(retorno => {
			return validarRetorno(retorno);
		})
		.catch(retorno => {
			return validarRetorno(retorno);
		});
	}
};

export { apiCompanyLite };