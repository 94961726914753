import React from 'react';

// Configs
import { DEV, URL_BLOG, URL_CROWD_ACADEMY } from '@_config/config';

/*
** Sidebar Freelancer
** ================================================== */
const sidebarFreelancerPadrao = {
	menu: [
		{
			title: 'Contratações',
			itens: [
				{
					title: 'Página inicial',
					path: '/inicio',
					icon: 'solid/house.svg',
				},
				{
					title: 'Meu Perfil',
					path: '/perfil',
					icon: 'solid/user-large.svg',
				},
				{
					title: 'Propostas de jobs',
					path: '/jobs',
					icon: 'match.svg',
					custom: true,
				},
				{
					title: 'Meus jobs',
					path: '/meus-jobs',
					icon: 'solid/shield-halved.svg',
				},
			]
		},
		{
			title: 'Outros',
			itens: [

				{
					title: 'Academy',
					path: URL_CROWD_ACADEMY,
					icon: 'solid/book-open.svg',
				},
				{
					title: 'Blog',
					path: URL_BLOG,
					icon: 'solid/newspaper.svg',
				},
				{
					title: 'Termos de uso',
					path: '/perfil?secao=termos',
					icon: 'regular/file-lines.svg',
				},
			]
		},
		{
			title: '',
			itens: []
		},
		{
			title: '',
			itens: [
				{
					title: 'Central de ajuda',
					path: '/perfil?secao=ajuda',
					icon: 'solid/circle-question.svg',
				},
				{
					title: 'Logout',
					path: '/logout',
					icon: 'solid/right-from-bracket.svg',
				},
			]
		},
	],
	btn: {
		title: 'Possui Agência?',
		path: 'https://crowd.br.com/recrutamento-e-selecao/',
	}
};

/*
** Sidebar Freelancer (Perfil não finalizado)
** ================================================== */
const sidebarFreelancerNaoFinalizado = {
	menu: [
		{
			title: 'Início',
			itens: [
				{
					title: 'Perfil',
					path: '/perfil',
					icon: 'solid/radar.svg',
				},
			]
		},
		{
			title: <>
				<div className="color-1 bold">Importante:</div>
				<div className="no-case margin-t-10">
					Finalize todas as etapas do seu perfil para liberar o menu. Fazendo isso, você pode receber propostas para os jobs.
				</div>
			</>,
		},
		{
			title: '',
			itens: [
				{
					title: 'Logout',
					path: '/logout',
					icon: 'solid/right-from-bracket.svg',
				},
			]
		},
	],
	btn: {
		title: 'Possui Agência?',
		path: 'https://crowd.br.com/recrutamento-e-selecao/',
	}
};

const sidebarFreelancer = {
	padrao: sidebarFreelancerPadrao,
	naoFinalizado: sidebarFreelancerNaoFinalizado
};

export { sidebarFreelancer };
