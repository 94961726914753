import { validarRetorno, config } from '@_api/axios';
import api from 'axios';
import qs from 'qs';

const apiBriefings = {
	match: {
		post: async dados => {
			return await api.post(`/briefings/match`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export { apiBriefings };