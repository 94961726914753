import React, { useContext, useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalEmailAcessoFormEdicao.css';

// API
import apiFreelancer from "@api/freelancer/freelancer";

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import apiRedefinition from "@api/redefinition/redefinition";
import Info2 from "@cpnt/Info2/Info2";
import Input2 from "@cpnt/Input2/Input2";

// Funções
import { salvarSessaoLocal } from "@_func/auth";
import { camposInvalidos,  validarEmail } from '@func/utils';
import { api } from "@_api/api";
import { msgs, msgStatus } from "@_func/request";

const PortalEmailAcessoFormEdicao = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		id,
		idFreelancer,
		tipo = 'freelancer',
		desc,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-dispremun-form-edicao',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	/*
	** States
	** ================================================== */
	const [_stateDados, _setStateDados] = useState({});
	const [stateShowConfirmacaoToken, setStateShowConfirmacaoToken] = useState(false);
	const [stateEmail, setStateEmail] = useState('');
	const [stateEmailAntigo, setStateEmailAntigo] = useState('');
	const [stateEmailNovo, setStateEmailNovo] = useState('');
	const [stateToken, setStateToken] = useState('');
	const [stateFinalizado, setStateFinalizado] = useState(false);

	// Edição
	const [loadingAlterar, setLoadingAlterar] = useState(false);
	const [loadingConfirmarAlteracao, setLoadingConfirmarAlteracao] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setLoading(true);

			setStateShowConfirmacaoToken(false);
			setStateEmail('');
			setStateEmailAntigo('');
			setStateEmailNovo('');
			setStateToken('');
			setStateFinalizado(false);

			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		setLoading(true);

		var retGetDados;

		if(tipo === 'freelancer') {
			retGetDados = await apiFreelancer.getOne.get(idFreelancer);
		} else if(tipo === 'usuario') {
			retGetDados = await api.users.getOne.get(id);
		}
		_setStateDados(msgStatus(retGetDados));

		if(retGetDados.status === 200) {
			setStateEmailAntigo(retGetDados.data.email);
		} else {
			msgs([retGetDados]);
		}

		setLoading(false);
	}

	const alterarEmail = async () => {
		if(loadingAlterar) {
			msg.warning('Por favor, aguarde...');
		}

		const dadosEnviar = {
			id_user: id,
			email: stateEmail,
		};

		if(camposInvalidos(dadosEnviar)) return;

		if(!validarEmail(dadosEnviar.email)) return;

		setLoadingAlterar(true);

		// const retUpdateEmail = {status: 200, msg: 'Enviado token'};
		const retUpdateEmail = await apiRedefinition.user.email.verification.put(dadosEnviar);

		if(retUpdateEmail.status === 200) {
			msg.success(
				<>
					<div>Foi enviado um código de confirmação para o e-mail:</div>
					<div className="margin-t-10"><strong>{stateEmail}</strong></div>
					<div className="margin-t-10">Verifique seu e-mail.</div>
				</>
			);
			setStateShowConfirmacaoToken(true);
			setStateEmailNovo(dadosEnviar.email);
		} else {
			msgs([retUpdateEmail]);
		}

		setLoadingAlterar(false);
	}

	/*
	** Editar dados da empresa
	** ================================================== */
	const confirmarAlteracao = async e => {
		e.preventDefault();

		if(loadingConfirmarAlteracao) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			id_user: id,
			email: stateEmailNovo,
			hash: stateToken,
		};

		if(camposInvalidos(dadosEnviar)) return;

		setLoadingConfirmarAlteracao(true);

		const retAlteracaoEmail = await apiRedefinition.user.email.redefine.put(dadosEnviar);

		if(retAlteracaoEmail.status === 200) {
			msgs([retAlteracaoEmail], true);
			salvarSessaoLocal({
				...store,
				usuario: {
					...store.usuario,
					email: stateEmailNovo,
				},
			});

			setStore({
				...store,
				usuario: {
					...store.usuario,
					email: stateEmailNovo,
				}
			});

			msg.success('E-mail alterado com sucesso.');
			onSuccess({
				ret: retAlteracaoEmail,
				dados: stateEmailNovo,
			});
			setStateFinalizado(true);

		} else {
			msgs([retAlteracaoEmail]);
			onError({
				ret: retAlteracaoEmail,
			});
		}

		setLoadingConfirmarAlteracao(false);
	}

	return (
		<Portal
			titulo="Alterar e-mail de acesso a plataforma"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<>
								{stateFinalizado ? (
									<>
										<Info
											icone="sucesso"
											texto={
												<>
													<h3>E-mail alterado com sucesso!</h3>
													<div>A partir de agora, utilize o e-mail <strong>{stateEmailNovo}</strong> para acessar a plataforma.</div>
												</>
											}
										/>
										<div className="form-bottom">
											<Button2
												_={{
													value: 'Fechar',
													onClick: onClose,
												}}
											/>
										</div>
									</>
								) : (
									<>
										{desc && <div className="desc">{desc}</div>}
										<div className="margin-t-10">Email atual: <strong>{stateEmailAntigo}</strong></div>
										<div className="wrap-5 margin-t-10">
											<div className="flex flex-wrap">
												<div className="input-box flex-1 box-1-580">
													<Input2
														label="Novo e-mail"
														req
														_={{
															value: stateEmail,
															onChange: e => {
																setStateEmail(e.target.value);
															},
															...(stateEmailNovo && stateEmailNovo !== '' ? {disabled: true} : {})
														}}
													/>
												</div>
												<div className="input-box flex box-1-580">
													<Button2
														_={{
															value: 'Alterar e-mail',
															onClick: alterarEmail,
															...(stateShowConfirmacaoToken ? {disabled: true} : {})
														}}
														loading={loadingAlterar}
														wrap="w-100p"
														classNameExtra="w-100p"
													/>
												</div>
											</div>
										</div>

										{stateShowConfirmacaoToken ? (
											<div>
												<div className="msg-alt msg-alt3-info margin-t-30">
													<Info2
														titulo="Verifique seu e-mail"
														desc={
															<>Enviamos um token de confirmação para o seu <strong>novo</strong> e-mail.</>
														}
														icone="info"
														iconeCor="#0277BD"
													/>
												</div>
												<div className="wrap-5 margin-t-10">
													<div className="flex flex-wrap">
														<div className="input-box flex-1 box-1-580">
															<Input2
																label="Token de confirmação"
																req
																_={{
																	value: stateToken,
																	onChange: e => {
																		setStateToken(e.target.value);
																	},
																}}
															/>
														</div>
														<div className="input-box flex box-1-580">
															<Button2
																_={{
																	value: 'Confirmar alteração',
																	onClick: confirmarAlteracao,
																}}
																loading={loadingConfirmarAlteracao}
																wrap="w-100p"
																classNameExtra="w-100p"
															/>
														</div>
													</div>
												</div>
											</div>
										) : null}
										<div className="form-bottom">
											<Button2
												_={{
													value: 'Cancelar',
													className: 'btn btn-1',
													onClick: onClose,
												}}
											/>
										</div>
									</>
								)}

							</>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export { PortalEmailAcessoFormEdicao };
