import React from 'react';

// CSS
import { HomeScreen } from "./HomeScreen/HomeScreen";

// Img
import imgIconeMatch from '@_svg/match.svg';
import imgIconeBlog from '@fa/solid/newspaper.svg';
import imgIconeContratacoes from '@fa/solid/shield-halved.svg';
import imgIconePremium from '@fa/regular/star.svg';

// URLs
import { URL_BLOG, URL_SITE } from "@_config/config";

export const HomeFreeScreen = () => {
	return (
		<HomeScreen
			boxes={[
				{
					icone: imgIconeMatch,
					titulo: 'Briefing/Vaga',
					desc: 'Publique e nossa IA faz o match',
					link: '/briefing',
				},
				{
					icone: imgIconeContratacoes,
					titulo: 'Contratações',
					desc: 'Acesse suas contratações',
					link: '/contratacoes',
				},
				{
					icone: imgIconeBlog,
					titulo: 'Blog',
					desc: 'Dicas em marketing e tecnologia',
					link: URL_BLOG,
				},
				{
					icone: imgIconePremium,
					titulo: 'Upgrade Premium',
					desc: 'CLT e PJ em 4x menos tempo e mais',
					link: 'https://crowd.br.com/recrutamento-e-selecao/',
				},
			]}
		/>
	);
}
