import React, { useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';
import Erro404Logado from "@page/Logado/Erro404Logado/Erro404Logado";

export const VideosCrowder = props => {
	const [store, setStore] = useContext(StoreContext);

	if(store.usuario.id_company !== 1) {
		return <Erro404Logado />
	}

	return (
		<div className="w-100p flex">
			<iframe title="academy" src="https://academy.crowd.br.com/crowder" className="w-100p"></iframe>
		</div>
	);
};
