import React from 'react';

// css
import './Foto2.css';

// Imagens
import imgUserPicDefault from '@img/userpic.png';

// Componentes
import Tip from '@cpnt/Tip/Tip';
import { cssBg, cssTamanho } from '@func/utils';

const Foto2 = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		img,
		preview = true,
		classNameImg = 'foto-wrap',
		classNameImgBox = '',
		classNameBg = 'bg',
		tipTitulo,
		tamanho = '40px',
		onClick,
		_tipDefaults = {
			placement: 'right-center',
		},
	} = props;

	var {
		placeholder = 'userpicture',
		imgPreview,
	} = props;

	if(placeholder) {
		switch (placeholder) {
			case 'userpicture': placeholder = imgUserPicDefault; break;
			case 'blank': placeholder = ''; break;
			default: placeholder = ''; break;
		}
	}

	if(!imgPreview) {
		imgPreview = img;
	}

	return (
		<>
			<div className={`cpnt-foto2 ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-foto2-box ${className}`}>
				{img ? (
					preview ? (
						<Tip
							tip={
								<div className={`cpnt-foto2-foto-box ${classNameImgBox}`} style={cssTamanho(tamanho)} onClick={onClick}>
									<div className={`cpnt-foto2-img fill ${classNameBg} ${classNameImg}`} style={cssBg(img)}>
										{placeholder && <div className={`cpnt-foto2-img-placeholder fill-bg ${classNameBg}`} style={cssBg(placeholder)}></div>}
									</div>
								</div>
							}
							closeOnLeave
							_defaults={_tipDefaults}
							_={{
								delayLeave: 0,
							}}
							{...(tipTitulo ? {titulo: tipTitulo} : {})}
						>
							<div className="cpnt-foto2-foto-preview-box">
								<div className={`cpnt-foto2-foto-preview ratio-1 ${classNameBg}`} style={cssBg(imgPreview)}>
									{placeholder && <div className={`cpnt-foto2-img-placeholder fill-bg ${classNameBg}`} style={cssBg(placeholder)}></div>}
								</div>
							</div>
						</Tip>
					) : (
						<div className={`cpnt-foto2-foto-box ${classNameImgBox}`} style={cssTamanho(tamanho)} onClick={onClick}>
							<div className={`cpnt-foto2-img fill ${classNameBg} ${classNameImg}`} style={cssBg(img)}>
								{placeholder && <div className={`cpnt-foto2-img-placeholder fill-bg ${classNameBg}`} style={cssBg(placeholder)}></div>}
							</div>
						</div>
					)
				) : null}
				</div>
			</div>
		</>
	);
};

export { Foto2 };