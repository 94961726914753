import React from 'react';
import ReactDiffViewer from 'react-diff-viewer';

// css
import './DiffViewer.css'

// Funções
import { htmlToJsx } from '@func/utils';

const DiffViewer = props => {
	var {
		className = '',
		wrap = '',
		pre,
		_,
	} = props;

	if(!_) {
		_ = {};
	}

	var campos = {
		oldValue: '',
		newValue: '',
		renderContent: e => htmlToJsx(e),
		leftTitle: false,
		rightTitle: false,
		splitView: true,
		showDiffOnly: false,
		disableWordDiff: false,
		hideLineNumbers: true,
		compareMethod: 'diffWordsWithSpace', // diffChars, diffWords, diffWordsWithSpace, diffLines, diffTrimmedLines, diffSentences, diffCss
		..._,
	};

	return (
		<div className={`cpnt-diffviewer ${wrap} ${pre ? 'cpnt-diffviewer-pre' : ''}`}>
			<div className={`cpnt-diffviewer-box ${className}`}>
				<ReactDiffViewer
					{...campos}
				/>
			</div>
		</div>
	);
};

export default DiffViewer;