import React, { useState } from 'react';

// Imagens
import imgIconeDoubleCheck from '@svg/outros/doublecheck.svg';
import imgIconePDF from '@svg/solid/file-pdf.svg';
import imgIconeXML from '@svg/solid/file-code.svg';
import imgContrato from '@img/outros/contrato-assinar.png';
import imgIconeCancelado from '@svg/solid/circle-x.svg';
import imgIconeAprovado from '@svg/solid/circle-check.svg';
import imgIconeContrato from '@svg/solid/file-signature.svg';

// Componentes
import Icone from '@cpnt/Icone/Icone';
import LinhaItem from '@cpnt/LinhaItem/LinhaItem';

// Funções
import { caminhoUrl, dataApiParaBrExtenso, dataHaQuantoTempo } from '@func/utils';
import Foto from '@cpnt/Foto/Foto';
import Button2 from '@cpnt/Button2/Button2';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';
import Portal from '@cpnt/Portal/Portal';
import textoTermosDeUso from '@texto/textoTermosDeUso';
import Input2 from '@cpnt/Input2/Input2';
import { primeiroNome } from "@_func/regex";

const ChatMensagemContrato = props => {
	const {
		metadata = {},
		wrap = '',
		className = '',
		mensagem,
		photo,
		dataExtenso,
		propria,
		name,
		tipo,
		mensagemLida,
		params = {},
		enviarMensagem = () => null,
	} = props;

	const [showPortalTermos, setShowPortalTermos] = useState(false);
	const [showPortalContrato, setShowPortalContrato] = useState(false);

	return (
		<div className={`cpnt-chatmsg cpnt-chatmsg-posicao-${propria ? 'right' : 'left'} cpnt-chatmsg-tipo-proposta ${wrap}`}>
			<div className={`cpnt-chatmsg-user cpnt-chatmsg-user-${propria ? 'right' : 'left'}`}>
				<div>
					<Foto
						imagem={photo}
					/>
				</div>
				{name && name !== '' ? (
					<div className="cpnt-chatmsg-user-name max-w-100p">
						<div className="text-one-line">
							{primeiroNome(name)}
						</div>
					</div>
				) : null}
			</div>
			<div className={`cpnt-chatmsg-mensagem-box cpnt-chatmsg-mensagem-box-${propria ? 'right' : 'left'} ${className}`}>
				<div className={`cpnt-chatmsg-custom-titulo cpnt-chatmsg-custom-titulo-black`}>
					<h3 className="margin-0 padding-0">{metadata.titulo || 'Contrato'}</h3>
					<div className="fs-14 color-white">{name} enviou um contrato</div>
				</div>
				<div className="cpnt-chatmsg-alerta">
					<h3 className="cpnt-chatmsg-alerta-titulo">Importante</h3>
					<div>O contrato tem validade jurídica, leia com atenção.</div>
				</div>
				<div className="cpnt-chatmsg-mensagem-contrato">
					<div className="cpnt-chatmsg-mensagem-infocontrato">
						<div className="cpnt-chatmsg-mensagem-infocontrato-info">
							<div>
								<LinhaItem
									titulo="Enviado em"
									desc={dataApiParaBrExtenso(props.created_at)}
									alt
								/>
								<LinhaItem
									titulo="Enviado há"
									desc={dataHaQuantoTempo(props.created_at)}
									alt
								/>
								<LinhaItem
									titulo="Expira em"
									desc="2 horas"
									alt
								/>
								<LinhaItem
									titulo="ID do contrato"
									desc={"8FJ2F3XGW"}
									alt
								/>
							</div>
							<div className="cpnt-chatmsg-mensagem-infocontrato-btn">
								<Button2
									_={{
										value: <TextoIconeLink texto="Visualizar contrato" icone={imgIconeContrato} iconeCor="#FFF" />,
										onClick: () => setShowPortalContrato(true)
									}}
								/>
							</div>
						</div>
						<div className="cpnt-chatmsg-mensagem-infocontrato-img">
							<img src={imgContrato} alt="contrato" className="block w-100p" />
						</div>
					</div>
				</div>

				{/* Botões */}
				<div className="cpnt-chatmsg-botoes relative">
					{params.contracted || params.refused ? (
						<div className={`fill-center z-index-1 strong ${params.contracted ? 'bg-green-light' : 'bg-red-light'}`}>
							{params.contracted ? (
								<div className="flex flex-center">
									<Icone
										imagem={imgIconeAprovado}
										cor="#229d3e"
										tamanho="16px"
									/>
									<span className="color-green margin-l-5">Proposta aceita</span>
								</div>
							) : (
								<div className="flex flex-center">
									<Icone
										imagem={imgIconeCancelado}
										cor="#E74C3C"
										tamanho="16px"
									/>
									<span className="color-red margin-l-5">Proposta recusada</span>
								</div>
							)}
						</div>
					) : null}
					<div
						className="cpnt-chatmsg-botao btn btn-red"
						onClick={() => null}
					>
						Não concordo com os termos
					</div>
					<div
						className="cpnt-chatmsg-botao btn btn-green"
						onClick={() => setShowPortalTermos(true)}
					>
						Li e concordo com os termos
					</div>
				</div>

				<div className={`cpnt-chatmsg-footer cpnt-chatmsg-footer-center`}>
					<span>{dataExtenso}</span>
					{propria ? (
						<span className="margin-l-10">
							<Icone
								imagem={imgIconeDoubleCheck}
								cor={mensagemLida ? '#2596e1' : '#D5D5D5'}
								tamanho="14px"
							/>
						</span>
					) : null}
				</div>
			</div>

			{/* Termos do termos */}
			<Portal
				show={showPortalTermos}
				onClose={() => {
					setShowPortalTermos(false);
				}}
				className="box-txt w-500"
			>
				<h3 className="margin-b-0">Assinatura do contrato</h3>
				<div className="color-1 fs-16 margin-t-5">
					<strong>Contrato: </strong>
					<span>{metadata.titulo || 'Contrato sem nome'}</span>
				</div>
				<div className="margin-t-20">
					<div className="fs-16">
						Enviamos um código de confirmação para o seu e-mail. Para continuar, insira o código que foi enviado no campo abaixo:
					</div>
					<div className="flex flex-center padding-t-30">
						<Input2
							label="Código"
							_={{
								className: 'align-center'
							}}
						/>
					</div>
				</div>
				<div className="flex flex-center margin-t-30">
					<Button2
						_={{
							value: 'Cancelar',
							type: 'button',
							onClick: e => {
								setShowPortalTermos(false);
							},
							className: 'btn btn-1',
						}}
					/>
					<Button2
						_={{
							value: 'Assinar contrato',
							type: 'button',
							onClick: e => {
								setShowPortalTermos(false);
								enviarMensagem({
									mensagem: 'Contrato assinado',
									metadata: metadata,
									type: 'contrato-assinado',
								})
							},
							className: 'btn btn-green margin-l-20',
						}}
					/>
				</div>
			</Portal>

			{/* Termos do contrato */}
			<Portal
				show={showPortalContrato}
				onClose={() => {
					setShowPortalContrato(false);
				}}
				className="box-txt w-1000"
			>
				{textoTermosDeUso()}
			</Portal>
		</div>
	);
};

export default ChatMensagemContrato;