import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// Screens
import { ScreensAdmin } from '@/_/roles/Screens/ScreensAdmin';
import { ScreensFree } from "@/_/roles/Screens/ScreensFree";
import { ScreensFreelancer } from '@/_/roles/Screens/ScreensFreelancer';
import { ScreensGerais } from '@/_/roles/Screens/ScreensGerais';
import { ScreensPremium } from '@/_/roles/Screens/ScreensPremium';

import { ROTAS_SEM_RESTRICOES, ScreensSemRestricoes } from "@/_/roles/Screens/ScreensSemRestricoes";

// SCSS
import '@scss/global.scss';

// CSS
// import '@css/btn.css';
// import '@css/fonts.css';
import '@css/libs/emoji-mart/emoji-mart.css';
import '@css/libs/react-datepicker/react-datepicker.css';
import '@css/libs/react-toastify/react-toastify.css';
import 'react-toastify/dist/ReactToastify.css';

// Funções úteis
import { exibirPaginaManutencao, isLogado } from '@_func/auth';

// Temp
import { addClassNivelAcesso, classNivelAcesso } from "@_func/profile";
import LayoutBlank from "@_role/Layouts/LayoutBlank/LayoutBlank";
import { EmManutencao } from "@page/SemRestricoes/EmManutencao/EmManutencao";

const Rotas = () => {

	const location = useLocation();

	var dadosLogado = isLogado();

	/*
	** Dados default
	** ================================================== */
	if(!dadosLogado) {
		dadosLogado = {
			token: false,
			usuario: {},
			config: {},
		};
	}

	/*
	** Store
	** ================================================== */
	const [store, setStore] = useState(dadosLogado);

	const isTelaSemRestricao = () => {
		var rotaLivre = false;
		if(location.pathname && location.pathname !== '' && location.pathname !== '/') {
			ROTAS_SEM_RESTRICOES.map(val => {
				if(val.path === location.pathname || location.pathname.indexOf('/freelancer/') > -1) {
					rotaLivre = true;
				}
			})
		}
		return rotaLivre;
	}

	/*
	** Adiciona a classe no body de acordo com o nível de acesso
	** ================================================== */
	useEffect(() => {
		addClassNivelAcesso(store.usuario.role);
	}, []);

	return (
		<>
			<StoreContext.Provider value={[store, setStore]}>
				<div className={`container`}>
					{exibirPaginaManutencao() ? (
						<LayoutBlank
							title="CROWD - Em manutenção"
							component={EmManutencao}
						/>
					) : (
						isTelaSemRestricao() ? (
							<ScreensSemRestricoes />
						) : (
							dadosLogado.usuario ? (
								<>
									{dadosLogado.usuario.role === 1 && <ScreensAdmin />}
									{dadosLogado.usuario.role === 2 && <ScreensFreelancer />}
									{dadosLogado.usuario.role === 3 && <ScreensPremium />}
									{dadosLogado.usuario.role === 9 && <ScreensFree />}
									{(!dadosLogado.usuario.role || (dadosLogado.usuario.role <= 0) || (dadosLogado.usuario.role >= 10)) && <ScreensGerais />}
								</>
							) : (
								<ScreensGerais />
							)
						)
					)}
					<ToastContainer
						position="bottom-right"
						autoClose={6000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						transition={Slide}
					/>
				</div>
			</StoreContext.Provider>
		</>
	)
}

export default Rotas;