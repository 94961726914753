import React, { useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// Componentes
import Input2 from "@cpnt/Input2/Input2";
import InputCpfCnpj from "@cpnt/InputCpfCnpj/InputCpfCnpj";
import { InputDate } from "@cpnt/InputDate/InputDate";
import InputTelefone from "@cpnt/InputTelefone/InputTelefone";
import { Select2 } from "@cpnt/Select2/Select2";

// css
// import './FormDadosPessoaisUsuario.css';
import { InputFake } from "@cpnt/InputFake/InputFake";
import { msg } from "@func/utils";

const FormDadosPessoaisUsuario = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		stateDados = {},
		setStateDados,
		stateCargos,
		loadingGetCargos,
	} = props;


	const avisoEdicaoCamposNaoPermitidos = nivelAcesso => {
		if(nivelAcesso === 'admin') {
			msg.warning(
				<div>Você não pode editar seu próprio <strong>nível de acesso</strong>, caso seja realmente necessário, solicite a alteração para outro administrador.</div>
			)
		} else if(nivelAcesso === 'hunter') {
			msg.warning(
				<div>Você não pode editar seu <strong>cargo</strong> e nem o seu <strong>nível de acesso</strong>, caso seja realmente necessário, solicite a alteração para um administrador.</div>
			)
		}
	}

	return (
		<>
			<div className={`cpnt-formdadosuser ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-formdadosuser-box ${className}`}>
					<div className="wrap-5">
						<div className="flex flex-wrap">

							<div className="input-box w-40p box-1-680">
								<Input2
									label="Nome completo"
									req
									_={{
										value: stateDados.name,
										onChange: e => setStateDados({
											...stateDados,
											name: e.target.value,
										}),
									}}
								/>
							</div>
							<div className="input-box w-30p box-1-680">
								<InputDate
									label="Data de nascimento"
									value={stateDados.birth_date}
									onChange={e => {
										setStateDados({
											...stateDados,
											birth_date: e,
										});
									}}
								/>
							</div>
							<div className="input-box w-30p box-1-680">
								<InputTelefone
									label="Telefone (WhatsApp)"
									_={{
										value: stateDados.phone,
										onChange: e => {
											setStateDados({
												...stateDados,
												phone: e
											});
										},
									}}
								/>
							</div>
							<div className="input-box w-33p box-1-680">
								<InputCpfCnpj
									label='CPF ou CNPJ'
									_={{
										value: stateDados.cpf_cnpj,
										onChange: e => {
											setStateDados({
												...stateDados,
												cpf_cnpj: e.target.value,
											})
										}
									}}
								/>
							</div>

							<div className="input-box w-33p box-1-680">
								{store.usuario.role === 1 ? (
									<Select2
										label="Cargo"
										req
										_={{
											value: stateDados.id_position,
											options: stateCargos,
											onChange: e => {
												setStateDados({
													...stateDados,
													id_position: e.value,
												});
											},
										}}
										loading={loadingGetCargos}
									/>
								) : (
									<InputFake
										label="Cargo"
										value={stateDados.role_position}
										onClick={e => avisoEdicaoCamposNaoPermitidos('hunter')}
									/>
								)}
							</div>

							<div className="input-box w-33p box-1-680">
								<InputFake
									label="Nível de acesso"
									value={stateDados.access_level}
									onClick={e => avisoEdicaoCamposNaoPermitidos(stateDados.role === 1 ? 'admin' : 'hunter')}
								/>
							</div>

						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { FormDadosPessoaisUsuario };
