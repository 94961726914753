import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalPerfil.css';

// API
import apiFreelancerLanguages from '@api/freelancerLanguages/freelancerLanguages';
import apiFreelancerSocial from '@api/freelancerSocial/freelancerSocial';
import apiFreelancerSkills from '@api/freelancerSkills/freelancerSkills';
import apiFreelancerCompetence from '@api/freelancerCompetence/freelancerCompetence';
import apiFreelancerSegments from '@api/freelancerSegments/freelancerSegments';
import apiFreelancerExperience from '@api/freelancerExperience/freelancerExperience';
import apiFreelancerFormation from '@api/freelancerFormation/freelancerFormation';
import apiFreelancerCertificate from '@api/freelancerCertificate/freelancerCertificate';
import apiFreelancerAwards from '@api/freelancerAwards/freelancerAwards';
import apiFreelancerPortfolio from '@api/freelancerPortfolio/freelancerPortfolio';
import apiFreelancerGoals from '@api/freelancerGoals/freelancerGoals';
import { api } from "@_api/api";

// Components
import Portal from '@cpnt/Portal/Portal';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import apiFreelancer from '@api/freelancer/freelancer';

// Partes
import PerfilFreelancer from './PerfilFreelancer/PerfilFreelancer';
import PerfilUsuario from './PerfilUsuario/PerfilUsuario';
import apiUserSkills from '@api/userSkills/userSkills';
import apiUserSkillsExpertise from '@api/userSkillsExpertise/userSkillsExpertise';
import apiUserCompetences from '@api/userCompetences/userCompetences';
import { msgs } from '@_func/request';

const PortalPerfil = props => {
	const {
		show,
		wrap = '',
		onClose,
		closeOnEsc = true,
		className = '',
		portalClassName = 'cpnt-perfil-portal-container',
		idFreelancer,
		idUsuario,
		tipo = 'freelancer'
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetRedesSociais, setLoadingGetRedesSociais] = useState(true);
	const [loadingGetIdiomas, setLoadingGetIdiomas] = useState(true);

	const [loadingGetHabilidades, setLoadingGetHabilidades] = useState(true);
	const [loadingGetCompetencias, setLoadingGetCompetencias] = useState(true);
	const [loadingGetSegmentos, setLoadingGetSegmentos] = useState(true);
	const [loadingGetExperiencias, setLoadingGetExperiencias] = useState(true);
	const [loadingGetFormacoes, setLoadingGetFormacoes] = useState(true);
	const [loadingGetCertificados, setLoadingGetCertificados] = useState(true);
	const [loadingGetPremios, setLoadingGetPremios] = useState(true);
	const [loadingGetPortfolios, setLoadingGetPortfolios] = useState(true);
	const [loadingGetObjetivos, setLoadingGetObjetivos] = useState(true);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(false);
	const [stateRedesSociais, setStateRedesSociais] = useState([]);
	const [stateIdiomas, setStateIdiomas] = useState([]);
	const [stateHabilidades, setStateHabilidades] = useState([]);
	const [stateCompetencias, setStateCompetencias] = useState([]);
	const [stateSegmentos, setStateSegmentos] = useState([]);
	const [stateExperiencias, setStateExperiencias] = useState([]);
	const [stateFormacoes, setStateFormacoes] = useState([]);
	const [stateCertificados, setStateCertificados] = useState([]);
	const [statePremios, setStatePremios] = useState([]);
	const [statePortfolios, setStatePortfolios] = useState([]);
	const [stateObjetivos, setStateObjetivos] = useState([]);
	// const [stateDadosEmpresa, setStateDadosEmpresa] = useState([]);

	/*
	** State - Usuário
	** ================================================== */
	const [stateHabilidadesArea, setStateHabilidadesArea] = useState([]);
	const [stateCompetenciasUsuario, setStateCompetenciasUsuario] = useState([]);

	/*
	** State - Loading
	** ================================================== */
	const [loadingGetHabilidadesArea, setLoadingGetHabilidadesArea] = useState(false);
	const [loadingGetHabilidadesEspecialidade, setLoadingGetHabilidadesEspecialidade] = useState(false);
	const [loadingGetCompetenciasUsuario, setLoadingGetCompetenciasUsuario] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		inicializarGets();
	}, [show]);

	/*
	** FREELANCER
	** ================================================== */
	const inicializarGets = async () => {
		if(show === true) {

			setStateDados(false);
			setLoading(true);

			// FREELANCER
			if(tipo === 'freelancer') {
				const retGetFreelancer = await apiFreelancer.getOne.get(idFreelancer);
				if(retGetFreelancer.status === 200) {
					setLoading(false);
					setStateDados(retGetFreelancer.data);
					getRedesSociais(retGetFreelancer.data.id_user);
					getIdiomas(idFreelancer);
					getObjetivos(idFreelancer);
					getHabilidadesFreelancer(idFreelancer);
					getCompetencias(idFreelancer);
					getSegmentos(idFreelancer);
					getExperiencias(idFreelancer);
					getFormacoes(idFreelancer);
					getCertificados(idFreelancer);
					getPremios(idFreelancer);
					getPortfolios(idFreelancer);
				} else {
					setLoading(false);
					setStateDados(retGetFreelancer.error || 'Não foi possível carregar os dados do profissional.');
					msgs([retGetFreelancer]);
				}

			// HUNTER ou ADMIN
			} else if(tipo === 'usuario') {
				const retGetUsuario = await api.users.getOne.get(idUsuario);
				if(retGetUsuario.status === 200) {
					setLoading(false);
					setStateDados(retGetUsuario.data);
					getHabilidadesArea(idUsuario);
					getCompetenciasUsuario(idUsuario);
				} else {
					setLoading(false);
					setStateDados(retGetUsuario.error || 'Não foi possível carregar os dados do usuário.');
					msgs([retGetUsuario]);
				}
			} else {
				const mensagem = `Tipo de perfil inválido "${tipo}". Informe algum dos tipos: "freelancer" ou "usuario".`;
				setLoading(false);
				setStateDados(mensagem);
				msg.error(mensagem);
			}
		}
	}

	/*
	** API - Objetivos
	** ================================================== */
	const getObjetivos = async idFreelancer => {
		setLoadingGetObjetivos(true);
		const retGetFreelancerObjetivos = await apiFreelancerGoals.all.get(idFreelancer);
		if(retGetFreelancerObjetivos.status === 200) {
			setStateObjetivos(retGetFreelancerObjetivos.data);
		} else {
			setStateObjetivos([]);
		}
		setLoadingGetObjetivos(false);
	}

	/*
	** API - Redes sociais
	** ================================================== */
	const getRedesSociais = async idUsuario => {
		setLoadingGetRedesSociais(true);
		const retGetRedesSociais = await apiFreelancerSocial.all.get(idUsuario);
		if(retGetRedesSociais.status === 200) {
			setStateRedesSociais(retGetRedesSociais.data);
		} else {
			setStateRedesSociais([]);
		}
		setLoadingGetRedesSociais(false);
	}

	/*
	** API - Idiomas
	** ================================================== */
	const getIdiomas = async idUsuario => {
		setLoadingGetIdiomas(true);
		const retGetIdiomas = await apiFreelancerLanguages.all.get(idUsuario);
		if(retGetIdiomas.status === 200) {
			setStateIdiomas(retGetIdiomas.data);
		} else {
			setStateIdiomas([]);
		}
		setLoadingGetIdiomas(false);
	}

	/*
	** API - Habilidades
	** ================================================== */
	const getHabilidadesFreelancer = async idFreelancer => {
		setLoadingGetHabilidades(true);
		const retGetFreelancerHabilidades = await apiFreelancerSkills.all.get(idFreelancer);
		if(retGetFreelancerHabilidades.status === 200) {
			setStateHabilidades(retGetFreelancerHabilidades.data);
		} else {
			setStateHabilidades([]);
		}
		setLoadingGetHabilidades(false);
	}

	/*
	** API - Competencias
	** ================================================== */
	const getCompetencias = async idFreelancer => {
		setLoadingGetCompetencias(true);
		const retGetFreelancerCompetencias = await apiFreelancerCompetence.all.get(idFreelancer);
		if(retGetFreelancerCompetencias.status === 200) {
			setStateCompetencias(retGetFreelancerCompetencias.data);
		} else {
			setStateCompetencias([]);
		}
		setLoadingGetCompetencias(false);
	}

	/*
	** API - Segmentos
	** ================================================== */
	const getSegmentos = async idFreelancer => {
		setLoadingGetSegmentos(true);
		const retGetFreelancerSegmentos = await apiFreelancerSegments.all.get(idFreelancer);
		if(retGetFreelancerSegmentos.status === 200) {
			setStateSegmentos(retGetFreelancerSegmentos.data);
		} else {
			setStateSegmentos([]);
		}
		setLoadingGetSegmentos(false);
	}

	/*
	** API - Experiências
	** ================================================== */
	const getExperiencias = async idFreelancer => {
		setLoadingGetExperiencias(true);
		const retGetExperiencias = await apiFreelancerExperience.all.get(idFreelancer, {
			orderBy: 'start_date',
			orderType: 'desc',
		});
		if(retGetExperiencias.status === 200) {
			setStateExperiencias(retGetExperiencias.data);
		} else {
			setStateExperiencias([]);
		}
		setLoadingGetExperiencias(false);
	}

	/*
	** API - Formações
	** ================================================== */
	const getFormacoes = async idFreelancer => {
		setLoadingGetFormacoes(true);
		const retGetFormacoes = await apiFreelancerFormation.all.get(idFreelancer, {
			orderBy: 'start_date',
			orderType: 'desc',
		});
		if(retGetFormacoes.status === 200) {
			setStateFormacoes(retGetFormacoes.data);
		} else {
			setStateFormacoes([]);
		}
		setLoadingGetFormacoes(false);
	}

	/*
	** API - Certificados
	** ================================================== */
	const getCertificados = async idFreelancer => {
		setLoadingGetCertificados(true);
		const retGetCertificados = await apiFreelancerCertificate.all.get(idFreelancer, {
			orderBy: 'issue_date',
			orderType: 'desc',
		});
		if(retGetCertificados.status === 200) {
			setStateCertificados(retGetCertificados.data);
		} else {
			setStateCertificados([]);
		}
		setLoadingGetCertificados(false);
	}

	/*
	** API - Prêmios
	** ================================================== */
	const getPremios = async idFreelancer => {
		setLoadingGetPremios(true);
		const retGetPremios = await apiFreelancerAwards.all.get(idFreelancer, {
			orderBy: 'issue_date',
			orderType: 'desc',
		});
		if(retGetPremios.status === 200) {
			setStatePremios(retGetPremios.data);
		} else {
			setStatePremios([]);
		}
		setLoadingGetPremios(false);
	}

	/*
	** API - Portfolios
	** ================================================== */
	const getPortfolios = async idFreelancer => {
		setLoadingGetPortfolios(true);
		const retGetPortfolios = await apiFreelancerPortfolio.all.get(idFreelancer);
		if(retGetPortfolios.status === 200) {
			setStatePortfolios(retGetPortfolios.data);
		} else {
			setStatePortfolios([]);
		}
		setLoadingGetPortfolios(false);
	}

	/*
	** USUARIO
	** ================================================== */

	/*
	** API - Habilidades área
	** ================================================== */
	const getHabilidadesArea = async idUsuario => {
		setLoadingGetHabilidadesArea(true);
		const retGetHabilidadesArea = await apiUserSkills.getAll.get(idUsuario);
		if(retGetHabilidadesArea.status === 200) {
			setStateHabilidadesArea(retGetHabilidadesArea.data);
		} else {
			setStateHabilidadesArea([]);
		}
		setLoadingGetHabilidadesArea(false);
	}

	/*
	** API - Competências usuário
	** ================================================== */
	const getCompetenciasUsuario = async idUsuario => {
		setLoadingGetCompetenciasUsuario(true);
		const retGetCompetenciasUsuario = await apiUserCompetences.getAll.get(idUsuario);
		if(retGetCompetenciasUsuario.status === 200) {
			setStateCompetenciasUsuario(retGetCompetenciasUsuario.data);
		} else {
			setStateCompetenciasUsuario([]);
		}
		setLoadingGetCompetenciasUsuario(false);
	}

	return (
		<Portal
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
			classNameDefault="cpnt-perfil-portal"
			classNameX="x"
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				typeof(stateDados) === 'object' ? (
					<div className={`cpnt-perfil ${wrap}`}>
						<div className={`cpnt-perfil-box ${className}`}>
							{tipo === 'freelancer' && (
								<PerfilFreelancer
									{...stateDados}

									// Funções
									onClose={onClose}

									// States
									redesSociais={stateRedesSociais}
									idiomas={stateIdiomas}
									habilidades={stateHabilidades}
									competencias={stateCompetencias}
									segmentos={stateSegmentos}
									experiencias={stateExperiencias}
									formacoes={stateFormacoes}
									certificados={stateCertificados}
									premios={statePremios}
									portfolios={statePortfolios}
									objetivos={stateObjetivos}

									// Loading
									loadingGetRedesSociais={loadingGetRedesSociais}
									loadingGetIdiomas={loadingGetIdiomas}
									loadingGetHabilidades={loadingGetHabilidades}
									loadingGetCompetencias={loadingGetCompetencias}
									loadingGetSegmentos={loadingGetSegmentos}
									loadingGetExperiencias={loadingGetExperiencias}
									loadingGetFormacoes={loadingGetFormacoes}
									loadingGetCertificados={loadingGetCertificados}
									loadingGetPremios={loadingGetPremios}
									loadingGetPortfolios={loadingGetPortfolios}
									loadingGetObjetivos={loadingGetObjetivos}
								/>
							)}
							{tipo === 'usuario' ? (
								<PerfilUsuario
									{...stateDados}

									// States
									habilidadesArea={stateHabilidadesArea}
									competencias={stateCompetenciasUsuario}

									// Loading
									loadingGetHabilidadesArea={loadingGetHabilidadesArea}
									loadingGetHabilidadesEspecialidade={loadingGetHabilidadesEspecialidade}
									loadingGetCompetenciasUsuario={loadingGetCompetenciasUsuario}
								/>
							 ) : null }

							{tipo !== 'freelancer' && tipo !== 'usuario' ? (
								<div>
									{typeof(stateDados) === 'string' ? (
										stateDados
									) : (
										<div>Tipo desconhecido, informe o tipo "freelancer" ou "usuario".</div>
									)}
								</div>
							) : null}
						</div>
					</div>
				) : (
					<div className="padding-30">
						<Info icone="erro" texto={stateDados} />
					</div>
				)
			)}
		</Portal>
	);
};

export default PortalPerfil;