import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalProcessoSeletivoHistorico.css';

// Componentes
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import apiBriefings from "@api/briefings/briefings";
import apiBriefingsHistory from '@api/briefingsHistory/briefingsHistory';
import Button2 from '@cpnt/Button2/Button2';
import ListaTituloDesc from "@cpnt/ListaTituloDesc/ListaTituloDesc";
import { dataPorExtenso} from '@func/utils';
import { gets } from "@route/routeUtils";
import PortalProcessoSeletivoInfoHistorico from "../PortalProcessoSeletivoInfoHistorico/PortalProcessoSeletivoInfoHistorico";
import { msgs, msgStatus } from "@_func/request";

const PortalProcessoSeletivoHistorico = props => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();
	const location = useLocation();

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-historico',
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetHistorico, setLoadingGetHistorico] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({});
	const [_stateDados, _setStateDados] = useState({});
	const [stateHistorico, setStateHistorico] = useState([]);
	const [stateBriefing, setStateBriefing] = useState([]);
	const [stateInfoHistorico, setStateInfoHistorico] = useState(false);

	/*
	** Diff
	** ================================================== */
	const [stateEdicaoAnterior, setStateEdicaoAnterior] = useState({});
	const [stateEdicaoAtual, setStateEdicaoAtual] = useState({});
	const [stateEdicaoTemDiff, setStateEdicaoTemDiff] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setLoading(true);
			setStateDados({});
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** FREELANCER
	** ================================================== */
	const inicializarGets = async () => {
		if(show === true) {
			const retGetBriefing = await apiBriefings.getOne.get(id);

			if(retGetBriefing.status === 200) {
				var autorizado = true;

				if(store.usuario.role !== 1) {
					if(retGetBriefing.data.id_company !== store.usuario.id_company) {
						autorizado = false;
					}
				}

				if(autorizado) {
					setStateDados(retGetBriefing.data);
					_setStateDados(msgStatus(retGetBriefing));

					setLoadingGetHistorico(true);
					const retGetItem = await apiBriefingsHistory.getAll.get(id);
					if(retGetItem.status === 200) {
						setStateHistorico(retGetItem.data);
					}
					setLoadingGetHistorico(false);

				} else {
					_setStateDados({
						status: 400,
						msg: 'Você não tem permissão para acessar essas informações',
					});
				}

			} else {
				_setStateDados(msgStatus(retGetBriefing));
				msgs([retGetBriefing]);
			}

			setLoading(false);
		}
	}

	return (
		<>
			<Portal
				titulo="Histórico de edições"
				show={show}
				closeOnEsc={closeOnEsc}
				onClose={onClose}
				className={portalClassName}
			>
				{loading ? (
					<Loading
						texto="Carregando dados, aguarde..."
						wrap="padding-30"
					/>
				) : (
					<div className={wrap}>
						<div className={className}>
							{_stateDados.status === 200 ? (
								<div>
									<div>
										<div className="titulo">Proposta: {stateDados.title}</div>
										<div className="margin-t-10">
											<div className="desc"><strong>Projeto:</strong> {stateDados.project_name}</div>
											<div className="desc"><strong>ID da proposta:</strong> {stateDados.id}</div>
											<div className="desc"><strong>Publicado em:</strong> {dataPorExtenso(stateDados.created_at)}</div>
										</div>
									</div>
									<div className="padding-t-10 margin-t-15 sep-t">Total de atualizações: {stateHistorico.length}</div>
									<div className="margin-t-15">
										<ListaTituloDesc
											itens={stateHistorico}
											onClickItem={e => {
												setStateInfoHistorico(e.id);
											}}
											loading={loadingGetHistorico}
										/>
									</div>
								</div>
							) : (
								<Info
									texto={_stateDados.msg}
									icone="erro"
								/>
							)}
						</div>
						<div className="form-bottom">
							<Button2
								_={{
									value: 'Fechar',
									onClick: onClose,
								}}
							/>
						</div>
					</div>
				)}
			</Portal>

			<PortalProcessoSeletivoInfoHistorico
				id={stateInfoHistorico}
				idBriefing={stateDados.id}
				show={stateInfoHistorico}
				onClose={() => setStateInfoHistorico(false)}
			/>
		</>
	);
};

export { PortalProcessoSeletivoHistorico };
