import React, { useEffect, useState } from 'react';

// css
// import './DiffViewerContainer.css';

// Components
import { Select2 } from '@cpnt/Select2/Select2';
import Checkbox2 from '@cpnt/Checkbox2/Checkbox2';
import DiffViewer from '@cpnt/DiffViewer/DiffViewer';

const DiffViewerContainer = props => {
	const {
		className = '',
		wrap = '',

		subtitulo = 'Texto anterior e texto atual',

		oldValue,
		newValue,
		expandirOverlay,
		setExpandirOverlay,
		after,

		splitView = true,
		showDiffOnly = true,
		disableWordDiff = false,
		hideLineNumbers = true,
		compareMethod = 'diffWordsWithSpace',

		hideCheckbox,
		hideMetodos,
	} = props;

	/*
	** Checkbox
	** ================================================== */
	const [_compareMethod, _setCompareMethod] = useState(compareMethod);
	const [_splitView, _setSplitView] = useState(splitView);
	const [_disableWordDiff, _setDisableWordDiff] = useState(disableWordDiff);
	const [_hideLineNumbers, _setHideLineNumbers] = useState(hideLineNumbers);
	// const [_showDiffOnly, _setShowDiffOnly] = useState(showDiffOnly);

	return (
		<div className={`cpnt-nome ${wrap}`}>
			<div className={`cpnt-nome-box ${className}`}>
					{!hideCheckbox && !hideMetodos ? (
					<div>
						<div className="flex flex-center-v">
							{!hideCheckbox ? (
								<div className="flex flex-column">
									<Checkbox2
										label="Comparação lado a lado"
										_={{
											checked: _splitView,
											onChange: e => _setSplitView(e.target.checked)
										}}
									/>
									{/* <Checkbox2
										label="Mostrar apenas o que foi editado"
										_={{
											checked: _showDiffOnly,
											onChange: e => _setShowDiffOnly(e.target.checked)
										}}
									/> */}
									<Checkbox2
										label="Destacar palavras alteradas"
										_={{
											checked: !_disableWordDiff,
											onChange: e => _setDisableWordDiff(!e.target.checked)
										}}
									/>
									<Checkbox2
										label="Exibir número das linhas"
										_={{
											checked: !_hideLineNumbers,
											onChange: e => _setHideLineNumbers(!e.target.checked)
										}}
									/>
									<Checkbox2
										label="Expandir visualização de tela"
										_={{
											checked: expandirOverlay,
											onChange: e => setExpandirOverlay(e.target.checked)
										}}
									/>
								</div>
							) : null}

							{!hideMetodos ? (
								<div className="flex-1 min-w-300 padding-l-100">
									<Select2
										label="Método de comparação"
										_={{
											value: _compareMethod,
											onChange: e => {
												_setCompareMethod(e.value);
											},
											options: [
												{
													// WORDS_WITH_SPACE
													label: 'Diferenciar palavras (considerando espaço)',
													value: 'diffWordsWithSpace',
												},
												{
													// WORDS
													label: 'Diferenciar palavras (sem considerar espaço)',
													value: 'diffWords',
												},
												{
													// CHARS
													label: 'Diferenciar caracteres',
													value: 'diffChars',
												},
												{
													// SENTENCES
													label: 'Diferenciar sentenças',
													value: 'diffSentences',
												},
												{
													// LINES
													label: 'Diferenciar linhas',
													value: 'diffLines',
												},
												{
													// TRIMMED_LINES
													label: 'Diferenciar linhas removidas',
													value: 'diffTrimmedLines',
												},
												{
													// CSS
													label: 'Diferenciar estilização',
													value: 'diffCss',
												},
											]
										}}
									/>
								</div>
							) : null}
						</div>
					</div>
				) : null}
				<div>
					<h3>{subtitulo}</h3>
				</div>
 				<div className="box-default-3 margin-t-20">
					<DiffViewer
						_={{
							oldValue: oldValue || '',
							newValue: newValue || '',
							splitView: _splitView,
							// showDiffOnly: _showDiffOnly,
							disableWordDiff: _disableWordDiff,
							hideLineNumbers: _hideLineNumbers,
							compareMethod: _compareMethod,
						}}
					/>
				</div>
			</div>
			{after}
		</div>
	);
};

export default DiffViewerContainer;