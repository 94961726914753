import { isNumeric } from "@_func/regex";
import { LISTA_FREELANCERS_CROWD, LISTA_USUARIOS_CROWD } from '@config/objetos';
import { caminhoFoto, dataApiParaBr, dataApiParaHoraMinSeg } from '@func/utils';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';

export const checkItensMerge = ({
	retornoGeral = {},
	retornoChecked = {},
	chaveValidar = 'id',
	valorValidar = 'id',
}) => {
	var SegmentosMescladas = [];

	if(retornoGeral.status === 200) {
		retornoGeral.data.map((val, key) => {
			var temEssaChave = false;

			if(retornoChecked.status === 200) {
				temEssaChave = _.find(retornoChecked.data, {[chaveValidar]: val[valorValidar]});
			}

			if(temEssaChave) {
				SegmentosMescladas.push({
					...val,
					checked: true,
				});
			} else {
				SegmentosMescladas.push({
					...val,
					checked: false,
				});
			}
			return null;
		});
	}
	return SegmentosMescladas;
}

export const checkItensMergeHabilidades = ({
	retornoGeral = {},
	retornoChecked = {},
}) => {
	const nivel = e => {
		const niveis = ['senior', 'pleno', 'junior'];
		if(typeof(niveis[(e - 1)]) !== undefined && niveis[(e - 1)]) {
			return niveis[(e - 1)];
		}
		return '';
	}

	var habilidadesMescladas = [];
	if(retornoGeral.status === 200) {
		retornoGeral.data.map((val, key) => {
			var temEssaChave = false;

			if(retornoChecked.status === 200) {
				temEssaChave = _.find(retornoChecked.data, {id: val.id});
			}

			if(temEssaChave) {
				habilidadesMescladas.push({
					...val,
					checked: true,
					_nivel: nivel(temEssaChave.id_level)
				});
			} else {
				habilidadesMescladas.push({
					...val,
					checked: false,
				});
			}
			return null;
		});
	}

	return habilidadesMescladas;
}

export const checkItensMergeIdiomas = ({
	retornoGeral = {},
	retornoChecked = {},
	retornoNiveis = {},
	chaveValidarFreelancer = 'id_language',
	chaveValidarGeral = 'id_language',
	validarNivel = true,
}) => {
	var idiomasMesclados = [];

	if(retornoGeral.status === 200 && retornoNiveis.status === 200) {

		// Idiomas do freelancer
		if(retornoChecked.status === 200) {
			retornoChecked.data = retornoChecked.data.map((val, key) => {
				var idiomaNome = _.find(retornoGeral.data, {id: val[chaveValidarFreelancer]});
				var nivelNome = '';
				if(validarNivel) {
					nivelNome = _.find(retornoNiveis.data, {id: val.id_level});
				}
				return {
					...val,
					name_language: idiomaNome.name,
					name_level: nivelNome.name,
				}
			})
		}

		// Idiomas Mesclados
		retornoGeral.data.map((val, key) => {
			var freelancerConheceEsseIdioma = false;

			if(retornoChecked.status === 200) {
				freelancerConheceEsseIdioma = _.find(retornoChecked.data, {[chaveValidarGeral]: val.id});
			}
			if(freelancerConheceEsseIdioma) {
				var nivelNome = _.find(retornoNiveis.data, {id: freelancerConheceEsseIdioma.id_level});
				idiomasMesclados.push({
					id: val.id,
					value: val.id,
					label: val.name,
					id_language: val.id,
					name_language: val.name,
					id_level: freelancerConheceEsseIdioma.id_level,
					name_level: nivelNome && nivelNome.name,
					checked: true,
				});
			} else {
				idiomasMesclados.push({
					id: val.id,
					value: val.id,
					label: val.name,
					id_language: val.id,
					name_language: val.name,
					id_level: 0,
					name_level: '',
					checked: false,
				});
			}
			return null;
		});
	}

	return idiomasMesclados;
}

export const juniorPlenoSenior = (dados, tipo) => {
	var retorno = [];
	if(typeof(dados) === 'object') {
		if(dados.length !== undefined) {
			retorno = dados.filter(val => {
				if(val.senioridade === tipo) {
					return val;
				}
			});
		}
	}
	return retorno;
}

export const nomeStatus = (status) => {
	var retorno = '';
	switch(status){
		case 1: retorno = 'Ativo'; break;
		case 2: retorno = 'Pausado'; break;
		case 3: retorno = 'Finalizado'; break;
		default: retorno = 'Não identificado';
	}
	return retorno;
}

export const fotoAleatoria = (id, freelancer = false) => {
	var lista = [];

	if(!freelancer) {
		lista = LISTA_USUARIOS_CROWD;
	} else {
		lista = LISTA_FREELANCERS_CROWD;
	}

	var keys = Object.keys(lista);

	var placeholder = {
		img: require(`@img/userpic.png`),
		nome: 'Nome do usuário',
		area: 'Cargo',
	};

	if(id !== undefined && id !== null) {
		if(keys[id] !== undefined) {
			return lista[id];
		} else {
			return placeholder;
		}
	} else {
		var index = Math.floor(Math.random() * keys.length);
		if(lista[index] !== undefined) {
			return lista[index];
		} else {
			return placeholder;
		}
	}
}

export const getLabelState = (state, id, stateKey = 'id', stateLabel = 'label', subKey = false) => {
	if(typeof(state) === 'object' && state.length) {
		if(typeof(id) === 'object') {
			if(id) {
				var retorno = [];
				id.map((val, key) => {
					var campoExistenteArr = _.find(state, {[stateKey]: (subKey ? Number(val[subKey]) : Number(val))});
					if(campoExistenteArr) {
						retorno.push(campoExistenteArr[stateLabel]);
					}
				});
				if(retorno.length) {
					return retorno;
				}
			}
		} else {
			if(isNumeric(id)) {
				id = Number(id);
			}
			var campoExistente = _.find(state, {value: id});
			if(campoExistente) {
				return campoExistente[stateLabel];
			}
		}
	}
	return false;
}

// Formata a mensagem para o formato do componente de <Chat />
export const formatarMensagemChat = val => {
	var foto;
	var nome;

	var params = val._ || {};

	if(val.id_user) {
		foto = caminhoFoto(val.photo_user).url;
		nome = val.name_user;
	} else {
		if(val.name_user_freelancer) {
			nome = val.name_user_freelancer;
			foto = caminhoFoto(val.photo_user_freelancer).url;
		} else if(val.id_user_freela) {
			nome = val.name_frelancer;
			foto = caminhoFoto(val.photo_user_freela).url;
		} else {
			nome = val.name_freelancer;
			foto = caminhoFoto(val.photo_user_freela).url;
		}
	}

	var horaMinSeg = dataApiParaHoraMinSeg(val.created_at);
	var horaExtenso = '';

	if(moment(val.created_at).isSame(moment(), 'day')) {
		horaExtenso = `Hoje às ${horaMinSeg}`;
	} else {
		horaExtenso = `Enviado em ${dataApiParaBr(val.created_at)} às ${horaMinSeg}`;
	}

	var retorno = {
		_: val,
		...val,
		mensagem: val.text,
		propria: val.id_user ? false : true,
		photo: foto,
		name: nome,
		mensagemLida: val.readed,
		data: horaMinSeg,
		dataExtenso: horaExtenso,
		...params,
	};

	return retorno;
}
