import React from 'react';

// API
import { apiGetCnpj } from "@api/_functions/geral";

// Componentes
import Input2 from "@cpnt/Input2/Input2";
import Loading from "@cpnt/Loading/Loading";
import { validarCnpj } from "@func/utils";

const PtDadosDaEmpresa = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		stateDadosEmpresa = {},
		setStateDadosEmpresa,
		loadingGetDadosEmpresa,
		setLoadingGetDadosEmpresa,
	} = props;

	const pesquisarCNPJ = async e => {
		var cnpj = e.target.value;
		if(cnpj === '') {
			setStateDadosEmpresa({
				cnpj: '',
				fantasy_name: '',
				razao_social: '',
				address: '',
			})
			return;
		}
		if(!validarCnpj(cnpj)) return;
		apiGetCnpj({
			cnpj: cnpj,
			stateDadosEmpresa,
			setStateDadosEmpresa,
			loadingGetDadosEmpresa,
			setLoadingGetDadosEmpresa,
		});
	};

	return (
		<>
			<div className={`cpnt-ptendereco ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-ptendereco-box ${className}`}>
					<div>
						<div className="wrap-5">
							<div className="flex flex-wrap relative">
								{loadingGetDadosEmpresa && <Loading alt absolute />}
								<div className="input-box w-30p box-1-780">
									<Input2
										label="CNPJ"
										req
										_={{
											value: stateDadosEmpresa.cnpj,
											onChange: e => {
												setStateDadosEmpresa({
													...stateDadosEmpresa,
													cnpj: e.target.value,
												})
											},
											onBlur: pesquisarCNPJ,
											mask: '99.999.999/9999-99'
										}}
									/>
								</div>
								<div className="input-box w-70p box-1-780">
									<Input2
										label="Razão social"
										req
										_={{
											value: stateDadosEmpresa.razao_social,
											onChange: e => {
												setStateDadosEmpresa({
													...stateDadosEmpresa,
													razao_social: e.target.value,
												})
											}
										}}
									/>
								</div>
								<div className="input-box w-30p box-1-780">
									<Input2
										label="Nome Fantasia"
										req
										_={{
											value: stateDadosEmpresa.fantasy_name,
											onChange: e => {
												setStateDadosEmpresa({
													...stateDadosEmpresa,
													fantasy_name: e.target.value,
												})
											}
										}}
									/>
								</div>
								<div className="input-box w-70p box-1-780">
									<Input2
										label="Endereço completo da sede"
										req
										_={{
											value: stateDadosEmpresa.address,
											onChange: e => {
												setStateDadosEmpresa({
													...stateDadosEmpresa,
													address: e.target.value,
												})
											}
										}}
									/>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { PtDadosDaEmpresa };
