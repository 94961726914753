import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast as msg } from 'react-toastify';
import moment from 'moment/min/moment-with-locales';
import qs from 'qs';

// Store e hooks
import StoreContext from '@/_/contexts/StoreContext';
import useWindowFocus from '@/_/hooks/useWindowFocus';

// css
import './NegociarMeusJobs.css';

// API
import apiBriefingMessages from '@api/briefingMessages/briefingMessages';
import socket from '@_api/socket';
import apiTasks from '@api/tasks/tasks';
import apiTasksMessages from '@api/tasksMessages/tasksMessages';

// Components
import Foto from '@cpnt/Foto/Foto';
import TextoWrap from '@cpnt/TextoWrap/TextoWrap';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';
import Tooltip from '@cpnt/Tooltip/Tooltip';
import Chat from '@cpnt/Chat/Chat';
import Icone from '@cpnt/Icone/Icone';
import LinhaItem from '@cpnt/LinhaItem/LinhaItem';
import PortalPerfil from '@portalGeral/PortalPerfil/PortalPerfil';
import Portal from '@cpnt/Portal/Portal';
import Button2 from '@cpnt/Button2/Button2';
import Equipe from '@cpnt/Equipe/Equipe';
import Loading from '@cpnt/Loading/Loading';
import { iconeStatusCard } from '@cpnt/StatusCards/StatusCards';

// Imagens
import imgIconeVoltar from '@svg/solid/arrow-left.svg';
import imgIconeValor from '@svg/solid/sack-dollar.svg';
import imgIconePDF from '@svg/solid/file-pdf.svg';
import imgIconeXML from '@svg/solid/file-code.svg';
import imgIconeEnviar from '@svg/regular/paperclip.svg';
import imgIconeTour from '@svg/regular/earth-americas.svg';
import imgIconeContratado from '@svg/solid/messages.svg';
import imgIconeBriefing from '@svg/regular/file-lines.svg';
import imgIconeEditar from '@svg/regular/pen.svg';
import imgIconeDetalhes from '@svg/regular/file-lines.svg';
import imgIconeProjeto from '@svg/regular/table-tree.svg';
import imgIconeInfo from '@svg/solid/circle-info.svg';


// Funções
import { formatarMensagemChat, fotoAleatoria } from '@shared/functions/components';
import templatesBriefings from '@config/templateBriefings';
import { caminhoFoto, caminhoUrl, dataApiParaBr, dataHaQuantoTempo, formatarDinheiro, html, htmlToJsx, sanitize, validarDataAPI } from '@func/utils';
import { chatFormatarDadosUsuario, chatSalaAddUsuarioOnline, chatSalaAddUsuariosOnline, chatSalaRemoverUsuarioOnline } from '@cpnt/Chat/functions';
import notificacao from '@_func/notification';
import Voltar from "@cpnt/Voltar/Voltar";
import { initFuncExec, setGet } from "@route/routeUtils";
import useWindowDimension from "@/_/hooks/useWindowDimensions";
import useDebounce from "@/_/hooks/useDebounce";
import Tip from "@cpnt/Tip/Tip";
import ImgTituloDesc from "@cpnt/ImgTituloDesc/ImgTituloDesc";
import Info from "@cpnt/Info/Info";
import { PortalPerfilUsuario } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilUsuario";
import { PortalCardDetalhes } from "@cpnt/Portais/PortaisProcessoSeletivo/PortalCardDetalhes/PortalCardDetalhes";
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";
import { PortalJobDetalhes } from "@cpnt/Portais/PortaisProcessoSeletivo/PortalJobDetalhes/PortalJobDetalhes";
import { isNumeric } from "@_func/regex";
import { msgs, msgStatus } from "@_func/request";

const useQuery = () => {
	return new URLSearchParams(useLocation().search);
}

const NegociarMeusJobs = () => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	const janelaAtiva = useWindowFocus();
	const screen = useWindowDimension();

	const [isDigitando, setIsDigitando] = useState(false);
	const debounceEvento = useDebounce(isDigitando, 500);

	/*
	** Debounce - Para não ficar enviando o evento a todo momento
	** ================================================== */
	useEffect(() => {
		socket.emit('message', {
			data: {
				id_freelancer: stateGet.id_freelancer,
				id_task: stateGet.id_task,
			},
			context: 'TasksMessages',
			function: 'userTyping',
		});
	}, [debounceEvento]);

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = (getsAtualizados = getsDefault) => {
		initFuncExec([
			{
				func: getTarefa,
				param: getsAtualizados
			},
		], init, location, stateLocation);
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		overlay: [],
		id_briefing: null,
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	const janelaAtivaRef = useRef();

	const [showPortalPerfilCompleto, setShowPortalPerfilCompleto] = useState(false);
	const [showPortalTextoBriefing, setShowPortalTextoBriefing] = useState(false);
	const [showPortalEquipeProjeto, setShowPortalEquipeProjeto] = useState(false);
	const [stateIdUsuarioPerfilCompleto, setStateIdUsuarioPerfilCompleto] = useState(false);
	const [showPortalDetalhes, setShowPortalDetalhes] = useState(false);
	const [showPortalConfirmacaoProposta, setShowPortalConfirmacaoProposta] = useState(false);
	const [taxaPorcentagem, setTaxaPorcentagem] = useState(0);

	/*
	** Chat
	** ================================================== */

	// Debug
	if(window.location.host === 'localhost:3000') {
		// localStorage.debug = 'socket.io-client:socket';
	}

	// States
	const [mensagensAntigas, setMensagensAntigas] = useState(false);
	const [mensagensChat, setMensagensChat] = useState([]);
	const [mensagemChat, setMensagemChat] = useState('');
	const [usuariosOnline, setUsuariosOnline] = useState([]);
	const [isDigitandoChat, setIsDigitandoChat] = useState(false);
	const [isDigitandoInfoChat, setIsDigitandoInfoChat] = useState(false);
	const [loadingGetMensagensChat, setLoadingGetMensagensChat] = useState(true);

	const [stateTarefa, setStateTarefa] = useState({});
	const [_stateTarefa, _setStateTarefa] = useState({});
	const [_retGetTarefa, _setRetGetTarefa] = useState(null);
	const [loadingGetTarefa, setLoadingGetTarefa] = useState(true);
	const [loadingEnviarPdfOuXml, setLoadingEnviarPdfOuXml] = useState(false);
	const [showPortalProposta, setShowPortalProposta] = useState(false);

	const [emitirEventoMensagemLida, setEmitirEventoMensagemLida] = useState(false);
	const mensagensChatRef = useRef([]);
	const usuariosOnlineRef = useRef([]);

	const stateGetRef = useRef(stateGet);

	useEffect(() => {
		stateGetRef.current = stateGet;
	}, [stateGet]);

	/*
	** Chat - Sair da sala
	** ================================================== */
	useEffect(() => {
		return () => {
			socket.emit('message', {
				data: {
					id_task: stateGet.id_task,
					id_freelancer: store.usuario.id_freelancer,
				},
				context: 'TasksMessages',
				function: 'leaveRoom'
			});
		}
	}, []);

	/*
	** Chat - inicializar
	** ================================================== */
	useEffect(() => {
		if(!socket.connected) {
			socket.auth.token = store.token;
  			socket.connect();
		}
	}, []);

	/*
	** Alterar estado do focus
	** ================================================== */
	useEffect(() => {
		janelaAtivaRef.current = janelaAtiva;
	}, [janelaAtiva]);

	/*
	** Verifica se tem novas mensagens e emite evento
	** ================================================== */
	useEffect(() => {
		if(emitirEventoMensagemLida) {
			marcarMensagemComoLida({
				id_task: stateGet.id_task,
				id_freelancer: store.usuario.id_freelancer,
			});
		}
	}, [janelaAtiva]);

	useEffect(() => {
		if(janelaAtivaRef.current) {
			marcarMensagemComoLida({
				id_task: stateGet.id_task,
				id_freelancer: store.usuario.id_freelancer,
			});
		}
	}, [emitirEventoMensagemLida]);

	/*
	** Chat - eventos
	** ================================================== */
	useEffect(() => {

		/*
		** Digitando
		** ================================================== */
		socket.on('typingTaskMessage', e => {
			setIsDigitandoChat(true);
			setIsDigitandoInfoChat(chatFormatarDadosUsuario(e.data));
		});

		/*
		** Nova mensagem do outro usuário - insere no chat
		** ================================================== */
		socket.on('getMessage', dados => {
			setIsDigitandoChat(false);

			var mensagensAtualizadas = [...mensagensChatRef.current];
			mensagensAtualizadas.push({
				...formatarMensagemChat(dados.data),
				propria: false,
			});
			setMensagensChat(mensagensAtualizadas);
			mensagensChatRef.current = mensagensAtualizadas;
			setEmitirEventoMensagemLida(true);

			if(!janelaAtivaRef.current) {
				var foto;
				var nome;

				if(dados.data.id_user) {
					foto = caminhoFoto(dados.data.photo_user).url;
					nome = dados.data.name_user;
				} else {
					if(dados.data.name_user_freelancer) {
						nome = dados.data.name_user_freelancer;
						foto = caminhoFoto(dados.data.photo_user_freelancer).url;
					} else {
						nome = dados.data.name_freelancer;
						foto = caminhoFoto(dados.data.photo_user_freela).url;
					}
				}

				if(dados.data.type === 'imagem-url') {
					notificacao(nome, {
						body: `${nome} enviou uma imagem.`,
						icon: dados.data.metadata.imagem.preview
					});
				} if(dados.data.type === 'nota-fiscal-xml' || dados.data.type === 'nota-fiscal-pdf') {
					notificacao(nome, {
						body: `${nome} enviou uma nota fiscal.`,
						icon: foto
					});
				} else {
					notificacao(nome, {
						body: dados.data.text && dados.data.text !== '' ? dados.data.text : 'Você recebeu uma nova mensagem.',
						icon: foto
					});
				}
			}
		});

		/*
		** Própria mensagem enviada e cadastrada, insere no chat
		** ================================================== */
		socket.on('insertMessage', dados => {
			if(dados.success) {
				var mensagensAtualizadas = [...mensagensChatRef.current];
				mensagensAtualizadas.push({
					...formatarMensagemChat(dados.data),
					propria: true,
				});
				setMensagensChat(mensagensAtualizadas);
				mensagensChatRef.current = mensagensAtualizadas;
			} else {
				msg.error(dados.message, {
					autoClose: false,
				});
			}
		});

		/*
		** Usuário conectado, exibe a imagem no topo do chat
		** ================================================== */
		socket.on('joinChatTask', e => {
			const _usuariosOnline = chatSalaAddUsuarioOnline(e.data, usuariosOnlineRef.current);
			setUsuariosOnline(_usuariosOnline);
			usuariosOnlineRef.current = _usuariosOnline;
		});

		/*
		** Usuário desconectado, remove da sala
		** Remove apenas se desconectar para não dar a impressão
		** que ele ficou offline
		** ================================================== */
		socket.on('disconnected', e => {
			const _usuariosOnline = chatSalaRemoverUsuarioOnline(e.data, usuariosOnlineRef.current);
			setUsuariosOnline(_usuariosOnline);
			usuariosOnlineRef.current = _usuariosOnline;
		});

		/*
		** Mensagens lidas
		** ================================================== */
		socket.on('messagesReaded', dados => {
			if(dados.data.id_user !== store.usuario.id) {
				const mensagensLidas = mensagensChatRef.current.map(val => {
					return {
						...val,
						mensagemLida: true,
					}
				});
				setMensagensChat(mensagensLidas);
				mensagensChatRef.current = mensagensLidas;
			}
		});

		/*
		** Proposta aceita
		** ================================================== */
		socket.on('acceptedPropose', async dados => {
			if(dados.success) {
				const idTarefa = stateGetRef.current.id_task
				const retGetTarefa = await apiTasks.getOne.get(idTarefa);
				if(retGetTarefa.status === 200) {
					setStateTarefa(retGetTarefa.data);
				}
			}
		});

		/*
		** Recebe quem está conectado na
		** ================================================== */
		socket.on('usersConnected', async dados => {
			const _usuariosOnline = chatSalaAddUsuariosOnline(dados.data, {
				id_freelancer: store.usuario.id_freelancer,
			});
			setUsuariosOnline(_usuariosOnline);
			usuariosOnlineRef.current = _usuariosOnline;
		});

		/*
		** Se conecta na sala para receber as mensagens
		** ================================================== */
		socket.emit('message', {
			data: {
				id_task: stateGetRef.current.id_task,
				id_freelancer: store.usuario.id_freelancer,
			},
			context: 'TasksMessages',
			function: 'joinRoom'
		});

		/*
		** Verifica quem está online ao se conectar na sala
		** ================================================== */
		socket.emit('message', {
			data: {
				id_task: stateGetRef.current.id_task,
				id_freelancer: store.usuario.id_freelancer,
			},
			context: 'TasksMessages',
			function: 'getUsersConnected'
		});
	}, [socket]);

	/*
	** API - Get Dados da tarefa
	** ================================================== */
	const getTarefa = async (dadosObj = {}) => {
		const idTarefa = dadosObj.id_task;

		if(!idTarefa || !isNumeric(idTarefa)) {
			msg.error('O id da tarefa deve ser informado.');
			_setStateTarefa(msgStatus({
				status: 403,
			}, {
				msg: 'O id da tarefa deve ser informado.',
			}));
			return;
		}

		setLoadingGetTarefa(true);
		const retGetTarefa = await apiTasks.getOne.get(idTarefa);
		_setStateTarefa(msgStatus(retGetTarefa));
		setLoadingGetTarefa(false);

		if(retGetTarefa.status === 200) {
			// Taxa porcentagem
			setTaxaPorcentagem(retGetTarefa.data.customer_tax_percent ? retGetTarefa.data.customer_tax_percent : 12);

			setStateTarefa(retGetTarefa.data);
			getMensagensChat(retGetTarefa.data, dadosObj);
		} else {
			msgs([retGetTarefa]);
			setLoadingGetMensagensChat(false);
			setMensagensChat(400);
		}

	}

	/*
	** API - Get mensagens
	** ================================================== */
	const getMensagensChat = async (dados, dadosObj) => {

		setLoadingGetMensagensChat(true);

		/*
		** Get mensagens do Briefing antes das mensagens do chat
		** ================================================== */
		var mensagensFormatadas = [];
		var retGetMensagensBriefing = false;

		if(dados.id_briefing) {
			retGetMensagensBriefing = await apiBriefingMessages.all.get({
				id_briefing: dados.id_briefing,
				id_freelancer: dados.id_freelancer,
				limit: 9999
			});
		}

		if(retGetMensagensBriefing && retGetMensagensBriefing.status === 200) {
			mensagensFormatadas = retGetMensagensBriefing.data.map(val => {
				var propria = false;
				if(!val.id_user) {
					propria = true;
				}
				return {
					...formatarMensagemChat(val),
					propria: propria,
				};
			})
		} else if(retGetMensagensBriefing && retGetMensagensBriefing.status === 403) {
			mensagensFormatadas = [formatarMensagemChat({
				created_at: null,
				metadata: {
					titulo: 'Aviso',
					tipo: 'vermelho',
					icone: 'error',
				},
				text: 'Você não tem permissão para acessar as mensagens referente a proposta de job deste orçamento.',
				type: 'sistema',
			})];
		}

		const retGetMensagens = await apiTasksMessages.all.get({
			id_task: dados.id,
			// id_freelancer: store.usuario.id_freelancer,
			limit: 9999
		});

		if(mensagensFormatadas.length) {
			mensagensFormatadas.push(formatarMensagemChat({
				created_at: null,
				metadata: {
					titulo: 'Aviso',
					tipo: 'default',
				},
				text: 'As mensagens acima são referentes as "Propostas de jobs". As mensagens referentes ao "Job" serão listadas abaixo (caso houver).',
				type: 'sistema',
			}));
		}

		if(retGetMensagens.status === 200) {
			mensagensFormatadas.push(...retGetMensagens.data.map(val => {
				var propria = false;
				if(!val.id_user) {
					propria = true;
				}
				return {
					...formatarMensagemChat(val),
					propria: propria,
				};
			}));
			marcarMensagemComoLida({
				id_task: dadosObj.id_task,
				id_freelancer: dados.id_freelancer,
			});
		}

		setMensagensChat(mensagensFormatadas);
		mensagensChatRef.current = mensagensFormatadas;
		setLoadingGetMensagensChat(false);
	}

	/*
	** Marcar mensagem como lida
	** ================================================== */
	const marcarMensagemComoLida = (dados = {}) => {
		const dadosMensagem = {
			id_task: dados.id_task,
			id_freelancer: store.usuario.id_freelancer,
			...dados,
		};

		const dadosEnviar = {
			data: dadosMensagem,
			context: 'TasksMessages',
			function: 'readingMessages'
		};

		socket.emit('message', dadosEnviar);
		setEmitirEventoMensagemLida(false);
	}

	// API - Enviar mensagem
	const enviarMensagemChat = async (dadosObj = {}) => {

		var texto = '';

		if(dadosObj.mensagem) {
			texto = dadosObj.mensagem;
		} else {
			texto = mensagemChat;
		}

		if(!texto || texto === '' || texto.trim() === '') {
			if(dadosObj.type !== 'proposta') {
				if(!dadosObj.byPassEnvio) {
					return;
				}
			}
		}

		const dadosEnviar = {
			data: {
				id_freelancer: store.usuario.id_freelancer,
				id_task: stateGet.id_task,
				// active: 0,
				text: texto,
				readed: 0,
				type: dadosObj.type ? dadosObj.type : 'default',
				...(dadosObj.metadata ? {metadata: dadosObj.metadata} : {}),
			},
			context: 'TasksMessages',
			function: 'postMessage'
		};

		socket.emit('message', dadosEnviar);
		setMensagemChat('');
	}

	const digitandoChat = () => {
		socket.emit('message', {
			data: {
				id_freelancer: store.usuario.id_freelancer,
				id_task: stateGet.id_task,
			},
			context: 'TasksMessages',
			function: 'userTyping',
		});
	}

	const infoPagamento = dados => {
		if(dados.status === 15 && !dados.payday) {
			return 'Pagamento realizado';
		}
		if(!dados.payday) {
			return 'Pagamento pendente';
		}
		if(validarDataAPI(dados.payday)) {
			if(moment(dados.payday).utc().toDate() > moment().utc().toDate()) {
				return `${dataApiParaBr(dados.payday)} (em ${dataHaQuantoTempo(dados.payday)})`;
			}
			return `${dataApiParaBr(dados.payday)} (pago há ${dataHaQuantoTempo(dados.payday)})`;
		}

		return 'Pagamento realizado';
	}

	// API - Enviar mensagem
	const enviarProposta = async (dados = {}) => {
		const dadosEnviar = {
			data: {
				id_freelancer: store.usuario.id_freelancer,
				id_user: stateTarefa.id_user,
				id_task: stateGet.id_task,
				// active: 0,
				text: dados.mensagem,
				readed: 0,
				type: 'proposta',
				metadata: {
					deadline_days: dados.prazo && dados.prazo !== '' ? dados.prazo : 0,
					price: dados.valorComPorcentagem,
					liquid_price: dados.valor,
					proposta: dados.proposta,
				},
			},
			context: 'TasksMessages',
			function: 'postMessage'
		};
		socket.emit('message', dadosEnviar);
		setMensagemChat('');
	}

	return (
		<div className="tela-detalhesjob">
			<div className="pg-negocjob-wrapper">
				<div className="titulo-box titulo-box-res">
					<div className="pg-negocjob-titulo-box">
						<Voltar
							desc="Visualizar todos os jobs"
							link={location.state ? `/meus-jobs?${qs.stringify(location.state)}` : '/jobs'}
						/>
						{_stateTarefa.status !== 200 ? (
							<h3 className="titulo text-one-line">Negociar job</h3>
						) : (
							<div className="pg-negocjob-titulo-inner">
								<h3 className="titulo text-one-line">{stateTarefa.title}</h3>
								<div className="pg-negocjob-titulo-status td-desc">
									<Icone imagem={iconeStatusCard(stateTarefa.status).icone} tamanho="16px" cor={iconeStatusCard(stateTarefa.status).cor} />
									<span className="margin-l-5">{stateTarefa.status_name}</span>
								</div>
							</div>
						)}
					</div>
					{_stateTarefa.status === 200 ? (
						<div className="cpnt-btnadd-wrap pg-negocjob-titulo-sep-box">
							<div className="pg-negocjob-titulo-sep">
								<Tip
									tip={
										<TextoIconeLink
											icone={imgIconeInfo}
											texto="Detalhes"
											className="a"
											iconeTamanho="18px"
											iconeCor="#0277BD"
											onClick={e => setShowPortalDetalhes(true)}
										/>
									}
									info
								>
									Detalhes do job
								</Tip>
							</div>
							{stateTarefa.id_briefing ? (
								<div className="pg-negocjob-titulo-sep">
									<Tip
										tip={
											<TextoIconeLink
												icone={imgIconeContratado}
												texto="Proposta"
												className="a"
												iconeTamanho="18px"
												iconeCor="#0277BD"
												onClick={e => setShowPortalProposta(true)}
											/>
										}
										info
									>
										Esse job faz parte de uma proposta de job
									</Tip>
								</div>
							) : null}
						</div>
					) : null}
				</div>
			</div>

			<div className="tela-negociarjob-container">
				<div className="tela-negociarjob-info-box box-default overflow">
					<div className="tela-negociarjob-info-wrap">
						<div className="tela-detalhejob-info-hunter">

							{_stateTarefa.status !== 200 ? (
								<>
									<div className="tela-detalhejob-info-hunter">
										<ImgTituloDesc
											wrap="a td-info"
											titulo={loadingGetTarefa ? 'Carregando dados' : 'Dados indisponíveis'}
											desc={loadingGetTarefa ? 'Aguarde...' : 'Não foi possível carregar as informações'}
											placeholder='userpicture'
											classNameTitulo="td-titulo"
											classNameDesc="td-desc"
											classNameImgBox="foto-wrap"
											responsivo={true}
										/>
									</div>
								</>
							) : (
								<>
									<div className="tela-detalhejob-info-hunter" data-tour="responsavel">
										<ImgTituloDesc
											wrap="a td-info"
											titulo={stateTarefa.user_name}
											desc="Responsável por esse job"
											img={caminhoFoto(stateTarefa.user_photo).url}
											placeholder='userpicture'
											classNameTitulo="td-titulo"
											classNameDesc="td-desc"
											classNameImgBox="foto-wrap"
											responsivo={true}
											onClick={() => {
												setShowPortalPerfilCompleto(true);
											}}
										/>
									</div>
								</>
							)}
						</div>
					</div>
					<div className="tela-negociarjob-info-dados relative box-txt scroll scroll-alt">
						{loadingGetTarefa ? (
							<Loading
								texto="Carregando, aguarde..."
							/>
						) : (
							_stateTarefa.status === 200 ? (
								<div>
									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-0">{stateTarefa.title || '(título não informado)'}</h3>
									</div>

									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-0">Valor</h3>
										<div className="flex flex-center-v margin-t-2">
											<Icone imagem={imgIconeValor} tamanho="15px" cor="#000" />
											<div className="margin-l-5"><strong>Líquido:</strong> <span className="color-green">{formatarDinheiro(stateTarefa.liquid_price, 'R$ ')}</span></div>
										</div>
										<div className="flex flex-center-v margin-t-2">
											<Icone imagem={imgIconeValor} tamanho="15px" cor="#000" />
											<div className="margin-l-5"><strong>Bruto:</strong> {formatarDinheiro(stateTarefa.price, 'R$ ')}</div>
										</div>
									</div>

									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Datas e Prazos</h3>
										<div>
											<LinhaItem
												titulo="Criado em"
												desc={dataApiParaBr(stateTarefa.created_at, 'Não informado')}
												alt
											/>
											<LinhaItem
												titulo="Iniciado em"
												desc={dataApiParaBr(stateTarefa.start_date, 'Não informado')}
												alt
											/>
											<LinhaItem
												titulo="Aprovação em"
												desc={dataApiParaBr(stateTarefa.start_date, 'Não informado')}
												alt
											/>
										</div>
									</div>

									<div className="tela-detalhejob-sec box-txt">
										<div className="flex flex-between flex-center-v">
											<div>
												<h3 className="margin-0">Status do Job</h3>
												<div className="flex flex-center-v">
													<Icone imagem={iconeStatusCard(stateTarefa.status).icone} tamanho="16px" cor={iconeStatusCard(stateTarefa.status).cor} />
													<div className="margin-l-5">{stateTarefa.status_name}</div>
												</div>
											</div>
										</div>
									</div>

									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-0">Status do pagamento</h3>
										<div className="flex flex-center-v">
											<Icone imagem={imgIconeValor} tamanho="16px" cor="#229d3e" />
											<div className="margin-l-5">{infoPagamento(stateTarefa)}</div>
										</div>
									</div>

									{/* <div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Nota Fiscal</h3>
										<div className="flex flex-center-v">
											<Icone imagem={imgIconePDF} tamanho="16px" cor="#AD0B00" />
											<div className="margin-l-5">
												<strong className="margin-r-5">Arquivo PDF:</strong>
												{stateTarefa.invoice_pdf ? (
													<a href={caminhoUrl(stateTarefa.invoice_pdf)} target="_blank" rel="noreferrer">Visualizar PDF</a>
												) : (
													<>Não enviado</>
												)}
											</div>
										</div>
										<div className="flex flex-center-v margin-t-5">
											<Icone imagem={imgIconeXML} tamanho="16px" cor="#0277BD" />
											<div>
												<strong className="margin-r-5">Arquivo XML:</strong>
												{stateTarefa.invoice_xml ? (
													<a href={caminhoUrl(stateTarefa.invoice_xml)} target="_blank" rel="noreferrer">Visualizar XML</a>
												) : (
													<>Não enviado</>
												)}
											</div>
										</div>
									</div> */}

									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Projeto</h3>
										<div>
											{stateTarefa.project_name}
										</div>
									</div>

								</div>
							) : (
								<Info
									texto={_stateTarefa.msg}
									icone="erro"
									alt
								/>
							)
						)}
					</div>
				</div>

				<div className="tela-negociarjob-chat-box">
					<Chat
						enviarMensagem={enviarMensagemChat}
						mensagem={mensagemChat}
						enviarProposta={enviarProposta}
						setMensagem={setMensagemChat}
						mensagens={mensagensChat}
						setDigitando={digitandoChat}

						isDigitando={isDigitandoChat}
						setIsDigitando={setIsDigitandoChat}
						isDigitandoInfo={isDigitandoInfoChat}
						loadingGetMensagens={loadingGetMensagensChat}

						// conversandoCom={usuariosOnline}
						// conversandoComExibirOnline={true}
						layoutExibicao="freelancer"
						exibirBotaoProposta={stateTarefa.status === 15 ? false : true}
						taxaPorcentagem={taxaPorcentagem}
					/>
				</div>
			</div>

			{/* Portal informações hunter */}
			<PortalPerfilUsuario
				id={stateTarefa.id_user}
				show={showPortalPerfilCompleto}
				onClose={() => {
					setShowPortalPerfilCompleto(false);
				}}
			/>

			<PortalCardDetalhes
				show={showPortalDetalhes}
				id={stateTarefa.id}
				showNegociar={false}
				onClose={() => setShowPortalDetalhes(false)}
			/>

			<PortalJobDetalhes
				show={showPortalProposta}
				id={stateTarefa.id_briefing}
				onClose={() => setShowPortalProposta(false)}
			/>

		</div>
	);
};

export default NegociarMeusJobs;