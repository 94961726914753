import React from 'react';
import { toast as msg } from 'react-toastify';

// Socket
import socket from '@_api/socket';

// API
import apiTasksCombos from "@api/tasksCombos/tasksCombos";

// Funções
import { formatarSelect } from "@func/utils";

// Textos
import JOB_MENSAGENS_PADROES from "@texto/jobMensagensPadroes";
import apiTemplates from "@api/templates/templates";
import { msgs, msgStatus } from "@_func/request";

/*
** API - Get status
** ================================================== */
export const getStatusJobsAdminNivelAcesso = async ({statusAtual, setStateStatus, role}) => {
	const retGetStatusTask = await apiTasksCombos.status.get();
	if(retGetStatusTask.status === 200) {
		var dadosSelect = formatarSelect({dados: retGetStatusTask.data, keyValue: 'id'});
		setStateStatus(statusJobsAdminTratarStatus(dadosSelect, statusAtual, role));
	} else {
		msgs([retGetStatusTask]);
	}
}

export const statusJobsAdminTratarStatus = (listaStatus, status, role) => {
	if(role === 1) {
		return listaStatus;
	}

	var statusHead = [
		// Cancelado
		...(status === 16 ? listaStatus.filter(item => item.value === 16) : []),

		// Aprovado
		...(status === 15 ? listaStatus.filter(item => item.value === 15) : []),

		// Execução
		...(status === 14 ? listaStatus.filter(item => item.value !== 13) : []),

		// Execução
		...(status === 13 ? listaStatus.filter(item => item.value !== 15) : []),
	];

	return statusHead;
}

export const statusJobsAdminEnviarMensagemAlteracaoStatus = async (props) => {

	// id: 13 => Em orçamento
	// id: 14 => Em execução
	// id: 15 => Aprovado
	// id: 16 => Cancelado

	const {
		idJob,
		idStatus,
		idFreelancer,
		idUser,
		statusLabel,
	} = props;

	var mensagemDefault = {
		13: JOB_MENSAGENS_PADROES.jobEmOrcamento,
		14: JOB_MENSAGENS_PADROES.jobEmExecucao,
		15: JOB_MENSAGENS_PADROES.jobAprovado,
		16: JOB_MENSAGENS_PADROES.jobCancelado,
	};

	var mensagemEnviar = {};

	if(mensagemDefault[idStatus] !== undefined) {
		mensagemEnviar = mensagemDefault[idStatus];
	}

	if(idStatus === 15) {
		const retGetTemplateSim = await apiTemplates.getOne.get(47); // 47 -> Este job foi aprovado
		if(retGetTemplateSim.status === 200) {
			mensagemEnviar = {
				titulo: retGetTemplateSim.data.metadata.title,
				mensagem: retGetTemplateSim.data.text,
				tipo: retGetTemplateSim.data.metadata.type,
			};
		}
	} else if(idStatus === 16) {
		const retGetTemplateNao = await apiTemplates.getOne.get(50); // 51 -> Este job foi cancelado
		if(retGetTemplateNao.status === 200) {
			mensagemEnviar = {
				titulo: retGetTemplateNao.data.metadata.title,
				mensagem: retGetTemplateNao.data.text,
				tipo: retGetTemplateNao.data.metadata.type,
			};
		}
	}

	const dadosEnviarChat = {
		data: {
			id_freelancer: idFreelancer,
			id_task: idJob,
			id_user: idUser,
			// active: 0,
			text: mensagemEnviar.mensagem,
			readed: 0,
			type: mensagemEnviar.tipo,
			metadata: {
				titulo: mensagemEnviar.titulo,
			}
		},
		context: 'TasksMessages',
		function: 'postMessage'
	};

	socket.emit('message', dadosEnviarChat);

	msg.success(<>Status alterado para: {statusLabel}.<br /><br />Também foi enviado uma mensagem no chat para o freelancer informando o novo status.</>);
}

export const statusJobsAdminEnviarMensagemAlteracaoTitulo = (props) => {

	// id: 13 => Em orçamento
	// id: 14 => Em execução
	// id: 15 => Aprovado
	// id: 16 => Cancelado

	const {
		idJob,
		tituloJob,
		tituloJobOld,
		idFreelancer,
		idUser,
		nomeUsuario,
	} = props;

	const dadosEnviarChat = {
		data: {
			id_freelancer: idFreelancer,
			id_task: idJob,
			id_user: idUser,
			// active: 0,
			text:
`De: <strong>${tituloJobOld}</strong>
Para: <strong>${tituloJob}</strong>
Alterado por: <strong>${nomeUsuario}</strong>
ID do usuário: <strong>ID: ${idUser}</strong>`,
			readed: 0,
			type: 'amarelo',
			metadata: {
				titulo: 'Alteração no título do job',
				id_task: idJob,
				id_user: idUser,
			}
		},
		context: 'TasksMessages',
		function: 'postMessage'
	};

	socket.emit('message', dadosEnviarChat);
}
