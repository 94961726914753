import React from 'react';
import { Link } from 'react-router-dom';

import imgIconeContratado from '@svg/light/handshake.svg';

const chatMensagensVariaveis = props => {
	const {
		modelo,
		tipoUsuario,
	} = props;

	const chatMensagensVariaveis = {};

	chatMensagensVariaveis.avisoBriefingAprovado = {
		tipo: 'azul',
		titulo: 'Parabéns, você foi contratado!',
		icone: imgIconeContratado,
		mensagem:
<>Quando o briefing é aprovado, é gerado um novo "Job" na seção de "Meus Jobs", você pode acessar seus jobs clicando no menu lateral, ou caso prefira você também pode acessá-los <Link to={`${tipoUsuario === 'freelancer' ? '/meus-jobs' : '/contratacoes'}`}>clicando aqui</Link>.</>
	};

	if(chatMensagensVariaveis[modelo]) {
		return chatMensagensVariaveis[modelo];
	}

	return {};
}

export default chatMensagensVariaveis;