import React from 'react';
import Mortal from 'react-mortal';

// CSS
import './PortalLateral.css';

// Funções
import { hash } from '@_func/generate';

const PortalLateral = props => {
    const {
		show,
		onClose,
		closeOnClickBg = false,
		closeOnEsc,
		children,
		x = true,
		id = `cpnt-portallateral-${hash()}`,
		classNameDefault = 'cpnt-portallateral-default',
		classNameWrapBox = 'cpnt-portallateral-wrap-box',
		classNameWrapBoxLeft = 'cpnt-portallateral-wrap-box-left',
		classNameWrapLateralLeft = 'cpnt-portallateral-wrap-left',
		className = '',
		classNameContainer = '',
		classNameBg = '',
		classNameX = '',
		overflow = true,
		pos = 'right',
		outside,
	} = props;

	if(overflow) {
		if(show) {
			window.document.body.classList.add('overflow');
		} else {
			window.document.body.classList.remove('overflow');
		}
	}

	const closeOnClickCheck = e => {
		if(e.target.id === id) {
			onClose();
		}
	}

    return (
		<Mortal
			isOpened={show}
			onClose={onClose}
			closeOnEsc={closeOnEsc}
			motionStyle={(spring, isVisible) => {
				if(pos === 'right') {
					return {
						opacity: spring(isVisible ? 1 : 0),
						panelOffset: spring(isVisible ? 0 : 100),
					}
				} else {
					return {
						opacity: spring(isVisible ? 1 : 0),
						panelOffset: spring(isVisible ? 0 : -100),
					}
				}
			}}
		>
			{(motion, isVisible) => {
				return (
					<div
						className={`cpnt-portallateral ${classNameContainer}`}
						style={{
							opacity: motion.opacity,
							pointerEvents: isVisible ? 'auto' : 'none',
						}}
					>
						<div
							className={`${classNameWrapBox}`}
							id={id}
							{...(closeOnClickBg ? {onClick: closeOnClickCheck} : {})}
						>
							<div
								className={`cpnt-portallateral-wrap ${pos === 'left' ? classNameWrapLateralLeft : ''}`}
								style={{
									transform: `translate3d(${motion.panelOffset}%, 0, 0)`,
								}}
							>
								<div className="cpnt-portallateral-inner">
									{x && <span className={classNameX ? classNameX : 'cpnt-portallateral-close'} onClick={onClose}></span>}
									<div className={`${classNameDefault} ${className}`}>{children}</div>
									{outside}
								</div>
							</div>
						</div>
						<div className={`cpnt-portallateral-bg ${classNameBg}`}></div>
					</div>
				)
			}}
    	</Mortal>
    )
}

export default PortalLateral