import React, { useState, useEffect } from 'react';
import codes from 'country-calling-code';
import _ from 'lodash';
import { isSupportedCountry } from 'react-phone-number-input';

import Input2 from "@cpnt/Input2/Input2";
import { Select2 } from "@cpnt/Select2/Select2";

// css
// import './PtDadosPessoais.css';
import InputTelefone2 from "@cpnt/InputTelefone2/InputTelefone2";
import { InputDate } from "@cpnt/InputDate/InputDate";

const PtDadosPessoais = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		stateDados = {},
		setStateDados,
		statePaises = [],
		loadingGetPaises,
	} = props;

	const [stateTelefone, setStateTelefone] = useState('+55');
	const [stateSiglaPaisSelecionado, setStateSiglaPaisSelecionado] = useState('BR');

	useEffect(() => {
		if(stateDados.phone && stateDados.phone !== '') {
			setStateTelefone(stateDados.phone);
		}
	}, []);

	useEffect(() => {
		if(stateTelefone !== stateDados.phone) {
			setStateDados({
				...stateDados,
				phone: stateTelefone,
			})
		}
	}, [stateTelefone]);

	return (
		<>
			<div className={`cpnt-ptdadospessoais ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-ptdadospessoais-box ${className}`}>
					<div>
						<div className="wrap-5">
							<div className="flex flex-wrap">
								<div className="input-box box-3 box-2-780 box-1-580">
									<Input2
										label="Nome completo"
										req
										_={{
											value: stateDados.name,
											onChange: e => {
												setStateDados({
													...stateDados,
													name: e.target.value,
												})
											}
										}}
									/>
								</div>
								<div className="input-box box-3 box-2-780 box-1-580">
									<InputDate
										label="Data de nascimento"
										value={stateDados.birth_date}
										onChange={e => {
											setStateDados({
												...stateDados,
												birth_date: e,
											});
										}}
									/>
								</div>
								<div className="input-box box-3 box-2-780 box-1-580">
									<Select2
										label="País"
										tip="O seu documento e endereço é baseado no país que você selecionar."
										req
										_={{
											options: statePaises,
											value: stateDados.id_country,
											isClearable: false,
											onChange: e => {
												if(e && e.value) {
													setStateDados({
														...stateDados,
														id_country: e.value,
													});

													// Pega o código de acordo com a letra
													var numeroObj = _.find(codes, {isoCode2: e.initials});
													var numero = '';

													// Define o número para modificar o campo
													if(numeroObj) {
														numero = _.get(numeroObj, 'countryCodes.0');
														if(numero && numero !== '') {
															numero = `+${numero}`;
														}
													}

													// Define os valores
													// setStateSiglaPaisSelecionado(e.initials);
													// setStateTelefone(numero);
												}
											},
										}}
										loading={loadingGetPaises}
										virtualized
									/>
								</div>
								<div className="input-box box-3 box-2-780 box-1-580">
									<InputTelefone2
										label="Telefone (com prefixo do país)"
										req
										_={{
											value: stateTelefone,
											onChange: setStateTelefone,
											international: true,
											withCountryCallingCode: true,
											country: stateSiglaPaisSelecionado,
											defaultCountry: 'BR',
											onCountryChange: siglaPais => {
												if(isSupportedCountry(siglaPais)) {
													setStateSiglaPaisSelecionado(siglaPais);
												} else {
													setStateSiglaPaisSelecionado('');
												}
											},
										}}
									/>
								</div>
								<div className="input-box box-3 box-2-780 box-1-580">
									{stateDados.id_country === 33 ? (
										<Input2
											label="CPF"
											req
											_={{
												value: stateDados.cpf_cnpj,
												onChange: e => {
													setStateDados({
														...stateDados,
														cpf_cnpj: e.target.value,
													})
												},
												mask: '999.999.999-99'
											}}
										/>
									) : (
										<Input2
											label="Documento válido"
											req
											_={{
												value: stateDados.cpf_cnpj,
												onChange: e => {
													var doc = e.target.value.replace(/[/\\$~%`´~@¨§º=^'"?<>|{}]/g, '');
													setStateDados({
														...stateDados,
														cpf_cnpj: doc,
													})
												}
											}}
										/>
									)}
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { PtDadosPessoais };