import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalSenhaAcessoFormEdicao.css';

// API
import apiFreelancer from "@api/freelancer/freelancer";

// Partes

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import apiRedefinition from "@api/redefinition/redefinition";
import { api } from "@_api/api";
import { validarSenha } from "@cpnt/CriteriosSenha/CriteriosSenha";
import FormSenha from "@cpnt/Forms/FormSenha/FormSenha";
import { camposInvalidos } from '@func/utils';
import { msgs, msgStatus } from "@_func/request";

const PortalSenhaAcessoFormEdicao = props => {

	const {
		id,
		idFreelancer,
		tipo = 'freelancer',
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-senhaacesso-form-edicao',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetPaises, setLoadingGetPaises] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({
		description: '',
	});
	const [_stateDados, _setStateDados] = useState({});
	const [stateSenha, setStateSenha] = useState({
		actual_password: '',
		new_password: '',
		password_confirmation: '',
	});

	// Edição
	const [loadingEditar, setLoadingEditar] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		setLoading(true);

		var retGetDados;

		if(tipo === 'freelancer') {
			retGetDados = await apiFreelancer.getOne.get(idFreelancer);
		} else {
			retGetDados = await api.users.getOne.get(id);
		}

		_setStateDados(msgStatus(retGetDados));

		if(retGetDados.status === 200) {
			setStateDados(retGetDados.data);
		} else {
			msgs([retGetDados]);
		}

		setLoading(false);
	}

	/*
	** Editar dados
	** ================================================== */
	const editar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			id: id,
			actual_password: stateSenha.actual_password,
			new_password: stateSenha.new_password,
			password_confirmation: stateSenha.password_confirmation,
		};

		if(camposInvalidos(dadosEnviar)) return;

		if(!validarSenha(stateSenha.new_password).valido) {
			msg.error(
				<>
					<div>A senha inserida não atende as critérios necessários.</div>
					<div className="margin-t-10">
						<ul className="margin-0">
							<li>No mínimo 8 caracteres</li>
							<li>No mínimo 1 letra maiúscula</li>
							<li>No mínimo 1 dígito</li>
							<li>No mínimo 1 caractere especial</li>
						</ul>
					</div>
				</>
			);
			return;
		}

		if(dadosEnviar.new_password !== dadosEnviar.password_confirmation) {
			msg.error('A confirmação da senha está diferente da senha digitada');
			return;
		}

		setLoadingEditar(true);

		const retUpdatePassword = await apiRedefinition.user.password.put(dadosEnviar);

		if(retUpdatePassword.status === 200) {
			msg.success('Senha alterada com sucesso!');
			onSuccess({
				ret: retUpdatePassword,
			});
		} else {
			msgs([retUpdatePassword]);
			onError({
				ret: retUpdatePassword,
			});
		}

		setLoadingEditar(false);
	}

	return (
		<Portal
			titulo="Senha de acesso"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<>
								<FormSenha
									stateSenha={stateSenha}
									setStateSenha={setStateSenha}
									email={stateDados.email}
								/>
								<div className="form-bottom">
									<Button2
										_={{
											value: 'Cancelar',
											className: 'btn btn-1',
											onClick: onClose,
										}}
									/>
									<Button2
										_={{
											value: 'Confirmar alteração',
											onClick: editar,
										}}
										wrap="margin-l-10"
										loading={loadingEditar}
									/>
								</div>
							</>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export { PortalSenhaAcessoFormEdicao };
