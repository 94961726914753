import React, { Fragment, useState, memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// css
import './ProfissionaisSelecionados.css';

// Imagens
import imgIconeX from '@svg/solid/circle-x.svg';
import imgIconeMinimizar from '@svg/solid/chevron-left.svg';
import imgIconeMaximizar from '@svg/solid/chevron-right.svg';

// Partes
import { ItemProfissionalSelecionado } from './ItemProfissionalSelecionado/ItemProfisisonalSelecionado';

// Componentes
import { ImgDesc } from '@cpnt/ImgDesc/ImgDesc';
import Tip from '@cpnt/Tip/Tip';

// Portais

// Funções
import { unificarArr } from '@func/utils';

// Rotas
import { gets, showPortal } from '@route/routeUtils';

// Lista local
import { ListaLocalSelect } from './ListaLocalSelect/ListaLocalSelect';
import { formatarGetParaListaProfissional } from "@page/Logado/CadastrarProcessoSeletivo/funcCadastrarProcessoSeletivo";
import TextoIconeLink from "@cpnt/TextoIconeLink/TextoIconeLink";
import LinhaItem from "@cpnt/LinhaItem/LinhaItem";
import Icone from "@cpnt/Icone/Icone";
import { primeiroNome } from "@_func/regex";

const ProfissionaisSelecionadosMemo = props => {

	const {
		stateGet,
		navigate,

		classNameDefault = 'cpnt-profsel-box',
		classNameWrapDefault = 'cpnt-profsel',
		classNameInner = '',
		className = '',
		classNameWrap = '',
		wrap = '',

		stateListaAtual,
		setStateListaAtual,
		stateListas,
		setStateListas,
		stateSelecionados,
		stateSelecionadosAnteriormente,
		setStateSelecionadosAnteriormente,
		setStateSelecionados,
		onClickSelectListaLocal,
		onClickRemover,
		before,
		after,
		onClickLimparListaSalva,
		onClickExibirTodosSalvoELocal,
		onClickExibirTodosSalvos,
		view,
		textoEdicaoAnterior = 'Esses profissionais não fazem parte da lista salva localmente. Eles foram selecionados na edição anterior. Você também poderá removê-los antes de publicar a proposta de job caso queira.',
		showBtnRemover = true,
		btn,
	} = props;

	const [stateIsMinimizadoAnteriores, setStateIsMinimizadoAnteriores] = useState(true);
	const [stateIsMinimizadoLocal, setStateIsMinimizadoLocal] = useState(false);

	const dadosFreelancers = () => {
		var listaFormatada = stateSelecionadosAnteriormente.map(item => formatarGetParaListaProfissional(item));
		var retorno = unificarArr([listaFormatada, stateSelecionados]);
		return retorno
	}

	const tipTodosSelecionados = () => {
		return (
			<Tip
				titulo="Todos os profissionais selecionados"
				tip={
					<div className="btn-circulo a" onClick={onClickExibirTodosSalvoELocal}>
						<div className="btn-circulo-wrap">
							<span>{(unificarArr([stateSelecionadosAnteriormente, stateSelecionados]).length) > 99 ? '99+' : (unificarArr([stateSelecionadosAnteriormente, stateSelecionados]).length)}</span>
						</div>
						<div className="td-desc-alt no-wrap">
							Todos
						</div>
					</div>
				}
				info
				closeOnLeave={false}
				classNameTip="cpnt-profsel-btn-todos"
				delay={200}
			>
				<div>Todos os profissionais selecionados, unificando as duas listas e removendo os duplicados.</div>
				<div className="sep-t margin-t-15 padding-t-15 align-center">
					<LinhaItem
						titulo="Total das duas listas"
						desc={stateSelecionadosAnteriormente.length + stateSelecionados.length}
						alt
					/>
					<LinhaItem
						titulo="Selecionados anteriormente"
						desc={stateSelecionadosAnteriormente.length}
						alt
					/>
					<LinhaItem
						titulo="Lista salva localmente"
						desc={stateSelecionados.length}
						alt
					/>
				</div>
			</Tip>
		)
	}

	return (
		<>
			<div className={`${classNameWrapDefault} ${wrap} ${classNameWrap}`}>
				<div className={`${classNameDefault} ${className}`}>

					<div className="cpnt-profsel-freelas-wrapper">
						<div className={`cpnt-profsel-freelas ${classNameInner}`}>
							{before}
							{view ? (
								<>
									{tipTodosSelecionados()}
									{dadosFreelancers().map((val, key) => {
										return (
											<ItemProfisisonalSelecionadoComImg
												key={key}
												dados={val}
												onClickRemover={onClickRemover}
												stateGet={stateGet}
												navigate={navigate}
												classNameWrap="cpnt-profsel-itens-salvo"
												x={false}
											/>
										)
									})}
								</>
							) : (
								<>
									{stateSelecionadosAnteriormente && typeof(stateSelecionadosAnteriormente) === 'object' && stateSelecionadosAnteriormente.length ? (
										<>
											{/*
											** Apenas se tiver duas listas (local e anterior)
											** ================================================== */}
											{tipTodosSelecionados()}

											{/*
											** Lista dos selecionados anteriormente
											** ================================================== */}
											<Tip
												titulo="Profissionais selecionados anteriormente"
												tip={
													<div className="btn-circulo a" onClick={onClickExibirTodosSalvos}>
														<div className="btn-circulo-wrap">
															<span>{stateSelecionadosAnteriormente.length > 99 ? '99+' : stateSelecionadosAnteriormente.length}</span>
														</div>
														<div className="td-desc-alt no-wrap">
															Anteriores
														</div>
													</div>
												}
												info
												closeOnLeave={false}
												classNameTip="cpnt-profsel-btn-salvo"
												delay={200}
											>
												<div>{textoEdicaoAnterior}</div>
												{showBtnRemover ? (
													<div className="sep-t margin-t-15 padding-t-15 align-center">
														<TextoIconeLink
															texto="Remover perfis salvos da lista final"
															icone={imgIconeX}
															iconeCor="red"
															onClick={onClickLimparListaSalva}
															className="item-hover"
														/>
													</div>
												) : null}
											</Tip>
										</>
									) : null}

									{stateSelecionadosAnteriormente && typeof(stateSelecionadosAnteriormente) === 'object' && stateSelecionadosAnteriormente.length ? (
										stateSelecionadosAnteriormente.map((val, key) => {
											if(stateIsMinimizadoAnteriores && key >= 3 && stateSelecionadosAnteriormente.length > 3) {
												return <Fragment key={key}></Fragment>;
											}
											var _val = formatarGetParaListaProfissional(val);
											return (
												<ItemProfisisonalSelecionadoComImg
													key={key}
													dados={_val}
													onClickRemover={onClickRemover}
													stateGet={stateGet}
													navigate={navigate}
													classNameWrap="cpnt-profsel-itens-salvo"
													x={!view}
												/>
											)
										}
									)) : null}

									{stateSelecionadosAnteriormente.length > 3 ? (
										<Tip
											titulo={stateIsMinimizadoAnteriores ? 'Expandir' : 'Minimizar'}
											tip={
												<div className="btn-circulo a" onClick={e => setStateIsMinimizadoAnteriores(!stateIsMinimizadoAnteriores)}>
													<div className="btn-circulo-wrap border-transparent cpnt-profsel-itens-salvo">
														{stateIsMinimizadoAnteriores ? (
															<>+{stateSelecionadosAnteriormente.length - 3}</>
														) : (
															<Icone imagem={imgIconeMinimizar} />
														)}
													</div>
													<div className="td-desc-alt no-wrap">
														{stateIsMinimizadoAnteriores ? 'Expandir' : 'Minimizar'}
													</div>
												</div>
											}
											info
											closeOnLeave={false}
											classNameTip="cpnt-profsel-btn-salvo"
											delay={200}
										>
											{stateIsMinimizadoAnteriores ? (
												<>Expande a visualização dessa lista e exibe todos os profisisonais selecionados.</>
											) : (
												<>Minimiza a lista a esquerda para uma melhor visualização dos demais profissionais selecionados.</>
											)}
										</Tip>
									) : null}

									<Tip
										titulo="Detalhes da lista"
										tip={
											<>
												<div className="btn-circulo a" onClick={() => showPortal({portal: 'profissionais-selecionados', stateGet, navigate})}>
													<div className="btn-circulo-wrap">
														<span>{stateSelecionados.length > 99 ? '99+' : stateSelecionados.length}</span>
													</div>
													<div className="td-desc-alt no-wrap">
														Lista local
													</div>
												</div>
											</>
										}
										closeOnLeave={false}
										_={{
											delayLeave: 100
										}}
										classNameTip="cpnt-profsel-btn-local"
									>
										<div className="cpnt-profsel-lista">
											<div>
												<span>Lista atual:</span>
												<span className="margin-l-5"><strong>{stateListaAtual}</strong></span>
											</div>
											<div>
												<span>Profissionais selecionados:</span>
												<span className="margin-l-5"><strong>{stateSelecionados.length}</strong></span>
											</div>
											<div className="sep-t margin-t-10 padding-t-10"></div>
											<ListaLocalSelect onClick={onClickSelectListaLocal} />
										</div>
									</Tip>

									{stateSelecionados.length ? stateSelecionados.map((val, key) => {
										if(stateIsMinimizadoLocal && key >= 3 && stateSelecionados.length > 3) {
											return <></>;
										}
										return (
											<ItemProfisisonalSelecionadoComImg
												key={key}
												dados={val}
												onClickRemover={onClickRemover}
												stateGet={stateGet}
												navigate={navigate}
												classNameWrap="cpnt-profsel-itens-local"
											/>
										)
									}) : null}

									{stateSelecionados.length > 3 ? (
										<Tip
											titulo={stateIsMinimizadoLocal ? 'Expandir' : 'Minimizar'}
											tip={
												<div className="btn-circulo a" onClick={e => setStateIsMinimizadoLocal(!stateIsMinimizadoLocal)}>
													<div className="btn-circulo-wrap border-transparent cpnt-profsel-itens-salvo">
														{stateIsMinimizadoLocal ? (
															<>+{stateSelecionados.length - 3}</>
														) : (
															<Icone imagem={imgIconeMinimizar} />
														)}
													</div>
													<div className="td-desc-alt no-wrap">
														{stateIsMinimizadoLocal ? 'Expandir' : 'Minimizar'}
													</div>
												</div>
											}
											info
											closeOnLeave={false}
											classNameTip="cpnt-profsel-btn-salvo"
											delay={200}
										>
											{stateIsMinimizadoLocal ? (
												<>Expande a visualização dessa lista e exibe todos os profisisonais selecionados.</>
											) : (
												<>Minimiza a lista a esquerda para uma melhor visualização dos demais profissionais selecionados.</>
											)}
										</Tip>
									) : null}

									{after}
								</>
							)}


						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const ProfissionaisSelecionados = memo(ProfissionaisSelecionadosMemo);
export { ProfissionaisSelecionados };

export const ItemProfisisonalSelecionadoComImg = (props) => {
	const {
		onClickRemover,
		dados,
		stateGet,
		navigate,
		classNameWrap = '',
		className = '',
		x = true,
		onClick = e => showPortal({portal: 'profissional-perfil-freelancer', dados: e, stateGet, navigate}),
	} = props;

	return (
		<ItemProfissionalSelecionado
			dados={dados}
			onClickRemover={onClickRemover}
			classNameWrap={classNameWrap}
			x={x}
		>
			<div className={`cpnt-profsel-freela-box ${className}`}>
				<ImgDesc
					wrap="cpnt-profsel-imgdesc a td-info"
					titulo={primeiroNome(dados.nome)}
					img={dados.foto}
					preview={false}
					onClick={e => onClick(dados.id)}
					classNameImgBox="foto-wrap-2"
					tamanho="46px"
					classNameWrap="relative"
					before={x ? <div className="x x-min cpnt-profsel-imgdesc-x" onClick={e => onClickRemover(dados)}></div> : null}
				/>
			</div>
		</ItemProfissionalSelecionado>
	)
}
