import React from 'react';

// Imagens
import imgIconeAdd from '@img/icones/geral/add.png';

const BtnAdicionar = props => {
	const dados = {
		titulo: 'Adicionar',
		onClick: () => null,
		icone: imgIconeAdd,
		class: '',
		className: '',
		...props,
	};

	return (
		<button type="button" onClick={dados.onClick} className={`btn-link flex flex-center-v cursor-pointer ${dados.class} ${dados.className}`}>
			<span className="link fs-14">{dados.titulo}</span>
			{dados.icone &&
				<span className="w-15 margin-l-10">
					<img src={imgIconeAdd} alt="Add" className="block w-100p" />
				</span>
			}
		</button>
	)
}

export default BtnAdicionar;