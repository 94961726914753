import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import c from './CadastroDeEmpresasLiteAtivacao.module.css';

import Button2 from "@cpnt/Button2/Button2";
import Input2 from "@cpnt/Input2/Input2";
import TextoIconeLink from "@cpnt/TextoIconeLink/TextoIconeLink";

import imgIconeUnlock from '@svg/solid/unlock-keyhole.svg';

import { VideoPlayer } from "@_cpnt/VideoPlayer/VideoPlayer";
import { camposInvalidos, msg } from "@func/utils";
import imgGmail from '@img/icones/servicos/gmail.png';
import imgIcloud from '@img/icones/servicos/icloud.png';
import imgOutlook from '@img/icones/servicos/outlook.png';

import videoAI from '@video/crowd-ai-working.mp4';
import logo from '@img/logo.png';
import imgSucesso from '@_img/default/icone-sucesso.png';
import { DEV } from "@_config/config";
import { msgs, msgStatus } from "@_func/request";
import { api } from "@_api/api";

export const CadastroDeEmpresasLiteAtivacao = props => {

	const [stateCodigo, setStateCodigo] = useState('');
	const [stateCadastroAtivado, setStateCadastroAtivado] = useState(false);
	const [loadingAtivar, setLoadingAtivar] = useState(false);
	const [showAnimacao, setShowAnimacao] = useState(false);

	const ativarCadastro = async (e) => {
		e.preventDefault();

		if(loadingAtivar) {
			msg.warning('Por favor, aguarde...');
			return;
		}

		if(camposInvalidos({
			code: stateCodigo,
		}, {
			code: 'Código de ativação',
		})) return;

		if(stateCodigo.length <= 5 || stateCodigo.length > 6) {
			msg.error('Código inválido. O código deve conter 6 dígitos.');
			return;
		}

		setLoadingAtivar(true);

		const ret = await api.companyLite.activate.get(stateCodigo);

		if(ret.status === 200) {

			setShowAnimacao(true);
			setTimeout(() => {
				msg.success('Cadastro ativado com sucesso!');
				setStateCadastroAtivado(true);
				setShowAnimacao(false);
			}, 5000);

		} else {
			msgs([ret]);
		}

		setLoadingAtivar(false);

	}

	const paginacao = () => {
		if(DEV) {
			return (
				<div className="flex flex-center margin-t-30">
					<span className="bg-1 padding-5" onClick={e => {setStateCadastroAtivado(!stateCadastroAtivado)}}>Desativar</span> | <span className="bg-1 padding-5" onClick={e => {
						setShowAnimacao(true);
						setTimeout(() => {
							setStateCadastroAtivado(!stateCadastroAtivado);
							setShowAnimacao(false);
						}, 4000);
					}}>Ativar</span>
				</div>
			)
		}
		return '';
	}

	return (
		<div className={c['container']}>
			<div className="base">
				<div className="base-content">
					<div className={`${c['aviso-container']}`}>
						{showAnimacao ? (
							<div className={`${c['aviso-d']} ${c['aviso-box']}`}>
								<VideoPlayer
									_={{
										url: videoAI,
										playing: true,
										controls: false,
										loop: true,
										muted: true,
										style: {
											backgroundColor: '#FDFDFD',
										}
									}}
								/>
								<p className="margin-v-0 padding-t-25 align-center"><strong>IA trabalhando:</strong> Analisando mais de 30.000 talentos e selecionando os profissionais ideais para você.</p>
							</div>
						) : (
							!stateCadastroAtivado ? (
								<form
									className={`${c['aviso-e']} ${c['aviso-box']}`}
									onSubmit={ativarCadastro}
								>
									<div className={`${c['img-logo']}`}><img src={logo} alt="Logo CROWD" className="block w-100p max-w-250" /></div>
									<div className={`${c['textos']} box-txt`}>
										<h3 className={`${c['aviso-titulo']} margin-v-0 padding-t-15`}>Só falta ativar seu cadastro!</h3>
										<p className="margin-v-0 padding-t-25">Insira o código que foi enviado para o seu e-mail:</p>
									</div>
									<div className={`padding-t-30 w-250 ${c['input-code']}`}>
										<Input2
											label="Código de ativação"
											req
											_={{
												value: stateCodigo,
												onChange: e => {
													setStateCodigo(e.target.value);
												},
												mask: '999999',
											}}
										/>
									</div>
									<div className="padding-t-30">
										<Button2
											_={{
												value: <TextoIconeLink texto="Ativar cadastro" icone={imgIconeUnlock} iconeCor="#eab20e" reverso />,
												type: 'submit',
											}}
											classNameExtra={`padding-h-30 ${c['btn-liberar-acesso']}`}
											wrap={`${c['btn-liberar-acesso']}`}
											loading={loadingAtivar}
										/>
									</div>
									<div className={c['box-btns-email']}>
										<a href="https://gmail.com" target="_blank" rel="noreferrer" className={c['btn-email']}>
											<img src={imgGmail} alt="Gmail" />
											<span>Gmail</span>
										</a>
										<a href="https://outlook.live.com/" target="_blank" rel="noreferrer" className={c['btn-email']}>
											<img src={imgOutlook} alt="Outlook" />
											<span>Outlook</span>
										</a>
										<a href="https://www.icloud.com/mail" target="_blank" rel="noreferrer" className={c['btn-email']}>
											<img src={imgIcloud} alt="iCloud mail" />
											<span>iCloud mail</span>
										</a>
									</div>
								</form>
							) : (
								<>
									<div className={`${c['aviso-e']} ${c['aviso-box']}`}>
										<div><img src={logo} alt="Logo CROWD" className="block w-100p max-w-250" /></div>
										<div className={c['textos']}>
											<div className={`flex flex-center-v margin-t-30 gap-15 ${c['box-texto-img-sucesso']}`}>
												<div><img src={imgSucesso} alt="Sucesso" className={`block w-100p ${c['img-sucesso']}`} /></div>
												<h3 className={`${c['aviso-titulo']} margin-v-0`}>Match realizado com sucesso!</h3>
											</div>
											<p className="margin-v-0 padding-t-25">Em até 12 horas você receberá propostas. Vamos notificar você por email.</p>
											<p>Obs: Fique atento a caixa de spam." Ai em baixo mostrar o botão efetuar login na plataforma</p>
											<Link
												to="/login"
												className={`btn btn-default padding-h-30 margin-t-30 ${c['btn-liberar-acesso']}`}
											>
												Fazer login
											</Link>
										</div>
									</div>
								</>
							)
						)}
					</div>
					{paginacao()}
				</div>
			</div>
		</div>
	)
};