import { validarRetorno, config } from '@_api/axios';
import api from 'axios';

const apiUsers = {
	insert: {
		post: async dados => {
			return await api.post(`/users/insert`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	update: {
		put: async dados => {
			return await api.put(`/users/update`, dados, config(true))
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getOne: {
		get: async id => {
			return await api.get(`/users/getOne/${id}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getAllUsers: {
		get: async (idEmpresa, ativo = 1) => {
			return await api.get(`/users/getAllUsers/${idEmpresa}/${ativo}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	confirm: {
		post: async dados => {
			return await api.post(`/users/confirm`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	foto: {
		delete: async id => {
			return await api.delete(`/users/foto/${id}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	uploadPhoto: {
		post: async dados => {
			return await api.post(`/users/uploadPhoto`, dados, config(true))
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	validateAccess: {
		get: async () => {
			return await api.get(`/users/validateAccess`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export { apiUsers };