import { components } from 'react-select';

// CSS
import c from './OptionDefault.module.scss';

const { Option } = components;

export const OptionDefault = (props) => {
	return (
		<Option {...props}>
			<div className={`${c.container}`}>
				{props.data.icon &&
					<div className={`${c['icone-wrap']}`}>
						<div className={`${c['icone']}`} style={{backgroundImage: `url(${props.data.icon})`}}></div>
					</div>
				}
				<div className={`${props.data.req === true ? c['req'] : ''}`}>{props.data.label}</div>
			</div>
		</Option>
	)
};
