import React from 'react';

// css
import c from './PortalTermosContratacaoSegura.module.css';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Portal from '@cpnt/Portal/Portal';

export const PortalTermosContratacaoSegura = props => {

	const {
		show,
		onClose,
		closeOnEsc = true,
		portalClassName = c['portal'],
	} = props;

	return (
		<Portal
			titulo="Garantia de Contratação Segura CROWD"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<div className="box-txt">
				<ul className="lista-check">
					<li><strong>Aprovação do Trabalho:</strong> Pagamento liberado após sua aprovação final.</li>
					<li><strong>Cessão de Propriedade Intelectual:</strong> Todos os direitos, incluindo o código-fonte do trabalho produzido, são propriedades do contratante. </li>
					<li><strong>Confidencialidade:</strong> O profissional compromete-se com a proteção das suas informações.</li>
					<li><strong>Ausência de Vínculo Trabalhista:</strong> O profissional assina um termo de não-vínculo, assegurando flexibilidade e conformidade legal.</li>
				</ul>
			</div>
			<div className="form-bottom">
				<Button2
					_={{
						value: 'Fechar',
						onClick: onClose,
					}}
				/>
			</div>
		</Portal>
	);
};
