import React from 'react';

// css
import './UsuariosQtdResultado.css';

const UsuariosQtdResultado = props => {
	const {
		stateUsuarios,
		className = '',
		wrap = '',
	} = props;

	return (
		<div className={`cpnt-usuariosqtdresultado ${wrap}`}>
			<div className={`cpnt-usuariosqtdresultado-inner ${className}`}>
				<div className="cpnt-usuariosqtdresultado-box">
					<div className="cpnt-usuariosqtdresultado-item">Total de usuários: {stateUsuarios.length}</div>
				</div>
				<div className="cpnt-usuariosqtdresultado-box">
					<div className="cpnt-usuariosqtdresultado-item">Administradores: {stateUsuarios.filter(item => item.role === 1).length || '0'}</div>
					<div className="cpnt-usuariosqtdresultado-item">Hunters: {stateUsuarios.filter(item => item.role !== 1).length || '0'}</div>
					<div className="cpnt-usuariosqtdresultado-item">Ativos: {stateUsuarios.filter(item => item.active === 1).length || '0'}</div>
					<div className="cpnt-usuariosqtdresultado-item">Inativos: {stateUsuarios.filter(item => !item.active).length || '0'}</div>
				</div>
			</div>
		</div>
	);
};

export { UsuariosQtdResultado };