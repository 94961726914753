import { validarRetorno, config } from '../axios';
import api from 'axios';
import qs from 'qs';

const apiCompanyIndependent = {
	insert: {
		post: async dados => {
			return await api.post(`/companyIndependent/insert`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	validation: {
		post: async dados => {
			return await api.post(`/companyIndependent/validation`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	activate: {
		put: async dados => {
			return await api.put(`/companyIndependent/activate`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiCompanyIndependent;