import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast as msg } from 'react-toastify';
import moment from 'moment/min/moment-with-locales';
import qs from 'qs';
import debounce from "debounce-promise";
import _ from "lodash";

// Store e Hooks
import StoreContext from '@/_/contexts/StoreContext';
import useWindowFocus from '@/_/hooks/useWindowFocus';

// Textos
import JOB_MENSAGENS_PADROES from '@texto/jobMensagensPadroes';

// css
import './NegociarJobs.css';

// API
import socket from '@_api/socket';
import apiTasksMessages from '@api/tasksMessages/tasksMessages';
import apiTasks from '@api/tasks/tasks';
import apiBriefingMessages from '@api/briefingMessages/briefingMessages';
import apiTasksCombos from '@api/tasksCombos/tasksCombos';

// Componentes
import Chat from '@cpnt/Chat/Chat';
import Foto from '@cpnt/Foto/Foto';
import TextoWrap from '@cpnt/TextoWrap/TextoWrap';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';
import Icone from '@cpnt/Icone/Icone';
import LinhaItem from '@cpnt/LinhaItem/LinhaItem';
import PortalPerfil from '@portalGeral/PortalPerfil/PortalPerfil';
import Portal from '@cpnt/Portal/Portal';
import Button2 from '@cpnt/Button2/Button2';
import Equipe from '@cpnt/Equipe/Equipe';
import Loading from '@cpnt/Loading/Loading';
import StatusRadio from '@cpnt/StatusRadio/StatusRadio';
import { iconeStatusCard } from '@cpnt/StatusCards/StatusCards';
import Input2 from '@cpnt/Input2/Input2';
import Radio2 from '@cpnt/Radio2/Radio2';
import ChatMensagem from '@cpnt/Chat/ChatMensagem/ChatMensagem';

// Imagens
import imgIconeVoltar from '@svg/solid/arrow-left.svg';
import imgIconeValor from '@svg/solid/sack-dollar.svg';
import imgIconeEditar from '@svg/regular/pen.svg';
import imgIconeDetalhes from '@svg/regular/file-lines.svg';
import imgIconeProjeto from '@svg/regular/table-tree.svg';
import imgIconePDF from '@svg/solid/file-pdf.svg';
import imgIconeXML from '@svg/solid/file-code.svg';
import imgIconeInfo from '@svg/solid/circle-info.svg';

// Funções
import { formatarMensagemChat, fotoAleatoria } from '@shared/functions/components';
import { caminhoFoto, caminhoUrl, camposInvalidos, dataApiParaBr, dataHaQuantoTempo, formatarDinheiro, htmlToJsx, sanitize, tamanhoTela, validarDataAPI } from '@func/utils';
import notificacao from '@_func/notification';
import { chatFormatarDadosUsuario, chatSalaAddUsuarioOnline, chatSalaAddUsuariosOnline, chatSalaRemoverUsuarioOnline } from '@cpnt/Chat/functions';

import { hash } from "@_func/generate";

// Config
import templatesBriefings from '@config/templateBriefings';
import { PortalCardDetalhes } from "@cpnt/Portais/PortaisProcessoSeletivo/PortalCardDetalhes/PortalCardDetalhes";
import { initFuncExec, setGet } from "@route/routeUtils";
import Voltar from "@cpnt/Voltar/Voltar";
import Info from "@cpnt/Info/Info";
import Tip from "@cpnt/Tip/Tip";
import { PortalPerfilFreelancer } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilFreelancer";
import PortalProjetosDetalhes from "@cpnt/Portais/PortaisProjetos/PortalProjetosDetalhes/PortalProjetosDetalhes";
import { Select3Lista } from "@cpnt/Select3/Select3";
import { statusJobsAdminEnviarMensagemAlteracaoStatus, statusJobsAdminTratarStatus } from "@page/Logado/JobsAdmin/funcJobsAdmin";
import useWindowDimension from "@/_/hooks/useWindowDimensions";
import useDebounce from "@/_/hooks/useDebounce";
import ImgTituloDesc from "@cpnt/ImgTituloDesc/ImgTituloDesc";
import apiTemplates from "@api/templates/templates";
import { msgs, msgStatus } from "@_func/request";
import { PortalTermosContratacaoSegura } from "@cpnt/Portais/PortaisTermos/PortalTermosContratacaoSegura/PortalTermosContratacaoSegura";

const NegociarJobs = () => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	const janelaAtiva = useWindowFocus();
	const screen = useWindowDimension();

	const [isDigitando, setIsDigitando] = useState(false);
	const debounceEvento = useDebounce(isDigitando, 500);

	/*
	** Debounce - Para não ficar enviando o evento a todo momento
	** ================================================== */
	useEffect(() => {
		socket.emit('message', {
			data: {
				id_freelancer: stateGet.id_freelancer,
				id_task: stateGet.id_task,
			},
			context: 'TasksMessages',
			function: 'userTyping',
		});
	}, [debounceEvento]);

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = (getsAtualizados = getsDefault) => {
		initFuncExec([
			{
				func: getsIniciais,
				param: getsAtualizados
			},
		], init, location, stateLocation);
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		overlay: [],
		id_user: null,
		id_freelancer: null,
		id_task: null,
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	/*
	** Loading
	** ================================================== */
	const [loadingGetTarefa, setLoadingGetTarefa] = useState(false);
	const [showPortalTermosContratacaoSegura, setShowPortalTermosContratacaoSegura] = useState(false);

	/*
	** States
	** ================================================== */

	const [stateTarefaMetadata, setStateTarefaMetadata] = useState({});

	const [showPortalPerfilCompleto, setShowPortalPerfilCompleto] = useState(false);
	const [showPortalTextoBriefing, setShowPortalTextoBriefing] = useState(false);
	const [showPortalEquipeProjeto, setShowPortalEquipeProjeto] = useState(false);
	const [stateIdUsuarioPerfilCompleto, setStateIdUsuarioPerfilCompleto] = useState(false);
	const [showPortalDetalhes, setShowPortalDetalhes] = useState(false);

	// Popup
	const [showPopupAlterarStatus, setShowPopupAlterarStatus] = useState(false);
	const janelaAtivaRef = useRef();

	/*
	** States
	** ================================================== */
	const [stateTarefa, setStateTarefa] = useState({});
	const [_stateTarefa, _setStateTarefa] = useState({});

	const [loadingEnviarPdfOuXml, setLoadingEnviarPdfOuXml] = useState(false);

	const [showPortalMensagemBotaoProposta, setShowPortalMensagemBotaoProposta] = useState(false);

	const [stateProposta, setStateProposta] = useState({
		idProposta: false,
	});

	const statePropostaRef = useRef(stateProposta);

	// Briefing
	const [loadingGetStatusTarefa, setLoadingGetStatusTarefa] = useState(false);
	const [loadingAlterarStatusTarefa, setLoadingAlterarStatusTarefa] = useState(false);
	const [stateStatusTarefa, setStateStatusTarefa] = useState([]);

	const [taxaPorcentagem, setTaxaPorcentagem] = useState(0);

	const [showPortalDetalhesProjeto, setShowPortalDetalhesProjeto] = useState(false);

	/*
	** Chat
	** ================================================== */

	// Debug
	if(window.location.host === 'localhost:3000') {
		// localStorage.debug = 'socket.io-client:socket';
	}

	// States
	const [mensagensChat, setMensagensChat] = useState([]);
	const [mensagemChat, setMensagemChat] = useState('');
	const [usuariosOnline, setUsuariosOnline] = useState([]);
	const [isDigitandoChat, setIsDigitandoChat] = useState(false);
	const [isDigitandoInfoChat, setIsDigitandoInfoChat] = useState(false);
	const [loadingGetMensagensChat, setLoadingGetMensagensChat] = useState(true);

	const [emitirEventoMensagemLida, setEmitirEventoMensagemLida] = useState(false);
	const mensagensChatRef = useRef([]);
	const usuariosOnlineRef = useRef([]);

	const [loadingGetMensagemProposta, setLoadingGetMensagemProposta] = useState(false);
	const [stateTemplateSim, setStateTemplateSim] = useState({});
	const [stateTemplateNao, setStateTemplateNao] = useState({});

	const stateGetRef = useRef(stateGet);

	useEffect(() => {
		stateGetRef.current = stateGet;
	}, [stateGet]);

	/*
	** Init
	** ================================================== */
	const getsIniciais = async (dadosObj = {}) => {
		const retGetTarefa = await getTarefas(dadosObj.id_task);
		if(retGetTarefa.status === 200) {
			getMensagensChat({
				id_freelancer: dadosObj.id_freelancer,
				id_task: dadosObj.id_task,
				id_briefing: dadosObj.id_briefing,
			});
			getStatusBriefing();
			setTimeout(() => {
				marcarMensagemComoLida({
					id_task: dadosObj.id_task,
					id_freelancer: dadosObj.id_freelancer,
				});
			}, 2000);
		}
	}

	/*
	** Chat - inicializar
	** ================================================== */
	useEffect(() => {
		if(!socket.connected) {
			socket.auth.token = store.token;
  			socket.connect();
		}
	}, []);

	/*
	** Chat - sair da sala
	** ================================================== */
	useEffect(() => {
		return () => {
			socket.emit('message', {
				data: {
					id_task: stateGet.id_task,
					id_freelancer: stateGet.id_freelancer,
				},
				context: 'TasksMessages',
				function: 'leaveRoom'
			});
		}
	}, []);

	/*
	** Alterar estado do focus
	** ================================================== */
	useEffect(() => {
		janelaAtivaRef.current = janelaAtiva;
	}, [janelaAtiva]);

	/*
	** Verifica se tem novas mensagens e emite evento
	** ================================================== */
	useEffect(() => {
		if(emitirEventoMensagemLida) {
			marcarMensagemComoLida({
				id_task: stateGet.id_task,
				id_freelancer: stateGet.id_freelancer,
			});
		}
	}, [janelaAtiva]);

	useEffect(() => {
		if(janelaAtivaRef.current) {
			marcarMensagemComoLida({
				id_task: stateGet.id_task,
				id_freelancer: stateGet.id_freelancer,
			});
		}
	}, [emitirEventoMensagemLida]);

	/*
	** Chat - eventos
	** ================================================== */
	useEffect(() => {


		/*
		** Digitando
		** ================================================== */
		socket.on('typingTaskMessage', e => {
			setIsDigitandoChat(true);
			setIsDigitandoInfoChat(chatFormatarDadosUsuario(e.data));
		});

		/*
		** Nova mensagem do outro usuário - insere no chat
		** ================================================== */
		socket.on('getMessage', dados => {
			setIsDigitandoChat(false);

			var mensagensAtualizadas = [...mensagensChatRef.current];
			mensagensAtualizadas.push({
				...formatarMensagemChat(dados.data),
				propria: false,
			});
			setMensagensChat(mensagensAtualizadas);
			mensagensChatRef.current = mensagensAtualizadas;
			setEmitirEventoMensagemLida(true);

			if(!janelaAtivaRef.current) {
				var foto;
				var nome;

				if(dados.data.id_user) {
					foto = caminhoFoto(dados.data.photo_user).url;
					nome = dados.data.name_user;
				} else {
					if(dados.data.name_user_freelancer) {
						nome = dados.data.name_user_freelancer;
						foto = caminhoFoto(dados.data.photo_user_freelancer).url;
					} else {
						nome = dados.data.name_freelancer;
						foto = caminhoFoto(dados.data.photo_user_freela).url;
					}
				}

				if(dados.data.type === 'imagem-url') {
					notificacao(nome, {
						body: `${nome} enviou uma imagem`,
						icon: dados.data.metadata.imagem.preview
					});
				} else if(dados.data.type === 'nota-fiscal-xml' || dados.data.type === 'nota-fiscal-pdf') {
					notificacao(nome, {
						body: `${nome} enviou uma nota fiscal.`,
						icon: foto
					});
				} else {
					notificacao(nome, {
						body: dados.data.text && dados.data.text !== '' ? dados.data.text : 'Você recebeu uma nova mensagem.',
						icon: foto
					});
				}
			}
		});

		/*
		** Própria mensagem enviada e cadastrada, insere no chat
		** ================================================== */
		socket.on('insertMessage', dados => {
			if(dados.success) {
				var mensagensAtualizadas = [...mensagensChatRef.current];
				mensagensAtualizadas.push({
					...formatarMensagemChat(dados.data),
					propria: true,
				});
				setMensagensChat(mensagensAtualizadas);
				mensagensChatRef.current = mensagensAtualizadas;
			} else {
				msg.error(dados.message, {
					autoClose: false,
				});
			}
		});

		/*
		** Usuário conectado, exibe a imagem no topo do chat
		** ================================================== */
		socket.on('joinChatTask', e => {
			// const _usuariosOnline = chatSalaAddUsuarioOnline(e.data, usuariosOnlineRef.current);
			// setUsuariosOnline(_usuariosOnline);
			// usuariosOnlineRef.current = _usuariosOnline;
		});

		/*
		** Usuário desconectado, remove da sala
		** Remove apenas se desconectar para não dar a impressão
		** que ele ficou offline
		** ================================================== */
		socket.on('disconnected', e => {
			// const _usuariosOnline = chatSalaRemoverUsuarioOnline(e.data, usuariosOnlineRef.current);
			// setUsuariosOnline(_usuariosOnline);
			// usuariosOnlineRef.current = _usuariosOnline;
		});

		/*
		** Mensagens lidas
		** ================================================== */
		socket.on('messagesReaded', dados => {
			if(dados.data.id_user !== store.usuario.id) {
				const mensagensLidas = mensagensChatRef.current.map(val => {
					return {
						...val,
						mensagemLida: true,
					}
				});
				setMensagensChat(mensagensLidas);
				mensagensChatRef.current = mensagensLidas;
			}
		});

		/*
		** Se conecta na sala para receber as mensagens
		** ================================================== */
		socket.emit('message', {
			data: {
				id_task: stateGetRef.current.id_task,
				id_freelancer: stateGetRef.current.id_freelancer,
			},
			context: 'TasksMessages',
			function: 'joinRoom'
		});

		/*
		** Verifica quem está online ao se conectar na sala
		** ================================================== */
		socket.emit('message', {
			data: {
				id_task: stateGetRef.current.id_task,
				id_freelancer: stateGetRef.current.id_freelancer,
			},
			context: 'TasksMessages',
			function: 'getUsersConnected'
		});

		/*
		** Recebe quem está conectado na
		** ================================================== */
		socket.on('usersConnected', async dados => {

			// TELA DE ADMINISTRADOR
			// JOBS / DETALHES

			// const _usuariosOnline = chatSalaAddUsuariosOnline(dados.data, {
			// 	id_user: store.usuario.id,
			// });
			// setUsuariosOnline(_usuariosOnline);
			// usuariosOnlineRef.current = _usuariosOnline;
		});

		/*
		** Proposta aceita
		** ================================================== */
		socket.on('acceptedPropose', async dados => {
			if(dados.success) {
				confirmacaoDeRespostaMensagemChat(dados);
				const idTarefa = stateGetRef.current.id_task;
				const retGetTarefa = await apiTasks.getOne.get(idTarefa);
				if(retGetTarefa.status === 200) {
					setStateTarefa(retGetTarefa.data);
				}
			} else {
				msg.error(dados.error);
			}
		});

		/*
		** Proposta recusada
		** ================================================== */
		socket.on('refusedPropose', async dados => {
			if(dados.success) {
				confirmacaoDeRespostaMensagemChat(dados);
				const idTarefa = stateGetRef.current.id_task;
				const retGetTarefa = await apiTasks.getOne.get(idTarefa);
				if(retGetTarefa.status === 200) {
					setStateTarefa(retGetTarefa.data);
				}
			} else {
				msg.error(dados.error);
			}
		});

	}, [socket]);

	/*
	** Após enviar a proposta, envia a mensagem no chat
	** ================================================== */
	const confirmacaoDeRespostaMensagemChat = (dados) => {
		if(dados.user.id_user === store.usuario.id) {
			const mensagensComBotaoOculto = mensagensChatRef.current.map(val => {
				if(val && val.id && statePropostaRef.current.params && val.id === statePropostaRef.current.params.id) {
					if(statePropostaRef.current.resposta) {
						val.contracted = true;
					} else {
						val.refused = true;
					}
				}
				return val;
			});

			setMensagensChat(mensagensComBotaoOculto);
			mensagensChatRef.current = mensagensComBotaoOculto;

			// Envia mensagem de confirmação
			const dadosEnviar = {
				data: {
					id_freelancer: stateGetRef.current.id_freelancer,
					id_task: stateGetRef.current.id_task,
					// active: 0,
					text: statePropostaRef.current.mensagem,
					readed: 0,
					id_user: store.usuario.id,
					metadata: {
						titulo: statePropostaRef.current.titulo,
						idProposta: statePropostaRef.current.idProposta,
					},
					type: statePropostaRef.current.resposta ? 'verde' : 'vermelho',
				},
				context: 'TasksMessages',
				function: 'postMessage'
			};

			socket.emit('message', dadosEnviar);
		}
	}

	/*
	** API - Projetos Filtro
	** ================================================== */
	const getTarefas = async (idTask) => {
		if(loadingGetTarefa) {
			return;
		}
		setLoadingGetTarefa(true);

		const retGetTarefa = await apiTasks.getOne.get(idTask);

		_setStateTarefa(msgStatus(retGetTarefa));

		if(retGetTarefa.status === 200) {
			setStateTarefa(retGetTarefa.data);
			setStateTarefaMetadata(retGetTarefa.metadata);
		} else if(retGetTarefa.status === 400) { // precisa arrumar backend para 403
			setStateTarefa([]);
			setStateTarefaMetadata({});
			setMensagensChat(400);
		} else {
			setStateTarefa([]);
			setStateTarefaMetadata({});
		}

		setLoadingGetTarefa(false);
		return retGetTarefa;
	}

	/*
	** API - Get mensagens
	** ================================================== */
	const getMensagensChat = async (dadosObj = {}) => {
		setLoadingGetMensagensChat(true);

		/*
		** Get mensagens do Briefing antes das mensagens do chat
		** ================================================== */
		var retGetMensagensBriefing = {};
		if(dadosObj.id_briefing && dadosObj.id_briefing !== '') {
			retGetMensagensBriefing = await apiBriefingMessages.all.get({
				id_briefing: dadosObj.id_briefing,
				id_freelancer: dadosObj.id_freelancer,
				limit: 999
			});
		}

		var mensagensFormatadas = [];

		if(retGetMensagensBriefing.status === 200) {
			mensagensFormatadas = retGetMensagensBriefing.data.map(val => {
				var propria = false;
				// if(store.usuario.id === val.id_user) {
				if(val.id_user) {
					if(val.type !== 'proposta') {
						propria = true;
					}
				}
				return {
					...formatarMensagemChat(val),
					propria: propria,
				};
			})
		} else if(retGetMensagensBriefing.status === 403) {
			mensagensFormatadas = [formatarMensagemChat({
				created_at: null,
				metadata: {
					titulo: 'Aviso',
					tipo: 'vermelho',
					icone: 'error',
				},
				text: 'Você não tem permissão para acessar as mensagens referente a proposta de job deste orçamento.',
				type: 'sistema',
			})];
		}

		/*
		** Get mensagens do chat atual
		** ================================================== */
		const retGetMensagens = await apiTasksMessages.all.get({
			id_task: dadosObj.id_task,
			limit: 999
		});

		if(mensagensFormatadas.length) {
			mensagensFormatadas.push(formatarMensagemChat({
				created_at: null,
				metadata: {
					titulo: 'Aviso',
					tipo: 'default',
				},
				text: 'As mensagens acima são referentes as "Propostas de jobs". As mensagens referentes ao "Job" serão listadas abaixo (caso houver).',
				type: 'sistema',
			}));
		}

		if(retGetMensagens.status === 200) {
			mensagensFormatadas.push(...retGetMensagens.data.map(val => {
				var propria = false;
				// if(store.usuario.id === val.id_user) {
				if(val.id_user) {
					if(val.type !== 'proposta') {
						propria = true;
					}
				}
				return {
					...formatarMensagemChat(val),
					propria: propria,
				};
			}));
			marcarMensagemComoLida({
				id_task: dadosObj.id_task,
				id_freelancer: dadosObj.id_freelancer,
			});
		}

		setMensagensChat(mensagensFormatadas);
		mensagensChatRef.current = mensagensFormatadas;
		setLoadingGetMensagensChat(false);
	}

	/*
	** Marcar mensagem como lida
	** ================================================== */
	const marcarMensagemComoLida = (dadosObj = {}) => {
		const dadosMensagem = {
			...dadosObj,
			id_task: dadosObj.id_task,
			id_freelancer: dadosObj.id_freelancer,
		};

		const dadosEnviar = {
			data: dadosMensagem,
			context: 'TasksMessages',
			function: 'readingMessages'
		};

		socket.emit('message', dadosEnviar);
		setEmitirEventoMensagemLida(false);
	}

	// API - Enviar mensagem
	const enviarMensagemChat = async (dadosObj = {}) => {

		var texto = '';

		if(dadosObj.mensagem) {
			texto = dadosObj.mensagem;
		} else {
			texto = mensagemChat;
		}

		if(!texto || texto === '' || texto.trim() === '') {
			if(dadosObj.type !== 'proposta' && dadosObj.type !== 'imagem-url') {
				return;
			}
		}

		const dadosEnviar = {
			data: {
				id_freelancer: Number(stateGet.id_freelancer),
				id_task: Number(stateGet.id_task),
				id_user: store.usuario.id,
				// active: 0,
				text: texto,
				readed: 0,
				type: dadosObj.type ? dadosObj.type : 'default',
				...(dadosObj.metadata ? {metadata: dadosObj.metadata} : {}),
			},
			context: 'TasksMessages',
			function: 'postMessage'
		};

		socket.emit('message', dadosEnviar);
		setMensagemChat('');
	}

	const digitandoChat = () => {
		setIsDigitando(hash());
	}

	/*
	** Para evitar o travamento no loading
	** ================================================== */
	const onClickBtnRespostaProposta = dados => {
		if(dados.params.contracted || dados.params.refused) {
			msg.warning('Essa proposta já foi respondida anteriormente.');
			return;
		}

		// Evita o travamento do popup
		setLoadingGetMensagemProposta(true);
		setShowPortalMensagemBotaoProposta(true);

		setTimeout(() => {
			onClickBtnRespostaPropostaAcao(dados);
		}, 500);
	}

	/*
	** Mensagem - clicar no botão de ação
	** ================================================== */
	const onClickBtnRespostaPropostaAcao = async dados => {
		if(dados.params.contracted || dados.params.refused) {
			msg.warning('Essa proposta já foi respondida anteriormente.');
			return;
		}

		// Default, caso não consiga carregar o template
		var dadosResposta = {
			titulo: dados.resposta ? 'Aprovada' : 'Declinada',
			mensagem: '(não foi possível carregar o template, remova esse texto e insira sua mensagem manualmente)',
			tipo: dados.resposta ? 'verde' : 'vermelho',
		};

		setLoadingGetMensagemProposta(true);
		const retGetTemplateSim = await apiTemplates.getOne.get(44); // 47 -> Este job foi aprovado
		const retGetTemplateNao = await apiTemplates.getOne.get(51); // 51 -> Este job foi cancelado
		setLoadingGetMensagemProposta(false);

		var templateSim = dadosResposta;
		var templateNao = dadosResposta;

		if(retGetTemplateSim.status === 200) {
			templateSim = {
				titulo: retGetTemplateSim.data.title,
				mensagem: retGetTemplateSim.data.text,
				tipo: retGetTemplateSim.data.metadata.type
			};
		}

		if(retGetTemplateNao.status === 200) {
			templateNao = {
				titulo: retGetTemplateNao.data.title,
				mensagem: retGetTemplateNao.data.text,
				tipo: retGetTemplateNao.data.metadata.type
			};
		}

		setStateTemplateSim(templateSim);
		setStateTemplateNao(templateNao);

		if(dados.resposta) {
			dadosResposta = templateSim;
		} else {
			dadosResposta = templateNao;
		}

		const dadosProposta = {
			...dados,
			...dadosResposta,
			idProposta: dados.idProposta,
		};

		setStateProposta(dadosProposta);
		statePropostaRef.current = dadosProposta;

		return;
	}


	/*
	** Enviar orçamento no overlay
	** ================================================== */
	const onClickBtnEnviarRespostaProposta = () => {
		if(camposInvalidos(stateProposta, {titulo: 'Título da mensagem'}, [
			'titulo', 'mensagem', 'idProposta'
		])) {
			return;
		}

		setShowPortalMensagemBotaoProposta(false);

		const dadosEnviar = {
			data: {
				id_freelancer: Number(stateGet.id_freelancer),
				id_task: Number(stateGet.id_task),
				id_user: store.usuario.id,
				id_propose: stateProposta.idProposta,
			},
			context: 'TasksMessages',
			function: stateProposta.resposta === true ? 'acceptPropose' : 'refusePropose',
		};

		if(socket.emit) {
			socket.emit('message', dadosEnviar);
		}

	}

	/*
	** API - Status do briefing
	** ================================================== */
	const getStatusBriefing = async () => {
		setLoadingGetStatusTarefa(true);
		const retGetStatus = await apiTasksCombos.status.get();
		if(retGetStatus.status === 200) {
			setStateStatusTarefa([...retGetStatus.data.map(val => {
				return {
					label: val.name,
					value: val.id,
				}
			})]);
		} else {
			msgs([retGetStatus]);
			setStateStatusTarefa([]);
		}
		setLoadingGetStatusTarefa(false);
	}

	/*
	** API - Alterar status da tarefa
	** ================================================== */
	const alterarStatusTarefa = async dadosObj => {
		if(loadingAlterarStatusTarefa) {
			msg.warning('Por favor, aguarde...');
			return;
		}

		if(dadosObj.idStatus === dadosObj.idStatusAntigo) {
			return;
		}

		const dadosEnviar = {
			id: dadosObj.idJob,
			status: dadosObj.idStatus,
			statusAntigo: dadosObj.idStatusAntigo,
			price: dadosObj.price,
		};

		if(!dadosObj.price && dadosObj.idStatus === 15) {
			msg.warning('Antes de aprovar o job, é necessário que o profissional remoto freelancer envie uma proposta no chat e essa proposta seja aceita por um usuário Hunter ou Admin.');
			return;
		} else if(!dadosObj.price && dadosObj.idStatus === 14) {
			msg.warning('Antes de iniciar a execução do job, é necessário que o profissional remoto freelancer envie uma proposta no chat e essa proposta seja aceita por um usuário Hunter ou Admin.');
			return;
		}

		setLoadingAlterarStatusTarefa(dadosObj.idJob);

		const retUpdateStatus = await apiTasks.update.put(dadosEnviar);

		if(retUpdateStatus.status === 200) {
			statusJobsAdminEnviarMensagemAlteracaoStatus({
				idJob: dadosObj.idJob,
				idStatus: dadosObj.idStatus,
				idFreelancer: dadosObj.idFreelancer,
				statusLabel: dadosObj.statusLabel,
				idUser: store.usuario.id,
			});
			setStateTarefa({
				...stateTarefa,
				status: dadosObj.idStatus,
				status_name: dadosObj.statusLabel,
			});
		} else {
			msgs([retUpdateStatus]);
		}
		setLoadingAlterarStatusTarefa(false);
	}

	/*
	** Informações de pagamento
	** ================================================== */
	const infoPagamento = dados => {
		if(dados.status === 15 && !dados.payday) {
			return 'Pagamento realizado';
		}
		if(!dados.payday) {
			return 'Pagamento pendente';
		}
		if(validarDataAPI(dados.payday)) {
			if(moment(dados.payday).utc().toDate() > moment().utc().toDate()) {
				return `${dataApiParaBr(dados.payday)} (em ${dataHaQuantoTempo(dados.payday)})`;
			}
			return `${dataApiParaBr(dados.payday)} (pago há ${dataHaQuantoTempo(dados.payday)})`;
		}

		return 'Pagamento realizado';
	}

	const onClickInfo = () => {
		setShowPortalTermosContratacaoSegura(true);
	}

	var winPosTooltipEsquerdo = tamanhoTela([
		{width: 980, dados: {placement: 'bottom-center'}},
	], {placement: 'bottom-center'}, screen);

	return (
		<div className="tela-detalhesjob">
			<div className="pg-negocjob-wrapper">
				<div className="titulo-box titulo-box-res">
					<div className="pg-negocjob-titulo-box">
						<Voltar
							desc="Visualizar todos as contratações"
							link={location.state ? `/contratacoes?${qs.stringify(location.state)}` : '/jobs'}
						/>
						{_stateTarefa.status !== 200 ? (
							<h3 className="titulo text-one-line">Negociar contratações</h3>
						) : (
							<div className="pg-negocjob-titulo-inner">
								<h3 className="titulo text-one-line">{stateTarefa.title}</h3>
								<div className="pg-negocjob-titulo-status td-desc">
									<Icone imagem={iconeStatusCard(stateTarefa.status).icone} tamanho="16px" cor={iconeStatusCard(stateTarefa.status).cor} />
									<span className="margin-l-5">{stateTarefa.status_name}</span>
								</div>
							</div>
						)}
					</div>
					{_stateTarefa.status === 200 ? (
						<div className="cpnt-btnadd-wrap pg-negocjob-titulo-sep-box">
							<div className="pg-negocjob-titulo-sep">
								<Tip
									tip={
										<TextoIconeLink
											icone={imgIconeInfo}
											texto="Job"
											className="a"
											iconeTamanho="18px"
											iconeCor="#0277BD"
											onClick={e => setShowPortalDetalhes(true)}
										/>
									}
									info
								>
									Detalhes do job
								</Tip>
							</div>
							{stateTarefa.id_project ? (
								<div className="pg-negocjob-titulo-sep">
									<Tip
										tip={
											<TextoIconeLink
												icone={imgIconeProjeto}
												texto="Projeto"
												className="a"
												iconeTamanho="18px"
												iconeCor="#0277BD"
												onClick={e => setShowPortalDetalhesProjeto(true)}
											/>
										}
										info
									>
										Esse job está associado a um projeto.
									</Tip>
								</div>
							) : null}
							<div className="pg-negocjob-titulo-sep">
								<Tip
									tip={
										<TextoIconeLink
											icone={imgIconeEditar}
											texto="Status"
											className="a"
											iconeTamanho="18px"
											iconeCor="#0277BD"
										/>
									}
									click={true}
									show={loadingAlterarStatusTarefa === stateTarefa.id ? true : false}
									_defaults={winPosTooltipEsquerdo}
								>
									<Select3Lista
										titulo={<h3 className="margin-0">Status do job</h3>}
										options={statusJobsAdminTratarStatus(stateStatusTarefa, stateTarefa.status, store.usuario.role)}
										optionsType="radio"
										optionsChecked={{
											value: stateTarefa.status,
										}}
										onClick={e => {
											alterarStatusTarefa({
												idJob: stateTarefa.id,
												idStatus: e.value,
												statusLabel: e.label,
												idFreelancer: stateTarefa.id_freelancer,
												price: stateTarefa.price,
												idStatusAntigo: stateTarefa.status,
											});
										}}
										loading={loadingAlterarStatusTarefa === stateTarefa.id ? true : false}
										labelClass="flex-1"
										posicao=""
										classNameDefault=""
									/>
								</Tip>
							</div>
						</div>
					) : null}
				</div>
			</div>

			<div className="tela-negociarcard-container">
				<div className="tela-negociarcard-info-box box-default overflow">
					<div className="tela-negociarcard-info-wrap">
						<div className="tela-detalhejob-info-hunter">
							{_stateTarefa.status !== 200 ? (
								<ImgTituloDesc
									wrap="a td-info"
									titulo={loadingGetTarefa ? 'Carregando dados' : 'Dados indisponíveis'}
									desc={loadingGetTarefa ? 'Aguarde...' : 'Não foi possível carregar'}
									placeholder='userpicture'
									classNameTitulo="td-titulo"
									classNameDesc="td-desc"
									classNameImgBox="foto-wrap"
									responsivo={true}
								/>
							) : (
								<>
									<ImgTituloDesc
										wrap="a td-info"
										titulo={stateTarefa.freelancer_name}
										desc="Irá receber pelo job"
										img={caminhoFoto(stateTarefa.freelancer_photo).url}
										placeholder='userpicture'
										classNameTitulo="td-titulo"
										classNameDesc="td-desc"
										classNameImgBox="foto-wrap"
										responsivo={true}
										onClick={() => {
											setStateIdUsuarioPerfilCompleto(stateTarefa.id_freelancer);
											setShowPortalPerfilCompleto(true);
										}}
									/>
								</>
							)}
						</div>
					</div>
					<div className="tela-negociarcard-info-dados relative box-txt scroll scroll-alt">

						{loadingGetTarefa ? (
							<Loading
								texto="Carregando, aguarde..."
							/>
						) : (
							_stateTarefa.status === 200 ? (
								<div>
									<div className="tela-detalhejob-sec box-txt">
										<h3 className="titulo">{stateTarefa.title}</h3>
										<LinhaItem
											titulo="ID"
											desc={stateTarefa.id}
											alt
										/>
									</div>

									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-0">Valor</h3>
										<div className="flex flex-center-v margin-t-2">
											<Icone imagem={imgIconeValor} tamanho="15px" cor="#000" />
											<div className="margin-l-5"><strong>Líquido:</strong> <span className="color-green">{formatarDinheiro(stateTarefa.liquid_price, 'R$ ')}</span></div>
										</div>
										<div className="flex flex-center-v margin-t-2">
											<Icone imagem={imgIconeValor} tamanho="15px" cor="#000" />
											<div className="margin-l-5"><strong>Bruto:</strong> {formatarDinheiro(stateTarefa.price, 'R$ ')}</div>
										</div>
									</div>

									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Datas e Prazos</h3>
										<div>
											<LinhaItem
												titulo="Criado em"
												desc={dataApiParaBr(stateTarefa.created_at, 'Não informado')}
												alt
											/>
											<LinhaItem
												titulo="Iniciado em"
												desc={dataApiParaBr(stateTarefa.start_date, 'Não informado')}
												alt
											/>
											<LinhaItem
												titulo="Aprovação em"
												desc={dataApiParaBr(stateTarefa.start_date, 'Não informado')}
												alt
											/>
										</div>
									</div>

									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-0">Status do Job</h3>
										<div className="flex flex-center-v">
											<Icone imagem={iconeStatusCard(stateTarefa.status).icone} tamanho="16px" cor={iconeStatusCard(stateTarefa.status).cor} />
											<div className="margin-l-5">{stateTarefa.status_name}</div>
										</div>
									</div>

									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-0">Status do pagamento</h3>
										<div className="flex flex-center-v">
											<Icone imagem={imgIconeValor} tamanho="16px" cor="#229d3e" />
											<div className="margin-l-5">{infoPagamento(stateTarefa)}</div>
										</div>
									</div>

									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Projeto</h3>
										<div>
											{stateTarefa.project_name}
										</div>
									</div>

									<div className="tela-detalhejob-sec box-txt">
										<h3 className="margin-b-5">Cliente</h3>
										<div>
											{stateTarefa.customer_name}
										</div>
									</div>
								</div>
							) : (
								<Info
									texto={_stateTarefa.msg}
									icone="erro"
									alt
								/>
							)
						)}
					</div>
				</div>

				<div className="tela-negociarcard-chat-box">
					<Chat
						enviarMensagem={enviarMensagemChat}
						mensagem={mensagemChat}
						setMensagem={setMensagemChat}
						mensagens={mensagensChat}
						setDigitando={digitandoChat}
						// showTelaInicial={true}

						isDigitando={isDigitandoChat}
						setIsDigitando={setIsDigitandoChat}
						isDigitandoInfo={isDigitandoInfoChat}
						loadingGetMensagens={loadingGetMensagensChat}

						// conversandoCom={usuariosOnline}
						// conversandoComExibirOnline={true}
						layoutExibicao="usuario"
						// exibirBotaoProposta={false}

						onClickBtnRespostaProposta={onClickBtnRespostaProposta}
						onClickInfo={onClickInfo}
						taxaPorcentagem={taxaPorcentagem}
					/>
				</div>
			</div>

			{/* Portal informações hunter */}
			<PortalPerfilFreelancer
				id={stateIdUsuarioPerfilCompleto}
				show={showPortalPerfilCompleto}
				onClose={() => {
					setShowPortalPerfilCompleto(false);
				}}
			/>

			{/* Portal Mensagem customizada */}
			<Portal
				titulo="Resposta da proposta do job"
				show={showPortalMensagemBotaoProposta}
				onClose={() => {
					setShowPortalMensagemBotaoProposta(false);
				}}
				className="w-900"
			>
				<div className="desc">Caso necessário, você pode escrever uma mensagem informando o motivo de ter aceitado ou de ter recusado o job do freelancer.</div>
				<div className="relative">
					{loadingGetMensagemProposta ? <Loading absolute bgAlt2 /> : null}
					<div className="cpnt-chatmsgcustom-dados-preview margin-t-15">
						<div className="cpnt-chatmsgcustom-dados-mensagem-box">
							<div className="cpnt-chatmsgcustom-dados-mensagem">
								<div className="wrap-5">
									<div className="input-box">
										<Input2
											label="Título da mensagem"
											_={{
												value: stateProposta.titulo,
												onChange: e => {
													const dadosProposta = {
														...stateProposta,
														titulo: e.target.value
													};
													setStateProposta(dadosProposta);
													statePropostaRef.current = dadosProposta;
												}
											}}
										/>
									</div>
									<div className="input-box">
										<Input2
											label="Mensagem"
											_={{
												type: 'textarea',
												value: stateProposta.mensagem,
												onChange: e => {
													const dadosProposta = {
														...stateProposta,
														mensagem: e.target.value
													};
													setStateProposta(dadosProposta);
													statePropostaRef.current = dadosProposta;
												}
											}}
										/>
									</div>
									<div className="input-box">
										<div className="strong">Sua resposta:</div>
										<div className="flex margin-t-5">
											<Radio2
												label="Aceitar"
												_={{
													name: 'opcao-proposta',
													checked: stateProposta.resposta === true ? true : false,
													onChange: e => {
														const dadosProposta = {
															...stateProposta,
															...stateTemplateSim,
															resposta: e.target.checked
														};
														setStateProposta(dadosProposta);
														statePropostaRef.current = dadosProposta;
													}
												}}
											/>
											<Radio2
												label="Recusar"
												_={{
													name: 'opcao-proposta',
													checked: stateProposta.resposta === false ? true : false,
													onChange: e => {
														const dadosProposta = {
															...stateProposta,
															...stateTemplateNao,
															resposta: e.target.checked ? false : true,
														};
														setStateProposta(dadosProposta);
														statePropostaRef.current = dadosProposta;
													}
												}}
												wrap="margin-l-15"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="cpnt-chatmsgcustom-preview-box">
							<h3 className="margin-0">Pré visualização</h3>
							<div className="cpnt-chatmsgcustom-preview">
								<ChatMensagem
									{...{
										data: moment().subtract(0, 'minutes').format('HH:mm'),
										mensagem: stateProposta.mensagem,
										enviarMensagem: enviarMensagemChat,
										metadata: {
											titulo: stateProposta.titulo,
										},
										name: store.usuario.name,
										photo: caminhoFoto(store.usuario.photo).url,
										type: stateProposta.resposta === true ? 'verde' : 'vermelho',
										propria: false,
									}}
								/>
							</div>
						</div>
					</div>
					{!loadingGetMensagemProposta ? (
						<div className="form-bottom">
							<Button2
								_={{
									value: 'Cancelar',
									type: 'button',
									onClick: () => {
										setShowPortalMensagemBotaoProposta(false);
									},
									className: 'btn btn-1 margin-l-10',
								}}
							/>
							<Button2
								_={{
									value: stateProposta.resposta === true ? 'Aprovar proposta' : 'Recusar proposta',
									type: 'button',
									onClick: onClickBtnEnviarRespostaProposta,
									className: `btn margin-l-10 ${stateProposta.resposta === true ? 'btn-green' : 'btn-red'}`,
								}}
							/>
						</div>
					) : null}
				</div>
			</Portal>

			<PortalProjetosDetalhes
				show={showPortalDetalhesProjeto}
				id={stateTarefa.id_project}
				onClose={() => setShowPortalDetalhesProjeto(false)}
			/>

			<PortalCardDetalhes
				show={showPortalDetalhes}
				id={stateTarefa.id}
				onClose={() => setShowPortalDetalhes(false)}
				showNegociar={false}
			/>

			<PortalTermosContratacaoSegura
				show={showPortalTermosContratacaoSegura}
				onClose={() => setShowPortalTermosContratacaoSegura(false)}
			/>

		</div>
	);
};

export default NegociarJobs;