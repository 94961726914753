import React, { useState } from 'react';

import axios from 'axios';
import _ from 'lodash';

// css
import './PtLinkedin.css';

// Imagens
import imgIconeLinkedin from '@img/icones/redes-sociais/linkedin.png';
import imgPerfilLinkedin from '@img/outros/linkedin-export-pdf.png';
import imgIconeCheck from '@svg/solid/circle-check.svg';
import imgIconeQ from '@svg/solid/circle-question.svg';
import imgIconePDF from '@svg/solid/file-pdf.svg';

// Componentes
import { config, validarRetorno } from "@api/axios";
import { EMAIL_CONTATO, URL_API } from "@_config/config";
import BtnLabel from "@cpnt/BtnLabel/BtnLabel";
import Button2 from "@cpnt/Button2/Button2";
import FormExperiencia from "@cpnt/Forms/FormExperiencia/FormExperiencia";
import FormFormacoes from "@cpnt/Forms/FormFormacao/FormFormacao";
import Icone from "@cpnt/Icone/Icone";
import Input2 from "@cpnt/Input2/Input2";
import ItemLista from "@cpnt/ItemLista/ItemLista";
import LoadingBar from "@cpnt/LoadingBar/LoadingBar";
import Portal from "@cpnt/Portal/Portal";
import Radio2 from "@cpnt/Radio2/Radio2";
import TextoIconeLink from "@cpnt/TextoIconeLink/TextoIconeLink";
import Tip from "@cpnt/Tip/Tip";
import { dataBrParaEngDb, msg, validarDataBr } from "@func/utils";
import { checkItensMergeHabilidades, checkItensMergeIdiomas } from "@shared/functions/components";
import FormCertificado from "@cpnt/Forms/FormCertificado/FormCertificado";
import FormPremio from "@cpnt/Forms/FormPremio/FormPremio";
import { msgs, msgStatus } from "@_func/request";
import { api } from "@_api/api";

const PtLinkedin = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		stateDados,
		setStateDados,

		setLoadingPesquisaArea,

		stateHabilidades,
		setStateHabilidades,

		stateExperiencias,
		setStateExperiencias,

		stateFormacoes,
		setStateFormacoes,

		stateCertificados,
		setStateCertificados,

		statePremios,
		setStatePremios,

		stateIdiomas,
		setStateIdiomas,

		stateIdiomasNiveis,
	} = props;

	/*
	** LinkedIn PDF
	** ================================================== */
	const [showPortalComoImportar, setShowPortalComoImportar] = useState(false);
	const [showPortalEnviarPDF, setShowPortalEnviarPDF] = useState(false);
	const [showPortalRetornoPdfLinkedin, setShowPortalRetornoPdfLinkedin] = useState(false);

	const [loadingEnviarArquivoPdfLinkedin, setLoadingEnviarArquivoPdfLinkedin] = useState(false);
	const [loadingLinkedinDefinirImportacao, setLoadingLinkedinDefinirImportacao] = useState(false);

	const [stateUploadArquivoLinkedinProgresso, setStateUploadArquivoLinkedinProgresso] = useState(0);
	const [stateDadosLinkedin, setStateDadosLinkedin] = useState({});
	const [isArquivoPdfLinkedinSelecionado, setIsArquivoPdfLinkedinSelecionado] = useState(false);
	const [nomeArquivoPdfSelecionadoLinkedin, setNomeArquivoPdfSelecionadoLinkedin] = useState(false);


	// Checkbox de usar importação do LinkedIn
	const [stateLinkedinUsarIdiomas, setStateLinkedinUsarIdiomas] = useState('adicionar');
	const [stateLinkedinUsarHabilidades, setStateLinkedinUsarHabilidades] = useState('adicionar');
	const [stateLinkedinUsarExperiencias, setStateLinkedinUsarExperiencias] = useState('adicionar');
	const [stateLinkedinUsarFormacoes, setStateLinkedinUsarFormacoes] = useState('adicionar');
	const [stateLinkedinUsarCertificados, setStateLinkedinUsarCertificados] = useState('adicionar');
	const [stateLinkedinUsarPremios, setStateLinkedinUsarPremios] = useState('adicionar');
	const [stateLinkedinUsarCartaApresentacao, setStateLinkedinUsarCartaApresentacao] = useState('adicionar');
	const [isDragArquivoPdf, setIsDragArquivoPdf] = useState(false);

	/*
	** Edição dados LinkedIn PDF
	** ================================================== */

	// Experiências
	const [showPortalLinkedinEdicaoExperiencias, setShowPortalLinkedinEdicaoExperiencias] = useState(false);
	const [stateLinkedinEdicaoExperiencias, setStateLinkedinEdicaoExperiencias] = useState({
		dados: {
			company: '', role: '', active: '', start_date: '', end_date: '', description: '',
		},
	});

	// Formações
	const [stateLinkedinEdicaoFormacoes, setStateLinkedinEdicaoFormacoes] = useState({
		dados: {
			company: '', description: '', start_date: '', end_date: '', active: 1,
		}
	});
	const [showPortalLinkedinEdicaoFormacoes, setShowPortalLinkedinEdicaoFormacoes] = useState(false);

	// Certificados
	const [stateLinkedinEdicaoCertificados, setStateLinkedinEdicaoCertificados] = useState({
		title: '', issuer: '', url: '', issue_date: '',
	});
	const [showPortalLinkedinEdicaoCertificados, setShowPortalLinkedinEdicaoCertificados] = useState(false);

	// Prêmios
	const [stateLinkedinEdicaoPremios, setStateLinkedinEdicaoPremios] = useState({
		title: '', issue_date: '',
	});
	const [showPortalLinkedinEdicaoPremios, setShowPortalLinkedinEdicaoPremios] = useState(false);


	const pdfOnDrag = (evento, nomeEvento) => {
		if(nomeEvento === 'dragEnter') {
			setIsDragArquivoPdf(true);
		}
		if(nomeEvento === 'dragExit') {
			setIsDragArquivoPdf(false);
		}
	}

	/*
	** Salva sessão do popup
	** ================================================== */
	const setIsVisualizadoPopupLinkedin = () => {
		var salvo = false;
		try {
			sessionStorage.setItem('@crowdplataforma/avisolinkedin', 'true');
			salvo = true;
		} catch (error) {
		}
		return salvo;
	}

	/*
	** LinkedIn - Selecionar arquivo PDF
	** ================================================== */
	const arquivoPdfLinkedinSelecionado = async e => {
		setIsDragArquivoPdf(false);
		const arquivo = e.target.files[0];
		if(arquivo) {
			if(arquivo.type !== 'application/pdf') {
				msg.error('É necessário o envio de arquivos no formato PDF.');
			} else {

				setIsArquivoPdfLinkedinSelecionado(true);
				setNomeArquivoPdfSelecionadoLinkedin(arquivo.name);
				setLoadingEnviarArquivoPdfLinkedin(true);
				setStateUploadArquivoLinkedinProgresso(0);
				setStateDadosLinkedin({});

				// API
				const dadosEnviar = new FormData();
				dadosEnviar.append('pdf', arquivo);

				const retEnviarArquivo = await axios.post(`${URL_API}/linkedin`, dadosEnviar, {
					headers: {
						...config(),
						"Content-Type": "multipart/form-data",
					},
					onUploadProgress: data => {
						setStateUploadArquivoLinkedinProgresso(Math.round((100 * data.loaded) / data.total));
					},
				})
				.then(retorno => {
					return validarRetorno(retorno);
				})
				.catch(retorno => {
					return validarRetorno(retorno);
				});

				if(retEnviarArquivo.status === 200) {

					var dadosEditados = {};

					Object.keys(retEnviarArquivo.data).map((val, key) => {

						// Habilidades
						if(val === 'skills') {
							if(retEnviarArquivo.data.skills && typeof(retEnviarArquivo.data.skills) === 'object' && retEnviarArquivo.data.skills.length) {
								if(retEnviarArquivo.data.skills[0] && retEnviarArquivo.data.skills[0].id_area && retEnviarArquivo.data.skills[0].id_area !== '' && retEnviarArquivo.data.skills[0] && retEnviarArquivo.data.skills[0].area && retEnviarArquivo.data.skills[0].area !== '') {
									var idArea = retEnviarArquivo.data.skills[0].id_area;
									var nomeArea = retEnviarArquivo.data.skills[0].area;

									if(idArea && idArea !== '' && nomeArea && nomeArea !== '') {
										dadosEditados._areaAtuacao = {
											label: nomeArea,
											value: idArea,
										};
									}
								}
							}
						}

						// Resumo / Carta de apresentação
						if(val === 'resume' && typeof(retEnviarArquivo.data.resume) === 'string') {
							dadosEditados.resume = retEnviarArquivo.data.resume;
						}

						// Prêmios
						if(val === 'awards') {
							if(retEnviarArquivo.data.awards && typeof(retEnviarArquivo.data.awards) === 'object' && retEnviarArquivo.data.awards.length) {
								dadosEditados.awards = retEnviarArquivo.data.awards.map((val2, key2) => {
									if(val2 && val2 !== '') {
										return {
											title: val2,
											issue_date: null,
										}
									}
									return false;
								});
								dadosEditados.awards = dadosEditados.awards.filter(item => item !== false);
							}
						}

						// Certificados
						if(val === 'certificates') {
							if(retEnviarArquivo.data.certificates && typeof(retEnviarArquivo.data.certificates) === 'object' && retEnviarArquivo.data.certificates.length) {
								dadosEditados.certificates = retEnviarArquivo.data.certificates.map((val2, key2) => {
									if(val2 && val2 !== '') {
										return {
											title: val2,
											issuer: '',
											url: '',
											issue_date: null,
										}
									}
									return false;
								});
								dadosEditados.certificates = dadosEditados.certificates.filter(item => item !== false);
							}
						}

						// Experiências
						if(val === 'experiences') {
							if(retEnviarArquivo.data.experiences && typeof(retEnviarArquivo.data.experiences) && retEnviarArquivo.data.experiences.length) {
								dadosEditados.experiences = retEnviarArquivo.data.experiences.map((val2, key2) => {
									if(val2 && typeof(val2) === 'object' && Object.keys(val2).length > 1 && val2.role && typeof(val2.role) === 'object') {
										return {
											company: val2.company && typeof(val2.company) === 'string' ? val2.company : '',
											role: val2.role.role,
											start_date: val2.role.date_start && typeof(val2.role.date_start) === 'string' && validarDataBr(val2.role.date_start) ? dataBrParaEngDb(val2.role.date_start) : null,
											end_date: val2.role.date_end && typeof(val2.role.date_end) === 'string' && validarDataBr(val2.role.date_end) ? dataBrParaEngDb(val2.role.date_end) : null,
											active: false,
											description: val2.activity && typeof(val2.activity) === 'string' ? val2.activity : '',
										}
									}
									return false;
								});
								dadosEditados.experiences = dadosEditados.experiences.filter(item => item !== false);
							}
						}

						// Formações
						if(val === 'formation') {
							if(retEnviarArquivo.data.formation && typeof(retEnviarArquivo.data.formation) === 'object' && retEnviarArquivo.data.formation.length) {
								dadosEditados.formation = retEnviarArquivo.data.formation.map((val2, key2) => {
									if(val2 && typeof(val2) === 'object' && Object.keys(val2).length > 1 && val2.role) {
										return {
											company: val2.company && typeof(val2.company) === 'string' ? val2.company : '',
											description: val2.role && typeof(val2.role) === 'string' ? val2.role : '',
											start_date: val2.date_start && typeof(val2.date_start) === 'string' && validarDataBr(val2.date_start) ? dataBrParaEngDb(val2.date_start) : null,
											end_date: val2.date_end && typeof(val2.date_end) === 'string' && validarDataBr(val2.date_end) ? dataBrParaEngDb(val2.date_end) : null,
											active: '',
										}
									}
									return false;
								});
								dadosEditados.formation = dadosEditados.formation.filter(item => item !== false);
							}
						}

						// Idiomas
						if(val === 'languages') {
							if(retEnviarArquivo.data.languages && typeof(retEnviarArquivo.data.languages) === 'object' && retEnviarArquivo.data.languages.length) {
								dadosEditados.languages = retEnviarArquivo.data.languages.map((val2, key2) => {
									if(val2 && typeof(val2) === 'object' && Object.keys(val2).length > 1 && val2.id_language && val.id_language !== '' && val2.id_level && val2.id_level !== '' && val2.language && val2.language !== '') {
										return {
											id_language: val2.id_language,
											id_level: val2.id_level,
											name_level: val2.level,
											name_language: val2.language,
										}
									}
									return false;
								});
								dadosEditados.languages = dadosEditados.languages.filter(item => item !== false);
							}
						}

						// Habilidades
						if(val === 'skills') {
							if(retEnviarArquivo.data.skills && typeof(retEnviarArquivo.data.skills) === 'object' && retEnviarArquivo.data.skills.length) {
								dadosEditados.skills = retEnviarArquivo.data.skills.map((val2, key2) => {
									if(val2 && typeof(val2) === 'object' && Object.keys(val2).length > 1 && val2.competence && typeof(val2.competence) === 'string' && val2.id_skill && val2.id_area) {
										return {
											name_skill: val2.competence,
											id_skill: val2.id_skill,
											id_area: val2.id_area,
										}
									}
									return false;
								});
								dadosEditados.skills = dadosEditados.skills.filter(item => item !== false);
							}
						}

					});

					setStateDadosLinkedin(dadosEditados);

					setShowPortalEnviarPDF(false);
					setShowPortalRetornoPdfLinkedin(true);
					msg.success('Arquivo PDF enviado com sucesso, confira as informações abaixo');
				} else {
					setStateDadosLinkedin({});
					msg.error('Não foi possível enviar seu PDF, tente enviar novamente.');
					msgs([retEnviarArquivo]);
				}
				setLoadingEnviarArquivoPdfLinkedin(false);
			}
		} else {
			msg.error('Não foi selecionar esse arquivo, tente enviar novamente, ou tente enviar outro arquivo.');
		}
	}

	/*
	** LinkedIn - Editar dados importados
	** ================================================== */
	const editarLinkedinDadosImportados = dados => {
		dados.evento.preventDefault();

		var dadosAlterados = {...stateDadosLinkedin};

		if(stateDadosLinkedin[dados.chave] && stateDadosLinkedin[dados.chave][dados.index]) {
			dadosAlterados[dados.chave][dados.index] = dados.dados;
			setStateDadosLinkedin(dadosAlterados);
		}

		const portais = {
			experiences: setShowPortalLinkedinEdicaoExperiencias,
			formation: setShowPortalLinkedinEdicaoFormacoes,
			certificates: setShowPortalLinkedinEdicaoCertificados,
			awards: setShowPortalLinkedinEdicaoPremios,
			// skills: setShowPortalLinkedinEdicaoHabilidades,
			// languages: setShowPortalLinkedinEdicaoIdiomas,
		}

		if(portais[dados.chave]) {
			portais[dados.chave](false);
		}
	}

	const temCampoVazioExperiencias = val => {
		if(!val.company || val.company === '' || !val.role || val.role === '' || !val.start_date || val.start_date === '' || !val.description || val.description === '') {
			return true;
		}

		// Maior que hoje
		if(val.start_date && new Date(val.start_date) > new Date()) {
			return true;
		}

		// Inicial maior que final
		if(!val.active || val.active === '') {
			if(val.start_date && val.end_date) {
				if(new Date(val.start_date) >= new Date(val.end_date)) {
					return true;
				}
			}
		}

		return false;
	}

	const temCampoVazioFormacoes = val => {
		if(!val.company || val.company === '' || !val.description || val.description === '' || !val.start_date || val.start_date === '') {
			return true;
		}

		// Maior que hoje
		if(val.start_date && new Date(val.start_date) > new Date()) {
			return true;
		}

		// Inicial maior que final
		if(!val.active || val.active === '') {
			if(val.start_date && val.end_date) {
				if(new Date(val.start_date) >= new Date(val.end_date)) {
					return true;
				}
			}
		}

		return false;
	}

	const temCampoVazioCertificados = val => {
		if(!val.title || val.title === '' || !val.issuer || val.issuer === '' || !val.issue_date || val.issue_date === '') {
			return true;
		}
		if(val.issue_date && val.issue_date !== '') {
			if(new Date(val.issue_date) > new Date()) {
				return true;
			}
		}
		return false;
	}

	const temCampoVazioPremios = val => {
		if(!val.title || val.title === '' || !val.issue_date || val.issue_date === '') {
			return true;
		}
		if(val.issue_date && val.issue_date !== '') {
			if(new Date(val.issue_date) > new Date()) {
				return true;
			}
		}
		return false;
	}

	/*
	** LinkedIn - Editar dados importados
	** ================================================== */
	const mesclarDadosLinkedinComDadosPerfil = async () => {

		if(loadingLinkedinDefinirImportacao) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		// Experiências
		if(stateLinkedinUsarExperiencias !== 'ignorar' && stateDadosLinkedin.experiences && typeof(stateDadosLinkedin.experiences) === 'object' && stateDadosLinkedin.experiences.length) {
			var campoVazioExperiencias = false;
			const dadosExperiencias = stateDadosLinkedin.experiences.map((val, key) => {
				if((temCampoVazioExperiencias(val))) {
					campoVazioExperiencias = true;
				}
				return {
					...val,
					// end_date: dataObjParaApi(val.end_date),
					// start_date: dataObjParaApi(val.start_date),
				}
			});

			if(campoVazioExperiencias) {
				msg.error(<>Preencha os campos obrigatórios.<br /><br />Para continuar, corrija os campos marcados em vermelho na seção <strong>Experiência Profissional</strong>.</>);
				return;
			}

			if(stateLinkedinUsarExperiencias === 'substituir') {
				setStateExperiencias(dadosExperiencias);
			} else if(stateLinkedinUsarExperiencias === 'adicionar') {
				setStateExperiencias([
					...stateExperiencias,
					...dadosExperiencias,
				]);
			}
		}

		// Formações
		if(stateLinkedinUsarFormacoes !== 'ignorar' && stateDadosLinkedin.formation && typeof(stateDadosLinkedin.formation) === 'object' && stateDadosLinkedin.formation.length) {
			var campoVazioFormacoes = false;

			const dadosFormacoes = stateDadosLinkedin.formation.map((val, key) => {
				if((temCampoVazioFormacoes(val))) {
					campoVazioFormacoes = true;
				}
				return {
					...val,
					// end_date: dataObjParaApi(val.end_date),
					// start_date: dataObjParaApi(val.start_date),
				}
			});

			if(campoVazioFormacoes) {
				msg.error(<>Preencha os campos obrigatórios.<br /><br />Para continuar, corrija os campos marcados em vermelho na seção <strong>Formações</strong>.</>);
				return;
			}

			if(stateLinkedinUsarFormacoes === 'substituir') {
				setStateFormacoes(dadosFormacoes);
			} else if(stateLinkedinUsarFormacoes === 'adicionar') {
				setStateFormacoes([
					...stateFormacoes,
					...dadosFormacoes,
				]);
			}
		}

		// Certificados
		if(stateLinkedinUsarCertificados !== 'ignorar' && stateDadosLinkedin.certificates && typeof(stateDadosLinkedin.certificates) === 'object' && stateDadosLinkedin.certificates.length) {
			var campoVazioCertificados = false;

			const dadosCertificados = stateDadosLinkedin.certificates.map((val, key) => {
				if((temCampoVazioCertificados(val))) {
					campoVazioCertificados = true;
				}
				return {
					...val,
					// issue_date: dataObjParaApi(val.issue_date),
				}
			});

			if(campoVazioCertificados) {
				msg.error(<>Preencha os campos obrigatórios.<br /><br />Para continuar, corrija os campos marcados em vermelho na seção <strong>Certificados</strong>.</>);
				return;
			}

			if(stateLinkedinUsarCertificados === 'substituir') {
				setStateCertificados(dadosCertificados);
			} else if(stateLinkedinUsarCertificados === 'adicionar') {
				setStateCertificados([
					...stateCertificados,
					...dadosCertificados,
				]);
			}
		}

		// Prêmios
		if(stateLinkedinUsarPremios !== 'ignorar' && stateDadosLinkedin.awards && typeof(stateDadosLinkedin.awards) === 'object' && stateDadosLinkedin.awards.length) {
			var campoVazioPremios = false;

			const dadosPremios = stateDadosLinkedin.awards.map((val, key) => {
				if((temCampoVazioPremios(val))) {
					campoVazioPremios = true;
				}
				return {
					...val,
					// issue_date: dataObjParaApi(val.issue_date),
				}
			});

			if(campoVazioPremios) {
				msg.error(<>Preencha os campos obrigatórios.<br /><br />Para continuar, corrija os campos marcados em vermelho na seção <strong>Prêmios</strong>.</>);
				return;
			}

			if(stateLinkedinUsarPremios === 'substituir') {
				setStatePremios(dadosPremios);
			} else if(stateLinkedinUsarPremios === 'adicionar') {
				setStatePremios([
					...statePremios,
					...dadosPremios,
				]);
			}
		}

		// Idiomas
		if(stateLinkedinUsarIdiomas !== 'ignorar' && stateDadosLinkedin.languages && typeof(stateDadosLinkedin.languages) === 'object' && stateDadosLinkedin.languages.length) {

			setLoadingLinkedinDefinirImportacao(true);
			const retGetIdiomas = await api.select.languages.get();

			if(retGetIdiomas.status === 200) {
				if(stateLinkedinUsarIdiomas === 'substituir') {

					setStateIdiomas(checkItensMergeIdiomas({
						retornoGeral: retGetIdiomas,
						retornoChecked: {
							status: 200,
							data: stateDadosLinkedin.languages,
						},
						retornoNiveis: {
							status: 200,
							data: stateIdiomasNiveis,
						},
					}));

				} else if(stateLinkedinUsarIdiomas === 'adicionar') {
					const idiomasMesclados = checkItensMergeIdiomas({
						retornoGeral: retGetIdiomas,
						retornoChecked: {
							status: 200,
							data: stateDadosLinkedin.languages,
						},
						retornoNiveis: {
							status: 200,
							data: stateIdiomasNiveis,
						},
					});

					// Remove as keys duplicadas pelo id do idioma
					const idiomasState = _.uniqBy([
						...idiomasMesclados,
						...stateIdiomas,
					], e => {
						return e.id_language;
					});

					setStateIdiomas(idiomasState);
				}
			}
		}

		var perfilState = {...stateDados};
		var atualizarStatePerfil = false;

		// Habilidades
		if(stateLinkedinUsarHabilidades !== 'ignorar' && stateDadosLinkedin.skills && typeof(stateDadosLinkedin.skills) === 'object' && stateDadosLinkedin.skills.length) {
			if(stateDadosLinkedin._areaAtuacao && stateDadosLinkedin._areaAtuacao.value && typeof(stateDadosLinkedin._areaAtuacao.value) === 'number') {

				setLoadingPesquisaArea(true);
				setLoadingLinkedinDefinirImportacao(true);
				const retGetHabilidades = await api.select.skills.get(stateDadosLinkedin._areaAtuacao.value);
				setLoadingPesquisaArea(false);

				const habilidadesDeUmaUnicaArea = stateDadosLinkedin.skills.filter(item => item.id_area === stateDadosLinkedin._areaAtuacao.value).map(item => {
					return {
						...item,
						id: item.id_skill,
						id_level: 1,
					}
				});

				setStateHabilidades(checkItensMergeHabilidades({
					retornoGeral: retGetHabilidades,
					retornoChecked: {
						status: 200,
						data: habilidadesDeUmaUnicaArea,
					},
				}));

				// Define a área selecionada como o ID da área que retornar
				perfilState.id_area = stateDadosLinkedin._areaAtuacao.value;
				atualizarStatePerfil = true;
			}
		}

		// Carta de apresentação
		if(stateLinkedinUsarCartaApresentacao !== 'ignorar' && stateDadosLinkedin.resume && typeof(stateDadosLinkedin.resume) === 'string' && stateDadosLinkedin.resume !== '') {
			if(stateLinkedinUsarCartaApresentacao === 'substituir') {
				atualizarStatePerfil = true;
				perfilState.description = stateDadosLinkedin.resume;
			} else if(stateLinkedinUsarCartaApresentacao === 'adicionar') {
				atualizarStatePerfil = true;
				perfilState.description = stateDadosLinkedin.resume;
			}
		}

		if(atualizarStatePerfil) {
			setStateDados(perfilState);
		}

		setLoadingLinkedinDefinirImportacao(false);
		setIsArquivoPdfLinkedinSelecionado(false);
		setNomeArquivoPdfSelecionadoLinkedin(false);
		setShowPortalRetornoPdfLinkedin(false);
	}

	return (
		<>
			<div className={`cpnt-ptlinkedin ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-ptlinkedin-box ${className}`}>
					<div className="cpnt-ptlinkedin-boxwrap">
						<div className="cpnt-ptlinkedin-link-ico-box">
							<Icone
								imagem={imgIconeLinkedin}
								svg={false}
								tamanho="30px"
							/>
							<span className="cpnt-ptlinkedin-txt a" onClick={e => setShowPortalComoImportar(true)}>Importar dados do LinkedIn</span>
						</div>
						<div className="cpnt-ptlinkedin-link-info">
							<div className="btn-min-2" onClick={e => setShowPortalComoImportar(true)}>
								<Icone imagem={imgIconeQ} />
								<div className="margin-l-10">Como importar</div>
							</div>
							<div className="btn-min-2 margin-l-15" onClick={e => setShowPortalEnviarPDF(true)}>
								<Icone imagem={imgIconePDF} cor="#AD0B00" />
								<div className="margin-l-10">Enviar PDF</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Portal informações linkedin */}
			<Portal
				titulo="Agilize o preenchimento do seu cadastro"
				show={showPortalComoImportar}
				closeOnEsc={false}
				x={false}
				onClose={() => {
					setShowPortalComoImportar(false);
				}}
				className="w-800 fs-15"
			>
				<div>
					Você tem perfil no <span className="inline-flex flex-center-v"><Icone imagem={imgIconeLinkedin} svg={false} tamanho="20px" className="margin-b--4" /><span className="link cursor-default margin-l-2">LinkedIn</span></span>? Envie o arquivo <span className="inline-flex flex-center-v"><Icone imagem={imgIconePDF} tamanho="20px" cor="#AD0B00" className="margin-b--4" /><strong className="color-red margin-l-2">PDF</strong></span> do seu perfil e nos encarregaremos de preencher suas informações nos passos seguintes como por exemplo suas experiências, suas habilidades, suas certificações, seus prêmios, áreas que tem conhecimento, idiomas que fala e também o resumo de quem você é.
				</div>

				<div>
					<h3>Como gerar o PDF do seu perfil no LinkedIn</h3>
					<div className="box-txt">
						<p>Acesse seu perfil no LinkedIn, depois clique em mais e depois em Salvar como PDF. Após gerar o arquivo, faça o envio e nós cuidaremos do resto.</p>
					</div>
				</div>

				<div className="margin-t-25">
					<img src={imgPerfilLinkedin} alt="perfil-linkedin" className="perfilsteps-img-linkedin" />
				</div>

				<p><strong className="color-red">Importante:</strong> no momento é suportado apenas perfis que estão utilizando o idioma principal do LinkedIn em Português, caso seu LinkedIn esteja em inglês, recomendamos gerar o PDF em português.</p>

				<div className="form-bottom">
					<Button2
						_={{
							value: 'Preencher manualmente',
							onClick: () => {
								setShowPortalComoImportar(false)
								setIsVisualizadoPopupLinkedin();
							},
						}}
					/>
					<Button2
						_={{
							value: <TextoIconeLink texto="Enviar arquivo PDF" icone={imgIconePDF} iconeCor="#e33024" />,
							onClick: () => {
								setShowPortalComoImportar(false)
								setIsArquivoPdfLinkedinSelecionado(false);
								setNomeArquivoPdfSelecionadoLinkedin(false);
								setShowPortalEnviarPDF(true);
								setIsVisualizadoPopupLinkedin();
							},
						}}
						wrap="margin-l-15"
					/>
				</div>
			</Portal>

			{/* Portal enviar PDF */}
			<Portal
				titulo="Importar dados do LinkedIn"
				show={showPortalEnviarPDF}
				closeOnEsc={false}
				x={false}
				onClose={() => {
					setShowPortalEnviarPDF(false);
				}}
				className="w-700"
			>
				<div className="box-txt fs-15">
					<p>Envie o arquivo <span className="inline-flex flex-center-v"><Icone imagem={imgIconePDF} tamanho="20px" cor="#AD0B00" className="margin-b--4" /><strong className="color-red margin-l-2">PDF</strong></span> do seu perfil e nos encarregaremos de preencher automaticamente todas as informações disponíveis.</p>
				</div>
				<div className="margin-t-30">
					{!isArquivoPdfLinkedinSelecionado ? (
						<label
							className="perfilsteps-box-info-pdf flex-center-v perfilsteps-box-info-pdf-hover"
						>
							{isDragArquivoPdf ? (
								<div className="perfilsteps-box-info-pdf-drag">Solte o arquivo aqui para enviar</div>
							) : null}
							<Icone imagem={imgIconePDF} tamanho="60px" cor="#AD0B00" />
							<div className="margin-t-15">Selecionar arquivo PDF</div>
							<input
								onDragEnter={e => pdfOnDrag(e, 'dragEnter')}
								onDragExit={e => pdfOnDrag(e, 'dragExit')}
								className="perfilsteps-box-info-pdf-input"
								type="file"
								accept=".pdf"
								onChange={arquivoPdfLinkedinSelecionado}
							/>
						</label>
					) : (
						<div className="perfilsteps-box-info-pdf">
							<LoadingBar
								porcentagem={stateUploadArquivoLinkedinProgresso}
								loading={loadingEnviarArquivoPdfLinkedin}
								abs
							/>
							<div className="flex flex-center-v">
								<div className="flex flex-center-v min-w-0">
									<Icone imagem={imgIconePDF} tamanho="22px" cor="#AD0B00" />
									<div className="margin-l-5 text-one-line">{nomeArquivoPdfSelecionadoLinkedin}</div>
								</div>
								<div className="margin-l-auto">
									<div
										className="x-alt margin-l-10"
										onClick={() => {
											setIsArquivoPdfLinkedinSelecionado(false);
											setNomeArquivoPdfSelecionadoLinkedin(false);
										}}
									>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Cancelar',
							onClick: () => {
								setShowPortalEnviarPDF(false);
								setIsArquivoPdfLinkedinSelecionado(false);
								setNomeArquivoPdfSelecionadoLinkedin(false);
							},
						}}
					/>
				</div>
			</Portal>

			{/* Portal informações PDF escaneado */}
			<Portal
				titulo="Importando dados do LinkedIn"
				show={showPortalRetornoPdfLinkedin}
				closeOnEsc={false}
				x={false}
				onClose={() => {
					setShowPortalRetornoPdfLinkedin(false);
				}}
				className="w-900"
			>
				{stateDadosLinkedin && typeof(stateDadosLinkedin) === 'object' && Object.keys(stateDadosLinkedin).length ? (
					<div>
						<div className="cpnt-perfilsteps-listalinkedin box-txt">
							<div>
								Confira as informações abaixo e selecione a ação que deseja realizar para cada uma delas.
							</div>

							<div className="box-txt margin-t-15">
								<h3 className="margin-b-0">Opções da importação</h3>
								<div className="fs-15 margin-t-10">
									<div><strong>Substituir:</strong> vai substituir as informações que você já preencheu anteriormente (caso tenha).</div>
									<div><strong>Adicionar:</strong> vai adicionar as informações encontradas no final das informações que já estão preenchidas.</div>
									<div><strong>Ignorar:</strong> vai ignorar as informações encontradas.</div>
								</div>
							</div>

							<div className="box-txt margin-t-15">
								<h3 className="margin-b-0">Campos obrigatórios</h3>
								<div className="fs-15">Caso tenha algum campo marcado com a borda em <span className="color-red">vermelho</span>, verifique as informações clicando em editar e corrija para poder continuar.</div>
							</div>

							<div className="margin-t-25">

								{/* Resumo */}
								{stateDadosLinkedin.resume && stateDadosLinkedin.resume !== '' ? (
									<div className="cpnt-ptlinkedin-lista">
										<div className="flex flex-between flex-center-v">
											<h3 className="margin-0">Carta de apresentação</h3>
											<div className="cpnt-ptlinkedin-lista-checkbox">
												<Radio2
													label="Adicionar"
													_={{
														checked: stateLinkedinUsarCartaApresentacao === 'adicionar' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarCartaApresentacao('adicionar');
															}
														}
													}}
												/>
												<Radio2
													label="Substituir"
													wrap="margin-l-20"
													_={{
														checked: stateLinkedinUsarCartaApresentacao === 'substituir' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarCartaApresentacao('substituir');
															}
														}
													}}
												/>
												<Radio2
													label="Ignorar"
													wrap="margin-l-20"
													_={{
														checked: stateLinkedinUsarCartaApresentacao === 'ignorar' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarCartaApresentacao('ignorar');
															}
														}
													}}
												/>
											</div>
										</div>
										<div className="cpnt-ptlinkedin-lista-wrap">
											<div className="cpnt-ptlinkedin-lista-infos">
												<div className="margin-t-10">
													<div className="margin-t-5 wrap-5 relative">
														{stateLinkedinUsarCartaApresentacao === 'ignorar' ? (
															<div className="fill bg-white-5 z-index-10"></div>
														) : null}
														<div>
															<Input2
																_={{
																	type: 'textarea',
																	value: stateDadosLinkedin.resume,
																	onChange: e => {
																		setStateDadosLinkedin({
																			...stateDadosLinkedin,
																			resume: e.target.value,
																		});
																	}
																}}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								) : null }

								{/* Idiomas */}
								{stateDadosLinkedin.languages && stateDadosLinkedin.languages.length ? (
									<div className="cpnt-ptlinkedin-lista">
										<div className="flex flex-between flex-center-v">
											<h3 className="margin-0">Idiomas</h3>
											<div className="cpnt-ptlinkedin-lista-checkbox">
												<Radio2
													label="Adicionar"
													_={{
														checked: stateLinkedinUsarIdiomas === 'adicionar' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarIdiomas('adicionar');
															}
														}
													}}
												/>
												<Radio2
													label="Substituir"
													wrap="margin-l-20"
													_={{
														checked: stateLinkedinUsarIdiomas === 'substituir' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarIdiomas('substituir');
															}
														}
													}}
												/>
												<Radio2
													label="Ignorar"
													wrap="margin-l-20"
													_={{
														checked: stateLinkedinUsarIdiomas === 'ignorar' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarIdiomas('ignorar');
															}
														}
													}}
												/>
											</div>
										</div>
										<div className="cpnt-ptlinkedin-lista-wrap">
											<div className="cpnt-ptlinkedin-lista-infos">
												<div className="margin-t-10">
													<div className="margin-t-5 wrap-5 relative">
														{stateLinkedinUsarIdiomas === 'ignorar' ? (
															<div className="fill bg-white-5 z-index-10"></div>
														) : null}
														<div className="flex flex-wrap">
															{stateDadosLinkedin.languages.map((val, key) => {
																return (
																	<BtnLabel texto={<>{val.name_language} <span className="color-3">({val.name_level})</span></>} onClose={() => {
																		setStateDadosLinkedin({
																			...stateDadosLinkedin,
																			languages: stateDadosLinkedin.languages.filter(item => item !== val)
																		});
																	}} />
																)
															})}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								) : null }

								{/* Habilidades */}
								{stateDadosLinkedin.skills && stateDadosLinkedin.skills.length ? (
									<div className="cpnt-ptlinkedin-lista">
										<div className="flex flex-between flex-center-v">
											<h3 className="margin-0">Habilidades</h3>
											<div className="cpnt-ptlinkedin-lista-checkbox">
												<Radio2
													label="Adicionar"
													_={{
														checked: stateLinkedinUsarHabilidades === 'adicionar' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarHabilidades('adicionar');
															}
														}
													}}
												/>
												<Radio2
													label="Substituir"
													wrap="margin-l-20"
													_={{
														checked: stateLinkedinUsarHabilidades === 'substituir' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarHabilidades('substituir');
															}
														}
													}}
												/>
												<Radio2
													label="Ignorar"
													wrap="margin-l-20"
													_={{
														checked: stateLinkedinUsarHabilidades === 'ignorar' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarHabilidades('ignorar');
															}
														}
													}}
												/>
											</div>
										</div>
										<div className="relative">
											{stateLinkedinUsarHabilidades === 'ignorar' ? (
												<div className="fill bg-white-5 z-index-10"></div>
											) : null}
											<div className="margin-t-15 flex flex-wrap flex-center-v">
												<Tip
													tip={
														<div>
															<strong>Área identificada: </strong>
															<span>{stateDadosLinkedin._areaAtuacao.label}</span>
															<span className="q margin-l-5"></span>
														</div>
													}
													info
												>
													Não é sua a categoria? Você poderá selecionar uma nova área quando chegar no passo correspondente, por enquanto você pode ignorar essa seção.
												</Tip>
											</div>
											<div className="margin-t-15">
												<strong>Habilidades nessa área</strong>
											</div>
											<div className="cpnt-ptlinkedin-lista-wrap">
												<div className="cpnt-ptlinkedin-lista-infos">
													<div className="margin-t-10">
														<div className="margin-t-5 wrap-5">
															<div className="flex flex-wrap">
																{stateDadosLinkedin.skills.map((val, key) => {
																	return (
																		<BtnLabel texto={val.name_skill} onClose={() => {
																			setStateDadosLinkedin({
																				...stateDadosLinkedin,
																				skills: stateDadosLinkedin.skills.filter(item => item !== val)
																			});
																		}} />
																	)
																})}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								) : null }

								{/* Experiência Profissional */}
								{stateDadosLinkedin.experiences && stateDadosLinkedin.experiences.length ? (
									<div className="cpnt-ptlinkedin-lista">
										<div className="flex flex-between flex-center-v">
											<h3 className="margin-0">Experiência Profissional</h3>
											<div className="cpnt-ptlinkedin-lista-checkbox">
												<Radio2
													label="Adicionar"
													_={{
														checked: stateLinkedinUsarExperiencias === 'adicionar' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarExperiencias('adicionar');
															}
														}
													}}
												/>
												<Radio2
													label="Substituir"
													wrap="margin-l-20"
													_={{
														checked: stateLinkedinUsarExperiencias === 'substituir' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarExperiencias('substituir');
															}
														}
													}}
												/>
												<Radio2
													label="Ignorar"
													wrap="margin-l-20"
													_={{
														checked: stateLinkedinUsarExperiencias === 'ignorar' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarExperiencias('ignorar');
															}
														}
													}}
												/>
											</div>
										</div>
										<div className="cpnt-ptlinkedin-lista-wrap">
											<div className="cpnt-ptlinkedin-lista-infos">
												<div className="margin-t-10 fs-13 relative">
													{stateLinkedinUsarExperiencias === 'ignorar' ? (
														<div className="fill bg-white-5 z-index-10"></div>
													) : null}
													{stateDadosLinkedin.experiences.map((val, key) => {
														return (
															<ItemLista
																key={key}
																className={temCampoVazioExperiencias(val) ? 'cpnt-itemlista-red' : ''}
																texto={`${val.company} - ${val.role}`}
																acoes={[
																	{
																		acao: e => {
																			setStateLinkedinEdicaoExperiencias({
																				dados: val,
																				index: key,
																				chave: 'experiences',
																			});
																			setShowPortalLinkedinEdicaoExperiencias(true);
																		},
																		icone: {
																			imagem: 'editar',
																		},
																	},
																	{
																		acao: () => {
																			setStateDadosLinkedin({
																				...stateDadosLinkedin,
																				experiences: stateDadosLinkedin.experiences.filter(item => item !== val)
																			});
																		},
																		icone: {
																			imagem: 'remover',
																			cor: '#E74C3C',
																		},
																	},
																]}
															/>
														)
													})}
												</div>
											</div>
										</div>
									</div>
								) : null }

								{/* Formações */}
								{stateDadosLinkedin.formation && stateDadosLinkedin.formation.length ? (
									<div className="cpnt-ptlinkedin-lista">
										<div className="flex flex-between flex-center-v">
											<h3 className="margin-0">Formações</h3>
											<div className="cpnt-ptlinkedin-lista-checkbox">
												<Radio2
													label="Adicionar"
													_={{
														checked: stateLinkedinUsarFormacoes === 'adicionar' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarFormacoes('adicionar');
															}
														}
													}}
												/>
												<Radio2
													label="Substituir"
													wrap="margin-l-20"
													_={{
														checked: stateLinkedinUsarFormacoes === 'substituir' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarFormacoes('substituir');
															}
														}
													}}
												/>
												<Radio2
													label="Ignorar"
													wrap="margin-l-20"
													_={{
														checked: stateLinkedinUsarFormacoes === 'ignorar' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarFormacoes('ignorar');
															}
														}
													}}
												/>
											</div>
										</div>
										<div className="cpnt-ptlinkedin-lista-wrap">
											<div className="cpnt-ptlinkedin-lista-infos">
												<div className="margin-t-10 fs-13 relative">
													{stateLinkedinUsarFormacoes === 'ignorar' ? (
														<div className="fill bg-white-5 z-index-10"></div>
													) : null}
													{stateDadosLinkedin.formation.map((val, key) => {
														return (
															<ItemLista
																key={key}
																className={temCampoVazioFormacoes(val) ? 'cpnt-itemlista-red' : ''}
																texto={`${val.company} - ${val.description}`}
																acoes={[
																	{
																		acao: e => {
																			setStateLinkedinEdicaoFormacoes({
																				dados: val,
																				index: key,
																				chave: 'formation',
																			});

																			setShowPortalLinkedinEdicaoFormacoes(true);
																		},
																		icone: {
																			imagem: 'editar',
																		},
																	},
																	{
																		acao: () => {
																			setStateDadosLinkedin({
																				...stateDadosLinkedin,
																				formation: stateDadosLinkedin.formation.filter(item => item !== val)
																			});
																		},
																		icone: {
																			imagem: 'remover',
																			cor: '#E74C3C',
																		},
																	},
																]}
															/>
														)
													})}
												</div>

											</div>

										</div>
									</div>
								) : null }

								{/* Certificados */}
								{stateDadosLinkedin.certificates && stateDadosLinkedin.certificates.length ? (
									<div className="cpnt-ptlinkedin-lista">
										<div className="flex flex-between flex-center-v">
											<h3 className="margin-0">Certificados</h3>
											<div className="cpnt-ptlinkedin-lista-checkbox">
												<Radio2
													label="Adicionar"
													_={{
														checked: stateLinkedinUsarCertificados === 'adicionar' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarCertificados('adicionar');
															}
														}
													}}
												/>
												<Radio2
													label="Substituir"
													wrap="margin-l-20"
													_={{
														checked: stateLinkedinUsarCertificados === 'substituir' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarCertificados('substituir');
															}
														}
													}}
												/>
												<Radio2
													label="Ignorar"
													wrap="margin-l-20"
													_={{
														checked: stateLinkedinUsarCertificados === 'ignorar' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarCertificados('ignorar');
															}
														}
													}}
												/>
											</div>
										</div>
										<div className="cpnt-ptlinkedin-lista-wrap">
											<div className="cpnt-ptlinkedin-lista-infos">
												<div className="margin-t-10 fs-13 relative">
													{stateLinkedinUsarCertificados === 'ignorar' ? (
														<div className="fill bg-white-5 z-index-10"></div>
													) : null}
													{stateDadosLinkedin.certificates.map((val, key) => {
														return (
															<ItemLista
																key={key}
																className={temCampoVazioCertificados(val) ? 'cpnt-itemlista-red' : ''}
																texto={val.title}
																acoes={[
																	{
																		acao: e => {
																			setStateLinkedinEdicaoCertificados({
																				dados: val,
																				index: key,
																				chave: 'certificates',
																			});
																			setShowPortalLinkedinEdicaoCertificados(true);
																		},
																		icone: {
																			imagem: 'editar',
																		},
																	},
																	{
																		acao: () => {
																			setStateDadosLinkedin({
																				...stateDadosLinkedin,
																				certificates: stateDadosLinkedin.certificates.filter(item => item !== val)
																			});
																		},
																		icone: {
																			imagem: 'remover',
																			cor: '#E74C3C',
																		},
																	},
																]}
															/>
														)
													})}
												</div>
											</div>
										</div>
									</div>
								) : null }

								{/* Prêmios */}
								{stateDadosLinkedin.awards && stateDadosLinkedin.awards.length ? (
									<div className="cpnt-ptlinkedin-lista">
										<div className="flex flex-between flex-center-v">
											<h3 className="margin-0">Prêmios</h3>
											<div className="cpnt-ptlinkedin-lista-checkbox">
												<Radio2
													label="Adicionar"
													_={{
														checked: stateLinkedinUsarPremios === 'adicionar' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarPremios('adicionar');
															}
														}
													}}
												/>
												<Radio2
													label="Substituir"
													wrap="margin-l-20"
													_={{
														checked: stateLinkedinUsarPremios === 'substituir' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarPremios('substituir');
															}
														}
													}}
												/>
												<Radio2
													label="Ignorar"
													wrap="margin-l-20"
													_={{
														checked: stateLinkedinUsarPremios === 'ignorar' ? true : false,
														onChange: e => {
															if(e.target.checked) {
																setStateLinkedinUsarPremios('ignorar');
															}
														}
													}}
												/>
											</div>
										</div>
										<div className="cpnt-ptlinkedin-lista-wrap">
											<div className="cpnt-ptlinkedin-lista-infos">
												<div className="margin-t-10 fs-13 relative">
													{stateLinkedinUsarPremios === 'ignorar' ? (
														<div className="fill bg-white-5 z-index-10"></div>
													) : null}
													{stateDadosLinkedin.awards.map((val, key) => {
														return (
															<ItemLista
																key={key}
																className={temCampoVazioPremios(val) ? 'cpnt-itemlista-red' : ''}
																texto={val.title}
																acoes={[
																	{
																		acao: e => {
																			setStateLinkedinEdicaoPremios({
																				dados: val,
																				index: key,
																				chave: 'awards',
																			});
																			setShowPortalLinkedinEdicaoPremios(true);
																		},
																		icone: {
																			imagem: 'editar',
																		},
																	},
																	{
																		acao: () => {
																			setStateDadosLinkedin({
																				...stateDadosLinkedin,
																				awards: stateDadosLinkedin.awards.filter(item => item !== val)
																			});
																		},
																		icone: {
																			imagem: 'remover',
																			cor: '#E74C3C',
																		},
																	},
																]}
															/>
														)
													})}
												</div>
											</div>
										</div>
									</div>
								) : null }
							</div>
						</div>

						<div className="form-bottom">
							<Button2
								_={{
									value: 'Cancelar',
									className: 'btn btn-1',
									onClick: () => {
										setShowPortalRetornoPdfLinkedin(false);
										setIsArquivoPdfLinkedinSelecionado(false);
										setNomeArquivoPdfSelecionadoLinkedin(false);
									},
								}}
							/>
							<Tip
								tip={
									<Button2
										_={{
											value: <TextoIconeLink texto="Enviar PDF novamente" icone={imgIconePDF} iconeCor="#e33024" />,
											onClick: () => {
												setShowPortalRetornoPdfLinkedin(false);
												setIsArquivoPdfLinkedinSelecionado(false);
												setNomeArquivoPdfSelecionadoLinkedin(false);
												setShowPortalEnviarPDF(true);
											},
										}}
									/>
								}
								info
								_defaults={{
									placement: 'top-center',
								}}
								classNameTipDefault="margin-l-10"
							>
								Qualquer configuração que você tenha feito na importação atual será perdida.
							</Tip>
							<Tip
								tip={
									<Button2
										_={{
											value: <TextoIconeLink texto="Finalizar importação" icone={imgIconeCheck} iconeCor="#229d3e" />,
											onClick: mesclarDadosLinkedinComDadosPerfil,
										}}
										loading={loadingLinkedinDefinirImportacao}
									/>
								}
								info
								_defaults={{
									placement: 'top-center',
								}}
								classNameTipDefault="margin-l-10"
							>
								As informações serão inseridas na tela, mas só serão salvas quando você chegar no passo respectivo e prosseguir para o passo seguinte.
							</Tip>
						</div>
					</div>
				) : (
					<div className="box-txt">
						<h3>Dados do arquivo PDF</h3>
						<div className="flex flex-column flex-center">
							<Icone
								imagem={imgIconePDF}
								tamanho="80px"
								cor="#AD0B00"
							/>
							<div className="margin-t-15 color-red box-txt align-center">
								<div>Nenhuma informação encontrada.</div>
								<div>Verifique se você enviou o arquivo correto.</div>
							</div>
						</div>
						<p>Alguns erros podem ocorrer caso ao fazer a exportação do arquivo PDF caso o idioma do seu LinkedIn não esteja em Português.</p>
						<p>Caso você tenha certeza que tudo está correto e o erro persista, por favor entre em contato conosco através do email <a href={`mailto:${EMAIL_CONTATO}?subject=CROWD Plataforma - Erro ao importar PDF do LinkedIn&body=Olá, não consegui importar o PDF do meu perfil do LinkedIn na plataforma, estou enviando o PDF em anexo para análise, obrigado.`}>{EMAIL_CONTATO}</a>, e envie seu PDF para que possamos analisar o erro que pode ter ocorrido.</p>
						<div className="flex flex-end margin-t-25">
							<Button2
								_={{
									value: 'Enviar novamente',
									onClick: () => {
										setShowPortalRetornoPdfLinkedin(false)
										setIsArquivoPdfLinkedinSelecionado(false);
										setNomeArquivoPdfSelecionadoLinkedin(false);
										setShowPortalEnviarPDF(true);
									},
								}}
							/>
							<Button2
								_={{
									value: 'Fechar',
									onClick: () => setShowPortalRetornoPdfLinkedin(false),
								}}
								wrap="margin-l-15"
							/>
						</div>
					</div>
				)}
			</Portal>

			{/* EDIÇÃO DOS DADOS ESCANEADOS DO PDF */}
			{/* Portal informações PDF escaneado */}


			{/* LinkedIn - Experiências */}
			<Portal
				titulo="Editar experiência"
				show={showPortalLinkedinEdicaoExperiencias}
				closeOnEsc={false}
				x={false}
				onClose={() => {
					setShowPortalLinkedinEdicaoExperiencias(false);
				}}
				className="w-700"
			>
				<FormExperiencia
					state={stateLinkedinEdicaoExperiencias.dados}
					setState={dadosState => {
						setStateLinkedinEdicaoExperiencias({
							...stateLinkedinEdicaoExperiencias,
							dados: dadosState,
						});
					}}
				/>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Cancelar',
							onClick: e => {
								setShowPortalLinkedinEdicaoExperiencias(false);
							},
							className: 'btn btn-1',
						}}
					/>
					<Button2
						_={{
							value: 'Concluir edição',
							onClick: e => {
								editarLinkedinDadosImportados({
									evento: e,
									...stateLinkedinEdicaoExperiencias,
								});
							},
						}}
						wrap="margin-l-10"
					/>
				</div>
			</Portal>


			{/* LinkedIn - Formações */}
			<Portal
				titulo="Editar formação"
				show={showPortalLinkedinEdicaoFormacoes}
				closeOnEsc={false}
				x={false}
				onClose={() => {
					setShowPortalLinkedinEdicaoFormacoes(false);
				}}
				className="w-700"
			>
				<FormFormacoes
					state={stateLinkedinEdicaoFormacoes.dados}
					setState={dadosState => {
						setStateLinkedinEdicaoFormacoes({
							...stateLinkedinEdicaoFormacoes,
							dados: dadosState,
						});
					}}
				/>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Cancelar',
							onClick: e => {
								setShowPortalLinkedinEdicaoFormacoes(false);
							},
							className: 'btn btn-1',
						}}
					/>
					<Button2
						_={{
							value: 'Concluir edição',
							onClick: e => {
								editarLinkedinDadosImportados({
									evento: e,
									...stateLinkedinEdicaoFormacoes,
								});
							},
						}}
						wrap="margin-l-10"
					/>
				</div>
			</Portal>


			{/* LinkedIn - Certificados */}
			<Portal
				titulo="Editar certificado"
				show={showPortalLinkedinEdicaoCertificados}
				closeOnEsc={false}
				x={false}
				onClose={() => {
					setShowPortalLinkedinEdicaoCertificados(false);
				}}
				className="w-700"
			>
				<FormCertificado
					state={stateLinkedinEdicaoCertificados.dados}
					setState={dadosState => {
						setStateLinkedinEdicaoCertificados({
							...stateLinkedinEdicaoCertificados,
							dados: dadosState,
						});
					}}
				/>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Cancelar',
							onClick: e => {
								setShowPortalLinkedinEdicaoCertificados(false);
							},
							className: 'btn btn-1',
						}}
					/>
					<Button2
						_={{
							value: 'Concluir edição',
							onClick: e => {
								editarLinkedinDadosImportados({
									evento: e,
									...stateLinkedinEdicaoCertificados,
								});
							},
						}}
						wrap="margin-l-10"
					/>
				</div>
			</Portal>


			{/* LinkedIn - Premios */}
			<Portal
				titulo="Editar prêmio"
				show={showPortalLinkedinEdicaoPremios}
				closeOnEsc={false}
				x={false}
				onClose={() => {
					setShowPortalLinkedinEdicaoPremios(false);
				}}
				className="w-700"
			>
				<FormPremio
					state={stateLinkedinEdicaoPremios.dados}
					setState={dadosState => {
						setStateLinkedinEdicaoPremios({
							...stateLinkedinEdicaoPremios,
							dados: dadosState,
						})
					}}
				/>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Cancelar',
							onClick: e => {
								setShowPortalLinkedinEdicaoPremios(false);
							},
							className: 'btn btn-1',
						}}
					/>
					<Button2
						_={{
							value: 'Concluir edição',
							onClick: e => {
								editarLinkedinDadosImportados({
									evento: e,
									...stateLinkedinEdicaoPremios,
								});
							},
						}}
						wrap="margin-l-10"
					/>
				</div>
			</Portal>
		</>
	);
};

export { PtLinkedin };
