// Libs
import _ from 'lodash'

// Func
import { isNumeric } from "@_func/regex";

/*
** Filtra o valor do select informando apenas um item de dentro do array
** ================================================== */
export const valueSelect = (id, dados) => {
	var retorno = null;
	if(id && id !== '' && dados && typeof(dados) === 'object' && dados.length !== undefined) {
		retorno = dados.filter(item => {
			if(item.value) {
				return item.value.toString() === id.toString();
			}
			return null;
		});
		if(retorno) {
			retorno = retorno[0];
		}
	}
	if(isNumeric(id)) {
		if(dados) {
			// var ret = dados.filter(item => Number(item.value) === Number(id));
			var ret = dados.filter(item => Number(item.value) === Number(id)); // converte string para numero
			if(ret) {
				retorno = ret[0];
			}
		}
	}
	return retorno;
}

/*
** Filtra o valor do select informando apenas um item de dentro do array
** Funciona para a opção de multiplos
** ================================================== */
export const valueSelectMultiple = (values = [], opcoes = []) => {
	var retorno = [];
	if(values && typeof(values) === 'object' && values.length) {
		retorno = _.flatten(_.map(values, function(item){
			if(item && typeof(item) === 'object') {
				// Array / obj
				var itemValue = item.value;
				if(isNumeric(itemValue)) {
					return _.filter(opcoes, {value: Number(itemValue)});
				} else {
					return _.filter(opcoes, {value: itemValue});
				}
			} else {
				// Normal
				if(isNumeric(item)) {
					return _.filter(opcoes, {value: Number(item)});
				} else {
					return _.filter(opcoes, {value: item});
				}
			}
		}));
	}
	return retorno;
}
