import { BtnAdd } from "@cpnt/BtnAdd/BtnAdd";
import React from 'react';

// css
import './BoxWrap.css';
import Button2 from "@cpnt/Button2/Button2";

const BoxWrap = props => {
	const {
		className = '',
		classNameWrap = '',
		classNameDesc = '',
		wrap = '',
		titulo,
		children,
		btnTitulo,
		btnImg = true,
		btnReverso,
		onClickBtn,
		btnAlt,
	} = props;

	return (
		<>
			<div className={`cpnt-boxwrap ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-boxwrap-box ${className}`}>
					<div className="cpnt-boxwrap-titulo-wrap">
						{titulo ? (
							<h3 className="cpnt-boxwrap-titulo">{titulo}</h3>
						) : null}
						{btnTitulo ? (
							!btnAlt ? (
								<BtnAdd
									titulo={btnTitulo}
									img={btnImg}
									reverse={btnReverso}
									onClick={onClickBtn}
								/>
							) : (
								<Button2
									_={{
										value: btnTitulo,
										onClick: onClickBtn,
									}}
								/>
							)
						) : null}
					</div>
					<div className={`cpnt-boxwrap-desc ${classNameDesc}`}>{children}</div>
				</div>
			</div>
		</>
	);
};

export { BoxWrap };