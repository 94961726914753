import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast as msg } from 'react-toastify';

// Funções
import { dataApiParaBr } from '@func/utils';

const _Testes = props => {
	return (
		<div className="w-100p flex">
			<div className="padding-50">

				<div className="padding-10">
					<h3>Componentes</h3>
					<ul>
						<li>
							<Link to="/testes/notificacoes">
								Notificações
							</Link>
						</li>
						<li>
							<Link to="/testes/editor">
								Editor TinyMCE
							</Link>
						</li>
					</ul>
				</div>

				<div className="padding-10">
					<h3>Circle</h3>
					<ul>
						<li>
							<Link to="/testes/circle-canal">
								Embed canal
							</Link>
						</li>
						<li>
							<Link to="/testes/circle-widget">
								Embed Widget (Overlay)
							</Link>
						</li>
						<li>
							<Link to="/testes/circle-post">
								Embed Post
							</Link>
						</li>
						<li>
							<Link to="/testes/circle-embed">
								Embed Geral
							</Link>
						</li>
					</ul>
				</div>

				<div className="padding-10">
					<h3>Wistia</h3>
					<ul>
						<li>
							<Link to="/testes/wistia">
								Embed Wistia
							</Link>
						</li>
					</ul>
				</div>

				<div className="padding-10">
					<h3>Rocket.Chat</h3>
					<ul>
						<li>
							<Link to="/testes/rocketchat">
								Embed Rocket.Chat
							</Link>
						</li>
					</ul>
				</div>

			</div>
		</div>
	);
};

export { _Testes };