import React, { useState } from 'react';

// Componentes
import Input2 from "@cpnt/Input2/Input2";
import { Select2 } from "@cpnt/Select2/Select2";
import Tip from "@cpnt/Tip/Tip";

// css
import './Passo4.css';

import Button2 from "@cpnt/Button2/Button2";
import { ListaComCheckbox2 } from "@cpnt/ListaComCheckbox2/ListaComCheckbox2";
import { ListaSecao } from "@cpnt/ListaSecao/ListaSecao";
import Loading from "@cpnt/Loading/Loading";
import Portal from "@cpnt/Portal/Portal";
import Radio2 from "@cpnt/Radio2/Radio2";
import { senioridadeFreelancer } from "@func/utils";
import { SelecaoIdiomasNiveis } from "@cpnt/SelecaoIdiomasNiveis/SelecaoIdiomasNiveis";
import { PtRemuneracao } from "@cpnt/Partes/PtRemuneracao/PtRemuneracao";

const Passo4 = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		stateDados = {},
		setStateDados,

		stateAreas = [],
		loadingGetAreas,

		stateSenioridades = [],
		loadingGetSenioridades,

		stateHabilidades = [],
		setStateHabilidades,
		loadingGetHabilidades,

		stateIdiomas = [],
		setStateIdiomas,
		stateIdiomasNiveis,
		loadingGetIdiomas,

		stateSegmentos = [],
		setStateSegmentos,
		loadingGetSegmentos,

		stateCompetencias = [],
		setStateCompetencias,
		loadingGetCompetencias,

		getHabilidades,
		before,
	} = props;

	const [showPortalHabilidades, setShowPortalHabilidades] = useState(false);
	const [showPortalIdiomas, setShowPortalIdiomas] = useState(false);
	const [showPortalSegmentos, setShowPortalSegmentos] = useState(false);
	const [showPortalCompetencias, setShowPortalCompetencias] = useState(false);

	return (
		<>
			<div className={`cpnt-passo4 ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-passo4-box ${className}`}>
					{before}
					<div>
						<h3 className="subtitulo margin-t-0 margin-b-10">Perfil profissional</h3>
						<div className="wrap-5">
							<div className="flex">
								<div className="input-box w-50p">
									<Input2
										label="Título profissional"
										req
										tip="Informe um título para o seu perfil (ex: programador full-stack, redator publicitário, designer UX, gerente de projetos, etc.)"
										_={{
											value: stateDados.title,
											onChange: e => {
												setStateDados({
													...stateDados,
													title: e.target.value,
												});
											}
										}}
									/>
								</div>
								<div className="input-box w-50p">
									<Select2
										label="Categoria"
										req
										tip="Qual área você tem experiência e está focado atualmente."
										_={{
											value: stateDados.id_area,
											options: stateAreas,
											onChange: e => {
												setStateDados({
													...stateDados,
													id_area: e.value,
												});
												getHabilidades(e.value);
											}
										}}
										loading={loadingGetAreas || loadingGetHabilidades}
									/>
								</div>
							</div>
						</div>
					</div>

					<div>
						<h3 className="subtitulo margin-b-10 flex flex-center-v flex-wrap">
							<Tip
								tip={
									<>
										<span>Qual seu nível de experiência nessa área?</span>
										<div className="q"></div>
									</>
								}
								info
							>
								As habilidades correspondem às suas competências técnicas. Elas se relacionam ao conhecimento que você adquiriu com suas experiências e formações profissionais. Por exemplo: graduação, curso técnico, domínio de outros idiomas, metodologias de trabalho e especializações.
							</Tip>
						</h3>

						<div className="cpnt-passo3-box-check margin-t-15">
							{loadingGetSenioridades ? (
								<div className="inline-flex">
									<Loading alt />
								</div>
							) : (
								stateSenioridades.map((val, key) => {
									return (
										<div key={key} className="margin-t-5">
											<Radio2
												label={senioridadeFreelancer(val.value)}
												_={{
													checked: val.value === stateDados.seniority ? true : false,
													onChange: () => {
														setStateDados({
															...stateDados,
															seniority: val.value,
														});
													}
												}}
											/>
										</div>
									)
								})
							)}
						</div>

						<PtRemuneracao
							stateDados={stateDados}
							setStateDados={setStateDados}
						/>

					</div>

					<ListaSecao
						titulo="Habilidades"
						subtitulo="Escolha no mínimo 3 e no máximo 10"
						textoNenhumItemEncontrado="Nenhuma habilidade selecionada"
						stateDados={stateHabilidades}
						setStateDados={setStateHabilidades}
						keyTitulo="name"
						classNameWrap="margin-t-25"
						onClickAdd={e => setShowPortalHabilidades(true)}
						loading={loadingGetHabilidades}
					/>

					<ListaSecao
						titulo="Idiomas"
						subtitulo="Escolha no mínimo 1"
						textoNenhumItemEncontrado="Nenhum idioma selecionado"
						stateDados={stateIdiomas}
						setStateDados={setStateIdiomas}
						subLabel="name_level"
						textoSubLabelInvalido="informe o nível"
						classNameWrap="margin-t-25"
						onClickAdd={e => setShowPortalIdiomas(true)}
						loading={loadingGetIdiomas}
					/>

					<ListaSecao
						titulo="Segmentos de interesse"
						subtitulo="Escolha no mínimo 3 e no máximo 10"
						textoNenhumItemEncontrado="Nenhum segmento selecionado"
						stateDados={stateSegmentos}
						setStateDados={setStateSegmentos}
						keyTitulo="name"
						classNameWrap="margin-t-25"
						onClickAdd={e => setShowPortalSegmentos(true)}
						loading={loadingGetSegmentos}
					/>

					<ListaSecao
						titulo="Competências"
						subtitulo="Escolha no máximo 5"
						textoNenhumItemEncontrado="Nenhuma competência selecionada"
						stateDados={stateCompetencias}
						setStateDados={setStateCompetencias}
						keyTitulo="name"
						classNameWrap="margin-t-25"
						onClickAdd={e => setShowPortalCompetencias(true)}
						loading={loadingGetCompetencias}
					/>

				</div>
			</div>

			<Portal
				titulo="Selecione suas habilidades"
				show={showPortalHabilidades}
				onClose={() => setShowPortalHabilidades(false)}
				className="w-600"
			>
				<ListaComCheckbox2
					stateItens={stateHabilidades}
					setStateItens={setStateHabilidades}
					after={
						<div className="form-bottom">
							<Button2
								_={{
									value: 'Fechar',
									onClick: e => setShowPortalHabilidades(false)
								}}
							/>
						</div>
					}
				/>
			</Portal>

			<Portal
				titulo="Selecione os idiomas que você tem conhecimento"
				show={showPortalIdiomas}
				onClose={() => setShowPortalIdiomas(false)}
				className="w-600"
			>
				<SelecaoIdiomasNiveis
					itens={stateIdiomas}
					itensSub={stateIdiomasNiveis}
					onChange={setStateIdiomas}
				/>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Fechar',
							onClick: e => setShowPortalIdiomas(false)
						}}
					/>
				</div>
			</Portal>

			<Portal
				titulo="Selecione os segmentos de interesse"
				show={showPortalSegmentos}
				onClose={() => setShowPortalSegmentos(false)}
				className="w-600"
			>
				<div className="fs-14 padding-b-25">Gosta de trabalhar somente em projetos de Educação? Moda? Varejo? Agora é a hora de escolher as áreas que você deseja receber jobs. Selecione até 10 segmentos de seu interesse.</div>
				<ListaComCheckbox2
					stateItens={stateSegmentos}
					setStateItens={setStateSegmentos}
					after={
						<div className="form-bottom">
							<Button2
								_={{
									value: 'Fechar',
									onClick: e => setShowPortalSegmentos(false)
								}}
							/>
						</div>
					}
				/>
			</Portal>

			<Portal
				titulo="Selecione suas competências"
				show={showPortalCompetencias}
				onClose={() => setShowPortalCompetencias(false)}
				className="w-600"
			>
				<div className="fs-14 padding-b-25">Sua vivência influencia suas competências e demonstram quem você é. Por isso, selecione até 5 itens na lista abaixo.</div>
				<ListaComCheckbox2
					stateItens={stateCompetencias}
					setStateItens={setStateCompetencias}
					after={
						<div className="form-bottom">
							<Button2
								_={{
									value: 'Fechar',
									onClick: e => setShowPortalCompetencias(false)
								}}
							/>
						</div>
					}
				/>
			</Portal>
		</>
	);
};

export { Passo4 };
