import React from 'react';

// CSS
import './FotosAgrupadas.css';

// Imagens
import imgUserPicDefault from '@img/userpic.png';
import Tip from '@cpnt/Tip/Tip';

const FotosAgrupadas = props => {

	const dados = {
		fotos: [],
		qtd: 3,
		placeholder: imgUserPicDefault,
		onClick: () => null,
		circulo: true,
		className: '',
		alt: false,
		espacoAlt: false,
		tamanho: 'default',
		...props
	};

	const renderTipo = (val, key) => {

		if(!val.desc) {
			return (
				<div
					key={key}
					className={`cpnt-fotosagrupadas-img ${dados.circulo ? 'cpnt-fotosagrupadas-img-borda' : ''} bg cpnt-fotosagrupadas-grupo cpnt-fotosagrupadas-grupo-${key}`}
					style={dados.placeholder ? {backgroundImage: `url(${dados.placeholder})`} : null}
				>
					{val && (
						<div
							className={`fill bg ${dados.alt ? '' : 'border-radius-50 overflow'}`}
							style={{backgroundImage: `url(${val.foto})`}}
						>
						</div>
					)}
				</div>
			)
		}

		return (
			<Tip
				key={key}
				_={{
					delayLeave: 0,
				}}
				closeOnLeave={true}
				tipContainer={false}
				tipAttr={{
					className: `cpnt-fotosagrupadas-img ${dados.circulo ? 'cpnt-fotosagrupadas-img-borda' : ''} bg cpnt-fotosagrupadas-grupo cpnt-fotosagrupadas-grupo-${key}`,
					style: dados.placeholder ? {backgroundImage: `url(${dados.placeholder})`} : null,
				}}
				child={
					val && (
						<div
							className={`fill bg ${dados.alt ? '' : 'border-radius-50 overflow'}`}
							style={{backgroundImage: `url(${val.foto})`}}
						>
						</div>
					)
				}
			>
				<div>{val.desc}</div>
			</Tip>
		)
	}

	return (
		<div className={`relative inline-flex flex-center-v cpnt-fotosagrupadas-tamanho-${dados.tamanho} ${dados.className} ${dados.alt ? 'cpnt-fotosagrupadas-alt' : ''} ${dados.espacoAlt !== false ? `cpnt-fotosagrupadas-espaco-${dados.espacoAlt}` : ''}`} onClick={e => dados.onClick(e)}>
			{dados.fotos.map((val, key) => {
				if(key === dados.qtd) {
					return (
						<div key={key} className="cpnt-fotosagrupadas-grupo-mais">
							+{(dados.fotos.length - dados.qtd)}
						</div>
					)
				}
				if(key > dados.qtd) return null;
				return renderTipo(val, key);
			})}
		</div>
	)
}

export default FotosAgrupadas;
