import React, { useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './RevisaoComplementoBriefing.css';

// Componentes
import { BoxWrap } from "@cpnt/BoxWrap/BoxWrap";
import LinhaItem from '@cpnt/LinhaItem/LinhaItem';
import Loading from "@cpnt/Loading/Loading";
import { ItemProfisisonalSelecionadoComImg } from '@cpnt/ProfissionaisSelecionados/ProfissionaisSelecionados';

// Objetos

// Funções
import { LIMITE_QTD_FREELANCERS_COMPLEMENTO_BRIEFING } from "@_config/config";
import Checkbox2 from "@cpnt/Checkbox2/Checkbox2";
import { htmlToJsx, msg } from "@func/utils";
import { showPortal } from "@route/routeUtils";

const RevisaoComplementoBriefing = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		stateGet,
		navigate,
		stateDados = {},
		setStateDados,
		setStep,

		titulo = 'Revisão do complemento da proposta e publicação',
		stateDescricao = '',

		stateListaAtual,
		setStateListaAtual,
		stateListas,
		setStateListas,
		stateSelecionados,
		setStateSelecionados,

		stateClientes,
		stateProjetos,
		stateUsuarios,
		stateAreas,

		stateIdiomas,
		stateHabilidades,
		stateCompetencias,
		stateSegmentos,

		stateFreelancers,
		setStateFreelancers,
		loading,

		isComplemento,
	} = props;

	return (
		<div className="relative">
			<h3 className="titulo">{titulo}</h3>

			{loading && <Loading absolute bgAlt2 />}

			<BoxWrap titulo={`Dados da edição`} className="margin-t-15">
				<LinhaItem titulo="Título" desc={stateDados.title} alt />
				<LinhaItem titulo="ID da proposta" desc={stateDados.id} alt />
				<LinhaItem titulo="Motivo da edição" desc={stateDados.reason || <span className="color-red">Não informado. Informe o motivo.</span>} alt />
			</BoxWrap>

			<BoxWrap titulo={`Novos profissionais selecionados`} className="margin-t-15">

				<div className="relative padding-v-1">
					{stateDados._ignorar_freelancers ? (
						<div className="over-conteudo"></div>
					) : null}
					<span className="a" onClick={e => showPortal({portal: 'listas-salvas-local', stateGet, navigate})}>Selecionar lista diferente</span>
				</div>

				<div className="margin-t-15 sep-t sep-b padding-v-10 relative">
					{stateDados._ignorar_freelancers ? (
						<div className="over-conteudo"></div>
					) : null}
					<LinhaItem
						titulo="Lista atual"
						desc={stateListaAtual}
						alt
					/>
					<LinhaItem
						titulo="Total de profissionais nessa lista"
						desc={stateSelecionados.length}
						alt
					/>
				</div>

				{store.usuario.id_company !== 1 && stateSelecionados && stateSelecionados.length > LIMITE_QTD_FREELANCERS_COMPLEMENTO_BRIEFING ? (
					<div className="margin-t-15 sep-b padding-b-10 relative">
						<div className="box-default-3 box-error">
							<h3 className="color-red margin-t-0">Selecione no máximo {LIMITE_QTD_FREELANCERS_COMPLEMENTO_BRIEFING} profissionais</h3>
							<div>Você ultrapassou o limite máximo de {LIMITE_QTD_FREELANCERS_COMPLEMENTO_BRIEFING} profissionais por proposta de job. Remova o excedente para poder finalizar o complemento da proposta.</div>
							<div className="margin-t-20"><span className="link" onClick={e => {
								showPortal({portal: 'listas-salvas-local', stateGet, navigate});
								msg.info(`Para continuar, remova os excedentes da lista até que fique com o total de ${LIMITE_QTD_FREELANCERS_COMPLEMENTO_BRIEFING} profissionais, ou selecione uma outra lista com essa quantidade ou menos.`);
							}}>EDITAR LISTA</span></div>
						</div>
					</div>
				) : null}

				{stateSelecionados && stateSelecionados.length ? (
					<div className="wrap-h-5 padding-v-10 relative border-b">
						{stateDados._ignorar_freelancers ? (
							<div className="over-conteudo"></div>
						) : null}
						<div className="flex flex-wrap">
							{stateSelecionados.map((val, key) => {
								return (
									<ItemProfisisonalSelecionadoComImg
										key={key}
										dados={val}
										stateGet={stateGet}
										navigate={navigate}
										classNameWrap=""
										className=""
										x={false}
									/>
								)
							})}
						</div>
					</div>
				) : (
					<div>Nenhum profissional novo selecionado.</div>
				)}

				<div className="margin-t-15">
					<Checkbox2
						label={
							<>
								<div>Não incluir esses profissionais na proposta</div>
								<div className="color-1 fs-13 margin-t-5">Caso marcado, os profissionais dessa lista não serão adicionados a essa proposta.</div>
							</>
						}
						_={{
							checked: stateDados._ignorar_freelancers,
							onChange: e => {
								setStateDados({
									...stateDados,
									_ignorar_freelancers: e.target.checked,
								});
							}
						}}
					/>
				</div>
			</BoxWrap>

			<BoxWrap titulo="Notificações" className="margin-t-15">
				<div className="color-1 fs-16">Selecione quem você deseja notificar sobre essa atualização</div>
				<div className="margin-t-15">
					<Checkbox2
						label="Novos freelancers selecionados"
						_={{
							checked: stateDados.notify_new_freelancer,
							onChange: e => {
								setStateDados({
									...stateDados,
									notify_new_freelancer: e.target.checked ? 1 : 0,
								});
							}
						}}
						wrap="margin-t-10"
					/>
					<Checkbox2
						label="Novo responsável selecionado"
						_={{
							checked: stateDados.notify_responsible,
							onChange: e => {
								setStateDados({
									...stateDados,
									notify_responsible: e.target.checked ? 1 : 0,
								});
							}
						}}
						wrap="margin-t-10"
					/>
					<Checkbox2
						label="Freelancers anteriores a edição"
						_={{
							checked: stateDados.notify_old_freelancer,
							onChange: e => {
								setStateDados({
									...stateDados,
									notify_old_freelancer: e.target.checked ? 1 : 0,
								});
							}
						}}
						wrap="margin-t-10"
					/>
				</div>
			</BoxWrap>

			<BoxWrap titulo="Descrição da proposta de job" className="margin-t-15" classNameDesc="box-txt fs-15">
				{stateDescricao && stateDescricao !== '' ? htmlToJsx(stateDescricao) : (
					<div>
						<div className="color-red">Nenhuma descrição adicionada.</div>
						<div className="fs-15">Você pode adicionar uma descrição no passo 1.</div>
					</div>
				)}
			</BoxWrap>

		</div>
	);
};

export { RevisaoComplementoBriefing };
