import React from 'react';

// css
import './TextoWrap.css';

const TextoWrap = props => {
	const {
		titulo,
		subtitulo,
		classNameTitulo = '',
		classNameSubtitulo = '',
		className = '',
		wrap = '',
		tipo = '',
		corAlt,
		borda,
	} = props;

	return (
		<div className={`cpnt-textowrap ${wrap} ${corAlt ? `cpnt-textwrap-coralt-${corAlt === true ? '1' : corAlt}` : ''}`}>
			<div className={`cpnt-textowrap-box ${className} ${tipo} ${borda ? 'cpnt-textwrap-borda' : ''}`}>
				{titulo && <div className={`cpnt-textowrap-titulo ${classNameTitulo}`}>{titulo}</div>}
				{subtitulo && <div className={`cpnt-textowrap-subtitulo ${classNameSubtitulo}`}>{subtitulo}</div>}
			</div>
		</div>
	);
};

export default TextoWrap;