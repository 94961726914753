import { disponibilidadeFreelancer } from "@func/utils";

export const NOMES_CAMPOS = {
	name: 'Nome',
	password: 'Senha',
	email: 'E-mail',
	email_responsible: 'E-mail do responsável',
	cpf_cnpj: 'CPF ou CNPJ',
	cnpj: 'CNPJ',
	accept_terms: 'Termos de uso',
	accept_issuing_invoice: 'Termos de Nota Fiscal',
	id_bank: 'ID do banco',
	full_name: 'Nome completo',
	agency: 'Agência',
	account: 'Conta',
	cpf: 'CPF',
	pix_value: 'PIX',
	pix_type: 'Tipo de chave PIX',
	swift_bic_code: 'Código Swift / BIC',
	iban_account_number: 'Número de conta / IBAN',
	accept_date_payment: 'Termos de data de pagamento',
	actual_password: 'Senha atual',
	new_password: 'Nova senha',
	password_confirmation: 'Confirmação de senha',
	city: 'Cidade',
	complement: 'Complemento',
	district: 'Bairro',
	number: 'Número',
	state: 'Estado',
	street: 'Logradouro',
	zip_code: 'CEP',
	company: 'Empresa',
	description: 'Descrição',
	start_date: 'Data inicial',
	end_date: 'Data final',
	title: 'Título',
	id_user: 'Usuário',
	id_project: 'Projeto',
	id_freelancer: 'Freelancer',
	id_area: 'Categoria',
	id_company: 'Empresa',
	id_position: 'Cargo',
	social_reason: 'Razão social',
	name_responsible: 'Nome do responsável',
	position_responsible: 'Cargo do responsável',
	id_customer: 'Cliente',
	hash: 'Token',
	message: 'Mensagem',
	phone: 'Telefone',
	role: 'Cargo',
	url: 'URL',
	issuer: 'Emissor',
	issuer_date: 'Data de emissão',
	issue_date: 'Data de emissão',
	percentage: 'Porcentagem',
	fantasy_name: 'Nome fantasia',
	id_model_work: 'Modelo de trabalho',
	id_profession: 'Profissão',
	seniority: 'Senioridade',
	text: 'Descrição',
	id_type_contract: 'Tipo de contrato'
};

export const NIVEIS_ACESSO_GERAL = [
	{
		value: 1,
		label: 'Administrador',
	},
	{
		value: 2,
		label: 'Freelancer',
	},
	{
		value: 3,
		label: 'Hunter',
	},
];

export const NIVEIS_ACESSO_GERAL_PLURAL = [
	{
		value: 1,
		label: 'Administradores',
	},
	{
		value: 2,
		label: 'Freelanceres',
	},
	{
		value: 3,
		label: 'Hunters',
	},
];

export const NIVEIS_ACESSO = [
	{
		value: 1,
		label: 'Administrador',
	},
	{
		value: 3,
		label: 'Hunter',
	},
];

export const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
export const dias = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

export const localidade = {
	localize: {
		month: n => meses[n],
		day: n => dias[n]
	},
	formatLong: {}
};

export const LISTA_USUARIOS_CROWD = [
	{
		img: require(`@img/fotos/equipe/gabriel.png`),
		nome: 'Gabriel Matias',
		area: 'CEO. Fundador',
	},
	{
		img: require(`@img/fotos/equipe/juan.png`),
		nome: 'Juan Zaragoza',
		area: 'Novos Negócios',
	},
	{
		img: require(`@img/fotos/equipe/juliene.png`),
		nome: 'Juliene Goes',
		area: 'Financeiro e RH',
	},
	{
		img: require(`@img/fotos/equipe/danilo.png`),
		nome: 'Danilo Matos',
		area: 'Comercial',
	},
	{
		img: require(`@img/fotos/equipe/sheila.png`),
		nome: 'Sheila Limp',
		area: 'Atendimento',
	},
	{
		img: require(`@img/fotos/equipe/thais.png`),
		nome: 'Thais Sarno',
		area: 'Atendimento',
	},
	{
		img: require(`@img/fotos/equipe/amanda.png`),
		nome: 'Amanda Froes',
		area: 'Atendimento',
	},
	{
		img: require(`@img/fotos/equipe/priscila.png`),
		nome: 'Priscila Giancursi',
		area: 'Atendimento',
	},
	{
		img: require(`@img/fotos/equipe/fabi.png`),
		nome: 'Fabi Gomes',
		area: 'Marketing',
	},
	{
		img: require(`@img/fotos/equipe/natalia.png`),
		nome: 'Natalia Laurindo',
		area: 'Operação',
	},
	{
		img: require(`@img/fotos/equipe/dionathan.png`),
		nome: 'Dionathan Clak',
		area: 'Tecnologia',
	},
	{
		img: require(`@img/fotos/equipe/alexandre.png`),
		nome: 'Alexandre Rosembaum',
		area: 'Tecnologia',
	},
	{
		img: require(`@img/fotos/equipe/jana.png`),
		nome: 'Jana Diniz',
		area: 'Design',
	},
	{
		img: require(`@img/fotos/equipe/verena.png`),
		nome: 'Verena Spohr',
		area: 'Conteúdo',
	},
	{
		img: require(`@img/fotos/equipe/nicole.png`),
		nome: 'Nicole Torres',
		area: 'Conteúdo',
	},
	{
		img: require(`@img/fotos/equipe/natasha.png`),
		nome: 'Natasha Fogasça',
		area: 'Design',
	},
];

export const LISTA_FREELANCERS_CROWD = [
	{
		id: 1,
		nome: 'Rafael Nepomuceno',
		area: 'Desenvolvimento',
		img: require(`@img/fotos/freela/rafael.png`)
	},
	{
		id: 2,
		nome: 'Rodrigo Nunes',
		area: 'Desenvolvimento',
		img: require(`@img/fotos/freela/nunes.png`)
	},
	{
		id: 3,
		nome: 'Sandra Segato',
		area: 'Desenvolvimento',
		img: require(`@img/fotos/freela/sandra.png`)
	},
];

export const FILTROS_RESULTADOS = [
	{value: 10, label: '10 resultados'},
	{value: 20, label: '20 resultados'},
	{value: 30, label: '30 resultados'},
	{value: 40, label: '40 resultados'},
	{value: 50, label: '50 resultados'},
	{value: 60, label: '60 resultados'},
	{value: 70, label: '70 resultados'},
	{value: 80, label: '80 resultados'},
	{value: 90, label: '90 resultados'},
	{value: 100, label: '100 resultados'},
];

export const STATUS_PROJETO = [
	{value: 1, label: 'Ativo'},
	{value: 2, label: 'Pausado'},
	{value: 3, label: 'Finalizado'},
];

export const STATUS_FILTRO = [
	{label: 'Ativo', value: 1},
	{label: 'Inativo', value: 0},
	{label: 'Todos', value: 2},
];

export const CADASTRO_INDEPENDENTE = [
	{label: 'Cadastros independentes', value: 1},
	{label: 'Cadastros via painel', value: 0},
	{label: 'Todos', value: 2},
];

export const STATUS_FILTRO_MIN = [
	{label: 'Ativo', value: 1},
	{label: 'Inativo', value: 0},
];

export const STATUS_DEFAULT = [
	{label: 'Ativo', value: 1},
	{label: 'Inativo', value: 0},
];

export const SENIORIDADE = [
	{label: 'Sênior', value: 3},
	{label: 'Pleno', value: 2},
	{label: 'Júnior', value: 1},
];

export const SENIORIDADE_ALGOLIA = [
	{label: 'Sênior', value: 'senior'},
	{label: 'Pleno', value: 'pleno'},
	{label: 'Júnior', value: 'junior'},
];

export const SENIORIDADE_TEMPO = [
	{label: 'Sênior - mais que 5 anos de exeperiência', value: 'senior'},
	{label: 'Pleno - entre 3 e 5 anos de experiência', value: 'pleno'},
	{label: 'Júnior - menos que 3 anos de experiência', value: 'junior'},
];

export const DISPONIBILIDADE_HORAS_PROFISSIONAL = [
	{label: 'De 31h a 40h por semana', value: 1},
	{label: 'De 11h a 30h por semana', value: 2},
	{label: 'Cerca de 10h por semana', value: 3},
];

export const CHAT_MSGS_TIPO_CORES = [
	{label: 'Padrão', value: 'default'},
	{label: 'Verde', value: 'verde'},
	{label: 'Vermelho', value: 'vermelho'},
	{label: 'Amarelo', value: 'amarelo'},
	{label: 'Azul', value: 'azul'},
];

export const SELECT_TIPO_SERVICO = [
	{label: 'Profissional', value: 13}, // Tipo de template, rota: /select/templates-type
	{label: 'Serviço', value: 14},
];

export const SELECT_TIPO_SERVICO_STRING = [
	{label: 'Profissional', value: 'P'}, // Tipo de template, rota: /select/templates-type
	{label: 'Serviço', value: 'S'},
];

// Tabela: crowd_template_types
// id: 1  =  tipo: Propostas de jobs
// id: 2  =  tipo: Pagamentos de jobs
// id: 3  =  tipo: Termos de uso e condições
// id: 4  =  tipo: Carta de apresentação de usuários
// id: 5  =  tipo: Carta de apresentação de freelancers
// id: 6  =  tipo: Mensagens personalizadas do chat
// id: 7  =  tipo: Contratos de empresas
// id: 8  =  tipo: Contratos de freelancers

export const TIPOS_TEMPLATE = {
	editor: [1, 3, 7, 8, 12, 13, 14], // IDs
	textarea: [2, 4, 5, 6, 9, 11],
};

export const CORES_AREAS = {
	1: {
		area: 'Administração e Finanças',
		cor: '#666666',
		corTexto: '#FFF',
	},
	2: {
		area: 'Áudio, Foto e Vídeo',
		cor: '#BCDEA1',
		corAlt: '#1C391B',
	},
	3: {
		area: 'Atendimento e Customer Success',
		cor: '#666666',
		corTexto: '#FFF',
		corAlt: '#000000',
		corAltTexto: '#000000',
	},
	4: {
		area: 'Design e UX',
		cor: '#ED4B68',
		corAlt: '#FFF',
	},
	5: {
		area: 'Comercial e SDR',
		cor: '#000000',
		corAlt: '#666666',
	},
	6: {
		area: 'Conteúdo e Redes Sociais',
		cor: '#BCDEA1',
		corAlt: '#1C391B',
	},
	7: {
		area: 'Marketing e Growth',
		cor: '#000000',
		corAlt: '#666666',
		corTexto: '#FFF',
	},
	8: {
		area: 'Tecnologia e Programação',
		cor: '#84C9F2',
		corAlt: '#1F1D32',
	},
	9: {
		area: 'Mídia e Tráfego',
		cor: '#FFC701',
		corAlt: '#7A6000',
	},
	10: {
		area: 'Gerente de Projetos e P.Os',
		cor: '#84C9F2',
		corAlt: '#1F1D32',
	},
	11: {
		area: 'RH e Talent Acquisition',
		cor: '#666666',
		corTexto: '#FFF',
		corAlt: '#000000',
	},
};
