import React from 'react';

// css
import c from './EmManutencao.module.scss';

import logo from '@img/logo.png';

const EmManutencao = props => {

	return (
		<>
			<div className={`${c['container']}`}>
				<div className={`${c['inner']}`}>
					<div className={`${c['img-logo']}`}><img src={logo} alt="Logo CROWD" className="block w-100p max-w-250" /></div>
					<div className={`${c['textos']} box-txt`}>
						<h3 className={`${c['aviso-titulo']} margin-v-0 padding-t-15`}>CROWD - Soluções em Marketing e Tecnologia</h3>
						<p className="margin-v-0 padding-t-25">Estamos atualizando nossa plataforma e retornaremos em alguns instantes...</p>
					</div>
				</div>
			</div>
		</>
	);
};

export { EmManutencao };