import React, { useState } from 'react';

// css
import './SecaoPremios.css';

// Portais
import { PortalPremioFormCadastro } from "@cpnt/Portais/PortaisPremios/PortalPremioFormCadastro/PortalPremioFormCadastro";
import { PortalPremioFormEdicao } from "@cpnt/Portais/PortaisPremios/PortalPremioFormEdicao/PortalPremioFormEdicao";

// Componentes
import { ListaSecao } from "@cpnt/ListaSecao/ListaSecao";
import { dataEngParaBr } from "@func/utils";

const SecaoPremios = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		classNameTituloBox = '',
		classNameListaWrap = 'margin-t-25',
		classNameBox = 'box-2',

		onClickBtnSalvar,
		loadingBtnSalvar,
		statePremios = [],
		setStatePremios,
		loadingGetPremios,
	} = props;

	const [showPortalAdd, setShowPortalAdd] = useState(false);
	const [showPortalEditar, setShowPortalEditar] = useState(false);
	const [statePremioEditar, setStatePremioEditar] = useState({});

	return (
		<>
			<div className={`cpnt-secexp ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-secexp-box ${className}`}>
					<ListaSecao
						titulo="Prêmios"
						textoAdd="Adicionar"
						onClickBtnSalvar={onClickBtnSalvar}
						loadingBtnSalvar={loadingBtnSalvar}
						textoNenhumItemEncontrado="Nenhum prêmio adicionado"
						stateDados={statePremios}
						setStateDados={setStatePremios}
						keyTitulo="title"
						keyDesc="issue_date"
						keyDescCb={dataEngParaBr}
						classNameWrap={classNameListaWrap}
						classNameTituloBox={classNameTituloBox}
						classNameBox={classNameBox}
						exibirApenasChecked={false}
						onClickAdd={e => setShowPortalAdd(true)}
						onClickEdit={e => {
							setStatePremioEditar({
								key: e.key,
								...e.dados,
							});
							setShowPortalEditar(true);
						}}
						onClickRemover={e => {
							var itens = statePremios.filter((val, key) => key !== e.key);
							setStatePremios(itens);
						}}
						loading={loadingGetPremios}
						alt
					/>
				</div>
			</div>

			<PortalPremioFormCadastro
				show={showPortalAdd}
				onClose={e => setShowPortalAdd(false)}
				onSuccess={e => {
					var exp = [...statePremios];
					exp.push(e);
					setStatePremios(exp);
					setShowPortalAdd(false);
				}}
			/>

			<PortalPremioFormEdicao
				show={showPortalEditar}
				onClose={e => setShowPortalEditar(false)}
				dados={statePremioEditar}
				onSuccess={e => {
					var exp = [...statePremios];
					exp[e.key] = e;
					setStatePremios(exp);
					setShowPortalEditar(false);
				}}
			/>
		</>
	);
};

export { SecaoPremios };
