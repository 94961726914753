import React, { Fragment } from 'react';

// Libs
import { components } from 'react-select';
import WindowedSelect from 'react-windowed-select';

// CSS
import c from './Select.module.scss';

// Func
import { valueSelect, valueSelectMultiple } from "./Options/funcSelect";

// Componentes
import { Loading } from "@_cpnt/Loading/Loading";
import { OptionDefault } from "./Options/OptionDefault/OptionDefault";

const MenuCustomizado = (props, before, after) => {
	return (
		<Fragment>
			<components.Menu {...props}>
				<div>
					{before}
					<div>{props.children}</div>
					{after}
				</div>
			</components.Menu>
		</Fragment>
	);
};

export const Select = props => {

	const {
		req,
		label,
		loading,
		before,
		after,
		tip,
		clear,
		show = true,
		_ = {},
	} = props;

	if(!show) {
		return <></>;
	}

	var _defaults = {
		placeholder: 'Selecione...',
		isClearable: clear,
		..._,
		components: {
			Option: e => <OptionDefault {...e} />,
			Menu: e => MenuCustomizado(e, before, after),
			..._.components,
		},
	}

	if(_.value !== undefined && _.value !== null) {
		if(typeof(_.value) === 'object' && _.value.length !== undefined) {
			_.value = valueSelectMultiple(_.value, _.options);
			_defaults.value = _.value;
		} else {
			if(typeof(_.value) === 'object') {
				_.value = _.value.value;
			}
			_.value = valueSelect(_.value, _.options);
			_defaults.value = _.value;
		}
	}

	return (
		<div className={`${c['container']}`}>
			<div className={`${c['inner']}`}>
				<Label label={label} req={req} className={`${c['label']}`} />
				<div {...(_.onClick ? {onClick: _.onClick} : null)} className={`${c['select-wrapper']}`}>
					<Loading
						abs
						show={loading}
						bg="transparent"
						size="x2"
						{...(label ? {className: c['loading-label']} : {})}
					/>
					<WindowedSelect
						{..._defaults}
						// className={c['select-container']}
						// classNamePrefix={c['menu']}
						classNames={{
							container: () => c['select-container'],
							control: ({isFocused}) => {
								return `${c['select-control']} ${isFocused ? c['focused'] : ''}`
							},
							input: () => c['select-input']
						}}
						formatOptionLabel={option => (
							<div className={`${c['selected-box']}`}>
								{option.icon ? (
									<div className={`${c['selected-img']}`} style={{backgroundImage: `url(${option.icon})`}} />
								) : null}
								<span className={`${c['selected-label']}`}>{option.label}</span>
							</div>
						)}
						theme={(theme) => ({
							...theme,
							colors: {
							  ...theme.colors,
							  primary25: 'var(--color-2)',
							  primary: 'var(--color-1)',
							},
						})}
					/>
				</div>
			</div>
		</div>
	);
}

const Label = (props) => {
	const {
		label,
		req,
	} = props;

	if(!label) return <></>;

	if(typeof(label) === 'string') {
		return <div className={`${c['label']}`}>{label}{req ? <span className={`${c['req']}`}></span> : null}</div>;
	}

	return label;
}
