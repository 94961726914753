import React, { useState, useEffect, useRef } from 'react';
import InputMask from '@mona-health/react-input-mask';
import CurrencyInput from 'react-currency-input';

// CSS
import './Input.css';

const Input = props => {

	var dados = {
		type: 'text',
		value: '',
		label: false,
		readOnly: false,
		class: '',
		classBox: '',
		active: false,
		invalid: false,
		placeholder: '',
		wrap: false,
		autoFocus: false,
		dropDown: false,
		ref: null,
		init: false,
		invalidToggle: () => null,
		onClick: () => null,
		onBlur: () => null,

		// Mask
		mask: false,
		maskPlaceholder: '',
		// beforeMaskedStateChange: e => e,

		...props
	};

	if(dados.value === null || dados.value === undefined) {
		dados.value = '';
	}

	const [value, setValue] = useState(dados.value);
	const inputRef = useRef();

	useEffect(() => {
		if(dados.init) {
			if(!dados.autoFocus && inputRef.current && inputRef.current.focus && inputRef.current.blur) {
				inputRef.current.focus();
				inputRef.current.blur();
			}
		}
	}, []);

	useEffect(() => {
		setValue(dados.value);
	}, [dados.value]);

	const setValueCallback = e => {
		if(dados.valueCallback) {
			dados.valueCallback(e);
		}
		setValue(e);
	}

	const teste = e => {
		let { value } = e.nextState;
		if (value.endsWith("/")) {
			value = value.slice(0, -1);
		}

		return {
			...e.nextState,
			value
		};
	}

	return (
		<div className={dados.wrap ? dados.wrap : ''}>
			{/* <div className={`inputtext-box ${dados.active ? 'inputtext-box-ativo' : ''} ${dados.classBox} ${dados.invalid ? 'inputtext-box-invalido' : ''} ${dados.dropDown ? 'inputtext-box-dropdown' : ''}`}> */}
			<div className={`inputtext-box inputtext-box-ativo ${dados.classBox} ${dados.invalid ? 'inputtext-box-invalido' : ''} ${dados.dropDown ? 'inputtext-box-dropdown' : ''}`}>

				{dados.type === 'textarea' && (
					<textarea
						name={dados.name}
						value={(value === null ? '' : value)}
						onChange={e => setValueCallback(e.target.value)}
						onFocus={e => dados.invalidToggle({
							[`${dados.name}Invalido`]: false
						})}
						{...(dados.autoFocus ? {autoFocus: true} : null)}
						className={dados.class}
						placeholder={dados.placeholder}
						ref={inputRef}
						readOnly={dados.readOnly}
						onClick={dados.onClick}
					/>
				)}

				{dados.mask &&
					<InputMask
						name={dados.name}
						value={(value === null ? '' : value)}
						onChange={e => setValueCallback(e.target.value)}
						onFocus={e => dados.invalidToggle({
							[`${dados.name}Invalido`]: false
						})}
						{...(dados.autoFocus ? {autoFocus: true} : null)}
						className={dados.class}
						placeholder={dados.placeholder}
						ref={inputRef}
						readOnly={dados.readOnly}
						onClick={dados.onClick}

						mask={dados.mask}
						maskPlaceholder={dados.maskPlaceholder}
						beforeMaskedStateChange={teste}
						onBlur={() => dados.onBlur(dados)}
					/>
				}

				{dados.dinheiro &&
					<CurrencyInput
						name={dados.name}
						value={(value === null ? '' : value)}
						onChangeEvent={e => setValueCallback(e.target.value)}
						onFocus={e => dados.invalidToggle({
							[`${dados.name}Invalido`]: false
						})}
						{...(dados.autoFocus ? {autoFocus: true} : null)}
						className={dados.class}
						placeholder={dados.placeholder}
						ref={inputRef}
						readOnly={dados.readOnly}
						onClick={dados.onClick}
						decimalSeparator=","
						thousandSeparator="."
					/>
				}

				{!dados.mask && !dados.dinheiro && dados.type !== 'textarea' &&
					<input
						type={dados.type}
						name={dados.name}
						value={(value === null ? '' : value)}
						onChange={e => setValueCallback(e.target.value)}
						onFocus={e => dados.invalidToggle({
							[`${dados.name}Invalido`]: false
						})}
						{...(dados.autoFocus ? {autoFocus: true} : null)}
						className={dados.class}
						placeholder={dados.placeholder}
						ref={dados.ref}
						readOnly={dados.readOnly}
						onClick={dados.onClick}
						onBlur={() => dados.onBlur(dados)}
					/>
				}

				{dados.label && <label>{dados.label}</label>}
				{dados.dropDown && <span className="inputtext-dropdown"></span>}
			</div>
		</div>
	)
}

export default Input;