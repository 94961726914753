import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './MeusJobs.css';
import 'react-datepicker/dist/react-datepicker.css';

// API
import apiTasks from '@api/tasks/tasks';

// Components
import Input2 from '@cpnt/Input2/Input2';
import Loading from '@cpnt/Loading/Loading';
import { Select2 } from '@cpnt/Select2/Select2';
import Icone from '@cpnt/Icone/Icone';
import ItemFotoDesc from '@cpnt/ItemFotoDesc/ItemFotoDesc';
import Paginacao from '@cpnt/Paginacao/Paginacao';
import Qtd from '@cpnt/Qtd/Qtd';
import PortalPerfil from '@portalGeral/PortalPerfil/PortalPerfil';
import StatusCards from '@cpnt/StatusCards/StatusCards';

// Imagens
import imgIconeComentario from '@svg/regular/comment-lines.svg';
import imgIconePDF from '@svg/solid/file-pdf.svg';
import imgIconeXML from '@svg/solid/file-code.svg';
import imgIconeProposta from '@svg/solid/file-magnifying-glass.svg';

// Funções
import { caminhoFoto, dataApiParaBr, formatarDinheiro, validarDataAPI, singularPlural, tamanhoTela } from '@func/utils';
import { removerKeysVazias } from '@func/utils';
import Button2 from '@cpnt/Button2/Button2';
import { PlaceholderRender } from "@cpnt/Placeholder/Placeholder";
import FiltrosWrap from "@cpnt/FiltrosWrap/FiltrosWrap";
import { clearGet, closePortal, getPortalDados, initFuncExec, isShowPortal, setGet, setGetNavigate, showPortal } from "@route/routeUtils";
import useWindowDimension from "@/_/hooks/useWindowDimensions";
import { FILTROS_RESULTADOS } from "@config/objetos";
import { BtnLimparFiltros } from "@cpnt/BtnLimparFiltros/BtnLimparFiltros";
import TotalPaginacao from "@cpnt/TotalPaginacao/TotalPaginacao";
import Tip from "@cpnt/Tip/Tip";
import IconeChat from "@cpnt/IconeChat/IconeChat";
import ImgTituloDesc from "@cpnt/ImgTituloDesc/ImgTituloDesc";
import { PortalJobDetalhes } from "@cpnt/Portais/PortaisProcessoSeletivo/PortalJobDetalhes/PortalJobDetalhes";
import { PortalCardDetalhes } from "@cpnt/Portais/PortaisProcessoSeletivo/PortalCardDetalhes/PortalCardDetalhes";
import Disponibilidade from "@cpnt/Disponibilidade/Disponibilidade";
import { PortalPerfilUsuario } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilUsuario";
import { IconeChatWrap } from "@cpnt/IconeChatWrap/IconeChatWrap";
import { msgs, msgStatus } from "@_func/request";

import { primeiroNome } from "@_func/regex";

const MeusJobs = props => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	const screen = useWindowDimension();

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = (getsAtualizados = getsDefault) => {
		initFuncExec([
			{
				func: getCards,
				param: getsAtualizados
			},
			{
				func: getCombos,
				init: true,
			},
		], init, location, stateLocation);
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		overlay: [],
		limit: 20,
		page: 1,

		title_briefing: '',
		id_company: null,
		hired: null,
		id_user: null,
		id_project: null,
		status: null,
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);


	/*
	** Portais
	** ================================================== */
	const [showPortalPerfilCompleto, setShowPortalPerfilCompleto] = useState(false);

	/*
	** Loading
	** ================================================== */
	const [loadingGetCards, setLoadingGetCards] = useState(false);
	const [loadingCadastrarCard, setLoadingCadastrarCard] = useState(false);
	const [loadingGetEmpresasFiltro, setLoadingGetEmpresasFiltro] = useState(false);
	const [loadingGetClientesFiltro, setLoadingGetClientesFiltro] = useState(false);
	const [loadingGetProjetosFiltro, setLoadingGetProjetosFiltro] = useState(false);
	const [loadingGetCombos, setLoadingGetCombos] = useState(false);
	const [loadingGetUsuariosFiltro, setLoadingGetUsuariosFiltro] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateInit, setStateInit] = useState(false);
	const [stateCards, setStateCards] = useState([]);
	const [_stateCards, _setStateCards] = useState([]);
	const [stateCardsMetadata, setStateCardsMetadata] = useState({});
	const [statePerfilCompletoId, setStatePerfilCompletoId] = useState(false);
	const [statePerfilCompletoTipo, setStatePerfilCompletoTipo] = useState('freelancer');
	const [freelancerPossuiPagamentos, setFreelancerPossuiPagamentos] = useState(true);
	const [stateExibirFiltro, setStateExibirFiltro] = useState(false);

	/*
	** Filtros
	** ================================================== */
	const [stateCombos, setStateCombos] = useState({
		status: [],
		responsibles: [],
		projects: [],
		companies: [],
	});
	const [stateUsuarioSelecionadoFiltro, setStateUsuarioSelecionadoFiltro] = useState(false);
	const [stateStatusSelecionadoFiltro, setStateStatusSelecionadoFiltro] = useState(false);
	const [stateClienteSelecionadoFiltro, setStateClienteSelecionadoFiltro] = useState(false);
	const [stateProjetoSelecionadoFiltro, setStateProjetoSelecionadoFiltro] = useState(false);

	/*
	** API - Jobs
	** ================================================== */
	const getCards = async (dadosObj = {}) => {
		if(loadingGetCards) {
			return;
		}
		setLoadingGetCards(true);

		var dadosEnviar = clearGet(dadosObj);
		const retGetCards = await apiTasks.all.get(dadosEnviar);

		_setStateCards(msgStatus(retGetCards));

		if(retGetCards.status === 200) {
			setStateCards(retGetCards.data);
			setStateCardsMetadata(retGetCards.metadata);
		} else {
			setStateCards([]);
			setStateCardsMetadata({});
		}

		setLoadingGetCards(false);
	}

	/*
	** API - Status do briefing
	** ================================================== */
	const getCombos = async () => {
		setLoadingGetCombos(true);
		const retGetCombos = await apiTasks.tasksCombos.get(store.usuario.id_freelancer);
		if(retGetCombos.status === 200) {
			var combos = Object.keys(retGetCombos.data);
			var combosFinal = {
				status: [],
				responsibles: [],
				projects: [],
				companies: [],
			};

			var temPagamentos = false;

			combos.map((val, key) => {
				retGetCombos.data[val].length && retGetCombos.data[val].map((val2, key2) => {
					combosFinal[val].push({
						value: val2.id,
						label: val2.name,
					});
					if(val !== 'status') {
						temPagamentos = true;
					}
				});
			});

			setFreelancerPossuiPagamentos(temPagamentos);
			setStateCombos(combosFinal);
		} else {
			setStateCombos({
				status: [],
				responsibles: [],
				projects: [],
				companies: [],
			});
			msgs([retGetCombos]);
		}
		setLoadingGetCombos(false);
	}

	var winPosTooltipDireito = tamanhoTela([
		{width: 980, dados: {placement: 'bottom-center'}},
	], {placement: 'right-center'}, screen);

	return (
		<div className="pg-clientes base-full">
			<div className="conteudo-box">
				<div className="titulo-box">
					<h3 className="titulo">Meus Jobs</h3>
				</div>

				<FiltrosWrap>
					<form
						onSubmit={e => e.preventDefault()}
						className="flex flex-wrap wrap-5 relative"
					>
						<div className="input-box w-75p box-2-980 box-1-680">
							<Input2
								label="Pesquisar jobs"
								_={{
									value: stateGet.title,
									onChange: e => {
										setGetNavigate({
											gets: {title: e.target.value},
											navigate,
											stateGet,
											location,
										});
									}
								}}
							/>
						</div>
						<div className="input-box w-25p box-2-980 box-1-680">
							<Select2
								label="Cliente"
								_={{
									value: stateGet.id_company,
									options: stateCombos.companies,
									onChange: e => {
										setGetNavigate({
											gets: {id_company: e ? e.value : null},
											navigate,
											stateGet,
											location,
										});
									},
									isClearable: true,
								}}
								loading={loadingGetCombos}
							/>
						</div>
						<div className="input-box w-25p box-2-980 box-1-680">
							<Select2
								label="Responsável"
								_={{
									value: stateGet.id_user,
									options: stateCombos.responsibles,
									onChange: e => {
										setGetNavigate({
											gets: {id_user: e ? e.value : null},
											navigate,
											stateGet,
											location,
										});
									},
									isClearable: true,
								}}
								loading={loadingGetCombos}
							/>
						</div>
						<div className="input-box w-25p box-2-980 box-1-680">
							<Select2
								label="Projeto"
								_={{
									value: stateGet.id_project,
									options: stateCombos.projects,
									onChange: e => {
										setGetNavigate({
											gets: {id_project: e ? e.value : null},
											navigate,
											stateGet,
											location,
										});
									},
									isClearable: true,
								}}
								loading={loadingGetCombos}
							/>
						</div>
						<div className="input-box w-25p box-2-980 box-1-680">
							<Select2
								label="Status"
								_={{
									value: stateGet.status,
									options: stateCombos.status,
									onChange: e => {
										setGetNavigate({
											gets: {status: e ? e.value : null},
											navigate,
											stateGet,
											location,
										});
									},
									isClearable: true,
								}}
								loading={loadingGetCombos}
							/>
						</div>
						<div className="input-box flex w-25p input-box-btnlimpar box-2-980 box-1-680">
							<Select2
								label="Resultados por página"
								_={{
									value: stateGet.limit,
									onChange: e => {
										setGetNavigate({
											gets: {limit: e.value},
											navigate,
											stateGet,
											location,
										});
									},
									options: FILTROS_RESULTADOS,
								}}
								wrap="flex-1"
							/>
							<BtnLimparFiltros
								_={{
									onClick: e => {
										setGetNavigate({
											gets: getsDefault,
											navigate,
											stateGet,
											location,
										});
									},
								}}
							/>
						</div>
					</form>
				</FiltrosWrap>

				<div id="tabela-container">
					<TotalPaginacao {...{stateGet, stateMetadata: stateCardsMetadata, loading: loadingGetCards, getsDefault}} />
					<div className="table-wrap">
						<table className="tabela">
							<thead>
								<tr>
									<th className="col-id">
										<Tip tip="ID">ID único do job</Tip>
									</th>
									<th className="col-min-3">
										<Tip tip="Resp.">Responsável por esse job</Tip>
									</th>
									<th className="col-min-3">
										<Tip tip="Chat">Utilize o chat para negociar o job ou tirar dúvidas a respeito</Tip>
									</th>
									<th>
										<Tip tip="Título">Título do Job</Tip>
									</th>
									<th>
										<Tip tip="Projeto">Projeto que esse job faz parte</Tip>
									</th>
									<th className="col-valor">
										<Tip tip="Valor">Valor a receber pelo job</Tip>
									</th>
									<th className="col-data">
										<Tip tip="Data aprov.">Data de aprovação do job</Tip>
									</th>
									<th className="col-data">
										<Tip tip="Data pgto.">Data prevista para o pagamento do job</Tip>
									</th>
									<th className="col-min-4">
										<Tip tip="Proposta">Se esse job faz parte de uma proposta de job</Tip>
									</th>
									<th className="tbl-meusjobs-col-status">
										<Tip tip="Status">Status do job</Tip>
									</th>
								</tr>

							</thead>
							<tbody>
								<PlaceholderRender
									loading={loadingGetCards}
									init={init}
									itens={stateCards}
									request={_stateCards}
									cpnt="PlaceholderCards"
									qtdColunas={10}
								>
									{stateCards.map((val, key) => {
										var link = `/meus-jobs/negociacao?id_task=${val.id}${val.id_briefing ? `&id_briefing=${val.id_briefing}` : ''}`;
										return (
											<tr key={key}>

												<td data-titulo="ID" className="fs-12 bold align-center">
													<div className="td-wrap">{val.id}</div>
												</td>

												<td data-titulo="Hunter" className="align-center">
													<div className="td-wrap">
														<ItemFotoDesc
															foto={caminhoFoto(val.user_photo).url}
															className="margin-auto cursor-pointer"
															onClick={() => {
																setStatePerfilCompletoId(val.id_user);
																setShowPortalPerfilCompleto(true);
															}}
														/>
														<div className="text-one-line fs-12">{primeiroNome(val.user_name)}</div>
													</div>
												</td>

												<td data-titulo="Chat">
													<div className="td-wrap scroll-visible align-center">
														<IconeChatWrap
															qtdMensagens={val.countMessages}
															link={link}
															linkState={stateGet}
															_tipDefaults={winPosTooltipDireito}
														/>
													</div>
												</td>

												<td data-titulo="Título">
													<div className="td-wrap">
														<ImgTituloDesc
															wrap="a td-info"
															titulo={val.title || '(job sem título)'}
															desc={val.company_name}
															classNameTitulo="td-titulo"
															classNameDesc="td-desc"
															responsivo={true}
															onClick={() => showPortal({portal: 'detalhes', dados: val.id, stateGet, navigate})}
														/>
													</div>
												</td>

												<td data-titulo="Projeto">
													<div className="td-wrap">
														{val.project_name || '(projeto sem título)'}
													</div>
												</td>

												<td data-titulo="Valor" className="align-center">
													<div className="td-wrap">
														{val.liquid_price ? (
															formatarDinheiro(val.liquid_price, 'R$ ')
														) : (
															<Tip
																tip={formatarDinheiro(val.price, 'R$ ')}
																info
															>
																Valor bruto. Não foi possível calcular o valor líquido sem as taxas da plataforma.
															</Tip>
														)}
													</div>
												</td>

												<td data-titulo="Data aprov." className="align-center">
													<div className="td-wrap">{validarDataAPI(val.approval_at) ? dataApiParaBr(val.approval_at) : '-'}</div>
												</td>

												<td data-titulo="Data pgto." className="align-center">
													<div className="td-wrap">{validarDataAPI(val.payday) ? dataApiParaBr(val.payday) : '-'}</div>
												</td>

												<td data-titulo="Proposta">
													<div className="td-wrap">
														<div className="flex flex-center">
															{val.id_briefing ? (
																<div
																	className="a"
																	onClick={() => showPortal({portal: 'proposta', dados: val.id_briefing, stateGet, navigate})}
																>
																	<Icone
																		imagem={imgIconeProposta}
																		cor="#555"
																		className="margin-auto cursor-pointer"
																	/>
																	<div className="td-desc-alt">Proposta</div>
																</div>
															) : (
																<div>-</div>
															)}
														</div>
													</div>
												</td>

												<td data-titulo="Status">
													<div className="td-wrap align-center">
														<StatusCards
															status={val.status}
														/>
														<div className="td-desc-alt">
															{val.status === 14 ? 'Execução' : (val.status_name ? val.status_name : '(não informado)')}
														</div>
													</div>
												</td>

											</tr>
										)
									})}
								</PlaceholderRender>

							</tbody>
						</table>
					</div>
					<TotalPaginacao {...{stateGet, stateMetadata: stateCardsMetadata, loading: loadingGetCards, getsDefault}} />
				</div>
			</div>

			<PortalCardDetalhes
				show={isShowPortal({portal: 'detalhes', stateGet})}
				id={getPortalDados({portal: 'detalhes', stateGet})}
				onClose={() => closePortal({portal: 'detalhes', stateGet, navigate})}
			/>

			<PortalJobDetalhes
				show={isShowPortal({portal: 'proposta', stateGet})}
				id={getPortalDados({portal: 'proposta', stateGet})}
				onClose={() => closePortal({portal: 'proposta', stateGet, navigate})}
			/>

			{/* Portal informações perfil */}
			<PortalPerfilUsuario
				id={statePerfilCompletoId}
				show={showPortalPerfilCompleto}
				onClose={() => {
					setShowPortalPerfilCompleto(false);
				}}
			/>

		</div>
	);
}

export default MeusJobs;
