import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiLogin = {
	login: {
		post: async dados => {
			return await api.post(`/login`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
		refreshToken: {
			get: async dados => {
				return await api.get(`/login/refreshToken`, config())
				.then(retorno => {
					return validarRetorno(retorno);
				})
				.catch(retorno => {
					return validarRetorno(retorno);
				});
			}
		},
	},

	logout: {
		get: async () => {
			return await api.get(`/login/logout`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiLogin;