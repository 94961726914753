import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalPortfolioFormCadastro.css';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { addPrefixo, camposInvalidos, validarUrl } from '@func/utils';
import FormPortfolio from "@cpnt/Forms/FormPortfolio/FormPortfolio";

const PortalPortfolioFormCadastro = props => {

	const {
		show,
		onClose = () => null,
		closeOnEsc = false,
		portalClassName = 'portal-port-form-cadastro',
		onSuccess = () => null,
		dados = {},
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		url: '',
		...dados,
	};

	const [stateDados, setStateDados] = useState(camposDefault);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados(stateDados);
		}
	}

	/*
	** Editar empresa
	** ================================================== */
	const cadastrar = async e => {
		e.preventDefault();

		const dadosEnviar = {
			...camposDefault,
			url: stateDados.url,
		};

		if(camposInvalidos(dadosEnviar, {}, [
			'url',
		])) return;

		if(!validarUrl(dadosEnviar.url)) return;

		onSuccess({
			...stateDados,
			url: addPrefixo(stateDados.url),
		});

		setTimeout(() => {
			setStateDados(camposDefault);
		}, 400);
	}

	return (
		<Portal
			titulo="Adicionar portfólio"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<form onSubmit={cadastrar}>
				<FormPortfolio
					state={stateDados}
					setState={setStateDados}
				/>
				<div className="form-bottom">
					<Button2
						_={{
							type: 'button',
							value: 'Cancelar',
							className: 'btn btn-1',
							onClick: onClose,
						}}
					/>
					<Button2
						_={{
							value: 'Adicionar portfólio',
						}}
						wrap="margin-l-10"
					/>
				</div>
			</form>
		</Portal>
	);
};

export { PortalPortfolioFormCadastro };
