import React from 'react';

// CSS
import './ItemLista.css';

// Imagens
import imgIconeEditar from '@svg/solid/pencil.svg';
import imgIconeRemover from '@svg/solid/circle-x.svg';

// Componentes
import Icone from '@cpnt/Icone/Icone';

const ItemLista = props => {
	const {
		texto = '',
		acoes = [],
		nowrap = true,
		className = '',
	} = props;

	const definirIcone = icone => {

		var retorno = icone;

		if(icone && typeof(icone) === 'object') {
			if(typeof(icone.imagem) === 'string') {

				if(icone.imagem === 'editar') {
					retorno = {
						imagem: imgIconeEditar,
						cor: icone.cor || '#000',
					};
				}

				else if(icone.imagem === 'remover') {
					retorno = {
						imagem: imgIconeRemover,
						cor: icone.cor || '#E74C3C',
					};
				}

				else {
					retorno = {
						imagem: imgIconeEditar,
						cor: icone.cor || '#000',
					};
				}

			}

		}

		return retorno;
	}

	var acoesJsx;

	if(acoes.length > 0) {
		acoesJsx = acoes.map((val, index) => {
			return (
				<div key={index} className="cpnt-itemlista-acao" onClick={() => val.acao(val)}>
					{val.icone &&
						<div className="cpnt-itemlista-icone-box">
							<Icone
								imagem={definirIcone(val.icone).imagem}
								cor={definirIcone(val.icone).cor}
								tamanho={val.tamanho || '18px'}
							/>
						</div>
					}
					{val.texto && <div className="cpnt-itemlista-texto">{val.texto}</div>}
				</div>
			)
		});
	}

	return (
		<div className={`cpnt-itemlista-container ${className}`}>
			<div className="cpnt-itemlista-titulo-box">
				<div className={`cpnt-itemlista-titulo ${nowrap ? 'cpnt-itemlista-titulo-nowrap' : ''}`}>{texto}</div>
				<div className="cpnt-itemlista-acoes">{acoesJsx}</div>
			</div>
		</div>
	)
}

export default ItemLista;