import React from 'react';

// css
import './PorcentagemCirculo.css';

// Funções
import { corDegrade } from '@func/utils';
import { isNumeric } from "@_func/regex";

const PorcentagemCirculo = props => {
	const {
		className = '',
		wrap = '',
		porcentagem = 0,
		onClick,
		alt,
	} = props;

	var cor = '#FAFAFA';
	if(porcentagem >= 100) {
		cor = '#1099ec';
	} else if(!isNumeric(porcentagem) || (isNumeric(porcentagem) && porcentagem === 0)) {
		cor = '#DFDFDF';
	} else {
		cor = corDegrade('#F7B36B', '#019E33', porcentagem);
	}

	return (
		<div className={`cpnt-porcentagemcirculo ${wrap} ${alt ? 'cpnt-porcentagemcirculo-alt' : ''}`} onClick={onClick}>
			<div className={`cpnt-porcentagemcirculo-box ${className}`}>
				<div className="cpnt-porcentagemcirculo-inner">
					<div className="cpnt-porcentagemcirculo-chart-box">
						<svg viewBox="0 0 36 36" className="cpnt-porcentagemcirculo-chart">
							<path className="cpnt-porcentagemcirculo-bg"
								d="M18 2.0845
								a 15.9155 15.9155 0 0 1 0 31.831
								a 15.9155 15.9155 0 0 1 0 -31.831"
							/>
							<path className="cpnt-porcentagemcirculo-circulo"
								strokeDasharray={`${porcentagem}, 100`}
								d="M18 2.0845
								a 15.9155 15.9155 0 0 1 0 31.831
								a 15.9155 15.9155 0 0 1 0 -31.831"
								stroke={cor}
							/>
							<text
								{...(alt ? {
									x: 18,
									y: 22,
								} : {
									x: 18,
									y: 22,
								})}
								className="cpnt-porcentagemcirculo-porcentagem"
							>
								{porcentagem}%
							</text>
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PorcentagemCirculo;