import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

const RedirectTo = props => {
	const { redirectTo } = props;
	const navigate = useNavigate();
	const [showAviso, setShowAviso] = useState(false);

	useEffect(() => {
		if(redirectTo) {
			navigate(redirectTo);
		} else {
			setShowAviso(true);
		}
	}, []);

	return (
		<div>
			{showAviso ? 'Rota não encontrada' : ''}
		</div>
	)
};

export { RedirectTo };
