import { htmlToJsx, sanitize } from "@func/utils";

const templatesBriefings = [
	/*
	** Template 1
	** ================================================== */
	{
		label: 'Template - Modelo Padrão',
		subLabel: 'Template padrão explicando sobre o cliente, qual a missão da CROWD, qual será o papel de cada um, como o projeto é gerenciado, e como funciona o pagamento.',
		template:
`<p>Olá, tudo bem?</p>
<p>Meu nome é <span style="color:#0277BD">[escreva seu nome]</span> e eu faço parte do time de recrutamento e curadoria da Crowd. Somos responsáveis por selecionar os especialistas que atuam em nossos times ágeis e o seu perfil deu match com o  briefing deste projeto!</p>

<h3>SOBRE O CLIENTE?</h3>

<p>Trata-se de uma empresa do ramo <span style="color:#0277BD">[indique o ramo de atuação da empresa]</span> que <span style="color:#0277BD">[escreva o que a empresa faz]</span> e está buscando <span style="color:#0277BD">[descreva o momento da empresa]</span>.</p>

<h3>QUAL É A MISSÃO DA CROWD?</h3>

<p>Nossa equipe analisou as necessidades do cliente durante a etapa de venda e juntos nós criamos uma estratégia que visa <span style="color:#0277BD">[descreva o objetivo do projeto. Ex: alavancar as vendas, atrair mais leads qualificados]</span>.</p>

<h3>QUAIS SÃO AS ENTREGAS DO ESCOPO?</h3>

<ul>
	<li><span style="color:#0277BD">[entrega 1]</span></li>
	<li><span style="color:#0277BD">[entrega 2]</span></li>
	<li><span style="color:#0277BD">[entrega 3]</span></li>
	<li><span style="color:#0277BD">[entrega 4]</span></li>
</ul>

<h3>QUAL SERÁ SEU PAPEL NO NOSSO TIME ÁGIL?</h3>

<p>Nesse projeto, você assumirá o papel de <span style="color:#0277BD">[indique o papel do especialista no projeto]</span> e será responsável por:</p>

<ul>
	<li><span style="color:#0277BD">[entrega 1]</span></li>
</ul>

<h3>COMO O PROJETO SERÁ GERENCIADO?</h3>

<p>Ao fazer parte do nosso time, você terá a oportunidade de conhecer o <strong>Agile Crowd</strong>, nosso framework exclusivo de gestão ágil, focado em equipes remotas. Caso seja escolhido, você assistirá a um treinamento básico sobre o <strong>AgC</strong>, participará do planejamento das nossas sprints e será orientado diariamente sobre como interagir com nossas tarefas.</p>

<p>Além dos demais especialistas que vamos contratar aqui na plataforma, a <strong>liderança dessa equipe</strong> é formada por uma dupla: </p>

<ul>
	<li>O (A) Agilista do time, a quem você responderá diretamente, responsável por promover a cultura de agilidade, zelar pelos prazos e remover os impedimentos do projeto;</li>
	<li>O (A) Product Owner do projeto, responsável pelo contato com o cliente no dia-a-dia, pela validação das entregas e pela priorização do backlog;</li>
</ul>

<p>Vocês também poderão contar com o apoio de:</p>

<ul>
	<li>Agile Coaches da agência, responsáveis por orientar toda nossa equipe sobre as boas práticas de agilidade;</li>
	<li>Mentores de cada categoria, que podem ser acionados para darem suporte aos profissionais envolvidos no projeto.</li>
</ul>

<h3>COMO FUNCIONA O PAGAMENTO?</h3>

<p>O projeto tem duração de <span style="color:#0277BD">[xx meses]</span>, começando em <span style="color:#0277BD">[indique o mês de início]</span> com expectativas de término para [indique o mês de término]. Portanto, a nossa proposta por sua atuação neste período é que os pagamentos também ocorram mensalmente.</p>

<p>A aprovação do seus cards mensais será feita pelo seu Agilista, mediante o cumprimento das suas entregas, e de acordo com o que for planejado junto com o time para as sprints do mês.</p>

<h4 style="font-style: italic;">Sobre os termos de uso</h4>

<p>Acreditamos que você já deve ter conferido os nossos termos de uso, mas aproveito esta oportunidade para reforçar a importância dessa leitura antes de aceitar seguir com o job, para que você não se depare com nenhum impedimento na hora de receber pelo trabalho realizado.</p>

<p>Todas as informações do seu cadastro devem ser compatíveis, desde os seus dados pessoais, os dados da sua nota fiscal e os dados bancários onde o pagamento deverá ser depositado. Para garantir que você não seja prejudicado, a Crowd não aceita NF de terceiros e não deposita seu pagamento na conta de outra pessoa.</p>

<p style="font-weight: bold; font-style: italic; text-decoration: underline;">Não há exceções para essa regra.</p>

<h4 style="font-style: italic;">Sobre as datas:</h4>

<ul>
	<li>Para os Projetos concluídos e aprovados entre o dia 1 até o dia 30/31 de cada mês, os valores serão repassados todo dia 30 do mês subsequente à prestação de serviços.</li>
	<li>Caso estas datas caiam em um final de semana,  o pagamento será realizado no primeiro dia útil anterior à data, ou seja: na sexta-feira que antecede o final de semana.</li>
</ul>

<p style="font-weight: bold; font-style: italic; text-decoration: underline;">Não há exceções para essa regra.</p>

<h4 style="font-style: italic; opacity: 0.8;">Sobre a emissão de nota:</h4>

<p>Quando seu card for aprovado, você receberá uma notificação da plataforma para que você anexe a sua nota no campo indicado. Fique atento aos nossos avisos, pois, para realizar o seu pagamento, a <strong>NF é imprescindível</strong>.</p>

<p>Aguardo sua resposta, ok?</p>

<p style="color:#0277BD">[preencha sua saudação como preferir]</p>`,
		templatePreenchido:
htmlToJsx(`<p>Olá, tudo bem?</p>
<p>Meu nome é <span style="color:#0277BD">[escreva seu nome]</span> e eu faço parte do time de recrutamento e curadoria da Crowd. Somos responsáveis por selecionar os especialistas que atuam em nossos times ágeis e o seu perfil deu match com o  briefing deste projeto!</p>
<h3>SOBRE O CLIENTE?</h3>
<p>Trata-se de uma empresa do ramo <span style="color:#0277BD">[indique o ramo de atuação da empresa]</span> que <span style="color:#0277BD">[escreva o que a empresa faz]</span> e está buscando <span style="color:#0277BD">[descreva o momento da empresa]</span>.</p>
<h3>QUAL É A MISSÃO DA CROWD?</h3>
<p>Nossa equipe analisou as necessidades do cliente durante a etapa de venda e juntos nós criamos uma estratégia que visa <span style="color:#0277BD">[descreva o objetivo do projeto. Ex: alavancar as vendas, atrair mais leads qualificados]</span>.</p>
<h3>QUAIS SÃO AS ENTREGAS DO ESCOPO?</h3>
<ul>
	<li><span style="color:#0277BD">[entrega 1]</span></li>
	<li><span style="color:#0277BD">[entrega 2]</span></li>
	<li><span style="color:#0277BD">[entrega 3]</span></li>
	<li><span style="color:#0277BD">[entrega 4]</span></li>
</ul>
<h3>QUAL SERÁ SEU PAPEL NO NOSSO TIME ÁGIL?</h3>
<p>Nesse projeto, você assumirá o papel de <span style="color:#0277BD">[indique o papel do especialista no projeto]</span> e será responsável por:</p>
<ul>
	<li><span style="color:#0277BD">[entrega 1]</span></li>
</ul>
<h3>COMO O PROJETO SERÁ GERENCIADO?</h3>
<p>Ao fazer parte do nosso time, você terá a oportunidade de conhecer o <strong>Agile Crowd</strong>, nosso framework exclusivo de gestão ágil, focado em equipes remotas. Caso seja escolhido, você assistirá a um treinamento básico sobre o <strong>AgC</strong>, participará do planejamento das nossas sprints e será orientado diariamente sobre como interagir com nossas tarefas.</p>
<p>Além dos demais especialistas que vamos contratar aqui na plataforma, a <strong>liderança dessa equipe</strong> é formada por uma dupla: </p>
<ul>
	<li>O (A) Agilista do time, a quem você responderá diretamente, responsável por promover a cultura de agilidade, zelar pelos prazos e remover os impedimentos do projeto;</li>
	<li>O (A) Product Owner do projeto, responsável pelo contato com o cliente no dia-a-dia, pela validação das entregas e pela priorização do backlog;</li>
</ul>
<p>Vocês também poderão contar com o apoio de:</p>
<ul>
	<li>Agile Coaches da agência, responsáveis por orientar toda nossa equipe sobre as boas práticas de agilidade;</li>
	<li>Mentores de cada categoria, que podem ser acionados para darem suporte aos profissionais envolvidos no projeto.</li>
</ul>
<h3>COMO FUNCIONA O PAGAMENTO?</h3>
<p>O projeto tem duração de <span style="color:#0277BD">[xx meses]</span>, começando em <span style="color:#0277BD">[indique o mês de início]</span> com expectativas de término para [indique o mês de término]. Portanto, a nossa proposta por sua atuação neste período é que os pagamentos também ocorram mensalmente.</p>
<p>A aprovação do seus cards mensais será feita pelo seu Agilista, mediante o cumprimento das suas entregas, e de acordo com o que for planejado junto com o time para as sprints do mês.</p>
<h4 style="font-style: italic;">Sobre os termos de uso</h4>
<p>Acreditamos que você já deve ter conferido os nossos termos de uso, mas aproveito esta oportunidade para reforçar a importância dessa leitura antes de aceitar seguir com o job, para que você não se depare com nenhum impedimento na hora de receber pelo trabalho realizado.</p>
<p>Todas as informações do seu cadastro devem ser compatíveis, desde os seus dados pessoais, os dados da sua nota fiscal e os dados bancários onde o pagamento deverá ser depositado. Para garantir que você não seja prejudicado, a Crowd não aceita NF de terceiros e não deposita seu pagamento na conta de outra pessoa.</p>
<p style="font-weight: bold; font-style: italic; text-decoration: underline;">Não há exceções para essa regra.</p>
<h4 style="font-style: italic;">Sobre as datas:</h4>
<ul>
	<li>Para os Projetos concluídos e aprovados entre o dia 1 até o dia 30/31 de cada mês, os valores serão repassados todo dia 30 do mês subsequente à prestação de serviços.</li>
	<li>Caso estas datas caiam em um final de semana,  o pagamento será realizado no primeiro dia útil anterior à data, ou seja: na sexta-feira que antecede o final de semana.</li>
</ul>
<p style="font-weight: bold; font-style: italic; text-decoration: underline;">Não há exceções para essa regra.</p>
<h4 style="font-style: italic; opacity: 0.8;">Sobre a emissão de nota:</h4>
<p>Quando seu card for aprovado, você receberá uma notificação da plataforma para que você anexe a sua nota no campo indicado. Fique atento aos nossos avisos, pois, para realizar o seu pagamento, a <strong>NF é imprescindível</strong>.</p>
<p>Aguardo sua resposta, ok?</p>
<p style="color:#0277BD">[preencha sua saudação como preferir]</p>`)
	},
];

export default templatesBriefings;
