import { Texto } from "@_cpnt/Texto/Texto/Texto"

import c from './Topbar.module.scss';

export const Topbar = {
	Titulo: (props) => {
		return <Texto className={`${c['titulo']}`} {...props} />
	},
	Desc: (props) => {
		return <Texto className={`${c['desc']}`} {...props} />
	},
};
