import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalEmpresasFormEdicao.css';

// API
import { apiCompany } from '@_api/requests/company/company';

// Partes
import FormEmpresa from '@page/Logado/Empresas/FormEmpresa/FormEmpresa';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { camposInvalidos,  formatarCEP,  removerKeysVazias, validarCnpj, validarEmail } from '@func/utils';
import { msgs, msgStatus } from "@_func/request";
import { validarCpfCnpj } from "@cpnt/InputCpfCnpj/InputCpfCnpj";
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";
import { api } from "@_api/api";

const PortalEmpresasFormEdicao = props => {

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-empresas-form-edicao',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		id: '', name: '', active: 1, cnpj: '', qty_persons: '', name_responsible: '', email_responsible: '', state_registration: '',
		municipal_registration: '', social_reason: '', phone: '', street: '', district: '', zip_code: '', state: '',
		city: '', number: '', complement: '', type: 'J', percentage: 12,

		site: '',
		tipo_taxa: 'fixa',
		porcentagem_taxa: '12',
		data_faturamento_ddl: '',
		tax_percent: '12',
	};

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	// Edição
	const [loadingEditarEmpresa, setLoadingEditarEmpresa] = useState(false);
	const [loadingApagarEmpresa, setLoadingApagarEmpresa] = useState(false);

	// Portal
	const [showPortalConfirmacao, setShowPortalConfirmacao] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});
	const [stateFechamentoDados, setStateFechamentoDados] = useState([]);
	const [stateTaxaVariavelDados, setStateTaxaVariavelDados] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados(camposDefault);
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await apiCompany.getOne.get(id);

		if(retGetItem.status === 200) {
			setStateDados(retGetItem.data);
			_setStateDados(msgStatus(retGetItem));
		} else {
			_setStateDados(msgStatus(retGetItem));
			msgs([retGetItem]);
		}

		setLoading(false);
	}

	/*
	** Editar empresa
	** ================================================== */
	const editarEmpresa = async e => {
		e.preventDefault();

		if(loadingEditarEmpresa) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			id: stateDados.id_empresa,
			name: stateDados.name,
			active: stateDados.active,
			cnpj: stateDados.cnpj,
			qty_persons: stateDados.qty_persons,
			name_responsible: stateDados.name_responsible,
			email_responsible: stateDados.email_responsible,
			state_registration: stateDados.state_registration,
			municipal_registration: stateDados.municipal_registration,
			social_reason: stateDados.social_reason,
			phone: stateDados.phone,
			street: stateDados.street,
			district: stateDados.district,
			zip_code: formatarCEP(stateDados.zip_code),
			state: stateDados.state,
			city: stateDados.city,
			number: stateDados.number,
			complement: stateDados.complement,
			type: 'J',
			percentage: stateDados.percentage,
		};

		if(camposInvalidos(dadosEnviar, {}, [
			'name',
			'cnpj',
			'name_responsible',
			'email_responsible',
			'type',
			'percentage',
		])) return;

		if(!validarCpfCnpj(dadosEnviar.cnpj)) return;

		if(dadosEnviar.email_responsible && dadosEnviar.email_responsible !== '' && !validarEmail(dadosEnviar.email_responsible)) return;

		setLoadingEditarEmpresa(true);

		var dadosEnviarSemKeysVazias = removerKeysVazias(dadosEnviar);

		const retUpdateEmpresa = await apiCompany.update.put(dadosEnviarSemKeysVazias);

		if(retUpdateEmpresa.status === 200) {
			msg.success('Empresa atualizada com sucesso.');
			onSuccess({
				ret: retUpdateEmpresa,
			})
		} else {
			msgs([retUpdateEmpresa]);
			onError({
				ret: retUpdateEmpresa,
			});
		}

		setLoadingEditarEmpresa(false);
	}

	/*
	** Deletar
	** ================================================== */
	const apagarEmpresa = async () => {
		if(loadingApagarEmpresa) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		setLoadingApagarEmpresa(true);

		const retDelete = await api.company.delete(id);

		if(retDelete.status === 200) {
			msg.success('Empresa apagada com sucesso.');
			setShowPortalConfirmacao(false);
			onSuccess();
		} else {
			msgs([retDelete]);

		}

		setLoadingApagarEmpresa(false);
	}

	return (
		<>
			<Portal
				titulo="Editar empresa"
				show={show}
				closeOnEsc={closeOnEsc}
				onClose={onClose}
				className={portalClassName}
			>
				{loading ? (
					<Loading
						texto="Carregando dados, aguarde..."
						wrap="padding-30"
					/>
				) : (
					<div className={wrap}>
						<div className={className}>
							{_stateDados.status === 200 ? (
								<FormEmpresa
									onSubmit={editarEmpresa}
									stateDados={stateDados}
									setStateDados={setStateDados}
									stateFechamentoDados={stateFechamentoDados}
									setStateFechamentoDados={setStateFechamentoDados}
									stateTaxaVariavelDados={stateTaxaVariavelDados}
									setStateTaxaVariavelDados={setStateTaxaVariavelDados}
									after={
										<div className="form-bottom">
											<Button2
												_={{
													value: 'Apagar',
													className: 'btn btn-red',
													type: 'button',
													onClick: e => setShowPortalConfirmacao(true),
												}}
												classExtra="btn-1"
											/>
											<Button2
												_={{
													value: 'Cancelar',
													className: 'btn btn-1',
													type: 'button',
													onClick: onClose,
												}}
												classExtra="btn-1"
												wrap="margin-l-auto"
											/>
											<Button2
												_={{
													value: 'Salvar dados',
													onClick: editarEmpresa
												}}
												loading={loadingEditarEmpresa}
												wrap="margin-l-15"
											/>
										</div>
									}
								/>
							) : (
								<Info
									texto={_stateDados.msg}
									icone="erro"
								/>
							)}
						</div>
					</div>
				)}
			</Portal>

			<PortalConfirmacao
				show={showPortalConfirmacao}
				titulo={`Deseja realmente apagar a empresa essa empresa?`}
				desc={
					<>
						<div>A empresa "<strong>{stateDados.name}</strong>" será removida. Essa ação não poderá ser desfeita.</div>
						<div className="margin-t-10">Todos os clientes e dados associados a essa empresa, incluindo logins, projetos, usuários, contratações, pagamentos, etc., também serão removidos.</div>
						<div className="margin-t-10">Deseja continuar?</div>
					</>
				}
				onClose={() => setShowPortalConfirmacao(false)}
				onClickCancelar={() => setShowPortalConfirmacao(false)}
				onClickConfirmar={apagarEmpresa}
				loadingConfirmar={loadingApagarEmpresa}
			/>
		</>
	);
};

export default PortalEmpresasFormEdicao;