import React from 'react';
import Placeholder from '@cpnt/Placeholder/Placeholder';

const PlaceholderBriefings = props => {
	return (
		<tr>
			<td className="col-id">
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="circulo" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="circulo" />
					<Placeholder tipo="linha-min" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
					<Placeholder tipo="linha-min" wrap="w-80p" />
				</div>
			</td>
			<td className="tbl-projetos-col-cenario">
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td className="col-solucoes">
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="circulo" width="25px" height="25px" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="circulo" width="25px" height="25px" />
					<Placeholder tipo="linha-min" height="14px" wrap="w-30 margin-auto" />
				</div>
			</td>
			<td className="col-min-4">
				<div className="td-wrap">
					<Placeholder tipo="circulo" width="25px" height="25px" />
					<Placeholder tipo="linha-min" height="14px" wrap="w-30 margin-auto" />
				</div>
			</td>
			<td className="col-min-3">
				<div className="td-wrap">
					<Placeholder tipo="circulo" width="25px" height="25px" />
					<Placeholder tipo="linha-min" height="14px" />
				</div>
			</td>
			<td className="col-acao">
				<div className="td-wrap">
					<Placeholder tipo="retangulo-alt" />
				</div>
			</td>
		</tr>
	)
}

export { PlaceholderBriefings };
