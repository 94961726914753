import React from 'react';

// css
import './XLoading.css';

// Imagens
import imgLoading from '@imgdefault/loading-azul.svg';
import Tip from "@cpnt/Tip/Tip";

const XLoading = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		onClick,
		abs,
		absAlt,
		loading,
		tip = 'Remover',
		tipLoading = 'Removendo...'
	} = props;

	return (
		<>
			<div className={`cpnt-xloading ${wrap} ${classNameWrap} ${abs ? 'cpnt-xloading-abs' : ''} ${absAlt ? 'cpnt-xloading-abs-alt' : ''}`}>
				<div className={`cpnt-xloading-box ${className}`}>
					<Tip
						tip={
							<span className="cpnt-xloading-x" onClick={onClick}>
								{loading && <span className="fill bg scale-1-7" style={{backgroundImage: `url(${imgLoading})`}}></span>}
							</span>
						}
					>
						{loading ? tipLoading : tip}
					</Tip>
				</div>
			</div>
		</>
	);
};

export { XLoading };