import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalTemplateFormEdicao.css';

// API
import apiTemplates from "@api/templates/templates";
import { apiGetEmpresas, apiGetProfissoesPredefinidas, apiGetTemplates, apiGetTemplatesTipo } from '@apiGeral/geral';

// Partes
import { FormTemplate } from "@page/Logado/Templates/FormTemplate/FormTemplate";

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Portal from '@cpnt/Portal/Portal';
import Info from "@cpnt/Info/Info";
import Loading from "@cpnt/Loading/Loading";
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";

// Funções
import { camposInvalidos, formatarSelect, msg, removerKeysVazias } from '@func/utils';

// Rotas
import { closePortal, gets, isShowPortal, showPortal } from "@route/routeUtils";
import apiProfessions from "@api/professions/professions";
import { msgs, msgStatus } from "@_func/request";

const PortalTemplateFormEdicao = props => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();
	const location = useLocation();

	const {
		show,
		wrap = '',
		id,
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-template-form-edicao',
		onSuccess = () => null,
		onError = () => null,
		onDelete = () => null,
		onErrorDelete = () => null,
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		id: '',
		title: '',
		text: '',
		excerpt: '',
		id_type: 0,
		active: 1,
		metadata: {},
		id_company: 0,

		id_profession: null,

		// Metadata separado
		chat_titulo: '',
		chat_cor: 'default',
	};

	// Edição
	const [loading, setLoading] = useState(false);
	const [loadingEditarTemplate, setLoadingEditarTemplate] = useState(false);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);
	const [loadingGetTemplatesTipo, setLoadingGetTemplatesTipo] = useState(false);
	const [loadingDeletarTemplate, setLoadingDeletarTemplate] = useState(false);
	const [loadingGetProfissoes, setLoadingGetProfissoes] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});
	const [stateEmpresas, setStateEmpresas] = useState([]);
	const [stateTemplatesTipo, setStateTemplatesTipo] = useState([]);
	const [stateProfissoes, setStateProfissoes] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const retGet = await apiTemplates.getOne.get(id);

		_setStateDados(msgStatus(retGet));

		if(retGet.status === 200) {
			setStateDados({
				...retGet.data,
				...(retGet.data.metadata ? {
					chat_titulo: retGet.data.metadata.title,
					chat_cor: retGet.data.metadata.type,
				} : {}),
				...(!retGet.data.id_company ? {
					id_company: 0,
				} : {}),
			});
			apiGetTemplatesTipo({
				...(store.usuario.role !== 1 ? {
					filtros: {
						enable_in_company: 1,
					}
				} : {}),
				setLoadingGetTemplatesTipo,
				setStateTemplatesTipo
			});
			if(store.usuario.role === 1) {
				apiGetEmpresas({setLoadingGetEmpresas, setStateEmpresas});

				const retProfissoes = await apiProfessions.all.get({
					// id_company: 1,
					active: 1,
					limit: 999,
				});

				if(retProfissoes.status === 200) {
					setStateProfissoes(formatarSelect({
						dados: retProfissoes.data,
						keyValue: 'id',
						keyName: 'title',
					}));
				} else {
					msgs([retProfissoes]);
				}
			}
		} else {
			msgs([retGet]);
		}

		setLoading(false);
	}

	const listaTipoTemplate = () => {
		var retorno = stateTemplatesTipo;
		if(stateDados.id_company === 0 && store.usuario.role === 1) {
			retorno = [];
			stateTemplatesTipo.map((val, key) => {
				if(val.id === 5 || val.enable_in_company !== 0)  {
					retorno.push(val);
				}
			})
		} else if(store.usuario.role === 1) {
			retorno = [];
			stateTemplatesTipo.map((val, key) => {
				if(val.id !== 5)  {
					retorno.push(val);
				}
			})
		}
		return retorno
	}

	/*
	** Editar cliente
	** ================================================== */
	const editarTemplate = async e => {
		e.preventDefault();

		if(loadingEditarTemplate) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			...stateDados,
		};

		if(camposInvalidos(dadosEnviar, {
			title: 'Título',
			text: 'Texto do template',
			excerpt: 'Resumo do template',
			id_type: 'Tipo do template',
		}, [
			'title',
			'text',
			'excerpt',
			'id_type',
		])) return;

		var dadosSemKeysVazias = removerKeysVazias(dadosEnviar);

		/*
		** Caso seja tipo chat
		** ================================================== */

		// Tabela: crowd_template_types
		// id: 1  =  tipo: Propostas de jobs
		// id: 2  =  tipo: Pagamentos de jobs
		// id: 3  =  tipo: Termos de uso e condições
		// id: 4  =  tipo: Carta de apresentação de usuários
		// id: 5  =  tipo: Carta de apresentação de freelancers
		// id: 6  =  tipo: Mensagens personalizadas do chat
		// id: 7  =  tipo: Contratos de empresas
		// id: 8  =  tipo: Contratos de freelancers

		if(stateDados.id_type === 6) {
			if(camposInvalidos(dadosEnviar, {
				chat_titulo: 'Título da mensagem do chat',
				chat_cor: 'Cor da mensagem',
			}, [
				'chat_titulo',
				'chat_cor',
			])) return;


			dadosSemKeysVazias.metadata = {
				title: stateDados.chat_titulo,
				type: stateDados.chat_cor,
			};

		}

		if(stateDados.id_type === 13 || stateDados.id_type === 14) { // LITE
			dadosSemKeysVazias.id_profession = stateDados.id_profession;

			if(camposInvalidos(dadosSemKeysVazias, {
				id_profession: 'Profissão para cadastro de perfil lite',
			}, [
				'id_profession',
			])) return;
		}

		dadosSemKeysVazias.active = stateDados.active;

		if(stateDados.id_company === 0) {
			dadosSemKeysVazias.id_company = null;
		}

		setLoadingEditarTemplate(true);

		const retUpdate = await apiTemplates.update.put(dadosSemKeysVazias);

		if(retUpdate.status === 200) {
			msg.success('Template atualizado com sucesso.');
			onSuccess({
				ret: retUpdate,
			});
			setStateDados(camposDefault);
		} else {
			msgs([retUpdate]);
			onError({
				ret: retUpdate,
			});
		}

		setLoadingEditarTemplate(false);
	}

	/*
	** Deletar
	** ================================================== */
	const deletarTemplate = async (e) => {
		e.preventDefault();

		if(loadingDeletarTemplate) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		setLoadingDeletarTemplate(true);

		const retDelete = await apiTemplates.delete(id);

		if(retDelete.status === 200) {
			msg.success('Template apagado com sucesso.');
			setTimeout(() => {
				onDelete({
					ret: retDelete,
				});
				setStateDados(camposDefault);
			}, 500);
		} else {
			msgs([retDelete]);
			onErrorDelete({
				ret: retDelete,
			});
		}

		setLoadingDeletarTemplate(false);
	}

	return (
		<Portal
			titulo="Editar template"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<FormTemplate
								onSubmit={editarTemplate}
								stateDados={stateDados}
								setStateDados={setStateDados}
								loadingGetTemplatesTipo={loadingGetTemplatesTipo}
								stateTemplatesTipo={listaTipoTemplate()}
								loadingGetEmpresas={loadingGetEmpresas}
								stateEmpresas={[
									{value: 0, label: <strong>Template público (todos terão acesso)</strong>},
									...stateEmpresas
								]}
								loadingGetProfissoes={loadingGetProfissoes}
								stateProfissoes={stateProfissoes}
								after={
									<div className="form-bottom">
										<Button2
											_={{
												value: 'Apagar',
												className: 'btn btn-red',
												type: 'button',
												onClick: e => showPortal({portal: 'confirmacao-deletar-template', stateGet, navigate}),
											}}
											classExtra="btn-1"
										/>
										<Button2
											_={{
												value: 'Cancelar',
												className: 'btn btn-1',
												type: 'button',
												onClick: onClose,
											}}
											classExtra="btn-1"
											wrap="margin-l-auto"
										/>
										<Button2
											_={{
												value: 'Salvar dados',
												onClick: editarTemplate
											}}
											loading={loadingEditarTemplate}
											wrap="margin-l-15"
										/>
									</div>
								}
							/>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}

			<PortalConfirmacao
				show={isShowPortal({portal: 'confirmacao-deletar-template', stateGet})}
				titulo="Deseja realmente apagar esse template?"
				desc={
					<>
						<div>O template "<strong>{stateDados.title}</strong>" será removido. Essa ação não poderá ser desfeita.</div>
						<div className="margin-t-10">Deseja continuar?</div>
					</>
				}
				onClose={() => closePortal({portal: 'confirmacao-deletar-template', stateGet, navigate})}
				onClickCancelar={() => closePortal({portal: 'confirmacao-deletar-template', stateGet, navigate})}
				onClickConfirmar={deletarTemplate}
				loadingConfirmar={loadingDeletarTemplate}
			/>

		</Portal>
	);
};

export default PortalTemplateFormEdicao;