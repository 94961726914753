import React from 'react';

// CSS
import './Info.css';

// Imagens
import iconeSucesso from '@_img/default/icone-sucesso.png';
import iconeErro from '@_img/default/icone-erro.png';
import iconeInfo from '@_img/default/icone-info.png';
import iconeAlerta from '@_img/default/icone-alerta.png';

const Info = props => {

	var icone = props.icone;

	if(props.icone && typeof(props.icone) !== 'string') {
		icone = props.icone;
	} else if(props.icone === 'sucesso') {
		icone = iconeSucesso;
	} else if(props.icone === 'erro') {
		icone = iconeErro;
	} else if(props.icone === 'info') {
		icone = iconeInfo;
	} else if(props.icone === 'alerta') {
		icone = iconeAlerta;
	}

	const dados = {
		texto: '',
		classTexto: 'align-center',
		classNameWrap: '',
		...props
	};

	return (
		<div className={`cpnt-info-conteudo ${dados.classNameWrap}`}>
			<div className="cpnt-info-wrap">

				<div className="cpnt-info-inner">
					{dados.before}
					{icone ? (
						<div className={`cpnt-info-icone-wrap ${dados.alt ? 'cpnt-info-icone-wrap-alt' : ''}`}>
							<div className="cpnt-info-icone">
								<img src={icone} alt="icone" />
							</div>
						</div>
					) : null}

					{dados.texto && dados.texto !== '' ? (
						<div className={`cpnt-info-texto ${dados.classTexto}`}>
							{dados.texto}
						</div>
					) : null}
					{dados.after}
				</div>

			</div>
		</div>
	)
}

export default Info;