import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { toast as msg } from 'react-toastify';

// API
import { api } from "@_api/api";

// Components
import Info from '@cpnt/Info/Info';

// Imagens
import logo from '@img/logo.png';

// Funções
import { validar200 } from '@func/utils';
import { EMAIL_CONTATO } from '@_config/config';
import { msgs, msgStatus } from "@_func/request";

const AtivarCadastroUsuario = props => {

	const [infoAlteracao, setInfoAlteracao] = useState('');
	const [msgAlteracao, setMsgAlteracao] = useState('Nenhuma informação disponível.');

	useEffect(() => {
		alterarEmail();
	}, []);

	const alterarEmail = async e => {
		const q = queryString.parse(window.location.search);

		if(!q.token) {
			var msgToken = 'Token inválido. Não é possível ativar o seu cadastro com o token informado.';
			msg.error(msgToken);
			setMsgAlteracao(msgToken);
			setInfoAlteracao(false);
			return;
		}

		const retAtivacaoCadastro = await api.users.confirm.post({
			hash: q.token,
		});

		if(validar200([retAtivacaoCadastro])) {
			setInfoAlteracao(true);
			setMsgAlteracao(
				<>
					<div>Cadastro ativado com sucesso!</div>
					<div className="margin-t-20">Agora você já pode fazer login na plataforma CROWD.</div>
				</>
			);
		} else {
			setInfoAlteracao(false);
			setMsgAlteracao(retAtivacaoCadastro.error);
			msgs([retAtivacaoCadastro]);
		}
	}

	return (
		<div>
			<div className="tela-inicio-e-inner flex flex-center">
				<div className="tela-inicio-e-logo padding-t-30 padding-b-30">
					<img src={logo} alt="Logo CROWD" className="block w-100p max-w-350 margin-auto" />
				</div>
				<div className="margin-t-30">
					{infoAlteracao === '' ? (
						<Info
							icone="loading"
							texto={<>Ativando cadastro<br />Aguarde...</>}
						/>
					) : (
						<>
							{infoAlteracao === false && (
								<div>
									<Info
										icone="erro"
										texto={msgAlteracao}
									/>
									<div className="margin-t-20 align-center fs-14">Precisa de ajuda? Fale conosco através do e-mail <a href={`mailto:${EMAIL_CONTATO}`}>{EMAIL_CONTATO}</a>.</div>
								</div>
							)}
							{infoAlteracao === true && (
								<Info
									icone="sucesso"
									texto={msgAlteracao}
								/>
							)}

							<div className="margin-t-30">
								<div className="margin-t-15 flex flex-center">
									<Link to="/login" className="fs-16 no-decoration bold">Fazer login</Link>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default AtivarCadastroUsuario;