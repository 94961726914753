import React, { useState } from 'react';

// css
import c from './PortalExclusaoPerfilProfissional.module.scss';

// Componentes
import { Portal } from "@_cpnt/Portal/Portal/Portal";
import { Radio } from "@_cpnt/Inputs/Radio/Radio";
import { Input } from "@_cpnt/Inputs/Input/Input";
import { Btn } from "@_cpnt/Btn/Btn/Btn";
import { msg } from "@_func/cpnt";

const PortalExclusaoPerfilProfissional = props => {

	const {
		show,
		onClose,
		x,
		closeOnEsc = false,
		portalClassName = c['container'],
	} = props;

	const [stateOpcao, setStateOpcao] = useState(false);
	const [stateDesc, setStateDesc] = useState('');

	const opcoes = [
		{
			value: 2,
			label: 'Estou cadastrado há muito tempo e não tenho recebido oportunidades.',
		},
		{
			value: 3,
			label: 'Excesso de convites de oportunidades irrelevantes.',
		},
		{
			value: 4,
			label: 'Estou mudando de carreira e prioridades.',
		},
		{
			value: 5,
			label: 'Tive uma experiência negativa com um cliente ou projeto.',
		},
		{
			value: 6,
			label: 'Quero manter a privacidade e melhor controle dos meus dados.',
		},
	];

	const onCancel = () => {
		setStateOpcao(false);
		setStateDesc('');
		onClose();
	}

	const excluirPerfil = async () => {
		msg.info('Falta integração da API');
	}

	return (
		<Portal
			titulo="Excluir meu perfil"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			classNameWrap={portalClassName}
			x={x}
		>
			<p className="desc margin-t-0">Ao excluir o seu perfil, você não poderá mais fazer login na plataforma, receber propostas de novos jobs.</p>
			<h3>Por gentileza, nos informe porquê você está excluindo o perfil*:</h3>
			<div className="fs-18 flex flex-column gap-10">
				{opcoes.map((val, key) => {
					return (
						<div key={key}>
							<Radio
								label={val.label}
								_={{
									value: val.value,
									checked: stateOpcao === val.value ? true : false,
									onChange: e => {
										setStateOpcao(val.value);
									}
								}}
								wrap="margin-t-10"
							/>
						</div>
					)
				})}
			</div>

			<div className="margin-t-25">
				{stateOpcao === 1 ? (
					<Input
						label="Por favor, nos dê mais detalhe para que possamos investigar melhor"
						_={{
							type: 'textarea',
							value: stateDesc,
							onChange: e => {
								setStateDesc(e.target.value);
							},
						}}
					/>
				) : null}
			</div>

			<div className="form-bottom btns flex-end margin-t-25">
				<Btn
					_={{
						value: 'Não quero excluir',
						onClick: onCancel,
					}}
				/>
				<Btn
					_={{
						value: 'Confirmar exclusão',
						onClick: excluirPerfil,
					}}
					tipo="error"
				/>
			</div>

		</Portal>
	);
};

export { PortalExclusaoPerfilProfissional };
