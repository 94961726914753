import React, { useContext, useState, useEffect } from 'react';
import { Sketch } from '@uiw/react-color';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import Button2 from "@cpnt/Button2/Button2";
import Checkbox2 from "@cpnt/Checkbox2/Checkbox2";
import Input2 from "@cpnt/Input2/Input2";
import { InputDate } from "@cpnt/InputDate/InputDate";
import { Select2 } from '@cpnt/Select2/Select2';
import { InputColor } from "@cpnt/InputColor/InputColor";

// Style
import c from './AvisosTopo.module.css';
import apiDashNotifications from "@api/dashNotifications/dashNotifications";
import { apenasLetrasSlug, camposInvalidos, msg, splitDataDaDataHoraApi, splitHoraDaDataApi, stringPesquisa, stringSlug } from "@func/utils";
import { Editor } from "@_cpnt/Inputs/Editor/Editor";
import Tip from "@cpnt/Tip/Tip";
import { resetExibicaoNotificacaoTopo } from "@func/local";
import { msgs, msgStatus } from "@_func/request";

const AvisosTopo = props => {
	const [store, setStore] = useContext(StoreContext);
	const [loadingSalvar, setLoadingSalvar] = useState(false);

	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		stateAvisoTopo = {},
		setStateAvisoTopo,
	} = props;

	const camposDefault = {
		id: '',
		active: '',
		text: '',
		text_color: '',
		bar_color: '',
		date_start: '',
		date_end: '',
		code: '',
		roles: [],

		preview: true,
		nivel_acesso: [],
		date_start_time: '',
		date_end_time: '',
	};

	const selectNiveisAcesso = {
		2: 'Freelancers',
		3: 'Hunters / Empresas beta',
		1: 'Administradores'
	};

	const [stateDados, setStateDados] = useState(camposDefault);

	useEffect(() => {
		getDashNotifications();
	}, []);

	const getDashNotifications = async () => {
		const ret = await apiDashNotifications.getOne.get();

		if(ret.status === 200) {
			var horaInicio = splitHoraDaDataApi(ret.data.date_start);
			var horaFim = splitHoraDaDataApi(ret.data.date_end);
			var niveisAcesso = [];

			if(ret.data.roles.length) {
				niveisAcesso = ret.data.roles.map(val => {
					return {
						label: selectNiveisAcesso[val.id],
						value: Number(val.id),
					};
				})
			}

			setStateDados({
				...camposDefault,
				...ret.data,
				date_start_time: horaInicio,
				date_end_time: horaFim,
				nivel_acesso: niveisAcesso,
				preview: true,
			});

			setStateAvisoTopo({
				texto: ret.data.text,
				textColor: ret.data.text_color,
				backgroundColor: ret.data.bar_color,
				preview: true,
				code: ret.data.code,
			});
		} else {
			msgs([ret]);
		}
	}

	const salvarConfiguracoes = async () => {
		const dadosEnviar = {
			id: 1,
			active: stateDados.active ? 1 : 0,
			text: stateDados.text,
			text_color: stateDados.text_color,
			bar_color: stateDados.bar_color,
			code: stateDados.code,
		};

		var date_start = splitDataDaDataHoraApi(stateDados.date_start);
		var date_end = splitDataDaDataHoraApi(stateDados.date_end);

		dadosEnviar.date_start = `${date_start} ${stateDados.date_start_time}`;
		dadosEnviar.date_end = `${date_end} ${stateDados.date_end_time}`;

		dadosEnviar.roles = stateDados.nivel_acesso.map(item => {
			return {
				id: item.value,
			}
		});

		if(camposInvalidos(dadosEnviar, {
			text: 'Texto da mensagem',
			text_color: 'Cor do texto',
			bar_color: 'Cor de fundo',
			code: 'ID da notificação',
		}, [
			'id',
			'text',
			'text_color',
			'bar_color',
			'code',
		])) return;

		setLoadingSalvar(true);

		const ret = await apiDashNotifications.update.put(dadosEnviar);

		if(ret.status === 200) {
			msg.success('Configurações atualizadas com sucesso');
		} else {
			msgs([ret]);
		}

		setLoadingSalvar(false);
	}

	return (
		<>
			<div className="pg-avisos base-full">
				<div className="conteudo-box">
					<div className="titulo-box titulo-box-res">
						<div className="flex flex-center-v">
							<h3 className="titulo">Avisos da barra do topo</h3>
						</div>
					</div>

					<div className={`${wrap} ${classNameWrap}`}>
						<div className={`${className}`}>
							<div className="base">
								<div className="base-content">
									<div className={`${c['pg-avisos-inner']}`}>
										<h3>Configurações da barra</h3>
										<div className="input-box">
											<div className="label">Texto da notificação</div>
											<Editor
												value={stateDados.text}
												setValue={e => {
													setStateDados({
														...stateDados,
														text: e,
													});
													setStateAvisoTopo({
														...stateAvisoTopo,
														texto: e,
													});
												}}
												height={180}
											/>
										</div>
										<form onSubmit={e => {
											e.preventDefault();
											salvarConfiguracoes();
										}} className={`${c['box-geral']} box`}>
											<div className={`${c['box-l']} box-2`}>
												<div className="flex">
													<div className="input-box box-2">
														<InputDate
															label="Data de inicio"
															req
															value={stateDados.date_start}
															onChange={e => {
																setStateDados({
																	...stateDados,
																	date_start: e,
																});
															}}
															classNameWrap="flex-1"
														/>
													</div>
													<div className="input-box box-2">
														<Input2
															label="Hora de início"
															req
															tip="Entre 00:00:00 e 23:59:59"
															_={{
																value: stateDados.date_start_time,
																onChange: e => {
																	setStateDados({
																		...stateDados,
																		date_start_time: e.target.value,
																	});
																},
																mask: '99:99:99',
															}}
															wrap="flex-1"
														/>
													</div>
												</div>
												<div className="flex">
													<div className="input-box box-2">
														<InputDate
															label="Data final"
															req
															value={stateDados.date_end}
															onChange={e => {
																setStateDados({
																	...stateDados,
																	date_end: e,
																});
															}}
															classNameWrap="flex-1"
														/>
													</div>
													<div className="input-box box-2">
														<Input2
															label="Hora final"
															req
															tip="Entre 00:00:00 e 23:59:59"
															_={{
																value: stateDados.date_end_time,
																onChange: e => {
																	setStateDados({
																		...stateDados,
																		date_end_time: e.target.value,
																	});
																},
																mask: '99:99:99',
															}}
															wrap="flex-1"
														/>
													</div>
												</div>
												<div className="input-box">
													<Select2
														label="Exibir notificação para"
														_={{
															value: stateDados.nivel_acesso,
															options: [
																{label: selectNiveisAcesso[2], value: 2},
																{label: selectNiveisAcesso[3], value: 3},
																{label: selectNiveisAcesso[1], value: 1},
															],
															onChange: e => {
																setStateDados({
																	...stateDados,
																	nivel_acesso: e,
																})
															},
															isMulti: true,
														}}
														wrap="flex-1"
													/>
												</div>

												<div className="flex">
													<div className="input-box">
														<InputColor
															label="Cor do texto"
															stateColor={stateDados.text_color}
															setStateColor={e => {
																setStateDados({
																	...stateDados,
																	text_color: e.hex,
																});
																setStateAvisoTopo({
																	...stateAvisoTopo,
																	textColor: e.hex,
																});
															}}
														/>
													</div>
													<div className="input-box">
														<InputColor
															label="Cor de fundo"
															stateColor={stateDados.bar_color}
															setStateColor={e => {
																setStateDados({
																	...stateDados,
																	bar_color: e.hex,
																});
																setStateAvisoTopo({
																	...stateAvisoTopo,
																	backgroundColor: e.hex,
																});
															}}
														/>
													</div>
													<div className="input-box margin-l-auto">
														<Input2
															label="ID da notificação"
															req
															tip={
																<>
																	<div>A exibição do aviso para os usuários é baseada nesse ID, caso o usuário fechar a mensagem, esse ID será definido na seção e não será exibido novamente. Então sempre que iniciar uma nova mensagem, insira um ID diferente, algo como: <strong>avisoapresentacao</strong>.</div>
																	<div className="margin-t-10">Não utilize caracteres especiais e nem ascentos</div>
																</>
															}
															_={{
																value: stateDados.code,
																onChange: e => {
																	var a = apenasLetrasSlug(e.target.value);
																	setStateDados({
																		...stateDados,
																		code: a,
																	});
																},
															}}
															debounce={false}
														/>
													</div>
												</div>
											</div>
											<div className={`${c['box-r']} box-2`}>
												<div className={`input-box ${stateDados.active ? 'color-green' : 'color-red'}`}>
													<Checkbox2
														label="Ativar notificação"
														_={{
															checked: stateDados.active,
															onChange: e => {
																setStateDados({
																	...stateDados,
																	active: e.target.checked,
																});
															}
														}}
													/>
													<div className={"desc"}>
														<div>Ao ativar, todos os usuários selecionados verão essa mensagem</div>
														<div className="margin-t-10">Se você ainda está formatando a mensagem e realizando testes, é recomendado deixar inativa até a finalização.</div>
													</div>
												</div>
												<div className="input-box margin-t-10">
													<Checkbox2
														label="Pré visualizar alterações"
														tip="Ignora se a barra foi fechada e exibe para pré visualização"
														_={{
															checked: stateDados.preview,
															onChange: e => {
																setStateDados({
																	...stateDados,
																	preview: e.target.checked,
																});
																setStateAvisoTopo({
																	...stateAvisoTopo,
																	preview: e.target.checked,
																});
															}
														}}
													/>
													<div className="desc">
														Irá sobrescrever temporariamente a barra atual para pré visualização
													</div>
												</div>
												<div className="flex flex-end margin-t-10">
													<div className="input-box">
														<Tip
															tip={
																<Button2
																	_={{
																		value: 'Limpar ID da seção',
																		type: 'button',
																		className: 'btn btn-1',
																		onClick: () => {
																			resetExibicaoNotificacaoTopo();
																			msg.success('Cookie removido com sucesso. Atualize a página para voltar a ver a barra de notificações.');
																		},
																	}}
																/>
															}
															info
															_defaults={{
																placement: 'top-center'
															}}
														>
															<div>Caso você tenha clicado no <strong className="color-red">(x)</strong> para fechar a barra de avisos, ela voltará a ser exibida nas outras páginas sem a necessidade de limpar o cache.</div>
															<div className="margin-t-10">É necessário atualizar a página após clicar neste botão para que a mensagem volte a ser exibida.</div>
														</Tip>
													</div>
													<div className="input-box">
														<Button2
															_={{
																value: 'Salvar configurações',
															}}
															loading={loadingSalvar}
														/>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { AvisosTopo };
