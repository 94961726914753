import imgIconeLink from '@svg/padrao/link.svg';
import imgIconeBehance from '@svg/redes-sociais/behance-square.svg';
import imgIconeDribbble from '@svg/redes-sociais/dribbble-square.svg';
import imgIconeFacebook from '@svg/redes-sociais/facebook-square.svg';
import imgIconeGithub from '@svg/redes-sociais/github-square.svg';
import imgIconeGit from '@svg/redes-sociais/git-square.svg';
import imgIconeInstagram from '@svg/redes-sociais/instagram-square.svg';
import imgIconeLastFm from '@svg/redes-sociais/lastfm-square.svg';
import imgIconeLinkedin from '@svg/redes-sociais/linkedin.svg';
import imgIconePinterest from '@svg/redes-sociais/pinterest-square.svg';
import imgIconeReddit from '@svg/redes-sociais/reddit-square.svg';
import imgIconeTumblr from '@svg/redes-sociais/tumblr-square.svg';
import imgIconeTwitter from '@svg/redes-sociais/twitter-square.svg';
import imgIconeVimeo from '@svg/redes-sociais/vimeo-square.svg';
import imgIconeWhatsApp from '@svg/redes-sociais/whatsapp-square.svg';
import imgIconeXing from '@svg/redes-sociais/xing-square.svg';
import imgIconeYoutube from '@svg/redes-sociais/youtube-square.svg';

const stringImg = string => {
	var icone = {
		icone: imgIconeLink,
		cor: '#CCC',
	};
	switch(string){
		case (/(behance.net|behance.com)/.test(string) ? string : false):
			icone = {
				icone: imgIconeBehance,
				cor: false,
			};
			break;
		case (/(dribbble.com)/.test(string) ? string : false):
			icone = {
				icone: imgIconeDribbble,
				cor: '#EA4C89',
			};
			break;
		case (/(facebook.com|fb.com)/.test(string) ? string : false):
			icone = {
				icone: imgIconeFacebook,
				cor: '#1877F2',
			};
			break;
		case (/(github.com)/.test(string) ? string : false):
			icone = {
				icone: imgIconeGithub,
				cor: '#24292E',
			};
			break;
		case (/(gitlab)/.test(string) ? string : false):
			icone = {
				icone: imgIconeGit,
				cor: '#FC6D26',
			};
			break;
		case (/(instagram.com|instagram.net)/.test(string) ? string : false):
			icone = {
				icone: imgIconeInstagram,
				cor: '#F27D2B',
			};
			break;
		case (/(lastfm.com|last.fm)/.test(string) ? string : false):
			icone = {
				icone: imgIconeLastFm,
				cor: false,
			};
			break;
		case (/(linkedin.com)/.test(string) ? string : false):
			icone = {
				icone: imgIconeLinkedin,
				cor: '#0A66C2',
			};
			break;
		case (/(pinterest.com)/.test(string) ? string : false):
			icone = {
				icone: imgIconePinterest,
				cor: '#E60023',
			};
			break;
		case (/(reddit.com)/.test(string) ? string : false):
			icone = {
				icone: imgIconeReddit,
				cor: false,
			};
			break;
		case (/(tumblr.com)/.test(string) ? string : false):
			icone = {
				icone: imgIconeTumblr,
				cor: false,
			};
			break;
		case (/(twitter.com)/.test(string) ? string : false):
			icone = {
				icone: imgIconeTwitter,
				cor: '#1DA1F2',
			};
			break;
		case (/(vimeo.com)/.test(string) ? string : false):
			icone = {
				icone: imgIconeVimeo,
				cor: false,
			};
			break;
		case (/(whatsapp.com)/.test(string) ? string : false):
			icone = {
				icone: imgIconeWhatsApp,
				cor: false,
			};
			break;
		case (/(xing.com)/.test(string) ? string : false):
			icone = {
				icone: imgIconeXing,
				cor: false,
			};
			break;
		case (/(youtube.com|youtube.net|youtube.me|youtu.be)/.test(string) ? string : false):
			icone = {
				icone: imgIconeYoutube,
				cor: '#FF0000',
			};
			break;
		default:
			icone = {
				icone: imgIconeLink,
				cor: false,
			};
	}

	return icone;
}

export default stringImg;
