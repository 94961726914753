
/*
** Verifica se está no ambiente de desenvolvimento
** Ambiente de dev exibe ou oculta determinadas
** informações que estão em desenvolvimento
** ================================================== */
export const DEV = process.env.REACT_APP_DEV === 'dev' ? true : false;
export const MANUTENCAO = process.env.REACT_APP_MANUTENCAO && process.env.REACT_APP_MANUTENCAO !== '' ? true : false;

console.log("CROWD 02/09/2024 14:07");

/*
** Mesma coisa que o DEV, apenas para identificar
** onde estão sendo feitas validações por ID para dev e prod
** ================================================== */
export const DEV_ID = DEV;

/*
** Configurações
** ================================================== */
export const TITULO_PAGINA_PREFIXO = 'CROWD - ';
export const TITULO_PAGINA_DEFAULT = 'CROWD - Soluções em Marketing e Tecnologia.';
export const EMAIL_CONTATO = 'suporte@crowd.net.br';
export const API_TIMEOUT = 15000; // 1000 = 1 segundo | 600.000 = 10 minutos (envio de vídeo é demorado)
export const API_TIMEOUT_UPLOAD = 60000; // 1000 = 1 segundo | 600.000 = 10 minutos (envio de vídeo é demorado)

/*
** Chaves de API
** ================================================== */
export const API_KEY_ZENDESK = '575bff62-47dd-478f-805e-330dff4aadfa';
export const GOOGLE_ANALYTICS_KEY = 'UA-51170795-3';
export const TINY_MCE_API_KEY = '5o0nl1krc6531hu0987jq0ksuqzwkajfory4q7w6aiagdyv2';
export const HUBSPOT_API_KEY = 'pat-na1-9608445c-a99f-40c9-9a27-c7f95edc7695';
export const GTM_ID = 'GTM-KM4NFNM9';

/*
** URLs
** ================================================== */
export const URL = window.location.origin;
export const URL_API = process.env.REACT_APP_URL_BACKEND ? process.env.REACT_APP_URL_BACKEND.replace(/\/$/, '') : 'https://novaapi.crowd.br.com';
// export const URL_API_SOCKET = `${URL_API.replace(/^.*:\/\//i, '')}`;
export const URL_API_SOCKET = URL_API;
export const URL_API_IMAGENS = process.env.REACT_APP_URL_IMAGENS ? process.env.REACT_APP_URL_IMAGENS.replace(/\/$/, '') : 'https://plataformacrowd.s3.sa-east-1.amazonaws.com';
export const URL_API_ARQUIVOS = process.env.REACT_APP_URL_ARQUIVOS ? process.env.REACT_APP_URL_ARQUIVOS.replace(/\/$/, '') : 'https://plataformacrowd.s3.sa-east-1.amazonaws.com';
export const URL_SITE = 'https://crowd.br.com';
export const URL_BLOG = 'https://blog.crowd.br.com';
export const URL_LOGO = 'https://static.crowd.br.com/plataforma/imagens/logo.png';
export const URL_LOGO_SINGLE = 'https://static.crowd.br.com/plataforma/imagens/logo-single-preto-padding.png';
export const URL_API_BLOG_WORDPRESS = 'https://blog.crowd.br.com/wp-json/wp/v2';
export const URL_INDICACAO_FREELANCER = `${URL}/cadastro-freelancer-por-convite?indicacao=`;
export const URL_SUPORTE_ZENDESK = 'https://comunidadecrowd.zendesk.com/hc/pt-br/requests/new';
export const URL_CROWD_ACADEMY = 'https://academy.crowd.br.com/';
export const URL_CROWD_SQUAD = 'https://crowd.br.com/';

/*
** Arquivos
** ================================================== */
export const ARQUIVO_TERMOS_DE_USO = 'termos-de-uso-crowd-vf-120324.pdf'; // src/assets/pdf

/*
** SEO
** ================================================== */
export const SEO_URL_DEFAULT = `%PUBLIC_URL%/assets/images/preview.png`;

/*
** Liga / Desliga
** ================================================== */

// Permite acessar o passo a passo mesmo que já tenha sido finalizado
export const BYPASS_PERFIL_STEPS = DEV;

// Exibe a tela de /perfil mesmo sem finalização do passo a passo
export const BYPASS_PERFIL = DEV;

// Permite acessar o passo a passo do briefing sem salvar os dados
export const BYPASS_BRIEFING_STEPS = DEV;

// Exibir link de teste na sidebar
export const BYPASS_TESTE_SIDEBAR = DEV;

// Exibe o console.log da requests com os dados e retorno
export const EXIBIR_CONSOLE_LOG_REQUESTS = DEV;

// Exibe o console.log da requests com os dados e retorno
export const EXIBIR_CONSOLE_LOG_DEBUG = DEV;

// Exibe o botão de ajuda do Zendesk
export const EXIBIR_WIDGET_ZENDESK = DEV;

/*
** Configs
** ================================================== */
export const LIMITE_QTD_FREELANCERS_COMPLEMENTO_BRIEFING = 25;
export const LIMITE_QTD_FREELANCERS_NOVO_BRIEFING = 50;
export const TEMPO_ANIMACAO_MATCH = 5000;
