import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalProfissoesPredefinidasFormEdicao.css';

// API
import apiProfessions from "@api/professions/professions";
import { apiGetAreas, apiGetCompetencias, apiGetEmpresas, apiGetHabilidades, apiGetIdiomas, apiGetSegmentos, apiGetTemplate } from '@apiGeral/geral';

// Imagens
import imgIconePesquisa from '@svg/solid/magnifying-glass.svg';

// Partes
import FormProfissoesPredefinidas from "@page/Logado/ProfissoesPredefinidas/FormProfissoesPredefinidas/FormProfissoesPredefinidas";

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from "@cpnt/Info/Info";
import Loading from "@cpnt/Loading/Loading";
import Portal from '@cpnt/Portal/Portal';
import TextoIconeLink from "@cpnt/TextoIconeLink/TextoIconeLink";

// Funções
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";
import { arrGetCheckeds, arrValues, camposInvalidos, checkArr, formatarSelect, msg} from '@func/utils';
import { closePortal, gets, isShowPortal, showPortal } from "@route/routeUtils";
import { getLabelState } from "@shared/functions/components";
import { msgs, msgStatus } from "@_func/request";

const PortalProfissoesPredefinidasFormEdicao = props => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();
	const location = useLocation();

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-prof-pred-form',
		onSuccess = () => null,
		onError = () => null,
		exibirMatch,
		onDelete,
		onErrorDelete = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		id: 0,
		title: '',
		active: 0,
		id_template: 0,
		id_area: 0,
		id_company: 0,
		keyword: '',
		type: '',
	};

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	// Edição
	const [loadingEditarProfissaoPredefinida, setLoadingEditarProfissaoPredefinida] = useState(false);
	const [loadingGetAreas, setLoadingGetAreas] = useState(false);
	const [loadingGetTemplate, setLoadingGetTemplate] = useState(false);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);
	const [loadingDeletarProfissao, setLoadingDeletarProfissao] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});
	const [stateAreas, setStateAreas] = useState([]);
	const [stateTemplate, setStateTemplate] = useState([]);
	const [stateEmpresas, setStateEmpresas] = useState([]);

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** FREELANCER
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await apiProfessions.getOne.get(id);
		_setStateDados(msgStatus(retGetItem));
		if(retGetItem.status === 200) {
			setStateDados({
				...retGetItem.data,
				...(!retGetItem.data.id_company ? {id_company: 0} : {})
			});
			apiGetAreas({setLoadingGetAreas, setStateAreas});
			if(retGetItem.data.id_template) {
				apiGetTemplate({id: retGetItem.data.id_template, setLoadingGetTemplate, setStateTemplate});
			}
			if(store.usuario.role === 1) {
				apiGetEmpresas({setLoadingGetEmpresas, setStateEmpresas});
			}
		} else {
			msgs([retGetItem]);
		}
		setLoading(false);
	}

	/*
	** Exibe a pesquisa
	** ================================================== */
	const exibirPesquisaAlgolia = () => {
		var dadosEnviar = {
			area_name: getLabelState(stateAreas, stateDados.id_area, 'value'),
			text: stateDados.keyword,
		};
		exibirMatch(dadosEnviar);
	}

	/*
	** Editar ProfissaoPredefinida
	** ================================================== */
	const editarProfissaoPredefinida = async e => {
		e.preventDefault();

		if(loadingEditarProfissaoPredefinida) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			id: stateDados.id,
			title: stateDados.title,
			active: stateDados.active ? 1 : 0,
			id_template: stateDados.id_template,
			id_area: stateDados.id_area,
			id_company: stateDados.id_company,
			keyword: stateDados.keyword,
			type: stateDados.type,
		};

		if(camposInvalidos(dadosEnviar, {}, [
			'title',
			'id_area',
		])) return;

		if(!dadosEnviar.id_company || dadosEnviar.id_company === 0 || dadosEnviar.id_company === '') {
			dadosEnviar.id_company = null;
		}

		if(!dadosEnviar.id_template || dadosEnviar.id_template === 0 || dadosEnviar.id_template === '') {
			dadosEnviar.id_template = null;
		}

		setLoadingEditarProfissaoPredefinida(true);

		const retUpdateProfissaoPredefinida = await apiProfessions.update.put(dadosEnviar);

		if(retUpdateProfissaoPredefinida.status === 200) {
			msg.success('Profissão atualizada com sucesso.');
			onSuccess({
				ret: retUpdateProfissaoPredefinida,
			})
		} else {
			msgs([retUpdateProfissaoPredefinida]);
			onError({
				ret: retUpdateProfissaoPredefinida,
			});
		}

		setLoadingEditarProfissaoPredefinida(false);
	}

	/*
	** Deletar
	** ================================================== */
	const deletarProfissao = async (e) => {
		e.preventDefault();

		if(loadingDeletarProfissao) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		setLoadingDeletarProfissao(true);

		const retDelete = await apiProfessions.delete(id);

		if(retDelete.status === 200) {
			msg.success('Profissão apagada com sucesso.');
			setTimeout(() => {
				onDelete({
					ret: retDelete,
				});
				setStateDados(camposDefault);
			}, 500);
		} else {
			msgs([retDelete]);
			onErrorDelete({
				ret: retDelete,
			});
		}

		setLoadingDeletarProfissao(false);
	}

	return (
		<Portal
			titulo="Editar configuração do Briefing/Vaga"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<div className={wrap}>
				<div className={className}>
					{loading ? (
						<Loading
							texto="Carregando, aguarde..."
						/>
					) : (
						_stateDados.status === 200 ? (
							<FormProfissoesPredefinidas
								stateDados={stateDados}
								setStateDados={setStateDados}
								stateAreas={stateAreas}
								setStateAreas={setStateAreas}
								stateTemplate={stateTemplate}
								setStateTemplate={setStateTemplate}
								stateEmpresas={[
									{value: 0, label: <strong>Profissão pública (todos terão acesso)</strong>},
									...stateEmpresas
								]}
								loadingGetAreas={loadingGetAreas}
								loadingGetTemplate={loadingGetTemplate}
								loadingGetEmpresas={loadingGetEmpresas}
								after={
									<div className="form-bottom">
										<div className="flex flex-center-v">
											<Button2
												_={{
													value: 'Apagar',
													className: 'btn btn-red',
													type: 'button',
													onClick: e => showPortal({portal: 'confirmacao-deletar-profissao', stateGet, navigate}),
												}}
												classExtra="btn-1"
											/>
											<Button2
												_={{
													value: <TextoIconeLink texto="Simular pesquisa" icone={imgIconePesquisa} />,
													onClick: () => exibirPesquisaAlgolia(),
												}}
												wrap="margin-l-10"
											/>
										</div>
										<div className="flex flex-center-v margin-l-auto">
											<Button2
												_={{
													value: 'Cancelar',
													className: 'btn btn-1',
													onClick: onClose,
												}}
											/>
											<Button2
												_={{
													value: 'Salvar dados',
													onClick: editarProfissaoPredefinida,
												}}
												loading={loadingEditarProfissaoPredefinida}
												wrap="margin-l-10"
											/>
										</div>
									</div>
								}
							/>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)
					)}
				</div>
			</div>

			<PortalConfirmacao
				show={isShowPortal({portal: 'confirmacao-deletar-profissao', stateGet})}
				titulo="Deseja realmente apagar essa profissão predefinida?"
				desc={
					<>
						<div>A profissão predefinida "<strong>{stateDados.title}</strong>" será removida. Essa ação não poderá ser desfeita.</div>
						<div className="margin-t-10">Deseja continuar?</div>
					</>
				}
				onClose={() => closePortal({portal: 'confirmacao-deletar-profissao', stateGet, navigate})}
				onClickCancelar={() => closePortal({portal: 'confirmacao-deletar-profissao', stateGet, navigate})}
				onClickConfirmar={deletarProfissao}
				loadingConfirmar={loadingDeletarProfissao}
			/>
		</Portal>
	);
};

export { PortalProfissoesPredefinidasFormEdicao };
