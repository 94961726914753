import React from 'react';

// CSS
import '@css/frameworcss.css';
import '@css/partes.css';
import '@css/tabela.css';
// import '@css/animations.css';
import '@css/style.css';
import './LayoutInicioTexto.css';

// Imagens
import imgBg from '@img/bg/bg.png';
import imgBgDireito from '@img/fotos/foto-1.png';

// Config
import { TITULO_PAGINA_PREFIXO, TITULO_PAGINA_DEFAULT } from '@_config/config';

const LayoutInicioTexto = props => {

	var titulo = TITULO_PAGINA_DEFAULT;
	if(props.title) {
		titulo = props.title;
	}

	// window.document.title = titulo;

	const Cpnt = props.component;
	const CpntTexto = props.texto;

	return (
		<div {...props.container}>
			<div style={{backgroundImage: `url(${imgBg})`}} className="tela-inicio-bg-animado-top-left">
				<div className="container-center">
					<div className="container-center-wrap">
						<div className="container-center-inner">
							<div className="base">
								<div className="box-default-alt">
									<div className="tela-inicio-container">
										<div className="tela-inicio-e">
											<div className="tela-inicio-e-wrap relative">
												<Cpnt {...props} />
											</div>
										</div>
										<div className="tela-inicio-d-alt">
											<CpntTexto {...props} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LayoutInicioTexto;
