import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalPropostaDeJobDetalhes.css';

// Componentes
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import apiBriefingFreelancers from "@api/briefingFreelancers/briefingFreelancers";
import apiBriefingGoals from "@api/briefingGoals/briefingGoals";
import apiBriefingLanguages from "@api/briefingLanguages/briefingLanguages";
import apiBriefings from "@api/briefings/briefings";
import apiBriefingSegments from "@api/briefingSegments/briefingSegments";
import apiBriefingSkills from "@api/briefingSkills/briefingSkills";
import Button2 from '@cpnt/Button2/Button2';
import { PortalPerfilFreelancer } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilFreelancer";

import DetalhesProcessoSeletivo from "@page/Logado/ProcessosSeletivos/DetalhesProcessoSeletivo/DetalhesProcessoSeletivo";
import { closePortal, getPortalDados, gets, isShowPortal } from "@route/routeUtils";
import { msgs, msgStatus } from "@_func/request";

const PortalPropostaDeJobDetalhes = props => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();
	const location = useLocation();

	const {
		show,
		id,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-proposta-detalhes',
		portalId = 'portal-proposta-detalhes',
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGeFreelancers, setLoadingGeFreelancers] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({});
	const [_stateDados, _setStateDados] = useState({});
	const [stateFreelancers, setStateFreelancers] = useState([]);

	const [loadingGetProfissionais, setLoadingGetProfissionais] = useState(false);
	const [loadingGetHabilidades, setLoadingGetHabilidades] = useState(false);
	const [loadingGetObjetivos, setLoadingGetObjetivos] = useState(false);
	const [loadingGetSegments, setLoadingGetSegments] = useState(false);
	const [loadingGetIdiomas, setLoadingGetIdiomas] = useState(false);
	const [loadingGetCompetencias, setLoadingGetCompetencias] = useState(false);
	const [loadingGetHistorico, setLoadingGetHistorico] = useState(false);

	const [stateBriefingProfissionais, setStateBriefingProfissionais] = useState([]);
	const [stateBriefingHabilidades, setStateBriefingHabilidades] = useState([]);
	const [stateBriefingObjetivos, setStateBriefingObjetivos] = useState([]);
	const [stateBriefingSegmentos, setStateBriefingSegmentos] = useState([]);
	const [stateBriefingIdiomas, setStateBriefingIdiomas] = useState([]);
	const [stateHistoricoDados, setStateHistoricoDados] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setLoading(true);
			setStateDados({});
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** FREELANCER
	** ================================================== */
	const inicializarGets = async () => {
		if(show === true) {
			const retGetBriefing = await apiBriefings.getOne.get(id);

			if(retGetBriefing.status === 200) {
				var autorizado = true;

				if(store.usuario.role !== 1) {
					if(retGetBriefing.data.id_company !== store.usuario.id_company) {
						autorizado = false;
					}
				}

				if(autorizado) {
					setStateDados(retGetBriefing.data);
					_setStateDados(msgStatus(retGetBriefing));

					// Gets
					getProfissionais(id);
					getHabilidades(id);
					getObjetivos(id);
					getSegments(id);
					getIdiomas(id);

					setLoadingGeFreelancers(false);

				} else {
					_setStateDados({
						status: 400,
						msg: 'Você não tem permissão para acessar essas informações',
					});
				}

			} else {
				_setStateDados(msgStatus(retGetBriefing));
				msgs([retGetBriefing]);
			}

			setLoading(false);
		}

	}

	/*
	** API - Get Profissionais
	** ================================================== */
	const getProfissionais = async idBriefing => {
		setLoadingGetProfissionais(true);
		const retGetProfissionais = await apiBriefingFreelancers.getAll.get(idBriefing);
		if(retGetProfissionais.status === 200) {
			setStateBriefingProfissionais(retGetProfissionais.data);
		} else {
			setStateBriefingProfissionais([]);
		}
		setLoadingGetProfissionais(false);
	}

	/*
	** API - Get Habilidades
	** ================================================== */
	const getHabilidades = async idBriefing => {
		setLoadingGetHabilidades(true);
		const retGetHabilidades = await apiBriefingSkills.getAll.get(idBriefing);
		if(retGetHabilidades.status === 200) {
			setStateBriefingHabilidades(retGetHabilidades.data);
		} else {
			setStateBriefingHabilidades([]);
		}
		setLoadingGetHabilidades(false);
	}
	/*
	** API - Get Objetivos
	** ================================================== */
	const getObjetivos = async idBriefing => {
		setLoadingGetObjetivos(true);
		const retGetObjetivos = await apiBriefingGoals.getAll.get(idBriefing);
		if(retGetObjetivos.status === 200) {
			setStateBriefingObjetivos(retGetObjetivos.data);
		} else {
			setStateBriefingObjetivos([]);
		}
		setLoadingGetObjetivos(false);
	}
	/*
	** API - Get Segmentos
	** ================================================== */
	const getSegments = async idBriefing => {
		setLoadingGetSegments(true);
		const retGetSegments = await apiBriefingSegments.getAll.get(idBriefing);
		if(retGetSegments.status === 200) {
			setStateBriefingSegmentos(retGetSegments.data);
		} else {
			setStateBriefingSegmentos([]);
		}
		setLoadingGetSegments(false);
	}
	/*
	** API - Get Idiomas
	** ================================================== */
	const getIdiomas = async idBriefing => {
		setLoadingGetIdiomas(true);
		const retGetIdiomas = await apiBriefingLanguages.getAll.get(idBriefing);
		if(retGetIdiomas.status === 200) {
			setStateBriefingIdiomas(retGetIdiomas.data);
		} else {
			setStateBriefingIdiomas([]);
		}
		setLoadingGetIdiomas(false);
	}

	return (
		<Portal
			titulo="Detalhes da proposta de job"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{loadingGeFreelancers ? (
							<Loading />
						) : (
							_stateDados.status === 200 ? (
								<>
									<DetalhesProcessoSeletivo
										stateDados={stateDados}

										stateBriefingProfissionais={stateBriefingProfissionais}
										stateBriefingHabilidades={stateBriefingHabilidades}
										stateBriefingObjetivos={stateBriefingObjetivos}
										stateBriefingSegmentos={stateBriefingSegmentos}
										stateBriefingIdiomas={stateBriefingIdiomas}
										stateHistoricoDados={stateHistoricoDados}

										loadingGetProfissionais={loadingGetProfissionais}
										loadingGetHabilidades={loadingGetHabilidades}
										loadingGetObjetivos={loadingGetObjetivos}
										loadingGetSegments={loadingGetSegments}
										loadingGetIdiomas={loadingGetIdiomas}
										loadingGetCompetencias={loadingGetCompetencias}
										loadingGetHistorico={loadingGetHistorico}
									/>
								</>
							) : (
								<>
									<Info
										texto={_stateDados.msg}
										icone="erro"
									/>

								</>
							)
						)}
						<div className="form-bottom">
							<Button2
								_={{
									value: 'Fechar',
									type: 'button',
									onClick: onClose,
								}}
							/>
						</div>
					</div>
				</div>
			)}

			<PortalPerfilFreelancer
				id={getPortalDados({portal: portalId, stateGet})}
				show={isShowPortal({portal: portalId, stateGet})}
				onClose={() => closePortal({portal: portalId, stateGet, navigate})}
			/>

		</Portal>
	);
};

export { PortalPropostaDeJobDetalhes };
