import React, { useState, useEffect } from 'react';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

import '../Input/Input.css';
import { msg, validarCnpj, validarCpf } from "@func/utils";

const InputCpfCnpj = props => {

	const dados = {
		type: 'text',
		value: '',
		label: '',
		class: '',
		classBox: '',
		active: false,
		invalid: false,
		placeholder: '',
		wrap: false,
		req: false,
		valueCallback: () => null,
		invalidToggle: () => null,
		...props,
	};

	var {
		_ = {}
	} = props;

	const [value, setValue] = useState(dados.value);

	useEffect(() => {
		setValue(dados.value);
	}, [dados.value]);

	return (
		<div className={dados.wrap ? dados.wrap : ''}>
			<div className={`inputtext-box inputtext-box-ativo ${dados.classBox} ${dados.invalid ? 'inputtext-box-invalido' : ''}`}>
				<CpfCnpj
					type={dados.type}
					name={dados.name}
					value={value}
					onChange={(e, type) => {
						setValue(e.target.value);
						dados.valueCallback(e.target.value);
					}}
					onFocus={e => dados.invalidToggle({
						[`${dados.name}Invalido`]: false
					})}
					className={dados.class}
					placeholder={dados.placeholder}
					{..._}
				/>
				<label>{dados.label}{dados.req && <span className="inputtext2-req">*</span>}</label>
			</div>
		</div>
	)
}

export default InputCpfCnpj;

export const validarCpfCnpj = (cpfOuCnpj = '', mensagem = 'CPF ou CNPJ inválido.') => {

	// não é cpf
	if(validarCpf(cpfOuCnpj, false)) {
		return true;
	}

	// não é cnpj
	if(validarCnpj(cpfOuCnpj, false)) {
		return true;
	}

	if(mensagem) {
		msg.error(mensagem);
	}

	return false;
}

export const isCPFouCNPJ = (cpfOuCnpj) => {
	if(validarCpf(cpfOuCnpj, false)) {
		return 'cpf';
	}
	if(validarCnpj(cpfOuCnpj, false)) {
		return 'cnpj';
	}
	return false;
}

export const cpfOuCnpjString = (cpfOuCnpj, naoIdentificadoTexto = '(não identificado)') => {
	const strings = {
		cpf: 'CPF',
		cnpj: 'CNPJ',
	};
	var qual = isCPFouCNPJ(cpfOuCnpj);
	if(qual) {
		return strings[qual];
	}
	return naoIdentificadoTexto;
}
