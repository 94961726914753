import React from 'react';

// CSS
import './StatusCards.css';

// Components
import Icone from '@cpnt/Icone/Icone';

// Imagens
import imgIconeEmOrcamento from '@svg/solid/sack-dollar.svg';
import imgIconeEmExecucao from '@svg/solid/circle-play.svg';
import imgIconeFinalizado from '@svg/solid/circle-check.svg';
import imgIconeCancelado from '@svg/solid/circle-x.svg';
import imgIconeDesconhecido from '@svg/solid/file.svg';

export const iconeStatusCard = id => {
	// IDS dos status dos cards

	// id: 13 => Em orçamento
	// id: 14 => Em execução
	// id: 15 => Aprovado
	// id: 16 => Cancelado

	const imagens = {
		id13: {icone: imgIconeEmOrcamento, cor: '#eab20e'},
		id14: {icone: imgIconeEmExecucao, cor: '#229d3e'},
		id15: {icone: imgIconeFinalizado, cor: '#2596e1'},
		id16: {icone: imgIconeCancelado, cor: '#E74C3C'},
	};
	if(imagens[`id${id}`] !== undefined) {
		return imagens[`id${id}`];
	}
	return {
		icone: imgIconeDesconhecido,
		cor: '#CCCCCC',
	};
}

const StatusCards = props => {

	const {
		status = 0,
		className = '',
		onClick = () => null,
	} = props;

	const iconeMaisCor = iconeStatusCard(status);

	return (
		<div className={`cpnt-statuscards ${className}`} onClick={onClick}>
			<div className="cpnt-statuscards-icones">
				<span className="cpnt-statuscards-icone">
					<Icone imagem={iconeMaisCor.icone} cor={iconeMaisCor.cor} tamanho="22px" />
				</span>
			</div>
		</div>
	);
};

export default StatusCards;
