import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './ComplementarProcessoSeletivo.css';

// API
import apiAlgolia from '@api/algolia/algolia';
import apiBriefingFreelancers from '@api/briefingFreelancers/briefingFreelancers';
import apiBriefings from '@api/briefings/briefings';
import { api } from "@_api/api";
import { apiGetAreas, apiGetBriefingHistoricoAtualizacoesGetAll, apiGetCompetencias, apiGetEstados, apiGetHabilidades, apiGetIdiomas, apiGetObjetivos, apiGetSegmentos } from "@api/_functions/geral";

// Shared componentes
import { getLabelState } from '@shared/functions/components';

// Imagens
import imgIconeSetaDir from '@svg/padrao/arrow-right.svg';
import imgIconeBriefing from '@svg/regular/file-lines.svg';
import imgIconePublicar from '@svg/solid/circle-check.svg';
import imgIconeNovo from '@svg/solid/file.svg';
import imgIconeProfissionais from '@svg/solid/user-check.svg';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import { FiltrosProfissionais } from "@cpnt/FiltrosProfissionais/FiltrosProfissionais";
import FiltrosWrap from "@cpnt/FiltrosWrap/FiltrosWrap";
import Info from '@cpnt/Info/Info';
import LinhaItem from '@cpnt/LinhaItem/LinhaItem';
import { ListaProfissionais } from "@cpnt/ListaProfissionais/ListaProfissionais";
import { ListaProfissionaisAcoes } from "@cpnt/ListaProfissionais/ListaProfissionaisAcoes/ListaProfissionaisAcoes";
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';

// Portais
import PortalProcessoSeletivoInfoHistorico from '@portal/PortaisProcessoSeletivo/PortalProcessoSeletivoInfoHistorico/PortalProcessoSeletivoInfoHistorico';

// Funções
import { desselecionarTodos, getAllUsuariosLocal, limparListaLocal, onClickRemover, selecionarTodos, selecionarUm } from '@func/selecaoUsuariosBriefings';
import { conveterRealParaNumero, dataPorExtenso, formatarSelect, isNumeroDiferente, isObjDiferente, msg, removerKeysVazias, sanitize, tamanhoTela, trim } from '@func/utils';
import { qualStep } from "@page/Logado/CadastrarProcessoSeletivo/funcCadastrarProcessoSeletivo";

// Partes
import { PropostaJobComplementoForm } from "./PropostaJobComplementoForm/PropostaJobComplementoForm";
import { RevisaoComplementoBriefing } from "./RevisaoComplementoBriefing/RevisaoComplementoBriefing";

// Config
import { SENIORIDADE } from "@config/objetos";

// Rotas
import { clearGet, closePortal, getPortalDados, gets, initFuncExec, isShowPortal, setGet, setGetNavigate, showPortal } from "@route/routeUtils";
import { PortalPerfilUsuario } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilUsuario";
import useWindowDimension from "@/_/hooks/useWindowDimensions";
import { LIMITE_QTD_FREELANCERS_COMPLEMENTO_BRIEFING } from "@_config/config";
import { msgs, msgStatus } from "@_func/request";

const ComplementarProcessoSeletivo = () => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	const countRef = useRef(0);
	const screen = useWindowDimension();

	useEffect(() => {
		const q = queryString.parse(window.location.search);
		if(! q.edit_id_briefing || q.edit_id_briefing === '') {
			_setStateDados({status: 400, msg: 'Nenhuma proposta de job foi selecionada.'});
		}
	}, []);

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = (getsAtualizados = getsDefault) => {

		initFuncExec([
			/*
			** STEP 1 - EDICAO
			** Campos do cadastro
			** ================================================== */
			{
				func: getCamposFormularioEdicao,
				condicao: getsAtualizados.edit_id_briefing && isNumeroDiferente(stateGet.edit_id_briefing, getsAtualizados.edit_id_briefing),
				param: getsAtualizados.edit_id_briefing
			},

			/*
			** Executam os childs caso a condição principal retornar true
			** ================================================== */
			{
				child: {
					condicao: getsAtualizados.step === '2',
					func: [
						{
							func: getCamposAlgoliaInit,
							condicao: countRef.current === 0,
						},
						{
							func: getProfissionaisManualApi,
							param: getsAtualizados,
							condicao: isObjDiferente(stateGet, getsAtualizados),
						},
						{
							func: apiGetHabilidades,
							param: {
								idArea: getsAtualizados.area,
								setLoadingGetHabilidades,
								setStateHabilidades,
							},
							condicao: countRef.current > 0 ? true : isNumeroDiferente(stateGet.area, getsAtualizados.area),
						},
					]
				}
			}

		], init, location, stateLocation);
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		page: 1,
		limit: 50,
		step: '1',
		edit_id_briefing: null,
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	/*
	** Timeout do botão de publicar
	** ================================================== */
	const [isWaiting, setIsWaiting] = useState(false);

	useEffect(() => {
		if(isWaiting) {
			var waiting = isWaiting - 1;
			setTimeout(() => {
				setIsWaiting(waiting);
			}, 1000);
		}
	}, [isWaiting])

	/*
	** INIT - Inicializa o Algolia
	** ================================================== */
	const getCamposAlgoliaInit = async () => {
		countRef.current++;
		await apiGetAreas({setLoadingGetAreas, setStateAreas});
		await apiGetEstados({setLoadingGetEstados, setStateEstados});
		await apiGetIdiomas({setLoadingGetIdiomas, setStateIdiomas});
		await apiGetCompetencias({setLoadingGetCompetencias, setStateCompetencias});
		await apiGetObjetivos({setLoadingGetObjetivos, setStateObjetivos});
		await apiGetSegmentos({setLoadingGetSegmentos, setStateSegmentos});
	}

	/*
	** Verifica se precisa exibir aviso
	** ================================================== */
	useEffect(() => {
		if(store.usuario.id_company !== 1 && stateGet.step === '2' && showPortalAvisoLimite === false) {
			// setShowPortalAvisoLimite(true);
		}
	}, [stateGet.step]);

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		reason: '',
		new_freelancers: [],
		notify_new_freelancer: 1,
		notify_responsible: 0,
		notify_old_freelancer: 0,
		_ignorar_freelancers: false,
	};

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({status: 200});
	const [stateAreas, setStateAreas] = useState([]);
	const [stateEstados, setStateEstados] = useState([]);
	const [stateIdiomas, setStateIdiomas] = useState([]);
	const [stateCompetencias, setStateCompetencias] = useState([]);
	const [stateObjetivos, setStateObjetivos] = useState([]);
	const [stateSegmentos, setStateSegmentos] = useState([]);
	const [stateHabilidades, setStateHabilidades] = useState([]);
	const [stateIsDescricaoAlterada, setStateIsDescricaoAlterada] = useState('');

	/*
	** States
	** ================================================== */
	const [stateProfissionais, setStateProfissionais] = useState([]);
	const [_stateProfissionais, _setStateProfissionais] = useState({});
	const [stateProfissionaisMetadata, setStateProfissionaisMetadata] = useState({});
	const [stateProfissionaisSelecionados, setStateProfissionaisSelecionados] = useState([]);

	// Step
	const maxStep = 4;

	/*
	** SATES STEP 1
	** ================================================== */
	const [stateUsuariosStep1, setStateUsuariosStep1] = useState([]);
	const [stateFreelancersStep1, setStateFreelancersStep1] = useState([]);

	/*
	** COMPONENTES DE LISTA LOCAL
	** ================================================== */

	/*
	** Lista local
	** ================================================== */
	const [stateListaAtual, setStateListaAtual] = useState('Lista padrão');
	const [stateListas, setStateListas] = useState([]);
	const [stateSelecionados, setStateSelecionados] = useState(getAllUsuariosLocal(stateListaAtual));

	/*
	** Portais
	** ================================================== */
	const [showPortalInfoHistorico, setShowPortalInfoHistorico] = useState(false);
	const [showPortalEdicaoFinalizada, setShowPortalEdicaoFinalizada] = useState(false);
	const [showPortalAvisoLimite, setShowPortalAvisoLimite] = useState(false);

	/*
	** Loading
	** ================================================== */
	const [loadingBtnSalvarEdicao, setLoadingBtnSalvarEdicao] = useState(false);
	const [loadingGetHistoricoAtualizacoes, setLoadingGetBriefingHistoricoAtualizacoes] = useState(false);
	const [loadingGetBriefing, setLoadingGetBriefing] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateHistoricoAtualizacoes, setStateBriefingHistoricoAtualizacoes] = useState([]);
	const [stateBriefingHistoricoId, setStateBriefingHistoricoId] = useState({});

	// Campos
	const [stateDescricaoBriefing, setStateDescricaoBriefing] = useState('');

	/*
	** Componente de filtro
	** ================================================== */
	const [loadingGetProfissionais, setLoadingGetProfissionais] = useState(false);

	// Filtro loading
	const [loadingGetAreas, setLoadingGetAreas] = useState(false);
	const [loadingGetHabilidades, setLoadingGetHabilidades] = useState(false);
	const [loadingGetEstados, setLoadingGetEstados] = useState(false);
	const [loadingGetIdiomas, setLoadingGetIdiomas] = useState(false);
	const [loadingGetObjetivos, setLoadingGetObjetivos] = useState(false);
	const [loadingGetSegmentos, setLoadingGetSegmentos] = useState(false);
	const [loadingGetCompetencias, setLoadingGetCompetencias] = useState(false);

	/*
	** EDICAO - Inicializa os campos do formulário
	** ================================================== */
	const getCamposFormularioEdicao = async (id_briefing) => {

		setLoadingGetBriefing(true);
		const retGetBriefing = await apiBriefings.getOne.get(id_briefing);

		// id: 4 => "Em Andamento"
		// id: 5 => "Pausado"
		// id: 6 => "Contratado"
		// id: 7 => "Arquivado"
		// id: 8 => "Em rascunho"

		var opcoesStatus = {
			8: 'rascunho',
			7: 'arquivado',
			6: 'contratado',
			5: 'pausado',
			4: 'em-andamento',
		}

		if(retGetBriefing.status !== 200) {
			_setStateDados(msgStatus(retGetBriefing));
		} else {

			setStateDados({
				...camposDefault,
				...retGetBriefing.data,
			});

			var opcoes = {
				tipo: opcoesStatus[retGetBriefing.data.id_status]
			};

			if(store.usuario.role !== 1) {
				if(retGetBriefing.data.id_company !== store.usuario.id_company) {
					opcoes = {
						tipo: 'nao-autorizado',
						status: 0,
					};
				}
			}

			_setStateDados({
				...msgStatus(retGetBriefing),
				...opcoes,
			});

			setStateDescricaoBriefing(sanitize(retGetBriefing.data.text || ''));

			/*
			** Dados gerais
			** ================================================== */
			const retGetUsuarios = await api.users.getAllUsers.get(retGetBriefing.data.id_company, 2);
			const retGetFreelancers = await apiBriefingFreelancers.getAll.get(id_briefing);

			/*
			** Guarda o id_freelancer na chave id para usar nos subcomponentes
			** ================================================== */
			var freela = formatarSelect({dados: retGetFreelancers.data, keyValue: 'id'});
			freela = freela.map(item => {return {...item, id: item.id_freelancer}});
			setStateFreelancersStep1(freela);

			/*
			** Campos do select
			** ================================================== */
			setStateUsuariosStep1(formatarSelect({dados: retGetUsuarios.data, keyValue: 'id', keyIcone: 'photo', iconClass: 'border-radius-50'}));
			apiGetBriefingHistoricoAtualizacoesGetAll({idBriefing: id_briefing, setLoadingGetBriefingHistoricoAtualizacoes, setStateBriefingHistoricoAtualizacoes});
		}

		setLoadingGetBriefing(false);
	}

	/*
	** API ALGOLIA - Get manual
	** ================================================== */
	const getProfissionaisManualApi = async (dadosObj = {}) => {

		const dadosEnviarObj = {
			...gets(), // Não retornou no dadosObj porque está sendo chamado no useEffect e não no init.
			...dadosObj,
		}

		var dadosEnviar = {
			...dadosObj,
			area: getLabelState(stateAreas, dadosEnviarObj.area, 'value'),
			seniority: getLabelState(SENIORIDADE, dadosEnviarObj.seniority, 'value'),
			state: getLabelState(stateEstados, dadosEnviarObj.state, 'value', 'uf'),
			languages: getLabelState(stateIdiomas, dadosEnviarObj.languages, 'value'),
			competences: getLabelState(stateCompetencias, dadosEnviarObj.competences, 'value'),
			segments: getLabelState(stateSegmentos, dadosEnviarObj.segments, 'value'),
			skills: getLabelState(stateHabilidades, dadosEnviarObj.skills, 'value'),
			goals: getLabelState(stateObjetivos, dadosEnviarObj.goals, 'value'),
		};

		var dadosEnviarClear = clearGet(dadosEnviar);

		/*
		** Caso seja para usar o valor por hora
		** ================================================== */
		if(dadosEnviarObj.use_price) {
			dadosEnviarClear.price_lower = conveterRealParaNumero(dadosEnviarClear.price_min);
			dadosEnviarClear.price_higher = conveterRealParaNumero(dadosEnviarClear.price_max);
		} else {
			delete dadosEnviarObj.price_min;
			delete dadosEnviarObj.price_max;
		}
		delete dadosEnviarObj.use_price;

		/*
		** API
		** ================================================== */
		setLoadingGetProfissionais(true);

		const retGetProfissionais = await apiAlgolia.withoutRelevance.post(dadosEnviarClear);

		_setStateProfissionais(msgStatus(retGetProfissionais));

		if(retGetProfissionais.status === 200) {
			setStateProfissionais(retGetProfissionais.data);
			setStateProfissionaisMetadata(retGetProfissionais.metadata);
		} else {
			setStateProfissionais([]);
			setStateProfissionaisMetadata({});
		}

		setLoadingGetProfissionais(false);
	}

	/*
	** Definir steps / passo a passo / passos steps / passo-a-passo / step-step / step-by-step
	** ================================================== */
	const definirStep = (acao) => {
		var novoStep = qualStep({
			acao: acao,
			step: stateGet.step,
			maxStep,
		});
		setGetNavigate({
			gets: {step: novoStep},
			navigate,
			stateGet,
			location,
		});
	}

	const salvarProposta = async () => {
		if(loadingBtnSalvarEdicao) {
			msg.warning('Por favor, aguarde...');
			return;
		}

		if(!stateDados.title || stateDados.title === '') {
			msg.error('É necessário preencher o título na seção "Dados da proposta".');
			return;
		}

		if(!stateDados.reason || stateDados.reason === '') {
			msg.error('É necessário preencher o motivo da edição na seção "Dados da proposta".');
			return;
		}

		var texto = trim(sanitize(stateDescricaoBriefing));

		if(!texto || texto === '') {
			msg.error('É necessário preencher a descrição do briefing na seção "Dados da proposta".');
			return;
		}

		var freelancers = [];
		if(!stateDados._ignorar_freelancers) {
			if(stateSelecionados && stateSelecionados.length) {
				freelancers = stateSelecionados.map(item => {
					return {
						id_freelancer: item.id,
					}
				});
			}

			if(store.usuario.id_company !== 1 && freelancers.length > LIMITE_QTD_FREELANCERS_COMPLEMENTO_BRIEFING) {
				msg.error(`Você ultrapassou o limite máximo de ${LIMITE_QTD_FREELANCERS_COMPLEMENTO_BRIEFING} profissionais por proposta de job. Remova o excedente para poder finalizar o complemento da proposta.`);
				return;
			}
		}


		if(stateIsDescricaoAlterada) {
			sanitize(stateDescricaoBriefing);
		}

		const dadosEnviar = {
			id: Number(stateGet.edit_id_briefing),
			title: stateDados.title,
			reason: stateDados.reason,
			budget: conveterRealParaNumero(stateDados.budget),
			text: texto,
			new_freelancers: freelancers,
			notify_new_freelancer: stateDados.notify_new_freelancer,
			notify_old_freelancer: stateDados.notify_old_freelancer,
			notify_responsible: stateDados.notify_responsible,
			id_user: stateDados.id_user,
			id_status: 4, // "Em Andamento"
		};

		const dadosENviarSemKeysVazias = removerKeysVazias(dadosEnviar);

		setLoadingBtnSalvarEdicao(true);

		const retInsertHistorico = await apiBriefings.update.put(dadosENviarSemKeysVazias);

		if(retInsertHistorico.status === 200) {
			msg.success('Proposta de job atualizada com sucesso');
			setShowPortalEdicaoFinalizada(true);
			limparListaLocal({
				stateListaAtual: 'Lista padrão',
				setStateSelecionados,
			}, false);
		} else {
			msgs([retInsertHistorico]);
		}

		setLoadingBtnSalvarEdicao(false);
	}

	const renderTelaCorreta = () => {
		// if(_stateDados.tipo === 'rascunho') {
		// 	return (
		// 		<div className="cadprocsel-box cadprocsel-passo cadprocsel-passo-1">
		// 			<div className="base-m">
		// 				<div className="base-content">
		// 					<Info
		// 						texto={
		// 							<div>
		// 								<h3 className="margin-t-0">Essa proposta ainda está como rascunho</h3>
		// 								<div className="flex flex-column flex-center">
		// 									<LinhaItem
		// 										titulo="Proposta"
		// 										desc={stateDados.title}
		// 										alt
		// 									/>
		// 									<LinhaItem
		// 										titulo="Criada em"
		// 										desc={dataPorExtenso(stateDados.created_at, true, 'há ', '', '')}
		// 										alt
		// 									/>
		// 								</div>
		// 								<p>Essa proposta de job que você está tentando complementar ainda não foi publicada. Antes de complementar uma proposta, você deve publicá-la através da página de <Link to={`/briefing/cadastrar?edit_id_briefing=${stateDados.id}`}>cadastro de propostas</Link>.</p>
		// 							</div>
		// 						}
		// 						classNameWrap="relative"
		// 						icone="info"
		// 					/>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	);
		// }

		if(_stateDados.status === 403) {
			return (
				<div className="cadprocsel-box cadprocsel-passo cadprocsel-passo-1">
					<div className="base-m">
						<div className="base-content">
							<Info
								texto={
									<div>
										<h3 className="margin-0">Sem permissão</h3>
										<div>{_stateDados.msg}</div>
									</div>
								}
								icone="erro"
							/>
						</div>
					</div>
				</div>
			);
		}

		if(_stateDados.tipo === 'nao-autorizado') {
			return (
				<div className="cadprocsel-box cadprocsel-passo cadprocsel-passo-1">
					<div className="base-m">
						<div className="base-content">
							<Info
								texto={
									<div>
										<h3 className="margin-0">Sem permissão</h3>
										<div>Você não tem permissão para editar essa proposta de job.</div>
									</div>
								}
								icone="erro"
							/>
						</div>
					</div>
				</div>
			);
		}

		if(_stateDados.status === 400) {
			return (
				<div className="cadprocsel-box cadprocsel-passo cadprocsel-passo-1">
					<div className="base-m">
						<div className="base-content">
							<Info
								texto={
									<div>
										<h3 className="margin-0">Proposta não encontrada</h3>
										<div>{_stateDados.msg}</div>
									</div>
								}
								icone="erro"
							/>
						</div>
					</div>
				</div>
			);
		}

		if(_stateDados.status === 200 || !stateDados.id) {
			return (
				<>
					{stateGet.step === '1' ? (
						<PropostaJobComplementoForm
							stateDados={stateDados}
							setStateDados={setStateDados}
							getHistorico={e => {
								apiGetBriefingHistoricoAtualizacoesGetAll({idBriefing: stateGet.edit_id_briefing, setLoadingGetBriefingHistoricoAtualizacoes, setStateBriefingHistoricoAtualizacoes});
							}}
							loadingGetHistorico={loadingGetHistoricoAtualizacoes}

							stateHistorico={stateHistoricoAtualizacoes}

							stateSelecionadosAnteriormente={stateFreelancersStep1}
							setStateSelecionadosAnteriormente={setStateFreelancersStep1}
							stateProfissionaisSelecionados={stateProfissionaisSelecionados}
							setStateProfissionaisSelecionados={setStateProfissionaisSelecionados}

							stateDescricaoBriefing={stateDescricaoBriefing}
							setStateDescricaoBriefing={setStateDescricaoBriefing}
							setStateIsDescricaoAlterada={setStateIsDescricaoAlterada}

							stateGet={stateGet}
							navigate={navigate}

							stateUsuarios={stateUsuariosStep1}

							stateListaAtual={stateListaAtual}
							setStateListaAtual={setStateListaAtual}
							stateListas={stateListas}
							setStateListas={setStateListas}
							stateSelecionados={stateSelecionados}
							setStateSelecionados={setStateSelecionados}

							exibirHistorico={e => {
								showPortal({portal: 'historico', dados: e.id, stateGet, navigate})
							}}
						/>
					) : null}

					{stateGet.step === '2' ? (
						<div className="cadprocsel-passo cadprocsel-passo-3">
							<div className="cadprocsel-acoes-pesquisa-box">
								<FiltrosWrap>
									<FiltrosProfissionais
										// Rota
										stateGet={stateGet}
										getsDefault={getsDefault}
										// States
										stateEstados={stateEstados}
										stateIdiomas={stateIdiomas}
										stateCompetencias={stateCompetencias}
										stateObjetivos={stateObjetivos}
										stateAreas={stateAreas}
										stateHabilidades={stateHabilidades}
										stateSegmentos={stateSegmentos}
										// Loadings
										loadingGetEstados={loadingGetEstados}
										loadingGetIdiomas={loadingGetIdiomas}
										loadingGetCompetencias={loadingGetCompetencias}
										loadingGetObjetivos={loadingGetObjetivos}
										loadingGetAreas={loadingGetAreas}
										loadingGetHabilidades={loadingGetHabilidades}
										loadingGetSegmentos={loadingGetSegmentos}
									/>
								</FiltrosWrap>
							</div>

							{store.usuario.id_company !== 1 && stateSelecionados.length > LIMITE_QTD_FREELANCERS_COMPLEMENTO_BRIEFING ? (
								<div className="box-default-3 box-error margin-5">
									<h3 className="color-red margin-t-0 margin-b-10">Selecione no máximo {LIMITE_QTD_FREELANCERS_COMPLEMENTO_BRIEFING} profissionais</h3>
									<div>Você ultrapassou o limite máximo de {LIMITE_QTD_FREELANCERS_COMPLEMENTO_BRIEFING} profissionais por proposta de job. Remova o excedente para poder finalizar a publicação da proposta.</div>
									<div className="margin-t-10"><span className="link" onClick={e => {
										showPortal({portal: 'listas-salvas-local', stateGet, navigate});
										msg.info(`Para continuar, remova os excedentes da lista até que fique com o total de ${LIMITE_QTD_FREELANCERS_COMPLEMENTO_BRIEFING} profissionais, ou selecione uma outra lista com essa quantidade ou menos.`);
									}}>EDITAR LISTA</span></div>
								</div>
							) : null}

							<ListaProfissionais
								// Rota
								init={init}
								stateGet={stateGet}
								getsDefault={getsDefault}
								navigate={navigate}

								// default
								stateProfissionais={stateProfissionais}
								setStateProfissionais={setStateProfissionais}
								stateProfissionaisSelecionados={stateProfissionaisSelecionados}
								setStateProfissionaisSelecionados={setStateProfissionaisSelecionados}
								_stateProfissionais={_stateProfissionais}
								_setStateProfissionais={_setStateProfissionais}
								stateProfissionaisMetadata={stateProfissionaisMetadata}
								setStateProfissionaisMetadata={setStateProfissionaisMetadata}
								loadingGetProfissionais={loadingGetProfissionais}
								setLoadingGetProfissionais={setLoadingGetProfissionais}
								stateSelecionadosAnteriormente={stateFreelancersStep1}
								setStateSelecionadosAnteriormente={setStateFreelancersStep1}

								// Lista local
								stateListaAtual={stateListaAtual}
								setStateListaAtual={setStateListaAtual}
								stateListas={stateListas}
								setStateListas={setStateListas}
								stateSelecionados={stateSelecionados}
								setStateSelecionados={setStateSelecionados}

								// Ações da lista
								selecionarUm={selecionarUm}
								selecionarTodos={selecionarTodos}
								desselecionarTodos={desselecionarTodos}
								onClickRemover={onClickRemover}
								onClickRemoverSalvos={e => {
									onClickRemover({dados: e, stateListaAtual, setStateSelecionados})
									setStateFreelancersStep1(stateFreelancersStep1.filter(item => item.id_freelancer !== e.id));
								}}
							/>
						</div>
					) : null}

					{stateGet.step === '3' ? (
						<div className="cadprocsel-box cadprocsel-passo cadprocsel-passo-3">
							<div className="base-m">
								<div className="base-content">
									<RevisaoComplementoBriefing
										stateGet={stateGet}
										navigate={navigate}
										stateDados={stateDados}
										setStateDados={setStateDados}
										stateDescricao={stateDescricaoBriefing}
										stateListaAtual={stateListaAtual}
										setStateListaAtual={setStateListaAtual}
										stateListas={stateListas}
										setStateListas={setStateListas}
										stateSelecionados={stateSelecionados}
										setStateSelecionados={setStateSelecionados}

										stateFreelancers={stateFreelancersStep1}
										setStateFreelancers={setStateFreelancersStep1}
									/>
								</div>
							</div>
						</div>
					) : null}
				</>
			)
		}

		return <div>Passo não encontrado.</div>;
	}

	var winPosTextoBtn = tamanhoTela([
		{
			width: 680,
			dados: {
				dados: 'Dados',
				dadosIcone: imgIconeBriefing,
				profissionais: 'Profissionais',
				profissionaisIcone: imgIconeProfissionais,
				publicar: 'Publicar'
			}
		},
		{
			width: 400,
			dados: {
				dados: 'Dados',
				dadosIcone: false,
				profissionais: 'Profissionais',
				profissionaisIcone: false,
				publicar: 'Publicar'
			}
		}
	], {
		dados: 'Dados da proposta',
		dadosIcone: imgIconeBriefing,
		profissionais: 'Selecionar profissionais',
		profissionaisIcone: imgIconeProfissionais,
		publicar: 'Publicar alterações',
	}, screen);

	return (
		<>
			<div className="pg-complprocsel base-full">
				<div className="conteudo-box">
					<div className="titulo-box titulo-box-res">
						<h3 className="titulo">Complementar proposta</h3>
					</div>
					{loadingGetBriefing ? (
						<Loading
							texto="Carregando, aguarde..."
						/>
					) : (
						renderTelaCorreta()
					)}
				</div>

				{(!loadingGetBriefing && _stateDados.status === 200) ? (
				// {(!loadingGetBriefing && _stateDados.status === 200 && _stateDados.tipo !== 'rascunho') ? (
					<div className="bottombar padding-l-0 padding-r-0">
						<div className="base-m">
							<div className="base-content">
								<div className="pg-carprocsel-bottombar">
									<div className="pg-carprocsel-bottombar-btn-l-box">
										<Button2
											_={{
												value: <TextoIconeLink texto={winPosTextoBtn.dados} icone={winPosTextoBtn.dadosIcone} />,
												onClick: e => {
													if(loadingBtnSalvarEdicao) return;
													definirStep('1');
													window.scrollTo(0, 0);
												},
												disabled: stateGet.step === '1' ? true : false,
											}}
											loading={loadingBtnSalvarEdicao}
										/>
									</div>
									<div className="pg-carprocsel-bottombar-btn-r-box inline-flex flex-center flex-wrap">
										<Button2
											_={{
												value: <TextoIconeLink texto={winPosTextoBtn.profissionais} icone={winPosTextoBtn.profissionaisIcone} />,
												onClick: e => {
													if(loadingBtnSalvarEdicao) return;
													definirStep('2');
													window.scrollTo(0, 0);
												},
												disabled: stateGet.step === '2' ? true : false,
											}}
											loading={loadingBtnSalvarEdicao}
											classNameExtra="margin-r-10"
										/>
										{stateGet.step === '3' ? (
											<Button2
												_={{
													value: isWaiting ? <TextoIconeLink texto={`${winPosTextoBtn.publicar} (${isWaiting}s)`} icone={imgIconePublicar} iconeCor="#1cb53f" /> : <TextoIconeLink texto={winPosTextoBtn.publicar} icone={imgIconePublicar} iconeCor="#1cb53f" />,
													onClick: isWaiting ? () => null : salvarProposta,
													disabled: isWaiting ? true : false,
												}}
												loading={loadingBtnSalvarEdicao}
											/>
										) : (
											<Button2
												_={{
													value: <TextoIconeLink texto="Finalização" icone={imgIconeSetaDir} reverso={true} />,
													onClick: e => {
														if(loadingBtnSalvarEdicao) return;
														setIsWaiting(3);
														definirStep('3');
														window.scrollTo(0, 0);
													},
												}}
												loading={loadingBtnSalvarEdicao}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</div>

			{/* Portal - Info do histórico */}
			<PortalProcessoSeletivoInfoHistorico
				id={stateBriefingHistoricoId}
				historico={stateHistoricoAtualizacoes}
				show={showPortalInfoHistorico}
				onClose={() => {
					setShowPortalInfoHistorico(false);
				}}
			/>

			{stateGet.step === '1' || stateGet.step === '3' ? (
				<ListaProfissionaisAcoes
					stateGet={stateGet}
					navigate={navigate}
					stateListaAtual={stateListaAtual}
					setStateListaAtual={setStateListaAtual}
					stateListas={stateListas}
					setStateListas={setStateListas}
					stateSelecionados={stateSelecionados}
					setStateSelecionados={setStateSelecionados}
					setStateFreelancersSalvos={setStateSelecionados} // remover
					stateSelecionadosAnteriormente={stateFreelancersStep1}
				/>
			) : null}

			{/* Portal de templates de briefing */}
			<Portal
				titulo="Proposta atualizada com sucesso"
				show={showPortalEdicaoFinalizada}
				className="cadprocsel-overlay-sucesso"
				x={false}
				closeOnEsc={false}
			>
				<Info
					icone="sucesso"
					texto={
						<>
							<h3>Parabéns, sua proposta de job foi complementada com sucesso!</h3>
						</>
					}
				/>
				<div className="form-bottom">
					<Button2
						_={{
							value: <TextoIconeLink texto="Complementar novamente" icone={imgIconeNovo} />,
							onClick: () => {
								setShowPortalEdicaoFinalizada(false);
								setStateIsDescricaoAlterada(false);
								getCamposFormularioEdicao(stateGet.edit_id_briefing);
								setGetNavigate({
									gets: {step: 1, edit_id_briefing: stateGet.edit_id_briefing},
									navigate,
									stateGet,
									location,
									uniq: true,
								});
							},
						}}
					/>
					<Button2
						_={{
							value: <TextoIconeLink texto="Visualizar propostas publicadas" icone={imgIconeSetaDir} reverso={true} />,
							onClick: () => {
								navigate('/briefing');
							},
						}}
						wrap="margin-l-auto"
					/>
				</div>
			</Portal>

			<PortalPerfilUsuario
				id={getPortalDados({portal: 'perfil-usuario', stateGet})}
				show={isShowPortal({portal: 'perfil-usuario', stateGet})}
				onClose={() => closePortal({portal: 'perfil-usuario', stateGet, navigate})}
			/>

			<PortalProcessoSeletivoInfoHistorico
				id={getPortalDados({portal: 'historico', stateGet})}
				idBriefing={stateGet.edit_id_briefing}
				show={isShowPortal({portal: 'historico', stateGet})}
				onClose={() => closePortal({portal: 'historico', stateGet, navigate})}
			/>

			{/* Portal de aviso de limite de profissionais para empresas beta */}
			<Portal
				titulo="Quantidade de profissionais"
				show={showPortalAvisoLimite === true}
				className="w-800"
				x={false}
				closeOnEsc={false}
			>
				<Info
					icone="alerta"
					texto={
						<>
							<h3>Selecione até {LIMITE_QTD_FREELANCERS_COMPLEMENTO_BRIEFING} perfis para receberem esta proposta de job</h3>
							<div>
								<p>Para uma melhor experiência de contratação dos profissionais remotos, temos uma limitação de {LIMITE_QTD_FREELANCERS_COMPLEMENTO_BRIEFING} profissionais por complemento de proposta de job. Fique atento a quantidade selecionada para não ultrapassar esse limite.</p>
								<p>Caso necessário, você poderá complementar essa proposta posteriormente e selecionar novos profissionais.</p>
							</div>
						</>
					}
				/>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Entendi',
							onClick: () => {
								setShowPortalAvisoLimite(1)
							},
						}}
					/>
				</div>
			</Portal>
		</>
	);
};

export default ComplementarProcessoSeletivo;