import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './ItemProfissional.css';

// Imagens
import imgIconeEmail from '@svg/regular/at.svg';
import imgIconePortfolio from '@svg/regular/folder-open.svg';
import imgIconeIdioma from '@svg/regular/globe.svg';
import imgIconeObjetivo from '@svg/regular/location-crosshairs.svg';
import imgIconeHabilidades from '@svg/regular/memo-circle-check.svg';
import imgIconeTelefone from '@svg/regular/phone.svg';
import imgIconePais from '@svg/solid/earth-americas.svg';
import imgIconeDinheiro from '@svg/solid/sack-dollar.svg';
import imgIconeRedesSociais from '@svg/solid/share-nodes.svg';
import imgIconeId from '@svg/solid/user.svg';

// Componentes
import Checkbox2 from '@cpnt/Checkbox2/Checkbox2';
import Estrelas from '@cpnt/Estrelas/Estrelas';
import Tip from '@cpnt/Tip/Tip';
import Disponibilidade from "@cpnt/Disponibilidade/Disponibilidade";
import { AvaliacoesGerais } from '@cpnt/Avaliacoes/Avaliacoes';
import { Foto2 } from '@cpnt/Foto2/Foto2';
import Icone from '@cpnt/Icone/Icone';
import stringImg from '@cpnt/stringImg/stringImg';
import { Tag } from '@cpnt/Tag/Tag';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';

// funções
import { addPrefixo, formatarTelefone, htmlToJsx, stringPesquisa } from '@func/utils';

// Rota
import { gets } from '@route/routeUtils';

const ItemProfissional = props => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();
	const location = useLocation();
	const show = store.usuario.id_company === 1 ? true : false;

	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		tabIndex,

		// Funções
		onSelect = () => null,

		// Dados
		id,
		idUser,
		nome,
		titulo,
		descricao,
		nomeDestaque,
		tituloDestaque,
		foto,
		valorHora,
		habilidades,
		disponibilidade,
		segmento,
		email,
		emailDestaque,
		dataCadastro,
		perfilFinalizado,
		telefone,
		objetivos,
		endereco,
		idiomas,
		redesSociais,
		portfolios,
		senioridade,
		avaliacoes,
		checked,
		showSelect = true,

		idiomasExibicao,
		objetivosExibicao,
		habilidadesExibicao,
		onClickVerPerfil = () => null,
		onClickVerFoto = () => null,
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	return (
		<>
			<div className={`cpnt-itemprof ${wrap} ${classNameWrap} ${checked && disponibilidade ? 'cpnt-itemprof-checked' : ''}`}>
				<div className={`cpnt-itemprof-box ${className}`}>
					<div className="cpnt-itemprof-topbar">
						<div className="cpnt-itemprof-tituloarea">{htmlToJsx(segmento)}</div>
						<div className="cpnt-itemprof-checkdisponibilidade">
							{disponibilidade && showSelect ? (
								<div className="cpnt-itemprof-checkbox">
									<Checkbox2
										label="selecionar"
										_={{
											onChange: e => {
												onSelect({
													...props,
													_checked: e.target.checked,
												});
											},
											checked: checked,
										}}
									/>
								</div>
							) : null}
							<Disponibilidade status={disponibilidade} textoIndisponivel="Indisponível para novos jobs" min />
						</div>
					</div>

					<div className="cpnt-itemprof-cols">
						<div className="cpnt-itemprof-col cpnt-itemprof-col-1">
							<div className="cpnt-itemprof-col-wrap cpnt-itemprof-col-wrap-1">
								<Foto2
									img={foto}
									tamanho="95px"
									onClick={e => onClickVerFoto(foto)}
									classNameImgBox="cursor-pointer"
									preview={false}
								/>
								<Tip
									tip={
										<Estrelas
											qtd={avaliacoes.total}
											tamanho="80px"
										/>
									}
								>
									<AvaliacoesGerais avaliacoes={avaliacoes} />
								</Tip>
								<div
									className="cpnt-itemprof-perfil-btn a fs-15 bold underline"
									onClick={() => onClickVerPerfil(id)}
								>
									Ver perfil
								</div>
								<div className="cpnt-itemprof-perfilsenioridade">
									<Tip
										tip={
											<div className={`cpnt-itemprof-senioridade cpnt-itemprof-senioridade-${stringPesquisa(senioridade)}`}>{senioridade || '-'}</div>
										}
									>
										<div className="max-w-250 align-center">{senioridade ? <>Este profissional considera ter habilidades no nível <strong>{senioridade}</strong></> : 'Nível de conhecimento não informado'}</div>
									</Tip>
								</div>

								<div className="cpnt-itemprof-val-id">
									{/* <div className="cpnt-itemprof-val">
										<div>Valor hora</div>
										<div className="cpnt-itemprof-box-val">
											<Icone imagem={imgIconeDinheiro} cor="#229d3e" tamanho="14px" />
											<strong className="margin-l-5">{valorHora}</strong>
										</div>
									</div> */}
									<div className="cpnt-itemprof-id">
										<div>ID</div>
										<div className="cpnt-itemprof-box-val">
											<Icone imagem={imgIconeId} cor="#0277BD" tamanho="14px" />
											<strong className="margin-l-5">{id}</strong>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="cpnt-itemprof-col cpnt-itemprof-col-2">
							<div className="cpnt-itemprof-col-wrap cpnt-itemprof-col-wrap-2">

								<h4 className="titulo">{htmlToJsx(nome)}</h4>
								<div className="cpnt-itemprof-titulo-destaque">{htmlToJsx(tituloDestaque)}</div>

								<div className="cpnt-itemprof-icones-box">
									<div className="cpnt-itemprof-icones-box-1">
										{show ? (
											<Tip
												titulo="E-mail"
												classNameTitulo="align-center"
												tip={
													<Icone
														imagem={imgIconeEmail}
														cor="#999"
														tamanho="19px"
													/>
												}
												classNameTip="cpnt-itemprof-icone-info-l"
												classNameConteudo="cpnt-itemprof-tooltip-conteudo"
												closeOnLeave={false}
												_={{delayLeave: 100}}
											>
												<a href={`mailto:${email}`}>{htmlToJsx(email)}</a>
											</Tip>
										) : null}
										{show ? (
											<Tip
												titulo="Telefone"
												classNameTitulo="align-center"
												tip={
													<Icone
														imagem={imgIconeTelefone}
														cor="#999"
														tamanho="19px"
													/>
												}
												classNameTip="cpnt-itemprof-icone-info-l"
												classNameConteudo="cpnt-itemprof-tooltip-conteudo"
												closeOnLeave={false}
												_={{delayLeave: 100}}
											>
												{formatarTelefone(telefone)}
											</Tip>
										) : null}
										<Tip
											titulo="Objetivos"
											classNameTitulo="align-center"
											tip={
												<Icone
													imagem={imgIconeObjetivo}
													cor={objetivos.length ? '#999' : '#DDD'}
													tamanho="19px"
												/>
											}
											classNameTip="cpnt-itemprof-icone-info-l"
											classNameConteudo="cpnt-itemprof-tooltip-conteudo"
											closeOnLeave={false}
											_={{delayLeave: 100}}
										>
											{objetivosExibicao}
										</Tip>
									</div>

									<div className="cpnt-itemprof-icones-box-2">
										{show ? (
											<Tip
												titulo="Redes sociais"
												classNameTitulo="align-center"
												tip={
													<Icone
														imagem={imgIconeRedesSociais}
														cor={redesSociais.length ? '#999' : '#DDD'}
														tamanho="19px"
													/>
												}
												classNameTip="cpnt-itemprof-icone-info-r"
												classNameConteudo="cpnt-itemprof-tooltip-conteudo"
												closeOnLeave={false}
												_={{delayLeave: 100}}
											>
												{redesSociais.length ? redesSociais.map((val, key) => {
													var ico = stringImg(val.url);
													return (
														<TextoIconeLink
															key={key}
															icone={ico.icone}
															iconeCor={ico.cor}
															link={addPrefixo(val.url)}
															texto={val.url}
															target="_blank"
															iconeTamanho="20px"
															className={`text-one-line ${key === 0 ? '' : 'margin-t-5'}`}
														/>
													)
												}) : (
													<div className="center">Nenhuma rede social cadastrado.</div>
												)}
											</Tip>
										) : null}
										<Tip
											titulo="Portfólio"
											classNameTitulo="align-center"
											tip={
												<Icone
													imagem={imgIconePortfolio}
													cor={portfolios.length ? '#999' : '#DDD'}
													tamanho="19px"
												/>
											}
											classNameTip="cpnt-itemprof-icone-info-r"
											classNameConteudo="cpnt-itemprof-tooltip-conteudo"
											closeOnLeave={false}
											_={{delayLeave: 100}}
										>
											{portfolios.length ? portfolios.map((val, key) => {
												return (
													<TextoIconeLink
														key={key}
														link={addPrefixo(val)}
														target="_blank"
														texto={val}
														className={`text-one-line ${key === 0 ? '' : 'margin-t-5'}`}
													/>
												)
											}) : (
												<div className="center">Nenhum portfólio cadastrado.</div>
											)}
										</Tip>
										<Tip
											titulo="Habilidades"
											classNameTitulo="align-center"
											tip={
												<Icone
													imagem={imgIconeHabilidades}
													cor={habilidades.length ? '#999' : '#DDD'}
													tamanho="19px"
												/>
											}
											classNameTip="cpnt-itemprof-icone-info-r"
											classNameConteudo="cpnt-itemprof-tooltip-conteudo flex flex-wrap"
											closeOnLeave={false}
											_={{delayLeave: 100}}
										>
											{habilidadesExibicao}
										</Tip>
									</div>
								</div>

								<div className="cpnt-itemprof-icones-exp">
									<div className="flex flex-center-v">
										<Icone
											imagem={imgIconePais}
											cor="#999"
											tamanho="16px"
										/>
										<span className="margin-l-5">{endereco}</span>
									</div>
									<div className="flex flex-center-v">
										<Icone
											imagem={imgIconeIdioma}
											cor="#999"
											tamanho="16px"
										/>
										<span className="margin-l-5">{idiomasExibicao}</span>
									</div>
								</div>

								<div className="cpnt-itemprof-desctexto-wrap" tabIndex={tabIndex}>
									<div className="cpnt-itemprof-prevent-scroll"></div>
									<div className="cpnt-itemprof-desctexto">{htmlToJsx(descricao)}</div>
								</div>

							</div>
						</div>
					</div>

					<div className="cpnt-itemprof-tags-wrapper">
						<div className="cpnt-itemprof-tags">
							<div className="cpnt-itemprof-tags-wrap">
								{habilidades.length ? (
									habilidades.map((val, key) => {
										return (
											<Tag
												key={key}
												texto={val.habilidade}
												className="no-wrap"
												classNameWrap="cpnt-itemprof-tag-item"
											/>
										)
									})
								) : (
									<div className="cpnt-itemprof-sem-tags">Nenhuma habilidade cadastrada.</div>
								)}
							</div>
						</div>
					</div>

				</div>
			</div>
		</>
	);
};

export { ItemProfissional };
