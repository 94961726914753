import { Btn } from "@_cpnt/Btn/Btn/Btn";
import React from 'react';

const ChatMensagemBriefing = props => {
	const {
		metadata = {},
		wrap = '',
		className = '',
		mensagem,
	} = props;

	return (
		<div className={`cpnt-chatmsg cpnt-chatmsg-posicao-center cpnt-chatmsg-tipo-${metadata.tipo ? metadata.tipo : 'default'} ${wrap}`}>
			<div className={`cpnt-chatmsg-mensagem-box cpnt-chatmsg-mensagem-box-center ${className}`}>
				<div className={`cpnt-chatmsg-custom-titulo`}>
					<h3 className="margin-0 padding-0">Detalhes do briefing</h3>
				</div>
				<div className="cpnt-chatmsg-mensagem align-center">
					<div>Confira aqui o briefing para esse job. Caso tenha dúvidas, não hesite em perguntar.</div>
					<div className="flex flex-center margin-t-20">
						<Btn
							_={{
								value: 'Visualizar briefing',
								onClick: props.onClick,
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChatMensagemBriefing;