import React from 'react';

// css
import './PgIndUser.css';

const PgIndUser = props => {
	const {
		className = '',
		classNameWrap = '',
		img,
		texto,
		nome,
		desc,
	} = props;

	return (
		<>
			<div className={`cpntpg-induser ${classNameWrap}`}>
				<div className={`cpntpg-induser-box ${className}`}>
					<div className="cpntpg-induser-imgbox">
						<img src={img} className="cpntpg-induser-img" alt="foto" />
					</div>
					<div className="cpntpg-induser-texto">
						{texto}
					</div>
					<div className="cpntpg-induser-nomedesc">
						<div className="cpntpg-induser-nome strong">{nome}</div>
						<div className="cpntpg-induser-desc">{desc}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { PgIndUser };