import { validarRetorno, config } from '@_api/axios';
import api from 'axios';
import qs from 'qs';

const apiCustomers = {
	insert: {
		post: async dados => {
			return await api.post(`/customers/insert`, dados, config(true))
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	update: {
		put: async dados => {
			return await api.put(`/customers/update`, dados, config(true))
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getOne: {
		get: async id => {
			return await api.get(`/customers/getOne/${id}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	all: {
		get: async dadosObj => {
			var dadosEnviar = qs.stringify(dadosObj, {encode: false});
			return await api.get(`/customers/all?${dadosEnviar}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	attachs: {
		get: async idCustomer => {
			return await api.get(`/customers/attachs/${idCustomer}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export { apiCustomers };