import React, { useContext, useState, useEffect } from 'react';
import { toast as msg } from 'react-toastify';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './Fechamento.css';
import "react-datepicker/dist/react-datepicker.css";

// Components
import BtnAdicionar from '@cpnt/BtnAdicionar/BtnAdicionar';
import { Select2 } from '@cpnt/Select2/Select2';
import Input2 from '@cpnt/Input2/Input2';
import Qtd from '@cpnt/Qtd/Qtd';
import Portal from '@cpnt/Portal/Portal';
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Paginacao from '@cpnt/Paginacao/Paginacao';
import PortalPerfil from '@portalGeral/PortalPerfil/PortalPerfil';

// Funções
import { removerKeysVazias } from '@func/utils';

// Configs
import { _tempDadosFechamento } from './_tempDadosFechamento';
import ItemFotoDesc from '@cpnt/ItemFotoDesc/ItemFotoDesc';
import { caminhoFoto, dataApiParaBr, formatarDinheiro, joinArray } from '@func/utils';
import Checkbox2 from '@cpnt/Checkbox2/Checkbox2';
import InputCalendario from '@cpnt/InputCalendario/InputCalendario';
import Select3 from '@cpnt/Select3/Select3';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';

// Imagens
import imgIconeEnviarExportar from '@svg/regular/file-export.svg';
import imgIconeEnviarRemessa from '@svg/regular/paper-plane.svg';
import Icone from '@cpnt/Icone/Icone';
import imgIconeDownload from '@svg/padrao/cloud-arrow-down.svg';
import PortalConfirmacao from '@portalGeral/PortalConfirmacao/PortalConfirmacao';
import StatusJobs from '@cpnt/StatusJobs/StatusJobs';

import imgIconePDF from '@svg/solid/file-pdf.svg';
import imgIconeXLS from '@svg/solid/file-excel.svg';
import imgIconeCSV from '@svg/solid/file-csv.svg';

import imgIconeDiretoria from '@svg/solid/user-tie.svg';
import imgIconeCliente from '@svg/solid/arrow-right-arrow-left.svg';
import imgIconeOperacoes from '@svg/solid/ballot-check.svg';
import imgIconeDesconhecido from '@svg/solid/file.svg';
import { LISTA_USUARIOS_CROWD } from '@config/objetos';
import { primeiroNome } from "@_func/regex";

const Fechamento = () => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();

	/*
	** Portais
	** ================================================== */
	const [showPortalAdicionarProjeto, setShowPortalAdicionarProjeto] = useState(false);
	const [showPortalPerfilCompleto, setShowPortalPerfilCompleto] = useState(false);
	const [showPortalEditarFechamento, setShowPortalEditarFechamento] = useState(false);
	const [showPortalExportacao, setShowPortalExportacao] = useState(false);
	const [showPortalEnviarRemessaCliente, setShowPortalEnviarRemessaCliente] = useState(false);
	const [showPortalEnvioRemessaEnviadoCliente, setShowPortalEnvioRemessaEnviadoCliente] = useState(false);
	const [showPortalEnviarRemessaDiretoria, setShowPortalEnviarRemessaDiretoria] = useState(false);
	const [showPortalEnvioRemessaEnviadoDiretoria, setShowPortalEnvioRemessaEnviadoDiretoria] = useState(false);
	const [showPortalEnviarRemessaOperacoes, setShowPortalEnviarRemessaOperacoes] = useState(false);
	const [showPortalEnvioRemessaEnviadoOperacoes, setShowPortalEnvioRemessaEnviadoOperacoes] = useState(false);
	const [showPortalConfirmacaoEnvioRemessaOperacoes, setShowPortalConfirmacaoEnvioRemessaOperacoes] = useState(false);

	/*
	** Popups
	** ================================================== */
	const [showPortalConfirmacaoEnvioRemessaCliente, setShowPortalConfirmacaoEnvioRemessaCliente] = useState(false);
	const [showPortalConfirmacaoEnvioRemessaDiretoria, setShowPortalConfirmacaoEnvioRemessaDiretoria] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateFechamento, setStateFechamento] = useState([]);
	const [stateFechamentoMetadata, setStateFechamentoMetadata] = useState({});
	const [stateTipoExportacao, setStateTipoExportacao] = useState(false);

	/*
	** Code get
	** ================================================== */
	const [statusCodeGetFechamento, setStatusCodeGetFechamento] = useState(false);


	/*
	** Loading
	** ================================================== */
	const [loadingGetEmpresasFiltro, setLoadingGetEmpresasFiltro] = useState(false);
	const [loadingGetClientesFiltro, setLoadingGetClientesFiltro] = useState(false);
	const [loadingGetUsuariosEquipeFiltro, setLoadingGetUsuariosEquipeFiltro] = useState(false);
	const [loadingGetTipoProdutosFiltro, setLoadingGetTipoProdutosFiltro] = useState(false);
	const [loadingGetStatusFiltro, setLoadingGetStatusFiltro] = useState(false);
	const [loadingGetFechamento, setLoadingGetFechamento] = useState(false);
	const [loadingGetTipoCenariosFiltro, setLoadingGetTipoCenariosFiltro] = useState(false);

	// Popups
	const [statePerfilCompletoId, setStatePerfilCompletoId] = useState(false);
	const [statePerfilCompletoTipo, setStatePerfilCompletoTipo] = useState('freelancer');

	/*
	** Filtros Select
	** ================================================== */
	const [stateEmpresasFiltro, setStateEmpresasFiltro] = useState([]);
	const [stateClientesFiltro, setStateClientesFiltro] = useState([]);
	const [stateUsuariosEquipeFiltro, setStateUsuariosEquipeFiltro] = useState([]);
	const [stateTipoProdutosFiltro, setStateTipoProdutosFiltro] = useState([]);
	const [stateTipoCenariosFiltro, setStateTipoCenariosFiltro] = useState([]);
	const [stateStatusFiltro, setStateStatusFiltro] = useState([]);

	// Get empresa por id no GET
	var nav = useLocation();
	const q = queryString.parse(nav.search);
	var navNumeroPagina = Number(q.pagina);
	var navIdEmpresa = Number(q.id_empresa);

	navNumeroPagina = isNaN(navNumeroPagina) ? 1 : navNumeroPagina;
	navIdEmpresa = isNaN(navIdEmpresa) ? false : navIdEmpresa;

	// Paginação
	const [stateLimitePorPagina, setStateLimitePorPagina] = useState(20);

	// Filtros
	const [stateClienteSelecionadoFiltro, setStateClienteSelecionadoFiltro] = useState(navIdEmpresa);
	const [stateEmpresaSelecionadaFiltro, setStateEmpresaSelecionadaFiltro] = useState(store.usuario.id_company);
	const [stateUsuarioEquipeSelecionadoFiltro, setStateUsuarioEquipeSelecionadoFiltro] = useState([]);
	const [stateTipoProdutoSelecionadoFiltro, setStateTipoProdutoSelecionadoFiltro] = useState(false);
	const [stateStatusSelecionadoFiltro, setStateStatusSelecionadoFiltro] = useState(1);
	const [stateTipoCenarioSelecionadoFiltro, setStateTipoCenarioSelecionadoFiltro] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		// Filtros
		getFechamento();
	}, []);

	/*
	** API - Get Fechamento get
	** ================================================== */
	useEffect(() => {
		getFechamento({
			page: navNumeroPagina
		});
	}, [navNumeroPagina]);

	/*
	** API - Fechamento Filtro
	** ================================================== */
	const getFechamento = async (dados = {}) => {

		if(loadingGetFechamento) {
			return;
		}

		setLoadingGetFechamento(true);

		var dadosEnviar = {
			// id_company: stateEmpresaSelecionadaFiltro,
			// status: stateStatusSelecionadoFiltro,
			// id_customer: stateClienteSelecionadoFiltro,
			// product_type: stateTipoProdutoSelecionadoFiltro,
			// scenery_type: stateTipoCenarioSelecionadoFiltro,
			// id_user: stateUsuarioEquipeSelecionadoFiltro,
			limit: stateLimitePorPagina,
			page: navNumeroPagina,
			...dados,
		};

		if(dadosEnviar.page) {
			dadosEnviar.page = (dadosEnviar.page - 1);
		}

		dadosEnviar = removerKeysVazias(dadosEnviar);
		// const retGetFechamento = await apiFechamento.all.get(dadosEnviar);
		const retGetFechamento = _tempDadosFechamento;

		setStateFechamento(retGetFechamento);
		setStateFechamentoMetadata({});

		// if(retGetFechamento.status === 200) {
		// 	setStateFechamento(retGetFechamento.data);
		// 	setStateFechamentoMetadata(retGetFechamento.metadata || {});
		// } else {
		// 	setStateFechamento([]);
		// 	setStateFechamentoMetadata({});
		// 	if(retGetFechamento.status === 400) {
		// 		msg.error('Nenhuma job encontrado para o fechamento.');
		// 	} else {
		// 		msgs([retGetFechamento]);
		// 	}
		// }
		setLoadingGetFechamento(false);
	}

	const contabilizarPaginas = () => {
		var conta1 = ((navNumeroPagina-1)*stateLimitePorPagina)+1;
		var conta2 = ((navNumeroPagina-1)*stateLimitePorPagina)+stateLimitePorPagina;
		if(navNumeroPagina === stateFechamentoMetadata.qtdPages) {
			conta2 = stateFechamentoMetadata.qtdRecords;
		}
		return <div>Exibindo {conta1}-{conta2} de {stateFechamentoMetadata.qtdRecords} resultados</div>;
	}

	const contabilizarValorTotalPrevisto = () => {
		const checkeds = stateFechamento.filter(item => item._checked);
		var valorSelecionados = 0;
		var valorTotal = 0;

		stateFechamento.map(val => {
			valorTotal += val.liquid_price;
		});

		if(checkeds.length) {
			checkeds.map(val => {
				valorSelecionados += val.liquid_price;
			});
		}

		return (
			<div>
				<div className="flex flex-wrap fs-15">
					<div className="margin-r-30 margin-b-5">
						<span className="strong">Valor total previsto:</span>
						<strong className="margin-l-5 color-green">
							{valorTotal > 0 ? formatarDinheiro(valorTotal, 'R$ ') : 'Nenhum job listado.'}
						</strong>
					</div>
					<div className="margin-r-30 margin-b-5">
						<span className="strong">Valor dos jobs selecionados:</span>
						<strong className="margin-l-5 color-green">
							{valorSelecionados > 0 ? formatarDinheiro(valorSelecionados, 'R$ ') : 'Nenhum job selecionado.'}
						</strong>
					</div>
					<div className="margin-r-30">
						<span className="strong">Jobs selecionados (IDs):</span>
						{!checkeds.length ? (
							<span className="margin-l-5">Nenhum job selecionado</span>
						) : (
							<strong className="margin-l-5 color-blue">{joinArray(checkeds, ', ', ' e ', 'id')}</strong>
						)}
					</div>
				</div>
			</div>
		);
	}

	// const iconeFechamento = (id) => {

	// 	const imagens = {
	// 		id14: {icone: imgIconeDiretoria, cor: '#2596e1'},
	// 		id15: {icone: imgIconeCliente, cor: '#eab20e'},
	// 		id16: {icone: imgIconeOperacoes, cor: '#676767'},
	// 	};
	// 	if(imagens[`id${id}`] !== undefined) {
	// 		return imagens[`id${id}`];
	// 	}
	// 	return {
	// 		icone: imgIconeDesconhecido,
	// 		cor: '#CCCCCC',
	// 	};
	// }

	return (
		<div className="base-full">
			<div className="conteudo-box">
				<div className="flex flex-center-v flex-between padding-t-5 padding-b-25">
					<h3 className="margin-0 flex flex-center-v margin-t-0">Fechamento</h3>
				</div>

				<div>
					<form
						onSubmit={e => {
							e.preventDefault();
							getFechamento({});
						}}
						className="flex flex-wrap align-center wrap-5"
					>
						<div className="input-box-alt w-25p">
							<Input2
								label="Pesquisar ID do job"
							/>
						</div>
						<div className="input-box-alt w-25p relative">
							{loadingGetEmpresasFiltro && <Loading texto="Carregando, aguarde..." alt absolute wrap="fs-14" />}
							<Select2
								label="Empresa"
								_={{
									val: stateEmpresaSelecionadaFiltro,
									options: stateEmpresasFiltro,
									onChange: e => {
										setStateEmpresaSelecionadaFiltro(e.val);

										// Zerar filtros
										setStateUsuarioEquipeSelecionadoFiltro([]);
										setStateUsuariosEquipeFiltro([]);
										setStateClienteSelecionadoFiltro(false);
										setStateTipoProdutoSelecionadoFiltro(false);
										setStateStatusSelecionadoFiltro(1);
										setStateFechamento([]);

										// Zera a página
										getFechamento({
											id_company: e.val,
										});
										navigate('/fechamento');
									},
								}}
								virtualized
							/>
						</div>
						<div className="input-box-alt w-25p relative">
							<Select2
								label="Projeto"
								_={{}}
							/>
						</div>
						<div className="input-box-alt w-25p relative">
							<Select2
								label="Status do job"
								_={{}}
							/>
						</div>
						<div className="input-box-alt w-25p relative">
							<Input2
								label="Pesquisar freelancer"
							/>
						</div>
						<div className="input-box-alt w-25p relative">
							<Input2
								label="Pesquisar por valor freela"
							/>
						</div>
						<div className="input-box-alt w-25p relative">
							<Select2
								label="Job aprovado por"
								_={{}}
							/>
						</div>
						<div className="input-box-alt w-25p relative">
							<Select2
								label="Resultados por página"
								_={{
									value: stateLimitePorPagina,
									options: [
										{value: 10, label: '10 resultados'},
										{value: 20, label: '20 resultados'},
										{value: 30, label: '30 resultados'},
										{value: 40, label: '40 resultados'},
										{value: 50, label: '50 resultados'},
										{value: 60, label: '60 resultados'},
										{value: 70, label: '70 resultados'},
										{value: 80, label: '80 resultados'},
										{value: 90, label: '90 resultados'},
										{value: 100, label: '100 resultados'},
									],
									onChange: e => {
									},
								}}
							/>
						</div>

						<div className="flex w-500">
							<div className="input-box-alt flex-1 relative">
								<InputCalendario
									label="Data inicial"
									class="flex-1"
								/>
							</div>
							<div className="input-box-alt flex-1 relative">
								<InputCalendario
									label="Data final"
									class="flex-1"
								/>
							</div>
							<div className="input-box-alt relative">
								<Button2
									_={{
										value: 'Buscar'
									}}
								/>
							</div>
						</div>

						<div className="flex flex-grow flex-end margin-l-auto flex-center-v">
							<div className="input-box-alt relative">
								<Select3
									customLabel={
										<TextoIconeLink
											texto="Exportar"
											icone={imgIconeEnviarExportar}
											iconeCor="#0277BD"
										/>
									}
									options={[
										{value: 1, label: 'Em .xlsx'},
										{value: 2, label: 'Em .csv'},
										{value: 3, label: 'Em .pdf'},
									]}
									onClick={e => {
										setStateTipoExportacao(e.value);
										setShowPortalExportacao(true);
									}}
								/>
							</div>
							<div className="input-box-alt padding-l-30 relative">
								<Select3
									customLabel={
										<TextoIconeLink
											texto="Enviar remessa para"
											icone={imgIconeEnviarRemessa}
											iconeCor="#0277BD"
										/>
									}
									options={[
										{value: 1, label: 'Operações revisar'},
										{value: 2, label: 'Diretoria aprovar'},
										{value: 3, label: 'Cliente aprovar'},
									]}
									onClick={e => {
										if(e.value === 1) {
											setShowPortalEnviarRemessaOperacoes(true);
										} else if(e.value === 2) {
											setShowPortalEnviarRemessaDiretoria(true);
										} else if(e.value === 3) {
											setShowPortalEnviarRemessaCliente(true);
										}
									}}
								/>
							</div>
						</div>

						<button type="submit" className="none"></button>
					</form>

				</div>

				{loadingGetFechamento === true && stateFechamento.length <= 0 ? (
					<div className="margin-t-30">
						<Loading />
					</div>
				) : (
					<div>
						<div className="tela-fechamento-info">
							{contabilizarValorTotalPrevisto()}
						</div>
						<div className="flex flex-between flex-center-v margin-t-15">
							<div>
								{stateFechamento.length > 0 ? (
									<div>
										{contabilizarPaginas()}
									</div>
								) : (
									<div>Nenhum resultado encontrado</div>
								)}
							</div>
							<Paginacao
								prefixoLink="/projetos?pagina="
								paginaAtual={navNumeroPagina}
								totalItens={stateFechamentoMetadata.qtdRecords}
								totalPaginas={stateFechamentoMetadata.qtdPages}
								qtdItensPorPagina={stateLimitePorPagina}
								inativo={stateFechamentoMetadata.qtdPages > 1 ? false : true}
							/>
						</div>

						{!stateFechamento.length ? (
							<div>
								<h3 className="msg">Nenhum projeto encontrado</h3>
							</div>
						) : (
							<div className="lista-fechamento relative">
								{loadingGetFechamento && <Loading bgAlt absolute alt />}
								<table className="tabela tabela-td-alt margin-t-30">
									<tbody>
										<tr className="tabela-titulo-fixo">
											<th className="lista-fechamento-col-checkbox">
												<Checkbox2
													_={{
														onClick: e => {
															setStateFechamento([...stateFechamento.map(val2 => {
																return {
																	...val2,
																	_checked: e.target.checked,
																}
															})]);
														}
													}}
													wrap="margin-auto"
												/>
											</th>
											<th className="lista-fechamento-col-responsavel lista-fechamento-col-foto">Responsável</th>
											<th className="lista-fechamento-col-id">ID Job</th>
											<th className="lista-fechamento-col---">Job</th>
											<th className="lista-fechamento-col---">Projeto</th>
											<th className="lista-fechamento-col-freelancer lista-fechamento-col-foto">Freelancer</th>
											<th className="lista-fechamento-col---">Dados da empresa</th>
											<th className="lista-fechamento-col-valor">Valor Plat.</th>
											<th className="lista-fechamento-col-valor">Valor Freela</th>
											<th className="lista-fechamento-col-data">Data Aprov.</th>
										</tr>
										{stateFechamento.map((val, index) => {
											return (
												<tr key={index} {...(val._checked ? {className: 'tr-checked'} : {})}>

													<td className="align-center">
														<Checkbox2
															_={{
																checked: val._checked,
																onClick: e => {
																	setStateFechamento([...stateFechamento.map(val2 => {
																		if(val2.id === val.id) {
																			return {
																				...val2,
																				_checked: e.target.checked,
																			}
																		}
																		return val2;
																	})])
																}
															}}
														/>
													</td>

													<td className="shrink fs-12 bold align-center">
														<ItemFotoDesc
															foto={caminhoFoto(val.user_photo).url}
															className="margin-auto cursor-pointer"
															onClick={() => {
																setStatePerfilCompletoId(val.id_user);
																setShowPortalPerfilCompleto(true);
															}}
														/>
														<div className="text-one-line fs-12">{primeiroNome(val.user_name)}</div>
													</td>

													<td className="shrink fs-12 bold align-center">
														{val.id_job}
													</td>

													<td className="strong">
														{val.title}
													</td>

													<td>
														{val.project_name}
													</td>

													<td className="shrink fs-12 bold align-center">
														<ItemFotoDesc
															foto={caminhoFoto(val.freelancer_photo).url}
															className="margin-auto cursor-pointer"
															onClick={() => {
																setStatePerfilCompletoId(val.id_freelancer);
																setShowPortalPerfilCompleto(true);
															}}
														/>
														<div className="text-one-line fs-12">{primeiroNome(val.freelancer_name)}</div>
													</td>

													<td>
														<strong>{val.customer_name}</strong>
														<div className="color-1 fs-13 text-one-line">{val.cnpj}</div>
													</td>

													<td className="shrink align-center">
														{formatarDinheiro(val.price, 'R$ ')}
													</td>

													<td className="shrink align-center">
														{formatarDinheiro(val.liquid_price, 'R$ ')}
													</td>

													<td className="shrink align-center">
														{dataApiParaBr(val.approval_at)}
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						)}

						<div className="flex flex-between padding-t-30 padding-b-10">
							<div></div>
							<Paginacao
								prefixoLink="/projetos?pagina="
								paginaAtual={navNumeroPagina}
								totalItens={stateFechamentoMetadata.qtdRecords}
								totalPaginas={stateFechamentoMetadata.qtdPages}
								qtdItensPorPagina={stateLimitePorPagina}
								inativo={stateFechamentoMetadata.qtdPages > 1 ? false : true}
							/>
						</div>
					</div>
				)}
			</div>


			{/* Portal de adicionar novo projeto */}
			<Portal
				show={showPortalAdicionarProjeto}
				closeOnEsc={false}
				onClose={() => {
					setShowPortalAdicionarProjeto(false);
				}}
				className="w-800"
			>
				<div>ADICIONAR FECHAMENTO</div>
			</Portal>


			{/* Portal de edição de projeto */}
			<Portal
				show={showPortalEditarFechamento}
				closeOnEsc={false}
				onClose={() => {
					setShowPortalEditarFechamento(false);
				}}
				className="w-800"
			>
				{loadingGetFechamento === true ? (
					<Loading texto="Carregando informações do projeto, aguarde..." />
				) : (
					statusCodeGetFechamento === 403 ? (
						<Info
							icone="erro"
							texto="Você não tem permissão para editar esse usuário."
						/>
					) : (
						statusCodeGetFechamento !== 200 ? (
							<Info
								icone="erro"
								texto="Não foi possível carregar informações do projeto."
							/>
						) : (
							<div>EDIÇÃO</div>
						)
					)
				)}
			</Portal>

			{/* Portal informações perfil */}
			<PortalPerfil
				idFreelancer={statePerfilCompletoId}
				idUsuario={statePerfilCompletoId}
				tipo={statePerfilCompletoTipo}
				show={showPortalPerfilCompleto}
				onClose={() => {
					setShowPortalPerfilCompleto(false);
				}}
			/>

			{/* Portal de exportação de PDF, XLS, e CSV */}
			<Portal
				show={showPortalExportacao}
				closeOnEsc={true}
				onClose={() => {
					setShowPortalExportacao(false);
				}}
				className="w-500"
				classNameX="x"
			>
				<h3 className="margin-t-0">Exportar fechamento</h3>
				<div className="cpnt-perfil-btn-download-pdf">
					{/* PDF */}
					{stateTipoExportacao === 3 ? (
						<div className="flex flex-column flex-center">
							<Icone
								imagem={imgIconePDF}
								cor="#ad0b00"
								tamanho="80px"
							/>
							<div className="align-center margin-t-15">
								<div>
									<div>PDF gerado com sucesso!</div>
									<div className="flex flex-center margin-t-15 color-black">
										<div>Download PDF</div>
										<Icone
											imagem={imgIconeDownload}
											cor="#ad0b00"
											tamanho="26px"
											wrap="margin-l-10"
										/>
									</div>
								</div>
							</div>
						</div>
					) : null}

					{/* XLSX */}
					{stateTipoExportacao === 1 ? (
						<div className="flex flex-column flex-center">
							<Icone
								imagem={imgIconeXLS}
								cor="#217346"
								tamanho="80px"
							/>
							<div className="align-center margin-t-15">
								<div>
									<div>XLSX gerado com sucesso!</div>
									<div className="flex flex-center margin-t-15 color-black">
										<div>Download XLSX</div>
										<Icone
											imagem={imgIconeDownload}
											cor="#217346"
											tamanho="26px"
											wrap="margin-l-10"
										/>
									</div>
								</div>
							</div>
						</div>
					) : null}

					{/* CSV */}
					{stateTipoExportacao === 2 ? (
						<div className="flex flex-column flex-center">
							<Icone
								imagem={imgIconeXLS}
								cor="#0093A1"
								tamanho="80px"
							/>
							<div className="align-center margin-t-15">
								<div>
									<div>CSV gerado com sucesso!</div>
									<div className="flex flex-center margin-t-15 color-black">
										<div>Download CSV</div>
										<Icone
											imagem={imgIconeDownload}
											cor="#0093A1"
											tamanho="26px"
											wrap="margin-l-10"
										/>
									</div>
								</div>
							</div>
						</div>
					) : null}
				</div>
			</Portal>

			{/* Portal com as informações de remessa para o cliente */}
			<Portal
				show={showPortalEnviarRemessaCliente}
				closeOnEsc={true}
				onClose={() => {
					setShowPortalEnviarRemessaCliente(false);
				}}
				className="w-700 box-txt"
				classNameX="x"
			>
				<h3>Enviar remessa de fechamento para o <span className="color-blue">cliente</span> aprovar</h3>
				<div className="flex flex-wrap wrap-5">
					<div className="input-box-alt w-100p">
						<Select2
							label="Empresa"
							_={{
								options: [
									{value: 1, label: "Crowd Agency Sistemas e Tecnologia Ltda-me"},
									{value: 136, label: "Unimark / Longo Comunicação Ltda."},
									{value: 137, label: "VML Propaganda Ltda."},
									{value: 268, label: "Troiano Consultoria de Marca Ltda"},
									{value: 608, label: "Zeps Strategy Serviços LTDA - ME"},
									{value: 2441, label: "Jumptank Serviços de Marketing e Mídia LTDA"},
									{value: 5097, label: "Sr. Jorge"},
									{value: 5098, label: "Tutoriais Experts"},
									{value: 5099, label: "COLONY"},
									{value: 5100, label: "Criaflow"},
									{value: 5101, label: "Orbyka"},
									{value: 5102, label: "Rede Lojacorr"}
								],
							}}
						/>
					</div>
					<div className="input-box-alt w-50p">
						<Input2
							label="Nome do responsável"
							_={{
								defaultValue: 'Letícia Suher',
							}}
						/>
					</div>
					<div className="input-box-alt w-50p">
						<Input2
							label="E-mail"
							_={{
								defaultValue: 'fulano@example.com',
							}}
						/>
					</div>
					<div className="input-box-alt w-100p">
						<Input2
							label="Título"
							_={{
								defaultValue: 'Revisão de Fechamento - Outubro/2021 - Sr. Jorge',
							}}
						/>
					</div>
					<div className="input-box-alt w-100p">
						<Input2
							label="Descrição"
							_={{
								type: 'textarea',
								defaultValue:
`Oi Letícia! Por gentileza, você poderia revisar os jobs deste fechamento?

O job estando certo, você pode alterar o status do job para [NOME STATUS].

Clique aqui para acessar a remessa de fechamento.

Qualquer dúvida, estou à disposição.

Obrigada.`,
							}}
						/>
					</div>
					<div className="flex flex-end margin-t-50 w-100p">
						<Button2
							_={{
								value: 'Cancelar',
								className: 'btn btn-2 margin-r-15',
								onClick: e => {
									setShowPortalEnviarRemessaCliente(false);
								},
							}}
						/>
						<Button2
							_={{
								value: 'Enviar mensagem',
								onClick: e => {
									setShowPortalConfirmacaoEnvioRemessaCliente(true);
								},
							}}
						/>
					</div>
				</div>
			</Portal>

			{/* Portal com as informações de remessa para diretoria */}
			<Portal
				show={showPortalEnviarRemessaDiretoria}
				closeOnEsc={true}
				onClose={() => {
					setShowPortalEnviarRemessaDiretoria(false);
				}}
				className="w-700 box-txt"
				classNameX="x"
			>
				<h3>Enviar remessa de fechamento para <span className="color-blue">diretoria</span> aprovar</h3>
				<div className="flex flex-wrap wrap-5">
					<div className="input-box-alt w-100p">
						<Select2
							label="Empresa"
							_={{
								options: [
									{value: 1, label: "Crowd Agency Sistemas e Tecnologia Ltda-me"},
									{value: 136, label: "Unimark / Longo Comunicação Ltda."},
									{value: 137, label: "VML Propaganda Ltda."},
									{value: 268, label: "Troiano Consultoria de Marca Ltda"},
									{value: 608, label: "Zeps Strategy Serviços LTDA - ME"},
									{value: 2441, label: "Jumptank Serviços de Marketing e Mídia LTDA"},
									{value: 5097, label: "Sr. Jorge"},
									{value: 5098, label: "Tutoriais Experts"},
									{value: 5099, label: "COLONY"},
									{value: 5100, label: "Criaflow"},
									{value: 5101, label: "Orbyka"},
									{value: 5102, label: "Rede Lojacorr"}
								],
							}}
						/>
					</div>
					<div className="input-box-alt w-100p">
						<Select2
							label="Enviar para"
							_={{
								options: [
									{ label: 'Gabriel Matias', value: 2 },
									{ label: 'Juan Zaragoza', value: 3 },
								],
								isMulti: true,
							}}
						/>
					</div>
					<div className="input-box-alt w-100p">
						<Input2
							label="Título"
							_={{
								defaultValue: 'Revisão de Fechamento - Outubro/2021 - Sr. Jorge'
							}}
						/>
					</div>
					<div className="input-box-alt w-100p">
						<Input2
							label="Observações"
							_={{
								type: 'textarea',
							}}
						/>
					</div>
					<div className="flex flex-end margin-t-50 w-100p">
						<Button2
							_={{
								value: 'Cancelar',
								className: 'btn btn-2 margin-r-15',
								onClick: e => {
									setShowPortalEnviarRemessaDiretoria(false);
								},
							}}
						/>
						<Button2
							_={{
								value: 'Enviar para aprovação',
								onClick: e => {
									setShowPortalConfirmacaoEnvioRemessaDiretoria(true);
								},
							}}
						/>
					</div>
				</div>
			</Portal>

			{/* Portal com as informações de remessa para operações revisar */}
			<Portal
				show={showPortalEnviarRemessaOperacoes}
				closeOnEsc={true}
				onClose={() => {
					setShowPortalEnviarRemessaOperacoes(false);
				}}
				className="w-700 box-txt"
				classNameX="x"
			>
				<h3>Enviar remessa de fechamento para <span className="color-blue">operações</span> revisar</h3>
				<div className="flex flex-wrap wrap-5">
					<div className="input-box-alt w-100p">
						<Select2
							label="Empresa"
							_={{
								options: [
									{value: 1, label: "Crowd Agency Sistemas e Tecnologia Ltda-me"},
									{value: 136, label: "Unimark / Longo Comunicação Ltda."},
									{value: 137, label: "VML Propaganda Ltda."},
									{value: 268, label: "Troiano Consultoria de Marca Ltda"},
									{value: 608, label: "Zeps Strategy Serviços LTDA - ME"},
									{value: 2441, label: "Jumptank Serviços de Marketing e Mídia LTDA"},
									{value: 5097, label: "Sr. Jorge"},
									{value: 5098, label: "Tutoriais Experts"},
									{value: 5099, label: "COLONY"},
									{value: 5100, label: "Criaflow"},
									{value: 5101, label: "Orbyka"},
									{value: 5102, label: "Rede Lojacorr"}
								],
							}}
						/>
					</div>
					<div className="input-box-alt w-100p">
						<Select2
							label="Enviar para"
							_={{
								options: [...LISTA_USUARIOS_CROWD.map((item, key) => {
									if(key <= 3) {
										return null;
									}
									return {
										label: item.nome,
										value: item.nome,
									}
								})].filter(item => item !== null),
								isMulti: true,
								closeMenuOnSelect: false,
								isClearable: true,
							}}
						/>
					</div>
					<div className="input-box-alt w-100p">
						<Input2
							label="Título"
							_={{
								defaultValue: 'Revisão de Fechamento - Outubro/2021 - Sr. Jorge'
							}}
						/>
					</div>
					<div className="input-box-alt w-100p">
						<Input2
							label="Observações"
							_={{
								type: 'textarea',
							}}
						/>
					</div>
					<div className="input-box-alt w-100p">
						<div className="strong">Total de jobs selecionados: {stateFechamento.filter(item => item._checked).length}</div>
						<div className="fs-14 box-default margin-t-10 padding-10">
							{!stateFechamento.filter(item => item._checked).length ? (
								<div>Nenhum job selecionado.</div>
							) : (
								joinArray(stateFechamento.filter(item => item._checked), ', ', ' e ', 'id')
							)}
						</div>
					</div>
					<div className="flex flex-end margin-t-50 w-100p">
						<Button2
							_={{
								value: 'Cancelar',
								className: 'btn btn-2 margin-r-15',
								onClick: e => {
									setShowPortalEnviarRemessaOperacoes(false);
								},
							}}
						/>
						<Button2
							_={{
								value: 'Enviar para aprovação',
								onClick: e => {
									setShowPortalConfirmacaoEnvioRemessaOperacoes(true);
								},
							}}
						/>
					</div>
				</div>
			</Portal>

			{/* Confirmação de envio - Cliente*/}
			<PortalConfirmacao
				titulo="Deseja realmente enviar?"
				desc="Será gerado uma remessa na lista do cliente onde ele poderá visualizar e fazer o acompanhamento. Também será enviado um e-mail informando esse cliente que a remessa foi gerada."
				show={showPortalConfirmacaoEnvioRemessaCliente}
				onClose={() => setShowPortalConfirmacaoEnvioRemessaCliente(false)}
				onClickCancelar={() => setShowPortalConfirmacaoEnvioRemessaCliente(false)}
				onClickConfirmar={() => {
					setShowPortalEnviarRemessaCliente(false);
					setShowPortalConfirmacaoEnvioRemessaCliente(false);
					setShowPortalEnvioRemessaEnviadoCliente(true);
				}}
				loadingConfirmar={false}
			/>

			{/* Confirmação de envio - Diretoria */}
			<PortalConfirmacao
				titulo="Deseja realmente enviar?"
				desc="Será gerado uma remessa visível apenas para a diretoria até que seja aprovada. Após a aprovação, será possível enviar essa remessa para o cliente."
				show={showPortalConfirmacaoEnvioRemessaDiretoria}
				onClose={() => setShowPortalConfirmacaoEnvioRemessaDiretoria(false)}
				onClickCancelar={() => setShowPortalConfirmacaoEnvioRemessaDiretoria(false)}
				onClickConfirmar={() => {
					setShowPortalEnviarRemessaDiretoria(false);
					setShowPortalConfirmacaoEnvioRemessaDiretoria(false);
					setShowPortalEnvioRemessaEnviadoDiretoria(true);
				}}
				loadingConfirmar={false}
			/>

			{/* Confirmação de envio - Operações */}
			<PortalConfirmacao
				titulo="Deseja realmente enviar?"
				desc="Será gerado uma remessa para a operação revisar. O cliente não será notificado até que essa remessa seja aprovada pela diretoria."
				show={showPortalConfirmacaoEnvioRemessaOperacoes}
				onClose={() => setShowPortalConfirmacaoEnvioRemessaOperacoes(false)}
				onClickCancelar={() => setShowPortalConfirmacaoEnvioRemessaOperacoes(false)}
				onClickConfirmar={() => {
					setShowPortalEnviarRemessaOperacoes(false);
					setShowPortalConfirmacaoEnvioRemessaOperacoes(false);
					setShowPortalEnvioRemessaEnviadoOperacoes(true);
				}}
				loadingConfirmar={false}
			/>

			{/* Portal de exportação de PDF, XLS, e CSV */}
			<Portal
				show={showPortalEnvioRemessaEnviadoCliente}
				closeOnEsc={true}
				onClose={() => {
					setShowPortalEnvioRemessaEnviadoCliente(false);
				}}
				className="w-500"
				classNameX="x"
			>
				<Info
					icone="sucesso"
					texto="Remessa enviada com sucesso!"
				/>
			</Portal>

			{/* Portal de envio para diretoria*/}
			<Portal
				show={showPortalEnvioRemessaEnviadoDiretoria}
				closeOnEsc={true}
				onClose={() => {
					setShowPortalEnvioRemessaEnviadoDiretoria(false);
				}}
				className="w-500"
				classNameX="x"
			>
				<Info
					icone="sucesso"
					texto="Remessa enviada com sucesso!"
				/>
			</Portal>

			{/* Portal de envio operações */}
			<Portal
				show={showPortalEnvioRemessaEnviadoOperacoes}
				closeOnEsc={true}
				onClose={() => {
					setShowPortalEnvioRemessaEnviadoOperacoes(false);
				}}
				className="w-500"
				classNameX="x"
			>
				<Info
					icone="sucesso"
					texto="Remessa enviada com sucesso!"
				/>
			</Portal>

		</div>
	);
}

export default Fechamento;