import React, { useState } from 'react';

// Componentes
import Input2 from "@cpnt/Input2/Input2";
import { Select2 } from "@cpnt/Select2/Select2";
import Tip from "@cpnt/Tip/Tip";

// css
import './Passo6.css';

import Button2 from "@cpnt/Button2/Button2";
import { ListaComCheckbox2 } from "@cpnt/ListaComCheckbox2/ListaComCheckbox2";
import { ListaSecao } from "@cpnt/ListaSecao/ListaSecao";
import Loading from "@cpnt/Loading/Loading";
import Portal from "@cpnt/Portal/Portal";
import Radio2 from "@cpnt/Radio2/Radio2";
import { senioridadeFreelancer } from "@func/utils";
import { SelecaoIdiomasNiveis } from "@cpnt/SelecaoIdiomasNiveis/SelecaoIdiomasNiveis";
import { SecaoItens } from "@cpnt/SecaoItens/SecaoItens";
import { ListaSecaoAlt } from "@cpnt/ListaSecaoAlt/ListaSecaoAlt";
import { SecaoExperiencias } from "@cpnt/SecaoExperiencias/SecaoExperiencias";
import { SecaoFormacoes } from "@cpnt/SecaoFormacoes/SecaoFormacoes";
import { SecaoCertificados } from "@cpnt/SecaoCertificados/SecaoCertificados";
import { SecaoPremios } from "@cpnt/SecaoPremios/SecaoPremios";
import { SecaoPortfolios } from "@cpnt/SecaoPortfolios/SecaoPortfolios";
import Info from "@cpnt/Info/Info";
import Info2 from "@cpnt/Info2/Info2";

const Passo6 = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		stateExperiencias = [],
		setStateExperiencias,
		loadingGetExperiencias,

		stateFormacoes = [],
		setStateFormacoes,
		loadingGetFormacoes,

		stateCertificados = [],
		setStateCertificados,
		loadingGetCertificados,

		statePremios = [],
		setStatePremios,
		loadingGetPremios,

		statePortfolios = [],
		setStatePortfolios,
		loadingGetPortfolios,

		before,
		onClickBtnSalvarExperiencias,
		onClickBtnSalvarFormacoes,
		onClickBtnSalvarCertificados,
		onClickBtnSalvarPremios,
		onClickBtnSalvarPortfolios,

		loadingBtnSalvarExperiencias,
		loadingBtnSalvarFormacoes,
		loadingBtnSalvarCertificados,
		loadingBtnSalvarPremios,
		loadingBtnSalvarPortfolios,
	} = props;

	return (
		<>
			<div className={`cpnt-passo6 ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-passo6-box ${className}`}>
					{before}

					<SecaoExperiencias
						onClickBtnSalvar={onClickBtnSalvarExperiencias}
						loadingBtnSalvar={loadingBtnSalvarExperiencias}
						stateExperiencias={stateExperiencias}
						setStateExperiencias={setStateExperiencias}
						loadingGetExperiencias={loadingGetExperiencias}
					/>

					<div className="msg-alt msg-alt3-info margin-t-25">
						<Info2
							titulo="Preenchimento opcional"
							desc={
								<>Fique tranquilo, os itens <strong>abaixo</strong> não são obrigatórios para receber propostas na plataforma.</>
							}
							icone="info"
							iconeCor="#0277BD"
						/>
					</div>

					<SecaoFormacoes
						onClickBtnSalvar={onClickBtnSalvarFormacoes}
						loadingBtnSalvar={loadingBtnSalvarFormacoes}
						stateFormacoes={stateFormacoes}
						setStateFormacoes={setStateFormacoes}
						loadingGetFormacoes={loadingGetFormacoes}
					/>

					<SecaoCertificados
						onClickBtnSalvar={onClickBtnSalvarCertificados}
						loadingBtnSalvar={loadingBtnSalvarCertificados}
						stateCertificados={stateCertificados}
						setStateCertificados={setStateCertificados}
						loadingGetCertificados={loadingGetCertificados}
					/>

					<SecaoPremios
						onClickBtnSalvar={onClickBtnSalvarPremios}
						loadingBtnSalvar={loadingBtnSalvarPremios}
						statePremios={statePremios}
						setStatePremios={setStatePremios}
						loadingGetPremios={loadingGetPremios}
					/>

					<SecaoPortfolios
						onClickBtnSalvar={onClickBtnSalvarPortfolios}
						loadingBtnSalvar={loadingBtnSalvarPortfolios}
						statePortfolios={statePortfolios}
						setStatePortfolios={setStatePortfolios}
						loadingGetPortfolios={loadingGetPortfolios}
					/>
				</div>
			</div>

		</>
	);
};

export { Passo6 };
