import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast as msg } from 'react-toastify';

// Socket

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './JobsAdminLite.css';

// Hooks
import useWindowDimension from '@/_/hooks/useWindowDimensions';

// API
import apiTasks from '@api/tasks/tasks';
import { apiGetClientes, apiGetEmpresas, apiGetOneAlgolia, apiGetSelectCardsStatus, apiGetSelectProjetos, apiGetSelectProjetosClientes, apiGetUsuarios, apiValidarAcesso } from '@apiGeral/geral';

// Imagens
import imgIconeEditar from '@svg/regular/pen.svg';
import imgIconeStatus from '@svg/regular/play-pause.svg';

// Components
import { BtnAdd } from '@cpnt/BtnAdd/BtnAdd';
import { BtnLimparFiltros } from '@cpnt/BtnLimparFiltros/BtnLimparFiltros';
import FiltrosWrap from '@cpnt/FiltrosWrap/FiltrosWrap';
import Icone from '@cpnt/Icone/Icone';
import { IconeChatWrap } from '@cpnt/IconeChatWrap/IconeChatWrap';
import { ImgDesc } from '@cpnt/ImgDesc/ImgDesc';
import ImgTituloDesc from '@cpnt/ImgTituloDesc/ImgTituloDesc';
import Input2 from '@cpnt/Input2/Input2';
import { PlaceholderRender } from '@cpnt/Placeholder/Placeholder';
import { PortalJobsAdminFormCadastro } from '@portal/PortaisEmpresas/PortalJobsAdminFormCadastro/PortalJobsAdminFormCadastro';
import { PortalJobsAdminFormEdicao } from '@portal/PortaisEmpresas/PortalJobsAdminFormEdicao/PortalJobsAdminFormEdicao';
import { PortalPerfilFreelancer } from '@portalGeral/PortalPerfil/PortalPerfilFreelancer';
import { PortalPerfilUsuario } from '@portalGeral/PortalPerfil/PortalPerfilUsuario';
import PortalProjetosDetalhes from '@portal/PortaisProjetos/PortalProjetosDetalhes/PortalProjetosDetalhes';
import { Select2 } from '@cpnt/Select2/Select2';
import Select2Algolia from '@cpnt/Select2Algolia/Select2Algolia';
import { Select3Lista } from '@cpnt/Select3/Select3';
import StatusCards from '@cpnt/StatusCards/StatusCards';
import Tip from '@cpnt/Tip/Tip';
import TotalPaginacao from '@cpnt/TotalPaginacao/TotalPaginacao';

// Partes

// Objetos
import { FILTROS_RESULTADOS } from '@config/objetos';

// Rotas
import { clearGet, closePortal, getPortalDados, gets, initFuncExec, isShowPortal, setGet, setGetNavigate, showPortal } from '@route/routeUtils';

// Funções
import { caminhoFoto, dataApiParaBr, formatarDinheiro, isNumeroDiferente, tamanhoTela } from '@func/utils';
import { statusJobsAdminLiteTratarStatus, statusJobsAdminTratarStatus } from './funcJobsAdminLite';
import { PortalJobDetalhes } from "@cpnt/Portais/PortaisProcessoSeletivo/PortalJobDetalhes/PortalJobDetalhes";
import { PortalCardDetalhes } from "@cpnt/Portais/PortaisProcessoSeletivo/PortalCardDetalhes/PortalCardDetalhes";
import AvisoAcessoRestrito from "@cpnt/AvisoAcessoRestrito/AvisoAcessoRestrito";
import { MSG_ACESSO_EM_ANALISE_FUNCIONALIDADE_DESATIVADA } from "@texto/textosGerais";
import { PortalJobsAdminLiteFormCadastro } from "@cpnt/Portais/PortaisEmpresas/PortalJobsAdminLiteFormCadastro/PortalJobsAdminLiteFormCadastro";
import { statusJobsAdminEnviarMensagemAlteracaoStatus } from "../JobsAdmin/funcJobsAdmin";
import { isNumeric } from "@_func/regex";
import { msgs, msgStatus } from "@_func/request";

import { primeiroNome } from "@_func/regex";
import { Btn } from "@_cpnt/Btn/Btn/Btn";

const JobsAdminLite = props => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	const screen = useWindowDimension();

	/*
	** Validar acesso
	** ================================================== */
	const [stateAcesso, setStateAcesso] = useState({});
	const [stateIsAcessoValido, setStateIsAcessoValido] = useState(true);

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = async (getsAtualizados = getsDefault) => {

		const validarAcesso = await apiValidarAcesso({setStateAcesso, setStateIsAcessoValido});

		if(validarAcesso.sucesso) {
			initFuncExec([
				{
					func: getCards,
					param: getsAtualizados
				},
				{
					func: apiGetOneAlgolia,
					param: {
						text: getsAtualizados.id_freelancer,
						setLoadingGetAlgolia,
						setStateAlgolia,
					},
					condicao: isNumeric(getsAtualizados.id_freelancer) ? true : false,
					init: true,
				},
				{
					func: apiGetEmpresas,
					condicao: store.usuario.role === 1,
					param: {
						setLoadingGetEmpresas,
						setStateEmpresas,
					},
					init: true,
				},
				{
					func: apiGetClientes,
					param: {
						id: getsAtualizados.id_company,
						setLoadingGetClientes,
						setStateClientes,
					},
					condicao: !init ? true : isNumeroDiferente(stateGet.id_company, getsAtualizados.id_company)
				},
				{
					func: apiGetUsuarios,
					param: {
						id: getsAtualizados.id_company,
						setLoadingGetUsuarios,
						setStateUsuarios,
					},
					condicao: !init ? true : isNumeroDiferente(stateGet.id_company, getsAtualizados.id_company)
				},
				{
					func: apiGetSelectProjetosClientes,
					param: {
						idEmpresa: getsAtualizados.id_company,
						idCliente: getsAtualizados.id_customer,
						setLoadingGetProjetos,
						setStateProjetos,
					},
					condicao: !init ? true : (isNumeroDiferente(stateGet.id_company, getsAtualizados.id_company) || isNumeroDiferente(stateGet.id_customer, getsAtualizados.id_customer))
				},
				{
					func: apiGetSelectCardsStatus,
					param: {
						setLoadingGetStatusCards,
						setStateStatusCards,
					},
					condicao: !init ? true : isNumeroDiferente(stateGet.id_company, getsAtualizados.id_company)
				},
			], init, location, stateLocation);
		}
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		overlay: [],
		limit: 20,
		page: 1,

		id_company: store.usuario.id_company,
		title: '',
		id_customer: null,
		status: null,
		id_project: null,
		id_user: null,
		id_freelancer: null,
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
		init: init,
		defaults: {
			id_user: store.usuario.role === 3 ? store.usuario.id : null,
		}
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	/*
	** Loading
	** ================================================== */
	const [loadingGetCards, setLoadingGetCards] = useState(false);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);
	const [loadingGetClientes, setLoadingGetClientes] = useState(false);
	const [loadingGetUsuarios, setLoadingGetUsuarios] = useState(false);
	const [loadingGetProjetos, setLoadingGetProjetos] = useState(false);
	const [loadingGetStatusCards, setLoadingGetStatusCards] = useState(false);
	const [loadingGetAlgolia, setLoadingGetAlgolia] = useState(false);
	const [loadingAlterarStatus, setLoadingAlterarStatus] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateCards, setStateCards] = useState([]);
	const [_stateCards, _setStateCards] = useState({});
	const [stateCardsMetadata, setStateCardsMetadata] = useState({});
	const [stateEmpresas, setStateEmpresas] = useState([]);
	const [stateClientes, setStateClientes] = useState([]);
	const [stateUsuarios, setStateUsuarios] = useState([]);
	const [stateProjetos, setStateProjetos] = useState([]);
	const [stateStatusCards, setStateStatusCards] = useState([]);
	const [stateAlgolia, setStateAlgolia] = useState([]);

	/*
	** API - Projetos Filtro
	** ================================================== */
	const getCards = async (dadosObj = {}) => {
		if(loadingGetCards) {
			return;
		}
		setLoadingGetCards(true);

		var dadosEnviar = clearGet(dadosObj);
		const retGetCards = await apiTasks.all.get(dadosEnviar);

		_setStateCards(msgStatus(retGetCards));

		if(retGetCards.status === 200) {
			setStateCards(retGetCards.data);
			setStateCardsMetadata(retGetCards.metadata);
		} else {
			setStateCards([]);
			setStateCardsMetadata({});
		}

		setLoadingGetCards(false);
	}

	/*
	** API - Alterar status da tarefa
	** ================================================== */
	const alterarStatusJob = async dadosObj => {
		if(loadingAlterarStatus === dadosObj.idStatus) {
			msg.warning('Por favor, aguarde...');
			return;
		}

		if(dadosObj.idStatus === dadosObj.idStatusAntigo) {
			return;
		}

		const dadosEnviar = {
			id: dadosObj.idJob,
			status: dadosObj.idStatus,
			statusAntigo: dadosObj.idStatusAntigo,
			price: dadosObj.price,
		};

		if(!dadosObj.price && dadosObj.idStatus === 15) {
			msg.warning('Antes de aprovar o job, é necessário que o profissional remoto freelancer envie uma proposta no chat e essa proposta seja aceita por você.');
			return;
		} else if(!dadosObj.price && dadosObj.idStatus === 14) {
			msg.warning('Antes de iniciar a execução do job, é necessário que o profissional remoto freelancer envie uma proposta no chat e essa proposta seja aceita por você.');
			return;
		}

		setLoadingAlterarStatus(dadosObj.idJob);

		const retUpdateStatus = await apiTasks.update.put(dadosEnviar);

		if(retUpdateStatus.status === 200) {
			statusJobsAdminEnviarMensagemAlteracaoStatus({
				idJob: dadosObj.idJob,
				idStatus: dadosObj.idStatus,
				idFreelancer: dadosObj.idFreelancer,
				statusLabel: dadosObj.statusLabel,
				idUser: store.usuario.id,
			});
			setStateCards([...stateCards.map(item => {
				if(item.id === dadosObj.idJob) {
					return {
						...item,
						status: dadosObj.idStatus,
					}
				}
				return item;
			})])
		} else {
			msgs([retUpdateStatus]);
		}
		setLoadingAlterarStatus(false);
	}

	/*
	** Nomes dos status abreviados
	** ================================================== */
	const nomeStatusJobsAdminExibicao = status => {
		var _status = '(desconhecido)';
		switch (status) {
			case 'Em orçamento': _status = 'Orçamento'; break;
			case 'Em execução': _status = 'Execução'; break;
			default: _status = status; break;
		}
		return _status;
	}

	/*
	** Variaveis de acordo com o tamanho da tela
	** ================================================== */
	var winQtdFotos = tamanhoTela([
		{width: 1580, dados: 2},
		{width: 1320, dados: 1},
		{width: 1280, dados: 1},
		{width: 980, dados: 10},
		{width: 680, dados: 4},
	], 3, screen);

	var winQtdSolucoes = tamanhoTela([
		{width: 1320, dados: 1},
		{width: 1280, dados: 2},
		{width: 980, dados: 10},
		{width: 680, dados: 4},
	], 2, screen);

	var winPosTooltipDireito = tamanhoTela([
		{width: 980, dados: {placement: 'bottom-center'}},
	], {placement: 'right-center'}, screen);

	var winPosTooltipEsquerdo = tamanhoTela([
		{width: 980, dados: {placement: 'bottom-center'}},
	], {placement: 'left-center'}, screen);

	return (
		<>
			<div className="pg-jobsadmin base-full">
				<div className="conteudo-box">
					<div className="titulo-box titulo-box-res">
						<div className="flex flex-column">
							<h3 className="titulo">Contratações</h3>
						</div>
						<Btn
							_={{
								value: 'Fazer uma contratação',
								onClick: () => {
									if(stateIsAcessoValido) {
										showPortal({portal: 'cadastro', stateGet, navigate})
									} else {
										msg.warning(MSG_ACESSO_EM_ANALISE_FUNCIONALIDADE_DESATIVADA)
									}
								}
							}}
							size="m"
						/>
					</div>

					<FiltrosWrap>
						<form
							onSubmit={e => e.preventDefault()}
							className="flex flex-wrap wrap-5"
						>
							<div className="input-box w-25p box-2-980 box-1-680">
								<Input2
									label="Pesquisar contratações"
									_={{
										value: stateGet.title,
										onChange: e => {
											setGetNavigate({
												gets: {title: e.target.value},
												navigate,
												stateGet,
												location,
											});
										}
									}}
								/>
							</div>
							<div className="input-box w-25p relative box-2-980 box-1-680">
								<Select2
									label="Status"
									_={{
										value: stateGet.status,
										onChange: e => {
											setGetNavigate({
												gets: {status: e ? e.value : null},
												navigate,
												stateGet,
												location,
											});
										},
										options: stateStatusCards,
										isClearable: true,
									}}
									loading={loadingGetStatusCards}
								/>
							</div>
							<div className="w-25p input-box relative box-2-980 box-1-680">
								<Select2Algolia
									label="Profissional"
									setStateOptions={setStateAlgolia}
									_={{
										value: stateGet.id_freelancer,
										onChange: e => {
											setGetNavigate({
												gets: {id_freelancer: e ? e.value : null},
												navigate,
												stateGet,
												location,
											});
										},
										defaultOptions: stateAlgolia,
										options: stateAlgolia,
										isClearable: true,
									}}
									loading={loadingGetAlgolia}
									desativarRequest={!stateIsAcessoValido}
								/>
							</div>
							<div className="input-box input-box-btnlimpar w-25p box-2-980 box-1-680">
								<Select2
									label="Resultados por página"
									_={{
										value: stateGet.limit,
										onChange: e => {
											setGetNavigate({
												gets: {limit: e.value},
												navigate,
												stateGet,
												location,
											});
										},
										options: FILTROS_RESULTADOS,
									}}
								/>
								<BtnLimparFiltros
									_={{
										onClick: e => {
											setGetNavigate({
												gets: getsDefault,
												navigate,
												stateGet,
												location,
											});
										},
									}}
								/>
							</div>
						</form>
					</FiltrosWrap>

					{!stateIsAcessoValido && !loadingGetCards ? (
						<AvisoAcessoRestrito {...stateAcesso} />
					) : (
						<div id="tabela-container">
							<TotalPaginacao {...{stateGet, stateMetadata: stateCardsMetadata, loading: loadingGetCards, getsDefault}} />
							<div className="table-wrap">
								<table className="tabela">
									<thead className="tabela-titulo-fixo">
										<tr>
											<th className="col-id">
												<Tip info tip="ID">Identificador único do job.</Tip>
											</th>
											<th className="col-min-3">
												<Tip info tip="Resp.">Profissional da sua empresa responsável por contratar e conduzir o job.</Tip>
											</th>
											<th className="col-min-2">
												<Tip info tip="Chat">Conversar com o profissional</Tip>
											</th>
											<th className="">
												<Tip info tip="Job">Título do job na plataforma.</Tip>
											</th>
											<th className="col-min-3">
												<Tip info tip="Prof.">Empresa freelancer contratada para a prestação do serviço.</Tip>
											</th>
											{store.usuario.role === 1 ? (
												<th className="col-valor">
													<Tip info tip="Valor liq.">Valor que a empresa freelancer vai receber após a prestação do serviço.</Tip>
												</th>
											) : null}
											<th className="col-valor">
												<Tip info tip={
													<>
														Valor do serviço
														<span className="i q-white"></span>
													</>
												}>Valor total do serviço contratado. Neste valor está incluso o "Preço do Serviço do Freela" + a "Taxa de Contratação Segura Crowd".</Tip>
											</th>
											<th className="col-data">
												<Tip info tip="Criado em">Data que foi criado.</Tip>
											</th>
											<th className="col-data">
												<Tip info tip="Data aprov.">Data em que o job foi aprovado pela empresa contratante.</Tip>
											</th>
											<th className="col-min-5">
												<Tip info tip="Status">Etapa em que o job se encontra</Tip>
											</th>
											<th className="col-acao">
												<Tip info tip="Ação">Funcionalidade para atualizar o status do job e, se necessário, ajustar o nome do job.</Tip>
											</th>
										</tr>
									</thead>

									<tbody>
										<PlaceholderRender
											loading={loadingGetCards}
											init={init}
											itens={stateCards}
											request={_stateCards}
											cpnt="PlaceholderJobsAdminLite"
											qtdColunas={10}
											itensQtd={{
												foto: winQtdFotos,
												solucoes: winQtdSolucoes,
											}}
										>
											{stateCards.map((val, key) => {
												var link = `/contratacoes/negociacao?id_task=${val.id}&id_freelancer=${val.id_freelancer}${val.id_briefing ? `&id_briefing=${val.id_briefing}` : ''}`;
												return (
													<tr key={key}>

														<td data-titulo="ID" className="col-id">
															<div className="td-wrap">
																{val.id}
															</div>
														</td>

														<td data-titulo="Recrutador">
															<div className="td-wrap align-center">
																<ImgDesc
																	wrap="a td-info"
																	titulo={primeiroNome(val.user_name)}
																	img={caminhoFoto(val.user_photo).url}
																	_tipDefaults={winPosTooltipDireito}
																	onClick={() => showPortal({portal: 'perfil-usuario', dados: val.id_user, stateGet, navigate})}
																/>
															</div>
														</td>

														<td data-titulo="Chat">
															<div className="td-wrap scroll-visible align-center">
																<IconeChatWrap
																	qtdMensagens={val.countMessages}
																	link={link}
																	linkState={stateGet}
																	_tipDefaults={winPosTooltipDireito}
																/>
															</div>
														</td>

														<td data-titulo="Título">
															<div className="td-wrap">
																<ImgTituloDesc
																	wrap="a td-info"
																	titulo={val.title || '(Job sem título)'}
																	desc={val.company_name}
																	classNameTitulo="td-titulo"
																	classNameDesc="td-desc"
																	responsivo={true}
																	onClick={() => showPortal({portal: 'detalhes', dados: val.id, stateGet, navigate})}
																/>
															</div>
														</td>

														<td data-titulo="Freelancer">
															<div className="td-wrap align-center">
																<ImgDesc
																	wrap="a td-info"
																	titulo={primeiroNome(val.freelancer_name)}
																	img={caminhoFoto(val.freelancer_photo).url}
																	_tipDefaults={winPosTooltipDireito}
																	onClick={() => showPortal({portal: 'perfil-freelancer', dados: val.id_freelancer, stateGet, navigate})}
																/>
															</div>
														</td>

														{store.usuario.role === 1 ? (
															<td data-titulo="Valor líquido">
																<div className="td-wrap align-center">
																	{val.liquid_price ? formatarDinheiro(val.liquid_price, 'R$ ') : '-'}
																</div>
															</td>
														) : null}

														<td data-titulo="Valor do serviço">
															<div className="td-wrap align-center">
																{val.price ? formatarDinheiro(val.price, 'R$ ') : '-'}
															</div>
														</td>

														<td data-titulo="Criado em">
															<div className="td-wrap align-center">
																{dataApiParaBr(val.created_at, '-')}
															</div>
														</td>

														<td data-titulo="Data aprovação">
															<div className="td-wrap align-center">
																{dataApiParaBr(val.approval_at, '-')}
															</div>
														</td>

														<td data-titulo="Status">
															<div className="td-wrap scroll-visible align-center">
																<Tip
																	tip={
																		<div className="a">
																			<StatusCards
																				status={val.status}
																				className="cursor-pointer"
																			/>
																			<div className="td-desc-alt">{nomeStatusJobsAdminExibicao(val.status_name)}</div>
																		</div>
																	}
																	click={true}
																	show={loadingAlterarStatus === val.id ? true : false}
																	_defaults={winPosTooltipEsquerdo}
																>
																	<Select3Lista
																		titulo={<h3 className="margin-0">Status do job</h3>}
																		options={statusJobsAdminLiteTratarStatus(stateStatusCards, val.status, store.usuario.role)}
																		optionsType="radio"
																		optionsChecked={{
																			value: val.status,
																		}}
																		onClick={e => {
																			alterarStatusJob({
																				idJob: val.id,
																				idStatus: e.value,
																				statusLabel: e.label,
																				idFreelancer: val.id_freelancer,
																				price: val.price,
																				idStatusAntigo: val.status,
																			});
																		}}
																		loading={loadingAlterarStatus === val.id ? true : false}
																		labelClass="flex-1"
																		posicao=""
																		classNameDefault=""
																	/>
																</Tip>
															</div>
														</td>

														<td data-titulo="Ação">
															<div className="td-wrap align-center">
																<Icone
																	imagem={imgIconeEditar}
																	cor="var(--color-1)"
																	className={`margin-auto cursor-pointer`}
																	onClick={() => showPortal({portal: 'edicao', dados: val.id, stateGet, navigate})}
																/>
															</div>
														</td>

													</tr>
												)
											})}
										</PlaceholderRender>
									</tbody>
								</table>
							</div>
							<TotalPaginacao {...{stateGet, stateMetadata: stateCardsMetadata, loading: loadingGetCards, getsDefault}} />
						</div>
					)}
				</div>
			</div>

			{stateIsAcessoValido ? (
				<>
					<PortalPerfilUsuario
						id={getPortalDados({portal: 'perfil-usuario', stateGet})}
						show={isShowPortal({portal: 'perfil-usuario', stateGet})}
						onClose={() => closePortal({portal: 'perfil-usuario', stateGet, navigate})}
					/>

					<PortalPerfilFreelancer
						id={getPortalDados({portal: 'perfil-freelancer', stateGet})}
						show={isShowPortal({portal: 'perfil-freelancer', stateGet})}
						onClose={() => closePortal({portal: 'perfil-freelancer', stateGet, navigate})}
					/>

					<PortalJobsAdminLiteFormCadastro
						show={isShowPortal({portal: 'cadastro', stateGet})}
						id={getPortalDados({portal: 'cadastro', stateGet})}
						onClose={() => closePortal({portal: 'cadastro', stateGet, navigate})}
						onSuccess={() => {
							closePortal({portal: 'cadastro', stateGet, navigate});
							getCards(gets());
						}}
						closeOnEsc={false}
					/>

					<PortalJobsAdminFormEdicao
						show={isShowPortal({portal: 'edicao', stateGet})}
						id={getPortalDados({portal: 'edicao', stateGet})}
						onClose={() => closePortal({portal: 'edicao', stateGet, navigate})}
						onSuccess={() => {
							closePortal({portal: 'edicao', stateGet, navigate});
							getCards(gets());
						}}
						closeOnEsc={false}
					/>

					<PortalCardDetalhes
						show={isShowPortal({portal: 'detalhes', stateGet})}
						id={getPortalDados({portal: 'detalhes', stateGet})}
						onClose={() => closePortal({portal: 'detalhes', stateGet, navigate})}
					/>

				</>
			) : null}
		</>
	);
}

export default JobsAdminLite;
