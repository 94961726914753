import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import 'react-datepicker/dist/react-datepicker.css';
import './Jobs.css';

// API
import apiBriefingFreelancers from '@api/briefingFreelancers/briefingFreelancers';

// Components
import Disponibilidade from '@cpnt/Disponibilidade/Disponibilidade';
import Input2 from '@cpnt/Input2/Input2';
import ItemFotoDesc from '@cpnt/ItemFotoDesc/ItemFotoDesc';
import Portal from '@cpnt/Portal/Portal';
import Posts from '@cpnt/Posts/Posts';
import { Select2 } from '@cpnt/Select2/Select2';
import StatusBriefing from '@cpnt/StatusBriefing/StatusBriefing';
import PortalPerfil from '@portalGeral/PortalPerfil/PortalPerfil';

// Imagens

// Funções
import useWindowDimension from "@/_/hooks/useWindowDimensions";
import { msgs, msgStatus } from "@_func/request";
import { FILTROS_RESULTADOS } from "@config/objetos";
import { BtnLimparFiltros } from "@cpnt/BtnLimparFiltros/BtnLimparFiltros";
import FiltrosWrap from "@cpnt/FiltrosWrap/FiltrosWrap";
import { IconeChatWrap } from "@cpnt/IconeChatWrap/IconeChatWrap";
import ImgTituloDesc from "@cpnt/ImgTituloDesc/ImgTituloDesc";
import { PlaceholderRender } from "@cpnt/Placeholder/Placeholder";
import { PortalJobDetalhes } from "@cpnt/Portais/PortaisProcessoSeletivo/PortalJobDetalhes/PortalJobDetalhes";
import Tip from "@cpnt/Tip/Tip";
import TotalPaginacao from "@cpnt/TotalPaginacao/TotalPaginacao";
import { caminhoFoto, dataApiParaBr, htmlToJsx, tamanhoTela, validarDataAPI } from '@func/utils';
import { clearGet, closePortal, getPortalDados, initFuncExec, isShowPortal, setGet, setGetNavigate, showPortal } from "@route/routeUtils";

import { primeiroNome } from "@_func/regex";

const Jobs = props => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	const screen = useWindowDimension();

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = (getsAtualizados = getsDefault) => {
		initFuncExec([
			{
				func: getJobs,
				param: getsAtualizados
			},
			{
				func: getCombos,
				init: true,
			},
		], init, location, stateLocation);
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		overlay: [],
		limit: 20,
		page: 1,

		title_briefing: '',
		id_company: null,
		hired: null,
		id_user: null,
		id_project: null,
		id_status: null,
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);


	/*
	** Loading
	** ================================================== */
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);
	const [loadingGetUsuarios, setLoadingGetUsuarios] = useState(false);
	const [loadingGetProjetos, setLoadingGetProjetos] = useState(false);
	const [loadingGetStatus, setLoadingGetStatus] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateJobs, setStateJobs] = useState([]);
	const [_stateJobs, _setStateJobs] = useState({});
	const [stateJobsMetadata, setStateJobsMetadata] = useState({});

	const [stateEmpresas, setStateEmpresas] = useState([]);
	const [stateUsuarios, setStateUsuarios] = useState([]);
	const [stateProjetos, setStateProjetos] = useState([]);
	const [stateStatus, setStateStatus] = useState([]);

	/*
	** Portais
	** ================================================== */
	const [showPortalInformacoesRecrutador, setShowPortalInformacoesRecrutador] = useState(false);
	const [showPortalInformacoesBriefing, setShowPortalInformacoesBriefing] = useState(false);
	const [showPortalInformacoesRespostas, setShowPortalInformacoesRespostas] = useState(false);
	const [showPortalInformacoesTextoBriefing, setShowPortalInformacoesTextoBriefing] = useState(false);

	/*
	** Loading
	** ================================================== */
	const [loadingGetJobs, setLoadingGetJobs] = useState(false);
	const [loadingGetBriefingVisualizacao, setLoadingGetBriefingVisualizacao] = useState(false);
	const [loadingGetCombos, setLoadingGetCombos] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateBriefings, setStateBriefings] = useState([]);
	const [stateBriefingsMetadata, setStateBriefingsMetadata] = useState({});
	const [stateCombos, setStateCombos] = useState({
		companies: [],
		projects: [],
		responsibles: [],
		status: [],
	});
	const [possuiJobs, setPossuiJobs] = useState(true);

	/*
	** Filtros
	** ================================================== */
	const [stateEmpresaJobselecionadaFiltro, setStateEmpresaJobselecionadaFiltro] = useState(false);
	const [stateUsuarioSelecionadoFiltro, setStateUsuarioSelecionadoFiltro] = useState(false);
	const [stateStatusSelecionadoFiltro, setStateStatusSelecionadoFiltro] = useState(false);
	const [stateProjetoSelecionadoFiltro, setStateProjetoSelecionadoFiltro] = useState(false);
	const [stateContratadoFiltro, setStateContratadoFiltro] = useState(false);

	/*
	** Informações portais
	** ================================================== */
	const [stateBriefingVisualizacao, setStateBriefingVisualizacao] = useState({});
	const [stateBriefingObjetivosVisualizacao, setStateBriefingObjetivosVisualizacao] = useState([]);
	const [stateBriefingSegmentosVisualizacao, setStateBriefingSegmentosVisualizacao] = useState([]);
	const [stateBriefingIdiomasVisualizacao, setStateBriefingIdiomasVisualizacao] = useState([]);
	const [stateBriefingCompetenciasVisualizacao, setStateBriefingCompetenciasVisualizacao] = useState([]);
	const [stateBriefingHabilidadesVisualizacao, setStateBriefingHabilidadesVisualizacao] = useState([]);
	const [stateInformacoesTextoBriefing, setStateInformacoesTextoBriefing] = useState('');

	const [statePerfilCompletoId, setStatePerfilCompletoId] = useState(false);
	const [showPortalPerfilCompleto, setShowPortalPerfilCompleto] = useState(false);

	/*
	** Verifica se é para exibir os posts
	** Apenas para testes
	** ================================================== */
	useEffect(() => {
		if(typeof(stateGet.posts) === 'string') {
			setPossuiJobs(false);
			setLoadingGetJobs(false);
		}
	}, [stateGet.posts]);

	/*
	** API - Jobs
	** ================================================== */
	const getJobs = async (dadosObj = {}) => {
		if(loadingGetJobs) {
			return;
		}
		setLoadingGetJobs(true);

		var dadosEnviar = clearGet(dadosObj);
		const retGetJobs = await apiBriefingFreelancers.all.get(dadosEnviar);

		_setStateJobs(msgStatus(retGetJobs));

		if(retGetJobs.status === 200) {
			setStateJobs(retGetJobs.data);
			setStateJobsMetadata(retGetJobs.metadata);
		} else {
			setStateJobs([]);
			setStateJobsMetadata({});
		}

		setLoadingGetJobs(false);
	}

	/*
	** API - Combos
	** ================================================== */
	const getCombos = async () => {
		setLoadingGetCombos(true);
		const retGetCombos = await apiBriefingFreelancers.jobsCombos.get(store.usuario.id_freelancer);

		if(retGetCombos.status === 200) {
			var combos = Object.keys(retGetCombos.data);

			var combosFinal = {
				companies: [],
				projects: [],
				responsibles: [],
				status: [],
			};

			var temJobs = false;

			combos.map((val, key) => {
				retGetCombos.data[val].length && retGetCombos.data[val].map((val2, key2) => {
					combosFinal[val].push({
						value: val2.id,
						label: val2.name,
					});
					if(val !== 'status') {
						temJobs = true;
					}
				});
			});

			setPossuiJobs(temJobs);
			setStateCombos(combosFinal);
		} else {
			setStateCombos({
				companies: [],
				projects: [],
				responsibles: [],
				status: [],
			});
			msgs([retGetCombos]);
		}
		setLoadingGetCombos(false);
	}

	var winPosTooltipDireito = tamanhoTela([
		{width: 980, dados: {placement: 'bottom-center'}},
	], {placement: 'right-center'}, screen);

	return (
		<div className="pg-jobs base-full">
			<div className="conteudo-box">
				<div className="titulo-box titulo-box-res">
					<div className="flex flex-column">
						<h3 className="titulo">Propostas de Jobs</h3>
						<div className="td-desc-alt">Aqui você confere as propostas que as empresas enviarem para você para prestação de serviços de marketing, comunicação ou tecnologia.</div>
					</div>
				</div>

				<FiltrosWrap>
					<form
						onSubmit={e => e.preventDefault()}
						className="flex flex-wrap wrap-5 relative"
					>

						{!possuiJobs ? (
							<div className="fill-center z-index-10 bg-7-opct">
								<div className="align-center fs-16 box-txt">
									<p>Mantenha seu perfil completo e atualizado para receber propostas de job.</p>
									<p>Aqui, são as empresas contratantes que buscam prestadores de serviços freelancer. Assim, você recebe apenas propostas alinhadas ao seu perfil, expertise e segmento de atuação.</p>
									<p>Os filtros serão liberados após você receber a primeira proposta de job.</p>
								</div>
							</div>
						) : null}

						<div className="input-box w-50p box-2-980 box-1-680">
							<Input2
								label="Pesquisar proposta"
								_={{
									value: stateGet.title_briefing,
									onChange: e => {
										setGetNavigate({
											gets: {title_briefing: e.target.value},
											navigate,
											stateGet,
											location,
										});
									}
								}}
							/>
						</div>
						<div className="input-box w-25p box-2-980 box-1-680">
							<Select2
								label="Empresa"
								_={{
									value: stateGet.id_company,
									options: stateCombos.companies,
									onChange: e => {
										setGetNavigate({
											gets: {id_company: e ? e.value : null},
											navigate,
											stateGet,
											location,
										});
									},
									isClearable: true,
								}}
								loading={loadingGetCombos}
							/>
						</div>
						<div className="input-box w-25p box-2-980 box-1-680">
							<Select2
								label="Contratado"
								_={{
									value: stateGet.hired,
									options: [
										{label: 'Sim', value: 1},
										{label: 'Não', value: 0},
										{label: 'Em contratação', value: 2},
									],
									onChange: e => {
										setGetNavigate({
											gets: {hired: e ? e.value : null},
											forceCamposVazios: {hired: e ? e.value : null},
											navigate,
											stateGet,
											location,
										});
									},
									isClearable: true,
								}}
							/>
						</div>
						<div className="input-box w-25p box-2-980 box-1-680">
							<Select2
								label="Responsável"
								_={{
									value: stateGet.id_user,
									options: stateCombos.responsibles,
									onChange: e => {
										setGetNavigate({
											gets: {id_user: e ? e.value : null},
											navigate,
											stateGet,
											location,
										});
									},
									isClearable: true,
								}}
								loading={loadingGetCombos}
							/>
						</div>
						<div className="input-box w-25p box-2-980 box-1-680">
							<Select2
								label="Projeto"
								_={{
									value: stateGet.id_project,
									options: stateCombos.projects,
									onChange: e => {
										setGetNavigate({
											gets: {id_project: e ? e.value : null},
											navigate,
											stateGet,
											location,
										});
									},
									isClearable: true,
								}}
								loading={loadingGetCombos}
							/>
						</div>
						<div className="input-box w-25p box-2-980 box-1-680">
							<Select2
								label="Status"
								_={{
									value: stateGet.id_status,
									options: stateCombos.status,
									onChange: e => {
										setGetNavigate({
											gets: {id_status: e ? e.value : null},
											navigate,
											stateGet,
											location,
										});
									},
									isClearable: true,
								}}
								loading={loadingGetCombos}
							/>
						</div>
						<div className="input-box flex w-25p box-1-980 input-box-btnlimpar">
							<Select2
								label="Resultados por página"
								_={{
									value: stateGet.limit,
									onChange: e => {
										setGetNavigate({
											gets: {limit: e.value},
											navigate,
											stateGet,
											location,
										});
									},
									options: FILTROS_RESULTADOS,
								}}
								wrap="flex-1"
							/>
							<BtnLimparFiltros
								_={{
									onClick: e => {
										setGetNavigate({
											gets: getsDefault,
											navigate,
											stateGet,
											location,
										});
									},
								}}
							/>
						</div>
					</form>
				</FiltrosWrap>

				{!possuiJobs ? (
					<Posts
						wrap="padding-15"
						before={
							<div className="padding-b-20">
								<h3 className="margin-0">Você ainda não possui nenhuma proposta de job</h3>
								<div>Quer saber como conseguir jobs? Veja as dicas que postamos em nosso blog nos links a seguir</div>
							</div>
						}
					/>
				) : (
					<div id="tabela-container">
						<TotalPaginacao {...{stateGet, stateMetadata: stateJobsMetadata, loading: loadingGetJobs, getsDefault}} />
						<div className="table-wrap">
							<table className="tabela">
								<thead>
									<tr>
										<th className="col-id">
											<Tip tip="ID">ID único da empresa</Tip>
										</th>
										<th className="col-min-3">
											<Tip tip="Hunter">Hunter responsável por esse job</Tip>
										</th>
										<th className="col-min-3">
											<Tip tip="Chat">Chat para negociar ou conversar sobre esse job</Tip>
										</th>
										<th>
											<Tip tip="Proposta">Proposta de job</Tip>
										</th>
										<th>
											<Tip tip="Projeto">Projeto atrelado a esse job</Tip>
										</th>
										<th className="col-area">
											<Tip tip="Área">Área que foi publicado essa proposta</Tip>
										</th>
										<th className="col-data">
											<Tip tip="Data receb.">Data em que o job foi recebido</Tip>
										</th>
										<th className="col-min-5">
											<Tip tip="Contratado">Se você foi ou não contratado para esse job</Tip>
										</th>
										<th className="tbl-jobs-col-status">
											<Tip tip="Status">Status do job</Tip>
										</th>
									</tr>
								</thead>
								<tbody>
									<PlaceholderRender
										loading={loadingGetJobs}
										init={init}
										itens={stateJobs}
										request={_stateJobs}
										cpnt="PlaceholderJobs"
										qtdColunas={9}
									>
										{stateJobs.map((val, key) => {
											return (
												<tr key={key}>

													<td data-titulo="ID" className="fs-12 bold align-center">
														<div className="td-wrap">{val.id}</div>
													</td>

													<td data-titulo="Hunter" className="align-center">
														<div className="td-wrap">
															<ItemFotoDesc
																foto={caminhoFoto(val.photo_hunter).url}
																className="margin-auto cursor-pointer"
																onClick={() => {
																	setStatePerfilCompletoId(val.id_hunter);
																	setShowPortalPerfilCompleto(true);
																}}
															/>
															<div className="text-one-line fs-12">{primeiroNome(val.name_hunter)}</div>
														</div>
													</td>

													<td data-titulo="Chat" className="">
														<div className="td-wrap scroll-visible align-center">
															<IconeChatWrap
																qtdMensagens={val.count_messages}
																link={`/jobs/detalhes?id_briefing=${val.id}`}
																linkState={stateGet}
																_tipDefaults={winPosTooltipDireito}
															/>
														</div>
													</td>

													<td data-titulo="Proposta">
														<div className="td-wrap">
															<ImgTituloDesc
																wrap="a td-info"
																titulo={val.title_briefing || '(proposta sem título)'}
																desc={val.name_company}
																classNameTitulo="td-titulo"
																classNameDesc="td-desc"
																responsivo={true}
																onClick={() => showPortal({portal: 'detalhes', dados: val.id, stateGet, navigate})}
															/>
														</div>
													</td>

													<td data-titulo="Projeto">
														<div className="td-wrap">
															{val.name_project || '-'}
														</div>
													</td>

													<td data-titulo="Área" className="align-center">
														<div className="td-wrap">
															{val.area || '-'}
														</div>
													</td>

													<td data-titulo="Data" className="align-center">
														<div className="td-wrap">{validarDataAPI(val.received_at) ? dataApiParaBr(val.received_at) : '-'}</div>
													</td>

													<td data-titulo="Contratado" className="align-center">
														<div className="td-wrap">
															<div className="flex flex-center">
																<Disponibilidade
																	status={val.hired ? true : (val.hired === 0 ? false : 3)}
																	textoDisponivel="Sim"
																	textoIndisponivel="Não"
																	textoOutro="N/A"
																	exibirTextoOutro
																	min
																/>
															</div>
														</div>
													</td>

													<td data-titulo="Status" className="align-center">
														<div className="td-wrap">
															{val.id_status === 6 && val.hired === 0 ? (
																<div>
																	<StatusBriefing
																		status={1}
																	/>
																	<div className="fs-12">Não contratado</div>
																</div>
															) : (
																<div>
																	<StatusBriefing
																		status={val.id_status}
																	/>
																	<div className="fs-12">{val.name_status}</div>
																</div>
															)}

														</div>
													</td>

												</tr>
											)
										})}
									</PlaceholderRender>

								</tbody>
							</table>
						</div>
						<TotalPaginacao {...{stateGet, stateMetadata: stateJobsMetadata, loading: loadingGetJobs, getsDefault}} />
					</div>
				)}

			</div>

			<PortalJobDetalhes
				show={isShowPortal({portal: 'detalhes', stateGet})}
				id={getPortalDados({portal: 'detalhes', stateGet})}
				onClose={() => closePortal({portal: 'detalhes', stateGet, navigate})}
			/>

			{/* Portal Texto do briefing */}
			<Portal
				show={showPortalInformacoesTextoBriefing}
				closeOnEsc={false}
				onClose={() => {
					setShowPortalInformacoesTextoBriefing(false)
				}}
				className="box-txt w-800"
			>
				{htmlToJsx(typeof(stateInformacoesTextoBriefing) === 'string' ? stateInformacoesTextoBriefing : '<h3>Briefing da proposta de job</h3><div>Nenhum briefing adicionado a essa proposta de job.</div>')}
			</Portal>

			{/* Portal informações hunter */}
			<PortalPerfil
				idUsuario={statePerfilCompletoId}
				show={showPortalPerfilCompleto}
				tipo="usuario"
				onClose={() => {
					setShowPortalPerfilCompleto(false);
				}}
			/>

		</div>
	);
}

export default Jobs;
