import React, { useEffect, useState } from 'react';

// css
import './PortalClientesDetalhesAnexos.css';

// API
import { api } from "@_api/api";

// Componentes
import Portal from '@cpnt/Portal/Portal';
import Loading from '@cpnt/Loading/Loading';
import Info from '@cpnt/Info/Info';

// Funções

import { DetalhesCliente } from '@page/Logado/Clientes/DetalhesCliente/DetalhesCliente';
import Button2 from '@cpnt/Button2/Button2';
import { DetalhesClienteAnexos } from '@page/Logado/Clientes/DetalhesClienteAnexos/DetalhesClienteAnexos';

import { msgs, msgStatus } from "@_func/request";

const PortalClientesDetalhesAnexos = props => {

	const {
		id,
		show,
		onClose,
		closeOnEsc = false,
		portalClassName = 'portal-clientes-detalhes-anexos',
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetAnexos, setLoadingGetAnexos] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({});
	const [_stateDados, _setStateDados] = useState({});
	const [stateAnexos, setStateAnexos] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		} else {
			// Tempo da animação de fechar, limpa os dados
			setTimeout(() => {
				setStateDados(false);
			}, 500);
		}
	}

	/*
	** INIT
	** ================================================== */
	const inicializarGets = async () => {
		if(show === true) {
			const retGetCliente = await api.customers.getOne.get(id);
			if(retGetCliente.status === 200) {
				setStateDados(retGetCliente.data);
				_setStateDados({
					status: 200,
				});

				getAnexos(id);
			} else {
				_setStateDados(msgStatus(retGetCliente));
				msgs([retGetCliente]);
			}

			setLoading(false);

		} else {
			// Tempo da animação de fechar, limpa os dados
			setTimeout(() => {
				setStateDados(false);
			}, 500);
		}
	}

	const getAnexos = async (idCliente) => {
		setLoadingGetAnexos(true);
		const retGetAnexos = await api.customers.attachs.get(idCliente);
		if(retGetAnexos.status === 200) {
			setStateAnexos(retGetAnexos.data);
		}
		setLoadingGetAnexos(false);
	}

	return (
		<Portal
			titulo="Anexos do cliente por projeto"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				_stateDados.status === 200 ? (
					<DetalhesClienteAnexos
						stateCliente={stateDados}
						stateAnexos={stateAnexos}
						loadingGetAnexos={loadingGetAnexos}
						after={
							<div className="form-bottom">
								<Button2
									_={{
										value: 'Fechar',
										type: 'button',
										onClick: onClose,
									}}
								/>
							</div>
						}
					/>
				) : (
					<Info
						texto={_stateDados.msg}
						icone="erro"
					/>
				)
			)}
		</Portal>
	);
};

export { PortalClientesDetalhesAnexos };
