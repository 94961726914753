import React, { useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import c from './Notificacoes.module.scss';

// Imagens
import imgIconeNotificacao from '@svg/regular/bell.svg';

// Cpnt
import { Select } from "@_cpnt/Inputs/Select/Select";
import { IconeTexto } from "@_cpnt/Texto/IconeTexto/IconeTexto";
import { IconeTextoRoot } from "@_cpnt/Texto/IconeTexto/IconeTextoRoot/IconeTextoRoot";
import { Btn } from "@_cpnt/Btn/Btn/Btn";
import { Loading } from "@_cpnt/Loading/Loading";
import { RequestInfo } from "@_cpnt/RequestInfo/RequestInfo";
import { ItemNotificacao } from "@_cpnt/Item/ItemNotificacao/ItemNotificacao";
import { URL } from "@_config/config";
import { data } from "@_func/date";

export const Notificacoes = props => {
	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
		loading,
		getNotificacoes,
		abrirNotificacao,
		marcarTodasComoLida,
		stateDados = {},
	} = props;

	return (
		<div className={`${className} ${c['container']}`}>
			<Loading abs size="x5" show={loading} />
			<div className={`${c['wrap']}`}>
				<div className={`${c['header']}`}>

					<IconeTextoRoot className={`${c['titulo']}`}>
						<IconeTexto.Icone img={imgIconeNotificacao} />
						<IconeTexto.Texto texto="Notificações" />
					</IconeTextoRoot>

					<div className={`${c['acoes-box']}`}>
						<Select
							label="Visualizar"
							_={{
								onChange: e => {
									getNotificacoes(e.value);
								},
								options: [
									{label: 'Todas', value: null},
									{label: 'Não lidas', value: 0},
									{label: 'Lidas', value: 1},
								]
							}}
							// loading={loading}
						/>
						<Btn
							_={{
								value: "Marcar como lida"
							}}
							icone={false}
							onClick={marcarTodasComoLida}
						/>
					</div>
				</div>

				<div className={`${c['conteudo']}`}>
					{stateDados?.status === 200 ? (
						stateDados?.data.map((val, key) => {
							return (
								<ItemNotificacao
									onClick={() => abrirNotificacao(val)}
									key={key}
									titulo={val.text}
									desc={data.porExtenso({data: val.date})}
									className={!val.readed ? `${c['nao-lida']}` : ''}
								/>
							)
						})
					) : (
						<RequestInfo req={stateDados} />
					)}
				</div>
			</div>
		</div>
	);
}
