import { toast as msg } from 'react-toastify';

import { URL_LOGO_SINGLE } from '@_config/config';

export const requestPermissionNotificacao = (exibirMensagens = false) => {

	if (!("Notification" in window)) {
		if(exibirMensagens === true) {
			msg.error('Seu navegador não suporte notificações.');
		}
		return false;
	}

	else if (Notification.permission === "granted") {
		if(exibirMensagens === true) {
			new Notification('Notificações já habilitadas', {
				body: 'Você já havia habilitado as notificações na área de trabalho anteriormente.',
				icon: URL_LOGO_SINGLE,
			});
		}
		return true;
	}

	else if (Notification.permission === 'denied') {
		if(exibirMensagens === true) {
			msg.warning('Você bloqueou as notificacões do chat, desbloqueie as notificações no seu navegador para poder utilizar as notificações do chat novamente.');
		}
		return false;
	}

	else if (Notification.permission !== 'denied') {
		return Notification.requestPermission(function (permission) {
			if (permission === "granted") {
				new Notification('Habilitado com sucesso', {
					body: 'Notificações habilitadas com sucesso! Agora você poderá receber as mensagens do chat em tempo real na sua área de trabalho.',
					icon: URL_LOGO_SINGLE,
				});
				return true;
			} else if(permission !== 'default') {
				msg.error('Você não permitiu as notificacões do chat, não será possível enviar notificações na área de trabalho.');
				return false;
			}
		});
	}
}

const notificacao = (titulo, mensagemObjeto = {}, exibirMensagens = false) => {
	if(requestPermissionNotificacao(exibirMensagens)) {
		return new Notification(titulo, mensagemObjeto);
	}
}

export default notificacao;