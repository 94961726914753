import React from 'react';

// CSS
import './StatusGeral.css';

// Imagens
import imgIconePlay from '@svg/solid/circle-play.svg';
import imgIconePause from '@svg/solid/circle-pause.svg';
import imgIconeDesconhecido from '@svg/solid/file.svg';

// Componentes
import Icone from '@cpnt/Icone/Icone';

const StatusGeral = props => {

	const dados = {
		status: 0,
		className: '',
		onClick: () => null,
		...props,
	};

	const renderStatus = (idStatus) => {
		if(idStatus === 1) {
			return (
				<span className="cpnt-statusgeral-icone" title="Ativo">
					<Icone imagem={imgIconePlay} cor="#229d3e" tamanho="22px" />
				</span>
			)
		}

		if(idStatus === 0) {
			return (
				<span className="cpnt-statusgeral-icone" title="Inativo">
					<Icone imagem={imgIconePause} cor="#E74C3C" tamanho="22px" />
				</span>
			)
		}

		return (
			<span className="cpnt-statusgeral-icone" title="Desconhecido">
				<Icone imagem={imgIconeDesconhecido} cor="#CCC" tamanho="22px" />
			</span>
		)

	}

	return (
		<div className={`cpnt-statusgeral ${dados.className}`} onClick={dados.onClick}>
			<div className="cpnt-statusgeral-icones">
				{renderStatus(dados.status)}
			</div>
		</div>
	);
};

export default StatusGeral;
