import { URL_API, API_TIMEOUT, EXIBIR_CONSOLE_LOG_REQUESTS, EMAIL_CONTATO, API_TIMEOUT_UPLOAD } from '@_config/config';
import { isLogado } from '@_func/auth';

export const config = (formData) => {
	const logado = isLogado();

	var token = {};

	if(logado) {
		if(logado.token !== null && logado.token !== undefined && logado.token !== '') {
			token = {
				'Authorization': `Bearer ${logado.token}`
			};
		}
	}

	var headers = {
		'Content-Type': 'application/json',
		...token,
	};

	if(formData) {
		headers['Content-Type'] = 'multipart/form-data';
	}

	var axiosConfig = {
		baseURL: URL_API,
		timeout: formData ? API_TIMEOUT_UPLOAD : API_TIMEOUT,
		headers: headers
	};

	return axiosConfig;
}

export const validarRetornoAxios = (retorno) => {
	var retJSON = {};
	try {
		if(retorno.request.data !== undefined) {
			retJSON = retorno.request.data;
		} else if(retorno.request.response !== undefined) {
			if(typeof(retorno.request.response) === 'object') {
				retJSON = retorno.request.response;
			} else {
				retJSON = JSON.parse(retorno.request.response);
			}
		} else if(retorno.request._response !== undefined) {
			if(typeof(retorno.request._response) === 'object') {
				retJSON = retorno.request._response;
			} else {
				retJSON = JSON.parse(retorno.request._response);
			}
		}
	} catch (error) {
		if(retorno.request.response === '') {
			retJSON = { message: <>Não foi possível obter uma resposta da API, tente fazer o envio novamente.<br /><br />Caso o erro persista, por favor nos informe o problema através do email <strong><a href={`mailto:${EMAIL_CONTATO}`}>{EMAIL_CONTATO}</a></strong>.</>};
		} else if(typeof(retorno.request.response) === 'string') {
			retJSON = { message: retorno.request.response };
		} else {
			retJSON = {};
		}
	}

	if(retJSON[0]) {
		return {
			data: retJSON,
			status: retorno.request.status,
			statusText: retorno.request.statusText,
		}
	}

	if(retorno.request.status === 0) {
		return {
			...retJSON,
			status: 504,
			statusText: 'Não foi possível obter uma resposta no servidor, tente novamente.',
		};
	}

	return {
		...retJSON,
		status: retorno.request.status,
		statusText: retorno.request.statusText,
	}
}

export const validarRetorno = (retorno) => {
	const retJSON = validarRetornoAxios(retorno);
	if(EXIBIR_CONSOLE_LOG_REQUESTS) {
		var dadosEnviados = retorno && retorno.config && retorno.config.data;
		try {
			dadosEnviados = JSON.parse(dadosEnviados);
		} catch (error) {
		}

		var corStatus;
		const status = retJSON.status;

		// Status
		if(status >= 200 && status <= 299) {
			corStatus = 'green';
		}
		else if(status >= 300 && status <= 399) {
			corStatus = 'yellow';
		}
		else {
			corStatus = 'red';
		}

		// Método
		var corMetodo;
		const metodo = retorno.config.method;

		if(metodo === 'get') {
			corMetodo = '#61AFFE';
		} else if(metodo === 'post') {
			corMetodo = '#49CC90';
		} else if(metodo === 'put') {
			corMetodo = '#FCA130';
		} else if(metodo === 'delete') {
			corMetodo = '#F93E3E';
		} else {
			corMetodo = '#000';
		}

		console.groupCollapsed(
			`%c.%c${metodo.toUpperCase()}%c${retorno.config.url}`,
			'background-color: '+corStatus+'; border-radius: 50%; display: inline-flex; width: 13px; height: 13px; color: transparent;',
			'color: #FFF; padding: 2px 3px; margin-left: 5px; border-radius: 3px; color: '+corMetodo+'; ',
			'margin-left: 5px',
			(dadosEnviados ? dadosEnviados : ' ')
		);
		retJSON && console.log(retJSON);
		console.groupEnd();
	}
	return retJSON;
};
