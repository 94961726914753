import React, { useState, useEffect, useContext } from 'react';
import Masonry from 'react-masonry-component';
import moment from 'moment/min/moment-with-locales';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './Tenor.css';

// API
import apiTenor from '@api/tenor/tenor';

// Componentes
import Input2 from '@cpnt/Input2/Input2';
import Portal from '@cpnt/Portal/Portal';
import Button2 from '@cpnt/Button2/Button2';
import Loading from '@cpnt/Loading/Loading';

// Funções
import { caminhoFoto } from '@func/utils';

const Tenor = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
		wrap = '',

		enviarMensagem,
		setShowPortal,
	} = props;

	const [loading, setLoading] = useState(true);
	const [loadingCarregarMais, setLoadingCarregarMais] = useState(false);

	const [stateResultados, setStateResultados] = useState([]);
	const [termoPesquisa, setTermoPesquisa] = useState('');
	const [stateMetadataResultados, setStateMetadataResultados] = useState(null);

	const [showPortalDetalhes, setShowPortalDetalhes] = useState(false);
	const [stateDetalhes, setStateDetalhes] = useState(false);

	// Masonry
	const masonryOptions = {
		transitionDuration: 500
	};
	const imagesLoadedOptions = { background: '.cpnt-terno-imagem-loading' };

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			pesquisar({
				name: termoPesquisa
			});
		}, 300);
		return () => clearTimeout(timeOutId);
	}, [termoPesquisa]);

	const pesquisar = async termo => {
		const retGetTenor = await apiTenor.get({
			q: termoPesquisa,
		});

		if(retGetTenor.status === 200) {
			if(retGetTenor.next === '0' || !retGetTenor.results.length) {}
			setStateResultados(retGetTenor.results);
			setStateMetadataResultados(retGetTenor.next);
		} else {
			setStateMetadataResultados(false);
			setStateResultados([]);
			msg.error('Nenhum resultado encontrado');
		}

		setLoading(false);
	}

	const carregarMais = async () => {
		setLoadingCarregarMais(true);
		const retGetTenor = await apiTenor.get({
			pos: stateMetadataResultados,
		});

		if(retGetTenor.status === 200) {
			var imagensConcatenadas = stateResultados.concat(retGetTenor.results);
			setStateMetadataResultados(retGetTenor.next);
			setStateResultados(imagensConcatenadas);
		} else {
			setStateMetadataResultados(false);
			msg.success('Todos os GIFs disponíveis para o termo atual já foram carregados.');
		}

		setLoadingCarregarMais(false);
	}

	return (
		<div className={`cpnt-tenor ${wrap}`}>
			<div className={`cpnt-tenor-box ${className} box-txt`}>
				<Input2
					label="Pesquisar"
					_={{
						value: termoPesquisa,
						autoFocus: true,
						onChange: e => {
							setTermoPesquisa(e.target.value);
						}
					}}
				/>
				<div className="cpnt-tenor-resultados-box">
					{!stateResultados.length ? (
						loading ? (
							<div className="margin-t-30">
								<Loading />
							</div>
						) : (
							<div className="margin-t-15">Nenhum resultado encontrado</div>
						)
					) : (
						<Masonry
							className={'cpnt-tenor-masonry'} // default ''
							elementType={'div'} // default 'div'
							options={masonryOptions} // default {}
							disableImagesLoaded={false} // default false
							updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
							imagesLoadedOptions={imagesLoadedOptions} // default {}
						>
							{stateResultados.map((val, key) => {
								return (
									<div key={key} className="cpnt-tenor-masony-item-wrap">
										<div
											className="cpnt-tenor-masony-item"
											onClick={() => {
												setStateDetalhes(val);
												setShowPortalDetalhes(true);
											}}
										>
											<div className="cpnt-placeholder-animation cpnt-placeholder-linha fs-12 color-transparent">
												<div className="relative z-index-10"><img src={val.media[0].nanogif.url} width={val.media[0].nanogif.dims[0]} height={val.media[0].nanogif.dims[1]} alt="Carregando..." className="block w-100p" /></div>
											</div>
										</div>
									</div>
								)
							})}
						</Masonry>
					)}

					{stateResultados.length && stateMetadataResultados !== false ? (
						<div className="margin-t-30 flex flex-center">
							{loadingCarregarMais ? (
								<Loading />
							) : (
								<Button2
									_={{
										value: 'Carregar mais',
										onClick: carregarMais,
									}}
								/>
							)}
						</div>
					) : null }
				</div>
			</div>

			<Portal
				show={showPortalDetalhes}
				onClose={() => {
					setShowPortalDetalhes(false);
				}}
				className="cpnt-tenor-portal-preview"
				classNameDefault="cpnt-portal-default-alt"
				classNameX="x"
			>
				<div className="cpnt-tenor-portal-img-preview">
					{stateDetalhes && <img src={stateDetalhes.media[0].gif.url} alt="gif" className="block w-100p margin-auto relative z-index-2" />}
					<Loading texto="" absolute wrap="cpnt-tenor-loading-preview" />
				</div>

				<div className="cpnt-tenor-btn-box">
					<Button2
						_={{
							value: 'Fechar',
							className: 'btn btn-1',
							type: 'button',
							onClick: e => {
								setShowPortalDetalhes(false);
							}
						}}
						classExtra="btn-1"
					/>
					<Button2
						_={{
							value: 'Enviar GIF',
							onClick: () => {
								enviarMensagem({
									byPassEnvio: true,
									name: store.usuario.name,
									photo: caminhoFoto(store.usuario.photo).url,
									msgBy: 'freelancer',
									type: 'imagem-url',
									data: moment().subtract(0, 'minutes').format('HH:mm'),
									metadata: {
										imagem: {
											preview: stateDetalhes.media[0].nanogif.url,
											url: stateDetalhes.media[0].gif.url,
										}
									},
								});
								setShowPortal(false);
								setShowPortalDetalhes(false);
							}
						}}
						wrap="margin-l-20"
					/>
				</div>

			</Portal>
		</div>
	);
};

export default Tenor;