import React from 'react';

// css
import './FotoGlyph.css';

// Imagens
import imgUserPicDefault from '@img/userpic.png';

const FotoGlyph = props => {
	const {
		className = '',
		wrap = '',
		foto,
		onClick,
	} = props;

	return (
		<div className={`${wrap}`} onClick={onClick}>
			<div className={`cpnt-perfilimg ${className}`}>
				<div className="cpnt-perfilimg-wrap">
					<div className="cpnt-perfilimg-inner">
						<div className="bg fill border-radius-50" style={{backgroundImage: `url(${imgUserPicDefault})`}}></div>
						<div className="bg fill border-radius-50 cpnt-perfilimg-inner-img" style={{backgroundImage: `url(${foto})`}}></div>
					</div>
				</div>
				<div>
					<span className="cpnt-perfilimg-glyph cpnt-perfilimg-glyph-1"></span>
					<span className="cpnt-perfilimg-glyph cpnt-perfilimg-glyph-2"></span>
					<span className="cpnt-perfilimg-glyph cpnt-perfilimg-glyph-3"></span>
					<span className="cpnt-perfilimg-glyph cpnt-perfilimg-glyph-4"></span>
				</div>
			</div>
		</div>
	);
};

export default FotoGlyph;