import React, { useEffect, useState } from 'react';

// css
import './PortalTemplateDetalhes.css';

// API
import apiTemplates from "@api/templates/templates";

// Partes
import { DetalhesTemplate } from "@page/Logado/Templates/DetalhesTemplate/DetalhesTemplate";

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções

import { msgs, msgStatus } from "@_func/request";

const PortalTemplateDetalhes = props => {

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		onClickEditar,
		className = '',
		view,
		portalClassName = 'portal-template-form-detalhes',
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({});
	const [_stateDados, _setStateDados] = useState({});

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await apiTemplates.getOne.get(id);

		if(retGetItem.status === 200) {
			setStateDados(retGetItem.data);
			_setStateDados(msgStatus(retGetItem));
		} else {
			_setStateDados(msgStatus(retGetItem));
			msgs([retGetItem]);
		}

		setLoading(false);
	}

	return (
		<Portal
			titulo="Detalhes do template"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<DetalhesTemplate
								stateDados={stateDados}
								after={
									view ? (
										<div className="form-bottom">
											<Button2
												_={{
													value: 'Fechar',
													onClick: onClose,
												}}
											/>
										</div>
									) : (
										<div className="form-bottom">
											<Button2
												_={{
													value: 'Fechar',
													onClick: onClose,
													className: 'btn btn-1',
												}}
												wrap="margin-l-auto"
											/>
											<Button2
												_={{
													value: 'Editar template',
													onClick: e => {
														onClickEditar(stateDados.id);
													}
												}}
												wrap="margin-l-10"
											/>
										</div>
									)
								}
							/>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export { PortalTemplateDetalhes };
