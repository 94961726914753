import React from 'react';

// CSS
import c from './Info.module.scss';

// Imagens
import iconeSucesso from '@_img/default/icone-sucesso.png';
import iconeErro from '@_img/default/icone-erro.png';
import iconeInfo from '@_img/default/icone-info.png';
import iconeAlerta from '@_img/default/icone-alerta.png';

const Info = props => {

	const {
		icone,
		texto,
	} = props;

	var img;

	if(icone && typeof(icone) !== 'string') {
		img = icone;
	} else if(icone === 'sucesso') {
		img = iconeSucesso;
	} else if(icone === 'erro' || icone === 'error') {
		img = iconeErro;
	} else if(icone === 'info') {
		img = iconeInfo;
	} else if(icone === 'alerta') {
		img = iconeAlerta;
	}

	return (
		<div className={`${c['container']}`}>

			<div className={`${c['inner']}`}>
				{icone ? (
					<div className={`${c['icone-box']}`}>
						<img src={img} alt="icone" className={`${c['icone']}`} />
					</div>
				) : null}

				{texto ? (
					<div className={`${c['texto']}`}>
						{texto}
					</div>
				) : null}
			</div>

		</div>
	)
}

export default Info;