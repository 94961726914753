import React from 'react';

// CSS
import './Erro404Logado.css';

// Imagens
import logo from '@img/logo-single-preto.png';

const Erro404Logado = props => {
	return (
		<div>
			<div className="box-default margin-15 padding-30 flex flex-center-v">
				<div className="w-50"><img src={logo} alt="Logo CROWD" className="block w-100p max-w-350 margin-auto" /></div>
				<div className="margin-l-30">
					<h1 className="margin-0 fs-26">Página não encontrada...</h1>
					<p className="margin-0 margin-t-5">Erro 404 - A página que você está procurando não existe.</p>
				</div>
			</div>
		</div>
	)
}

export default Erro404Logado;