import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiUserCompetences = {
	getAll: {
		get: async (id) => {
			return await api.get(`/userCompetences/getAll/${id}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	insertMany: {
		post: async (dados) => {
			return await api.post(`/userCompetences/insertMany`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiUserCompetences;