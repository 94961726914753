import debounce from "debounce-promise";
import React, { Fragment } from 'react';
import ReactSelect, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import WindowedSelect from 'react-windowed-select';

// CSS
import './Select2.css';

// Componentes
import Loading from '@cpnt/Loading/Loading';

// Funções

import Tip from "@cpnt/Tip/Tip";
import { OptionAlgolia } from './Options/OptionAlgolia/OptionAlgolia';
import { OptionDefault } from './Options/OptionDefault/OptionDefault';
import { valueSelect, valueSelectMultiple } from "@_cpnt/Inputs/Select/Options/funcSelect";

const MenuCustomizado = (props, before, after) => {
	return (
		<Fragment>
			<components.Menu {...props}>
				<div>
					{before}
					<div>{props.children}</div>
					{after}
				</div>
			</components.Menu>
		</Fragment>
	);
};

const Select2 = props => {

	const {
		ajax,
		wrap = 'flex-1',
		classNameWrap = '',
		invalid,
		req,
		label,
		customLabel,
		classNameLista = 'default',
		virtualized = true,
		loading,
		loadingTexto,
		before,
		after,
		cpnt,
		cpntParams,
		ajaxPlaceholder = 'Digite um nome para pesquisar...',
		min,
		tip,
		_,
	} = props;

	// const [StateInputValue, setStateInputValue] = useState('');

	/*
	** Opção de passar componentes diferentes do "option"
	** ================================================== */
	const optCustom = {
		default: OptionDefault,
		algolia: OptionAlgolia,
	};

	var option = optCustom['default'];

	if(cpnt && optCustom[cpnt] !== undefined) {
		option = optCustom[cpnt];
	}

	var OptionCpnt = option;

	var componentesDefault = {
		Option: e => <OptionCpnt {...e} _={cpntParams} />,
		Menu: e => MenuCustomizado(e, before, after),
	};

	// const onInputChange = (value, action) => {
	// 	if (action.action !== 'input-blur' && action.action !== 'menu-close') {
	// 		setStateInputValue(value);
	// 	}
	// };

	_.noOptionsMessage = () => ajax ? ajaxPlaceholder : 'Nenhum resultado encontrado';
	_.placeholder = _.placeholder ? _.placeholder : 'Selecione...';
	_.loadingMessage = () => <Loading alt texto="Pesquisando, aguarde..." />;
	_.isClearable = _.isClearable ? _.isClearable : false;
	_.components = {
		...componentesDefault,
		...(_.components ? _.components : {}),
	};
	if(ajax) {
		_.loadOptions = debounce(ajax, 500);
	}

	if(_.defaultValue !== undefined && _.defaultValue !== null) {
		if(typeof(_.defaultValue) === 'object' && _.defaultValue.length !== undefined) {
			_.defaultValue = valueSelectMultiple(_.defaultValue, _.options);
		} else {
			if(typeof(_.defaultValue) === 'object') {
				_.defaultValue = _.defaultValue.defaultValue;
			}
			_.defaultValue = valueSelect(_.defaultValue, _.options);
		}
	}

	if(_.value !== undefined && _.value !== null) {
		if(typeof(_.value) === 'object' && _.value.length !== undefined) {
			_.value = valueSelectMultiple(_.value, _.options);
		} else {
			if(typeof(_.value) === 'object') {
				_.value = _.value.value;
			}
			_.value = valueSelect(_.value, _.options);
		}
	}

	return (
		<div className={`cpnt-select2 ${invalid === true ? 'cpnt-select2-invalido' : ''} ${wrap} ${classNameWrap} cpnt-select2-lista-${classNameLista} ${min ? 'cpnt-select2-min' : ''}`}>
			{loading && <Loading {...(loadingTexto ? {texto: loadingTexto} : {})} alt absolute className="fs-14" />}
			<div className="relative">
				{tip ? (
					<div className={`cpnt-select2-customlabel ${req ? 'cpnt-select2-label-req' : ''} label ativo`}>
						<Tip
							tip={
								<>
									{label}<span className="q"></span>{req && <span className="inputtext2-req">*</span>}
								</>
							}
							info
						>
							{tip}
						</Tip>
					</div>
				) : (
					label && <div className={`cpnt-select2-label ${req ? 'cpnt-select2-label-req' : ''} label ativo`}>{label}{req && <span className="inputtext2-req">*</span>}</div>
				)}
				{customLabel && <div className={`cpnt-select2-customlabel`}>{customLabel}{req && <span className="inputtext2-req">*</span>}</div>}
				<div {...(_.onClick ? {onClick: _.onClick} : null)}>
					{ajax ? (
						<AsyncSelect
							{..._}
							cacheOptions={true}
						/>
					) : (
						<>
							{virtualized ? (
								<WindowedSelect
									{..._}
								/>
							) : (
								<ReactSelect
									{..._}
								/>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export { Select2 };
