import React from 'react';

// Compoenentes
import Input2 from '@cpnt/Input2/Input2';
import { InputDate } from "@cpnt/InputDate/InputDate";

const FormCertificado = props => {
	const {
		className = '',
		wrap = '',

		state,
		setState,
	} = props;

	return (
		<div className={`cpnt-formcertificado ${wrap}`}>
			<div className={`cpnt-formcertificado-box ${className}`}>

				<div className="fs-15">
					<div>Informe o título do certificado e o nome da empresa emissora.</div>
					<div className="margin-t-10"><strong>Exemplo:</strong> Especialista em Marketing de Conteúdo - DigitalMarketer</div>
				</div>

				<div className="wrap-5 margin-t-15">
					<div className="flex flex-wrap">
						<div className="input-box box-2 box-1-680">
							<Input2
								label="Título"
								req
								_={{
									value: state.title,
									onChange: e => {
										setState({
											...state,
											title: e.target.value,
										});
									}
								}}
							/>
						</div>
						<div className="input-box box-2 box-1-680">
							<Input2
								label="Emissor"
								req
								_={{
									value: state.issuer,
									onChange: e => {
										setState({
											...state,
											issuer: e.target.value,
										});
									}
								}}
							/>
						</div>
						<div className="input-box flex-1 box-1-680">
							<Input2
								label="URL"
								tip="Caso esse certificado seja acessível publicamente."
								_={{
									value: state.url,
									onChange: e => {
										setState({
											...state,
											url: e.target.value,
										});
									}
								}}
							/>
						</div>
						<div className="input-box w-200 box-1-680">
							<InputDate
								label="Data de emissão"
								req
								value={state.issue_date}
								onChange={e => {
									setState({
										...state,
										issue_date: e,
									});
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FormCertificado;