import { isNumeric } from "@_func/regex";
import { caminhoFoto, formatarDinheiro, msg } from "@func/utils";

/*
** Configurações do Step
** ================================================== */
export const qualStep = props => {
	const {
		step,
		maxStep,
		acao,
	} = props;

	var retorno = 1;
	var _step = 1;

	if(isNumeric(acao)) {
		_step = Number(acao);
		if(_step > maxStep) {
			_step = maxStep;
		} else if(_step < 1) {
			_step = 1;
		}
		retorno = _step;
	} else {
		if(isNumeric(step)) {
			_step = Number(step);
		}
		if(acao === 'prev') {
			if(_step <= 1) {
				msg.warning('Não há mais telas anteriores para navegar.');
			} else {
				retorno = (_step - 1);
			}
		} else if(acao === 'next') {
			if(_step >= maxStep) {
				msg.warning('Não há mais telas a seguir para navegar.');
			}
			if(step === 'bem-vindo') {
				_step = 0;
			}
			retorno = (_step + 1);
		} else {
			msg.warning('Step não encontrado');
		}
	}

	// if(retorno > maxStep) {
	// 	retorno = maxStep;
	// } else if(retorno < 1) {
	// 	retorno = 1;
	// }

	if(retorno < 1) {
		retorno = 1;
	}

	return Number(retorno);
}

export const formatarGetParaListaProfissionais = props => {
	const {
		stateFreelancers,
	} = props;

	var retorno = [];

	if(stateFreelancers && typeof(stateFreelancers) === 'object' && stateFreelancers.length > 0) {
		stateFreelancers.map((val, key) => {
			var _val = {
				nome: val.name_freelancer,
				foto: caminhoFoto(val.photo).url,
				id: val.id_freelancer,
				valor: formatarDinheiro(val.price, 'R$ '),
				endereco: `${val.city} / ${val.state}`,
			};
			retorno.push(_val);
		});
	}
	return retorno;
}

export const formatarGetParaListaProfissional = val => {
	return {
		nome: val.name_freelancer || '(Nome não informado)',
		foto: caminhoFoto(val.photo).url,
		id: val.id_freelancer,
		valorHora: val.price ? formatarDinheiro(val.price, 'R$ ') : '(valor não informado)',
		endereco: val.city && val.state ? `${val.city} / ${val.state}` : '(endereço não informado)',
		area: val.area || '(Área não informada)',
	};
}

export const formatarAlgoliaParaListaProfissional = lista => {
	if(!lista || typeof(lista) !== 'object' || !lista.length) {
		return [];
	}
	var retorno = lista.map(val => {
		return {
			...val,
			photo: val.foto,
			name_freelancer: val.nome,
		}
	})
	return retorno;
}
