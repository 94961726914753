import React, { useContext, useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// Componentes
import { Select2 } from '@cpnt/Select2/Select2';
import Input2 from '@cpnt/Input2/Input2';

const FormJobsAdminEdicao = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		onSubmit,
		stateDados = {},
		setStateDados,
		stateStatus,
		loadingGetStatus,
		after,
	} = props;

	const [idStatus, setIdStatus] = useState(stateDados.status);

	useEffect(() => {
		setIdStatus(stateDados.status);
	}, []);

	// Status IDs
	// id: 13 => Em orçamento
	// id: 14 => Em execução
	// id: 15 => Aprovado
	// id: 16 => Cancelado

	const hunterPodeEditar = () => {
		if(store.usuario.role === 1) {
			return true;
		}
		if(idStatus === 13 || idStatus === 14) {
			return true;
		}
		return false;
	}

	const nomeStatus = idStatus => {
		var retorno = '(não identificado)';
		if(idStatus === 15) {
			retorno = 'Aprovado';
		} else if(idStatus === 16	) {
			retorno = 'Cancelado';
		}
		return retorno;
	}

	return (
		<form onSubmit={onSubmit}>
			<div className="flex wrap-5 flex-wrap">
				{!hunterPodeEditar() ? (
					<div className="input-box w-66p box-1-680">
						<div
							className="input input-scroll input-inativo w-100p"
							onClick={() => {
								msg.warning(<>Apenas usuários administradores podem alterar o título de jobs com status <strong>{nomeStatus(idStatus)}</strong>.</>);
							}}
						>
							<span className="label ativo">Título*</span>
							<span className="input-scroll-desc">{stateDados.title}</span>
						</div>
					</div>
				) : (
					<div className="input-box w-66p box-1-680">
						<Input2
							label="Título"
							req
							_={{
								value: stateDados.title,
								onChange: e => setStateDados({
									...stateDados,
									title: e.target.value,
								})
							}}
						/>
					</div>
				)}

				<div className="input-box w-33p box-1-680">
					<Select2
						label="Status"
						req
						_={{
							options: stateStatus,
							value: stateDados.status,
							onChange: e => {
								setStateDados({
									...stateDados,
									status: e.value,
									statusLabel: e.label,
								})
							}
						}}
						loading={loadingGetStatus}
					/>
				</div>
			</div>
			{after}
		</form>
	);
};

export { FormJobsAdminEdicao };