import React from 'react';

const LayoutBlank = (props) => {

	const {
		className = '',
	} = props;

	const Cpnt = props.component;

	return (
		<div className={`${className}`}>
			<Cpnt {...props} />
		</div>
	);
}

export default LayoutBlank;
