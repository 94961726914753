import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiCnpj = {
	get: async cnpj => {
		return await api.get(`/cnpj/${cnpj}`, {
			...config(),
			'timeout': 10000,
		})
		.then(retorno => {
			return validarRetorno(retorno);
		})
		.catch(retorno => {
			return validarRetorno(retorno);
		});
	}
}

export default apiCnpj;
