import React from 'react';

// Phone
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import flags from 'country-flag-icons/react/3x2';

// CSS
import './InputTelefone2.css';

const InputTelefone2 = props => {

	const {
		_ = {},
		label = false,
		wrap = '',
		className = '',
		req,
		invalid,
	} = props;

	if(!_.defaultCountry) {
		_.defaultCountry = 'BR';
	}

	return (
		<div className={`cpnt-inputtelefone2 ${wrap}`}>
			<div className={`cpnt-inputtelefone2-box cpnt-inputtelefone2-box-ativo ${className} ${invalid ? 'cpnt-inputtelefone2-box-invalido' : ''}`}>
				<PhoneInput
					{..._}
					// countrySelectProps={{ unicodeFlags: false }}
					flags={flags}
				/>
				{label && <label className="label ativo">{label}{req && <span className="cpnt-inputtelefone2-req">*</span>}</label>}
			</div>
		</div>
	)
}

export default InputTelefone2;