import React from 'react';

// Componentes
import Disponibilidade from '@cpnt/Disponibilidade/Disponibilidade';
import Qtd from '@cpnt/Qtd/Qtd';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';

// Imagens
import imgIconeAnexo from '@svg/regular/paperclip.svg';

const DetalhesAnexos = props => {
	const {
		className = '',
		wrap = '',
		restricted,
		name,
		id,
		client_name,
		attachs = [],
		after,
	} = props;

	return (
		<>
			<h3 className="margin-v-0 inline-flex flex-center-v">
				{restricted === 1 && <Disponibilidade textoIndisponivel="Restrito" min status={0} className="margin-r-7 margin-b--3" />}
				<span>{name} <span className="color-1">(ID: {id})</span></span>
			</h3>
			<div className="fs-16 color-1 margin-t-2">{client_name}</div>

			<h3 className="margin-b-0">Anexos</h3>
			<div>
				{!attachs || !attachs.length ? (
					<div>Nenhum anexo encontrado.</div>
				) : (
					<div>
						<div>
							<Qtd
								qtd={attachs.length}
								vazio="Nenhum anexo"
								singular="anexo"
								plural="anexos"
							/>
						</div>
						<div className="margin-t-20">
							{attachs.map((val, key) => {
								return (
									<div key={key}>
										<TextoIconeLink
											icone={imgIconeAnexo}
											texto={val.label}
											link={val.link}
											target="_blank"
										/>
									</div>
								)
							})}
						</div>
					</div>
				)}
			</div>
			{after}
		</>
	);
};

export default DetalhesAnexos;