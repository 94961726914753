import React, { useEffect, useRef } from 'react';
import Mortal from 'react-mortal';
import { lock, unlock } from 'tua-body-scroll-lock';

// CSS
import c from './Portal.module.scss';

// Funções
import { hash } from '@_func/generate';

const Portal = props => {

	const refPortal = useRef();
	const _id = hash();

    const {
		show,
		titulo,
		subtitulo,
		onClose = () => null,
		closeOnClickBg = false,
		closeOnEsc = true,
		children,
		bottom,
		x = true,
		// id = hash(''),
		classNameDefault = c['container'],
		className = '',
		classNameContainer = '',
		classNameBg = '',
		classNameX = '',
		classNameWrapbox = '',
		classNameWrap = '',
		classNameWrapDefault = c['wrap'],
		classNameConteudo = c['conteudo'],
		outside,

		bg,
	} = props;

	var id = hash('')

	/*
	** Desativa o scroll no body e deixa scroll apenas no overlay
	** ================================================== */
	useEffect(() => {
		if(show) {
			/*
			** Copia o ref para evitar de perder ao desmontar
			** ================================================== */
			var _refPortal = refPortal.current;

			/*
			** Desativa o scroll no body e deixa scroll apenas no overlay
			** ================================================== */
			lock(_refPortal, { useGlobalLockState: true });
			/*
			** Verifica o evento ao pressionar a tecla (ESC)
			** ================================================== */
			window.addEventListener('keydown', closeOnEscBtn);

			/*
			** Ao desmontar
			** ================================================== */
			return () => {

				/*
				** Ativa o scroll novamente
				** ================================================== */
				unlock(_refPortal, { useGlobalLockState: true });

				/*
				** Remove o eventListener para evitar sobrecarga
				** ================================================== */
				window.removeEventListener('keydown', closeOnEscBtn);

				/*
				** Verifica se tem outro overlay ativo e da focus nele
				** Assim é possível usar o ESC no próximo portal também
				** ================================================== */
				if(_refPortal.parentElement
					&& _refPortal.parentElement.previousElementSibling
					&& _refPortal.parentElement.previousElementSibling.children
					&& _refPortal.parentElement.previousElementSibling.children[0]
					&& _refPortal.parentElement.previousElementSibling.children[0].dataset
					&& _refPortal.parentElement.previousElementSibling.children[0].dataset.portal
				) {
					_refPortal.parentElement.previousElementSibling.children[0].focus();
				}
			}
		}
	}, [show]);

	/*
	** Fecha ao clicar no background
	** ================================================== */
	const closeOnClickCheck = e => {
		if(e.target.id === id) {
			onClose();
		}
	}

	/*
	** Fecha ao pressionar ESC
	** ================================================== */
	const closeOnEscBtn = e => {
		if(closeOnEsc) {
			if(e.key === 'Escape' && refPortal.current.parentElement.nextElementSibling === null){
				onClose();
			}
		}
	}

    return (
		<Mortal
			isOpened={show}
			onClose={onClose}
			closeOnEsc={false}
			motionStyle={(spring, isVisible) => {
				return {
					opacity: spring(isVisible ? 1 : 0),
					scale: spring(isVisible ? 1 : 1.07),
					visibility: spring(isVisible ? 'visible' : 'hidden'),
				}
			}}
		>
			{(motion, isVisible) => {
				return (
					<div
						className={`${c['portal']} ${c['bg']} ${classNameContainer}`}
						style={{
							pointerEvents: isVisible ? 'auto' : 'none',
							opacity: motion.opacity,
							transform: `scale(${motion.scale})`,
						}}
						tabIndex={1}
						ref={refPortal}
						data-portal
					>
						<div
							className={`${c['wrap-box']} ${classNameWrapbox}`}
							id={id}
							{...(closeOnClickBg ? {onClick: closeOnClickCheck} : {})}
						>
							<div className={`${classNameWrapDefault} ${classNameWrap}`}>
								<div className={`${c['inner']}`}>
									{x && <span className={classNameX ? classNameX : c['close']} onClick={onClose}></span>}

									<div className={`${classNameConteudo}`} {...bg ? {style: {backgroundColor: bg}} : {}}>
										{titulo || subtitulo ? (
											<div className={`${c['top']}`}>
												{titulo && <h3 className={`${c['titulo']}`}>{titulo}</h3>}
												{subtitulo && <div className={`${c['subtitulo']}`}>{subtitulo}</div>}
											</div>
										) : null}
										<div className={`${classNameDefault} ${className}`}>{children}{bottom && <div className="form-bottom btns">{bottom}</div>}</div>
									</div>
									{outside}
								</div>
							</div>
						</div>
						<div className={`${c['bg']} ${c['bg-back']} ${classNameBg}`}></div>
					</div>
				)
			}}
		</Mortal>
    )
}

export { Portal }