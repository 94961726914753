import React, { useContext, useEffect, useRef, useState } from 'react';

// Socket
import socket from "@_api/socket";

// Libs
import { useNavigate } from 'react-router-dom';

// Zendesk
// import Zendesk, { ZendeskAPI } from 'react-zendesk';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// Hooks
import useWindowFocus from "@_hook/useWindowFocus";

// CSS
import c from './Header.module.scss';

// Func
import { api } from "@_api/api";
import { msg, toggleSidebar } from "@_func/cpnt";
import { msgs } from '@_func/request';
import { q } from "@_func/url";
import { apiObj } from "@_func/request";
import notificacao from "@_func/notification";

// Imagens
import imgLogoCrowd from '@_img/default/logo.svg';

// Cpnt
import { API_KEY_ZENDESK, URL_LOGO_SINGLE } from "@_config/config";
import { BtnHamburguer } from "@_cpnt/Btn/BtnHamburguer/BtnHamburguer";
import { BtnNotificacoes } from "@_cpnt/Btn/BtnNotificacoes/BtnNotificacoes";
import { Notificacoes } from "@_cpnt/Notificacoes/Notificacoes";
import { PortalLateral } from "@_cpnt/Portal/PortalLateral/PortalLateral";

/*
** Configurações do Zendesk
** ================================================== */
// ZendeskAPI("webWidget", "setLocale", "pt-BR");

export const Header = props => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();
	const janelaAtiva = useWindowFocus();
	const janelaAtivaRef = useRef();
	const contNotificacao = useRef(0);

	const {
		className = '',
	} = props;

	const [loading, setLoading] = useState(false);
	const [showPortalNotificacoes, setShowPortalNotificacoes] = useState(false);
	const [stateNotificacoes, setStateNotificacoes] = useState(apiObj());

	useEffect(() => {
		/*
		** Notificações
		** ================================================== */
		if(contNotificacao.current === 0) {
			contNotificacao.current++;
			getNotificacoes();
		}

		/*
		** Socket
		** ================================================== */
		if(!socket.connected) {
			socket.auth.token = store.token;
  			socket.connect();
		}

		/*
		** Chat Zendesk - oculta e exibe ao carregar e saiar
		** ================================================== */
		// if(store.usuario.role && store.usuario.role !== 2) {
		// 	ZendeskAPI("webWidget", "show");
		// 	return () => {
		// 		ZendeskAPI("webWidget", "hide");
		// 	}
		// }

		/*
		** Socket - ao sair
		** ================================================== */
		return () => {
			socket.emit('message', {
				data: {},
				context: 'UserSocket',
				function: 'leaveRoom'
			});
			socket.disconnect();
		}

	}, []);

	/*
	** Alterar estado do focus da janela
	** Serve para enviar notificações
	** ================================================== */
	useEffect(() => {
		janelaAtivaRef.current = janelaAtiva;
	}, [janelaAtiva]);

	/*
	** Verifica se houve interação no socket
	** ================================================== */
	useEffect(() => {
		/*
		** Socket - Novas notificações para o menu
		** ================================================== */
		socket.on('newNotification', e => {
			getNotificacoes();
			if(!janelaAtivaRef.current) {
				notificacao('Nova notificação', {
					body: 'Há novas notificações disponíveis.',
					icon: URL_LOGO_SINGLE
				});
			}
		});

		/*
		** Chat - Logar na sala geral
		** ================================================== */
		socket.emit('message', {
			data: {},
			context: 'UserSocket',
			function: 'joinRoom'
		});

	}, [socket]);

	/*
	** API - Notificações
	** ================================================== */
	const getNotificacoes = async (filtros = {}) => {
		if(loading) {
			msg.warning('Por favor, aguarde...');
			return;
		}

		setLoading(true);

		const ret = await api.notification.all.get({
			id_user: store.usuario.id,
			readed: filtros
		});

		var retObj = apiObj(ret);

		if(retObj.status === 400) {
			retObj.msg = 'Nenhuma notificação encontrada';
		}

		setStateNotificacoes(retObj);
		setLoading(false);
	}

	/*
	** API - Marcar uma mensagem como lida
	** ================================================== */
	const abrirNotificacao = async dados => {

		if(loading !== false) {
			msg.warning('Por favor, aguarde...');
			return;
		}

		setLoading(dados.id);

		const ret = await api.notification.read.put({
			id: dados.id,
			id_user: store.usuario.id,
		});

		if(ret.status === 200) {
			var notificacoes = {
				...stateNotificacoes,
				data: [...stateNotificacoes?.data?.map(val => {
					return {
						...val,
						...(val.id === dados.id ? {readed: true} : {})
					}
				})]
			};
			setStateNotificacoes(notificacoes);

			const link = tratarLink(dados);
			setShowPortalNotificacoes(false);
			navigate(link);
		} else {
			msgs([ret]);
		}

		setLoading(false);
	}

	/*
	** API - Marcar todas as mensagens como lidas
	** ================================================== */
	const marcarTodasComoLidas = async () => {
		if(loading) {
			msg.warning('Por favor, aguarde...');
			return;
		}

		setLoading(true);

		const ret = await api.notification.readAll.put({
			id_user: store.usuario.id,
		});

		if(ret.status === 200) {
			msg.success('Todas as mensagens foram marcadas como lidas');
			var dados = apiObj(ret);
			dados.data = [...stateNotificacoes?.data?.map(val => {
				return {
					...val,
					readed: true,
				}
			}) || []];
			setStateNotificacoes(dados);
		} else {
			msgs([ret]);
		}

		setLoading(false);
	}

	/*
	** Tratar link
	** As URLs de freelancers e de admins são diferentes
	** Essa função verifica qual a role para criar a URL correta
	** ================================================== */
	const tratarLink = dados => {
		const get = q(dados.link);

		// Freelancer
		if(store.usuario.role === 2) {
			if(dados.type === 'briefing') {
				return `/jobs/detalhes?id_briefing=${get.id_briefing}`;
			} else {
				return `/meus-jobs/negociacao?id_task=${get.id_task}`;
			}
		} else {
			// Admin e hunter
			if(dados.type === 'briefing') {
				return `/briefing/negociacao?id_briefing=${get.id_briefing}&id_freelancer=${get.id_freelancer}`;
			} else {
				return `/contratacoes/negociacao?id_task=${get.id_task}&id_freelancer=${get.id_freelancer}`;
			}
		}
	}

	/*
	** Quantidade de não lidas
	** ================================================== */
	const qtdNotificacoesNaoLidas = () => {
		const qtd = stateNotificacoes?.data?.filter(item => !item.readed);
		if(qtd?.length) {
			return qtd.length > 99 ? '99+' : qtd.length;
		}
		return false;
	}

	return (
		<>
			<div className={`${className} ${c['header']}`}>
				<BtnHamburguer
					className={`${c['left']}`}
					onClick={() => toggleSidebar({store, setStore})}
				/>
				<div className={`${c['logo-box']} ${c['center']}`}>
					<img src={imgLogoCrowd} className={`${c['logo']}`} alt="logo-crowd" />
				</div>
					{store.usuario.role === 2 && !store.usuario.finish ? (
						<div className={`${c['right']} ${c['texto-cadastro-incompleto']}`}>Cadastro incompleto</div>
					) : (
						<BtnNotificacoes
							className={`${c['right']}`}
							classNameTexto={`${c['right-texto']}`}
							qtd={qtdNotificacoesNaoLidas()}
							onClick={() => setShowPortalNotificacoes(true)}
						/>
					)}
			</div>

			<PortalLateral
				show={showPortalNotificacoes}
				onClose={() => {
					setShowPortalNotificacoes(false);
				}}
			>
				<Notificacoes
					stateDados={stateNotificacoes}
					getNotificacoes={getNotificacoes}
					marcarTodasComoLida={marcarTodasComoLidas}
					abrirNotificacao={abrirNotificacao}
					loading={loading}
				/>
			</PortalLateral>

			{/* {store.usuario.role && store.usuario.role !== 2 ? (
				<Zendesk
					defer
					zendeskKey={API_KEY_ZENDESK}
					onLoaded={() => null}
					color={{
						theme: "#FFF"
					}}
				/>
			) : null} */}
		</>
	);
}
