import React, { useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

export const VideosFreelancer = props => {
	const [store, setStore] = useContext(StoreContext);

	return (
		<div className="w-100p flex">
			<iframe
				title="academy"
				src="https://academy.crowd.br.com/freelancer?utm_source=plataforma+crowd"
				className="w-100p"
// sandbox="
// allow-downloads
// allow-downloads-without-user-activation
// allow-forms
// allow-modals
// allow-orientation-lock
// allow-pointer-lock
// allow-popups
// allow-popups-to-escape-sandbox
// allow-presentation
// allow-same-origin
// allow-scripts
// allow-storage-access-by-user-activation
// allow-top-navigation
// allow-top-navigation-by-user-activation
// allow-top-navigation-to-custom-protocols
// "
				></iframe>
		</div>
	);
};
