import Icone from "@cpnt/Icone/Icone";
import React from 'react';

// css
import './BtnIconeDesc.css';

const BtnIconeDesc = props => {
	const {
		className = '',
		classNameWrap = '',
		classNameBtn = '',
		classNameTitulo = '',
		wrap = '',

		titulo,
		desc,

		cor,
		icone,
		iconeCor,
		iconeTamanho = '34px',
		alt,
		onClick,
	} = props;

	return (
		<>
			<div className={`cpnt-btnicodesc ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-btnicodesc-box ${className}`}>
					<div
						className={`cpnt-btnicodesc-wrapper ${alt ? 'cpnt-btnicoedesc-alt' : ''} ${classNameBtn} ${cor ? `cpnt-btnicodesc-${cor}` : ''}`}
						onClick={onClick}
					>
						<Icone
							imagem={icone}
							cor={iconeCor}
							tamanho={iconeTamanho}
						/>
						<div className="padding-l-10">
							{titulo && <div className={`${classNameTitulo}`}>{titulo}</div>}
							{desc && <div className="td-desc-alt">{desc}</div>}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { BtnIconeDesc };