import React from 'react';

// CSS
import c from './BtnHamburguer.module.scss';

export const BtnHamburguer = props => {
	const {
		className = '',
		onClick,
	} = props;

	return (
		<div className={`${className}`}>
			<div className={`${c['btn']}`} onClick={onClick}>
				<span className={`${c['linha']} ${c['linha-1']}`}></span>
				<span className={`${c['linha']} ${c['linha-2']}`}></span>
				<span className={`${c['linha']} ${c['linha-3']}`}></span>
			</div>
		</div>
	);
}
