import { components } from 'react-select';
import ImgTituloDesc from '@cpnt/ImgTituloDesc/ImgTituloDesc';

// CSS
import './OptionAlgolia.css';

// Imagens
import imgIconeBriefing from '@svg/regular/circle-info.svg';

// Funções
import { cssCorImagem, formatarDinheiro } from '@func/utils';

// Componente Default
const { Option } = components;

export const OptionAlgolia = (props) => {
	const {
		_,
		isSelected,
	} = props;

	var dados = props.data;
	if(!dados) {
		dados = {};
	}

	return (
		<Option {...props}>
			<div className={`cpnt-optionalgolia-box ${isSelected ? 'cpnt-optionalgolia-box-selected' : ''}`}>
				<ImgTituloDesc
					wrap="td-info"
					titulo={dados.label}
					desc={
						<div className={`td-desc fs-12 cpnt-optionalgolia-desc ${isSelected ? 'cpnt-optionalgolia-desc-selected' : ''}`}>
							<div>{dados.email}</div>
							{/* <div>{dados.price ? `${formatarDinheiro(dados.price, 'R$ ')}/h` : ''} <span className="margin-l-10">{dados.area}</span></div> */}
						</div>
					}
					img={dados.photo}
					preview={false}
					placeholder='userpicture'
					classNameTitulo="td-titulo"
					classNameImgBox="foto-wrap"
					classNameBg="bg"
				/>
				<span
					className="cpnt-optionalgolia-info bg-alt"
					onClick={e => _.onClick(dados.id || props.value)}
					style={cssCorImagem({
						width: '20px',
						height: '20px',
						img: imgIconeBriefing,
						cor: isSelected ? '#FFF' : '#0277BD'
					})}
				>
				</span>
			</div>
		</Option>
	)
};
