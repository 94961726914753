import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalCartaApresentacaoUsuarioFormEdicao.css';

// API
import apiFreelancer from "@api/freelancer/freelancer";

// Partes
import { PtCartaApresentacao } from "@cpnt/Partes/PtCartaApresentacao/PtCartaApresentacao";

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { camposInvalidos,  sanitize } from '@func/utils';
import { trim } from "lodash";
import { api } from "@_api/api";

import { msgs, msgStatus } from "@_func/request";

const PortalCartaApresentacaoUsuarioFormEdicao = props => {

	const {
		idUsuario,
		idTipoTemplate,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-cartaapresuser-form-edicao',
		onSuccess = () => null,
		onError = () => null,

		tituloApresentacao = 'Fale um pouco sobre você',
		keyDescricao = 'description',
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({
		[keyDescricao]: '',
	});
	const [_stateDados, _setStateDados] = useState({});

	// Edição
	const [loadingEditar, setLoadingEditar] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		setLoading(true);

		const retGetDados = await api.users.getOne.get(idUsuario);
		_setStateDados(msgStatus(retGetDados));

		if(retGetDados.status === 200) {
			setStateDados(retGetDados.data);
		} else {
			msgs([retGetDados]);
		}

		setLoading(false);
	}

	/*
	** Editar carta de apresentação
	** ================================================== */
	const editar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		var desc = sanitize(stateDados[keyDescricao]);
		desc = trim(desc, ' ');

		const dadosEnviar = {
			id: idUsuario,
			[keyDescricao]: desc,
		};

		if(camposInvalidos(dadosEnviar, {
			[keyDescricao]: 'Carta de apresentação',
		}, [
			keyDescricao,
		])) return;

		setLoadingEditar(true);

		const retUpdateUsuario = await api.users.update.put(dadosEnviar);

		if(retUpdateUsuario.status === 200) {
			msg.success('Carta de apresentação atualizada com sucesso');
			onSuccess({
				ret: retUpdateUsuario,
				dados: desc,
			})
		} else {
			msgs([retUpdateUsuario]);
			onError({
				ret: retUpdateUsuario,
			});
		}

		setLoadingEditar(false);
	}

	return (
		<Portal
			titulo="Carta de apresentação"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<>
								<PtCartaApresentacao
									idTipoTemplate={idTipoTemplate}
									titulo={tituloApresentacao}
									stateTexto={stateDados[keyDescricao]}
									showDica={false}
									setStateTexto={e => {
										setStateDados({
											...stateDados,
											[keyDescricao]: e,
										});
									}}
								/>
								<div className="form-bottom">
									<Button2
										_={{
											value: 'Cancelar',
											className: 'btn btn-1',
											onClick: onClose,
										}}
									/>
									<Button2
										_={{
											value: 'Salvar dados',
											onClick: editar,
										}}
										wrap="margin-l-10"
										loading={loadingEditar}
									/>
								</div>
							</>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export { PortalCartaApresentacaoUsuarioFormEdicao };
