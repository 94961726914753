import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalEnderecoFormEdicao.css';

// API
import apiFreelancer from "@api/freelancer/freelancer";

// Partes

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { msgs, msgStatus } from "@_func/request";
import { apiGetPaises } from "@api/_functions/geral";
import { PtEndereco } from "@cpnt/Partes/PtEndereco/PtEndereco";
import { camposInvalidos,  removerKeysVazias } from '@func/utils';

const PortalEnderecoFormEdicao = props => {

	const {
		idFreelancer,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-ender-form-edicao',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetPaises, setLoadingGetPaises] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({
		zip_code: '',
		street: '',
		number: '',
		complement: '',
		district: '',
		city: '',
		state: '',
	});
	const [_stateDados, _setStateDados] = useState({});
	const [statePaises, setStatePaises] = useState([]);

	// Edição
	const [loadingEditar, setLoadingEditar] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		setLoading(true);

		const retGetDados = await apiFreelancer.getOne.get(idFreelancer);
		_setStateDados(msgStatus(retGetDados));

		if(retGetDados.status === 200) {
			setStateDados(retGetDados.data);
			apiGetPaises({setStatePaises, setLoadingGetPaises});
		} else {
			msgs([retGetDados]);
		}

		setLoading(false);
	}

	/*
	** Editar dados da empresa
	** ================================================== */
	const editar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			zip_code: stateDados.zip_code,
			street: stateDados.street,
			number: stateDados.number,
			complement: stateDados.complement,
			district: stateDados.district,
			city: stateDados.city,
			state: stateDados.state,
		};

		if(camposInvalidos(dadosEnviar, {}, [
			'zip_code',
			'street',
			'number',
			'district',
			'city',
			'state',
		])) return;

		setLoadingEditar(true);

		const dadosSemKeysVazias = removerKeysVazias(dadosEnviar);

		const retUpdateFreelancer = await apiFreelancer.update.put({
			...dadosSemKeysVazias,
			id: idFreelancer,
		});

		if(retUpdateFreelancer.status === 200) {
			msg.success('Endereço atualizado com sucesso.');
			onSuccess({
				ret: retUpdateFreelancer,
				dados: stateDados,
			});
		} else {
			msgs([retUpdateFreelancer]);
			onError({
				ret: retUpdateFreelancer,
			});
		}

		setLoadingEditar(false);
	}

	return (
		<Portal
			titulo="Endereço para contato"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<>
								<PtEndereco
									stateDados={stateDados}
									setStateDados={setStateDados}
									internacional={stateDados.id_country !== 33 ? true : false}

									showPais
									statePaises={statePaises}
									loadingGetPaises={loadingGetPaises}
								/>
								<div className="form-bottom">
									<Button2
										_={{
											value: 'Cancelar',
											className: 'btn btn-1',
											onClick: onClose,
										}}
									/>
									<Button2
										_={{
											value: 'Salvar dados',
											onClick: editar,
										}}
										wrap="margin-l-10"
										loading={loadingEditar}
									/>
								</div>
							</>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export { PortalEnderecoFormEdicao };
