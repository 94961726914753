import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Componentes
import ItemFotoDesc from '@cpnt/ItemFotoDesc/ItemFotoDesc';
import { PortalPerfilFreelancer } from '@portalGeral/PortalPerfil/PortalPerfilFreelancer';

// Funções
import { closePortal, getPortalDados, gets, isShowPortal, showPortal } from '@route/routeUtils';

const DetalhesProfissionaisSelecionados = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		className = '',
		wrap = '',
		after,
		stateListaAtual,
		stateSelecionados,
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	return (
		<>
			<div className={`cpnt-detalhesequipe ${wrap}`}>
				<div className={`cpnt-detalhesequipe-box ${className}`}>
					{stateListaAtual && stateListaAtual !== '' ? (
						<h3 className="margin-v-0 inline-flex flex-center-v">
							Lista: {stateListaAtual}
						</h3>
					) : null}
					<div className="desc">Profissionais selecionados: {stateSelecionados.length}</div>

					<div className="padding-t-15">
						{stateSelecionados && stateSelecionados.length ? (
							<div className="wrap-5">
								{stateSelecionados.map((val, key) => {
									return (
										<ItemFotoDesc
											className="margin-5 cursor-pointer"
											key={key}
											foto={val.foto}
											titulo={val.nome}
											subtitulo={val.titulo}
											onClick={() => {
												showPortal({
													portal: 'detalhes-profissionais-perfil',
													dados: val.id,
													stateGet,
													navigate,
												});
											}}
										/>
									)
								})}
							</div>
						) : (
							<div>
								<h3 className="margin-b-0">Nenhum freelancer selecionado</h3>
							</div>
						)}
					</div>

				</div>
			</div>

			{after}

			<PortalPerfilFreelancer
				id={getPortalDados({portal: 'detalhes-profissionais-perfil', stateGet})}
				show={isShowPortal({portal: 'detalhes-profissionais-perfil', stateGet})}
				onClose={() => closePortal({portal: 'detalhes-profissionais-perfil', stateGet, navigate})}
			/>
		</>
	);
};

export { DetalhesProfissionaisSelecionados };
