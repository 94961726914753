import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// css
import './DetalhesClienteAnexos.css';

// Imagens
import imgUserPic from '@img/userpic.png';
import imgIconeAnexo from '@svg/regular/paperclip.svg';

// Funções
import { gets } from '@route/routeUtils';
import Qtd from '@cpnt/Qtd/Qtd';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';
import { addPrefixo } from '@func/utils';
import Loading from '@cpnt/Loading/Loading';

const DetalhesClienteAnexos = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		className = '',
		wrap = '',
		after,

		stateCliente = {},
		stateAnexos = [],
		loadingGetAnexos,
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	return (
		<div className={`cpnt-detalhesclienteanexos ${wrap}`}>
			<div className={`cpnt-detalhesclienteanexos-box ${className}`}>
				<h3 className="margin-0">{stateCliente.name} <span className="color-1">(ID: {stateCliente.id})</span></h3>
				{loadingGetAnexos ? <div className="desc">Carregando anexos...</div> : null}
				<div>
					{loadingGetAnexos ? (
						<Loading />
					) : (
						!stateAnexos.length ? (
							<div className="msg margin-t-15">Nenhum anexo encontrado.</div>
						) : (
							<div>
								{stateAnexos.map((val, key) => {
									return (
										<div key={key}>
											<h3>{val.name_project} <span className="color-1"> (ID: {val.id_project})</span></h3>
											{val.attachs.map((val2, key2) => {
												return (
													<div key={key2}>
														<TextoIconeLink
															icone={imgIconeAnexo}
															texto={val2.label}
															link={addPrefixo(val2.link)}
															target="_blank"
														/>
													</div>
												)
											})}
										</div>
									)
								})}
							</div>
						)
					)}
				</div>
				{after}
			</div>

		</div>
	);
};

export { DetalhesClienteAnexos };