import React from 'react';

// CSS
import './BarraHorizontal.css';

// Imagens
import imgIconeEditar from '@img/icones/geral/editar.png';
import imgIconeFechar from '@img/icones/geral/x.png';
import imgLoading from '@imgdefault/loading-azul.svg';
import { cssBg, urlExibicao } from "@func/utils";
import Tip from "@cpnt/Tip/Tip";
import Icone from "@cpnt/Icone/Icone";

const BarraHorizontal = props => {
	const {
		titulo = '',
		link,
		nowrap = true,
		classNameWrap = '',
		className = 'margin-5',
		classNameDesc = 'td-desc-alt',
		classNameLink = 'a',
		icone,
		iconeCor,
		desc,
		x = true,
		edit = true,
		onClickEdit,
		onClickX,
	} = props;

	return (
		<div className={`cpnt-barrah-container ${classNameWrap}`}>
			<div className={`cpnt-barrah-titulo-box ${className}`}>
				<div className={`cpnt-barrah-titulo ${nowrap ? 'text-one-line cpnt-barrah-titulo-nowrap' : ''}`}>
					<div className="cpnt-barrah-titulowrap">
						{icone ? (
							<div className="cpnt-barrah-icone-box">
								<Icone imagem={icone} cor={iconeCor} />
							</div>
						) : null}
						{link ? (
							<a href={link} target="_blank" rel="noreferrer" className={`${classNameLink}`}>{urlExibicao(titulo)}</a>
						) : titulo}
					</div>
					{desc ? (
						<div className={`${classNameDesc}`}>{desc}</div>
					) : null}
				</div>
				<div className="cpnt-barrah-acoes">
					<Tip
						tip={
							<div className="cpnt-barrah-acao" onClick={() => onClickEdit(props)}>
								<div className="cpnt-barrah-icone-box">
									<div style={cssBg(imgIconeEditar)} className="cpnt-barrah-icone bg"></div>
								</div>
							</div>
						}
						classNameTipDefault="cpnt-barrah-acao-box"
					>
						Editar
					</Tip>
					<Tip
						tip={
							<div className="cpnt-barrah-acao" onClick={() => onClickX(props)}>
								<div className="cpnt-barrah-icone-box">
									<div style={cssBg(imgIconeFechar)} className="cpnt-barrah-icone bg"></div>
								</div>
							</div>
						}
						classNameTipDefault="cpnt-barrah-acao-box"
					>
						Remover
					</Tip>
				</div>
			</div>
		</div>
	)
}

export { BarraHorizontal };