import React from 'react';

// Imagens
import imgQuestion from '@svg/solid/circle-question.svg';

// Componentes
import { Select2 } from '@cpnt/Select2/Select2';
import Tooltip from '@cpnt/Tooltip/Tooltip';
import Input2 from '@cpnt/Input2/Input2';
import Icone from '@cpnt/Icone/Icone';

// Funções
import { html } from '@func/utils';
import InputCpfCnpj from '../InputCpfCnpj/InputCpfCnpj';
import Checkbox2 from "@cpnt/Checkbox2/Checkbox2";

const PagamentoDadosBancarios = props => {
	const {
		classNameWrap = '',
		stateBancos,
		stateDadosBancarios,
		setStateDadosBancarios,
	} = props;

	return (
		<div className={`${classNameWrap}`}>
			<h3 className="titulo margin-t-0">Dados bancários</h3>
			<div className="wrap-5 margin-t-5">
				<div className="flex flex-wrap">
					<div className="input-box w-50p box-1-680">
						<Select2
							label="Banco"
							req
							_={{
								options: stateBancos,
								value: stateDadosBancarios.id_bank,
								onChange: e => {
									setStateDadosBancarios({
										...stateDadosBancarios,
										id_bank: e.value,
									})
								},
							}}
						/>
					</div>
					<div className="input-box w-50p box-1-680">
						<Input2
							label="Títular da conta"
							req
							_={{
								value: stateDadosBancarios.full_name,
								onChange: e => setStateDadosBancarios({
									...stateDadosBancarios,
									full_name: e.target.value,
								})
							}}
						/>
					</div>
					<div className="input-box w-40p box-1-680">
						<Input2
							label="CPF do títular ou CNPJ"
							req
							_={{
								value: stateDadosBancarios.cpf,
								onChange: e => {
									setStateDadosBancarios({
										...stateDadosBancarios,
										cpf: e.target.value,
									});
								}
							}}
							cpfCnpj
						/>
					</div>
					<div className="input-box w-30p box-1-680">
						<Input2
							label="Agência (com dígito)"
							req
							_={{
								value: stateDadosBancarios.agency,
								onChange: e => setStateDadosBancarios({
									...stateDadosBancarios,
									agency: e.target.value,
								})
							}}
						/>
					</div>
					<div className="input-box w-30p box-1-680">
						<Input2
							label="Conta (com dígito)"
							req
							_={{
								value: stateDadosBancarios.account,
								onChange: e => setStateDadosBancarios({
									...stateDadosBancarios,
									account: e.target.value,
								})
							}}
						/>
					</div>
				</div>
			</div>

		</div>
	);
};

export default PagamentoDadosBancarios;