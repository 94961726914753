import React, { useContext, useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalClientesFormCadastro.css';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { api } from '@_api/api';
import { apiGetEmpresas } from '@apiGeral/geral';
import { addPrefixo, camposInvalidos, removerKeysVazias, validarCnpj, validarCpf, validarEmail, validarUrl } from '@func/utils';
import FormCliente from '@page/Logado/Clientes/FormCliente/FormCliente';

import { msgs, msgStatus } from "@_func/request";

const PortalClientesFormCadastro = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-800',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		id_company: store.usuario.id_company, cpf_cnpj: '', name: '', social_reason: '', street: '', district: '',
		zip_code: '', state: '', city: '', number: '', complement: '', phone: '', active: 1, description: '',
		name_responsible: '', email_responsible: '', position_responsible: '', prospect: '', logo: false,
		site: '', type: 'j',
	};

	// Edição
	const [loadingCadastrarCliente, setLoadingCadastrarCliente] = useState(false);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [stateLogotipo, setStateLogotipo] = useState(false);
	const [stateLogotipoNovo, setStateLogotipoNovo] = useState(false);
	const [stateEmpresas, setStateEmpresas] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados(camposDefault);
			setStateLogotipo(false);
			setStateLogotipoNovo(false);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** API - Get empresas
	** ================================================== */
	const inicializarGets = async () => {
		if(store.usuario.role === 1) {
			apiGetEmpresas({setLoadingGetEmpresas, setStateEmpresas});
		}
	}

	/*
	** Editar cliente
	** ================================================== */
	const cadastrarCliente = async e => {
		e.preventDefault();

		if(loadingCadastrarCliente) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			// id: stateDados.id,
			id_company: stateDados.id_company,
			cpf_cnpj: stateDados.cpf_cnpj,
			name: stateDados.name,
			social_reason: stateDados.social_reason,
			street: stateDados.street,
			district: stateDados.district,
			zip_code: stateDados.zip_code,
			state: stateDados.state,
			city: stateDados.city,
			number: stateDados.number,
			complement: stateDados.complement,
			name_responsible: stateDados.name_responsible,
			email_responsible: stateDados.email_responsible,
			description: stateDados.description,
			position_responsible: stateDados.position_responsible,
			phone: stateDados.phone,
			site: addPrefixo(stateDados.site),
			active: stateDados.active,
			type: stateDados.type,
		};

		if(camposInvalidos(dadosEnviar, {
			cpf_cnpj: 'CNPJ',
			name: 'Nome do cliente',
			name_responsible: 'Nome do principal contato',
			position_responsible: 'Cargo',
		}, [
			'id_company',
			'cpf_cnpj',
			'name',
			...(stateDados.type === 'j' ? ['social_reason'] : []),
			'name_responsible',
			'position_responsible',
			'email_responsible',
			'zip_code',
			'street',
			'number',
			'district',
			'city',
			'state',
			'type',
		])) return;

		if((stateDados.type === 'J' || stateDados.type === 'j') && !validarCnpj(dadosEnviar.cpf_cnpj)) return;
		if((stateDados.type === 'F' || stateDados.type === 'f') && !validarCpf(dadosEnviar.cpf_cnpj)) return;

		if(!validarEmail(dadosEnviar.email_responsible)) return;

		if(dadosEnviar.site && dadosEnviar.site !== '' && !validarUrl(dadosEnviar.site)) return;

		var dadosEnviarSemKeysVaziasOuFormData = removerKeysVazias(dadosEnviar);

		// if(stateLogotipoNovo) {
		// 	dadosEnviarSemKeysVaziasOuFormData = await dadosEnviarFormData({
		// 		dadosEnviar,
		// 		imagem: stateLogotipo,
		// 		showConsoleLog: true,
		// 	});
		// 	consoleFormData(dadosEnviarSemKeysVaziasOuFormData)
		// }

		setLoadingCadastrarCliente(true);

		const retInsertCliente = await api.customers.insert.post(dadosEnviarSemKeysVaziasOuFormData);

		if(retInsertCliente.status === 200) {
			msg.success('Cliente cadastrado com sucesso.');
			onSuccess({
				ret: retInsertCliente,
			})
		} else {
			msgs([retInsertCliente]);
			onError({
				ret: retInsertCliente,
			});
		}

		setLoadingCadastrarCliente(false);
	}

	return (
		<Portal
			titulo="Cadastrar cliente"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<div className={wrap}>
				<div className={className}>
					<FormCliente
						isCadastro={true}
						onSubmit={cadastrarCliente}
						stateDados={stateDados}
						setStateDados={setStateDados}
						stateLogotipo={stateLogotipo}
						setStateLogotipo={setStateLogotipo}
						stateLogotipoNovo={stateLogotipoNovo}
						setStateLogotipoNovo={setStateLogotipoNovo}
						stateEmpresas={stateEmpresas}
						loadingGetEmpresas={loadingGetEmpresas}
						after={
							<div className="form-bottom">
								<Button2
									_={{
										value: 'Cancelar',
										className: 'btn btn-1',
										type: 'button',
										onClick: onClose,
									}}
									classExtra="btn-1"
								/>
								<Button2
									_={{
										value: 'Cadastrar cliente',
										onClick: cadastrarCliente
									}}
									loading={loadingCadastrarCliente}
									wrap="margin-l-15"
								/>
							</div>
						}
					/>
				</div>
			</div>
		</Portal>
	);
};

export default PortalClientesFormCadastro;