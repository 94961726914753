import React, { useState } from 'react';

// css
import './SecaoCertificados.css';

// Portais
import { PortalCertificadoFormCadastro } from "@cpnt/Portais/PortaisCertificados/PortalCertificadoFormCadastro/PortalCertificadoFormCadastro";
import { PortalCertificadoFormEdicao } from "@cpnt/Portais/PortaisCertificados/PortalCertificadoFormEdicao/PortalCertificadoFormEdicao";

// Componentes
import { ListaSecao } from "@cpnt/ListaSecao/ListaSecao";

const SecaoCertificados = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		classNameTituloBox = '',
		classNameListaWrap = 'margin-t-25',
		classNameBox = 'box-2',

		onClickBtnSalvar,
		loadingBtnSalvar,
		stateCertificados = [],
		setStateCertificados,
		loadingGetCertificados,
	} = props;

	const [showPortalAdd, setShowPortalAdd] = useState(false);
	const [showPortalEditar, setShowPortalEditar] = useState(false);
	const [stateCertificadoEditar, setStateCertificadoEditar] = useState({});

	return (
		<>
			<div className={`cpnt-secexp ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-secexp-box ${className}`}>
					<ListaSecao
						titulo="Certificados"
						textoAdd="Adicionar"
						onClickBtnSalvar={onClickBtnSalvar}
						loadingBtnSalvar={loadingBtnSalvar}
						textoNenhumItemEncontrado="Nenhum certificado adicionado"
						stateDados={stateCertificados}
						setStateDados={setStateCertificados}
						keyTitulo="title"
						keyDesc="issuer"
						classNameWrap={classNameListaWrap}
						classNameTituloBox={classNameTituloBox}
						classNameBox={classNameBox}
						exibirApenasChecked={false}
						onClickAdd={e => setShowPortalAdd(true)}
						onClickEdit={e => {
							setStateCertificadoEditar({
								key: e.key,
								...e.dados,
							});
							setShowPortalEditar(true);
						}}
						onClickRemover={e => {
							var itens = stateCertificados.filter((val, key) => key !== e.key);
							setStateCertificados(itens);
						}}
						loading={loadingGetCertificados}
						alt
					/>
				</div>
			</div>

			<PortalCertificadoFormCadastro
				show={showPortalAdd}
				onClose={e => setShowPortalAdd(false)}
				onSuccess={e => {
					var exp = [...stateCertificados];
					exp.push(e);
					setStateCertificados(exp);
					setShowPortalAdd(false);
				}}
			/>

			<PortalCertificadoFormEdicao
				show={showPortalEditar}
				onClose={e => setShowPortalEditar(false)}
				dados={stateCertificadoEditar}
				onSuccess={e => {
					var exp = [...stateCertificados];
					exp[e.key] = e;
					setStateCertificados(exp);
					setShowPortalEditar(false);
				}}
			/>
		</>
	);
};

export { SecaoCertificados };
