import { API_TIMEOUT, HUBSPOT_API_KEY, URL_API } from "@_config/config";
import api from 'axios';
import { config, validarRetorno } from '../axios';

const validarRetornoHub = dados => {
	var ret = validarRetorno(dados);
	if(ret.errors) {
		ret.invalidFields = [];
		ret.errors.map(val => {
			ret.invalidFields.push({msg: val.message});
		});
	}
	return ret;
}

const apiHubspot = {
	sendForm: {
		post: async (idForm, dados) => {
			return await api.post(`/hubspot/sendForm/${idForm}`, dados, config())
			.then(retorno => {
				return validarRetornoHub(retorno);
			})
			.catch(retorno => {
				return validarRetornoHub(retorno);
			});
		}
	},
	// public: {
	// 	sendForm: {
	// 		post: async (idForm, dados) => {
	// 			return await api.post(`https://api.hsforms.com/submissions/v3/integration/submit/4224406/${idForm}`, dados, {
	// 				// baseURL: URL_API,
	// 				timeout: 5000,
	// 				headers: {
	// 					'Content-Type': 'application/json',
	// 					'Authorization': `Bearer ${HUBSPOT_API_KEY}`
	// 				}
	// 			})
	// 			.then(retorno => {
	// 				return validarRetornoHub(retorno);
	// 			})
	// 			.catch(retorno => {
	// 				return validarRetornoHub(retorno);
	// 			});
	// 		}
	// 	}
	// },
	public: {
		sendForm: {
			post: async (idForm, dados) => {
				return await api.post(`/hubspot/public/sendForm/${idForm}`, dados, {
					baseURL: URL_API,
					timeout: API_TIMEOUT,
					headers: {
						'Content-Type': 'application/json',
					}
				})
				.then(retorno => {
					return validarRetornoHub(retorno);
				})
				.catch(retorno => {
					return validarRetornoHub(retorno);
				});
			}
		}
	},
	marketing: {
		v3: {
			forms: {
				post: async dados => {
					return await api.post(`https://api.hsforms.com/submissions/v3/integration/submit/4224406/f1a7c432-012a-4d64-aa9d-f2e0d15381a4`, dados, {
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${HUBSPOT_API_KEY}`
						}
					})
					.then(retorno => {
						return validarRetornoHub(retorno);
					})
					.catch(retorno => {
						return validarRetornoHub(retorno);
					});
				}
			}
		},
	},
};

export default apiHubspot;