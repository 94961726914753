export const LINKS_SECOES_SIDEBAR_USUARIO = [
	{
		titulo: 'Dados pessoais',
		links: [
			{
				texto: 'Resumo do perfil',
				link: 'resumo',
			},
			{
				texto: 'Dados pessoais',
				link: 'dados',
			},
			{
				texto: 'Foto de perfil',
				link: 'foto',
			},
			{
				texto: 'Apresentação profissional',
				link: 'apresentacao-profissional',
			},
			{
				texto: 'Apresentação pessoal',
				link: 'apresentacao-pessoal',
			},
		],
	},
	{
		titulo: 'Conhecimentos e experiências',
		links: [
			{
				texto: 'Área e habilidades',
				link: 'habilidades',
			},
			{
				texto: 'Competências',
				link: 'competencias',
			},
		],
	},
	{
		titulo: 'Outras configurações',
		links: [
			{
				texto: 'Dados de acesso',
				link: 'acessos',
			},
			{
				texto: 'Central de Ajuda',
				link: 'ajuda',
			},
			{
				texto: 'Termos de Uso',
				link: 'termos',
			},
			{
				texto: 'Política de privacidade',
				link: 'politica',
			},
		],
	},
];