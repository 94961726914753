import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiProjectsCombos = {
	areas: {
		get: async () => {
			return await api.get(`/projectsCombos/areas`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	status: {
		get: async () => {
			return await api.get(`/projectsCombos/status`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	tipoProduto: {
		get: async () => {
			return await api.get(`/projectsCombos/tipoProduto`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	tipoProjeto: {
		get: async () => {
			return await api.get(`/projectsCombos/tipoProjeto`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	solution: {
		get: async () => {
			return await api.get(`/projectsCombos/solution`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	complexity: {
		get: async () => {
			return await api.get(`/projectsCombos/complexity`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	scenery: {
		get: async () => {
			return await api.get(`/projectsCombos/scenery`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export default apiProjectsCombos;
