import React from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import qs from 'qs';
import { scroller } from 'react-scroll';

// css
import './Paginacao.css';

// Imagens
import imgIconePrevAll from '@svg/solid/angles-left.svg';
import imgIconeNextAll from '@svg/solid/angles-right.svg';
import imgIconePrev from '@svg/solid/chevron-left.svg';
import imgIconeNext from '@svg/solid/chevron-right.svg';

// Componentes
import Icone from '@cpnt/Icone/Icone';
import { removerKeysVazias } from '@func/utils';
import Loading from '../Loading/Loading';
import { isNumeric } from "@_func/regex";

const Paginacao = props => {
	var {
		wrap = '',
		className = '',
		totalItens,
		totalPaginas,
		qtdItensPorPagina,
		paginaAtual = 0,
		inativo,
		getsDefault = {},
		loading,
	} = props;

	const gets = qs.parse(window.location.search.slice(1));

	if(isNumeric(totalPaginas)) totalPaginas = Number(totalPaginas);
	if(isNumeric(totalItens)) totalItens = Number(totalItens);
	if(isNumeric(paginaAtual)) paginaAtual = Number(paginaAtual);
	if(isNumeric(qtdItensPorPagina)) qtdItensPorPagina = Number(qtdItensPorPagina);

	const navigate = useNavigate();

	const definirPagina = e => {
		gets.page = e;
		var getsDefaultSemVazio = removerKeysVazias(getsDefault);
		var getsFinal = {...getsDefaultSemVazio, ...gets};
		const getsQ = qs.stringify(getsFinal);
		navigate({search: getsQ});
		scrollTo();
	}

	const definirPaginaInput = e => {
		var value = Number(e.target.value);
		if(!isNaN(value)) {
			if(value >= totalPaginas) {
				value = totalPaginas;
			} else if(value <= 0) {
				value = 1;
			}
			gets.page = value;
			var getsDefaultSemVazio = removerKeysVazias(getsDefault);
			var getsFinal = {...getsDefaultSemVazio, ...gets};
			const getsQ = qs.stringify(getsFinal);
			navigate({search: getsQ});
		}
	}

	const scrollTo = () => {
		scroller.scrollTo('tabela-container', {
			duration: 800,
			delay: 0,
			smooth: 'ease',
			offset: 1,
		});
	}

	return (
		<div className={`cpnt-paginacao ${wrap} ${inativo ? 'opct-5' : ''}`}>
			{loading && <Loading absolute alt />}
			{inativo && <div className="fill z-index-10"></div>}
			<div className={`cpnt-paginacao-box ${className}`}>
				<div className="cpnt-paginacao-info-paginas">
					<div className="cpnt-paginacao-info-texto">
						Página
					</div>
					<div className="cpnt-paginacao-info-input">
						<input
							type="text"
							value={paginaAtual}
							onChange={definirPaginaInput}
						/>
					</div>
					<div className="cpnt-paginacao-info-btn">
						de {totalPaginas || 1}
					</div>
				</div>
				<div className="cpnt-paginacao-componente">
					<Pagination
						activePage={paginaAtual}
						itemsCountPerPage={qtdItensPorPagina}
						innerClass="cpnt-paginacao-lista"
						activeClass="cpnt-paginacao-item-ativo"
						linkClass="cpnt-paginacao-btn-next-prev-box"
						totalItemsCount={totalItens || 0}
						pageRangeDisplayed={5}
						onChange={definirPagina}
						prevPageText={
							<div className="flex flex-center">
								<Icone
									imagem={imgIconePrev}
									tamanho="16px"
								/>
								<span className="cpnt-paginacao-texto-prev-next padding-b-3 padding-l-5 fs-14">
									Anterior
								</span>
							</div>
						}
						nextPageText={
							<div className="flex flex-center">
								<span className="cpnt-paginacao-texto-prev-next padding-b-3 padding-r-5 fs-14">
									Próximo
								</span>
								<Icone
									imagem={imgIconeNext}
									tamanho="16px"
								/>
							</div>
						}
						firstPageText={<Icone imagem={imgIconePrevAll} tamanho="16px" />}
						lastPageText={<Icone imagem={imgIconeNextAll} tamanho="16px" />}
					/>
				</div>
			</div>
		</div>
	);
};

export default Paginacao;