import queryString from 'query-string';

import { URL_API_IMAGENS } from "@_config/config";
import imgUserPicDefault from '@_img/default/userpic.png';

/*
** Verifica o caminho das URLs, se é API e etc.
** ================================================== */
export const urlImg = (url, placeholder = imgUserPicDefault) => {
	if(url && typeof(url) === 'string' && url.length > 1) {
		if(url.indexOf('amazonaws.com') > -1) {
			return url;
		} else if(url.indexOf('Content/images/customer') > -1) {
			return `${URL_API_IMAGENS}/${url}`;
		} else if(url.indexOf('Content/images/user') > -1) {
			return `${URL_API_IMAGENS}/${url}`;
		} else if(url.indexOf('users/profile') > -1) {
			return `${URL_API_IMAGENS}/${url}`;
		} else if(url.indexOf('/static/media') > -1) {
			return url;
		} else {
			return placeholder;
		}
	}

	return placeholder;
}

/*
** Faz o parse do GET
** ================================================== */
export const q = stringGet => {
	var dados = queryString.parse(stringGet);
	return dados;
}

/*
** Verifica se o link é externo e retornar o target blank
** ================================================== */
export const linkTarget = link => {
	if(!link) return {};

	if(link.indexOf('https://') >= 0 || link.indexOf('http://') >= 0) {
		return {target: '_blank', rel: 'noreferrer'};
	}

	return {};
}
