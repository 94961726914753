import React from 'react';
import { singularPlural, toFixed } from '@func/utils';
import Estrelas from '../Estrelas/Estrelas';

// css
import './Avaliacoes.css';
import { isNumeric } from "@_func/regex";

const Avaliacoes = props => {
	const {
		className = '',
		wrap = '',

		tasks = {},
		ratings = {},
		tamanho = '110px',
	} = props;

	return (
		<div className={`cpnt-avaliacoes ${wrap}`}>
			<div className={`cpnt-avaliacoes-box ${className}`}>
				<div className="box-txt">
					<div className="box-txt margin-t-10">
						<h3 className="margin-0">Jobs</h3>
						{!ratings.ratings_count ? (
							<div className="margin-t-5 fs-13 color-1">Esse profissional ainda não possui jobs.</div>
						) : (
							<div className="margin-t-10">
								<div className="cpnt-avaliacoes-item">
									<div>Concluídos</div>
									<div className="cpnt-avaliacoes-linhasep"></div>
									<div className="cpnt-avaliacoes-item-numero">{tasks.tasks_concluded}</div>
								</div>
								<div className="cpnt-avaliacoes-item">
									<div>Em execução</div>
									<div className="cpnt-avaliacoes-linhasep"></div>
									<div className="cpnt-avaliacoes-item-numero">{tasks.tasks_in_progress}</div>
								</div>
								<div className="cpnt-avaliacoes-item">
									<div>Cancelados</div>
									<div className="cpnt-avaliacoes-linhasep"></div>
									<div className="cpnt-avaliacoes-item-numero">{tasks.tasks_canceled}</div>
								</div>
								<div className="cpnt-avaliacoes-item">
									<div>Total</div>
									<div className="cpnt-avaliacoes-linhasep"></div>
									<div className="cpnt-avaliacoes-item-numero">{tasks.tasks_total}</div>
								</div>
							</div>
						)}
					</div>
					<div className="margin-t-15">
						<AvaliacoesGerais avaliacoes={ratings} tamanho={tamanho} />
					</div>
				</div>
			</div>
		</div>
	);
};

export const AvaliacoesGerais = props => {

	const {
		avaliacoes = {},
		tamanho = '110px',
	} = props;

	const exibirMedia = (numero) => {
		if(isNumeric(numero)) {
			return `(${toFixed(numero)})`;
		}
		return '';
	}

	return (
		<>
			<h3 className="margin-0">Avaliações</h3>
			{!avaliacoes.ratings_count ? (
				<div className="fs-13 color-1">Nenhuma avaliação disponível.</div>
			) : (
				<div className="color-1 fs-14">Baseado em <strong>{avaliacoes.ratings_count ? avaliacoes.ratings_count : '-'}</strong> {singularPlural(avaliacoes.ratings_count, 'análise', 'análises')}</div>
			)}
		</>
	)
}

export default Avaliacoes;