import React, { useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import c from './RevisaoBriefingSelecionados.module.css';

// Componentes
import { BoxWrap } from "@cpnt/BoxWrap/BoxWrap";
import LinhaItem from '@cpnt/LinhaItem/LinhaItem';
import { ListaProfissionaisAcoes, onClickExibirTodosSalvoELocal, onClickExibirTodosSalvos, onClickLimparListaSalva, onClickSelectListaLocal } from "@cpnt/ListaProfissionais/ListaProfissionaisAcoes/ListaProfissionaisAcoes";
import { ListarTags } from "@cpnt/ListarTags/ListarTags";
import Loading from "@cpnt/Loading/Loading";
import { ProfissionaisSelecionados } from '@cpnt/ProfissionaisSelecionados/ProfissionaisSelecionados';

// Objetos
import { DISPONIBILIDADE_HORAS_PROFISSIONAL, SENIORIDADE } from "@config/objetos";

// Funções
import { onClickRemover } from '@func/selecaoUsuariosBriefings';
import { arrValue, formatarDinheiro, htmlToJsx, msg } from "@func/utils";
import { LIMITE_QTD_FREELANCERS_NOVO_BRIEFING } from "@_config/config";

const RevisaoBriefingSelecionados = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		stateGet,
		navigate,
		stateDados = {},
		setStep,

		titulo = 'Revisão da proposta e publicação',
		stateDescricao = '',

		stateListaAtual,
		setStateListaAtual,
		stateListas,
		setStateListas,
		stateSelecionados,
		setStateSelecionados,

		stateClientes,
		stateProjetos,
		stateUsuarios,

		stateFreelancers,
		setStateFreelancers,
		loading,
		definirStep,
	} = props;

	return (
		<div className="relative">
			<h3 className="titulo">{titulo}</h3>

			{loading && <Loading absolute bgAlt2 />}

			<BoxWrap
				titulo={`Profissionais selecionados (${stateSelecionados.length})`}
				className="margin-t-15"
				btnTitulo="Adicionar mais profissionais"
				onClickBtn={e => {
					navigate('/buscar-profissionais')
				}}
				btnAlt
			>

				<ProfissionaisSelecionados
					view={true}
					classNameDefault=''
					classNameWrapDefault=''
					classNameInner='flex-wrap'
					stateGet={stateGet}
					navigate={navigate}
					stateListaAtual={stateListaAtual}
					setStateListaAtual={setStateListaAtual}
					stateListas={stateListas}
					setStateListas={setStateListas}
					stateSelecionados={stateSelecionados}
					setStateSelecionados={setStateSelecionados}
					stateSelecionadosAnteriormente={[]}
					setStateSelecionadosAnteriormente={setStateFreelancers}
					onClickExibirTodosSalvoELocal={e => {
						onClickExibirTodosSalvoELocal({
							stateGet,
							navigate,
						})
					}}
				/>

			</BoxWrap>

			<BoxWrap
				titulo="Dados da proposta de job"
				className="margin-t-15"
				btnTitulo="Editar"
				onClickBtn={e => {
					definirStep(1);
				}}
				btnAlt
			>
				<LinhaItem titulo="ID da proposta de job" desc={stateDados.id} alt />
				<LinhaItem titulo="Título" desc={stateDados.title} alt />
				<LinhaItem titulo="Cliente" desc={arrValue(stateClientes, 'id', stateDados.id_customer, 'name', '(não informado)')} alt />
				<LinhaItem titulo="Projeto" desc={arrValue(stateProjetos, 'id', stateDados.id_project, 'name', '(não informado)')} alt />
				<LinhaItem titulo="Responsável" desc={arrValue(stateUsuarios, 'id', stateDados.id_user, 'name', '(não informado)')} alt />
				<LinhaItem titulo="Orçamento" desc={formatarDinheiro(stateDados.budget, 'R$ ')} alt />
			</BoxWrap>

			<BoxWrap
				titulo="Descrição da proposta de job"
				className="margin-t-15"
				classNameDesc="box-txt fs-15"
				btnTitulo="Editar"
				onClickBtn={e => {
					definirStep(2);
				}}
				btnAlt
			>
				<div className="fs-14 italic">Estas são as informações que o freelancer prestador de serviços vai consultar para poder enviar um orçamento para você:</div>
				<div className="margin-t-15">
					{stateDescricao && stateDescricao !== '' ? htmlToJsx(stateDescricao) : (
						<div>
							<div className="color-red">Nenhuma descrição adicionada.</div>
							<div className="fs-15">Você pode adicionar uma descrição no <span className="a" onClick={e => definirStep(2)}>passo 2</span>.</div>
						</div>
					)}
				</div>
			</BoxWrap>

			<ListaProfissionaisAcoes
				stateGet={stateGet}
				navigate={navigate}
				stateListaAtual={stateListaAtual}
				setStateListaAtual={setStateListaAtual}
				stateListas={stateListas}
				setStateListas={setStateListas}
				stateSelecionados={stateSelecionados}
				setStateSelecionados={setStateSelecionados}
				setStateFreelancersSalvos={setStateFreelancers}
				stateSelecionadosAnteriormente={[]}
			/>

		</div>
	);
};

export { RevisaoBriefingSelecionados };
