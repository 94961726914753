import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './Usuarios.css';

// API
import { api } from "@_api/api";
import { apiGetEmpresas } from '@apiGeral/geral';

// Partes
import ItemUsuario from '@page/Logado/Usuarios/ItemUsuario/ItemUsuario';
import { UsuariosQtdResultado } from './UsuariosQtdResultado/UsuariosQtdResultado';

// Components
import { BtnAdd } from '@cpnt/BtnAdd/BtnAdd';
import Button2 from '@cpnt/Button2/Button2';
import FiltrosWrap from '@cpnt/FiltrosWrap/FiltrosWrap';
import Info from '@cpnt/Info/Info';
import Input2 from '@cpnt/Input2/Input2';
import { PlaceholderRender } from '@cpnt/Placeholder/Placeholder';
import PortalPerfil from '@portalGeral/PortalPerfil/PortalPerfil';
import { PortalUsuariosFormCadastro } from '@portal/PortaisUsuarios/PortalUsuariosFormCadastro/PortalUsuariosFormCadastro';
import { PortalUsuariosFormEdicao } from '@portal/PortaisUsuarios/PortalUsuariosFormEdicao/PortalUsuariosFormEdicao';
import Portal from '@cpnt/Portal/Portal';
import { Select2 } from '@cpnt/Select2/Select2';

// Funções
import { STATUS_FILTRO } from '@config/objetos';
import { caminhoFoto} from '@func/utils';

// Rotas
import { clearGet, closePortal, getPortalDados, gets, initFuncExec, isShowPortal, setGet, setGetNavigate, showPortal } from '@route/routeUtils';
import { BtnLimparFiltros } from '@cpnt/BtnLimparFiltros/BtnLimparFiltros';
import { msgStatus } from "@_func/request";
import { Btn } from "@_cpnt/Btn/Btn/Btn";
import { fotoOuGravatar } from "@_func/profile";

const Usuarios = props => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = (getsAtualizados = getsDefault) => {
		initFuncExec([
			{
				func: getUsuarios,
				param: getsAtualizados,
			},
			{
				func: apiGetEmpresas,
				param: {
					setLoadingGetEmpresas,
					setStateEmpresas,
				},
				init: true,
			},
		], init, location, stateLocation);
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		overlay: [],
		limit: 20,
		page: 1,

		id_company: store.usuario.id_company,
		name: '',
		status: null,
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	/*
	** States
	** ================================================== */
	const [stateUsuarios, setStateUsuarios] = useState([]);
	const [_stateUsuarios, _setStateUsuarios] = useState({});
	const [stateUsuariosMetadata, setStateUsuariosMetadata] = useState({});
	const [stateEmpresas, setStateEmpresas] = useState([]);

	/*
	** Loading
	** ================================================== */
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);
	const [loadingGetUsuarios, setLoadingGetUsuarios] = useState(false);

	/*
	** API - Pesquisar usuários
	** ================================================== */
	const getUsuarios = async (dadosObj = {}) => {
		if(loadingGetUsuarios) {
			return;
		}

		setLoadingGetUsuarios(true);

		var dadosEnviar = clearGet(dadosObj);

		var idCompany = dadosEnviar.id_company;
		var status = dadosEnviar.status;

		const retGetUsuarios = await api.users.getAllUsers.get(idCompany, status);

		_setStateUsuarios(msgStatus(retGetUsuarios));

		if(retGetUsuarios.status === 200) {
			setStateUsuarios(retGetUsuarios.data);
			setStateUsuariosMetadata(retGetUsuarios.metadata);
		} else {
			setStateUsuarios([]);
			setStateUsuariosMetadata({});
		}

		setLoadingGetUsuarios(false);
	}

	return (
		<>
			<div className="pg-usuarios">
				<div className="conteudo-box">
					<div className="titulo-box">
						<h3 className="titulo">Usuários</h3>
						<Btn
							_={{
								value: 'Cadastrar usuário',
								onClick: () => showPortal({portal: 'cadastro', stateGet, navigate})
							}}
							size="m"
						/>
					</div>

					<FiltrosWrap>
						<form
							onSubmit={e => e.preventDefault()}
							className="flex flex-wrap wrap-5"
						>
							<div className="input-box w-50p box-1-980">
								<Select2
									label="Empresa"
									_={{
										value: stateGet.id_company,
										options: stateEmpresas,
										onChange: e => {
											setGetNavigate({
												gets: {
													id_company: e.value,
													name: '',
												},
												navigate,
												stateGet,
												location,
											});
										},
									}}
									loading={loadingGetEmpresas}
								/>
							</div>
							<div className="input-box w-25p box-1-980">
								<Input2
									label="Pesquisar usuário"
									_={{
										value: stateGet.name,
										onChange: e => {
											setGetNavigate({
												gets: {name: e.target.value},
												navigate,
												stateGet,
												location,
											});
										}
									}}
								/>
							</div>
							<div className="input-box w-25p box-1-980 flex">
								<Select2
									label="Status"
									_={{
										value: stateGet.status,
										onChange: e => {
											setGetNavigate({
												gets: {status: e ? e.value : null},
												forceCamposVazios: {status: e ? e.value : null},
												navigate,
												stateGet,
												location,
											});
										},
										options: STATUS_FILTRO,
										isClearable: true,
									}}
									loading={loadingGetEmpresas}
								/>
								<BtnLimparFiltros
									_={{
										onClick: e => {
											setGetNavigate({
												gets: getsDefault,
												navigate,
												stateGet,
												location,
											});
										},
									}}
								/>
							</div>
						</form>
					</FiltrosWrap>

					<div className="subtitulo-box">
						<UsuariosQtdResultado stateUsuarios={stateUsuarios} />
					</div>

					<div className="pg-usuarios-lista-usuarios box">
						<PlaceholderRender
							qtd={12}
							table={false}
							loading={loadingGetUsuarios}
							init={init}
							itens={stateUsuarios}
							request={_stateUsuarios}
							cpnt="PlaceholderUsuarios"
							classNamePlaceholder="box-3 box-2-1680 box-1-980"
						>
							{stateUsuarios.map((val, index) => {
								var _val = {
									...val,
									foto: fotoOuGravatar({foto: val.photo, email: val.email}),
									onClickFoto: e => showPortal({portal: 'perfil', dados: e.id, stateGet, navigate}),
									onClickEditar: e => showPortal({portal: 'edicao', dados: e.id, stateGet, navigate})
								}
								return (
									<div key={index} className="box-3 box-2-1680 box-1-980">
										<ItemUsuario {..._val} />
									</div>
								)
							})}
						</PlaceholderRender>
					</div>

					<div className="subtitulo-box">
						<UsuariosQtdResultado stateUsuarios={stateUsuarios} />
					</div>

				</div>
			</div>

			<PortalPerfil
				idFreelancer={getPortalDados({portal: 'perfil', stateGet})}
				idUsuario={getPortalDados({portal: 'perfil', stateGet})}
				tipo="usuario"
				show={isShowPortal({portal: 'perfil', stateGet})}
				onClose={() => closePortal({portal: 'perfil', stateGet, navigate})}
			/>

			<PortalUsuariosFormCadastro
				show={isShowPortal({portal: 'cadastro', stateGet})}
				id={getPortalDados({portal: 'cadastro', stateGet})}
				onClose={() => closePortal({portal: 'cadastro', stateGet, navigate})}
				onSuccess={() => {
					getUsuarios(gets());
					closePortal({portal: 'cadastro', stateGet, navigate})
				}}
				onSuccessStatus={() => getUsuarios(gets())}
				closeOnEsc={false}
			/>

			<PortalUsuariosFormEdicao
				show={isShowPortal({portal: 'edicao', stateGet}) && getPortalDados({portal: 'edicao', stateGet}) !== store.usuario.id ? true : false}
				id={getPortalDados({portal: 'edicao', stateGet})}
				onClose={() => closePortal({portal: 'edicao', stateGet, navigate})}
				onSuccess={() => {
					getUsuarios(gets());
					closePortal({portal: 'edicao', stateGet, navigate})
				}}
				onSuccessStatus={() => getUsuarios(gets())}
				closeOnEsc={false}
			/>

			<Portal
				titulo="Você está editando seu próprio perfil"
				show={isShowPortal({portal: 'edicao', stateGet}) && getPortalDados({portal: 'edicao', stateGet}) === store.usuario.id ? true : false}
				onClose={() => closePortal({portal: 'edicao', stateGet, navigate})}
				className="w-600"
			>
				<Info
					icone="info"
					texto={<>Utilize a página de <Link to="/perfil" className="underline">perfil</Link> para poder editar seu próprio perfil, por lá você tem muito mais autonomia e consegue utilizar recursos não disponíveis nesta seção.</>}
				/>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Fechar',
							type: 'button',
							className: 'btn btn-1',
							onClick: e => closePortal({portal: 'edicao', stateGet, navigate}),
						}}
					/>
					<Link to="/perfil" className="btn btn-default margin-l-15">
						Acessar página de perfil
					</Link>
				</div>
			</Portal>

		</>
	);
}

export default Usuarios;
