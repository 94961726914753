import React from 'react';

// Componentes
import Input2 from '@cpnt/Input2/Input2';

const PagamentoInternacional = props => {
	const {
		classNameWrap = '',
		stateDadosBancarios,
		setStateDadosBancarios,
	} = props;

	return (
		<div className={`${classNameWrap}`}>
			<h3 className="titulo margin-t-0">Pagamento Internacional</h3>
			<div className="wrap-5 margin-t-5">
				<div className="flex flex-wrap">
					<div className="input-box w-50p box-1-780">
						<Input2
							label="Full name or account holder"
							req
							_={{
								value: stateDadosBancarios.full_name,
								onChange: e => setStateDadosBancarios({
									...stateDadosBancarios,
									full_name: e.target.value,
								})
							}}
						/>
					</div>
					<div className="input-box w-25p box-1-780">
						<Input2
							label="SWIFT / BIC code"
							req
							_={{
								value: stateDadosBancarios.swift_bic_code,
								onChange: e => setStateDadosBancarios({
									...stateDadosBancarios,
									swift_bic_code: e.target.value,
								})
							}}
						/>
					</div>
					<div className="input-box w-25p box-1-780">
						<Input2
							label="IBAN / Account number"
							req
							_={{
								value: stateDadosBancarios.iban_account_number,
								onChange: e => setStateDadosBancarios({
									...stateDadosBancarios,
									iban_account_number: e.target.value
								})
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PagamentoInternacional;