import { toast as msgToast } from 'react-toastify';

/*
** Mensagens
** ================================================== */
export const msg = msgToast;

/*
** Toggle sidebar
** ================================================== */
export const toggleSidebar = props => {
	const {
		store,
		setStore,
	} = props;

	setStore({
		...store,
		config: {
			...store.config,
			hideSidebar: store.config && store.config.hideSidebar ? false : true,
		}
	});
}

/*
** Esconder sidebar
** ================================================== */
export const hideSidebar = props => {
	const {
		store,
		setStore,
	} = props;

	setStore({
		...store,
		config: {
			...store.config,
			hideSidebar: false,
		}
	});
}

/*
** Verifica se está escondida e adiciona a classe
** ================================================== */
export const isHiddenSidebar = (store, classNameHide) => {
	if(store?.config?.hideSidebar) {
		return classNameHide;
	}
	return '';
}
