import React, { useContext, useEffect, useState } from 'react';

// Libs
import moment from 'moment/min/moment-with-locales';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import c from './AvisoTopo.module.css';

// API
import apiDashNotifications from "@api/dashNotifications/dashNotifications";

// Componentes
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";

// Funções
import { isToShowNotificacaoTopo, salvarNaoExibirNotificacaoTopo } from "@func/local";
import { htmlToJsxAdmin } from "@func/utils";

const AvisoTopo = props => {

	const {
		x = true,
		texto,
		preview,
		backgroundColor,
		textColor,
		setStateAvisoTopo,
		code,
	} = props;

	const [store, setStore] = useContext(StoreContext);
	const [stateShowBar, setStateShowBar] = useState(preview);
	const [showPortalConfirmacao, setShowPortalConfirmacao] = useState(false);

	useEffect(() => {
		if(!preview) {
			getConfiguracoes();
		}
	}, []);

	useEffect(() => {
		setStateShowBar(preview);
	}, [preview]);

	const getConfiguracoes = async () => {
		const ret = await apiDashNotifications.getOne.get(1);
		if(ret.status === 200 && ret.data.active) {

			var data = moment(moment().toDate()).format('YYYY-MM-DDTHH:mm:ss[.000Z]');

			if(moment(data).isBetween(ret.data.date_start, ret.data.date_end)) {
				if(isToShowNotificacaoTopo(ret.data.code)) {
					if(ret.data.roles.filter(item => Number(item.id) === Number(store.usuario.role)).length) {
						setStateAvisoTopo({
							texto: ret.data.text,
							textColor: ret.data.text_color,
							backgroundColor: ret.data.bar_color,
							code: ret.data.code,
						});
						setStateShowBar(true);
					}
				}
			}
		}
	}

	return (
		<>
			{stateShowBar ? (
				<div className={`${store.config && store.config.hideSidebar ? '' : c['topbar-sidebar']}`} style={{backgroundColor: backgroundColor, color: textColor}}>
					<div className={`${c['topbar']}`}>
						<div className="base-content">
							<div className={`${c['topbar-wrapper']} ${x ? c['topbar-wrapper-x'] : ''}`}>
								<div className={`${c['topbar-inner']} box-txt`}>
									{htmlToJsxAdmin(texto)}
								</div>
								{x ? (
									<div className={`${c['topbar-x']}`} onClick={() => {
										setShowPortalConfirmacao(true);
									}}></div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			) : null}

			<PortalConfirmacao
				show={showPortalConfirmacao}
				titulo="Deseja ocultar essa mensagem?"
				desc={
					<>
						<div>Deseja realmente ocultar essa mensagem?</div>
						<div className="margin-t-10">Para visualizar essa mensagem futuramente, você precisará deslogar e logar novamente, ou limpar os cookies do seu navegador.</div>
					</>
				}
				onClose={() => {
					setShowPortalConfirmacao(false);
				}}
				onClickCancelar={() => {
					setShowPortalConfirmacao(false);
				}}
				onClickConfirmar={() => {
					salvarNaoExibirNotificacaoTopo(code);
					setStateShowBar(false);
					setShowPortalConfirmacao(false);
				}}
			/>
		</>
	);
};

export { AvisoTopo };
