import React from 'react';

// css
import './Tag.css';

import imgIconeX from '@svg/solid/circle-x.svg';
import { cssCorImagem } from '@func/utils';

const Tag = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		texto,
		x,
		onClick,
	} = props;

	return (
		<>
			<div className={`cpnt-tag ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-tag-box ${className}`}>
					<div className="cpnt-tag-texto">{texto}</div>
					{x ? (
						<div
							className="cpnt-tag-x cursor-pointer"
							onClick={e => onClick(props)}
							style={cssCorImagem({
								img: imgIconeX,
								width: '14px',
								cor: '#E74C3C',
							})}
						>
						</div>
					) : null}
				</div>
			</div>
		</>
	);
};

export { Tag };