import textoTermosDeUso from "@texto/textoTermosDeUso";
import React from 'react';

// css
import './TermosDeUso.css';

const TermosDeUso = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
	} = props;

	return (
		<>
			<div className={`page-termosuso ${wrap} ${classNameWrap}`}>
				<div className={`page-termosuso-box ${className}`}>
					<div className="base-m">
						<div className="base-content">{textoTermosDeUso()}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { TermosDeUso };