import React from 'react';

// CSS
import c from './RequestInfo.module.scss';

// Cpnt
import Info from "@_cpnt/Info/Info";

export const RequestInfo = props => {
	const {
		className = '',
		req: {
			msg = 'Nenhuma informação disponível',
		},
	} = props;

	return (
		<div className={`${className} ${c['container']}`}>
			<Info
				icone="error"
				texto={msg}
			/>
		</div>
	);
}
