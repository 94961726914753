import React, { useContext, useEffect, useState } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalTemplateFormCadastro.css';

// API
import apiTemplates from "@api/templates/templates";
import { apiGetEmpresas, apiGetTemplatesTipo } from '@apiGeral/geral';

// Partes
import { FormTemplate } from "@page/Logado/Templates/FormTemplate/FormTemplate";

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { camposInvalidos, formatarSelect, msg, removerKeysVazias } from '@func/utils';
import apiProfessions from "@api/professions/professions";
import { msgs } from "@_func/request";

const PortalTemplateFormCadastro = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-template-form-cadastro',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		title: '',
		text: '',
		excerpt: '',
		id_type: 0,
		active: 1,
		metadata: {},
		id_company: 0,

		id_profession: null,

		// Metadata separado
		chat_titulo: '',
		chat_cor: 'default',
	};

	// Cadastro
	const [loadingCadastrarTemplate, setLoadingCadastrarTemplate] = useState(false);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);
	const [loadingGetTemplatesTipo, setLoadingGetTemplatesTipo] = useState(false);
	const [loadingGetProfissoes, setLoadingGetProfissoes] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [stateEmpresas, setStateEmpresas] = useState([]);
	const [stateTemplatesTipo, setStateTemplatesTipo] = useState([]);
	const [stateProfissoes, setStateProfissoes] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** API - Get empresas
	** ================================================== */
	const inicializarGets = async () => {
		apiGetTemplatesTipo({
			...(store.usuario.role !== 1 ? {
				filtros: {
					enable_in_company: 1,
				}
			} : {}),
			setLoadingGetTemplatesTipo,
			setStateTemplatesTipo
		});

		if(store.usuario.role === 1) {
			apiGetEmpresas({setLoadingGetEmpresas, setStateEmpresas});

			const retProfissoes = await apiProfessions.all.get({
				// id_company: 1,
				active: 1,
				limit: 999,
			});

			if(retProfissoes.status === 200) {
				setStateProfissoes(formatarSelect({
					dados: retProfissoes.data,
					keyValue: 'id',
					keyName: 'title',
				}));
			} else {
				msgs([retProfissoes]);
			}
		}
	}

	/*
	** Editar cliente
	** ================================================== */
	const cadastrarTemplate = async e => {
		e.preventDefault();

		if(loadingCadastrarTemplate) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			...stateDados,
		};

		if(camposInvalidos(dadosEnviar, {
			title: 'Título',
			text: 'Texto do template',
			excerpt: 'Resumo do template',
			id_type: 'Tipo do template',
		}, [
			'title',
			'text',
			'excerpt',
			'id_type',
		])) return;

		var dadosSemKeysVazias = removerKeysVazias(dadosEnviar);

		/*
		** Caso seja tipo chat
		** ================================================== */

		// Tabela: crowd_template_types
		// id: 1  =  tipo: Propostas de jobs
		// id: 2  =  tipo: Pagamentos de jobs
		// id: 3  =  tipo: Termos de uso e condições
		// id: 4  =  tipo: Carta de apresentação de usuários
		// id: 5  =  tipo: Carta de apresentação de freelancers
		// id: 6  =  tipo: Mensagens personalizadas do chat
		// id: 7  =  tipo: Contratos de empresas
		// id: 8  =  tipo: Contratos de freelancers
		// id: 11  =  tipo: Mensagens gerais

		if(stateDados.id_type === 6) {
			if(camposInvalidos(dadosEnviar, {
				chat_titulo: 'Título da mensagem do chat',
				chat_cor: 'Cor da mensagem',
			}, [
				'chat_titulo',
				'chat_cor',
			])) return;

			dadosSemKeysVazias.metadata = {
				title: stateDados.chat_titulo,
				type: stateDados.chat_cor,
			};
		}

		if(stateDados.id_type === 13 || stateDados.id_type === 14) { // LITE
			dadosSemKeysVazias.id_profession = stateDados.id_profession;

			if(camposInvalidos(dadosSemKeysVazias, {
				id_profession: 'Profissão para cadastro de perfil lite',
			}, [
				'id_profession',
			])) return;
		}

		dadosSemKeysVazias.active = stateDados.active;

		setLoadingCadastrarTemplate(true);

		const retInsertTemplate = await apiTemplates.insert.post(dadosSemKeysVazias);

		if(retInsertTemplate.status === 200) {
			msg.success('Template cadastrado com sucesso.');
			onSuccess({
				ret: retInsertTemplate,
			});
			setTimeout(() => {
				setStateDados(camposDefault);
			}, 500);
		} else {
			msgs([retInsertTemplate]);
			onError({
				ret: retInsertTemplate,
			});
		}

		setLoadingCadastrarTemplate(false);
	}

	const listaTipoTemplate = () => {
		var retorno = stateTemplatesTipo;
		if(stateDados.id_company === 0 && store.usuario.role === 1) {
			retorno = [];
			stateTemplatesTipo.map((val, key) => {
				if(val.id === 5 || val.enable_in_company !== 0)  {
					retorno.push(val);
				}
			})
		} else if(store.usuario.role === 1) {
			retorno = [];
			stateTemplatesTipo.map((val, key) => {
				if(val.id !== 5)  {
					retorno.push(val);
				}
			})
		}
		return retorno
	}

	return (
		<Portal
			titulo="Cadastrar template"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<div className={wrap}>
				<div className={className}>
					<FormTemplate
						isCadastro={true}
						onSubmit={cadastrarTemplate}
						stateDados={stateDados}
						setStateDados={setStateDados}
						stateTemplatesTipo={listaTipoTemplate()}
						loadingGetTemplatesTipo={loadingGetTemplatesTipo}
						loadingGetEmpresas={loadingGetEmpresas}
						stateEmpresas={[
							{value: 0, label: <strong>Template público (todos terão acesso)</strong>},
							...stateEmpresas
						]}
						loadingGetProfissoes={loadingGetProfissoes}
						stateProfissoes={stateProfissoes}
						after={
							<div className="form-bottom">
								<Button2
									_={{
										value: 'Cancelar',
										className: 'btn btn-1',
										type: 'button',
										onClick: onClose,
									}}
									classExtra="btn-1"
								/>
								<Button2
									_={{
										value: 'Cadastrar template',
										onClick: cadastrarTemplate
									}}
									loading={loadingCadastrarTemplate}
									wrap="margin-l-15"
								/>
							</div>
						}
					/>
				</div>
			</div>
		</Portal>
	);
};

export default PortalTemplateFormCadastro;