import React from 'react';

// Components
import Icone from '@cpnt/Icone/Icone';

// Imagens
import { primeiroNome } from "@_func/regex";
import imgIconeBemVindo from '@svg/light/message-smile.svg';

const PassoBemVindo = props => {

	const {
		stateDados,
		classNameWrap = '',
		after,
	} = props;

	return (
		<div className={classNameWrap}>
			<div>
				<div className="flex flex-center">
					<Icone
						imagem={imgIconeBemVindo}
						tamanho="180px"
						cor="#0277BD"
					/>
				</div>
				<div className="align-center fs-16">
					<h1 className="fs-24">Olá <span className="underline color-4">{primeiroNome(stateDados.name)}</span>, seja bem vindo!</h1>
					<p>Aqui você finaliza o preenchimento do seu perfil. Com seu perfil em dia, você está pronto para receber propostas de jobs.</p>
					<p>Não se preocupe, o processo é <strong>bem rápido</strong> e prático. O quanto antes você terminar, mais rápido iniciará seus jobs!</p>
					{after}
				</div>
			</div>

		</div>
	);
}

export { PassoBemVindo };
