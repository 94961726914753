import React, { useRef } from 'react';

import Icone from "@cpnt/Icone/Icone";
import Tip from "@cpnt/Tip/Tip";

// css
import './IndiqueShare.css';

import imgIconeLink from '@svg/regular/link.svg';
import imgIconeWhatsApp from '@svg/brands/whatsapp-square.svg';
import imgIconeFacebook from '@svg/brands/facebook.svg';
import imgIconeLinkedin from '@svg/brands/linkedin.svg';
import imgIconeTweeter from '@svg/brands/twitter-square.svg';
import imgIconeEmail from '@svg/solid/envelope.svg';
import imgIconeCopiar from '@svg/regular/copy.svg';
import { msg } from "@func/utils";

const IndiqueShare = props => {
	const refLinkIndicacao = useRef();

	const {
		texto,
	} = props;

	const abrirLink = async (tipo) => {

		const links = {
			whatsapp: `https://wa.me/?text=`,
			facebook: `https://www.facebook.com/sharer/sharer.php?u=`,
			linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=`,
			tweeter: `https://twitter.com/intent/tweet?url=`,
			email: `mailto:?body=`,
		}

		if(!links[tipo]) {
			msg.warning('Tipo de compartilhamento não identificado');
			return;
		}

		var linkTipo = `${links[tipo]}${texto}`;

		refLinkIndicacao.current.href = linkTipo;
		refLinkIndicacao.current.click();
	}

	const copiar = tipo => {
		navigator.clipboard.writeText(texto);
		msg.success('Link copiado com sucesso');
	}

	return (
		<>
			<div className="cpnt-indiqshare-wrap">
				<Tip tip={<Icone imagem={imgIconeLink} tamanho="28px" cor="#0054a6" onClick={copiar} className="cursor-pointer" />}>Copiar link</Tip>
				<Tip tip={<Icone imagem={imgIconeWhatsApp} tamanho="28px" cor="#34af23" onClick={e => abrirLink('whatsapp')} className="cursor-pointer" />}>Compartilhar no WhatsApp</Tip>
				<Tip tip={<Icone imagem={imgIconeFacebook} tamanho="28px" cor="#3b5998" onClick={e => abrirLink('facebook')} className="cursor-pointer" />}>Compartilhar no Facebook</Tip>
				<Tip tip={<Icone imagem={imgIconeLinkedin} tamanho="28px" cor="#007bb6" onClick={e => abrirLink('linkedin')} className="cursor-pointer" />}>Compartilhar no LinkedIn</Tip>
				<Tip tip={<Icone imagem={imgIconeTweeter} tamanho="28px" cor="#00aced" onClick={e => abrirLink('tweeter')} className="cursor-pointer" />}>Compartilhar no Tweeter</Tip>
				<Tip tip={<Icone imagem={imgIconeEmail} tamanho="28px" cor="#f4ae01" onClick={e => abrirLink('email')} className="cursor-pointer" />}>Envia por e-mail</Tip>
			</div>
			<a href="#linkindicacao" target="_blank" rel="noreferrer" ref={refLinkIndicacao} className="none">Link</a>
		</>
	);
};

export { IndiqueShare };