import React, { useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// Config
import { TITULO_PAGINA_PREFIXO, TITULO_PAGINA_DEFAULT } from '@_config/config';
import { TopoPage } from "./TopoPage/TopoPage";
import { FooterPage } from "./FooterPage/FooterPage";

const LayoutPage = (props) => {

	const [store, setStore] = useContext(StoreContext);

	var titulo = TITULO_PAGINA_DEFAULT;
	if(props.title) {
		titulo = props.title;
	}

	// window.document.title = titulo;

	const Cpnt = props.component;

	return (
		<div className={`container-layoutpage ${props.classContainer ? props.classContainer : ''}`}>
			<TopoPage {...props} />
			<Cpnt {...props} />
			<FooterPage {...props} />
		</div>
	);
}

export { LayoutPage };
