import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from "@/_/contexts/StoreContext";

// CSS
import './BuscarProfissionais.css';

// API
import apiAlgolia from '@api/algolia/algolia';
import { apiGetAreas, apiGetCompetencias, apiGetEstados, apiGetHabilidades, apiGetIdiomas, apiGetObjetivos, apiGetSegmentos, apiValidarAcesso } from '@apiGeral/geral';

// Componentes
import { FiltrosProfissionais } from '@cpnt/FiltrosProfissionais/FiltrosProfissionais';
import FiltrosWrap from '@cpnt/FiltrosWrap/FiltrosWrap';
import { ListaProfissionais } from '@cpnt/ListaProfissionais/ListaProfissionais';

// Funções
import { conveterRealParaNumero, isNumeroDiferente, msg } from '@func/utils';
import { getLabelState } from '@shared/functions/components';

// Local
import { desselecionarTodos, getAllUsuariosLocal, initListaLocal, listasLocal, onClickRemover, selecionarTodos, selecionarUm } from '@func/selecaoUsuariosBriefings';

// Rotas
import { clearGet, gets, initFuncExec, setGet } from '@route/routeUtils';

// Objetos
import { LIMITE_QTD_FREELANCERS_NOVO_BRIEFING } from "@_config/config";
import { SENIORIDADE } from '@config/objetos';
import AvisoAcessoRestrito from "@cpnt/AvisoAcessoRestrito/AvisoAcessoRestrito";
import { temBriefingSelecionadoLocal } from "@func/local";
import { msgStatus } from "@_func/request";
import { Btn } from "@_cpnt/Btn/Btn/Btn";

const BuscarProfissionais = props => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();
	const location = useLocation();

	/*
	** A função de getProfissionais executada ao iniciar
	** precisa que todas as outras funções já tenham sido
	** carregadas. o countRef serve para contar quantas foram
	** executadas. Atualmente dispara no 7
	** ================================================== */
	const countRef = useRef(0);

	/*
	** Validar acesso
	** ================================================== */
	const [stateAcesso, setStateAcesso] = useState({});
	const [stateIsAcessoValido, setStateIsAcessoValido] = useState(true);

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = async (getsAtualizados = getsDefault) => {
		const validarAcesso = await apiValidarAcesso({setStateAcesso, setStateIsAcessoValido});
		if(validarAcesso.sucesso) {
			initFuncExec([
				{
					func: getCamposAlgoliaInit,
					condicao: countRef.current === 0,
				},
				{
					func: getProfissionais,
					param: getsAtualizados,
					condicao: countRef.current > 0,
				},
				{
					func: apiGetHabilidades,
					param: {
						idArea: getsAtualizados.area,
						setLoadingGetHabilidades,
						setStateHabilidades,
					},
					condicao: isNumeroDiferente(stateGet.area, getsAtualizados.area),
				},
			], init, location, stateLocation);
		}
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		overlay: [],
		limit: 50,
		page: 1,

		area: null,
		skills: null,
		seniority: null,
		state: null,
		city: '',
		languages: null,
		competences: null,
		goals: null,
		segments: null,
		text: '',
		price_min: '',
		price_max: '',
		use_price: false,
		availability: null,
		all_results: null,
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	/*
	** Lista local
	** ================================================== */
	useEffect(() => {
		initListaLocal();
		setStateListas(listasLocal());
	}, []);

	/*
	** COMPONENTES DE LISTA LOCAL
	** ================================================== */

	/*
	** Lista local
	** ================================================== */
	const [stateListaAtual, setStateListaAtual] = useState('Lista padrão');
	const [stateListas, setStateListas] = useState([]);
	const [stateSelecionados, setStateSelecionados] = useState(getAllUsuariosLocal(stateListaAtual));

	/*
	** Loadings
	** ================================================== */
	const [loadingGetProfissionais, setLoadingGetProfissionais] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateProfissionais, setStateProfissionais] = useState([]);
	const [_stateProfissionais, _setStateProfissionais] = useState({});
	const [stateProfissionaisMetadata, setStateProfissionaisMetadata] = useState({});
	const [stateProfissionaisSelecionados, setStateProfissionaisSelecionados] = useState([]);
	const [temBriefingSelecionado, setTemBriefingSelecionado] = useState(temBriefingSelecionadoLocal());

	/*
	** COMPONENTES DE PESQUISA - FILTROS
	** ================================================== */

	/*
	** Filtros usados no componente de pesquisa
	** ================================================== */
	const [stateEstados, setStateEstados] = useState([]);
	const [stateIdiomas, setStateIdiomas] = useState([]);
	const [stateCompetencias, setStateCompetencias] = useState([]);
	const [stateObjetivos, setStateObjetivos] = useState([]);
	const [stateAreas, setStateAreas] = useState([]);
	const [stateHabilidades, setStateHabilidades] = useState([]);
	const [stateSegmentos, setStateSegmentos] = useState([]);

	/*
	** Loading
	** ================================================== */
	const [loadingGetEstados, setLoadingGetEstados] = useState(false);
	const [loadingGetIdiomas, setLoadingGetIdiomas] = useState(false);
	const [loadingGetCompetencias, setLoadingGetCompetencias] = useState(false);
	const [loadingGetObjetivos, setLoadingGetObjetivos] = useState(false);
	const [loadingGetAreas, setLoadingGetAreas] = useState(false);
	const [loadingGetHabilidades, setLoadingGetHabilidades] = useState(false);
	const [loadingGetSegmentos, setLoadingGetSegmentos] = useState(false);

	/*
	** INIT - Inicializa o Algolia
	** ================================================== */
	const getCamposAlgoliaInit = async () => {
		await apiGetAreas({setLoadingGetAreas, setStateAreas});
		await apiGetEstados({setLoadingGetEstados, setStateEstados});
		await apiGetIdiomas({setLoadingGetIdiomas, setStateIdiomas});
		await apiGetCompetencias({setLoadingGetCompetencias, setStateCompetencias});
		await apiGetObjetivos({setLoadingGetObjetivos, setStateObjetivos});
		await apiGetSegmentos({setLoadingGetSegmentos, setStateSegmentos});
		getProfissionais(getsDefault);
		countRef.current++;
	}

	/*
	** API - Get Projetos
	** ================================================== */
	const getProfissionais = async (dadosObj = {}) => {

		const dadosEnviarObj = {
			...gets(),
			...dadosObj,
		}

		var dadosEnviar = {
			...dadosEnviarObj,
			area: getLabelState(stateAreas, dadosEnviarObj.area, 'value'),
			seniority: getLabelState(SENIORIDADE, dadosEnviarObj.seniority, 'value'),
			state: getLabelState(stateEstados, dadosEnviarObj.state, 'value', 'uf'),
			languages: getLabelState(stateIdiomas, dadosEnviarObj.languages, 'value'),
			competences: getLabelState(stateCompetencias, dadosEnviarObj.competences, 'value'),
			segments: getLabelState(stateSegmentos, dadosEnviarObj.segments, 'value'),
			skills: getLabelState(stateHabilidades, dadosEnviarObj.skills, 'value'),
			goals: getLabelState(stateObjetivos, dadosEnviarObj.goals, 'value'),
		};

		var dadosEnviarClear = clearGet(dadosEnviar);

		/*
		** Caso seja para usar o valor por hora
		** ================================================== */
		if(dadosEnviarObj.use_price) {
			dadosEnviarClear.price_lower = conveterRealParaNumero(dadosEnviarClear.price_min);
			dadosEnviarClear.price_higher = conveterRealParaNumero(dadosEnviarClear.price_max);
		} else {
			delete dadosEnviarClear.price_min;
			delete dadosEnviarClear.price_max;
		}

		if(dadosEnviarClear.all_results === 'sim') {
			dadosEnviarClear.all_results = 1;
		} else if(dadosEnviarClear.all_results === 'nao') {
			dadosEnviarClear.all_results = 0;
		} else {
			delete dadosEnviarClear.all_results;
		}

		/*
		** API
		** ================================================== */
		setLoadingGetProfissionais(true);

		const retGetProfissionais = await apiAlgolia.withoutRelevance.post(dadosEnviarClear);

		_setStateProfissionais(msgStatus(retGetProfissionais));

		if(retGetProfissionais.status === 200) {
			setStateProfissionais(retGetProfissionais.data);
			setStateProfissionaisMetadata(retGetProfissionais.metadata);
		} else {
			setStateProfissionais([]);
			setStateProfissionaisMetadata({});
		}

		setLoadingGetProfissionais(false);
	}

	const onClickBtnCadastrar = () => {
		if(!stateSelecionados || !stateSelecionados.length) {
			msg.error('É necessário selecionar pelo menos um profissional para a proposta de job.');
			return;
		}

		if(store.usuario.id_company !== 1 && stateSelecionados.length > LIMITE_QTD_FREELANCERS_NOVO_BRIEFING) {
			msg.error(`Você ultrapassou o limite máximo de ${LIMITE_QTD_FREELANCERS_NOVO_BRIEFING} profissionais por proposta de job. Remova o excedente para continuar com a publicação da proposta.`);
			return;
		}

		navigate('/briefing/cadastrar-selecionados');
	}

	return (
		<div className="pg-buscarprof base-full">
			<div className="conteudo-box">
				<div className="titulo-box titulo-box-res">
					<div className="flex flex-column">
						<h3 className="titulo">Busca com IA</h3>
					</div>
					<Btn
						_={{
							value: temBriefingSelecionado ? 'Continuar cadastrando Briefing/Vaga' : 'Avançar com Profissionais',
							onClick: onClickBtnCadastrar
						}}
						size="m"
					/>
				</div>

				<FiltrosWrap>
					<FiltrosProfissionais
						// Rota
						stateGet={stateGet}
						getsDefault={getsDefault}

						// States
						stateEstados={stateEstados}
						stateIdiomas={stateIdiomas}
						stateCompetencias={stateCompetencias}
						stateObjetivos={stateObjetivos}
						stateAreas={stateAreas}
						stateHabilidades={stateHabilidades}
						stateSegmentos={stateSegmentos}

						// Loadings
						loadingGetEstados={loadingGetEstados}
						loadingGetIdiomas={loadingGetIdiomas}
						loadingGetCompetencias={loadingGetCompetencias}
						loadingGetObjetivos={loadingGetObjetivos}
						loadingGetAreas={loadingGetAreas}
						loadingGetHabilidades={loadingGetHabilidades}
						loadingGetSegmentos={loadingGetSegmentos}
					/>
				</FiltrosWrap>

				{!stateIsAcessoValido && !loadingGetProfissionais ? (
					<AvisoAcessoRestrito {...stateAcesso} />
				) : (
					<ListaProfissionais
						// Rota
						init={init}
						stateGet={stateGet}
						getsDefault={getsDefault}
						navigate={navigate}

						// default
						stateProfissionais={stateProfissionais}
						setStateProfissionais={setStateProfissionais}
						stateProfissionaisSelecionados={stateProfissionaisSelecionados}
						setStateProfissionaisSelecionados={setStateProfissionaisSelecionados}
						_stateProfissionais={_stateProfissionais}
						_setStateProfissionais={_setStateProfissionais}
						stateProfissionaisMetadata={stateProfissionaisMetadata}
						setStateProfissionaisMetadata={setStateProfissionaisMetadata}
						loadingGetProfissionais={loadingGetProfissionais}
						setLoadingGetProfissionais={setLoadingGetProfissionais}

						// Lista local
						stateListaAtual={stateListaAtual}
						setStateListaAtual={setStateListaAtual}
						stateListas={stateListas}
						setStateListas={setStateListas}
						stateSelecionados={stateSelecionados}
						setStateSelecionados={setStateSelecionados}

						// Ações da lista
						selecionarUm={selecionarUm}
						selecionarTodos={selecionarTodos}
						desselecionarTodos={desselecionarTodos}
						onClickRemover={onClickRemover}
					/>
				)}
			</div>
		</div>
	);
}

export default BuscarProfissionais;
