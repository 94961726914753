import { useRoutes } from 'react-router-dom';

// Admin
import LayoutInicio from '@role/Layouts/LayoutInicio/LayoutInicio';
import LayoutInicioTexto from "@role/Layouts/LayoutInicioTexto/LayoutInicioTexto";

// Screens
import AtivarCadastroUsuario from "@page/Deslogado/AtivarCadastroUsuario/AtivarCadastroUsuario";
import Cadastro from "@page/Deslogado/Cadastro/Cadastro";
import Erro404Deslogado from "@page/Deslogado/Erro404Deslogado/Erro404Deslogado";
import Login from "@page/Deslogado/Login/Login";
import RecuperarSenha from "@page/Deslogado/RecuperarSenha/RecuperarSenha";
import RedefinirSenha from "@page/Deslogado/RedefinirSenha/RedefinirSenha";
import Logout from "@page/SemRestricoes/Logout/Logout";
import { CadastroIndicacaoTexto } from "@texto/CadastroIndicacaoTexto";

export const ROTAS_GERAIS = [
	{
		path: '/',
		element:
		<LayoutInicio
			title="Login"
			component={Login}
		/>,
	},
	{
		path: '/login',
		element:
		<LayoutInicio
			title="Login"
			component={Login}
		/>,
	},
	{
		path: '/recuperar-senha',
		element:
		<LayoutInicio
			title="Recuperar senha"
			component={RecuperarSenha}
		/>,
	},
	{
		path: '/redefinir-senha',
		element:
		<LayoutInicio
			title="Redefinir senha"
			component={RedefinirSenha}
		/>,
	},
	{
		path: '/ativar-cadastro',
		element:
		<LayoutInicio
			title="Ativar cadastro"
			component={AtivarCadastroUsuario}
		/>,
	},
	{
		path: '/cadastre-se',
		element:
		<LayoutInicio
			title="Cadastro"
			component={Cadastro}
		/>,
	},
	{
		path: '/cadastro-freelancer-por-convite',
		element:
		<LayoutInicioTexto
			title="Cadastro de freelancer por convite"
			component={Cadastro}
			texto={CadastroIndicacaoTexto}
			container={{
				className: 'tela-inicio-res-alt'
			}}
		/>,
	},
	{
		path: '/logout',
		element:
		<Logout
			title="Fazendo logout, aguarde..."
		/>,
	},
	{
		path: '*',
		element:
		<Erro404Deslogado
			title="Página não encontrada..."
			component={Erro404Deslogado}
		/>,
	}
];

function ScreensGerais() {
	const rotasGerais = useRoutes(ROTAS_GERAIS);
	return rotasGerais;
}

export { ScreensGerais };