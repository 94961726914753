// Func
import { dadosSelect } from "@_func/components";
import { msgs, apiObj } from "@_func/request";
import { apiProfessions } from "./professions";

export const apiProfessionsGeral = {
	all: {
		get: async ({data, setLoadingGetProfissoes, setStateProfissoes, showMsg}) => {
			setLoadingGetProfissoes(true);

			const req = await apiProfessions.all.get(data);
			const ret = apiObj(req);

			if(ret.status === 200) {
				ret.data = dadosSelect({
					data: ret.data,
					value: 'title',
				});
			} else {
				if(showMsg) {
					msgs([ret]);
				}
			}

			setStateProfissoes(ret);
			setLoadingGetProfissoes(false);
			return ret;
		}
	},
};
