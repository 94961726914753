import _ from 'lodash';

const LISTA_PIX = [
	{
		value: 'cpf',
		label: 'CPF',
	},
	{
		value: 'cnpj',
		label: 'CNPJ',
	},
	{
		value: 'email',
		label: 'E-mail',
	},
	{
		value: 'celular',
		label: 'Celular',
	},
	// {
	// 	value: 'aleatorio',
	// 	label: 'Chave Aleatória',
	// },
];

export const pixExibicao = e => {
	var nomeExibicao = _.find(LISTA_PIX, {value: e});
	if(nomeExibicao) {
		return nomeExibicao.label;
	}
	return 'Não identificado';
}

export { LISTA_PIX };
