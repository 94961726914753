import React, { useEffect, useRef, useState } from 'react';

// CSS
import './SelecaoIdiomasNiveis.css';

// Componentes
import Checkbox2 from '@cpnt/Checkbox2/Checkbox2';
import Input2 from '@cpnt/Input2/Input2';
import Radio2 from '@cpnt/Radio2/Radio2';
import { stringPesquisa } from "@func/utils";

const SelecaoIdiomasNiveis = props => {
	const {
		itens = [],
		itensSub = [],
		onChange,
	} = props;

	const refPesquisa = useRef();
	const [itensExibicao, setItensExibicao] = useState(itens);

	useEffect(() => {
		setItensExibicao(itens);
		filtrarItens(refPesquisa.current.value);
	}, [itens]);

	const filtrarItens = termo => {
		var itensFiltrados = itens.filter(value => {
			var textoUsuario = stringPesquisa(termo);
			var textoExistente = stringPesquisa(value.name_language);

			if(textoExistente.indexOf(textoUsuario) > -1) {
				return textoExistente;
			}
		});
		setItensExibicao(itensFiltrados);
	}

	const marcarCampo = e => {
		const isChecked = e.evento.target.checked;
		const itensAtualizados = itens.map((val, key) => {
			if(val.id_language === e.dados.id_language) {
				if(isChecked) {
					return {
						...val,
						checked: true,
					}
				} else {
					return {
						...val,
						checked: false,
						id_level: 0
					}
				}
			}
			return val;
		});
		onChange(itensAtualizados);
	}

	const marcarNivel = e => {
		const itensAtualizados = itens.map((val, key) => {
			if(val.id_language === e.dados.id_language) {
				return {
					...val,
					checked: true,
					id_level: e.nivel.id,
					name_level: e.nivel.name,
				}
			}
			return val;
		});
		onChange(itensAtualizados);
	}

	return (
		<>
			<div className="margin-b-20">
				<Input2
					label="Pesquisar idioma"
					_={{
						onChange: e => filtrarItens(e.target.value),
						type: 'search',
						autoFocus: true,
						ref: refPesquisa,
					}}
					debounce={0}
				/>
			</div>
			<div className="cpnt-selidiomas-container">
				{!itensExibicao.length ?
					<h3 className="msg">Nenhuma informação encontrada</h3>
				:
					itensExibicao.map((value, key) => {
						return (
							<form key={key} className="cpnt-selidiomas-box">
								<div className={`flex flex-center-v relative ${itensSub === false ? 'cpnt-selidiomas-sem-niveis' : ''}`}>
									<div>
										<Checkbox2
											label={value.name_language}
											_={{
												checked: value.checked,
												onChange: e => marcarCampo({
													evento: e,
													dados: value,
												})
											}}
											wrap="cpnt-selidiomas-check"
										/>
									</div>
								</div>
								{itensSub && itensSub.length &&
									<div className="cpnt-selidiomas-nivel-container flex flex-center-v padding-v-10 padding-h-10 padding-b-10">
										<div className="cpnt-selidiomas-nivel-titulo fs-15">Nível:</div>
										<div className="cpnt-selidiomas-nivel-opts flex">
											{itensSub.map((value2, key2) => {
												var checked = false;
												if(value.id_level === value2.id) {
													checked = true;
												}
												return (
													<Radio2
														key={`2-${key2}`}
														label={value2.name}
														_={{
															name: 'nivelIdioma',
															checked: checked,
															onChange: e => marcarNivel({
																evento: e,
																dados: value,
																nivel: value2,
															})
														}}
														wrap="radio-small-text margin-l-20"
													/>
												)
											})}
										</div>
									</div>
								}
							</form>
						)
					})
				}
			</div>
		</>
	);
};

export { SelecaoIdiomasNiveis };
