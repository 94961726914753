import React, { useEffect, useState } from 'react';

// css
import './PortalProfissoesPredefinidasDetalhes.css';

// API
import apiProfessions from "@api/professions/professions";
import { apiGetTemplate } from "@api/_functions/geral";

// Imagens
import imgIconePesquisa from '@svg/solid/magnifying-glass.svg';

// Partes
import { DetalhesProfissoesPredefinidas } from "@page/Logado/ProfissoesPredefinidas/DetalhesProfissoesPredefinidas/DetalhesProfissoesPredefinidas";

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';
import TextoIconeLink from "@cpnt/TextoIconeLink/TextoIconeLink";

// Funções

import { msgs, msgStatus } from "@_func/request";

const PortalProfissoesPredefinidasDetalhes = props => {

	const {
		id,
		show,
		onClose,
		closeOnEsc = false,
		portalClassName = 'portal-profissoes-detalhes',
		exibirMatch,
		onClickEditar,
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGetTemplate, setLoadingGetTemplate] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({});
	const [_stateDados, _setStateDados] = useState({});
	const [stateTemplate, setStateTemplate] = useState({});

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		} else {
			// Tempo da animação de fechar, limpa os dados
			setTimeout(() => {
				setStateDados(false);
			}, 500);
		}
	}

	/*
	** INIT
	** ================================================== */
	const inicializarGets = async () => {
		if(show === true) {
			const retGetItem = await apiProfessions.getOne.get(id);

			_setStateDados(msgStatus(retGetItem));

			if(retGetItem.status === 200) {
				setStateDados(retGetItem.data);
				if(retGetItem.data.id_template) {
					apiGetTemplate({id: retGetItem.data.id_template, setStateTemplate, setLoadingGetTemplate})
				}
			} else {
				msgs([retGetItem]);
			}

			setLoading(false);

		} else {
			// Tempo da animação de fechar, limpa os dados
			setTimeout(() => {
				setStateDados(false);
			}, 500);
		}
	}

	return (
		<Portal
			titulo="Detalhes das Configurações do Match"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				_stateDados.status === 200 ? (
					<DetalhesProfissoesPredefinidas
						stateDados={stateDados}
						stateTemplate={stateTemplate}
						loadingGetTemplate={loadingGetTemplate}
						after={
							<div className="form-bottom">
								<Button2
									_={{
										value: <TextoIconeLink texto="Simular pesquisa" icone={imgIconePesquisa} />,
										onClick: () => {
											exibirMatch({
												...stateDados,
												text: stateDados.keyword,
											});
										},
									}}
								/>
								<Button2
									_={{
										value: 'Fechar',
										onClick: onClose,
										className: 'btn btn-1',
									}}
									wrap="margin-l-auto"
								/>
								<Button2
									_={{
										value: 'Editar profissão',
										onClick: e => {
											onClickEditar(stateDados.id);
										}
									}}
									wrap="margin-l-10"
								/>
							</div>
						}
					/>
				) : (
					<Info
						texto={_stateDados.msg}
						icone="erro"
					/>
				)
			)}
		</Portal>
	);
};

export default PortalProfissoesPredefinidasDetalhes;