import React, { useContext, useState } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// Imagens
import imgIconeCancelado from '@svg/solid/circle-x.svg';
import imgIconeAprovado from '@svg/solid/circle-check.svg';
import imgContrato from '@img/outros/contrato-assinar.png';
import imgIconeDoubleCheck from '@svg/outros/doublecheck.svg';
import imgIconeContrato from '@svg/solid/file-signature.svg';

// Componentes
import Icone from '@cpnt/Icone/Icone';

// Funções
import { dataApiParaBrExtenso, formatarDinheiro, removerHtml } from '@func/utils';
import Foto from '@cpnt/Foto/Foto';
import LinhaItem from '@cpnt/LinhaItem/LinhaItem';
import Button2 from '@cpnt/Button2/Button2';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';
import Portal from '@cpnt/Portal/Portal';
import textoTermosDeUso from '@texto/textoTermosDeUso';

import { primeiroNome } from "@_func/regex";

const ChatMensagemContratoAssinado = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
		propria,
		wrap = '',

		name,
		photo,

		mensagem,
		enviarMensagem = () => null,
		type = 'default',
		data,
		dataExtenso,
		mensagemLida,
		metadata = {},
		onClickBtnRespostaProposta = () => null,

		setDetalhesMensagem = () => null,
		setShowDetalhesMensagem = () => null,
		idProposta,
	} = props;

	const [showPortalContrato, setShowPortalContrato] = useState(false);

	return (
		<div className={`cpnt-chatmsg cpnt-chatmsg-posicao-${propria ? 'right' : 'left'} cpnt-chatmsg-tipo-verde ${wrap}`}>
			<div className={`cpnt-chatmsg-user cpnt-chatmsg-user-${propria ? 'right' : 'left'}`}>
				<div>
					<Foto
						imagem={photo}
					/>
				</div>
				{name && name !== '' ? (
					<div className="cpnt-chatmsg-user-name max-w-100p">
						<div className="text-one-line">
							{primeiroNome(name)}
						</div>
					</div>
				) : null}
			</div>

			<div className={`cpnt-chatmsg-mensagem-box cpnt-chatmsg-mensagem-box-${propria ? 'right' : 'left'} ${className}`}>
				<div className={`cpnt-chatmsg-custom-titulo`}>
					<h3 className="margin-0 padding-0">Contrato assinado com sucesso</h3>
					<div className="fs-14">
						{metadata.titulo}
					</div>
				</div>

				<div className="cpnt-chatmsg-mensagem-contrato">
					<div className="cpnt-chatmsg-mensagem-infocontrato">
						<div className="cpnt-chatmsg-mensagem-infocontrato-info">

							<div>
								<LinhaItem
									titulo="Enviado em"
									desc={dataApiParaBrExtenso(props.created_at)}
									alt
								/>
								<LinhaItem
									titulo="Assinado em"
									desc={dataApiParaBrExtenso(props.created_at)}
									alt
								/>
								<LinhaItem
									titulo="ID do contrato"
									desc={"8FJ2F3XGW"}
									alt
								/>
								<LinhaItem
									titulo="ID da assinatura"
									desc={"1S0C42VQB"}
									alt
								/>
							</div>
							<div className="cpnt-chatmsg-mensagem-infocontrato-btn">
								<Button2
									_={{
										value: <TextoIconeLink texto="Visualizar contrato" icone={imgIconeContrato} iconeCor="#FFF" />,
										onClick: () => setShowPortalContrato(true),
									}}
								/>
							</div>
						</div>
						<div className="cpnt-chatmsg-mensagem-infocontrato-img">
							<img src={imgContrato} alt="contrato" className="block w-100p" />
						</div>
					</div>
				</div>

				<div className={`cpnt-chatmsg-footer cpnt-chatmsg-footer-${propria ? 'right' : 'left'}`}>
					<span>{dataExtenso || data}</span>
					{propria ? (
						<span className="margin-l-10">
							<Icone
								imagem={imgIconeDoubleCheck}
								cor={mensagemLida ? '#2596e1' : '#D5D5D5'}
								tamanho="14px"
							/>
						</span>
					) : null}
				</div>
			</div>

			{/* Termos do contrato */}
			<Portal
				show={showPortalContrato}
				onClose={() => {
					setShowPortalContrato(false);
				}}
				className="box-txt w-1000"
			>
				{textoTermosDeUso()}
			</Portal>
		</div>
	);
};

export default ChatMensagemContratoAssinado;