import { BtnAdd } from "@cpnt/BtnAdd/BtnAdd";
import React from 'react';

// css
import './BoxWrap2.css';

const BoxWrap2 = props => {
	const {
		className = '',
		classNameWrap = '',
		classNameDesc = '',
		wrap = '',
		titulo,
		children,
		btnTitulo,
		btnImg = true,
		btnReverso,
		onClickBtn,
	} = props;

	return (
		<>
			<div className={`cpnt-boxwrap2 ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-boxwrap2-box ${className}`}>
					<div className="cpnt-boxwrap2-titulo-wrap">
						{titulo ? (
							<h3 className="cpnt-boxwrap2-titulo">{titulo}</h3>
						) : null}
						{btnTitulo ? (
							<BtnAdd
								titulo={btnTitulo}
								img={btnImg}
								reverse={btnReverso}
								onClick={onClickBtn}
							/>
						) : null}
					</div>
					<div className={`cpnt-boxwrap2-desc ${classNameDesc}`}>{children}</div>
				</div>
			</div>
		</>
	);
};

export { BoxWrap2 };