import React, { useRef, useState, useEffect, useContext } from 'react';
import axios, { validarRetorno, validarRetornoAxios } from 'axios';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// Exportar para PDF
import { PDFDownloadLink } from '@react-pdf/renderer';

// css
import './PerfilFreelancer.css';

// Imagens
import imgLogoCrowd from '@img/logo.svg';
import imgIconeCheck from '@svg/solid/check.svg';
import imgIconeTelefone from '@svg/regular/phone.svg';
import imgIconeEmail from '@svg/solid/at.svg';
import imgIconeSite from '@svg/regular/earth-americas.svg';
import imgIconeVideoPlay from '@img/icones/play.png';
import imgIconeUsuario from '@svg/solid/user-lock.svg';
import imgIconeSenha from '@svg/regular/key.svg';
import imgIconePDF from '@svg/solid/file-pdf.svg';
import imgIconeAcoes from '@svg/solid/gear.svg';
import imgIconeExcluir from '@svg/solid/trash-can.svg';
import imgIconeLoading from '@imgdefault/loading-azul.svg';
import imgIconeDownload from '@svg/padrao/cloud-arrow-down.svg';
import imgUserPicDefault from '@img/userpic.png';
import imgIconePerfilExcluido from '@svg/solid/trash-can.svg';
import imgIconeDesativar from '@svg/solid/ban.svg';
import imgIconeInfo from '@svg/solid/circle-info.svg';
import imgDisponivel from '@fa/solid/shield-check.svg';

// Components
import Icone from '@cpnt/Icone/Icone';
import LinhaItem from '@cpnt/LinhaItem/LinhaItem';
import Bullet from '@cpnt/Bullet/Bullet';
import Loading from '@cpnt/Loading/Loading';
import stringImg from '@cpnt/stringImg/stringImg';
import TimelineVertical from '@cpnt/TimelineVertical/TimelineVertical';
import Portal from '@cpnt/Portal/Portal';
import Estrelas from '@cpnt/Estrelas/Estrelas';
import Avaliacoes from '@cpnt/Avaliacoes/Avaliacoes';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';
import Radio2 from '@cpnt/Radio2/Radio2';
import Input2 from '@cpnt/Input2/Input2';
import Button2 from '@cpnt/Button2/Button2';
import PortalConfirmacao from '@portalGeral/PortalConfirmacao/PortalConfirmacao';
import Select3 from '@cpnt/Select3/Select3';
import PerfilFreelancerPDF from '@portalGeral/PortalPerfil/PerfilFreelancerPDF/PerfilFreelancerPDF';
import PortalDadosContato from '@portalGeral/PortalDadosContato/PortalDadosContato';

// Parte
import FotoGlyph from '../FotoGlyph/FotoGlyph';

// Funções
import { addPrefixo, calcularAnos, caminhoFoto, dataApiParaBr, dataApiParaBrAno, dataApiParaBrExtenso, dataHaQuantoTempo, disponibilidadeFreelancer, formatarDinheiro, formatarTelefone, html, simNao, stringPesquisa, validarDataAPI, validarDataSegundosAPI } from '@func/utils';
import { DEV, URL_API_IMAGENS } from '@_config/config';
import { PortalFoto } from "../../PortalFoto/PortalFoto";

import { primeiroNome } from "@_func/regex";
import Tip from "@cpnt/Tip/Tip";

const PerfilFreelancer = props => {

	const [store, setStore] = useContext(StoreContext);
	const show = store.usuario.role === 1 || store.usuario.role === 3 ? true : false;

	const refVideoFreelancer = useRef(null);

	const {
		id,
		email,
		phone,
		city,
		price,
		active,
		availability,
		state,
		description,
		title,
		name,
		video,
		photo,
		birth_date,
		name_area,
		seniority,
		seniority_level,

		onClose,

		website,
		created_at,
		ratings = {},
		tasks = {},

		// States
		idiomas,
		redesSociais = [],
		habilidades = [],
		competencias = [],
		segmentos = [],
		experiencias = [],
		formacoes = [],
		certificados = [],
		premios = [],
		portfolios = [],
		objetivos = [],
		// dadosEmpresa,

		// Loading
		loadingGetRedesSociais,
		loadingGetIdiomas,
		loadingGetHabilidades,
		loadingGetCompetencias,
		loadingGetSegmentos,
		loadingGetExperiencias,
		loadingGetFormacoes,
		loadingGetCertificados,
		loadingGetPremios,
		loadingGetPortfolios,
		loadingGetObjetivos,
		// loadingGetDadosEmpresa,
	} = props;

	// Portais
	const [showPortalVideo, setShowPortalVideo] = useState(false);
	const [showPortalFoto, setShowPortalFoto] = useState(false);
	const [showPortalPDF, setShowPortalPDF] = useState(false);
	const [showPortalExcluirPerfil, setShowPortalExcluirPerfil] = useState(false);
	const [showPortalExcluirPerfilConfirmacao, setShowPortalExcluirPerfilConfirmacao] = useState(false);
	const [showPortalPerfilExcluido, setShowPortalPerfilExcluido] = useState(false);
	const [showPortalDadosContato, setShowPortalDadosContato] = useState(false);

	// States
	const [stateExcluirPerfilOpcao, setStateExcluirPerfilOpcao] = useState(false);
	const [stateExcluirPerfilDescricao, setStateExcluirPerfilDescricao] = useState('');

	const [stateFoto, setStateFoto] = useState(false);

	useEffect(() => {
		const fotoComURL = `${caminhoFoto(photo).url}?nocache`; // nocache \o/
		downloadFoto(fotoComURL, 'download');
	}, []);

	const downloadFoto = async fileUrl => {
		// const response = await axios({
		// 	method: 'GET',
		// 	url: fileUrl,
		// 	responseType: 'arraybuffer',
		// 	withCredentials: false,
		// 	headers: {
		// 		"Access-Control-Allow-Origin": "*",
		// 	}
		// })
		// .then(res => {
		// 	return `data:image/jpeg;base64,${Buffer.from(res.data, 'binary').toString('base64')}`;
		// })
		// .catch(erro => {
		// 	return `data:image/jpeg;base64,${Buffer.from(imgUserPicDefault, 'binary').toString('base64')}`;
		// });
		// setStateFoto(response);
		setStateFoto(fileUrl);
	};

	return (
		<div className="cpnt-perfil cpnt-perfil-freelancer">
			<div className="cpnt-perfil-wrap">
				<aside className="cpnt-perfil-sidebar">
					{name_area ? (
						<div className={`cpnt-perfil-freelancer-senioridade`}>
							{name_area}
						</div>
					) : null}
					<div className="cpnt-perfilfreelancer-img-container">
						<FotoGlyph
							foto={caminhoFoto(photo).url}
							wrap="cpnt-perfilfreelancer-img"
							{...(photo ? {
								onClick: () => setShowPortalFoto(true),
								className: 'cursor-pointer',
							} : {})}
						/>
						<div className="cpnt-perfil-info cpnt-perfilfreelancer-info-box">
							<div className="cpnt-perfilfreelancer-nome">{name}</div>
							<div className="cpnt-perfilfreelancer-infobox">
								{show ? (
									<>
										<div>{validarDataAPI(birth_date) ? `${calcularAnos(birth_date)} anos` : <i>Idade</i>}</div>
										<div className="cpnt-perfil-sep"></div>
									</>
								) : null}
								<div title={`${city || 'Cidade'} / ${state || 'Estado'}`} className="text-one-line cpnt-perfilfreelancer-infobox-estado">{city || <i>Cidade</i>} / {state || <i>Estado</i>}</div>
								<div className="cpnt-perfil-sep"></div>
								<div>{formatarDinheiro(price, 'R$ ')} /h</div>
							</div>

							<div className="flex flex-center margin-t-10">
								<Tip
									titulo="Avaliações"
									classNameTitulo="align-center"
									tip={
										<Estrelas
											qtd={ratings.total}
											corInativo="#AAA"
											tamanho="110px"
										/>
									}
								>
									<Avaliacoes
										ratings={ratings}
										tasks={tasks}
										tamanho="110px"
									/>
								</Tip>
							</div>

						</div>
					</div>

					<div className="cpnt-perfil-sidebar-inner">
						<div className="cpnt-perfil-secao">
							<div className="cpnt-perfil-info-inativo">
								<Icone
									imagem={active !== 1 ? imgIconeUsuario : imgDisponivel}
									cor={`${active !== 1 ? '#E74C3C' : 'var(--color-geren)'}`}
									tamanho="26px"
								/>
								<span className="margin-l-10 bold">{active !== 1 ? 'Indisponível para receber novas oportunidades' : 'Disponível para receber novas oportunidades'}</span>
							</div>
						</div>

						<div className="cpnt-perfil-secao">
							<h3 className="cpnt-perfil-titulo">Dados pessoais</h3>
							<div className="cpnt-perfil-secao-linhas">
								{show ? (
									<LinhaItem
										titulo="Data de nascimento"
										desc={validarDataAPI(birth_date) ? `${dataApiParaBr(birth_date)} (${dataHaQuantoTempo(birth_date)})` : 'Data não informada'}
										alt
									/>
								) : null}
								<LinhaItem
									titulo="Cadastrado em"
									desc={validarDataSegundosAPI(created_at) ? `${dataApiParaBr(created_at)} (${dataHaQuantoTempo(created_at, 'há ')})` : 'Não disponível' }
									alt
								/>
								<LinhaItem
									titulo="ID na plataforma"
									desc={id}
									alt
								/>
							</div>
						</div>

						<div className="cpnt-perfil-secao">
							<h3 className="cpnt-perfil-titulo">Segmentos de interesse</h3>
							<div>
								{loadingGetSegmentos ? (
									<Loading alt />
								) : (
									<>
										{segmentos && segmentos.length ? (
											<div className="wrap-3">
												<div className="flex flex-wrap">
													{segmentos.map((val, key) => {
														return (
															<div key={key} className="cpnt-perfil-tag-sidebar">
																{val.name}
															</div>
														)
													})}
												</div>
											</div>
										) : (
											<div>Nenhum segmento de interesse cadastrado.</div>
										)}
									</>
								)}
							</div>
						</div>

						<div className="cpnt-perfil-secao">
							<h3 className="cpnt-perfil-titulo">Habilidades profissionais</h3>
							{seniority_level ? (
								<div className="cpnt-perfil-habilidade-titulo">{seniority_level} em</div>
							) : null}
							{loadingGetHabilidades ? (
								<Loading alt />
							) : (
								!habilidades.length ? (
									<div>Nenhuma habilidade cadastrada.</div>
								) : (
									<div className="flex flex-wrap">
										{habilidades.map((val, key) => {
											return (
												<div key={key} className="cpnt-perfil-tag-sidebar">
													{val.name}
												</div>
											)
										})}
									</div>
								)
							)}
						</div>

						<div className="cpnt-perfil-secao">
							<h3 className="cpnt-perfil-titulo">Competências</h3>
							<div>
								{loadingGetCompetencias ? (
									<Loading alt />
								) : (
									<>
										{competencias && competencias.length ? (
											<div className="wrap-3">
												<div className="flex flex-wrap">
													{competencias.map((val, key) => {
														return (
															<div key={key} className="cpnt-perfil-tag-sidebar">
																{val.name}
															</div>
														)
													})}
												</div>
											</div>
										) : (
											<div>Nenhuma competência cadastrada.</div>
										)}
									</>
								)}
							</div>
						</div>

						<div className="cpnt-perfil-secao">
							<h3 className="cpnt-perfil-titulo">Idiomas</h3>
							{loadingGetIdiomas ? (
								<Loading alt />
							) : (
								<>
									{idiomas && idiomas.length ? (
										<div>
											{idiomas.map((val, key) => {
												return (
													<LinhaItem
														key={key}
														tituloSep=""
														titulo={val.language_name}
														desc={`(${val.level_name})`}
														alt
													/>
												)
											})}
										</div>
									) : (
										<div>Nenhuma rede social cadastrada.</div>
									)}
								</>
							)}
						</div>

						<div className="cpnt-perfil-secao">
							<h3 className="cpnt-perfil-titulo">Portfólio{portfolios.length > 1 ? 's' : ''}</h3>
							<div className="cpnt-perfil-descricao">
								{loadingGetPortfolios ? (
									<Loading alt />
								) : (
									<>
										{portfolios && portfolios.length ? (
											<div>
												{portfolios.map((val, key) => {
													return (
														<div key={key} className="text-one-line padding-b-3">
															<a href={addPrefixo(val.url)} target="_blank" rel="noreferrer" className="cpnt-perfil-link-sidebar">{val.url}</a>
														</div>
													)
												})}
											</div>
										) : (
											<div>Nenhum portfólio cadastrado.</div>
										)}
									</>
								)}
							</div>
						</div>

						{show ? (
							<div className="cpnt-perfil-secao">
								<h3 className="cpnt-perfil-titulo">Contato</h3>
								{phone ? (
									<div className="text-one-line padding-b-3">
										<LinhaItem
											titulo="Telefone"
											desc={phone ? formatarTelefone(phone) : 'não informado'}
											icone={imgIconeTelefone}
											className="flex flex-nowrap"
											alt
										/>
									</div>
								) : null}
								<div className="text-one-line padding-b-3">
									<LinhaItem
										titulo="E-mail"
										desc={email ? <a className="cpnt-perfil-link-sidebar" href={addPrefixo(email, 'mailto:')} target="_blank" rel="noreferrer">{email}</a> : 'não informado'}
										icone={imgIconeEmail}
										className="flex flex-nowrap"
										// classNameDesc="text-one-line"
										alt
									/>
								</div>
								{website ? (
									<div className="text-one-line padding-b-3">
										<LinhaItem
											titulo="Website"
											desc={website ? <a className="cpnt-perfil-link-sidebar" href={addPrefixo(website)} target="_blank" rel="noreferrer">{addPrefixo(website)}</a> : 'não informado'}
											icone={imgIconeSite}
											className="flex flex-nowrap padding-b-4"
											// classNameDesc="text-one-line"
											alt
										/>
									</div>
								) : null}
							</div>
						) : null}

						{show ? (
							<div className="cpnt-perfil-secao">
								<h3 className="cpnt-perfil-titulo">Redes sociais</h3>
								{loadingGetRedesSociais ? (
									<Loading alt />
								) : (
									<>
										{redesSociais && redesSociais.length ? (
											<div className="wrap-3">
												<div className="flex flex-wrap">
													{redesSociais.map((val, key) => {
														var imgObj = stringImg(val.url);
														return (
															<div key={key}>
																<div className="cpnt-perfil-rede-social-wrap">
																	<a href={val.url} target="_blank" rel="noreferrer" data-tip={addPrefixo(val.url)} data-for="tooltip-perfil-freelancer">
																		<Icone
																			imagem={imgObj.icone}
																			cor={imgObj.cor ? imgObj.cor : '#999'}
																			tamanho="31px"
																		/>
																	</a>
																</div>
															</div>
														)
													})}
												</div>
											</div>
										) : (
											<div>Nenhuma rede social cadastrada.</div>
										)}
									</>
								)}
							</div>
						) : null}
					</div>

				</aside>

				<div className="cpnt-perfil-conteudo">
					<div className="cpnt-perfil-secao">
						{store.usuario.role === 3 ? ( // TEMP
							<div className="cpnt-perfil-btn-pdf cursor-pointer" onClick={() => setShowPortalPDF(true)}>
								<div className="flex flex-center-v transition color-blue-hover">
									<Icone
										imagem={imgIconePDF}
										cor="#ad0b00"
										tamanho="15px"
									/>
									<span className="margin-l-3">Exportar para PDF</span>
								</div>
							</div>
						) : (
							store.usuario.role === 1 ? (
								<div className="cpnt-perfil-btn-acoes">
									<Select3
										customLabel={
											<div className="flex flex-center-v transition color-blue-hover cursor-pointer">
												<Icone
													imagem={imgIconeAcoes}
													cor="#2596e1"
													tamanho="15px"
												/>
												<span className="margin-l-3">Ações</span>
											</div>
										}
										classNameLabel="padding-b-5"
										classNameDropdown="no-wrap"
										options={[
											{value: 1, label: <TextoIconeLink icone={imgIconePDF} iconeCor="#ad0b00" texto="Exportar para PDF" />},
											{value: 2, label: <TextoIconeLink icone={imgIconeInfo} iconeCor="#0277BD" texto="Dados de contato" />},
											(DEV ? {titulo: <div className="strong padding-10">Gerenciar profissional</div>} : null),
											(DEV ? {value: 3, label: <TextoIconeLink icone={imgIconeUsuario} iconeCor="#000" texto="Alterar disponibilidade" />} : null),
											(DEV ? {value: 3, label: <TextoIconeLink icone={imgIconeSenha} iconeCor="#000" texto="Alterar senha" />} : null),
											(DEV ? {value: 4, label: <TextoIconeLink icone={imgIconeDesativar} iconeCor="#000" texto="Desativar acesso" />} : null),
											(DEV ? {value: 5, label: <TextoIconeLink icone={imgIconeExcluir} iconeCor="#F00" texto="Excluir perfil" />} : null),
										]}
										onClick={e => {
											switch (e.value) {
												case 1: setShowPortalPDF(true); break;
												case 2: setShowPortalDadosContato(true); break;
												case 5: setShowPortalExcluirPerfil(true); break;
												default: msg.warning('Funcionalidade indisponível no momento.'); break;
											}
										}}
									/>
								</div>
							) : null
						)}
						<h3 className="cpnt-perfil-titulo">Sobre {name}</h3>
						<div className="cpnt-perfil-video-texto-box">
							{show && video && video !== '' ? (
								<div className="cpnt-perfil-video-preview-box cursor-pointer" onClick={() => {
									setShowPortalVideo(true);
								}}>
									<div className="cpnt-perfil-video-icone-play">
										<Icone
											imagem={imgIconeVideoPlay}
											tamanho="50px"
											cor="#000"
										/>
									</div>
									<video className="cpnt-perfil-video-preview">
										<source src={`${URL_API_IMAGENS}/${video}`} type="video/webm" />
									</video>
								</div>
							) : null}
							<div className={`cpnt-perfil-video-texto ${!show ? 'padding-l-0' : ''}`}>
								<h3 className="margin-0">{title}</h3>
								<div className="margin-t-10">
									<LinhaItem
										titulo="Senioridade"
										desc={seniority_level}
										alt
									/>
								</div>
							</div>
						</div>
						<div className="relative">
							<div className="cpnt-perfildesc-glyph cpnt-perfildesc-glyph-1"></div>
							<div className="cpnt-perfildesc-glyph cpnt-perfildesc-glyph-2"></div>
							<div className="cpnt-perfildesc-glyph cpnt-perfildesc-glyph-3"></div>
							<div className="cpnt-perfildesc-glyph cpnt-perfildesc-glyph-4"></div>
							<div className="cpnt-perfil-descricao-perfil scroll scroll-alt">
								{description || 'Nenhuma informação adicionada.'}
							</div>
						</div>
					</div>

					<div className="cpnt-perfil-secao">
						<h3 className="cpnt-perfil-titulo">Experiência profissional</h3>
						<div>
							{loadingGetExperiencias ? (
								<Loading alt />
							) : (
								<>
									{experiencias && experiencias.length ? (
										<div className="padding-t-5">
											{experiencias.map((val, key) => {
												return (
													<TimelineVertical
														key={key}
														label={dataApiParaBrAno(val.start_date)}
														titulo={val.company}
														subtitulo={val.role}
														subtitulo2={`${dataApiParaBrExtenso(val.start_date)} até ${val.end_date ? dataApiParaBrExtenso(val.end_date) : 'o momento'}`}
														descricao={val.description}
														qtd={experiencias.length}
														primeiro={key === 0 ? true : false}
														ultimo={key === (experiencias.length - 1) ? true : false}
													/>
												)
											})}
										</div>
									) : (
										<div>Nenhuma experiência profissional cadastrada.</div>
									)}
								</>
							)}
						</div>
					</div>

					<div className="cpnt-perfil-secao">
						<h3 className="cpnt-perfil-titulo">Formações</h3>
						<div>
							{loadingGetFormacoes ? (
								<Loading alt />
							) : (
								<>
									{formacoes && formacoes.length ? (
										<div className="padding-t-5">
											{formacoes.map((val, key) => {
												return (
													<TimelineVertical
														key={key}
														label={dataApiParaBrAno(val.start_date)}
														titulo={val.company}
														subtitulo={val.role}
														subtitulo2={`${dataApiParaBrExtenso(val.start_date)} até ${val.end_date ? dataApiParaBrExtenso(val.end_date) : 'o momento'}`}
														descricao={val.description}
														qtd={formacoes.length}
														primeiro={key === 0 ? true : false}
														ultimo={key === (formacoes.length - 1) ? true : false}
													/>
												)
											})}
										</div>
									) : (
										<div>Nenhuma formação cadastrada.</div>
									)}
								</>
							)}
						</div>
					</div>

					<div className="cpnt-perfil-secao">
						<h3 className="cpnt-perfil-titulo">Certificados</h3>
						<div>
							{loadingGetCertificados ? (
								<Loading alt />
							) : (
								<>
									{certificados && certificados.length ? (
										<div className="padding-t-5">
											{certificados.map((val, key) => {
												return (
													<TimelineVertical
														key={key}
														label={dataApiParaBrAno(val.issue_date)}
														titulo={val.title}
														subtitulo2={dataApiParaBrExtenso(val.issue_date)}
														qtd={certificados.length}
														primeiro={key === 0 ? true : false}
														ultimo={key === (certificados.length - 1) ? true : false}
													/>
												)
											})}
										</div>
									) : (
										<div>Nenhum certificado cadastrado.</div>
									)}
								</>
							)}
						</div>
					</div>

					<div className="cpnt-perfil-secao">
						<h3 className="cpnt-perfil-titulo">Prêmios</h3>
						<div>
							{loadingGetPremios ? (
								<Loading alt />
							) : (
								<>
									{premios && premios.length ? (
										<div className="padding-t-5">
											{premios.map((val, key) => {
												return (
													<TimelineVertical
														key={key}
														label={dataApiParaBrAno(val.issue_date)}
														titulo={val.title}
														subtitulo2={dataApiParaBrExtenso(val.issue_date)}
														qtd={premios.length}
														primeiro={key === 0 ? true : false}
														ultimo={key === (premios.length - 1) ? true : false}
													/>
												)
											})}
										</div>
									) : (
										<div>Nenhum prêmio cadastrado.</div>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>

			<div className="cnpt-perfil-footer">
				<div className="cnpt-perfil-footer-wrap"><img src={imgLogoCrowd} alt="logo-crowd" /></div>
			</div>

			<Portal
				show={showPortalVideo}
				closeOnEsc={true}
				onClose={() => {
					setShowPortalVideo(false);
				}}
				className="w-900"
				classNameX="x"
			>
				<div className="cpnt-perfil-video-player-wrap">
					<video className="cpnt-perfil-video-player" controls>
						<source src={`${URL_API_IMAGENS}/${video}`} type="video/webm" ref={refVideoFreelancer} />
					</video>
				</div>
			</Portal>

			<PortalFoto
				show={showPortalFoto}
				foto={photo}
				onClose={() => {
					setShowPortalFoto(false);
				}}
			/>

			<Portal
				show={showPortalPDF}
				closeOnEsc={true}
				onClose={() => {
					setShowPortalPDF(false);
				}}
				className="w-500"
				classNameX="x"
			>
				<h3 className="margin-t-0">Exportar perfil para PDF</h3>
				<PDFDownloadLink className="cpnt-perfil-btn-download-pdf" document={<PerfilFreelancerPDF {...props} foto={stateFoto} />} fileName={`${primeiroNome(name)} (${name_area}).pdf`}>
					{({ blob, url, loading, error }) => {
						return (
							<div className="flex flex-column flex-center">
								<Icone
									imagem={loading ? imgIconeLoading : imgIconePDF}
									{...(loading ? {} : {cor: '#ad0b00'})}
									tamanho="80px"
								/>
								<div className="align-center margin-t-15">
									{loading ? (
										<div>Gerando PDF, aguarde...</div>
									) : (
										<div>
											<div>PDF gerado com sucesso!</div>
											<div className="flex flex-center margin-t-15 color-black">
												<div>Download PDF</div>
												<Icone
													imagem={imgIconeDownload}
													cor="#0277BD"
													tamanho="26px"
													wrap="margin-l-10"
												/>
											</div>
										</div>
									)}
								</div>
							</div>
							// <Info
							// 	icone={loading ? 'loading' : imgIconePDF}
							// 	texto={loading ? 'Gerando PDF, aguarde...' : (
							// 		<div className="align-center">
							// 			<div>PDF gerado com sucesso!</div>
							// 			<div className="margin-t-10">Download PDF</div>
							// 		</div>
							// 	)}
							// 	alt
							// />
						)
					}}
				</PDFDownloadLink>
			</Portal>

			<Portal
				show={showPortalExcluirPerfil}
				// closeOnEsc={false}
				onClose={() => {
					setShowPortalExcluirPerfil(false);
				}}
				className="w-700 box-txt"
			>
				<h3>Remover usuário freelancer da plataforma CROWD</h3>
				<p className="fs-14 italic">Se você excluir a conta deste usuário freelancer, ele não poderá mais: fazer login na plataforma, receber propostas de novos jobs, negociar novos jobs, consultar o seu histórico de jobs realizados e receber emails com jobs e novidades.</p>
				<h4 className="fs-">Por gentileza, nos informe porquê você está excluindo o perfil*:</h4>
				<div className="fs-18">
					<Radio2
						label="O usuário freelancer solicitou exclusão via Suporte"
						_={{
							value: 7,
							checked: stateExcluirPerfilOpcao === 7 ? true : false,
							onChange: e => {
								setStateExcluirPerfilOpcao(7);
							}
						}}
						wrap="margin-t-10"
					/>
					<Radio2
						label="O usuário freelancer não atendeu os termos de uso"
						_={{
							value: 8,
							checked: stateExcluirPerfilOpcao === 8 ? true : false,
							onChange: e => {
								setStateExcluirPerfilOpcao(8);
							}
						}}
						wrap="margin-t-10"
					/>
					<Radio2
						label="Encerrando uma conta duplicada"
						_={{
							value: 9,
							checked: stateExcluirPerfilOpcao === 9 ? true : false,
							onChange: e => {
								setStateExcluirPerfilOpcao(9);
							}
						}}
						wrap="margin-t-10"
					/>
					<Radio2
						label="Encerrando uma conta de testes"
						_={{
							value: 10,
							checked: stateExcluirPerfilOpcao === 10 ? true : false,
							onChange: e => {
								setStateExcluirPerfilOpcao(10);
							}
						}}
						wrap="margin-t-10"
					/>
					<Radio2
						label="Outro"
						_={{
							value: 11,
							checked: stateExcluirPerfilOpcao === 11 ? true : false,
							onChange: e => {
								setStateExcluirPerfilOpcao(11);
							}
						}}
						wrap="margin-t-10"
					/>
				</div>

				<div className="margin-t-25">
					{stateExcluirPerfilOpcao === 7 ? (
						<Input2
							_={{
								placeholder: 'Nos informe a data de solicitação e o número do ticket no Suporte*',
								type: 'textarea',
								value: stateExcluirPerfilDescricao,
								onChange: e => {
									setStateExcluirPerfilDescricao(e.target.value);
								}
							}}
						/>
					) : null}
					{stateExcluirPerfilOpcao === 8 ? (
						<Input2
							_={{
								placeholder: 'Nos informe o que levou a exclusão deste usuário. Se possível, informando qual(is) item(s) dos termos de uso o freelancer não atendeu.*',
								type: 'textarea',
								value: stateExcluirPerfilDescricao,
								onChange: e => {
									setStateExcluirPerfilDescricao(e.target.value);
								}
							}}
						/>
					) : null}
					{stateExcluirPerfilOpcao === 11 ? (
						<Input2
							_={{
								placeholder: 'Nos informe o que levou a exclusão deste usuário:*',
								type: 'textarea',
								value: stateExcluirPerfilDescricao,
								onChange: e => {
									setStateExcluirPerfilDescricao(e.target.value);
								}
							}}
						/>
					) : null}
				</div>
				{stateExcluirPerfilOpcao !== false ? (
					<div className="margin-t-30">
						<h4 className="align-center margin-b-15">Tem certeza que deseja excluir essa conta?</h4>
						<div className="flex flex-center">
							<Button2
								_={{
									value: 'Não, quero manter essa conta',
									onClick: () => {
										setShowPortalExcluirPerfil(false);
									}
								}}
								wrap="margin-10"
							/>
							<Button2
								_={{
									value: 'Sim, quero excluir essa conta',
									className: 'btn btn-red',
									onClick: () => {
										if(stateExcluirPerfilOpcao === 7 || stateExcluirPerfilOpcao === 8 || stateExcluirPerfilOpcao === 11) {
											if(stateExcluirPerfilDescricao.trim() === '') {
												msg.error('Por favor, preencha o motivo antes de confirmar a exclusão da sua conta.');
												return;
											}
										}
										setShowPortalExcluirPerfilConfirmacao(true);
									}
								}}
								wrap="margin-10"
							/>
						</div>
					</div>
				) : null}
			</Portal>

			{/* Confirmação de exclusão */}
			<PortalConfirmacao
				titulo="Deseja realmente excluir este perfil de freelancer?"
				desc="Essa ação não poderá ser desfeita. Todos os dados associados a esse perfil também serão excluídos."
				show={showPortalExcluirPerfilConfirmacao}
				onClose={() => setShowPortalExcluirPerfilConfirmacao(false)}
				onClickCancelar={() => setShowPortalExcluirPerfilConfirmacao(false)}
				onClickConfirmar={() => {
					setShowPortalExcluirPerfilConfirmacao(false);
					setShowPortalPerfilExcluido(true);
				}}
				loadingConfirmar={false}
			/>

			{/* Confirmação de exclusão de conta */}
			<Portal
				show={showPortalPerfilExcluido}
				closeOnEsc={false}
				onClose={() => {
					setShowPortalPerfilExcluido(false);
				}}
				className="w-600 box-txt"
				x={false}
			>
				<div className="flex flex-center">
					<div className="margin-auto">
						<Icone
							imagem={imgIconePerfilExcluido}
							cor="#cf0000"
							tamanho="150px"
						/>
					</div>
					<div className="fs-16 padding-l-30">
						<h3 className="color-red">Conta excluída com sucesso.</h3>
						<p>Este usuário não poderá mais fazer login na plataforma. {stateExcluirPerfilOpcao === 7 ? <>Também foi enviado um e-mail para esse usuário informando que sua conta foi excluída.</> : null}</p>
					</div>
				</div>
				<div className="margin-t-30">
					<div className="flex flex-center">
						<Button2
							_={{
								value: 'Finalizar',
								onClick: () => {
									setShowPortalPerfilExcluido(false);
									setShowPortalExcluirPerfil(false);
									onClose();
								}
							}}
							wrap="margin-10"
						/>
					</div>
				</div>
			</Portal>

			{/* Portal informações hunter */}
			<PortalDadosContato
				idFreelancer={id}
				show={showPortalDadosContato}
				onClose={() => {
					setShowPortalDadosContato(false);
				}}
			/>

		</div>
	)
};

export default PerfilFreelancer;