import React from 'react';

// css
import './Disponibilidade.css';

const Disponibilidade = props => {
	const {
		status,
		className = '',
		textoDisponivel = 'Disponível',
		textoIndisponivel = 'Indisponível',
		textoOutro = 'Não informado',
		exibirTextoOutro,
		min,
		alt,
	} = props;

	return (
		<div className={`${min ? 'cpnt-disponibilidade-min' : 'cpnt-disponibilidade'} ${status ? (alt ? 'cpnt-disponibilidade-disponivel-alt' : 'cpnt-disponibilidade-disponivel') : (alt ? 'cpnt-disponibilidade-indisponivel-alt' : 'cpnt-disponibilidade-indisponivel')} ${exibirTextoOutro ? (status === 3 ? 'cpnt-disponibilidade-outro' : '') : ''} ${className}`}>
			{!exibirTextoOutro || (exibirTextoOutro && status) !== 3 ? (
				status ? textoDisponivel : textoIndisponivel
			) : (
				textoOutro
			)}
		</div>
	);
};

export default Disponibilidade;