import React, { useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// Componentes
import Info from '@cpnt/Info/Info';

// Textos
import chatMensagensVariaveis from '@texto/chatMensagensVariaveis';

const ChatMensagemVariavel = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		metadata = {},
		wrap = '',
		className = '',
	} = props;

	var dados = chatMensagensVariaveis({
		modelo: metadata.modelo,
		tipoUsuario: store.usuario.role === 2 ? 'freelancer' : 'ususario',
	});

	if(!Object.keys(dados).length) {
		return (
			<div className={`cpnt-chatmsg cpnt-chatmsg-posicao-center cpnt-chatmsg-tipo-${metadata.tipo ? metadata.tipo : 'default'} ${wrap}`}>
				<div className={`cpnt-chatmsg-mensagem-box cpnt-chatmsg-mensagem-box-center ${className}`}>
					<div className="cpnt-chatmsg-mensagem">
						Conteúdo da mensagem não encontrado
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className={`cpnt-chatmsg cpnt-chatmsg-posicao-center cpnt-chatmsg-tipo-${dados.tipo ? dados.tipo : 'default'} ${wrap}`}>
			<div className={`cpnt-chatmsg-mensagem-box cpnt-chatmsg-mensagem-box-center ${className}`}>
				{dados.titulo && dados.titulo !== '' ? (
					<div className={`cpnt-chatmsg-custom-titulo`}>
						<h3 className="margin-0 padding-0">{dados.titulo}</h3>
					</div>
				) : null}
				<div className="cpnt-chatmsg-mensagem">
					{dados.icone ? (
						<Info
							icone={dados.icone}
							texto={dados.mensagem}
							alt
						/>
					) : (
						dados.mensagem
					)}
				</div>
			</div>
		</div>
	);
};

export default ChatMensagemVariavel;