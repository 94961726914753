import React, { useEffect, useRef, useState } from 'react';

// Import
import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";
import '@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css';

// css
import './InputDate.css';

// Componentes
import Input2 from "@cpnt/Input2/Input2";
import Tip from "@cpnt/Tip/Tip";

// Funções
import { dataApiCrop, dataBrParaObj, dataEngParaBrAlt, dataEngParaObj, validarFormatoDataBr } from "@func/utils";

const InputDate = props => {

	const inputRef = useRef();

	const {
		className = '',
		classNameWrap = '',
		wrap = '',

		_,
		_defaultsCalendar = {},

		label,
		value,
		onChange,
		disabled,
		req,
		tip,
	} = props;

	useEffect(() => {
		var dataString = dataApiCrop(value);
		var dataObj = dataEngParaObj(dataString);
		if(dataObj) {
			setValueCalendario(dataObj);
			var dataBr = dataEngParaBrAlt(dataString);
			inputRef.current.value = dataBr;
		}
	}, [value]);

	const [valueCalendario, setValueCalendario] = useState(dataEngParaObj(value));
	const [showCalendario, setShowCalendario] = useState(false);

	const dataObjParaString = data => {
		return `${data.year}-${(data.month >= 10 ? data.month : `0${data.month}`)}-${(data.day >= 10 ? data.day : `0${data.day}`)}`;
	}

	const myCustomLocale = {
		months: [
			'Janeiro',
			'Fevereiro',
			'Março',
			'Abril',
			'Maio',
			'Junho',
			'Julho',
			'Agosto',
			'Setembro',
			'Outubro',
			'Novembro',
			'Dezembro',
		],
		weekDays: [
			{
				name: 'Domingo', // used for accessibility
				short: 'D', // displayed at the top of days' rows
				isWeekend: true, // is it a formal weekend or not?
			},
			{
				name: 'Segunda',
				short: 'S',
			},
			{
				name: 'Terça',
				short: 'T',
			},
			{
				name: 'Quarta',
				short: 'Q',
			},
			{
				name: 'Quinta',
				short: 'Q',
			},
			{
				name: 'Sexta',
				short: 'S',
			},
			{
				name: 'Sábado',
				short: 'S',
				isWeekend: true,
			},
		],

		// just play around with this number between 0 and 6
		weekStartingIndex: 0,

		// return a { year: number, month: number, day: number } object
		getToday(gregorainTodayObject) {
			return gregorainTodayObject;
		},

		// return a native JavaScript date here
		toNativeDate(date) {
			return new Date(date.year, date.month - 1, date.day);
		},

		// return a number for date's month length
		getMonthLength(date) {
			return new Date(date.year, date.month, 0).getDate();
		},

		// return a transformed digit to your locale
		transformDigit(digit) {
			return digit;
		},

		// texts in the date picker
		nextMonth: 'Próximo mês',
		previousMonth: 'Mês anterior',
		openMonthSelector: 'Abrir seletor de mês',
		openYearSelector: 'Abrir seletor de ano',
		closeMonthSelector: 'Fechar seletor de mês',
		closeYearSelector: 'Fechar seletor de ano',
		defaultPlaceholder: 'Selecione...',

		// for input range value
		from: 'de',
		to: 'para',


		// used for input value when multi dates are selected
		digitSeparator: ',',

		// if your provide -2 for example, year will be 2 digited
		yearLetterSkip: 0,

		// is your language rtl or ltr?
		isRtl: false,
	}

	/*
	** Calendar - Define a data ao selecionar no calendario
	** ================================================== */
	const definirDataCalendar = dataObj => {
		var dataString = dataObjParaString(dataObj);
		onChange(dataString);
		setValueCalendario(dataObj);
		setShowCalendario(false)
	}

	/*
	** Blur - define a data ou limpa o campo
	** ================================================== */
	const definirDataInputBlur = data => {
		if(validarFormatoDataBr(data)) {
			var dataObj = dataBrParaObj(data);
			var dataString = dataObjParaString(dataObj);
			onChange(dataString);
			setValueCalendario(dataObj);
		} else {
			onChange('');
			setValueCalendario(false);
			inputRef.current.value = '';
		}
	}

	/*
	** Change - Ao alterar os dados, define nova data
	** ================================================== */
	const definirDataInputChange = data => {
		if(validarFormatoDataBr(data)) {
			var dataObj = dataBrParaObj(data);
			var dataString = dataObjParaString(dataObj);
			onChange(dataString);
			setValueCalendario(dataObj);
		}
	}

	return (
		<>
			<div className={`cpnt-inputdate ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-inputdate-box ${className}`}>
					<Tip
						tip={
							<Input2
								label={label}
								req={req}
								tip={tip}
								_={{
									onBlur: e => {
										definirDataInputBlur(e.target.value);
									},
									onChange: e => {
										definirDataInputChange(e.target.value);
									},
									onFocus: e => {
										setShowCalendario(true);
									},
									mask: '99/99/9999',
									_,
									ref: inputRef,
									...(disabled ? {disabled: true} : {})
								}}
							/>
						}
						click
						classNameDefault="z-index-10000"
						classNameTipDefault=""
						_defaults={{
							placement: 'bottom-center'
						}}
					>
						<Calendar
							value={valueCalendario}
							onChange={definirDataCalendar}
							shouldHighlightWeekends
							locale={myCustomLocale}
							colorPrimary="#0277BD" // added this
							{..._defaultsCalendar}
						/>
					</Tip>
				</div>
			</div>
		</>
	);
};

export { InputDate };
