import { caminhoFoto, dataApiParaHoraMin, dataApiParaHoraMinSeg } from "@func/utils";

// Definir ID único baseado no id do usuário
export const chatGetId = (usuario) => {
	if(!usuario) {
		return 0;
	}

	var _id;

	if(usuario._id) {
		return usuario._id;
	}

	if(usuario.user_id) {
		_id = usuario.user_id;
	}

	else if(usuario.id_user) {
		_id = usuario.id_user;
	}

	else if(usuario.freelancer_id) {
		_id = usuario.freelancer_id;
	}

	if(usuario.id_freelancer) {
		_id = usuario.id_freelancer;
	}

	return _id;
}

// Formatar usuários online
export const chatFormatarDadosUsuario = (usuario) => {
	return {
		_: usuario,
		_id: chatGetId(usuario),
		nome: usuario.user_name || usuario.name_user || usuario.freelancer_name || usuario.name_freelancer || usuario.name,
		foto: caminhoFoto(usuario.image_user).url,
	};
}

// Formatar usuários online
export const chatFormatarDadosUsuarioSidebar = (usuario) => {
	return {
		_: usuario,
		_id: chatGetId(usuario),
		name: usuario.name,
		photo: caminhoFoto(usuario.photo).url,
		mensagem: usuario.text,
		qtdMensagens: usuario.qtdNotReaded,
		data: dataApiParaHoraMin(usuario.created_at, ''),
	};
}

// Adicionar usuário
export const chatSalaAddUsuarioOnline = (usuarioAtual, usuariosAnteriores = []) => {
	const usuarioAtualFormatado = chatFormatarDadosUsuario(usuarioAtual);
	const existe = usuariosAnteriores.filter(item => item._id === usuarioAtualFormatado._id);
	if(existe.length) {
		return usuariosAnteriores;
	}
	usuariosAnteriores.push(usuarioAtualFormatado);
	return usuariosAnteriores;
}

// Remover usuário
export const chatSalaRemoverUsuarioOnline = (usuarioAtual, usuariosAnteriores = []) => {
	// var usuarios = [...usuariosAnteriores];
	const usuarioAtualFormatado = chatFormatarDadosUsuario(usuarioAtual);
	const usuariosSemOnline = usuariosAnteriores.filter(item => item._id !== usuarioAtualFormatado._id);
	return usuariosSemOnline;
}

// Adicionar usuário
export const chatSalaAddUsuariosOnline = (listaUsuarios = [], usuarioAtual = {}) => {
	const usuarioAtualFormatado = chatFormatarDadosUsuario(usuarioAtual);
	const listaSemAtual = listaUsuarios.filter(item => {
		const itemOk = chatFormatarDadosUsuario(item);
		return itemOk._id !== usuarioAtualFormatado._id
	});
	const listaSemAtualFormatada = listaSemAtual.map(item => {
		return chatFormatarDadosUsuario(item);
	});
	return listaSemAtualFormatada;
}
