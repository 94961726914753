import { Select3Lista } from '@cpnt/Select3/Select3';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';
import React from 'react';

// Imagens
import imgIconeAdd from '@svg/solid/circle-plus.svg';
import imgIconeX from '@svg/solid/circle-x.svg';
import imgIconeCog from '@svg/solid/gear.svg';
import imgIconeEditar from '@svg/solid/pencil.svg';
import imgIconeRemover from '@svg/solid/trash-can.svg';
import imgIconeListaAtual from '@svg/solid/user-check.svg';
import imgIconeUsuario from '@svg/solid/user.svg';
import { showPortal } from '@route/routeUtils';
import { msg } from '@func/utils';

export const ListaLocalSelect = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		onClick,
	} = props;

	return (
		<Select3Lista
			options={[
				{
					value: 'visualizarListas',
					label: (
						<TextoIconeLink
							texto="Visualizar listas salvas"
							icone={imgIconeListaAtual}
							iconeCor="#676767"
						/>
					)
				},
				{
					value: 'visualizarPerfis',
					label: (
						<TextoIconeLink
							texto="Visualizar selecionados"
							icone={imgIconeUsuario}
							iconeCor="#0277BD"
						/>
					)
				},
				{
					value: 'novaLista',
					label: (
						<TextoIconeLink
							texto="Nova lista"
							icone={imgIconeAdd}
							iconeCor="#229D3E"
						/>
					)
				},
				{
					value: 'limparLista',
					label: (
						<TextoIconeLink
							texto="Limpar lista atual"
							icone={imgIconeX}
							iconeCor="#eab20e"
						/>
					)
				},
				{
					value: 'editarLista',
					label: (
						<TextoIconeLink
							texto="Editar lista"
							icone={imgIconeEditar}
							iconeCor="#555"
						/>
					)
				},
				{
					value: 'removerLista',
					label: (
						<TextoIconeLink
							texto="Remover lista atual"
							icone={imgIconeRemover}
							iconeCor="#E74C3C"
						/>
					)
				},
				{
					value: 'removerTodasAsListas',
					label: (
						<TextoIconeLink
							texto="Remover todas as listas"
							icone={imgIconeX}
							iconeCor="red"
						/>
					)
				},
			]}
			onClick={onClick}
			labelClass="flex-1"
			posicao=""
			classNameDefault=""
		/>
	);
};

