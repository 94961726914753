import React from 'react';

// CSS
import './LinhaItem.css';

// Components
import Icone from '../Icone/Icone';

const LinhaItem = props => {
	const {
		titulo,
		desc,
		tituloSep = ':',
		className = '',
		classNameDesc = '',
		icone,
		iconeTamanho = '14px',
		iconeCor = '#999',
		alt,
		onClick,
	} = props;

	return (
		<div className={`cpnt-linhaitem ${className}`} {...(onClick ? {onClick: onClick} : {})}>
			{icone && <Icone imagem={icone} wrap="cpnt-linhaitem-icone margin-r-5" tamanho={iconeTamanho} cor={iconeCor} />}
			{titulo && <span className={`cpnt-linhaitem-titulo inline-flex flex-wrap flex-center-v margin-r-5 ${alt ? 'bold' : ''}`}>{titulo}{tituloSep}</span>}
			{desc && <span className={`cpnt-linhaitem-desc ${classNameDesc}`}>{desc}</span>}
		</div>
	);
};

export default LinhaItem;