import React, { useContext, useEffect, useState } from 'react';
import { scroller } from 'react-scroll';

// Store
import StoreContext from '@/_/contexts/StoreContext';

import Button2 from "@cpnt/Button2/Button2";
import Input2 from "@cpnt/Input2/Input2";

// css
import apiHubspot from "@api/_externo/hubspot";
import { apiGetTemplate } from "@api/_functions/geral";
import Checkbox2 from "@cpnt/Checkbox2/Checkbox2";
import OverlayConteudo from "@cpnt/OverlayConteudo/OverlayConteudo";
import { camposInvalidos, htmlToJsx, msg, ultimoNome } from "@func/utils";
import imgPessoas from '@img/fotos/pessoas-2.png';
import imgPessoa from '@img/fotos/programa-de-recomendacao.jpg';
import imgApresentacao from '@img/outros/apresentacao-crowd.png';
import pdfApresentacao from '@pdf/apresentacao-crowd-plataforma-de-talentos-de-marketing-e-tecnologia.pdf';
import './IndiqueACrowd.css';
import { DEV_ID, URL } from "@_config/config";
import { IndiqueShare } from "./IndiqueShare/IndiqueShare";
import { msgs, msgStatus } from "@_func/request";

import { primeiroNome } from "@_func/regex";

const IndiqueACrowd = props => {
	const [store, setStore] = useContext(StoreContext);

	const {
		className = '',
		classNameWrap = '',
		wrap = '',
	} = props;

	const camposDefault = {
		firstname: primeiroNome(store.usuario.name),
		lastname: ultimoNome(store.usuario.name),
		email: store.usuario.email,
		phone: store.usuario.phone,
		accept_terms: false,
		message: '',
	};
	const [stateDados, setStateDados] = useState(camposDefault);
	const [showMensagemSucesso, setShowMensagemSucesso] = useState(false);
	const [loadingGetTemplate, setLoadingGetTemplate] = useState(false);
	const [loadingEnviar, setLoadingEnviar] = useState(false);
	const [stateTemplate, setStateTemplate] = useState({
		text: '',
	});
	const [textoIndicacaoRetorno, setTextoIndicacaoRetorno] = useState('');
	const [stateBackupTemplate, setStateBackupTemplate] = useState('');

	useEffect(() => {
		getTemplates();
	}, []);

	const getTemplates = async () => {
		const retGetTemplate = await apiGetTemplate({id: (DEV_ID ? 62 : 94), setLoadingGetTemplate, setStateTemplate});
		if(retGetTemplate.status === 200) {
			setStateBackupTemplate(retGetTemplate.data.text);
		}
	}

	const enviarConvite = async (e) => {
		e.preventDefault();

		if(loadingEnviar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			firstname: stateDados.firstname,
			lastname: stateDados.lastname,
			email: stateDados.email,
			phone: stateDados.phone,
			accept_terms: stateDados.accept_terms,
			message: stateTemplate.text,
		};

		if(camposInvalidos(dadosEnviar, {
			firstname: 'Seu nome',
			email: 'Seu e-mail',
			lastname: 'Seu sobrenome',
			accept_terms: 'Termos de uso e política de privacidade',
			phone: 'Seu número de telefone / WhatsApp',
			message: 'Sua indicação (dados do indicado)',
		}, [
			'email',
			'firstname',
			'lastname',
			'phone',
			'message',
			'accept_terms',
		])) return;

		const dadosEnviarHub = {
			fields: [
				{
					name: 'email',
					value: dadosEnviar.email,
				},
				{
					name: 'firstname',
					value: dadosEnviar.firstname
				},
				{
					name: 'lastname',
					value: dadosEnviar.lastname
				},
				{
					name: 'message',
					value: stateTemplate.text
				}
			],
			legalConsentOptions: {
				consent: {
					consentToProcess: stateDados.accept_terms,
					text: "Li e concordo com os Termos de uso e a Política de privacidade.",
				}
			},
			context: {
				pageName: document.title,
				pageUri: window.location.href,
			}
		};

		setLoadingEnviar(true);

		const retEnviarConvite = await apiHubspot.sendForm.post('2e4216bc-fc73-45a1-9ad7-5d219567eaa2', dadosEnviarHub);

		if(retEnviarConvite.status === 200) {
			msg.success('Indicação realizada com sucesso!');
			setTextoIndicacaoRetorno(retEnviarConvite.data.inlineMessage);
			setShowMensagemSucesso(true);
			setStateDados(camposDefault);
			setStateTemplate({
				text: stateBackupTemplate,
			});
		} else {
			msgs([retEnviarConvite]);
		}
		setLoadingEnviar(false);
	}

	return (
		<>
			<div className="pg-perfil base-full">
				<div className="conteudo-box">
					<div className="titulo-box titulo-box-res">
						<div className="flex flex-center-v">
							<h3 className="titulo">Programa de Recomendação CROWD</h3>
						</div>
					</div>

					<div className={`pg-indiquecrowd ${wrap} ${classNameWrap}`}>
						<div className={`pg-indiquecrowd-box ${className}`}>
							<div className="base">
								<div className="base-content">
									<div className="pg-indique-box">
										<div className="pg-indique-l box-1-980">
											<h3 className="titulo titulo-g">Indique a Crowd para sua rede de contatos. Ganhe uma comissão recorrente enquanto suas indicações permanecerem clientes da Crowd.</h3>
											<div>
												<img src={imgPessoas} alt="pessoas" className="block w-100p padding-t-30 padding-b-10" />
											</div>
											<div className="italic margin-v-30">
												Uma conversa de bar com um amigo que é gerente em uma empresa e um whatsapp conectando a CROWD pode te gerar uma renda extra recorrente.
											</div>
											<ul className="lista-numero">
												<li>Envie o contato de conhecidos interessados em contratar serviços ou profissionais de marketing, produto e tecnologia.</li>
												<li>Nosso time comercial recebe a indicação e prossegue com a abordagem para fazer a melhor venda para esse potencial cliente.</li>
												<li>Quando essa venda acontece, uma renda extra entra na conta de quem fez a indicação. Ganhe uma comissão recorrente enquanto suas indicações permanecerem clientes da Crowd.</li>
											</ul>
										</div>
										<div className="pg-indique-r relative box-1-980" id="indique">
											<h3 className="margin-t-0">Envie sua indicação aqui</h3>

											<OverlayConteudo
												exibir={showMensagemSucesso}
												exibirFechar={true}
												texto={
													<div>
														<div className="strong fs-20">Indicação realizada com sucesso!</div>
														<div className="margin-t-20">{htmlToJsx(textoIndicacaoRetorno)}</div>
													</div>
												}
												toggle={e => setShowMensagemSucesso(false)}
												bgAlt={true}
											/>
											<form
												onSubmit={enviarConvite}
												className="wrap-5 margin-t-5 padding-t-5"
											>
												<div className="input-box">
													<Input2
														label="Seu e-mail"
														req
														_={{
															type: 'email',
															value: stateDados.email,
															onChange: e => {
																setStateDados({
																	...stateDados,
																	email: e.target.value,
																});
															}
														}}
													/>
												</div>
												<div className="input-box">
													<Input2
														label="Seu nome"
														req
														_={{
															value: stateDados.firstname,
															onChange: e => {
																setStateDados({
																	...stateDados,
																	firstname: e.target.value,
																});
															}
														}}
													/>
												</div>
												<div className="input-box">
													<Input2
														label="Seu sobrenome"
														req
														_={{
															value: stateDados.lastname,
															onChange: e => {
																setStateDados({
																	...stateDados,
																	lastname: e.target.value,
																});
															}
														}}
													/>
												</div>
												<div className="input-box">
													<Input2
														label="Seu número de telefone / WhatsApp"
														req
														_={{
															value: stateDados.phone,
															onChange: e => {
																setStateDados({
																	...stateDados,
																	phone: e.target.value,
																});
															}
														}}
													/>
												</div>
												<div className="input-box">
													<Input2
														label="Sua indicação (dados do indicado)"
														tip="Insira os dados de quem você está indicando, como por exemplo o nome, e-mail, telefone, redes sociais, e etc. Informe também qual sua ligação com esse contato, de forma que você seja reconhecido, assim poderemos formalizar a sua indicação."
														req
														_={{
															type: 'textarea',
															value: stateTemplate.text,
															onChange: e => {
																setStateTemplate({
																	...stateTemplate,
																	text: e.target.value,
																});
															}
														}}
														loading={loadingGetTemplate}
													/>
												</div>
												<div className="input-box">
													<Checkbox2
														label={<>Li e concordo com os <a href="https://crowd.app.br/termos-de-uso" target="_blank" rel="noreferrer" className="link">Termos de uso</a> e a <a href="https://crowd.br.com/politica-de-privacidade" target="_blank" rel="noreferrer" className="link">Política de privacidade</a>.</>}
														_={{
															checked: stateDados.accept_terms,
															onChange: e => {
																setStateDados({
																	...stateDados,
																	accept_terms: e.target.checked ? true : false,
																});
															},
														}}
														smallLabel
													/>
												</div>
												<div className="input-box">
													<Button2
														_={{
															value: 'Enviar indicação',
															onClick: enviarConvite,
														}}
														wrap="w-100p flex-1"
														classNameExtra="w-100p"
														loading={loadingEnviar}
													/>
												</div>
											</form>
										</div>

									</div>

									<div>
										<h3 className="align-center">SOBRE A CROWD - PLATAFORMA DE TALENTOS DE MARKETING E TECNOLOGIA</h3>
										<div>Compartilhe os materiais abaixo com seus indicados:</div>
										<div className="box indique-box-video-apresentacao">
											<div className="box-2 indique-box-inner indique-box-video box-1-980">
												<div className="video-box">
													<iframe className="video-box-inner" width="560" height="315" src="https://www.youtube.com/embed/gYgW7uc5kVA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
												</div>
												<h3 className="align-center">Conheça a Crowd: a melhor plataforma de talentos de marketing e tecnologia</h3>
												<IndiqueShare
													texto="https://www.youtube.com/embed/gYgW7uc5kVA"
												/>
											</div>
											<div className="box-2 indique-box-inner indique-box-apresentacao box-1-980">
												<a href={pdfApresentacao} target="_blank" rel="noreferrer" className="a color-inherit">
													<img src={imgApresentacao} alt="apresentacao" className="w-100p block" />
													<h3 className="align-center">Conheça a Crowd - Apresentação em PDF</h3>
												</a>
												<IndiqueShare
													texto={`${URL}/assets/pdf/por-que-contratar-a-crowd-plataforma-de-talentos-de-marketing-e-tecnologia.pdf`}
												/>
											</div>
										</div>
										<div className="flex flex-center padding-t-50">
											<Button2
												_={{
													value: 'Envie sua indicação aqui',
													onClick: e => {
														scroller.scrollTo('indique', {
															duration: 800,
															delay: 0,
															smooth: 'ease',
															offset: 1,
														});
													},
													className: 'btn btn-default btn-large'
												}}
											/>
										</div>
									</div>

									<div className="margin-t-30">
										<div className="titulo-g">COMO FUNCIONA</div>
										<div className="box">
											<div className="box-2 pg-indique-como-funciona-lista box-1-980">
												<ol>
													<li>Para participar do programa de recomendação é necessário estar cadastrado na plataforma Crowd (crowd.app.br).</li>
													<li>No formulário acima, indique uma ou mais pessoas para se tornar cliente da Crowd.</li>
													<li>Nosso time comercial recebe a indicação e prossegue com a abordagem para fazer a melhor venda para esse potencial cliente. </li>
													<li>Se a sua indicação se tornar cliente da Crowd, você será notificado e receberá uma comissão de 5% do valor bruto da venda do 1º contrato do cliente indicado enquanto a pessoa/empresa permanecer como cliente da Crowd durante a vigência do 1º contrato.</li>
													<li>Para receber a comissão é necessário emitir e enviar sua nota fiscal para a Crowd seguindo as orientações previamente informadas pelo departamento financeiro da Crowd.</li>
													<li>O dinheiro é depositado na sua conta todo dia 30 após o pagamento do cliente. Ou seja, a cada mês que o cliente efetua o pagamento do seu compromisso com a Crowd, o valor da comissão é repassado para quem indicou o contato. </li>
													<li>Se o cliente encerrar o contrato com a Crowd, a comissão será encerrada.</li>
													<li>Para saber sobre o andamento das suas indicações, entre em contato com indicacoes@crowd.net.br. </li>
												</ol>
											</div>
											<div className="box-2 pg-indique-como-funciona-img none-980">
												<img src={imgPessoa} alt="pessoa" className="w-100p block" />
											</div>
										</div>
									</div>

									<div className="flex flex-center padding-t-50">
										<Button2
											_={{
												value: 'Indique aqui',
												onClick: e => {
													scroller.scrollTo('indique', {
														duration: 800,
														delay: 0,
														smooth: 'ease',
														offset: 1,
													});
												},
												className: 'btn btn-default btn-large'
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { IndiqueACrowd };
