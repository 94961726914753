import { urlImg } from "./url";

import imgPlaceholderPessoa from '@_img/default/userpic.png';

export const dadosSelect = (dadosObj = {}) => {
	const {
		data = [],
		key = 'id',
		value = 'name',
		keyIcone,
		classNameIcon,
		caminhoFotoDefault = true,
		placeholderPessoa = imgPlaceholderPessoa,
		placeholderIcone = imgPlaceholderPessoa,
	} = dadosObj;

	var retorno = [];
	if(data && typeof(data) === 'object' && data.length) {
		data.map((val) => {
			var obj = {
				...val,
				value: val[key],
				label: val[value],
			};
			if(keyIcone) {
				if(val[keyIcone] !== undefined) {
					if(caminhoFotoDefault) {
						if(val[keyIcone] !== '') {
							obj.icon = urlImg(val[keyIcone]);
						} else {
							obj.icon = placeholderPessoa;
						}
					} else {
						obj.icon = val[keyIcone];
					}
					if(classNameIcon) {
						obj.classNameIcon = classNameIcon;
					}
				}
			}
			retorno.push(obj);
		});
	}
	return retorno;
}
