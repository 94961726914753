import React, { useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';
import { primeiroNome } from "@_func/regex";

const CadastroIndicacaoTexto = props => {

	const [store, setStore] = useContext(StoreContext);

	var nome = store.indicacao?.name;

	if(nome) {
		nome = primeiroNome(nome);
	}

	return (
		<div className="fs-15">
			<h1 className="align-center margin-t-0">Você recebeu um convite para se cadastrar na Crowd</h1>
			<p>Seja bem vindo, você foi convidado por <strong className="color-blue">{nome || '[desconhecido]'}</strong> para fazer parte da plataforma Crowd.</p>
			<p>Isso é um sinal de que você é comprometido com a sua carreira freelancer em marketing ou tecnologia.</p>
			<p>E que você investe constantemente para ser o melhor profissional para seus clientes.</p>
			<p>Agora, você pode contar com uma plataforma para te ajudar a crescer profissionalmente, ser valorizado e reconhecido pelo seu ótimo trabalho.</p>

			<h2 className="align-center padding-t-20">O que é a Crowd?</h2>
			<p className="align-center">Plataforma de negócios para freelancers de marketing e tecnologia que buscam crescimento profissional, clientes melhores e boa remuneração</p>

			<ul className="lista-check">
				<li>Cadastro 100% gratuito ao freelancer</li>
				<li>Possibilidade de receber propostas de jobs que pagam em real ou em dólar</li>
				<li>Jobs remotos para trabalhar nos locais e horários que se sente mais produtivo</li>
				<li>Oportunidades para atuar em projetos de grandes e médias empresas</li>
				<li>Não precisa correr atrás de jobs, as melhores propostas chegam até o freelancer</li>
				<li>Previsibilidade e certeza de pagamento após aprovação dos jobs</li>
				<li>Você como freelancer protagonista e com voz ativa nos projetos</li>
				<li>Academy com diversos cursos gratuitos de marketing, produto e tecnologia</li>
				<li>Comunidade para você fazer networking com outros freelancers e com profissionais que contratam freelancers na plataforma</li>
			</ul>

			<p>Cadastre-se gratuitamente para poder receber propostas de jobs remotos de marketing ou tecnologia adequados ao seu perfil e expertise.</p>

		</div>
	);
};

export { CadastroIndicacaoTexto };
